import React from 'react';

import { CampaignUnit } from '../../models/Unit';
import MapBox from './MapBox';

type Props = {
  mapbox: MapBox
  show_availability: boolean
}
export default class UnitLabels extends React.Component<Props, {}> {
  map: mapboxgl.Map
  recommendedImage: string = 'recommended-unit'
  availableImage: string = 'available-unit'
  requestedImage: string = 'requested-unit'
  cartImage: string = 'cart-unit'
  favoritedImage: string = 'favorited-unit'

  recommendedLayer: string = "units-recommended-layer"
  availableLayer: string = "units-available-layer"
  requestedLayer: string = "units-requested-layer"
  cartLayer: string = "units-cart-layer"
  favoritedLayer: string = 'units-favorited-layer'
  imagesLoaded: boolean = false

  componentDidMount() {
    this.map = this.props.mapbox.map;
    this.props.mapbox.onUnitsUpdate(this.updateLabels.bind(this));
  }

  updateLabels(_mapbox: MapBox) {
    this.loadImages();

    this.updateRecommended();
    this.updateAvailable();
    this.updateRequested();
    this.updateInCart();
    this.updateFavorited();
  }

  loadImages() {
    if (this.imagesLoaded) return;

    this.props.mapbox.addImage(this.recommendedImage);
    this.props.mapbox.addImage(this.favoritedImage);
    this.props.mapbox.addImage(this.availableImage);
    this.props.mapbox.addImage(this.requestedImage);
    this.props.mapbox.addImage(this.cartImage);
  }

  updateRecommended() {
    if (this.props.show_availability) return;
    this.updateLayer(this.recommendedLayer, ["get", "is_recommended"], this.recommendedImage, 30, -12);
  }

  updateAvailable() {
    this.updateLayer(this.availableLayer, ["==", ["get", "status_badge"], "available"], this.availableImage, 30, -12);
  }

  updateRequested() {
    this.updateLayer(this.requestedLayer, ["==", ["get", "status_badge"], "updating"], this.requestedImage, 30, -12);
  }

  updateInCart() {
    this.updateLayer(this.cartLayer, ["==", ["get", "status_badge"], "cart"], this.cartImage, 30, -12);
  }

  updateFavorited() {
    this.updateLayer(this.favoritedLayer, ["get", "is_favorited"], this.favoritedImage, 30, 12);
  }

  updateLayer(id, filter, image, x, y) {
    if (!this.map.getSource(this.source())) return;
    if (this.map.getLayer(id)) this.map.removeLayer(id);

    this.map.addLayer({
      id: id,
      type: "symbol",
      source: this.source(),
      filter: filter,
      layout: {
        "icon-image": image,
        "icon-offset": [
          "case",
          ["==", ["get", "direction"], "E"], ["literal", [-1 * x, y]],
          ["==", ["get", "direction"], "NE"], ["literal", [-1 * x, y]],
          ["==", ["get", "direction"], "SE"], ["literal", [-1 * x, y]],
          ["literal", [x, y]]
        ],
        "icon-rotation-alignment": "map",
        "icon-size": 0.5,
        "icon-allow-overlap": true,
        "text-allow-overlap": true
      }
    });
  }

  source() {
    return this.props.mapbox.unitsSource().id;
  }

  render() {
    return <div/>
  }
}




