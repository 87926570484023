import { connect } from 'react-redux';
import React from 'react'
import AuthStore from './../../stores/AuthStore';
import { fetchGeoRecommendations, loadPlan } from './actions';
import AddUnitsFromLocation from './AddUnitsFromLocation';
import Audience from './Audience';
import Geographies from './Audience/Geographies';
import GeoRecommendations from './Audience/GeoRecommendations';
import Geos from './Audience/Geos';
import Basics from './Basics';
import Summary from './Summary';
import ProgressTracker from './ProgressTracker';
import BottomNav from './BottomNav';
import { STEPS } from './reducer';

class Planner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentWillMount() {
    if (this.props.params && this.props.params.token) {
      await this.props.loadPlan(this.props.params.token);
      this.props.fetchGeoRecommendations();
    }
  }

  render() {
    const { step } = this.props;
    const geo_styles = STEPS.geography ? { marginTop: "40px" } : {};
    if (AuthStore.isLoggedIn()){
      return (
        <div className="container-fluid planner" sytles={geo_styles}>
            <div className="row">
              <ProgressTracker/>
            </div>
          <div className="row">
            <div className="col-12 planner-main">
              { step === STEPS.basics && <Basics /> }
              { step === STEPS.geography && <Geographies />}
              { step === STEPS.demographics && <Audience /> }
              { step === STEPS.budget && <Geos /> }
              { step === STEPS.confirm && <AddUnitsFromLocation /> }
            </div>
          </div>
        </div>
      )
    }else {
      return <div></div>
    }
    
  }
}

export default connect(
  ({ planner }) => ({ ...planner }),
  { loadPlan, fetchGeoRecommendations }
)(Planner)
