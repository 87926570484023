import * as React from 'react';
import Select from 'react-select';
import UploadButton from '../../../UploadButton';
import UploadStore from '../../../../stores/UploadStore'
import { get, del } from '../../../../utils/api'

const mobileAdCPM = 4.78;
const allowedMobileAdSizes = ["320x50", "640x100", "300x250", "600x500"];

class GeofencedMobileAds extends React.Component {
  constructor(props) {
    super(props);
    const { old_analytics } = this.props
    this.state = {
      mobile_ad_impressions: old_analytics.mobile_ad_impressions || "",
      mobile_ad_budget: old_analytics.mobile_ad_budget || "",
      mobile_ad_radius: old_analytics.mobile_ad_radius || "",
      mobile_ad_url: old_analytics.mobile_ad_url || "",
      mobile_ad_creatives: old_analytics.mobile_ad_creatives || [],
      errors: []
    }
    this.onSave = this.onSave.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onImpressionsChange = this.onImpressionsChange.bind(this)
    this.onBudgetChange = this.onBudgetChange.bind(this)
    this.onUploadComplete = this.onUploadComplete.bind(this)
    this.onUploadChange = this.onUploadChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.handleFileError = this.handleFileError.bind(this)
  }

  componentDidMount() {
    const { scrollToTop } = this.props
    this.unsubscribeList = [
      UploadStore.listen(this.onUploadChange),
    ]
    scrollToTop()
    this.getMobileAdCreatives()
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  onUploadChange(eventName, response) {
    if (eventName == 'upload:uploaded') {
      this.getMobileAdCreatives()
    }
  }

  async getMobileAdCreatives() {
    const { token } = this.props
    const { mobile_ad_creatives } = await get(`/api/v1/campaigns/${token}/analytics/old_analytics`)
    this.setState({ mobile_ad_creatives })
  }

  radiusOptions() {
    let options = []
    for (let i = 0.1; i < 2.1; i += 0.1) {
      let radius = Number.parseFloat(i).toFixed(1);
      let text = "miles";
      if (radius == 1.0) text = "mile";
      options.push({ label: radius + " " + text, value: radius });
    }
    return options;
  }

  onInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  applyCommas(number) {
    if (!number) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  onImpressionsChange(event) {
    let mobile_ad_impressions = event.target.value.replace(/,/g, "");
    if (isNaN(mobile_ad_impressions)) {
      return this.setState({ mobile_ad_impressions })
    }
    let mobile_ad_budget = ((mobile_ad_impressions / 1000) * mobileAdCPM).toFixed(2);
    this.setState({
      mobile_ad_budget: this.applyCommas(mobile_ad_budget),
      mobile_ad_impressions: this.applyCommas(mobile_ad_impressions)
    })
  }

  onBudgetChange(event) {
    let mobile_ad_budget = event.target.value.replace(/,/g, "");
    if (isNaN(mobile_ad_budget)) {
      return this.setState({ mobile_ad_budget });
    }
    let mobile_ad_impressions = ((mobile_ad_budget / mobileAdCPM) * 1000).toFixed(0);
    this.setState({
      mobile_ad_budget: this.applyCommas(mobile_ad_budget),
      mobile_ad_impressions: this.applyCommas(mobile_ad_impressions)
    })
  }

  onSelectChange(selection) {
    this.setState({ mobile_ad_radius: selection.value })
  }

  onUploadComplete(upload, file) {
    const { errors } = this.state
    const { token } = this.props
    this.setState({ error: null, mobileAdSuccess: null })
    this.checkImageSize(file.url).then((size) => {
      if (allowedMobileAdSizes.includes(size)) {
        UploadStore.uploadMobileAd(upload, file, size, token,
          {
            success: (data) => { this.setState({ mobileAdSuccess: true }) }
          }
        );
      } else {
        this.setState({ errors: [...errors, "Please upload one of the above sizes."] })
      }
    })
  }

  checkImageSize(url) {
    return new Promise((resolve, reject) => {
      let temp = new Image();
      temp.src = url;
      $(temp).one('load', () => {
        const size = temp.width + "x" + temp.height;
        resolve(size);
      });
    })
  }

  async onSave() {
    const { mobile_ad_impressions, mobile_ad_budget, mobile_ad_radius, mobile_ad_url } = this.state
    const { oldSave, onSubmit, analytic, deleteStep, card } = this.props
    const payload = { mobile_ad_impressions, mobile_ad_budget, mobile_ad_radius, mobile_ad_url }
    await oldSave(payload)
    await onSubmit(analytic)
    deleteStep(card.key)
  }

  async removeCreative(id) {
    const { token } = this.props
    const payload = {
      id,
      campaign_id: token
    }
    await del(`/api/v1/campaigns/${token}/analytics/remove_mobile_ad`, payload)
    this.getMobileAdCreatives()
  }

  renderCreatives() {
    const { mobile_ad_creatives } = this.state
    return mobile_ad_creatives.map((creative, idx) => {
      return (
        <div className="creative" key={`creative-${idx}`}>
          <div className="creative_header">
            <span className="file_name">{creative.name}</span>
            <span className="size">{creative.size}</span>
            <i className="fa fa-trash-o" onClick={this.removeCreative.bind(this, creative.id)}></i>
          </div>
          <div className="creative_image">
            <img src={creative.image_url} />
          </div>
        </div>
      )
    })
  }

  showErrors() {
    const { errors } = this.state
    return errors.map(error => 
      <div className="alert alert-danger">
        {error}
      </div>
    )
  }

  handleFileError(errors) {
    this.setState({ errors });
  }

  render() {
    const { mobile_ad_impressions, mobile_ad_budget, mobile_ad_radius, mobile_ad_url } = this.state
    return (
      <div className="analytics_custom_setup second_level">
        <div className="body_title">
          <h4>Configure Geofenced Mobile Ads</h4>
        </div>
        <div className="custom_body">
          <p>Run mobile ads that will appear on users' phones when they are near one of your outdoor ad units. The industry benchmark is to spend about 10% of what you're spending on outdoor ads on geofenced mobile ads.</p>
          <div className="field cost_calculator">
            <div className="impressions">
              <label htmlFor="mobile_ad_impressions">
                Impressions
              </label>
              <input
                type="text"
                id="mobile_ad_impressions"
                name="mobile_ad_impressions"
                className="form-control"
                value={mobile_ad_impressions}
                onChange={this.onImpressionsChange}
              />
            </div>
            <div className="symbol">
              x
            </div>
            <div className="cpm">
              <label>
                CPM
              </label>
              <div className="cpm_number">
                ${mobileAdCPM}
              </div>
            </div>
            <div className="symbol">
              =
            </div>
            <div className="budget">
              <label htmlFor="mobile_ad_budget">
                Budget
              </label>
              <div className="dollar_symbol">
                <input
                  type="text"
                  id="mobile_ad_budget"
                  name="mobile_ad_budget"
                  className="form-control"
                  value={mobile_ad_budget}
                  onChange={this.onBudgetChange}
                />
              </div>
            </div>
          </div>
          <div className="field delivery_radius">
            <label htmlFor="mobile_ad_radius">Delivery Radius</label>
            <Select
              name="mobile_ad_radius"
              options={this.radiusOptions()}
              value={this.radiusOptions().find(o => o.value == mobile_ad_radius)}
              onChange={this.onSelectChange}
              clearable={false}
              placeholder="Choose a delivery radius"
            />
          </div>
          <div className="field landing_page_url">
            <label htmlFor="mobile_ad_url">
              Landing Page URL
            </label>
            <input
              type="text"
              id="mobile_ad_url"
              name="mobile_ad_url"
              className="form-control"
              placeholder="e.g. https://www.mysite.com"
              value={mobile_ad_url}
              onChange={this.onInputChange}
            />
          </div>
          <div className="line_break"></div>
          <div>
            <div className="title">
              Upload Creative
              <span className="max"> (max of 10)</span>
            </div>

            <div className="description">
              Please upload both a standard and retina size for each creative.
            </div>

            <div className="size_options">
              <div className="size_option">
                Size option 1:
                Format: PNG/JPEG, Standard: 320 x 50, Retina: 640 x 100
              </div>

              <div className="size_option">
                Size option 2:
                Format: PNG/JPEG, Standard: 300 x 250,  Retina: 600 x 500
              </div>

              <div className="creative_upload clearfix">
                <UploadButton
                  handleFileError={this.handleFileError}
                  customDropzoneProps={{ accept: "image/*" }}
                  onComplete={this.onUploadComplete}
                >
                  <div>
                    <div className="file_icons">
                      <i className="fa fa-file-image-o"></i>
                    </div>
                    <div className="main_text">Drop files here</div>
                    <div className="sub_text">or <u>click to upload</u></div>
                  </div>
                </UploadButton>
              </div>
            </div>
          </div>
          {this.state.errors.length > 0 &&
            this.showErrors()
          }
          {!!this.state.mobileAdSuccess &&
            <div className="alert alert-success">
              Your mobile ad creative has been successfully uploaded.
            </div>
          }
          <div className="creatives_container">
            {this.renderCreatives()}
          </div>
        </div>
        <div className="body_button">
          <button className="primary-button" onClick={this.onSave}> Save</button>
        </div>
      </div>
    )
  }
}

export default GeofencedMobileAds;