import _ from "lodash";
import moment from "moment";
import Tooltip from "rc-tooltip";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import GlobalActions from "../../../actions/GlobalActions";
import { filterUnits } from "../../../actions/UnitsActions";
import { sidebarUnits, sortBySize } from "../../../models/Units";
import { loadCampaignUnits } from "../../Campaign/actions";
import Summary from "../../Campaign/Vendors/Summary";
import FiltersBar from "../../Filters/FiltersBar";
import { fetchFlightTypes } from "../../Inventory/actions";
import { removeUnit } from "./actions";
import Modals from "./Modals";
import Unit from "./Unit";

interface Props {
  flightTypes: any;
  removeUnit: Function;
  fetchFlightTypes: Function;
  onSubmit: Function;
  campaign_token: any;
  units_added: any;
  isBuilderView: any;
  campaign_dates: any;
  supplier_face_ids: any;
  onAddByMap: Function;
  units: any;
  filterUnits: any;
  loadCampaignUnits: any;
  loading_units: boolean;
  loading_availability: boolean;
}

interface State {
  removing: boolean;
  deleting: boolean;
  show_delete_confirmation: boolean;
  selected_units: any;
  allSelected: boolean;
  show_booking_flights_modal: boolean;
  show_hold_flights_modal: boolean;
  show_movable_flights_modal: boolean;
  show_proposal_flights_modal: boolean;
  show_add_notes_modal: boolean;
  show_delete_units_modal: boolean;
  show_change_dates_modal: boolean;
  show_change_spots_modal: boolean;
  show_change_pricing_modal: boolean;
  units_added: any;
  unit_for_delete: any;
  chunked_units: Array<Array<any>>;
  numberOfPages: number;
  lastPage: number;
  firstPage: number;
  currentPage: number;
  sortBy: string | null;
}

class ReviewUnits extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      removing: false,
      deleting: false,
      show_delete_confirmation: false,
      selected_units: [],
      allSelected: false,
      show_booking_flights_modal: false,
      show_hold_flights_modal: false,
      show_movable_flights_modal: false,
      show_proposal_flights_modal: false,
      show_add_notes_modal: false,
      show_delete_units_modal: false,
      show_change_dates_modal: false,
      show_change_spots_modal: false,
      show_change_pricing_modal: false,
      units_added: null,
      unit_for_delete: null,
      chunked_units: [[]],
      numberOfPages: 0,
      lastPage: 0,
      firstPage: 0,
      currentPage: 0,
      sortBy: "cpm",
    };

    this.confirmRemoveUnit = this.confirmRemoveUnit.bind(this);
    this.onUnitSelectAll = this.onUnitSelectAll.bind(this);
    this.onToggleUnitSelect = this.onToggleUnitSelect.bind(this);
    this.renderHelperMessage = this.renderHelperMessage.bind(this);
    this.openActionModal = this.openActionModal.bind(this);
    this.onHideDeleteModal = this.onHideDeleteModal.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  componentDidMount() {
    const { campaign_token, fetchFlightTypes, loadCampaignUnits } = this.props;
    const units_added = this.props.units_added || [];
    loadCampaignUnits(campaign_token);
    this.setState({ units_added: units_added.filter(n => n) });
    fetchFlightTypes();
    this.paginatedUnits();
  }

  units() {
    const { units, isBuilderView } = this.props;
    const { sortBy } = this.state;
    const units_added = this.state.units_added || [];
    if (isBuilderView) {
      return units_added;
    }
    const filtered_ids = sidebarUnits(units).map(unit => unit.campaign_unit_token);
    const filtered = units_added.filter(u => filtered_ids.includes(u.campaign_unit_token));

    switch (sortBy) {
      case "supplier_face_id":
        return _.orderBy(filtered, ["supplier_face_id"], ["asc"]);
      case "cpm":
        return _.orderBy(filtered, ["cpm"], ["asc"]);
      case "-cpm":
        return _.orderBy(filtered, ["cpm"], ["desc"]);
      case "week_total_cost":
        return _.orderBy(filtered, ["price"], ["asc"]);
      case "-week_total_cost":
        return _.orderBy(filtered, ["price"], ["desc"]);
      case "week_total_impressions":
        return _.orderBy(filtered, ["impressions"], ["asc"]);
      case "-week_total_impressions":
        return _.orderBy(filtered, ["impressions"], ["desc"]);
      case "size":
        return sortBySize(filtered, 0);
      case "-size":
        return sortBySize(filtered, 1);
      default:
        return filtered;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isBuilderView,
      units_added,
      units: { unit_filter, all_units },
    } = this.props;

    if (
      all_units !== prevProps.units.all_units ||
      JSON.stringify(units_added) !== JSON.stringify(prevProps.units_added)
    ) {
      this.paginatedUnits();
    }

    if (prevProps.units_added !== units_added && this.state.units_added !== units_added) {
      this.setState({ units_added }, () => this.paginatedUnits());
    }
    if (
      !!!isBuilderView &&
      prevProps.units &&
      prevProps.units.unit_filter &&
      prevProps.units.unit_filter !== unit_filter
    ) {
      const tokens = this.units().map(u => u.campaign_unit_token);
      const { selected_units } = this.state;
      this.setState(
        {
          allSelected: false,
          selected_units: selected_units.filter(token => tokens.includes(token)),
        },
        () => this.paginatedUnits(),
      );
    }
  }

  removeUnit(e, unit) {
    if (!!!unit.campaign_unit_token) {
      return;
    } else {
      const { removeUnit, campaign_token } = this.props;
      removeUnit(campaign_token, unit.campaign_unit_token);
    }
    this.setState({
      show_delete_confirmation: false,
      unit_for_delete: null,
    });
  }

  onToggleUnitSelect(e, unit) {
    const { selected_units, allSelected } = this.state;
    const units = this.units() || [];

    if (allSelected) {
      // all selected, deselecting one
      const selected = units
        .map(u => {
          return u.campaign_unit_token;
        })
        .filter(u => {
          return u !== unit.campaign_unit_token;
        });
      this.setState({
        allSelected: false,
        selected_units: selected,
      });
    } else if (selected_units.includes(unit.campaign_unit_token)) {
      // some selected, deselecting one
      const new_selected = selected_units.filter(token => {
        return token !== unit.campaign_unit_token;
      });
      this.setState({
        allSelected: false,
        selected_units: new_selected,
      });
    } else {
      // selecting unit
      this.setState({
        selected_units: [unit.campaign_unit_token, ...selected_units],
      });
    }
  }

  onHideDeleteModal() {
    this.setState({
      show_delete_confirmation: false,
    });
  }

  confirmRemoveUnit(e, unit_for_delete) {
    this.setState({
      show_delete_confirmation: true,
      unit_for_delete,
    });
  }

  openActionModal(modal) {
    const { campaign_token } = this.props;
    const { selected_units, allSelected } = this.state;
    const units = this.units();
    const unit_tokens = allSelected
      ? units.map(u => {
          return u.campaign_unit_token;
        })
      : selected_units;
    const all_selected = allSelected || selected_units.length === units.length;
    GlobalActions.openPopup(modal, { campaign_token, unit_tokens, all_selected });
  }

  renderModals() {
    const { campaign_token } = this.props;
    const {
      allSelected,
      selected_units,
      deleting,
      unit_for_delete,
      show_delete_confirmation,
      show_booking_flights_modal,
      show_hold_flights_modal,
      show_movable_flights_modal,
      show_proposal_flights_modal,
      show_add_notes_modal,
      show_delete_units_modal,
      show_change_dates_modal,
      show_change_spots_modal,
      show_change_pricing_modal,
    } = this.state;

    return (
      <div>
        <Modal show={show_delete_confirmation} onHide={this.onHideDeleteModal}>
          <Modal.Header>
            <h4>Remove Unit</h4>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to remove this unit?</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={deleting}
              className="btn btn-danger"
              type="button"
              onClick={e => this.removeUnit(e, unit_for_delete)}
            >
              Remove
            </button>
            <a onClick={() => this.setState({ show_delete_confirmation: false })} className="btn btn-default">
              Close
            </a>
          </Modal.Footer>
        </Modal>
        <Modals
          campaign_token={campaign_token}
          selected_units={selected_units}
          show_booking_flights_modal={show_booking_flights_modal}
          show_hold_flights_modal={show_hold_flights_modal}
          show_movable_flights_modal={show_movable_flights_modal}
          show_proposal_flights_modal={show_proposal_flights_modal}
          show_add_notes_modal={show_add_notes_modal}
          show_delete_units_modal={show_delete_units_modal}
          show_change_dates_modal={show_change_dates_modal}
          show_change_spots_modal={show_change_spots_modal}
          show_change_pricing_modal={show_change_pricing_modal}
          setParentState={this.setState.bind(this)}
        />
      </div>
    );
  }

  renderUnavailableTooltip() {
    return (
      <Tooltip
        placement="left"
        overlay={"Please deselect all unavailable flights to take this action"}
        overlayStyle={{ opacity: 1 }}
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: "#ffc300" }}></i>
      </Tooltip>
    );
  }

  renderStaticTooltip() {
    return (
      <Tooltip
        placement="left"
        overlay={"Please deselect all static units to take this action"}
        overlayStyle={{ opacity: 1 }}
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: "#ffc300" }}></i>
      </Tooltip>
    );
  }

  unavailableUnitsSelected() {
    const { units_added } = this.props;
    const { selected_units, allSelected } = this.state;

    return (
      _.filter(units_added, u => {
        return selected_units.includes(u.campaign_unit_token) && u.available !== "available";
      }).length > 0 ||
      (_.filter(units_added, u => {
        return u.available !== "available";
      }).length > 0 &&
        allSelected)
    );
  }

  staticUnitsSelected() {
    const { units_added } = this.props;
    const { selected_units, allSelected } = this.state;
    return (
      _.filter(units_added, u => {
        return selected_units.includes(u.campaign_unit_token) && u.screen_type === "static";
      }).length > 0 ||
      (_.filter(units_added, u => {
        return u.screen_type === "static";
      }).length > 0 &&
        allSelected)
    );
  }

  renderActionsDropdown() {
    const { selected_units, allSelected } = this.state;
    const unavailable_selected = this.unavailableUnitsSelected();
    const static_selected = this.staticUnitsSelected();

    return (
      <div
        className="btn-group"
        style={{
          float: "right",
          marginBottom: 10,
          marginRight: 45,
        }}
      >
        <div className="dropdown">
          <button
            className={`btn btn-default dropdown-toggle ${selected_units.length > 0 || allSelected ? "" : "disabled"}`}
            type="button"
            data-toggle="dropdown"
          >
            Actions
            <span className="caret" style={{ marginLeft: "5px", marginBottom: "2px" }} />
          </button>
          <ul className="dropdown-menu dropdown-menu-left">
            <li className="dropdown-item">
              <a onClick={() => this.openActionModal("add_contract")}>Generate New Contract</a>
            </li>
            <li className={"dropdown-item"}>
              <a onClick={() => this.setState({ show_delete_units_modal: true })}>Delete Units</a>
            </li>
            <li className={"dropdown-item"}>
              <a onClick={() => this.setState({ show_change_dates_modal: true })}>Change Dates</a>
            </li>
            <li className={`dropdown-item ${unavailable_selected ? "disabled" : ""}`}>
              <a
                onClick={() => {
                  if (!!!unavailable_selected) {
                    this.setState({ show_booking_flights_modal: true });
                  }
                }}
              >
                {unavailable_selected ? this.renderUnavailableTooltip() : ""} Create Booked Flights
              </a>
            </li>
            <li className={`dropdown-item ${unavailable_selected ? "disabled" : ""}`}>
              <a
                onClick={() => {
                  if (!!!unavailable_selected) {
                    this.setState({ show_movable_flights_modal: true });
                  }
                }}
              >
                {unavailable_selected ? this.renderUnavailableTooltip() : ""} Create Movable Flights
              </a>
            </li>
            <li className={`dropdown-item ${unavailable_selected ? "disabled" : ""}`}>
              <a
                onClick={() => {
                  if (!!!unavailable_selected) {
                    this.setState({ show_hold_flights_modal: true });
                  }
                }}
              >
                {unavailable_selected ? this.renderUnavailableTooltip() : ""} Create Hold Flights
              </a>
            </li>
            <li className={"dropdown-item"}>
              <a onClick={() => this.setState({ show_proposal_flights_modal: true })}>Create Proposed Flights</a>
            </li>
            <li className={"dropdown-item"}>
              <a onClick={() => this.setState({ show_add_notes_modal: true })}>Add notes to units</a>
            </li>
            <li className={`dropdown-item ${static_selected ? "disabled" : ""}`}>
              <a
                onClick={() => {
                  if (!!!static_selected) {
                    this.setState({ show_change_spots_modal: true });
                  }
                }}
              >
                {static_selected ? this.renderStaticTooltip() : ""} Change number of spots
              </a>
            </li>
            <li className={"dropdown-item"}>
              <a onClick={() => this.setState({ show_change_pricing_modal: true })}>Change pricing</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  onUnitSelectAll() {
    const { allSelected } = this.state;
    this.setState({
      allSelected: !!!allSelected,
      selected_units: !!!allSelected ? this.units().map(u => u.campaign_unit_token) : [],
    });
  }

  renderSelectAllCheckbox() {
    const { allSelected } = this.state;
    const iconClass = allSelected ? "fa-check-square-o" : "fa-square-o";

    return (
      <div
        className="btn btn-default btn-xs"
        onClick={this.onUnitSelectAll}
        style={{
          position: "relative",
          bottom: -4,
          float: "right",
          marginRight: 5,
          fontSize: 13,
          width: 25,
        }}
      >
        <i className={`fa ${iconClass}`} style={{ marginRight: 0 }} />
      </div>
    );
  }

  renderHelperMessage() {
    const units_added = this.state.units_added || [];
    const hash = window.location.hash;

    if (hash && units_added && units_added.length > 0) {
      if (hash.includes("?contracting=true")) {
        return (
          <div className="help-steps">
            <p className="help-step">
              <b>1.</b> Select the units you would like on the contract.
            </p>
            <p className="help-step">
              <b>2.</b> Choose <span className="button-name">Generate New Contract</span> from the{" "}
              <span className="button-name">Actions</span> menu to the right.
            </p>
          </div>
        );
      } else if (hash.includes("?booking=true")) {
        return (
          <div className="help-steps">
            <p className="help-step">
              <b>1.</b> Select the units you would like to add flights for.
            </p>
            <p className="help-step">
              <b>2.</b> Choose the Flight Type under the <span className="button-name">Actions</span> button.
            </p>
          </div>
        );
      }
    }
    return <div></div>;
  }

  paginatedUnits() {
    const units = this.units();
    const { currentPage } = this.state;
    const chunked_units = _.chunk(units, 20) as [[]];
    const numberOfPages = chunked_units.length;
    const lastPage = numberOfPages > 2 ? numberOfPages - 1 : 1;
    const firstPage = 0;
    this.setState({
      chunked_units,
      numberOfPages,
      lastPage,
      firstPage,
      currentPage: currentPage && !(currentPage > numberOfPages - 1) ? currentPage : firstPage,
    });
  }

  renderPages() {
    const { chunked_units, currentPage, selected_units, allSelected } = this.state;
    const { flightTypes, isBuilderView, campaign_dates, campaign_token } = this.props;
    const pageUnits = chunked_units[currentPage] || [];

    return (
      <div className="col-md-12">
        <div className="row form-group">
          {pageUnits.map(unit => (
            <Unit
              campaign_token={campaign_token}
              unit={unit}
              isBuilderView={isBuilderView}
              onToggleUnitSelect={this.onToggleUnitSelect}
              confirmRemoveUnit={this.confirmRemoveUnit}
              selected_units={selected_units}
              allSelected={allSelected}
              flightTypes={flightTypes}
              campaign_dates={campaign_dates}
            />
          ))}
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {this.renderPagination()}
        </div>
      </div>
    );
  }

  renderPagination() {
    const { numberOfPages, currentPage } = this.state;
    const pages = new Array(numberOfPages).fill(undefined).map((val, idx) => idx);
    const current_page_style = {
      backgroundColor: "#dadada",
      color: "#ffffff",
    };

    return (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li onClick={() => this.goToPage(currentPage - 1)}>
            <span className="back_forward_page">&laquo;</span>
          </li>
          {pages.map((v, i) => (
            <li onClick={e => this.goToPage(i)}>
              <a style={currentPage === i ? current_page_style : {}}>{i + 1}</a>
            </li>
          ))}
          <li onClick={() => this.goToPage(currentPage + 1)}>
            <span className="back_forward_page">&raquo;</span>
          </li>
        </ul>
      </nav>
    );
  }

  goToPage(page) {
    const { firstPage, lastPage } = this.state;
    if (page < firstPage) {
      page = lastPage;
    }
    if (page > lastPage) {
      page = firstPage;
    }
    this.setState({ currentPage: page });
  }

  onSort(value) {
    this.setState({ sortBy: value }, () => {
      this.paginatedUnits();
    });
  }

  render() {
    const { isBuilderView, loading_units, loading_availability } = this.props;
    const units = this.units() || [];

    if (loading_units) {
      return (
        <div className="campaign_builder">Loading units...</div>
      )
    }

    return (
      <div className="campaign_builder">
        {!!!isBuilderView && (
          <div className="row" style={{ marginBottom: 15 }}>
            <FiltersBar
              activeFilters={this.props.units.unit_filter}
              onSort={value => this.onSort(value)}
              isBrowseView={false}
              onFilter={this.props.filterUnits}
            />
          </div>
        )}
        {this.renderModals()}

        <div className="row">
          {units.length > 0 && (
            <div className="col-md-6">
              <Summary />
            </div>
          )}
          <div className="col-md-6">
            <div className="col-md-8 help-message-area">{this.renderHelperMessage()}</div>
            <div className="col-md-4" style={{ paddingTop: 30 }}>
              {units.length > 0 && (
                <div>
                  {!!!isBuilderView && this.renderSelectAllCheckbox()}
                  {!!!isBuilderView && this.renderActionsDropdown()}
                </div>
              )}
            </div>
          </div>
        </div>
        {units.length > 0 && this.renderPages()}
      </div>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { loading_units, loading_availability }, units, inventory: { flightTypes } }) => ({ units, flightTypes, loading_units, loading_availability }),
  {
    loadCampaignUnits,
    filterUnits,
    removeUnit,
    fetchFlightTypes,
  },
)(ReviewUnits);
