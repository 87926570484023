import _ from "lodash";
import moment from "moment";

import { LOAD_CAMPAIGN_SHIPPING_ADDRESSES } from "../actions/campaign_shipping_address_actions";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGN_SHIPPING_ADDRESSES:
      return action.payload;
    default:
      return state;
  }
};
