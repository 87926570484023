import cs from "classnames";
import { initial } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";
import { availableOrientations } from "../../models/Units";
import { clearOrientations, fetchOrientations } from "../Inventory/actions";

const POPUP_WIDTH = 220;

class OrientationFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {
      isExpanded: false,
      activeOrientation: this.props.initialValue
        ? {
            label: this.props.initialValue,
            value: this.props.initialValue,
          }
        : null,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
    const { fetchOrientations, isBrowseView, supplier, currentUser } = this.props;
    if (
      isBrowseView &&
      (supplier || (currentUser && currentUser.is_supplier) || (currentUser && currentUser.is_agency))
    ) {
      fetchOrientations(supplier);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, supplier, fetchOrientations, clearOrientations, initialValue } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }

    if (supplier !== prevProps.supplier) { fetchOrientations(supplier); }

    if (!!!supplier && prevProps.supplier) {
      this.setState({ activeOrientation: null }, () => {
        clearOrientations();
        this.onFilter();
      });
    }

    if (this.props.browse_orientation && !nextProps.browse_orientation) {
      this.setState({ activeOrientation: null });
    }

    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ activeOrientation: { label: initialValue, value: initialValue }})
    }
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onOrientationSelect(orientation) {
    const { activeOrientation } = this.state;
    if (activeOrientation && activeOrientation.value === orientation.value) {
      this.setState({ activeOrientation: null }, this.onFilter);
    } else {
      this.setState({ activeOrientation: orientation }, this.onFilter);
    }
  }

  onFilter() {
    const { filterUnits, handleQueryParams } = this.props;

    this.setState({ isExpanded: false });
    const filter = this.handleOrientationFilter()
    filterUnits(filter);
    handleQueryParams(filter)
  }

  handleOrientationFilter() {
    const { activeOrientation } = this.state
    const { isBrowseView } = this.props
    if (isBrowseView) { return { browse_orientation: !activeOrientation ? null : activeOrientation.value } }
    return { orientation: !activeOrientation ? null : activeOrientation.value }
  }

  getButtonLabel() {
    const { activeOrientation } = this.state;
    if (!activeOrientation) return "Orientation";
    return activeOrientation.value;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  onClear() {
    this.setState({ activeOrientation: null }, this.onFilter);
  }

  renderPopup() {
    const { activeOrientation } = this.state;
    const { units, isBrowseView, currentUser, supplier } = this.props;
    const orientations = isBrowseView ? this.props.orientations || [] : availableOrientations(units);
    return (
      <div className="filter_popup orientation_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>ORIENTATION</h4>
        {orientations.length === 0 && <div style={{ paddingLeft: 10, paddingBottom: 10 }}>No results</div>}
        {orientations.length === 0 && !!!supplier && currentUser && currentUser.is_admin && (
          <div style={{ paddingLeft: 10 }}>please select a vendor to use this filter</div>
        )}
        {activeOrientation && (
          <a
            style={{
              float: "right",
              top: -32,
              paddingRight: 12,
              position: "relative",
              color: "#999999",
            }}
            onClick={this.onClear}
          >
            Clear
          </a>
        )}
        <ul>
          {orientations.map(orientation => {
            const onClick = () => {
              this.onOrientationSelect(orientation);
            };
            const isActive = activeOrientation && activeOrientation.value === orientation.value;
            return (
              <li key={orientation.value} onClick={onClick} className={cs({ active: isActive })}>
                {orientation.value}
                <i className="count">({orientation.count})</i>
                <i className="fa fa-check" />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { isExpanded, activeOrientation } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: activeOrientation || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ units, inventory: { orientations }, currentUser }) => ({
  units,
  orientations,
  currentUser,
});

export default connect(mapStateToProps, {
  filterUnits,
  fetchOrientations,
  clearOrientations,
})(OrientationFilter);
