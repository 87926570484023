import { AvailabilityStatus } from "../models/availability_status";
import { del, get, post } from "../utils/api";

const prefix = "availability_statuses";
const SUPPLIERS_API_URL = "/api/v1/supplier_statuses"

export const LOAD_AVAILABILITY_STATUSES = `${prefix}/LOAD_AVAILABILITY_STATUSES`;
export const loadAvailabilityStatuses = () => async (dispatch) => {
  const res = await get(`${SUPPLIERS_API_URL}`);
  const statuses: AvailabilityStatus[] = res;
  dispatch({ type: LOAD_AVAILABILITY_STATUSES, payload: { statuses } });
};
