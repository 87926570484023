import { connect } from 'react-redux';
import React from 'react'
import accounting from 'accounting';
import cs from 'classnames'

import { demographic_name } from '../SelectDemos';
import { showBudget, toggleSpendGroup } from '../actions';

class GeoRecommendations extends React.Component {

  renderLocations() {
    DEBUG && console.log('geo_recommendations', this.props);
    this.renderedLocations = [];
    return this.renderMetroAreas();
  }

  sortByScore(a, b) {
    return this.score(a) - this.score(b);
  }

  score(location) {
    if (!!!location.demographics || !!!location.demographics.length || !!!location.cpm) return parseFloat(location.cpm);
    const value = parseFloat(location.cpm) / location.demographics.reduce((acc, d) => d.percentage / 100 * acc, 1);
    return value.toFixed(2);
  }

  renderMetroAreas() {
    const { metro_areas, places, zip_codes } = this.props.recommendations;
    const sorted_metro_areas = Object.values(metro_areas).sort(this.sortByScore.bind(this))

    return sorted_metro_areas.map(metro_area => this.renderLocation(metro_area, 'metro_area'));
  }

  planHasLocation(location_id) {
    return this.props.spend_groups.some(g => g.location.id === location_id);
  }

  renderLocation(location, type, level = 1) {
    if (level === 0 && this.renderedLocations.includes(location.location_id)) return null;
    this.renderedLocations.push(location.location_id);

    return (
      <tr key={`${location.location_id}-${level}`} className={`place level_${level}`}>
        <td
          className={`${this.planHasLocation(location.location_id) ? 'remove' : 'add'}`}
          onClick={ () => this.props.toggleSpendGroup(location.location_id) }>
          { this.planHasLocation(location.location_id) ? '-remove' : '+add' }
        </td>
        <td className="location_name">
          <div><span>{location.location_name}</span></div>
        </td>
        {!_.isEmpty(this.props.selected_demographics) &&
          <td className="attention">{this.score(location)}</td>
        }
        <td>{accounting.formatMoney(location.cpm)}</td>
        <td>{accounting.formatNumber(location.location_population)}</td>
        {this.renderDemographics(location, level)}
      </tr>
    );
  }

  renderDemographics(location, level) {
    return Object.values(this.props.selected_demographics).map(demo => {
      const demographic = location.demographics.find(d => d.name === demo.name)
      if(!demographic){
        return;
      }
      return (
        <td key={`${location.location_id}-${demo.name}-${level}`}>
          {accounting.toFixed(demographic.percentage, 2)}
        </td>
      );
    });
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.geo_recommendations_fetched_at !== nextProps.geo_recommendations_fetched_at ||
      this.props.spend_groups.length !== nextProps.spend_groups.length
    );
  }

  renderContinue() {
    if (!this.props.spend_groups.length) return <div/>;

    return (
      <div className="save-section" style={{ marginTop: '10px' }}>
        <button className="btn btn-primary" onClick={this.props.showBudget}>
          <span>Save & Continue</span>
          <i className="fa fa-arrow-right" />
        </button>
      </div>
    );
  }

  render() {
    const { recommendations, selected_demographics } = this.props;
    if (!recommendations) return <div/>;
    const demographics = Object.keys(selected_demographics);
    return (
      <div>
        { this.renderContinue() }
        <table className={cs('geo_recommendations', { 'no_demographics': _.isEmpty(demographics) })}>
          <thead>
            <tr>
              <th colSpan="2">
                Geography
              </th>
              <th colSpan={_.isEmpty(demographics) ? "1" : "2"}>
                Geo info & Pricing
              </th>
              <th colSpan={demographics.length + 1}>
                Demographics
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan="2">
                Metro Area
              </th>
              {!_.isEmpty(demographics) &&
                <th>
                  Score
                </th>
              }
              <th>
                Median CPM
              </th>
              <th>
                Population
              </th>
              {
                demographics.map(d =>
                  <th key={`planner-demographic-header-${d}`}>
                    <div>{demographic_name[d]} %</div>
                    <div className="demographic_name">{selected_demographics[d].name}</div>
                  </th>
                )
              }
            </tr>
            { this.renderLocations() }
          </tbody>
        </table>

        { this.renderContinue() }
      </div>
    );
  }
}

export default connect(
  ({planner}) => ({...planner}),
  { toggleSpendGroup, showBudget }
)(GeoRecommendations)
