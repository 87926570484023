import moment from 'moment';
import momentRange from 'moment-range';
import { calculateFlightLength } from './flightCalculator'

export const calculatePrice = (units) => {
  const prices = units.map((unit) => {
    const quantity = unit.quantity || 1
    const daysInRange = calculateDaysInRange(unit)
    const dailyPrice = parseFloat(unit.price) / 4 / 7
    const prodInstallCost = parseFloat(unit.installation_cost) + parseFloat(unit.production_cost) * quantity;
    const unitPrice = (dailyPrice * daysInRange * quantity) + prodInstallCost;
    return (unitPrice || 0)
  })

  const cost = prices.reduce((prev, curr) => prev + curr, 0)
  return cost;
}

export const calculateImpressions = (units) => {
  const impressions = units.map((unit) => {
    const daysInRange = calculateDaysInRange(unit);
    const singleUnitImpressions = ((unit.impressions / 4 / 7) * daysInRange)
    return singleUnitImpressions * (unit.quantity || 1)
  })

  return impressions.reduce((prev, curr) => prev + curr, 0)
}

export const calculateDaysInRange = (unit) => (
  calculateFlightLength(unit.start_date, unit.end_date)
)
