import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-legacy';
import {
  fetchSupplierTasks,
  setCampaignId,
  updateSupplierTask,
  deleteSupplierTask,
  completeAllOpenSupplierTasks
} from "./actions";
import { fetchSuppliers } from "../../Inventory/actions";
import TaskGroupSection from "./TaskGroupSection";
import BulkActionModal from "../BulkActionModal";
import { ApiResult } from "./ApiResult";

class CampaignRequests extends React.Component {

  constructor(props) {
    super(props);
    this.onComplete = this.onComplete.bind(this);
    this.completeAllOpenRequests = this.completeAllOpenRequests.bind(this);
    this.showBulkActionModal = this.showBulkActionModal.bind(this);
    this.hideBulkActionModal = this.hideBulkActionModal.bind(this);
    this.state = {
      selected_tasks: [],
      show_bulk_action_modal: false,
      modal_action: ""
    };
  }

  componentDidMount() {
    const {
      campaign,
      setCampaignId,
      fetchSupplierTasks,
      fetchSuppliers,
      user
    } = this.props;
    if (campaign) { setCampaignId(campaign.token) };
    if (user.is_admin) { fetchSuppliers(); }
    fetchSupplierTasks(campaign.token);
  }

  showBulkActionModal(selected_tasks, { action }) {
    this.setState({
      show_bulk_action_modal: true,
      selected_tasks,
      modal_action: action
    });
  }

  hideBulkActionModal() {
    this.setState({ show_bulk_action_modal: false });
  }

  onComplete(st) {
    this.props.updateSupplierTask({
      task: { id: st.id, completed: !!!st.completed }
    });
  }

  async completeAllOpenRequests() {
    if (confirm("This will complete all open requests for this campaign. Are you sure?")) {
      const { campaign } = this.props;
      await this.props.completeAllOpenSupplierTasks(campaign.token);
      await this.props.fetchSupplierTasks(campaign.token);
    }
  }

  renderBulkActionModal() {
    const { campaign } = this.props;
    const { show_bulk_action_modal, selected_tasks, modal_action } = this.state;

    return (
      <BulkActionModal
        onHide={this.hideBulkActionModal}
        show={show_bulk_action_modal}
        supplier_tasks={selected_tasks}
        campaign_token={campaign.token}
        action={modal_action}
      />
    );
  }

  render() {
    const { user, campaign, supplier_tasks, isFetching, campaignPermissions } = this.props;
    const incomplete_tasks = supplier_tasks ? supplier_tasks.filter(st => !!!st.completed && !st.canceled_at) : [];

    const complete_tasks = supplier_tasks ? supplier_tasks.filter(st => ((!!!st.declined && st.completed && st.task_type !== "rfp") || (!!!st.declined && st.completed && st.task_type === "rfp"))) : [];

    const declined_tasks = supplier_tasks ? supplier_tasks.filter(st => (st.declined && st.completed)) : [];

    const canceled_tasks = supplier_tasks ? supplier_tasks.filter(st => !!st.canceled_at) : []

    return (
      <div className="container col-xs-12">
        {user.permissions.can_manage_supplier_tasks && this.renderBulkActionModal()}

        <div>
          <TaskGroupSection
            sectionTitle="Open Requests"
            tasks={incomplete_tasks}
            groupType="open"
            campaign={campaign}
            user={user}
            showBulkActionModal={this.showBulkActionModal}
            isFetching={isFetching}
          />

          <TaskGroupSection
            sectionTitle="Completed Requests"
            tasks={complete_tasks}
            groupType="completed"
            campaign={campaign}
            user={user}
            showBulkActionModal={this.showBulkActionModal}
            isFetching={isFetching}
          />

          <TaskGroupSection
            sectionTitle="Declined Requests"
            tasks={declined_tasks}
            groupType="declined"
            campaign={campaign}
            user={user}
            showBulkActionModal={this.showBulkActionModal}
            isFetching={isFetching}
          />

          <TaskGroupSection
            sectionTitle="Canceled Requests"
            tasks={canceled_tasks}
            groupType="canceled"
            campaign={campaign}
            user={user}
            showBulkActionModal={this.showBulkActionModal}
            isFetching={isFetching}
          />
          {campaignPermissions.can_use_avails_integration && <ApiResult campaign={campaign} user={user}></ApiResult>}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({
      adquick_requests_ui: {
        campaign_requests: {
          supplier_tasks,
          campaign_id,
          isFetching
        }
      },
      inventory: { suppliers }
    }) => {
      return {
        supplier_tasks,
        campaign_id,
        suppliers,
        isFetching
      };
    },
    {
      fetchSupplierTasks,
      setCampaignId,
      fetchSuppliers,
      updateSupplierTask,
      deleteSupplierTask,
      completeAllOpenSupplierTasks
    }
  )(CampaignRequests)
);
