import React, { Component } from 'react';
import cs from 'classnames';

class CarouselControls extends Component {

  render(){
    const { show, onPrev, onNext, isBeginningOfList, isEndOfList } = this.props;
    return (
      <div className={cs('carousel_controls', { hidden: !show })}>
        <div className={cs('prev', { hidden: isBeginningOfList})} onClick={onPrev}><i className="fa fa-chevron-left"/></div>
        <div className={cs('next', { hidden: isEndOfList})} onClick={onNext}><i className="fa fa-chevron-right"/></div>
        <div className="hotkeys_wrapper">
          <div className="hotkeys">
            Keyboard Shortcuts
            <ul className="list-inline">
              <li>Previous <i className="fa fa-long-arrow-up"/></li>
              <li>Next <i className="fa fa-long-arrow-down"/></li>
              <li>Favorite <i>F</i></li>
              <li>Toggle Map<i>T</i></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

}

export default CarouselControls
