import * as React from "react";
import { Link } from "react-router-legacy";
import { browserHistory } from "react-router-legacy";

export default class ExportTemplates extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      exportTemplates: [],
      isLoading: true,
      showDeleted: location.query.deleted,
    };
  }

  componentDidMount() {
    const { showDeleted } = this.state;
    if (showDeleted) {
      setTimeout(() => {
        this.setState({ showDeleted: false });
      }, 5000);
    }
    $.ajax({
      url: "/api/v1/export_templates",
      success: response => {
        this.setState({
          exportTemplates: response,
          isLoading: false,
        });
      },
    });
  }

  onNew() {
    browserHistory.push("/export_templates/new");
  }

  render() {
    const { exportTemplates, showDeleted, isLoading } = this.state;

    return (
      <div className="container export_templates_index">
        <h4>
          Export Templates
          <button onClick={this.onNew} className="btn btn-primary new_button">
            + New Export Template
          </button>
        </h4>
        {showDeleted && <div className="alert alert-success">The template has been deleted.</div>}
        <div className="table ui">
          <table className={"table table-striped " + (isLoading ? "loading_blank" : "")}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Is Default?</th>
              </tr>
            </thead>
            <tbody>
              {exportTemplates.map(template => {
                return (
                  <tr key={template.id}>
                    <td><Link to={`/export_templates/${template.id}`}>{template.name}</Link></td>
                    <td>{template.is_default ? "✓" : ""}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
