import Reflux from "reflux";
import { get, post, put, del } from "../utils/api";

const API_URL = "/api/v1/places";

export default Reflux.createStore({
  listenables: [],

  init() {
    this.state = {};
  },

  onFilterByLabel() {
    this.trigger("places:filtered_by_label");
  },

  onAddNearbyUnits() {
    this.trigger("places:nearby_units_added");
  },

  onAddUnitsByZip() {
    this.trigger("places:nearby_units_added");
  },

  onImportPlaces() {
    this.trigger("places:updated");
  },

  onLocationPin(locationPin) {
    this.state.locationPin = locationPin;
    this.trigger("places:locationPin");
  },

  search(query, lat, lon, radius, campaign) {
    return get(
      `${API_URL}?lat=${lat}&lon=${lon}&radius=${radius}&campaign=${campaign}&query=${encodeURIComponent(
        query
      )}`
    )
      .then(res => this.trigger("places:loaded", res))
      .catch(error => this.trigger("places:error", error));
  },

  addPlace(place, params = {}) {
    return post(`${API_URL}`, {
      places: [{
        ...place,
      }], campaign: params.campaign
    })
      .then(res => this.trigger("places:updated", res))
      .catch(error => this.trigger("places:error", error));
  },

  loadFavorites(params = {}) {
    return get(`${API_URL}?campaign=${params.campaign}`)
      .then(res => this.trigger("places:loaded", res))
      .catch(error => this.trigger("places:error", error));
  },

  triggerUpdate(places) {
    this.trigger("places:updated", places)
  }
});
