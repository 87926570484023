import { LOAD_AVAILABILITY_STATUSES } from "../actions/availability_status_actions";
import { AvailabilityStatus } from "../models/availability_status";

interface Action {type: string; payload: any; }

export default(state: AvailabilityStatus[], action: Action): AvailabilityStatus[] => {
  switch (action.type) {
    case LOAD_AVAILABILITY_STATUSES: return action.payload.statuses;
    default: return state || [];
  }
};
