import { get } from '../utils/api';

export const CLEAR_SEGMENT = "segment/clear";
export const clearSegment = () => (dispatch) => dispatch({ type: CLEAR_SEGMENT });

export const LOAD_SEGMENT = "segment/load";
export const loadSegment = (campaignId) => async (dispatch) => {
  const segment = await get(`/api/v1/campaigns/${campaignId}/segment`);
  dispatch({type: LOAD_SEGMENT, payload: segment});
}

export const SELECT_METRIC = "segment/select-metric";
export const selectMetric = metric => dispatch => dispatch({ type: SELECT_METRIC, payload: metric });
