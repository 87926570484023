import React, { Component } from 'react';
import { isPosted } from '../../../models/design_assets';
import { Modal } from "react-bootstrap";
import { isEmpty, orderBy } from 'lodash';

interface ThumbnailProps {
  unit: any;
}
interface ThumbnailState {
  show_modal: boolean;
}
class Thumbnail extends Component<ThumbnailProps, ThumbnailState> {

  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

    this.state = {
      show_modal: false
    }
  }

  public render() {
    const { unit } = this.props;
    const { thumb_src, img_src } = this.getImagesFromUnit(unit);
    if (thumb_src && thumb_src != '') {
      return (
        <div
          className="design_asset_thumb" onClick={this.showModal}
          style={{ width: 64, height: 64, background: `url(${thumb_src}) no-repeat center center` }}
        >
          {this.renderModal(unit.name, img_src)}
        </div>
      )
    } else {
      return (
        <div className="design_asset_thumb no_image">
          <i className="fa fa-image" style={{ marginRight: 0 }} />
        </div>
      )
    }
  }

  private renderModal(title ,img_src) {
    return (
      <div onClick={e => e.stopPropagation()}>
        <Modal dialogClassName="image_modal" backdrop={true} keyboard={true} show={this.state.show_modal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={img_src}></img>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  private showModal() {
    this.setState({ show_modal: true });
  }
  private hideModal() {
    this.setState({ show_modal: false });
  }

  private getImagesFromUnit(unit: any) {
    if (isPosted(unit)) {
      const thumb_src = unit.pop && orderBy(unit.pop, ['uploaded_on'], ['desc'])[0].asset_thumbnail_url;
      const img_src = unit.pop && orderBy(unit.pop, ['uploaded_on'], ['desc'])[0].asset_image_url;
      return { thumb_src, img_src };
    } else {
      const thumb_src = !isEmpty(unit.design_assets) && unit.design_assets[0].asset_thumbnail_url;
      const img_src = !isEmpty(unit.design_assets) && unit.design_assets[0].asset_image_url;
      return { thumb_src, img_src };
    }
  }

}

export default Thumbnail
