import { connect } from 'react-redux';
import React, { Component } from 'react';
import { fetchQaIssues } from './actions';
import { removeCampaignUnitByToken } from "../actions";
import IssueGroup from './IssueGroup';
import { isEmpty } from 'lodash';
import Loading from '../../Loading';

class QaIssues extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.handleUnitDelete = this.handleUnitDelete.bind(this);
  }

  async componentWillMount() {
    this.campaign_id = this.props.data ? this.props.data.campaign.token : '';
    await this.props.fetchQaIssues(this.campaign_id, this.props.is_managed);
    window.afterRemoveUnit = this.props.fetchQaIssues;
  }

  async handleUnitDelete(campaign_unit_token) {
    await this.props.removeCampaignUnitByToken(campaign_unit_token);
    await this.props.fetchQaIssues(this.campaign_id, this.props.is_managed);
  }

  async componentDidUpdate(prevProps, prevState) {
    const token = this.props.data.campaign.token
    if (token != prevProps.data.campaign.token) {
      this.campaign_id = this.props.data ? this.props.data.campaign.token : '';
      await this.props.fetchQaIssues(this.campaign_id, this.props.is_managed);
    }
  }

  render() {
    const { issues, fetchQaIssues } = this.props;

    if (isEmpty(issues)) {
      return <Loading/>
    }

    return(
      <div>
        <IssueGroup
          key='CPM_IMPRESSIONS_PRICE'
          heading='Suspect CPM, Impressions, & Price'
          issues={issues['CPM_IMPRESSIONS_PRICE']}
          fetchQaIssues={fetchQaIssues}
          deleteCampaignUnit={this.handleUnitDelete}
          campaignId={this.campaign_id}
          hasRequests={this.props.data.campaignPermissions.show_unit_removal_reason}
          columns={[
            { property: 'unit_id', label: '', hidden: true },
            { property: 'supplier_face_id', label: 'Face ID' },
            { property: 'unit_name', label: 'Unit Name', width: '280' },
            { property: 'supplier_name', label: 'Vendor', width: '100' },
            { property: 'cpm', label: 'CPM', dataAlign: 'right', width: '100' },
            { property: 'price', label: 'Price', dataAlign: 'right', width: '80' },
            { property: 'floor_price', label: 'Floor Price', dataAlign: 'right', width: '100' },
            { property: 'impressions', label: 'Impressions', dataAlign: 'right' }
          ]}
        />

        <IssueGroup
          key='PRODUCTION_COST'
          heading='Suspect Production Cost'
          issues={issues['PRODUCTION_COST']}
          fetchQaIssues={fetchQaIssues}
          deleteCampaignUnit={this.handleUnitDelete}
          campaignId={this.campaign_id}
          hasRequests={this.props.data.campaignPermissions.show_unit_removal_reason}
          columns={[
            { property: 'unit_id', label: '', hidden: true },
            { property: 'supplier_face_id', label: 'Face ID' },
            { property: 'unit_name', label: 'Unit Name', width: '300' },
            { property: 'supplier_name', label: 'Vendor' },
            { property: 'screen_type', label: 'Screen Type', dataAlign: 'right' },
            { property: 'production_cost', label: 'Production Cost', width: '180', dataAlign: 'right' }
          ]}
        />

        <IssueGroup
          key='PRODUCTION_COST_AUTO'
          heading='Static billboards with wrong auto calculated production cost'
          issues={issues['PRODUCTION_COST_AUTO']}
          fetchQaIssues={fetchQaIssues}
          deleteCampaignUnit={this.handleUnitDelete}
          campaignId={this.campaign_id}
          columns={[
            { property: 'unit_id', label: '', hidden: true },
            { property: 'supplier_face_id', label: 'Face ID' },
            { property: 'unit_name', label: 'Unit Name', width: '300' },
            { property: 'supplier_name', label: 'Vendor' },
            { property: 'production_cost', label: 'Production Cost', dataAlign: 'right' },
            { property: 'auto_calculated_production_cost', label: 'Auto calc prod cost', dataAlign: 'right' },
          ]}
        />

        <IssueGroup
          key='LOCATION'
          heading='Suspect Location'
          issues={issues['LOCATION']}
          fetchQaIssues={fetchQaIssues}
          deleteCampaignUnit={this.handleUnitDelete}
          campaignId={this.campaign_id}
          hasRequests={this.props.data.campaignPermissions.show_unit_removal_reason}
          columns={[
            { property: 'unit_id', label: '', hidden: true },
            { property: 'supplier_face_id', label: 'Face ID' },
            { property: 'unit_name', label: 'Unit Name', width: '300' },
            { property: 'location', label: 'Location' }
          ]}
        />

        <IssueGroup
          key='IMAGES'
          heading='Missing Images'
          issues={issues['IMAGES']}
          fetchQaIssues={fetchQaIssues}
          deleteCampaignUnit={this.handleUnitDelete}
          campaignId={this.campaign_id}
          hasRequests={this.props.data.campaignPermissions.show_unit_removal_reason}
          columns={[
            { property: 'unit_id', label: '', hidden: true },
            { property: 'supplier_face_id', label: 'Face ID' },
            { property: 'unit_name', label: 'Unit Name', width: '300' },
            { property: 'supplier_name', label: 'Vendor' }
          ]}
        />

        <IssueGroup
          key='DIGITAL_INFO'
          heading='Missing Digital Unit Info'
          issues={issues['DIGITAL_INFO']}
          fetchQaIssues={fetchQaIssues}
          deleteCampaignUnit={this.handleUnitDelete}
          campaignId={this.campaign_id}
          hasRequests={this.props.data.campaignPermissions.show_unit_removal_reason} 
          columns={[
            { property: 'unit_id', label: '', hidden: true },
            { property: 'supplier_face_id', label: 'Face ID' },
            { property: 'unit_name', label: 'Unit Name', width: '300' },
            { property: 'supplier_name', label: 'Vendor' },
            { property: 'loop_length', label: 'Loop Length', dataAlign: 'right', width: '100' },
            { property: 'spot_length', label: 'Spot Length', dataAlign: 'right', width: '100' },
            { property: 'total_spots', label: 'Total Spots', dataAlign: 'right', width: '100' },
          ]}
        />

        <IssueGroup
          key='RFP'
          heading='Units with different prices than submitted in RFP'
          issues={issues['RFP']}
          fetchQaIssues={fetchQaIssues}
          deleteCampaignUnit={this.handleUnitDelete}
          campaignId={this.campaign_id}
          hasRequests={this.props.data.campaignPermissions.show_unit_removal_reason}
          columns={[
            { property: 'unit_id', label: '', hidden: true },
            { property: 'supplier_face_id', label: 'Face ID' },
            { property: 'unit_name', label: 'Unit Name', width: '300' },
            { property: 'supplier_name', label: 'Vendor' },
            { property: 'price', label: 'Price', dataAlign: 'right' },
            { property: 'inventory_file_price', label: 'RFP Price', dataAlign: 'right' },
            { property: 'inventory_file_id', label: '', hidden: true },
          ]}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ issues }) => ({
  ...issues
})

export default connect(
  mapStateToProps,
  {
    fetchQaIssues,
    removeCampaignUnitByToken
  }
)(QaIssues);
