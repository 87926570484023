import { post } from "../../../utils/api";

export const GET_STARTED = 'MOBILE_GET_STARTED';
export const SHARE_CAMPAIGN = 'MOBILE_SHARE_CAMPAIGN';
export const LEARN_MORE = 'MOBILE_LEARN_MORE';
export const REQUEST_HOLD = 'MOBILE_REQUEST_HOLD';
export const CONTACT_EXECUTIVE = 'MOBILE_CONTACT_EXECUTIVE';
export const SHOW_PROMPT = 'MOBILE_SHOW_PROMPT';
export const SHOW_TOAST = 'MOBILE_SHOW_TOAST';
export const CLEAR_DIALOG = 'MOBILE_CLEAR_DIALOG';

const CAMPAIGNS_API_URL = "/api/v1/campaigns";

export const showPrompt = (options) => (dispatch, getState) => {
  dispatch({ type: SHOW_PROMPT, payload: options })
}

export const sendInquiry = (campaign_token, inquire_type, data, acknowledgement) => async (dispatch, getState) => {
  console.log('sendInquiry', campaign_token, inquire_type, data);

  try {
    const response = await post(`${CAMPAIGNS_API_URL}/${campaign_token}/inquire/${inquire_type}`, { inquiry: data });
    dispatch({ type: SHOW_TOAST, payload: acknowledgement })
  } catch (error) {
    console.error("Error processing the inquiry", error);
    return null;
  }
}

export const onClose = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_DIALOG })
}

export const shareCampaign = (campaign_token, data, acknowledgement) => async (dispatch, getState) => {
  const { recipient_email, message } =  data;
  const params = {
    share: {
      recipient_email: recipient_email,
      message: message,
      include_excel_attachment: false
    },
    shareable_type: 'Campaign',
    shareable_id: campaign_token,
  };

  try {
    const response = await post('/api/v1/shares', params)
    dispatch({ type: SHOW_TOAST, payload: acknowledgement })
  } catch(error) {
    console.error("Error while attempting to share campaign", error);
  }
}
