import * as React from "react";
import styled, { css } from "styled-components";

import { User } from "../../types/user";
import Tab from "./Tab";

type Props = {
  user: User;
};

const StyledList = styled.ul`
  display: flex;
  margin: 0 13px;
  align-items: center;
`;

const TabList = ({ user }: Props) => {
  const {
    can_view_supplier_dashboard,
    can_view_browse,
    can_view_programmatic,
    can_view_inventory_management,
    can_view_campaigns_tab,
    can_view_adquick_requests_tab,
    can_view_orders,
    can_view_accounts,
    can_view_self_service,
    can_view_analytics_tab,
  } = user.permissions;

  return (
    <StyledList>
      <Tab name="Dashboard" url="/dash" show={can_view_supplier_dashboard} />
      <Tab name="Browse" url="/search" show={can_view_browse} />
      <Tab name="Inventory" url="/inventory" show={can_view_inventory_management} />
      <Tab name="Campaigns" url="/campaigns" show={can_view_campaigns_tab} />
      <Tab name="Programmatic" url="/programmatic" show={can_view_programmatic} />
      <Tab name="Analytics" url="/analytics" show={can_view_analytics_tab} />
      <Tab name="Self-Service" url="/self-service" show={can_view_self_service} />
      <Tab name={adquickRequestsName(user)} url="/adquick_requests" show={can_view_adquick_requests_tab} />
      <Tab name="Accounts" url="/users" show={can_view_accounts} />
      <Tab name="Orders" url="/orders" show={can_view_orders} />
      <Tab name="Leads" url="/vendor_leads" show={user.is_managed_supplier} />
    </StyledList>
  );
};
TabList.displayName = "NavBar/TabList";
export default TabList;

function adquickRequestsName(user: User) {
  const { is_supplier, is_agency, is_admin } = user;

  if (is_supplier) {
    return "AdQuick Requests";
  }

  return "Requests";
}
