import React from 'react';
import PropTypes from 'prop-types';
import abbreviate from "number-abbreviate";
import { formatMoney } from 'accounting';

class CollapsedTargetSpend extends React.Component {
  render() {
    const { spend_goal, spend_goal_required, percentage, onToggle } = this.props

    return (
      <div className={`target-spend ${spend_goal_required ? 'required' : ''}`}>
        <div className="target-spend-shadow" />
        <div className="target-spend-container">
          <div className="target-spend-header">
            <div className="target-spend-title">
              <i className="fa fa-bullseye-arrow" />
              {spend_goal_required && "Required "}
              Target Budget
            </div>
            <div>
              <strong>{percentage}</strong>
              {" of "}
              <span title={formatMoney(spend_goal)}>
                ${abbreviate(spend_goal, 1).toUpperCase()}
              </span>
              {" goal"}
            </div>
            <button className="target-spend-toggle" onClick={onToggle}>
              <i className="fal fa-chevron-up" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

CollapsedTargetSpend.propTypes = {
  spend_goal: PropTypes.number.isRequired,
  spend_goal_required: PropTypes.bool.isRequired,
  percentage: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default CollapsedTargetSpend
