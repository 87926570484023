import { LOAD_ALL_MARKETS } from '../actions/markets_actions';

type Action = {type: string, payload: any};

export default(state: any[] | null, action: Action): any[] | null => {
  switch(action.type) {
    case LOAD_ALL_MARKETS: return action.payload;
    default: return state || null;
  }
}
