import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { isMobile } from "../../../utils/mobile";
import { listViewUnits } from "../../../models/Units";
import UnitCollectionModel from "../../../models/UnitCollectionModel";
import FiltersBar from "../../Filters/FiltersBar";
import Scroll, { scroller } from "react-scroll";
import {
  favoriteUnits,
  unfavoriteUnits,
  sortUnits,
} from "../../../actions/UnitsActions";
import UnitsGroup from "./UnitsGroup";
import KeyboardNavigation from "./KeyboardNavigation";
import CampaignUnitPopup from "../CampaignUnitPopup"

const PER_PAGE = isMobile() ? 30 : 250;

class Catalog extends Component {

  constructor(props){
    super(props);
    this.state = {
      offset: 0,
      isModalOpen: false,
      activeUnitId: null,
      activeTab: "info",
    };
  }

  pageUnits(flatten = false) {
    const { offset } = this.state;

    const allUnitsByTag = listViewUnits(this.props.units);
    const allUnitsFlattenList = _.isEmpty(allUnitsByTag) ? [] : Object.values(allUnitsByTag).flat();

    const slice = allUnitsFlattenList.slice(offset, offset + PER_PAGE);
    if (flatten) return slice;
    return this.unitsGroupedByTag(slice);
  }

  unitsGroupedByTag(units) {
    const unitsGroupedByTag = _.groupBy(units, (unit) => {
      return _.get(unit, 'tags[0].tag', 'Untagged')
    });
    return unitsGroupedByTag;
  }

  onMoveCursor(unit) {
    const  { isModalOpen } = this.state;
    this.setState({
      activeUnitId: unit.id,
      unit: isModalOpen ? unit : null,
      activeTab: 'info',
    })
    this.scrollIntoViewIfNeeded()
  }

  scrollIntoViewIfNeeded() {
    const { activeUnitId } = this.state;
    const elem = $(`#${activeUnitId}`)[0];
    const { top, bottom } = elem.getBoundingClientRect();
    const padding = 90;
    const scrollNeeded = top < padding || bottom > window.innerHeight - padding;
    if (scrollNeeded) {
      const offset = -Math.abs(window.innerHeight / 3);
      scroller.scrollTo(activeUnitId, {
        duration: 300,
        delay: 200,
        smooth: true,
        offset: offset,
        containerId: 'app',
      })
    }
  }

  openUnitModal(unit_id, manualIndex = null) {
    const { unit, _package } = this.getUnitAndPackage(unit_id);
    this.setState({
      unit: unit,
      _package: _package,
      isModalOpen: true,
      activeUnitId: unit.id,
      manualIndex
    });
  }

  onSort(value) {
    this.props.sortUnits({ sort_by: value });
  }

  onCloseUnitModal() {
    this.setState({ unit: null, _package: null, isModalOpen: false });
  }

  onToggleInfoAndMap() {
    const { activeTab } = this.state;
    this.setState({ activeTab: activeTab == 'info' ? 'map' : 'info'})
  }

  getUnitAndPackage(unit_id) {
    const flattenUnitsList = this.pageUnits(true);
    const collection = new UnitCollectionModel(flattenUnitsList);
    const unit = collection.getUnitById(unit_id);
    const _package = collection.getPackageForUnit(unit);
    return({ unit, _package });
  }

  renderUnitsByTag(){
    const { activeUnitId } = this.state;
    const { campaign, campaign_permissions, hide_unit_prices } = this.props;
    const pageUnits = this.pageUnits();
    const tagGroupSizes = Object.values(pageUnits).map((v) => v.length);

    return (
      Object.keys(pageUnits).map((key, index) => {
        const indexOffset = index == 0 ? 0 : tagGroupSizes[index - 1];
        return (
          <UnitsGroup
            key={index}
            title={key}
            units={pageUnits[key]}
            campaign_permissions={campaign_permissions}
            campaign={campaign}
            onFavoriteUnit={this.props.onFavoriteUnit}
            onUnitCardClick={(unitId, idx) => this.openUnitModal(unitId, idx)}
            activeUnitId={activeUnitId}
            indexOffset={indexOffset}
            hide_unit_prices={hide_unit_prices}
          />
        )
      })
    )
  }

  renderUnitModalWithComments(){
    const { currentUser } = this.props;
    const { unit, _package, activeTab } = this.state;
    return(
      <CampaignUnitPopup
        unit={unit}
        _package={_package}
        user={currentUser}
        onClose={() => this.onCloseUnitModal()}
        activeTab={activeTab}
        isCampaignView={true}
      />
     )
  }

  onPageChange(data) {
    const selected = data.selected;
    const offset = Math.ceil(selected * PER_PAGE);
    this.setState({ offset: offset });
    Scroll.animateScroll.scrollToTop();
  }

  renderFiltersBar() {
    const { campaign, units } = this.props;
    const { unit_filter } = units;
    const isMock = (campaign && campaign.campaign && campaign.campaign.is_mock && !!!campaign.campaign.supplier_id);
    return (
      <FiltersBar
        isMock={isMock}
        onSort={(value) => this.onSort(value)}
        onFilter={this.props.onFilter}
        activeFilters={unit_filter}
      />
    )
  }

  renderHotkeys() {
    return (
      <div className="hotkeys_wrapper hidden-xs hidden-sm col-md-offset-2 col-md-8">
        <div className="hotkeys">
          Keyboard Shortcuts:
          <ul className="list-inline">
            <li>Previous <i className="fa fa-long-arrow-up"/></li>
            <li>Next <i className="fa fa-long-arrow-down"/></li>
            <li>Favorite <i>F</i></li>
            <li>Open Selected Unit <i>Enter</i></li>
            <li>Toggle Map<i>T</i></li>
          </ul>
        </div>
      </div>
    )
  }

  renderKeyboardNavigation() {
    const { isModalOpen, activeUnitId, manualIndex } = this.state;
    const pageUnits = this.pageUnits(true);
    return (
      <KeyboardNavigation
        units={pageUnits}
        carouselMode={isModalOpen}
        onMoveCursor={(unit) => this.onMoveCursor(unit)}
        onEnter={(unitId) => this.openUnitModal(unitId)}
        activeUnitId={activeUnitId}
        onFavoriteUnit={this.props.onFavoriteUnit}
        onToggleInfoAndMap={() => this.onToggleInfoAndMap()}
        manualIndex={manualIndex}
      />
    )
  }

  renderPlanNote() {
    const { campaign } = this.props;
    const { recommendedOnly } = this.state;
    const plan_note = _.get(campaign, 'campaign.plan_note', null);
    if (!plan_note || !recommendedOnly) return null;
    return (
      <div className="container">
        <div className="row campaign_plan_note">
          <h2><i className="fa-check-circle fa"/>Recommended Plan</h2>
          <p className="markdown-body">{plan_note}</p>
        </div>
      </div>
    )
  }

  renderPagination() {
    const allUnitsByTag = listViewUnits(this.props.units);
    const allUnitsFlattenList = _.isEmpty(allUnitsByTag) ? [] : Object.values(allUnitsByTag).flat();
    const pageCount = allUnitsFlattenList.length / PER_PAGE;

    if (pageCount < 2) return null;
    return (
      <div className="text-center">
        <ReactPaginate
          previousLabel={isMobile() ? "←" : "Previous"}
          nextLabel={isMobile() ? "→" : "Next"}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={isMobile() ? 0 : 2}
          pageRangeDisplayed={isMobile() ? 1 : 5}
          onPageChange={(data) => this.onPageChange(data) }
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    )
  }

  render(){
    const { units, hide_unit_prices } = this.props;
    const unitsCount = _.get(units, 'all_units.length', 0);
    const noUnitsMessage = unitsCount == 0 ? 'There are no units on this campaign' : 'No units were found matching your filters';
    const pageUnits = this.pageUnits();
    return (
      <div>
        {this.renderFiltersBar()}
        <div className="container">
          <div className="row">
            {this.renderHotkeys()}
          </div>
        </div>
        {this.renderPlanNote()}
        {_.isEmpty(pageUnits) &&
          <div className="catalog_blank_slate container">
            <h4>{noUnitsMessage}</h4>
          </div>
        }
        {!_.isEmpty(pageUnits) &&
          <div className="campaign_catalog">
            {this.renderUnitsByTag()}
            {this.renderPagination()}
            {this.renderUnitModalWithComments()}
            {this.renderKeyboardNavigation()}
          </div>
        }
      </div>
    )
  }

}

const mapStateToProps = ({
    currentUser,
    units
  }) => ({
    currentUser,
    units
});

export default connect(
  mapStateToProps,
  { favoriteUnits, unfavoriteUnits, sortUnits }
 )(Catalog)
