import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {hideModal} from "./actions";
import UploadButton from "../UploadButton"
import GlobalActions from "../../actions/GlobalActions";


class UploadSpecSheetsModal extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.state = {
      name: "",
      url: "",
      saving: false,
      uploadedUrl: "",
      verified: false,
      validationErrors: [],
      error: null
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { selected_units } = this.props;
    const unitIds = selected_units.map(u => u.id);
    let params = {
      units: unitIds,
      name: this.state.name,
      verified: this.state.verified,
      url: this.state.url
    }

    return this.updateSpecSheets(params)
  }

  async updateSpecSheets(params) {
    try {
      const res = await $.post('/api/v2/units/update_specsheets', params)
      this.props.hideModal();
      GlobalActions.showMessage("SpecSheets updated with success.")
    } catch (error) {
      GlobalActions.showError('Error saving specsheets')
      if (error.responseJSON) {
        return this.setState({validationErrors: error.responseJSON.errors})
      }
      this.setState({error: 'Error saving specsheets'})
    }
  }

  onInputChange(target, value) {
    this.setState({[target]: value})
  }

  onUploadComplete(event, file){
    this.setState({uploadedUrl: file.url, url: file.url})
  }

  render() {
    const {
      hideModal,
      show,
      selected_units
    } = this.props;
    const { saving } = this.state
    const selectedLength = this.props.selected_units.length;

    return (
      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{selectedLength === 0 ? 'Select units on inventory page to update availability' : `Upload Spec Sheets for ${selectedLength} units`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="clearfix">
            {selected_units.length > 0 && (
              <form onSubmit={this.onSubmit}>
                {this.renderSelectedUnitsHeader(selected_units)}
                <div className="form-group row">
                  {this.renderNameInput()}
                  {this.renderUploadInput()}
                </div>
                <div className="form-group row">
                  {this.renderUrlInput()}
                  {this.renderVerifyInput()}
                </div>
                <input
                  type="submit"
                  style={{margin: "10px"}}
                  value="Save"
                  className="btn btn-default"
                />
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  renderSelectedUnitsHeader(selected_units) {
    return <div
      className="selected_units form-group"
      style={{ backgroundColor: "#f0f0f0", margin: "10px", padding: "10px", overflow: 'auto', maxHeight: '200px'}}
    >
      <h5 style={{ marginLeft: "15px" }}>You're about to update spec sheets of the following units:</h5>
      <ul style={{ padding: "10px", marginLeft: "20px", listStyleType: "disc" }}>
        {selected_units.map(unit => {
          return (
            <li
              style={{ marginTop: "1px", padding: "1px", listStyleType: "disc" }}
              key={`selected-unit-${unit.id}-${unit.name}`}
            >{`${unit.supplier_face_id} - ${unit.name}`}</li>
          );
        })}
      </ul>
    </div>;
  }

  renderUploadInput() {
    let inputButton
    if (this.state.url !== "") {
      inputButton =
      <button className="btn btn-default" style={{ width: "100%" }} onClick={e => this.setState({url: "", uploadedUrl: ""})}>
        <i className="fa fa-file-times" aria-hidden="true" style={{ marginRight: 5 }}></i>Clear file
      </button>
    } else {
      inputButton =
      <UploadButton onComplete={this.onUploadComplete} isPublic={true} directory="uploads/spec_sheet">
        <button className="btn btn-default" style={{ width: "100%" }} onClick={e => e.preventDefault()} disabled={this.state.url === '' ? false : true}>
          <i className="fa fa-file-o" aria-hidden="true" style={{ marginRight: 5 }}></i>Click to Upload or Drop File Here
        </button>
      </UploadButton>
    }
    const error = this.state.validationErrors && this.state.validationErrors.uploaded_url
    return <div className="col-sm-6">
      <label>Upload:</label>
      <span>{error}</span>
      {inputButton}
    </div>;
  }

  renderUrlInput() {
    const error = this.state.validationErrors && this.state.validationErrors.url

    return <div className="col-sm-6">
      <label>Spec Sheet URL:</label>
      <span>{error}</span>
      <input
        className="form-control"
        disabled={this.state.uploadedUrl === '' ? false : true}
        value={this.state.url}
        name="url"
        type="text"
        onChange={e => this.onInputChange("url", e.target.value)} />
    </div>;
  }

  renderVerifyInput() {
    return <div className="col-sm-6">
      <label htmlFor="verified">Verify</label>
      <input
        style={{ height: "auto", width: "auto", marginRight: "10px" }}
        className="form-control"
        type="checkbox"
        name="verified"
        onChange={e => this.onInputChange("verified", e.target.checked)} />
    </div>;
  }

  renderNameInput() {
    return <div className="col-sm-6">
      <label>Name (optional):</label>
      <input
        className="form-control"
        name="name"
        value={this.state.name}
        type="text"
        onChange={e => this.onInputChange("name", e.target.value)} />
    </div>;
  }
};

export default connect(
  ({inventory: {selected_units}}) => {
    return {selected_units};
  }, {hideModal}
)(UploadSpecSheetsModal);
