import React from 'react'
import Select from 'react-select';

const styles = {
  demo: {
    marginBottom: 10
  }
}

export default class SelectDemos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      demographics: {}
    }
  }

  componentDidMount() {
    $.get(`/api/v1/demographics`, {source: "census", group_by: "topic"}).then(({ demographics }) => {
      this.setState({ demographics })
    })
  }

  onSelect(selection) {
    this.props.onSelect(selection.value)
  }

  renderTopicSelect(topic) {
    const { demographics } = this.state;
    const demographicsTopic = demographics[topic] || [];
    const options = demographicsTopic.map(({ name, id }) => ({ label: name, value: id }));
    const selected = options.find(o => o.value === this.props.demographic_ids[0]);
    return (
      <Select
        id={`${topic}_select`}
        className="selectInput"
        placeholder="Select Demographic"
        value={selected}
        options={options}
        onChange={demographic => this.onSelect(demographic)}
      />
    )
  }

  render() {
    return (
      <div>
        <div style={styles.demo}>
          <div><b>Household Income</b></div>
          {this.renderTopicSelect("income")}
        </div>
        <div style={styles.demo}>
          <b>Age and Sex</b>
          {this.renderTopicSelect("age_sex")}
        </div>
        <div style={styles.demo}>
          <b>Race</b>
          {this.renderTopicSelect("race")}
        </div>
        <div style={styles.demo}>
          <b>Employment Status</b>
          {this.renderTopicSelect("employment")}
        </div>
        <div style={styles.demo}>
          <b>Veterans</b>
          {this.renderTopicSelect("veterans")}
        </div>
        <div style={styles.demo}>
          <b>Education</b>
          {this.renderTopicSelect("education")}
        </div>
      </div>
    )
  }
}
