import * as React from "react";
import Select from "react-select";
import debounce from "lodash/debounce";

import { get } from "../utils/api";

type Props = {
  campaign: object;
  onSelectCampaign: (value: any) => void;
};

const SelectCampaign = (props: Props) => {
  const [campaigns, setCampaigns] = React.useState<Array<object>>([]);

  const loadCampaigns = async (query: string | null): Promise<void> => {
    const endpoint = !!query ? `/api/v1/search/options?query=${query}` : "/api/v1/search/options";
    const { campaigns } = await get(endpoint);
    setCampaigns(campaigns);
  };

  const debounceInputChange = debounce(query => {
    if (query.length < 1) { return; }
    loadCampaigns(query);
  }, 400);

  const { campaign, onSelectCampaign } = props;

  return (
    <Select
      placeholder="Select a campaign"
      options={campaigns}
      value={campaign}
      onChange={onSelectCampaign}
      onInputChange={(query) => debounceInputChange(query)}
      simpleValue
    />
  );
};

export default SelectCampaign;
