import mapboxgl from "mapbox-gl";
import _ from 'lodash';

import { allPartitions, hasUnits } from '../models/Units';
import { get, post, del, put } from "../utils/api";
import { partitions } from "../stores/NewMapStore";
import Filter from "../components/NewMap/Filter";
import GlobalActions from "./GlobalActions";

export const REFIT_UNITS = "marketplace/refit-units";
export const FILTER_UNITS = "marketplace/filter-units";
export const filterUnits = filter => async (dispatch, getState) => {
  dispatch({ type: "CAMPAIGN_TOGGLE_LOADING" });
  const { unit_filter } = getState().units;

  if (_.has(filter, 'tags') && unit_filter.tags != filter.tags) {
    dispatch({ type: REFIT_UNITS });
  }

  filter.unit_ids = await new Filter({
    ...unit_filter,
    ...filter,
  }).asyncFilter();
  dispatch({ type: FILTER_UNITS, payload: { filter } });
  dispatch({ type: "CAMPAIGN_TOGGLE_LOADING" });
};

export const CLEAR_FILTERS = "marketplace/clear-filters";
export const clearFilters = filter => async (dispatch, getState) => {
  dispatch({ type: CLEAR_FILTERS });
};

export const SORT_UNITS = "marketplace/sort-units";
export const sortUnits = ({ sort_by }) => dispatch => {
  dispatch({ type: SORT_UNITS, payload: { sort_by } });
};

export const TOGGLE_UNIT_LOCK = 'marketplace/toggle-unit-lock'
export const SET_UNIT_LOADING = 'marketplace/toggle-unit-loading'
export const CLEAR_UNIT_PARTITIONS = 'marketplace/clear-unit-partitions'
export const LOAD_UNIT_PARTITION = 'marketplace/load-unit-partition'
export const loadAllUnits = (position: string | any[], filter = '', embed = false) => async (dispatch, getState) => {
  if(getState().map.lock) return;
  dispatch({ type: TOGGLE_UNIT_LOCK });

  const bounds = getBounds(position);
  const p = allPartitions(getState().units);
  const current_partitions = await partitions(bounds, filter);
  if (!current_partitions || !current_partitions.length) {
    dispatch({ type: TOGGLE_UNIT_LOCK });
    return;
  }
  const current_partition_map = current_partitions.reduce((map, part) => {
    map[part.partition] = part.cache;
    return map;
  }, {});

  const old_partitions = Object.keys(p).filter(
    partition => !(current_partition_map[partition] === p[partition].cache)
  );
  dispatch({ type: CLEAR_UNIT_PARTITIONS, payload: { old_partitions } });

  const new_partitions = current_partitions.filter(
    ({ partition, cache }) => !(p[partition] && p[partition].cache === cache)
  );

  if (new_partitions.length) dispatch({ type: SET_UNIT_LOADING, payload: true });

  const { use_rate_card_price_on_browse } = getState().currentUser;
  const can_view_admin_info = getState().currentUser.permissions.can_view_price_on_browse

  const promises = new_partitions.map(({partition, cache}) => {
    return get(`/api/v1/maps?part=${partition}&cache=${cache}&admin=${can_view_admin_info}${embed ? '&embed=true' : ''}`)
    .then(unit_data => dispatch({ type: LOAD_UNIT_PARTITION, payload: { partition, cache, unit_data, use_rate_card_price_on_browse } }))
    .catch(e => console.error(`Error fetching partition ${partition}`, e));
  });
  await Promise.all(promises);
  dispatch({ type: TOGGLE_UNIT_LOCK });

  if (!hasUnits(getState().units)) dispatch({ type: SET_UNIT_LOADING, payload: false });
};

export const CLEAR_UNITS = 'marketplace/clear-units'
export const loadSupplierUnits = (position: string | any[]) => async (dispatch, getState) => {
  if(getState().map.lock) return;
  dispatch({type: CLEAR_UNITS });
  dispatch({ type: TOGGLE_UNIT_LOCK });
  dispatch({ type: SET_UNIT_LOADING, payload: true });
  const { supplier_id } = getState().currentUser
  const bounds = getBounds(position);
  const current_partitions = await partitions(bounds, '');
  const promises = current_partitions.map(({ partition, cache }) => {
    return get(`/api/v1/suppliers/${supplier_id}/map?partition=${partition}&cache=${cache}`)
    .then(unit_data => dispatch({ type: LOAD_UNIT_PARTITION, payload: { partition, cache, unit_data } }))
    .catch(e => console.error(`Error fetching partition ${partition}`, e));
  });
  await Promise.all(promises);
  dispatch({ type: TOGGLE_UNIT_LOCK });
  dispatch({ type: SET_UNIT_LOADING, payload: false });
}

function getBounds(position: string | any[]) {
  if (Array.isArray(position)) {
    if (position.length == 2) {
      position[2] = position[0];
      position[3] = position[1];
    }
    return new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(position[0], position[1]),
      new mapboxgl.LngLat(position[2], position[3])
    );
  } else {
    position = position.split(",");
    return new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(position[1], position[0]),
      new mapboxgl.LngLat(position[3], position[2])
    );
  }
}

export const endUnitLoading =  () => (dispatch) => dispatch({ type: SET_UNIT_LOADING, payload: false });

export const TOGGLE_RECOMMENDED_UNITS = "markeplace/units/toggle-recommended";
export const toggleRecommendUnits = (
  unit_ids,
  campaign_id,
  action
) => async dispatch => {
  try {
    const {recommended_units_count, unrecommended_units_count} = await post(
      `/api/v1/campaigns/${campaign_id}/toggle_recommend_units`,
      {unit_ids, flow: action}
    );
    const count = recommended_units_count || unrecommended_units_count;
    if (count) {
      GlobalActions.showMessage(`${count} units were successfully ${action}ed`);
    } else {
      GlobalActions.showMessage(`All units were already ${action}ed`);
    }
    dispatch({
      type: TOGGLE_RECOMMENDED_UNITS,
      payload: {unit_ids, is_recommended: action === "recommend"}
    });
  } catch (e) {
    GlobalActions.showError(e);
  }
  };

  export const TOGGLE_BONUS_UNITS = "markeplace/units/toggle-bonus";
  export const toggleBonusUnit = (campaign_unit_token, unit_id, action ) => async dispatch => {
    try {
      const { is_bonus_unit } = await put(`/api/v1/campaign_units/${campaign_unit_token}/toggle_bonus`, {action});
      if (action === 'set_bonus') {
        GlobalActions.showMessage(`Unit set as bonus`);
      } else if (action === 'unset_bonus') {
        GlobalActions.showMessage(`Unit unset as bonus`);
      }
      dispatch({
        type: TOGGLE_BONUS_UNITS,
        payload: {unit_ids: [unit_id], is_bonus_unit: is_bonus_unit}
      });
    } catch (e) {
      GlobalActions.showError(e);
    }
    };


export const FAVORITE_UNITS = "marketplace/unit/favorite";
export const favoriteUnits = (campaign_unit_ids, unit_ids, campaign_id, showCallback) => async (
  dispatch,
  getState
) => {
  const payload = _.isEmpty(campaign_unit_ids) ? { unit_ids } : { campaign_unit_ids }
  dispatch({ type: FAVORITE_UNITS, payload: { unit_ids, campaign_unit_ids } });
  try {
    const { count } = await post(
      `/api/v1/campaigns/${campaign_id}/campaign_units/batch_favorite`,
      payload
    );
    if(showCallback) {
      if (count) {
        GlobalActions.showMessage(`${count} unit(s) successfully favorited.`);
      } else {
        GlobalActions.showMessage(`All units were already favorited`);
      }
    }
  } catch (error) {
    GlobalActions.showError("You need permission to favorite units");
    dispatch({ type: UNFAVORITE_UNITS, payload: { unit_ids, campaign_unit_ids } });
  }
};

export const UNFAVORITE_UNITS = "marketplace/unit/unfavorite";
export const unfavoriteUnits = (campaign_unit_ids, unit_ids, campaign_id, showCallback) => async (
  dispatch,
  getState
) => {
  const payload = _.isEmpty(campaign_unit_ids) ? { unit_ids } : { campaign_unit_ids }
  dispatch({ type: UNFAVORITE_UNITS, payload: { unit_ids, campaign_unit_ids } });
  try {
    const { count } = await post(
      `/api/v1/campaigns/${campaign_id}/campaign_units/batch_favorite/unfavorite`,
      payload
    );
    if(showCallback) {
      if (count) {
        GlobalActions.showMessage(`${count} unit(s) successfully unfavorited.`);
      } else {
        GlobalActions.showMessage(`All units were already unfavorited`);
      }
    }
  } catch (error) {
    GlobalActions.showError(`Error unfavoriting unit(s):  ${error}`);
    dispatch({ type: FAVORITE_UNITS, payload: { unit_ids, campaign_unit_ids } });
  }
};

export const GET_UNIT_PRICE_HISTORY = "marketplace/unit/price_history";
export const loadUnitPriceHistory = (unit_slug, only_booked_campaigns) => async (dispatch, getState) => {
  try {
    const price_history = get(`/api/v1/units/${unit_slug}/price_history?only_booked_campaigns=${only_booked_campaigns}`);
    dispatch({ type: GET_UNIT_PRICE_HISTORY, payload: { price_history, only_booked_campaigns } });

    return price_history;
  } catch (error) {
    GlobalActions.showError("There has been an error while loading price history.");
  }
};
