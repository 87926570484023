import { connect } from "react-redux";
import React, { Component } from 'react';
import GlobalActions from '../../../actions/GlobalActions';
import {
  bulkRemoveProductionMarker,
} from "../../../actions/campaign_design_actions";

interface Props {
  campaign: any;
  design_asset_ids: number[];
  bulkRemoveProductionMarker: (campaign_id: string, design_asset_ids: number[]) => void;
}

interface State {
}

class BulkRemoveProductionMarkerAction extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {}
  }

  public render() {
    return (
      <a key="remove_production_marker" onClick={this.onClick} className="btn btn-default"
         style={{ color: "#555555", width: "100%", fontSize: 12 }}>
           Unmark for Production
      </a>
    )
  }

  private async onClick() {
    const { campaign, design_asset_ids, bulkRemoveProductionMarker } = this.props;
    const campaign_id = campaign.campaignId;

    if(confirm("Are you sure you want to unmark for production the selected units?")) {
      await bulkRemoveProductionMarker(campaign_id, design_asset_ids);
      GlobalActions.showMessage("Successfully removed production marker for the selected campaign units.");
    }
  }

}

const mapStateToProps = ({}) => ({});
export default connect(
  mapStateToProps,
  { bulkRemoveProductionMarker },
 )(BulkRemoveProductionMarkerAction);
