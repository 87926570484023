import { formatNumber } from 'accounting'
import React from 'react';

class Location extends React.Component {
  render() {
    const { name, impressions, cpm, unit_count, population } = this.props;
    return (
      <li className="planner_location">
        <div className="header">{ name }</div>
        <div className="body">
          <div>
            <div className="metric">impressions</div>
            <div className="value">{ formatNumber(impressions) }</div>
          </div>
          <div>
            <div className="metric">units</div>
            <div className="value">{ formatNumber(unit_count) }</div>
          </div>
          <div>
            <div className="metric">population</div>
            <div className="value">{ formatNumber(population) }</div>
          </div>
          <div>
            <div className="metric">median cpm</div>
            <div className="value">{ formatNumber(cpm, 2) }</div>
          </div>
        </div>
      </li>
    );
  }
}

export default Location
