import {
  LOAD_CAMPAIGN_POPS,
} from "../actions/campaign_design_actions";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGN_POPS:
      return action.payload;
    default:
      return state;
  }
};
