import _ from "lodash";
import moment from "moment";

import {
  ADD_POSTING_NOTE,
  APPROVE_DESIGN_ASSET,
  LOAD_CAMPAIGN_DESIGNS,
  MARK_STILL_POSTED,
  REJECT_DESIGN_ASSET,
  REMOVE_POSTING_NOTE,
  REMOVE_PRODUCTION_MARKER,
  UPDATE_TAKE_DOWN_DATES,
} from "../actions/campaign_design_actions";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CAMPAIGN_DESIGNS:
      return action.payload;
    case APPROVE_DESIGN_ASSET:
      return onDesignAssetUpdate(state, action.payload);
    case REJECT_DESIGN_ASSET:
      return onDesignAssetUpdate(state, action.payload);
    case REMOVE_PRODUCTION_MARKER:
      return onDesignAssetUpdate(state, action.payload);
    case ADD_POSTING_NOTE:
      return onPostingNoteUpdate(state, action.payload);
    case REMOVE_POSTING_NOTE:
      return onPostingNoteUpdate(state, action.payload);
    case UPDATE_TAKE_DOWN_DATES:
      return onTakeDownDateUpdate(state, action.payload);
    case MARK_STILL_POSTED:
      return onTakeDownDateUpdate(state, action.payload);
    default:
      return state;
  }
};

function onPostingNoteUpdate(state, payload) {
  return state.map(unit => {
    if (payload.campaign_unit_ids.includes(unit.campaign_unit_token)) {
      return {
        ...unit,
        posting_note: payload.posting_note,
        posting_note_at: payload.posting_note_at,
        posting_note_by: payload.posting_note_by,
      };
    } else {
      return unit;
    }
  });
}

function onTakeDownDateUpdate(state, payload) {
  return state.map(unit => {
    if (payload.campaign_unit_ids.includes(unit.campaign_unit_token)) {
      return {
        ...unit,
        take_down_date: payload.take_down_date ? moment(payload.take_down_date) : null,
        marked_still_posted_at: payload.marked_still_posted_at ? moment(payload.marked_still_posted_at) : null,
      };
    } else {
      return unit;
    }
  });
}

function onDesignAssetUpdate(design_assets, design_asset) {
  const existing_design = findById(design_assets, design_asset.id);
  const new_design = { ...existing_design, ...design_asset };
  const updated_designs = replaceDesignAsset(design_assets, new_design);

  return updated_designs;
}

function onDesignAssetDelete(design_assets: any[], design_asset) {
  const updated_designs = design_assets.filter(d => {
    const assets = d.design_asset;
    if (findAssetInDesignAssetArray(assets, design_asset.id)) {
      d.design_asset = d.design_asset.filter(a => a.id !== design_asset.id);
    }
    return d;
  });

  return updated_designs;
}

function onCreateProductionOrders(design_assets: any[], design_asset_ids: number[], production_orders: any[]) {
  const orders_by_design_asset_id = _.groupBy(production_orders, po => {
    return po.items[0].design_asset_id;
  });

  let updated_designs = [...design_assets];
  design_asset_ids.forEach(design_asset_id => {
    const existing_design = findById(design_assets, design_asset_id);
    const new_design = {
      ...existing_design,
      design_asset: {
        ...existing_design.design_asset,
        production_order: orders_by_design_asset_id[design_asset_id][0],
      },
    };
    updated_designs = replaceDesignAsset(design_assets, new_design);
  });

  return updated_designs;
}

function updateProductionOrder(design_assets, design_asset_id, production_order) {
  const existing_design = findById(design_assets, design_asset_id);
  const new_design = {
    ...existing_design,
    design_asset: {
      ...existing_design.design_asset,
      production_order: production_order,
    },
  };
  const updated_designs = replaceDesignAsset(design_assets, new_design);

  return updated_designs;
}

function deleteProductionOrder(design_assets, design_asset_id) {
  const updated_designs = updateProductionOrder(design_assets, design_asset_id, null);

  return updated_designs;
}

function findById(design_assets: any[], id: number) {
  return design_assets
    .map(d => d.design_assets)
    .flat()
    .find(asset => {
      return asset && asset.id === id;
    });
}

function replaceDesignAsset(design_assets: any[], new_design: any) {
  return design_assets.map(d => {
    const assets = d.design_assets;
    if (findAssetInDesignAssetArray(assets, new_design.id)) {
      updateAssetInDesignAssetArray(assets, new_design);
    }
    return d;
  });
}

function updateAssetInDesignAssetArray(assets: any[], new_design: any) {
  return assets.map(a => (a.id === new_design.id ? { ...a, ...new_design } : a));
}

function findAssetInDesignAssetArray(assets: any[], design_asset_id: number) {
  return assets.find(a => a.id === design_asset_id);
}
