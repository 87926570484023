import Reflux from 'reflux';

import { get, post, put, del } from '../utils/api';

const API_URL = '/api/v1/uploads';

export default Reflux.createStore({
  listenables: [],

  init() {
    this.state = {}
  },

  uploadImage(units, upload, file, options) {
    const params = {
      units: units.map((unit) => unit.id),
      campaign_id: options.campaign_id,
      upload_type: options.upload_type,
      upload: {
        file_name: file.name,
        file_path: upload.path,
        file_size: file.size,
        file_type: file.type,
      }
    }

    return post(API_URL, params)
      .then(res => this.trigger('image:uploaded', res))
      .catch(err => this.trigger('image:error', err));
  },

  uploadFile(params) {
    return post(API_URL, params)
      .then(res => this.trigger('upload:uploaded', res))
      .catch(err => this.trigger('upload:error', err));
  },

  uploadInventoryFile(upload, file, campaign_id = null, supplier_id = null, params = {}) {
    const data = {
      inventory_file: true,
      campaign_id: campaign_id,
      supplier_id: supplier_id,
      upload: {
        file_name: file.name,
        file_path: upload.path,
        file_size: file.size,
        file_type: file.type,
      },
      process_after_upload: params.process_after_upload,
      used_drop_zone: params.usedDropZone
    }

    return post(API_URL, data)
      .then(res => {
        this.trigger('upload:uploaded', res);
        if (params.success) params.success(res);
      })
      .catch(err => {
        this.trigger('upload:error', err);
        throw(err);
      });
  },

  uploadPlaceMarkFile(upload, file, campaign_id, supplier_id) {
    const data = {
      upload_type: "placemark",
      campaign_id: campaign_id,
      supplier_id: supplier_id,
      upload: {
        file_name: file.name,
        file_path: upload.path,
        file_size: file.size,
        file_type: file.type
      },
      process_after_upload: true
    };

    return post(API_URL, data)
      .then(res => {
        this.trigger('upload:uploaded', Object.assign({placemark_upload: true}, res));
      })
      .catch(err => {
        this.trigger('upload:error', Object.assign({placemark_upload: true}, {error: err}));
        throw(err);
      });
  },

  uploadMobileAd(upload, file, imageSize, campaign_id, params={}) {
    const data = {
      upload_type: "mobile_ad",
      campaign_id: campaign_id,
      upload: {
        file_name: file.name,
        file_path: upload.path,
        file_size: file.size,
        file_type: file.type,
        image_size: imageSize,
      },
    }
    return post(API_URL, data)
      .then(res => {
        this.trigger('upload:uploaded', res);
        if (params.success) params.success(res);
      })
      .catch(err => {
        this.trigger('upload:error', err);
        throw(err);
      });
  },

  uploadProgrammaticCreative(upload, file, plan_id) {
    const data = {
      upload_type: "programmatic_creative",
      plan_id,
      upload: {
        file_name: file.name,
        file_path: upload.path,
        file_size: file.size,
        file_type: file.type,
        image_size: file.size,
      },
    }
    return post("/api/v1/programmatic/uploads", data)
      .then(res => {
        this.trigger('upload:uploaded', res);
      })
      .catch(err => {
        this.trigger('upload:error', err);
        throw(err);
      });
  },

});
