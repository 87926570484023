import "./tab.css";

import * as React from "react";

type Props = {
  name: string;
  url: string;
  show?: boolean;
};

type ActiveProps = { active?: boolean };

const isActive = (url: string): boolean => document.location.pathname.startsWith(url);

const Tab: React.FunctionComponent<Props> = ({ name, url, show }) => {
  if (!show) return <></>;

  const active = isActive(url);

  return (
    <li className={`navbar-button ${active ? "current" : ""}`}>
      <a href={url} className="navbar-link">{name}</a>
    </li>
  );
};
Tab.displayName = "NavBar/Tab";
export default Tab;
