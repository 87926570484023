import React, { Component } from "react";
import _ from "lodash";

import { getStatusForGrouping, state_map } from "../../../models/design_assets";
import Card from "./Card";
import IncompleteBulkActions from "./Incomplete/IncompleteBulkActions";
import PostedBulkActions from "./Posted/PostedBulkActions";
import RejectedBulkActions from "./Rejected/RejectedBulkActions";
import SubmittedBulkActions from "./Submitted/SubmittedBulkActions";

interface UnitsGroupProps {
  status: string;
  title: string;
  units: any;
  campaign: any;
  campaign_permissions: any;
  onUnitSelect: (unit, isSelected) => void;
  onUnitBulkSelect: (selected_units) => void;
}
interface UnitsGroupState {
  unit_selection_map: any;
  expanded: boolean;
}
class UnitsGroup extends Component<UnitsGroupProps, UnitsGroupState> {
  constructor(props) {
    super(props);
    this.renderBulkAction = this.renderBulkAction.bind(this);
    this.onUnitSelected = this.onUnitSelected.bind(this);
    this.unselectAll = this.unselectAll.bind(this);
    this.onToggleSelectAll = this.onToggleSelectAll.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);

    this.state = {
      unit_selection_map: {},
      expanded: true,
    };
  }

  public render() {
    const { title, units, status } = this.props;
    const { expanded } = this.state;
    const margin_for_expanded = expanded ? '40px' : '4px';
    return (
      <div key={title} className="row" style={{ marginLeft: -15, marginBottom: margin_for_expanded }}>
        <div className="col-md-6">
          <h5 className="group_header" style={{ cursor: "default" }}>
            {this.renderExpandedCaret()}
            <span style={{ cursor: "pointer" }} onClick={this.toggleExpand}>
              {title}
            </span>
            <span style={{ fontWeight: 400, marginLeft: 5, fontSize: 13 }}>({units.length})</span>
          </h5>
        </div>
        <div className="col-md-6" style={{ display: expanded ? "block" : "none" }}>
          {this.renderBulkAction(status, units)}
        </div>
        <div className="col-md-12" style={{ display: expanded ? "block" : "none" }}>
          {this.renderUnits(status, units)}
        </div>
      </div>
    );
  }

  renderExpandedCaret() {
    const { expanded } = this.state
    if (expanded) { return <i className="fa fa-caret-down margin-right group_arrow" /> }
    return <i className="fa fa-caret-right margin-right group_arrow" />
  }

  private toggleExpand() {
    this.setState(state => ({ expanded: !state.expanded }));
  }

  private renderBulkAction(status: string, units: any) {
    const { campaign, campaign_permissions } = this.props;
    const { unit_selection_map } = this.state;

    switch (status) {
      case state_map.incomplete:
        return (
          <IncompleteBulkActions
            unit_selection_map={unit_selection_map}
            units={units}
            status={status}
            campaign={campaign}
            campaign_permissions={campaign_permissions}
            unselectAll={this.unselectAll}
            onToggleSelectAll={this.onToggleSelectAll}
          />
        );
      case state_map.submitted:
        return (
          <SubmittedBulkActions
            unit_selection_map={unit_selection_map}
            units={units}
            status={status}
            campaign={campaign}
            campaign_permissions={campaign_permissions}
            unselectAll={this.unselectAll}
            onToggleSelectAll={this.onToggleSelectAll}
          />
        );
      case state_map.posted:
        return (
          <PostedBulkActions
            unit_selection_map={unit_selection_map}
            units={units}
            status={status}
            campaign={campaign}
            campaign_permissions={campaign_permissions}
            unselectAll={this.unselectAll}
            onToggleSelectAll={this.onToggleSelectAll}
          />
        );
      case state_map.rejected:
        // @ts-ignore
        return (
          <RejectedBulkActions
            unit_selection_map={unit_selection_map}
            units={units}
            status={status}
            campaign={campaign}
            campaign_permissions={campaign_permissions}
            unselectAll={this.unselectAll}
            onToggleSelectAll={this.onToggleSelectAll}
          />
        );
      default:
        return null;
    }
  }

  private unselectAll() {
    const { unit_selection_map } = this.state;
    const newState = Object.keys(unit_selection_map).reduce((acc, value) => {
      acc[value] = false;
      return acc;
    }, {});
    this.setState({ unit_selection_map: {} }, () => this.props.onUnitBulkSelect(newState));
  }

  private onToggleSelectAll(selected: boolean) {
    const { units, onUnitBulkSelect } = this.props;
    const unit_selection_map = {};
    units.forEach(unit => {
      unit_selection_map[unit.id] = selected;
    });
    this.setState({ unit_selection_map }, () => onUnitBulkSelect(unit_selection_map));
  }

  private renderUnits(status, units) {
    const { campaign_permissions, campaign } = this.props;
    const { unit_selection_map } = this.state;
    return units.map((unit, index) => {
      return (
        <Card
          key={`${unit.id}${index}`}
          status={status === "all" ? getStatusForGrouping(unit) : status}
          unit={unit}
          active={unit_selection_map[unit.id] || unit.selected ||this.getActiveStateFromHash(unit.id)}
          campaign_permissions={campaign_permissions}
          campaign={campaign}
          onUnitSelected={this.onUnitSelected}
        />
      );
    });
  }

  private onUnitSelected(unit, selected) {
    const { onUnitSelect } = this.props;
    this.setState(
      {
        unit_selection_map: {
          ...this.state.unit_selection_map,
          [unit.id]: selected,
        },
      },
      () => onUnitSelect(unit, selected),
    );
  }

  private getActiveStateFromHash(id) {
    const hash = location.hash;
    if (hash && hash.includes(id)) {
      return true;
    }
    return false;
  }
}

export default UnitsGroup;
