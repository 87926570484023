import Creatable from 'react-select/creatable';
import { connect } from 'react-redux';
import React from 'react';
import { addCampaignTag } from '../Campaign/actions';
import GlobalActions from '../../actions/GlobalActions'

class Tags extends React.Component {

  constructor(props) {
    super(props);
    const { unit, campaign } = this.props;
    const possibleTags = campaign.unit_tags.map(tag => ({ label: tag.tag, value: tag.tag }));
    this.state = {
      possibleTags,
      tags: []
    };
    this.onAdd = this.onAdd.bind(this);
  }

  async onAdd() {
    const { unitIds, campaign, addCampaignTag, onHide } = this.props;
    const { tags } = this.state;
    const params = { unit_ids: unitIds, tags: tags.map(tag => tag.value) };

    try {
      await addCampaignTag(campaign.token, params);
      GlobalActions.showMessage('Added tags to campaign units');
      onHide();
    } catch (e) {
      GlobalActions.showError(e);
    }
  }

  render() {
    const { unit, saving_campaign_tags } = this.props;
    const { possibleTags, tags } = this.state;

    return (
      <div className="clearfix" style={{display: 'flex'}}>
        <div style={{ float: 'left', marginRight: 5, width: '100%' }}>
          <Creatable
            placeholder="Add a tag"
            options={possibleTags}
            value={tags}
            onChange={tags => this.setState({ tags })}
            promptTextCreator={text => `Add tag ${text}`}
            isMulti
          />
        </div>
        <button
          className="btn btn-primary"
          style={saving_campaign_tags ? {backgroundColor: '#7e7e7e'} : {}}
          onClick={this.onAdd}
          disabled={saving_campaign_tags}
        >
          {saving_campaign_tags ?
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          : 'Save Tags'}
        </button>
      </div>
    )
  }
};

export default connect(({
  campaign: { saving_campaign_tags }
}) => ({ saving_campaign_tags }), { addCampaignTag })(Tags);
