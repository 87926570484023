import React, { Component } from 'react';

class PillBadge extends Component {

  render(){
    const { label } = this.props;
    return (
      <span className="badge badge-light">{_.startCase(label)}</span>
    )
  }

}

export default PillBadge
