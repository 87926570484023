import { CLEAR_MAP } from '../components/NewMap/actions';
import {
  CLEAR_UNIT_PARTITIONS,
  FAVORITE_UNITS,
  FILTER_UNITS,
  CLEAR_FILTERS,
  LOAD_UNIT_PARTITION,
  SORT_UNITS,
  TOGGLE_RECOMMENDED_UNITS,
  UNFAVORITE_UNITS,
  TOGGLE_BONUS_UNITS
} from '../actions/UnitsActions';
import {
  CLEAR_UNITS,
  INITIAL_UNIT_REQUEST,
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_UNITS,
  REMOVE_ACTIVE_TAGS,
  REMOVE_CAMPAIGN_TAGS,
  REMOVE_CAMPAIGN_UNITS,
  REMOVE_CAMPAIGN_UNIT,
  REMOVE_CAMPAIGN_UNIT_BY_TOKEN,
  MANAGE_UNITS_ROW_UPDATE,
  REFRESH_UNIT_TAGS
} from '../components/Campaign/actions';
import {
  SUPPLIER_CONFIRM_UNIT,
  SUPPLIER_UNAVAILABLE_UNIT,
  RESET_SUPPLIER_STATUS
} from '../actions/UnitRequestsActions';
import {
  TOGGLE_IN_CART_UNITS
} from '../actions/cart';
import {allUnits} from '../models/Units';
import { parseBrowseUnits, parseCampaignUnits, parseCampaignUnit } from '../models/Unit';
import {buildKdbushIndex, clearKdbushIndex} from "../models/CampaignSpatialIndex";

const initialState = {
  sort_by: 'default',
  unit_filter: {
    tags: [],
    audience_enabled: false,
    audience_units: []
  },
  all_units: [],
  loaded: false,
  loadedDataSets: new Set()
};
/*
  IMPORTANT: If the action changes `all_units` it needs to run `buildKdbushIndex`.
  */
export default (state = initialState, action) => {
  let newState = state;
  switch(action.type) {
    case CLEAR_MAP:
      newState = initialState;
      break;
    case CLEAR_UNITS:
      return clearUnitsFromState(state);
    case CLEAR_UNIT_PARTITIONS:
      newState = clearUnitPartitions(state, action.payload);
      break;
    case LOAD_UNIT_PARTITION:
      newState = loadUnitPartition(state, action.payload);
      break;
    case LOAD_CAMPAIGN_UNITS:
      newState = loadCampaignUnits(state, action.payload);
      break;
    case FILTER_UNITS:
      newState = filterUnits(state, action.payload);
      break;
    case CLEAR_FILTERS:
      return clearFilters(state, action.payload);
    case SORT_UNITS:
      return  sortUnits(state, action.payload);
      break;
    case INITIAL_UNIT_REQUEST:
      newState = initialUnitRequest(state, action.payload);
      break;
    case REMOVE_CAMPAIGN_UNITS:
      newState = removeCampaignUnits(state, action.payload);
      break;
    case REMOVE_CAMPAIGN_TAGS:
      newState = setAttribute(state, action.payload.unit_ids, 'tags', []);
      break;
    case REMOVE_ACTIVE_TAGS:
      newState = removeActiveTags(state, action);
      break;
    case REFRESH_UNIT_TAGS:
      return filterExistingTags(state, action.payload);
    case REMOVE_CAMPAIGN_UNIT:
      newState = removeCampaignUnits(state, action.payload);
      break;
    case REMOVE_CAMPAIGN_UNIT_BY_TOKEN:
      newState = removeCampaignUnit(state, action.payload);
      break;
    case LOAD_CAMPAIGN:
      return sortUnits(state, action.payload);
    case TOGGLE_RECOMMENDED_UNITS:
      newState = setAttribute(state, action.payload.unit_ids, 'is_recommended', action.payload.is_recommended);
      break;
    case TOGGLE_BONUS_UNITS:
      newState = setAttribute(state, action.payload.unit_ids, 'is_bonus_unit', action.payload.is_bonus_unit);
      break;
    case FAVORITE_UNITS:
      newState = setFavorite(state, action.payload.campaign_unit_ids, action.payload.unit_ids, true);
      break;
    case UNFAVORITE_UNITS:
      newState = setFavorite(state, action.payload.campaign_unit_ids, action.payload.unit_ids, false);
      break;
    case SUPPLIER_CONFIRM_UNIT:
      newState = setAttribute(state, [action.payload.unit_id], 'supplier_status', action.payload.supplier_status);
      break;
    case SUPPLIER_UNAVAILABLE_UNIT:
      newState = setAttribute(state, [action.payload.unit_id], 'supplier_status', action.payload.supplier_status);
      break;
    case RESET_SUPPLIER_STATUS:
      newState = setAttribute(state, [action.payload.unit_id], 'supplier_status', action.payload.supplier_status);
      break;
    case MANAGE_UNITS_ROW_UPDATE:
      newState = manageUnitsRowUpdate(state, action.payload);
      break;
    case TOGGLE_IN_CART_UNITS:
      newState = setAttribute(state, action.payload.unit_ids, 'in_cart', action.payload.in_cart);
      break;
    default:
      return state;
  }

  buildKdbushIndex(newState);
  return newState;
};

function removeActiveTags(state, action) {
  return setAttribute(
    state,
    action.payload.unit_ids,
    'tags',
    (unit) => ({
      ...unit,
      tags: unit.tags.filter(tag => !action.payload.tagNames.includes(tag.tag))
    })
  );
}

function setAttribute(state, unit_ids, attribute, valueOrCallback) {
  const units = state.all_units
    .filter(u => unit_ids.includes(u.id) || unit_ids.includes(u.package_id))
    .map(u => {
      return typeof valueOrCallback === 'function'
        ? valueOrCallback(u)
        : { ...u, [attribute]: valueOrCallback }
    });

  const all_units = [
    ...state.all_units.filter(u => {
      return !unit_ids.includes(u.id) && !unit_ids.includes(u.package_id);
    }),
    ...units,
  ];

  return {
    ...state,
    all_units,
  };
}

function setFavorite(state, campaign_unit_ids, unit_ids, value) {
  const all_units = state.all_units.map(u => {
    if (!unit_ids.includes(u.id)) return u;

    if (!campaign_unit_ids || !campaign_unit_ids.length) {
      return {
        ...u,
        is_favorited: value,
        campaign_units: u.campaign_units.map(cu => ({...cu, is_favorited: value}))
      };
    } else {
      return {
        ...u,
        is_favorited: value,
        campaign_units: u.campaign_units.map(cu => campaign_unit_ids.includes(cu.id) ? {...cu, is_favorited: value} : cu)
      };
    }

  });
  return {
    ...state,
    all_units
  };
}

function clearUnitsFromState(state) {
  clearKdbushIndex();
  return {
    ...state,
    all_units: [],
    loaded: false,
    loadedDataSets: new Set()
  };
}

function clearUnitPartitions(state, {old_partitions}) {
  if (!old_partitions.length) return state;

  const all_units = Array.isArray(state.all_units) ? {} : { ...state.all_units };
  old_partitions.forEach(part => delete all_units[part]);

  return {
    ...state,
    all_units,
  }
}

function loadUnitPartition(state, { partition, cache, unit_data, use_rate_card_price_on_browse }) {
  const all_units = Array.isArray(state.all_units) ? {} : { ...state.all_units };
  all_units[partition] = {
    cache,
    units: parseBrowseUnits(unit_data, use_rate_card_price_on_browse)
  };

  return {
    ...state,
    all_units,
  };
}

function loadCampaignUnits(state, { unitData, campaignUnitData }) {
  const loadedDataSets = new Set([
    ...state.loadedDataSets,
    ...Object.keys(unitData),
    ...Object.keys(campaignUnitData).filter(dataSet => dataSet !== 'tags')
  ]);

  const all_units = parseCampaignUnits(unitData, campaignUnitData);

  return {
    ...state,
    all_units,
    loaded: true,
    loadedDataSets
  };
}

function filterUnits(state, { filter }) {
  return {
    ...state,
    unit_filter: {
      ...state.unit_filter,
      ...filter
    },
  };
}

function clearFilters(state) {
  return {
    ...state,
    unit_filter: initialState.unit_filter,
  };
}

function sortUnits(state, { sort_by }) {
  if (!sort_by) return state;

  return {
    ...state,
    sort_by,
  };
}

function initialUnitRequest(state, { unit_id, startDate, endDate }) {
  const all_units = allUnits(state).map(unit => {
    if (unit.id !== unit_id && unit.package_id !== unit_id) return unit;

    return {
      ...unit,
      supplier_status: 'initial_requested',
      status: 'initial_requested',
      start_date: startDate,
      end_date: endDate
    }
  });

  return {
    ...state,
    all_units,
  }
}

function removeCampaignUnit(state, { campaign_unit_token }) {
  const all_units = allUnits(state).filter(u => u.campaign_unit_token != campaign_unit_token)

  return {
    ...state,
    all_units,
  };
}

function removeCampaignUnits(state, { unit_ids }) {
  unit_ids = new Set(unit_ids);
  const all_units = allUnits(state).filter(u => !unit_ids.has(u.id) && !unit_ids.has(u.package_id))

  return {
    ...state,
    all_units,
  };
}

function manageUnitsRowUpdate(state, { campaign_unit_token, price, start_date, end_date, quantity }) {
  const unit = state.all_units.find(u => u.campaign_unit_token === campaign_unit_token);
  const updatedUnit = parseCampaignUnit({ ...unit, start_date, end_date, price, quantity });
  // here we also update the fields on (unit.campaign_units)
  const cu = updatedUnit.campaign_units.find(cu => cu.id === campaign_unit_token);
  cu.start_date = start_date;
  cu.end_date = end_date;
  cu.price = price;

  const all_units = [
    ...state.all_units.filter(u => u.campaign_unit_token !== unit.campaign_unit_token),
    ...[updatedUnit],
  ];

  return {
    ...state,
    all_units
  };
}

function filterExistingTags(state, { unit_tags }) {
  const unitTagsThatExistInCampaignTags =
    state.unit_filter.tags.filter(
      (unitTag) => unit_tags.some(
        (campaignTag) => unitTag.tag === campaignTag.tag && unitTag.color === campaignTag.color
      )
    )

  return {
    ...state,
    unit_filter: {
      ...state.unit_filter,
      tags: unitTagsThatExistInCampaignTags
    }
  }
}
