import { connect } from 'react-redux';
import React, { Component } from 'react';
import cs from 'classnames';
import _ from 'lodash';

import { CAMPAIGN_TAB_SUBTAB_PATH, goToRoute } from '../../../Routes';
import {
  statuses,
  status_to_title,
  designsTabUnits,
  groupByStatus,
  withProductionOrder
} from '../../../models/design_assets';
import {
  loadCampaignDesigns,
  loadCampaignPops,
  sendToProduction
} from '../../../actions/campaign_design_actions';
import {
  loadCampaignShippingAddresses,
} from '../../../actions/campaign_shipping_address_actions';
import {
  sortUnits,
} from '../../../actions/UnitsActions';
import FiltersBar from "../../Filters/FiltersBar";
import UnitsInStatus from "./UnitsInStatus";
import Loading from "../../Loading";
import CircleGraphics from "./CircleGraphics/index";

class Designs extends Component {

  constructor(props) {
    super(props);
    this.onLoadAllUnits = this.onLoadAllUnits.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.state = {
      force_show_all_untis: false,
      active_tab: this.props.step || "incomplete"
    }
  }

  render() {
    const { campaign_permissions } = this.props;
    const designs_tab_units = this.pageUnits();
    const { active_tab } = this.state;
    if ( designs_tab_units == null ) {
      return <Loading>Units are loading. This could take up to a minute.</Loading>;
    } else {
      const units_by_status = groupByStatus(designs_tab_units);
      units_by_status["circlegraphics"] = withProductionOrder(designs_tab_units);

      return (
        <div>
          {this.renderFiltersBar()}
          <div className="campaign_designs_header">
            <span className="title">Design Assets</span>
            { this.renderStatusTabs(active_tab, units_by_status, campaign_permissions) }
          </div>
          {_.isEmpty(designs_tab_units) &&
            <div className="campaign_designs_blank_slate container">
              <h4>There are no units on this campaign</h4>
              <p><strong>Hint:</strong> Check any active filters.</p>
            </div>
          }
          {this.unitLoadLimit() &&
            <div className="container" style={{marginTop: 25}}>
              <div className="alert alert-warning">
                <i className="fa fa-exclamation-triangle margin-right" />
                Since this campaign is not booked yet, we are not showing all design assets. Click <a onClick={this.onLoadAllUnits}>here</a> to load them all.
              </div>
            </div>
          }
          <div style={{marginTop: 4}}>
            {this.renderNewTabLink(active_tab)}
            {this.renderDesignAssets(active_tab, units_by_status)}
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    const { campaign: { campaignId } } = this.props
    this.props.loadCampaignDesigns(campaignId);
    this.props.loadCampaignShippingAddresses(campaignId);
    this.props.loadCampaignPops(campaignId);
  }

  componentDidUpdate(){
    const selected = location.hash;
    if (selected && selected.length > 0) {
      const elem = document.getElementById(selected.replace("#", ""));
      if (elem && !this.scrolled) {
        elem.scrollIntoView();
        this.scrolled = true;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.step) {
      this.setState({active_tab: nextProps.step})
    }
  }

  pageUnits() {
    const { units, design_assets, pops, proofs } = this.props;

    if ( units.loaded && _.isEmpty(units.all_units) ) return [];

    if ( _.isEmpty(units.all_units) || _.isEmpty(design_assets) || _.isEmpty(pops) ) return null;

    return designsTabUnits(units, design_assets, pops, proofs, this.unitLoadLimit());
  }

  unitLoadLimit() {
    if (this.state.force_show_all_untis) return null;

    const { campaign, units } = this.props;
    if ((campaign.campaign && !campaign.campaign.booked) && units.all_units.length > 600){
      return 100;
    } else {
      return null;
    }
  }

  onSort(value) {
    this.props.sortUnits({ sort_by: value });
  }

  renderFiltersBar() {
    const { campaign, units, onFilter } = this.props;
    const { unit_filter } = units;
    const isMock = (campaign && campaign.campaign && campaign.campaign.is_mock && !!!campaign.campaign.supplier_id);
    return (
      <FiltersBar
        isMock={isMock}
        onSort={(value) => this.onSort(value)}
        onFilter={onFilter}
        activeFilters={unit_filter}
      />
    );
  }

  renderStatusTabs(active_tab, units_by_status, campaign_permissions) {
    const { currentUser, units } = this.props;
    const { all_units } = units;

    return (
      <ul>
        { statuses.map((status) => {
            if (status === "circlegraphics" && !campaign_permissions.can_create_production_order) {
              return null;
            } else if (status === "all" && !!!currentUser.is_admin) {
              return null;
            } else if (status === "all" && currentUser.is_admin) {
              return (
                <li key="all" className={cs('', {active: active_tab === status})}
                    onClick={() => this.selectTab('all')}>
                    All <span className="count">({all_units ? all_units.length : 0})</span>
                </li>
              );
            }
            return (
              <li key={status} className={cs('', {active: active_tab === status})}
                  onClick={() => this.selectTab(status)}>
                  { status_to_title[status] } <span className="count">({units_by_status[status] ? units_by_status[status].length : 0})</span>
              </li>
            );
          }
        )}
      </ul>
    );
  }

  selectTab(status) {
    goToRoute(CAMPAIGN_TAB_SUBTAB_PATH,
      {
        campaign_id: this.props.campaign.campaignId,
        page: 'design_assets',
        step: status
      });
  }

  renderNewTabLink(status) {
    const { campaign } = this.props.campaign
    const popUrl = `/campaigns_pops/${this.props.campaign.campaignId}`
    const creativeUrl = `/campaigns/${this.props.campaign.campaignId}/creatives${campaign.booked ? '?booked_status=all_booked&page=1' : ''}`
    const style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'aliceblue',
      padding: '5px'
    }
    const url = (status == 'posted' || status == 'submitted') ? popUrl : creativeUrl
    const message = (status == 'posted' || status == 'submitted') ? "POPs" : "Creatives"
    return (
      <div style={style}>
        <a href={url} target='_blank' >{`Click here to use our improved ${message} experience`}</a>
      </div>
    )
  }

  renderDesignAssets(active_tab, units_by_status){
    const { campaign, campaign_permissions } = this.props;

    const from_all_statuses = [].concat.apply([], Object.keys(units_by_status).map((k) => units_by_status[k]));
    const units_in_status = active_tab === 'all' ? from_all_statuses : units_by_status[active_tab] || [];

    if(active_tab === "circlegraphics") {
      return (
        <CircleGraphics
          units_in_status={units_in_status}
          campaign={campaign}
          campaign_permissions={campaign_permissions}
        />
      )
    }
    return (
      <UnitsInStatus
        key={active_tab}
        status={active_tab}
        total_unit_count={this.props.units.all_units.length}
        units_in_status={units_in_status}
        campaign={campaign}
        campaign_permissions={campaign_permissions}
        sendToProduction={this.props.sendToProduction}
      />
    );
  }

  onLoadAllUnits() {
    this.setState({force_show_all_untis: true});
  }
}

const mapStateToProps = ({
    campaign,
    currentUser,
    design_assets,
    pops,
    units,
    proofs
  }) => ({
    campaign,
    currentUser,
    design_assets,
    pops,
    units,
    proofs
});

export default connect(
  mapStateToProps,
  {
    loadCampaignShippingAddresses,
    loadCampaignDesigns,
    loadCampaignPops,
    sendToProduction,
    sortUnits
  },
 )(Designs);
