import React, { Component } from 'react';

const HELLOSIGN = 'hellosign';
const UPLOAD = 'upload';

class ActionBadge extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { contract, onShowForm } = this.props;
    const { type, url, in_negotiation, contract_download_url } = contract;
    const download_url = type == UPLOAD ? url : contract_download_url;

    if (in_negotiation) {
      onShowForm(type, contract);
      return false;
    }

    if (!download_url) {
      alert('Contract is still being processed. Please refresh in a couple of minutes.');
      return false;
    }
    window.open(download_url, '_blank');
  }

  label() {
    const { contract } = this.props;
    const { type, has_signed_contract_file, in_negotiation } = contract;

    if (in_negotiation) {
      return (
        <div>
          View proposal <i className="fa fa-caret-down" aria-hidden="true"></i>
        </div>
      )
    }

    switch(type) {
      case HELLOSIGN: return has_signed_contract_file ? 'Download Copy' : 'View Contract';
      case UPLOAD:    return 'Download PDF';
      default:        return 'Still working...';
    }
  }

  badgeClassName() {
    const { contract } = this.props;
    const { type } = contract;

    switch(type) {
      case HELLOSIGN: return 'hellosign';
      case UPLOAD:    return 'upload';
      default:        return '';
    }
  }

  render(){
    const { contract } = this.props;
    const { canceled_at } = contract;

    return (
      <span onClick={this.onClick} className={`link link-${this.badgeClassName()}`}>{this.label()}</span>
    )
  }

}

export default ActionBadge
