import React, { Component } from "react";

import ReviewCampaignButton from "./ReviewCampaignButton";
import UnitsAddedCount from "./UnitsAddedCount";

export default class Header extends Component<any, any> {
  constructor(props) {
    super(props);
    this.onContinueWithoutBoards = this.onContinueWithoutBoards.bind(this);
    this.goToCampaign = this.goToCampaign.bind(this);
  }

  renderButton() {
    const { onSubmit, save_enabled, campaign, goToStep, units_added } = this.props;

    if (onSubmit) {
      return (
        <button
          className="btn btn-primary float-right"
          style={{
            width: 162,
            maxHeight: 42,
            marginLeft: 0,
          }}
          type="submit"
          disabled={!!!save_enabled}
          onClick={() => onSubmit()}
        >
          Next
        </button>
      );
    } else {
      return (
        <ReviewCampaignButton
          campaign={campaign}
          goToStep={goToStep}
          disabled={units_added && units_added.length ? null : true}
        />
      );
    }
  }

  onContinueWithoutBoards() {
    const { campaign } = this.props;
    window.location.href = `/campaigns/${campaign.token}`;
  }

  renderConttinueWithoutBoards() {
    const { step, campaign } = this.props;
    const units_added = this.props.units_added || [];

    const link_style = {
      color: "#364cbe",
      textDecoration: "underline",
      marginLeft: 5,
      cursor: "pointer",
    };

    if (step && step === 2 && units_added.length === 0) {
      return (
        <div onClick={this.onContinueWithoutBoards} style={link_style}>
          Continue with no boards
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  goToCampaign() {
    const { campaign } = this.props;
    if (campaign && campaign.token) {
      window.location.href = `/campaigns/${campaign.token}`;
    } else {
      return;
    }
  }

  render() {
    const { campaign, units_added } = this.props;
    const header_style = this.props.header_style || {};
    const campaign_name_style =
      campaign && campaign.token
        ? {
            color: "#364cbe",
            textDecoration: "underline",
            cursor: "pointer",
          }
        : {};

    return (
      <div className="header-area" style={header_style}>
        <div style={{ margin: "0px 20px", display: "flex", paddingTop: 13 }}>
          <div className="create-new-campaign">
            <div className="header">Create New Campaign</div>
            <div className="campaign-name" style={campaign_name_style} onClick={this.goToCampaign}>
              {campaign && campaign.name ? campaign.name : "Untitled Campaign"}
            </div>
          </div>
          <UnitsAddedCount units_added={units_added} />
          <div className="button-area">
            {this.renderButton()}
            {this.renderConttinueWithoutBoards()}
          </div>
        </div>
      </div>
    );
  }
}
