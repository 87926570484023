var Reflux = require("reflux");

export default Reflux.createActions({
  toggleDesignAssets: {},
  toggleCluster: {},
  togglePrice: {},
  toggleFaceId: {},
  toggleTransitLayer: {},
  zoomOut: {},
});
