export default class JobProgress {
  constructor() {
    this.pollingInterval;
  }

  startPolling(job_id, completed_callback) {
    this.pollingInterval = setInterval(() => this.loadProgressData(job_id, completed_callback), 1000);
    return this.pollingInterval
  }

  stop() {
    clearInterval(this.pollingInterval);
  }

  async loadProgressData(job_id, completed_callback) {
    try {
      const response = await $.get(`/api/v1/job_progress/${job_id}`);
      if (response) {
        const { completed_tasks, total_tasks, failed_tasks } = response;
  
        if ((completed_tasks === total_tasks) || (failed_tasks === total_tasks)) {
          clearInterval(this.pollingInterval);
          completed_callback(response);
        }
      } else {
        console.log(`no response received in job progress for job id ${job_id}`)
      }
    } catch (error) {
      console.log(`error in job progress for job id ${job_id}`)
      console.log({ error })
      throw error;
    }
  }
}
