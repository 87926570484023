import React from 'react';
import { browserHistory } from 'react-router-legacy';
import moment from 'moment';
import momentRange from 'moment-range';
import { connect } from 'react-redux';
import accounting from 'accounting';
import createClass from 'create-react-class';
import cs from 'classnames';
import GlobalActions from '../../actions/GlobalActions';
import ActivityStore from '../../stores/ActivityStore';
import AuthStore from '../../stores/AuthStore';
import Item from './Item'
import { fetchRows } from '../Campaigns/actions';
import Loading from '../Loading';

const Activity = createClass({
  displayName: 'Activity/Index',

  getInitialState() {
    const { user } = AuthStore.getState()

    return {
      user,
      campaign: {},
      transactionsOnly: true,
      activity_logs: [],
      totals: {},
      isLoading: true
    };
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ]

    this.props.fetchRows()
    this.loadData();
    this.interval = setInterval(this.loadData, 5000);
  },

  onAuthChange() {
    this.setState({
      user: AuthStore.getState().user
    })

    if (AuthStore.isLoggedIn()) {
      this.props.fetchRows()
    }
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn())
    clearInterval(this.interval);
  },

  loadData() {
    if (this.state.transactionsOnly != 'overview') {
      $.get(`/api/v1/logs?transactions_only=${this.state.transactionsOnly}`)
      .then(({ activity_logs, totals }) => this.setState({ activity_logs, totals, isLoading: false }));
    }
  },

  setFilters(params) {
    const { transactionsOnly } = params
    this.setState({ transactionsOnly, isLoading: true }, this.loadData)
  },

  renderNoActivity() {
    const { activity_logs } = this.state

    return (
      <div className="row">
        <div className="col-sm-12">
          {activity_logs.length == 0 && <div style={{paddingTop:20}}>
            There is no recent activity. <br />
            When a customer views a proposal or signs a contract, a feed of activity will populate on this page.
          </div>}
        </div>
      </div>
    )
  },

  renderTransactions() {
    const { activity_logs } = this.state
    let byDate = {}

    activity_logs.forEach(log => {
      const date = moment(log.created_at).format('LL')
      if (!byDate[date]) byDate[date] = []
      byDate[date].push(log)
    })

    return (
      <div>
        {Object.keys(byDate).map(date => <div key={date} style={{marginBottom:25}}>
          <h4>{date}</h4>
          <ul>
            {byDate[date].map(log => <li key={log.id}>
              <Item key={log.id} log={log} />
            </li>)}
          </ul>
        </div>)}
      </div>
    )
  },

  render() {
    const { campaign, transactionsOnly, activity_logs, user, totals, isLoading } = this.state;

    const logoStyle = { maxHeight: 33, maxWidth: 400, marginRight: 20, verticalAlign: 'top' }

    return (
      <div className="container">
        <div className="row" style={{marginTop:25}}>
          <div className="col-sm-8">
            {user.company_logo_url &&
              <img src={user.company_logo_url} style={logoStyle} />}
            <div className="btn-group" style={{marginRight:10,marginBottom:15}}>
              <button type="button" className={cs('btn', 'btn-default', {active: transactionsOnly === false})} onClick={() => this.setFilters({transactionsOnly: false})}>
                <i className="fa fa-grid" /> All Activity
              </button>
              <button type="button" className={cs('btn', 'btn-default', {active: transactionsOnly === true})} onClick={() => this.setFilters({transactionsOnly: true})}>
                <i className="fa fa-grid" /> Recent Transactions
              </button>
            </div>
          </div>
          <div className="col-sm-4 text-right">
            <span style={{color:'gray'}}>Contract Total: </span>
            <span style={{color:'green'}}>{accounting.formatMoney(totals.booked)}</span>
            <br />
            <span style={{color:'gray'}}>Hold Total: </span>
            <span style={{color:'purple'}}>{accounting.formatMoney(totals.on_hold)}</span>
          </div>
        </div>
        {isLoading ?
            <Loading/>
          :
            <div>
            {activity_logs.length == 0 ? this.renderNoActivity() : this.renderTransactions()}
            </div>
        }
      </div>
    );
  },
});

export default connect(
  null,
  { fetchRows },
)(Activity);
