const advertiserGreyLabel = AppConfig.advertiserGreyLabel;

export const calculateTotals = (favoritesOnly, recommendedOnly, campaign, units_of_campaign, user) => {
  units_of_campaign = Array.isArray(units_of_campaign) ? units_of_campaign : [];
  const units = (campaign.contracted_at || user.is_managed_supplier) ?
    units_of_campaign.filter(unit => !!!unit.unavailable) :
    unitsForTotals(favoritesOnly, recommendedOnly, units_of_campaign);

  const howLong = 4;
  const monthPrice = units.map(unit => (unit.price || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  const weekPrice = monthPrice / 4;
  const cost = weekPrice * howLong;
  const production = units.map(unit => (unit.production_cost || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  const installation = units.map(unit => (unit.installation_cost || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

  const monthImpressions = units.map(unit => (unit.impressions || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  const weekImpressions = monthImpressions / 4;
  const impressions = weekImpressions * howLong;
  const totalImpressions = units.map(unit => impressionsForDuration(unit)).reduce((a, b) => a + b, 0);

  const price = parseFloat(cost);
  const totalPrice = units.map(unit => priceForDuration(unit, 'price')).reduce((a, b) => a + b, 0);
  const production_install = parseFloat(installation) + parseFloat(production);
  const cpm = impressions > 0 && price > 0 ? price / (impressions / 1000) : 0;
  const totalCpm = totalImpressions > 0 && totalPrice > 0 ? totalPrice / (totalImpressions / 1000) : '';
  const only4week = totalPrice <= 0;

  const total_rate_card_price = units.map(unit => priceForDuration(unit, 'rate_card_price')).reduce((a, b) => a + b, 0);
  const total_savings = total_rate_card_price - totalPrice;
  const total_savings_percent = (((total_savings) / total_rate_card_price) * 100).toFixed(0) + '%';

  const total_card_rate_4weeks = 4 * (units.map(unit => (unit.rate_card_price || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)) /4 ;
  const total_4week_saving = total_card_rate_4weeks - cost;
  const total_4week_saving_percent = (((total_4week_saving / total_card_rate_4weeks) * 100).toFixed(0) + '%')

  const count = units.length;

  return {
    only4week,
    price,
    totalPrice,
    impressions,
    totalImpressions,
    cpm,
    totalCpm,
    production_install,
    total_rate_card_price,
    total_savings,
    total_savings_percent,
    total_card_rate_4weeks,
    total_4week_saving,
    total_4week_saving_percent,
    count
  };
}

export const impressionsTotal = (showTotal, impressions4week, totalImpressions, only4week) => {
  let impressions, message;
  if (showTotal && !only4week) {
    return totalImpressions;
  } else {
    return impressions4week;
  }
}

export const advertiserFeeData = (totalPrice, price, production_install, showTotal) => {
  const fee = !!!advertiserGreyLabel ? 0.039 : advertiserGreyLabel.adquick_fee;
  const subdomain = !!!advertiserGreyLabel ? '' : advertiserGreyLabel.subdomain;
  const calculatedFee = (showTotal ? ((totalPrice + production_install) * fee) : ((price + production_install) * fee));
  const isCompass = (subdomain === 'compass');
  return {advertiserGreyLabel, calculatedFee, isCompass}
}

const daysReserved = (unit) => {
  if (
    unit.start_date &&
    unit.end_date &&
    unit.start_date.format &&
    unit.end_date.format &&
    unit.start_date.format() === unit.end_date.format()
  ) {
    return 1;
  }
  if (unit.start_date && unit.end_date && unit.start_date < unit.end_date) {
    return ((new Date(unit.end_date) - new Date(unit.start_date)) / 1000 / 60 / 60 / 24) + 1;
  }
  return 0;
}

const pricePerDay = (unit, type)  => {
  if (!unit[type]) return 0;
  return unit[type] / 4.0 / 7.0;
}

const impressionsPerDay = (unit) => {
  if (!unit.impressions) return 0;
  return unit.impressions / 4.0 / 7.0;
}

export const priceForDuration = (unit, type) => {
  return pricePerDay(unit, type) * daysReserved(unit);
}

export const impressionsForDuration = (unit) => {
  return parseInt(impressionsPerDay(unit) * daysReserved(unit));
}

const unitsForTotals = (favoritesOnly, recommendedOnly, units) => {
  units = units || [];

  if (favoritesOnly && recommendedOnly) {
    return units.filter(unit => !!!unit.unavailable && unit.is_favorited && unit.is_recommended);
  } else if (favoritesOnly) {
    return units.filter(unit => !!!unit.unavailable && unit.is_favorited);
  } else { // defaults to recommended
    return units.filter(unit => !!!unit.unavailable && unit.is_recommended);
  }
}
