class UnitCollectionModel {

  constructor(units) {
    this.units = units;
    this.unitsById = {}
    this.unitsByCampaignUnitToken = {}
    this.unitsByPackageId = {}

    // all units with package units included in top level
    units.forEach(unit => {
      if (unit) {
        this.unitsById[unit.id] ||= unit
        this.unitsByCampaignUnitToken[unit.campaign_unit_token] ||= unit
        this.unitsByPackageId[unit.package_id] ||= unit

        if (unit.package_units) {
          unit.package_units.forEach(packageUnit => {
            this.unitsById[packageUnit.id] ||= packageUnit
            this.unitsByCampaignUnitToken[packageUnit.campaign_unit_token] ||= packageUnit
            this.unitsByPackageId[packageUnit.package_id] ||= packageUnit
          })
        }
       }
    });
  }

  getUnitById(id) {
    return this.unitsById[id]
  }

  getCampaignUnitToken(token) {
    return this.unitsByCampaignUnitToken[token]
  }

  getPackageForUnit(unit) {
    if (unit && unit.package_id) {
      return this.unitsByPackageId[unit.package_id]
    }
  }

}

export default UnitCollectionModel;
