import { connect } from "react-redux";
import React, { Component } from "react";

import { setBounds } from '../../NewMap/actions';
import { loadAllUnits, loadSupplierUnits, filterUnits } from "../../../actions/UnitsActions";
import { loadZipCodes } from '../../Vendors/CampaignBuilder/actions';
import BrowseMap from "../../Browse/Map";
import GlobalActions from '../../../actions/GlobalActions';

class VendorMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      map_moved: false
    };

    this.onMapMove = this.onMapMove.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  componentDidMount() {
    const {
      loadSupplierUnits,
      loadZipCodes,
      campaign_token,
      setBounds,
      map
    } = this.props;

    if (campaign_token){
      loadZipCodes(campaign_token);
    }
    const bounds = [
      map.bounds[0][1],
      map.bounds[0][0],
      map.bounds[1][1],
      map.bounds[1][0]
    ].join(',');
    setBounds(bounds);
    loadSupplierUnits(bounds);
  }

  componentDidUpdate(prevProps) {
    const {
      map,
      currentUser,
      setBounds
    } = this.props;
    const { map_moved } = this.state;
    const default_bounds = currentUser.default_bounds || null;

    if (!!!map_moved && default_bounds && default_bounds.split(',').length === 4) {
      const split_bounds = default_bounds.split(',');
      const bounds = [
        [split_bounds[0], split_bounds[1]].sort().reverse(),
        [split_bounds[2], split_bounds[3]].sort().reverse()
      ]
      setBounds(`${bounds[0].join(',')},${bounds[1].join(',')}`);
    }

  }

  onMapMove(position) {
    const { setBounds } = this.props;
    if (window.location.pathname.includes("/campaigns/") && window.location.hash !== "#units") { return; }
    this.setState({ map_moved: true });
    const bounds = position.bounds;
    const boundsString = [
      bounds.getSouth(),
      bounds.getWest(),
      bounds.getNorth(),
      bounds.getEast()
    ].join(",");
    setBounds(boundsString);
  }

  onMarkerClick(features) {
    GlobalActions.openUnitID(features.id, { isVendorMapView: true });
  }

  render() {
    const { currentUser, units, filterUnits, campaign, zip_codes } = this.props;
    return (
      <BrowseMap
        loadPlacemarkers={true}
        className="main__map"
        style={{ left: 0, overflow: "scroll" }}
        user={currentUser}
        units={units}
        filterUnits={filterUnits}
        onMove={this.onMapMove}
        draw_tool={true}
        campaign={campaign.campaign}
        onMarkerClick={this.onMarkerClick}
        zip_codes={zip_codes}
        isAddByMap={true}
      />
    );
  }
}

export default connect(
  ({
    campaign_builder_ui: {
      zip_codes,
      campaign_token
    },
    map,
    units,
    campaign,
    currentUser
  }) => ({
    zip_codes,
    campaign_token,
    map,
    units,
    campaign,
    currentUser
  }),
  { loadAllUnits, loadSupplierUnits, filterUnits, setBounds, loadZipCodes }
)(VendorMap);
