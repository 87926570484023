import React from 'react';
import ReactDOMServer from 'react-dom/server';

class DemographicDataLayer {

  buildDataLayerLegend(data, shading_variable) {
    if (data && data.length == 0) return null;

    const no_data = {
      text: 'No Data',
      color: '#d8d8d8'
    };

    const scores = data.map(datum => datum[shading_variable])
    const min = Math.min(...scores)
    const max = Math.max(...scores)

    if ( max <= min ) return [no_data];

    const distance = max - min;
    const range_size = Math.trunc( distance / 5 ) * 100;
    const legend = [];
    const suffix = (shading_variable == "matched_percentage") ? "%" : "";

    let start = min * 100;
    let end = start + range_size;
    while(end <= max * 100) {
      legend.push({
        start: start / 100,
        end: end / 100
      });
      start = end;
      end = end + range_size;
    };

    if ( legend.length == 0 ) return [no_data];

    legend[legend.length-1].end = max;

    legend[0].text = `< ${legend[0].end}${suffix}`;
    legend[0].color = '#ffffb2';

    legend[1].text = `${legend[1].start}${suffix} - ${legend[1].end}${suffix}`;
    legend[1].color = '#fecc5c';

    legend[2].text = `${legend[2].start}${suffix} - ${legend[2].end}${suffix}`;
    legend[2].color = '#fd8d3c';

    legend[3].text = `${legend[3].start}${suffix} - ${legend[3].end}${suffix}`;
    legend[3].color = '#f03b20';

    legend[4].text = `> ${legend[4].start}${suffix}`;
    legend[4].color = '#bd0026';

    return [no_data].concat(legend);
  }

  dataLayer(data, shading_variable, legend) {
    return { areas: this.featureCollection(data, shading_variable, legend) };
  }

  featureCollection(data, shading_variable, legend) {
    const features = data.map(datum => {
      const color = this.calculateColor(datum, shading_variable, legend);
      return {
        type: 'Feature',
        properties: {
          ...datum,
          opacity: 0.4,
          fillColor: color,
          color: color,
          popup_content: this.renderPopup(datum, shading_variable)
        },
        geometry: {
          coordinates: datum.coordinates,
          type: 'MultiPolygon',
        }
      }
    });

    return {
      type: 'FeatureCollection',
      features: features
    };
  }

  calculateColor(datum, shading_variable, legend) {
    const inRange = (number, start, end) => {
      return number >= Math.min(start, end) && number <= Math.max(start, end)
    }

    const score = datum[shading_variable];
    const no_data = legend[0];

    if (score == null || score == 0) { return no_data.color; }

    const shading_key = _.find(legend, (sk) => {
      return inRange(score, sk.start, sk.end);
    })
    if (shading_key){
      return shading_key.color;
    } else {
      if (window.Rollbar) Rollbar.error('Error calculating DataLayer color.', 'No shading_key matches the score.', { legend: legend, datum })
      console.error('Error calculating DataLayer color.', 'No shading_key matches the score.', { legend: legend, datum })
      return 'black';
    }
  }

  renderPopup(datum, shading_variable) {
    return ReactDOMServer.renderToString(
      <div className="text-left" key={`popup-${datum.label}`}>
        <div><b>{datum.title}</b></div>
        {datum.matched_total == null ?
          <div>No demographic data for this zip code.</div>
          :
            [
              <div>Total {datum.demographic_universe}: {datum.total}</div>,
              <div><span className={ (shading_variable === "matched_percentage") || (shading_variable === "matched_total") ? "bold" : ""}>Matched {datum.demographic_universe}:</span>
                   <span className={ shading_variable === "matched_total" ? "bold" : ""}>&nbsp;{datum.matched_total}</span>
                   <span className={ shading_variable === "matched_percentage" ? "bold" : ""}>&nbsp;({datum.matched_percentage}%)</span>
              </div>,
              <div>
                {datum.national_percentile &&
                  <span className={ shading_variable === "national_percentile" ? "bold" : ""}>National Percentile: {datum.national_percentile}</span> }
              </div>
            ]

            }
      </div>
    )
  }

}

export default DemographicDataLayer;
