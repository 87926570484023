import React, { PureComponent } from "react";
import UploadPopWithPostedDate from "../UploadPopWithPostedDate";
import * as styleGen from "../styles";

interface IPostedMainAction {
  unit: any;
  campaign_permissions: any;
  campaign_id: string;
}
class PostedMainAction extends PureComponent<IPostedMainAction> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { unit, campaign_permissions } = this.props;

    if (campaign_permissions.can_upload_pop) {
      return this.renderUploadPopButton();
    } else {
      return this.renderDownloadPopButton(unit);
    }
  }

  private renderDownloadPopButton(unit) {
    return (
      <a
        href={unit.pop.asset_url}
        target="_blank"
        className="btn btn-default"
        style={styleGen.uploadButton()}
      >
        <i className="fa fa-cloud-download" style={{ marginRight: 5 }} />{" "}
        Download POP
      </a>
    );
  }

  private renderUploadPopButton() {
    const { unit, campaign_id, campaign_permissions } = this.props;
    const buttonChild = (
      <div>
        <i className="fa fa-cloud-upload" style={{ marginRight: 5 }} /> Upload
        POP
      </div>
    );
    return (
      <UploadPopWithPostedDate
        unit={unit}
        campaign_permissions={campaign_permissions}
        campaign_id={campaign_id}
        buttonStyle={styleGen.uploadButton()}
        buttonChild={buttonChild}
      />
    );
  }
}

export default PostedMainAction;
