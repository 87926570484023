import cs from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router-legacy';

import {
  addUnitsToCart,
  getCartTotal
} from '../../actions/cart';
import GlobalActions from '../../actions/GlobalActions';
import MapActions from '../../actions/MapActions';
import NewMapActions from '../../actions/NewMapActions';
import {
  favoriteUnits,
  filterUnits,
  sortUnits,
  toggleRecommendUnits,
  unfavoriteUnits,
  toggleBonusUnit
} from '../../actions/UnitsActions';
import UnitCollectionModel from '../../models/UnitCollectionModel';
import {
  allUnits,
  campaignUnitsLoaded,
  sidebarUnits
} from '../../models/Units';
import ActivityStore from '../../stores/ActivityStore';
import AuthStore from '../../stores/AuthStore';
import CampaignStore from '../../stores/CampaignStore';
import NewMapStore from '../../stores/NewMapStore';
import UnitStore from '../../stores/UnitStore';
import { post, put, patch } from '../../utils/api';
import { get } from "../../utils/api2";
import JobProgress from '../../utils/JobProgress';
import { isMobile } from '../../utils/mobile';
import FiltersBar from '../Filters/FiltersBar';
import { clearMap, setBounds, updateMapPosition } from '../NewMap/actions';
import PointsOfInterestModal from '../PointsOfInterestModal';
import ProposalSubmissionToolModal from '../ProposalSubmissionToolModal';
import { fetchSupplierTasks } from '../Requests/CampaignRequests/actions';
import VendorListing from '../Timeline/VendorListings';
import {
  clearCampaign,
  loadCampaign,
  loadCampaignUnits,
  loadDemographics,
  refreshUnitTags
} from './actions';
import Activity from './Activity';
import AddCustomPOIFormModal from './AddCustomPOIFormModal';
import AddCustomPOILogoModal from './AddCustomPOILogoModal';
import AddUnitsByZipCodeModal from './AddUnitsByZipCodeModal';
import AddUnitsNearPlacemarkersModal from './AddUnitsNearPlacemarkersModal';
import CampaignAttachments from "./Attachments";
import BookByWarning from "./BookByWarning";
import CampaignAnalytics from './CampaignAnalytics';
import CampaignUnitPopup from './CampaignUnitPopup'
import Cart from './Cart';
import Catalog from './Catalog';
import Contracts from './Contracts';
import CustomGrids from './CustomGrids';
import Designs from './DesignAssets';
import {
  customExportToExcel,
  exportToPdf,
  exportToPpt,
  standardExportToExcel,
} from './Export/actions';
import GeopathTable from './GeopathTable';
import {copyContentToClipboard} from "./Header/CopyToClipboardFunctions";
import HighlightedArea from "./HighlightedArea";
import ImportPlacemarkers from './ImportPlacemarkers';
import ImportPoisByBrand from './ImportPoisByBrand'
import Manage from './Manage';
import MapLoader from './MapLoader';
import MobileCampaign from "./Mobile";
import NewAnalytics from './NewAnalytics/index';
import PricingTable from './PricingTable';
import SendCampaignLink from './SendCampaignLink';
import Share from './Share';
import Sidebar from './Sidebar';
import Summary from './Summary';
import ExportProgress from "./Export/ExportProgress";

class CampaignTab extends React.Component {

  constructor(props) {
    super(props);

    const auth = AuthStore.getState();
    const sortBy = 'cpm';
    const { params } = this.props;

    this.state = {
      auth: auth,
      campaign_id: params.campaign_id,
      page: (params.page == null || typeof (params.page) === 'undefined') ? '' : params.page,
      source: null,
      pageLoading: false,
      loading: true,
      data: null,
      bounds: '',
      sortBy,
      favoritesOnly: false,
      recommendedOnly: false,
      filters: {},
      exportTemplates: [],
      commonAgencyFormatExports: [],
      newMap: !queryString.parse(document.location.search)['old_map'],
      showTips: !(auth.user && auth.user.hide_tips),
      showTimeline: false,
      showPlacesBox: false,
      showProposalTool: false,
      showShareModal: false,
      showTransitRoutes: true,
      hasTransitRoutes: false,
      showTotal: true,
      importPoisByBrand: false,
      job_pooling: new JobProgress(),
      showAnalyticsResults: false,
      currentCustomPOI: false,
    };
  }

  async componentDidMount() {
    const { loadCampaign, loadCampaignUnits, params, getCartTotal, filterUnits } = this.props;
    const { campaign_id } = params;

    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange.bind(this)),
      NewMapStore.listen(this.onNewMapStoreChange.bind(this)),
    ];

    this.loadExportTemplates();
    this.logActivity('CAMPAIGN_VIEWED');
    this.hideTips();
    this.initializeMenus();
    this.mockWarningDisplayed = false;

    await loadCampaign(campaign_id);
    if (!!!(params && params.step === "add_by_map")) {
      await loadCampaignUnits(campaign_id);
    }
    await getCartTotal(campaign_id);

    if (params.step != "units") {
      if (this.props.campaign.campaign.booked) _.defer(filterUnits, { only_booked: true });
    }

    if (!this.hasDemographicDataLayerSet()) { this.renderCustomBounds(); }

    document.title = `${this.props.campaign.campaign.name} | AdQuick`;
  }

  initializeMenus() {
    const { campaign_id } = this.props.params;
    const actions = [
      { id: "#search_for_pois", onClick: this.togglePOIs.bind(this) },
      { id: "#pois_from_csv", onClick: this.importPlacemarkers.bind(this) },
      { id: "#add_units_near_pois", onClick: this.onAddNearbyUnits.bind(this) },
      { id: "#nearest_poi", onClick: this.onCalculateNearestPOI.bind(this) },
      { id: "#pois_by_zip", onClick: this.onAddUnitsByZip.bind(this) },
      { id: "#pois_by_brand", onClick: this.importPoisByBrand.bind(this) },
      { id: "#pois_logo", onClick: this.onAddCustomPOILogo.bind(this) },
      { id: "#upload_proposal", onClick: this.toggleProposalSubmissionToolModal.bind(this) },
      { id: "#generate_contract", onClick: () => browserHistory.push(`/campaigns/${campaign_id}/contracts`) },
      { id: "#remove_priceless_units", onClick: this.onRemovePricelessUnits.bind(this) },
      { id: "#remove_rateless_units", onClick: this.onRemoveRatelessUnits.bind(this) },
      { id: "#remove_pictureless_units", onClick: this.onRemovePicturelessUnits.bind(this) },
      { id: "#remove_impressionless_units", onClick: this.onRemoveImpressionlessUnits.bind(this) },
      { id: "#remove_campaign_unit_scores", onClick: this.onRemoveUnitScores.bind(this) },
      { id: "#remove_distance_to_poi", onClick: this.onRemoveDistanceToPoi.bind(this) },
      { id: "#view_order", onClick: this.onViewOrder.bind(this) },
      { id: "#copy_vendor_link", onClick: this.onCopyVendorLink.bind(this) },
      { id: "#copy_campaign_id", onClick: this.onCopyCampaignId.bind(this) },
      { id: "#finalize_selection", onClick: this.onFinalizeSelections.bind(this) },
      { id: "#add_market_tag_to_units", onClick: this.onAddMarketTagToUnits.bind(this) },
      { id: "#add_custom_bounds", onClick: () => this.setState({ showHighlightedAreaModal: true }) },
      { id: "#export_to_excel", onClick: () => this.onDownload() },
      { id: "#export_to_pdf", onClick: () => this.onPdfDownload() },
      { id: "#export_to_pptx", onClick: () => this.onPptxDownload() },
      { id: "#geopath_export_all", onClick: () => this.onGeopathDownload() },
      { id: "#geopath_export_rec", onClick: () => this.onGeopathDownload("recommended") },
      { id: "#geopath_export_fav", onClick: () => this.onGeopathDownload("favorites") },
      { id: "#mid_campaign_pdf", onClick: () => this.onMidCampDownload("pdf") },
      { id: "#campaign_summary_pdf", onClick: () => this.onSummaryCampDownload("pdf") },
      { id: "#campaign_pre_rfp_pptx", onClick: () => this.onPreRFPCampDownload() },
      { id: "#campaign_strategy_pre_rfp_pptx", onClick: () => this.onPreRFPCampDownload(true) },
      { id: "#mid_campaign_ppt", onClick: () => this.onMidCampDownload("pptx") },
      { id: "#pop_only_pdf", onClick: () => this.onMidCampDownload("pdf", true) },
      { id: "#pop_only_ppt", onClick: () => this.onMidCampDownload("pptx", true) },
      { id: "#share_button", onClick: () => this.setState({ showShareModal: true }) },
      { id: "#leasing_report_export", onClick: () => this.onLeasingReportDownload() },
      { id: "#billing_report_export", onClick: () => this.onBillingReportDownload() },
      { id: "#children_report_export", onClick: () => this.onChildrenReportDownload() },
      { id: "#calculate_campaign_unit_scores", onClick: this.onCalculateCampaignUnitScore.bind(this) }
    ];

    actions.forEach((a) => {
      const element = document.querySelector(a.id);
      if (element) {
        element.addEventListener('click', (e) => {
          e.preventDefault();
          a.onClick()
        });
      }
    });
    document.querySelectorAll('.export_template_item').forEach((element) => {
      if (element) {
        element.addEventListener('click', (e) => {
          e.preventDefault();
          try {
            const filter = this.exportFilter()
            this.onExportFromTemplate(element.id, filter);
          } catch (error) {
            GlobalActions.showError(error);
          }
        });
      }
    });
  }

  onExportFromTemplate(id, filter) {
    const {
      campaign_exporting,
      customExportToExcel,
      params
    } = this.props;
    const { campaign_id } = params;
    if (!!!campaign_exporting) { customExportToExcel(campaign_id, id, filter); }
    GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
  }

  onCopyVendorLink() {
    const { campaign_id } = this.props.params;
    copyContentToClipboard('vendorLink', campaign_id)
  }

  onCopyCampaignId() {
    const { campaign } = this.props;
    const { id } = campaign.campaign;
    copyContentToClipboard('campaignID', id)
  }

  async onFinalizeSelections() {
    try {
      await post(`/api/v1/campaigns/${this.state.campaign_id}/send_finalized_selections_mailer`)
      GlobalActions.showMessage("The team has been notified of your finalization and will reach out shortly!")
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  async onAddMarketTagToUnits() {
    const { campaign_id, job_pooling } = this.state

    try {
      const { job_id } = await post(`/api/v1/campaigns/${campaign_id}/add_market_tag_to_units`)

      job_pooling.startPolling(job_id, this.onAddMarketTagToUnitsComplete.bind(this))

      GlobalActions.showMessage("Adding tags for every campaign unit...")
    } catch (error) {
      GlobalActions.showError("An error happened when adding market tags to units.");
    }
  }

  onViewOrder() {
    const { campaign } = this.props;
    const { order_id } = campaign.campaign;
    window.location.href = `/admin/orders/${order_id}`;
  }

  onRemovePricelessUnits() {
    const { campaign_id } = this.props.params;
    post(`/api/v1/campaigns/${campaign_id}/remove_units_without_price`)
      .then(_result => GlobalActions.showMessage("Units without price are being removed in the background. Please refresh in a few minutes."))
      .catch(err => { throw (err); })
  }

  onRemoveRatelessUnits() {
    const { campaign_id } = this.props.params;
    if (confirm(`Are you sure you want to remove all units without rate card?`)) {
      post(`/api/v1/campaigns/${campaign_id}/remove_units_without_rate_card`)
        .then(_result => GlobalActions.showMessage("Units without rate card are being removed in the background. Please refresh in a few minutes."))
        .catch(err => {
          throw (err);
        })
    }
  }

  onRemovePicturelessUnits() {
    const { campaign_id } = this.props.params;
    post(`/api/v1/campaigns/${campaign_id}/remove_units_without_picture`)
      .then(_result => GlobalActions.showMessage("Units without a picture are being removed in the background. Please refresh in a few minutes."))
      .catch(err => { throw (err); })
  }

  onRemoveImpressionlessUnits() {
    const { campaign_id } = this.props.params;
    post(`/api/v1/campaigns/${campaign_id}/remove_units_without_impressions`)
      .then(_result => GlobalActions.showMessage("Units without a impressions are being removed in the background. Please refresh in a few minutes."))
      .catch(err => { throw (err); })
  }

  onRemoveUnitScores() {
    const { campaign_id } = this.props.params;
    post(`/api/v1/campaigns/${campaign_id}/remove_units_score`)
      .then(_result => GlobalActions.showMessage("The calculated scores have been removed, please refresh the page to reflect the changes."))
      .catch(err => { throw (err); })
  }

  onRemoveDistanceToPoi() {
    const { campaign_id } = this.props.params;
    post(`/api/v1/campaigns/${campaign_id}/remove_distance_to_poi`)
      .then(_result => GlobalActions.showMessage("The distance of each Unit's nearest POI are being removed in the background. Please refresh in a few minutes."))
      .catch(err => { throw (err); })
  }

  async componentDidUpdate(prevProps) {
    const { campaign, units, params, cart, loadCampaign, loadCampaignUnits, clearMap } = this.props;
    const { unit, unit_id } = this.state;
    const { campaign_id } = params;

    if (campaign_id != this.state.campaign_id) {
      this.setState({ campaign_id });
      clearMap();

      await loadCampaign(campaign_id);
      await loadCampaignUnits(campaign_id);
    }

    if (!campaign.campaign || !units.all_units || _.isEmpty(campaign.permissions)) return;

    if (params.unit_id && (!unit || unit_id !== params.unit_id)) {
      if (!allUnits(units).find(u => u.id === params.unit_id)) return;
      const newState = this.unitValuesFromUrl(params.unit_id, { ...this.state, ...params });
      this.setState(newState);
    }
    if (!params.unit_id && unit) {
      this.setState({ unit: null, unit_id: null, _package: null });
    }
    if (params.page != prevProps.params.page) {
      if (prevProps.params.page === 'units') this.props.loadCampaignUnits(this.props.campaign.campaignId);
      const newState = { ...this.state, ...params };
      newState.page = params.page || '';
      this.setState(newState);
    }
  }

  async renderCustomBounds() {
    const data_layers = await get("/api/v1/data_layers", { campaign_id: this.state.campaign_id });
    const extractedAreas = HighlightedArea.extractHighlightedAreas(data_layers);
    this.renderHighlightedAreasOnMap(extractedAreas.filter(area => area.visible));
    this.setState({ data_layers });
  }

  hasDemographicDataLayerSet() {
    return !!queryString.parse(location.search).data_layer;
  }

  unsetDataLayers() {
    GlobalActions.showMessage("Data layer hidden to display custom bounds.", "info");
    browserHistory.push({
      pathname: location.pathname,
      search: `?${queryString.stringify({ data_layer: null })}`
    });
  }

  onNewMapStoreChange(event) {
    if (event === 'map:loadUnitGeojson') this.setState({ hasTransitRoutes: true });
  }

  // todo: move this to CampaignStore, extract campaign from data, refactor and clean up this whole component
  async updateCampaign(values, onSuccess, onError) {
    const data = this.props.campaign
    const new_campaign = { ...data.campaign, ...values }
    const new_data = { ...data, campaign: new_campaign }

    // jquery won't send empty arrays to the server
    if (values.media_types && values.media_types.length === 0) values.media_types = [""]
    if (values.media_subtypes && values.media_subtypes.length === 0) values.media_subtypes = [""]

    this.setState({ data: new_data });
    await patch(
      `/api/v1/campaigns/${this.props.campaign.campaign.token}`,
      { campaign: values }
    )
  }

  hideTips() {
    if (this.state.auth.user.id && !this.state.auth.user.hide_tips) {
      put(`/api/v1/users/${this.state.auth.user.id}`, { hide_tips: true })
    }
  }

  loadExportTemplates() {
    const { user } = this.state.auth;
    const user_permissions = this.state.auth.user.permissions;

    if (user.is_agency || user.is_admin || user_permissions.can_view_agency_exports || user_permissions.can_view_common_agency_format_exports) {
      $.ajax({
        url: "/api/v1/all_export_templates",
        success: (response) => {
          this.setState({ exportTemplates: response.supplier_templates });
          if (user_permissions.can_view_common_agency_format_exports) {
            this.setState({ commonAgencyFormatExports: response.common_agency_format_exports });
          }
        }
      })
    }
  }

  unitValuesFromUrl(unit_id, currentState) {
    if (!this.props.campaign || !this.props.units.all_units) return currentState;

    const newState = Object.assign({}, currentState);
    if (unit_id) {
      const { unit, _package } = this.getUnitAndPackage(unit_id);
      newState.unit_id = unit_id;
      newState.unit = unit;
      newState._package = _package;
    } else {
      newState.unit_id = null;
      newState.unit = null;
      newState._package = null;
    }
    return newState;
  }

  componentWillMount() {
    const user = this.state.auth.user;
    if (user.is_admin || user.isSupplier || user.is_agency) this.props.fetchSupplierTasks(this.state.campaign_id)
  }

  componentWillUnmount() {
    // clearInterval(this.activityInterval)
    const { clearCampaign, clearMap, filterUnits } = this.props;
    clearCampaign();
    clearMap();
    filterUnits({ campaign: null });
    CampaignStore.setCurrentCampaign(null)
    _.has(this, 'unsubscribeList') && this.unsubscribeList.map(fn => fn());
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }
    document.title = AppConfig.defaultTitle;
  }

  onAuthChange() {
    const auth = AuthStore.getState();
    this.logActivity('CAMPAIGN_VIEWED');
    const { loadCampaign, loadCampaignUnits, params } = this.props;
    loadCampaign(params.campaign_id);
    loadCampaignUnits(params.campaign_id);
    this.setState({ auth });
  }

  openLoginModal() {
    if (AuthStore.isLoggedOut()) {
      window.location.assign("/login");
    }
  }

  async logActivity(event, params = {}) {
    ActivityStore.log(event, { campaign: this.props.params.campaign_id }, params);
    if (!!!this.state.auth.user || !!!this.props.params.campaign_id) return
    await $.post("/api/v1/users/analytics", {
      email: this.state.auth.user.email,
      ahoy: {
        visit_id: ahoy.visit_id,
        visitor_token: ahoy.visitor_token
      },
      campaign_id: this.props.params.campaign_id,
      path: window.location.pathname,
      inspectlet_id: window.__insp ? window.__insp.sid : null,
    })
  }

  onLocationSearch(params) {
    const { updateMapPosition } = this.props;
    this.logActivity('CAMPAIGN_LOCATION_SEARCH', { query: params.query });
    updateMapPosition({ bounds: params.boundsString });
  }

  onSearchClear() {
    this.setState({ bounds: null, loading: true });
  }

  onMapMove(position) {
    const bounds = position.bounds;
    const boundsString = [bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast()].join(',');
    const { setBounds } = this.props;
    setBounds(boundsString);
  }

  onCampaignLoaded(response, options = {}) {
    let newState = {}
    newState.data = response
    newState.loading = false
    newState.source = new Date().getTime()
    newState.sidebarLoading = false
    newState.mapLoading = false
    this.nonLocallyFilteredData = response // not storing in React state for performance reasons

    this.setState(newState, () => {
      if (this.props.campaign) {
        const { phone_number } = this.props.campaign;
        GlobalActions.setContactPhoneNumber(phone_number);
      }
    });
  }

  // from the map
  onMarkerClick(params) {
    this.logActivity('CAMPAIGN_MAP_UNIT_CLICK', { unit_id: params.id });
    this.navigateToUnitModal(params.id);
  }

  // from the sidebar
  onUnitListingClick(unit_id) {
    this.logActivity('CAMPAIGN_UNIT_CLICK', { unit_id });
    this.navigateToUnitModal(unit_id);
  }

  // for timeline
  onTimelineUnitListingClick(unit_id) {
    this.logActivity('CAMPAIGN_UNIT_CLICK', { unit_id });
    this.openUnitModal(unit_id);
  }

  navigateToUnitModal(unit_id) {
    browserHistory.push(`/campaigns/${this.props.campaign.campaign.token}/units/${unit_id}`);
  }

  navigateToMap() {
    browserHistory.push(`/campaigns/${this.props.campaign.campaign.token}/map`);
  }

  getUnitAndPackage(unit_id) {
    const collection = new UnitCollectionModel(sidebarUnits(this.props.units));
    const unit = collection.getUnitById(unit_id);
    const _package = collection.getPackageForUnit(unit);
    return ({ unit, _package });
  }

  openUnitModal(unit_id, options = {}) {
    const { unit, _package } = this.getUnitAndPackage(unit_id);
    this.setState({ unit: unit, _package: _package });
  }

  onCloseUnitModal() {
    if (!this.state.page) {
      this.navigateToMap();
    } else {
      this.setState({ unit: null, _package: null });
    }
  }

  onSort(value) {
    this.logActivity('CAMPAIGN_UNITS_SORT', { sortBy: value });
    this.setState({ sortBy: value });
    this.props.sortUnits({ sort_by: value });
  }

  async onFavoriteUnit(unitId, campaignUnitId, isFavorited, showCallback) {
    const campaignUnitIds = campaignUnitId ? [campaignUnitId] : []
    const { unfavoriteUnits, favoriteUnits } = this.props;
    const {campaignId} = this.props.campaign;

    isFavorited ?
      await unfavoriteUnits(campaignUnitIds, [unitId], campaignId, showCallback) :
      await favoriteUnits(campaignUnitIds, [unitId], campaignId, showCallback);
  }

  onPinUnit(unitId) {
    // TODO this is dead code, we need to clean
    let newData = Object.assign({}, this.props.campaign);
    let index = newData.units.findIndex(unit => unit.id === unitId);
    let unit = newData.units[index];
    let newPinStatus = !unit.is_pinned;
    unit.is_pinned = newPinStatus;
    if (newPinStatus === true) {
      this.logActivity('CAMPAIGN_PINNED_UNIT', { unit: unitId });
    } else {
      this.logActivity('CAMPAIGN_UNPINNED_UNIT', { unit: unitId });
    }
    this.setState({ data: newData })
    UnitStore.updatePinStatus(unit, this.state.campaign_id);
  }

  async onToggleRecommendUnits(unitId, action) {
    const unit = sidebarUnits(this.props.units).find(u => u.id === unitId);
    if (!unit) return;

    const { campaignId } = this.props.campaign;
    await this.props.toggleRecommendUnits([unitId], campaignId, action)
  }

  async onToggleBonusUnit(campaignUnitToken, unitId, action) {
    const unit = sidebarUnits(this.props.units).find(u => u.campaign_unit_token === campaignUnitToken);

    if (!unit) return;

    await this.props.toggleBonusUnit(campaignUnitToken, unitId, action)
  }

  onFilter(filters) {
    this.setState({ filters })
    this.logActivity('CAMPAIGN_FILTER_APPLIED', filters);
    this.props.filterUnits({
      ...filters,
      only_favorites: this.state.favoritesOnly,
      only_recommended: this.state.recommendedOnly,
      is_mock: this.props.campaign.campaign.is_mock,
      hide_face_ids: this.props.campaign.campaign.hide_face_ids
    });
  }

  onLocalFilter(localFilters) {
    const { tag } = localFilters

    if (tag) {
      this.props.filterUnits({ tag, campaign: this.state.campaign_id });
    }
  }

  onFavoriteSelect(value) {
    this.setState({ favoritesOnly: value });
    this.props.filterUnits({ only_favorites: value });
    this.logActivity('CAMPAIGN_SHOW_FAVORITES_ONLY', { value });
  }

  onRecommendedFilter(value) {
    this.setState({ recommendedOnly: value });
    this.props.filterUnits({ only_recommended: value });
    this.logActivity('CAMPAIGN_SHOW_RECOMMENDED_ONLY', { value });
  }

  onAvailabilityFilter(availabilityStatus) {
    this.setState({ availabilityStatus })
    this.props.filterUnits({ availability_status: availabilityStatus })
    this.logActivity('CAMPAIGN_SHOW_STATUS', { availabilityStatus })
  }

  async onAddAllToCart() {
    const { campaign, addUnitsToCart, units, currentUser } = this.props;
    const filteredUnitTokens = this.getFilteredUnits(true);
    const allUnits = sidebarUnits(units, true);

    const notLoggedIn = !currentUser || currentUser.is_guest;
    if (notLoggedIn && !campaign.permissions.can_checkout_logged_out) {
      window.location.assign("/login");
      return;
    }

    this.logActivity('CAMPAIGN_ADD_ALL_TO_CART');

    const valid = allUnits.every((unit) => {
      if (unit.is_place_marker) return true;
      return unit.start_date && unit.end_date;
    });

    if (!valid) {
      return GlobalActions.showMessage('Please add dates before adding to cart');
    }

    // returns true if at least one unit is already booked
    const alreadyHadBookedUnits = allUnits.some((unit) => {
      return (unit.supplier_status === 'booking_approved' || unit.supplier_status === 'booking_adjusted')
    })

    if (alreadyHadBookedUnits) {
      GlobalActions.showMessage('One or more of these units are booked and were not added to the cart', 'info');
    }

    const eventProperties = {
      'Campaign Name': campaign.name,
      'Campaign Token': campaign.token,
      'Units Count': allUnits.length
    }
    window.mixpanel.track('Added to Cart', eventProperties)

    await addUnitsToCart(campaign.token, filteredUnitTokens, true);
  }

  formatDate(date) {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }

    return moment.utc(date.format('YYYY-MM-DD'));
  }

  onViewOnMap() {
    this.setState({ page: '' });
  }

  onDownload() {
    const {
      campaign_exporting,
      standardExportToExcel
    } = this.props;
    const { campaign_id } = this.state;
    try {
      const filter = this.exportFilter()
      const default_template_id = event.target.dataset.defaultTemplateId;
      if (!!!campaign_exporting) {
        if(default_template_id) {
          this.onExportFromTemplate(default_template_id, filter);
        } else {
          standardExportToExcel(campaign_id, filter);
        }
      }
      this.hideAllOpenDropdowns();
      GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  exportFilter() {
    const export_filter = document.getElementById('filter_by').value;
    if (export_filter === "with_filters") { return this.unitTokensFiltered() }
    return export_filter && export_filter !== "with_filters" ? { [export_filter] : true } : {};
  }

  unitTokensFiltered() {
    const filteredTokens = this.getFilteredUnits()
    if (filteredTokens.length == 0) {
      return {} // empty filters
    }
    return { token: filteredTokens }
  }

  onMidCampDownload(type, popOnly = false){
    const { campaign_exporting, exportToPdf, exportToPpt } = this.props;
    const { campaign_id } = this.state;
    const options = { export: { is_mid_camp: true, pop_only: popOnly } };
      if(type == "pptx"){
        if (!!!campaign_exporting) {
          exportToPpt(campaign_id, options);
        }
      } else if( type === "pdf"){
        if (!!!campaign_exporting) {
          exportToPdf(campaign_id, options);
        }
      }
    GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
  }

  onSummaryCampDownload(type){
    const { campaign_exporting, exportToPdf, exportToPpt } = this.props;
    const { campaign_id } = this.state;
    const selected_value = document.getElementById('filter_by').value;
    const filter = this.normalizeSelectedFilter(selected_value);
    let options = filter ? { export: { is_summary: true, [filter]: true } } : { export: { is_summary: true } };

    if (filter === "with_filters") {
      const filteredUnitTokens = this.getFilteredUnits();
      if (filteredUnitTokens.length == 0) {
        options = { export: { is_summary: true } };
      } else {
        options.export.tokens = filteredUnitTokens;
      }
    }

    if (!!!campaign_exporting) {
      exportToPpt(campaign_id, options);
    }
    GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
  }

  onPreRFPCampDownload(useStrategy = false){
    const { campaign_exporting, exportToPdf, exportToPpt } = this.props;
    const { campaign_id } = this.state;
    const selected_value = document.getElementById('filter_by').value;
    const filter = this.normalizeSelectedFilter(selected_value);
    let options = filter ? { export: { is_pre_rfp: true, use_strategy: useStrategy, [filter]: true } } : { export: { is_pre_rfp: true, use_strategy: useStrategy } };

    if (filter === "with_filters") {
      const filteredUnitTokens = this.getFilteredUnits();
      if (filteredUnitTokens.length == 0) {
        options = { export: { is_pre_rfp: true } };
      } else {
        options.export.tokens = filteredUnitTokens;
      }
    }

    if (!!!campaign_exporting) {
      exportToPpt(campaign_id, options);
    }
    this.hideAllOpenDropdowns();
    GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
  }


  onPptxDownload() {
    const { campaign_exporting, exportToPpt } = this.props;
    const { campaign_id } = this.state;
    const selected_value = document.getElementById('filter_by').value;
    const pptx_filter = this.normalizeSelectedFilter(selected_value);
    let pptx_filter_options = pptx_filter ? { export: { [pptx_filter] : true }} : { export: {} };

    if (pptx_filter === "with_filters") {
      const filteredUnitTokens = this.getFilteredUnits();
      if (filteredUnitTokens.length == 0) {
        pptx_filter_options = { export: {} };
      } else {
        pptx_filter_options.export.tokens = filteredUnitTokens;
      }
    }

    if (!!!campaign_exporting) {
      exportToPpt(campaign_id, pptx_filter_options);
    }
    this.hideAllOpenDropdowns();
    GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
  }

  onPdfDownload() {
    const { campaign_exporting, exportToPdf } = this.props;
    const { campaign_id } = this.state;

    const selected_value = document.getElementById('filter_by').value;
    const pdf_filter = this.normalizeSelectedFilter(selected_value);
    let pdf_filter_options = pdf_filter ? { export: { [pdf_filter]: true } } : {};

    if(pdf_filter === "with_filters") {
      const filteredUnitTokens = this.getFilteredUnits();
      if (filteredUnitTokens.length == 0) {
        pdf_filter_options = { export: {} };
      } else {
        pdf_filter_options.export.tokens = filteredUnitTokens;
      }
    }

    if (!!!campaign_exporting) { exportToPdf(campaign_id, pdf_filter_options);}
    this.hideAllOpenDropdowns();
    GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
  }

  exportInProgressMessage(campaign_id) {
    const message = (
      <span>
        Export is in progress and will be available in the <a onClick={() => browserHistory.push(`/campaigns/${campaign_id}/attachments`)}>Attachments Tab</a> when
        complete.
      </span>
    );
    return message;
  }

  normalizeSelectedFilter(value) {
    if (value === 'with_filters') return value;
    switch(value) {
      case 'favorited':     return 'favorites_only';
      case 'recommended':   return 'recommended_only';
      default:              return null;
    }
  }

  hideAllOpenDropdowns() {
    document.querySelectorAll('.ui_dropdown_body.open').forEach((element) => {
      element.classList.remove('open');
    });
  }

  onShowTotal(showTotal) {
    this.setState({ showTotal: showTotal });
  }

  getFilteredUnits(multi_flight = false) {
    const { units } = this.props;
    const filteredUnits = sidebarUnits(units, !multi_flight);
    const filteredUnitTokens = filteredUnits.length != units.all_units.length ? filteredUnits.map(u => u.campaign_unit_token) : [];
    return filteredUnitTokens;
  }

  async onGeopathDownload(market_tag = null) {
    this.setState({ pageLoading: true });
    try {
      const { job_id } = await put(`/api/v1/campaigns/${this.state.campaign_id}/campaign_exports/geopath_format`, { market_tag })
      this.setState({ pageLoading: false });
      this.state.job_pooling.startPolling(job_id, this.onGeopathDownloadComplete.bind(this))
    } catch (error) {
      this.setState({ pageLoading: false, loading: false });
      GlobalActions.showError(error)
    }
  }

  onGeopathDownloadComplete(response) {
    const { data } = response
    const { file_link, error } = data
    if (file_link) { window.open(file_link) }
    if (error) { GlobalActions.showError(error) }
  }

  async onLeasingReportDownload() {
    try {
      const { url } = await post(`/api/v1/campaigns/${this.state.campaign_id}/export_leasing_report`);
      window.open(url);
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  async onBillingReportDownload() {
    try {
      const { url } = await post(`/api/v1/campaigns/${this.state.campaign_id}/export_billing_report`);
      window.open(url);
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  async onChildrenReportDownload() {
    const { campaign_id } = this.state;
    try {
      const { url } = await post(`/api/v1/campaigns/${this.state.campaign_id}/export_children_report`);
      GlobalActions.showMessage(this.exportInProgressMessage(campaign_id), 'success', true);
      this.hideAllOpenDropdowns();
      window.open(url);
    } catch (error) {
      GlobalActions.showError(error);
    }
  }


  async onCalculateCampaignUnitScore() {
    const { campaign_id, job_pooling } = this.state
    try {
      const { job_id } = await post(`/api/v1/campaigns/${campaign_id}/campaign_units/calculate_campaign_unit_scores`)
      job_pooling.startPolling(job_id, this.onCalculateScoreComplete.bind(this))
      GlobalActions.showMessage("Calculating campaign units scores")
    } catch (error) {
      GlobalActions.showError("An unexpected error happened when calculating the scores");
    }
  }

  onCalculateScoreComplete(response) {
    const { data } = response
    const { error } = data
    if (error) { return GlobalActions.showError(error) }
    return GlobalActions.showMessage("Campaign units score calculation completed")
  }

  onAddMarketTagToUnitsComplete(response) {
    const { error } = response.data
    if (error) { return GlobalActions.showError(error) }

    const { loadCampaignUnits, params, refreshUnitTags } = this.props
    const { campaign_id } = params;

    refreshUnitTags()
    loadCampaignUnits(campaign_id)

    return GlobalActions.showMessage("Market tags have been created for every campaign unit")
  }

  importPlacemarkers() {
    this.setState({ importPlacemarkers: true });
  }

  importPoisByBrand() {
    this.setState({ importPoisByBrand: true })
  }

  onCloseImportPlacemarkers() {
    this.setState({ importPlacemarkers: false });
  }

  togglePOIs() {
    this.setState({ showPlacesBox: !!!this.state.showPlacesBox })
  }

  onCalculateNearestPOI() {
    const { campaign } = this.props;
    CampaignStore.calculateNearestPlaceMarker(campaign.token);
  }

  toggleProposalSubmissionToolModal() {
    this.setState({ showProposalTool: !!!this.state.showProposalTool })
  }

  toggleTransitLayer() {
    NewMapActions.toggleTransitLayer();
    this.setState({ showTransitRoutes: !!!this.state.showTransitRoutes });
  }

  toggleCustomPOIFormModal() {
    this.setState({showAddCustomPOIForm: true})
  }

  onAddNearbyUnits() {
    this.setState({ showAddNearbyUnitsModal: true });
  }

  onAddUnitsByZip() {
    this.setState({ showAddByZipModal: true });
  }

  onAddCustomPOILogo() {
    this.setState({ showAddCustomPOILogoModal: true });
  }

  onShowTimeline() {
    this.setState({ showTimeline: true })
  }

  onShowMap() {
    this.setState({ showTimeline: false })
  }

  onShowShareModal() {
    this.setState({ showShareModal: true })
  }

  checkFlag() {
    const { feature_flags } = this.props
    const loadingFlag = _.find(feature_flags, flag => flag.name === 'campaign_loading') || false
    return loadingFlag && loadingFlag.status
  }

  renderLoadingState() {
    if (this.state.pageLoading) return true;
    if (this.props.params.page === 'summary') return false;
    const unitsLoaded = campaignUnitsLoaded(this.props.units);
    if (!this.checkFlag()) return !this.props.campaign.bounds || !unitsLoaded;
    return false
  }

  onCheckoutClick() {
    const { campaign } = this.props;
    const eventProperties = {
      'Campaign Name': campaign.name,
      'Campaign Token': campaign.token,
    }

    window.mixpanel.track('Checked Out', eventProperties)
    browserHistory.push(`/campaigns/${campaign.token}/cart`);

  }

  unitPopupParams() {
    return {
      campaign: this.props.campaign,
      user: AuthStore.getState().user,
      campaignId: this.state.campaign_id,
      isCampaignView: true
    }
  }

  setMapbox(mapbox) {
    this.setState({ mapbox: mapbox });
  }

  async renderHighlightedAreasOnMap(highlighted_areas) {
    if (_.isEmpty(highlighted_areas)) {
      MapActions.showDataLayer(null, 'HighlightedArea');
      return;
    }
    const dataLayers = await Promise.all(highlighted_areas.map(async (highlighted_area) => {
      return await get(`/api/v1/data_layers/${highlighted_area.id}`, { campaign_id: this.state.campaign_id });
    }));
    const dataLayer = new HighlightedArea.DataLayer(dataLayers);
    MapActions.showDataLayer(dataLayer, 'HighlightedArea');
  }

  renderFloatingCart() {
    const { campaign } = this.props;
    return (
      <Cart campaignPermissions={campaign.permissions} token={campaign.token} onCheckoutClick={this.onCheckoutClick.bind(this)} />
    )
  }

  renderMap() {
    return (
      <MapLoader
        showLoadingIndicator={true}
        loading={!this.props.campaign.campaign || !this.props.units.all_units}
        campaign={this.props.campaign.campaign}
        units={this.props.units}
        permissions={this.props.campaign.permissions}
        campaign_permissions={this.props.campaign.permissions}
        user={this.state.auth && this.state.auth.user}
        adwords_zip_codes={this.state.adwords_zip_codes}
        onMove={this.onMapMove.bind(this)}
        onMarkerClick={this.onMarkerClick.bind(this)}
        toggleCustomPOIFormModal={this.toggleCustomPOIFormModal.bind(this)}
        showTips={isMobile() ? false : this.state.show_tips}
        timestamp={this.props.units.timestamp}
        filterUnits={this.props.filterUnits}
        rich_pins={true}
        draw_tool={true}
        sidebar_popup={true}
        isCampaignView={true}
        onMapboxCreated={this.setMapbox.bind(this)}
        current_custom_poi={this.props.current_custom_poi}
        updateDataLayers={this.renderCustomBounds.bind(this)}
      />
    )
  }

  renderFiltersBar() {
    const { campaign } = this.props;
    const isMock = (campaign && campaign.campaign && campaign.campaign.is_mock && !!!campaign.campaign.supplier_id);
    if (!campaign.permissions.can_view_filters) return null;
    return <FiltersBar
      onSort={(value) => this.onSort(value)}
      onFilter={this.onFilter.bind(this)}
      activeFilters={this.props.units.unit_filter}
      isMock={isMock}
      isCampaignMap={true}
    />
  }

  renderCampaignRefreshBanner() {
    return <turbo-frame id="campaign-refresh-banner" src={`/api/v1/avails_api/refreshes?campaign_token=${this.props.campaign.token}`} />
  }

  renderSidebar() {
    return (
      <Sidebar
        renderCustomBounds={this.renderCustomBounds.bind(this)}
        all_units_loaded={this.props.campaign.all_units_loaded}
        campaign={this.props.campaign.campaign}
        data={this.props.campaign}
        units={this.props.units}
        loading={this.props.campaign.loading || !this.props.units.loaded}
        flags={this.props.feature_flags}
        sortBy={this.props.units.sort_by}
        onLocationSearch={this.onLocationSearch.bind(this)}
        onSearchClear={this.onSearchClear.bind(this)}
        onUnitListingClick={this.onUnitListingClick.bind(this)}
        onSort={this.onSort.bind(this)}
        onFavoriteUnit={this.onFavoriteUnit.bind(this)}
        onToggleRecommendUnits={this.onToggleRecommendUnits.bind(this)}
        onFilter={this.onFilter.bind(this)}
        onLocalFilter={this.onLocalFilter.bind(this)}
        onFavoriteSelect={this.onFavoriteSelect.bind(this)}
        onAvailabilityFilter={this.onAvailabilityFilter.bind(this)}
        favoritesOnly={this.state.favoritesOnly}
        recommendedOnly={this.state.recommendedOnly}
        onRecommendedFilter={this.onRecommendedFilter.bind(this)}
        filters={this.state.filters}
        onAddAllToCart={this.onAddAllToCart.bind(this)}
        isAddingAllToCart={this.props.cart.isAddingAllToCart}
        tab={this.props.location.query.tab}
        showTips={isMobile() ? false : this.state.show_tips}
        user={AuthStore.getState().user}
        showTimeline={this.state.showTimeline}
        onShowTimeline={this.onShowTimeline.bind(this)}
        onShowMap={this.onShowMap.bind(this)}
        onShowTotal={this.onShowTotal.bind(this)}
        loadCampaignUnits={this.props.loadCampaignUnits}
        loadDemographics={this.props.loadDemographics}
        showShareModal={this.onShowShareModal.bind(this)}
        mapbox={this.state.mapbox}
        showUnitScores={this.isUnitScoreFlagEnabled()}
      />
    )
  }

  switchToAnalyticResults() {
    const { showAnalyticsResults } = this.state;
    this.setState({ showAnalyticsResults: !showAnalyticsResults });
  }

  renderNewAnalytics() {
    const { showAnalyticsResults } = this.state;
    const query_params = queryString.parse(this.props.location.search);
    if (showAnalyticsResults) {
      return <CampaignAnalytics
        permissions={this.props.campaign.permissions}
        campaign={this.props.campaign.campaign}
        direct_responses={this.props.campaign.direct_responses}
        switchToAnalyticResults={this.switchToAnalyticResults.bind(this)}
        step={this.props.params.step}
      />;
    }
    return (
      <NewAnalytics
        permissions={this.props.campaign.permissions}
        campaign={this.props.campaign.campaign}
        direct_responses={this.props.campaign.direct_responses}
        switchToAnalyticResults={this.switchToAnalyticResults.bind(this)}
        query_params={query_params}
        step={this.props.params.step}
      />
    );
  }

  mockWarningMessage() {
    const message = (
      <span>
        <b>Sample inventory</b>
        <span className="detail"> — price ranges are estimates and actual prices may vary</span>
      </span>
    );
    return message;
  }

  isUnitScoreFlagEnabled() {
    const { feature_flags, campaign: { campaign } } = this.props
    const { can_show_scores } = campaign
    const flag = feature_flags.find(f => f.name == 'unit_scores')
    return (flag && flag['status']) || can_show_scores
  }

  render() {
    const { params, campaign, supplier_tasks, units } = this.props;

    const {
      campaign_id, data_layers, unit, _package, page, hideSidebar,
      sidebar, filters, showTimeline, showAddNearbyUnitsModal,
      showAddByZipModal, showAddCustomPOILogoModal, showAddCustomPOIForm,
      showPlacesBox, showProposalTool, showHighlightedAreaModal, showShareModal
    } = this.state;

    const { user } = this.state.auth;
    const { hide_unit_prices } = user.permissions;
    const { is_mock, book_by } = campaign.campaign;

    if (isMobile()) {
      return <MobileCampaign hide_unit_prices={hide_unit_prices} />
    }

    if (is_mock && !this.mockWarningDisplayed) {
      GlobalActions.showMessage(this.mockWarningMessage(), 'warning', true);
      this.mockWarningDisplayed = true;
    }

    // FIXME sidebarUnits as a prop for every component that needs this.props.compaign.units
    this.props.campaign.units = sidebarUnits(this.props.units);

    if (this.renderLoadingState()) {
      return <div className="searchTab__loading" style={{ margin: 'auto', marginTop: 100 }} />
    }

    let current_page = page;
    // if the user is not supposed to see the map, default them to design assets. this case happens for campaign suppliers.
    if (page == '' || (page == 'timeline' && (!!!user.is_managed_supplier && !!!(!!!user.is_unmanaged_supplier && user.is_supplier_contact)))) {
      if (_.get(campaign.permissions, 'can_view_map_tab')) {
        current_page = 'map'
      } else {
        current_page = 'design_assets'
      }
    }

    return (<div className={cs('campaign-tab', 'new-search', 'campaign_timeline', { 'campaign-timeline': showTimeline })}>
      <AddUnitsNearPlacemarkersModal
        campaign={params.campaign_id}
        show={showAddNearbyUnitsModal}
        userPermissions={this.state.auth.user.permissions}
        onHide={() => { this.setState({ showAddNearbyUnitsModal: false }); }}
      />
      <ExportProgress />

      <AddUnitsByZipCodeModal
        campaign={params.campaign_id}
        show={showAddByZipModal}
        userPermissions={this.state.auth.user.permissions}
        onHide={() => { this.setState({ showAddByZipModal: false }); }}
      />

      <AddCustomPOILogoModal
        campaign={params.campaign_id}
        show={showAddCustomPOILogoModal}
        onHide={() => { this.setState({ showAddCustomPOILogoModal: false }); }}
      />

      <AddCustomPOIFormModal
        campaign_id={ params.campaign_id }
        map={ this }
        show={showAddCustomPOIForm}
        onHide={() => { this.setState({ showAddCustomPOIForm: false }); }}
      />

      <PointsOfInterestModal
        show={showPlacesBox}
        onHide={this.togglePOIs.bind(this)}
      />

      <ProposalSubmissionToolModal
        show={showProposalTool}
        onHide={this.toggleProposalSubmissionToolModal.bind(this)}
      />

      <HighlightedArea.Modal
        show={showHighlightedAreaModal}
        campaignId={campaign_id}
        highlightedAreas={HighlightedArea.extractHighlightedAreas(data_layers)}
        onHide={() => {
          if (this.hasDemographicDataLayerSet()) {
            this.unsetDataLayers();
          }
          this.renderCustomBounds();
          this.setState({ showHighlightedAreaModal: false});
        }}
      />

      <Modal
        show={showShareModal}  >
        <Modal.Header>
          <Modal.Title>Share Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Share data={campaign} onHide={() => this.setState({ showShareModal: false })} />
        </Modal.Body>
      </Modal>

      <Modal
        show={this.state.importPlacemarkers}
        onHide={this.onCloseImportPlacemarkers.bind(this)}
      >
        <ImportPlacemarkers
          campaign_id={campaign.token}
          hide={this.onCloseImportPlacemarkers.bind(this)}
        />
      </Modal>

      <Modal
        show={this.state.importPoisByBrand}
        onHide={() => this.setState({ importPoisByBrand: false })}>
        <ImportPoisByBrand
          campaign_id={campaign.token}
          hide={() => this.setState({ importPoisByBrand: false })}
        />
      </Modal>

      <CampaignUnitPopup
        unit={unit}
        _package={_package}
        user={user}
        onPinUnit={this.onPinUnit.bind(this)}
        onToggleRecommendUnit={this.onToggleRecommendUnits.bind(this)}
        onToggleBonusUnit={this.onToggleBonusUnit.bind(this)}
        onClose={this.onCloseUnitModal.bind(this)}
        isCampaignView={true}
      />

      { book_by && campaign.campaignId && <BookByWarning campaign={campaign.campaign} /> }

      {(current_page === "map") && <div className="campaign-tab__body map-tab__body">
        {isMobile() && <div className={cs('campaign-tab__map', { 'expand-map': hideSidebar, 'contract-map': sidebar == true })} style={{ left: 0, height: '100%', position: 'relative' }}>
          {this.renderCampaignRefreshBanner()}
          {this.renderMap()}
        </div>}
        {this.renderFiltersBar()}
        {!isMobile() && <div className={cs('campaign-tab__map', { 'expand-map': hideSidebar, 'contract-map': sidebar == true })}>
          {this.renderCampaignRefreshBanner()}
          {this.renderMap()}
          {this.renderFloatingCart()}
        </div>}
        {!isMobile() && <div className={cs('campaign-tab__side', { 'hide-sidebar': hideSidebar, 'show-sidebar': sidebar == true })}>
          {this.renderSidebar()}
        </div>}
      </div>}
      {current_page == 'review' && <div className="campaign-tab__body">
        <Catalog
          campaign_permissions={campaign.permissions}
          campaign={campaign}
          onFilter={this.onFilter.bind(this)}
          onFavoriteUnit={this.onFavoriteUnit.bind(this)}
          hide_unit_prices={hide_unit_prices}
        />
      </div>}
      {((current_page == 'design_assets' || current_page == 'proof_of_postings') && this.state.auth.user.is_admin) && <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
        <Designs
          campaign_permissions={campaign.permissions}
          campaign={campaign}
          onFilter={this.onFilter.bind(this)}
          step={params.step}
        />
      </div>}
      {current_page == 'analytics' && <div className="campaign-tab__body">
        {this.renderNewAnalytics()}
      </div>}
      {current_page == 'geopath' && <div className="campaign-tab__body">
        <GeopathTable
          campaign={campaign}
        />
      </div>}

      {current_page == 'custom_grids' &&
        <CustomGrids
          campaign={campaign}
          user_permissions={user.permissions}
        />
      }
      {current_page == 'timeline' &&
        <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
          <div className="container">
            <VendorListing
              onUnitListingClick={this.openUnitModal.bind(this)}
              filters={filters}
              isCampaignView={true}
            />
          </div>
        </div>
      }
      {current_page == 'send_campaign_link' && <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
        <div className="container">
          <SendCampaignLink data={campaign} />
        </div>
      </div>}
      {current_page == 'activity_log' && <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
        <div className="container">
          <Activity data={campaign} unitPopup={this.unitPopupParams()} />
        </div>
      </div>}
      {current_page == 'contracts' && <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
        <div className="container">
          <Contracts
            contracts={campaign.contracts}
            campaign_token={campaign.campaign.token}
            campaign_status={campaign.campaign.status}
            user={user}
          />
        </div>
      </div>}
      {current_page == 'summary' && <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
        <Summary
          campaign={{ ...campaign.campaign }}
          units={[...campaign.units]}
          onUnitListingClick={this.onUnitListingClick.bind(this)}
          permissions={campaign.permissions}
        />
      </div>}
      {current_page == 'manage' && <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
        <Manage
          user={user}
          data={campaign}
          campaign={campaign.campaign}
          total_issues_count={campaign.campaign.qa_issue_count}
          campaignPermissions={campaign.permissions}
          updateCampaign={this.updateCampaign.bind(this)}
          supplier_tasks={supplier_tasks}
          step={params.step}
          onUnitListingClick={this.onUnitListingClick.bind(this)}
        />
      </div>}
      {current_page == 'pricing_table' && <PricingTable
        campaign_id={params.campaign_id}
      />}
      {current_page == "attachments" &&
        <div className="campaign-tab__body" style={{ paddingTop: 10 }}>
          <CampaignAttachments
            campaign={campaign.campaign}
            user={user}
            campaign_permissions={campaign.permissions}
          />
        </div>
      }
    </div>);
  }
};

export default connect(
  ({
    feature_flags,
    units,
    campaign,
    adquick_requests_ui: { campaign_requests: { supplier_tasks } },
    campaign_export: { campaign_exporting },
    cart,
    currentUser,
  }) => ({
    campaign,
    units,
    supplier_tasks,
    feature_flags,
    campaign_exporting,
    cart,
    currentUser,
  }),
  {
    fetchSupplierTasks,
    loadCampaign,
    loadCampaignUnits,
    filterUnits,
    sortUnits,
    updateMapPosition,
    favoriteUnits,
    unfavoriteUnits,
    toggleRecommendUnits,
    clearCampaign,
    clearMap,
    setBounds,
    standardExportToExcel,
    exportToPdf,
    exportToPpt,
    loadDemographics,
    addUnitsToCart,
    getCartTotal,
    customExportToExcel,
    refreshUnitTags,
    toggleBonusUnit
  }
)(CampaignTab)
