import Spiderifier from 'mapboxgl-spiderifier';


export class PackageChildrenSpider {
  map: mapboxgl.Map;
  unitClick: any;
  spider: Spiderifier;
  highlithChild: any;
  highlightParent: any;
  removeUnitPopup: any;
  highlightChildrenUnits: any;
  constructor(map : mapboxgl.Map, unitClick, highlightParent, removeUnitPopup, highlightChildrenUnits) {
    this.map = map
    this.unitClick = unitClick
    this.highlightParent = highlightParent
    this.removeUnitPopup = removeUnitPopup
    this.highlightChildrenUnits = highlightChildrenUnits
    this.spider = new Spiderifier(this.map, {
      animate: true,
      animationSpeed: 200,
      onClick: this.onClick.bind(this),
      initializeLeg: this.initializeLeg.bind(this)
    })
  }

  spiderfy(coordinates, markers) {
    this.spider.spiderfy(coordinates, markers)
  }

  onClick(e, marker) {
    let friendlyId
    if (marker) {
      friendlyId = marker.feature.friendlyId
    }
    if (friendlyId) {
      this.unitClick({id: friendlyId});
    }
  }

  initializeLeg(spiderLeg) {
    const pinElem = spiderLeg.elements.pin
    const feature = spiderLeg.feature
    pinElem.style['background-color'] = feature.parentColor;
    pinElem.addEventListener('mouseenter', () => {
      this.highlightParent(feature.parentId)
      this.highlightChildrenUnits(feature.parentId)
    });
    pinElem.addEventListener('mouseleave', () => {
      this.removeUnitPopup();
      if (this.map.getLayer("highlighted-children")) this.map.removeLayer("highlighted-children");
      if (this.map.getLayer("highlighted-unit-markers")) this.map.removeLayer("highlighted-unit-markers");
      if (this.map.getLayer("highlighted-cluster-markers")) this.map.removeLayer("highlighted-cluster-markers");
    });
  }

  close() {
    this.spider.unspiderfy()
  }
}
