import * as React from "react";
import { PureComponent } from "react";
import accounting from 'accounting';

export default class UnitImpressions extends PureComponent {
  constructor(props) {
    super(props)
  }

  renderIcon() {
    const { fromGeopath } = this.props
    if(!fromGeopath) { return }
    return (
      <i
        className="fas fa-map-marker-check"
        title="Geopath"
        style={{
          color: '#65A7Ab',
          fontSize: '16px',
          marginLeft: '5px'
        }}
      />
    )
  }

  render() {
    const { label, impressions } = this.props
    return (
      <div className="infoTab__impressions">
        {label}
        <span className="infoTab__spec-value">
          {" "}{accounting.format(impressions)}
        </span>
        {this.renderIcon()}
      </div>
    )
  }
}