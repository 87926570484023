import React, { Component } from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import Fuse from 'fuse.js';
import { isMobile } from '../../../utils/mobile';
import { loadSupplierOptions } from "../../../actions/supplier_actions";
const POPUP_WIDTH = 220;

class Supplier extends Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.debouncedSearch = _.debounce(this.searchBySupplierName, 200);
    this.onClear = this.onClear.bind(this);

    this.state = {
      isExpanded: false,
      activeSupplier: null,
      narrowedList: null,
    }
  }

  componentDidMount() {
    const { loadSupplierOptions } = this.props;
    document.addEventListener("click", this.onToggle);
    loadSupplierOptions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.supplier === null) {
      this.setState({
        activeSupplier: null
      });
    }

    const { suppliers } = this.props;
    if (!!!suppliers && nextProps.suppliers && nextProps.suppliers.length > 0) {
      const filters = this.props.filters || {};
      const selected = nextProps.suppliers.filter((s) => {
        return s.id === filters.supplier;
      });
      if (selected.length > 0) {
        this.setState({ activeSupplier: selected[0] });
      };
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  searchBySupplierName(query) {
    var options = {
      keys: ['label'],
      minMatchCharLength: 3,
      threshold: 0.4,
      maxPatternLength: 20,
    }
    const fuse = new Fuse(this.props.suppliers, options)
    this.setState({ narrowedList: fuse.search(query) })
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onClear() {
    this.setState({ activeSupplier: null }, this.onFilter);
  }

  onSupplierSelect(supplier) {
    const { activeSupplier } = this.state;
    if (activeSupplier === supplier) {
      this.setState({ activeSupplier: null}, this.onFilter);
    } else {
      this.setState({ activeSupplier: supplier }, this.onFilter);
    }
  }

  onFilter() {
    const { activeSupplier } = this.state;
    const { setFilter } = this.props;
    const supplier = !activeSupplier ? null : activeSupplier.value;
    this.setState({ isExpanded: false }, () => {
      setFilter({ 'supplier': supplier });
    });
  }

  getButtonLabel() {
    const { activeSupplier } = this.state;
    if (!activeSupplier) return 'Vendor';
    return (
      _.truncate(activeSupplier.label, {length: 18})
    )
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { activeSupplier, narrowedList } = this.state;
    const { suppliers } = this.props;
    const suppliersList = narrowedList && narrowedList.length ? narrowedList : suppliers;

    const clearStyle = {
      float: 'right',
      top: -32,
      paddingRight: 12,
      position: 'relative'
    };

    return (
      <div className="filter_popup suppliers_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>VENDOR</h4>
        <a style={clearStyle} onClick={this.onClear}>Clear</a>
        <input
          autoFocus={true}
          type="text"
          className="narrow_list"
          onChange={(e) => this.debouncedSearch(e.target.value)}
          placeholder="Filter by name"
        />
        <ul>
          {suppliersList.map((supplier) => {
            const onClick = () => {
              this.onSupplierSelect(supplier);
            }
            const isActive = activeSupplier === supplier;
            return <li
              key={supplier.value}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {_.truncate(supplier.label, {length: 18})}
                {supplier.count && <i className="count">({supplier.count})</i>}
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { isExpanded, activeSupplier } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs({active: activeSupplier || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};

const mapStateToProps = ({ suppliers }) => ({ suppliers });
export default connect(
  mapStateToProps,
  { loadSupplierOptions }
 )(Supplier)
