import React from 'react';
import createClass from 'create-react-class';
import GlobalActions from '../../../actions/GlobalActions'
import { browserHistory } from 'react-router-legacy';
import { post } from '../../../utils/api'

const NewCompanyGroup = createClass({
  displayName: 'NewCompanyGroup',

  getInitialState() {
    return {
      name: '',
      csv_file: null,
      saving: false,
      errors: []
    }
  },

  async postCompanyGroup(payload) {
    const response = await post('/api/v1/company_groups', payload)
    Promise.resolve(response).then(() => {
      GlobalActions.showMessage("Added company group.")
      browserHistory.push('/businesses')
    })
  },

  onSubmit(e) {
    e.preventDefault()
    const { name, csv_file } = this.state
    if (!csv_file) { return this.postCompanyGroup({ name })}
    this.submitFile()
  },

  handleSelectedFile(e) {
    this.setState({
      csv_file: e.target.files[0]
    })
  },

  submitFile() {
    const { name } = this.state
    const reader = this.handleFileEncode()
    reader.addEventListener("load", () => {
      const file = reader.result
      this.postCompanyGroup({ name, file })
    }, false)
  },

  handleFileEncode() {
    const { csv_file } = this.state
    const reader = new FileReader()
    reader.readAsDataURL(csv_file)
    return reader
  },

  render() {
    const { name, saving } = this.state

    return (
      <div className="container create-campaign">
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <b>New Group</b>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="section">
                <div className="row subsection">
                  <div className="col-sm-8">
                    <span className="section-name">Group Name</span>
                    <input type="text"
                      className="form-control"
                      value={name}
                      onChange={e => this.setState({ name: e.target.value })} />
                  </div>
                  <div className="col-sm-4">
                    <span className="section-name">CSV File</span>
                    <input type="file"
                      onChange={this.handleSelectedFile} />
                  </div>
                </div>
              </div>
              <div className="row subsection">
                <div className="col-sm-12">
                  <input type="submit"
                    className="btn btn-primary btn-submit"
                    disabled={saving || !name}
                    value="Save Company Group" />
                </div>
                <div className="col-sm-12">
                  <button className="btn btn-default btn-submit" onClick={() => browserHistory.push('/businesses')}>Go back</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
})

export default NewCompanyGroup;
