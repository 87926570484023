import React from 'react';
import { connect } from 'react-redux';
import Listing from './Listing';
import LocationInput from './LocationInput';
import MarketOverview from '../MarketOverview';
import VendorBranding from '../VendorBranding';

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.pageSize = 10;
    this.state = {
      data: this.props.data || {},
      limit: this.pageSize,
    };
  }

  componentDidMount() {
    $('.main__side').on('scroll', this.trackScrolling.bind(this));
  }

  componentWillUnmount() {
    $('.main__side').off('scroll', this.trackScrolling.bind(this));
  }

  isBottom(el) {
    return Math.round(el.getBoundingClientRect().bottom) <= Math.round(window.innerHeight);
  }

  trackScrolling(event) {
    const wrappedElement = document.getElementById('browse-sidebar');
    if (this.isBottom(wrappedElement)) {
      this.setState({ limit: this.state.limit + this.pageSize });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data, loading: nextProps.loading });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bounds != this.props.bounds) {
      this.setState({ limit: this.pageSize });
    }
  }

  onLocationSearch(params) {
    if (this.props.onLocationSearch) this.props.onLocationSearch(params);
  }

  render() {
    const { data, user } = this.props;
    const { supplier, units_count } = data;

    return (<div>
      <div style={{ borderBottom: "2px solid #eee" }}>
        {supplier && <div style={{ borderBottom: '1px solid #E2E2E2' }}>
          <VendorBranding supplier={supplier} />
        </div>}
        <LocationInput
          onSearch={this.onLocationSearch.bind(this)}
          placeholder="Search by city, state, or zip"
          onFaceIdSearch={this.props.onFaceIdSearch}
          value={this.props.query}
          mapbox={this.props.mapbox}
        />
        <MarketOverview
          user={this.props.user}
          isSearchView={true} />
      </div>
      <div id="browse-sidebar">
        {this.state.loading ? <div className="searchTab__loading" style={{ margin: 'auto', marginTop: 100 }} /> : <div>
          <Listing
            limit={this.state.limit}
            data={this.props.data}
            units={this.props.units}
            bounds={this.props.bounds}
            onUnitListingClick={this.props.onUnitListingClick}
            isSearchView={true}
            isPublicView={this.props.isPublicView}
            isAgencyView={this.props.isAgencyView}
          />
          {this.props.onLoadMore && <div style={{padding:20, textAlign:'center'}} onClick={this.props.onLoadMore}>
            {this.props.isLoadingMore && "Loading More..."}
          </div>}
        </div>}
      </div>
      {/* <div className="visible-xs" style={{padding:30,textAlign:'center'}}>
        <div style={{fontWeight:'bold',marginBottom:20}}>
          <i className="fa fa-mobile" /> AdQuick Mobile Beta
        </div>
        <div>
          Open AdQuick in your desktop browser for our full browsing experience
        </div>
      </div>*/}
    </div>);
  }
}

export default connect(({ map }) => ({ bounds: map.bounds }))(Sidebar);

