import React from "react";
import Select from "react-select";
import GlobalActions from "../../actions/GlobalActions";
import { connect } from "react-redux";
import { addContract } from "../Inventory/actions";
import { browserHistory } from 'react-router-legacy';
import ContractConfirmation from '../ContractConfirmation';

class VendorContractForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contract: {
        title: null,
        message: null,
        customer_name: null,
        signer_organization: null,
        signer_email: null,
        signature_request: false
      },
      billing_address: null,
      contract_templates: [],
      contract_template: null,
      saving: false,
      contracts_loaded: false,
      is_counter_signer: false,
      show_confirmation: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleConfirmationClick = this.handleConfirmationClick.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const can_send_counter_signature = _.get(user, 'permissions.can_send_counter_signature');
    this.setState({is_counter_signer: can_send_counter_signature});
  }

  async componentWillMount() {
    const { user } = this.props;
    if (user && user.is_agency) {
      const res = await $.get(`/api/v1/contract_templates/all_templates`);
      if (res.status == 200) {
        const { contract_templates } = res.data;
        this.setState({ contract_templates, contracts_loaded: true });
      }
    } else {
      const res = await $.get(`/api/v1/contract_templates/all_templates`);
      if (res.status == 200) {
        const { contract_templates } = res;
        this.setState({ contract_templates, contracts_loaded: true });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { added_contract_id } = this.props;
    if (!!!added_contract_id && nextProps.added_contract_id) {
      const { campaign_token } = this.props;
      browserHistory.push(`/campaigns/${campaign_token}/contracts`);
      GlobalActions.showMessage("Successfully added contract");
      GlobalActions.closePopup();
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { campaign_token, addContract, all_selected, unit_tokens, user } = this.props;
    const {
      customer_name,
      signer_organization,
      contract_template,
      title,
      message,
      signer_email,
      signature_request,
      billing_address,
      is_counter_signer,
    } = this.state;
    if (!!!/^\S+@\S+$/.test(signer_email))
      return GlobalActions.showError("Please enter valid email");
    const cu_ids = all_selected ? null : unit_tokens.join(',');
    const contract_template_id = user.is_admin ? 36 : contract_template.value;
    const params = {
      campaign_token,
      contract: {
        title,
        message,
        signer_email,
        customer_name,
        signer_organization,
        signer_name: customer_name,
        contract_template_id: contract_template_id,
        signature_request,
        cu_ids,
      },
      billing_address,
      is_counter_signer,
    };
    this.setState({ saving: true }, async () => {
      await addContract(params);
      this.setState({ saving: false });
    });
  }

  onSave(e) {
    e.preventDefault();
    this.setState({ show_confirmation: true });
  }

  handleConfirmationClick() {
    this.setState({ show_confirmation: !this.state.show_confirmation });
  }

  render() {
    const { serverErrors, user } = this.props;
    const {
      contract_template,
      contract_templates,
      contracts_loaded,
      saving,
      signature_request,
      is_counter_signer,
      show_confirmation,
    } = this.state;

    const options = contract_templates.map(c =>({label: c.name, value: c.id }));
    const hide_select = user.is_admin ? { display: "none" } : {};
    const show_confirmation_modal = is_counter_signer && show_confirmation;

    if (!!!contract_templates.length && contracts_loaded)
      return (
        <div>
          <h5>Please contact AdQuick to setup your contract terms and conditions</h5>
        </div>
      );

    return (
      <div className="login__form">
        {serverErrors && (
          <div className="row">
            <div className="col-sm-12" style={{ color: "red", padding: 15, paddingTop: 0 }}>
              {serverErrors.map(error => (
                <div>{error}</div>
              ))}
            </div>
          </div>
        )}
        <form className="form">
          <div className="form-group row" style={hide_select}>
            <div className="col-sm-12">
              <label className="col-2 col-form-label">Select Contract Template</label>
              <div className="col-10">
                <Select
                  options={options}
                  value={contract_template}
                  onChange={contract_template => this.setState({ contract_template })}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Contract Name</label>
              <div className="col-10">
                <input className="form-control" onChange={e => this.setState({ title: e.target.value })} />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Customer Name</label>
              <div className="col-10">
                <input className="form-control" onChange={e => this.setState({ customer_name: e.target.value })} />
              </div>
            </div>
          </div>
          {!this.state.is_counter_signer && (
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Customer Organization</label>
                <div className="col-10">
                  <input
                    className="form-control"
                    onChange={e => this.setState({ signer_organization: e.target.value })}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Customer Email</label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="email"
                  onChange={e => this.setState({ signer_email: e.target.value })}
                />
              </div>
            </div>
          </div>
          {!this.state.is_counter_signer && (
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Billing Address</label>
                <div className="col-10">
                  <textarea
                    className="form-control"
                    onChange={e => this.setState({ billing_address: e.target.value })}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="form-group row">
            <div className="col-sm-12">
              <div className="col-xs-5" style={{ paddingTop: "10px" }}>
                <label>Send Digital Signature Request</label>
              </div>
              <div className="col-xs-1">
                <input
                  className="form-control"
                  type="checkbox"
                  defaultChecked={signature_request}
                  onChange={e => this.setState({ signature_request: e.target.checked })}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Notes</label>
              <div className="col-10">
                <textarea className="form-control" onChange={e => this.setState({ message: e.target.value })} />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <button
                className="btn btn_primary register__submit"
                disabled={!!!this.state.title || saving}
                onClick={is_counter_signer ? this.onSave : this.handleSubmit}
              >
                {saving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </form>
        {is_counter_signer && (
          <ContractConfirmation
            handleConfirmationClick={this.handleConfirmationClick}
            show_confirmation={show_confirmation}
            onSubmit={this.handleSubmit}
            type={"client_contract"}
          />
        )}
      </div>
    );
  }
};

export default connect(
  ({ inventory }) => ({ added_contract_id: inventory.added_contract_id }),
  { addContract }
)(VendorContractForm);
