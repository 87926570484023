import React from 'react';
import createClass from 'create-react-class';
import Listings from '../Timeline/Listings';
import GlobalActions from '../../actions/GlobalActions'
import { connect } from 'react-redux';

class Timeline extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      units,
      filters,
      selectedUnitIds,
      onUnitSelect,
      onUnitSelectAll,
      onShowEditFlightModal,
      periodForecast
    } = this.props;

    return (
      <div>
        <Listings
          editable={true}
          clickable={(currentUser && currentUser.is_supplier_contact) ? false : true}
          data={{ units }}
          filters={filters || {}}
          onUnitListingClick={() => { }}
          onUnitDetailsClick={unitId => GlobalActions.openUnitID(unitId)}
          isInventoryView
          selectedUnitIds={selectedUnitIds}
          onUnitSelect={onUnitSelect}
          onUnitSelectAll={onUnitSelectAll}
          onShowEditFlightModal={onShowEditFlightModal}
          periodForecast={periodForecast} />
      </div>
    )
  }
}

export default connect(
  ({ currentUser }) => ({ currentUser }), {}
)(Timeline)
