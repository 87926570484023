import React, { Component } from 'react';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import {
  fetchUnitTagOptions,
  fetchUnitTags,
  createUnitTags,
  clearUnitTags,
  createTag,
  deleteUnitTag
} from './actions';
import isEmpty from 'lodash/isEmpty';

class Tags extends Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      selected_tags: this.props.unit_tags,
    };
  }

  async componentDidMount() {
    const { unit_id, fetchUnitTags, fetchUnitTagOptions } = this.props;
    await fetchUnitTagOptions();
    await fetchUnitTags(unit_id);
  }

  componentWillUnmount() {
    const { clearUnitTags } = this.props;
    clearUnitTags();
  }

  renderLoadingSpinner() {
    const { tags_loading } = this.props;
    if (!tags_loading) {
      return;
    }
    return (
      <div className="loading-spinner">
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    const { unit_tags } = this.props;
    if (prevProps.unit_tags != unit_tags) {
      this.setState({ selected_tags: unit_tags });
    }
  }

  onTagChange(tags) {
    const { selected_tags } = this.state;
    this.setState({
      selected_tags: [...selected_tags, ...tags]
    }, () => this.createUnitTags());
  }

  tagOptions() {
    const unit_tag_options = this.props.unit_tag_options || [];
    const unitTagIds = this.props.unit_tags.map(o => o.value);
    const options = unit_tag_options.filter(o => !unitTagIds.includes(o.value));
    return options;
  }

  async onTagCreate(tag) {
    const { createTag } = this.props;
    await createTag(tag);
  }

  async onTagRemove(e, tag_id) {
    const { unit_id, deleteUnitTag } = this.props;
    await deleteUnitTag(unit_id, tag_id);
  }

  createUnitTags() {
    const { unit_id, tags_loading, createUnitTags } = this.props;
    const { selected_tags } = this.state;
    if (tags_loading) {
      return;
    }
    return createUnitTags(unit_id, selected_tags);
  }

  async onSearch(text) {
    const options = this.tagOptions();
    const match = options.filter(o => o.label.toLocaleLowerCase().includes(text.toLocaleLowerCase()) );
    return match;
  }

  renderSelectedTags() {
    const { selected_tags } = this.state;
    if (isEmpty(selected_tags)) { return; }
    return (
      <div>
        <h5
          style={{color: "#4A90E2"}}
        >
          Selected Unit Attributes
        </h5>
        <div style={{
          display: "flex",
          flexWrap: "wrap"
        }}>
          {selected_tags.map((tag, idx) => {
            return (
              <div
                className="selected-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "rgba(74, 144, 226, 0.06)",
                  borderRadius: "4px",
                  color: "#4A90E2",
                  padding: "5px 0 5px 10px",
                  margin: "3px 5px"
                }}
                key={`tag-${idx}`}
              >
                <div>{tag.label}</div>
                <div
                  onClick={(e) => this.onTagRemove(e, tag.value)}
                  style={{
                  padding: "0 12px",
                  fontWeight: "bolder",
                  cursor: "pointer"
                }}
                >
                  x
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }

  render() {
    const unit_tag_options = this.tagOptions();
    return (
      <div id="unit-tags">
        <div className="header-section">
          <div className="header">
            <label>Unit Attributes</label>
          </div>
          {this.renderLoadingSpinner()}
        </div>
        <AsyncCreatableSelect
          className="selectInput"
          isClearable={false}
          placeholder="Select Unit Attributes"
          isMulti
          onChange={this.onTagChange.bind(this)}
          onCreateOption={this.onTagCreate.bind(this)}
          loadOptions={this.onSearch.bind(this)}
          defaultOptions={unit_tag_options}
          styles={{
            multiValue: (base) => ({ ...base, display: "none" })
          }}
        />
        {this.renderSelectedTags()}
      </div>
    );
  }
}

export default connect(
  ({
    // @ts-ignore
    inventory: { tags_loading, unit_tags, unit_tag_options },
  }) => ({
    tags_loading,
    unit_tags,
    unit_tag_options,
  }),
  {
    fetchUnitTagOptions,
    fetchUnitTags,
    createUnitTags,
    clearUnitTags,
    createTag,
    deleteUnitTag
  }
)(Tags);
