import "./NewCustomGridModal.css";

import { Button, Loading, Modal, ModalBody, ModalFooter } from "@adquick/ui";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

interface Props {
  showNewCustomGridModal: boolean;
  campaign: any;
  onCreateNewCustomGrid: ({ spreadsheet_url, id, job_id }) => void;
  onCancelNewCustomGrid: () => void;
}

interface ExportTemplate {
  id: number;
  name: string;
  column_map: string[][];
}

/**
 * Represents the selected columns to be exported, per user input in the modal body, e.g.:
 *  {
 *    face_id:    { enable: false, header: "Face ID"},
 *    start_date: { enable: true,  header: "Start Date"},
 *    end_date:   { enable: true,  header: "End Date"},
 *    token:      { enable: true,  header: "Campaign Unit Token"},
 *  }
 */
interface ColumnMap {
  [key: string]: {
    enabled: boolean;
    header: string;
  };
}

/**
 * The general idea of having this ADT is to reduce a list of ExportTemplates into this dictionary, e.g.:
 *  {
 *    template1: {
 *      face_id:    { enable: false, header: "Face ID"},
 *      start_date: { enable: true,  header: "Start Date"},
 *      end_date:   { enable: true,  header: "End Date"},
 *      token:      { enable: true,  header: "Campaign Unit Token"},
 *    }
 *    template2: {
 *      face_id:    { enable: true,  header: "Face ID"},
 *      start_date: { enable: false, header: "Start Date"},
 *      end_date:   { enable: false, header: "End Date"},
 *      token:      { enable: true,  header: "Campaign Unit Token"},
 *    }
 *  }
 */
interface ExportColumnMap {
  [name: string]: ColumnMap;
}

interface State {
  show: boolean;
  showLoading: boolean;

  exportTemplateName: string;

  customGridName: string;
  exportColumnMap: ExportColumnMap;
}

class NewCustomGridModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { name } = this.props.campaign;

    this.state = {
      show: this.props.showNewCustomGridModal,
      showLoading: false,

      exportTemplateName: "AdQuick Standard Export",

      customGridName: name,
      exportColumnMap: {},
    };

    this.onSelectExportTemplate = this.onSelectExportTemplate.bind(this);

    this.onChangeCustomGridName = this.onChangeCustomGridName.bind(this);
    this.onChangeKeyCheck = this.onChangeKeyCheck.bind(this);
    this.onChangeColumnHeader = this.onChangeColumnHeader.bind(this);

    this.onCancel = this.onCancel.bind(this);
    this.onExport = this.onExport.bind(this);
  }

  componentDidMount(): void {
    $.get(`/api/v1/export_templates/?column_map=true&created_by=true`)
      .done((exportTemplates: ExportTemplate[]) => {
        const exportColumnMap: ExportColumnMap = exportTemplates.reduce((exportColumnMap, exportTemplate) => {
          return {
            ...exportColumnMap,
            ...this.exportTemplateToExportColumpMap(exportTemplate),
          };
        }, {});

        this.setState({ exportColumnMap });
      })
      .always(() => {
        this.setState({ showLoading: false });
      });

    this.setState({ showLoading: true });
  }

  /**
   * Helper function to convert ExportTemplate into ExportColumnMap
   */
  exportTemplateToExportColumpMap(exportTemplate: ExportTemplate): ExportColumnMap {
    const exportColumnMap: ExportColumnMap = {};

    if (!!!exportTemplate.column_map.find(([_, key]) => key == "token")) {
      return exportColumnMap;
    }

    const column_map = exportTemplate.column_map.reduce((columnMap: {}, [header, key]) => {
      columnMap[key] = {
        header,
        enabled: true,
      };
      return columnMap;
    }, {});

    exportColumnMap[exportTemplate.name] = column_map;
    return exportColumnMap;
  }

  onSelectExportTemplate(event): void {
    const exportTemplateName = event.target.value;

    this.setState({ exportTemplateName });
  }

  onChangeCustomGridName(name): void {
    this.setState({ customGridName: name });
  }

  onChangeKeyCheck(key, enabled): void {
    const { exportTemplateName } = this.state;
    const exportColumnMap = {
      ...this.state.exportColumnMap,
    };
    exportColumnMap[exportTemplateName][key].enabled = enabled;

    this.setState({ exportColumnMap });
  }

  onChangeColumnHeader(key, header): void {
    const exportColumnMap = {
      ...this.state.exportColumnMap,
    };
    exportColumnMap[this.state.exportTemplateName][key].header = header;

    this.setState({ exportColumnMap: exportColumnMap });
  }

  onCancel(): void {
    this.props.onCancelNewCustomGrid();
  }

  onExport(): void {
    const campaignId = this.props.campaign.id;
    const { customGridName, exportTemplateName, exportColumnMap } = this.state;
    const column_map = exportColumnMap[exportTemplateName];

    const headers = {};
    const values = {};
    let idx = 0;
    _.forEach(column_map, ({ header, enabled }, key) => {
      if (enabled) {
        headers[idx] = header;
        values[idx] = key;
      }
      idx++;
    });

    const exportTemplate = {
      export_template: {
        name: customGridName,
        column_map: {
          header: headers,
          value: values,
        },
      },
    };
    $.post(`/api/v1/export_templates`, exportTemplate)
      .done(({ id }) => {
        $.post(`/api/v1/campaigns/${campaignId}/campaign_google_sheets/?export_template_id=${id}`)
          .done(this.props.onCreateNewCustomGrid)
          .fail(this.props.onCancelNewCustomGrid);
      })
      .fail(this.props.onCancelNewCustomGrid);

    this.setState({ showLoading: true });
  }

  render(): JSX.Element {
    const { show, exportTemplateName, showLoading, exportColumnMap } = this.state;

    const exportTemplateNames = _.map(exportColumnMap, (v, k) => k);
    const column_map = exportColumnMap[exportTemplateName];

    return (
      <Modal show={show} backdrop onBackdropClick={this.onCancel}>
        <ModalBody>
          {showLoading ? (
            <div className="customgrids__modal__loading">
              <Loading />
            </div>
          ) : (
            <div>
              <div className="customgrids__modal__name">
                <label htmlFor="customgrid_name">Custom grid name:</label>
                <input
                  id="customgrid_name"
                  type="text"
                  defaultValue={this.state.customGridName}
                  onChange={({ target: { value } }) => this.onChangeCustomGridName(value)}
                />
              </div>
              <hr />
              <div className="customgrids__modal__templates__selector">
                <label htmlFor="export_templates">Export Template:</label>
                <select id="export_templates" className="form-control" onChange={this.onSelectExportTemplate}>
                  {exportTemplateNames.map(exportTemplateName => (
                    <option
                      key={exportTemplateName}
                      value={exportTemplateName}
                      selected={exportTemplateName == this.state.exportTemplateName}
                    >
                      {exportTemplateName}
                    </option>
                  ))}
                </select>
              </div>
              <hr />
              <ul className="customgrids__modal__column_map">
                {_.map(column_map, ({ header, enabled }, key) => {
                  return (
                    <li key={key}>
                      <span>
                        <input
                          type="checkbox"
                          id={key}
                          disabled={key == "token"}
                          name={key}
                          checked={enabled}
                          onChange={({ target: { checked } }) => this.onChangeKeyCheck(key, checked)}
                        />
                        <label htmlFor={key}>{key}</label>
                      </span>
                      <span>
                        <input
                          type="text"
                          id={key}
                          value={header}
                          onChange={({ target: { value } }) => this.onChangeColumnHeader(key, value)}
                        />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </ModalBody>
        {!showLoading && (
          <ModalFooter>
            <div className="customgrids__modal__footer">
              <Button onClick={this.onExport}>Create</Button>
            </div>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default connect()(NewCustomGridModal);
