import { del, get, post, put } from "../utils/api2";
import GlobalActions from "./GlobalActions";

export const LOAD_CAMPAIGN_DESIGNS = "marketplace/campaign/designs";
export const loadCampaignDesigns = campaign_id => async (dispatch, getState) => {
  try {
    const designs = await get(`/api/v1/campaigns/${campaign_id}/design_assets`);
    dispatch({ type: LOAD_CAMPAIGN_DESIGNS, payload: designs });

    return designs;
  } catch (error) {
    GlobalActions.showError("There has been an error while loading designs for the campaign.");
    throw error;
  }
};

export const uploadDesignAsset = (campaign_id, units, upload, asset_id) => async (dispatch, getState) => {
  try {
    const params = {
      units: units.map(unit => unit.campaign_unit_token),
      upload: upload,
      asset_id,
      upload_type: "design_asset",
    };
    return await post(`/api/v1/campaigns/${campaign_id}/design_assets/upload`, params);
  } catch (error) {
    const {
      data: { message },
    } = error;
    GlobalActions.showError(message);
    throw error;
  }
};

export const APPROVE_DESIGN_ASSET = "marketplace/campaign/designs/approve";
export const approveDesignAsset = (campaign_id, design_asset_id) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/${design_asset_id}/approve`);
  if (response.status == 200) {
    const { design_asset } = response;
    dispatch({ type: APPROVE_DESIGN_ASSET, payload: design_asset });
    dispatch(loadCampaignDesigns(campaign_id));
  }
};

export const bulkApproveDesignAssets = (campaign_id, design_asset_ids) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_approve`, { design_asset_ids });
  if (response.status == 200) {
    const { design_assets } = response;
    design_assets.forEach(design_asset => {
      dispatch({ type: APPROVE_DESIGN_ASSET, payload: design_asset });
      dispatch(loadCampaignDesigns(campaign_id));
    });
  }
};

export const REJECT_DESIGN_ASSET = "marketplace/campaign/designs/reject";
export const rejectDesignAsset = (campaign_id, design_asset_id, reason) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/${design_asset_id}/reject`, { reason });
  if (response.status == 200) {
    const { design_asset } = response;
    dispatch({ type: REJECT_DESIGN_ASSET, payload: design_asset });
    dispatch(loadCampaignDesigns(campaign_id));
  }
};

export const bulkRejectDesignAssets = (campaign_id, design_asset_ids, reason) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_reject`, {
    design_asset_ids,
    reason,
  });
  if (response.status == 200) {
    const { design_assets } = response;
    design_assets.forEach(design_asset => {
      dispatch({ type: REJECT_DESIGN_ASSET, payload: design_asset });
      dispatch(loadCampaignDesigns(campaign_id));
    });
  }
};

export const DELETE_DESIGN_ASSET = "marketplace/campaign/designs/delete";
export const deleteDesignAsset = (campaign_id, design_asset_id) => async (dispatch, getState) => {
  try {
    const response = await del(`/api/v1/campaigns/${campaign_id}/design_assets/${design_asset_id}`);
    const { design_asset } = response;
    dispatch({ type: DELETE_DESIGN_ASSET, payload: design_asset });
    dispatch(loadCampaignDesigns(campaign_id));

    GlobalActions.showMessage("Design Asset deleted.");
  } catch (error) {
    const {
      data: { message },
    } = error;
    GlobalActions.showError(message);
    throw error;
  }
};

export const ADD_POSTING_NOTE = "marketplace/campaign/designs/add_posting_note";
export const bulkAddPostingNote = (campaign_id, campaign_unit_ids, note) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_add_posting_note`, {
    campaign_unit_ids,
    note,
  });
  if (response.status == 200) {
    dispatch({ type: ADD_POSTING_NOTE, payload: response });
  }
};

export const EXPORT_TO_PRODUCTION_GRID = "marketplace/campaign/designs/export_to_production_grid";
export const exportToProductionGrid = (campaign_id, campaign_unit_ids) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/export_to_production_grid`, {
    campaign_unit_ids,
  });
  return response.job_id;
};

export const REMOVE_POSTING_NOTE = "marketplace/campaign/designs/remove_posting_note";
export const bulkRemovePostingNote = (campaign_id, campaign_unit_ids) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_remove_posting_note`, {
    campaign_unit_ids,
  });
  if (response.status == 200) {
    dispatch({ type: REMOVE_POSTING_NOTE, payload: response });
  }
};

export const UPDATE_TAKE_DOWN_DATES = "marketplace/campaign/designs/update_take_down_dates";
export const updateTakedownDates = (campaign_id, campaign_unit_ids, take_down_date) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_update_take_down_dates`, {
    campaign_unit_ids,
    take_down_date,
  });
  if (response.status == 200) {
    dispatch({ type: UPDATE_TAKE_DOWN_DATES, payload: response });
  }
};

export const MARK_STILL_POSTED = "marketplace/campaign/designs/mark_still_posted";
export const markStillPosted = (campaign_id, campaign_unit_ids) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_mark_still_posted`, {
    campaign_unit_ids,
  });
  if (response.status == 200) {
    dispatch({ type: MARK_STILL_POSTED, payload: response });
  }
};

export const REMOVE_PRODUCTION_MARKER = "marketplace/campaign/designs/remove_production_marker";
export const bulkRemoveProductionMarker = (campaign_id, design_asset_ids) => async (dispatch, getState) => {
  const response = await $.post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_remove_production_marker`, {
    design_asset_ids,
  });
  if (response.status == 200) {
    const { design_assets } = response;
    design_assets.forEach(design_asset => {
      dispatch({ type: REMOVE_PRODUCTION_MARKER, payload: design_asset });
      dispatch(loadCampaignDesigns(campaign_id));
    });
  }
};

export const LOAD_CAMPAIGN_POPS = "marketplace/campaign/pops";
export const loadCampaignPops = campaign_id => async (dispatch, getState) => {
  try {
    const pops = await get(`/api/v1/campaigns/${campaign_id}/proof_of_postings`);
    dispatch({ type: LOAD_CAMPAIGN_POPS, payload: pops });

    return pops;
  } catch (error) {
    GlobalActions.showError("There has been an error while loading pops for the campaign.");
    throw error;
  }
};

export const UPLOAD_POP = "marketplace/campaign/designs/upload_poop";
export const uploadPop = (campaign_id, units, upload, posted_date) => async (dispatch, getState) => {
  const params = {
    upload,
    units: units.map(unit => unit.campaign_unit_token),
    posted_date,
    upload_type: "pop",
  };
  const response = await post(`/api/v1/campaigns/${campaign_id}/proof_of_postings`, params);
  if (response.status == 200) {
    const { pops } = response;
    dispatch({ type: UPLOAD_POP, payload: pops });
    dispatch(loadCampaignDesigns(campaign_id));
    dispatch(loadCampaignPops(campaign_id));
  }
};

export const DELETE_POP = "marketplace/campaign/pops/delete";
export const deletePop = (campaign_id, pop_id) => async (dispatch, getState) => {
  const response = await del(`/api/v1/campaigns/${campaign_id}/proof_of_postings/${pop_id}`);
  try {
    const { pop } = response;
    dispatch({ type: DELETE_POP, payload: pop });
    dispatch(loadCampaignDesigns(campaign_id));
    dispatch(loadCampaignPops(campaign_id));

    GlobalActions.showMessage("Proof of Posting deleted.");
  } catch (error) {
    GlobalActions.showError("There has been an error deleting the proof of posting.");
    throw error;
  }
};

export const UPDATE_SHOW_POP_ON_OVERVIEW = "marketplace/campaign/pops/show";
export const updateShowPopOnOverview = (campaign_id, pop_id, show_pop_on_overview) => async (dispatch, getState) => {
  const response = await post(
    `/api/v1/campaigns/${campaign_id}/proof_of_postings/${pop_id}/update_show_pop_on_overview`,
    { show_pop_on_overview: show_pop_on_overview },
  );
  const message = show_pop_on_overview ? "be shown on Overview" : "will not be shown on overview";
  try {
    const { pop } = response;
    dispatch({ type: UPDATE_SHOW_POP_ON_OVERVIEW, payload: pop });
    dispatch(loadCampaignDesigns(campaign_id));
    dispatch(loadCampaignPops(campaign_id));
    GlobalActions.showMessage(`Proof of Posting will ${message}`);
  } catch (error) {
    GlobalActions.showError("There has been an error updating the proof of posting.");
    throw error;
  }
};

export const SEND_TO_PRODUCTION = "marketplace/campaign/designs/send_to_production";
export const sendToProduction = (campaign_token, design_asset_ids) => async (dispatch, getState) => {
  try {
    const payload = { campaign_token, design_asset_ids };
    const production_orders = await post(`/api/v1/production_orders`, payload);

    dispatch({
      type: SEND_TO_PRODUCTION,
      payload: { design_asset_ids, production_orders },
    });
    dispatch(loadCampaignDesigns(campaign_token));

    GlobalActions.showMessage("Production Order(s) created.");
    return production_orders;
  } catch (error) {
    GlobalActions.showError("There has been an error while sending the design asset to prodution.");
    throw error;
  }
};

export const FETCH_PRODUCTION_ORDERS = "marketplace/campaign/designs/fetch_production_orders";
export const fetchProductionOrders = campaign_token => async dispatch => {
  try {
    const orders = await get(`/api/v1/campaigns/${campaign_token}/production_orders`);
    dispatch(loadCampaignDesigns(campaign_token));
    return orders;
  } catch (error) {
    GlobalActions.showError("There has been an error while fetching production orders");
    throw error;
  }
};

export const SUBMIT_PRODUCTION_ORDER = "marketplace/campaign/designs/submit_production_order";
export const submitProductionOrder = production_order_id => async (dispatch, getState) => {
  try {
    const production_order = await post(`/api/v1/production_orders/${production_order_id}/submit`);
    dispatch({ type: SUBMIT_PRODUCTION_ORDER, payload: { production_order } });
    dispatch(loadCampaignDesigns(production_order.campaign_id));

    GlobalActions.showMessage("Production Order submitted.");
    return production_order;
  } catch (error) {
    const default_message = "There has been an error while submitting the production order.";
    GlobalActions.showError(error.data.message || default_message, true);
    throw error;
  }
};

export const REFRESH_PRODUCTION_ORDER = "marketplace/campaign/designs/refresh_production_order";
export const refreshProductionOrder = production_order_id => async (dispatch, getState) => {
  try {
    const production_order = await post(`/api/v1/production_orders/${production_order_id}/refresh`);
    dispatch({
      type: REFRESH_PRODUCTION_ORDER,
      payload: { production_order },
    });
    dispatch(loadCampaignDesigns(production_order.campaign_id));

    GlobalActions.showMessage("Production Order refreshed.");
    return production_order;
  } catch (error) {
    const default_message = "There has been an error while refreshing the production order.";
    GlobalActions.showError(error.data.message || default_message, true);
    throw error;
  }
};

export const CANCEL_PRODUCTION_ORDER_ITEM = "marketplace/campaign/designs/cancel_production_order_item";
export const cancelProductionOrderItem = production_order_id => async (dispatch, getState) => {
  try {
    const production_order = await del(`/api/v1/production_orders/${production_order_id}/cancel`);
    dispatch({
      type: CANCEL_PRODUCTION_ORDER_ITEM,
      payload: { production_order },
    });
    dispatch(loadCampaignDesigns(production_order.campaign_id));

    GlobalActions.showMessage(
      "Order canceled. Canceling from here WILL NOT cancel the order entirely, you must reach out to Circle Graphics directly to cancel this order.",
    );
    return production_order;
  } catch (error) {
    GlobalActions.showError("There has been an error while canceling the production order.");
    throw error;
  }
};

export const UPDATE_PRODUCTION_ORDER_ITEM = "marketplace/campaign/designs/cancel_production_order_item";
export const updateProductionOrderItem = (order_item_id, payload) => async (dispatch, getState) => {
  try {
    const {
      campaign: { campaignId },
    } = getState();
    const order_item = await put(`/api/v1/production_order_items/${order_item_id}`, payload);
    dispatch({
      type: UPDATE_PRODUCTION_ORDER_ITEM,
      payload: { order_item_id, payload },
    });
    dispatch(loadCampaignDesigns(campaignId));

    GlobalActions.showMessage("Item updated.");
    return order_item;
  } catch (error) {
    GlobalActions.showError("There has been an error while updating the production order item.");
    throw error;
  }
};

export const DELETE_PRODUCTION_ORDER = "marketplace/campaign/designs/delete_production_order";
export const deleteProductionOrder = production_order_id => async (dispatch, getState) => {
  try {
    const production_order = await del(`/api/v1/production_orders/${production_order_id}`);
    dispatch({
      type: DELETE_PRODUCTION_ORDER,
      payload: { production_order },
    });
    dispatch(loadCampaignDesigns(production_order.campaign_id));

    GlobalActions.showMessage("Order deleted.");
    return production_order;
  } catch (error) {
    GlobalActions.showError("There has been an error while deleting the prodution order.");
    throw error;
  }
};

export const SUBMIT_PRODUCTION_ORDER_BULK = "marketplace/campaign/designs/submit_production_order_bulk";
export const submitProductionOrderBulk = (order_id, payload) => async (dispatch, getState) => {
  try {
    const {
      campaign: { campaignId },
    } = getState();
    const production_order = await post(`/api/v1/production_orders/${order_id}/apply_bulk_changes`, payload);
    dispatch(loadCampaignDesigns(campaignId));
    dispatch({ type: SUBMIT_PRODUCTION_ORDER_BULK, payload });

    return GlobalActions.showMessage("Changes applied.");
  } catch (error) {
    const { data } = error;
    const message = data.message || "There has been an error while applying changes.";
    GlobalActions.showError(message);
    throw error;
  }
};

export const sendToInstallers = (campaign_id, campaign_unit_tokens) => async dispatch => {
  try {
    await post(`/api/v1/campaigns/${campaign_id}/installers`, { campaign_unit_tokens });
  } catch (error) {
    console.log(error);
  }
};
