import React from 'react';
import GlobalActions from '../../actions/GlobalActions'
import GlobalStore from '../../stores/GlobalStore'
import moment from 'moment'
import { put, get, post } from '../../utils/api'
import JobProgress from '../../utils/JobProgress';
import { Pagination } from 'react-bootstrap'
import ScrapeCompanyModal from './ScrapeCompanyForm';

export default class IntroTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkedin_users: [],
      connections: [],
      show_company: false,
      job_pooling: new JobProgress(),
      current_page: 1,
      total_pages: 0
    };
    this.updateProfile = this.updateProfile.bind(this);
    this.findByLinkedinProfile = this.findByLinkedinProfile.bind(this);
    this.scrapeLinkedinCompany = this.scrapeLinkedinCompany.bind(this);
    this.onScrapeComplete = this.onScrapeComplete.bind(this);
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  componentDidMount() {
    this.fetchData();
    this.getLinkedinUsers();
    this.unsubscribeList = [
      GlobalStore.listen(this.onGlobalChange),
    ];
  }

  onGlobalChange(eventName) {
    if (eventName == 'email_sent') {
      this.fetchData()
    }
  }

  async getLinkedinUsers() {
    const { users } = await get(`/api/v1/users?linkedin_users=true`);
    this.setState({ linkedin_users: users });
  }

  async scrapeLinkedinCompany(payload) {
    const { job_pooling } = this.state;
    const { job_id } = await post(`/api/v1/linkedin_scrape`, payload);
    job_pooling.startPolling(job_id, this.onScrapeComplete);
  }

  onScrapeComplete(response) {
    const { data } = response;
    const { error } = data;
    if(error) {
      return GlobalActions.showError("Something went wrong while scraping");
    }
    this.fetchData();
  }

  renderPagination() {
    const { current_page, total_pages } = this.state;

    return (
      <div className="text-center">
        <Pagination
          bsSize="small"
          prev
          next
          last
          ellipsis
          boundaryLinks
          items={total_pages}
          maxButtons={5}
          activePage={parseInt(current_page)}
          onSelect={(page) => this.onPageChange(page)} />
      </div>
    )
  }

  onPageChange(page) {
    this.setState({ current_page: page }, () => this.fetchData());
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  onGlobalChange(eventName) {
    if (eventName == 'email_sent') {
      this.fetchData()
    }
  }

  async fetchData() {
    const { current_page, show_company } = this.state;
    const params = new URLSearchParams({
      page: current_page,
      show_company
    });
    const {pages, connections} = await get(`/api/v1/connections?${params.toString()}`);
    this.setState({ connections, total_pages: pages });
  }

  askForIntro(connection) {
    GlobalActions.openPopup('compose_email', {
      email: connection.connector_email,
      company: connection.connected_to_company,
      first_degree_full_name: connection.connector_name,
      first_degree_first_name: connection.connector_first_name,
      first_degree_last_name: connection.connector_last_name,
      second_degree_full_name: connection.display_name,
      second_degree_first_name: connection.first_name,
      second_degree_last_name: connection.last_name,
      sendable_type: 'UserLinkedinConnection',
      sendable_id: connection.id
    })
  }

  getEmail({ connector_id, connector_company_id }) {
    $.post('/api/v1/clearbit', {
      linkedin_profile_id: connector_id,
      company_id: connector_company_id
    }).then(response => {
      if (response.status == 200) {
        this.fetchData()
        GlobalActions.showMessage("Email found successfully!")
      } else {
        GlobalActions.showError("Could not find an email for this person")
      }
    })
      .catch(({ responseJSON }) => {
        GlobalActions.showError(responseJSON.error.message)
      })
  }

  redirectToProfile(identifier) {
    window.open(`https://linkedin.com/in/${identifier}`, '_blank');
  }

  async fetchCompanies(query) {
    const url = `/api/v1/businesses?q=${encodeURI(query)}`
    const companies = await get(url)
    return companies
  }

  async findByLinkedinProfile(profile_id) {
    await post(`/api/v1/linkedin_profiles/${profile_id}/lookup_company`);
    this.fetchData();
    GlobalActions.closePopup()
  }

  async lookupCompanyDomain(company_id) {
    try {
      await post(`/api/v1/businesses/${company_id}/lookup_domain`);
      this.fetchData();
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  openEditModal({ id, name }, inputType) {
    const type = {
      'select': {
        label: 'Companies',
        attribute: 'company_id',
        options: this.state.companies,
        onInputChange: this.fetchCompanies
      },
      'text': {
        label: 'Email',
        attribute: 'email'
      }
    }

    GlobalActions.openPopup('edit_email', {
      id,
      name,
      update: this.updateProfile,
      scrape_profile: this.findByLinkedinProfile,
      label: type[inputType].label,
      attribute: type[inputType].attribute,
      [inputType]: type[inputType]
    })
  }

  async updateProfile(id, payload) {
    try {
      await put(`/api/v1/linkedin_profiles/${id}`, payload)
      this.fetchData()
      GlobalActions.closePopup()
    } catch (error) { }
  }

  connectedToParams({ connected_to_id, display_name }) {
    return { id: connected_to_id, name: display_name }
  }

  connectedByParams({ connector_id, connector_first_name }) {
    return { id: connector_id, name: connector_first_name }
  }

  onShowCompany(show_company) {
    this.setState({ show_company }, () => this.fetchData());
  }

  renderConnectorCompany(connection) {
    const connectorHasCompany = !!connection.connector_company;
    if(connectorHasCompany) {
      const { connector_company, connector_company_domain, connector_company_id } = connection;
      return (
        <div>
          <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'select')}>
            {connector_company}
          </a>
          {!connector_company_domain &&
            <button style={{ marginLeft: '10px' }} className="btn btn-primary btn-xs" onClick={() => this.lookupCompanyDomain(connector_company_id)}>
              Domain Lookup
            </button>
          }
        </div>
      )
    }
    return (
      <div>
        <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'select')}>
          Define
        </a>
      </div>
    )
  }

  renderConnections(connections) {
    return (
      <table className="table" style={{ marginBottom: 0 }}>
        <thead>
          <tr style={{ fontWeight: 'bold' }}>
            <td></td>
            <td colSpan={3}>Your Connection</td>
            <td colSpan={3}>Who You're Trying To Reach</td>
            <td></td>
            <td></td>
          </tr>
          <tr style={{ fontWeight: 'bold' }}>
            <td style={{backgroundColor:'lightblue'}}>User</td>
            <td style={{backgroundColor:'lightblue'}}>Company</td>
            <td style={{backgroundColor:'lightblue'}} width="150">Name</td>
            <td style={{backgroundColor:'lightblue'}}>Email</td>
            <td style={{backgroundColor:'lightgreen'}}>Company</td>
            <td style={{backgroundColor:'lightgreen'}}>Name</td>
            <td style={{backgroundColor:'lightgreen'}}>Title</td>
            <td style={{backgroundColor:'lightpink'}}></td>
            <td style={{backgroundColor:'lightpink'}}></td>
          </tr>
        </thead>
        <tbody>
          {connections.map(connection => <tr key={connection.id}>
            <td>{connection.user_name}</td>
            <td>{this.renderConnectorCompany(connection)}</td>
            <td style={{ cursor: 'pointer' }} onClick={() => this.redirectToProfile(connection.connector_identifier)}>{connection.connector_name}</td>
            <td>{
              connection.connector_email ? <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'text', connection.connector_email)}>{connection.connector_email}</a> :
                <div>
                  <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'text')}>Define</a>
                  {connection.connector_company_id &&
                    <button style={{ marginLeft: '10px' }} className="btn btn-primary btn-xs" onClick={() => this.getEmail(connection)}>Clearbit</button>
                  }
                </div>
            }</td>
            <td>
              {
                connection.connected_to_company ?
                <a onClick={(e) => this.openEditModal(this.connectedToParams(connection), 'select')}>{connection.connected_to_company}</a> :
                <a onClick={(e) => this.openEditModal(this.connectedToParams(connection), 'select')}>Define</a>
              }
            </td>
            <td style={{ cursor: 'pointer' }} onClick={() => this.redirectToProfile(connection.identifier)}>{connection.first_name} {connection.last_name}</td>
            <td>{connection.title}</td>
            <td>{connection.intro_requested_at ? moment(connection.intro_requested_at).format('L LT') : ''}</td>
            <td>{connection.connector_email ? <a onClick={() => this.askForIntro(connection)}>Compose</a> : 'Compose'}</td>
          </tr>)}
        </tbody>
      </table>
    )
  }

  render() {
    const { connections, linkedin_users } = this.state

    return (
      <div style={{margin:'10px 30px 10px 30px'}}>
        <ScrapeCompanyModal
          linkedin_users={linkedin_users}
          scrapeLinkedinCompany={this.scrapeLinkedinCompany}
        />
        <h2>Connections</h2>
        <div style={{border:'1px solid #ccc',padding:10}}>
          <label>
            <input type="checkbox" style={{marginRight:10}} checked={this.state.show_company} onClick={e => this.onShowCompany(e.target.checked)} />
            Show connections across company
          </label>
        </div>
        {this.renderPagination()}
        {this.renderConnections(connections)}
        {this.renderPagination()}
      </div>
    )
  }
}
