import * as React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { bulkAddPostingNote } from "../../../actions/campaign_design_actions";
import _ from "lodash";
import GlobalActions from "../../../actions/GlobalActions";
const ASSETS = (window as any).ASSETS;

interface Props {
  show: boolean;
  onHide: Function;
  bulkAddPostingNote: (
    campaign_id: string,
    campaign_unit_ids: string[],
    note: string
  ) => void;
  campaign: any;
  selected_units: { campaign_unit_token: string }[];
}

interface State {
  note: string;
  saving: boolean;
}

class AddPostingNoteModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      saving: false
    };
    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.limit = this.limit.bind(this);
    this.submit = this.submit.bind(this);
  }

  onHide() {
    const { onHide } = this.props;
    this.setState({ note: "", saving: false }, () => onHide());
  }

  async submit() {
    const { note } = this.state;
    const { campaign, selected_units } = this.props;
    const campaign_id = campaign.campaignId;
    const campaign_unit_ids = selected_units.map(u => u.campaign_unit_token);
    const { bulkAddPostingNote } = this.props;
    await bulkAddPostingNote(campaign_id, campaign_unit_ids, note);
    GlobalActions.showMessage(
      `Posting note added to ${campaign_unit_ids.length} campaign units.`
    );
    this.onHide();
  }

  private onSubmit() {
    const { note } = this.state;
    if (_.isEmpty(note) || note.length > this.limit()) {
      return;
    }
    this.setState({ saving: true }, this.submit);
  }

  limit() {
    return 100;
  }

  onTextChange(e) {
    this.setState({ note: e.target.value });
  }

  renderBody() {
    const { note, saving } = this.state;
    const lessThanLimit = this.limit() - note.length >= 0;

    return (
      <Modal.Body>
        <textarea
          className={`form-control text-area  ${
            lessThanLimit ? "" : "too_many"
          }`}
          value={note}
          onChange={this.onTextChange}
        />
        {lessThanLimit && (
          <div className="characters_rem">
            {this.limit() - note.length} characters remaining.
          </div>
        )}
        {!!!lessThanLimit && (
          <div className="characters_rem too_many">
            {Math.abs(this.limit() - note.length)} too many characters.
          </div>
        )}
        <button
          className="btn btn-success form-control"
          disabled={!!!lessThanLimit || note.length === 0 || saving}
          onClick={this.onSubmit}
          style={{
            marginTop: 10
          }}
        >
          Submit
        </button>
      </Modal.Body>
    );
  }

  render() {
    const { show } = this.props;

    return (
      <Modal
        className="add_posting_note_modal"
        show={show}
        onHide={this.onHide}
      >
        <Modal.Header>
          <div className="header-area">
            <div className="modal-header-text">Add Posting Note</div>
            <div className="close-modal-area">
              <div className="close-modal" onClick={this.onHide}>
                <img
                  className="close-modal-x"
                  src={ASSETS["x"]}
                  style={{ float: "right" }}
                />
              </div>
            </div>
          </div>
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(({}) => ({}), { bulkAddPostingNote })(
  AddPostingNoteModal
);
