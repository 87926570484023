import accounting, { formatNumber } from "accounting";
import cs from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import UnitModel from "../../models/unitModel";
import { calculateDaysInRange } from "../../utils/invoice";
import Favorite from "../Campaign/Favorite";
import Recommend from "../Campaign/Recommend";
import SupplierName from "../SupplierName";
import { transitUnitPriceRange } from "../UnitInfo";
import UnitAvailability from "./UnitAvailability";
import FairPriceIcon from "../UnitInfo/FairPriceIcon";

export default class ListItemExpanded extends React.Component {

  constructor(props) {
    super(props);
    const advertiserGreyLabel = AppConfig.advertiserGreyLabel;
    this.state = {
      campaign: {},
      advertiserGreyLabel: advertiserGreyLabel,
      override_face_mock: false,
    }
  }

  unitModel() {
    const { unit } = this.props;
    return new UnitModel(unit);
  }

  getPriceAndRangeData() {
    // todo: get all this data from the api, this logic is duplicated
    const { unit } = this.props;
    const price = unit.price;
    const quantity =
      (unit.has_quantity || unit.is_digital) && unit.campaign_units
        ? unit.campaign_units.reduce((total, cu) => total + cu.quantity)
        : 1;
    const daysInRange = calculateDaysInRange(unit);
    const dailyPrice = (Number(price) * quantity) / 4 / 7;
    const durationPrice = Number(dailyPrice) * daysInRange;
    const prodInstallPrice = (Number(unit.installation_cost) + Number(unit.production_cost)) * quantity;
    const totalPrice = durationPrice + prodInstallPrice;
    const rangeUnit = Number(unit.daysInRange) > 1 ? "days" : "day";
    const impressionsTotal = unit.week_total_impressions
      ? (unit.week_total_impressions / 7) * daysInRange * quantity
      : null;
    return {
      price,
      daysInRange,
      dailyPrice,
      durationPrice,
      rangeUnit,
      impressionsTotal,
      prodInstallPrice,
      totalPrice,
    };
  }

  renderQuantity() {
    const { unit } = this.props;
    if (!!!unit.has_quantity && !!!unit.is_digital) return;
    const title = unit.is_digital ? "Spots: " : "Quantity: ";
    return (
      <div className="listItem__impressions">
        {title}
        {unit.quantity}
      </div>
    );
  }

  lockIcon() {
    return <i className="fa fa-lock" />;
  }

  renderMultipleFligths() {
    const { unit } = this.props;
    const units = unit.campaign_units ? unit.campaign_units.filter(u => u.start_date && u.end_date) : [];
    if (_.isEmpty(units)) {
      return;
    }
    return (
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {units.map(fu => {
            return (
              <div
                key={fu.id}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}
              >
                <div className="listItemExpanded__fligh-container">
                  <Favorite
                    isFavorited={fu.is_favorited}
                    unitId={fu.unit_id}
                    campaignUnitId={fu.id}
                    onFavoriteUnit={this.props.onFavoriteUnit}
                    showCallback={false}
                  />
                  <div className="listItemExpanded__flight-date" style={{ marginLeft: "5px" }}>
                    {moment.utc(fu.start_date).format("MMM DD")}
                  </div>
                </div>
                <div className="listItemExpanded__flight-arrow"></div>
                <div className="listItemExpanded__flight-date">{moment.utc(fu.end_date).format("MMM DD")}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderLeftSide() {
    const { unit, isPublicView, isAgencyView, campaign } = this.props;
    const showSize = unit.lime || unit.size;
    let cpm, size;

    if (this.props.isPublicView) {
      cpm = this.lockIcon();
      size = unit.size; // we're okay with sharing size
    } else {
      cpm = this.unitModel().getMockCpm(campaign);
      size = unit.size;
    }
    const showCPM = AppConfig.showPricing && cpm && (cpm > 0 || !this.props.isPublicView);
    if (unit.lime) {
      size = "Various";
    }

    return (
      <div className="listItemExpanded__props listItemExpanded__props_main">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="listItemExpanded__price_cpm">
            {(showSize || isPublicView) && (
              <span className="listItemExpanded__size">
                Size
                <span className="listItemExpanded__value"> {size}</span>
              </span>
            )}
            {!isAgencyView && (showCPM || isPublicView) && (
              <div>
                <span className="listItemExpanded__cpm">
                  CPM
                  <span className="listItemExpanded__value"> {cpm}</span>
                </span>
              </div>
            )}
          </div>
          <div>
            {this.renderQuantity()}
            {this.renderRightSide()}
          </div>
        </div>
        {campaign && this.renderMultipleFligths()}
      </div>
    );
  }

  renderRightSide() {
    const { unit, isPublicView, isAgencyView, isSearchView, user, campaign } = this.props;
    const hidePrices = (campaign && campaign.hide_prices) || false;
    const showPriceForDuration = (campaign && campaign.show_price_for_duration) || false;
    let pricingDiv, removeDiv, impressionsDiv;
    const showImpressions = unit.impressions && unit.impressions > 0;
    let price, impressions;

    if (isPublicView) {
      impressions = `${this.lockIcon()} imp/4w`;
      price = `$ ${this.lockIcon()}/4w`
    } else {
      impressions = showPriceForDuration
        ? `${accounting.format(unit.impressions_for_duration)} imp`
        : `${accounting.format(unit.impressions)} imp/4w`;
      price = unit.media_subtypes
        ? `${transitUnitPriceRange(unit)}/4w`
        : showPriceForDuration
        ? this.unitModel().getPriceForDuration(unit)
        : isSearchView && user.permissions.agency_browse_type == "range"
        ? `${this.unitModel().getPrice(unit, { lower: "lower_price", higher: "higher_price" })}/4w`
        : `${this.unitModel().getMockPrice(campaign)}/4w`;
    }
    const showPrice = price && AppConfig.showPricing && !hidePrices;
    if (!isAgencyView && !user.permissions.hide_unit_prices && (showPrice || isPublicView)) {
      pricingDiv = (
        <div key="nonCartPrice" className="listItemExpanded__price">
          {price}
        </div>
      );
    }

    if (showImpressions || isPublicView) {
      impressionsDiv = (
        <span key="impressions" className="listItemExpanded__impressions">
          {impressions}
        </span>
      );
    }
    return [pricingDiv, removeDiv, impressionsDiv];
  }

  renderDemographics(unit) {
    const { campaign, demographic } = this.props;

    if (demographic) {
      const impressions = demographic.audience;
      const vehicleTraffic = demographic.vehicle_audience;
      let percentVehicle, percentFoot;

      if (vehicleTraffic) {
        percentVehicle = (Number(vehicleTraffic) / impressions) * 100;
        percentFoot = 100 - percentVehicle;
      }
      const showGrp = demographic.gross_rating_points && demographic.gross_rating_points > 0;
      const showPaf = demographic.plan_average_frequency && demographic.plan_average_frequency > 0;
      const showPr = demographic.plan_reach && demographic.plan_reach > 0;
      const showPrp = demographic.plan_reach_percentage && demographic.plan_reach_percentage > 0;

      return (
        <div className="listItemExpanded__distance" style={{ marginTop: 5, color: "green" }}>
          {showGrp && <div>{accounting.format(demographic.gross_rating_points, 2)} gross rating points</div>}
          {campaign.token === "2a12i68hi9" && (
            <div>
              {showPaf && <div>{formatNumber(demographic.plan_average_frequency, 4)} frequency</div>}
              {showPr && <div>{accounting.format(demographic.plan_reach, 0)} reach</div>}
              {showPrp && <div>{formatNumber(demographic.plan_reach_percentage * 100, 2)}% reach</div>}
            </div>
          )}
          <div>
            {impressions && impressions > 0 && (
              <div style={{ marginTop: 5 }}>
                <i className="fa fa-users" />
                {accounting.format(impressions)} imp/4w
              </div>
            )}
            {percentVehicle && percentVehicle > 0 && (
              <div>
                <span>
                  <i className="fa fa-car" />
                  {accounting.toFixed(percentVehicle)}%
                </span>
                <span style={{ marginLeft: 10 }}>
                  <i className="fa fa-user" />
                  {accounting.toFixed(percentFoot)}%
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  renderUnitName() {
    const { campaign } = this.props;
    if (campaign && campaign.is_mock) {
      return false;
    }
    return true;
  }

  overrideFaceMock(e) {
    e.stopPropagation();
    const { override_face_mock } = this.state;
    if (this.props.user.is_admin) {
      this.setState({ override_face_mock: !override_face_mock });
    }
  }

  renderFaceId() {
    const { campaign, unit } = this.props;
    const is_ibo_unit = unit.supplier_id === 1835;
    if (campaign && (campaign.is_mock || campaign.hide_face_ids) && !this.state.override_face_mock) {
      return unit._id;
    }
    if (is_ibo_unit) {
      return `${unit.supplier_face_id.split("-")[0]}`;
    }
    return unit.supplier_face_id;
  }

  showFairPriceIcon() {
    const { campaign, isCartView, isPublicView, user } = this.props;
    const permissions = this.props.permissions ? this.props.permissions : user.permissions;
    const price_guide_disabled = campaign ? campaign.price_guide_disabled : true;
    if (!!!campaign || campaign.price_guide_disabled || campaign.hide_prices) { return false; }
    if (!!!permissions.can_view_price_guide) { return false; }
    if (isCartView || isPublicView || !!!AppConfig.showPricing) { return false; }
    return true;
  }

  unitHasCartRefreshTag(unit) {
    // See app/models/avails_refresh.rb for the tag constant AvailsRefresh::CART_REFRESH_TAG
    return unit.tags.some(tag => tag.tag === "Removed from cart");
  }

  unitNotAvailable(unit) {
    return unit.supplier_status === "api_unavailable"
  }

  removedFromCartBanner() {
    return <div className="listItemExpanded__unavailable_banner">
      <i className="fa-regular fa-circle-exclamation" />
      This unit was removed from your cart because it recently became unavailable.
    </div>
  }

  render() {
    const { unit, isInCart, campaign, user, isCampaignView, scoreFlagEnabled } = this.props;
    const screenType = this.unitModel().prettyScreenType();
    let { permissions } = this.props;

    const tooltipFavoriteText = (
      <span>
        Click the <i className="fa fa-heart-o" /> to save a location to your favorites and easily find it later.
      </span>
    );
    const tooltipUnitText = (
      <span>
        View detailed info and images for each location by clicking on each unit from the sidebar or the pin on the map.
      </span>
    );
    const advertiser = campaign ? campaign.advertiser : null;
    const isAvailabilityFlowEnabled = _.get(advertiser, "unit_availability_flow");

    let className = cs({
      listItemExpanded: true,
      listItemExpanded_active: this.props.isActive,
    });
    className += ` listItemExpanded_${unit.lime ? "lime" : unit.unit_type}`;

    let orientation;
    if (unit.supplier_id == 30 && unit.supplier_face_id && !!!unit.is_package && unit.supplier_face_id) {
      if (unit.supplier_face_id.endsWith("L")) {
        orientation = "Left";
      } else if (unit.supplier_face_id.endsWith("R")) {
        orientation = "Right";
      } else if (unit.supplier_face_id.endsWith("H")) {
        orientation = "Street - Horizontal";
      } else if (unit.supplier_face_id.endsWith("V")) {
        orientation = "Street - Vertical";
      }
    }

    if (permissions == null) {
      permissions = user.permissions;
    }

    const showFairPriceIcon = this.showFairPriceIcon();

    return (
      <div className={className}>
        <div>
          {unit.is_recommended && permissions.can_view_recommended && (
            <div className="listItemExpanded__recommended">Recommended</div>
          )}
          <div className={cs({ listItemExpanded__image: true, default_image: unit.picture.is_default_image })}
               style={{ backgroundImage: `url(${unit.image_file_link})` }}>
            {this.unitNotAvailable(unit) && (
              <div className="listItemExpanded__cart_refresh_tag">
                <i className="fa-regular fa-circle-xmark" />
                Unavailable
              </div>
            )}
          </div>
        </div>

        <div className="listItemExpanded__content">
          {isCampaignView && scoreFlagEnabled && unit.score &&
            <div className="listItemExpanded__score">{unit.score}</div>
          }
          {isCampaignView && permissions.can_recommend_units && (
            <div className="listItemExpanded__recommend">
              <Recommend
                isRecommended={unit.is_recommended}
                unitId={unit.id}
                onToggleRecommendUnit={this.props.onToggleRecommendUnit}
              />
            </div>
          )}

          {isCampaignView && (
            <div className="listItemExpanded__favorite">
              <Favorite
                isFavorited={unit.campaign_units && !!unit.campaign_units.find(u => u.is_favorited)}
                unitId={unit.id}
                onFavoriteUnit={this.props.onFavoriteUnit}
                showCallback={false}
              />
            </div>
          )}

          <h2 className="listItemExpanded__title">
            {isInCart && <i className="fa fa-check" />}
            {(unit.is_package) && (
              <span>
                <span className="label label-default">PKG</span>{" "}
              </span>
            )}

            {unit.supplier_face_id && !unit.lime && (
              <span className="listItemExpanded__face_id" onClick={e => this.overrideFaceMock(e)}>
                {this.renderFaceId()}
              </span>
            )}

            {this.renderUnitName() && unit.name}

            <div className="listItemExpanded__tags flex gap-2 text-xs font-semibold text-[#7c8e92]">
              {showFairPriceIcon && <FairPriceIcon
                unit={unit}
                iconSize={16}
                className={"inline-flex items-center bg-grayscale-200 py-[2px] px-[4px]"}
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.06)"
                }}
              />}
              <span className="inline-flex items-center bg-grayscale-200 py-[2px] px-[4px]">{screenType || "Static"}</span>
              {(!isCampaignView || (permissions && permissions.can_view_supplier_name)) && (
                <span>
                  {!user.is_managed_supplier && (
                    <SupplierName
                      className="inline-flex items-center bg-grayscale-200 py-[2px] px-[4px]"
                      user={user}
                      supplier={{
                        name: (unit.supplier === "wlvgeno73t" && unit.alt_supplier_name) ? unit.alt_supplier_name : unit.supplier_name,
                        is_managed: unit.is_managed,
                        is_fully_submitted: unit.is_fully_submitted,
                        is_broker: unit.is_broker
                      }}
                    />
                  )}
                </span>
              )}
              {orientation && <span className="inline-flex items-center bg-grayscale-200 py-[2px] px-[4px]">{orientation}</span>}
            </div>
          </h2>

          <div style={{ overflow: "hidden" }}>{this.renderLeftSide()}</div>

          {/* TODO add POI slug */}
          {unit.distance_from_point_of_interest && !this.props.pointOfInterest ? (
            <div className="listItem__distance">
              {accounting.format(Number(unit.distance_from_point_of_interest), 2)} miles from closest point of
              interest
            </div>
          ) : (
            ""
          )}
          {unit.distance_from_point_of_interest && this.props.pointOfInterest ? (
            <div className="listItem__distance" onClick={this.onPointOfInterestClick.bind(this)}>
              {accounting.format(Number(unit.distance_from_point_of_interest), 2)} miles from{" "}
              {this.props.pointOfInterest.name}
            </div>
          ) : (
            ""
          )}

          {unit.has_restrictions && (
            <div className="listItemExpanded__restrictions">
              <i className="fa fa-close" /> Has Restrictions
              {unit.restrictions_description && ` - ${unit.restrictions_description}`}
            </div>
          )}

          {this.renderDemographics(unit)}
          {isAvailabilityFlowEnabled && (
            <UnitAvailability permissions={permissions} unit={unit} campaign={campaign} isInCart={isInCart} />
          )}

          {/*unit.apparatix_unit_id && (
            <UnitApparatixAvailability
              permissions={permissions}
              unit={unit}
              campaign={campaign}
              isInCart={isInCart}
            />
          )*/}
        </div>
        {campaign && this.unitHasCartRefreshTag(unit) && this.removedFromCartBanner()}
      </div>
    );
  }
}
