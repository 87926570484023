import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import GlobalActions from "../../../actions/GlobalActions";
import { updatePricing } from "./actions";

class ChangePricingModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      price: null,
      production: null,
      installation: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { updatePricing, unit_tokens, campaign_token } = this.props;
    const { price, production, installation } = this.state;
    updatePricing(unit_tokens, campaign_token, price, production, installation);
  }

  componentDidUpdate(prevProps) {
    const { saving_pricing, onHide } = this.props;
    if (!!!saving_pricing && prevProps.saving_pricing) {
      GlobalActions.showMessage("Flight pricing updated");
      onHide();
    }
  }

  renderBody() {
    const { saving_pricing, onHide } = this.props;
    const { price, installation, production } = this.state;

    return (
      <Modal.Body>
        {saving_pricing && (
          <div>
            <div className="loading-spinner">
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        )}
        {!!!saving_pricing && (
          <div>
            <div className="form-group row">
              <div className="col-sm-4">
                <div className="title">4 Week Price</div>
                <input
                  className="form-control"
                  value={price}
                  type="number"
                  onChange={e => this.setState({ price: e.target.value })}
                />
              </div>
              <div className="col-sm-4">
                <div className="title">Production</div>
                <input
                  className="form-control"
                  value={production}
                  type="number"
                  onChange={e => this.setState({ production: e.target.value })}
                />
              </div>
              <div className="col-sm-4">
                <div className="title">Installation</div>
                <input
                  className="form-control"
                  value={installation}
                  type="number"
                  onChange={e => this.setState({ installation: e.target.value })}
                />
              </div>
            </div>
            <div className="button-area">
              <button className="confirmation_button btn btn-success" onClick={this.onSubmit}>
                Submit
              </button>
              <button className="cancel_button btn btn-default" onClick={onHide}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    );
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="flight_action_modal">
        <Modal.Header>
          <Modal.Title>Change Flight Pricing</Modal.Title>
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { saving_pricing } }) => ({ saving_pricing }),
  { updatePricing },
)(ChangePricingModal);
