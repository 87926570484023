import "rc-tooltip/assets/bootstrap.css";

import * as React from "react";
import { Component } from "react";
import Tooltip from "rc-tooltip";

import { dollarFormatter } from "../../utils/formatters";

export default class PriceDistributionGraph extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  unitScore() {
    const { unit } = this.props;
    const { campaign_unit_price, lower_fair_price, upper_fair_price } = unit;
    const lower = lower_fair_price === upper_fair_price ? parseInt(lower_fair_price) - 50 : parseInt(lower_fair_price);
    const upper = lower_fair_price === upper_fair_price ? parseInt(upper_fair_price) + 50 : parseInt(upper_fair_price);
    const current_price = campaign_unit_price ? campaign_unit_price : unit.price;
    // Fair lives between 27-67
    const fair_range = upper - lower;
    const divisor = fair_range / 40;
    const score = Math.floor((upper - current_price) / divisor) + 27;
    if (score > 96) {
      return 96;
    }
    if (score < 3) {
      return 3;
    }
    return score;
  }

  pinImgUrl(unit_score) {
    if (unit_score > 67) {
      return "https://adquick-public.s3-us-west-2.amazonaws.com/low-price-icon.png";
    } else if (unit_score < 27) {
      return "https://adquick-public.s3-us-west-2.amazonaws.com/high-price-icon.png";
    } else {
      return "https://adquick-public.s3-us-west-2.amazonaws.com/price-icon.png";
    }
  }

  renderPin() {
    // Ugly AF but works well
    const pin_top_positioning = {
      "96": 145,
      "95": 145,
      "94": 145,
      "93": 145,
      "92": 145,
      "91": 145,
      "90": 145,
      "89": 145,
      "88": 145,
      "87": 142,
      "86": 141,
      "85": 139,
      "84": 137,
      "83": 134,
      "82": 131,
      "81": 128,
      "80": 125,
      "79": 121,
      "78": 117,
      "77": 113,
      "76": 108,
      "75": 102,
      "74": 99,
      "73": 93,
      "72": 88,
      "71": 81,
      "70": 75,
      "69": 71,
      "68": 66,
      "67": 58,
      "66": 53,
      "65": 47,
      "64": 44,
      "63": 36,
      "62": 32,
      "61": 27,
      "60": 23,
      "59": 18,
      "58": 13,
      "57": 11,
      "56": 7,
      "55": 3,
      "54": 1,
      "53": -2,
      "52": -4,
      "51": -6,
      "50": -7,
      "49": -8,
      "48": -8,
      "47": -8,
      "46": -8,
      "45": -8,
      "44": -6,
      "43": -5,
      "42": -4,
      "41": -2,
      "40": 1,
      "39": 3,
      "38": 5,
      "37": 9,
      "36": 12,
      "35": 17,
      "34": 21,
      "33": 25,
      "32": 30,
      "31": 35,
      "30": 41,
      "29": 48,
      "28": 54,
      "27": 60,
      "26": 64,
      "25": 71,
      "24": 76,
      "23": 82,
      "22": 87,
      "21": 94,
      "20": 100,
      "19": 105,
      "18": 111,
      "17": 117,
      "16": 122,
      "15": 125,
      "14": 130,
      "13": 134,
      "12": 138,
      "11": 141,
      "10": 143,
      "9": 145,
      "8": 145,
      "7": 145,
      "6": 145,
      "5": 145,
      "4": 145,
      "3": 145,
    };
    const unit_score = this.unitScore();
    const pinImgUrl = this.pinImgUrl(unit_score);
    const { unit } = this.props;
    const { campaign_unit_price, price } = unit;
    const current_price = campaign_unit_price ? campaign_unit_price : unit.price;

    return (
      <Tooltip overlayClassName="this-unit" placement="bottom" overlay={<div>{dollarFormatter(current_price)}</div>}>
        <img
          src={pinImgUrl}
          style={{
            cursor: "pointer",
            height: 24,
            zIndex: 2,
            position: "relative",
            right: `${unit_score}%`,
            top: pin_top_positioning[unit_score],
          }}
        />
      </Tooltip>
    );
  }

  renderLabels() {
    const center: "center" = "center";
    const label_style = {
      width: "33%",
      fontSize: 10,
      color: "#858585",
      marginTop: 10,
      textAlign: center,
    };

    const container_style = {
      marginBottom: 22,
      width: "100%",
      display: "flex",
    };

    return (
      <div style={container_style}>
        <div style={{ ...label_style }}>Great Price</div>
        <div style={{ ...label_style }}>Good Price</div>
        <div style={{ ...label_style }}>Above Market</div>
      </div>
    );
  }

  renderChart() {
    const rel: "relative" = "relative";
    const line_style = {
      borderRight: "solid 1px #cccccc",
      height: 155,
      width: 1,
      position: rel,
    };

    const top = 12;
    const leftRight = 140;

    return (
      <div
        className="chart"
        style={{
          borderBottom: "3px solid #CCCCCC",
          display: "inline-flex",
          marginTop: 10,
        }}
      >
        <div
          style={{
            ...line_style,
            top,
            left: leftRight,
          }}
        ></div>
        <img
          src="https://adquick-public.s3-us-west-2.amazonaws.com/curve.png"
          style={{
            width: 440,
            maxWidth: "98%",
            height: "100%",
          }}
        />
        {this.renderPin()}
        <div
          style={{
            ...line_style,
            top,
            right: leftRight,
          }}
        ></div>
      </div>
    );
  }

  fairPriceStyle() {
    const rel: "relative" = "relative";
    const center: "center" = "center";
    return {
      position: rel,
      bottom: 218,
      height: 0,
      fontWeight: 500,
      fontSize: 11,
      textAlign: center,
      color: "#858585",
    };
  }

  renderLowerFair() {
    const { unit } = this.props;
    const { lower_fair_price, upper_fair_price } = unit;
    const parsed_lower =
      lower_fair_price === upper_fair_price ? parseInt(lower_fair_price) - 50 : parseInt(lower_fair_price);
    return <div style={{ ...this.fairPriceStyle(), right: 95 }}>{dollarFormatter(parsed_lower)}</div>;
  }

  renderUpperFair() {
    const { unit } = this.props;
    const { lower_fair_price, upper_fair_price } = unit;
    const parsed_upper =
      lower_fair_price === upper_fair_price ? parseInt(upper_fair_price) + 50 : parseInt(upper_fair_price);
    return <div style={{ ...this.fairPriceStyle(), left: 95 }}>{dollarFormatter(parsed_upper)}</div>;
  }

  public render() {
    return (
      <div id="price-distribution-graph" style={{ textAlign: "center" }}>
        {this.renderChart()}
        {this.renderLabels()}
        {this.renderLowerFair()}
        {this.renderUpperFair()}
      </div>
    );
  }
}
