
import React from 'react'
import { connect } from 'react-redux';
import { STEPS } from './reducer';
import _ from 'lodash'
import {
  formattedToValue,
  showBasics,
  showBudget,
  showDemographics,
  showGeography,
  showRecommendations,
  savePlan,
  toggleAddToCampaign,
} from './actions';

class BottomNav extends React.Component {

  determineBackClick() {
    let STEPS = this.props.step;
    switch(STEPS) {
      case 'DEMOGRAPHICS':
        this.props.showBasics();
        break;
      case 'GEOGRAPHY':
        this.props.showDemographics();
        break;
      case 'BUDGET':
        this.props.showGeography();
        break;
    }
  }

  isStepReady() {
    let STEPS = this.props.step;
    switch (STEPS) {
     case 'BASICS':
       return (!_.isEmpty(this.props.selected_objectives) && !_.isEmpty(this.props.selected_media_types));
       break;
     case 'DEMOGRAPHICS':
       return !_.isEmpty(this.props.selected_demographics);
       break;
     case 'GEOGRAPHY':
       return this.props.spend_groups.length > 0;
       break;
     case 'BUDGET': 
       return (this.props.spend_groups.length > 0 && !this.props.spend_groups.some(group => group['spend'] <= 0 || ''));
     break;
    }
  }

  handleAddToCampaignClick(e){
    this.props.toggleAddToCampaign();
    this.props.savePlan();
  }

  render() {
    const backButton = (
      <button className="back-button" onClick={this.determineBackClick.bind(this)}>
        <i className="fa fa-chevron"/>
        <span>{"< Back"}</span>
      </button>
    );

    const addToCampaignLink = (
      <div className="bottom-nav-add-link">
        <div className="middle">or</div>
        <div className="link" onClick={this.handleAddToCampaignClick.bind(this)}>Add Units to Existing Campaign</div>
      </div>
    )
    const isStepReady = this.isStepReady();
    return (
      <div className="bottom-nav-panel">
        <div className="bottom-nav">
          { this.props.step != 'BASICS' && backButton}
          <button 
            className={isStepReady ? 'continue-button' : 'disabled-button'}
            onClick={this.props.savePlan}
          >
            <span>{this.props.step == 'BUDGET' ? 'Save Campaign' : 'Continue'}</span>
          </button>
        </div>
        {this.props.step === 'BUDGET' ? addToCampaignLink : '' }
      </div>
    )
  }
}

export default connect(
  ({ planner }) => ({ ...planner }),
  { showBasics, showDemographics, showGeography, showBudget, savePlan, toggleAddToCampaign }
)(BottomNav)
