import React from 'react';
import cs from 'classnames';
import { Modal } from 'react-bootstrap';
import AuthStore from '../../stores/AuthStore';

export default class GoogleButton extends React.Component {
  render() {
    return (
      <form action="/auth/google" id="google-auth-form" method="post">
        <input type="hidden" name="authenticity_token" value={window.CSRF_TOKEN}/>
        <button form="google-auth-form" className="google-login-button">
          <img
            className="google-logo"
            src={window.ASSETS['google-login-logo']}
          />
          <span className="button-text">
            Continue with Google
          </span>
        </button>
      </form>
    )
  }
}
