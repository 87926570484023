import _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import Tooltip from "rc-tooltip";

import GlobalActions from "../../../actions/GlobalActions";

class SpecSheet extends PureComponent<any, any> {
  private MAX_LENGTH = 15;

  constructor(props) {
    super(props);
    this.openUnitEditPopup = this.openUnitEditPopup.bind(this);
    this.renderAssignSpecSheetLink = this.renderAssignSpecSheetLink.bind(this);
  }

  public render() {
    const { unit, campaign_permissions } = this.props;
    if (unit.spec_sheet) {
      return (
        <Tooltip
          placement="bottom"
          trigger={['hover']}
          overlayStyle={{ width: '200px', height: "200px" }}
          overlay={<span style={{ width: "fit-content" }}>Download individual spec sheets or use the checkbox selections and "actions" box (arrow) to mass download spec sheets.</span>}
        >
          <ul>
            <li style={{ fontSize: 11, marginBottom: 5 }}>
              Production Spec
              {campaign_permissions.can_assign_spec_sheet && this.renderAssignSpecSheetLink("change")}
            </li>
            <li>
              <a
                key={unit.id}
                href={unit.spec_sheet.file_url}
                className="btn btn-default"
                title={unit.spec_sheet.comment}
                target="_blank"
                style={{
                  color: unit.spec_sheet_verified_at ? "#ffffff" : "#555555",
                  width: "100%",
                  fontSize: 12,
                  backgroundColor: unit.spec_sheet_verified_at ? "#18b163" : "#ffffff",
                  borderColor: unit.spec_sheet_verified_at ? "#18b163" : "#cccccc",
                }}
              >
                <i
                  className={`fa ${unit.spec_sheet_verified_at ? "fa-check-circle-o" : "fa-cloud-download"}`}
                  style={{
                    marginRight: 5,
                    fontSize: 15,
                  }}
                />{" "}
                {this.specSheetLabel(unit.spec_sheet)}
              </a>
            </li>
          </ul>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          placement="bottom"
          trigger={['hover']}
          overlayStyle={{ width: '200px', height: "200px" }}
          overlay={<span style={{ width: "fit-content" }}>Download individual spec sheets or use the checkbox selections and "actions" box (arrow) to mass download spec sheets.</span>}
        >
          <ul>
            <li style={{ fontSize: 11, marginBottom: 5 }}>Production Spec</li>
            <li>{campaign_permissions.can_assign_spec_sheet && this.renderAssignSpecSheetLink("Assign Spec Sheet")}</li>
          </ul>
        </Tooltip>
      );
    }
  }

  private renderAssignSpecSheetLink(text) {
    return (
      <span>
        (<a onClick={this.openUnitEditPopup}>{text}</a>)
      </span>
    );
  }

  private openUnitEditPopup() {
    const { unit, campaignId } = this.props;
    GlobalActions.openPopup("edit_unit", { unit, campaignId, initialTab: "spec_sheet" });
  }

  private specSheetLabel(spec_sheet) {
    if (spec_sheet.comment) {
      return _.truncate(spec_sheet.comment, { length: this.MAX_LENGTH });
    } else {
      return "Download";
    }
  }
}

export default SpecSheet;
