import "./modal.css";
import React, { ReactNode, useEffect } from "react";

export interface IModalProps {
  children: React.ReactNode;
  backdrop?: boolean;
  show?: boolean;
  onHide?: () => void;
  onBackdropClick?: () => void;
}

export interface IModalBodyProps {
  children?: ReactNode | string;
}

export interface IModalFooterProps {
  children?: ReactNode | string;
}

export const Modal = ({ children, backdrop, show, onHide, onBackdropClick }: IModalProps) => {
  const getOpenClass = (prefix: string) => {
    return show ? `${prefix}--open` : "";
  };

  useEffect(() => {
    if (show === false && onHide) {
      onHide();
    }
  }, [show]);

  return (
    <>
      <div className={`ui-modal ${getOpenClass("ui-modal")}`}>{children}</div>
      {backdrop && (
        <div className={`ui-modal__backdrop ${getOpenClass("ui-modal__backdrop")}`} onClick={onBackdropClick}></div>
      )}
    </>
  );
};

export const ModalBody = ({ children }: IModalBodyProps) => {
  return <div className="ui-modal__body">{children}</div>;
};

export const ModalFooter = ({ children }: IModalFooterProps) => {
  return <div className="ui-modal__footer">{children}</div>;
};
