import { Market, MarketGeojson, MarketsGeojson } from './market';

export function mapMarkets(markets: any[]): MarketsGeojson {
  return {
    type: 'FeatureCollection',
    features: markets.map(mapMarket)
  };
}

function mapMarket(market: Market): MarketGeojson {
  return {
    geometry: {
      type: 'Point',
      coordinates: [market.center_lon, market.center_lat]
    },
    properties: market
  }
}
