import React from 'react';
import createClass from 'create-react-class';
import { BootstrapTable } from 'react-bootstrap-table';
import AddCompanyModalBody from './AddCompanyModalBody'
import Group from './Group'
import { browserHistory } from 'react-router-legacy';
import { get, post, put } from '../../utils/api'
import * as Actions from '../Companies/actions'
import { connect } from 'react-redux';

const CompanyGroups = createClass({
  displayName: 'CompanyGroups',

  getInitialState() {
    const { location } = this.props
    const { id } = location.query

    return {
      group_id: id,
    }
  },

  componentDidMount() {
    this.fetchCompanies()
  },

  fetchCompanies() {
    const { getCompaniesFromGroup } = this.props
    const { group_id } = this.state
    getCompaniesFromGroup(group_id)
  },

  onDeleteRow(rows) {
    const { group_id } = this.state
    const { deleteCompanyFromGroup } = this.props
    deleteCompanyFromGroup(group_id, rows)
  },

  onAddRow(row) {
    const { group_id } = this.state
    const { addCompanyToGroup } = this.props
    addCompanyToGroup(group_id, [row])
  },

  insertModal(onModalClose, onSave, columns, validateState, ignoreEditable) {
    const column = columns.filter(c => c.field == 'name')
    const attr = {
      onModalClose, onSave, columns: column, validateState, ignoreEditable
    };
    return (
      <AddCompanyModalBody { ...attr } />
    )
  },

  render() {
    const { group_id } = this.state
    const { companies } = this.props
    
    return (
      <div className="container create-campaign">
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <b>Company Groups</b>
            </div>
            <Group
              group={companies.company_groups.find(g => g.id === Number(group_id))}
              companies={companies.companies}
              scrape={companies.scrape}
            />
            <BootstrapTable
              data={companies.companies}
              options={{ onDeleteRow: this.onDeleteRow, onAddRow: this.onAddRow, insertModal: this.insertModal }}
              striped
              hover
              deleteRow
              insertRow
              search
              multiColumnSearch
              selectRow={{ mode: 'checkbox' }} >
              <TableHeaderColumn isKey dataField="id" dataSort searchable={ false }>Company ID</TableHeaderColumn>
              <TableHeaderColumn dataField="name" dataSort>Name</TableHeaderColumn>
              <TableHeaderColumn dataField="linkedin_profiles_count" dataSort dataFormat={value => value || 0}>Profiles Count</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    )
  }
})

const mapProps = ({ companies }) => ({
  companies
})

const mapActions = {
  getCompaniesFromGroup: Actions.asyncFetchCompaniesFromGroup,
  addCompanyToGroup: Actions.asyncAddCompanieToGroup,
  deleteCompanyFromGroup: Actions.asyncDeleteCompanieFromGroup
}

export default connect(mapProps, mapActions)(CompanyGroups)