import { get, put } from "../../../utils/api";

export const FETCH_ACTIVITY_LOGS = 'ACTIVITY.FETCH_ACTIVITY_LOGS'
export const FETCH_ACTIVITY_LOGS_SUCCESS = 'ACTIVITY.FETCH_ACTIVITY_LOGS_SUCCESS'
export const RESTORE_CAMPAIGN_UNIT = 'ACTIVITY.RESTORE_CAMPAIGN_UNIT'

export const fetchActivityLogs = () => async (dispatch, getState) => {
  const { campaignId } = getState().campaign

  dispatch({
    type: FETCH_ACTIVITY_LOGS
  })

  const { activity_logs } = await get(`/api/v1/logs?campaign_id=${campaignId}`)
  dispatch({ type: FETCH_ACTIVITY_LOGS_SUCCESS, payload: { activity_logs }})
}

export const restoreCampaignUnit = (campaign_unit_token) => async (dispatch) => {
  const { restored_activity_log } = await put(`/api/v1/campaign_units/${campaign_unit_token}/restore`)
  dispatch({ type: 'UNIT_RESTORED', payload: { restored_activity_log }})
  dispatch(fetchActivityLogs())
}

export const batchRestoreCampaignUnit = (data) => async (dispatch) => {
  const { restored_activity_logs } = await put(`/api/v1/campaign_units/batch_restore`, {unit_ids: data.unit_ids, campaign_id: data.campaign_id})
  restored_activity_logs.map (restored_activity_log => {
    dispatch({ type: 'UNIT_RESTORED', payload: { restored_activity_log }})
  })
  dispatch(fetchActivityLogs())
}
