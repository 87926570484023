const initialState = []

const convertStatus = ({ status }) => {
  const values = {
    'on': true,
    'off': false,
    'conditional': true
  }
  return values[status]
}

const getBooleans = (features) => {
  return features.reduce((acc, value) => {
    value.status = convertStatus(value)
    acc.push(value)
    return acc
  }, []);
}

export default (state = initialState, { type, feature_flags = [] }) => ({
  FETCH_FEATURE_FLAGS: [...state, ...getBooleans(feature_flags)]
}[type] || state)
