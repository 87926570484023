import { post } from "../utils/api";

export const createAndAssignSpecSheet = spec_sheet => async dispatch => {
  try {
    const created_spec_sheet = await post("/api/v1/spec_sheets", spec_sheet);
    return created_spec_sheet;
  } catch (error) {
    throw error;
  }
};

export const assignSpecSheet = (unit_id, spec_sheet_id) => async dispatch => {
  try {
    await post(`/api/v1/spec_sheets/${spec_sheet_id}/assign`, { unit_id });
  } catch (error) {
    throw error;
  }
};

export const saveUnitSpecSheetVerified = (unit_id, data) => async dispatch => {
  return await post(`/api/v1/units/${unit_id}/verify_spec_sheet`, { ...data });
};
