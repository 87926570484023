import React from "react";
import ReactGA from "react-ga";
import { generatePath } from "react-router-dom";
import {
  IndexRoute,
  Route,
  browserHistory,
  hashHistory
} from "react-router-legacy";

import Activity from "./components/Activity";
import EagleEye from "./components/Admin/EagleEye";
import Advertisers from "./components/Advertisers";
import BrowseTab from "./components/Browse";
import CampaignTab from "./components/Campaign";
import CampaignCheckout from "./components/Campaign/CampaignCheckout";
import CampaignLogin from "./components/Campaign/Login";
import ConfirmPricing from "./components/CampaignSupplierContracts/ConfirmPricing";
import Careers from "./components/Careers";
import Companies from "./components/Companies";
import CompanyGroups from "./components/CompanyGroups";
import NewCompanyGroup from "./components/CompanyGroups/New";
import AccountNameProgress from "./components/CompanyGroups/Progress";
import Contracts from "./components/Contracts";
import DemoRequest from "./components/DemoRequest";
import EmailTemplates from "./components/EmailTemplates";
import CreateEmailTemplate from "./components/EmailTemplates/Create";
import ShowEmailTemplate from "./components/EmailTemplates/Show";
import ExportTemplateEdit from "./components/ExportTemplates/edit";
import ExportTemplatesIndex from "./components/ExportTemplates/index";
import Greylabel from "./components/Greylabel";
import GreylabelCampaignRequest from "./components/Greylabel/CampaignRequest";
import GreylabelCampaignRequestThanks from "./components/Greylabel/CampaignRequest/Thanks";
import GreylabelCampaigns from "./components/Greylabel/Campaigns";
import HelpForm from "./components/HelpForm";
import IntroTool from "./components/IntroTool";
import Inventory from "./components/Inventory";
import Layout from "./components/Layout";
import Leads from "./components/Leads";
import LookAlikesTool from "./components/LookAlikesTool";
import Main from "./components/Main";
import OutdoorCompanyList from "./components/OutdoorCompanyList";
import PagesRoute from "./components/PagesRoute";
import Planner from "./components/Planner";
import Reports from "./components/Reports";
import SentEmails from "./components/SentEmails";
import Settings from "./components/Settings";
import SmartDevices from "./components/SmartDevices";
import Tasks from "./components/Tasks";
import ThanksForSubmitting from "./components/ThanksForSubmitting";
import UnitImporter from "./components/UnitImporter";
import Users from "./components/Users";
import UserSettings from "./components/Users/Settings";
import VendorDashboard from "./components/VendorDashboard";
import CampaignBuilder from "./components/Vendors/CampaignBuilder/index";
import VendorRegistration from "./components/Vendors/Registration";
import VendorsSetup from "./components/Vendors/Setup";
import VendorCampaigns from "./components/Vendors/VendorCampaigns";
import { logPageView } from "./utils/gaHelper";

export const ADQUICK_REQUESTS_PATH = "adquick_requests";
export const CAMPAIGN_PATH = "campaigns/:campaign_id";
export const CAMPAIGN_TAB_SUBTAB_PATH = "campaigns/:campaign_id/:page/:step";
const OLD_SUPPLIER_TASKS_PATH =
  "adquick_requests/:campaign_id/supplier_tasks/:supplier_task_id";
export const SUPPLIER_TASKS_PATH =
  "adquick_requests/:campaign_id/supplier_tasks/:supplier_task_id/review";

export const goToRoute = (path, params, options = {}) => {
  let full_path = generatePath(path, params);
  if (options.anchor) {
    full_path = full_path + options.anchor;
  }

  if (options.new_tab) {
    window.open(`/${full_path}`, "_blank");
  } else {
    browserHistory.push(`/${full_path}`);
  }
};

const routes = () => {
  ReactGA.initialize("UA-84047605-1");

  return (
    <Route path="/" component={Layout} history={hashHistory} onEnter={logPageView} onChange={logPageView}>
      <Route component={Main}>
        <Route path="welcome" component={BrowseTab} />
        <Route path="help" component={HelpForm} />
        <Route path="demo" component={DemoRequest} />
        <Route path="search" component={BrowseTab} />
        <Route path="demographics" component={BrowseTab} />
        <Route path="contact" component={BrowseTab} />
        <Route path="request" component={BrowseTab} />
        <Route path="units/:unit_id" component={BrowseTab} />
        <Route path="share" component={BrowseTab} />
        <Route path="vendors/signup" component={VendorRegistration} />
        <Route path="vendors/setup" component={VendorsSetup} />
        <Route path="vendor_leads" component={Advertisers} />
        <Route path="thanks-for-submitting" component={ThanksForSubmitting} />
        <Route path="confirm_pricing" component={ConfirmPricing} />
      </Route>
      <Route path="email_templates" component={EmailTemplates} />
      <Route path="email_templates/new" component={CreateEmailTemplate} />
      <Route path="email_templates/:email_template_id" component={ShowEmailTemplate} />
      <Route path="businesses" component={Companies} />
      <Route path="intro_tool" component={IntroTool} />
      <Route path="businesses/:company_id" component={Companies} />
      <Route path="company_groups" component={CompanyGroups} />
      <Route path="company_groups/new" component={NewCompanyGroup} />
      <Route path="company_groups/:company_group_id/progress" component={AccountNameProgress} />
      <Route path="sent_emails" component={SentEmails} />
      <Route path="adquick_requests/:campaign_id" component={CampaignTab} />
      <Route path="adquick_requests/:campaign_id/:page" component={CampaignTab} />
      <Route path={OLD_SUPPLIER_TASKS_PATH} onEnter={({ params }) => goToRoute(SUPPLIER_TASKS_PATH, params)} />
      <Route path="vendor_campaigns" component={VendorCampaigns} />
      <Route path="campaign_builder" component={CampaignBuilder} />
      <Route path="campaign_builder/:campaign_builder_token" component={CampaignBuilder} />
      <Route path="campaign_builder/:campaign_builder_token/:step" component={CampaignBuilder} />
      <Route path="campaigns/:campaign_id/cart" component={CampaignCheckout} />
      <Route path={CAMPAIGN_PATH} component={CampaignTab} />
      <Route path="campaigns/:campaign_id/login" component={CampaignLogin} />
      <Route path="campaigns/:campaign_id/units/:unit_id" component={CampaignTab} />
      {/* holder */}
      <Route path="campaigns/:campaign_id/cart" component={CampaignCheckout} />
      {/* holder */}
      <Route path="campaigns/:campaign_id/:page" component={CampaignTab} />
      <Route path={CAMPAIGN_TAB_SUBTAB_PATH} component={CampaignTab} />
      <Route path="inventory" component={Inventory} />
      <Route path="inventory/:unit_id" component={Inventory} />
      <Route path="old_planner" component={Planner} />
      <Route path="old_planner/:token" component={Planner} />
      <Route path="reports" component={Reports} />
      <Route path="leads" component={Leads} />
      <Route path="dash" component={VendorDashboard} />

      <Route path="team" component={Greylabel}>
        <IndexRoute component={GreylabelCampaignRequest} />
        <Route path="campaigns" component={GreylabelCampaigns} />
        <Route path="campaigns/create" component={GreylabelCampaignRequest} />
        <Route path="campaigns/thanks" component={GreylabelCampaignRequestThanks} />
        <Route path="campaigns/:campaign_id" component={CampaignTab} />
        <Route path="campaigns/:campaign_id/login" component={CampaignLogin} />
        <Route path="campaigns/:campaign_id/units/:unit_id" component={CampaignTab} />
        <Route path="campaigns/:campaign_id/:page" component={CampaignTab} />
        <Route path="campaigns/:campaign_id/:page/:step" component={CampaignTab} />
      </Route>

      <Route path="unit_importer/:file_id" component={UnitImporter} />

      <Route path="export_templates" component={ExportTemplatesIndex} />
      <Route path="export_templates/new" component={ExportTemplateEdit} />
      <Route path="export_templates/:id" component={ExportTemplateEdit} />
      <Route path="smart_devices" component={SmartDevices} />
      <Route path="/look_alikes" component={LookAlikesTool} />
      <Route path="users" component={Users} />
      <Route path="users/:user_id" component={UserSettings} />
      <Route path="activity" component={Activity} />
      <Route path="street_view" component={EagleEye} />
      <Route path="contracts" component={Contracts} />
      <Route path="outdoor-companies" component={OutdoorCompanyList} />
      <Route path="settings" component={Settings} />
      <Route path="tasks" component={Tasks} />
      <Route path="careers" component={Careers} />
      <Route path="careers/:job" component={Careers} />
      <Route path="*" component={PagesRoute} />
    </Route>
  );
};

export default routes;
