import React from 'react'
import PropTypes from 'prop-types';
import Select from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';
import cs from "classnames";
import GlobalActions from '../../../actions/GlobalActions';
import validate from "validate.js";
import { get, post } from '../../../utils/api';
import { removeUnit } from './../actions'
import { connect } from 'react-redux';
import { labels, constraints } from "./formHelper";

class UnitForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      constraints,
      unit: this.props.unit,
      values: { supplier_id: this.props.supplierToken },
      media_types: [{ id: '', name: '' }],
      screen_subtypes: [{id: '', name: ''}],
      installers: [{ id: '', name: ''}],
      errors: {}
    }
    this.onRemove = this.onRemove.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onAddAdvertiser = this.onAddAdvertiser.bind(this);
    this.onAdvertiserChange = this.onAdvertiserChange.bind(this);
    this.getAdvertisers = this.getAdvertisers.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.getMediaTypes();
    await this.getInstallers();
    await this.getScreenSubtypes();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.unit.previous_advertisers != this.props.unit.previous_advertisers) {
      this.setState({ unit: this.props.unit });
    }
  }

  async getMediaTypes() {
    const types = await get(`/api/v1/media_types?include_subtypes=true`);
    const subtypes = {};
    types.data.media_types.forEach(mt => {
      subtypes[mt.id] = mt.media_subtypes;
    });
    this.setState({
      media_types: [
        {id: '', name: ''},
        ...types.data.media_types
      ],
      media_subtypes_by_type: subtypes
    });
  }

  async getScreenSubtypes() {
    const response = await fetch("/api/v1/media_types/screen_subtypes")
    const responseJson = await response.json()
    const data = responseJson.data.screen_subtypes
    this.setState({
      screen_subtypes: [
        {id: '', name: ''},
        ...data
      ]
    })
  }

  async getInstallers() {
    const { supplier_id } = this.props.unit;
    if (supplier_id != null) {
      const installers = await get(`/api/v1/suppliers/${supplier_id}/installers`);
      this.setState({
        installers: [
          {id: '', name: ''},
          ...installers
        ]
      });
    }
  }

  async onRemove(unit, e) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete the unit from your inventory?")) {
      const response = await this.props.removeUnit(unit.id);
      if (response.status == 200) {
        GlobalActions.closePopup();
        GlobalActions.showMessage("Removed Unit");
      } else {
        GlobalActions.showError("Could not remove unit");
      }
    }
  }

  render() {
    return this.renderForm();
  }

  renderForm() {
    return (
      <div>
        <div className="row">
          {this.renderDetailsForm()}
          {this.renderPriceForm()}
          {this.renderDescriptionForm()}
        </div>
        {this.renderFooter()}
      </div>
    )
  }

  renderDetailsForm() {
    return (
      <div className="col-md-4">
        {this.renderInput("supplier_face_id")}
        {this.renderInput("name")}
        {this.renderInput("size")}
        {this.renderInput("address")}
        {this.renderInput("lat")}
        {this.renderInput("lon")}
        {this.renderSelect("direction")}
        {this.renderSelect("orientation")}
        {this.renderCreatableSelect("previous_advertisers")}
        {this.renderSelect("installer_id")}
      </div>
    )
  }

  renderPriceForm() {
    return (
      <div className="col-md-4">
        {this.renderInput("rate_card_price")}
        {this.renderInput("price")}
        {this.renderInput("floor_price")}
        {this.renderInput("installation_cost")}
        {this.renderInput("production_cost")}
        {this.renderInput("impressions")}
        {this.renderInput("illuminated")}
        {this.renderInput("tab_panel_id")}
        {this.renderCheckbox("has_restrictions")}
        {this.renderCheckbox("is_cannabis_friendly")}
        {this.renderCheckbox("has_extensions")}
        {this.renderTextArea("extensions_description")}
        {this.renderTextArea("restrictions_description")}
      </div>
    )
  }

  renderDescriptionForm() {
    return (
      <div className="col-md-4">
        {this.renderTextArea("description")}
        {this.renderSelect("geojson_id")}
        {this.renderSelect("screen_type")}
        {this.renderSelect("unit_screen_subtype_id")}
        {this.renderInput("spot_length")}
        {this.renderInput("total_spots")}
        {this.renderSelect("media_type_id")}
        {this.renderSelect("media_subtype_id")}
        {this.props.user.is_supplier &&
          <div>
            {this.renderInput("location_name")}
            {this.renderInput("revenue_target")}
          </div>
        }
        {this.renderInput("venue")}
        {this.props.user.is_admin &&
          <div>
            {this.renderRadioButton("has_attribution")}
          </div>
        }
      </div>
    )
  }

  renderInput(attr) {
    const { unit, errors } = this.state;
    const hasErrors = !_.isEmpty(errors[attr]);
    if(attr === "price") {
      attr = !!unit.unit_price ? "unit_price" : "price";
    }
    return (
      <div className={cs("form-group", {"has-error": hasErrors })}>
        <label className="control-label">{labels(attr,"input")}</label>
        <input
          className="form-control"
          type="text"
          name={attr}
          defaultValue={unit[attr]}
          onChange={this.onInputChange}
        />
        {this.renderFieldErrors(attr)}
      </div>
    )
  }

  renderNumberInput(attr) {
    const { unit, errors } = this.state;
    const hasErrors = !_.isEmpty(errors[attr]);
    if(attr === "price") {
      attr = !!unit.unit_price ? "unit_price" : "price";
    }
    return (
      <div className={cs("form-group", {"has-error": hasErrors })}>
        <label className="control-label">{labels(attr,"input")}</label>
        <input
          className="form-control"
          type="number"
          name={attr}
          defaultValue={unit[attr]}
          onChange={this.onInputChange}
        />
        {this.renderFieldErrors(attr)}
      </div>
    )
  }

  renderSelect(attr) {
    const { unit, errors } = this.state;
    const options = this.optionsFor(attr);
    const defaultValue = options.find(o => o.value == unit[attr]);
    const hasErrors = !_.isEmpty(errors[attr]);
    return (
      <div className={cs("form-group", {"has-error": hasErrors })}>
        <label className="control-label">{labels(attr,"input")}</label>
        <Select
          value={defaultValue}
          options={options}
          onChange={(value) => this.onSelectChange(attr, value)}
        />
        {this.renderFieldErrors(attr)}
      </div>
    );
  }

  renderCreatableSelect(attr) {
    return (
      <div className="form-group">
        <label className="control-label">{labels(attr,"input")}</label>
        <AsyncCreatableSelect
          onChange={this.onAdvertiserChange}
          loadOptions={this.getAdvertisers}
          defaultOptions={true}
          placeholder="Start typing to search…"
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          promptTextCreator={e => `Add advertiser "${e}"`}
          onCreateOption={this.onAddAdvertiser}
          cacheOptions={false}
        />
      </div>
    );
  }

  async getAdvertisers(text) {
    const options = this.optionsFor("previous_advertisers");
    if(!text) { return options; }
    const match = options.filter(o => o.label.toLocaleLowerCase().includes(text.toLocaleLowerCase()) );
    if(_.isEmpty(match)) {
      if(text.length < 3) { return []; }
      const { advertisers } = await get(`/api/v1/merchants/search?q=${text}`);
      const options = advertisers.map(a => {
        return { label: a.name, value: a.id };
      })
      return options;
    }
    return match;
  }

  async onAdvertiserChange(advertiser, action) {
    const { unit } = this.state;
    try {
      await post(`/api/v1/advertisers/${advertiser.value}/create_previous_advertiser`, { unit_id: unit.id });
      const newUnitState = {
        ...unit,
          previous_advertisers: [
            ...unit.previous_advertisers,
            { id: advertiser.value, name: advertiser.label }
          ]
      };
      this.setState({ unit: newUnitState }, async () => {
        await this.loadData();
        this.showAdvertiserAddSuccess(advertiser.label)
      });
    } catch (error) {
      this.showAdvertiserAddError();
    }
  }

  async onAddAdvertiser(value) {
    const { unit } = this.state;
    try {
      const { advertiser } = await post('/api/v1/advertisers', { name: value });
      await post(`/api/v1/advertisers/${advertiser.id}/create_previous_advertiser`, { unit_id: unit.id });
      this.setState({
        unit: {
          ...unit,
          previous_advertisers: [...unit.previous_advertisers, advertiser]
        }
      }, async () => {
        await this.props.loadData();
        this.showAdvertiserAddSuccess(value);
      });
    } catch (error) {
      this.showAdvertiserAddError();
    }
  }

  showAdvertiserAddSuccess(advertiser) {
    return GlobalActions.showMessage(`${advertiser} successfully added.`);
  }

  showAdvertiserAddError() {
    return GlobalActions.showError("An error occurred.");
  }

  renderTextArea(attr) {
    const { unit } = this.state;
    return (
      <div className="form-group">
        <label className="control-label">{labels(attr,"input")}</label>
        <textarea
          style={{resize: "none"}}
          className="form-control"
          defaultValue={unit[attr]}
          name={attr}
          onChange={this.onInputChange}
          rows="3"
        />
      </div>
    );
  }

  renderCheckbox(attr) {
    const { unit } = this.state;
    return (
      <div className="form-group">
        <label className="control-label">{labels(attr,"checkbox")}</label>
        <input
          style={{height:'auto',width:'auto',marginRight:'10px'}}
          className="form-control"
          type="checkbox"
          name={attr}
          checked={unit[attr]}
          onChange={this.onInputChange}
        />
      </div>
    );
  }

  renderRadioButton(attr) {
    const { unit } = this.state;
    return (
      <div>
        <label className="control-label">{labels(attr, "radiobutton")}</label>
        <div className="form-check">
          <input
            style={{ height: 'auto', width: 'auto', marginRight: '10px' }}
            className="form-check-input"
            type="radio"
            value={true}
            name={attr}
            checked={unit[attr]}
            onChange={this.onInputChange} />
          Yes
        </div>
        <div className="form-check">
            <input
              style={{ height: 'auto', width: 'auto', marginRight: '10px' }}
              className="form-check-input"
              type="radio"
              value={false}
              name={attr}
              checked={!unit[attr] && unit[attr] == false} // not null and false
              onChange={this.onInputChange} />
          No
        </div>
        <div className="form-check">
            <input
              style={{ height: 'auto', width: 'auto', marginRight: '10px' }}
              className="form-check-input"
              type="radio"
              value={''}
              name={attr}
              checked={unit[attr] == null}
              onChange={this.onInputChange} />
          Unknown
        </div>
      </div>
    );
  }

  renderFooter() {
    const { saving, isCreate, onHide, user } = this.props;
    const { unit } = this.state;
    const onCloseAction = isCreate ? onHide : GlobalActions.closePopup;
    return (
      <div className="row">
        <div className="col-md-12">
          <button
            className={cs("btn btn-primary", {"disabled": saving })}
            disabled={saving}
            onClick={this.onSubmit}
          >
            {saving ? "Saving..." : "Save"}
          </button>
          {!isCreate && user.permissions.can_remove_unit &&
            <button
              className="btn btn-danger"
              style={{ marginLeft: 10 }}
              onClick={e => this.onRemove(unit, e)}
            >
              Remove Unit
            </button>
          }
          <button
            className="btn btn-default"
            style={{ marginLeft: 10 }}
            onClick={onCloseAction}
          >
            Close
          </button>
          {!isCreate && user.admin &&
            <a target="_blank" style={{ marginLeft: '20px' }} href={`/admin/units/${unit.slug}`}>Open Admin Form</a>
          }
        </div>
      </div>
    )
  }

  renderFieldErrors(field) {
    const { errors } = this.state;
    if(_.isEmpty(errors[field])) { return; }
    const fieldErrors = errors[field];
    return fieldErrors.map((error, idx) => this.renderErrorHelper(field, error, idx));
  }

  renderErrorHelper(field, error, idx) {
    return (
      <span
        key={`${field}-error${idx}`}
        className="help-block"
        style={{color:"red"}}
      >
        {error}
      </span>
    )
  }

  optionsFor(attr) {
    const { media_types, media_subtypes_by_type, unit, installers, screen_subtypes } = this.state;
    const unit_previous_advertisers = unit.previous_advertisers || [];
    const previous_advertisers = unit_previous_advertisers.map(p => {
      return { label: _.capitalize(p.name), value: p.id }
    });
    const media_type_id = media_types.map(m => {
      return { label: m.name, value: m.id }
    });

    const sub_types = (media_subtypes_by_type ? media_subtypes_by_type[unit.media_type_id] : [])
    let media_subtype_id = []
    if (sub_types) {
      media_subtype_id = sub_types.map(s => {
        return { label: s.name, value: s.id };
      });
    }

    const unit_screen_subtype_id = screen_subtypes.filter(s => s.screen_type === unit.screen_type || s.id == '').map(s => {
      return { label: s.name, value: s.id };
    });

    const geojson_id = this.props.geoJsons.map(g => {
      return { label: g.name, value: g.id }
    });
    const installer_id = installers.map(i => {
      return { label: i.name, value: i.id }
    })

    const orientation = [
      {label: 'Left', value: 'LHR'},
      {label: 'Right', value: 'RHR'},
      {label: 'Center', value: 'CR'}
    ]
    const direction = [
      {label: 'North', value: 'N'},
      {label: 'South', value: 'S'},
      {label: 'East', value: 'E'},
      {label: 'West', value: 'W'},
      {label: 'Northeast', value: 'NE'},
      {label: 'Northwest', value: 'NW'},
      {label: 'Southeast', value: 'SE'},
      {label: 'Southwest', value: 'SW'},
      {label: 'Multiple', value: 'Multiple'}
    ]
    const options = {
      screen_type: [
        {label: "Static", value: "static"},
        {label: "Digital", value: "digital"}
      ],
      media_type_id,
      media_subtype_id,
      unit_screen_subtype_id,
      geojson_id,
      previous_advertisers,
      installer_id,
      orientation,
      direction
    }

    return options[attr];
  }

  onInputChange(event) {
    const { unit } = this.state;
    const { value, checked, type, name } = event.target;
    let stateAttribute = { [name]: value }
    if(!value && type == 'radio') {
      return this.setState({
        unit: {
          ...unit,
          ...{[name]: null}
        }
      })
    };
    if (type == 'checkbox') { stateAttribute[name] = checked }
    if (type == 'radio') { stateAttribute[name] = JSON.parse(value) }
    this.setState({
      unit: {
        ...unit,
        ...stateAttribute
      }
    });
  }

  onSelectChange(attr, value) {
    const { unit } = this.state;
    if(!value) {
      return this.setState({
        unit: {
          ...unit,
          ...{[attr]: null}
        }
      });
    }
    this.setState({
      unit: {
        ...unit,
        ...{[attr]: value.value}
      }
    });
  }

  onSubmit() {
    const { unit, constraints } = this.state;
    const { onSubmit } = this.props;
    let errors
    if (unit.is_package) {
      const conditionalConstraints = constraints.supplier_face_id = {}
      errors = validate(unit, conditionalConstraints) || {};
    } else {
      errors = validate(unit, constraints) || {};
    }
    if(this.unitHasErrors(errors)) { return this.setState({ errors }); }
    onSubmit(this.state.unit);
  }

  unitHasErrors(unitErrors = null) {
    const { errors } = this.state;
    const toValidate = unitErrors || errors;
    const fieldKeys = Object.keys(toValidate);
    const hasErrors = fieldKeys.find(key => !_.isEmpty(toValidate[key]));
    return hasErrors;
  }
};

UnitForm.propTypes = {
  unit: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  saving: PropTypes.bool,
}

export default connect(
  null,
  { removeUnit }
)(UnitForm);
