import "rc-tooltip/assets/bootstrap.css";

import * as React from "react";
import { Component } from "react";
import Tooltip from "rc-tooltip";

export default class FairPriceIcon extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  pinImgUrl() {
    const { unit } = this.props;
    const { lower_fair_price, upper_fair_price, campaign_unit_price, price } = unit;
    const current_price = campaign_unit_price ? campaign_unit_price : price;

    const icons = {
      high: "https://adquick-public.s3-us-west-2.amazonaws.com/high-price-icon.png",
      low: "https://adquick-public.s3-us-west-2.amazonaws.com/low-price-icon.png",
      fair: "https://adquick-public.s3-us-west-2.amazonaws.com/price-icon.png",
      none: "https://adquick-public.s3-us-west-2.amazonaws.com/price-icon-gray.png",
    };

    if (!!!current_price || !!!upper_fair_price || !!!lower_fair_price) {
      return icons.none;
    }
    if (current_price > upper_fair_price) {
      return icons.high;
    }
    if (current_price < lower_fair_price) {
      return icons.low;
    }
    return icons.fair;
  }

  renderOverlay() {
    const scoreDetails = this.unitScoring();

    if (scoreDetails) {
      return (
        <div className="mini-chart-area">
          <img src="https://adquick-public.s3-us-west-2.amazonaws.com/curve.png" style={{ width: 100 }} />
          {this.renderChartPin(scoreDetails)}
          <div style={{ textAlign: "center" }}>{scoreDetails.label}</div>
        </div>
      );
    } else {
      return (
        <div style={{ maxWidth: 160 }}>
          <div style={{ textAlign: "center" }}>Not enough similar units to calculate price comparison.</div>
        </div>
      );
    }
  }

  unitScoring() {
    const { unit } = this.props;
    const { lower_fair_price, upper_fair_price, campaign_unit_price, price } = unit;
    const current_price = campaign_unit_price ? campaign_unit_price : price;

    if (!!!current_price || !!!lower_fair_price || !!!upper_fair_price) {
      return false;
    }

    if (current_price < lower_fair_price) {
      return {
        label: "Great Price",
        right: "78%",
        top: 9,
      };
    }
    if (current_price > upper_fair_price) {
      return {
        label: "Above Market",
        right: "21%",
        top: 6,
      };
    }
    return {
      label: "Good Price",
      right: "51%",
      top: -18,
    };
  }

  renderChartPin(scoreDetails) {
    const pinUrl = "https://adquick-public.s3-us-west-2.amazonaws.com/price-ellipse.png";
    const rel: "relative" = "relative";
    const pinStyle = {
      width: 6,
      borderRadius: 6,
      position: rel,
      top: scoreDetails.top,
      right: scoreDetails.right,
    };
    return <img src={pinUrl} style={pinStyle} />;
  }

  renderIcon() {
    const { iconSize } = this.props;

    return (
      <Tooltip overlayClassName="fair-price-icon" placement="bottom" overlay={this.renderOverlay()}>
        <img src={this.pinImgUrl()} style={{ height: iconSize }} />
      </Tooltip>
    );
  }

  public render() {
    const { style, className } = this.props;

    return (
      <div id="fair-price-icon" style={{ ...style, cursor: "pointer" }} className={className}>
        {this.renderIcon()}
      </div>
    );
  }
}
