import styled from "styled-components";
import { LabelWrapper } from "../Label";

export const Container = styled.div`

  ${LabelWrapper} {
    padding: 16px 0 9px 0;
  }
`;

export default Container;
