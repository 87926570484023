import React, { Component } from 'react';
import Map from '../UnitMap/Map'

class UnitMapTab extends Component {

  render(){
    const { unit } = this.props;
    if (!unit.lat || !unit.lon) return <div className="message_error">Unit is missing location data</div>;
    return (
      <Map unit={unit} />
    )
  }

}

export default UnitMapTab
