import React from 'react';
import { Modal } from 'react-bootstrap';
import AuthStore from '../../stores/AuthStore';
import GoogleButton from './GoogleButton'
import GlobalActions from '../../actions/GlobalActions';
import ResetPasswordModal from './ResetPasswordModal';

export default class LoginModal extends React.Component {

  constructor(props) {
    super(props);
    const auth = AuthStore.getState();
    const { errors } = this.props;
    this.state = {
      auth: auth,
      errors: errors || '',
      step: 1,
      email: '',
      invalidEmail: false,
      forgotPassword: false
    };
    this.onForgotPassword = this.onForgotPassword.bind(this);
    this.hideUnlessForce = this.hideUnlessForce.bind(this);
    this.onAuthChange = this.onAuthChange.bind(this);
    this.onOpenSignup = this.onOpenSignup.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.renderResetPasswordModal = this.renderResetPasswordModal.bind(this);
    this.onBackFromForgot = this.onBackFromForgot.bind(this);
    this.emailInput;
  }

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ];
    const search = window.location.search;
    if (search === "?invalid_token") {
      this.setState({
        errors: "Your login link is invalid or expired"
      }, () => {
        window.history.pushState({}, document.title, "/");
      });
    }
  }

  validateEmail() {
    const { email } = this.state;
    const match = email.match(/\S+@\S+\.\S/);
    return match;
  }

  onForgotPassword() {
    this.setState({
      forgotPassword: true
    })
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  componentWillReceiveProps(nextProps) {
    const { errors } = this.state;
    if (errors == '' || errors == null || typeof errors === 'undefined') {
      this.setState({ errors: nextProps.errors });
    }
  }

  onAuthChange(event) {
    const auth = AuthStore.getState();
    this.setState({ auth: AuthStore.getState() });

    if (event == 'auth:signin_error') {
      const { errors } = AuthStore.getState();
      this.setState({ errors });
    }

    if (event == 'auth:signin') {
      if (ahoy) {
        const visitor_token = ahoy.getVisitorToken();
        const user = auth.user || {};
        const { id, name, email, created_at, company, is_admin, is_supplier } = user;
        const properties = { id, email, name, created_at, company, is_admin, is_supplier }
      }
      const { onHide } = this.props;
      if (onHide) {
        onHide();
      }
    }
  }

  onSubmit(e) {
    const { email, password, step } = this.state;
    e.preventDefault();
    if (step === 1) {
      if (this.validateEmail()) {
        this.setState({ step: 2 });
      } else {
        this.setState({ invalidEmail: true })
      }
    } else {
      AuthStore.onSignIn({
        email: email,
        password: password
      });
    }
  }

  onOpenSignup() {
    const { onOpenSignup } = this.props;
    if (onOpenSignup) { onOpenSignup(); }
  }

  hideUnlessForce() {
    const { force, onHide } = this.props;
    if (!force) {
      this.setState({ step: 1 }, () => {
        onHide();
      })
    }
  }

  onEmailChange(e) {
    const { invalidEmail } = this.state;

    this.setState({ email: e.target.value }, () => {
      if (invalidEmail && this.validateEmail()) {
        this.setState({ invalidEmail: false});
      }
    })
  }

  onBackFromForgot() {
    this.setState({
      forgotPassword: false
    })
  }

  renderResetPasswordModal() {
    const { allow_signup, show } = this.props;
    const { email } = this.state;

    return (
      <ResetPasswordModal
        show={show}
        onHide={() => { GlobalActions.closePopup(); }}
        onOpenSignup={ allow_signup ? () => { GlobalActions.openPopup('register') } : null }
        onBack={this.onBackFromForgot}
        email={email}
      />
    )
  }

  render() {
    const { errors, auth, step, invalidEmail, forgotPassword } = this.state;
    const { user } = auth;
    const { force, show, allow_signup } = this.props;

    if (forgotPassword) {
      return (
        <div>
          {this.renderResetPasswordModal()}
        </div>
      )
    }

    return (<Modal show={show} onHide={this.hideUnlessForce} className="sign-up-modal" animation={!window.mobileCheck()} id="login-modal">
      <Modal.Header>
        {step === 2 && <div
          className="back-icon-area"
          onClick={() => this.setState({step: 1})}
        >
          <i className="fa fa-chevron-left back-icon" aria-hidden="true"/>
        </div>}
        {!force && <img
          className="close-modal-x"
          src={ASSETS['x']}
          style={{ float: 'right' }}
          onClick={this.hideUnlessForce}
        />}
        <div className="tagline text">
          Let's get you signed in.
        </div>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && <div className="google-login-area">
          <GoogleButton />
        </div>}
        {step === 1 && <div className="or-section">
          <div className="line"></div>
          <div className="text">or</div>
          <div className="line"></div>
        </div>}
        <form onSubmit={this.onSubmit}>
          {errors && <div className="message message_error">
            {errors == 'Email has already been taken' ? "You've already signed up with this email. Please sign in!" : errors}
          </div>}
          {invalidEmail && <div className="email-error">
            Please enter a valid email address.
          </div>}
          <div className="input-area">
            <input
              className={`form-control email ${invalidEmail ? 'error' : ''}`}
              name="email"
              type="email"
              placeholder="Work email address"
              onChange={this.onEmailChange}
              autoFocus
            />
          </div>
          {step === 2 && <div className="input-area">
            <input
              className="form-control password"
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => { this.setState({ password: e.target.value }); }}
            />
          </div>}
          <div
            className="form-field"
            style={{
              marginBottom: 0,
              textAlign: 'center',
              marginTop: 7
            }}>
              <button
                className="adstrap_button submit-button"
                type="submit"
                onClick={this.onSubmit}
                id="login-button-modal"
              >
                {step === 1 ? "Continue" : "Sign In"}
              </button>
          </div>
        </form>
        {allow_signup &&
          <div className="links">
            Don't have an account yet? <a onClick={(this.onOpenSignup)} id="login-modal-signup-link">Sign up here</a>
          </div>
        }
        {step === 2 && <div className="forgot_password_link">
          <a onClick={this.onForgotPassword}>Don’t know your password?</a>
        </div>}
      </Modal.Body>
    </Modal>);
  }
};
