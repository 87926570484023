import { del, put } from "../utils/api";

const API_URL = '/api/v1/campaigns';

export const SUPPLIER_CONFIRM_UNIT = "unit-request/confirm-unit";
export const supplierConfirmUnit = (campaign_id, unit_id, params) => async (dispatch, getState) => {
  try {
    const response = await put(`${API_URL}/${campaign_id}/supplier_units/${unit_id}`, params)
    dispatch({ type: SUPPLIER_CONFIRM_UNIT, payload: response });
  } catch(err) {
    throw (err);
  }
};

export const SUPPLIER_UNAVAILABLE_UNIT = "unit-request/unavailable-unit";
export const supplierUnavailableUnit = (campaign_id, unit_id, params = {}) => async (dispatch, getState) => {
  try {
    const response = await del(`${API_URL}/${campaign_id}/supplier_units/${unit_id}`, params)
    dispatch({ type: SUPPLIER_UNAVAILABLE_UNIT, payload: response });
  } catch(err) {
    throw (err);
  }
};

export const UPDATE_BACKUP_HOLD_DATE = "unit-request/update-backup-hold-date";
export const supplierUpdateBackupHoldDateForUnit = (campaign_id, unit_id, params = {}) => async (dispatch, getState) => {
  try {
    const response = await put(`${API_URL}/${campaign_id}/supplier_units/${unit_id}/backup_hold_date`, params)
    dispatch({ type: SUPPLIER_UNAVAILABLE_UNIT, payload: response });
  } catch(err) {
    throw (err);
  }
}

export const RESET_SUPPLIER_STATUS = "unit-request/reset-supplier-status";
export const resetSupplierStatus = (campaign_id, unit_id, supplier_status) => async (dispatch, getState) => {
  dispatch({ type: RESET_SUPPLIER_STATUS, payload: {campaign_id, unit_id, supplier_status} });
}


