import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import moment from "moment";

import { createHolds } from "./actions";

class CreateHoldFlights extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      hold_expires_at: null,
    };

    this.onHoldExpDateChange = this.onHoldExpDateChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onHoldExpDateChange(hold_expires_at) {
    this.setState({ hold_expires_at });
  }

  componentDidUpdate(prevProps) {
    const { creating_flights, onHide } = this.props;

    if (!!!creating_flights && prevProps.creating_flights) {
      onHide();
    }
  }

  onSubmit() {
    const { createHolds, unit_tokens, onHide, campaign_token } = this.props;
    const { hold_expires_at } = this.state;
    createHolds(unit_tokens, campaign_token, hold_expires_at);
  }

  render() {
    const { show, onHide, creating_flights, unit_tokens } = this.props;
    const { hold_expires_at } = this.state;
    const countText = `${unit_tokens.length} selected`;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="flight_action_modal">
        <Modal.Header>
          <Modal.Title>Create Hold Flights</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {creating_flights && (
            <div>
              <div className="loading-spinner">
                <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
              </div>
            </div>
          )}
          {!!!creating_flights && (
            <div>
              <div className="confirmation-text">
                Create hold flights for {countText} unit{`${unit_tokens.length > 1 ? "s" : ""}`}?
              </div>
              <div>
                <div>Hold Expiration Date</div>
                <Datetime
                  dateFormat="LL"
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{ placeholder: "Hold Expiration Date" }}
                  onChange={hold_expires_at => this.setState({ hold_expires_at: moment(hold_expires_at) })}
                  value={hold_expires_at}
                />
              </div>
              <div className="button-area">
                <button className="confirmation_button btn btn-success" onClick={this.onSubmit}>
                  Submit
                </button>
                <button className="cancel_button btn btn-default" onClick={onHide}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { creating_flights } }) => ({ creating_flights }),
  { createHolds },
)(CreateHoldFlights);
