import React from 'react';
import { isMobile } from '../../../utils/mobile';
import cs from 'classnames';
const POPUP_WIDTH = 180;

export default class ActiveStatus extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
    const filters = this.props.filters || {};
    this.state = {
      isExpanded: false,
      selectedStatus: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.active_status === null) {
      this.setState({
        selectedStatus: null
      });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onClear() {
    this.setState({ selectedStatus: null }, this.onFilter)
  }

  onActiveStatusSelect(option) {
    const { selectedStatus } = this.state;
    if (selectedStatus && selectedStatus === option) {
      this.setState({
        selectedStatus: null
      }, this.onFilter);
    } else {
      this.setState({
        selectedStatus: option
      }, this.onFilter);
    }
  }

  onFilter() {
    const { selectedStatus } = this.state;
    const { setFilter } = this.props;
    this.setState({ isExpanded: false });
    setFilter({
      active_status: selectedStatus
    });
  }

  getButtonLabel() {
    const { selectedStatus } = this.state;
    if (!selectedStatus) return 'Status';
    return _.capitalize(selectedStatus);
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { selectedStatus } = this.state;
    const statuses = ['active', 'inactive', 'retired']
    return (
      <div className="filter_popup orientation_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>UNIT STATUS</h4>
        {selectedStatus && <a
          style={{
            float: 'right',
            top: -32,
            paddingRight: 12,
            position: 'relative',
            color: '#999999'
          }}
          onClick={this.onClear}
        >Clear</a>}
        <ul>
          {statuses.map((option) => {
            const onClick = () => {
              this.onActiveStatusSelect(option);
            }
            const isActive = selectedStatus && selectedStatus === option;
            return <li
              key={option}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {_.capitalize(option)}
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { isExpanded, selectedStatus } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs({active: selectedStatus || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};
