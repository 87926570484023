import "./index.css";
import accounting from 'accounting';
import FaRegTrashAlt from "@adquick/icons/fa/FaRegTrashAlt";
import _, { isEmpty } from "lodash";
import { Pagination } from "react-bootstrap";

import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalActions from '../../../actions/GlobalActions';
import { removeFromCart, loadCartUnits, getCartTotal } from "../../../actions/cart";
import FaceId from "../Catalog/FaceId";

interface RootState {
  cart: any;
}

type Props = {
  token: string;
};

const AddOnInfo = rowInfo => {
  return (
    <div className="add-on-summary-info-row">
      <div className="row-info-name-info-block">
        <div className="row-info">
          <div className="row-info-name" id={rowInfo.rowInfo.unit_id} onClick={e => rowInfo.handleModal(e)}>
            <FaceId label={rowInfo.rowInfo.id} />
            {rowInfo.rowInfo.name}
          </div>
          <div>
            {`${rowInfo.rowInfo.start_date} - ${rowInfo.rowInfo.end_date}`}
          </div>
          <div className="row-info-cost">
            {accounting.formatMoney(rowInfo.rowInfo.client_price, "$", 0)}
            <span style={{fontWeight: 400}}>{rowInfo.rowInfo.quantity > 1 ? ` (x${rowInfo.rowInfo.quantity})`: ''}</span>
          </div>
          <div className="row-info-cost">
            {accounting.formatMoney(rowInfo.rowInfo.total_price, "$", 0)}
          </div>
          <div
            className="row-info-name-trash"
            id={rowInfo.rowInfo.id}
            data-token={rowInfo.rowInfo.token}
            onClick={e => { rowInfo.handler(e) }}
          >
            {/* <FaRegTrashAlt /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddOnsSummary: React.FunctionComponent<Props> = (props: Props) => {
  /* campaign token */
  const { token } = props;

  const cart = useSelector((state: RootState) => state.cart);
  const { add_ons } = cart;
  const dispatch = useDispatch();

  const handleRemoveItem = e => {
    console.log("NOT YET IMPLEMENTED");
  };

  const handleModalPopup = e => {
    const id = e.currentTarget.getAttribute("id") || "";
    GlobalActions.openUnitID(id);
  };

  const renderAddOns = () => {
    return add_ons.map((item, idx) => <AddOnInfo key={idx} rowInfo={item} handler={handleRemoveItem} handleModal={handleModalPopup} />)
  }

  return (
    <div className="units-summary-container">
      <h4>Add-ons</h4>
      <div>
        {renderAddOns()}
      </div>
    </div>
  );
};

export default AddOnsSummary;
