import * as React from "react";
import Theme from "../../theme";
import { IThemeText } from "../../types/theme";
import { PaddingProperty } from "csstype";
export interface ITextIconProps {
  children: React.ReactNode;
  theme?: IThemeText;
  srcIcon?: string;
  faIcon?: string;
  fontSize?: string;
  iconColor?: string;
  textColor?: string;
  lineHeight?: string;
  capitalize?: boolean;
  padding?: PaddingProperty<string>;
}

export const TextIcon = ({
  srcIcon,
  faIcon,
  children,
  fontSize,
  iconColor,
  textColor,
  lineHeight,
  capitalize,
  padding,
  theme = Theme.TEXT_DEFAULT,
}: ITextIconProps) => {

  const textIconStyle = () => ({
    ...(textColor ? { color: textColor } : {}),
    fontWeight: theme.fontWeight,
    fontSize: fontSize || theme.fontSize,
    lineHeight: lineHeight || theme.lineHeight,
    verticalAlign: "middle",
    padding,
    textTransform: capitalize ? "capitalize" : "none",
  } as React.CSSProperties);

  return (
    <div style={textIconStyle()}>
      {srcIcon && <img style={{
        verticalAlign: "middle",
        marginRight: "12px",
      }} src={srcIcon} height={theme.iconHeight} />}
      {faIcon && <i style={iconColor ? { color: iconColor } : {}} className={`fa ${faIcon}`}></i>}
      {children}
    </div>
  );
};

export default TextIcon;
