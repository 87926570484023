import accounting, { formatMoney } from "accounting";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import ReactSelect from "react-select";

import GlobalActions from "../../../../actions/GlobalActions";
import { saveCampaignUnit } from "../../../Campaign/actions";
import DateRangePicker from "../../../DatePicker/Range";
import {
  addNotes,
  createBookings,
  createHolds,
  createMovables,
  createProposals,
  updateDates,
  updatePricing,
  updateSpots,
} from "../actions";

class Unit extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { unit, campaign_dates } = this.props;
    const { start_date, end_date } = campaign_dates;
    this.state = {
      price: unit.price,
      production_cost: unit.production_cost || 0.0,
      installation_cost: unit.installation_cost || 0.0,
      quantity: unit.quantity || (unit.screen_type === "digital" ? 1 : null),
      start_date: unit.start_date ? moment(unit.start_date) : start_date ? moment(start_date) : null,
      end_date: unit.end_date ? moment(unit.end_date) : end_date ? moment(end_date) : null,
      rationale: unit.rationale || "",
      workflow_state: unit.workflow_state,
    };
    this.onDatesChanged = this.onDatesChanged.bind(this);
    this.saveCampaignUnit = _.debounce(this.saveCampaignUnit, 500);
    this.updateDates = this.updateDates.bind(this);
    this.updateFlightType = this.updateFlightType.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { unit, campaign_dates } = this.props;
    const { start_date, end_date } = campaign_dates;
    if (JSON.stringify(unit) !== JSON.stringify(prevProps.unit)) {
      this.setState({
        ...unit,
        start_date: unit.start_date ? moment(unit.start_date) : start_date ? moment(start_date) : null,
        end_date: unit.end_date ? moment(unit.end_date) : end_date ? moment(end_date) : null,
      });
    }
  }

  async saveCampaignUnit() {
    const { unit, saveCampaignUnit } = this.props;
    const {
      price,
      production_cost,
      installation_cost,
      quantity,
      start_date,
      end_date,
      rationale,
      workflow_state,
    } = this.state;
    const cu_token = unit.campaign_unit_token;
    if (!!!cu_token) {
      return;
    }
    await saveCampaignUnit(cu_token, {
      price,
      production_cost,
      installation_cost,
      quantity,
      start_date,
      end_date,
      rationale,
      workflow_state,
    });
    this.setState({
      price,
      production_cost,
      installation_cost,
      quantity,
      start_date,
      end_date,
      rationale,
      workflow_state,
    });
  }

  async saveFlightType(type, original_wfs) {
    const { campaign_token, unit, createProposals, createBookings, createHolds, createMovables } = this.props;
    const { campaign_unit_token } = unit;

    if (type === "proposed") {
      createProposals([campaign_unit_token], campaign_token);
    } else if (type === "booked") {
      const success = await createBookings([campaign_unit_token], campaign_token);
      if (!!!success) {
        this.setState({ workflow_state: original_wfs });
      }
    } else if (type === "on_hold") {
      const success = await createHolds([campaign_unit_token], campaign_token);
      if (!!!success) {
        this.setState({ workflow_state: original_wfs });
      }
    } else if (type === "movable") {
      const success = await createMovables([campaign_unit_token], campaign_token);
      if (!!!success) {
        this.setState({ workflow_state: original_wfs });
      }
    }
  }

  updateFlightType(opt) {
    const original_wfs = this.state.workflow_state;
    this.setState({ workflow_state: opt.value }, () => {
      this.saveFlightType(opt.value, original_wfs);
    });
  }

  renderHoldPriorityBanner() {
    const { unit } = this.props;

    return <div className={`hold_priority hold_${unit.hold_priority}`}>{unit.hold_priority} Priority</div>;
  }

  renderFlightTypeSection() {
    const { flightTypes, unit } = this.props;
    const { workflow_state } = this.state;
    const types = flightTypes
      ? flightTypes.map(f => {
          return { value: f.internal_type, label: f.name };
        })
      : [];
    const flightTypeChangeable = ["booked", "movable"].includes(workflow_state) || unit.available === "available";
    const typeOptions =
      types.length > 0
        ? types.concat({
            value: "proposed",
            label: "Proposed",
          })
        : [];

    return (
      <div className="flight_type">
        <div className="title">Flight Type</div>
        <ReactSelect
          options={typeOptions.filter(t => t.value !== "available")}
          valueKey="value"
          labelKey="label"
          value={typeOptions.find(o => o.value == workflow_state)}
          onChange={opt => this.updateFlightType(opt)}
          clearable={false}
          disabled={!!!flightTypeChangeable}
        />
        {workflow_state === "on_hold" && unit.hold_priority && this.renderHoldPriorityBanner()}
      </div>
    );
  }

  renderSmallInput(unit, className, title, value, onChange) {
    const { isBuilderView } = this.props;
    const disabled = className === "spots" && unit.screen_type === "static";
    const type = className === "spots" ? "quantity" : className;
    return (
      <div className={`${className} ${isBuilderView ? "wide" : ""}`}>
        <div className="title">{title}</div>
        <input
          disabled={unit.disabled || disabled}
          placeholder={disabled ? "N/A" : ""}
          className="form-control"
          value={value}
          onChange={e => this.setState({ [`${type}`]: `${parseFloat(e.target.value) || 0}` })}
          onBlur={e => onChange(e, unit)}
          type="number"
        />
      </div>
    );
  }

  async updateDates(tokens, start_date, end_date, old_start, old_end) {
    const { updateDates, campaign_token } = this.props;
    const success = await updateDates(tokens, campaign_token, start_date, end_date);
    if (!!!success) {
      this.setState({ start_date: old_start, end_date: old_end });
    }
  }

  async updateSpots(tokens, spots) {
    const { updateSpots, campaign_token } = this.props;
    const success = await updateSpots(tokens, campaign_token, spots);
  }

  async updateNotes(tokens, notes) {
    const { addNotes, campaign_token } = this.props;
    const success = await addNotes(tokens, campaign_token, notes);
  }
  async updatePriceItem(tokens, amount) {
    const { updatePricing, campaign_token } = this.props;
    const { price, installation_cost, production_cost } = this.state;
    const success = await updatePricing(tokens, campaign_token, price, production_cost, installation_cost);
  }

  onSpotsChanged(spots) {
    const { unit } = this.props;
    this.setState({ quantity: spots }, () => this.updateSpots([unit.campaign_unit_token], spots));
  }

  onDatesChanged(dates, unit) {
    const { start_date, end_date } = dates;
    const old_start = this.state.start_date;
    const old_end = this.state.end_date;

    if (start_date && end_date) {
      this.setState(
        {
          start_date,
          end_date,
        },
        () => this.updateDates([unit.campaign_unit_token], start_date, end_date, old_start, old_end),
      );
    }
  }

  onNotesChanged(e) {
    const { unit } = this.props;
    const notes = e.target.value;
    this.setState({ rationale: notes }, () => this.updateNotes([unit.campaign_unit_token], notes));
  }

  onPricingChange(e, type) {
    const { unit } = this.props;
    const amount_changed = e.target.value;
    this.setState({ [`${type}`]: `${parseFloat(amount_changed) || 0}` }, () =>
      this.updatePriceItem([unit.campaign_unit_token], amount_changed),
    );
  }

  render() {
    const { unit, isBuilderView, onToggleUnitSelect, confirmRemoveUnit, selected_units, allSelected } = this.props;
    const { start_date, end_date, quantity, price, installation_cost, production_cost, rationale } = this.state;
    const avail_style =
      unit.available === "available"
        ? {
            backgroundColor: "rgba(68, 172, 107, 0.12)",
            color: "#44AC6B",
          }
        : {
            backgroundColor: "#cccccc",
          };

    const default_avail_style = {
      backgroundColor: "#ffffff",
      color: "rgb(24, 24, 24)",
      border: "solid 1px rgb(24, 24, 24)",
    };

    const isSelected = allSelected ? true : selected_units.includes(unit.campaign_unit_token);

    return (
      <div key={unit.campaign_unit_token} className={"unit_list_item clearfix"}>
        <div className="top_row clearfix">
          <div className="unit_info">
            <div className="row_1">
              {unit.supplier_face_id && <div className="face_id">{unit.supplier_face_id}</div>}
              {unit.name && (
                <div className="name" title={unit.name}>
                  {unit.name}
                </div>
              )}
            </div>

            <div className="row_2">
              {unit.cpm && <div className="cpm">CPM: {formatMoney(unit.cpm)}</div>}
              {unit.size && <div className="size">Size: {unit.size}</div>}
            </div>

            <div className="row_3">
              {unit.size && <div className="impressions">{accounting.format(unit.impressions)} imp/4w</div>}
            </div>

            <div className="row_4">
              <div className={`screen_type_icon ${unit.unit_type ? unit.unit_type : "other"}`}></div>
              {unit.screen_type && <div className="screen_type_tag">{unit.screen_type}</div>}
              {unit.market_name && <span className="market">{`${unit.market_name}`}</span>}
            </div>
          </div>
          {this.renderSmallInput(unit, "price", "4 Week Price", price, e => this.onPricingChange(e, "price"))}
          {this.renderSmallInput(unit, "production_cost", "Production", production_cost, e =>
            this.onPricingChange(e, "production_cost"),
          )}
          {this.renderSmallInput(unit, "installation_cost", "Installation", installation_cost, e =>
            this.onPricingChange(e, "installation_cost"),
          )}
          {this.renderSmallInput(unit, "spots", "Spots", quantity, e => this.onSpotsChanged(e.target.value))}
          <div className={`start_end_date ${isBuilderView ? "wide" : ""}`}>
            <div className="title">Dates</div>
            <DateRangePicker
              disabled={unit.disabled}
              start_date={start_date}
              end_date={end_date}
              onDatesChange={dates => this.onDatesChanged(dates, unit)}
              small={true}
              hideClearButton={true}
            />
          </div>

          <div className={`rationale ${isBuilderView ? "wide" : ""}`}>
            <div className="title">Notes</div>
            <input
              disabled={unit.disabled}
              className="form-control"
              value={rationale}
              onChange={e => this.setState({ rationale: e.target.value })}
              onBlur={e => {
                this.onNotesChanged(e);
              }}
            />
          </div>

          {!!!isBuilderView && this.renderFlightTypeSection()}

          <div className="available">
            <div
              className="avail-status"
              style={
                unit.workflow_state === "booked" || unit.workflow_state === "movable"
                  ? default_avail_style
                  : avail_style
              }
            >
              {unit.workflow_state === "booked" || unit.workflow_state === "movable"
                ? unit.workflow_state
                : (unit.available == null ? 'Loading availability...' : unit.available)}
            </div>
          </div>

          <div className="remove_unit">
            <i className="fa fa-trash" onClick={e => confirmRemoveUnit(e, unit)}></i>
          </div>
          {!!!isBuilderView && (
            <div className="select_unit">
              <i
                className={`fa ${isSelected ? "fa-check-square-o" : "fa-square-o"}`}
                onClick={e => onToggleUnitSelect(e, unit)}
              ></i>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(({}) => ({}), {
  saveCampaignUnit,
  updateDates,
  createBookings,
  createHolds,
  createMovables,
  createProposals,
  updateSpots,
  addNotes,
  updatePricing,
})(Unit);
