import * as React from "react";
import { Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  saving: boolean;
  onConfirm: Function;
  onHide: Function;
}

export default class ConfirmPriceResetModal extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { onConfirm, onHide, show, saving } = this.props;

    return (
      <Modal show={show} onHide={onHide} id="confirm_contract_price_reset_modal">
        <Modal.Header>
          <Modal.Title>Reset Contract Prices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {saving && (
            <div>
              <div className="loading-spinner">
                <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
              </div>
            </div>
          )}
          {!!!saving && (
            <div>
              <div className="confirmation-text">
                Pressing confirm will reset contract prices to the original price submitted by the vendor
              </div>
              <div className="button-area">
                <button className="confirmation_button btn btn-success" onClick={() => onConfirm()}>
                  Confirm
                </button>
                <button className="cancel_button btn btn-default" onClick={() => onHide()}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
