import React from 'react'
import moment from 'moment';
import { formatMoney } from 'accounting';
import Loading from '../Loading';

export default class Leads extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      leads: [],
      loading: true
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    console.log('props', )
    const { supplier_id } = this.props.location.query

    $.get('/api/v1/businesses', { supplier_id }).then(({ businesses }) => {
      this.setState({ businesses, loading: false })
    })
  }

  render() {
    const { businesses, loading } = this.state

    if (loading) {
      return <Loading/>
    }

    return (
      <div>
        <h3 style={{margin:20}}>Leads Directory</h3>

        <table className="table table-striped">
          <thead>
            <tr style={{fontWeight:'bold'}}>
              <td>Vendor</td>
              <td style={{width:130}}>Near Face ID</td>
              <td>Business</td>
              <td>Latitude</td>
              <td>Longitude</td>
              <td>Reviews</td>
              <td>Rating</td>
              <td>Phone</td>
              <td>Categories</td>
              <td>Market</td>
            </tr>
          </thead>
          <tbody>
            {businesses.map(biz => (
              <tr key={biz.id}>
                <td>{biz.unit.supplier_name}</td>
                <td>{biz.unit.supplier_face_id}</td>
                <td><a href={biz.url} target="_blank">{biz.name}</a></td>
                <td>{biz.lat}</td>
                <td>{biz.lon}</td>
                <td>{biz.reviews_count}</td>
                <td>{biz.rating}</td>
                <td>{biz.phone}</td>
                <td>{biz.categories}</td>
                <td>{biz.market}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="alert alert-warning container">
          This is a demo. Please upgrade for full access.
        </div>
      </div>
    )
  }
}
