import React, { Component } from 'react';
import { connect } from 'react-redux';

class Toast extends Component {
  render() {
    const { onClose } = this.props;
    const { acknowledgement } = this.props.dialog;
    const { title, message } = acknowledgement;
    return (
      <div>
        <div className="mobile-overlay" onClick={onClose}></div>
        <div className="mobile-toast">
          <div className="toast-title">
            <h4>{title}</h4>
          </div>
          <div className="toast-icon">
            <i className="fa fa-check-circle" />
          </div>
          <div className="toast-message">
            {message}
          </div>
          <div className="toast-close">
            <button onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
    dialog,
  }) => ({
    dialog,
});

export default connect(
  mapStateToProps,
  {}
)(Toast)
