import React from 'react';
import cs from 'classnames';
import { connect } from 'react-redux';
const POPUP_WIDTH = 220;
import {
  fetchOrientations,
  clearOrientations
} from '../actions';

class Orientation extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {
      isExpanded: false,
      selectedOrientation: null
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selectedOrientation } = this.state;
    if (selectedOrientation && !nextProps.filters.orientation) {
      this.setState({ selectedOrientation: null });
    };

    if (nextProps.filters.supplier && nextProps.filters.supplier !== this.props.filters.supplier) {
      const { fetchOrientations } = this.props;
      fetchOrientations(nextProps.filters.supplier);
    };

    const filters = this.props.filters || {};
    if (filters.supplier && !!!nextProps.filters.supplier) {
      const { clearOrientations, setFilter } = this.props;
      this.setState({ selectedOrientation: null }, () => {
        clearOrientations();
        setFilter('orientation', null);
      });
    };

    const { orientations } = this.props;
    if (!!!orientations && nextProps.orientations && nextProps.orientations.length > 0) {
      const filters = this.props.filters || {};
      const selected = nextProps.orientations.filter((o) => {
        return o.value === filters.orientation;
      });
      if (selected.length > 0) {
        this.setState({ selectedOrientation: selected[0] });
      };
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
    const { fetchOrientations, currentUser } = this.props;
    const filters = this.props.filters || {};
    const { supplier } = filters;
    fetchOrientations(supplier);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    };
  }

  onOrientationSelect(orientation) {
    const { selectedOrientation } = this.state;
    const { setFilter } = this.props;
    if (selectedOrientation && selectedOrientation.value === orientation.value) {
      this.setState({ selectedOrientation: null}, () => {
        setFilter('orientation', null);
      });
    } else {
      this.setState({ selectedOrientation: orientation }, () => {
        setFilter('orientation', orientation);
      });
    };
  }

  onClear() {
    const { setFilter } = this.props;
    this.setState({ isExpanded: false, selectedOrientation: null }, () => {
      setFilter('orientation', null);
    });
  }

  getButtonLabel() {
    const { selectedOrientation } = this.state;
    if (!selectedOrientation) return 'Orientation';
    return selectedOrientation.value;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { selectedOrientation } = this.state;
    const { orientations, currentUser } = this.props;

    return (
      <div className="filter_popup orientation_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>ORIENTATION</h4>
          {(!!!orientations || orientations.length === 0) &&
            <div style={{ paddingLeft: 10, paddingBottom: 10 }}>
              No results
            </div>
          }
        {(!!!orientations && currentUser && currentUser.is_admin) &&
          <div style={{ paddingLeft: 10 }}>
            please select a vendor to use this filter
          </div>
        }
        {selectedOrientation && <a
          style={{
            float: 'right',
            top: -32,
            paddingRight: 12,
            position: 'relative',
            color: '#999999'
          }}
          onClick={this.onClear}
        >Clear</a>}
        <ul>
          {orientations && orientations.map((orientation) => {
            const onClick = () => {
              this.onOrientationSelect(orientation);
            }
            const isActive = selectedOrientation && selectedOrientation.value === orientation.value;
            return <li
              key={orientation.value}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {orientation.value}
                <i className="count">({orientation.count})</i>
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { isExpanded, selectedOrientation } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs({active: selectedOrientation || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};

export default connect(
  ({ inventory: { orientations }, currentUser }) => ({
    orientations,
    currentUser
  }),
  { fetchOrientations, clearOrientations }
)(Orientation);
