import { CSSProperties } from "react";

const COLOR_BLOCK_SIZE = "22px";
const BOUNDS_ROW_PADDING = "10px";

export const inlineBlock = {
  display: "inline-block"
}

export const highlightedAreaRow = (): CSSProperties => ({
  display: "grid",
  gridTemplateColumns: "30px 40px auto 40px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: BOUNDS_ROW_PADDING,
  position: "relative",
  marginBottom: "10px"
});

export const colorPickerBtn = (color: string): CSSProperties => ({
  backgroundColor: color,
  display: "inline-block",
  height: COLOR_BLOCK_SIZE,
  width: COLOR_BLOCK_SIZE,
  borderRadius: "4px",
  zIndex: 10,
  position: "relative",
  transform: "translateY(-50%)",
  top: "50%"
});

export const colorPicker = (show: boolean): CSSProperties => ({
  zIndex: 11,
  transform: `scale(${show ? 1 : 0}) translate(-10px, ${COLOR_BLOCK_SIZE})`,
  position: "absolute",
  opacity: show ? 1 : 0,
  transformOrigin: "left top",
  transition: `
    0.2s transform ease,
    0.2s opacity ease`,
});

export const highlightedAreaTitle = (): CSSProperties => ({
  fontSize: "12px",
  fontWeight: "bold",
  color: "#999",
  margin: 0,
});

export const btnTrash = (): CSSProperties => ({
  color: "#999",
  transition: "0.2s color ease",
  position: "absolute",
  fontSize: "18px",
  top: "50%",
  transform: "translateY(-50%)",
  right: BOUNDS_ROW_PADDING
});

export const highlightedAreaName = (): CSSProperties => ({
  marginBottom: 0,
  marginTop: "5px"
});

export const btnAdd = (): CSSProperties => ({
  marginLeft: "5px",
  verticalAlign: "top"
})

export const districtsSelect = (): CSSProperties => ({
  width: "222px",
  display: "inline-block",
  marginTop: "-1px"
})