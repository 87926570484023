import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { browserHistory } from 'react-router-legacy';
import moment from "moment";
import cs from 'classnames';
import {
  completeSupplierTask,
  cancelSupplierTask,
  releaseSupplierTask,
  changeAssignee,
  fetchSupplierTasks
} from "./CampaignRequests/actions";
import {
  fetchCampaignsWithSupplierTasks,
  reopenSupplierTask,
  sendReminder,
} from "./actions"
import GlobalActions from '../../actions/GlobalActions';
import SupplierName from '../SupplierName';
import { format, isFuture, isTomorrow, isToday, isYesterday, distanceInWords } from 'date-fns';
import Tooltip from "rc-tooltip";

class RequestCardItem extends PureComponent {
  isTaskCompleted() {
    const { supplier_task } = this.props;
    return supplier_task.completed;
  }

  isTaskCanceled() {
    const { supplier_task } = this.props;
    return !!supplier_task.canceled_at;
  }

  render() {
    return this.renderTaskCard();
  }

  renderTaskCard() {
    const { supplier_task, onToggle } = this.props;
    const outerContainerModifier = this.renderOuterContainerTaskModifier();
    const innerContainerModifier = this.renderInnerContainerTaskModifier();
    return (
      <div
        className="campaign_request clearfix"
        onClick={(e) => onToggle(supplier_task)}
      >
        <div className={`col-xs-12 todo_card ${outerContainerModifier}`}>
          <div className={innerContainerModifier}>
            {this.renderTaskTitle()}
            {this.renderTaskDescription()}
          </div>
          {this.renderTaskDates()}
          {this.isTaskCompleted() &&
            this.renderDownloadCSV()
          }
          {this.isTaskCompleted() &&
            this.renderActionsForCompletedTask()
          }
          {!this.isTaskCompleted() && !this.isTaskCanceled() &&
            this.renderRespondButton()
          }
          {this.renderCardCheckbox()}
        </div>
      </div>
    )
  }

  renderOuterContainerTaskModifier() {
    if(this.isTaskCompleted()) { return "todo_completed"; }
    if(this.isTaskCanceled()) { return "todo_canceled"; }
    return "todo_outstanding";
  }

  renderInnerContainerTaskModifier() {
    const { user } = this.props;
    if(this.isTaskCompleted()) { return user.is_admin ? "col-xs-5" : "col-xs-8"; }
    if(this.isTaskCanceled()) { return "col-xs-8"; }
    return "col-xs-5";
  }

  renderTaskDescription() {
    const { supplier_task } = this.props;
    if(this.isTaskCompleted()) {
      return (
        <div className="todo_description">
          {supplier_task.description}
        </div>
      )
    }
    if(supplier_task.submitted_unit_count > 0) {
      return (
        <div className="todo_description">
          {supplier_task.submitted_unit_count} units submitted.
        </div>
      )
    }
  }

  renderTaskDates() {
    const { supplier_task } = this.props;
    if(this.isTaskCompleted()) { return this.renderCompletedDate(supplier_task); }
    if(this.isTaskCanceled()) { return this.renderCanceledDate(supplier_task); }
    return (
      <div className="col-xs-3">
        <div style={{ display: 'flex', justifyContent: 'space-around'}}>
          {this.renderDueDate(supplier_task)}
          {this.renderDeliveryDate()}
        </div>
      </div>
    )
  }

  renderTaskTitle() {
    const { supplier_task, user, campaign_token } = this.props;
    const is_campaign_admin = user.is_admin || user.is_agency;
    return (
      <div className="todo_title row">
        <div
          className="col-xs-6"
          onClick={() => this.showTask(user, supplier_task, campaign_token)}
        >
          <small>{supplier_task.title}</small>
          {is_campaign_admin &&
            <SupplierName className="inline-flex items-center bg-grayscale-200 text-xs p-1"
              user={user}
              supplier={{
                name: supplier_task.supplier_name,
                is_managed: supplier_task.is_managed,
                is_fully_submitted: supplier_task.is_fully_submitted
              }}
            />
          }
        </div>
        {this.renderAssignee(is_campaign_admin, user.is_supplier_admin)}
      </div>
    );
  }

  renderAssignee(isAdmin, isSupplierAdmin) {
    const { supplier_task } = this.props;
    const { delivery_at, completed, canceled } = supplier_task;
    const contacts = _.get(supplier_task, 'supplier_users', []);
    const pendingDelivery = !!delivery_at && !completed && !canceled && isFuture(delivery_at);
    const isEditable = (isAdmin && contacts.length) && pendingDelivery;
    if (!isAdmin && !isSupplierAdmin) return null;
    return (
      <div className="col-xs-6">
        <small>Assignee</small>
        {isEditable &&
          <div className="btn-group btn-group-xs">
            <button
              type="button"
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ marginRight: 10 }}
            >
              {supplier_task.assignee_name}
              <span className="caret"></span>
            </button>
            <ul className="dropdown-menu">
              {contacts.map( user =>
                <li key={user.id}><a onClick={() => { this.changeRecipient(supplier_task, user.id)}}>{user.name}</a></li>
              )}
            </ul>
          </div>
        }
        {!isEditable &&
          <span style={{ fontWeight: 200 }}>{supplier_task.assignee_name}</span>
        }
      </div>
    )
  }

  renderCompletedDate(supplier_task) {
    const { declined_reason, declined_at, completed_at, no_inventory, submitted_unit_count, no_inventory_reason_and_note } = supplier_task;

    return (
      <div className="todo_completed_date col-xs-4">
        <div>
          <small>Completed On</small>
          {completed_at && (
            moment(completed_at).format('M/D')
          )}
          {no_inventory &&
            <strong className="no_inventory">No inventory submitted</strong>
          }
          {no_inventory_reason_and_note &&
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.3}
              overlay={no_inventory_reason_and_note}
            >
              <i className="fal fa-info-circle"></i>
            </Tooltip>
          }
          {submitted_unit_count > 0 &&
            <strong className="submitted">
              {`Submitted ${submitted_unit_count} ${this.unitOrUnits(submitted_unit_count)}`}
            </strong>
          }
        </div>
        {declined_at &&
          <div className="col-md-4">
            <small>Declined</small>
            {declined_reason &&
              <span style={{fontWeight: 400}}>{declined_reason}</span>
            }
          </div>
        }
      </div>
    );
  }

  renderCanceledDate(supplier_task) {
    return (
      <div className="col-xs-3">
        <small>Canceled</small>
        {`${distanceInWords(supplier_task.canceled_at, new Date())} ago`}
      </div>
    )
  }

  unitOrUnits(count) {
    if (count > 1) return "units";
    return "unit";
  }

  renderDueDate(supplier_task) {
    const due_date = moment(supplier_task.due_date);
    const today = moment();

    let due_date_formated = supplier_task.due_date ? due_date.format("M/D") : "";
    if ( today.format("M/D") === due_date_formated) {
      due_date_formated = "Today";
    }
    const overdue = due_date.isBefore(today) ? "overdue" : "";

    return (
      <div className={`${overdue} todo_due_date`}>
        <small>Due Date</small>
        {due_date_formated}
      </div>
    );
  }

  renderDeliveryDate() {
    const { supplier_task } = this.props;
    const delivery_at = supplier_task.delivery_at || supplier_task.created_at;

    const deliveryDate = this.formatDeliveryDate(supplier_task);

    return (
      <div>
        <small>{isFuture(delivery_at) ? 'Scheduled For' : 'Delivered'}</small>
        {deliveryDate}
      </div>
    );
  }

  formatDeliveryDate(supplier_task) {
    const { delivery_at } = supplier_task;
    if (!delivery_at) {
      return 'Sent immediately*';
    }

    if (isToday(delivery_at)) return format(delivery_at, '[Today at] h A')

    if (isFuture(delivery_at)) {
      if (isTomorrow(delivery_at)) {
        return format(delivery_at, '[Tomorrow at] h A')
      } else {
        return format(delivery_at, '[Next] dddd [at] h A')
      }
    } else {
      if (isYesterday(delivery_at)) {
        return format(delivery_at, '[Yesterday at] h A')
      } else {
        return format(delivery_at, 'MMM D [at] h A')
      }
    }
  }

  renderRespondButton() {
    const { user, supplier_task, campaign_token } = this.props;
    const showRespondButton = user.permissions.can_view_supplier_task_respond_button
    return (
      <div className='col-xs-3'>
        <div className='row'>
          <div className='col-xs-6'>
            {showRespondButton &&
              <button
                className='btn btn-primary todo_btn'
                style={{ width: '100%' }}
                onClick={(e) => this.showTask(user, supplier_task, campaign_token)}
              >
                Respond
              </button>
            }
          </div>
          <div className='col-xs-6'>
            {this.renderActionsForOpenTask(user, supplier_task)}
          </div>
        </div>
      </div>
    );
  }

  renderActionsForOpenTask(user, supplier_task) {
    const last_reminder = supplier_task.last_reminder_sent_at ?
      moment(supplier_task.last_reminder_sent_at).format('M/D') : "-";
    const { task_type } = supplier_task;
    const canBeReleased = !!supplier_task.job_id && isFuture(supplier_task.delivery_at);
    if (user.is_admin || user.is_agency) {
      return (
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-default dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ marginRight: 10 }}
            onClick={(e) => e.stopPropagation()}
          >
            Actions <span className="caret"></span>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <li>
              <a onClick={(e) => this.completeTask(e, supplier_task)}>Complete</a>
            </li>
            {user.is_admin && ["initial", "hold", "refresh"].includes(task_type) &&
              [
                <li
                  key="cancel"
                  className={cs({disabled: !isFuture(supplier_task.delivery_at)})}
                >
                  <a onClick={(e) => this.cancelRequest(e, supplier_task)}>
                    Cancel Request
                  </a>
                </li>,
                <li
                  key="release"
                  className={cs({disabled: !canBeReleased})}
                >
                    <a onClick={(e) => this.releaseRequest(e, supplier_task)}>
                      Release Now
                    </a>
                </li>
              ]
            }
            {(task_type === 'rfp') &&
              [<li
                key="send_reminder"
              >
                <a onClick={(e) => this.sendReminder(e, supplier_task)}>
                  Send Reminder
                </a>
              </li>,
              <li
                key="last_reminder_sent_at"
                className="dropdown-header"
              >
                  Last reminder sent at: { last_reminder }
              </li>]
            }
          </ul>
        </div>)
    }
  }

  renderActionsForCompletedTask() {
    const { user, supplier_task } = this.props;
    const { task_type } = supplier_task;
    if(!user.is_admin) { return }
    return (
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-default dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ marginRight: 10 }}
          onClick={(e) => e.stopPropagation()}
        >
          Actions <span className="caret"></span>
        </button>
        <ul className="dropdown-menu dropdown-menu-right">
          <li>
            <a onClick={(e) => this.reopenTask(e, supplier_task)}>
              Reopen
            </a>
          </li>
          {task_type === "rfp" &&
            <li>
              <a onClick={(e) => this.declineRequest(e, supplier_task)}>
                Decline
              </a>
            </li>
          }
        </ul>
      </div>
    )
  }

  renderDownloadCSV() {
    const { supplier_task } = this.props
    const url = `/api/v1/supplier_tasks/${supplier_task.id}/download`

    return (
      <div className="col-xs-2">
        <small>Unit Grid (CSV)</small>
        <a href={url} onClick={(e) => e.stopPropagation()}>Download</a>
      </div>
    )
  }

  renderCardCheckbox() {
    const { supplier_task } = this.props;
    const isSelected = supplier_task.selected;
    return (
      <div className="col-xs-1">
        <input type="checkbox" checked={isSelected} />
      </div>
    )
  }

  async reopenTask(e, supplier_task) {
    const { campaign_token } = this.props;
    e.stopPropagation()
    await this.props.reopenSupplierTask(supplier_task.id);
    await this.props.fetchCampaignsWithSupplierTasks("completed");
    await this.props.fetchSupplierTasks(campaign_token);
    GlobalActions.showMessage("The task has been reopened.");
  }

  async completeTask(e, supplier_task) {
    const { campaign_token } = this.props;
    e.stopPropagation()
    await this.props.completeSupplierTask(supplier_task.id);
    await this.props.fetchCampaignsWithSupplierTasks("open");
    await this.props.fetchSupplierTasks(campaign_token);
    GlobalActions.showMessage("The task has been marked as complete.");
  }

  async sendReminder(e, supplier_task) {
    e.stopPropagation()
    await this.props.sendReminder(supplier_task.id);
    await this.props.fetchCampaignsWithSupplierTasks("open", false);
    GlobalActions.showMessage(`An RFP reminder was sent to the ${supplier_task.assignee_name}.`);
  }

  async cancelRequest(e, supplier_task) {
    const { cancelSupplierTask, campaign_token } = this.props;
    e.stopPropagation()
    await cancelSupplierTask(supplier_task.id)
    await this.props.fetchCampaignsWithSupplierTasks("open");
    await this.props.fetchSupplierTasks(campaign_token);
    GlobalActions.showMessage("The request was successfully canceled");
  }

  async releaseRequest(e, supplier_task) {
    const { releaseSupplierTask } = this.props;
    e.stopPropagation()
    await releaseSupplierTask(supplier_task.id)
    await this.props.fetchCampaignsWithSupplierTasks("open");
    GlobalActions.showMessage("The request was successfully released");
  }

  async changeRecipient(supplier_task, user_id) {
    const { changeAssignee } = this.props;
    await changeAssignee(supplier_task.id, user_id)
    await this.props.fetchCampaignsWithSupplierTasks("open");
    GlobalActions.showMessage("Successfully updated the request assignee.");
  }

  async declineRequest(e, supplier_task) {
    e.stopPropagation()
    await this.props.showDeclineTaskModal(supplier_task)
  }

  showTask(user, supplier_task, campaign_token) {
    if (user.is_agency || supplier_task.canceled_at) {
      return
    }

    if (['rfp', 'initial', 'hold'].includes(supplier_task.task_type)) {
      window.location.assign(`/adquick_requests/${campaign_token}/supplier_tasks/${supplier_task.id}/review`)
    } else {
      browserHistory.push(`/adquick_requests/${campaign_token}/overview`)
    }
  };
}

export default connect(
  () => { return {}; },
  {
    completeSupplierTask,
    reopenSupplierTask,
    fetchCampaignsWithSupplierTasks,
    sendReminder,
    cancelSupplierTask,
    releaseSupplierTask,
    changeAssignee,
    fetchSupplierTasks
  }
)(RequestCardItem);
