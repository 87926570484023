import * as React from "react";

export interface IAnalyticsHeaderProps {
  title?: string;
  subTitle?: string;
  faIcon?: string;
  disableWrap?: boolean;
  children?: React.ReactNode;
}

const Header = ({ title, subTitle, faIcon, disableWrap, children }: IAnalyticsHeaderProps) => (
  <div className={`analytics_header${disableWrap ? " disable_wrap" : ""}`}>
    <div className="title">
      {faIcon && <i className={`fa ${faIcon}`}></i>}
      {title && title}
      {subTitle && <span style={subTitleStyle()}>{subTitle}</span>}
    </div>
    {children}
  </div>
);

const subTitleStyle = () => ({
  verticalAlign: "middle",
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "15px",
  letterSpacing: "0.15em",
  textTransform: "uppercase",
  color: "#858585",
  marginLeft: "1rem",
  marginTop: "3px",
} as React.CSSProperties);

export default Header;
