import * as React from "react";
import styled from "styled-components";
import Theme from "../../../theme";
import Button from "../../Button";

export const GRID = {
  days: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  hours: Array.from(Array(24).keys()),
};

export type DayIndex = number;
export type HourIndex = number;

export const buildSelectedMatrix = (value: boolean = false) =>
  GRID.days.map(() => GRID.hours.map(() => value));

export const Dayparting = () => {
  const [matrix, updateMatrix] = React.useState<boolean[][]>(buildSelectedMatrix());
  const [pressed, setPressed] = React.useState<boolean>(false);
  const [firstAction, setFirstAction] = React.useState<boolean>(true);
  const [firstPosition, setFirstPosition] = React.useState<[DayIndex, HourIndex]>([0, 0]);

  const selectAll = () => updateMatrix(buildSelectedMatrix(true));
  const clearAll = () => updateMatrix(buildSelectedMatrix(false));

  const updateValueOnMatrix = (x: number, y: number, val: boolean) => {
    const newMatrix = [...matrix];
    newMatrix[x][y] = val;
    updateMatrix(newMatrix);
  };

  const startSelect = (dayIndex: DayIndex, hourIndex: HourIndex) => {
    setFirstPosition([dayIndex, hourIndex]);
    setFirstAction(!matrix[dayIndex][hourIndex]);
  };

  const stopSelect = (dayIndex: DayIndex, hourIndex: HourIndex) => {
    if (firstPosition[0] === dayIndex && firstPosition[1] === hourIndex) {
      updateValueOnMatrix(dayIndex, hourIndex, !matrix[dayIndex][hourIndex]);
    }
  }

  const performSquareSelect = (dayIndex: DayIndex, hourIndex: HourIndex) => {
    if (!pressed) { return; }
    updateValueOnMatrix(dayIndex, hourIndex, firstAction);
  };

  const getHourByIndex = (hourIndex: number) => {
    const h = hourIndex % 12 || 12;
    const unit = (hourIndex > 11 || hourIndex === 24) ? "PM" : "AM";
    return `${h} ${unit}`;
  }

  React.useEffect(() => {
    document.body.onmousedown = () => setPressed(true);
    document.body.onmouseup = () => setPressed(false);
    // disabling annoying Firefox dragstart.
    document.addEventListener("dragstart", (e) => e.preventDefault());
  }, []);

  return (
    <DaypartingWrapper>
      <DaypartingToolbar>
        <DaypartingAction onClick={() => selectAll()}>Select All</DaypartingAction>
        <DaypartingAction onClick={() => clearAll()}>Clear All</DaypartingAction>
      </DaypartingToolbar>
      <DaypartingDays>
        {GRID.days.map((day, dayIndex) =>
          <div key={`day_${dayIndex}`}>
            <DayTitle>{day}</DayTitle>
            <DaypartingHours>
              {GRID.hours.map((_h, hourIndex) =>
                <div key={`day_${dayIndex}_hour_${hourIndex}`}>
                  {dayIndex === 0 && <HourTitle>{getHourByIndex(hourIndex)}</HourTitle>}
                  <Hour
                    onMouseDown={() => startSelect(dayIndex, hourIndex)}
                    onMouseUp={() => stopSelect(dayIndex, hourIndex)}
                    onMouseOver={() => performSquareSelect(dayIndex, hourIndex)}
                    isSelected={matrix[dayIndex][hourIndex]} />
                </div>
              )}
            </DaypartingHours>
          </div>
        )}
      </DaypartingDays>
    </DaypartingWrapper>
  );
};

export const DaypartingAction = styled.a`
  display: inline-block;
  padding: 6px 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #858585;
  margin-left: 10px;
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: ${Theme.PRIMARY_COLOR.color};
  }
`;

export const DaypartingToolbar = styled.div`
  text-align: right;
`;

export const DayTitle = styled.div`
  user-select: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 6px;
`;

export const HourTitle = styled.div`
  user-select: none;
  padding-right: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-transform: uppercase;
  position: absolute;
  transform: translateX(-100%);
`;

export const DaypartingDays = styled.div`
  display: grid;
  grid-gap: 4px;
  cursor: pointer;
  grid-template-columns: repeat(${GRID.days.length}, 36px);
`;

export const DaypartingHours = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-rows: repeat(${GRID.hours.length}, 20px);
`;

export const DaypartingWrapper = styled.div`
  display: inline-block;
  padding: 20px;
  padding-left: 40px;
`;

export interface IHour {
  isSelected: boolean;
  onMouseDown: () => void;
  onMouseOver: () => void;
}

export const Hour = styled.div<IHour>`
  height: 20px;
  border-radius: 1px;
  background: ${(props) => props.isSelected ? Theme.PRIMARY_COLOR.color : "#EEE"};
  transition: 0.2s linear;
  transition-property: background;

  &:hover {
    background: ${Theme.NEUTRO_COLOR.comp};
  }
`;

export default Dayparting;
