import React from 'react';
import createClass from 'create-react-class';

const PopTile = createClass({
  displayName: 'PopTile',

  getInitialState() {
    return {
      pop: this.props.pop,
      unit: this.props.unit,
      campaign_unit_token: this.props.campaignUnitToken,
    }
  },

  render() {
    const { pop } = this.state;
    const checked = pop.selected;
    const asset_url = (pop.asset_url && !pop.asset_url.includes(".zip") && !pop.asset_url.includes(".pdf")) ? pop.asset_url : ''
    const dev_mode = AppConfig.developmentMode;
    const class_name = `pop-tile-img ${checked ? 'checked' : 'unchecked'}`;
    const fixed_url = dev_mode ? asset_url.replace("development", "production") : asset_url

    if (asset_url) {
      return (
        <div className="pop-tile" onClick={this.props.onImageSelect}>
          <img className={class_name} src={fixed_url} />
        </div>
      );
    } else {
      return (
        <div></div>
      )
    }
  }
});

export default PopTile;
