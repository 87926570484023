import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import GlobalActions from "../../../actions/GlobalActions";
import { addNotes } from "./actions";

class AddNotesModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      rationale: "",
    };
    this.renderBody = this.renderBody.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRationaleChange = this.onRationaleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { adding_notes, onHide } = this.props;
    if (!!!adding_notes && prevProps.adding_notes) {
      GlobalActions.showMessage("Notes saved");
      onHide();
    }
  }

  onSubmit() {
    const { addNotes, unit_tokens, campaign_token } = this.props;
    const { rationale } = this.state;
    addNotes(unit_tokens, campaign_token, rationale);
  }

  onRationaleChange(e) {
    this.setState({ rationale: e.target.value });
  }

  renderBody() {
    const { adding_notes, onHide } = this.props;
    const { rationale } = this.state;

    return (
      <Modal.Body>
        {adding_notes && (
          <div>
            <div className="loading-spinner">
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        )}
        {!!!adding_notes && (
          <div>
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Notes</label>
                <div className="col-10">
                  <textarea className="form-control" defaultValue={rationale} onChange={this.onRationaleChange} />
                </div>
              </div>
            </div>
            <div className="button-area">
              <button className="confirmation_button btn btn-success" onClick={this.onSubmit}>
                Submit
              </button>
              <button className="cancel_button btn btn-default" onClick={onHide}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    );
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="add_notes_modal">
        <Modal.Header>
          <Modal.Title>Add Notes</Modal.Title>
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { adding_notes } }) => ({ adding_notes }),
  { addNotes },
)(AddNotesModal);
