import React from 'react';
import accounting from 'accounting';
import createClass from 'create-react-class';
import Select from 'react-select';
import ContentLoader from 'react-content-loader'
import JobProgress from '../../../utils/JobProgress'

const Demographics = createClass({
  displayName: 'Campaign/Geopath/index.js',

  getInitialState() {
    const { demographics } = this.props
    return {
      response: null,
      demo_keys: [],
      demo_key: demographics.demo_key || [],
      job_pooling: new JobProgress(),
      valid: true
    };
  },

  componentDidMount() {
    if (!this.props.data.permissions.can_view_geopath) return
  },

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.demographics.demo_key !== this.props.demographics.demo_key) { this.setState({ demo_key: this.props.demographics.demo_key })}
  },

  loadDemographicKeys() {
    $.get(`/api/v1/geopath_segments?for=sidebar`).then(response => {
      this.setState(
          {
            demo_keys: response.geopath_segments.map(s => { return { label: s.name, value: s.id } })
          }
      )
    })
  },

  onSubmit(e) {
    e.preventDefault()

    if (this.validateForm()) {
      this.generateRfp()
    }
  },

  onDemoKeyChange(option) {
    if (option) {
      this.setState({ demo_key: option.value, valid: true })
    }
  },

  validateForm() {
    if (this.state.demo_key === null) {
      this.setState({ valid: false });
      return false
    } else {
      this.setState({ valid: true });
      return true
    }
  },

  generateRfp() {
    this.setState({ generating: true }, async () => {
      const campaignId = this.props.campaign.token;
      const params =  {
        demo_key: this.state.demo_key,
      }
      const { job_id } = await $.post(`/api/v1/campaigns/${campaignId}/geopath`, params)
      this.state.job_pooling.startPolling(job_id, this.onJobComplete)
      this.setState({ job_id })
    })
  },

  onJobComplete(_response) {
    if(!this.state.generating) { return }
    this.setState({ generating: false })
    if (this.props.loadGeopath) this.props.loadGeopath()
  },

  renderRefreshButton() {
    if (this.state.generating) {
      return (
        <b style={{marginLeft:15}}>Generating...</b>
      )
    } else {
      const hasValidationError = !this.state.valid

      return (
        <form className="geopath-summary-form" onSubmit={this.onSubmit} style={{paddingBottom:10}}>
          <div className="row" style={{margin:0}}>
            <div className="col-sm-12 col-md-6">
              <Select
                className={`selectInput ${hasValidationError ? 'validation-error' : ''}`}
                placeholder="Select Demographic"
                value={this.state.demo_keys.find(o => o.value === this.state.demo_key)}
                options={this.state.demo_keys}
                onChange={this.onDemoKeyChange}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <input type="submit" className="btn btn-default" value="Generate"/>
            </div>
          </div>
        </form>
      )
    }
  },

  showSummary() {
    this.loadDemographicKeys()
    this.setState({renderMore: this.state.renderMore ? false : true})
  },

  renderToggle() {
    return (
      <div className="toggle-more-options" style={{padding:10,paddingTop:0}}>
        <a onClick={() => this.showSummary()}>
          {this.state.renderMore ?
              <i className="fa fa-caret-down" style={{ marginLeft: 5 }} /> :
                <i className="fa fa-caret-right" style={{ marginLeft: 5 }} />}
          {(this.state.renderMore && !this.props.demographics) ? "Loading" : "Show"} Geopath Summary
        </a>
      </div>
    );
  },

  render() {
    const response = this.props.demographics && this.props.demographics.by_market

    if(this.props.loading && this.props.flag) {
      return (
        <ContentLoader
          height={30}
          width={536}
          speed={2}
          primaryColor="#afcee9"
          secondaryColor="#ecebeb"
        >
          <rect x="14.84" y="6.67" rx="0" ry="0" width="217" height="14" />
        </ContentLoader>
      )
    }

    if (!this.props.data || !this.props.data.permissions || !this.props.data.permissions.can_view_geopath) {
      return <div></div>
    } else if (!response || !this.state.renderMore) {
      return this.renderToggle()
    } else if (response && response.length == 0) {
      return (
        <div>
          {this.renderToggle()}
          {this.renderRefreshButton()}
        </div>
      )
    }

    return (
      <div>
        {this.renderToggle()}
        {this.renderRefreshButton()}
        {response && !this.state.generating &&
          this.renderMetrics(response)
        }
      </div>
    );
  },

  renderSummaryTitle({ market }) {
    const tag = !!market.tag ? _.capitalize(`${market.tag} only`) : "All"
    return (
      <div className="market">
        <i className="fa fa-map-marker marker" />
        <span className="title">{(market.market_name || `${market.name} ${market.state}`)} {` - ${tag}`}</span>
      </div>
    )
  },

  renderMetrics(items) {
    const visible = items.filter(item => (
        !item.market.tag ||
        (this.props.favoritesOnly && item.market.tag == 'favorites') ||
        (this.props.recommendedOnly && item.market.tag == 'recommended') ||
        (!this.props.favoritesOnly && !this.props.recommendedOnly && ['recommended', 'favorites'].indexOf(item.market.tag) === -1)
      ))
    return visible.map(data => (
      <div className="campaign-demographics" key={`market-demo-${data.market.dma_id}${data.market.tag || ''}`}>
        {this.renderSummaryTitle(data)}
        <div className="population">
          <div>
            <span style={{fontWeight: 600}}>{accounting.formatNumber(data.market.population)}</span>
            <span style={{fontSize: '13px'}}>Population</span>
          </div>
          <div>
            <span style={{fontWeight: 600}}>{accounting.format(data.rfp_summary.average_frequency, 2)}</span>
            <span style={{fontSize: '13px'}}>Frequency (Avg.)</span>
          </div>
          <div>
            <span style={{fontWeight: 600}}>{accounting.format(data.rfp_summary.reach_percentage * 100, 2)}%</span>
            <span style={{fontSize: '13px'}}>Reach</span>
          </div>
        </div>
        <div className="line"><hr/></div>
        <div className="coverage">
          <div style={{color: '#777777'}}>
            <span style={{fontWeight: 600}}>{accounting.format(data.coverage_summary.tab_coverage, 2)}%</span>
            <span style={{fontSize: '13px'}}>Units with Geopath Data</span>
          </div>
          <div>
            <span style={{fontWeight: 600}}>{accounting.format(data.rfp_summary.gross_rating_points, 2)}</span>
            <span style={{fontSize: '13px'}}>{data.rfp_summary.demographic.demo_key == 'f419650a-8e66-443a-8b09-e80022cb104f' ? 'GRP' : 'TRP'}</span>
          </div>
        </div>
      </div>
    ));
  }
});

export default Demographics;
