import React, { Component } from "react";
import * as styleGen from "./styles";
import { ICampaignPermissions } from "../../../models/campaign";

interface IUploadButtonListProps {
  campaign_permissions: ICampaignPermissions;
  renderUploadButton: (disabled?: boolean) => React.ReactNode;
  renderUploadPopButton: () => React.ReactNode;
}

interface IUPloadButttonListState {
  show_dropdown: boolean;
}

class UploadButtonList extends Component<
  IUploadButtonListProps,
  IUPloadButttonListState
> {
  constructor(props) {
    super(props);
    this.state = {
      show_dropdown: false
    };
  }

  public render() {
    const { campaign_permissions } = this.props;
    const showUploadAsset = campaign_permissions.can_upload_design_asset;
    const showUploadPop = campaign_permissions.can_upload_pop;
    if (!showUploadAsset && !showUploadPop) {
      return this.renderNoActionButton();
    }
    return (
      <div style={{ position: "relative" }} onClick={e => e.stopPropagation()}>
        <button
          className="btn btn-default"
          onClick={this.toggleDropdown.bind(this)}
          style={styleGen.uploadButton()}
        >
          <i className="fa fa-cloud-upload" style={{ marginRight: 5 }} />
          Upload
          <i
            className="fa fa-angle-down"
            style={{ marginLeft: 5, marginRight: 0 }}
          />
        </button>
        {this.state.show_dropdown && (
          <ul style={styleGen.buttonList()}>
            {showUploadAsset && <li>{this.props.renderUploadButton()}</li>}
            {showUploadPop && (
              <li style={{ marginTop: "5px" }}>
                {this.props.renderUploadPopButton()}
              </li>
            )}
          </ul>
        )}
      </div>
    );
  }

  private renderNoActionButton() {
    return (
      <button
        className="btn btn-default"
        disabled={true}
        style={styleGen.uploadButton()}
      >
        -
      </button>
    );
  }

  private toggleDropdown() {
    const { show_dropdown } = this.state;
    this.setState({ show_dropdown: !show_dropdown });
  }
}

export default UploadButtonList;
