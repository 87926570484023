import accounting from 'accounting';
import React from 'react'

type HeaderProps = {
  unit: {
    installation_cost: number;
    production_cost: number;
    rate_card_price: number;
    price: number;
    higher_price: number;
    billing_cycle?: string;
    price_for_duration?: string;
  };
  user: {
    is_supplier: boolean;
  };
  base_price: string;
  showPricing: boolean;
  showRateCard: boolean;
  showPriceForDuration: boolean;
  mockRatecard: boolean;
  useRateCardOnly: boolean;
};

type RowProps = {
  row_info: {
    label: string;
    style: string;
    copy: string;
    icon: string | null
  };
  idx: number;
};

const saving_info = (price, higher_price, rate_card_price, holder_price) => {
  const price_to_calculate = holder_price.includes("–") ? parseInt(holder_price.replace(/,/g, "").match(/[-+]?[0-9]*\.?[0-9]+/g)[0]) : price;
  const savings_percent = (((rate_card_price - price_to_calculate) / rate_card_price) * 100).toFixed(0) + "%";
  const savings_total = rate_card_price - price_to_calculate;
  return { savings_percent, savings_total };
};

const RenderRow: React.FC<RowProps> = ({ row_info, idx }) => {
  return (
    <tr key={idx}>
      <td style={{ width: "70px" }}>
        <small>{row_info.label}</small>
      </td>
      <td className={row_info.style}>
        {row_info.copy}
        { row_info.icon && <i className={row_info.icon} style={{ marginLeft: '10px' }}></i> }
      </td>
    </tr>
  );
};

const determine_displayItems = (showPricing, showRateCard, showPriceForDuration, is_supplier, savings_total, rate_card_price, price, price_for_duration, installation_cost,
  production_cost, useRateCardOnly) => {
  let display_items = [] as string[];
  let prod_install_display_items = [] as string[];

  if ((price == 0 || price == null) && (rate_card_price == 0 || rate_card_price == null)) {
    display_items = ["prod_display", "install_display"];
    prod_install_display_items = [];
  } else {
    if (showPriceForDuration) {
      display_items.push("price_for_duration_display")
    } else {
      if (showPricing && !useRateCardOnly && price != 0 && price != null) {
        display_items.push("price_display")
      }
      if ((showPricing || showRateCard) && rate_card_price != 0 && rate_card_price != null) {
        display_items.push("rate_card_display")
      }
      if (showPricing && !is_supplier && savings_total > 0 && !useRateCardOnly) {
        display_items.push("savings_display");
      }
    }
    if (production_cost != null) {
      prod_install_display_items.push("prod_display")
    }
    if (installation_cost != null) {
      prod_install_display_items.push("install_display")
    }
  }

  if (display_items.length == 0 && prod_install_display_items.length > 1) {
    display_items = prod_install_display_items;
    prod_install_display_items = [];
  }

  return { display_items, prod_install_display_items };
};

const HeaderPrice: React.FC<HeaderProps> = ({ unit, user, base_price, showPricing, showRateCard, showPriceForDuration, mockRatecard, useRateCardOnly }) => {
  const { installation_cost, production_cost, rate_card_price, price, higher_price, price_for_duration } = unit;
  const holder_price = typeof base_price === "string" ? base_price : "0";
  const up_to_string = holder_price.includes("–") ? "up to " : "";
  const { savings_percent, savings_total } = saving_info(price, higher_price, rate_card_price, holder_price);
  const display_price = holder_price.replace(/\.(.*?[0-9]{1,2})/g, "");
  const show_monthy_cycle_icon = unit.billing_cycle && unit.billing_cycle == 'monthly'
  const items = {
    price_display: { style: "price_total", label: "Your Price: ", copy: `${display_price} / 4wk`, icon: `${show_monthy_cycle_icon ? "fal fa-calendar" : null}` },
    price_for_duration_display: { style: "price_total", label: "Your Price: ", copy: accounting.formatMoney(price_for_duration), icon: `${show_monthy_cycle_icon ? "fal fa-calendar" : null}` },
    rate_card_display: { style: "rate_card_prices", label: "Rate Card: ", copy: accounting.formatMoney(rate_card_price), icon: null },
    savings_display: { style: "savings_rate_prices", label: "You Save:", copy: `${up_to_string}${accounting.formatMoney(savings_total)} (${savings_percent})`, icon: null },
    install_display: { style: "prod_install", label: "+Installation: ", copy: `${accounting.formatMoney(installation_cost)}`, icon: null },
    prod_display: { style: "prod_install", label: "+Production: ", copy: `${accounting.formatMoney(production_cost)}`, icon: null },
    mock_rate_display: { style: "price_total", label: "Rate Card: ", copy: `${mockRatecard}`, icon: null },
  };
  const { display_items, prod_install_display_items } = determine_displayItems(
    showPricing,
    showRateCard,
    showPriceForDuration,
    user.is_supplier,
    savings_total,
    rate_card_price,
    price,
    price_for_duration,
    installation_cost,
    production_cost,
    useRateCardOnly
  );

  return (
    <>
      <div className="unitInfo__price">
        <table className="totals_items">
          <tr>
            <th style={{ textAlign: "right" }}></th>
            <th style={{ textAlign: "left" }}></th>
          </tr>
          {display_items.map((item, idx) => <RenderRow row_info={items[item]} idx={idx} />)}
        </table>
        <table className="totals_items">
          {prod_install_display_items.map((item, idx) => <RenderRow row_info={items[item]} idx={idx} />)}
        </table>
      </div>
    </>
  );
};

export default HeaderPrice;
