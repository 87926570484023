import React from 'react';
import createClass from 'create-react-class';

import GlobalStore from '../stores/GlobalStore';
import GlobalActions from '../actions/GlobalActions';
import UnitActions from '../actions/UnitActions';

const Overlay = createClass({

  displayName: 'Overlay',

  componentDidMount() {
    document.addEventListener('keydown', this.onEscape);
  },

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape);
  },

  onClick() {
    this.closePopup();
  },

  onEscape(e) {
    if (e.keyCode == 27) {
      this.closePopup();
    }
  },

  closePopup() {
    const global = GlobalStore.getState();

    if (global.popup != 'splash') {
      GlobalActions.closePopup();
      if (global.popup == 'unit') {
        UnitActions.setActiveUnit(null);
      }
    }
  },

  render() {
    return (
      <div className="overlay" onClick={this.onClick}>
        {this.props.children}
      </div>
    );
  },
});

export default Overlay;
