import React from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
	unitOverage: any;
	campaignUnitToken: string;
};

type State = {
	show_modal: boolean;
	overage: any;
	campaignUnitToken: string;
};

class PackageOverage extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.showModal = this.showModal.bind(this);
		this.hideModal = this.hideModal.bind(this);
		this.renderForm = this.renderForm.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {
			show_modal: false,
			overage: this.props.unitOverage,
			campaignUnitToken: this.props.campaignUnitToken
		};
	}

	renderModalFormLink(text: string) {
		return <a onClick={this.showModal}>{text}</a>;
	}

	renderOverage() {
		if (this.state.overage) {
			return (
				<div>
					{this.state.overage} ({this.renderModalFormLink('Edit')})
				</div>
			);
		} else {
			return this.renderModalFormLink('Assign Overage');
		}
	}

	async onSubmit(e) {
		try {
			const response = await fetch(`/api/v1/production_order_items`, {
				method: 'PUT',
				body: JSON.stringify({
					production_order_item: {
						overage: this.state.overage,
						campaign_unit_token: this.state.campaignUnitToken
					}
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			});
		} catch (error) {
			alert(error);
		}
	}

	renderForm() {
		return (
			<form onSubmit={this.onSubmit} className="form form-horizontal">
				<div className="form-group">
					<label className="col-sm-2">Overage</label>
					<div className="col-sm-10">
						<input
							className="input form-control"
							type="number"
							onChange={(e) => this.setState({ overage: e.target.value })}
							placeholder='Overage will increase the total Quantity'
							value={this.state.overage}
						/>
					</div>
				</div>

				<button className="btn btn_primary register__submit" type="submit">Submit</button>
			</form>
		);
	}

	private renderModal(title: String) {
		return (
			<div onClick={(e) => e.stopPropagation()}>
				<Modal
					dialogClassName="image_modal"
					backdrop={true}
					keyboard={true}
					show={this.state.show_modal}
					onHide={this.hideModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{this.renderForm()}</Modal.Body>
				</Modal>
			</div>
		);
	}

	private showModal() {
		this.setState({ show_modal: true });
	}
	private hideModal() {
		this.setState({ show_modal: false });
	}

	render() {
		return (
			<ul>
				<li style={{ fontSize: 11 }}>Overage</li>
				<li>{this.renderOverage()}</li>
				{this.renderModal('Assign Overage')}
			</ul>
		);
	}
}

export default PackageOverage;
