import React, { Component } from 'react'

class DataLayerLegend extends Component {
  constructor(props) {
    super(props)
    this.state = {show_legend: true}
  }
  render() {
    const legend = this.props.legend;
    const show_legend = this.state.show_legend;
    return (
      <div className="row">
        <div className="col-sm-2">
            {legend &&
            <span className="toggle_legend" onClick={() => this.setState({show_legend: show_legend ? false : true})}>
              {show_legend ?
                  <i title="Hide Legend" className="fa fa-eye" style={{ marginLeft: 5 }} /> :
                    <i title="Show Legend" className="fa fa-eye-slash" style={{ marginLeft: 5 }} />}
            </span>}
        </div>
        <div className="col-sm-10">
          {legend && show_legend &&
            legend.map(l => <div style={{marginBottom: 2, width:'50%',float:'left'}} key={l.text}>
            <div style={{height:20,width:20,backgroundColor:l.color,float:'left'}}></div>
              <span style={{marginLeft:5}}>{l.text}</span>
            </div>)}
        </div>
      </div>
    );
  }
}

export default DataLayerLegend;
