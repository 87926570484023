import PropTypes from "prop-types";
import React from "react";
import { connect } from 'react-redux';
import accounting from "accounting";
import AuthStore from "../../../stores/AuthStore";
import {
  calculateTotals,
  advertiserFeeData
} from "../../../utils/campaignGeopathCalculator";
import axios from "axios";
import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import Tooltip from "rc-tooltip";
import UnitModel from "../../../models/unitModel";
import { stringifyQueryState } from "../../../utils/QueryString";

class Summary extends React.Component {
  constructor(props) {
    super(props);
    const advertiserGreyLabel = AppConfig.advertiserGreyLabel;
    this.state = {
      showTotal: true,
      only4week: false,
      priceMessage: 'Recommended',
      advertiserGreyLabel: advertiserGreyLabel,
      user: AuthStore.getState().user,
      totals: {}
    }
    this.onToggle4Week = this.onToggle4Week.bind(this);
    this.getTotals = throttle(this.getTotals.bind(this), 500)
  }

  componentDidMount() {
    this.getTotals()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.unit_filter, this.props.unit_filter) ||
        prevProps.units.length != this.props.units.length) {
      this.getTotals()
    }
  }

  async getTotals() {
    this.props.enableSidebarOverlay();
    const { data } = await axios.get(`/api/v1/campaigns/${this.props.data.campaignId}/totals?${this.reduxStateToQueryString()}`);

    this.setState({totals: data || {}}, this.props.disableSidebarOverlay)
  }

  reduxStateToQueryString() {
     // Isolate some parts of the state:
     // 1. Tags we need to parse them manually since it's an object
     // 2. restOfFilters don't need parsing
     // 3. Remove params that equal false (e.g. foo=false) and stringify object
     const { tags, audience_units, ...restOfFilters } = this.props.unit_filter;
     const filtersState = {
       ...restOfFilters,
       ...{'tags[]': tags.map(t => JSON.stringify(Object.assign({}, {color: t.color, tag: t.tag} )))}
     }
     return stringifyQueryState(_.pickBy(filtersState, (i) => !!i))
  }

  renderImpressions(impressions4week, totalImpressions, only4week) {
    const { totals } = this.state
    const count = (this.state.showTotal && !only4week) ? totals['impressions'] : totals['total_4week_impressions']
    const message = (this.state.showTotal && !only4week) ? "Total Impressions" : "4 Week Impressions";

    return (
      <div>
        <div className="detail-amount">
          {!this.props.loading ?
            accounting.format(count) :
            <span style={{ color: 'gray' }}>−</span>
          }
        </div>
        <div className="detail-name">{message}</div>
      </div>
    );
  }

  renderManagedVendorPrice(price) {
    return (
      <div>
        <div className="detail-amount">
          {!this.props.loading ?
            accounting.formatMoney(price) :
            <span style={{ color: 'gray' }}>−</span>
          }
          {this.renderFourWeekToggle()}
        </div>
      </div>
    );
  }

  renderFourWeekToggle() {
    const totalMessage = (this.state.showTotal && !this.state.only4week ) ? 'View 4 Week ' : 'View Total';
    return <span className="four-week-toggle" onClick={this.onToggle4Week}>{totalMessage}</span>;
  }

  onToggle4Week() {
    const { only4week } = this.state
    this.setState({ only4week: !only4week })
  }

  renderPrice(isCompass) {
    const { only4week, priceMessage, showTotal, totals } = this.state;
    const calculatedFee = isCompass ? this.state.calculatedFee : 0;
    const message = (showTotal && !only4week) ? `Subtotal` : `4 Week Subtotal`;
    const currentPrice = (showTotal && !only4week) ? totals['media_total_with_analytics'] : totals['four_week_media_total_with_analytics'];
    const total_rate_card_price_display = (showTotal && !only4week) ? totals['rate_card'] : totals['total_4week_rate_card'];
    const total_savings_display = (showTotal && !only4week) ? totals['total_savings'] : totals['total_4week_savings'];
    const total_savings_percent_display = (showTotal && !only4week) ? totals['total_savings_percent'] : totals['total_4week_savings_percent'];

    return (
      <div style={{ position: 'relative' }}>
        <div className="detail-amount" style={{ paddingRight: '18px;' }}>
          {!this.props.loading ?
            accounting.formatMoney(currentPrice) :
            <span style={{ color: 'gray' }}>−</span>
          }
          {(calculatedFee > 0) &&
            <i className="fa fa-info-circle price-info-icon" aria-hidden="true"></i>
          }
          {(calculatedFee > 0) &&
            this.renderPriceInfoTooltip(currentPrice)
          }
          {this.renderFourWeekToggle()}
        </div>
        <div className="detail-name">
          {message}
        </div>
        {(currentPrice > 0 && total_savings_display > 0) &&
          <div className="detail-rate-amount">
            <div>
              Rate Card: {accounting.formatMoney(total_rate_card_price_display)}
            </div>
            <div>Savings: <span className="rate-highlight">
              {`${accounting.formatMoney(total_savings_display)} (${total_savings_percent_display})`}
            </span></div>
          </div>
        }
      </div>
    );
  }

  aggregateCPM() {
    if (this.props.loading) return <span style={{ color: 'gray' }}>−</span>
    const { totals, showTotal, only4week } = this.state
    const cpm_attribute = (showTotal && !only4week) ? "total_cpm" : "total_4week_cpm"
    if (!totals[cpm_attribute]) return accounting.formatMoney(0)
    const { enable_analytics_cpm, analytics_charge_method, analytics_cpm } = this.props.data.campaign
    return new UnitModel().getCpmWithAnalytics(enable_analytics_cpm, analytics_charge_method, totals[cpm_attribute], analytics_cpm)
  }

  renderCpmLabel() {
    const { enable_analytics_cpm, analytics_charge_method } = this.props.data.campaign
    return (
      <div className="detail-name">
        Aggregate CPM
        {enable_analytics_cpm && analytics_charge_method === 'baked_in' && (
          <Tooltip overlay={<span>Media + Analytics</span>}>
            <span> <i className="far fa-info-circle" /></span>
          </Tooltip>
        )}
      </div>
    )
  }

  renderPriceInfoTooltip(price) {
    const { showPriceInfoTooltip, advertiserGreyLabel,
      calculatedFee, production_install } = this.state;
    const feeAsPercentage = advertiserGreyLabel.adquick_fee ? (advertiserGreyLabel.adquick_fee * 100).toFixed(1).toString() : "3.9";
    return (
      <div className={showPriceInfoTooltip ? "price-info-tooltip" : "price-info-tooltip "}>
        <p className="price-info-tooltip-detail">
          Unit Costs<span className="right">{accounting.formatMoney(price)}</span>
        </p>
        <p className="price-info-tooltip-detail">
          ({feeAsPercentage}%) Fee<span className="right">+ {accounting.formatMoney(calculatedFee)}</span>
        </p>
        <p className="price-info-tooltip-detail">
          Production & Install<span className="right">+ {accounting.formatMoney(production_install)}</span>
        </p>
      </div>
    )
  }

  renderCampaignTotals() {
    const { data: { campaign }} = this.props
    const { only4week, showTotal, totals } = this.state;
    const currentPrice = (showTotal && !only4week) ? totals['media_total_with_analytics'] : totals['four_week_media_total_with_analytics'];
    const total_analytics = (showTotal && !only4week) ? totals['total_analytics'] : totals['total_4week_analytics'];
    const service_fee = (showTotal && !only4week) ? totals['service_fee'] : totals['four_week_service_fee']
    const total_overall_price = (showTotal && !only4week) ? totals['grand_total'] : totals['four_week_grand_total']
    return (
      <div className="campaign-detail campaign-totals">
        <table>
          <thead>
            <tr><th colSpan="2" className="title">Campaign Total</th></tr>
          </thead>
          <tbody>
            {!campaign.enable_analytics_cpm && (
              <tr>
                <td>Media</td>
                <td>{accounting.formatMoney(currentPrice)}</td>
              </tr>
            )}
            {campaign.enable_analytics_cpm && campaign.analytics_charge_method === 'baked_in' && (
              <tr>
                <td>Media &amp; Analytics</td>
                <td>{accounting.formatMoney(currentPrice)}</td>
              </tr>
            )}
            {campaign.enable_analytics_cpm && campaign.analytics_charge_method !== 'baked_in' && <>
              <tr>
                <td>Media</td>
                <td>{accounting.formatMoney(currentPrice)}</td>
              </tr>
              <tr>
                <td>Analytics</td>
                <td className={campaign.analytics_charge_method === 'crossed_out' ? 'line-through' : undefined}>
                  {accounting.formatMoney(total_analytics)}
                </td>
              </tr>
            </>}
            <tr>
              <td>Prod &amp; Install</td>
              <td>{accounting.formatMoney(totals['total_production_and_install_cost'])}</td>
            </tr>
            <tr>
              <td>Add On</td>
              <td>{accounting.formatMoney(totals['add_ons_totals'])}</td>
            </tr>
            <tr>
              <td>Service Fee</td>
              <td>{accounting.formatMoney(service_fee)}</td>
            </tr>
          </tbody>
          <tfoot>
            <td id="total">Total:</td>
            <td>{accounting.formatMoney(total_overall_price)}</td>
          </tfoot>
        </table>
      </div>
    );
  }

  render() {
    const { show, data } = this.props;
    const { only4week, totalPrice, impressions, totalImpressions, production_install, user, showTotal, totals } = this.state;
    const price = totals['price'];
    const count = totals['count'];
    const { campaign } = data;
    const isMockCampaign = campaign.is_mock;
    const { isCompass } = advertiserFeeData(totalPrice, price, production_install, showTotal);
    const unitCountDisplay = count > 0 ? `${count} UNITS` : ''

    if (campaign.hide_prices) return <div></div>

    if (!isMockCampaign && (show || user.is_managed_supplier || user.is_admin)) {
      return (
        <div style={{ padding: '0px 12px 0px 12px', borderBottom: 'solid 1px #dadada' }}>
          <div className="campaign-details" style={isCompass ? { height: 80 } : {}}>
            <div className="campaign-detail" style={price == 0 ? { height: '80px', position: 'relative' } : {}}>
              <div className="unit-count">{unitCountDisplay}</div>
              <div className="campaign-border" style={{ border: 'none' }}>
                {user.is_managed_supplier ?
                  <div>{this.renderManagedVendorPrice(totalPrice)}</div> :
                  <div>{this.renderPrice(isCompass)}</div>
                }
              </div>
              <div className="impressions-and-cpm">
                <div>
                  <div className="detail-amount">
                    {this.aggregateCPM()}
                  </div>
                  {this.renderCpmLabel()}
                </div>
                {this.renderImpressions(impressions, totalImpressions, only4week)}
              </div>
            </div>
            {this.renderCampaignTotals()}
          </div>
        </div>
      );
    } else {
      return <div />
    }
  }
}

Summary.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = ({ units: { unit_filter }}) => ({
  unit_filter
});

export default connect(mapStateToProps, null)(Summary)
