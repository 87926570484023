import { connect } from 'react-redux';
import React, { Component } from 'react';
import cs from 'classnames';
import {
  toggleRecommendUnits
} from '../../../actions/UnitsActions';

class RecommendedBadge extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  async onClick(event) {
    const { unitId, campaignId, isRecommended, toggleRecommendUnits } = this.props;
    event.stopPropagation();
    await toggleRecommendUnits([unitId], campaignId, this.action())
  }

  label() {
    const { isRecommended } = this.props;
    return isRecommended ? 'Recommended' : 'Recommend';
  }

  action() {
    const { isRecommended } = this.props;
    return isRecommended ? 'unrecommend' : 'recommend';
  }

  render(){
    const { isRecommended, canRecommend } = this.props;
    if (!canRecommend && !isRecommended) return '';
    if (!canRecommend && isRecommended) {
      return <span className='badge badge_recommended expanded is_recommended'>Recommended</span>
    } else if (canRecommend) {
      return (
        <span onClick={this.onClick} className={cs('badge badge_recommended expanded', { is_recommended: isRecommended })}>{this.label()}</span>
      )
    }
  }
}

export default connect(
  null,
  { toggleRecommendUnits }
)(RecommendedBadge)
