import React, { Component } from 'react';
import moment from 'moment';
import { truncate, get } from 'lodash';

import GlobalActions from '../../../actions/GlobalActions';

class ActivityLog extends Component {

  constructor(props) {
    super(props);
    this.onRestore = this.onRestore.bind(this);
    this.state = {
      restored: !!this.props.activity_log.data.restored,
      unitDeleted: !!this.props.activity_log.unit_deleted
    }
  }

  async onRestore(e) {
    e.preventDefault();

    if (confirm("Are you sure you want to restore this flight?")) {
      if (this.props.activity_log.data && this.props.activity_log.data.campaign_unit_token) {
        const campaign_unit_token = this.props.activity_log.data.campaign_unit_token
        await this.props.restoreCampaignUnit(campaign_unit_token);
        this.setState({restored: true})
        GlobalActions.showMessage('Flight restored!');
      } else {
        console.debug('cant find associated unit', log.event_name, log);
      }
    }
  }

  renderViewUnitLink(unit_id) {
    const { toggleUnitModal } = this.props
    if (!this.state.unitDeleted) {
      return <li><a style={{padding: '4px 6px'}} onClick={() => { toggleUnitModal(unit_id); }}>View unit</a></li>
    } else {
      return <li className='disabled'><a style={{padding: '4px 6px'}}>View unit</a></li>
    }
  }

  renderRestoreLink(data) {
    const padding = {padding: '4px 6px'}
    if (!this.state.restored && !this.state.unitDeleted) {
      return <li><a style={padding} onClick={this.onRestore}>Restore</a></li>
    } else {
      return <li className='disabled'><a style={padding} href='#'>{this.state.unitDeleted ? 'Restore' : 'Restored'}</a></li>
    }
  }

  render() {
    const noop = () => {};

    const rowStyle =  {
      color: '#7B8895',
      marginBottom: 8
    }

    const log = this.props.activity_log
    const userEmail = log.user_email
    const userName = log.user_name || truncate(userEmail, { length: 12, separator: '@'}) || 'User'
    const unitName = get(log, 'unit.name') || get(log, 'data.unit')
    const supplierFaceId = get(log, 'unit.supplier_face_id')
    const friendlyUnitName = unitName ? `Unit ${supplierFaceId}` : 'a unit'
    const recipientName = get(log, 'data.share.recipient_name', '')
    const recipientEmail = get(log, 'data.share.recipient_email', '')
    const recipientString = recipientName ? `${recipientName} - ${recipientEmail}` : `${recipientEmail}`

    const messages = {
      CAMPAIGN_UNITS_SORT: log => `sorted units by ${log.data.sortBy}`,
      CAMPAIGN_SHARED: log => `shared proposal with ${recipientString}`,
      CAMPAIGN_ADD_ALL_TO_CART: log => `added all units to cart`,
      CAMPAIGN_VIEWED: log => `viewed the campaign`,
      CAMPAIGN_UNIT_DESTROYED: log => `${friendlyUnitName} / Removal Reason: ${log.data.removal_reason == null ? 'none' : log.data.removal_reason}`,
      CAMPAIGN_UNIT_CLICK: log => `viewed ${friendlyUnitName}`,
      CAMPAIGN_MAP_UNIT_CLICK: log => friendlyUnitName,
      CAMPAIGN_LOCATION_SEARCH: log => `Searched for ${truncate(log.data.query, {'length': 24})}`,
      CAMPAIGN_UNIT_RESTORED: log => 'Restored flight',
      CAMPAIGN_SHARE_LINK_OPENED: log => `opened share link`,
      CAMPAIGN_FAVORITED_UNIT: log => friendlyUnitName,
      CAMPAIGN_UNFAVORITED_UNIT: log => friendlyUnitName,
      CAMPAIGN_RECOMMENDED_UNIT: log => friendlyUnitName,
      CAMPAIGN_UNRECOMMENDED_UNIT: log => friendlyUnitName
    }

    const icons = {
      CAMPAIGN_UNITS_SORT: "fa fa-sort-alpha-asc",
      CAMPAIGN_SHARED: "fa fa-share-square-o",
      CAMPAIGN_ADD_ALL_TO_CART: "fa fa-cart-plus",
      CAMPAIGN_VIEWED: "fa fa-eye",
      CAMPAIGN_UNIT_DESTROYED: "fa fa-trash-o",
      CAMPAIGN_UNIT_CLICK: "fa fa-mouse-pointer",
      CAMPAIGN_MAP_UNIT_CLICK: "fa fa-map-marker",
      CAMPAIGN_LOCATION_SEARCH: "fa fa-search",
      CAMPAIGN_UNIT_RESTORED: "fa fa-undo",
      CAMPAIGN_SHARE_LINK_OPENED: "fa fa-share-square-o",
      CAMPAIGN_FAVORITED_UNIT: "fa fa-heart",
      CAMPAIGN_UNFAVORITED_UNIT: "fa fa-heart-o",
      CAMPAIGN_RECOMMENDED_UNIT: "fa fa-registered",
      CAMPAIGN_UNRECOMMENDED_UNIT: "fa fa-times-circle"
    }

    const actions = {
      CAMPAIGN_UNITS_SORT: noop,
      CAMPAIGN_SHARED: noop,
      CAMPAIGN_ADD_ALL_TO_CART: noop,
      CAMPAIGN_VIEWED: noop,
      CAMPAIGN_UNIT_DESTROYED: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
          {this.renderRestoreLink(log.data)}
        </ul>
      ),
      CAMPAIGN_UNIT_CLICK: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
        </ul>
      ),
      CAMPAIGN_MAP_UNIT_CLICK: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
        </ul>
      ),
      CAMPAIGN_LOCATION_SEARCH: noop,
      CAMPAIGN_UNIT_RESTORED: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
        </ul>
      ),
      CAMPAIGN_SHARE_LINK_OPENED: noop,
      // Link is broken until we fix data (populate unit_id based on data->>unit token)
      CAMPAIGN_FAVORITED_UNIT: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
        </ul>
      ),
      // Link is broken until we fix data (populate unit_id based on data->>unit token)
      CAMPAIGN_UNFAVORITED_UNIT: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
        </ul>
      ),
      CAMPAIGN_RECOMMENDED_UNIT: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
        </ul>
      ),
      // Link is broken until we fix data (populate unit_id based on data->>unit token)
      CAMPAIGN_UNRECOMMENDED_UNIT: log => (
        <ul className="nav nav-pills">
          {this.renderViewUnitLink(log.unit.id)}
        </ul>
      )
    }

    const actionMessage =  !messages[log.event_name] ? log.event_name : messages[log.event_name](log);
    const actionLinks = !messages[log.event_name] ? '' : actions[log.event_name](log);
    const time = moment(log.created_at).format(this.props.timeFormat)
    const iconClassName = (icons[log.event_name] || 'fa fa-circle-thin')

    return (
      <div className='row' key={log.id} style={rowStyle}>
        <div className='col-sm-5'>{actionMessage}</div>
        <div className='col-sm-3'>{actionLinks}</div>
      </div>
    )
  }
}

export default ActivityLog
