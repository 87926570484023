import React from 'react';
import { browserHistory } from 'react-router-legacy';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore'
import GlobalActions from '../../actions/GlobalActions'
import { BootstrapTable } from 'react-bootstrap-table';
import { formatMoney } from 'accounting'
import moment from 'moment'
import UploadButton from '../UploadButton'

const EmailTemplates = createClass({
  displayName: 'EmailTemplates',

  getInitialState() {
    return {
      isLoggedIn: AuthStore.isLoggedIn(),
      user: AuthStore.getState().user,
      emailTemplates: [],
    }
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ]

    this.fetchContacts()
  },

  onAuthChange() {
    this.setState({ isLoggedIn: AuthStore.isLoggedIn() })

    if (AuthStore.isLoggedIn()) {
      this.fetchContacts()
    } else {
      GlobalActions.openPopup('login')
    }
  },

  fetchContacts() {
    $.get('/api/v1/email_templates').then(emailTemplates => this.setState({ emailTemplates }))
  },

  onUploadComplete(upload, file) {
    GlobalActions.showMessage("Your emailTemplates file is being processed in the background...")
    $.post('/api/v1/emailTemplates', { file_url: file.url }).then(() => {
      GlobalActions.showMessage("Your emailTemplates file has finished processing.")
    })
  },

  render() {
    const { isLoggedIn, emailTemplates } = this.state

    const emailTemplateStyle = {
      marginBottom: 10,
      border: '1px solid #eee',
      padding: 5,
      fontSize: 15,
    }

    if (isLoggedIn) {
      return (
        <div className="container">
          <div style={{float:'right',marginTop:25}}>
            <div style={{float:'right'}}>
              <button type="button" className="btn btn-default" onClick={() => browserHistory.push('/email_templates/new')}>
                <i className="fa fa-plus" style={{ marginRight: 7.5 }} />
                Add Template
              </button>
            </div>
          </div>

          <h1>Email Templates</h1>

          <table className="table table-striped">
            <thead>
              <tr style={{fontWeight:'bold'}}>
                <td>Creator</td>
                <td>Template Name</td>
                <td>Subject Line</td>
                <td>Sent</td>
                <td>Open Rate</td>
                <td>Sign Up Rate</td>
                <td>Campaign Request Rate</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {emailTemplates.map(emailTemplate => <tr key={emailTemplate.id}>
                <td>{emailTemplate.creator}</td>
                <td>{emailTemplate.name}</td>
                <td>{emailTemplate.subject}</td>
                <td>0</td>
                <td>0%</td>
                <td>0%</td>
                <td>0%</td>
                <td>
                  <a onClick={() => browserHistory.push(`/email_templates/${emailTemplate.id}`)}>Edit</a>
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="text-center" style={{ padding: 50 }}>Please login</div>
      )
    }
  }
})

export default EmailTemplates;
