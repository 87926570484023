import * as React from "react";
import { connect } from "react-redux";

import { fetchStaleAvailability } from "./actions";

class StaleAvailability extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier);
    }
  }

  fetchData(supplier) {
    const { fetchStaleAvailability } = this.props;
    fetchStaleAvailability(supplier);
  }

  onLinkClick() {
    const { currentUser, selected_supplier } = this.props;
    if (currentUser.is_admin) {
      window.location.href = `/inventory?stale_availability=true&supplier=${selected_supplier}`;
    } else {
      window.location.href = "/inventory?stale_availability=true";
    }
  }

  render() {
    const { stale_availability, last_flight_created_at, loading_stale_availability } = this.props;

    if (!!!loading_stale_availability && stale_availability) {
      return (
        <div id="stale_availability_banner">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <div className="count-area">A flight hasn't been created in the past {last_flight_created_at} days</div>
          <div className="banner-link-area">
            <a className="banner-link" onClick={this.onLinkClick}>
              Update Availability
            </a>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: { stale_availability, last_flight_created_at, loading_stale_availability, selected_supplier },
    // @ts-ignore
    currentUser,
  }) => ({
    stale_availability,
    last_flight_created_at,
    loading_stale_availability,
    selected_supplier,
    currentUser,
  }),
  { fetchStaleAvailability },
)(StaleAvailability);
