import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import GlobalActions from "../../actions/GlobalActions";
import { fetchOpenUserSupplierTasks } from './actions';
import { hideModal } from "./actions";

class AddUnitsToRfpModal extends React.Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      validationErrors: [],
      selected_task: {},
      altered_task_url: null
    }
  }

  async componentDidMount() {
    const { fetchOpenUserSupplierTasks } = this.props;
    await fetchOpenUserSupplierTasks(this.props.user.id);
  }

  async submit(event) {
    event.preventDefault()
    const units = this.props.selected_units
    const params = {
      unit_ids: units.map(u => u.id),
    }
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    const resp = await fetch(`/api/v1/supplier_tasks/${this.state.selected_task.value}/append_data`, {headers: headers, body: JSON.stringify(params), method: 'PUT'})
    if (resp.status == 500) {
      GlobalActions.showError('Error adding data to RFP')
      return this.setState({error: 'Error adding data to RFP'})
    }

    if (resp.status == 200) {
      const body = await resp.json()
      this.setState({altered_task_url: body.data.supplier_task.task_path})
      GlobalActions.showMessage("Data added to RFP.")
    }
  }

  render() {
    const {
      hideModal,
      show
    } = this.props;
    const selectedLength = this.props.selected_units.length;
    const message = `Adding data from ${selectedLength} units to the selected RFP.`;
    return (
      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{selectedLength === 0 ? 'Select units to add to RFP' : message} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderOptions()}
        </Modal.Body>
        <Modal.Footer>
          {this.state.altered_task_url && <a target='_blank' rel="noopener noreferrer" href={this.state.altered_task_url}>Click to see supplier task</a>}
        </Modal.Footer>

      </Modal>
    )
  }

  taskOptions() {
    const supplier_task_options = this.props.supplier_tasks || [];
    return supplier_task_options.map(task => {
      return {
        value: task.id,
        label: `[${task.assignee}] - ${task.campaign_name}`
      }
    });
  }


  handleChange(task) {
    this.setState({
      selected_task: task
    });
  }

  async onTagCreate(tag) {
    const { createTag } = this.props;
    await createTag(tag);
  }

  async onSearch(text) {
    const options = this.taskOptions();
    const match = options.filter(o => o.label.toLocaleLowerCase().includes(text.toLocaleLowerCase()) );
    return match;
  }

  async onTagRemove(e, tag_id) {
    const filtered_tags = this.state.selected_task.filter(t => t.value !== tag_id)
    this.setState({
      selected_task: filtered_tags
    })
  }

  renderOptions() {
    const supplier_task_options = this.taskOptions();

    return (
      <div id="unit-tags">
        <div className="header-section">
          <div className="header">
            <label>Open RFPs</label>
          </div>
        </div>
        <Select
          onChange={this.handleChange.bind(this)}
          onCreateOption={this.onTagCreate.bind(this)}
          options={supplier_task_options}
        />
        <input type="submit" value='Save' className='btn btn-primary' style={{marginTop: '10px'}} onClick={ e => this.submit(e)}/>
        <input type="submit" value='Close' className='btn btn-default' style={{marginTop: '10px', marginLeft: '20px'}} onClick={ _e => this.props.hideModal()}/>
      </div>
    );
  }

};

export default connect(
  ({inventory: {selected_units, supplier_tasks}}) => {
    return {selected_units, supplier_tasks};
  }, {hideModal, fetchOpenUserSupplierTasks }
)(AddUnitsToRfpModal);
