import React from "react";
import Dropzone from "react-dropzone";
import S3Upload from "react-s3-uploader/s3upload";

export default class DropzoneS3Uploader extends React.Component {
  constructor(props) {
    super();
    const uploadedFiles = [];
    const { filename } = props;
    if (filename) {
      uploadedFiles.push({
        filename,
        fileUrl: this.fileUrl(props.s3Url, filename),
        default: true,
        file: {},
      });
    }
    this.state = { uploadedFiles, uploaderOptions: {} };
    this.setUploaderOptions = this.setUploaderOptions.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleFinish = this.handleFinish.bind(this);
    this.fileUrl = this.fileUrl.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.renderProgress = this.renderProgress.bind(this);
  }

  componentDidMount() {
    this.setUploaderOptions(this.props);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setUploaderOptions(props);
  }

  setUploaderOptions(props) {
    const { isPublic } = props;
    let amzAcl;
    const directory = this.props.directory ? this.props.directory : "user_uploads";
    if (isPublic == true) {
      amzAcl = "public-read";
    } else {
      amzAcl = "private";
    }

    const signingUrlQueryParams = Object.assign(props.signingUrlQueryParams || {}, {
      amzAcl: amzAcl,
      directory: directory,
    });
    this.setState({
      uploaderOptions: Object.assign(
        {
          signingUrl: "/s3/sign",
          signingUrlQueryParams: signingUrlQueryParams,
          s3path: "",
          contentDisposition: "auto",
          uploadRequestHeaders: { "x-amz-acl": amzAcl },
          onFinishS3Put: this.handleFinish,
          onProgress: this.handleProgress,
          onError: this.handleError,
        },
        props.upload,
      ),
    });
  }

  handleProgress(progress, textState, file) {
    this.props.onProgress && this.props.onProgress(progress, textState, file);
    this.setState({ progress });
  }

  handleError(err, file) {
    this.props.onError && this.props.onError(err, file);
    this.setState({ error: err, progress: null });
  }

  handleFinish(info, file) {
    const uploadedFile = Object.assign(
      {
        file,
        fileUrl: this.fileUrl(this.props.s3Url, file.name),
      },
      info,
    );
    const uploadedFiles = this.state.uploadedFiles;
    uploadedFiles.push(uploadedFile);
    this.setState({ uploadedFiles, error: null, progress: null }, () => {
      this.props.onFinish && this.props.onFinish(uploadedFile);
    });
  }

  handleDrop(files, rejectedFiles) {
    this.setState({ uploadedFiles: [], error: null, progress: null });
    const options = {
      files,
      ...this.state.uploaderOptions,
    };
    new S3Upload(options); // eslint-disable-line
    this.props.onDrop && this.props.onDrop(files, rejectedFiles);
  }

  fileUrl(s3Url, filename) {
    return `${s3Url.endsWith("/") ? s3Url.slice(0, -1) : s3Url}/${filename}`;
  }

  renderImage({ uploadedFile }) {
    return (
      <div className="rdsu-image">
        <img src={uploadedFile.fileUrl} />
      </div>
    );
  }

  renderFile({ uploadedFile }) {
    return (
      <div className="rdsu-file">
        <div className="rdsu-file-icon">
          <span className="fa fa-file-o" style={{ fontSize: "50px" }} />
        </div>
        <div className="rdsu-filename">{uploadedFile.file.name}</div>
      </div>
    );
  }

  renderProgress({ progress }) {
    return progress ? <div className="rdsu-progress">{progress}</div> : null;
  }

  renderError({ error }) {
    return error ? <div className="rdsu-error small">{error}</div> : null;
  }

  render() {
    const { s3Url, passChildrenProps, children } = this.props;

    const ImageComponent = this.renderImage;
    const FileComponent = this.renderFile;
    const ProgressComponent = this.renderProgress;
    const ErrorComponent = this.renderError;

    const { uploadedFiles } = this.state;
    const childProps = { s3Url, ...this.state };

    let content = null;
    if (children) {
      content = passChildrenProps
        ? React.Children.map(children, child => React.cloneElement(child, childProps))
        : this.props.children;
    } else {
      content = (
        <div>
          {uploadedFiles.map(uploadedFile => {
            const new_props = {
              key: uploadedFile.filename,
              uploadedFile: uploadedFile,
              ...childProps,
            };
            return this.props.isImage(uploadedFile.fileUrl) ? (
              <ImageComponent {...new_props} />
            ) : (
              <FileComponent {...new_props} />
            );
          })}
          <ProgressComponent {...childProps} />
          <ErrorComponent {...childProps} />
        </div>
      );
    }

    return (
      <Dropzone {...this.props.customDropzoneProps} onDrop={this.handleDrop} style={{}}>
        {content}
      </Dropzone>
    );
  }
}
