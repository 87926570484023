import React, { Component } from 'react';
import accounting, { formatNumber } from "accounting";
import UnitModel from "../../../models/unitModel";

class Cpm extends Component {
   // TODO: Check permissions and hide cpm if necessary

  prettyLabel(){
    const { unit, campaign } = this.props
    if(!unit.cpm) { return }
    const unitModel = new UnitModel(unit)
    const cpm = unitModel.getMockCpm({ ...campaign, enable_analytics_cpm: false })
    return `CPM ${cpm}`
  }

  render(){
    return (
      <li>{this.prettyLabel()}</li>
    )
  }

}

export default Cpm
