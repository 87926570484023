import { connect } from 'react-redux';
import React from 'react'
import Select from 'react-select';
import _ from 'lodash';
import { fetchDemographics, updateSelectedDemographics } from './actions';
import titleize from '../../utils/titleize';

export const demographic_name = {
  age_sex: 'Age & Gender',
  income: 'Income',
  race: 'Race'
}

class Demographics extends React.Component {
  constructor(props){
    super(props);
    this.state = {
       demo_to_show : "age_sex"
    };
    this.selectDemoSearch = this.selectDemoSearch.bind(this);
    this.removeSelectDemo = this.removeSelectDemo.bind(this);
  }

  componentWillMount() {
    this.props.fetchDemographics();
  }

  selectedDemographicValue(topic) {
    const { selected_demographics } = this.props;
    if (!selected_demographics || !selected_demographics[topic])
      return '';
    return { label: selected_demographics[topic].name, value: selected_demographics[topic].id, lookup_key: selected_demographics[topic].lookup_key };
  }

  selectDemoSearch(e){
    e.preventDefault();
    this.setState({demo_to_show: e.target.dataset.topic});
  }

  removeSelectDemo(e){
    e.preventDefault();
    this.props.updateSelectedDemographics(e.currentTarget.dataset.topic, null);
  }

  renderTopicSelect(topic) {
    const { demographics } = this.props;
    return (
      <div key={topic} className="demo-select-input-container">
        <Select
          className="selectInput"
          placeholder={`Select ${demographic_name[topic] || titleize(topic)} Demograpic`}
          value={this.selectedDemographicValue(topic)}
          options={demographics[topic] && demographics[topic].map(({ id, name, lookup_key }) => ({ value: id, label: name, lookup_key: lookup_key }))}
          onChange={(option) => {
            option = option ? { id: option.value, name: option.label, lookup_key: option.lookup_key } : null;
            this.props.updateSelectedDemographics(topic, option)
          }}
        />
      </div>
    )
  }

  renderDemoSelectBar() {
    const topic_name = [{topic: 'age_sex', name:'Age & Gender'}, {topic: 'income', name:'Income'}, {topic: 'race', name:'Race'}, {topic: 'veterans', name: 'Veterans'}, { topic: 'employment', name: 'Employment'}]
    return (
      <div className="demo-select-bar">
      {topic_name.map((item, key) =>(
        <div className={`demo-select-button ${this.state.demo_to_show === item.topic ? 'active' : ''}`} 
          key={key} data-topic={item.topic} onClick={this.selectDemoSearch}>
          {item.name}
        </div>))}
      </div>);
  }

  renderSelectedDemoGraphics(){
    const { selected_demographics } = this.props;
    return (
       <div className="demo-selected-container">
        {
          Object.entries(selected_demographics).map(([key, value]) => (
            <div key={value.id} className="demo-selected-item"
            >
              <div className="demo-selected-name">{value.name}</div>
              <div className="demo-selected-icon" 
                onClick={this.removeSelectDemo}
                data-topic={key}
               >
                <i className="fa fa-minus" aria-hidden="true"></i>
              </div>
            </div>
          ))
        }  
      </div>
     )
  }

  render() {
    const { demographics, selected_demographics } = this.props;
    const holderForDemoselect = this.renderDemoSelectBar();
    const selectedDemographics = this.renderSelectedDemoGraphics();
    if (!demographics) return <div/>;
    return (
      <div className="planner-panel">
        {holderForDemoselect}
        <div>{this.renderTopicSelect(this.state.demo_to_show)}</div>
        {_.isEmpty(selected_demographics) ? (<div className="demo-selected-placeholder">Your Selected Demographics will appear here</div>) : selectedDemographics}
      </div>
    )
  }
}

export default connect(
  ({ planner }) => ({ ...planner }),
  { fetchDemographics, updateSelectedDemographics }
)(Demographics)
