import * as React from "react";
import GlobalActions from "../../../actions/GlobalActions";

interface Props {
  campaign: any
  sentToInstallers: (campaign_id, unit_ids) => void
  markAsReadyToInstall: (campaign_id, unit_ids) => void
  selectedUnits: any
}

interface State {}

class BulkSentToInstallers extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  async onClick() {
    const { campaign: { token }, selectedUnits } = this.props;
    const campaignUnitTokens = selectedUnits.map(u => u.campaign_unit_token);
    try {
      await this.props.markAsReadyToInstall(token, campaignUnitTokens);
      await this.props.sentToInstallers(token, campaignUnitTokens);
      GlobalActions.showMessage("Units marked as ready to be posted and sent to installers");
    } catch (error) {
      GlobalActions.showError("Something went wrong");
      console.log(error);
    }
  }

  public render() {
    const button_style = {
      color: "#555555",
      width: "100%",
      fontSize: 12,
      marginBottom: "5px"
    };

    return (
      <button
        key="add_posting_note"
        onClick={this.onClick}
        className={"btn btn-default"}
        style={button_style}
      >
        Send to installers
      </button>
    );
  }
}

export default BulkSentToInstallers;
