import * as React from "react";
import { connect } from "react-redux";

import { fetchMissingImageCount } from "./actions";

class UnitsMissingImages extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier);
    }
  }

  fetchData(supplier) {
    const { fetchMissingImageCount } = this.props;
    fetchMissingImageCount(supplier);
  }

  onLinkClick() {
    const { currentUser, selected_supplier } = this.props;
    if (currentUser.is_admin) {
      window.location.href = `/inventory?only_units_without_picture=true&supplier=${selected_supplier}`;
    } else {
      window.location.href = "/inventory?only_units_without_picture=true";
    }
  }

  render() {
    const { missing_image_count, loading_missing_image_count } = this.props;

    if (!!!loading_missing_image_count && missing_image_count > 0) {
      return (
        <div id="missing_images_banner">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <div className="count-area">{missing_image_count} units are missing images.</div>
          <div className="banner-link-area">
            <a className="banner-link" onClick={this.onLinkClick}>
              Add Images
            </a>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: { missing_image_count, loading_missing_image_count, selected_supplier },
    // @ts-ignore
    currentUser,
  }) => ({
    missing_image_count,
    loading_missing_image_count,
    selected_supplier,
    currentUser,
  }),
  { fetchMissingImageCount },
)(UnitsMissingImages);
