import React, { Component } from 'react'

class AddCompanyModalBody extends Component {

  handleSaveBtnClick() {
    const { columns, onSave } = this.props
    const newRow = {}
    columns.forEach((column, i) => {
      newRow[column.field] = this.refs[column.field].value
    }, this)
    onSave(newRow)
  }

  render() {
    const { onModalClose, onSave, columns } = this.props
    return (
      <div style={ { marginTop: '200px', padding: '20px' } } className='modal-content'>
        <h2>Add Company to Group</h2>
        <div className='modal-body'>
          <div>
            {
              columns.map((column, i) => {
                const { field, name } = column

                return (
                  <div className='form-group' key={ field }>
                    <label>{ name }</label>
                    <input ref={ field } type='text' defaultValue={ '' } placeholder={name} className="form-control editor edit-text" />
                  </div>
                )
              })
            }
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <button className='btn btn-danger' onClick={ onModalClose }>Cancel</button>
          <button style={{ marginRight: '10px' }} className='btn btn-success' onClick={ () => this.handleSaveBtnClick(columns, onSave) }>Save</button>
        </div>
      </div>
    )
  }
}

export default AddCompanyModalBody