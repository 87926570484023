const initialState = {
  page: 1,
  per_page: 10,
  query: "",
  advertisers: [],
  filters_changed: false,
  campaigns_page: 1,
  campaigns_per_page: 10,
  campaigns_filters_changed: false,
  campaigns_total_pages: 1,
  campaigns_query: "",
  show_delete_confirmation: false,
  campaign_for_deletion: {},
  campaigns_sort_by: {
    label: 'Sort By Last Updated ↓',
    value: 'updated_at-desc'
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ADVERTISER_SUCCESS": {
      const { advertiser, advertisers, advertiser_id } = action.payload;
      const new_advertisers = advertisers.concat(advertiser)
      return {
        ...state,
        campaigns: [],
        advertiser,
        advertiser_id,
        advertisers: new_advertisers,
        errors: {}
      };
    }
    case "CREATE_ADVERTISER_FAILURE": {
      const { errors } = action.payload;
      return {
        ...state,
        errors,
      };
    }
    case "FETCH_ADVERTISERS_SUCCESS": {
      const { advertisers } = action.payload;
      return {
        ...state,
        advertisers
      };
    }
    case "FETCH_DEFAULT_BACKGROUND_SUCCESS": {
      return {
        ...state,
        ...action.payload,
        filters_changed: false
      };
    }
    case "FETCH_DEFAULT_BACKGROUND": {
      return {
        ...state
      };
    }
    case "RESET_ADVERTISER": {
      return {
        ...state,
        advertiser: {},
        errors: {}
      };
    }
    case "SELECT_ADVERTISER": {
      const { advertiser } = action.payload;
      return {
        ...state,
        advertiser,
        campaigns_page: 1,
        campaigns: null,
        campaign_count: 0,
        campaigns_query: "",
        campaign_filters_changed: true,
      };
    }
    case "FETCH_ADVERTISER_CAMPAIGNS_SUCCESS": {
      const { results, campaigns_total_count, campaigns_per_page, campaigns_page, campaigns_total_pages } = action.payload;
      return {
        ...state,
        campaigns: results,
        loading_campaigns: false,
        campaign_filters_changed: false,
        campaigns_total_pages,
        campaigns_per_page,
        campaigns_page,
        campaigns_total_count
      };
    }
    case "SET_FILTERS": {
      const { per_page, page, query, lead_source, filters_changed } = action.payload;
      return {
        ...state,
        per_page,
        page,
        query,
        lead_source,
        filters_changed
      };
    }
    case "SET_CAMPAIGN_FILTERS": {
      const { campaigns_per_page, campaigns_page, campaigns_query, campaign_filters_changed, campaigns_sort_by } = action.payload;
      return {
        ...state,
        campaigns_per_page,
        campaigns_page,
        campaigns_query,
        campaigns_sort_by,
        campaign_filters_changed,
      };
    }
    case "CONFIRM_DELETE_CAMPAIGN": {
      const { campaign_for_deletion } = action.payload;
      return {
        ...state,
        show_delete_campaign_confirmation: true,
        campaign_for_deletion
      };
    }
    case "CONFIRM_DELETE_ADVERTISER": {
      const { advertiser_for_delete } = action.payload;
      return {
        ...state,
        show_delete_advertiser_confirmation: true,
        advertiser_for_delete
      };
    }
    case "SHOW_ADVERTISER_EDIT_MODAL": {
      return {
        ...state,
        show_edit_advertiser_modal: true
      }
    }
    case "HIDE_ADVERTISER_EDIT_MODAL": {
      return {
        ...state,
        show_edit_advertiser_modal: false
      }
    }
    case "UPDATE_ADVERTISER_BEGIN": {
      return {
        ...state,
        errors: {},
        advertiser_updating: true
      }
    }
    case "UPDATE_ADVERTISER_SUCCESS": {
      const { advertiser } = action.payload;
      const advertisers = state.advertisers.map((a) => {
        if (a.id === advertiser.id) {
          return advertiser;
        } else {
          return a;
        }
      });
      return {
        ...state,
        advertisers,
        advertiser,
        advertiser_updating: false
      }
    }
    case "UPDATE_ADVERTISER_FAILURE": {
      return {
        ...state,
        errors: action.payload.errors,
        advertiser_updating: false
      };
    }
    case "SHOW_ADVERTISER_CREATE_MODAL": {
      return {
        ...state,
        show_create_advertiser_modal: true
      };
    }
    case "HIDE_ADVERTISER_CREATE_MODAL": {
      return {
        ...state,
        show_create_advertiser_modal: false
      };
    }
    case "CANCEL_CONFIRM_DELETE_CAMPAIGN": {
      return {
        ...state,
        show_delete_campaign_confirmation: false,
        campaign_for_deletion: {}
      };
    }
    case "CANCEL_CONFIRM_DELETE_ADVERTISER": {
      return {
        ...state,
        show_delete_advertiser_confirmation: false
      };
    }
    case "DELETE_ADVERTISER_START": {
      return {
        ...state,
        deleting_advertiser: true
      };
    }
    case "DELETE_CAMPAIGN_START": {
      return {
        ...state,
        deleting_campaign: true
      };
    }
    case "DELETE_CAMPAIGN_SUCCESS": {
      const new_campaigns = state.campaigns.filter((campaign) => campaign.id !== action.payload.campaign_for_deletion.id);
      const updated_advertisers = updateAdvertiserCampaignCounts(
        state.advertiser,
        state.advertisers,
        action.payload.campaign_for_deletion.status
      );
      return {
        ...state,
        show_delete_campaign_confirmation: false,
        deleting_campaign: false,
        advertisers: updated_advertisers,
        campaigns: new_campaigns
      };
    }
    case "DELETE_ADVERTISER_SUCCESS": {
      const new_advertisers = state.advertisers.filter((advertiser) => advertiser.id !== action.payload.advertiser.id);
      return {
        ...state,
        show_delete_advertiser_confirmation: false,
        deleting_advertiser: false,
        advertisers: new_advertisers,
        advertiser: null,
        campaigns: []
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

function updateAdvertiserCampaignCounts(selected_advertiser, advertisers, status) {
  const new_advertisers = advertisers.map((advertiser) => {
    if (advertiser.id === selected_advertiser.id) {
      if (status === 'contracted') {
        return { ...advertiser, contracted_campaigns: (advertiser.contracted_campaigns - 1), campaigns_count: (advertiser.campaigns_count - 1) };
      } else if (status === 'proposal') {
        return { ...advertiser, proposed_campaigns: (advertiser.proposed_campaigns - 1), campaigns_count: (advertiser.campaigns_count - 1) };
      } else {
        return advertiser;
      }
    } else {
      return advertiser;
    }
  });
  return new_advertisers;
}
