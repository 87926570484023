import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

import accounting from 'accounting';

const Supplier = createClass({

  displayName: 'Supplier',

  propTypes: {
    supplier: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
  },

  getInitialState() {
    return {};
  },

  renderUnits() {
    return this.props.units.map(unit => <div className="unit" key={unit.id}>
      <div className="name">
        <a href={`/units/${unit.id}?skip_splash=1`}>{unit.name}</a>
      </div>
      <img src={unit.image_file_link} />
      <div className="details">
        <div>{accounting.formatMoney(unit.price)} per 4 weeks</div>
        <div>{accounting.formatMoney(unit.cpm)} CPM</div>
        <div>{accounting.formatNumber(unit.impressions)} impressions</div>
      </div>
    </div>);
  },

  render() {
    return (<div className="page page-supplier">
      <h1>{this.props.supplier.name}</h1>
      {this.renderUnits()}
    </div>);
  },
});

export default Supplier;
