import React from 'react';
import { connect } from 'react-redux';
import createClass from 'create-react-class';
import { setAdvertiser } from '../../actions/greylabel'

class GreylabelIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { greylabel } = this.props
    let advertiser = (greylabel || {}).advertiser || {}

    return (
      <div>
        {React.cloneElement(this.props.children, { advertiser })}
      </div>
    )
  }
}

export default connect(
  ({ greylabel }) => ({ greylabel }),
  { setAdvertiser }
)(GreylabelIndex)
