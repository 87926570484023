import React from 'react';
import ReactDOMServer from 'react-dom/server';

class HighlightedRegionDataLayer {

  buildDataLayerLegend(color) {
    const no_data = {
      text: 'Highlight',
      color: color || '#bd0026'
    };

    return [no_data];
  }

  dataLayer(data, shading_variable, legend, color) {
    return { areas: this.featureCollection(data, shading_variable, legend, color) };
  }

  featureCollection(data, shading_variable, legend, color) {
    const features = data.map(datum => {
      const newColor = color || this.calculateColor(datum, shading_variable, legend);
      return {
        type: 'Feature',
        properties: {
          ...datum,
          opacity: 0.4,
          fillColor: newColor,
          color: newColor,
          popup_content: this.renderPopup(datum, shading_variable)
        },
        geometry: {
          coordinates: datum.coordinates,
          type: 'MultiPolygon',
        }
      }
    });

    return {
      type: 'FeatureCollection',
      features: features
    };
  }

  calculateColor(datum, shading_variable, legend) {
    return '#bd0026';
  }

  renderPopup(datum, shading_variable) {
    return ReactDOMServer.renderToString(
      <div className="text-left" key={`popup-${datum.label}`}>
        <div><b>{datum.title}</b></div>
      </div>
    )
  }

}

export default HighlightedRegionDataLayer;
