import { PureComponent } from "react";
import React from "react";
import { getVendorLink } from "../actions"
import GlobalActions from "../../../actions/GlobalActions";
import PropTypes from 'prop-types';

const propTypes = {
  copy_payload: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  copy_type: PropTypes.string,
};

export default class CopyToClipboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { 
      copy_message: '',
      copy_content: ''
    };
    this.fallbackCopyContentToClipboard = this.fallbackCopyContentToClipboard.bind(this);
    this.copyContentToClipboard = this.copyContentToClipboard.bind(this);
    this.callActionsGetVendorLink = this.callActionsGetVendorLink.bind(this);
  }

  componentDidMount(){
    //the inputs are type and payload
    switch(this.props.copy_type){
      // if the payload is just simple text (example a campaign ID) then just assign it to copyContent
      // then assign the text you want to show on the copy message 
      case 'campaignID':
        this.setState({ copy_message: 'Campaign ID', copy_content: this.props.copy_payload});
        break;
      case 'look_alikes':
        this.setState({copy_message: '', copy_content: this.props.copy_payload})
        break;
      // if there is a call you need to make to pull the info (example a link)
      // write your async function at the bottom and make the call with the given payload
      case 'vendorLink':
        this.callActionsGetVendorLink().then((resp) => this.setState({ copy_message: 'Vendor Link', copy_content: resp }));
        break;
    }
  }
  
  fallbackCopyContentToClipboard(content, message) {
    const text_area = document.createElement("textarea");
    text_area.value = content;
    document.body.appendChild(text_area);
    text_area.focus();
    text_area.select();

    try {
      const successful = document.execCommand('copy');
      const exec_command_msg = successful ? 'successful' : 'unsuccessful';
      DEBUG && console.log('Fallback: Copying text command was ' + exec_command_msg);
      GlobalActions.showMessage(`${message} copied to clipboard.`);
    } catch (err) {
      DEBUG && console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(text_area);
  }

  copyContentToClipboard() {
    const content = this.state.copy_content;
    const message = this.state.copy_message;

    if (navigator.clipboard) {
      this.fallbackCopyContentToClipboard(content, message);
      return;
    }
    navigator.clipboard.writeText(content).then(function() {
      DEBUG && console.log('Async: Copying to clipboard was successful!');
      GlobalActions.showMessage(`${message} copied to clipboard.`);
    }, (err) => {
      DEBUG && console.error('Async: Could not copy text: ', err);
    });
  }

  async callActionsGetVendorLink(){
    const vendor_link = await getVendorLink(this.props.copy_payload);
    return vendor_link;
  }

  render() {
    return(
      <a onClick={() => this.copyContentToClipboard()}>
        <i className="fa fa-clipboard margin-right" aria-hidden="true"></i>{`Copy ${this.state.copy_message}`}
      </a>
    );
  }
}

CopyToClipboard.propTypes = propTypes;
