import accounting from "accounting";
import CSS from "csstype";
import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";
import Datetime from "react-datetime";
import { connect } from "react-redux";

import GlobalActions from "../../../actions/GlobalActions";
import AuthStore from "../../../stores/AuthStore";
import JobProgress from "../../../utils/JobProgress";
import WaitTyper from "../../../utils/WaitTyper";
import SupplierName from "../../SupplierName";
import {
  setSupplierContact,
  updateUnit
} from "../actions";

class CampaignUnitListItem extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      errors: "",
      start_date: "",
      end_date: "",
      price: "",
      quantity: "",
      isSaving: false,
      recentlySaved: false,
      job_pooling: new JobProgress(),
      wait_price_typer: new WaitTyper(),
      wait_quantity_typer: new WaitTyper(),
    };
    this.onPriceChange = this.onPriceChange.bind(this);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.setStateFromProps = this.setStateFromProps.bind(this);
    this.save = this.save.bind(this);
    this.onSupplierUserChange = this.onSupplierUserChange.bind(this);
  }

  onPriceChange(e) {
    const { wait_price_typer } = this.state;
    this.setState({ price: e.target.value }, () => {
      wait_price_typer.onStopTyping(this.save);
    });
  }

  onQuantityChange(e) {
    const { wait_quantity_typer } = this.state;
    this.setState({ quantity: e.target.value }, () => {
      wait_quantity_typer.onStopTyping(this.save);
    });
  }

  onStartDateChange(date) {
    this.setState({ start_date: date }, this.save);
  }

  onEndDateChange(date) {
    this.setState({ end_date: date }, this.save);
  }

  componentDidMount() {
    this.setStateFromProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setStateFromProps(nextProps);
  }

  componentDidUpdate(prevProps) {
    const { supplier_contacts } = this.props;
    const { selectedContact } = this.state;
    if (selectedContact && supplier_contacts && (supplier_contacts[1] === selectedContact)) {
      this.setState({ selectedContact: null });
    }
  }

  setStateFromProps(props) {
    const { unit } = props;
    const start_date = unit.start_date ? moment.utc(unit.start_date) : "";
    const end_date = unit.end_date ? moment.utc(unit.end_date) : "";

    this.setState({
      start_date: start_date,
      end_date: end_date,
      price: unit.price,
      quantity: unit.quantity,
      workflow_state: unit.workflow_state,
    });
  }

  async updateUnit() {
    const { start_date, end_date, price, quantity } = this.state;
    const { unit, updateUnit, campaign_token } = this.props;

    const params = {
      price,
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      quantity: (unit.has_quantity || unit.is_digital) ? quantity : null
    };

    await updateUnit(campaign_token, unit, params);
    this.setState({ isSaving: false, errors: "", recentlySaved: true }, () => {
      setTimeout(() => this.setState({ recentlySaved: false }), 1000);
    });
  }

  save() {
    const { price, quantity, start_date, end_date } = this.state;
    const { unit } = this.props;

    if (
      parseFloat(price) == parseFloat(unit.price) &&
      moment(end_date).isSame(unit.end_date) &&
      moment(start_date).isSame(unit.start_date) &&
      quantity === unit.quantity
    ) {
      if (DEBUG) console.log("nothing changed");
      return;
    }
    if ([start_date, end_date, price, quantity].includes("")) {
      this.setState({ errors: "Please specify price, start and end dates" });
      return;
    }
    this.setState({ isSaving: true }, this.updateUnit);
  }

  async onRemoveCampaignUnits(unit_ids) {
    const { removeCampaignUnits } = this.props
    removeCampaignUnits(unit_ids)
  }

  async saveSupplierContact(contact_id) {
    const { unit, setSupplierContact, campaign_token, campaign_unit_id } = this.props;
    try {
      await setSupplierContact(campaign_token, unit, contact_id, campaign_unit_id);
      this.setState({ isSaving: false, errors: "", recentlySaved: true, selectedContact: contact_id }, () => {
        setTimeout(() => this.setState({ recentlySaved: false }), 1000);
      });
    } catch (error) {
      this.setState({ isSaving: false });
      GlobalActions.showError(error);
    }
  }

  onSupplierUserChange(contact_id) {
    this.setState({ isSaving: true }, () => {
      this.saveSupplierContact(contact_id);
    });
  }

  render() {
    const {
      errors,
      isSaving,
      recentlySaved,
      price,
      quantity,
      start_date,
      end_date,
      selectedContact
    } = this.state;
    const { unit, campaign_permissions, checkedStatus, supplier_contacts } = this.props;
    const { user } = AuthStore.getState();
    const errorStyle: CSS.Properties = {
      marginLeft: "10px",
      color: "red",
      fontWeight: 600,
      padding: "5px",
    };
    const style: CSS.Properties = {
      padding: "10px",
      borderTop: "1px solid #eee",
      backgroundColor: errors.length > 0 ? "#feeef1" : "",
    };

    const supplier_contact_options = supplier_contacts ? supplier_contacts[0] : {};
    const current_supplier_contact = supplier_contacts ? supplier_contacts[1] : undefined;

    return (
      <div id={unit.id} className="row" style={style}>
        {errors.length > 0 && (
          <div className="col-sm-12">
            <div style={errorStyle}>{errors}</div>
          </div>
        )}
        <div className="col-sm-12">
          <div className="row">
            <div
              className="col-sm-1"
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => {
                this.props.handleCheckBoxClick(unit.campaign_unit_token, checkedStatus);
              }}
            >
              {campaign_permissions.can_perform_edit_units_actions && (
                <input
                  type="checkbox"
                  checked={checkedStatus}
                  onClick={() => {
                    this.props.handleCheckBoxClick(unit.campaign_unit_token, checkedStatus);
                  }}
                  name={unit.id}
                  style={{ display: "inline-block", marginRight: 10 }}
                />
              )}
              {unit.thumbnail_url ? (
                <img src={unit.thumbnail_url} style={{ display: "inline-block", width: 50, height: 50 }} />
              ) : (
                <div
                  style={{
                    width: 50,
                    height: 50,
                    border: "1px solid #ccc",
                    textAlign: "center",
                    paddingTop: 15,
                    color: "#ccc",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-image" style={{ margin: 0 }} />
                </div>
              )}
            </div>
            <div
              className="col-sm-2"
              onClick={() => {
                this.props.onUnitListingClick(unit.id);
              }}
            >
              <SupplierName
                className="label label-default"
                user={user}
                supplier={{
                  name: unit.supplier_name,
                  is_managed: unit.is_managed,
                  is_fully_submitted: unit.is_fully_submitted,
                  is_broker: unit.is_broker
                }}
              />
              <br />
              <a style={{ fontWeight: 600 }} target="_blank">
                {unit.supplier_face_id} – {unit.name}
              </a>
            </div>
            <div className="custom-medium-column">
              <span>4 Week Price</span>
              {!!!user.is_guest ? (
                <input className="form-control" value={price || ""} onChange={this.onPriceChange} />
              ) : (
                <div>{accounting.formatMoney(price)}</div>
              )}
            </div>
            {!!!user.is_guest ? (
              <div>
                <div className="custom-medium-column">
                  <span>Start Date</span>
                  <Datetime
                    dateFormat="LL"
                    timeFormat={false}
                    closeOnSelect
                    value={start_date}
                    inputProps={{ placeholder: "Select Start Date" }}
                    onChange={this.onStartDateChange}
                  />
                </div>
                <div className="custom-medium-column">
                  <span>End Date</span>
                  <Datetime
                    dateFormat="LL"
                    timeFormat={false}
                    closeOnSelect
                    value={end_date}
                    inputProps={{ placeholder: "Select End Date" }}
                    onChange={this.onEndDateChange}
                  />
                </div>
              </div>
            ) : (
              <div className="custom-medium-column">
                <div>Dates</div>
                <div>
                  {moment(start_date).format("M/D/YYYY")}
                  &nbsp;&nbsp;&rarr;&nbsp;&nbsp;
                  {moment(end_date).format("M/D/YYYY")}
                </div>
              </div>
            )}
            {(unit.has_quantity || unit.is_digital) && (
              <div className="quantity-column">
                <span>Quantity</span>
                <input className="form-control" value={quantity} onChange={this.onQuantityChange} />
              </div>
            )}
            <div className="custom-medium-column">
              <span>Vendor Contact</span>
              <select
                className="form-control"
                onChange={e => this.onSupplierUserChange(e.target.value)}
                value={selectedContact || current_supplier_contact}
              >
                {Object.keys(supplier_contact_options).map((name) =>
                  <option
                    key={supplier_contact_options[name]}
                    value={supplier_contact_options[name]}
                  >
                    {name}
                  </option>
                )}
              </select>
            </div>
            {!!!user.is_guest && (
              <div className="buttons-column">
                <Button
                  bsStyle="default"
                  onClick={() => this.onRemoveCampaignUnits([unit.id])}
                >
                  Remove
                </Button>
                {isSaving && (
                  <span className="text-green" style={{ marginLeft: 10 }}>
                    Saving...
                  </span>
                )}
                {!!!isSaving && recentlySaved && (
                  <span className="text-green" style={{ marginLeft: 10 }}>
                    Saved
                  </span>
                )}
              </div>
            )}
            {(user.is_admin || user.is_agency) &&
              <div className="custom-medium-column status-column">
                {unit.supplier_status}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(() => ({}), {
  setSupplierContact,
  updateUnit
})(CampaignUnitListItem);
