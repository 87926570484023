// LookAlikesTool.tsx   
import * as React from 'react';
import Select from 'react-select';
import _ from "lodash";
import JobProgress from '../utils/JobProgress';
import CopyToClipboard from './Campaign/Header/CopyToClipboard';
import AuthStore from '../stores/AuthStore';
import { get, post } from '../utils/api';
import { Helmet } from 'react-helmet';

class LookAlikesTool extends React.Component<any, any> {

  baseState = {
    current_state: "initial", // "initial", "market_data", "look_alikes_counts", "raw_look_alikes", "error"
    contains_zipcodes: "",
    error_message: "error!",
    is_hovered: false,
    is_loading: false,
    job_pooling: new JobProgress(),
    look_alikes: {},
    markets: [],
    market_mapping: [],
    selected_markets: [],
    zip_codes: "",
    zip_counts: [],
    zip_info: []
  }

  constructor(props) {
    super(props);
    this.state = this.baseState;
    this.handleClick = this.handleClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onJobComplete = this.onJobComplete.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.generateGoogleMapsLinks =this.generateGoogleMapsLinks.bind(this);
    this.generateHeading = this.generateHeading.bind(this);
    this.generateLookAlikeForm = this.generateLookAlikeForm.bind(this);
    this.generateLoadingMessage = this.generateLoadingMessage.bind(this);
    this.generateMarketMapping = this.generateMarketMapping.bind(this);
    this.generateSelectMarket =this.generateSelectMarket.bind(this);
    this.generateTab = this.generateTab.bind(this);
    this.generateTabLinks = this.generateTabLinks.bind(this);
    this.generateTextAreas = this.generateTextAreas.bind(this);
    this.generateZipCounts = this.generateZipCounts.bind(this);
  }

  async componentDidMount() {
    const { markets } = await get('/api/v1/look_alikes/');
    this.setState({ markets });
  }

  onJobComplete(response) {
    const { data } = response;
    const { error } = data;

    if (error) {
      this.setState({ current_state: 'error', error_message: error, is_loading: false});
      return;
    }

    const zip_info = data.results.zip_info;
    const market_info = data.results.markets_info;
    
    const market_mapping = 
    _.toPairs(_.groupBy(data.results.zip_info, 'market_id'))
    .sort((a, b) => b[1].length - a[1].length)
    .map((item, index) => {
      let temp_item = _.find(market_info, {id: parseInt(item[0])});
      // @ts-ignore
      item[2] = temp_item;
      item = item;
      return item;
    });
    
    const zip_counts = 
    _.toPairs(data.results.zip_counts)
    // @ts-ignore
    .sort((a, b) => b[1] - a[1])
    .map((item, index) => {
      let temp_item = _.find(zip_info, {zip_code: item[0]})
      // @ts-ignore
      item[2] = temp_item;
      item = item;
      return item;
    });

    this.setState({ 
      current_state: 'market_data',
      is_loading: false,
      look_alikes: data.results.look_alikes,
      market_mapping: market_mapping,
      zip_counts: zip_counts, 
      zip_info: zip_info
    });
  }

  handleClick(e){
    e.preventDefault();
    let click_type = e.target.dataset.clickType;
    this.setState({ current_state: click_type});
  }

  handleOnChange(e){
    let change_type = e.target.dataset.changeType;
    this.setState({ [change_type]: e.target.value });
  }

  handleSelectChange(e){
    this.setState({ selected_markets: e });
  }

  async handleSubmit(e){
    e.preventDefault();
    const submit_type = this.state.selected_markets.length > 0 ? 'similarly_selected' : 'top_10';
    const selected_markets = this.state.selected_markets.map(a => a.value);
    const options = submit_type === 'top_10' ? {zip_codes: this.state.zip_codes} : {zip_codes: this.state.zip_codes, contains_zip_codes: this.state.contains_zipcodes, zip_markets: selected_markets};
    this.setState({ is_loading: true });
    try {
      const { data } = await post(`/api/v1/look_alikes/${submit_type}`, options);
      this.state.job_pooling.startPolling(data.job_id, this.onJobComplete);
    } catch(err) {
      this.setState({ current_state: 'error', error_message: err, is_loading: false});
    }
  }

  generateErrorMessage(){
    return (
      <div className="card text-center">
        <h5 className="card-title">{this.state.error_message + "  "}</h5>
        <a href="/look_alikes" className="alert-link"> 
          <i className="fa fa-frown-o" aria-hidden="true"></i>
          Looks like there was an issue, Click Here to refresh
        </a>
      </div>
    );
  }

  generateHeading(header_object){
    return (
      <div className="text-center" style={{ marginTop: '30px', marginBottom: '10px', paddingBottom: '15px'}} >
        <h1 style={{ fontSize: '30px'}}>{header_object.title}</h1>
        <p style={{ fontSize: '18px', marginTop: '20px'}}>{header_object.caption}</p>
      </div>
     );
  }

  generateGoogleMapsLinks(zip_code, is_list){
    return (
      <a rel="noopener noreferrer" target="_blank" href={`http://maps.google.com/maps?q=postal_code=${zip_code.zip_code}`}>
        {
          is_list ? (
            <span>
              Google
              <i style={{ marginLeft: '6px', fontSize: '14px'}} 
              className="fa fa-external-link" aria-hidden="true">
              </i>
            </span> 
          ) : ( zip_code.zip_code )
        }
      </a>
    );
  }

  generateLoadingMessage(){
    return (
      <div style={{marginTop: '20px'}}>
        <p style={{fontStyle: 'italic',fontSize: '14px', color: '#ACACAC' }}>
          Hold tight, this can take a minute...         
        </p>
      </div>
    );
  }

  generateLookAlikes(){
    return(
      <table className="table table-borderless table-hover text-left" style={{width: '100%',margin: '0 auto'}}>
        <thead style={{ fontSize: '18px' }}>
          <tr>{['YOUR ZIP', 'LOOK ALIKES', ''].map(item => <th key={item}>{ item }</th>)}</tr>
        </thead>
        <tbody>
          {Object.keys(this.state.look_alikes).map((x,y) =>
              <tr key={y} style={{fontSize: '16px'}}>
                <td>{x}</td>
                <td>
                  {this.state.look_alikes[x].map((zip_code, index, arr) => {
                    let temp_item = _.find(this.state.zip_info, { zip_code: zip_code })
                      return (
                        <span key={zip_code}>
                          { this.generateGoogleMapsLinks(temp_item, false) }
                          { `${arr.length - 1 !== index ? ', ' : ' '}` }
                        </span>
                      );
                  })}
                </td>
                <td style={{fontSize: '14px'}}>
                  <CopyToClipboard 
                    copy_type="look_alikes" 
                    copy_payload={`${this.state.look_alikes[x].join(", ")}`}
                  />
                </td>
              </tr>
          )}
        </tbody>
      </table>
    );
  }

  generateLookAlikeForm(){
    return (
      <div className="container" style={{height: '50%', marginTop: '20px'}}>
        {this.generateHeading({title: 'Look-alike Lookup', caption: "Enter your best performing zip codes to get look-alike zips"})}
        {this.generateTextAreas([{"label": '', "data_change_type": 'zip_codes'}])}
        <div style={{width: '700px', margin: '0 auto'}}><label>(Optional) Search within specific markets</label></div>
        <div style={{width: '700px', margin: 'auto'}}>{this.generateSelectMarket()}</div>
        <hr style={{width: '714px'}}/>
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
          <button 
            className="col-lg-4 btn btn-primary btn-lg" 
            data-submit-type="top_10" 
            onClick={ this.handleSubmit } 
            style={{borderRadius: '500px', background: '#4a90e2', border: '1px solid #333333'}}
            disabled={this.state.is_loading}
            >
            {this.state.is_loading && <i style={{fontSize: '15px', marginRight: '5px'}} className="fa fa-cog fa-spin" aria-hidden="true"></i>}
            Get Look-alikes
          </button>
          {this.state.is_loading && this.generateLoadingMessage()}
        </div>
      </div>
    );
  }

  generateMarketMapping(){
    let market_mapping = this.state.market_mapping;
    return (
      <table className="table table-borderless table-hover text-left" style={{width: '100%',margin: '0 auto'}}>
        <thead style={{ fontSize: '18px' }}>
          <tr>
          {
            ['Geography', 'Match Count', 'Median CPM', 'Median Price', 'Look-alikes','']
            .map((header, index) => <th key={header}>{header}</th>)
          }
          </tr>
        </thead>
        <tbody>
          {market_mapping.map((item) => 
            <tr style={{fontSize: '16px'}} key={item[0]}>
              <td>{`${item[2].name}`}</td>
              <td>{`${item[1].length}`}</td>
              <td>{`$${_.round(item[2].median_cpm, 2)}`}</td>
              <td>{`$${_.round(item[2].median_price, 2)}`}</td>
              <td>
                {item[1].map((sub_item, sub_index, arr ) => 
                    <span key={sub_item.zip_code}>
                      { this.generateGoogleMapsLinks(sub_item, false) }
                      { arr.length - 1 !== sub_index ? ', ' : ' ' }
                    </span>
                )}
              </td>
              <td style={{fontSize: '14px'}}>
                <CopyToClipboard 
                  copy_type="look_alikes" 
                  copy_payload={`${item[1].map(sub_item => sub_item.zip_code).join(", ")}`}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  generateSelectMarket(){
    const options = this.state.markets ? this.state.markets.map((m) => ({ value: m.id, label: m.name })) : [];
    return (
      <Select 
        name="markets" 
        options={options} 
        onChange={this.handleSelectChange} 
        value={this.state.selected_markets}
        isMulti={true} 
        placeholder="Start typing to search..."
      />
    );
  }

  generateTabLinks(){
    let link_items = [
      {tag: 'market_data', name: 'Look-alike Markets', icon: 'fa fa-map-o'},
      {tag: 'raw_look_alikes', name: 'Raw Look-alikes', icon:'fa fa-th-list'},
      {tag: 'look_alikes_counts', name: 'Top Look-alike Zips', icon: 'fa fa-area-chart'},
    ];
    return (
      <div className="card-header" style={{fontSize: '18px'}}>
        <ul className="nav nav-tabs card-header-tabs nav-justified">
          {link_items.map(item => 
            <li key={item.tag} className={`nav-item ${this.state.current_state === item.tag ? "active" : "" }`}>
              <a data-click-type={item.tag} onClick={this.handleClick}>
              <i style={{pointerEvents: 'none', marginRight: '6px'}} className={item.icon}></i>
              {item.name}
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }

  toggleHover(){
    this.setState({is_hovered: !this.state.is_hovered});
  }

  generateTab(heading, state_check, generate_function){
    const hoverBackgroundColor = this.state.is_hovered ? 'white' : '#4a90e2';
    const hoverBorderColor = this.state.is_hovered ? '#4a90e2' : 'white';
    return (
      <div>
        {this.generateHeading({title: 'Look-alike Results', caption: ''})}
        <div className="card text-center" 
          style={{ marginBottom: '10px',  borderRadius: '4px', padding: '12px', fontSize: '14px'}}
        >
        { this.generateTabLinks() }
        <div className="card-body" >
          { this.generateHeading(heading) }
          { _.isEmpty(this.state[state_check]) ? " " : generate_function }
        </div>
        </div>
          <hr style={{width: '100%', paddingBottom: '40px'}}/>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button type="button" className="col-lg-4 btn btn-outline-primary btn-lg"
          data-click-type="initial"
          onClick={ this.handleClick }
          onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} 
          style=
            {{
              borderRadius: '500px', 
              color: hoverBackgroundColor,
              background: hoverBorderColor,
              borderColor: hoverBackgroundColor, 
              transition: '0.1s background linear',
            }}
          > 
            <span 
              style={{pointerEvents: 'none', fontSize: '15px', marginRight: '5px'}}
              className="fa fa-chevron-left"
            ></span> Go Back To Search
          </button>
        </div>
      </div>
    );
  }

  generateTextAreas(array){
    return ( 
      <div className="form-group" style={{ display: 'flex', justifyContent: 'center' }}>
        {array.map((item, index) =>
          <div key={index} >
            <label>{item.label}</label>
            <textarea style={{width: '700px', height: '152px', fontSize: '15px'}} 
              className="form-control" data-change-type={item.data_change_type} rows={10} 
              value={this.state[item.data_change_type]} 
              onChange={this.handleOnChange} 
              required 
              placeholder="Enter zips seperated by commas, spaces, or new lines">
            </textarea>
          </div>
        )}
      </div>
    );
  }

  generateZipCounts(){
    let counts = this.state.zip_counts;
    return (
      <table className="table table-borderless table-hover text-left" style={{width: '100%',margin: '0 auto'}}>
        <thead style={{ fontSize: '18px' }}>
          <tr>
            {
              ["Zip", "Name", "Market", "Count", "Population", "Per Capita Income", "Census", "Google"]
              .map((header, index) => <th scope="col" key={header} >{header}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {counts.map((item, index) => {
            let tempItem = _.find(this.state.markets, {id: parseInt(item[2].market_id)});
            return (
              <tr style={{ fontSize: '16px' }} key={item[0]}>
                <td>{item[0]}</td>
                <td>{`${item[2].place_name}, ${item[2].state_abbreviation}`}</td>
                <td>{tempItem.name}</td>
                <td>{item[1]}</td>
                <td>{!!item[2].demographics_data ? item[2].demographics_data.Population[0] : "N/A"}</td>
                <td>{!!item[2].demographics_data ? item[2].demographics_data['Per capita income'][0] : "N/A"}</td>
                <td>
                  <a rel="noopener noreferrer" target="_blank" href={`https://censusreporter.org/profiles/86000US${item[0]}-${item[0]}/`}>
                    Census
                    <i style={{ marginLeft: '6px', fontSize: '14px'}} className="fa fa-external-link" aria-hidden="true"></i>
                  </a>
                </td>
                <td>{this.generateGoogleMapsLinks(item[2], true)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  }

  render() {
    let components_to_show = (<div></div>);
    switch (this.state.current_state) {
      case "initial":
        components_to_show = this.generateLookAlikeForm();
        break;
      case "market_data":
        components_to_show = this.generateTab({title: '' , caption: 'These are the markets with the most look-alike zips:' }, 'market_mapping', this.generateMarketMapping());
        break;
      case "look_alikes_counts":
        components_to_show = this.generateTab({ title: '', caption: `${ _.isEmpty(this.state.zip_counts) ? 'No results found. Please search 2 or more zips to find top look-alikes.' : 'From the zip codes you submitted, here are look-alikes that appeared more than once:' }` }, 'zip_counts', this.generateZipCounts());
        break;
      case "raw_look_alikes":
        components_to_show = this.generateTab({ title: '', caption: 'For each zip code you submitted, these are the direct look-alikes:' }, 'look_alikes',  this.generateLookAlikes());
        break;
      case "error":
        components_to_show = this.generateErrorMessage();
        break;
    }
    return (
      <div className="container align-items-center h-100" 
        style={{width: '90%', maxWidth: '1400px', padding: '20px 0px 80px 0px'}}
      >
        <Helmet>
          <title>Lookalike Audience Builder | AdQuick</title>
        </Helmet>
        {AuthStore.isLoggedIn() && components_to_show}
      </div>
    );
  }
}

export default LookAlikesTool;
