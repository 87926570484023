import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import GlobalActions from "../../../actions/GlobalActions";
import { get, post } from "../../../utils/api";
import DatePicker from "../../DatePicker/Single";
import { setMaintenanceDetails } from "./actions";
import MaintenanceLogs from "./MaintenanceLogs";

class MaintenanceTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { unit } = this.props;

    this.state = {
      unit: unit,
      status: unit.status || "active",
      fault_reason: unit.fault_reason
        ? {
            value: unit.fault_reason,
            label: unit.fault_reason,
          }
        : null,
      fault_reason_other: null,
      retired_at: unit.retired_at ? moment(unit.retired_at) : null,
      maintenance_notes: unit.maintenance_notes || "",
      saving: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit() {
    const { setMaintenanceDetails } = this.props;
    const { unit } = this.state;
    const new_unit = await setMaintenanceDetails({
      unit_id: unit.id,
      ...this.state,
    });
    this.setState({ unit: new_unit, saving: false }, () => {
      GlobalActions.showMessage("Maintenance details saved");
    });
  }

  renderRetiredDate() {
    const { retired_at } = this.state;
    return (
      <DatePicker
        date={retired_at}
        onDateChange={({ date }) => this.setState({ retired_at: date })}
        showClearDate={true}
        placeholder={"Select date"}
        showDefaultInputIcon={true}
        small={true}
        isOutsideRange={() => false}
      />
    );
  }

  renderUnitStatus() {
    const { status, retired_at } = this.state;

    return (
      <div className="unit_status" style={{ margin: 20 }}>
        <label>Unit Status</label>
        {["active", "inactive", "retired"].map(status_name => {
          return (
            <div className="status_radio">
              <label
                style={{
                  textTransform: "capitalize",
                  fontWeight: "normal",
                  fontSize: 14,
                  color: "#333333",
                }}
              >
                <input
                  style={{ marginRight: 8 }}
                  type="radio"
                  value={status_name}
                  checked={status === status_name}
                  onChange={e => this.setState({ status: e.target.value })}
                />
                {status_name}
              </label>
            </div>
          );
        })}
        {status === "retired" && this.renderRetiredDate()}
      </div>
    );
  }

  renderNotes() {
    const { maintenance_notes } = this.state;

    return (
      <textarea
        className="form-control"
        style={{
          height: 120,
          margin: 20,
          width: "-webkit-fill-available",
          border: "1px solid #CCCCCC",
          borderRadius: 4,
          resize: "vertical",
          maxHeight: 400,
        }}
        value={maintenance_notes}
        onChange={e => this.setState({ maintenance_notes: e.target.value })}
      />
    );
  }

  saveDisabled() {
    const { status, fault_reason, retired_at, maintenance_notes } = this.state;
    const { unit } = this.state;
    const unit_notes = unit.maintenance_notes || "";
    const set_fault_reason = fault_reason ? fault_reason.value : null;
    if (
      status !== unit.status ||
      set_fault_reason !== unit.fault_reason ||
      retired_at !== unit.retired_at ||
      maintenance_notes !== unit_notes
    ) {
      return false;
    }
    return true;
  }

  renderFaultReason() {
    const { fault_reason, fault_reason_other } = this.state;
    const fault_reason_options = [
      { value: "Frame missing", label: "Frame missing" },
      { value: "Frame broken", label: "Frame broken" },
      { value: "Plexi glass missing", label: "Plexi glass missing" },
      { value: "Ad box missing", label: "Ad box missing" },
      { value: "Corral missing", label: "Corral missing" },
      { value: "Graffiti issue", label: "Graffiti issue" },
      { value: "Landlord remodel", label: "Landlord remodel" },
      { value: "Zoning", label: "Zoning" },
      { value: "Access blocked", label: "Access blocked" },
      { value: "Digital issue", label: "Digital issue" },
      { value: "Power outage", label: "Power outage" },
      { value: "Other", label: "Other" },
    ];

    return (
      <div className="fault_reason" style={{ margin: 20 }}>
        <label>Fault Reason</label>
        <Select
          value={fault_reason}
          onChange={option => this.setState({ fault_reason: option })}
          options={fault_reason_options}
          isClearable
          placeholder="Select a reason"
        />
        {fault_reason && fault_reason.value === "Other" && (
          <input
            style={{ marginTop: 10 }}
            className="form-control"
            type="text"
            name={fault_reason_other}
            placeholder={"Other fault reason"}
            defaultValue={""}
            onChange={e => this.setState({ fault_reason_other: e.target.value })}
          />
        )}
      </div>
    );
  }

  render() {
    const { onHide } = this.props;
    const { saving, unit } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-6">{this.renderUnitStatus()}</div>
          <div className="col-md-4">{this.renderFaultReason()}</div>
        </div>
        <div className="row">
          <div className="col-md-12">{this.renderNotes()}</div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-success"
              style={{ float: "right", margin: "0px 20px 0px 18px", width: 88, backgroundColor: "#44AC6B" }}
              onClick={() => this.setState({ saving: true }, this.onSubmit)}
              disabled={saving || this.saveDisabled()}
            >
              {saving && <i className="fa fa-cog fa-spin" aria-hidden="true"></i>} Save
            </button>
            <button className="btn btn-default" style={{ float: "right", width: 88 }} onClick={onHide}>
              Close
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaintenanceLogs
              unit={unit}
              style={{
                margin: 20,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({}) => ({}), { setMaintenanceDetails })(MaintenanceTab);
