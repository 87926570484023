import * as React from "react";
import styled from "styled-components";
import WhiteBox from "../WhiteBox";

export interface IRect {
  left: number;
  top: number;
  width: number;
}
export interface IPopoverProps {
  children: React.ReactNode | string;
  open?: boolean;
  padding?: string;
}

export const Popover = ({ children, open, padding }: IPopoverProps) => {
  return (
    <PopoverWrapper className="class-to-find-the-popover" open={open}>
      <LeftArrow />
      <WhiteBox padding={padding}>{children}</WhiteBox>
    </PopoverWrapper>
  );
};

export default Popover;

// TODO: this popover just appear on the right, needs to be improved;
export const PopoverWrapper = styled.div<IPopoverProps>`
  position: absolute;
  transition: 0.3s ease;
  z-index: 100;
  right: -20px;
  opacity: ${props => (props.open ? 1 : 0)};
  transform: ${props => (props.open ? "scale(1)" : "scale(0)")}
    translate(100%, -25%);
  transition-property: opacity, transform;
`;

export const LeftArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  z-index: 100;
  transform: translate(-90%, -50%);
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 14px solid #fff;
`;

export interface IPopoverManangerProps {
  linkPosition?: IRect;
}

export const PopoverManager = styled.div<IPopoverManangerProps>`
  position: ${props => (props.linkPosition ? "fixed" : "relative")};
  z-index: 100;
  width: ${props =>
    props.linkPosition ? `${props.linkPosition.width}px` : "auto"};
  top: ${props => props.linkPosition?.top || 0}px;
  left: ${props => props.linkPosition?.left || 0}px;
  display: ${props => (props.linkPosition ? "inline-block" : "block")};
`;

export const usePositionLink = (
  elmRef?: React.RefObject<HTMLDivElement>
): IRect => {
  const [rect, setRect] = React.useState({
    left: 0,
    top: 0,
    width: 0
  });

  React.useEffect(() => {
    const currentRect = elmRef?.current?.getBoundingClientRect();
    if (!currentRect || currentRect.top === rect.top) {
      return;
    }
    // TODO: link must follow the ref top position.
    setRect(currentRect);
  });

  return rect;
};
