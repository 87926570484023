/* FLIGHT ACTIONS **/
import GlobalActions from '../../../actions/GlobalActions'

export const addFlightsAsync = params => async (dispatch) => {
  const { job_id } = await $.post('/api/v1/campaign_units/async_flight_create', params);
  return job_id;
}

export const addFlightsAsyncResponse = (response_data) => async (dispatch) => {
  dispatch({ type: 'ADD_FLIGHTS_SUCCESS', payload: response_data })

  if (response_data && response_data.skipped_count) {
    GlobalActions.showMessage(`Flights saved - skipped ${response_data.skipped_count} units already on campaign.`);
  } else {
    if (response_data && response_data.conflicts.length) {
      GlobalActions.showMessage('Please review flights');
    } else {
      GlobalActions.showMessage('Flight information saved!');
    }
  }
}

export const addFlights = params => async (dispatch, getState) => {
  dispatch({ type: 'DEFAULT' })

  const res = await $.post('/api/v1/campaign_units', params);

  if (res.status === 200) {
    dispatch({ type: 'ADD_FLIGHTS_SUCCESS', payload: res.data })

    if (res.data && res.data.skipped_count) {
      GlobalActions.showMessage(`Flights saved - skipped ${res.data.skipped_count} units already on campaign.`);
    } else {
      if (res.data && res.data.conflicts.length) {
        GlobalActions.showMessage('Please review flights');
      } else {
        GlobalActions.showMessage('Flight information saved!');
      }
    }
  } else {
    if (res.status == 422 && res.message == "Some selected units are market as retired") {
      const retiredFaceIds = res.retired_units.map(u => u.face_id)
      GlobalActions.showError(`The units with the following face ids are marked as retired, no unit was added to the campaign: ${retiredFaceIds}`);
      return
    }
    GlobalActions.showError('There was an error adding your flights, please contact AdQuick');
  }
}

export const updateFlight = params => async (dispatch, getState) => {
  dispatch({ type: 'DEFAULT' })

  const res = await $.ajax({ url: `/api/v1/campaign_units/${params.campaign_unit.id}`, type: 'PUT', data: params })
  if (res.status === 200) {
    dispatch({ type: 'UPDATE_FLIGHT_SUCCESS', payload: res.data })
    GlobalActions.showMessage('Flight information saved!')
  } else {
    console.log({ res })
    GlobalActions.showError('There was an error updating your flight, please contact AdQuick')
  }
}

export const resetFlightForm = () => (dispatch, getState) => {
  dispatch({ type: 'RESET_FLIGHT_FORM' })
}

export const fetchSalesUsers = () => async (dispatch, getState) => {
  const { inventory } = getState()
  if (inventory.sales_users && inventory.sales_users.length)
    return dispatch({ type: 'DEFAULT_SUCCESS', payload: { sales_users: inventory.sales_users } })

  dispatch({ type: 'DEFAULT' })

  const res = await $.get('/api/v1/users?fields=name,id,email')
  if (res.users) {
    const sales_users = res.users.map(u => {
      return { id: u.id, name: (u.name && u.name.length ? u.name : u.email) }
    })
    dispatch({ type: 'DEFAULT_SUCCESS', payload: { sales_users } })
  } else { console.log("fetch sales users failed", res) }
}

export const selectSalesUser = sales_user_id => dispatch => {
  dispatch({ type: 'DEFAULT_SUCCESS', payload: { sales_user_id } })
}

export const deleteFlight = flight => async (dispatch, getState) => {
  dispatch({ type: 'DEFAULT' })

  const res = await $.ajax({ url: `/api/v1/campaign_units/${flight.id}`, type: 'DELETE' })
  if (res.status === 200) {
    dispatch({ type: 'DELETE_FLIGHT_SUCCESS', payload: { ...res.data, flight } })
    GlobalActions.showMessage('Flight removed!')
  } else { GlobalActions.showError('There was an error removing your flight, please contact AdQuick') }
}
