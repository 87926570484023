import React, { Component, CSSProperties, ReactNode } from "react";
import moment from "moment";

import Chart, { createDataSet, buildData } from "../Components/Chart";

import { INewAnalyticsProps } from "../index";
import { buildOptions } from "./Google.charts";

import {
  Header, Grid, SidenavWrapper, Sidenav,
  HighlightBox, WhiteBox,
  MetricBox, Table, Text, TextStyle,
} from "@adquick/ui";

const TABLE_MOCK = [
  ["head1", "head2", "head3", "head4"],
  ["Lorem Ipsum Dollor", "275", "538", "30%"],
  ["Lorem Ipsum Dollor", "275", "538", "30%"],
  ["Lorem Ipsum Dollor", "275", "538", "30%"],
  ["Lorem Ipsum Dollor", "275", "538", "30%"],
  ["Lorem Ipsum Dollor", "275", "538", "30%"],
];

const TABLE_CONFIG = [{ fontWeight: 600 }, undefined, undefined, { color: "#44AC6B" }];

const CAMPAIGN_DURATION = {
  start: "2019-10-06T12:44:37.413Z",
  end: "2019-10-10T12:44:37.413Z",
};

const CHART_DATA_MOCK = {
  xHighlightRange: CAMPAIGN_DURATION,
  datasets: [
    createDataSet("OOH Area",
      [
        { x: "2019-10-01T12:44:37.413Z", y: 3 },
        { x: "2019-10-02T12:44:37.413Z", y: 8 },
        { x: "2019-10-03T12:44:37.413Z", y: 4 },
        { x: "2019-10-04T12:44:37.413Z", y: 8 },
        { x: "2019-10-05T12:44:37.413Z", y: 11 },
        { x: "2019-10-06T12:44:37.413Z", y: 21 },
        { x: "2019-10-07T12:44:37.413Z", y: 18 },
        { x: "2019-10-08T12:44:37.413Z", y: 31 },
        { x: "2019-10-09T12:44:37.413Z", y: 25 },
        { x: "2019-10-10T12:44:37.413Z", y: 22 },
        { x: "2019-10-11T12:44:37.413Z", y: 15 },
        { x: "2019-10-12T12:44:37.413Z", y: 8 },
        { x: "2019-10-13T12:44:37.413Z", y: 3 },
      ], "#4A90E2",
    ),
    createDataSet("Control Area",
      [
        { x: "2019-10-01T12:44:37.413Z", y: 31 },
        { x: "2019-10-02T12:44:37.413Z", y: 22 },
        { x: "2019-10-03T12:44:37.413Z", y: 24 },
        { x: "2019-10-04T12:44:37.413Z", y: 18 },
        { x: "2019-10-05T12:44:37.413Z", y: 11 },
        { x: "2019-10-06T12:44:37.413Z", y: 7 },
        { x: "2019-10-07T12:44:37.413Z", y: 21 },
        { x: "2019-10-08T12:44:37.413Z", y: 23 },
        { x: "2019-10-09T12:44:37.413Z", y: 5 },
        { x: "2019-10-10T12:44:37.413Z", y: 8 },
        { x: "2019-10-11T12:44:37.413Z", y: 19 },
        { x: "2019-10-12T12:44:37.413Z", y: 12 },
        { x: "2019-10-13T12:44:37.413Z", y: 3 },
      ], "#911884",
    ),
    createDataSet(
      "Lift – OOH vs. Control",
      [
        { x: "2019-10-01T12:44:37.413Z", y: 13 },
        { x: "2019-10-02T12:44:37.413Z", y: 18 },
        { x: "2019-10-03T12:44:37.413Z", y: 14 },
        { x: "2019-10-04T12:44:37.413Z", y: 18 },
        { x: "2019-10-05T12:44:37.413Z", y: 21 },
        { x: "2019-10-06T12:44:37.413Z", y: 31 },
        { x: "2019-10-07T12:44:37.413Z", y: 28 },
        { x: "2019-10-08T12:44:37.413Z", y: 21 },
        { x: "2019-10-09T12:44:37.413Z", y: 15 },
        { x: "2019-10-10T12:44:37.413Z", y: 12 },
        { x: "2019-10-11T12:44:37.413Z", y: 25 },
        { x: "2019-10-12T12:44:37.413Z", y: 18 },
        { x: "2019-10-13T12:44:37.413Z", y: 13 },
      ], "#44AC6B",
    )],
};

class GoogleDashboard extends Component<INewAnalyticsProps> {

  constructor(props: INewAnalyticsProps) {
    super(props);
  }

  public render() {
    return (
      <div className="analytics_container analytics_common">
        <Header
          disableWrap={true}
          title="Analytics"
          subTitle="Primary Metrics"
          faIcon="fa-pie-chart" />

        <SidenavWrapper>
          <Sidenav.Sidenav>
            <Sidenav.Title>Final Setup</Sidenav.Title>

            <Sidenav.Notify type={Sidenav.NotifyType.Alert}>Required by <b>09/01/2019</b></Sidenav.Notify>

            <Sidenav.Button dot={Sidenav.NotifyType.Alert}>AdQuick Direct Attribution</Sidenav.Button>
            <Sidenav.Button dot={Sidenav.NotifyType.Alert}>Segment Integration</Sidenav.Button>

            <Sidenav.Separator />

            <Sidenav.Title>Campaign Sumary</Sidenav.Title>

            <Sidenav.Button>Highlights</Sidenav.Button>
            <Sidenav.Button>Recap</Sidenav.Button>
            <Sidenav.Button>Creative Gallery</Sidenav.Button>
            <Sidenav.Button>Photo Gallery</Sidenav.Button>

            <Sidenav.Separator />

            <Sidenav.Title>Analytics</Sidenav.Title>
            <Sidenav.Group title="Primary">
              <Sidenav.Button active={this.isCurrentProvider("google")}>Google Analytics</Sidenav.Button>
            </Sidenav.Group>

            <Sidenav.Group title="Reach">
              <Sidenav.Button>Mobile Impressions</Sidenav.Button>
            </Sidenav.Group>

            <Sidenav.Group title="Halo Effects">
              <Sidenav.Button>Google Ads</Sidenav.Button>
              <Sidenav.Button>Facebook Ads</Sidenav.Button>
              <Sidenav.Button>Instagram</Sidenav.Button>
            </Sidenav.Group>
          </Sidenav.Sidenav>

          <div>
            <Text
              theme={TextStyle.Headline}
              srcIcon={(window as any).ASSETS.analytics_icon_google}>
              Google Analytics
              <br /><br />
            </Text>

            <Text theme={TextStyle.Subhead} faIcon="fa-arrow-circle-up">Highlights</Text>

            <Grid columns="repeat(auto-fit, 205px)" padding="22px 0">
              <HighlightBox percentage={23}>
                Absolute increase in <b>Visits</b> in OOH ZIPs
              </HighlightBox>
              <HighlightBox percentage={15.2}>
                Relative increase in <b>Visits</b> (5 mile radius)
              </HighlightBox>
              <HighlightBox percentage={28}>
                Increase in Clicks in Experiment <b>ZIP</b>
              </HighlightBox>
              <HighlightBox percentage={34}>
                Increase in <b>CTR</b> in Experiment ZIP
              </HighlightBox>
            </Grid>

            <Text theme={TextStyle.Subhead} faIcon="fa-bar-chart">Charts</Text>

            {/* <Instruction>Select a comparison</Instruction>
            <Grid columns="repeat(3, 1fr)" padding="22px 0">
              <WhiteBox>
                <TextIcon theme="subhead">OOH ZIPs vs. Look-alike ZIPs</TextIcon>
                <i>One-to-one</i>
              </WhiteBox>
              <WhiteBox active={true}>
                <TextIcon theme="subhead">OOH ZIPs vs. Look-alike ZIPs</TextIcon>
                <i>One-to-one</i>
              </WhiteBox>
              <WhiteBox>
                <TextIcon theme="subhead">OOH ZIPs vs. Look-alike ZIPs</TextIcon>
                <i>One-to-one</i>
              </WhiteBox>
            </Grid> */}

            <Grid columns="1fr" padding="22px 0">
              <div>
                <MetricBox metric="Visits" percentage={23} active={true} useHover={true} />
                <MetricBox metric="Conversions" percentage={12} useHover={true} />
              </div>
              {/* <div>
                <Text theme={TextStyle.Small} >Add additional metric</Text>
              </div> */}
            </Grid>

            <WhiteBox margin="0 0 22px 0">
              <Chart
                highlight="during_x"
                type="Line"
                data={buildData(CHART_DATA_MOCK)}
                options={buildOptions(CAMPAIGN_DURATION)} />
            </WhiteBox>

            <WhiteBox>
              <Table data={TABLE_MOCK} config={TABLE_CONFIG} />
            </WhiteBox>
          </div>
        </SidenavWrapper>

      </div>
    );
  }

  private isCurrentProvider(provider: string): boolean {
    return this.props.query_params.provider === provider;
  }
}

export default GoogleDashboard;
