export const constraints = {
  supplier_face_id: {
    presence: {
      allowEmpty: false
    }
  },
  name: {
    presence: {
      allowEmpty: false
    }
  },
  lat: {
    presence: {
      allowEmpty: false
    }
  },
  lon: {
    presence: {
      allowEmpty: false
    }
  },
  price: {
    presence: {
      allowEmpty: false
    },
    numericality: {
      onlyInteger: false,
      greaterThan: 0
    }
  },
  spot_length: {
    numericality: {
      onlyInteger: true
    }
  },
  total_spots: {
    numericality: {
      onlyInteger: true
    }
  },
  media_type_id: {
    presence: {
      allowEmpty: false
    }
  },
  revenue_target: {
    numericality: {
      onlyInteger: true
    }
  },
  screen_type: {
    presence: {
      allowEmpty: false
    }
  },
  size: {
    presence: {
      allowEmpty: false
    }
  }
}

export const labels = (attr, type) => {
  const label = {
    supplier_face_id: "Face Id",
    name: "Name or Intersection",
    lat: "Latitude",
    lon: "Longitude",
    price: "Target Price",
    impressions: "4 Week Impressions",
    illuminated: "Illumination (yes/no)",
    restrictions_description: "Restrictions",
    geojson_id: "Transit Line",
    total_spots: "Total # Of Spots",
    media_type_id: "Media Type",
    tab_panel_id: "Geopath Panel Id"
  }
  return label[attr] || capitalizeLabel(attr, type);
}

const capitalizeLabel = (attr, type) => {
  const splitted = attr.split("_");
  const capitalized = splitted.map(l => _.capitalize(l)).join(" ");
  return type == "checkbox" || type == "radiobutton" ? `${capitalized}?` : capitalized;
}
