import React from 'react';
import { browserHistory } from 'react-router-legacy';
import moment from 'moment';
import momentRange from 'moment-range';
import accounting from 'accounting';
import createClass from 'create-react-class';
import cs from 'classnames';
import GlobalActions from '../../actions/GlobalActions';
import ActivityStore from '../../stores/ActivityStore';

const rowStyle = {
  padding: 5,
  fontSize: 15,
  borderTop: '1px solid #eee'
}

const formatCampaignStatus = (status) => status.replace('_', ' ')

const messages = {
  CAMPAIGN_UNITS_SORT: null,
  CAMPAIGN_SHARED: log => `Shared proposal with ${log.data.share.recipient_email}`,
  CAMPAIGN_ADD_ALL_TO_CART: log => 'Added all units to cart',
  CAMPAIGN_VIEWED: log => 'Viewed proposal',
  CAMPAIGN_LOCATION_SEARCH: log => `Searched for ${log.data.query}`,
  CAMPAIGN_FAVORITED_UNIT: log => (
    <span>
      Favorited unit <a onClick={() => { GlobalActions.openUnitID(log.data.unit); }}>{log.data.unit}</a>
    </span>
  ),
  // transactions
  HOLD_RELEASE: log => `Hold released`,
  HOLD: log => `Hold added`,
  HOLD_CREATED: log => `Hold added`,
  HOLD_UPDATED: log => `Hold updated`,
  HOLD_DESTROYED: log => `Hold released`,
  BOOKING_CREATED: log => `Booking added`,
  BOOKING_UPDATED: log => `Booking updated`,
  BOOKING_DESTROYED: log => `Booking canceled`,
  NEW_CONTRACT: log => `New contract signed`,
  NEW_ECONTRACT: log => `New e-contract signed`,
  CAMPAIGN_SHARE_LINK_OPENED: log => 'Opened share link',
  CAMPAIGN_LINK_SENT: log => 'Sent campaign link',
  CAMPAIGN_STATUS_CHANGED: log => `Campaign status changed to ${formatCampaignStatus(log.data.new_status)}`
};

const getTitleColor = log => {
  let titleColor;
  if (["NEW_CONTRACT", "NEW_ECONTRACT", "BOOKING_CREATED", "BOOKING_UPDATED", "BOOKING_DESTROYED"].includes(log.event_name)) {
    titleColor = '#8f2082'
  } else if (["HOLD", "HOLD_RELEASE", "HOLD_CREATED", "HOLD_UPDATED", "HOLD_DESTROYED"].includes(log.event_name)) {
    titleColor = '#3599e2'
  } else {
    titleColor = '#000'
  }
  return titleColor
}

const Item = ({ log }) => {
  if (!messages[log.event_name]) {
    console.debug('dont understand log', log.event_name, log)
    return <div />
  }

  const { campaign, unit, flight } = log
  const action = messages[log.event_name](log);
  const person = (log.user_name || log.user_email || 'Guest');
  const titleColor = getTitleColor(log)

  return (
    <div style={rowStyle} className="row">
      <div className="col-sm-12">
        <div style={{float:'right'}}>{moment(log.created_at).format('LT')}</div>
        <div>
          <span style={{color:titleColor}}>{action}</span>
          {unit && <span> - <b>{unit.supplier_face_id}</b> - {unit.name}</span>}
          {campaign && <span> - <a onClick={() => { window.location = `/campaigns/${campaign.token}`; }} style={{color:'black',textDecoration:'underline'}}>
              {campaign.name}
            </a>
          </span>}
          {(log.event_name == "NEW_ECONTRACT" && log.data.contract && log.data.contract.signed_contract_file) &&
            <span> - <a href={log.data.contract.signed_contract_file.url} target="_blank">View Contract</a></span>}
          {(log.event_name == "NEW_CONTRACT" && log.data.contract && log.data.contract.file) &&
            <span> - <a href={log.data.contract.file.url} target="_blank">View Contract</a></span>}
        </div>
        {(log.event_name == "NEW_CONTRACT" && log.data && log.data.contract) && <div>
          <span>{log.data.contract.title}</span>
        </div>}
        {(flight && flight.start_date && flight.end_date) && <div>
          {moment(flight.start_date).format('LL')} - {moment(flight.end_date).format('LL')}
        </div>}
        {(flight && flight.comment) && <div>
          {flight.comment.split('\n').map((item, key) => <span key={key}>{item}<br/></span>)}
        </div>}
        <div style={{color:"gray"}}>
          <small>{person}</small>
        </div>
      </div>
    </div>
  )
};

export default Item;
