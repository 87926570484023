import React from "react";
import groupBy from "lodash/groupBy";
import RequestCardItem from "../RequestCardItem";

class TaskGroupSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      task_cards: [],
      toggled: false
    }
    this.onSelectTaskCard = this.onSelectTaskCard.bind(this);
    this.onBulkActionClick = this.onBulkActionClick.bind(this);
    this.onToggleAllTasks = this.onToggleAllTasks.bind(this);
  }

  componentDidMount() {
    this.buildTaskCardsState();
  }

  componentDidUpdate(prevProps) {
    if(this.props.isFetching != prevProps.isFetching) {
      this.buildTaskCardsState();
    }
  }

  filterSelectedTasks() {
    const { task_cards } = this.state;
    return task_cards.filter(task => task.selected);
  }

  buildTaskCardsState() {
    const { tasks } = this.props;
    const task_cards = tasks.reduce((acc, current) => {
      current['selected'] = false;
      acc.push(current);
      return acc;
    }, [])
    this.setState({ task_cards });
  }

  sortTasksByCreated(tasks) {
    return tasks.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }

  onToggleAllTasks() {
    const { toggled } = this.state;
    const task_cards = this.state.task_cards.map(task => { return { ...task, selected: !toggled } });
    this.setState({
      task_cards,
      toggled: !toggled
    });
  }

  onSelectTaskCard(task) {
    const selected = this.toggleSelectedTask(task);
    const task_cards = this.state.task_cards.filter(c => c.id !== task.id);
    this.setState({
      task_cards: [...task_cards, selected]
    });
  }

  toggleSelectedTask({ id }) {
    const { task_cards } = this.state;
    const task = task_cards.find(c => c.id === id);
    return {...task, selected: !task.selected };
  }

  isTaskGroupEmpty() {
    const { task_cards } = this.state;
    return task_cards.length === 0;
  }

  groupedTasks() {
    const { task_cards } = this.state;
    return groupBy(this.sortTasksByCreated(task_cards), 'task_type');
  }

  headingForTaskType(taskType) {
    const headings = {
      rfp: "Requests for Proposals",
      initial: "Initial Responses",
      refresh: "Refresh Requests",
      hold: "Hold Requests"
    }
    return headings[taskType];
  }

  renderEmptyTaskGroup() {
    const { groupType } = this.props;
    return (
      <div className="no_requests_message">
        There are currently no {groupType} requests for this campaign.
      </div>
    )
  }

  renderTaskCount() {
    const { task_cards } = this.state;
    if (this.isTaskGroupEmpty()) { return; }
    return `(${task_cards.length})`;
  }

  renderTaskGroupCards() {
    const { campaign, user } = this.props;
    const groupedTasks = this.groupedTasks();
    return Object.keys(groupedTasks).map(task_type =>
      <div key={task_type}>
        <h5>{this.headingForTaskType(task_type)}</h5>
        {groupedTasks[task_type].map(task => 
          <RequestCardItem
            key={task.id}
            supplier_task={task}
            user={user}
            campaign_token={campaign.token}
            showDeclineTaskModal={this.props.showDeclineTaskModal}
            onToggle={this.onSelectTaskCard}
          />
        )}
      </div>
    )
  }

  renderTaskGroup() {
    if (this.isTaskGroupEmpty()) { return this.renderEmptyTaskGroup(); }
    return this.renderTaskGroupCards();
  }

  renderToggleAllButton() {
    return (
      <div className="btn-group" style={{ float: 'right'}}>
        <button type="button" className="btn btn-default" onClick={this.onToggleAllTasks}>
          Toggle All
        </button>
      </div>
    )
  }

  renderBulkActionsButton() {
    return (
      <div className="btn-group" style={{ float: 'right', marginLeft: 10}}>
        <button
          type="button"
          className="btn btn-default dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Bulk Actions <span className="caret"></span>
        </button>
        {this.renderBulkActionsDropdown()}
      </div>
    )
  }

  renderBulkActionsDropdown() {
    const { groupType } = this.props;
    const isCompleted = groupType === 'completed';
    const isOpen = groupType === 'open';
    const isNotOpen = groupType !==  'open';
    return (
      <ul className="dropdown-menu dropdown-menu-right">
        {isNotOpen &&
          <li key="reopen" onClick={this.onBulkActionClick}>
            <a data-action="reopen">Reopen</a>
          </li>
        }
        {isOpen &&
          <li key="remind" onClick={this.onBulkActionClick}>
            <a data-action="remind">Send Reminders</a>
          </li>
        }
        {isOpen &&
          <li key="cancel" onClick={this.onBulkActionClick}>
            <a data-action="cancel">Cancel Request(s)</a>
          </li>
        }
        {isCompleted &&
          <li key="decline" onClick={this.onBulkActionClick}>
            <a data-action="decline">Decline Request(s)</a>
          </li>
        }
        <li key="contact_suppliers" onClick={this.onBulkActionClick}>
          <a data-action="contact">Contact Supplier(s)</a>
        </li>
      </ul>
    )
  }

  onBulkActionClick(e) {
    const { target: { dataset } } = e
    const { showBulkActionModal } = this.props;
    const selectedTasks = this.filterSelectedTasks();
    return showBulkActionModal(selectedTasks, dataset);
  }

  render() {
    const { sectionTitle } = this.props;
    return (
      <div>
        <div className="row" style={{ paddingTop: 15}}>
          <div className="new_h1 col-xs-9">
            {sectionTitle} {this.renderTaskCount()}
          </div>
          <div className="col-xs-3">
            {this.renderBulkActionsButton()}
            {this.renderToggleAllButton()}
          </div>
        </div>
        <div className="row">
          <div className="campaign_requests col-xs-12">
            {this.renderTaskGroup()}
          </div>
        </div>
      </div>
    )
  }
}

export default TaskGroupSection;