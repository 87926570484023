import Reflux from 'reflux';

export default Reflux.createActions({
  "purchased": {},
  "addUnit": {},
  "removeUnit": {},
  "removeCartItem": {},
  "loadCampaign": {},
  "createCampaign": {},
  "resetCampaign": {},
  "submitAsProposal": {},
});
