import createClass from "create-react-class";
import moment from "moment";
import React from "react";
import { DropdownButton, MenuItem, Modal, Pagination } from "react-bootstrap";
import { BootstrapTable } from "react-bootstrap-table";
import { connect } from "react-redux";
import { withRouter } from "react-router-legacy";
import { browserHistory } from "react-router-legacy";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

import GlobalActions from "../../../actions/GlobalActions";
import { del, get, post, put } from "../../../utils/api";

class VendorCampaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per_page: 20,
      campaigns: [],
      loading: true,
      sort_by: {
        label: "Created Date ↓",
        value: "created_at desc",
      },
      show_delete_confirmation: false,
      campaign_for_delete: {},
      show_clone_confirmation: false,
      campaign_for_clone: {},
      saving: false,
    };

    this.onHideModal = this.onHideModal.bind(this);
    this.getCampaigns = this.getCampaigns.bind(this);
    this.onSalesUserChange = this.onSalesUserChange.bind(this);
    this.setDefaultToCurrentUser = this.setDefaultToCurrentUser.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onAdvertiserChange = this.onAdvertiserChange.bind(this);
    this.getAdvertisers = this.getAdvertisers.bind(this);
    this.onCreateCampaign = this.onCreateCampaign.bind(this);
    this.onArchive = this.onArchive.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onCloneConfirm = this.onCloneConfirm.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.renderCreateCampaignButtons = this.renderCreateCampaignButtons.bind(this);
    this.showObjectNameAttribute = this.showObjectNameAttribute.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.cellActionButtons = this.cellActionButtons.bind(this);
  }

  buildParams() {
    const { page, per_page, query, advertiser, sort_by, sales_user } = this.state;
    const page_param = `page=${page}`;
    const per_page_param = `sizePerPage=${per_page}`;
    const query_param = query ? `q=${query}&status=search` : "";
    const advertiser_param = advertiser ? `advertiser_id=${advertiser.value}` : "";
    const sorting_params = sort_by
      ? `sortColumn=${sort_by.value.split(" ")[0]}&sortOrder=${sort_by.value.split(" ")[1]}`
      : "";
    const sales_user_param = sales_user ? `sales_user_id=${sales_user.value}` : "";

    const params = [per_page_param, page_param, query_param, advertiser_param, sorting_params, sales_user_param];
    if (params.length > 0) {
      return params.join("&");
    } else {
      return "";
    }
  }

  async getCampaigns(event) {
    if (event) event.preventDefault();
    this.setState({ loading: true });
    const url = `/api/v1/campaigns?vendor_index=true&${this.buildParams()}`;
    const result = await get(url);
    this.setState({
      per_page: result.per_page,
      campaigns: result.results,
      total_count: result.total_count,
      total_pages: result.total_pages,
      loading: false,
    });
  }

  componentWillMount() {
    const { currentUser } = this.props;
    if (currentUser && !!!currentUser.is_guest) {
      this.loadSalesUsersAndCampaigns();
    }
  }

  loadingSpinner() {
    const { loading } = this.state;
    return (
      <div>
        {loading && (
          <div className="loading-spinner">
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          </div>
        )}
      </div>
    );
  }

  showObjectNameAttribute(cell, row) {
    if (cell) {
      return cell.name;
    } else {
      return "";
    }
  }

  formatDate(cell, row) {
    const formatted_date = moment(cell).format("L");
    if (formatted_date === "Invalid date") {
      return "";
    } else {
      return formatted_date;
    }
  }

  onRowClick(campaign) {
    window.location = `/campaigns/${campaign.token}`;
  }

  setFilter(filter) {
    this.setState(filter, this.getCampaigns);
  }

  renderPagination() {
    const perPageOptions = [10, 20, 30, 50, 100];
    const { total_pages, page, per_page } = this.state;

    return (
      <div className="row">
        <div className="col-sm-1">
          <div className="pagination">
            <DropdownButton title={per_page || "10"} id="bg-nested-dropdown">
              {perPageOptions.map(sizePerPage => (
                <MenuItem key={`per-page-${sizePerPage}`} onClick={() => this.setFilter({ per_page: sizePerPage })}>
                  {sizePerPage}
                </MenuItem>
              ))}
            </DropdownButton>
          </div>
        </div>
        <div className="col-sm-11">
          <Pagination
            bsSize="medium"
            prev
            next
            last
            ellipsis
            boundaryLinks
            items={total_pages}
            maxButtons={5}
            activePage={page || 1}
            onSelect={page => {
              this.setFilter({ page });
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
    );
  }

  setDefaultToCurrentUser() {
    this.getCampaigns();
  }

  async loadSalesUsersAndCampaigns() {
    const response = await get("/api/v1/users/new_sales_users");
    let users = [];
    if (response.users) {
      users = response.users;
    } else {
      users = response;
    }
    const options = users.map(u => Object.assign({ label: u.name_or_email, value: u.id }));
    this.setState(
      {
        sales_user_options: options,
      },
      this.setDefaultToCurrentUser,
    );
  }

  onSalesUserChange(sales_user) {
    this.setState({ sales_user }, this.getCampaigns);
  }

  renderSalesPersonFilter() {
    const { sales_user } = this.state;
    return (
      <div className="sales-person-filter">
        <ReactSelect
          value={sales_user}
          onChange={this.onSalesUserChange}
          options={this.state.sales_user_options}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          cache={false}
          isClearable
          placeholder="Filter by Account Manager"
        />
      </div>
    );
  }

  onSort(sort_by) {
    this.setState({ sort_by }, this.getCampaigns);
  }

  renderSortingDropdown() {
    const { sort_by } = this.state;
    const sort_by_options = [
      { label: "Campaign Name ↑", value: "LOWER(name) asc" },
      { label: "Campaign Name ↓", value: "LOWER(name) desc" },
      { label: "Created Date ↑", value: "created_at asc" },
      { label: "Created Date ↓", value: "created_at desc" },
      { label: "Last Updated ↑", value: "updated_at asc" },
      { label: "Last Updated ↓", value: "updated_at desc" },
      { label: "Start Date ↑", value: "start_date asc" },
      { label: "Start Date ↓", value: "start_date desc" },
      { label: "End Date ↑", value: "end_date asc" },
      { label: "End Date ↓", value: "end_date desc" },
      { label: "Archived ↑", value: "archived_at asc" },
      { label: "Archived ↓", value: "archived_at desc" },
    ];

    return (
      <div className="sorting-dropdown">
        <ReactSelect
          className="selectInput"
          placeholder="Sorting Options"
          value={sort_by}
          options={sort_by_options || []}
          onChange={this.onSort}
        />
      </div>
    );
  }

  async getAdvertisers(text) {
    if (!text || text.length < 3) {
      return this.state.advertisers;
    }

    const { status, advertisers } = await get(`/api/v1/merchants/search?q=${text}`);
    const options = advertisers.map(a => Object.assign({ label: a.name, value: a.id }));
    return options;
  }

  onAdvertiserChange(advertiser) {
    this.setState({ advertiser }, this.getCampaigns);
  }

  renderAdvertiserFilter() {
    const { advertiser } = this.state;
    return (
      <div className="advertiser-filter">
        <AsyncSelect
          value={advertiser}
          onChange={this.onAdvertiserChange}
          loadOptions={this.getAdvertisers}
          defaultOptions={true}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          cache={false}
          isClearable
          placeholder="Filter by Advertiser"
        />
      </div>
    );
  }

  renderSearchBar() {
    const { query } = this.state;

    return (
      <div>
        <div className="row" style={{ marginBottom: 15 }}>
          <div className="col-sm-4 search-bar-area">
            <form onSubmit={this.getCampaigns} style={{ margin: 0 }}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search campaigns"
                  value={query}
                  onChange={e => this.setState({ query: e.target.value, page: 1 })}
                />
                <span className="input-group-btn">
                  <input className="btn btn-default" type="submit" value="Search" />
                </span>
              </div>
            </form>
          </div>
          <div className="col-sm-3">{this.renderAdvertiserFilter()}</div>
          <div className="col-sm-3">{this.renderSalesPersonFilter()}</div>
          <div className="col-sm-2">{this.renderSortingDropdown()}</div>
        </div>
      </div>
    );
  }

  onCreateCampaign() {
    const { currentUser } = this.props;
    currentUser.is_managed_supplier
      ? browserHistory.push("/campaign_builder")
      : (window.location.href = "/campaigns/new");
  }

  renderCreateCampaignButtons() {
    const { currentUser } = this.props;
    const { toggleCrm } = this.props;

    if (currentUser.permissions.can_create_campaign) {
      return (
        <div>
          <button type="button" className="btn btn-primary create-campaign-button" onClick={this.onCreateCampaign}>
            <i className="fa fa-plus" /> Create Campaign
          </button>
          {currentUser.permissions.can_use_genius && (
            <div className="btn-group" style={{ marginRight: 10 }}>
              <button type="button" className="btn btn-default" onClick={() => browserHistory.push("/genius")}>
                <i className="fa fa-flask" /> Campaign Genius
              </button>
            </div>
          )}
          {currentUser.is_admin && toggleCrm && (
            <div className="btn-group" style={{ marginRight: 10 }}>
              <button type="button" className="btn btn-info" onClick={() => toggleCrm()}>
                <i className="fa fa-list-alt" aria-hidden="true"></i> Original View
              </button>
            </div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  cellActionButtons(cell, row, enumObject, rowIndex) {
    const { currentUser } = this.props;

    if (currentUser.permissions.can_create_campaign) {
      return (
        <div>
          <button
            type="button"
            title="Delete"
            className="btn btn-danger delete-campaign-button"
            onClick={e => this.onDeleteCampaign(e, row)}
          >
            <i className="fa fa-trash" />
          </button>
          <button
            type="button"
            title="Clone"
            className="btn btn-info clone-campaign-button"
            onClick={e => this.onCloneCampaign(e, row)}
          >
            <i className="fa fa-clone" />
          </button>
          {row.archived_at && (
            <button
              type="button"
              title="Unarchive"
              className="btn btn-default campaign-refresh-button"
              onClick={e => this.onUnarchive(e, row)}
            >
              <i className="fa fa-refresh" />
            </button>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  onDeleteCampaign(e, row) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      show_delete_confirmation: true,
      campaign_for_delete: row,
    });
  }

  onCloneCampaign(e, row) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      show_clone_confirmation: true,
      campaign_for_clone: row,
    });
  }

  onArchive() {
    const { campaign_for_delete } = this.state;
    post(`/api/v1/campaigns/${campaign_for_delete.token}/archive`).then(response => {
      if (response.status === 200) {
        this.setState({ show_delete_confirmation: false, saving: false }, () => this.getCampaigns());
      } else {
        GlobalActions.showError(`couldn't archive campaign ${campaign_for_delete.token}`);
      }
    });
  }

  onUnarchive(e, row) {
    e.stopPropagation();
    e.preventDefault();
    del(`/api/v1/campaigns/${row.token}/archive`).then(response => {
      if (response.status === 200) {
        this.getCampaigns();
      } else {
        GlobalActions.showError(`couldn't unarchive campaign ${row.token}`);
      }
    });
  }

  onDeleteConfirm() {
    const { campaign_for_delete } = this.state;
    this.setState({ saving: true });
    del(`/api/v1/campaigns/${campaign_for_delete.token}.json`, { method: "DELETE" }).then(response => {
      if (response.status === 200)
        this.setState({ show_delete_confirmation: false, saving: false }, () => this.getCampaigns());
      else {
        console.info(`couldn't delete campaign ${campaign_for_delete.token}, ${response.status}`);
      }
    });
  }

  onCloneConfirm() {
    const { campaign_for_clone } = this.state;
    this.setState({ saving: true });
    post(`/api/v1/campaigns/${campaign_for_clone.token}/clone?vendor_clone=true`).then(response => {
      if (response.status === 200)
        this.setState({ show_clone_confirmation: false, saving: false }, () => {
          this.setFilter({ page: 1 });
        });
      else {
        console.info(`couldn't clone campaign ${campaign_for_clone.token}, ${response.status}`);
      }
    });
  }

  onHideModal() {
    this.setState({
      show_delete_confirmation: false,
      show_clone_confirmation: false,
      campaign_for_clone: {},
      campaign_for_delete: {},
    });
  }

  renderSpinner() {
    const { saving } = this.state;
    const spinner_style = {
      textAlign: "center",
      fontSize: 24,
      width: "25%",
      marginLeft: "38%",
      height: 0,
      color: "#9c9c9c",
    };

    return (
      <div>
        {saving && (
          <div style={spinner_style}>
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          </div>
        )}
      </div>
    );
  }

  renderDeleteCampaignModal() {
    const { show_delete_confirmation, campaign_for_delete, saving } = this.state;

    return (
      <Modal show={show_delete_confirmation} onHide={this.onHideModal}>
        <Modal.Header>
          <h4>Are you sure you want to delete campaign {campaign_for_delete.name}?</h4>
        </Modal.Header>
        <Modal.Body>
          <p>Note that deleting this campaign will also delete all flights associated with this campaign</p>
          {!campaign_for_delete.archived_at && (
            <p>If you would like to Archive this campaign for future reference, select the Archive Button.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {this.renderSpinner()}
          {!campaign_for_delete.archived_at && (
            <button className="btn btn-warning" type="button" onClick={this.onArchive}>
              Archive
            </button>
          )}
          <button disabled={saving} className="btn btn-danger" type="button" onClick={this.onDeleteConfirm}>
            Delete
          </button>
          <a onClick={() => this.setState({ show_delete_confirmation: false })} className="btn btn-default">
            Close
          </a>
        </Modal.Footer>
      </Modal>
    );
  }

  renderCloneCampaignModal() {
    const { show_clone_confirmation, campaign_for_clone, saving } = this.state;

    return (
      <Modal show={show_clone_confirmation} onHide={this.onHideModal}>
        <Modal.Header>
          <h4>Are you sure you want to clone: {campaign_for_clone.name}?</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your new campaign will be named: <b>{campaign_for_clone.name} [Duplicate]</b>
          </p>
          <p>
            This action will clone any <b>units</b> and <b>points of interest</b> associated with this campaign as well.
          </p>
        </Modal.Body>
        <Modal.Footer>
          {this.renderSpinner()}
          <button disabled={saving} className="btn btn-info" type="button" onClick={this.onCloneConfirm}>
            Clone
          </button>
          <a onClick={() => this.setState({ show_clone_confirmation: false })} className="btn btn-default">
            Close
          </a>
        </Modal.Footer>
      </Modal>
    );
  }

  renderModals() {
    return (
      <div>
        {this.renderDeleteCampaignModal()}
        {this.renderCloneCampaignModal()}
      </div>
    );
  }

  render() {
    const { campaigns, loading, total_count, total_pages } = this.state;
    const { currentUser } = this.props;

    if (!!!currentUser || (currentUser && currentUser.is_guest)) {
      return (
        <div className="text-center" style={{ padding: 50 }}>
          Please login
        </div>
      );
    } else {
      return (
        <div id="vendor_campaigns">
          {this.loadingSpinner()}
          <div className="search-filter-sort-area">{!loading && this.renderSearchBar()}</div>
          {!loading && (
            <div>
              {this.renderModals()}
              <BootstrapTable
                data={campaigns}
                tableStyle={{ cursor: "pointer" }}
                options={{
                  onRowClick: this.onRowClick,
                  insertBtn: this.renderCreateCampaignButtons,
                }}
                striped
                hover
                condensed
                insertRow
              >
                <TableHeaderColumn dataField="token" isKey hidden />
                <TableHeaderColumn width="300" dataField="name">
                  Campaign Name
                </TableHeaderColumn>
                <TableHeaderColumn width="130" dataField="advertiser" dataFormat={this.showObjectNameAttribute}>
                  Advertiser
                </TableHeaderColumn>
                <TableHeaderColumn width="130" dataField="sales_user" dataFormat={this.showObjectNameAttribute}>
                  Account Manager
                </TableHeaderColumn>
                <TableHeaderColumn width="110" dataField="budget">
                  Budget
                </TableHeaderColumn>
                <TableHeaderColumn width="55" dataField="units_count">
                  Units
                </TableHeaderColumn>
                <TableHeaderColumn width="80" dataField="favorites_count">
                  Favorited
                </TableHeaderColumn>
                <TableHeaderColumn width="55" dataField="recommended_count">
                  Rec'd
                </TableHeaderColumn>
                <TableHeaderColumn width="100" dataField="start_date" dataFormat={this.formatDate}>
                  Start Date
                </TableHeaderColumn>
                <TableHeaderColumn width="100" dataField="end_date" dataFormat={this.formatDate}>
                  End Date
                </TableHeaderColumn>
                <TableHeaderColumn width="100" dataField="created_at" dataFormat={this.formatDate}>
                  Created Date
                </TableHeaderColumn>
                <TableHeaderColumn width="102" dataField="updated_at" dataFormat={this.formatDate}>
                  Last Updated
                </TableHeaderColumn>
                <TableHeaderColumn width="105" dataField="delete_button" dataFormat={this.cellActionButtons}>
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
              {this.renderPagination()}
            </div>
          )}
        </div>
      );
    }
  }
}

export default withRouter(connect(({ currentUser }) => ({ currentUser }), {})(VendorCampaigns));
