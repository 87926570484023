import React from 'react';
import { Modal } from 'react-bootstrap';
import createClass from 'create-react-class';

import GlobalActions from '../actions/GlobalActions';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';

export default createClass({
  displayName: 'WelcomeModal',

  getInitialState() {
    return {};
  },

  componentDidMount() {
    this.trackConversion();
    this.unsubscribeList = [];
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  trackConversion() {
    const google_conversion_id = 878432214;
    const google_conversion_label = 'Ck7HCPSLjGwQ1p_vogM';

    const image = new Image(1, 1);
    image.src = `//www.googleadservices.com/pagead/conversion/${google_conversion_id}/?label=${google_conversion_label}&script=0`;
  },

  render() {
    return (<Modal show={this.props.show} onHide={this.props.onHide} className="sign-up-modal" animation={!window.mobileCheck()} id="welcome-modal">
      <Modal.Header>
        <h1 className="text-center" style={{ margin: 0 }}>
          Welcome To AdQuick.<br />Thanks for signing up!
        </h1>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div>
          <div style={{ textAlign: 'center', fontSize: 19, margin: '0 20px 0 20px', color: '#aaa' }}>
            <i className="fa fa-file-text-o" />
          </div>
          <div style={{ color: '#333', textAlign: 'center', fontSize: 14, padding: 10 }}>
            The majority of our vendors only share inventory with verified buyers. By requesting a campaign proposal below, you will gain access to our nationwide inventory.
          </div>
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            <button className="btn btn-primary" onClick={this.props.onRequestProposal} style={{ padding: 10 }} id="request-campaign-proposal-button">
              Request Campaign Proposal
              <i className="fa fa-chevron-right" style={{ marginLeft: 5 }} />
            </button>
          </div>
          <div className="color-green" style={{ fontSize: 12, marginTop: 5 }}>1,000,000+ locations</div>
        </div>
        <hr style={{ margin: '30px 0' }} />
        <div>
          <div style={{ textAlign: 'center', fontSize: 19, margin: 20, color: '#aaa', marginBottom: 0 }}>
            <i className="fa fa-map-o" />
          </div>
          <div style={{ color: '#777', textAlign: 'center', fontSize: 14, padding: 10 }}>
            You can also browse our public inventory, but data will be limited until you request a proposal.
          </div>
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            <button className="btn btn-default color-green border-color-green" onClick={this.props.onHide} style={{ padding: 10 }} id="browse-button">
              Browse Public Inventory
              <i className="fa fa-chevron-right" style={{ marginLeft: 5 }} />
            </button>
          </div>
          <div className="color-green" style={{ fontSize: 12, marginTop: 5 }}>5,000+ locations</div>
        </div>
      </Modal.Body>
    </Modal>);
  },
});
