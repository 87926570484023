import debounce from 'lodash/debounce';

export const TOGGLE_MAP_CLUSTER = 'new-map/toggle-cluster';
export const toggleMapCluster = () => dispatch => dispatch({ type: TOGGLE_MAP_CLUSTER });

export const TOGGLE_MAP_PLACEMARKERS = 'new-map/toggle-show-placemarkers';
export const toggleMapPlacemarkers = () => dispatch => dispatch({ type: TOGGLE_MAP_PLACEMARKERS });

export const TOGGLE_MAP_SIZE = 'new-map/toggle-size';
export const toggleMapSize = () => dispatch => dispatch({ type: TOGGLE_MAP_SIZE });

export const TRIGGER_MAP_RESIZE = 'new-map/trigger-resize';
export const triggerMapResize = () => dispatch => dispatch({ type: TRIGGER_MAP_RESIZE });

export const TOGGLE_MAP_TRAFFIC = 'new-map/toggle-traffic';
export const toggleMapTraffic = () => dispatch => dispatch({ type: TOGGLE_MAP_TRAFFIC });

export const TOGGLE_SELECTED_MARKERS = 'new-map/toggle-selected-markers';
export const toggleSelectedMarkers = (selected_bounds = null) => dispatch => dispatch({
  type: TOGGLE_SELECTED_MARKERS,
  payload: { selected_bounds }
});

export const TOGGLE_SELECTED_POLYGONS = "new-map/toggle-selected-polygons";
export const toggleSelectedPolygons = (selected_polygons = null) => dispatch => dispatch({
  type: TOGGLE_SELECTED_POLYGONS,
  payload: { selected_polygons },
});

export const UPDATE_MAP_POSITION = 'new-map/update-position';
export const updateMapPosition = (position) => dispatch => dispatch({
  type: UPDATE_MAP_POSITION,
  payload: { position }
});

export const TOGGLE_UNIT_POPUP = 'new-map/toggle-unit-popup';
export const toggleUnitPopup = (unit_id) => dispatch => dispatch({
  type: TOGGLE_UNIT_POPUP,
  payload: { unit_id }
});

export const UPDATE_CURRENT_CUSTOM_POI = 'new-map/update-current-custom-poi';
export const updateCurrentCustomPOI = (poi) => dispatch => dispatch({
  type: UPDATE_CURRENT_CUSTOM_POI,
  payload: { poi }
});

export const SELECT_COORDINATES = 'new-map/select-coordinates';
export const selectCoordinates = ( coordinates ) => dispatch => dispatch({
  type: SELECT_COORDINATES,
  payload: { coordinates }
});


export const SET_BOUNDS_DIRTY = 'new-map/set-bounds-dirty';
export const setBoundsDirty = () => (dispatch) => dispatch({ type: SET_BOUNDS_DIRTY });
export const UNSET_BOUNDS_DIRTY = 'new-map/unset-bounds-dirty';
export const unsetBoundsDirty = () => (dispatch) => dispatch({ type: UNSET_BOUNDS_DIRTY });

export const SET_BOUNDS = 'new-map/set-bounds';
export const setBounds = (bounds) => (dispatch, getState) => {
  if (!Array.isArray(bounds)) {
    bounds = bounds.split(',');
    bounds = [[bounds[1], bounds[0]], [bounds[3],bounds[2]]];
  }

  const currentBounds = getState().map.bounds;
  // ignore small changes to avoid infinite loops
  if (
    currentBounds &&
    Math.round(100000 * currentBounds[0][0]) == Math.round(100000 * bounds[0][0]) &&
    Math.round(100000 * currentBounds[0][1]) == Math.round(100000 * bounds[0][1]) &&
    Math.round(100000 * currentBounds[1][0]) == Math.round(100000 * bounds[1][0]) &&
    Math.round(100000 * currentBounds[1][1]) == Math.round(100000 * bounds[1][1])
  ) return;

  DEBUG && console.log("updating map bounds", {from: currentBounds, to: bounds});

  setBoundsDirty()(dispatch);
  dispatch({ type: SET_BOUNDS, payload: { bounds }});
};

export const TOGGLE_UNIT_REACH = 'new-map/toggle-unit-reach';
export const toggleUnitReach = () => dispatch => dispatch({ type: TOGGLE_UNIT_REACH });

export const CLEAR_MAP = 'new-map-clear';
export const clearMap = () => dispatch => dispatch({ type: CLEAR_MAP });
