import "./menu-item.css";
import * as React from "react";

const menuStyle = () => ({
  fontSize: "14px",
});

const Menu = React.forwardRef(
  ({ children }: { children: React.ReactNode | string }, ref: React.Ref<HTMLInputElement> | undefined) => (
    <div ref={ref} style={menuStyle()}>
      {children}
    </div>
  ),
);

interface ILinkProp {
  children: React.ReactNode | string;
  active?: boolean;
  onClick: (event: React.MouseEvent) => void;
}

interface ILinkState {
  isActive?: boolean;
  isHover?: boolean;
}

enum ColorModifiers {
  PrimaryLight = "ui-button--primary-light",
  NeutroTransparent = "ui-button--neutro-transparent",
}

export class Link extends React.Component<ILinkProp, ILinkState> {
  constructor(props: ILinkProp) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.setState({ isActive: this.props.active });
  }

  public componentDidUpdate(prevProp: ILinkProp) {
    if (prevProp.active !== this.props.active) {
      this.setState({ isActive: this.props.active });
    }
  }

  private get mouseEvents() {
    return {
      onClick: (event: React.MouseEvent) => this.props.onClick(event),
      onMouseOver: () => this.setState({ isHover: true }),
      onMouseOut: () => this.setState({ isHover: false }),
    };
  }

  public render() {
    const { isActive } = this.state;
    const buttonColor: ColorModifiers = isActive ? ColorModifiers.PrimaryLight : ColorModifiers.NeutroTransparent;
    return (
      <button
        className={`menuItem ui-button ui-button--pill ${buttonColor}`}
        style={{ fontWeight: isActive ? 500 : 400 }}
        {...this.mouseEvents}
      >
        {this.props.children}
      </button>
    );
  }
}

export default Menu;
