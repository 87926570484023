import React from 'react'
import { Modal } from 'react-bootstrap'
import FlightForm from './FlightForm'
import moment from 'moment'

export default class EditFlightModal extends React.Component {

  constructor(props) {
    super(props);
    const { campaign_unit } = this.props;

    this.state = {
      campaign_unit: {
        ...campaign_unit,
        start_date: moment(campaign_unit.start_date),
        end_date: moment(campaign_unit.end_date)
      }
    }
  }

  render() {
    const { user, selectedUnits, show, onDelete, flightTypes, filterByFaceIds } = this.props
    const { campaign_unit } = this.state

    return (
      <Modal show={show} onHide={this.props.onAddHoldsHide}>
        <Modal.Header>
          <Modal.Title>Editing flight {campaign_unit.supplier_face_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add_campaign">
            {show &&
              <FlightForm
                formType={'edit'}
                campaign_unit={campaign_unit}
                onDelete={onDelete}
                flightTypes={flightTypes}
                filterByFaceIds={filterByFaceIds}
                user={user}
                unit={selectedUnits[0]}
              />
            }
          </div>
        </Modal.Body>
      </Modal>
    )
  }
};
