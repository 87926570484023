import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from "react-paginate";
import Scroll, { scroller } from "react-scroll";
import UnitsGroup from "../Catalog/UnitsGroup";
import { listViewUnits } from "../../../models/Units";
import UnitCollectionModel from "../../../models/UnitCollectionModel";
import {
  favoriteUnits,
  unfavoriteUnits,
} from "../../../actions/UnitsActions";

const PER_PAGE = 200;

class List extends Component {

  constructor(props){
    super(props);
    this.onFavoriteUnit = this.onFavoriteUnit.bind(this);
    this.state = {
      offset: 0,
      activeUnitId: null,
    };
  }

  toggleExpandUnitItem(unit_id, manualIndex = null) {
    const { activeUnitId } = this.state;
    const { unit, _package } = this.getUnitAndPackage(unit_id);

    if (activeUnitId === unit.id) {
      this.setState({
        activeUnitId: null,
      });
    } else {
      this.setState({
        activeUnitId: unit.id,
      });
    }

  }

  async onFavoriteUnit(unitId, campaignUnitId, isFavorited, showCallback) {
    const campaignUnitIds = campaignUnitId ? [campaignUnitId] : []
    const { campaignId } = this.props.campaign;
    isFavorited ?
      await this.props.unfavoriteUnits(campaignUnitIds, [unitId], campaignId, showCallback) :
      await this.props.favoriteUnits(campaignUnitIds, [unitId], campaignId, showCallback);
  }

  getUnitAndPackage(unit_id) {
    const flattenUnitsList = this.pageUnits(true);
    const collection = new UnitCollectionModel(flattenUnitsList);
    const unit = collection.getUnitById(unit_id);
    const _package = collection.getPackageForUnit(unit);
    return({ unit, _package });
  }

  pageUnits(flatten = false) {
    const { offset } = this.state;

    const allUnitsByMarket = listViewUnits(this.props.units, 'market');
    const allUnitsFlattenList = _.isEmpty(allUnitsByMarket) ? [] : Object.values(allUnitsByMarket).flat();

    const slice = allUnitsFlattenList.slice(offset, offset + PER_PAGE);
    if (flatten) return slice;
    return this.unitsGroupedByMarket(slice);
  }

  unitsGroupedByMarket(units) {
    const unitsGroupedByMarket = _.groupBy(units, (unit) => {
      return _.get(unit, 'market_name') || "Unknown"
    });
    return unitsGroupedByMarket;
  }

  onPageChange(data) {
    const selected = data.selected;
    const offset = Math.ceil(selected * PER_PAGE);
    this.setState({ offset: offset });
    Scroll.animateScroll.scrollToTop();
  }

  renderPagination() {
    const allUnitsByMarket = listViewUnits(this.props.units, 'market');
    const allUnitsFlattenList = _.isEmpty(allUnitsByMarket) ? [] : Object.values(allUnitsByMarket).flat();
    const pageCount = allUnitsFlattenList.length / PER_PAGE;

    if (pageCount <= 1) return null;
    return (
      <div className="text-center">
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={1}
          onPageChange={(data) => this.onPageChange(data) }
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    )
  }

  renderUnitsByMarket() {
    const { activeUnitId } = this.state;
    const { campaign, hide_unit_prices } = this.props;
    const campaign_permissions = campaign.permissions;
    const pageUnits = this.pageUnits();
    const groupSizes = Object.values(pageUnits).map((v) => v.length);
    
    return (
      Object.keys(pageUnits).map((key, index) => {
        const indexOffset = index == 0 ? 0 : groupSizes[index - 1];
        return (
          <UnitsGroup
            key={index}
            title={key}
            units={pageUnits[key]}
            campaign_permissions={campaign_permissions}
            campaign={campaign}
            onFavoriteUnit={this.onFavoriteUnit}
            onUnitCardClick={(unitId, idx) => this.toggleExpandUnitItem(unitId, idx)}
            activeUnitId={activeUnitId}
            indexOffset={indexOffset}
            hide_unit_prices={hide_unit_prices}
          />
        )
      })
    )
  }

  render() {
    const { units } = this.props;
    const unitsCount = _.get(units, 'all_units.length', 0);
    const noUnitsMessage = unitsCount == 0 ? 'There are no units on this campaign' : 'No units were found matching your filters';
    const pageUnits = this.pageUnits();

    return (
      <div id="mobile-list">
        {!_.isEmpty(pageUnits) &&
          <div>
            {this.renderUnitsByMarket()}
            {this.renderPagination()}
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = ({
    currentUser,
    units,
    campaign
  }) => ({
    currentUser,
    units,
    campaign
});

export default connect(
  mapStateToProps,
  { favoriteUnits, unfavoriteUnits }
 )(List)
