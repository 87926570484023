import React from 'react';
import Icon from '../Icon'

export const drawIcon = (type, value, isPackage=false, secondaryStyle=null) => (
  <Icon type={type} value={value} isPackage={isPackage} secondaryStyle={secondaryStyle} />
)

export const customIconMapping = PLACEMARKER_ICON_MAP;
export const unitTypeIconMapping = UNIT_TYPE_ICON_MAP;
export const unitAvailabilityMapping = UNIT_AVAILABILITY_MAP;

export const customIcons = Object.keys(customIconMapping)
