import createClass from "create-react-class";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from 'react-router-legacy';

import GlobalActions from "../actions/GlobalActions";
import { setAdvertiser } from "../actions/greylabel";
import { setCurrentUser } from "../features/User/actions";
import AuthStore from "../stores/AuthStore";
import GlobalStore from "../stores/GlobalStore";
import AddCreditCardModal from "./AddCreditCardModal";
import ConfirmAccountModal from "./Auth/ConfirmAccountModal";
import LoginModal from "./Auth/LoginModal";
import SignUpWizard from "./Auth/SignUpWizard";
import CalendarPopup from "./CalendarPopup";
import CalendarPickerModal from "./Campaign/DesignAssets/CalendarPickerModal";
import ShippingAddressModal from "./Campaign/DesignAssets/ShippingAddressModal";
import AnalyticSetupModal from "./Campaign/NewAnalytics/Modals/AnalyticSetupModal";
import RequestPriceModal from "./Campaign/RequestPriceModal";
import CampaignRequestSent from "./CampaignRequestSent";
import ComposeEmailModal from "./ComposeEmail/Modal";
import ConfirmGeniusCampaign from "./ConfirmGeniusCampaign";
import ConfirmSubmitModal from "./ConfirmSubmitModal";
import ContactModal from "./ContactModal";
import AddContract from "./Contracts/AddContract";
import EditEmailModal from "./EditEmail/Modal";
import { asyncFetchFeatures } from "./FeatureFlags/action";
import Icons from "./Icons";
import { hideModal as hideEditUnitModal } from "./Inventory/actions";
import EditUnitModal from "./Inventory/Unit/EditModal";
import VendorAddUnitsFromMarkers from "./Map/VendorAddUnitsFromMarkers";
import Message from "./Message";
import NoResultsFound from "./NoResultsFound";
import OnboardingModal from "./OnboardingModal";
import Overlay from "./Overlay";
import UploadAttachmentModal from "./Campaign/Attachments/UploadAttachmentModal";
import RequestNoteToSupplierModal from "./Campaign/Edit/Modals/RequestNoteToSupplierModal";
import Splash from "./Splash";
import UnitPopup from "./UnitPopup";
import WelcomeModal from "./WelcomeModal";

const Layout = createClass({
  displayName: "Layout",

  getInitialState() {
    return {
      global: GlobalStore.getState(),
      user: AuthStore.getState().user,
    };
  },

  componentDidMount() {
    window.reactRouter = browserHistory;
    this.props.asyncFetchFeatures();
    this.props.setCurrentUser();
    this.initializeTools();

    this.unsubscribeList = [
      GlobalStore.listen(this.onGlobalChange),
      AuthStore.listen(this.onAuthChange),
    ];
  },

  initializeTools() {
    this.initializeAnalytics();
  },

  initializeAnalytics() {
    const currentUser = this.state.user;
    if (currentUser) {
      this.updateSalesforce(currentUser);
    }
  },

  async updateSalesforce(user) {
    if (!!!ahoy) return;
    await $.post("/api/v1/users/analytics", {
      email: user.email,
      ahoy: {
        visit_id: ahoy.visit_id,
        visitor_token: ahoy.visitor_token,
      },
      path: window.location.pathname,
      inspectlet_id: window.__insp ? window.__insp.sid : null,
    });
  },

  componentWillUnmount() {
    if (this.unsubscribeList) {
      this.unsubscribeList.map(fn => fn());
    }
  },

  onGlobalChange() {
    const global = GlobalStore.getState();
    this.setState({ global });
  },

  onAuthChange() {
    const user = AuthStore.getState().user;
    this.setState({ user }, () => {
      this.props.setCurrentUser(user);
      if (user.advertiser) this.props.setAdvertiser(user.advertiser);
      this.initializeTools();
    });
  },

  renderPopup() {
    const { popup } = this.state.global;

    if (popup) {
      let popupElement;

      switch (popup) {
        case "splash":
          popupElement = <Splash />;
          break;
        case "welcome":
          popupElement = <WelcomeModal />;
          break;
        case "unit":
          popupElement = (
            <UnitPopup
              unit={this.state.global.unit}
              user={this.state.user}
              options={this.state.global.popupOptions || {}}
            />
          );
          break;
        case "unit_by_id":
          popupElement = (
            <UnitPopup
              unit_id={this.state.global.popupOptions.unit_id}
              user={this.state.user}
              options={this.state.global.popupOptions || {}}
              campaign={this.state.global.popupOptions.campaign || {}}
            />
          );
          break;
        case "contact_us":
          popupElement = <ContactModal />;
          break;
        case "no_results_found":
          popupElement = <NoResultsFound searchQuery={this.state.global.popupOptions.searchQuery} />;
          break;
        case "campaign_request_sent":
          popupElement = <CampaignRequestSent />;
          break;
        case "add_points_of_interest":
          popupElement = <PointsOfInterestModal />;
          break;
        case "add_credit_card":
          popupElement = <AddCreditCardModal options={this.state.global.popupOptions} />;
          break;
        case "confirm-submit":
          popupElement = <ConfirmSubmitModal options={this.state.global.popupOptions} />;
          break;
        case "save-genius":
          popupElement = <ConfirmGeniusCampaign options={this.state.global.popupOptions} />;
          break;
        case "request-price":
          popupElement = (
            <RequestPriceModal user={this.state.user} campaign={this.state.global.popupOptions.campaign} />
          );
          break;
        case "analytics_setup":
          popupElement = <AnalyticSetupModal popupOptions={this.state.global.popupOptions} />;
          break;
        case "shipping_address":
          popupElement = <ShippingAddressModal popupOptions={this.state.global.popupOptions} />;
          break;
        case "pick_calendar":
          popupElement = <CalendarPickerModal popupOptions={this.state.global.popupOptions} />;
          break;
        case "add_packages":
          popupElement = <AddPackageModal popupOptions={this.state.global.popupOptions} />;
          break;
        case "unit_face_id_selection":
          popupElement = <UnitFaceIdSelectionModal popupOptions={this.state.global.popupOptions} />;
          break;
        case "rfp_upload_images":
          popupElement = <UploadImagesModal popupOptions={this.state.global.popupOptions} />;
          break;
        case "upload_attachment":
          popupElement = <UploadAttachmentModal popupOptions={this.state.global.popupOptions}/>;
          break;
        case "request_note_to_supplier":
          popupElement = <RequestNoteToSupplierModal popupOptions={this.state.global.popupOptions}/>;
          break;
      }

      return popupElement;
    }
  },

  render() {
    const { hideEditUnitModal } = this.props;
    const { cart, user } = this.state;
    const { message, popup, popupOptions } = this.state.global;
    const hideHeader =
      /campaigns\/\w+\/login/.test(window.location.pathname) || /genius/.test(window.location.pathname);

    const BOOTSTRAP_MODALS = [
      "campaign_request",
      "reset-password",
      "login",
      "register",
      "confirm_account",
      "edit_unit",
      "add_contract",
      "edit_contract",
      "compose_email",
      "add_contact",
      "calendar",
      "edit_email",
    ];

    return (
      <React.StrictMode>
        <div className="layout">
          <Icons />
          {message && <Message text={message.text} type={message.type} sticky={message.sticky} />}
          {popup && !BOOTSTRAP_MODALS.includes(popup) && <Overlay>{this.renderPopup()}</Overlay>}

          {this.props.children}

          {popup == "add_contract" && (
            <AddContract
              show={true}
              upload_form={popupOptions.upload_form}
              campaign_token={popupOptions.campaign_token}
              unit_tokens={popupOptions.unit_tokens}
              all_selected={popupOptions.all_selected}
              user={user}
              onHide={() => GlobalActions.closePopup()}
            />
          )}
          {popup == "edit_unit" && (
            <EditUnitModal
              show={true}
              user={user}
              unit={popupOptions.unit}
              initialTab={popupOptions.initialTab}
              campaignId={popupOptions.campaignId}
              onHide={() => hideEditUnitModal()}
            />
          )}
          {popup == "vendor_add_units_modal" && <VendorAddUnitsFromMarkers options={this.state.global.popupOptions} />}
          {popup == "compose_email" && (
            <ComposeEmailModal show={true} options={popupOptions} onHide={() => GlobalActions.closePopup()} />
          )}
          {popup == "edit_email" && (
            <EditEmailModal show={true} options={popupOptions} onHide={() => GlobalActions.closePopup()} />
          )}
          <OnboardingModal
            show={popup == "campaign_request"}
            onHide={() => {
              GlobalActions.closePopup();
            }}
            showPublicViewUnitCopy={popupOptions.showPublicViewUnitCopy}
          />
          <LoginModal
            show={popup == "login"}
            title={popupOptions.title}
            allow_signup={popupOptions.allow_signup}
            force={popupOptions.force}
            returnTo={popupOptions.returnTo}
            onHide={() => {
              GlobalActions.closePopup();
            }}
            onOpenSignup={() => {
              GlobalActions.openPopup("register", popupOptions);
            }}
          />
          <ConfirmAccountModal
            show={popup == "confirm_account"}
            onHide={GlobalActions.closePopup}
            user={this.state.user}
          />
          <SignUpWizard
            show={popup == "register"}
            force={popupOptions.force}
            showSaveTen={popupOptions.showSaveTen}
            returnTo={popupOptions.returnTo}
            onHide={() => {
              GlobalActions.closePopup();
            }}
          />
          <CalendarPopup
            show={popup == "calendar"}
            onHide={popupOptions.onHide}
            onDateSelect={popupOptions.onDateSelect}
            startDate={popupOptions.startDate}
            endDate={popupOptions.endDate}
            isIntersectionStaticKiosk={popupOptions.isIntersectionStaticKiosk}
            title={popupOptions.title}
            confirmButtonLabel={popupOptions.select_text}
          />
        </div>
      </React.StrictMode>
    );
  },
});

const mapStateToProps = ({ campaignRequest, currentUser, sessionId }, ownProps) => ({
  campaignRequest,
  currentUser,
  sessionId,
});

export default connect(mapStateToProps, {
  hideEditUnitModal,
  asyncFetchFeatures,
  setCurrentUser,
  setAdvertiser,
})(Layout);
