import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { loadCampaignDesigns } from "../../../actions/campaign_design_actions";
import GlobalActions from "../../../actions/GlobalActions";
import {
  assignSpecSheet,
  createAndAssignSpecSheet,
  saveUnitSpecSheetVerified,
} from "../../../actions/spec_sheets_actions";
import { SpecSheet } from "../../../models/spec_sheets";
import UploadButton from "../../UploadButton";

interface SpecSheetProps {
  spec_sheets: any[];
  current_spec_sheet: SpecSheet;
  createAndAssignSpecSheet: (spec_sheet: any) => any;
  assignSpecSheet: (unit_id: string | number, spec_sheet_id: string | number) => void;
  afterAssignSpecSheet: (spec_sheet: any) => void;
  reloadDesignAssets: (campaign_id: string) => void;
  loadCampaignDesigns: (campaign_id: string) => void;
  unit_id: string | number;
  campaign: any;
  verified: any;
  loadData: any;
  saveUnitSpecSheetVerified: (unit_id: string | number, data: any) => any;
  is_admin: boolean;
  auditData: {email: string, verified_at: string, file: {comment: string | null, file_url: string}};
}

class SpecSheetTab extends Component<SpecSheetProps, any> {
  constructor(props: SpecSheetProps) {
    super(props);
    const { verified = false } = this.props;
    this.state = {
      is_changing_spec_sheet: false,
      name: "",
      url: "",
      error: null,
      verified: verified,
      saving: false
    };
    this.onSelectSpecSheet = this.onSelectSpecSheet.bind(this);
    this.onSaveSpecSheet = this.onSaveSpecSheet.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.saveSpecSheetVerified = this.saveSpecSheetVerified.bind(this);
  }

  public render() {
    const { current_spec_sheet, spec_sheets = [], is_admin } = this.props;
    const { is_changing_spec_sheet, error } = this.state;
    const spec_sheet_options = spec_sheets.map(s => ({ value: s.id, label: s.comment }));
    const selected = current_spec_sheet && spec_sheet_options.find(o => o.value === current_spec_sheet.id);
    if (!!!current_spec_sheet || is_changing_spec_sheet) {
      return (
        <div style={{ padding: 30 }}>
          {error && <div className="alert alert-danger">{error}</div>}
          <label>Spec Sheet:</label>
          <Select
            name="spec_sheet_id"
            value={selected}
            placeholder="Select an existing spec sheet..."
            isClearable={false}
            isMulti={false}
            onChange={this.onSelectSpecSheet}
            options={spec_sheet_options}
          />

          <p style={{ textAlign: "center", color: "#999999", padding: "20px", marginTop: "10px" }}>-- OR --</p>

          <div className="form-group">
            <label>Name (optional):</label>
            <input
              className="form-control"
              name="name"
              value={this.state.name}
              type="text"
              onChange={e => this.onInputChange("name", e.target.value)}
            />
          </div>
          <div className="form-group row">
            <div className="col-sm-6">
              <label>Spec Sheet URL:</label>
              <input
                className="form-control"
                value={this.state.url}
                name="url"
                type="text"
                onChange={e => this.onInputChange("url", e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <label>Upload:</label>
              <UploadButton onComplete={this.onUploadComplete} isPublic={false} directory="uploads/spec_sheet">
                <button className="btn btn-default" style={{ width: "100%" }} onClick={e => e.preventDefault()}>
                  <i className="fa fa-file-o" aria-hidden="true" style={{ marginRight: 5 }}></i>Click to Upload or Drop
                  File Here
                </button>
              </UploadButton>
            </div>
          </div>
          <div className="form-group" style={{ textAlign: "center", padding: "10px" }}>
            <button
              className="btn btn-primary"
              style={{ width: "40%", margin: "10px", padding: "10px" }}
              onClick={this.onSaveSpecSheet}
            >
              Save
            </button>
            {is_changing_spec_sheet && (
              <button
                className="btn btn-danger"
                style={{ width: "40%", margin: "10px", padding: "10px" }}
                onClick={this.onCancel}
              >
                Cancel
              </button>
            )}
            {is_admin && this.renderVerifiedCheckbox()}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ padding: 30 }}>
          <div id="existing_spec_sheet">
            {current_spec_sheet.comment && (
              <div className="row">
                <label>Spec Sheet:</label> <span>{current_spec_sheet.comment}</span>
              </div>
            )}
            {current_spec_sheet.size && (
              <div className="row">
                <label>Size:</label> <span>{current_spec_sheet.size}</span>
              </div>
            )}
            <div className="row">
              <label>File:</label>{" "}
              <a href={current_spec_sheet.file_url} target="_blank">
                Download
              </a>
            </div>
            <div className="row">
              <button className="btn btn-default" onClick={() => this.setState({ is_changing_spec_sheet: true })}>
                Change
              </button>
            </div>
            {is_admin && this.renderVerifiedCheckbox()}
            {this.renderAuditData()}
          </div>
        </div>
      );
    }
  }

  private renderAuditData() {
    return (
      <div>
        <div className='row'>
          <label>Verified by: </label> <span>{ this.props.auditData.email}</span>
        </div>
        <div className='row'>
          <label>Verified at: </label> <span>{ this.props.auditData.verified_at}</span>
        </div>
      </div>
    )
  }

  private renderVerifiedCheckbox() {
    const { verified, saving } = this.state;

    return (
      <div>
        <div
          className="row form-group"
          style={{
            paddingTop: 10,
            marginTop: 15,
            borderTop: "1px solid #dcdcdc",
          }}
        >
          <label
            className="control-label"
            style={{
              float: "left",
              paddingRight: 10,
            }}
          >
            Spec Sheet Verified
          </label>
          <input
            style={{ height: "auto", width: "auto", marginRight: "10px" }}
            className="form-control"
            type="checkbox"
            name="verified"
            checked={verified}
            onChange={() => this.setState({ verified: !!!verified })}
          />
        </div>
        <div className="row">
          <button
            className="btn btn-default"
            disabled={saving}
            onClick={() => this.setState({ saving: true }, this.saveSpecSheetVerified)}
          >
            Save Verified Status
          </button>
        </div>
      </div>
    );
  }

  private async saveSpecSheetVerified() {
    const { verified: spec_sheet_verified } = this.state;
    const { saveUnitSpecSheetVerified, unit_id, loadData: loadUnitData } = this.props;
    const res = await saveUnitSpecSheetVerified(unit_id, { spec_sheet_verified });
    if (res.errors) {
      GlobalActions.showError(`Error saving unit: ${res.errors.join(",")}`);
    } else {
      loadUnitData();
      GlobalActions.showMessage("Unit details were saved!");
    }
    this.setState({ saving: false });
  }

  private onUploadComplete(upload, file) {
    this.setState({ url: file.url });
  }

  private onCancel() {
    this.setState({ is_changing_spec_sheet: false });
  }

  private async onSaveSpecSheet() {
    const { unit_id } = this.props;
    const { name: comment, url: file_url } = this.state;
    const spec_sheet = { unit_id, comment, file_url };
    try {
      this.validateFileUrl();
      await this.props.createAndAssignSpecSheet(spec_sheet);
      this.afterAssign(spec_sheet);
    } catch (error) {
      this.setState({ error });
    }
  }

  private validateFileUrl() {
    const { url } = this.state;
    try {
      const parsedUrl = new URL(url);
      if (parsedUrl.protocol == 'http:') { throw 'Must be https' }
    } catch (error) {
      throw "Must be a valid spec sheet https url (E.g: https://my-domain.com/file')";
    }
  }

  private onInputChange(attribute, input_value) {
    this.setState({
      [attribute]: input_value,
    });
  }

  private async onSelectSpecSheet(selection) {
    const { spec_sheets, assignSpecSheet, unit_id } = this.props;
    const spec_sheet = spec_sheets.find(s => s.id == selection.value);
    await assignSpecSheet(unit_id, spec_sheet.id);
    this.afterAssign(spec_sheet);
  }

  private afterAssign(spec_sheet) {
    const { campaign, loadCampaignDesigns, afterAssignSpecSheet } = this.props;
    this.setState({ is_changing_spec_sheet: false, name: "", url: "", error: null });
    if (campaign.campaignId) {
      loadCampaignDesigns(campaign.campaign.token);
    }
    afterAssignSpecSheet(spec_sheet);
  }
}

export default connect(
  // @ts-ignore
  ({ campaign }) => ({ campaign }),
  {
    assignSpecSheet,
    createAndAssignSpecSheet,
    loadCampaignDesigns,
    saveUnitSpecSheetVerified,
  },
)(SpecSheetTab);
