import React from 'react'
import { connect } from 'react-redux'
import { put } from '../../utils/api'

class UserSettings extends React.Component {
  constructor(props) {
    super(props)
    const { currentUser } = props

    this.state = {
      linkedin_curl_command: currentUser.linkedin_curl_command,
      encrypted_gmail_password: ''
    }
  }

  submitLinkedinCommand() {
    const { linkedin_curl_command } = this.state
    this.updateUser({ linkedin_curl_command })
  }

  submitGmailPassword() {
    const { encrypted_gmail_password } = this.state
    this.updateUser({ encrypted_gmail_password })
  }

  async updateUser(payload) {
    await put(`/api/v1/users/${this.props.currentUser.id}`, payload)
  }

  render () {
    const { linkedin_curl_command, encrypted_gmail_password } = this.state
    return (
      <div className="user-settings">
        <div className="form-group">
          <label>Linkedin Curl Command</label>
          <textarea 
            className="form-control"
            style={{height:100}}
            value={linkedin_curl_command}
            onChange={(e) => this.setState({ linkedin_curl_command: e.target.value })}
          />
          <button className="btn btn-primary" onClick={() => this.submitLinkedinCommand()}>Submit Linkedin Curl Command</button>
        </div>
        <div className="form-group">
          <label>Gmail password</label>
          <textarea
            className="form-control"
            style={{height:100}}
            value={encrypted_gmail_password}
            onChange={(e) => this.setState({ encrypted_gmail_password: e.target.value })}
          />
          <button className="btn btn-primary" onClick={() => this.submitGmailPassword()}>Submit Gmail Password</button>
        </div>
      </div>
    )
  }
}

const mapProps = ({ currentUser }) => ({
  currentUser
})

const mapActions = {}

export default connect(mapProps, mapActions)(UserSettings)