import Reflux from 'reflux';
import { get, post, put, del } from '../utils/api';
import param from '../utils/objectToQuery';
import moment from 'moment';

import CampaignActions from '../actions/CampaignActions';
import UnitStore from '../stores/UnitStore';
import GlobalActions from '../actions/GlobalActions'

const API_URL = '/api/v1/campaigns';

export default Reflux.createStore({
  listenables: [CampaignActions],

  init() {
    this.state = {
      currentCampaign: null,
      units: [],
      campaigns: [],
      startDate: null,
      endDate: null,
      permissions: {},
      onlyShowRateCard: false,
      hide_prices: false,
      campaignResponse: {}
    };
  },

  onResetCampaign() {
    this.state = {
      units: [],
    };

    this.trigger('campaign:reset');
  },

  getState() {
    return this.state;
  },

  getPermissions() {
    return this.state.permissions;
  },

  setCurrentCampaign(value) {
    this.state.currentCampaign = value
  },

  updateCampaignData(campaign_attrs) {
    const { currentCampaign } = this.state
    this.state.currentCampaign = _.merge(currentCampaign, campaign_attrs)
    this.trigger('campaign:loaded');
  },

  setCampaignData(data) {
    const { campaign, permissions } = data;
    this.state.campaignResponse = data
    this.state.startDate = campaign.start_date && moment(campaign.start_date);
    this.state.endDate = campaign.end_date && moment(campaign.end_date);
    this.state.permissions = permissions;
    this.state.onlyShowRateCard = campaign.only_show_rate_card;
    this.state.hide_prices = campaign.hide_prices;
    this.state.currentCampaign = campaign
    this.trigger('campaign:loaded');
  },

  isCurrentCampaignContracted() {
    return (
      this.state && this.state.currentCampaign && this.state.currentCampaign.status === 'contracted'
    );
  },

  createCampaign(params = {}) {
    let cParams = { campaign: params };
    const createPost = post(API_URL, cParams)
      .then(response => {
        this.trigger('campaign:created', response);
      })
      .catch(response => {
        this.trigger('campaign:created', response);
      });
  },

  async hasAdwords(campaign_id) {
    if (!campaign_id) { return }
    if (this.state.adwordsStatus === false || this.state.adwordsStatus) {
      return this.state.adwordsStatus;
    }

    const { status } = await get(`${API_URL}/${campaign_id}/adwords_status`)
    this.state.adwordsStatus = status;
    return status;
  },

  async preloadCampaign(campaign_id, params, successCallback, failureCallback) {
    const api_base_path = `${API_URL}/${campaign_id}`;

    let campaignGet = get(`${api_base_path}`).catch(failureCallback)

    let preloadQueryString = param({ ...params })
    let preloadGet = get(`${api_base_path}/sidebar?partition=0&${preloadQueryString}`);

    let [campaign, preload] =
      await Promise.all([campaignGet, preloadGet])

    //campaign.units = preload.units

    this.setCampaignData(campaign);
    successCallback(campaign)
  },

  async loadCampaign(campaign_id, params = {}) {
    const sort_by = params.sortBy || ''
    const favorites_only = params.favoritesOnly || ''
    const recommended_only = params.recommendedOnly || ''
    const bounds = params.bounds || ''
    const filters = params.filters || {}
    const availability_status = params.availabilityStatus || {}
    const queryString = param({ ...filters, sort_by, favorites_only, recommended_only, bounds, availability_status })

    const api_base_path = `${API_URL}/${campaign_id}`;
    const { partition_count } = await get(`${api_base_path}/partition_count?${queryString}`)

    const unitGet = [];
    for(let i=0; i< partition_count; i++) {
      unitGet.push(get(`${api_base_path}/sidebar?partition=${i}&${queryString}`));
    }

    let placemarkerGet = get(`/api/v1/campaigns/${campaign_id}/placemarkers`)

    let partsLoaded = 0
    let units = await Promise.all(unitGet).then(part => {
      partsLoaded += 1
      return part.reduce((acc, { units }) => acc.concat(units), [])
    });

    units = _.uniqBy(units, 'id');

    const { recommended, normal } = units.reduce((acc, unit) => {
      if(unit.is_recommended) acc.recommended.push(unit)
      else acc.normal.push(unit)
      return acc;
    }, {recommended: [], normal: []})

    const placemarkers = await placemarkerGet;
    const res = this.state.campaignResponse || {};

    res.units = recommended.concat(normal);
    res.all_units_loaded = true;
    res.points = placemarkers;
    res.groups = {};

    // this.setCampaignData(res);
    params.success(res)
  },

  loadCampaignWithInventoryFiles(campaign_id, params = {}) {
    get(`${API_URL}/${campaign_id}/inventory_files`)
      .then(response => {
        if (params.success) params.success(response)
      })
      .catch(err => {
        if (params.failure) params.failure(err)
      });
  },

  loadCampaigns(params = {}) {
    get(`${API_URL}?supplier_token=${params.supplierToken || ''}`)
      .then(response => {
        if (params.success) params.success(response)
      })
      .catch(err => {
        if (params.failure) params.failure(err)
      });
  },

  loadProposals(params = {}) {
    get('/api/v1/campaigns/proposals')
      .then(response => {
        if (params.success) params.success(response)
      })
      .catch(err => {
        if (params.failure) params.failure(err)
      });
  },

  loadAutocomplete(params = {}) {
    get('/api/v1/campaigns/autocomplete')
      .then(response => {
        this.trigger('campaign:autocompleteLoaded', response)
        this.setState({ campaigns: response.data });
      })
      .catch(err => {
        if (params.failure) params.failure(err)
      });
  },

  getSuppliersByMarkets(campaign_id) {
    get('/api/v1/suppliers', { campaign: { campaign_id } })
      .then(response => {
        this.trigger('campaign:suppliersByMarket', response)
        this.setState({ suppliers: response });
      })
      .catch(err => {
        if (params.failure) params.failure(err)
      });
  },

  removeUnitFromCampaign(campaign_id, unit_id, params = {}) {
    del(API_URL + '/' + campaign_id + '/remove_unit/' + unit_id)
      .then(response => {
        this.trigger('campaign:removedUnit')
        if (params.success) params.success(response)
      })
      .catch(err => {
        if (params.failure) params.failure(err)
      });
  },

  calculateNearestPlaceMarker(campaign_id, params = {}) {
    post(`${API_URL}/${campaign_id}/calculate_nearest_poi`).then(response => {
      if (response.status == "200") {
        GlobalActions.showMessage("The unit distances are being calculated in the background. Please refresh the page in a few seconds.")
      } else if (response.status == "409") {
        GlobalActions.showMessage("The unit distances are still being calculated in the background. Please refresh the page in a few seconds.", "warning")
      } else {
        GlobalActions.showError("There was an error calculating the unit distances");
      }
    })
  }

});
