import React from 'react';
import { Modal } from 'react-bootstrap';
import FlightForm from './FlightForm';
import { connect } from 'react-redux';

class AddFlightsModal extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      user,
      onAddHoldsHide,
      show,
      flightTypes,
      filterByFaceIds,
      onUnitDeselectAll
    } = this.props;
    const selectedLength = this.props.selected_units.length;

    return (
      <Modal show={show} onHide={onAddHoldsHide}>
        <Modal.Header>
          <Modal.Title>{selectedLength === 0 ? 'Select units on inventory page to add flights' : `Add flights to ${selectedLength} units`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add_campaign">
            {show &&
              <FlightForm
                formType={'add'}
                flightTypes={flightTypes}
                filterByFaceIds={filterByFaceIds}
                user={user}
                onUnitDeselectAll={onUnitDeselectAll}
              />
            }
          </div>
        </Modal.Body>
      </Modal>
    )
  }
};

export default connect(
  ({ inventory: { selected_units } }) => {
    return { selected_units };
  }, {}
)(AddFlightsModal);
