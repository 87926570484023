import { areRangesOverlapping, isWithinRange } from "date-fns";
import point_in_polygon from "point-in-polygon";

import { availabilityStatus } from "../../utils/unitStatus";
import NewMapStore from "../../stores/NewMapStore";
import UnitModel from "../../models/unitModel.js";

export default class Filter {
  constructor(constraints) {
    this.constraints = constraints;
  }

  reset() {
    this.filteredUnits = null;
  }

  set draw(draw) {
    this.filteredUnits = null;
    this._draw = draw;
  }

  get draw() {
    return this._draw;
  }

  set unit_ids(ids) {
    this.constraints.unit_ids = ids;
  }

  filter(units) {
    if (this.filteredUnits) {
      return this.filteredUnits;
    }

    if (units && units.features) {
      units = units.features;
    }

    if (!units || !units.length) {
      return units;
    }

    this.selectFilters().forEach(filter => {
      units = units.filter(unit => filter.call(this, unit));
      DEBUG && console.log("after", filter.name, units.length);
    });

    this.filteredUnits = units;
    return units;
  }

  async asyncFilter() {
    const asyncFilters = this.asyncFilters();

    if (!asyncFilters || !asyncFilters.length) return null;

    const unit_promises = asyncFilters.map(async filter => {
      const filtered_unit_ids = await filter.fetch.call(this);
      DEBUG && console.log("matching", filter.name, filtered_unit_ids.length);
      return new Set(filtered_unit_ids);
    });

    const unit_sets = await Promise.all(unit_promises);
    let filtered_unit_ids = unit_sets.pop();
    let unit_set;
    for (unit_set of unit_sets) {
      filtered_unit_ids = new Set([...filtered_unit_ids].filter(u => unit_set.has(u)));
    }

    return filtered_unit_ids;
  }

  selectFilters() {
    const filters = [];
    if (this.constraints.unit_ids) filters.push(unit => this.byUnitIds(this.constraints.unit_ids, unit));
    if (this.constraints.tags && this.constraints.tags.length) filters.push(this.byTag);
    if (this.constraints.unit_tag) filters.push(this.byAttributes);
    if (this.constraints.only_units_with_pricing) filters.push(this.withPrice);
    if (this.constraints.only_units_without_pricing) filters.push(u => !this.withPrice(u));
    if (this.constraints.price_greater_than_rate_card) filters.push(u => this.withPriceGreaterThanRateCard(u));
    if (this.constraints.only_units_with_rate_card_pricing) filters.push(this.withRateCardPrice);
    if (this.constraints.only_units_with_picture) filters.push(this.withPicture);
    if (this.constraints.only_units_without_picture) filters.push(u => !this.withPicture(u));
    if (this.constraints.only_units_without_tags) filters.push(u => this.withoutTags(u));
    if (this.constraints.min_price) filters.push(this.minPrice);
    if (this.constraints.max_price) filters.push(this.maxPrice);
    if (this.constraints.min_poi_distance) filters.push(this.minPoiDistance);
    if (this.constraints.max_poi_distance) filters.push(this.maxPoiDistance);
    if (this.constraints.min_cpm) filters.push(this.minCpm);
    if (this.constraints.max_cpm) filters.push(this.maxCpm);
    if (this.constraints.min_diff) filters.push(this.minDiff);
    if (this.constraints.max_diff) filters.push(this.maxDiff);
    if (this.constraints.min_rate_card) filters.push(this.minRateCard);
    if (this.constraints.max_rate_card) filters.push(this.maxRateCard);
    if (this.constraints.only_favorites) filters.push(this.onlyFavorites);
    if (this.constraints.only_recommended) filters.push(this.onlyRecommended);
    if (this.constraints.only_in_cart) filters.push(this.onlyInCart);
    if (this.constraints.only_cannabis_friendly) filters.push(this.onlyCannabisFriendly);
    if (this.constraints.supplier) filters.push(this.bySupplier);
    if (this.constraints.previous_advertiser) filters.push(this.byPreviousAdvertiser);
    if (this.constraints.packages_only) filters.push(this.isPackage);
    if (this.constraints.exclude_packages) filters.push(u => !this.isPackage(u));
    if (this.constraints.exclude_units_with_restrictions) filters.push(this.withoutRestrictions);
    if (this.constraints.has_managed_supplier) filters.push(this.hasManagedSupplier);
    if (this.constraints.has_broker_supplier) filters.push(this.hasBrokerSupplier);
    if (this.constraints.has_not_broker_supplier) filters.push(this.withoutBrokerSupplier);
    if (this.constraints.has_margin_agreement) filters.push(this.hasMarginAgreement);
    if (this.constraints.availability_status) filters.push(this.byAvailabilityStatus);
    if (this.constraints.face_id) filters.push(this.byFaceId);
    if (this.constraints.search_by_face_id) filters.push(this.searchByFaceId);
    if (this.constraints.search_by_tab_panel_id) filters.push(this.searchByTabPanelId);
    if (this.constraints.size) filters.push(this.unitSize);
    if (this.constraints.orientation) filters.push(this.byOrientation);
    if (this.constraints.geopath_audited) filters.push(this.withGeopathAudited);
    if (this.constraints.exclude_favorites) filters.push(this.excludeFavorites);
    if (this.constraints.exclude_recommended) filters.push(this.excludeRecommended);
    if (this.constraints.no_attribution) filters.push(this.noAttribution);
    if (this.constraints.has_attribution && this.constraints.unknown_attribution) {
      filters.push(this.hasAttributionOrUnknownAttribution);
    } else if (this.constraints.has_attribution) {
      filters.push(this.hasAttribution);
    } else if (this.constraints.unknown_attribution) {
      filters.push(this.unknownAttribution);
    }
    if (this.constraints.aq_verified_attribution) filters.push(this.aqVerifiedAttribution);
    if (this.constraints.audience_enabled) filters.push(this.byAudienceUnits)
    if (this.constraints.instant_book) filters.push(this.instantBookOnly)
    if (this.types()) filters.push(this.byType);
    if (this.subtypes()) filters.push(this.bySubtype);
    if (this.constraints.only_booked || this.constraints.only_not_booked) filters.push(this.byBookingState);
    if (this.constraints.booked || this.constraints.held || this.constraints.movable || this.constraints.proposed) filters.push(this.byWorkflowState);
    if (this.screenTypes()) filters.push(this.byScreenType);
    if (this.screenSubtypes()) filters.push(this.byScreenSubtype)
    if (this.constraints.startDate && this.constraints.search_within_campaign) filters.push(this.byDate);
    if (this.markets()) filters.push(this.byMarket)
    if (this.draw || this.constraints.draw) filters.push(this.insideDraw);
    if (this.constraints.health_score_status) filters.push(this.byHealthScore);

    return filters;
  }

  asyncFilters() {
    const filters = [];

    if (this.constraints.startDate && !this.constraints.search_within_campaign) {
      filters.push({ name: "byDate", fetch: this.asyncFilterByDate });
    }
    if (this.constraints.on_hold) {
      filters.push({ name: "onlyOnHold", fetch: this.loadOnlyOnHold });
    }
    if (this.constraints.advertiser) {
      filters.push({ name: "byAdvertiser", fetch: this.asyncFilterByAdvertiser });
    }
    if (this.constraints.campaign) {
      filters.push({ name: "byCampaign", fetch: this.asyncFilterByCampaign });
    }
    if (this.constraints.browse_orientation) {
      filters.push({ name: "byOrientation", fetch: this.asyncFilterByOrientation });
    }

    return filters;
  }

  // Used for browse. For campaigns, we use client-side filtering. See `byDate`
  async asyncFilterByDate() {
    const { startDate, endDate, flight_types, partial_date_match, include_holds } = this.constraints;
    return await NewMapStore.filterByDate(startDate, endDate, flight_types, partial_date_match, include_holds);
  }

  async loadAvailableUnitsOnly() {
    return await NewMapStore.onlyAvailableUnits();
  }

  async asyncFilterByAdvertiser() {
    const { advertiser } = this.constraints;
    return await NewMapStore.filterByAdvertiser(advertiser);
  }

  async asyncFilterByCampaign() {
    const { campaign } = this.constraints;
    return await NewMapStore.filterByCampaign(campaign);
  }

  async asyncFilterByOrientation() {
    const { browse_orientation } = this.constraints;
    return await NewMapStore.filterByOrientation(browse_orientation);
  }

  async loadOnlyOnHold() {
    return await NewMapStore.onHoldUnits(this.constraints.campaign);
  }

  // Client-side filtering for Campaign.
  byDate(unit) {
    const { partial_date_match } = this.constraints;
    if (partial_date_match) {
      return this.partialDateMatch(unit);
    } else {
      return this.fullDateMatch(unit);
    }
  }

  fullDateMatch(unit) {
    const { startDate, endDate } = this.constraints;

    // toDate is required since these are moment objects
    const unitStartDate = unit.start_date.toDate();
    const unitEndDate = unit.end_date.toDate();

    const startDateIsWithinRange = isWithinRange(startDate, unitStartDate, unitEndDate);
    const endDateIsWithinRange = isWithinRange(endDate, unitStartDate, unitEndDate);
    return startDateIsWithinRange && endDateIsWithinRange;
  }

  partialDateMatch(unit) {
    if (!(unit.start_date && unit.end_date)) return false;

    const { startDate, endDate } = this.constraints;
    if (!(startDate && endDate)) { return false }
    return areRangesOverlapping(startDate, endDate, unit.start_date.toDate(), unit.end_date.toDate());
  }

  onlyFavorites(unit) {
    return unit.is_favorited;
  }

  onlyRecommended(unit) {
    return unit.is_recommended;
  }

  onlyInCart(unit) {
    return unit.in_cart;
  }

  excludeFavorites(unit) {
    return !unit.is_favorited;
  }

  excludeRecommended(unit) {
    return !unit.is_recommended;
  }

  hasAttribution(unit) {
    return unit.has_attribution;
  }

  noAttribution(unit) {
    if (unit.screen_type != 'static' || [4,9,14].includes(unit.media_type_id)) {
      return unit.has_attribution == false;
    } else if (unit.supplier.has_attribution == null && unit.has_attribution == false) {
      return unit.has_attribution == false;
    } else if (unit.supplier.has_attribution == false && unit.has_attribution == null) {
      return unit.has_attribution == false;
    } else if (unit.supplier.has_attribution == false && unit.has_attribution == false) {
      return unit.has_attribution == false;
    }
  }

  unknownAttribution(unit) {
    if (unit.supplier.has_attribution == null && unit.has_attribution == null && unit.screen_type != 'static') {
      return unit.has_attribution == null;
    }
  }

  hasAttributionOrUnknownAttribution(unit) {
    if (unit.has_attribution) {
      return true;
    } else if (unit.supplier.has_attribution == null && unit.has_attribution == null && unit.screen_type != 'static') {
      return unit.has_attribution == null;
    }
  }

  aqVerifiedAttribution(unit) {
    return unit.aq_verified_attribution;
  }

  byAudienceUnits(unit) {
    const { audience_units } = this.constraints
    return audience_units.includes(unit._id)
  }

  instantBookOnly(unit) {
    return unit.instant_book
  }

  byWorkflowState(unit) {
    const { booked, held, movable, proposed } = this.constraints;
    const filters = {
      booked,
      on_hold: held,
      movable,
      proposed
    };
    return filters[unit.workflow_state];
  }

  byBookingState(unit) {
    const { only_booked, only_not_booked } = this.constraints;
    if (only_booked && only_not_booked) {
      return true;
    } else if (only_booked) {
      return ["booking_approved", "booking_adjusted"].includes(unit.supplier_status);
    } else {
      return unit.supplier_status != 'booking_approved';
    }
  }

  onlyCannabisFriendly(unit) {
    return unit.is_cannabis_friendly;
  }

  byAvailabilityStatus(unit) {
    const status = this.constraints.availability_status;
    if (!status) {
      return true;
    }
    if (status == "not_requested") {
      return unit.supplier_status == null || unit.supplier_status == "";
    }
    return availabilityStatus[status].includes(unit.supplier_status);
  }

  byUnitIds(unitIds, unit) {
    return unitIds.has(unit.id);
  }

  byTag(unit) {
    if (_.isEmpty(unit.tags) && !this.constraints.tagsNegativeSearch) return false;
    const unitTags = unit.tags.map(t => t.tag);
    const constraintTags = this.constraints.tags.map(t => t.tag);
    if (this.constraints.useAnd) {
      if (this.constraints.tagsNegativeSearch) {
        return _.difference(constraintTags, unitTags).length;
      } else {
        return !_.difference(constraintTags, unitTags).length;
      }
    } else {
      if (this.constraints.tagsNegativeSearch) {
        return !_.intersection(unitTags, constraintTags).length
      } else {
        return _.intersection(unitTags, constraintTags).length;
      }
    }
  }

  byAttributes(unit) {
    return unit.attributes && unit.attributes.find(a => this.constraints.unit_tag.includes(a));
  }

  insideDraw(unit) {
    const draw = this.draw || this.constraints.draw;
    const geo = [unit.lon, unit.lat];
    return this.in_polygon(geo, draw);
  }

  byHealthScore(unit) {
    return unit.score_status == this.constraints.health_score_status
  }

  in_polygon(point, polygon) {
    return point_in_polygon(point, polygon);
  }

  byType(unit) {
    return this.types().includes(unit.unit_type);
  }

  byMarket(unit) {
    return this.markets().includes(unit.dma_id);
  }

  bySubtype(unit) {
    return this.subtypes().includes(unit.unit_subtype);
  }

  byScreenType(unit) {
    // if unit doesn't have a screen type assume static
    if (!!!unit.screen_type || !!!unit.screen_type.length) return this.screenTypes().includes("static");
    return this.screenTypes().includes(unit.screen_type);
  }

  byScreenSubtype(unit) {
    if (_.isEmpty(this.screenSubtypes())) {
      return true
    }
    return this.screenSubtypes().includes(unit.unit_screen_subtype);
  }

  bySupplier(unit) {
    return this.constraints.supplier.includes(unit.supplier) ||
      this.constraints.supplier.includes(unit.alt_supplier_name);
  }

  byPreviousAdvertiser(unit) {
    const { previous_advertiser_ids } = unit;
    if(_.isEmpty(previous_advertiser_ids)) { return false; }
    const intersection = _.intersection(previous_advertiser_ids, this.constraints.previous_advertiser);
    return !_.isEmpty(intersection);
  }

  byFaceId(unit) {
    const face_id = this.constraints.face_id;
    const safe_search = face_id.replace("\\", "");
    if (safe_search.split(",").length > 1) {
      const face_ids = safe_search.split(",").map(fid => fid.trim());
      return !!unit.supplier_face_id && face_ids.includes(unit.supplier_face_id);
    }
    return !!unit.supplier_face_id && !!unit.supplier_face_id.match(new RegExp(`${safe_search}`, "ig"));
  }

  searchByFaceId(unit) {
    const { search_by_face_id, is_mock, hide_face_ids } = this.constraints;
    const ids = search_by_face_id.split(/,|\s/).map(s => s.trim()).filter(s => s !== "")
    const useUnitIdInsteadOfFaceId = is_mock || hide_face_ids;
    if (useUnitIdInsteadOfFaceId) {
      return ids.some(id => unit._id.toString().toLowerCase().includes(id.toLowerCase()))
    }
    return !!unit.supplier_face_id && ids.some(id => unit.supplier_face_id.toLowerCase().includes(id.toLowerCase()))
  }

  searchByTabPanelId(unit) {
    const { search_by_tab_panel_id, is_mock, hide_face_ids } = this.constraints;
    const ids = search_by_tab_panel_id.split(/,|\s/).map(s => s.trim()).filter(s => s !== "")
    const useUnitIdInsteadOfFaceId = is_mock || hide_face_ids;
    if (useUnitIdInsteadOfFaceId) {
      return ids.some(id => unit._id.toString().toLowerCase().includes(id.toLowerCase()))
    }
    return !!unit.tab_panel_id && ids.some(id => unit.tab_panel_id.toString().includes(id.toString()))
  }

  isPackage(unit) {
    return unit.isPackage;
  }

  withoutRestrictions(unit) {
    return !unit.hasRestrictions;
  }

  hasManagedSupplier(unit) {
    return unit.is_managed;
  }

  hasBrokerSupplier(unit) {
    return unit.is_broker;
  }

  withoutBrokerSupplier(unit) {
    return !unit.is_broker;
  }

  hasMarginAgreement(unit) {
    return unit.has_margin_agreement;
  }

  withPrice(unit) {
    return (!!unit.label && parseFloat(unit.label) > 0) || unit.lower_price || unit.higher_price;
  }

  withPriceGreaterThanRateCard(unit) {
    return unit.price > unit.rate_card_price
  }

  withRateCardPrice(unit) {
    return !!unit.rate_card && parseFloat(unit.rate_card) > 0;
  }

  withPicture(unit) {
    return unit && unit.picture && unit.picture.indexOf("uploads/unit_image/defaults") === -1;
  }

  withoutTags(unit) {
    return _.isEmpty(unit.tags);
  }

  withGeopathAudited(unit) {
    return unit.tab_panel_id != null;
  }

  minPrice(unit) {
    const price = unit.price;
    return price && this.parsePrice(price) >= parseInt(this.constraints.min_price, 10);
  }

  maxPrice(unit) {
    const price = unit.price;
    return price && this.parsePrice(price) <= parseInt(this.constraints.max_price, 10);
  }

  minPoiDistance(unit) {
    const poiDistance = unit.distance_from_point_of_interest;
    return poiDistance && poiDistance >= this.constraints.min_poi_distance;
  }

  maxPoiDistance(unit) {
    const poiDistance = unit.distance_from_point_of_interest;
    return poiDistance && poiDistance <= this.constraints.max_poi_distance;
  }

  minCpm(unit) {
    let cpm = unit.cpm;
    if (!cpm) {
      const mock_values = new UnitModel(unit).getMockCpmValues();
      // NOTE: this implementes a very exclusive filter
      cpm = mock_values && mock_values.min_cpm;
    }
    return cpm && cpm >= parseFloat(this.constraints.min_cpm);
  }

  maxCpm(unit) {
    let cpm = unit.cpm;
    if (!cpm) {
      const mock_values = new UnitModel(unit).getMockCpmValues();
      // NOTE: this implementes a very exclusive filter
      cpm = mock_values && mock_values.max_cpm;
    }
    return cpm && cpm <= parseFloat(this.constraints.max_cpm);
  }

  minDiff(unit) {
    const { next_booked_diff } = unit;
    return next_booked_diff && next_booked_diff >= this.constraints.min_diff;
  }

  maxDiff(unit) {
    const { next_booked_diff } = unit;
    return next_booked_diff && next_booked_diff <= this.constraints.max_diff;
  }

  minRateCard(unit) {
    const { rate_card } = unit;
    return rate_card && this.parsePrice(rate_card) >= parseInt(this.constraints.min_rate_card, 10);
  }

  maxRateCard(unit) {
    const { rate_card } = unit;
    return rate_card && this.parsePrice(rate_card) <= parseInt(this.constraints.max_rate_card, 10);
  }

  parsePrice(price) {
    if (price.replace) {
      price = parseInt(price.replace("K", "000"), 10);
    }
    return price;
  }

  screenTypes() {
    if (!this.screenTypeList) {
      this.screenTypeList = this.constraints.screen_type ? this.constraints.screen_type : null;
    }
    return this.screenTypeList;
  }

  screenSubtypes() {
    if (!this.screenSubtypeList) {
      this.screenSubtypeList = this.constraints.unit_screen_subtype ? this.constraints.unit_screen_subtype : null;
    }
    return this.screenSubtypeList;

  }

  types() {
    if (!this.typeList) {
      this.typeList = this.constraints.type && this.constraints.type.length ? this.constraints.type : null;
    }
    return this.typeList;
  }

  subtypes() {
    if (!this.subtypeList) {
      this.subtypeList = this.constraints.subtypes && this.constraints.subtypes.length ? this.constraints.subtypes : null;
    }
    return this.subtypeList;
  }

  markets() {
    if (!this.marketList) {
      this.marketList = this.constraints.markets && this.constraints.markets.length ? this.constraints.markets : null;
    }
    return this.marketList;
  }

  unitSize(unit) {
    return this.constraints.size.includes(unit.size);
  }

  byOrientation(unit) {
    return unit.orientation === this.constraints.orientation;
  }
}
