import { connect } from 'react-redux'
import React from 'react';
import { loadCampaignUnits } from '../../Campaign/actions';
import { sidebarUnitsWithNoOp } from '../../../models/Units';
import AuthStore from '../../../stores/AuthStore';
import CampaignUnitList from './CampaignUnitList'
import GlobalActions from '../../../actions/GlobalActions';
import MassUpdateModal from './MassUpdateModal';
import FiltersBar from '../../Filters/FiltersBar';
import { post } from "../../../utils/api";
import JobProgress from "../../../utils/JobProgress";
import {
  filterUnits,
  sortUnits,
} from '../../../actions/UnitsActions';
import { removeCampaignUnits } from "../actions";

class CampaignUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedUnitIds: [],
      removingUnitIds: [],
      allSelected: false,
      showModal: null,
      job_pooling: new JobProgress()
    };
    this.isUnitChecked = this.isUnitChecked.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    this.sendUnitRequests = this.sendUnitRequests.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.resetSelectAll = this.resetSelectAll.bind(this);
    this.units = this.units.bind(this);
    this.onGenerateNewContract = this.onGenerateNewContract.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.renderHelperMessage = this.renderHelperMessage.bind(this);
    this.revertProductionCost = this.revertProductionCost.bind(this);
    this.bulkRemoveUnits = this.bulkRemoveUnits.bind(this);
    this.removeCampaignUnits = this.removeCampaignUnits.bind(this);
    this.onRemoveCampaignUnitsComplete = this.onRemoveCampaignUnitsComplete.bind(this);
    this.markCampaignUnitsAsBooked = this.markCampaignUnitsAsBooked.bind(this);
  }

  handleCheckBoxClick(campaignUnitId, checked) {
    const { checkedUnitIds } = this.state;
    const { units } = this.props;

    if (checked) {
      this.setState({ checkedUnitIds: checkedUnitIds.filter((id) => id !== campaignUnitId), allSelected: false });
    } else {
      this.setState({ checkedUnitIds: checkedUnitIds.concat(campaignUnitId) });
      if (sidebarUnitsWithNoOp(units).length === checkedUnitIds.concat(campaignUnitId).length) {
        this.setState({allSelected: true});
      }
    }
  }

  sendUnitRequests(type, noteToVendor) {
    const { checkedUnitIds } = this.state
    const { data, loadCampaignUnits } = this.props;
    const campaignId = data.campaign.token
    const params = {
      campaign_id: campaignId,
      campaign_unit_tokens: checkedUnitIds,
      type: type,
      note_to_vendor: noteToVendor
    }
    const message_type = type === "initial" ? "unit" : type;
    GlobalActions.showMessage(`Sending ${message_type} requests...`);
    this.setState({ isRequesting: true }, () => {
      $.post(`/api/v1/campaigns/${campaignId}/unit_requests`, params, (response) => {
        loadCampaignUnits(campaignId);
        GlobalActions.showMessage(`Your ${message_type} requests have been sent!`);;
      }).fail((data) => {
        if (data.responseJSON && data.responseJSON.error) {
          GlobalActions.showError('There was an error: ' + data.responseJSON.error);
        } else {
          GlobalActions.showError('There was a problem requesting those units. Talk to an engineer.');
        }
        throw data;
      }).always(() => {
        this.setState({ isRequesting: false });
      });
    })
  }

  openRequestNoteModal(requestType) {
    GlobalActions.openPopup(
      "request_note_to_supplier",
      {
        sendUnitRequests: this.sendUnitRequests.bind(this),
        requestType
      }
    )
  }

  isUnitChecked(unit_id) {
    const { checkedUnitIds } = this.state;
    return checkedUnitIds.includes(unit_id);
  }

  resetSelectAll() {
    this.setState({ checkedUnitIds: [], allSelected: false })
  }

  handleSelectAllClick() {
    const { checkedUnitIds } = this.state;
    const units = this.units();
    if (units.length === checkedUnitIds.length) {
      this.setState({ checkedUnitIds: [], allSelected: false })
    } else {
      const allIds = units.map(unit => unit.campaign_unit_token);
      this.setState({ checkedUnitIds: allIds, allSelected: true });
    }
  }

  units() {
    const { units } = this.props
    return sidebarUnitsWithNoOp(units)
  }

  unitIdsForSelectedUnits() {
    const { checkedUnitIds } = this.state
    return this.units().filter(u => checkedUnitIds.includes(u.campaign_unit_token)).map(u => u.id)
  }

  onGenerateNewContract() {
    const { data } = this.props;
    const campaign_token = data.campaign.token;
    const { checkedUnitIds } = this.state;
    GlobalActions.openPopup("add_contract", { campaign_token, unit_tokens: checkedUnitIds, all_selected: false });
  }

  renderHelperMessage() {
    const search = window.location.search;

    if (search && search.includes('?contracting=true')) {
      return (
        <div className="help-message-area">
          <div className="help-steps">
            <p className="help-step">
              <b>1.</b> Select the units you would like on the contract.
            </p>
            <p className="help-step">
              <b>2.</b> Choose <span className="button-name">Generate New Contract</span> from the <span className="button-name">Actions</span> menu to the right.
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div></div>
      );
    }
  }

  onFilter(filters) {
    this.setState({ filters })
    this.props.filterUnits({
      ...filters,
      only_favorites: this.state.favoritesOnly,
      only_recommended: this.state.recommendedOnly,
      is_mock: this.props.data.campaign.is_mock,
      hide_face_ids: this.props.data.campaign.hide_face_ids
    });
  }

  onSort(value) {
    this.setState({ sortBy: value });
    this.props.sortUnits({ sort_by: value });
  }

  async revertProductionCost() {
    const { data: { campaignId, campaign: { token } } } = this.props;
    const { checkedUnitIds } = this.state;
    const payload = {
      campaign_id: token,
      unit_ids: checkedUnitIds
    };
    try {
      await post("/api/v1/campaign_units/revert_production_cost", payload);
      this.props.loadCampaignUnits(campaignId);
      GlobalActions.showMessage("Production Cost reverted to original cost");
    } catch (error) {
      GlobalActions.showError("Something went wrong");
      console.log(error);
    }
  }

  bulkRemoveUnits() {
    const { checkedUnitIds } = this.state
    if (confirm(`Are you sure you want to remove ${checkedUnitIds.length} units?`)) {
      const unit_ids = this.unitIdsForSelectedUnits()
      return this.removeCampaignUnits(unit_ids)
    }
  }

  removeCampaignUnits(unit_ids) {
    const { campaignId } = this.props.data
    this.setState({ removingUnitIds: unit_ids }, async () => {
      const { job_id } = await $.post(`/api/v1/campaigns/${campaignId}/batch_remove_units`, { unit_ids: this.state.removingUnitIds })
      this.state.job_pooling.startPolling(job_id, this.onRemoveCampaignUnitsComplete)
    })
  }

  onRemoveCampaignUnitsComplete({ data }) {
    const { removingUnitIds } = this.state
    const { removeCampaignUnits } = this.props;
    const { removed_count, error } = data;
    if(error) { return GlobalActions.showError(error); }
    GlobalActions.showMessage(`${removed_count} unit(s) removed.`);
    removeCampaignUnits(removingUnitIds);
    this.setState({ removeCampaignUnits: [] })
  }

  markCampaignUnitsAsBooked() {
    this.onMarkCampaignUnitsAsBooked()
  }

  async onMarkCampaignUnitsAsBooked() {
    const { checkedUnitIds } = this.state
    const { data: { campaign } } = this.props
    const payload = { campaign_unit_tokens: checkedUnitIds }
    try {
      await post(`/api/v1/campaigns/${campaign.token}/campaign_units/batch_book_from_manage`, payload)
      await this.props.loadCampaignUnits(campaign.token)
      GlobalActions.showMessage(`${checkedUnitIds.length} unit(s) marked as booked.`)
    } catch (error) {
      GlobalActions.showError("An error occurred.");
    }
  }

  render() {
    const { isSaving, checkedUnitIds, allSelected, showModal } = this.state;
    const { data, onUnitListingClick } = this.props;
    const { campaign } = data;
    const campaign_permissions = data.permissions;
    const { user } = AuthStore.getState();
    let groupActionsClassNames = "btn btn-default dropdown-toggle";
    if (checkedUnitIds.length === 0) groupActionsClassNames += " disabled";

    return (
      <div>
        <FiltersBar
          onSort={(value) => this.onSort(value)}
          onFilter={this.onFilter}
          activeFilters={this.props.units.unit_filter}
          isBrowseView={false}
          resetSelectAll={this.resetSelectAll}
        />
        <div className="createCampaign campaign_units_edit" style={{ marginTop: 10 }}>
          <div className="row" style={{ marginTop: 20, marginBottom: 10 }}>
            <div className="col-sm-4">
              <div style={{ fontSize: 21, fontWeight: 600 }}>Customize Price and Dates</div>
            </div>
            <div className="col-sm-8 text-right">
              {this.renderHelperMessage()}
              {campaign_permissions.can_perform_edit_units_actions &&
                <div className="btn-group" style={{ paddingRight: 10 }}>
                  <button type="button" className={groupActionsClassNames} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Actions <span className="caret"></span>
                  </button>
                  <ul className="dropdown-menu">
                    {campaign_permissions.can_send_unit_requests &&
                      <li><a onClick={() => this.openRequestNoteModal("initial")}>Request Units Avails</a></li>
                    }
                    {campaign_permissions.can_send_hold_requests &&
                      <li><a onClick={() => this.openRequestNoteModal("hold")}>Request Holds</a></li>
                    }
                    <li><a onClick={() => this.setState({ showModal: 'mass_update' })}>Update Dates</a></li>
                    {user.is_admin &&
                      <li><a onClick={() => this.setState({ showModal: 'spec_sheet_mass_update' })}>Update Spec Sheets</a></li>
                    }
                    {user.is_admin &&
                      <li><a onClick={() => this.setState({ showModal: 'campaign_unit_pricing_mass_update' })}>Update Pricing</a></li>
                    }
                    {(user.is_admin || user.is_agency) &&
                      <li><a onClick={this.bulkRemoveUnits}>Remove Units</a></li>
                    }
                    {(user.is_admin || user.is_agency) &&
                      <li><a onClick={() => this.setState({ showModal: 'production_cost_mass_update' })}>Normalize Static Billboard Production</a></li>
                    }
                    {campaign_permissions.can_book_unit_from_campaign_manage &&
                      <li><a onClick={this.markCampaignUnitsAsBooked}>Mark Selected Units as Booked</a></li>
                    }
                    {user.is_admin &&
                      <li><a onClick={this.revertProductionCost}>Revert Production Cost</a></li>
                    }
                    {campaign_permissions.can_generate_contract &&
                      <li><a onClick={this.onGenerateNewContract}>Generate New Contract</a></li>
                    }
                  </ul>
                </div>
              }

              {user.is_admin &&
                <button className="btn btn-default" style={{ marginRight: 10 }}>
                  <a href={`/admin/campaigns/${campaign.token}/vendor_contacts`}>
                    Assign Vendor Contacts
                </a>
                </button>
              }

              {user.is_admin &&
                <button className="btn btn-default" style={{ marginRight: 10 }}>
                  <a href={`/admin/campaigns/${campaign.token}/assign_spec_sheets`}>
                    Assign Spec Sheets
                </a>
                </button>
              }

            </div>
          </div>
          {user.is_admin && campaign.workflow_state == 'purchased' &&
            "Admin note: We can show units on a booked campaign once we do the work to make price changes flow through to the invoice."
          }

          {campaign_permissions.can_perform_edit_units_actions &&
            <div className="action_row">
              <label>
                <span style={{ display: 'inline-block', marginRight: 10 }}><input
                    type="checkbox"
                    checked={allSelected}
                    onClick={this.handleSelectAllClick}
                /></span>
                <span style={{ fontWeight: 600 }}>Select All</span>
              </label>
            </div>
          }

          {campaign_permissions.can_edit_units_on_purchased_campaigns &&
            <CampaignUnitList
              units={this.units()}
              handleCheckBoxClick={this.handleCheckBoxClick}
              checkedStatus={this.isUnitChecked}
              campaign_token={campaign.token}
              campaign_permissions={campaign_permissions}
              onUnitListingClick={onUnitListingClick}
              removeCampaignUnits={this.removeCampaignUnits}
            />
          }

          {showModal == 'mass_update' &&
            <MassUpdateModal
              campaign_token={campaign.token}
              unit_ids={checkedUnitIds}
              show={true}
              onHide={() => this.setState({showModal: null})}
              modal={"mass_update"}
            />
          }

          {showModal == 'spec_sheet_mass_update' &&
            <MassUpdateModal
              campaign_token={campaign.token}
              unit_ids={checkedUnitIds}
              show={true}
              onHide={() => this.setState({showModal: null})}
              modal={"spec_sheet_mass_update"}
            />
          }

          {showModal == 'production_cost_mass_update' &&
            <MassUpdateModal
              campaign_token={campaign.token}
              unit_ids={checkedUnitIds}
              show={true}
              onHide={() => this.setState({showModal: null})}
              modal={"production_cost_mass_update"}
            />
          }

          {showModal == 'campaign_unit_pricing_mass_update' &&
            <MassUpdateModal
              campaign_token={campaign.token}
              unit_ids={checkedUnitIds}
              show={true}
              onHide={() => this.setState({ showModal: null })}
              modal={"campaign_unit_pricing_mass_update"}
            />
          }
        </div>
      </div>
    );
  }
};

export default connect( ({ units }) => ({ units }), { loadCampaignUnits, filterUnits, sortUnits, removeCampaignUnits })(CampaignUnits)
