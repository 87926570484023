import { connect } from 'react-redux';
import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';

import { Market, MarketGeojson } from '../../models/market';
import { loadAllUnits } from '../../actions/UnitsActions';
import { mapMarkets } from '../../models/markets';
import { updateMapPosition } from './actions';
import { updateMarket } from '../../actions/current_market_actions';
import MapBox from './MapBox';
import MarketPopup from './MarketPopup';

type Props = {
  markets: Market[];
  mapbox: MapBox;
  current_market: number | null;
  loadAllUnits: typeof loadAllUnits;
  updateMarket: typeof updateMarket;
  updateMapPosition: typeof updateMapPosition;
}

const DEBUG = (window as any).DEBUG;

const source = 'markets-overview'
const markerLayer = 'markets-marker'
const nameLayer = 'markets-name'

class Markets extends Component<Props, {}> {
  map: mapboxgl.Map;
  popup: MarketPopup;
  loaded: boolean = false;

  async componentDidMount() {
    this.map = this.props.mapbox.map;
    this.popup = new MarketPopup(this.map);

    await this.props.mapbox.waitMapLoad();
    this.setupMarkets();
    this.loaded = true;
  }

  componentDidUpdate() {
    if (!this.loaded) return;

    this.addMarkerLayer();
    this.addNameLayer();
  }

  addSource() {
    DEBUG && console.log({markets: mapMarkets(this.props.markets)});
    this.props.mapbox.setSource(source, mapMarkets(this.props.markets));
  }

  addMarkerLayer() {
    this.removeLayer(markerLayer)

    this.map.addLayer({
      id: markerLayer,
      type: 'circle',
      filter: this.layersFilter(),
      source,
      paint: {
        'circle-color': '#44AC6B',
        'circle-radius': {stops: [[8, 7], [9, 8], [10, 10], [14, 12]]},
        'circle-stroke-width': 2,
        'circle-stroke-color': '#FFFFFF'
      }
    });
  }

  addNameLayer() {
    this.removeLayer(nameLayer)

    this.map.addLayer({
      id: nameLayer,
      type: 'symbol',
      filter: this.layersFilter(),
      source,
      layout: {
        "text-field": "{name}",
        "text-font": ["Inter Bold"],
        "text-size": {stops: [[8, 11], [10, 13], [14, 15]]},
        "text-allow-overlap": false,
        "text-anchor": 'top',
        "text-offset": [
          'interpolate',
          ['linear'],
          ['zoom'],
          8, ['literal', [0.0, 0.7]],
          10, ['literal', [0.0, 0.8]],
          14, ['literal', [0.0, 0.9]]
        ]
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#FFF",
        "text-halo-width": 2
      }
    });
  }

  layersFilter() {
    if (!this.props.current_market) return ['has', 'name']
    return ['!=', 'id', this.props.current_market]
  }

  removeLayer(layerId) {
    if (this.map.getLayer(layerId)) this.map.removeLayer(layerId);
  }

  setupMarkets() {
    this.addSource();
    this.addMarkerLayer();
    this.addNameLayer();
    this.clickToMarket();
  }

  clickToMarket() {
    this.map.on('click', markerLayer, this.moveToMarket.bind(this));
    this.handleCursor();
    this.handlePopup();
  }

  handleCursor() {
    this.map.on('mouseenter', markerLayer, this.props.mapbox.cursorPointer.bind(this.props.mapbox));
    this.map.on('mouseleave', markerLayer, this.props.mapbox.defaultCursor.bind(this.props.mapbox));
  }

  handlePopup() {
    this.map.on('mouseenter', markerLayer, (event) => {
      if (!event || !event.features) return;

      const market = event.features[0].properties as Market;
      this.popup.show(market);
    });

    this.map.on('mouseleave', markerLayer, (event) => {
      this.popup.hide();
    });
  }

  moveToMarket(event) {
    this.props.updateMapPosition({
      center: [event.lngLat.lng, event.lngLat.lat],
      zoom: 9
    })

    this.props.updateMarket([event.lngLat.lng, event.lngLat.lat])
    this.props.loadAllUnits([event.lngLat.lng, event.lngLat.lat])
  }

  render() {
    return <div/>;
  }
}

// @ts-ignore
export default connect(({current_market}) => ({current_market}), {loadAllUnits, updateMarket, updateMapPosition})(Markets);
