import accounting from "accounting";
import moment from "moment";
import * as React from "react";
import { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";

import { loadUnitPriceHistory } from "../../actions/UnitsActions";
import PriceScoreSection from "./PriceScoreSection";

class PriceTab extends Component<any, any> {
  constructor(props) {
    super(props);
    this.togglePriceHistory = this.togglePriceHistory.bind(this);
    this.campaignFormatter = this.campaignFormatter.bind(this);
    this.toggleOnlyBookedCampaigns = this.toggleOnlyBookedCampaigns.bind(this);
    this.getUnitPriceHistory = this.getUnitPriceHistory.bind(this);
    this.state = {
      price_history: [],
      show_price_history: false,
      only_booked_campaigns: false,
    };
  }

  public async togglePriceHistory() {
    const { show_price_history, only_booked_campaigns } = this.state;

    if (show_price_history) {
      this.setState({ show_price_history: false });
    } else {
      const price_history = await this.getUnitPriceHistory(only_booked_campaigns);
      this.setState({ price_history, show_price_history: true });
    }
  }

  public async getUnitPriceHistory(only_booked_campaigns) {
    const { unit, loadUnitPriceHistory } = this.props;
    return await loadUnitPriceHistory(unit.id, only_booked_campaigns);
  }

  public async toggleOnlyBookedCampaigns() {
    const { only_booked_campaigns } = this.state;
    const price_history = await this.getUnitPriceHistory(!only_booked_campaigns);
    this.setState({ price_history, only_booked_campaigns: !only_booked_campaigns });
  }

  public mapPriceInfo(price_info, idx) {
    return (
      <div className={price_info.style} key={idx}>
        {price_info.label}
        <span className="infoTab__spec-value">{accounting.formatMoney(price_info.amount)}</span>
      </div>
    );
  }

  public priceFormatter(cell, row, callback, rowIdx) {
    return <span>{accounting.formatMoney(row.price)}</span>;
  }

  // Don't render a link if we don't have a campaign_token
  public campaignFormatter(cell, row, callback, rowIdx) {
    const { unit } = this.props;
    if (row.campaign_token) {
      return (
        <a target="_blank" href={`/campaigns/${row.campaign_token}/units/${unit.id}`}>
          {row.campaign_name}
        </a>
      );
    } else {
      return row.campaign_name;
    }
  }

  public dateFormatter(cell, row, callback, rowIdx) {
    return moment(row.date).format("M/D/Y");
  }

  public renderPricingSection() {
    const { unit, campaign, user } = this.props;
    const savings_percent = (((unit.rate_card_price - unit.price) / unit.rate_card_price) * 100).toFixed(0) + "%";
    const savings_total = unit.rate_card_price - unit.price;
    const display_items = ["unit_price", "rate_card"] as string[];

    const price_info_items = {
      unit_price: {
        label: "Price: ",
        style: "infoTab__unit_price",
        amount: unit.price,
      },
      rate_card: {
        label: "Rate Card: ",
        style: "infoTab__rate_card",
        amount: accounting.formatMoney(unit.rate_card_price),
      },
      predicted: {
        label: "Predicted Price: ",
        style: "infoTab__predicted_price label label-primary label-decorate",
        amount: accounting.formatMoney(unit.predicted_price),
      },
      prod: {
        label: "+Production: ",
        style: "prod-install",
        amount: accounting.formatMoney(unit.production_cost),
      },
      install: {
        label: "+Installation: ",
        style: "prod-install",
        amount: accounting.formatMoney(unit.installation_cost),
      },
      savings: {
        label: "Savings: ",
        style: "savings_rate_prices",
        amount: `${accounting.formatMoney(savings_total, "$", 0)}(${savings_percent})`,
      },
    };

    if (!user.is_supplier && savings_total > 0) {
      display_items.push("savings");
    }

    if (user.is_admin) {
      display_items.push("predicted");
    }

    return (
      <div className="infoTab__specs">
        <div className="infoTab__spec__pricing__wrapper">
          <div className="spec_holder">
            <div className="spec_title">Unit</div>
            {display_items.map((item, idx) => this.mapPriceInfo(price_info_items[item], idx))}
          </div>
          <div className="spec_holder">
            <div className="spec_title">One Time</div>
            {["prod", "install"].map((item, idx) => this.mapPriceInfo(price_info_items[item], idx))}
          </div>
        </div>
      </div>
    );
  }

  renderPriceHistory() {
    const { show_price_history, price_history, only_booked_campaigns } = this.state;

    return (
      <div style={{ padding: "0px 30px" }}>
        <div>
          <a onClick={this.togglePriceHistory}>{show_price_history ? "Hide Price History" : "Show Price History"}</a>
        </div>
        {show_price_history && (
          <div>
            <input
              value={only_booked_campaigns}
              type="checkbox"
              onChange={this.toggleOnlyBookedCampaigns}
              style={{ marginRight: 5 }}
            />{" "}
            Show only booked campaigns
            <BootstrapTable
              data={price_history}
              options={{ noDataText: "There is no price history for this unit." }}
              keyField="token"
              bordered={false}
            >
              <TableHeaderColumn key="date" dataFormat={this.dateFormatter} width="15%">
                Date
              </TableHeaderColumn>
              <TableHeaderColumn key="token" dataFormat={this.campaignFormatter} width="65%">
                Campaign
              </TableHeaderColumn>
              <TableHeaderColumn key="price" dataFormat={this.priceFormatter} width="20%">
                Price
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )}
      </div>
    );
  }

  private showPriceGuide() {
    const { unit, user, campaign } = this.props;
    if(user.permissions.hide_unit_prices) {
      return false;
    }
    if (!unit || !user || !user.permissions || !user.permissions.can_view_price_guide ) {
      return false;
    }
    if (campaign && campaign.campaign && campaign.campaign.price_guide_disabled) {
      return false;
    }
    if (!unit.lower_fair_price || !unit.upper_fair_price) {
      return false;
    }
    return true;
  }

  public render() {
    const { unit, user, campaign } = this.props;
    const showPriceGuide = this.showPriceGuide();

    return (
      <div className="infoTab">
        {showPriceGuide && <PriceScoreSection unit={unit} campaign={campaign} user={user} />}
        {this.renderPricingSection()}
        {user && user.is_admin && this.renderPriceHistory()}
      </div>
    );
  }
}

export default connect(({}) => ({}), { loadUnitPriceHistory })(PriceTab);
