import React, { Component } from "react";
import Button from "@adquick/ui/src/Components/Button";
import GlobalActions from "../../../../actions/GlobalActions";

class RequestNoteToSuppliersModal extends Component {
  constructor(props) {
    super(props)
    this.onClose = this.onClose.bind(this)
    this.saveNote = this.saveNote.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      note: null
    }
  }

  render() {
    return (
      <div
        className="default_modal_container"
        onClick={e => e.stopPropagation()}
      >
        <div
          className="default_modal modal_small"
          style={{padding: "20px"}}
        >
          <div
            className="modal_header"
            style={{color:"#858585",justifyContent: "flex-end"}}
          >
            <div
              className="close_button ticker"
              onClick={this.onClose}
              style={{fontSize: "20px", cursor: "pointer"}}
            >
              <i className="fal fa-times"></i>
            </div>
          </div>
          <div className="modal_body">
            <label>Optional note to supplier</label>
            <textarea
              className="form-control"
              onChange={this.saveNote}
              style={{height: "100px", resize: "none"}}
            />
          </div>
          <div
            className="modal_footer"
            style={{justifyContent: "flex-end"}}
          >
            <div
              onClick={this.onClose}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#4A90E2",
                cursor: "pointer",
                marginRight: "20px"
              }}
            >
              Cancel
            </div>
            <Button onClick={this.onSubmit}>Submit</Button>
          </div>
        </div>
      </div>
    )
  }

  onClose() {
    GlobalActions.closePopup()
  }

  saveNote(e) {
    this.setState({ note: e.target.value })
  }

  async onSubmit() {
    const { requestType, sendUnitRequests } = this.props.popupOptions
    const { note } = this.state
    await sendUnitRequests(requestType, note)
    this.onClose()
  }
}

export default RequestNoteToSuppliersModal