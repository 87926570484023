import React from 'react';
import isEqual from 'lodash/isEqual';

import Adwords from '../../Adwords';
import Geopath from '../Geopath'
import DataLayers from '../DataLayers'
import LocationInput from '../../Browse/LocationInput';
import NormalListing from '../../Browse/Listing';
import TimelineListing from '../../Timeline/Listings';
import Header from './Header';
import VendorBranding from '../../VendorBranding';
import Summary from './Summary';
import AddOns from './AddOns';
import FavoritesFilter from '../../FavoritesFilter';
import RecommendedFilter from '../../RecommendedFilter';
import InCartFilter from '../../InCartFilter';
import PinnedMessage from './PinnedMessage';
import {sidebarUnits, allUnits} from '../../../models/Units';
import TagsFilter from '../../Filters/TagsFilter';
import { paramsAdd, paramsRemove } from "../../../utils/QueryString";
import axios from "axios";
import TargetSpend from './TargetSpend/TargetSpend';

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sortBy: this.props.sortBy,
      data: this.props.data || {},
      tab: this.props.tab || 'units',
      loading: this.props.loading,
      gettingTotals: false,
      options: {
        campaigns: [],
      },
      demographics: null,
      sidebarLimit: 20,
      add_ons_list: []
    };
    this.enableSidebarOverlay = this.enableSidebarOverlay.bind(this);
    this.disableSidebarOverlay = this.disableSidebarOverlay.bind(this);
  }

  componentDidMount() {
    this.loadGeopath();
    this.getAddOns();
    $('.campaign-tab__side').on('scroll', this.trackScrolling.bind(this));
  }

  componentWillUnmount() {
    $('.campaign-tab__side').off('scroll', this.trackScrolling.bind(this));
  }

  isBottom(el) {
    const bottom = window.innerHeight + 1
    return el.getBoundingClientRect().bottom <= bottom;
  }

  trackScrolling(event) {
    const wrappedElement = document.getElementById('campaign-sidebar');
    if (this.isBottom(wrappedElement)) {
      this.setState({ sidebarLimit: this.state.sidebarLimit + 20 })
      document.removeEventListener('scroll', this.trackScrolling.bind(this));
    }
  }

  async loadGeopath() {
    if (!this.props.data || !this.props.data.permissions || !this.props.data.permissions.can_view_geopath) {
      return
    }

    this.props.loadDemographics()
  }

  async getTotals(){
    const queryString = window.location.toLocaleString();
    const { data } = await axios.get(`/campaigns/${this.props.data.campaign.id}/totals`);
  }

  async getAddOns(){
    const { data } = await axios.get(`/campaigns/${this.props.data.campaign.id}/add_ons`);
    const { add_ons, total, count} = data
    this.setState({
      add_ons_list: add_ons,
      add_ons_total: total,
      add_ons_count: count
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(!isEqual(prevProps.data, this.props.data)) { this.setState({ data: this.props.data })}
    if(!isEqual(prevProps.loading, this.props.loading)) { this.setState({ loading: this.props.loading })}
    if(!isEqual(prevProps.sortBy, this.props.sortBy)) { this.setState({ sortBy: this.props.sortBy })}
  }

  checkFlag() {
    const { flags } = this.props
    const loadingFlag = _.find(flags, flag => flag.name === 'campaign_loading')
    return loadingFlag && loadingFlag.status
  }

  renderListing() {
    const { showTips, showTimeline } = this.props
    const { campaign, permissions, demographics } = this.props.data;
    const { loading } = this.state
    const ListingComponent = showTimeline ? TimelineListing : NormalListing

    return (
      <ListingComponent
        demographics={demographics}
        loading={loading}
        flag={this.checkFlag()}
        campaign={campaign}
        permissions={permissions}
        data={this.props.data}
        units={this.props.units}
        sortBy={this.state.sortBy || 'default'}
        onUnitListingClick={this.props.onUnitListingClick}
        onFavoriteUnit={this.props.onFavoriteUnit}
        onToggleRecommendUnit={this.props.onToggleRecommendUnits}
        isCampaignView={true}
        showTips={showTips}
        limit={this.state.sidebarLimit}
        filters={this.props.filters}
      />
    )
  }

  showPinnedMessage() {
    const { campaign: {is_mock, show_mock_pinned_message, pinned_message_info } } = this.props.data;
    if(!is_mock) { return !!pinned_message_info }
    return is_mock && show_mock_pinned_message
  }

  campaignScore() {
    const { units } = this.props
    const campaignUnits = allUnits(units)
    const withScore = campaignUnits.filter(cu => !!cu.score)
    if (withScore.length == 0) { return }
    return Math.round(withScore.map(cu => cu.score).reduce((sum, score) => sum + score, 0) / withScore.length)
  }

  // This was copied from FiltersBar as a quick fix - might be good to refactor
  handleQueryParams(filter) {
    const keys = Object.keys(filter)
    keys.forEach(key => !!filter[key] ? paramsAdd(key, filter[key]) : paramsRemove(key))
  }

  enableSidebarOverlay() {
    this.setState({gettingTotals: true})
  }

  disableSidebarOverlay() {
    this.setState({gettingTotals: false})
  }

  render() {
    const { campaign, agency, permissions, demographics } = this.props.data;
    const { supplier_id, is_mock, contracted_at } = campaign;
    const { loading, add_ons_list, add_ons_total, add_ons_count, gettingTotals } = this.state
    const { onSort, showTips, isSearchView, units, isAddingAllToCart, showUnitScores } = this.props
    const haveTagFilter = units.unit_filter.tags && units.unit_filter.tags.length > 0
    const favoritesOnly = _.get(units, 'unit_filter.only_favorites', false);
    const recommendedOnly = _.get(units, 'unit_filter.only_recommended', false);
    const inCartOnly = _.get(units, 'unit_filter.only_in_cart', false);
    const activeTag = _.get(units, 'unit_filter.tag', null);
    const unitsCount = units ? sidebarUnits(units, true).length : 0;
    const showPlanFilters = ((!isSearchView && permissions.can_view_favorites && permissions.can_view_recommended) || loading)
    const showMockBanner = campaign && is_mock && !agency;
    const campaignScore = this.campaignScore();
    const handleQueryParams = this.handleQueryParams;
    const showSummary = haveTagFilter || favoritesOnly || recommendedOnly

    return (
      <div className="campaign-sidebar" id="campaign-sidebar">
        { showMockBanner &&
          <div style={{padding: '15px 15px 0 15px'}}>
            <div style={{display:'flex',alignItems:'center',backgroundColor:'#f1f7fd',borderColor:'#f1f7fd',borderRadius:'10px'}} className="alert alert-info">
              <i style={{color:'#4990DB',fontSize:'18px',marginRight:'10px'}} className="fa fa-usd"></i>
              <div>
                <p style={{color:'black',margin: 0}}><b>Best Price Guarantee</b> <span>&mdash;</span> we've never lost a price test for a reason.</p>
                <a href="/pricing">Learn how AdQuick always gets you the best price.</a>
              </div>
            </div>
          </div>
        }
        { this.showPinnedMessage() &&
          <PinnedMessage
            isMock={is_mock}
            pinnedMessageInfo={campaign.pinned_message_info}
            showShareModal={this.props.showShareModal}
          />
        }
      <div>
        <div className="sidebar-filters-overlay-area">
          {gettingTotals && <div className="sidebar-filters-overlay">
            <i className="fas fa-spinner fa-pulse"></i>
          </div>}
          <div className="sidebar-filters-and-location plan-filter-area">
            {showPlanFilters &&
              <React.Fragment>
                <FavoritesFilter isActive={favoritesOnly} handleQueryParams={handleQueryParams} />
                <RecommendedFilter isActive={recommendedOnly} handleQueryParams={handleQueryParams} />
                <InCartFilter isActive={inCartOnly} handleQueryParams={handleQueryParams} showIcon={true} />
              </React.Fragment>
            }
            {(permissions.can_view_filters || loading) &&
              <LocationInput
                onSearch={this.props.onLocationSearch}
                onClear={this.props.onSearchClear}
                showTips={showTips}
                placeholder="Search location"
                mapbox={this.props.mapbox}
              />
            }
          </div>
          {agency && <VendorBranding supplier={agency} />}
          {campaign.branding_logo_url && <div style={{ backgroundColor: campaign.branding_color, padding: 20 }}>
            <img src={campaign.branding_logo_url} style={{ maxWidth: 200, maxHeight: 80 }} />
          </div>}
          <div style={{ borderBottom: '1px solid #eee', backgroundColor: '#F5F5F5' }}>
            {(permissions.can_view_filters || loading) && <div style={{padding:5}}>
              <div style={{padding: '5px'}}>
                <TagsFilter
                  standaloneMode={true}
                  initialValue={activeTag}
                />
              </div>
            </div>}
            {((campaign && permissions.can_view_geopath) || loading) &&
              <Geopath
                loading={loading}
                campaign={campaign}
                flag={this.checkFlag()}
                data={this.props.data}
                loadGeopath={this.loadGeopath.bind(this)}
                demographics={demographics}
                favoritesOnly={favoritesOnly}
                recommendedOnly={recommendedOnly}
              />
            }

            {(permissions.can_view_data_layers || loading) &&
              <DataLayers loading={loading} campaign={campaign} flag={this.checkFlag()} units={this.props.units} renderCustomBounds={this.props.renderCustomBounds}/>
            }
            {campaign && (this.props.user.admin || this.props.data.adwords) && <Adwords campaign={campaign} />}
            { showPlanFilters && !!!supplier_id && showUnitScores && !!campaignScore &&
              <div className="campaign-score">
                <span>Avg Unit Score: </span><b>{campaignScore}</b>
              </div>
            }
          </div>
        </div>
        {showSummary && <Summary
          unit_filter={this.props.units.unit_filter}
          data={this.props.data}
          units={sidebarUnits(this.props.units)}
          loading={!!!this.props.data.units}
          favoritesOnly={favoritesOnly}
          recommendedOnly={recommendedOnly}
          show={showSummary}
          onShowTotal={this.props.onShowTotal}
          add_ons_list={add_ons_list}
          add_ons_total={add_ons_total}
          add_ons_count={add_ons_count}
          enableSidebarOverlay={this.enableSidebarOverlay}
          disableSidebarOverlay={this.disableSidebarOverlay}
        />}
        {
          add_ons_list.length > 0 &&
          <AddOns
            add_ons_list={add_ons_list}
            add_ons_total={add_ons_total}
            add_ons_count={add_ons_count}
            token={campaign.token}
          />
        }
        <div style={{ background: 'white' }}>
          <Header
            loading={loading}
            flag={this.checkFlag()}
            permissions={this.props.data.permissions}
            onSort={onSort}
            onAddAllToCart={this.props.onAddAllToCart}
            filters={this.props.filters}
            unitsCount={unitsCount}
            units={this.props.units}
            showTimeline={this.props.showTimeline}
            onShowMap={this.props.onShowMap}
            onShowTimeline={this.props.onShowTimeline}
            isMockCampaign={is_mock}
            isAddingAllToCart={isAddingAllToCart}
            campaignToken={campaign.token}
            disableCart={campaign.disable_cart}
          />
          {this.renderListing()}
        </div>
      </div>
      {campaign.spend_goal_enabled && <TargetSpend />}
    </div>);
  }
}

export default Sidebar;
