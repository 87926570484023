import React from 'react';
import createClass from 'create-react-class';

const Pin = createClass({
  displayName: 'Pin',

  getInitialState() {
    return ({
      isHovering: false
    });
  },

  isPinned() {
    return this.props.isPinned;
  },

  handleMouseEnter() {
    this.setState({ isHovering: true });
  },

  handleMouseLeave() {
    this.setState({ isHovering: false });
  },

  handleClick(e) {
    e.stopPropagation(),
    this.props.onPinUnit(this.props.unitId);
  },

  styles() {
    const css = {
      general: {
        color: "#9b9b9b",
        cursor: "pointer"
      },
      hover: {
        color: "#446bac"
      },
      pinned: {
        color: "#446bac"
      }
    }
    if (this.state.isHovering) {
      return Object.assign(css.general, css.hover);
    } else if (this.isPinned()) {
      return Object.assign(css.general, css.pinned);
    } else {
      return css.general;
    }
  },

  iconClasses() {
    let pinClass = (this.isPinned()) ? "fa-thumb-tack" : "fa-thumb-tack";
    return (pinClass += " fa pinned");
  },

  render() {
    return (<i
      style={this.styles()}
      className={this.iconClasses()}
      onClick={this.handleClick}
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
    ></i>)
  }
});

export default Pin;