import React from 'react';
import { Modal } from 'react-bootstrap';
import AuthStore from '../../stores/AuthStore';

export default class SignUpSuccessModal extends React.Component {

  constructor(props) {
    super(props);
    const auth = AuthStore.getState();
    this.state = {
      auth: auth
    };
    this.onAuthChange = this.onAuthChange.bind(this);
  }

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];
  }

  onAuthChange() {
    this.setState({ auth: AuthStore.getState() });
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  render() {
    const { user } = this.state.auth;
    const { show, onHide, onNext } = this.props;

    return (<Modal show={show} onHide={onHide} className="welcome-modal">
      <Modal.Body>
        <div className="logo">
          <img src={ASSETS['white-logo']} className="logo" />
        </div>
        <h1>Thanks for signing up!</h1>
        <button className="btn btn-default" onClick={onNext}>Start Tour</button>
      </Modal.Body>
    </Modal>);
  }
};
