import React, { Component } from 'react';
import { connect } from 'react-redux';
import accounting from 'accounting';
import {
  cancelCampaignSupplierContract,
  destroyCampaignSupplierContract
} from '../../actions/campaign_supplier_contracts';
import cs from 'classnames';
import GlobalActions from '../../actions/GlobalActions';
import StateBadge from './StateBadge';
import ActionBadge from './ActionBadge';

class Contract extends Component {

  constructor(props) {
    super(props);
    this.cancelContract = this.cancelContract.bind(this);
    this.destroyContract = this.destroyContract.bind(this);
  }

  async cancelContract() {
    const { campaign, contract, cancelCampaignSupplierContract } = this.props;
    if(confirm("Are you sure you want to cancel this contract?")) {
      await cancelCampaignSupplierContract(campaign.campaignId, contract.id);
      GlobalActions.showMessage("Contract was successfully canceled");
    }
  }

  formatMoney(amount) {
    // Remove cents if it's .00
    return accounting.formatMoney(amount).replace('.00', '')
  }

  async destroyContract() {
    const { campaign, contract, destroyCampaignSupplierContract } = this.props;
    if(confirm("Are you sure you want to delete this contract?")) {
      await destroyCampaignSupplierContract(campaign.campaignId, contract.id);
      GlobalActions.showMessage("Contract was successfully deleted");
    }
  }

  render() {
    const { contract, onShowForm } = this.props;
    const {
      units_cost,
      production_cost,
      installation_cost,
      total,
      canceled_at,
      signatures
    } = contract;

    return(
      <div className={cs('contract_item', { canceled: !!canceled_at })}>
        <div className="badges">
          <StateBadge contract={contract} />
          <ActionBadge contract={contract} onShowForm={onShowForm}/>
        </div>
        <div className="supplier_contracts_table">
          <table>
            <tbody>
              <tr>
                <td>
                  <ul className="signatures">
                    {signatures.map((s) => {
                      return (
                        <li className={s.status_code}>{s.name}</li>
                      )
                    })}
                  </ul>
                </td>
                <td>{this.formatMoney(units_cost)}</td>
                <td>{this.formatMoney(production_cost)}</td>
                <td>{this.formatMoney(installation_cost)}</td>
                <td>{this.formatMoney(total)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ display: 'flex' }}>
          {(!canceled_at && contract.type !== "upload") &&
            <a onClick={this.cancelContract}>Cancel contract</a>
          }
          <i className="fa fa-trash delete_icon" aria-hidden="true" onClick={this.destroyContract}></i>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ campaign }) => ({
  campaign,
})

export default connect(
  mapStateToProps,
  {
    cancelCampaignSupplierContract,
    destroyCampaignSupplierContract
  }
)(Contract)
