import { get } from '../utils/api';

export const SET_MARKET = 'adquick/set-market';
export const setMarket =
  (market_id) => async (dispatch) => dispatch({ type: SET_MARKET, payload: market_id });

export const updateMarket = (position: [number, number] | undefined = undefined) => async (dispatch, getState) => {
  const [lon, lat] = getPosition(position, getState);
  const { id } = await get(`/api/v1/market_overviews?lon=${lon}&lat=${lat}`);

  dispatch({ type: SET_MARKET, payload: id });
}

function getPosition(position: [number, number] | undefined, getState: Function): [number, number] {
  if (position) return position;

  const bounds = getState().map.bounds;
  const lon = (bounds[0][0] + bounds[1][0]) / 2.0;
  const lat = (bounds[0][1] + bounds[1][1]) / 2.0;

  return [lon, lat];
}
