import { connect } from "react-redux";
import React, { Component } from "react";
import _ from "lodash";

import * as styleGen from "../styles";
import { CampaignUnit } from "../../../../models/Units";
import { markCampaignUnitsAsReadyToInstall } from "../../../Campaign/actions";
import {
  bulkRejectDesignAssets,
  loadCampaignDesigns,
  sendToProduction,
  uploadDesignAsset,
  sendToInstallers
} from "../../../../actions/campaign_design_actions";
import {
  canReject,
  canSendToProduction,
  getDesignAssetsIds,
  isMarkedForProduction,
  proofPosted
} from "../../../../models/design_assets";
import { get } from "../../../../utils/api";
import AddPostingNoteModal from "../AddPostingNoteModal";
import BulkAddPostingNoteAction from "../BulkAddPostingNoteAction";
import BulkRemovePostingNoteAction from "../BulkRemovePostingNoteAction";
import BulkRemoveProductionMarkerAction from "../BulkRemoveProductionMarkerAction";
import BulkSetShippingAddressAction from "../BulkSetShippingAddressAction";
import BulkShippingAddressModal from "../BulkShippingAddressModal";
import BulkUnitInstallerModal from "../BulkUnitInstallerModal"
import BulkSentToInstallersAction from "../BulkSentToInstallersAction";
import BulkUpdateInstallerAction from "../BulkUpdateInstallerAction";
import JobProgress from "../../../../utils/JobProgress";
import UploadButton from "../../../UploadButton";
import UploadPopWithPostedDate from "../UploadPopWithPostedDate";

import Tooltip from "rc-tooltip";

interface BulkActionsProps {
  currentUser: any;
  units: CampaignUnit[];
  unit_selection_map: any;
  status: string;
  campaign_permissions: any;
  campaign: any;
  bulkApproveDesignAssets: (campaign_id: string, design_asset_ids: number[]) => void;
  bulkRejectDesignAssets: (campaign_id: string, design_asset_ids: number[], reason: string) => void;
  loadCampaignDesigns: (campaign_id: string) => void;
  sendToProduction: (campaign_id: string, design_asset_ids: number[]) => any;
  uploadDesignAsset: (campaign_id: string, units: CampaignUnit[], upload: {}) => {};
  unselectAll: () => void;
  onToggleSelectAll: (selected: boolean) => void;
  submittedUnitsSelected: () => boolean;
  sendToInstallers: () => void;
  markCampaignUnitsAsReadyToInstall: () => void;
}

interface BulkActionsState {
  show_posting_note_modal: boolean;
  show_shipping_address_modal: boolean;
  show_update_installer_modal: boolean;
  is_dropdown_active: boolean;
  job_polling: {
    startPolling: (job_id: string, callback: any) => void;
  };
}

const ASSETS = (window as any).ASSETS;

class BulkActions extends Component<BulkActionsProps, BulkActionsState> {
  constructor(props) {
    super(props);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.downloadSelectedDesignAssets = this.downloadSelectedDesignAssets.bind(this);
    this.onSendToProduction = this.onSendToProduction.bind(this);
    this.onBulkReject = this.onBulkReject.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.onShowPostingModal = this.onShowPostingModal.bind(this);
    this.onHidePostingNoteModal = this.onHidePostingNoteModal.bind(this);
    this.submittedUnitsSelected = this.submittedUnitsSelected.bind(this);
    this.onShowShippingAddressModal = this.onShowShippingAddressModal.bind(this);
    this.onHideShippingAddressModal = this.onHideShippingAddressModal.bind(this);
    this.onToggleUpdateInstallerModal = this.onToggleUpdateInstallerModal.bind(this);
    this.onCompleteZipFile = this.onCompleteZipFile.bind(this);
    this.onDropDownDomAction = this.onDropDownDomAction.bind(this);
    this.state = {
      show_posting_note_modal: false,
      show_shipping_address_modal: false,
      show_update_installer_modal: false,
      is_dropdown_active: false,
      job_polling: new JobProgress(),
    };
  }

  public render() {
    const { units, unit_selection_map, campaign, currentUser } = this.props;
    const all_selected = _.every(units, unit => unit_selection_map[unit.id]);
    const supplier_id = this.uniqSupplierIdsFromSelectedUnits()[0]
    const {
      show_posting_note_modal,
      show_shipping_address_modal,
      show_update_installer_modal
    } = this.state;

    return (
      <div className="bulk_actions" style={styleGen.actions()}>
        {this.renderActionsDropdown()}
        <AddPostingNoteModal
          show={show_posting_note_modal}
          campaign={campaign}
          selected_units={this.selectedUnits()}
          onHide={this.onHidePostingNoteModal}
        />
        <BulkShippingAddressModal
          campaign={campaign}
          onSubmit={() => {}}
          supplier_id={supplier_id}
          selected_units={this.selectedUnits()}
          show={show_shipping_address_modal}
          onHide={this.onHideShippingAddressModal}
        />
        <BulkUnitInstallerModal
          campaign={campaign}
          supplier_id={currentUser.supplier_id}
          selected_units={this.selectedUnits()}
          show={show_update_installer_modal}
          onHide={this.onToggleUpdateInstallerModal}
          loadCampaignDesigns={this.props.loadCampaignDesigns}
        />
        <input type="checkbox" onChange={this.selectAll} checked={all_selected} style={styleGen.checkbox()}></input>
      </div>
    );
  }
  onDropDownDomAction() {
    this.setState({ is_dropdown_active: !this.state.is_dropdown_active });
  }

  private onHidePostingNoteModal() {
    this.setState({ show_posting_note_modal: false });
  }

  private selectedUnits() {
    const { units, unit_selection_map } = this.props;
    return units.filter(unit => unit_selection_map[unit.id]);
  }

  private renderBulkRemovePostingNoteButton() {
    const { campaign } = this.props;
    return (
      <BulkRemovePostingNoteAction
        disabled={this.submittedUnitsSelected()}
        selected_units={this.selectedUnits()}
        campaign={campaign}
      />
    );
  }

  private renderSetShippingAddressButton() {
    return (
      <BulkSetShippingAddressAction
        disabled={this.submittedUnitsSelected()}
        showModal={this.onShowShippingAddressModal}
      />
    );
  }

  private onShowShippingAddressModal() {
    if(!this.ensureUniqSupplierFromSelectedUnits()) { return alert("Select units from one supplier one. Addresses are tied to suppliers.") }
    this.setState({
      show_shipping_address_modal: true,
    });
  }

  private ensureUniqSupplierFromSelectedUnits() {
    return this.uniqSupplierIdsFromSelectedUnits().length == 1
  }

  private uniqSupplierIdsFromSelectedUnits() {
    return [...new Set(this.supplierIdsFromSelectedUnits())]
  }

  private supplierIdsFromSelectedUnits() {
    return this.selectedUnits().map(u => u.supplier_id)
  }

  private onHideShippingAddressModal() {
    this.setState({
      show_shipping_address_modal: false,
    });
  }

  private onToggleUpdateInstallerModal() {
    const { show_update_installer_modal } = this.state;
    this.setState({
      show_update_installer_modal: !show_update_installer_modal
    })
  }

  private renderActionsDropdown() {
    const { campaign_permissions } = this.props;
    const selected_units = this.selectedUnits();
    const disabled = selected_units.length > 0 ? "" : "disabled";
    const userCanRemoveProdMarker =
      campaign_permissions.can_remove_production_marker &&
      _.every(selected_units, unit => isMarkedForProduction(unit.design_assets[0]));
    const userCanSendToProduction =
      campaign_permissions.can_create_production_order &&
      _.every(selected_units, unit => canSendToProduction(unit.design_assets[0]));
    const userCanReject =
      campaign_permissions.can_reject_design_asset && _.every(selected_units, unit => canReject(unit));
    const userCanDownloadDigitalProofs = _.some(selected_units, unit => proofPosted(unit));

    return (
      <div
        className="btn-group"
        style={{
          float: "right",
          marginBottom: 10,
        }}
      >
        <div className="dropdown">
          {
            !this.state.is_dropdown_active && (
              <Tooltip
                placement="bottom"
                trigger={['hover']}
                overlayStyle={{ width: '200px', height: "200px" }}
                overlay={<span style={{ width: "fit-content" }}>Upload individual creative assets by clicking "upload - creative" or mass upload creatives by using the checkbox selection + actions menu (arrow).</span>}
              >
                <button className={`btn btn-default dropdown-toggle ${disabled}`} type="button" data-toggle="dropdown" onClick={this.onDropDownDomAction}>
                  Actions
                  <span className="caret" style={{ marginLeft: "5px", marginBottom: "2px" }} />
                </button>
              </Tooltip>
            )
          }
          {
            this.state.is_dropdown_active && (
              <button className={`btn btn-default dropdown-toggle ${disabled}`} type="button" data-toggle="dropdown" onMouseLeave={this.onDropDownDomAction}>
                Actions
                <span className="caret" style={{ marginLeft: "5px", marginBottom: "2px" }} />
              </button>
            )
          }
          <ul className="dropdown-menu dropdown-menu-left" style={{ padding: 5 }}>
            {campaign_permissions.can_operate_install &&
              <li className="dropdown-item">
                {this.renderSentToInstallerButton()}
              </li>
            }
            {campaign_permissions.can_operate_install &&
              <li className="dropdown-item">
                {this.renderUpdateInstallerButton()}
              </li>
            }
            {campaign_permissions.can_add_posting_note && (
              <li className="dropdown-item">{this.renderAddPostingNoteButton()}</li>
            )}
            {campaign_permissions.can_add_posting_note && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderBulkRemovePostingNoteButton()}
              </li>
            )}
            {campaign_permissions.can_add_posting_note && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderSetShippingAddressButton()}
              </li>
            )}
            {userCanRemoveProdMarker && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderBulkRemoveProductionMarker()}
              </li>
            )}
            {userCanSendToProduction && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderSendToProductionButton()}
              </li>
            )}
            {userCanReject && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderBulkRejectButton()}
              </li>
            )}
            <li className="dropdown-item" style={{ marginTop: 5 }}>
              {this.renderDownloadDesignAssets()}
            </li>
            {campaign_permissions.can_upload_design_asset && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderUploadButton()}
              </li>
            )}
            {campaign_permissions.can_upload_pop && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderUploadPopButton()}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }

  private renderSendToProductionButton(): JSX.Element {
    return (
      <a
        key="send_to_production"
        onClick={this.onSendToProduction}
        className="btn btn-default"
        target="_blank"
        style={{ color: "#555555", width: "100%", fontSize: 12, height: 31 }}
      >
        <div style={{ display: "inline", verticalAlign: "text-bottom" }}>
          <img src={ASSETS["cg"]} />
        </div>{" "}
        Send to Production
      </a>
    );
  }

  private async onSendToProduction() {
    const { campaign } = this.props;
    const campaign_id = campaign.campaignId;
    const selected_units = this.selectedUnits();
    const design_asset_ids = getDesignAssetsIds(selected_units);
    await this.props.sendToProduction(campaign_id, design_asset_ids);
    this.props.unselectAll();
  }

  private selectAll(event) {
    this.props.onToggleSelectAll(event.target.checked);
  }

  private renderDownload(label, onButtonClick) {
    if (this.props.campaign_permissions.can_view_download_all_pops) {
      return (
        <a
          key={label}
          onClick={onButtonClick}
          className="btn btn-default"
          target="_blank"
          style={{ color: "#555555", width: "100%", fontSize: 12, height: 31 }}
        >
          <i className="fa fa-cloud-download" style={{ marginRight: 5 }} /> {label}
        </a>
      );
    } else {
      return <div className="download_all" />;
    }
  }

  private renderDownloadDesignAssets() {
    return this.renderDownload("Download Design Assets", this.downloadSelectedDesignAssets);
  }

  private renderBulkRejectButton() {
    return (
      <a
        key="bulk_reject"
        onClick={this.onBulkReject}
        className="btn btn-default approve_design_asset"
        style={{ ...styleGen.rejectButton(), height: 31, width: "100%" }}
      >
        Reject Selected
      </a>
    );
  }

  private onShowPostingModal() {
    this.setState({
      show_posting_note_modal: true,
    });
  }

  private submittedUnitsSelected() {
    const { units, unit_selection_map } = this.props;
    const selected_unit_ids = Object.keys(unit_selection_map);

    return (
      units.filter(
        u => _.get(u, "design_assets[0].production_order.status") === "submitted" && selected_unit_ids.includes(u.id),
      ).length > 0
    );
  }

  private renderAddPostingNoteButton() {
    return <BulkAddPostingNoteAction disabled={this.submittedUnitsSelected()} showModal={this.onShowPostingModal} />;
  }

  private renderBulkRemoveProductionMarker() {
    const selected_units = this.selectedUnits();
    const design_asset_ids = getDesignAssetsIds(selected_units);
    return (
      <BulkRemoveProductionMarkerAction
        key="remove_production_marker"
        campaign={this.props.campaign}
        design_asset_ids={design_asset_ids}
      />
    );
  }

  private renderSentToInstallerButton() {
    const selectedUnits = this.selectedUnits();
    return (
      <BulkSentToInstallersAction
        campaign={this.props.campaign}
        sentToInstallers={this.props.sendToInstallers}
        selectedUnits={selectedUnits}
        markAsReadyToInstall={this.props.markCampaignUnitsAsReadyToInstall}
      />
    );
  }

  private renderUpdateInstallerButton() {
    return (
      <BulkUpdateInstallerAction
        toggleModal={this.onToggleUpdateInstallerModal}
      />
    )
  }

  private async downloadSelectedDesignAssets() {
    const selected_units = this.selectedUnits();
    const ids = getDesignAssetsIds(selected_units).join(",");
    let url = `/api/v1/campaigns/${this.props.campaign.campaignId}/design_assets/download_zip`;
    if (selected_units.length > 0) {
      url += `?ids=${ids}`;
    }
    const { job_id } = await get(url);
    this.state.job_polling.startPolling(job_id, this.onCompleteZipFile);
  }

  onCompleteZipFile({ data }) {
    const { error } = data;
    if (error) {
      return;
    }
    window.open(data.file_url);
  }

  private renderUploadButton(disabled = false) {
    return (
      <UploadButton
        key="upload_button"
        onComplete={this.onUploadComplete}
        progressBarColor={styleGen.uploadButton().background}
      >
        <button
          className="btn btn-default"
          style={styleGen.uploadButton()}
          onClick={e => e.preventDefault()}
          disabled={disabled}
        >
          <i className="fa fa-cloud-upload" style={{ marginRight: 5 }} /> Upload Creative
        </button>
      </UploadButton>
    );
  }

  private renderUploadPopButton() {
    const {
      campaign_permissions,
      campaign: { campaignId },
    } = this.props;
    const selected_units = this.selectedUnits();
    const buttonChild = (
      <div>
        <i className="fa fa-cloud-upload" style={{ marginRight: 5 }} /> Upload POPs
      </div>
    );
    return (
      <UploadPopWithPostedDate
        key="upload_pop_button"
        units={selected_units}
        campaign_permissions={campaign_permissions}
        campaign_id={campaignId}
        buttonStyle={styleGen.uploadButton()}
        buttonChild={buttonChild}
      />
    );
  }

  private async onUploadComplete(upload, file) {
    const { campaign } = this.props;
    const campaign_id = campaign.campaignId;
    const selected_units = this.selectedUnits();

    const uploadInfo = {
      file_name: file.name,
      file_path: upload.path,
      file_size: file.size,
      file_type: file.type,
    };
    await this.props.uploadDesignAsset(campaign_id, selected_units, uploadInfo);
    this.props.loadCampaignDesigns(campaign_id);
    this.props.unselectAll();
  }

  private async onBulkReject() {
    const reason: string = prompt("Please inform the customer why you are rejecting the design assets:", "") || "";
    if (_.isEmpty(reason)) {
      return;
    }

    const { campaign } = this.props;
    const campaign_id = campaign.campaignId;
    const selected_units = this.selectedUnits();
    const design_asset_ids = getDesignAssetsIds(selected_units);

    await this.props.bulkRejectDesignAssets(campaign_id, design_asset_ids, reason);
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });
export default connect(
  mapStateToProps,
  {
    bulkRejectDesignAssets,
    loadCampaignDesigns,
    sendToProduction,
    uploadDesignAsset,
    sendToInstallers,
    markCampaignUnitsAsReadyToInstall
  },
  // @ts-ignore
)(BulkActions);
