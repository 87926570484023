import { formatNumber, unformat } from 'accounting';
import React from 'react';
import Select from 'react-select';
import createClass from 'create-react-class';
import PropTypes from "prop-types";

import UploadButton from '../../UploadButton';
import UploadStore from './../../../stores/UploadStore';

const mobileAdCPM = 4.78;
const targetedAudienceOptions = [
  { label: 'Affluent Shoppers', value: 'Affluent Shoppers'},
  { label: 'Art & Craft Lovers', value: 'Art & Craft Lovers'},
  { label: 'Arts Lovers', value: 'Arts Lovers'},
  { label: 'AT&T Subscribers', value: 'AT&T Subscribers'},
  { label: 'Baby Boomers', value: 'Baby Boomers'},
  { label: 'Bar/Pub Goers', value: 'Bar/Pub Goers'},
  { label: 'Beach Lovers', value: 'Beach Lovers'},
  { label: 'Big Box Shoppers', value: 'Big Box Shoppers'},
  { label: 'Big City Moms', value: 'Big City Moms'},
  { label: 'Boost Mobile Subscribers', value: 'Boost Mobile Subscribers'},
  { label: 'British Telecom Subscribers', value: 'British Telecom Subscribers'},
  { label: 'Budget Hotel Visitors', value: 'Budget Hotel Visitors'},
  { label: 'Business Travelers', value: 'Business Travelers'},
  { label: 'Car Enthusiasts', value: 'Car Enthusiasts'},
  { label: 'Car Owners', value: 'Car Owners'},
  { label: 'Car Renters', value: 'Car Renters'},
  { label: 'Casino Goers', value: 'Casino Goers'},
  { label: 'Children Shoppers', value: 'Children Shoppers'},
  { label: 'China Mobile Subscribers', value: 'China Mobile Subscribers'},
  { label: 'China Telecom Subscribers', value: 'China Telecom Subscribers'},
  { label: 'China Unicom Subscribers', value: 'China Unicom Subscribers'},
  { label: 'Chiropractors', value: 'Chiropractors'},
  { label: 'Coffee Lovers', value: 'Coffee Lovers'},
  { label: 'Commuters', value: 'Commuters'},
  { label: 'Convenience Shoppers', value: 'Convenience Shoppers'},
  { label: 'Cricket Wireless Subscribers', value: 'Cricket Wireless Subscribers'},
  { label: 'Cyclists', value: 'Cyclists'},
  { label: 'Dads', value: 'Dads'},
  { label: 'Dentists', value: 'Dentists'},
  { label: 'Department Shoppers', value: 'Department Shoppers'},
  { label: 'Deutsche Telekom Subscribers', value: 'Deutsche Telekom Subscribers'},
  { label: 'Diners', value: 'Diners'},
  { label: 'Discount Shoppers', value: 'Discount Shoppers'},
  { label: 'DIYs', value: 'DIYs'},
  { label: 'Electronics Enthusiasts', value: 'Electronics Enthusiasts'},
  { label: 'Entertainment Enthusiasts', value: 'Entertainment Enthusiasts'},
  { label: 'E-plus Subscribers', value: 'E-plus Subscribers'},
  { label: 'Evening Diners', value: 'Evening Diners'},
  { label: 'Fashion Shoppers', value: 'Fashion Shoppers'},
  { label: 'Fast Casual Diners', value: 'Fast Casual Diners'},
  { label: 'Fast Fashion Shoppers', value: 'Fast Fashion Shoppers'},
  { label: 'Fast Food Employees', value: 'Fast Food Employees'},
  { label: 'Financial Advisors', value: 'Financial Advisors'},
  { label: 'Fit Moms', value: 'Fit Moms'},
  { label: 'Fitness Enthusiasts ', value: 'Fitness Enthusiasts '},
  { label: 'Foodies', value: 'Foodies'},
  { label: 'Frequent Bank Customers', value: 'Frequent Bank Customers'},
  { label: 'Frequent Travelers', value: 'Frequent Travelers'},
  { label: 'Gamers', value: 'Gamers'},
  { label: 'Gen X', value: 'Gen X'},
  { label: 'Gen Z', value: 'Gen Z'},
  { label: 'Gen Z', value: 'Gen Z'},
  { label: 'Golfers', value: 'Golfers'},
  { label: 'Grocery Shoppers', value: 'Grocery Shoppers'},
  { label: 'Gyms Goers', value: 'Gyms Goers'},
  { label: 'Health Care Professionals', value: 'Health Care Professionals'},
  { label: 'Hispanics', value: 'Hispanics'},
  { label: 'Home Contractors', value: 'Home Contractors'},
  { label: 'Hutchison Subscribers', value: 'Hutchison Subscribers'},
  { label: 'In-Market For Auto', value: 'In-Market For Auto'},
  { label: 'In-Market For Carrier', value: 'In-Market For Carrier'},
  { label: 'In-Market For Furniture', value: 'In-Market For Furniture'},
  { label: 'In-Market For Luxury Auto', value: 'In-Market For Luxury Auto'},
  { label: 'In-Market For Mattresses', value: 'In-Market For Mattresses'},
  { label: 'International Travelers', value: 'International Travelers'},
  { label: 'Jewellery Shoppers', value: 'Jewellery Shoppers'},
  { label: 'Late Night Diners', value: 'Late Night Diners'},
  { label: 'Late Nighters', value: 'Late Nighters'},
  { label: 'Leisure Travelers', value: 'Leisure Travelers'},
  { label: 'Live Event Attendees', value: 'Live Event Attendees'},
  { label: 'Live Theater Goers', value: 'Live Theater Goers'},
  { label: 'Lunch Diners', value: 'Lunch Diners'},
  { label: 'Luxury Car Enthusiasts', value: 'Luxury Car Enthusiasts'},
  { label: 'Luxury Shoppers', value: 'Luxury Shoppers'},
  { label: 'Male Fashionistas', value: 'Male Fashionistas'},
  { label: 'Male Grocery Shoppers', value: 'Male Grocery Shoppers'},
  { label: 'MetroPCS Subscribers', value: 'MetroPCS Subscribers'},
  { label: 'Millenial Parents', value: 'Millenial Parents'},
  { label: 'Millennials', value: 'Millennials'},
  { label: 'Morning Diners', value: 'Morning Diners'},
  { label: 'Morning QSR Goers', value: 'Morning QSR Goers'},
  { label: 'Morning Risers', value: 'Morning Risers'},
  { label: 'Motorcycle Enthusiasts', value: 'Motorcycle Enthusiasts'},
  { label: 'Movie Goers', value: 'Movie Goers'},
  { label: 'Museums Lovers', value: 'Museums Lovers'},
  { label: 'Music Lovers', value: 'Music Lovers'},
  { label: 'NBA Enthusiasts', value: 'NBA Enthusiasts'},
  { label: 'O2 Subscribers', value: 'O2 Subscribers'},
  { label: 'Optometrists', value: 'Optometrists'},
  { label: 'Outdoor Enthusiasts', value: 'Outdoor Enthusiasts'},
  { label: 'Park Lovers', value: 'Park Lovers'},
  { label: 'Party Animals', value: 'Party Animals'},
  { label: 'Payday Loan Visitors', value: 'Payday Loan Visitors'},
  { label: 'Pet Lovers', value: 'Pet Lovers'},
  { label: 'Pharmacy Regulars', value: 'Pharmacy Regulars'},
  { label: 'Pizza Lovers', value: 'Pizza Lovers'},
  { label: 'QSR Diners', value: 'QSR Diners'},
  { label: 'Recent Auto Repair Visitors', value: 'Recent Auto Repair Visitors'},
  { label: 'Recently Moved', value: 'Recently Moved'},
  { label: 'School Staff', value: 'School Staff'},
  { label: 'Shoe Fanatics', value: 'Shoe Fanatics'},
  { label: 'Shoppers', value: 'Shoppers'},
  { label: 'Sit-Down Diners', value: 'Sit-Down Diners'},
  { label: 'Small & Medium Businesses', value: 'Small & Medium Businesses'},
  { label: 'Soccer Moms', value: 'Soccer Moms'},
  { label: 'Spa Enthusiasts', value: 'Spa Enthusiasts'},
  { label: 'Sports Enthusiasts', value: 'Sports Enthusiasts'},
  { label: 'Sportsmen', value: 'Sportsmen'},
  { label: 'Sprint Subscribers', value: 'Sprint Subscribers'},
  { label: 'Stay At Home Moms', value: 'Stay At Home Moms'},
  { label: 'Suburban Moms', value: 'Suburban Moms'},
  { label: 'Swimmers', value: 'Swimmers'},
  { label: 'Telefonica Germany Subscribers', value: 'Telefonica Germany Subscribers'},
  { label: 'Three Subscribers', value: 'Three Subscribers'},
  { label: 'T-Mobile Subscribers', value: 'T-Mobile Subscribers'},
  { label: 'US Cellular Subscribers', value: 'US Cellular Subscribers'},
  { label: 'Verified Legal Drinking Age', value: 'Verified Legal Drinking Age'},
  { label: 'Verizon Wireless Subscribers', value: 'Verizon Wireless Subscribers'},
  { label: 'Veterans And Military', value: 'Veterans And Military'},
  { label: 'Virgin Mobile Subscribers', value: 'Virgin Mobile Subscribers'},
  { label: 'Virgin UK Subscribers', value: 'Virgin UK Subscribers'},
  { label: 'Vodafone Subscribers', value: 'Vodafone Subscribers'},
  { label: 'Working Moms', value: 'Working Moms'},
  { label: 'Young Drivers', value: 'Young Drivers'},
  { label: 'Young Professionals', value: 'Young Professionals'},
];

export default createClass({
  displayName: 'MobileAds',

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      mobile_ad_creatives: [],
      mobile_ad_impressions: "",
      mobile_ad_budget: "",
      mobile_ad_url: "",
      mobile_ad_targeted_audience: "",
      targeted_audience_options: targetedAudienceOptions
    };
  },

  radiusOptions() {
    let options = []
    for (let i = 0.1; i < 2.1; i += 0.1) {
      let radius = Number.parseFloat(i).toFixed(1);
      let text = "miles";
      if (radius == 1.0) text = "mile";
      options.push({ label: radius + " " + text, value: radius });
    }
    return options;
  },

  onRadiusChange(selection) {
    this.setState({ mobile_ad_radius: selection.value },
      this.props.setAttributes({ mobile_ad_radius: selection.value })
    );
  },

  onMobileAdUrlChange(event) {
    const mobile_ad_url = event.target.value
    this.setState({ mobile_ad_url: mobile_ad_url },
      this.props.setAttributes({ mobile_ad_url: mobile_ad_url })
    );
  },

  onMobileTargetedAudienceChange(option) {
    this.setState({ mobile_ad_targeted_audience: option },
      this.props.setAttributes({ mobile_ad_targeted_audience: option })
    );
  },

  onUploadComplete(upload, file) {
    this.setState({ mobileAdError: null, mobileAdSuccess: false })
    this.checkImageSize(file.url).then((size) => {
      if (this.allowedMobileAdSizes.includes(size)) {
        // currently lacking the needed campaign token as the campaign is not created yet
        UploadStore.uploadMobileAd(upload, file, size, this.props.campaign.token,
          {
            success: (data) => {
              this.refeshData();
              this.setState({ mobileAdSuccess: true })
            }
          }
        );
      } else {
        this.setState({ mobileAdError: "Please upload one of the above sizes." })
      }
    })
  },

  allowedMobileAdSizes: ["320x50", "640x100", "300x250", "600x500"],

  checkImageSize(url) {
    return new Promise((resolve, reject) => {
      let temp = new Image();
      temp.src = url;
      $(temp).one('load', () => {
        const size = temp.width + "x" + temp.height;
        resolve(size);
      });
    })
  },

  onImpressionsChange(event) {
    let mobile_ad_impressions = unformat(event.target.value);
    if (isNaN(mobile_ad_impressions)) {
      this.setState({ mobile_ad_impressions });
      return;
    }
    let mobile_ad_budget = ((mobile_ad_impressions / 1000) * mobileAdCPM).toFixed(2);
    mobile_ad_budget = formatNumber(mobile_ad_budget);
    mobile_ad_impressions = formatNumber(mobile_ad_impressions);
    this.setState({ mobile_ad_impressions, mobile_ad_budget },
      this.props.setAttributes({
        mobile_ad_impressions: mobile_ad_impressions,
        mobile_ad_budget: mobile_ad_budget
      })
    )
  },

  onBudgetChange(event) {
    let mobile_ad_budget = unformat(event.target.value);
    if (isNaN(mobile_ad_budget)) {
      this.setState({ mobile_ad_budget });
      return;
    }
    let mobile_ad_impressions = ((mobile_ad_budget / mobileAdCPM) * 1000).toFixed(0);
    mobile_ad_budget = formatNumber(mobile_ad_budget);
    mobile_ad_impressions = formatNumber(mobile_ad_impressions);
    this.setState({ mobile_ad_impressions, mobile_ad_budget },
      this.props.setAttributes({
        mobile_ad_impressions: mobile_ad_impressions,
        mobile_ad_budget: mobile_ad_budget
      })
    )
  },

  renderCostCalculator() {
    const { mobile_ad_impressions } = this.state;
    return (
      <div className="cost-calculator">
        <div className="col-xs-4 impressions">
          <label htmlFor="mobile_ad_impressions">
            Impressions
          </label>
          <input
            type="text"
            id="mobile_ad_impressions"
            name="mobile_ad_impressions"
            className="form-control"
            value={mobile_ad_impressions}
            onChange={this.onImpressionsChange}
          />
        </div>
        <div className="col-xs-1 symbol"> x </div>
        <div className="col-xs-2 cpm">
          <label>CPM</label>
          <div className="cpm_number">${mobileAdCPM}</div>
        </div>
        <div className="col-xs-1 symbol"> = </div>
        <div className="col-xs-4 budget">
          <label htmlFor="mobile_ad_budget">Budget</label>
          <div className="dollar_symbol">
            <input
              type="text"
              id="mobile_ad_budget"
              name="mobile_ad_budget"
              className="form-control"
              value={this.state.mobile_ad_budget}
              onChange={this.onBudgetChange}
            />
          </div>
        </div>
      </div>
    )
  },

  render() {
    const { mobile_ad_notification, targeted_audience_options } = this.state;
    const hideUploader = true;

    return (
      <div id="campaign-request-mobile-ads">
        <h4 className="section_header">
          <i className="fa fa-location-arrow" aria-hidden="true"></i> Geofenced Mobile Ads
        </h4>
        <div className="section">
          <div>
            <div className="section-header">Configure Geofenced Mobile Ads</div>

            <div className="description">
              <div className="description_paragraph">
                Run mobile ads that will appear on users' phones when they
                are near one of your outdoor ad units. The industry benchmark is to spend about 10% of what you're spending on outdoor ads on geofenced mobile ads.
              </div>
              <div className="description_paragraph">
                You can set up these mobile ads at anytime before your campaign starts. You will not be charged until you complete checkout.
              </div>
            </div>

            {mobile_ad_notification && <div className="alert alert-success">
              {mobile_ad_notification}
            </div>}

            <div className="geo_ad_form">
              {this.renderCostCalculator()}
              <div className="delivery_radius">
                <label htmlFor="mobile_ad_radius">Delivery Radius</label>
                <Select
                  name="mobile_ad_radius"
                  options={this.radiusOptions()}
                  value={this.radiusOptions().find(o => o.value ===this.state.mobile_ad_radius)}
                  onChange={this.onRadiusChange}
                  isClearable={false}
                  placeholder="Choose a delivery radius"
                />
              </div>
              <div className="landing_page_url">
                <label htmlFor="mobile_ad_url">
                  Landing Page URL
                </label>
                <input
                  type="text"
                  id="mobile_ad_url"
                  name="mobile_ad_url"
                  className="form-control"
                  placeholder="e.g. https://www.mysite.com"
                  value={this.state.mobile_ad_url}
                  onChange={this.onMobileAdUrlChange}
                />
              </div>
              {targeted_audience_options && <div className="targeted_audience">
                <label htmlFor="mobile_ad_targeted_audience">
                  Targeted Audience
                </label>
                <Select
                  className="selectInput"
                  isClearable={false}
                  placeholder="Select your targeted audience"
                  value={this.state.mobile_ad_targeted_audience}
                  options={this.state.targeted_audience_options}
                  isMulti
                  onChange={this.onMobileTargetedAudienceChange}
                />
            </div>}
            </div>

            {!hideUploader && <div>
              <div className="line_break"></div>

              <div className="section-title">
                Upload Creative
                <span className="max"> (max of 10)</span>
              </div>

              <div className="description">
                Please upload both a standard and retina size for each creative.
              </div>

              <div className="size_options">
                <div className="size_option">
                  Size option 1:
                  Standard: 320 x 50,  Retina: 640 x 100
                </div>
                <div className="size_option">
                  Size option 2:
                  Standard: 300 x 250,  Retina: 600 x 500
                </div>
              </div>

              {this.state.mobileAdError &&
                <div className="alert alert-danger">
                  {this.state.mobileAdError}
                </div>
              }

              {this.state.mobileAdSuccess &&
                <div className="alert alert-success">
                  Your mobile ad creative has been successfully uploaded.
                </div>
              }

              <div className="creative_upload clearfix">
                <UploadButton onComplete={this.onUploadComplete}>
                  <div>
                    <div className="file_icons">
                      <i className="fa fa-file-image-o"></i>
                    </div>
                    <div className="main_text">Drop files here</div>
                    <div className="sub_text">or <u>click to upload</u></div>
                  </div>
                </UploadButton>
              </div>

              {this.state.mobile_ad_creatives.length > 0 &&
                <div className="title">Current Creatives</div>
              }

              {this.state.mobile_ad_creatives.map(creative => {
                return <div className="creative" key={creative.id}>
                  <div className="creative_header">
                    <span className="file_name">{creative.name}</span>
                    <span className="size">{creative.size}</span>
                    <i className="fa fa-trash-o" onClick={this.removeCreative.bind(this, creative.id)}></i>
                  </div>
                  <div className="creative_image">
                    <img src={creative.image_url} />
                  </div>
                </div>
              })}
            </div>}
          </div>
        </div>
      </div>
    )
  },
});
