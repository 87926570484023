import React, { Component } from 'react';
import accounting, { formatNumber } from "accounting";

const IMPRESSIONS_INTERVAL = "4w";

class Impressions extends Component {
  // TODO: Verfiy if isPublicView to hide impressions (as in Campaign/index)

  showImpressions(){
    const { impressions } = this.props;
    return impressions && impressions > 0
  }

  formattedImpressions(){
    let { impressions } = this.props;
    impressions = accounting.format(impressions);
    return `${impressions} imp / ${IMPRESSIONS_INTERVAL}`;
  }

  render(){
    if (!this.showImpressions()) return null;
    return (
      <li>{this.formattedImpressions()}</li>
    )
  }

}

export default Impressions
