import React from 'react';

export default class FaceId extends React.Component {
  constructor(props) {
    super(props);
    const filters = this.props.filters || {};
    const query = filters.query || '';
    this.state = {
      query
    };
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onFaceIdChange = this.onFaceIdChange.bind(this);
  }

  onClear() {
    const { setFilter } = this.props;
    this.setState({ query: '' }, () => {
      setFilter('query', '');
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.query === '') {
      this.setState({ query: ''});
    }
  }

  onSubmitSearch() {
    const { setFilter } = this.props;
    const query = this.state.query && this.state.query.trim();
    setFilter({ 'query': query });
  }

  onFaceIdChange(e) {

    this.setState({query: e.target.value}, () => {
      this.onSubmitSearch();
    });
  }

  render() {
    const { query } = this.state;
    const showClear = query && query !== '';

    return (
      <li className="filter_by_face_id">
        <div className="search_by_face_id_container">
          <input
            className="search_by_face_id_input"
            onChange={this.onFaceIdChange}
            value={query}
            placeholder="Filter by Face ID"
          />
          {showClear && <img
            className="search-clear"
            src={ASSETS['x']}
            onClick={this.onClear}
          />}
        </div>
      </li>
    )
  }
};
