import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { connect } from 'react-redux';
import { push } from '../../actions/router'
import Table from './Table'

const UsersIndex = createClass({
  getInitialState() {
    return {}
  },

  render() {
    return (
      <div className="container">
        <h1>Accounts</h1>
        <Table />
      </div>
    );
  }
})

UsersIndex.propTypes = {

};

export default connect(
  null, { push }
)(UsersIndex);
