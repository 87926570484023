import cs from "classnames";
import Fuse from "fuse.js";
import React from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";
import { fetchCampaigns } from "../Inventory/actions";

const POPUP_WIDTH = 220;

class CampaignFilter extends React.Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
    this.debouncedSearch = _.debounce(this.searchByCampaignName, 200);
    this.state = {
      isExpanded: false,
      activeCampaign: null,
      narrowedList: null,
    };
  }

  componentDidMount() {
    const { fetchCampaigns } = this.props;
    document.addEventListener("click", this.onToggle);
    fetchCampaigns();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onClear() {
    this.setState(
      {
        activeCampaign: null,
        narrowedList: null,
      },
      this.onFilter,
    );
  }

  searchByCampaignName(query) {
    const options = {
      keys: ["name"],
      minMatchCharLength: 3,
      threshold: 0.4,
      maxPatternLength: 20,
    };
    const fuse = new Fuse(this.props.campaigns, options);
    this.setState({ narrowedList: fuse.search(query) });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.campaign && !nextProps.campaign) {
      this.setState({ activeCampaign: null });
    }
  }

  onCampaignSelect(campaign) {
    const { activeCampaign } = this.state;
    if (activeCampaign === campaign) {
      this.setState({ activeCampaign: null }, this.onFilter);
    } else {
      this.setState({ activeCampaign: campaign }, this.onFilter);
    }
  }

  onFilter() {
    const { activeCampaign } = this.state;
    const { filterUnits } = this.props;
    const campaign = !activeCampaign ? null : activeCampaign.id;
    this.setState({ isExpanded: false }, () => {
      _.defer(filterUnits, { campaign: campaign });
    });
  }

  getButtonLabel() {
    const { activeCampaign } = this.state;
    if (!activeCampaign) return "Campaign";
    return _.truncate(activeCampaign.name, { length: 18 });
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { activeCampaign, narrowedList } = this.state;
    const { campaigns } = this.props;
    const campaignsList = narrowedList && narrowedList.length ? narrowedList : campaigns;
    const clearStyle = {
      float: "right",
      top: -32,
      paddingRight: 12,
      position: "relative",
      color: "#999999",
    };

    return (
      <div className="filter_popup suppliers_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>CAMPAIGNS</h4>
        <a style={clearStyle} onClick={this.onClear}>
          Clear
        </a>
        <input
          autoFocus="true"
          type="text"
          className="narrow_list"
          onChange={e => this.debouncedSearch(e.target.value)}
          placeholder="Filter by name"
        />
        <ul>
          {campaignsList.map(campaign => {
            const onClick = () => {
              this.onCampaignSelect(campaign);
            };
            const isActive = activeCampaign === campaign;
            return (
              <li key={campaign.id} onClick={onClick} className={cs({ active: isActive })}>
                {_.truncate(campaign.name, { length: 26 })}
                <i className="fa fa-check" />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { isExpanded, activeCampaign } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: activeCampaign || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(
  ({ inventory: { campaigns } }) => {
    return { campaigns };
  },
  {
    fetchCampaigns,
    filterUnits,
  },
)(CampaignFilter);
