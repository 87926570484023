import React from 'react';
import createClass from 'create-react-class';
import MarketSearch from '../Onboarding/MarketSearch';
import { get, put, post, del } from '../../utils/api';
import GlobalActions from '../../actions/GlobalActions';

export default createClass({

  async updateUser() {
    const user = (({ id, email, name, role, media_types, market_ids }) => (
      { id, email, name, role, media_types, market_ids }
    ))(this.state);

    let validationErrors = false;

    if (!user.email) {
      this.setState({ email_required: true });
      validationErrors = true;
    }
    if (!user.role) {
      this.setState({ role_required: true });
      validationErrors = true;
    }

    if (validationErrors) return false

    if (user.id) {
      await put(`/api/v1/supplier_users/${user.id}`, user);
    } else {
      await post(`/api/v1/supplier_users/`, user);
    }
    this.props.onUserSave();
    return true;
  },

  async save(e) {
    e.preventDefault();
    if (await this.updateUser()) {
      this.resetUser();
    }
  },

  resetUser() {
    this.setState({ hide: true, email_required: false, role_required: false });
    this.props.resetUser();
  },

  getInitialState() {
    return {
      hide: true,
      assistants: [],
      assistant: {
        name: "",
        email: ""
      }
    };
  },

  onEmailChanged(event) {
    const email = event.target.value;
    this.setState({ email });
  },

  permissionChecked(role) {
    return this.state.role == role;
  },

  onPermissionChanged(event) {
    const role = event.target.value;
    this.setState({ role });
  },

  onMediaTypeChange(event) {
    const { media_types } = this.state;
    const index = media_types.indexOf(event.target.value);

    if (index > -1) {
      media_types.splice(index, 1);
    } else {
      media_types.push(event.target.value);
    }

    this.setState({ media_types });
  },

  async onMarketsChange(locations) {
    if (!locations.length) {
      this.setState({ locations });
      return;
    }

    const labels = this.state.locations ? this.state.locations.map(l => l.label) : [];
    const newLocation = locations.find(l => labels.indexOf(l.label) === -1);
    this.setState({ locations });
    if (!newLocation) return;

    const newMarket = await get(`/api/v1/market_overviews?location=${newLocation.label}`);

    const newMarkets = this.state.newMarkets || {};
    newMarkets[newLocation.label] = newMarket;

    const markets = this.state.markets
    if (!markets.find(m => m.id === newMarket.id)) {
      markets.push(newMarket);
    }
    this.setState({ markets, newMarkets, market_ids: markets.map(m => m.id) });
  },

  removeMarket(marketId) {
    const markets = this.state.markets;
    const index = markets.findIndex(m => m.id === marketId);
    markets.splice(index, 1);

    const newMarkets = this.state.newMarkets || {};
    Object.keys(newMarkets).forEach(k => newMarkets[k].id === marketId && delete newMarkets[k]);

    this.setState({ markets, newMarkets, market_ids: markets.map(m => m.id) });
  },

  async componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      const assistants = nextProps.user.id ? await get(`/api/v1/supplier_users/${nextProps.user.id}/assistants`) : [];

      this.setState({ ...nextProps.user, assistants: assistants, locations: [], hide: false });
      if (nextProps.user.email !== this.state.email) {
        this.setState({ newMarkets: {} });
      }
    }
  },

  checkbox(type, value, checker, onChange) {
    return (
      <div key={type + value} className="checkbox" >
        <label>
          <input
            value={value}
            type="checkbox"
            checked={checker(value)}
            onChange={onChange} />
          {value}
        </label>
      </div>
    );
  },

  mediaTypeCheck(mediaType) {
    return this.checkbox('media-market', mediaType, this.hasMediaType, this.onMediaTypeChange);
  },

  hasMediaType(mediaType) {
    return this.state.media_types.indexOf(mediaType) > -1;
  },

  onAssistantInputChange(attribute, input_value) {
    const assistant = this.state.assistant;

    this.setState({
      assistant: {
        ...assistant,
        [attribute]: input_value
      }
    });
  },

  async onAddAssistant() {
    const { user } = this.props;
    const { assistant } = this.state;
    const new_assistant = await post(`/api/v1/supplier_users/${user.id}/assistants`, assistant);
    this.setState({
      assistants: [
        ...this.state.assistants,
        new_assistant
      ],
      assistant: {
        name: "",
        email: ""
      }
    });
  },

  async deleteUser() {
    const confirmed = confirm("Are you sure you want to delete this user?");

    if (confirmed) {
      const res = await del(`/api/v1/supplier_users/${this.state.id}`);
      if (res.status == 200) {
        this.resetUser();
        this.props.onUserSave();
      } else {
        GlobalActions.showError("Something went wrong and could not delete the user");
      }
    }
  },

  async onDeleteAssistant(assistant_id) {
    const { user } = this.props;
    const confirmed = confirm("Are you sure you want to delete this assistant?");

    if (confirmed) {
      await del(`/api/v1/supplier_users/${user.id}/assistants/${assistant_id}`);
      const filtered_assistants = this.state.assistants.filter((a) => (a.id !== assistant_id));
      this.setState({
        assistants: filtered_assistants
      });
    }
  },

  render() {
    const { hide, email_required, email, name, role_required, markets, locations, newMarkets, assistant, assistants } = this.state;

    if (hide) { return <div /> }
    return (
      <div className="edit-user">
        <div className="row">
          <div className="col-sm-6">
            <h4>User Information</h4>
            <form onSubmit={this.save}>
              <div className="section">
                <div className={`form-group`}>
                  <label className="control-label" htmlFor="name">Full Name</label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    className="form-control"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    onBlur={(e) => this.setState({ name: e.target.value })}
                    style={{ maxWidth: '350px' }} />
                </div>
                <div className={`form-group ${email_required ? 'has-error' : ''}`}>
                  <label className="control-label" htmlFor="email">Email {email_required ? "(required)" : ""}</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    className="form-control"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    onBlur={this.onEmailChanged}
                    style={{ maxWidth: '350px' }} />
                </div>
                <div className={role_required ? 'has-error' : ''}>
                  <label className="control-label">Permission {role_required ? "(required)" : ""}</label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="permissionRadio"
                      value="contact"
                      checked={this.permissionChecked('contact')}
                      onChange={this.onPermissionChanged}
                    />
                    Contact
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="permissionRadio"
                      value="sales"
                      checked={this.permissionChecked('sales')}
                      onChange={this.onPermissionChanged}
                    />
                    Sales
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="permissionRadio"
                      value="admin"
                      checked={this.permissionChecked('admin')}
                      onChange={this.onPermissionChanged}
                    />
                    Admin
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="permissionRadio"
                      value="installer"
                      checked={this.permissionChecked('installer')}
                      onChange={this.onPermissionChanged}
                    />
                    Installer
                  </label>
                </div>
                <div className="form-group" style={{ overflow: "hidden" }}>
                  <label className="section-name">Media Types</label>
                  <div className="media-types">
                    {["Billboard", "Street Furniture", "Transit", "Retail"].map(m => this.mediaTypeCheck(m))}
                  </div>
                  <div className="media-types">
                    {["Wallscape", "Wildposting", "Alternative"].map(m => this.mediaTypeCheck(m))}
                  </div>
                </div>
              </div>
              <div className="complete">
                {this.state.id && <button className="btn btn-danger" onClick={this.deleteUser}>Delete</button>}
                <button className="btn btn-primary" type="submit">Save</button>
              </div>
            </form>
          </div>
          <div className="col-sm-6">
            <h4>Markets</h4>
            <div className="section">
              <div className="form-group">
                <ul className="market-list">
                  {
                    markets && markets.map((market) => (
                      <li key={`u-market-${market.id}`}>
                        <a
                          className="btn btn-danger"
                          onClick={() => this.removeMarket(market.id)}>
                          <i className="fa fa-times-circle" />
                          {market.name}
                        </a>
                      </li>
                    ))
                  }
                </ul>
                <div>
                  <label>Enter a location and the market associated with it will be added:</label>
                  <MarketSearch
                    markets={locations}
                    onMarketsChange={this.onMarketsChange}
                  />
                  <ul>
                    {
                      newMarkets && Object.keys(newMarkets).map((place, i) => (
                        <li key={`place-${i}`} style={{ margin: "10px 0" }}>
                          {place} belongs to the {newMarkets[place].name} market
                            </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <h4>Assistants</h4>
            <div className="section">
              <div className="form-group">
                <div className="assistatns">
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <label>Name</label>
                      <input
                        className="form-control"
                        type="text"
                        value={assistant.name}
                        onChange={(e) => this.onAssistantInputChange("name", e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label>Email</label>
                      <input
                        className="form-control"
                        type="email"
                        value={assistant.email}
                        onChange={(e) => this.onAssistantInputChange("email", e.target.value)}
                      />
                    </div>
                    <div className="col-sm-2">
                      <label>&nbsp;</label>
                      <a className="btn btn-primary" onClick={this.onAddAssistant}>Add</a>
                    </div>
                  </div>
                  <ul>
                    {assistants.map(a => <li key={a.id}>{`${a.name} - ${a.email}`} - <a onClick={() => { this.onDeleteAssistant(a.id) }}>delete</a></li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});
