import React from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';

import { isMobile } from '../../../utils/mobile';
import { loadUnitTags } from '../../../actions/unit_tag_actions';

import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';
import Fuse from "fuse.js";

const POPUP_WIDTH = 220;

class UnitTags extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.onClear = this.onClear.bind(this);
    this.debouncedSearch = debounce(this.searchForTags, 200);
    this.state = {
      isExpanded: false,
      selectedUnitTags: [],
      narrowedList: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { unit_tags } = this.props;
    if (nextProps.filters && nextProps.filters.unit_tag === null) {
      this.setState({
        selectedUnitTags: []
      });
    }
    if (unit_tags.length === 0 && nextProps.unit_tags.length > 0) {
      const filters = this.props.filters || {};
      const unit_tag = filters.unit_tag ? filters.unit_tag.split(',') : [];
      const selected = nextProps.unit_tags.filter((tag) => {
        return unit_tag.includes(tag.value);
      });
      this.setState({ selectedUnitTags: selected });
    }
  }

  componentDidMount() {
    const { loadUnitTags } = this.props;
    document.addEventListener("click", this.onToggle);
    loadUnitTags();
  }

  componentWillUnmount() {
    this.setState({ narrowedList: null });
    document.removeEventListener("click", this.onToggle);
  }

  searchForTags(query) {
    const options = {
      keys: ["label"],
      minMatchCharLength: 3,
      threshold: 0.4,
      maxPatternLength: 20,
    };
    const fuse = new Fuse(this.sortedTags(), options);
    this.setState({ narrowedList: fuse.search(query) });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear() {
    this.setState({ selectedUnitTags: [] }, this.onFilter);
  }

  onUnitTagSelect(option) {
    const { selectedUnitTags } = this.state;
    if (selectedUnitTags.includes(option)) {
      this.setState({ selectedUnitTags: selectedUnitTags.filter((unitTag) => unitTag.value !== option.value )}, this.onFilter);
    } else {
      this.setState({ selectedUnitTags: selectedUnitTags.concat([option])}, this.onFilter);
    }
  }

  onFilter() {
    const { selectedUnitTags } = this.state;
    const { setFilter } = this.props;
    setFilter({'unit_tag': selectedUnitTags});
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedUnitTags } = this.state;
    if (!selectedUnitTags.length) return 'Attributes';
    if (selectedUnitTags.length == 1) return `${selectedUnitTags[0].label} only`;
    if (selectedUnitTags.length > 1) return `Attributes · ${selectedUnitTags.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  sortedTags() {
    const { unit_tags } = this.props;
    return sortBy(unit_tags, ['value']);
  }

  renderSearchField() {
    return (
      <input
        autoFocus={true}
        type="text"
        className="narrow_list"
        onChange={e => this.debouncedSearch(e.target.value)}
        placeholder="Filter by name"
      />
    );
  }

  renderSelectedTags() {
    const { selectedUnitTags } = this.state;
    return (
      <ul>
        <li className="separator">Selected tags</li>
        {selectedUnitTags.map(option => {
          const onClick = () => {
            this.onUnitTagSelect(option);
          }
          return (
            <li key={option.value} onClick={onClick} className={cs({ active: true })}>
              {option.label}
              <i className="fa fa-check" />
            </li>
          );
        })}
        <li className="separator">All Tags</li>
      </ul>
    );
  }

  renderPopup() {
    const { selectedUnitTags, narrowedList } = this.state;
    const unit_tags = this.sortedTags();
    const unit_tags_list = narrowedList && narrowedList.length ? narrowedList : unit_tags;
    return (
      <div className="filter_popup unit_tags_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>ATTRIBUTES</h4>
        {this.renderSearchField()}
        {selectedUnitTags.length > 0 && this.renderSelectedTags()}
        <ul>
          {unit_tags_list.map((option) => {
            const onClick = () => {
              this.onUnitTagSelect(option);
            }
            const isActive = selectedUnitTags.includes(option);
            return (
              <li key={option.value} onClick={onClick} className={cs({ active: isActive})}>
                {option.label}
                <i className="fa fa-check" />
              </li>
            );
          })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a className="clear hidden-xs" onClick={this.onClear}>Clear</a>
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const { isExpanded, selectedUnitTags } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs({active: (selectedUnitTags.length > 0 || isExpanded)})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};

const mapStateToProps = ({ unit_tags }) => ({ unit_tags });
export default connect(
  mapStateToProps,
  { loadUnitTags }
 )(UnitTags)
