import React, { Component } from 'react';
import moment from 'moment';
import {} from 'moment-range';
import DateRangePicker from "./DatePicker/Range";
import { Modal, Button } from "react-bootstrap";
import GlobalActions from '../actions/GlobalActions';

class CalendarPopup extends Component {

  constructor(props) {
    super(props);
    const startDate = moment(this.props.startDate);
    const endDate = moment(this.props.endDate);
    const range = startDate.isValid() && endDate.isValid() ? moment.range(startDate, endDate) : null;
    this.state = {
      range: range
    }
  }

  componentDidUpdate(oldProps) {
    const startDate = moment(this.props.startDate);
    const endDate = moment(this.props.endDate);
    if (!startDate.isSame(oldProps.startDate, 'day') || !endDate.isSame(oldProps.endDate, 'day')) {
      const range = startDate.isValid() && endDate.isValid() ? moment.range(startDate, endDate) : null;
      this.setState({
        range: range
      })
    }
  }

  onSelect(range, states) {
    const { isIntersectionStaticKiosk } = this.props;
    if (!isIntersectionStaticKiosk || this.validIntersectionDates(range)) {
      this.setState({
        range: range
      })
    } else {
      GlobalActions.showMessage(
        'Selected dates must start on the first Monday of a month, end on a Sunday and span at least 4 weeks.'
        , 'info'
      )
    }
  }

  validIntersectionDates(range) {
    const MONDAY = 1;
    const SUNDAY = 7;
    const FOUR_WEEKS = 27; // Four weeks minus one day so it ends on a Sunday.
    const { start, end } = range;
    if (start.isoWeekday() != MONDAY || end.isoWeekday() != SUNDAY) return false;
    if (range.diff('days') < FOUR_WEEKS) return false;
    return true;
  }

  onRequest(){
    const { onDateSelect, onHide } = this.props;
    const { start, end } = this.state.range;
    onDateSelect(start, end);
    onHide();
  }

  render() {
    const { show, onHide, title, confirmButtonLabel, isIntersectionStaticKiosk } = this.props;
    const { range } = this.state;
    const helpMessage = isIntersectionStaticKiosk ? '* Intersection static kiosks can only start on the first Monday of a month' : null;
    const isValidRange = !isIntersectionStaticKiosk || this.validIntersectionDates(range);

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{title || 'Select your dates'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker
            onSelect={(range, states) => this.onSelect(range, states)}
            singleDateRange={true}
            numberOfCalendars={2}
            selectionType="range"
            value={isValidRange ? range : null}
            helpMessage={helpMessage}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Cancel</Button>
          <Button bsStyle="primary" onClick={() => this.onRequest()}>{confirmButtonLabel || 'Confirm'}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default CalendarPopup;
