import React, { Component } from 'react';

class UnitPopupError extends Component {

  render() {
    const { onRetry } = this.props;
    return (
      <div className="unit_popup_error">
        <h4>Oops! Something went wrong.</h4>
        <p>No worries. Let's try this again.</p>
        <button className="btn btn-default btn_retry" onClick={onRetry}>
          <span>
            Refresh
            <i className="fa fa-refresh" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    )
  }
}

export default UnitPopupError
