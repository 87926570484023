import React, { PureComponent } from 'react';

interface Props {
  hide?: boolean;
}
export default class Loading extends PureComponent<Props> {
  render() {
    return (
      <div className="new_loading" style={{ textAlign: "center", padding: '80px 0', width: '100%', display: this.props.hide ? "none" : "block" }}>
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20"></circle>
        </svg>
        <p style={{ marginTop: '24px'}}>{this.props.children}</p>
      </div>
    );
  }

}
