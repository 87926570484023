import React, { Component } from "react";
import { connect } from "react-redux";

class UnitsAddedCount extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { unit_text } = this.props;
    const units_added = this.props.units_added || [];
    const filtered_units = units_added.filter(n => n);
    const style = this.props.style || {};
    if (filtered_units && filtered_units.length) {
      return (
        <div className="float-right units-added" style={style}>
          <i className="fa fa-check" aria-hidden="true"></i>{filtered_units.length} {unit_text ? unit_text : "Units Added"}
        </div>
      );
    } else {
      return null
    };
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { units_added } }) => ({ units_added }),
  {}
)(UnitsAddedCount);
