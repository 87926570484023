import React, { Component } from 'react';

class Thumbnail extends Component {

  render(){
    const { src } = this.props;
    if(src && src != ''){
      return (
        <div
          className="campaign_catalog_thumb"
          style={{ backgroundImage: `url(${src})` }}
        />
      )
    } else {
      return (
        <div className="campaign_catalog_thumb no_image">
          <i className="fa fa-image" style={{ marginRight: 0 }} />
        </div>
      )
    }
  }

}

export default Thumbnail
