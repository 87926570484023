import accounting from "accounting";
import cs from "classnames";

export const list_item_classes = (unit, campaign_unit) => {
  return cs(
    `timelineItem__flight supplier_id_${unit.supplier_id}`,
    `flight_token_${campaign_unit.token}`,
    {
      timelineItem__flight_on_hold: campaign_unit.workflow_state == 'on_hold',
      timelineItem__flight_auto_renew: campaign_unit.auto_renew,
      timelineItem__flight_contract_not_received: campaign_unit.contract_not_received,
      timelineItem__flight_run_of_network: !!!campaign_unit.contract_not_received && campaign_unit.run_of_network,
      timelineItem__flight_booked: !!!campaign_unit.run_of_network && !!!campaign_unit.contract_not_received && !!!campaign_unit.auto_renew && campaign_unit.workflow_state == 'booked',
      timelineItem__flight_available: campaign_unit.workflow_state == 'available',
      timelineItem__flight_proposed: campaign_unit.workflow_state == 'proposed',
      timelineItem__flight_movable: campaign_unit.workflow_state == 'movable',
    }
  );
};

export const text_for_flight = (unit, campaign_unit) => {
  let tooltip_text, bar_text;

  if (campaign_unit.workflow_state != "available") {
    let pretty_workflow_state;
    if (campaign_unit.workflow_state == "on_hold") {
      pretty_workflow_state = "Hold";
    } else {
      pretty_workflow_state =
        campaign_unit.workflow_state[0].toUpperCase() + campaign_unit.workflow_state.substr(1);
    }

    if (campaign_unit.campaign_name && campaign_unit.advertiser_name) {
      bar_text = `${campaign_unit.advertiser_name} \u2013 ${campaign_unit.campaign_name}`;
    } else if (campaign_unit.campaign_name) {
      bar_text = campaign_unit.campaign_name;
    } else {
      bar_text = pretty_workflow_state;
    }

    if (campaign_unit.total_price && !!!unit.has_quantity)
      bar_text += `: ${accounting.formatMoney(campaign_unit.total_price)}`;
    if (campaign_unit.price)
      bar_text += ` \u2013 ${accounting.formatMoney(campaign_unit.price)}/4w`;
    tooltip_text = bar_text;
  } else {
    const targetPrice = unit.price ? accounting.formatMoney(unit.price) : "";

    let allPrices = "";
    if (unit.rate_card_price)
      allPrices += `Rate Card: ${accounting.formatMoney(unit.rate_card_price)} |`;
    if (unit.local_price) allPrices += ` | Local: ${accounting.formatMoney(unit.local_price)}`;
    if (unit.floor_price) allPrices += ` | Floor: ${accounting.formatMoney(unit.floor_price)}`;

    bar_text = `Available${targetPrice ? ` \u2013 ${targetPrice}` : ""}`;
    tooltip_text = allPrices;
  }

  if (unit.has_quantity) bar_text += ` \u2013  ${campaign_unit.quantity}/w qty`;
  return { bar_text, tooltip_text };
};
