import * as React from "react";
import Theme from "../../theme";

export interface IAnalyticsHeaderProps {
  children?: React.ReactNode;
}

const Content = ({ children }: { children: React.ReactNode }) => (
  <div style={contentStyle()}>
    {children}
  </div>
);

const contentStyle = () => ({
  padding: Theme.CONTENT_PADDING,
} as React.CSSProperties);

export default Content;
