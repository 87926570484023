import {
  COMPLETE_SUPPLIER_TASK,
} from "./actions";

const initialState = {
  supplier_task: null,
  inventory_file: null,
  transit_units: [],
  grid_data: [],
  grid_has_errors: null,
  grid_error_message: null,
  grid_error_row: null,
  inventory_images: [],
  rfp_summary: {
    total_units: 0,
    images_specs_uploads: 0,
    total_impressions: 0,
    total_cost: 0
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_SUPPLIER_TASK: return updateSupplierTask(state, action.payload);
    default: return state;
  }
}

const updateSupplierTask = (state, supplier_task) => {
  return {
    ...state,
    supplier_task
  }
}
