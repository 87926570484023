import { get } from '../../utils/api'

const fetchFeaturesSuccess = (feature_flags) => ({
  type: 'FETCH_FEATURE_FLAGS',
  feature_flags
})

export const asyncFetchFeatures = () => async (dispatch) => {
  const { features } = await get('/api/v1/feature_flags')
  dispatch(fetchFeaturesSuccess(features))
}