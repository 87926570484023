import React from 'react';
import { FormControl, ControlLabel } from 'react-bootstrap';
import DropzoneS3Uploader from '../../DropzoneS3Uploader.jsx'
import { Line } from 'rc-progress';
import moment from 'moment';
import createClass from 'create-react-class';
import CampaignStore from '../../../stores/CampaignStore';
import AuthStore from '../../../stores/AuthStore';
import UploadStore from '../../../stores/UploadStore';
import SupplierName from '../../SupplierName';

const CampaignInventoryUpload = createClass({
  displayName: 'CampaignInventoryUpload',

  getInitialState() {
    return {
      auth: AuthStore.getState(),
      campaign: this.props.campaign || {},
      progress: 0,
      vendors: [],
      process_after_upload: false,
    };
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
      UploadStore.listen(this.onUploadChange),
    ];

    this.loadCampaign();
    this.loadVendors();
    this.interval = setInterval(this.loadCampaign, 5000);
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.campaign) {
      const campaign = Object.assign({}, this.state.campaign, nextProps.campaign);
      this.setState({ campaign }, this.loadCampaign);
    }
  },

  componentWillUnmount() {
    clearInterval(this.interval);
    this.unsubscribeList.map(fn => fn());
  },

  onAuthChange() {
    const auth = AuthStore.getState();
    this.setState({ auth });
    this.loadCampaign();
  },

  onUploadChange(eventName, response) {
    if (eventName == 'upload:uploaded') {
      this.loadCampaign();
    }

    if (eventName == 'upload:error') {
      alert(response);
    }
  },

  loadCampaign() {
    const campaign_id = this.state.campaign ? this.state.campaign.id : null

    if (campaign_id) {
      CampaignStore.loadCampaignWithInventoryFiles(campaign_id, {
        success: campaign => this.setState({ campaign }),
      });
    } else {
      console.log("No campaign present", this.state)
    }
  },

  onExpand() {
    this.props.onExpand();
  },

  onProgress(progress) {
    this.setState({ progress });
  },

  onDrop() {
    this.setState({ progress: 0 });
  },

  handleFinishedUpload(upload) {
    const file = upload.file;
    this.setState({ progress: null });
    if (confirm("Are you sure you want to upload this file to this Vendor? Doing so could cause duplication or overwrite existing data.")) {
      UploadStore.uploadInventoryFile(upload, file, this.state.campaign.id, this.state.supplier_id, {
        process_after_upload: this.state.process_after_upload,
      });
    }
  },

  loadVendors() {
    $.get('/api/v1/vendors', (response) => {
      this.setState({ vendors: response.vendors });
    });
  },

  render() {
    const { user } = AuthStore.getState();
    const { campaign } = this.state;
    const files = campaign.inventory_files || [];
    const time = (new Date()).getTime();
    const uploaderProps = {
      style: { cursor: 'pointer' },
      maxFileSize: 1024 * 1024 * 50,
      server: '',
      s3Url: `https://${AppConfig.s3Bucket}.s3.amazonaws.com/user_uploads/${time}`,
      signingUrlQueryParams: { time },
    };

    const showUpload = (this.state.supplier_id);
    const currentUploadTemplateUrl = AppConfig.currentUploadTemplateUrl;

    return (<div className="createCampaign" style={{ marginTop: 10 }}>
      <div>
        {user.is_admin &&
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-sm-6">
              <ControlLabel>Vendor:</ControlLabel>
              <FormControl componentClass="select" onChange={(e) => { this.setState({ supplier_id: e.target.value }); }} defaultValue={this.state.supplier_id}>
                <option />
                {this.state.vendors.map(vendor => <option value={vendor.id} key={vendor.id}>{vendor.name}</option>)}
              </FormControl>
            </div>
          </div>
        }
      </div>

      {showUpload && <DropzoneS3Uploader onDrop={this.onDrop} onProgress={this.onProgress} onFinish={this.handleFinishedUpload} {...uploaderProps}>
        {this.state.progress ?
          <Line percent={this.state.progress} strokeWidth="4" strokeColor="#44ac6b" /> :
          <div style={{ padding: 20, marginBottom: 10, border: '1px dotted gray', background: '#eee' }}>
            <div className="btn btn-primary">Upload CSV or Excel file</div>
            <span style={{ marginLeft: 10 }}>
              You can also drag and drop into this box. <a href={currentUploadTemplateUrl}>Download Template</a>
            </span>
          </div>}
      </DropzoneS3Uploader>}

      {files.length > 0 && <table className="table table-striped">
        <thead>
          <tr>
            <th width="30%">Upload Date</th>
            {user.is_admin && <th width="30%">Vendor</th>}
            <th width="30%">Proposal</th>
            <th width="30%">Uploaded By</th>
            <th width="30%">File</th>
            <th width="30%">Units</th>
            <th width="20%">Status</th>
            {user.is_admin && <th width="20%" />}
          </tr>
        </thead>
        <tbody>
          {files.map(file => <tr key={file.id}>
            <td>{moment(file.created_at).format('LL')}</td>
            {user.is_admin && <td width="30%"><SupplierName user={user} supplier={file.supplier}/></td>}
            <td width="30%">{file.proposal_name}</td>
            <td>{file.user.email}</td>
            <td><a href={`${file.file_url}`} target="_blank">{file.file}</a></td>
            <td>{file.rows_count || "tbd"}</td>
            <td>{file.workflow_state}</td>
            {user.is_admin && <td><a href={`/admin/files/${file.id}`} target="_blank">View</a></td>}
          </tr>)}
        </tbody>
      </table>}
    </div>);
  },
});

export default CampaignInventoryUpload;
