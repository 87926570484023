import React from 'react';
import createClass from 'create-react-class';

import { browserHistory } from 'react-router-legacy';
import UnitStore from '../stores/UnitStore';
import Supplier from './Supplier';
import Careers from './Careers';

export default createClass({
  displayName: 'PagesRoute',

  getInitialState() {
    return {
      supplier: {},
      units: [],
    };
  },

  componentDidMount() {
    this.loadPage(this.props.params.splat);
  },

  componentWillReceiveProps(nextProps) {
    this.loadPage(nextProps.params.splat);
  },

  loadPage(splatParam) {
    const pages = ['drake', 'compare-to-direct', 'otf-case-study', 'careers'];
    const page = splatParam.toLowerCase();

    if (pages.includes(page)) {
      this.setState({ page });
    }
  },

  render() {
    if (this.state.page == 'careers') {
      return <Careers />;
    } else if (this.state.page == 'compare-to-direct') {
      return (<div style={{ background: '#fff', textAlign: 'center' }}>
        <img style={{ maxWidth: 800, width: '100%' }} src="https://adquick-production.s3.amazonaws.com/uploads/inventory_file/file/149/AdQuick_v_Direct.png" />
      </div>);
    } else if (this.state.page == 'drake') {
      return (<div style={{ background: '#2a292f', textAlign: 'center' }}>
        <img style={{ maxWidth: 800, width: '100%' }} src="https://adquick-production.s3.amazonaws.com/uploads/inventory_file/file/130/drake_case_study.jpg" />
      </div>);
    } else if (this.state.page == 'otf-case-study') {
      return (<div style={{ background: '#fff', textAlign: 'center' }}>
        <img style={{ maxWidth: 800, width: '100%' }} src="https://adquick-production.s3.amazonaws.com/uploads/inventory_file/file/154/OTF_Direct_Response_with_AdQuick.png" />
      </div>);
    } else {
      return <div style={{textAlign:'center',padding:100,fontSize:20}}>
        <div><b>404</b></div>
        Page Not Found
      </div>
    }
  },
});
