import React from 'react';
import { browserHistory } from 'react-router-legacy';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore'
import GlobalActions from '../../actions/GlobalActions'
import moment from 'moment'

const Connections = createClass({
  displayName: 'Connections',

  getInitialState() {
    return {
      isLoggedIn: AuthStore.isLoggedIn(),
      emails: [],
      filter: this.props.location.query.filter || 'me'
    }
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ]

    this.fetchEmails()
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.query.filter != this.state.filter) {
      this.setState({ filter: nextProps.location.query.filter }, this.fetchEmails)
    }
  },

  onAuthChange() {
    this.setState({
      isLoggedIn: AuthStore.isLoggedIn()
    })

    if (AuthStore.isLoggedIn()) {
      this.fetchEmails()
    } else {
      GlobalActions.openPopup('login')
    }
  },

  fetchEmails() {
    const { filter } = this.state
    if (filter == "all") {
      $.get('/api/v1/sent_emails?filter=all').then(emails => this.setState({ emails }))
    } else if (filter == "me") {
      $.get('/api/v1/sent_emails?filter=me').then(emails => this.setState({ emails }))
    }
  },

  setFilter(filter) {
    browserHistory.push('/sent_emails?filter=' + filter)
  },

  render() {
    const { isLoggedIn, emails, filter } = this.state

    if (isLoggedIn) {
      return (
        <div className="container">
          <h1>Sent Emails</h1>

          <p>
            <a onClick={() => this.setFilter('all')} style={{marginRight:50,fontWeight:(filter == 'all' ? 'bold' : 'normal')}}>All</a>
            <a onClick={() => this.setFilter('me')} style={{marginRight:50,fontWeight:(filter == 'me' ? 'bold' : 'normal')}}>Mine</a>
          </p>

          <table className="table table-striped">
            <thead>
              <tr style={{fontWeight:'bold'}}>
                <td>Sender</td>
                <td>Recipient</td>
                <td>Subject</td>
                <td>Sent At</td>
                <td>Opened At</td>
                <td>Clicked At</td>
              </tr>
            </thead>
            <tbody>
              {emails.map(email => <tr key={email.id}>
                <td>{email.user_display_name}</td>
                <td>{email.to}</td>
                <td>{email.subject}</td>
                <td>{email.sent_at && moment(email.sent_at).format('lll')}</td>
                <td>{email.opened_at && moment(email.opened_at).format('lll')}</td>
                <td>{email.clicked_at && moment(email.clicked_at).format('lll')}</td>
              </tr>)}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="text-center" style={{ padding: 50 }}>Please login</div>
      )
    }
  }
})

export default Connections;
