import React from 'react';
import { Link } from 'react-router-legacy';
import createClass from 'create-react-class';

import UnitStore from '../stores/UnitStore';

const OutdoorCompanyList = createClass({

  displayName: 'OutdoorCompanyList',

  getInitialState() {
    return {
      suppliers: [],
    };
  },

  componentDidMount() {
    this.setState({ suppliers: this.props.suppliers || [] });
    this.loadSuppliers();
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ suppliers: this.props.suppliers || [] });
  },

  loadSuppliers() {
    UnitStore.loadSuppliers((response) => {
      this.setState({ suppliers: response });
    });
  },

  renderSuppliers() {
    return this.state.suppliers.map(supplier => <div className="supplier" key={supplier.id}>
      <div className="name" style={{ marginBottom: 5 }}>
        <Link to={`/${supplier.slug}`}>{supplier.name}</Link>
      </div>
    </div>);
  },

  render() {
    return this.state.suppliers.length > 0 ?
      <div className="page page-supplier">
        <h1>Outdoor Billboard Companies</h1>
        {this.renderSuppliers()}
      </div> :
      <div className="page-loading" />;
  },
});

export default OutdoorCompanyList;
