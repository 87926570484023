import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { allUniqueUnits } from '../../models/Units';
import Fuse from "fuse.js";
import uniqBy from "lodash/uniqBy"
import orderBy from "lodash/orderBy"
import countBy from "lodash/countBy"

import { filterUnits } from "../../actions/UnitsActions";

const POPUP_WIDTH = 220;

class MarketFilter extends Component {
  constructor(props) {
    super(props);
    this.debouncedSearch = _.debounce(this.searchByName, 200);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = {
      isExpanded: false,
      selectedMarkets: this.selectedMarketsFromInitialValue(),
    };
  }

  searchByName(query) {
    const options = {
      keys: ["market_name"],
      minMatchCharLength: 3,
      threshold: 0.4,
      maxPatternLength: 20,
    };
    const fuse = new Fuse(this.allUnits(), options);
    this.setState({ narrowedList: fuse.search(query) });
  }

  allUnits() {
    return allUniqueUnits(this.props.units)
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, units } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (units.loaded && !prevProps.units.loaded) {
      this.setState({ selectedMarkets: this.selectedMarketsFromInitialValue() }, this.onFilter)
    }
  }

  parseInitialValue() {
    const { initialValue } = this.props
    if(!initialValue) { return [] }
    return initialValue.map(dma_id => Number(dma_id))
  }

  selectedMarketsFromInitialValue() {
    const { initialValue } = this.props;
    if (this.allUnits().length == 0 || !initialValue) return [];
    return this.marketList().filter(m => this.parseInitialValue().includes(m.dma_id))
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear() {
    this.setState({ selectedMarkets: [] }, this.onFilter);
  }

  onMarketSelect(option) {
    const { selectedMarkets } = this.state;
    const isSelected = !selectedMarkets.find(m => m.name === option.name)
    if (isSelected) { return this.setState({ selectedMarkets: selectedMarkets.concat([option]) }, this.onFilter) }
    this.setState({ selectedMarkets: selectedMarkets.filter(m => m.name !== option.name) }, this.onFilter)
  }

  onFilter() {
    const { selectedMarkets } = this.state;
    const { filterUnits, handleQueryParams } = this.props;

    _.defer(filterUnits, { markets: selectedMarkets.map(m => m.dma_id) });
    handleQueryParams({ 'markets[]': selectedMarkets.map(m => m.dma_id) })
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ narrowedList: [] });
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedMarkets } = this.state;
    if (!selectedMarkets.length) return "Market";
    if (selectedMarkets.length == 1) return `${_.truncate(selectedMarkets[0].name, {length: 10})} only`;
    if (selectedMarkets.length > 1) return `Markets · ${selectedMarkets.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { selectedMarkets } = this.state;
    const marketList = this.marketList()

    return (
      <div className="filter_popup market_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>Market</h4>
        <input
          autoFocus={true}
          type="text"
          className="narrow_list"
          onChange={e => this.debouncedSearch(e.target.value)}
          placeholder="Filter by name"
        />
        <ul>
          {marketList.map(option => {
            const onClick = () => {
              this.onMarketSelect(option);
            };
            const isActive = selectedMarkets.find(m => m.name === option.name);
            return (
              <li key={option.name} onClick={onClick} className={cs({ active: isActive })}>
                {_.truncate(option.name, {length: 15})}
                <i className="count">({option.count})</i>
                <i className="fa fa-check" />
              </li>
            );
          })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  allUnitMarketList() {
    const { narrowedList } = this.state
    const unitList = (!!narrowedList && narrowedList.length > 0) ? narrowedList : this.allUnits()
    return unitList.map(u => Object.assign({}, { name: u.market_name, dma_id: u.dma_id }))
  }

  possibleMarketList() {
    return uniqBy(this.allUnitMarketList(), 'name')
  }

  countByMarket() {
    return countBy(this.allUnitMarketList(), 'name')
  }

  reducedMarketListWithCount() {
    const marketCount = this.countByMarket()
    return this.possibleMarketList().reduce((agg, value) => {
      const count = marketCount[value.name]
      value['count'] = count
      agg.push(value)
      return agg
    }, [])
  }

  marketList() {
    return orderBy(Object.entries(this.reducedMarketListWithCount()).map((tuple) => tuple[1]), ['name'], 'asc')
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ campaign, units }) => ({
  campaign,
  units,
});

export default connect(mapStateToProps, { filterUnits })(MarketFilter);
