import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { loadMediaTypes } from "../../actions/media_type_actions";
import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";

const POPUP_WIDTH = 220;

class MediaTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = {
      isExpanded: false,
      selectedMediaTypes: this.selectedMediaTypesFromInitialValue(),
    };
  }

  componentDidMount() {
    const { loadMediaTypes, campaign } = this.props;
    const campaignId = campaign && campaign.campaignId;
    document.addEventListener("click", this.onToggle);
    loadMediaTypes(campaignId);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, media_types, initialValue, units, loadMediaTypes, campaign } = this.props
    const campaignId = campaign && campaign.campaignId

    if (!isActive && prevProps.isActive) { this.onClear() }
    if (units.all_units.length !== prevProps.units.all_units.length) { loadMediaTypes(campaignId) }
    if (initialValue && media_types !== prevProps.media_types) {
      this.setState({ selectedMediaTypes: media_types.filter(mt => initialValue.includes(mt.value)) });
    }
    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ selectedMediaTypes: media_types.filter(mt => initialValue.includes(mt.value)) });
    }
  }

  selectedMediaTypesFromInitialValue() {
    // Because we only store a simple string for each selected media type on Redux,
    // here we convert from simple values to complex values:.
    // before: ['bus']
    // after:  [{name: 'Bus', value: 'bus', count: 5, id: 12}]
    const { media_types, initialValue } = this.props;
    if (!media_types.length || !initialValue) return [];
    return initialValue.map(v => {
      return media_types.find(mt => mt.value == v);
    });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear() {
    this.setState({ selectedMediaTypes: [] }, this.onFilter);
  }

  onMediaTypeSelect(option) {
    const { selectedMediaTypes } = this.state;
    if (selectedMediaTypes.includes(option)) {
      this.setState(
        { selectedMediaTypes: selectedMediaTypes.filter(mediaType => mediaType.value !== option.value) },
        this.onFilter,
      );
    } else {
      this.setState({ selectedMediaTypes: selectedMediaTypes.concat([option]) }, this.onFilter);
    }
  }

  onFilter() {
    const { selectedMediaTypes } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    _.defer(filterUnits, { type: selectedMediaTypes.map(type => type.value) });
    handleQueryParams({ 'type[]': selectedMediaTypes.map(type => type.value) })
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedMediaTypes } = this.state;
    if (!selectedMediaTypes.length) return "Media type";
    if (selectedMediaTypes.length == 1) return `${selectedMediaTypes[0].name} only`;
    if (selectedMediaTypes.length > 1) return `Media Types · ${selectedMediaTypes.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { selectedMediaTypes } = this.state;
    const { media_types, campaign, isBrowseView } = this.props;
    const [present, notPresent] = media_types.reduce(
      (result, mt) => {
        result[mt.count > 0 ? 0 : 1].push(mt);
        return result;
      },
      [[], []],
    );
    const showNotPresent = _.get(campaign, "permissions.can_add_units_near_pois", false) && notPresent.length;
    return (
      <div className="filter_popup media_type_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>MEDIA TYPE</h4>
        <ul>
          {present.map(option => {
            const onClick = () => {
              this.onMediaTypeSelect(option);
            };
            const isActive = selectedMediaTypes.includes(option);
            return (
              <li key={option.value} onClick={onClick} className={cs({ active: isActive })}>
                {option.name}
                <i className="count">({option.count})</i>
                <i className="fa fa-check" />
              </li>
            );
          })}
          {showNotPresent && !isBrowseView && <li className="separator">Zero count</li>}
          {(showNotPresent || isBrowseView) &&
            notPresent.map(option => {
              const onClick = () => {
                this.onMediaTypeSelect(option);
              };
              const isActive = selectedMediaTypes.includes(option);
              return (
                <li key={option.value} onClick={onClick} className={cs({ secondary: !isBrowseView, active: isActive })}>
                  {option.name}
                  <i className="fa fa-check" />
                </li>
              );
            })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ campaign, media_types, units }) => ({
  campaign,
  media_types,
  units,
});

export default connect(mapStateToProps, { filterUnits, loadMediaTypes })(MediaTypeFilter);
