import { connect } from 'react-redux';
import 'rc-tooltip/assets/bootstrap.css';

import { Modal } from 'react-bootstrap';
import React from 'react';
import Select from 'react-select';
import Tooltip from 'rc-tooltip';
import createClass from 'create-react-class';

import AdwordsAuthorize from './Adwords/Authorize';
import AdwordsAnalytics from './Adwords';
import DirectResponses from './DirectResponses';
import Instagram from './Instagram';
import UploadButton from '../UploadButton';
import UploadStore from '../../stores/UploadStore';
import GlobalStore from '../../stores/GlobalStore';
import { Tabs, Tab } from 'react-bootstrap';
import { browserHistory } from 'react-router-legacy';
import { get } from '../../utils/api';
import Loading from '../Loading';
import SegmentAnalytics from './Segment'
import SegmentApiKey from './Segment/ApiKey'
import FacebookAnalytics from './Facebook'

const mobileAdCPM = 4.78;

const CampaignAnalytics = createClass({
  displayName: 'Campaign/CampaignAnalytics',

  getInitialState() {
    return {
      sms_keywords: "",
      sms_default_response: "",
      instagram: false,
      facebook: false,
      twitter: false,
      hashtags: "",
      track_design_asset_shares: false,
      mobile_ad_impressions: "",
      mobile_ad_budget: "",
      mobile_ad_radius: "",
      mobile_ad_url: "",
      mobile_ad_creatives: [],
      mobileAdErrors: [],
      mobileAdSuccess: false,
      isLoading: true,
      survey_questions: {
        0: '',
        1: '',
        2: '',
        3: ''
      },
      activeTab: this.props.step
    };
  },

  analyticsApiUrl() {
    return `/api/v1/campaigns/${this.props.campaign.token}/analytics/old_analytics`;
  },

  showSegment() {
    return this.props.feature_flags.find(f => f.name === "segment_integration" && f.status == true)
  },

  showFacebook() {
    return this.props.feature_flags.find(f => f.name === "facebook_integration" && f.status == true)
  },

  componentDidMount() {
    this.refeshData();
    this.debouncedSave = _.debounce(this.saveChanges, 1000);
    this.debouncedSaveWithNotification = _.debounce(this.saveWithNotification, 1000);
    this.onGenerateAnalyticSnippet()
  },

  refeshData() {
    $.get(this.analyticsApiUrl()).then((response) => {
      response.mobile_ad_budget = this.applyCommas(response.mobile_ad_budget);
      response.mobile_ad_impressions = this.applyCommas(response.mobile_ad_impressions);
      if (response.mobile_ad_radius) {
        response.mobile_ad_radius = Number.parseFloat(response.mobile_ad_radius).toFixed(1)
      }
      this.setState(response);
      this.setState({ isLoading: false });
    })
  },

  // Things got kinda complex here, had to do it in order to
  // a) debonce the save, b) delay the message, c) apply a custom message
  // Here's the call stack:
  // saveWithDelayedNotification
  // debouncedSaveWithNotification
  // saveWithNotification
  // clearNotificationsAndSave -> debouncedSave, setTimeout on notification

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  },

  onUserGeneratedContentChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
    this.setState({
      ugc_notification: "Your changes have been saved."
    }, this.clearNotificationsAndSave)
  },

  onHashtagChange(event) {
    this.saveWithDelayedNotification(
      { hashtags: event.target.value },
      { ugc_notification: "Your changes have been saved." }
    )
  },

  saveWithDelayedNotification(data, notification ) {
    this.setState(data, () => {
      this.debouncedSaveWithNotification(notification)
    })
  },

  saveWithNotification(notification) {
    this.setState(notification, this.clearNotificationsAndSave);
  },

  onSmsKeywordSubmit() {
    this.setState({
      sms_notification: "Your keywords have been submitted for approval. You'll be notified by email when they are approved."
    }, this.clearNotificationsAndSave)
  },

  onSmsResponseSubmit() {
    this.setState({
      sms_notification: "Your new response has been saved."
    }, this.clearNotificationsAndSave)
  },

  // at some point perhaps these should be different api endpoints
  saveChanges() {
    const {
      sms_keywords, sms_default_response, instagram,
      facebook, twitter, hashtags, track_design_asset_shares,
      mobile_ad_impressions, mobile_ad_budget, mobile_ad_radius, mobile_ad_url,
      survey_questions, survey_zip_codes, survey_demographics, survey_number_of_people,
      survey_budget,
    } = this.state;
    $.ajax({
      url: this.analyticsApiUrl(),
      method: "PUT",
      data: {
        sms_keywords,
        sms_default_response,
        instagram,
        facebook,
        twitter,
        hashtags,
        track_design_asset_shares,
        mobile_ad_impressions,
        mobile_ad_budget,
        mobile_ad_radius,
        mobile_ad_url,
        survey_questions,
        survey_zip_codes,
        survey_demographics,
        survey_number_of_people,
        survey_budget,
      }
    })
  },

  clearNotificationsAndSave() {
    this.debouncedSave();
    setTimeout(this.clearNotificationsCallback, 8000);
  },

  clearNotificationsCallback() {
    this.setState({
      sms_notification: "",
      ugc_notification: "",
      mobile_ad_notification: "",
      survey_notification: "",
    })
  },

  smsResponseLength() {
    return this.state.sms_default_response.length;
  },

  onAuthorizeAnalytics() {
    const token = this.props.campaign.token;
    document.location = `/auth/analytics_access?return_to=/campaigns/${token}/analytics/configure&campaign_token=${token}`;
  },

  radiusOptions() {
    let options = []
    for (let i = 0.1; i < 2.1; i += 0.1) {
      let radius = Number.parseFloat(i).toFixed(1);
      let text = "miles";
      if (radius == 1.0) text = "mile";
      options.push({ label: radius + " " + text, value: radius });
    }
    return options;
  },

  onImpressionsChange(event) {
    let mobile_ad_impressions = event.target.value.replace(/,/g, "");
    if (isNaN(mobile_ad_impressions)) {
      this.setState({ mobile_ad_impressions });
      return;
    }
    let mobile_ad_budget = ((mobile_ad_impressions / 1000) * mobileAdCPM).toFixed(2);
    mobile_ad_budget = this.applyCommas(mobile_ad_budget);
    mobile_ad_impressions = this.applyCommas(mobile_ad_impressions);
    this.saveWithDelayedNotification(
      { mobile_ad_impressions, mobile_ad_budget },
      { mobile_ad_notification: "Your changes have been saved." }
    )
  },

  onBudgetChange(event) {
    let mobile_ad_budget = event.target.value.replace(/,/g, "");
    if (isNaN(mobile_ad_budget)) {
      this.setState({ mobile_ad_budget });
      return;
    }
    let mobile_ad_impressions = ((mobile_ad_budget / mobileAdCPM) * 1000).toFixed(0);
    mobile_ad_budget = this.applyCommas(mobile_ad_budget);
    mobile_ad_impressions = this.applyCommas(mobile_ad_impressions);
    this.saveWithDelayedNotification(
      { mobile_ad_impressions, mobile_ad_budget },
      { mobile_ad_notification: "Your changes have been saved." }
    )
  },

  onRadiusChange(selection) {
    this.setState(
      {
        mobile_ad_radius: selection.value,
        mobile_ad_notification: "Your changes have been saved."
      },
      this.clearNotificationsAndSave
    );
  },

  onMobileAdUrlChange(event) {
    this.saveWithDelayedNotification(
      { mobile_ad_url: event.target.value },
      { mobile_ad_notification: "Your changes have been saved." }
    );
  },

  applyCommas(number) {
    if (!number) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  removeCreative(id, event) {
    $.ajax({
      url: `${this.analyticsApiUrl()}/remove_mobile_ad`,
      method: "DELETE",
      data: {
        campaign_id: this.props.campaign.token,
        id: id,
      },
      success: () => { this.refeshData() }
    })
  },

  allowedMobileAdSizes: ["320x50", "640x100", "300x250", "600x500"],

  onUploadComplete(upload, file) {
    const { mobileAdErrors } = this.state;
    this.setState({ mobileAdErrors: [], mobileAdSuccess: false })
    this.checkImageSize(file.url).then((size) => {
      if (this.allowedMobileAdSizes.includes(size)) {
        UploadStore.uploadMobileAd(upload, file, size, this.props.campaign.token,
          {
            success: (data) => {
              this.refeshData();
              this.setState({ mobileAdSuccess: true })
            }
          }
        );
      } else {
        this.setState({ mobileAdErrors: [...mobileAdErrors, "Please upload one of the above sizes."] })
      }
    })
  },

  checkImageSize(url) {
    return new Promise((resolve, reject) => {
      let temp = new Image();
      temp.src = url;
      $(temp).one('load', () => {
        const size = temp.width + "x" + temp.height;
        resolve(size);
      });
    })
  },

  renderConfigure() {
    return (
      <div>
        <div className="main_header">
          Configure Campaign Analytics & Metrics
        </div>
        { this.state.isLoading ?
          <Loading/> :
          <div>{this.renderAnalytics()}</div>
        }
      </div>
    )
  },

  handleMobileFileError(errors) {
    this.setState({ mobileAdErrors: errors });
  },

  showMobileErrors() {
    const { mobileAdErrors } = this.state;
    return mobileAdErrors.map(error =>
      <div className="alert alert-danger">
        {error}
      </div>
    )
  },

  render() {
    const { activeTab } = this.state
    const { campaign, permissions, direct_responses } = this.props
    const goToTab = (activeTab) => {
      browserHistory.push(`/campaigns/${campaign.token}/analytics/${activeTab}`);
      this.setState({ activeTab });
    };

    if(campaign.token == 'zfv45yx0w1' || campaign.token == '20wasf5ztr') {
      return  <Instagram campaign={campaign} />
    }

    return (
      <div className="campaign_analytics" style={{paddingTop: 10}}>
        <div className="hero"  onClick={this.props.switchToAnalyticResults}>
          Go to Analytics Setup
        </div>
        <Tabs
          activeKey={activeTab}
          onSelect={activeTab => goToTab(activeTab)}
          id="adwords-tabs">
          <Tab title="Configure" eventKey="configure">
            {activeTab == 'configure' && this.renderConfigure()}
          </Tab>
          <Tab title="AdWords Results" eventKey="adwords">
            {activeTab == 'adwords' && <AdwordsAnalytics campaign={campaign} />}
          </Tab>
          {this.showSegment() &&
            <Tab title="Segment Results" eventKey="segment">
              {activeTab == 'segment' && <SegmentAnalytics campaign={campaign} />}
            </Tab>
          }
          {this.showFacebook() &&
            <Tab title="Facebook Results" eventKey="facebook">
              {activeTab == 'facebook' && <FacebookAnalytics campaign={campaign} />}
            </Tab>
          }
          {permissions.can_view_direct_response_tab &&
            <Tab title="SMS Responses" eventKey="direct_responses">
              {activeTab == 'direct_responses' && <DirectResponses direct_responses={direct_responses} />}
            </Tab>
          }
        </Tabs>
      </div>
    )
  },

  onQuestionChange(event) {
    const questions = {
      0: event.target.name === '0' ? event.target.value : this.state.survey_questions[0],
      1: event.target.name === '1' ? event.target.value : this.state.survey_questions[1],
      2: event.target.name === '2' ? event.target.value : this.state.survey_questions[2],
      3: event.target.name === '3' ? event.target.value : this.state.survey_questions[3]
    }
    this.saveWithDelayedNotification(
      { survey_questions: questions },
      { survey_notification: "Your changes have been saved." }
    )
  },

  renderQuestions(index) {
    const survey_questions = this.state.survey_questions
    const question_placeholders = {
      0: 'What is the first Real Estate company that comes to mind?',
      1: 'Select the companies you have heard of: CBRE, Century 21...',
      2: 'Additional Question',
      3: 'Additional Question'
    };

    return (
      <div>
        {Object.values(survey_questions).map((question, index) =>
          <div key={`survey-questions-${index}`} className="survey-question">
            <label htmlFor="survey_questions">Question {index + 1}</label>
            <textarea
              className="form-control"
              name={index}
              defaultValue={question}
              onChange={this.onQuestionChange}
              placeholder={question_placeholders[index]}
              style={{ height: 50 }}
            />
          </div>
        )}
      </div>
    )
  },


  renderSurveyQuestionsSection() {
    const { survey_zip_codes, survey_demographics, survey_number_of_people, survey_budget } = this.state;

    return (
      <div className="analytics_section surveys">
        <h4 className="section_header">
          <i className="fa fa-check-square-o" aria-hidden="true"></i> Surveys
        </h4>
        <div className="analytics_box">

          {this.state.survey_notification &&
            <div className="alert alert-success">
              {this.state.survey_notification}
            </div>
          }

          <div>
            <span className="description_paragraph">Enter up to 4 questions</span>
            {this.renderQuestions()}
            <div className="line_break"></div>
            <label htmlFor="survey_zip_codes">Target Zip Codes</label>
            <textarea
              className="form-control survey-info"
              defaultValue={survey_zip_codes}
              onChange={e => this.saveWithDelayedNotification({survey_zip_codes: e.target.value}, {survey_notification: "Your changes have been saved."})}
              placeholder="10011, 10065, 11215, 11365, 10075"
              style={{ height: 50 }}
            />
            <label htmlFor="survey_demographics">Target Survey Demographics</label>
            <textarea
              className="form-control survey-info"
              defaultValue={survey_demographics}
              onChange={e => this.saveWithDelayedNotification({survey_demographics: e.target.value}, {survey_notification: "Your changes have been saved."})}
              placeholder="Aged Between 20-35, Household Income between $49,000-$149,000"
              style={{ height: 50 }}
            />
            <label htmlFor="survey_number_of_people">Number of people to reach</label>
            <textarea
              className="form-control survey-info"
              defaultValue={survey_number_of_people}
              onChange={e => this.saveWithDelayedNotification({survey_number_of_people: e.target.value}, {survey_notification: "Your changes have been saved."})}
              placeholder="3,000"
              style={{ height: 50 }}
            />
            <label htmlFor="survey_budget">Budget</label>
            <input
              className="form-control survey-info"
              defaultValue={survey_budget}
              onChange={e => this.saveWithDelayedNotification({survey_budget: e.target.value}, {survey_notification: "Your changes have been saved."})}
              placeholder="1,000"
            />
          </div>
        </div>
      </div>
    )
  },

  async onGenerateAnalyticSnippet() {
    const { campaign } = this.props
    const { snippet } = await get(`/api/v1/campaigns/${campaign.token}/analytics/retrieve_snippet`)
    this.setState({ analytic_snippet: snippet });
  },

  onCopySnippet() {
    this.snippetTextarea.select();
    const { analytic_snippet } = this.state
    const fallBack = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        GlobalStore.onShowMessage("Snippet copied to your clipboard")
      } catch (err) {
        GlobalStore.onShowError(`Error copying snippet to clipboard: ${err}`)
      }

      document.body.removeChild(textArea);
    }

    try {
      navigator.clipboard.writeText(analytic_snippet)
      GlobalStore.onShowMessage("Snippet copied to your clipboard")
    } catch (error) {
      console.log(error)
      fallBack(analytic_snippet)
    }
  },

  renderAnalytics() {
    const smsResponseLength = this.smsResponseLength();
    return (
      <div>
        <div className="left">
          <div className="analytics_section google_analytics">
            <div className="section_header">
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
              Google Analytics
            </div>
            <div className="analytics_box">
              <div className="title">
                Google Analytics API Authorization
              </div>
              {this.state.google_analytics_enabled &&
                <div className="alert alert-success">
                  Google Analytics has been successfully authorized. You will receive an email once your report has been processed.
                </div>
              }
              <div className="description">
                <div className="description_paragraph">
                  Track visits to your website by
                  latitude and longitude and compare traffic before, during, and after your outdoor campaign.
                </div>
              </div>
              <div className="buttons">
                <div className="analytics_button analytics_button_primary" onClick={this.onAuthorizeAnalytics}>
                  Authorize Google Analytics
                </div>
              </div>
            </div>
          </div>

          <AdwordsAuthorize campaign={ this.props.campaign } google_adwords_enabled={ this.state.google_adwords_enabled } />

          <div className="analytics_section sms_direct_response">
            <div className="section_header">
              <i className="fa fa-comment" aria-hidden="true"></i>
              SMS Direct Response
            </div>
            <div className="analytics_box">

              {this.state.sms_notification &&
                <div className="alert alert-success">
                  {this.state.sms_notification}
                </div>
              }

              <div className="description sms_description">
                <div className="description_paragraph">
                  We support SMS direct response if you'd like to use this feature in your campaign.
                </div>

                <div className="description_paragraph">
                  Example: "Text 'fitness' to 477-11 to get your first class free!".
                </div>
              </div>

              <div className="title">
                Keywords
              </div>
              <div className="description">
                Let us know your requested keywords. Separate multiple keywords with commas.
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="sms_keywords"
                  value={this.state.sms_keywords}
                  onChange={this.onInputChange}
                  placeholder='e.g. "fitness"'
                />
              </div>
              <div className="buttons">
                <div className="analytics_button analytics_button_primary" onClick={this.onSmsKeywordSubmit}>
                  Submit Keyword(s) for Approval
                </div>
              </div>

              <div className="title title_with_space">
                Automatic Response Message
              </div>
              <div className="description">
                160 character limit. ({smsResponseLength}/160)
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="sms_default_response"
                  value={this.state.sms_default_response}
                  onChange={this.onInputChange}
                  placeholder='e.g. a link to claim the offer'
                  maxLength="160"
                />
              </div>
              <div className="buttons">
                <div className="analytics_button analytics_button_primary" onClick={this.onSmsResponseSubmit}>
                  Save SMS Response
                </div>
              </div>
            </div>
          </div>

          {this.renderSurveyQuestionsSection()}

        </div>
        {/* above div ends left section */}
        <div className="right">

          <SegmentApiKey header={true} />

          <div className="analytics_section user_generated_content">
            <div className="section_header">
              <i className="fa fa-bullhorn" aria-hidden="true"></i>
              Social Media Analytics
            </div>
            <div className="analytics_box clearfix">

              {this.state.ugc_notification &&
                <div className="alert alert-success">
                  {this.state.ugc_notification}
                </div>
              }

              <div className="title">
                Social Media Platforms
              </div>
              <div className="checkboxes">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="instagram"
                      checked={this.state.instagram}
                      onChange={this.onUserGeneratedContentChange}
                    />
                    Instagram
                  </label>
                </div>

                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="facebook"
                      checked={this.state.facebook}
                      onChange={this.onUserGeneratedContentChange}
                    />
                    Facebook
                  </label>
                </div>

                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="twitter"
                      checked={this.state.twitter}
                      onChange={this.onUserGeneratedContentChange}
                    />
                    Twitter
                  </label>
                </div>
              </div>

              <div className="title title_with_space">
                Hashtags
              </div>
              <div className="description">
                Track changes in hashtag usage. Separate multiple hashtags with commas.
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="hashtags"
                  value={this.state.hashtags}
                  onChange={this.onHashtagChange}
                  placeholder="#hashtag"
                />
              </div>

              <div className="title title_with_space">
                Image Recognition
              </div>

              <div className="checkboxes">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="track_design_asset_shares"
                      checked={this.state.track_design_asset_shares}
                      onChange={this.onUserGeneratedContentChange}
                    />
                    Track how often my design asset(s) are shared on the above social media platforms.
                  </label>
                </div>
              </div>

            </div>
          </div>

          <div className="analytics_section geofenced_mobile_ads">
            <div className="section_header">
              <i className="fa fa-location-arrow" aria-hidden="true"></i>
              Geofenced Mobile Ads
            </div>
            <div className="analytics_box clearfix">
              <div className="title">
                Configure Geofenced Mobile Ads
              </div>

              <div className="description">
                <div className="description_paragraph">
                  Run mobile ads that will appear on users' phones when they
                  are near one of your outdoor ad units. The industry benchmark is to spend about 10% of what you're spending on outdoor ads on geofenced mobile ads.
                </div>
                <div className="description_paragraph">
                  You can set up these mobile ads at anytime before your campaign starts. You will not be charged until you complete checkout.
                </div>
              </div>

              {this.state.mobile_ad_notification &&
                <div className="alert alert-success">
                  {this.state.mobile_ad_notification}
                </div>
              }

              <div className="geo_ad_form">

                <div className="cost_calculator">
                  <div className="impressions">
                    <label htmlFor="mobile_ad_impressions">
                      Impressions
                      {/*
                      <Tooltip
                        placement="top"
                        trigger={['hover']}
                        overlay={<span>some notes on impressions</span>}
                      >
                        <i className="fa fa-question-circle" aria-hidden="true" />
                      </Tooltip>
                      */}
                    </label>
                    <input
                      type="text"
                      id="mobile_ad_impressions"
                      name="mobile_ad_impressions"
                      className="form-control"
                      value={this.state.mobile_ad_impressions}
                      onChange={this.onImpressionsChange}
                    />
                  </div>
                  <div className="symbol">
                    x
                  </div>
                  <div className="cpm">
                    <label>
                      CPM
                    </label>
                    <div className="cpm_number">
                      ${mobileAdCPM}
                    </div>
                  </div>
                  <div className="symbol">
                    =
                  </div>
                  <div className="budget">
                    <label htmlFor="mobile_ad_budget">
                      Budget
                    </label>
                    <div className="dollar_symbol">
                      <input
                        type="text"
                        id="mobile_ad_budget"
                        name="mobile_ad_budget"
                        className="form-control"
                        value={this.state.mobile_ad_budget}
                        onChange={this.onBudgetChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="delivery_radius">
                  <label htmlFor="mobile_ad_radius">Delivery Radius</label>
                  <Select
                    name="mobile_ad_radius"
                    options={this.radiusOptions()}
                    value={this.radiusOptions().find(o => o.value == this.state.mobile_ad_radius)}
                    onChange={this.onRadiusChange}
                    clearable={false}
                    placeholder="Choose a delivery radius"
                  />
                </div>

                <div className="landing_page_url">
                  <label htmlFor="mobile_ad_url">
                    Landing Page URL
                  </label>
                  <input
                    type="text"
                    id="mobile_ad_url"
                    name="mobile_ad_url"
                    className="form-control"
                    placeholder="e.g. https://www.mysite.com"
                    value={this.state.mobile_ad_url}
                    onChange={this.onMobileAdUrlChange}
                  />
                </div>

              </div>

              <div className="line_break"></div>

              <div className="title">
                Upload Creative
                <span className="max"> (max of 10)</span>
              </div>

              <div className="description">
                Please upload both a standard and retina size for each creative.
              </div>

              <div className="size_options">

                <div className="size_option">
                  Size option 1:
                  Standard: 320 x 50,  Retina: 640 x 100
                </div>

                <div className="size_option">
                  Size option 2:
                  Standard: 300 x 250,  Retina: 600 x 500
                </div>

              </div>

              {this.state.mobileAdErrors.length > 0 &&
                this.showMobileErrors()
              }

              {this.state.mobileAdSuccess &&
                <div className="alert alert-success">
                  Your mobile ad creative has been successfully uploaded.
                </div>
              }

              <div className="creative_upload clearfix">
                <UploadButton
                  handleFileError={this.handleMobileFileError}
                  customDropzoneProps={{ accept: "image/*" }}
                  onComplete={this.onUploadComplete}
                >
                  <div>
                    <div className="file_icons">
                      <i className="fa fa-file-image-o"></i>
                    </div>
                    <div className="main_text">Drop files here</div>
                    <div className="sub_text">or <u>click to upload</u></div>
                  </div>
                </UploadButton>
              </div>

              {this.state.mobile_ad_creatives.length > 0 &&
                <div className="title">Current Creatives</div>
              }

              {this.state.mobile_ad_creatives.map(creative => {
                return <div className="creative" key={creative.id}>
                  <div className="creative_header">
                    <span className="file_name">{creative.name}</span>
                    <span className="size">{creative.size}</span>
                    <i className="fa fa-trash-o" onClick={this.removeCreative.bind(this, creative.id)}></i>
                  </div>
                  <div className="creative_image">
                    <img src={creative.image_url} />
                  </div>
                </div>
              })}

            </div>
          </div>

          <div className="analytics_section adquick_snippet">
            <div className="section_header">
              <i className="fa fa-bar-chart" aria-hidden="true"></i>
              AdQuick Snippet
            </div>
            <div className="analytics_box">
              <div className="title">
                Snippet Setup
              </div>
              <div className="description">
                <div className="description_paragraph">
                  The AdQuick snippet enables you to track geo-located lift in website traffic and conversions.
                </div>
                <div className="description_paragraph">
                    Simply click snippet below to copy it, paste it into the {"<head>"} of your website, then call the page and track methods as needed.{" "}
                    <a href="https://adquick.quip.com/yuFYATPZR97m/AdQuick-Snippet-Documentation">
                      Click here to view the full setup documentation.
                    </a>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  id="analytic_snippet"
                  className="form-control snippet_code"
                  name="analytic_snippet"
                  value={this.state.analytic_snippet}
                  ref={(input) => this.snippetTextarea = input }
                  onClick={this.onCopySnippet}
                  spellCheck={false}
                />
              </div>
            </div>
          </div>
        </div>
        {/* above div ends right section */}
      </div>
    );
  },

});

export default connect(({feature_flags}) => ({feature_flags}),{})(CampaignAnalytics);
