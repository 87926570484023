import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { confirmPricing } from "../../actions/campaign_supplier_contracts";
import GlobalActions from "../../actions/GlobalActions";

class ConfirmPricing extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: false,
    };
    this.confirmPricing = this.confirmPricing.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.query && location.query.token) {
      this.confirmPricing(location.query.token);
    }
  }

  async confirmPricing(token) {
    const { confirmPricing } = this.props;
    await confirmPricing(token);
    this.setState({ confirmed: true }, () => {
      GlobalActions.showMessage("Pricing Confirmed");
    });
  }

  render() {
    const { confirmed } = this.state;

    if (confirmed) {
      return (
        <div id="confirm_pricing">
          <div style={{ textAlign: "center", fontSize: 22, marginTop: "13%" }}>Pricing Confirmed</div>
        </div>
      );
    } else {
      return (
        <div id="confirm_pricing">
          <div style={{ textAlign: "center", fontSize: 22, marginTop: "13%" }}>
            Confirming Prices
            <i className="fa fa-circle-o-notch fa=spin" aria-hidden="true"></i>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = ({}) => ({});

export default connect(mapStateToProps, {
  confirmPricing,
})(ConfirmPricing);
