import * as React from "react";
import { CSSProperties, Component } from "react";

import { dollarFormatter } from "../../utils/formatters";
import PriceDistributionGraph from "./PriceDistributionGraph";

export default class PriceScoreSection extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  private savings_banner_style: React.CSSProperties = {
    background: "#edf4fc",
    padding: 10,
    textAlign: "center",
  };

  private renderSavingsBanner() {
    const { unit } = this.props;
    const { campaign_unit_price, price, rate_card_price } = unit;
    const current_price = campaign_unit_price ? campaign_unit_price : price;
    const diff = rate_card_price - current_price;
    if (!!!(diff > 0)) {
      return <div></div>;
    }
    const percentage = Math.round((diff / rate_card_price) * 100);
    const savings = `${percentage}%`;
    return (
      <div className="savings-banner" style={this.savings_banner_style}>
        You'll save <span style={{ fontWeight: 600 }}>{savings}</span> off rate card by booking with AdQuick!
      </div>
    );
  }

  public render() {
    const { unit, user } = this.props;

    return (
      <div id="price_score_section" style={{ padding: "20px 28px" }}>
        <div></div>
        <PriceDistributionGraph unit={unit} />
        {!!!user.is_supplier && this.renderSavingsBanner()}
      </div>
    );
  }
}
