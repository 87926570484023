import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const calculateFlightPrice = (monthPrice, startDate, endDate) => {
  const daysLength = calculateFlightLength(startDate, endDate)
  const dayPrice = monthPrice / 4 / 7
  return daysLength * dayPrice
}

export const calculateFlightLength = (startDate, endDate) => {
  const range = moment.range(utcDate(startDate), utcDate(endDate));
  const array = Array.from(range.by('day'));
  return array.length;
}

export const calculateEndDate = (startDate, howLongInWeeks) => {
  const date = moment.isMoment(startDate) ? startDate.clone() : moment(startDate)
  const daysLength = howLongInWeeks * 7
  const dates = []
  for (var i = 0; i < daysLength; i++) {
    dates.push(date.clone().add(i, 'days'))
  }
  const endDate = dates[dates.length - 1]
  return moment(endDate)
}

const utcDate = (date) => {
  if (!moment.isMoment(date)) {
    date = moment(date);
  } else {
    date = date.clone()
  }

  return moment.utc(date.format('YYYY-MM-DD'));
}
