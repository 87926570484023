import React, { Component, FunctionComponent } from 'react'; // let's also import Component
import Select from 'react-select';
import _ from 'lodash';
import GlobalActions from '../../actions/GlobalActions';
import AuthStore from '../../stores/AuthStore';

declare var google;
declare var EAGLEEYE;

const street_view_url = '/api/v1/street_view_leads';

const EagleEyeState = {
  current_state: "intial", // initial, view, loading, error
  company_name: 'string',
  company_phone: 'string',
  company_website: 'string',
  query: 'string',
  unit: 'object',
  selected_location: 'object',
  location_details: 'array',
  map: 'object',
  panorama: 'object',
  show_wallscape_billboard: 'boolean',
  is_position_saved: 'boolean',
  unsubscribeList: 'object'
}

const input_items = [
  {label: 'company_name', display: 'Name (Required)', isRequired: true},
  {label: 'company_phone', display: 'Phone (if present)', isRequired: false},
  {label: 'company_website', display: 'Website (Required)', isRequired: true}
];

class EagleEye extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { EagleEyeState };
    this.fetchLocationDemos = _.debounce(this.fetchLocationDemos, 1000);
  }
  
  componentDidMount() {
    this.setState({unsubscribeList: [AuthStore.listen(this.onAuthChange)]});
    if (AuthStore.isLoggedOut()) { window.location.assign("/login"); return }
  }

  componentWillUnmount() {
    this.state.unsubscribeList.map(fn => fn());
  }
  //Renders ******************************************************************************************
  renderForm() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        {input_items.map((item, idx) => this.renderInput(item, idx))}
        <div className="form-button-block">
          <input className="street-view-button" type="submit" value="Submit" />
        </div>
      </form>
    );
  }

  renderInput(item, idx) {
    return  (
      <div className="input-block" key={idx}>
        <label>{item.display}</label>
        <input 
          type="text" name={item.label} className="" value={this.state[item.label] || ""} 
          onChange={(e) => { this.setState({[item.label]: e.target.value }) }}
          required={item.isRequired}
        />
        {item.label === 'company_website' && this.renderGoogleButton()}
      </div>
    );
  }

  renderGoogleButton() {
    return (
      <div className="google-button"  onClick={this.handleGoogleSearch.bind(this)}>
        <i className="fa fa-google" aria-hidden="true" ></i>
        oogle Search Company Website
      </div>
    );
  }

  renderSavePosition() {
    return (
      <div className="save-position-block">
        <button className="street-view-button skip" onClick={this.onNewSearch.bind(this)}>New Search</button>
        <button className="street-view-button" onClick={this.updateUnitPosition.bind(this)}>Save Position</button>
        <button className="street-view-button skip" onClick={this.onLocationSearch.bind(this)}>Skip</button>
      </div>
    )
  }

  renderUnitDescription(unit) {
    const filler_image = <div className="no_image"><i className="fa fa-file-image-o" aria-hidden="true"></i></div>;
    return (
      <div className="unit-description-block">
        <div className="unit_image">
          {unit.image_file_link ? <img className="unit_photo" src={unit.image_file_link || ""} /> : filler_image}
        </div>
        <div className="unit_description">
          {['name', 'id', 'unit_type', 'supplier', 'size'].map((item, idx) => <p key={idx}><b>{item}:</b> {unit[item] || "None provided"}</p>)}
        </div>
      </div>
    );
  }

  renderCheckBox() {
    return (
      <div className="form-check">
        <input type="checkbox" className="form-check-input"
          onClick={this.handleCheckClick.bind(this)}
          checked={this.state.show_wallscape_billboard}
        />
        <label className="form-check-label">Limit to Wallscapes & Billboards</label>
      </div>
    );
  }

  renderSelect() {
    return (
      <div className="search_box">
        <h1>Eagle Eye</h1>
        {this.renderCheckBox()}
        <Select
            options={this.state.location_details}
            onInputChange={this.handleInputChange.bind(this)}
            onChange={this.onChange.bind(this)}
            cacheOptions={true}
            value={this.state.selected_location}
            className="street_view_select"
        />
        <button 
          className="search_button" 
          disabled={this.state.selected_location ? false : true } 
          onClick={this.onLocationSearch.bind(this)}>Search
        </button>
      </div>
    )
  }
//API Calls ******************************************************************************************
  async fetchLocationDemos(query) {
    try {
      const { geographies } = await $.get(`${street_view_url}/get_location_details`, { geography: query });
      if(geographies.length > 0 ){
        let location_details =  geographies.reduce((acc, { name, type, id }) => {
          acc.push(Object.assign({}, { value: id, label: name, type: type }))
          return acc
        }, [])
        this.setState({location_details: location_details});
      }
    }catch(err){
      GlobalActions.showError("There has been an error fetching location. Please contact AdQuick.");
    }
  }

  async fetchUnit(id) {
    try{
      const show_wallscape_billboard =  this.state.show_wallscape_billboard
      const { unit } = await $.get(`${street_view_url}/get_available_unit_json`, { id: id, show_wallscape_billboard: show_wallscape_billboard });
      this.setState({ 
        unit: unit, heading: unit.streetview_pov_heading, 
        pitch: unit.streetview_pov_pitch, company_name: "",
        company_phone: "", company_website: "" , is_position_saved: false
        }, ()=> this.setupStreetView()
      );
    }catch(err){
      GlobalActions.showError("Either this location is out of units or there has been an error fetching a unit. Please select a differnt market or contact AdQuick.");
    }
  }

  async postCompany() {
    const { company_name, company_phone, company_website } = this.state;
    const location = this.currentLocation();
    const companyInfo = { 
      name: company_name, phone: company_phone, 
      website_url: company_website, streetview_unit_id: this.state.unit.id,
    };
    let company = Object.assign({}, location, companyInfo);
    try{
      const { result } = await $.post(`${street_view_url}/create_company`, { unit_id: this.state.unit.id, company: company});
      GlobalActions.showMessage("Company Saved !");
      this.onLocationSearch();
    } catch(err) {
      GlobalActions.showError("There has been an error while submitting company info. Changes were rolled back.");
    }
  }

  async updateUnitPosition() {
    try {
      const url = `${street_view_url}/update_unit_position`;
      const result = await $.ajax({ method: "PATCH", url, data: { id: this.state.unit.id, unit: this.currentLocation()} });
      GlobalActions.showMessage("Position Saved !");
      this.setState({is_position_saved: true});
    } catch(err) {
      GlobalActions.showError("There has been an error while updating position. Changes were rolled back.");
    }
  }
//Utilities ******************************************************************************************
  currentLocation() {
    return {
      streetview_lat: this.state.position_lat,
      streetview_lon: this.state.position_lon,
      streetview_pano_id: this.state.pano,
      streetview_pov_heading: this.state.heading,
      streetview_pov_pitch: this.state.pitch,
    }
  }

  setupStreetView() {
    const { unit } = this.state;

    let map = new google.maps.Map(document.getElementById('map'), {
      center: {lat: unit.lat, lng: unit.lon},
      zoom: 15
    });

    let panorama = new google.maps.StreetViewPanorama(
      document.getElementById('pano'), {
        position: {lat: unit.streetview_lat, lng: unit.streetview_lon},
        pov: { heading: unit.streetview_pov_heading, pitch: unit.streetview_pov_pitch,},
        visible: true
      });
    
    map.setStreetView(panorama);

    panorama.addListener('pano_changed', () => {
      this.setState({ pano: panorama.getPano() });
    });

    panorama.addListener('position_changed', () => {
      const position = panorama.getPosition();
      const position_lat = position.lat();
      const position_lon = position.lng();
      this.setState({ position_lat, position_lon });
    });

    panorama.addListener('pov_changed', () => {
      const heading = panorama.getPov().heading;
      const pitch = panorama.getPov().pitch;
      this.setState({ heading, pitch });
    });
  
    this.setState({ map: map, panorama: panorama});
  }
//handlers ******************************************************************************************
  handleGoogleSearch() {
    if(_.isEmpty(this.state.company_name)){
      GlobalActions.showError("Please enter a company name to search");
      return;
    } else {
      const strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
      const url = `https://www.google.com/search?q=${this.state.company_name}`;
      const win = window.open(url, "_blank", strWindowFeatures);
      if (win === null) {
        return;
      } else {
        win.focus();
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if(!this.state.is_position_saved){
      GlobalActions.showError("The position of this unit was not saved! Please save the unit position in the streetview before submitting.");
      return;
    }
    this.postCompany();
  }

  handleInputChange(query) {
      this.setState({ query: query }, 
        () => { this.state.query.length > 1 && this.fetchLocationDemos(this.state.query);
      });
  }

  handleCheckClick(e) {
    this.setState({ show_wallscape_billboard: e.target.checked });
  }

  onChange(selected_location) {
    this.setState({ selected_location });
  }

  onLocationSearch() {
    this.fetchUnit(this.state.selected_location.value);
  }

  onAuthChange() {
    location.reload();
  }

  onNewSearch() {
    this.setState({ 
      unit: "", heading: "", 
      pitch: "", company_name: "",
      company_phone: "", company_website: "" , is_position_saved: false,
      selected_location: ""
      });
  }

  render() {
    const unit = this.state.unit || {};
    const user = AuthStore.getState().user;
    
    if(!EAGLEEYE) {
      window.location.href = "/";
      return <div></div>;
    }

    if ((AuthStore.isLoggedOut()) || (!(user.is_contractor || user.is_admin))) {
      return <div></div>;
    }
    return (
        <div className="street-view-container">
            {_.isEmpty(unit) && this.renderSelect()}
            {!_.isEmpty(unit) &&
              <div className="map-pano-container">
                <div className="map-pano">
                  <div id="map"></div>
                  <div id="pano"></div>
                  {!_.isEmpty(unit) && this.renderUnitDescription(unit)}
                </div>
                {this.renderSavePosition()}
            </div>
            }
            {!_.isEmpty(unit) && <div className="form-container">
              <h2>Company Info</h2>
              {this.renderForm()}
            </div>
            }
        </div> 
    );
  }
}
export default EagleEye;