import { SET_UNIT_LOADING } from '../../actions/UnitsActions';
import { fetchComments } from '../CampaignUnitComments/actions';
import { get, put, post, del, patch } from '../../utils/api';
import { loadFavorites } from '../Places/actions';
import GlobalActions from '../../actions/GlobalActions';
import { loadAvailabilityStatuses } from '../../actions/availability_status_actions';
import groupBy from 'lodash/groupBy';

const toggleLoading = () => ({
  type: 'CAMPAIGN_TOGGLE_LOADING'
})

const loadCacheKey = async (campaignID) => {
  const { cache_key } = await get(`/api/v1/campaigns/${campaignID}/cache_key`);
  return cache_key;
};

export const CLEAR_CAMPAIGN = 'marketplace/campaign/clear'
export const clearCampaign = () => dispatch => dispatch({
  type: CLEAR_CAMPAIGN
});

export const LOAD_CAMPAIGN = 'marketplace/campaign/load'
export const loadCampaign = campaignID => async (dispatch, getState) => {
  if (getState().campaign.campaignId !== campaignID) {
    clearCampaign();
  }
  dispatch(toggleLoading())
  const campaign = await get(`/api/v1/campaigns/${campaignID}`);

  dispatch({ type: LOAD_CAMPAIGN, payload: { campaignId: campaignID, ...campaign } });
  dispatch(loadAvailabilityStatuses())
  dispatch(toggleLoading())
}

export const LOAD_CART_REACH_FREQUENCY = 'marketplace/cart/load_reach_frequency'
export const loadSomeReachAndFrequency = campaignID => async (dispatch, _getState) => {
  const reachAndFrequency = await get(`/plus/campaigns/${campaignID}/reach_frequency/load_some`);
  dispatch({ type: LOAD_CART_REACH_FREQUENCY, payload: { reachAndFrequency } });
}

export const loadCampaignUnitData = (campaignID, dataSet, _cache_key) => async (dispatch, getState) => {
  let cache_key;
  if (!_cache_key) {
    cache_key = await loadCacheKey(campaignID);
  } else {
    cache_key = _cache_key;
  }

  const unit_data = await get(`/api/v1/campaigns/${campaignID}/${dataSet}?cache=${cache_key}`);
  const { campaign: {advertiser} } = getState();
  const show_availability = advertiser && advertiser.unit_availability_flow;

  if (!unit_data.length) dispatch({ type: SET_UNIT_LOADING, payload: false });
  const suppliers = getState().campaign_suppliers;
  dispatch({ type: LOAD_CAMPAIGN_UNITS, payload: { unit_data, suppliers, show_availability, dataSet } });
};

export const LOAD_SUPPLIER_CONTACTS = 'marketplace/campaign/load-unit-supplier-contacts';
export const loadSupplierContacts = (campaign_token) => async (dispatch, getState) => {
  const response = await get(`/api/v1/campaigns/${campaign_token}/vendor_contacts`);
  dispatch({ type: LOAD_SUPPLIER_CONTACTS, payload: response });
};

export const UPDATE_CAMPAIGN_UNIT = 'marketplace/campaign/update-campaign-unit';
export const MANAGE_UNITS_ROW_UPDATE = 'marketplace/campaign/manage-units-row-update';
export const updateUnit = (campaign_token, unit, params) => async (dispatch, getState) => {
  const response = await post(
    `/api/v1/campaigns/${campaign_token}/update_unit/${unit.campaign_unit_token}`,
    params
  );
  dispatch({ type: UPDATE_CAMPAIGN_UNIT, payload: response });
  dispatch({ type: MANAGE_UNITS_ROW_UPDATE, payload: { campaign_unit_token: unit.campaign_unit_token, ...params } });
};

export const SET_SUPPLIER_CONTACT = 'marketplace/campaign/set-unit-supplier-contacts';
export const setSupplierContact = (campaign_token, unit, contact_id, campaign_unit_id) => async (dispatch, getState) => {
  const response = await post(`/api/v1/campaigns/${campaign_token}/update_vendor_contact`, {
    unit,
    contact_id
  });
  dispatch({ type: SET_SUPPLIER_CONTACT, payload: { contact_id, campaign_unit_id }});
};

export const CLEAR_UNITS = 'marketplace/clear-units'
export const LOAD_CAMPAIGN_UNITS = 'marketplace/campaign/load-units'
export const UNIT_DATA_SETS = new Set(["map", "sidebar", "pictures", "details", "attributes", "suppliers"]);
export const CAMPAIGN_UNIT_DATA_SETS = new Set(["prices", "status", "tags", "supplier_action_log_diffs"]);
export const DATA_SETS = UNIT_DATA_SETS.union(CAMPAIGN_UNIT_DATA_SETS);
export const loadCampaignUnits = (campaignID, skipClear = false) => async (dispatch, getState) => {
  if (!skipClear) dispatch({type: CLEAR_UNITS});

  if (!campaignID) return;

  const cache_key = await loadCacheKey(campaignID);

  dispatch({ type: SET_UNIT_LOADING, payload: true });

  const unitData = await loadDataSets(campaignID, UNIT_DATA_SETS, cache_key, dataSet =>
    dataSet === "suppliers" ? "id" : "_id",
  );
  const { campaign: { advertiser, campaign: { show_price_for_duration } } } = getState();
  unitData.show_availability = advertiser && advertiser.unit_availability_flow;
  unitData.show_price_for_duration = show_price_for_duration

  const campaignUnitData = await loadDataSets(campaignID, CAMPAIGN_UNIT_DATA_SETS, cache_key, dataSet =>
    "campaign_unit_token",
  );

  dispatch({ type: LOAD_CAMPAIGN_UNITS, payload: { unitData, campaignUnitData } });
  dispatch(loadFavorites({ campaign: campaignID }));
  dispatch(fetchComments(campaignID));
  dispatch({ type: SET_UNIT_LOADING, payload: false });
}

async function loadDataSets(campaignID, dataSets, cache_key, groupByKey) {
  const payload = {};
  await Promise.all(
    dataSets.map(async dataSet => {
      const res = await fetch(`/api/v1/campaigns/${campaignID}/${dataSet}?cache=${cache_key}`);
      const data = await res.json();
      payload[dataSet] = groupBy(data, groupByKey(dataSet));
      return payload;
    }),
  );
  return payload;
}

export const INITIAL_UNIT_REQUEST = 'marketplace/campaign/initial-unit-request'
export const initialUnitRequest = (unit_id, startDate, endDate) => async dispatch => {
  //TODO move the initial unit request api call to the action
  dispatch({ type: INITIAL_UNIT_REQUEST, payload: { unit_id, startDate, endDate } });
}

export const FILTER_UNITS = 'marketplace/filter-units'
export const filterUnits = filter => async (dispatch, getState) => {
  dispatch(toggleLoading())
  const { unit_filter } = getState().campaign;

  filter.unit_ids = await new Filter({ ...unit_filter, ...filter }).asyncFilter();

  dispatch({ type: FILTER_UNITS, payload: { filter } });
  dispatch(toggleLoading())
}

export const SORT_UNITS = 'marketplace/sort-units'
export const sortUnits = ({ sort_by }) => dispatch => {
  dispatch({ type: SORT_UNITS, payload: { sort_by } });
}

export const REMOVE_CAMPAIGN_UNITS = 'marketplace/campaign/remove-units'
export const removeCampaignUnits = (unit_ids) => async dispatch => {
  dispatch({ type: REMOVE_CAMPAIGN_UNITS, payload: { unit_ids } });
}

export const REMOVE_CAMPAIGN_TAGS = 'marketplace/campaign/remove-tags'
export const removeCampaignTags = (unit_ids) => async dispatch => {
  dispatch({ type: REMOVE_CAMPAIGN_TAGS, payload: { unit_ids }})
  dispatch(refreshUnitTags());
}

export const REMOVE_ACTIVE_TAGS = 'marketplace/campaign/remove-active-tags'
export const removeActiveTags = (unit_ids, tagNames) => async dispatch => {
  dispatch({ type: REMOVE_ACTIVE_TAGS, payload: { unit_ids, tagNames }})
  dispatch(refreshUnitTags());
}

export const REMOVE_CAMPAIGN_UNIT = 'marketplace/campaign/remove-unit';
export const removeCampaignUnit = (campaign_id, unit_id) => async dispatch => {
  try {
    const { removed_count } = await del(`/api/v1/campaigns/${campaign_id}/remove_unit/${unit_id}`);
    dispatch({ type: REMOVE_CAMPAIGN_UNIT, payload: { unit_ids: [unit_id] } });
    GlobalActions.showMessage(`${removed_count} unit(s) removed.`);
  } catch (e) {
    GlobalActions.showError(e);
  }
};

export const REMOVE_CAMPAIGN_UNIT_BY_TOKEN = 'marketplace/campaign/remove-campaign-unit';
export const removeCampaignUnitByToken = (campaign_unit_token) => async dispatch => {
  try {
    await del(`/api/v1/campaign_units/${campaign_unit_token}`);
    dispatch({ type: REMOVE_CAMPAIGN_UNIT_BY_TOKEN, payload: { campaign_unit_token: campaign_unit_token } });
    GlobalActions.showMessage(`Unit successfully removed from campaign.`);
  } catch (e) {
    GlobalActions.showError(e);
  }
};

export const UPDATE_CAMPAIGN_TAGS = "marketplace/campaign/update-tags";
export const BEGIN_UPDATE_CAMPAIGN_TAGS = "marketplace/campaign/being-update-tags";
export const END_UPDATE_CAMPAIGN_TAGS = "marketplace/campaign/end-update-tags";
export const addCampaignTag = (campaignID, params) => async dispatch => {
  try {
    dispatch({ type: BEGIN_UPDATE_CAMPAIGN_TAGS });
    const { allTags, tags } = await post(`/api/v1/campaigns/${campaignID}/campaign_unit_tags`, params);
    dispatch({ type: UPDATE_CAMPAIGN_TAGS, payload: { unit_tags: allTags } });
    dispatch(loadCampaignUnits(campaignID));
    return { new_tags: tags };
  } finally {
    dispatch({ type: END_UPDATE_CAMPAIGN_TAGS });
  }
};

export const UPDATE_CAMPAIGN_MEDIA_TYPES = 'marketplace/campaign/update-media-types'
export const updateCampaignMediaTypes = (campaignID, params) => async dispatch => {
  // do an optimistic update
  dispatch({ type: UPDATE_CAMPAIGN_MEDIA_TYPES, payload: params });
  let data = Object.assign({}, params)
  // jquery won't send empty arrays to the server
  if (data.media_types && data.media_types.length === 0) data.media_types = [""]
  if (data.media_subtypes && data.media_subtypes.length === 0) data.media_subtypes = [""]
  const url = `/api/v1/campaigns/${campaignID}`
  const result = await patch(url, { campaign: data });
}

export const UPDATE_CAMPAIGN_SCREEN_TYPE = 'marketplace/campaign/update-screen-type'
export const updateCampaignScreenType = (campaignID, params) => async dispatch => {
  // do an optimistic update
  dispatch({ type: UPDATE_CAMPAIGN_SCREEN_TYPE, payload: params });
  const url = `/api/v1/campaigns/${campaignID}`
  const result = await patch(url, { campaign: params });
}

export const UPDATE_UNIT_TAG_NAME = 'marketplace/campaign/update-unit-tag-name'
export const updateUnitTagName = (updated_tag, old_tag) => async (dispatch, getState) => {
  const { campaignId } = getState().campaign
  const { unit_tags } = await put(`/api/v1/campaigns/${campaignId}/campaign_unit_tags`, { updated_tag, old_tag })
  dispatch({ type: UPDATE_UNIT_TAG_NAME, payload: { unit_tags } })
  dispatch(loadCampaignUnits(campaignId))
}

export const REFRESH_UNIT_TAGS = 'marketplace/campaign/refresh-unit-tags'
export const refreshUnitTags = () => async (dispatch, getState) => {
  const { campaignId } = getState().campaign;
  if (!campaignId) return;

  const { campaign_unit_tags } = await get(`/api/v1/campaigns/${campaignId}/campaign_unit_tags`)
  const tags_with_old_value = campaign_unit_tags.map(t => {
    t.oldTag = t.tag
    t.oldColor = t.color
    t.id = `${t.tag}-${t.color}`
    return t
    }).sort()
    dispatch({ type: REFRESH_UNIT_TAGS, payload: { unit_tags: tags_with_old_value}})
}

export const DELETE_UNIT_TAG = 'marketplace/campaign/delete-unit-tag'
export const deleteUnitTag = (tag, unit_id = null) => async (dispatch, getState) => {
  const { campaignId } = getState().campaign;
  const encoded = encodeURIComponent(tag);
  const { unit_tags } = await del(`/api/v1/campaigns/${campaignId}/campaign_unit_tags`, { tag: encoded, unit_id });
  dispatch({ type: DELETE_UNIT_TAG, payload: { unit_tags } });
  dispatch(loadCampaignUnits(campaignId));
}

export const SET_HIGHLIGHTED_ZIPS = 'marketplace/campaign/set-highlighted-zips'
export const generateHighlightedZipCodes = () => async (dispatch, getState) => {
  const { campaignId } = getState().campaign;
  const { zip_codes } = await get(`/api/v1/campaigns/${campaignId}/generate_highlighted_zip_codes`);
  dispatch({ type: SET_HIGHLIGHTED_ZIPS, payload: zip_codes.join(",") });
}

export const CLONE_CAMPAIGN = 'marketplace/campaign/clone'
export const cloneCampaign = (campaign_id) => async (dispatch, getState) => {
  const response = await post(`/api/v1/campaigns/${campaign_id}/clone`);
  dispatch({ type: CLONE_CAMPAIGN, payload: response });

  return response.campaign;
}

export const LOAD_DEMOGRAPHICS = 'marketplace/campaign/load-demographics'
export const loadDemographics = () => async (dispatch, getState) => {
  const { campaignId } = getState().campaign
  const demographics = await get(`/api/v1/campaigns/${campaignId}/geopath`)
  dispatch({ type: LOAD_DEMOGRAPHICS, payload: demographics })
  if (demographics.demo_key === 'f419650a-8e66-443a-8b09-e80022cb104f') {
    /* General Population, update unit impressions and CPM, so, reloading state to get new values */
    dispatch(loadCampaignUnits(campaignId))
  }
}

export const getVendorLink = async (campaign_id) => {
  const response = await get(`/api/v1/campaigns/${campaign_id}/vendor_link`);

  return response.url;
}

export const getCampaignUsers = async (campaign_id) => {
  const response = await get(`/api/v1/campaigns/${campaign_id}/campaign_users`);

  return response;
}

export const SAVE_CAMPAIGN_UNIT = 'marketplace/campaign/save-campaign-unit';
export const saveCampaignUnit = (cu_token, payload) => async (dispatch, getState) => {
  const { campaignId } = getState().campaign
  try {
    await put(`/api/v1/campaign_units/${cu_token}/simple_update`, { campaign_unit: payload });
    dispatch(loadCampaignUnits(campaignId));
    return GlobalActions.showMessage('Campaign Unit details were saved!');
  } catch (error) {
    let message = `Error updating campaign unit: ${error}`;
    GlobalActions.showError(message);
  }
}

export const CREATE_CAMPAIGN_UNIT = 'marketplace/campaign/create-campaign-unit';
export const createCampaignUnit = (payload) => async (dispatch, getState) => {
  const { campaignId } = getState().campaign;
  try {
    await post(`/api/v1/campaign_units/simple_create`, { campaign_unit: {...payload, campaign_id: campaignId }});
    dispatch(loadCampaignUnits(campaignId));
    return GlobalActions.showMessage('Campaign Unit created!');
  } catch (error) {
    let message = 'Campaign Unit details were saved with the following errors: ';
    errors.forEach((error) => {
      message = `${message + error};`;
    });
    GlobalActions.showError(message);
  }
}

export const markCampaignUnitsAsReadyToInstall = (campaign_id, campaign_unit_tokens) => async (dispatch) => {
  try {
    await post(`/api/v1/campaigns/${campaign_id}/campaign_units/ready_to_install`, { campaign_unit_tokens });
  } catch (error) {
    console.log(error);
  }
}
