import React, { Component } from 'react';
import { connect } from 'react-redux';

class Prompt extends Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.state = {
      formData: {},
      errors: {},
    }
  }

  onSubmit() {
    const { formData } = this.state;
    const { email } = this.props;
    const { dialog, onSubmit } = this.props;
    this.validate() && onSubmit(dialog.prompt.type, { ...formData, email: email });
  }

  saveFieldValue(name, value) {
    this.setState({ formData: { ...this.state.formData, ...{ [name]: value }}})
  }

  validate() {
    const missingFields = this.missingFields();
    if (!missingFields.length) return true;
    this.recordErrors(missingFields);
    return false;
  }

  requiredFields() {
    const { email } = this.props;
    const { fields } = this.props.dialog.prompt;
    return fields.filter((f) => {
      if (email && f.name === 'email') return false;
      return f.required;
    })
  }

  missingFields() {
    const { formData } = this.state;

    const requiredFields = this.requiredFields();
    return requiredFields.filter(f => {
      const value = _.get(formData, f.name);
      const missing = _.isEmpty(value);
      return !!missing
    });
  }

  recordErrors(missingFields) {
    let errors = {}
    missingFields.forEach(f => {
      errors[f.name] = `${f.label} is required`;
    })
    this.setState({ errors: errors })
  }

  renderField(options) {
    const { errors } = this.state;
    const { email } = this.props;
    const hasError = Object.keys(errors).includes(options.name);
    if (!!email && options.name === 'email') return null;
    if (options.type === 'text') {
      return (
        <input
          key={options.name}
          type={options.type}
          name={options.name}
          placeholder={options.label}
          onChange={(e) => this.saveFieldValue(options.name, e.target.value )}
          className={hasError ? 'has_error' : ''}
        />
      )
    } else if(options.type === 'textarea') {
      return (
        <textarea
          key={options.name}
          name={options.name}
          placeholder={options.label}
          rows='4'
          onChange={(e) => this.saveFieldValue(options.name, e.target.value )}
          className={hasError ? 'has_error' : ''}
        ></textarea>
      )
    }
  }

  render() {
    const { onClose, onSubmit } = this.props;
    const { prompt } = this.props.dialog;
    const { title, fields } = prompt;
    return (
      <div>
        <div className="mobile-overlay" onClick={onClose}></div>
        <div className="mobile-prompt">
          <div className="prompt-title">
            <h4>{title}</h4>
          </div>
          <div className="prompt-fields">
            {fields.map(f => this.renderField(f))}
          </div>
          <div className="prompt-submit">
            <button onClick={this.onSubmit}>Submit</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
    dialog,
  }) => ({
    dialog,
});

export default connect(
  mapStateToProps,
  {}
)(Prompt)
