/* UNIT REDUCER **/
import queryString from 'query-string'
import moment from 'moment'

export default (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_UNITS': {
      const { selected_unit_ids } = action.payload
      const { units } = state
      const selected_units = selected_unit_ids.map(id => units.find(u => u.id === id))

      return {
        ...state,
        selected_units: _.sortBy(selected_units, 'supplier_face_id'),
        loading: false,
      }
    }
    case 'SELECT_ALL_UNITS': {
      const { units } = state
      return {
        ...state,
        selected_units: units.map(u => u.id)
      }
    }
    case 'DESELECT_UNITS': {
      return {
        ...state,
        selected_units: []
      }
    }
    case 'FETCH_UNIT_TAG_OPTIONS': {
      const { unit_tag_options } = action.payload;
      return {
        ...state,
        unit_tag_options
      }
    }
    case 'FETCH_UNIT_TAGS': {
      const { unit_tags } = action.payload;
      return {
        ...state,
        unit_tags
      }
    }
    case 'CREATE_UNIT_TAGS_SUCCESS': {
      const { unit_tags } = action.payload;
      return {
        ...state,
        unit_tags,
        tags_loading: false
      }
    }
    case 'CLEAR_UNIT_TAGS': {
      return {
        ...state,
        unit_tags: [],
        tags_loading: false
      }
    }
    case 'CREATE_UNIT_TAGS_BEGIN': {
      return {
        ...state,
        tags_loading: true
      }
    }
    default:
      return false
  }
}
