import * as React from "react";
import { connect } from "react-redux";

import { fetchOpenRfpCount } from "./actions";

class OpenRfps extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.respondToRfps = this.respondToRfps.bind(this);
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier);
    }
  }

  respondToRfps() {
    window.location.href = "/adquick_requests";
  }

  fetchData(supplier) {
    const { fetchOpenRfpCount } = this.props;
    fetchOpenRfpCount(supplier);
  }

  render() {
    const { open_rfp_count, loading_open_rfp_count } = this.props;

    return (
      <div id="open_rfps" className="todo-card">
        {loading_open_rfp_count && (
          <div className="loading-spinner">
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          </div>
        )}
        {!!!loading_open_rfp_count && (
          <div className={`count ${open_rfp_count >= 10 ? "high" : ""}`}>{open_rfp_count}</div>
        )}
        <div className="card-name">Open RFPs</div>
        <button className="todo-button" onClick={this.respondToRfps}>
          View
        </button>
      </div>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ vendor_dashboard: { open_rfp_count, loading_open_rfp_count, selected_supplier } }) => ({
    open_rfp_count,
    loading_open_rfp_count,
    selected_supplier,
  }),
  { fetchOpenRfpCount },
)(OpenRfps);
