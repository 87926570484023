import "./progress.css";
import * as React from "react";
import styled from "styled-components";

interface IProgressProps {
  percent: number[] | number;
  inactive?: boolean;
  ahead?: boolean;
}

interface IProgressState {
  isHover?: boolean;
}

export default class Progress extends React.Component<IProgressProps, IProgressState> {
  private initialState: IProgressState = {
    isHover: false,
  };

  constructor(props: IProgressProps) {
    super(props);
    this.state = this.initialState;
  }

  public render() {
    const { percent, ahead, inactive } = this.props;
    const percentList = Array.isArray(percent) ? percent.sort() : [percent];
    const colors = inactive ? ["#858585", "rgba(133, 133, 133, 0.5)"] : ["#44AC6B", "rgba(68, 172, 107, 0.5)"]; // supports 2 lines max currently

    const markerMargin = Math.min(...percentList);
    const isCompleted = markerMargin >= 100;

    return (
      <div className="progressBar">
        <div className="progressBar__lineContainer" id="background-line"></div>
        {percentList.map((ptg, index) => {
          const widthPtg = ahead && index == 1 ? ptg - percent[0] : ptg;
          const color = ahead ? colors[1 - index] : colors[index];
          return (
            <div
              className={`progressBar__line ${isCompleted && "progressBar__line--full"}`}
              key={index}
              style={{
                width: `${widthPtg > 100 ? 100 : widthPtg}%`,
                background: color,
              }}
            ></div>
          );
        })}
        <div
          className={`progressBar__marker ${isCompleted && "progressBar__marker--full"}`}
          style={{
            left: `${markerMargin}%`,
            background: inactive ? "#858585" : "#44ac6b",
          }}
        ></div>
      </div>
    );
  }
}
