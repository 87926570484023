import React, { Component } from 'react';
import { isMobile } from '../../../utils/mobile';
import { formatMoney } from 'accounting';
const POPUP_WIDTH = 275;

export default class Price extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {
      isExpanded: false,
      minPriceLiveValue: '',
      maxPriceLiveValue: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.min_price === null && nextProps.filters.max_price === null) {
      this.setState({
        minPriceLiveValue: '',
        maxPriceLiveValue: ''
      });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onChange(event) {
    let minPriceLiveValue = this.minPriceInput.value;
    let maxPriceLiveValue = this.maxPriceInput.value;
    if (isNaN(parseFloat(minPriceLiveValue))) minPriceLiveValue = '';
    if (isNaN(parseFloat(maxPriceLiveValue))) maxPriceLiveValue = '';
    this.setState({ minPriceLiveValue, maxPriceLiveValue });
  }

  onFilter() {
    const { minPriceLiveValue, maxPriceLiveValue } = this.state;
    const { setFilter } = this.props;
    let minPrice = minPriceLiveValue;
    let maxPrice = maxPriceLiveValue;
    if (isNaN(parseFloat(minPrice))) minPrice = null;
    if (isNaN(parseFloat(maxPrice))) maxPrice = null;
    this.setState({ isExpanded: false }, () =>{
      setFilter({ 'min_price': minPrice, 'max_price': maxPrice });
    });
  }

  onClear(triggerFilter = false) {
    this.setState({
      minPriceLiveValue: '', // not-yet-applied value of input
      maxPriceLiveValue: '', // not-yet-applied value of input
    }, triggerFilter ? this.onFilter : null)
  }

  getButtonLabel() {
    const filters = this.props.filters || {};
    const { min_price, max_price } = filters;
    const formattedMinPrice = formatMoney(min_price);
    const formattedMaxPrice = formatMoney(max_price);
    if (!min_price && !max_price) return 'Price'
    if (min_price && max_price) return `${formattedMinPrice}–${formattedMaxPrice}`;
    if (min_price) return `${formattedMinPrice}+`;
    if (max_price) return `Up to ${formattedMaxPrice}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { minPriceLiveValue, maxPriceLiveValue} = this.state;
    return (
      <div className="filter_popup price_range_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>PRICE RANGE</h4>
        <div className="padded_content">
          <p>All pricing is per 4 weeks</p>
          <div className="range_inputs">
            <input type="number" min="0" value={minPriceLiveValue} onChange={this.onChange} placeholder='Min' ref={node => this.minPriceInput = node}/>
            <input type="number" min="0" value={maxPriceLiveValue} onChange={this.onChange} placeholder='Max' ref={node => this.maxPriceInput = node}/>
          </div>
          <p className="actions">
            <a className="clear hidden-xs" onClick={e => this.onClear(true)}>Clear</a>
            <a onClick={this.onFilter}>Apply</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const filters = this.props.filters || {};
    const { min_price, max_price } = filters;
    const { isExpanded } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={`${min_price || max_price ? 'active' : ''}`}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};
