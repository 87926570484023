import React from "react";
import createClass from "create-react-class";
import { Modal, Button } from "react-bootstrap";
import UploadButton from './UploadButton';
import UploadStore from '../stores/UploadStore';
import GlobalActions from '../actions/GlobalActions';
import { post } from '../utils/api';
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

const ProposalSubmissionToolModal = createClass({
  displayName: "ProposalSubmissionToolModal",

  getInitialState() {
    return {
      state: "initial",
      uploadedFile: null,
      vendorContactName: null,
      vendorContactEmail: null,
      notes: null
    };
  },

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.setState({ state: "initial", uploadedFile: null });
    }
  },

  onHide() {
    this.props.onHide();
  },

  async onSubmit(e) {
    e.preventDefault();
    if (!this.state.uploadedFile) {
      GlobalActions.showError('Proposal or Past Campaign is required');
      return;
    }
    this.setState({ state: "saving" });
    const params = {
      file_url: this.state.uploadedFile.url,
      vendor_contact_name: this.state.vendorContactName,
      vendor_contact_email: this.state.vendorContactEmail,
      notes: this.state.notes
    }
    await $.post(`/api/v1/proposal_or_past_campaigns`, params)
    this.setState({ state: "thankyou" });
  },

  async onUploadComplete(upload, file, params = {}) {
    try {
      this.setState({ uploadedFile: file });
    } catch (error) {
      GlobalActions.showError('Something went wrong');
    }
  },

  render() {
    const tipContent = <div>
      <div>
        We want to make sure you keep any of your existing vendor relationship.
      </div>
      <div>
         Add their details here and we will make sure they get credit.
      </div>
    </div>

    return (
        <Modal className="proposal-tool-modal" show={this.props.show} onHide={this.props.onHide}>
          {this.state.state != "thankyou" && <Modal.Header>
            <Modal.Title>Upload a proposal or past campaign</Modal.Title>
          </Modal.Header>}
          <Modal.Body>
            {this.state.state == "thankyou" && <div className="proposal-tool-thanks">
              <div className="thanks-check-container">
                <i className="fa fa-check-circle-o thanks-check" aria-hidden="true"></i>
              </div>
              <div className="upload-successful-container">
                <h3>Upload successful</h3>
              </div>
              <div className="submission-container">
                <div>Thank you for your submission. </div>
                <div>We'll get back to you with details within 24 hours.</div>
              </div>
            </div>}
            {this.state.state == "saving" && <div className="searchTab__loading" style={{ margin: 'auto' }} />}
            {this.state.state == "initial" && <form className="form" onSubmit={this.onSubmit}>
              <div style={{overflow:'hidden'}}>
                <div className="form-group">
                  <label>Upload Area</label>
                  <div className="modal-upload-area">
                    <UploadButton onComplete={(upload, file) => { this.onUploadComplete(upload, file, { usedDropZone: true }) }}>
                      <div>
                        <div>{this.state.uploadedFile && `Uploaded: ${this.state.uploadedFile.name}. `}Drop files here or <u>click to upload</u></div>
                      </div>
                    </UploadButton>
                  </div>
                </div>
              </div>

              <div style={{overflow:'hidden'}}>
                <div className="form-group">
                  <label>
                    Vendor Contact Name
                    <span className="info">
                      <Tooltip placement="top" overlay={tipContent} key="vendor-contact-name-tip">
                        <i className="fa fa-info-circle"/>
                      </Tooltip>
                    </span>
                  </label>
                  <input className="form-control"
                         type="input"
                         defaultValue={""}
                         placeholder="Optional"
                         onChange={(e) => { this.setState({ vendorContactName: e.target.value }); }} />
                </div>
              </div>

              <div style={{overflow:'hidden'}}>
                <div className="form-group">
                  <label>Vendor Contact Email</label>
                  <input className="form-control"
                         type="input"
                         defaultValue={""}
                         placeholder="Optional"
                         onChange={(e) => { this.setState({ vendorContactEmail: e.target.value }); }} />
                </div>
              </div>

              <div style={{overflow:'hidden'}}>
                <div className="form-group">
                  <label style={{marginLeft: 5 }}>Notes</label>
                  <textarea
                    className="form-control"
                    defaultValue={this.state.zip_codes}
                    placeholder="Any other details we should know about this campaigns?"
                    style={{ minWidth: "500px" }}
                    onChange={e => {
                      this.setState({ notes: e.target.value });
                    }}
                  />
                </div>
              </div>
            </form>}
          </Modal.Body>
          <Modal.Footer>
            {this.state.state != "thankyou" && <button
              disabled={this.state.state == "saving"}
              className="btn btn_primary register__submit proposal-button"
              type="submit"
              onClick={this.onSubmit}
            >
              Submit
            </button>}
            {this.state.state == "thankyou" && <button
              className="btn btn-default btn-white-fill register__submit proposal-button"
              type="submit"
              onClick={this.onHide}
            >
              Close
            </button>}
          </Modal.Footer>
        </Modal>
    );
  }
});

export default ProposalSubmissionToolModal;
