import React from 'react';
import Subheader from '../Subheader'

export default () => (
  <div>
    <Subheader />
    <div className="container create-campaign" style={{marginTop: 20}}>
      <div className="alert alert-success text-center">
        {(AppConfig.advertiserGreyLabel && AppConfig.advertiserGreyLabel.subdomain === "compass") ?
          <div>
            <div><b>Campaign created!</b></div>
            <div>
              <br />
              You will get an email shortly with all the units in your selected areas.
              <br />
              <br />
              The next step is to request availability on the units you are interested in.
              You'll be <br />notified via email when vendors respond.
              Please note that this can take up to <b>72 hours</b>.
            </div>
          </div>
          :
          <div>
            <div><b>Thanks!</b></div><div>We will email you options within 48 hours.</div>
          </div>
        }
      </div>
    </div>
  </div>
)
