import {
  GET_BACKGROUND_JOB_STATUS
} from '../actions/BackgroundJobActions';

export default (state = []  , { type, payload }) => {
  if (type === GET_BACKGROUND_JOB_STATUS) {
    return payload
  } else {
    return state;
  }
}
