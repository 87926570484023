import * as React from 'react';
import _ from 'lodash';
import DirectAttributionModal from './DirectAttributionModal'
import DirectResponsesModal from './DirectResponsesModal'
import AdquickSnippetModal from './AdquickSnippetModal'
import SegmentIntegrationModal from './SegmentIntegrationModal'
import GoogleAdwordsModal from './GoogleAdwordsModal'
import GlobalActions from "../../../../actions/GlobalActions"
import { put } from '../../../../utils/api'

class AnalyticSetupModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this)
    this.oldSaveAnalytics = this.oldSaveAnalytics.bind(this)
  }

  onSubmit() {
    const { popupOptions } = this.props
    const { onComplete, analytic } = popupOptions
    onComplete(analytic)
    GlobalActions.closePopup();
  }

  async oldSaveAnalytics(payload) {
    const { popupOptions } = this.props
    const { token } = popupOptions
    const response = await put(`/api/v1/campaigns/${token}/analytics`, payload)
  }

  renderBody() {
    const { popupOptions } = this.props
    const { card, token, analytic, onUpdate, old_analytics } = popupOptions
    const direct_response = {
      sms_default_response: old_analytics.sms_default_response,
      sms_keywords: old_analytics.sms_keywords
    }
    const options = {
      exposure_analysis: <DirectAttributionModal onSubmit={this.onSubmit} />,
      sms: <DirectResponsesModal onSubmit={this.onSubmit} oldSave={this.oldSaveAnalytics} direct_response={direct_response}/>,
      snippet: <AdquickSnippetModal token={token} onSubmit={this.onSubmit} />,
      google_adwords: <GoogleAdwordsModal token={token} analytic={analytic} onUpdate={onUpdate} />,
      segment_integration: <SegmentIntegrationModal token={token} onSubmit={this.onSubmit} />
    }
    return options[card.key] || <div>Modal not defined</div>
  }

  render() {
    return (
      <div className="analytic_setup_modal analytics_common" onClick={e => e.stopPropagation()}>
        {this.renderBody()}
      </div>
    )
  }
}

export default AnalyticSetupModal;
