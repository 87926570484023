import React from 'react';
import moment from 'moment';
import { DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import { isMobile } from '../../../utils/mobile';
import ToggleFilter from '../../ToggleFilter';
import { START_DATE } from 'react-dates/lib/constants';

const POPUP_WIDTH = 620;
const PRESETS = [
  { text: 'Any length', length: 0, period: 'days' },
  { text: '4 weeks', length: 4, period: 'weeks' },
  { text: '8 weeks', length: 8, period: 'weeks' },
  { text: '12 weeks', length: 12, period: 'weeks' },
  { text: '16 weeks', length: 16, period: 'weeks' }
];

export default class DateSearch extends React.Component {

  constructor(props) {
    super(props);
    const { filters } = this.props;

    this.state = {
      isExpanded: false,
      focusedInput: START_DATE,
      partial_date_match: filters.partial_date_match || false,
      include_holds: filters.include_holds || false,
      selectedPreset: 'Any length'
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.setPartialDateMatchFilter = this.setPartialDateMatchFilter.bind(this);
    this.setIncludeHoldsFilter = this.setIncludeHoldsFilter.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onDone = this.onDone.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onDatesChange(dates) {
    const { setFilter } = this.props;
    const { startDate, endDate } = dates;
    setFilter({'startDate': startDate, 'endDate': endDate});
  }

  onFocusChange(focusedInput) {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  onClear(triggerFilter = false) {
    const { setFilter } = this.props;
    setFilter({'startDate': null, 'endDate': null})
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  onPresetSelected(selectedPreset) {
    const { text, length, period } = selectedPreset;
    if (text == 'Any length') {
      this.setState({ selectedPreset: text })
    } else {
      let { startDate } = this.state;
      startDate = startDate ? moment(startDate) : this.findNextMonday();
      const endDate = moment(startDate).add(length, period).subtract(1, 'day');
      this.setState({ selectedPreset: text }, this.onDatesChange({ startDate, endDate }));
    }
  }

  findNextMonday() {
    return moment().startOf('isoWeek').add(1, 'week')
  }

  getButtonLabel() {
    const filters = this.props.filters || {};
    const { startDate, endDate } = filters;
    if (!startDate || !endDate) return 'Date';
    return `${moment(startDate).format('MMM D')} - ${moment(endDate) .format('MMM D')}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderHeader() {
    const { selectedPreset } = this.state;
    return (
      <div className="date_range_poupup_header">
        <h4>How long?</h4>
        {PRESETS.map((preset, idx) => {
          return (
            <a className={`${selectedPreset == preset.text ? 'active' : ''}`}
              key={idx}
              onClick={() => this.onPresetSelected(preset)}
            >
              {preset.text}
            </a>
          )
        })}
      </div>
    )
  }

  setPartialDateMatchFilter(filter, value) {
    const { setFilter } = this.props;
    this.setState({ partial_date_match: value }, setFilter(filter, value));
  }

  setIncludeHoldsFilter(filter, value) {
    const { setFilter } = this.props;
    this.setState({ include_holds: value }, setFilter(filter, value));
  }

  renderPopup() {
    const { focusedInput, partial_date_match, include_holds } = this.state;
    const isCampaignView = !!_.get(this.props, 'campaign.campaignId', null);
    const { setFilter } = this.props;
    const filters = this.props.filters || {};
    const { startDate, endDate } = filters;

    return (
      <div className="filter_popup date_range_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        {this.renderHeader()}

        <DayPickerRangeController
          startDate={startDate ? moment(startDate) : null}
          endDate={endDate ? moment(endDate) : null}
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          numberOfMonths={isMobile() ? 1 : 2}
          hideKeyboardShortcutsPanel={true}
          noBorder={true}
          enableOutsideDays={false}
          isOutsideRange={day => !isInclusivelyAfterDay(day, moment())}
        />
        <div className="padded_content">
          <div className="toggle-filters">
            <ToggleFilter
              filters={{ partial_date_match }}
              setFilter={this.setPartialDateMatchFilter}
              attributeName="partial_date_match"
              toggleOffName="Full Availability"
              toggleOnName="All Availability"
            />
            <ToggleFilter
              filters={{ include_holds }}
              setFilter={this.setIncludeHoldsFilter}
              attributeName="include_holds"
              toggleOffName="Exclude Held Units"
              toggleOnName="Include Held Units"
            />
          </div>
          <p className="actions">
            <div>
              <a className="clear hidden-xs" onClick={(e) => this.onClear(true)}>Clear</a>
              <a onClick={this.onDone}>Done</a>
            </div>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const { isExpanded } = this.state;
    const filters = this.props.filters || {};
    const { startDate, endDate } = filters;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={`${(startDate || endDate || isExpanded) ? 'active' : ''}`}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};
