import React from 'react';
import createClass from 'create-react-class'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

const ComposeEmail = createClass({
  displayName: 'EditEmailModal',

  getInitialState() {
    const { options } = this.props

    return {
      id: options.id,
      name: options.name,
      attribute: '',
      options: []
    }
  },

  componentWillMount() {
    this.debounceFetchCompanies = _.debounce((query) => {
      if(!!this.props.options.select && query.length > 0) {
        const response = this.props.options.select.onInputChange(query)
        this.saveOptions(response)
      }
    }, 1000)
  },

  async saveOptions(response) {
    const options = await response
    const result = options.reduce((acc, { id, name }) => {
      acc.push(Object.assign({}, { value: id, label: name }))
      return acc
    }, [])
    this.setState({ options: result })
  },

  onSubmit(e) {
    const { id, attribute } = this.state
    e.preventDefault()
    const payload = { [this.props.options.attribute]: attribute }
    this.props.options.update(id, payload)
  },

  renderText() {
    return <input className="form-control" value={this.state.attribute} onChange={e => this.setState({ attribute: e.target.value })} />
  },

  renderSelect() {
    const { attribute, options } = this.state
    return <Select
      options={options}
      value={options.find(o => o.value === attribute)}
      onChange={(selected) => this.setState({ attribute: selected.value })}
      onInputChange={this.debounceFetchCompanies}
      placeholder="Companies"
    />
  },

  onScrapeProfile() {
    const { id } = this.state
    this.props.options.scrape_profile(id);
  },

  renderInput() {
    const { options } = this.props
    if (!!options.select) { return this.renderSelect() }
    if (!!options.text) { return this.renderText() }
  },

  render() {
    const { show, onHide, options } = this.props
    const { name } = this.state

    return (
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header>
          <Modal.Title>{`Edit ${name} ${options.label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {options.label === 'Companies' &&
            <div className="form-group row" style={{marginTop:10}}>
              <div className="col-sm-12">
                <button className="btn btn_primary register__submit" onClick={this.onScrapeProfile}>Find Company by Linkedin Profile</button>
              </div>
            </div>
          }
          <div className="form-group row">
            <div className="col-sm-12">
              <label>{options.label}</label>
              {this.renderInput()}
            </div>
          </div>
          <div className="form-group row" style={{marginTop:10}}>
            <div className="col-sm-12">
              <button className="btn btn_primary register__submit" onClick={this.onSubmit}>Update</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
})

export default ComposeEmail
