import "./breadcrumb.css";
import FaChevronRight from "@adquick/icons/fa/FaChevronRight";
import FaRegCheckCircle from "@adquick/icons/fa/FaRegCheckCircle";
import React, { ReactElement, ReactNode, createContext, useContext, useState } from "react";

/*----------------------------------------------------------------------*/
/* This component takes the current step position as reference to paint */
/* the checked or next steps correctly.                                 */
/* This can be override by passing `checked` prop which will force the  */
/* checked style on the step.                                           */
/*----------------------------------------------------------------------*/

export interface BreadcrumbProps {
  children: ReactNode;
  addClass: string;
}

export interface BreadcrumbContext {
  current?: string;
  checked?: string[];
  next?: string[];
}

const BreadcrumbContext = createContext<BreadcrumbContext>({});

export const Breadcrumb = ({ children, addClass = "" }: BreadcrumbProps): ReactElement => {
  const [stepMap, setStepMap] = useState<BreadcrumbContext>();
  const extractCurrent = (props: StepProps): boolean => !!props.isCurrent;
  const mapChildrenProps = (item: { props: StepProps }): StepProps => item?.props;
  const stepsTrackProps = React.Children.map(children, mapChildrenProps);
  const currentIndex = stepsTrackProps?.findIndex(extractCurrent) || 0;
  const extractChecked = (props: StepProps, i: number): boolean => i < currentIndex;
  const extractNext = (props: StepProps, i: number): boolean => i > currentIndex;
  const extractStep = (props: StepProps): string => (props.step !== undefined ? props.step : props.children);

  React.useEffect(() => {
    const step = stepsTrackProps?.find(extractCurrent);

    setStepMap({
      checked: stepsTrackProps?.filter(extractChecked)?.map(extractStep),
      current: step ? extractStep(step) : undefined,
      next: stepsTrackProps?.filter(extractNext)?.map(extractStep),
    });
  }, [currentIndex]);

  return (
    <BreadcrumbContext.Provider value={stepMap as BreadcrumbContext}>
      <ul className={`breadcrumb ${addClass}`}>{children}</ul>
    </BreadcrumbContext.Provider>
  );
};

export enum StepStateModifier {
  Checked = "breadcrumb__step--checked",
  Present = "breadcrumb__step--active",
  Next = "breadcrumb__step--disabled",
}

export type StepProps = StepWithString | StepWithElement;

interface StepWithString {
  isCurrent?: boolean;
  onClick?: () => void;
  checked?: boolean;
  children: string;
  step: undefined;
}

interface StepWithElement {
  isCurrent?: boolean;
  onClick?: () => void;
  checked?: boolean;
  children: ReactElement;
  step: string;
}

export const Step = (props: StepProps): ReactElement => {
  const [modifier, setModifier] = useState<StepStateModifier>(StepStateModifier.Next);
  const stepMap = useContext(BreadcrumbContext);
  const isChecked = modifier === StepStateModifier.Checked;

  const currentStep: string = props.step !== undefined ? props.step : props.children;

  React.useEffect(() => {
    if (stepMap?.current === currentStep) {
      setModifier(StepStateModifier.Present);
    }

    if (stepMap?.checked?.includes(currentStep) || props.checked) {
      setModifier(StepStateModifier.Checked);
    }

    if (stepMap?.next?.includes(currentStep) && !props.checked) {
      setModifier(StepStateModifier.Next);
    }
  }, [stepMap?.current]);

  return (
    <li
      onClick={(): void => props.onClick && props.onClick()}
      className={`breadcrumb__step ${modifier} ${props.onClick ? "breadcrumb__step--clickable" : ""}`}
    >
      {isChecked && (
        <span className="circle">
          <FaRegCheckCircle />
        </span>
      )}
      {props.children}
      {
        <span className="chevron">
          <FaChevronRight />
        </span>
      }
    </li>
  );
};

export default Breadcrumb;
