const initialState = {
  places: [],
  search_results: [],
  filtered: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PLACES_SUCCESS": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "SEARCH_PLACES_SUCCESS": {
      const source_ids = state.places.map((p) => p.source_id);
      const search_results = action.payload.search_results.filter((r) => !source_ids.includes(r.id));
      const max_results_reached = action.payload.search_results.length >= 40;
      return {
        ...state,
        max_results_reached,
        search_results
      };
    }
    case "SAVE_PLACES_SUCCESS": {
      const new_places = state.places.concat(action.payload.places);
      const source_ids = action.payload.places.map((p) => p.source_id);
      const remaining_search_results = state.search_results.filter((r) => !source_ids.includes(r.id));

      return {
        ...state,
        search_results: remaining_search_results,
        places: new_places,
        max_results_reached: false
      };
    }
    case "REMOVE_PLACES_SUCCESS": {
      const remaining_places = state.places.filter((p) => !action.payload.place_ids.includes(p.id));
      const removed_places = state.places.filter((p) => action.payload.place_ids.includes(p.id)).map((p) => {
        return { ...p, id: p.source_id };
      });
      const new_search_results = removed_places.concat(state.search_results);
      return {
        ...state,
        search_results: new_search_results,
        places: remaining_places,
        max_results_reached: false
      };
    }
    case "FILTER_PLACES_BY_NAME": {
      return {
        ...state,
        filtered: action.payload.place_names
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
