import * as React from 'react';
import { get } from '../../../../utils/api'
import GlobalStore from '../../../../stores/GlobalStore'

class AdquickSnippetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
    this.onInputChange = this.onInputChange.bind(this)
    this.onCopySnippet = this.onCopySnippet.bind(this)
  }

  componentDidMount() {
    this.onGenerateAnalyticSnippet()
  }

  onInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  async onGenerateAnalyticSnippet() {
    const { token } = this.props
    const { snippet } = await get(`/api/v1/campaigns/${token}/analytics/retrieve_snippet`)
    this.setState({ analytic_snippet: snippet });
  }

  onCopySnippet() {
    this.snippetTextarea.select();
    const { analytic_snippet } = this.state
    const fallBack = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        GlobalStore.onShowMessage("Snippet copied to your clipboard")
      } catch (err) {
        GlobalStore.onShowError(`Error copying snippet to clipboard: ${err}`)
      }

      document.body.removeChild(textArea);
    }

    try {
      navigator.clipboard.writeText(analytic_snippet)
      GlobalStore.onShowMessage("Snippet copied to your clipboard")
    } catch (error) {
      console.log(error)
      fallBack(analytic_snippet)
    }
  }

  render() {
    const { onSubmit } = this.props
    return (
      <div className="modal_default">
        <div className="modal_header">
          <h5>AdQuick Snippet</h5>
        </div>
        <div className="modal_body">
          <div className="field">
            <div className="description">
              The AdQuick snippet enables you to track geo-located lift in website traffic and conversions.
            </div>
            <div className="description" style={{marginTop: '15px'}}>
              Simply click snippet below to copy it, paste it into the {"<head>"} of your website, then call the page and track methods as needed.{" "}
              <a href="https://adquick.quip.com/yuFYATPZR97m/AdQuick-Snippet-Documentation">
                Click here to view the full setup documentation.
              </a>
            </div>
            <textarea
              className="form-control survey-info"
              name="analytic_snippet"
              value={this.state.analytic_snippet}
              ref={(input) => this.snippetTextarea = input }
              onClick={this.onCopySnippet}
              spellCheck={false}
            />
          </div>
          <div className="modal_footer">
            <button className="primary-button" onClick={onSubmit}> Close</button>
          </div>
        </div>
      </div>
    )
  }
}

export default AdquickSnippetModal;