import * as React from "react";
import TextIcon from "../TextIcon";
import Theme from "../../theme";
import { PaddingProperty } from "csstype";

export enum TextStyle {
  Default = "TEXT_DEFAULT",
  Headline = "TEXT_HEADLINE",
  Subhead = "TEXT_SUBHEAD",
  Title = "TEXT_TITLE",
  Small = "TEXT_SMALL",
  Big = "TEXT_BIG",
}

export interface ITextProps {
  children: React.ReactNode;
  theme?: TextStyle;
  faIcon?: string;
  srcIcon?: string;
  color?: string;
  padding?: PaddingProperty<string>;
  capitalize?: boolean;
}

export const Text = ({
  children,
  theme = TextStyle.Default,
  faIcon,
  srcIcon,
  color,
  capitalize,
  padding,
}: ITextProps) => (
  <TextIcon
    theme={Theme[theme]}
    textColor={color}
    faIcon={faIcon}
    padding={padding}
    capitalize={capitalize}
    srcIcon={srcIcon}>
    {children}
  </TextIcon>
);

export default Text;
