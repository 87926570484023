import React from "react";
import createClass from "create-react-class";
import moment from "moment";
import PropTypes from "prop-types";

const howLongOptions = [
  { label: "", value: "" },
  { label: "4 weeks", value: 4 },
  { label: "8 weeks", value: 8 },
  { label: "12 weeks", value: 12 },
  { label: "16 weeks", value: 16 },
  { label: "24 weeks", value: 24 },
  { label: "28 weeks", value: 28 },
  { label: "32 weeks", value: 32 },
  { label: "36 weeks", value: 36 },
  { label: "40 weeks", value: 40 },
  { label: "44 weeks", value: 44 },
  { label: "48 weeks", value: 48 },
  { label: "52 weeks", value: 52 },
]

export default createClass({
  displayName: "HowLongDropDown",

  propTypes: {
    campaign: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  howLongChanged(value) {
    if (value != "" && this.props.campaign.start_date) {
      const startDate = moment.utc(this.props.campaign.start_date)
      const endDate = startDate.clone().add(parseInt(value), "w").subtract(1, "day")
      this.props.setAttributes({ end_date: endDate.format("LL") })
    }
  },

  render() {
    return (
      <div>
        <span className="section-name">How long?</span>
        <select className="form-control" onChange={e => this.howLongChanged(e.target.value)}>
          {howLongOptions.map(({ label, value }) => <option value={value} key={value}>{label}</option>)}
        </select>
      </div>
    );
  },
});
