import React from "react";
import { browserHistory, withRouter } from 'react-router-legacy';
import SummaryTab from "./SummaryTab";
import { Tabs, Tab, Row, Col, Nav, NavItem } from "react-bootstrap";
import AddByMap from "../../Vendors/CampaignBuilder/AddByMap";
import UnitsAddedCount from "../../Vendors/CampaignBuilder/UnitsAddedCount";
import ReviewUnits from "../../Vendors/CampaignBuilder/ReviewUnits";
import GlobalActions from "../../../actions/GlobalActions";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import QaIssues from "../Manage/QaIssues";

import {
  loadCampaignUnits
} from "../actions";
import {
  loadFaceIds,
  resetBuilderState,
  filterSupplierFaceIds,
  loadBuilderCampaignUnits,
  loadBuilderCampaignUnitsBg, loadBuilderAvailabilityBg
} from "../../Vendors/CampaignBuilder/actions";

class Summary extends React.Component {
  constructor(props) {
    super(props);
    const activeTab = this.determineStartingTab();
    this.state = {
      activeTab,
    };

    this.determineStartingTab = this.determineStartingTab.bind(this);
  }

  determineStartingTab() {
    const { currentUser } = this.props;
    if (currentUser && currentUser.is_admin) {
      return 'manage_units';
    }
    return this.props.params.step || "campaign_info";
  }

  componentDidMount() {
    const { activeTab } = this.state;
    const { campaign, loadFaceIds, loadBuilderCampaignUnitsBg, loadBuilderAvailabilityBg } = this.props;
    const campaign_token = (campaign && campaign.token) ? campaign.token : window.location.pathname.split('/summary')[0].split('campaigns/')[1];
    loadFaceIds();
    if (activeTab === "manage_units") {
      loadBuilderCampaignUnitsBg(campaign_token);
      loadBuilderAvailabilityBg(campaign_token);
    }
  }

  componentWillUnmount() {
    const { resetBuilderState, campaign, loadCampaignUnits } = this.props;
    resetBuilderState();
    loadCampaignUnits(campaign.token);
  }

  componentDidUpdate(prevProps, prevState) {
    const { units_added, filterSupplierFaceIds, loadCampaignUnits, campaign } = this.props;
    const prev_campaign_units = prevProps.units_added;

    if (units_added && units_added.length > 0 && units_added !== prev_campaign_units) {
      const excluded_supplier_ids = units_added.filter(n => n).map((campaign_unit) => {
        return campaign_unit.supplier_face_id;
      });
      const { supplier_face_ids } = this.props;
      filterSupplierFaceIds(supplier_face_ids, excluded_supplier_ids);
    }

    const { activeTab } = this.state;
    if (activeTab === "manage_units"  && prevState.activeTab !== "manage_units") {
      loadCampaignUnits(campaign.token);
    }
  }

  async onAddOrUpdateUnits(action = null) {
    if (action == "updating") {
      GlobalActions.showMessage("Flights successfully updated");
    } else {
      GlobalActions.showMessage("Units successfully added to the campaign");
    }
  }

  renderReviewUnitsTab() {
    const { campaign, supplier_face_ids, units_added, params: { campaign_id } } = this.props;
    const { currentUser } = this.props;
    const { activeTab } = this.state;
    const campaign_token = campaign && campaign.token || campaign_id

    if (!campaign_token) return (<div></div>);

    return (
      <Tab title="Manage Units" eventKey="manage_units">
        {activeTab == "manage_units" && <div className="campaign_builder" style={{padding: "20px 40px"}}>
          <UnitsAddedCount units_added={units_added} style={{top: 0, paddingRight: 5}} unit_text={"Total Units"} />
          <ReviewUnits
            onSubmit={this.onAddOrUpdateUnits.bind(this)}
            supplier_face_ids={supplier_face_ids}
            campaign_dates={{
              start_date: campaign.start_date && moment(campaign.start_date).format("M/D/YY"),
              end_date: campaign.end_date && moment(campaign.end_date).format("M/D/YY")
            }}
            campaign_token={campaign_token}
            campaign={campaign}
            units_added={units_added}
          />
        </div>}
      </Tab>
    );
  }

  renderAddByMapTab() {
    const { campaign, supplier_face_ids, units_added } = this.props;
    const { activeTab } = this.state;

    return (
      <Tab title="Add Units" eventKey="add_by_map">
        {activeTab == "add_by_map" &&
          <div className="campaign_builder">
            <UnitsAddedCount units_added={units_added} style={{top: 0, paddingRight: 5}} unit_text={"Total Units"} />
            <AddByMap
              active={activeTab == "add_by_map"}
              campaign={campaign}
              campaign_token={campaign.token}
              campaign_dates={{
                start_date: campaign.start_date && moment(campaign.start_date).format("M/D/YY"),
                end_date: campaign.end_date && moment(campaign.end_date).format("M/D/YY")
              }}
              onSubmit={this.onAddOrUpdateUnits.bind(this)}
              supplier_face_ids={supplier_face_ids}
              units_added={units_added}
            />
          </div>}
      </Tab>
    );
  }

  onTabChange(activeTab) {
    this.goToTab(activeTab);
  }

  goToTab(activeTab) {
    const { campaign: { token }, loadBuilderCampaignUnitsBg, units_added } = this.props;
    this.setState({ activeTab }, () =>
      browserHistory.push(`/campaigns/${token}/summary/${activeTab}`)
    );
    if (activeTab === "manage_units" && (units_added == null || units_added.length == 0)) {
      loadBuilderCampaignUnitsBg(token);
    }
  }

  render() {
    const { activeTab } = this.state;
    const { currentUser } = this.props;
    const { campaign } = this.props;
    const token = campaign && campaign.token;
    
    if (!token) return (<div></div>)
    if (currentUser.supplier_id != null && (currentUser.supplier_id !== campaign.supplier_id)) return (<div></div>);
    
    return (
      <div className="vendor_summary" data-turbo="false">
        <Tabs
          activeKey={activeTab}
          onSelect={(activeTab) => this.onTabChange(activeTab)}
          id="edit-unit-tabs"
          animation={false}
        >
          {(currentUser && currentUser.is_supplier) && <Tab title="Campaign Info" eventKey="campaign_info">
            {activeTab == "campaign_info" && <SummaryTab {...this.props} />}
          </Tab>}
          {(currentUser && currentUser.is_supplier && !!!currentUser.is_supplier_contact) && this.renderAddByMapTab()}
          {(currentUser && (currentUser.is_supplier || currentUser.is_admin) && !!!currentUser.is_supplier_contact) && this.renderReviewUnitsTab()}
          <Tab title="QA Issues" eventKey="qa_issues">
            <div className="container">
              <QaIssues data={{campaign: {token: token}, campaignPermissions: {show_unit_removal_reason: false} }} is_managed={true}/>
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
};


export default withRouter(
  connect(
    ({
      campaign_builder_ui: {
        supplier_face_ids,
        units_added
      },
      currentUser
    }) => ({
      supplier_face_ids,
      units_added,
      currentUser
    }), {
      loadFaceIds,
      loadBuilderCampaignUnits,
      loadBuilderCampaignUnitsBg,
      loadBuilderAvailabilityBg,
      filterSupplierFaceIds,
      resetBuilderState,
      loadCampaignUnits
    }
  )(Summary)
);
