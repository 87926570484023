import React from "react";
import { connect } from "react-redux";

import { fetchLeaseAttachments, fetchUnitLease } from "../Inventory/Unit/actions";
import LeaseMaterials from "../Inventory/Unit/LeaseMaterials";
import Loading from "../Loading";
import StatusBadge from "../StatusBadge";

class AdminLeaseSubtab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      code: "",
      name: "",
      start_date: null,
      end_date: null,
      status: "",
      notes: "",
      retired_at: null,
      days_remaining: null,
    };
    this.fetchUnitLease = this.fetchUnitLease.bind(this);
  }

  componentDidMount() {
    this.fetchUnitLease();
  }

  async fetchUnitLease() {
    const { fetchUnitLease, unit } = this.props;
    const lease = await fetchUnitLease(unit.id);
    this.setState(
      {
        id: lease.id || null,
        code: lease.code || "",
        name: lease.name || "",
        start_date: lease.start_date || null,
        end_date: lease.end_date || null,
        status: lease.status || "active",
        notes: lease.notes || "",
        retired_at: lease.retired_at || null,
        days_remaining: lease.days_remaining || null,
        loading: false,
        attachments: [],
        attachments_loading: true,
      },
      this.fetchAttachments,
    );
  }

  async fetchAttachments() {
    const { fetchLeaseAttachments } = this.props;
    const { id } = this.state;
    if (id) {
      const attachments = await fetchLeaseAttachments(id);
      this.setState({
        attachments_loading: false,
        attachments,
      });
    }
  }

  renderMaterials() {
    const { attachments_loading, attachments } = this.state;
    return (
      <div>
        <label>Lease Materials</label>
        <LeaseMaterials attachments_loading={attachments_loading} attachments={attachments} deletable={false} />
      </div>
    );
  }

  render() {
    const { unit } = this.props;
    const labelStyle = { fontSize: 14 };
    const { loading, code, name, start_date, end_date, status, notes, days_remaining } = this.state;
    const expired_lease = days_remaining <= 0;

    if (loading) return <Loading />;
    return (
      <div>
        <div className="row" style={{ margin: 20 }}>
          <div className="col-md-6">
            <div>
              <label>Basic Info</label>
              <div style={{ marginBottom: 5 }}>
                Lease Code: <span style={{ fontWeight: 600 }}>{code}</span>
              </div>
              <div style={{ marginBottom: 5 }}>
                Lease Name: <span style={{ fontWeight: 600 }}>{name}</span>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <label>Dates</label>
              <div style={{ marginBottom: 5 }}>
                Start: <span style={{ fontWeight: 600 }}>{start_date}</span>
              </div>
              <div style={{ marginBottom: 5 }}>
                End: <span style={{ fontWeight: 600 }}>{end_date}</span>
              </div>
            </div>
            {end_date && (
              <div>
                Days Remaining:{" "}
                <span style={{ fontWeight: 600, color: expired_lease ? "#ff0000" : "#000000" }}>{days_remaining}</span>
              </div>
            )}
          </div>
          <div className="col-md-6">
            <div>
              <label>Status</label>
              <StatusBadge status={status} />
            </div>
            <div style={{ marginTop: 28 }}>{this.renderMaterials()}</div>
            <div>
              <label>Notes</label>
              {notes && <div>{notes}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  fetchUnitLease,
  fetchLeaseAttachments,
})(AdminLeaseSubtab);
