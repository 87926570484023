import React, { Component } from 'react';

class UnitTypeIcon extends Component {

  render(){
    const { isLime, type } = this.props;
    const className = `unit_type_icon ${isLime ? 'lime' : type}`;
    return (
      <i className={className}/>
    )
  }

}

export default UnitTypeIcon
