import React, { Component } from 'react';

class PillBadge extends Component {

  render(){
    const { label, className } = this.props;
    return (
      <span className={`${className} badge badge-light`}>{_.startCase(label)}</span>
    )
  }

}

export default PillBadge
