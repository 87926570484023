import React from "react";
import { Modal } from "react-bootstrap";

import { mapUnits } from "../../models/Units";
import PlacesStore from "../../stores/PlacesStore";
import AddUnitsFromMarkers from "../Map/AddUnitsFromMarkers";
import EditUnitsFromMarkers from "../Map/EditUnitsFromMarkers";
import LocationPinModal from "../Map/LocationPinModal";
import VendorAddUnitsFromMarkers from "../Map/VendorAddUnitsFromMarkers";

export default class Modals extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      unitPressed: null
    };
    this.hideModal = this.hideModal.bind(this);
  }

  get vendorPermissions() {
    const { campaign, map, user, isAddByMap } = this.props;
    return campaign && map.selected_markers && user.permissions.replace_create_campaign_with_vendor_campaign_builder && isAddByMap;
  }

  get browserPermissions() {
    return this.props.map.selected_markers && !this.props.campaign;
  }

  componentDidMount() {
    PlacesStore.listen(this.onPlaceChange.bind(this));
  }

  onPlaceChange(event) {
    if (event === "places:locationPin") {
      const { locationPin } = PlacesStore.state;
      console.log("onPlaceChange", locationPin);
      this.setState({ locationPin });
    }
  }

  hideModal() {
    this.props.toggleSelectedMarkers();
  }

  render() {
    return (
      <div>
        <Modal
          show={!!this.state.locationPin}
          onHide={() => {
            this.setState({ locationPin: null });
          }}
          onEnter={() => {
            $(".modal").attr("tabindex", null);
          }}
          autoFocus={false}
        >
          <LocationPinModal
            {...this.state.locationPin}
            onHide={() => {
              this.setState({ locationPin: null });
            }}
          />
        </Modal>

        <Modal
          show={this.props.map.selected_markers && (this.vendorPermissions || this.browserPermissions)}
          onHide={this.hideModal.bind(this)}
          onEnter={() => {
            $(".modal").attr("tabindex", null);
          }}
          autoFocus={false}
        >
          {this.addUnitsFromMarkers()}
        </Modal>
      </div>
    );
  }

  markers() {
    const units = mapUnits(this.props.units);
    return units ? units.features : [];
  }

  addUnitsFromMarkers() {
    const { campaign, map, user, isAddByMap } = this.props;
    if (this.vendorPermissions) {
      return (
        <VendorAddUnitsFromMarkers
          markers={this.markers()}
          campaign={campaign}
          onHide={this.hideModal}
        />
      )
    }
    if (this.browserPermissions) {
      return (
        <AddUnitsFromMarkers
          user={user}
          markers={this.markers()}
          onHide={this.hideModal}
        />
      )
    }
  }
}

