import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { loadOnlySubtypes } from "../../actions/media_type_actions";
import { filterUnits } from "../../actions/UnitsActions";

const POPUP_WIDTH = 220;

class MediaSubTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = {
      isExpanded: false,
      selectedMediaSubTypes: this.selectedMediaSubTypesFromInitialValue(),
    };
  }

  componentDidMount() {
    const { loadOnlySubtypes, campaign } = this.props;
    const campaignId = campaign && campaign.campaignId;
    document.addEventListener("click", this.onToggle);
    loadOnlySubtypes(campaignId);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, media_subtypes, initialValue, units, loadOnlySubtypes, campaign } = this.props
    const campaignId = campaign && campaign.campaignId

    if (!isActive && prevProps.isActive) { this.onClear() }
    if (units.all_units.length !== prevProps.units.all_units.length) { loadOnlySubtypes(campaignId) }
    if (initialValue && media_subtypes !== prevProps.media_subtypes) {
      this.setState({ selectedMediaSubTypes: media_subtypes.filter(mt => initialValue.includes(mt.name)) });
    }
    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ selectedMediaSubTypes: media_subtypes.filter(mt => initialValue.includes(mt.name)) });
    }
  }

  selectedMediaSubTypesFromInitialValue() {
    // Because we only store a simple string for each selected media type on Redux,
    // here we convert from simple values to complex values:.
    // before: ['bus']
    // after:  [{name: 'Bus', value: 'bus', count: 5, id: 12}]
    const { media_subtypes, initialValue } = this.props;
    if (!media_subtypes.length || !initialValue) return [];
    return initialValue.map(v => {
      return media_subtypes.find(mt => mt.name == v);
    });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear() {
    this.setState({ selectedMediaSubTypes: [] }, this.onFilter);
  }

  onMediaTypeSelect(option) {
    const { selectedMediaSubTypes } = this.state;
    if (selectedMediaSubTypes.includes(option)) {
      this.setState(
        { selectedMediaSubTypes: selectedMediaSubTypes.filter(mediaType => mediaType.name !== option.name) },
        this.onFilter,
      );
    } else {
      this.setState({ selectedMediaSubTypes: selectedMediaSubTypes.concat([option]) }, this.onFilter);
    }
  }

  onFilter() {
    const { selectedMediaSubTypes } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    _.defer(filterUnits, { subtypes: selectedMediaSubTypes.map(type => type.name) });
    handleQueryParams({ 'subtypes[]': selectedMediaSubTypes.map(type => type.name) })
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedMediaSubTypes } = this.state;
    if (!selectedMediaSubTypes.length) return "Media subtype";
    if (selectedMediaSubTypes.length == 1) return `${selectedMediaSubTypes[0].name} only`;
    if (selectedMediaSubTypes.length > 1) return `Media SubTypes · ${selectedMediaSubTypes.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { selectedMediaSubTypes } = this.state;
    const { media_subtypes, campaign, isBrowseView } = this.props;
    const [present, notPresent] = media_subtypes.reduce(
      (result, mt) => {
        result[mt.count > 0 ? 0 : 1].push(mt);
        return result;
      },
      [[], []],
    );
    const showNotPresent = _.get(campaign, "permissions.can_add_units_near_pois", false) && notPresent.length;
    return (
      <div className="filter_popup media_type_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>MEDIA SUBTYPE</h4>
        <ul>
          {present.map(option => {
            const onClick = () => {
              this.onMediaTypeSelect(option);
            };
            const isActive = selectedMediaSubTypes.includes(option);
            return (
              <li key={option.name} onClick={onClick} className={cs({ active: isActive })}>
                {option.name}
                <i className="count">({option.count})</i>
                <i className="fa fa-check" />
              </li>
            );
          })}
          {showNotPresent && !isBrowseView && <li key='separator' className="separator">Zero count</li>}
          {(showNotPresent || isBrowseView) &&
            notPresent.map((option, index) => {
              const onClick = () => {
                this.onMediaTypeSelect(option);
              };
              const isActive = selectedMediaSubTypes.includes(option);
              return (
                <li key={`${option.name}-${index}`} onClick={onClick} className={cs({ secondary: !isBrowseView, active: isActive })}>
                  {option.name}
                  <i className="fa fa-check" />
                </li>
              );
            })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ campaign, media_subtypes, units }) => ({
  campaign,
  media_subtypes,
  units,
});

export default connect(mapStateToProps, { filterUnits, loadOnlySubtypes })(MediaSubTypeFilter);
