import { browserHistory } from 'react-router-legacy';
import { connect } from 'react-redux';
import React from 'react';

import {
  clearMap,
  setBounds,
  triggerMapResize,
  updateMapPosition,
  updateCurrentCustomPOI
} from '../NewMap/actions';
import {
  filterUnits,
  loadAllUnits,
  sortUnits
} from '../../actions/UnitsActions';
import { loadMarkets } from '../../actions/markets_actions';
import { logModalView } from '../../utils/gaHelper';
import { updateMarket } from '../../actions/current_market_actions';
import AuthStore from '../../stores/AuthStore';
import DemoRequest from '../DemoRequest';
import GlobalActions from '../../actions/GlobalActions';
import GlobalStore from '../../stores/GlobalStore';
import NewMap from './Map';
import Sidebar from './Sidebar';
import param from '../../utils/objectToQuery';
import FiltersBar from '../Filters/FiltersBar';
import Measure from "react-measure";

const AppConfig = window.AppConfig;
const DEBUG = window.DEBUG;
const $ = window.$;
const console = window.console;

class BrowseTab extends React.Component {
  
  constructor(props) {
    super(props);
    const auth = AuthStore.getState();
    const location = this.props.location || {};
    const filters = location.query || {};
    const params = this.props.params || {};
    const query = location.query || {};

    this.state = {
      auth: auth,
      unit_id: params.unit_id,
      query: query.q,
      bounds: query.bounds,
      filters,
      source: null,
      loading: true,
      sidebarData: {},
      sidebarOffset: 0,
      searchIsFaceId: false,
      sidebar_hidden: false,
      zoom: filters.zoom || AppConfig.defaultZoom,
      center: filters.center && this.props.location.query.center.split(',')
    };
  }

  showMarkets() {
    return !this.state.auth.user.is_supplier;
  }

  updateMarket(geocode) {
    if (this.showMarkets()) this.props.updateMarket(geocode);
  }

  componentDidMount() {
    const params = this.props.params || {};
    const unitId = params.unit_id;
    const { user } = this.state.auth || {};
    const canViewBrowse = user.permissions && user.permissions.can_view_browse
    
    if (!canViewBrowse) { return window.location.assign('/'); }
    
    if (unitId) {
      GlobalActions.openUnitID(unitId, { onPageLoad: true, isSearchView: true });
    }
    
    if (this.showMarkets()) this.props.loadMarkets();

    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange.bind(this)),
      GlobalStore.listen(this.onGlobalChange.bind(this)),
    ];

    const bounds = this.getBounds();
    this.props.setBounds(bounds);

    if (user && user.default_bounds) {
      this.props.loadAllUnits(bounds, '');
    }

    this.props.filterUnits(this.state.filters);
    document.title = 'Browse | AdQuick';
  }

  UNSAFE_componentWillMount(){
    this.props.setBounds(this.getBounds());
  }

  getBounds() {
    const { auth } = this.state || {};
    const { user } = auth;

    if (this.state.bounds) {
      return this.state.bounds;
    } else if (user && user.default_bounds) {
      return user.default_bounds;
    } else {
      return AppConfig.defaultBounds;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.map.full_screen != this.props.map.full_screen) {
      this.setState({ sidebar_hidden: this.props.map.full_screen }, this.props.triggerMapResize);
    }
  }

  componentWillUnmount() {
    this.props.clearMap();
    this.unsubscribeList.map(fn => fn());
    document.title = AppConfig.defaultTitle
  }

  onGlobalChange(value) {
    if (value == 'popup:close') {
      this.setState({ unit_id: null });
    }
  }

  onAuthChange(name, value) {
    this.setState({ auth: AuthStore.getState() });
  }

  onMapMove(position) {
    const bounds = position.bounds;
    const boundsString = [bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast()].join(',');
    this.props.setBounds(boundsString);
  }

  async onFaceIdSearch(face_id) {
    this.setState({
      loading: true,
      query: face_id,
      bounds: '',
      searchIsFaceId: true,
      searchIsAddress: false,
      searchPlacemarker: false,
    });

    const result = await $.get('/api/v1/search/by_face_id', {...this.state.filters, face_id});
    if (result.units && result.units.length > 0){
      const unit = result.units[0];
      this.props.updateMapPosition({ center: [unit.lon, unit.lat], zoom: 17 });
      this.updateMarket([unit.lon, unit.lat]);
      this.props.loadAllUnits([unit.lon, unit.lat]);
    } else {
      this.setState({ loading: false });
      alert(`Could not find a unit with face id: ${face_id}`);
    }
  }

  onLocationSearch(params) {
    if (DEBUG) console.log('[search] new search:', params);

    this.setState({
      loading: true,
      query: params.query,
      bounds: params.boundsString,
      searchIsFaceId: params.isFaceId,
      searchIsAddress: params.isAddress,
      searchPlacemarker: params.place,
    }, this.conductSearch.bind(this));

    if (params.boundsString) {
      this.props.updateMapPosition({ bounds: params.boundsString });
      this.updateMarket();
      this.props.loadAllUnits(params.boundsString);
    }
  }

  onMarkerClick(params) {
    if (this.isPublicView()) {
      GlobalActions.openPopup('campaign_request', {showPublicViewUnitCopy: true});
    } else {
      this.openUnitModal(params.id);
    }
  }

  onUnitListingClick(unit_id) {
    if (this.isPublicView()) {
      GlobalActions.openPopup('campaign_request', {showPublicViewUnitCopy: true});
    } else {
      this.openUnitModal(unit_id);
    }
  }

  isPublicView() {
    return this.state.sidebarData.view_type === 'public_view';
  }

  isAgencyView() {
    return this.state.sidebarData.view_type === 'agency_view';
  }

  openUnitModal(unit_id) {
    const currentLocation = browserHistory.getCurrentLocation();
    const currentUrl = currentLocation.pathname + currentLocation.search;

    GlobalActions.openUnitID(unit_id, {
      onClose: () => browserHistory.push(currentUrl),
      isSearchView: true
    });

    browserHistory.push(`/units/${unit_id}`);
    logModalView(`/units/${unit_id}`);
  }

  onFilter(params) {
    if (DEBUG) console.log('[search] will filter', params);
    this.setState({ loading: true, filters: params }, () => {
      browserHistory.push(`/search?${this.paramString()}`);
    });
    this.props.filterUnits(params);
  }

  conductSearch() {
    browserHistory.push(`/search?${this.paramString()}`);
    GlobalActions.logSearch({ data: { filters: this.state.filters, query: this.state.query } });
  }

  onSort(value) {
    this.props.sortUnits({ sort_by: value });
  }

  paramString() {
    const { query, bounds, searchIsFaceId } = this.state;
    const q = query;
    return param(Object.assign(this.state.filters, { bounds, q, mode: 'browse', is_face_id: searchIsFaceId }));
  }

  onLoadMoreSidebar() {
    const { sidebarData } = this.state;

    if (sidebarData && sidebarData.more_results_count > 0) {
      this.setState({ isLoadingMore: true }, () => {
        this.loadSidebarData(this.state.sidebarSource, { loadMore: true });
      });
    }
  }

  loadSidebarData(sidebarSource, params) {
    let { sidebarOffset } = this.state;
    if (params.loadMore && sidebarSource != null) {
      const offsetMatch = sidebarSource.match(/offset=([0-9]+)/)
      if (offsetMatch) {
        const newOffset = parseInt(offsetMatch[1]) + 20
        sidebarSource = sidebarSource.replace(offsetMatch[0], `offset=${newOffset}`)
        sidebarOffset = newOffset
      } else {
        sidebarSource = `${sidebarSource}&offset=20`
        sidebarOffset = 20
      }
    }

    if (sidebarSource == this.state.sidebarSource) {
      console.debug(`[search] sidebar source stayed the same: ${sidebarSource}`);
      return
    } else {
      console.debug(`[search] new sidebar source: ${sidebarSource}`);
    }

    const oldData = this.state.sidebarData
    this.sidebarRequest = $.get(`/api/v1/${sidebarSource}`, (sidebarData) => {
      let newData = sidebarData
      if (params && params.loadMore) {
        newData.units = (this.state.sidebarData.units || []).concat(sidebarData.units)
        newData.units = _.uniq(newData.units, (unit) => unit.id)
      }

      newData.units_count = newData.total_results_count

      this.setState({ sidebarData: newData, sidebarSource }, () => {
        if (sidebarData.units.length == 0 && !params.loadMore) {
          this.setState({ loading: false });
        }
      });
    }).fail((error) => {
      console.debug(`[search] failed to load ${sidebarSource}`, error);
      this.setState({ loading: false });
    }).always(() => {
      this.setState({ loading: false });
    });
  }

  isMobile() {
    return window.mobileCheck()
  }

  setMapbox(mapbox) {
    this.setState({mapbox: mapbox});
  }

  render() {
    const { auth, sidebar_hidden, sidebarData,
      isLoadingMore, filters, mapSource, isAddress,
      searchPlacemarker, zoom, center, filter_height } = this.state;
    const { user } = auth;
    const canViewBrowse = (auth && user && user.permissions) ? user.permissions.can_view_browse : false
    const { units, map, filterUnits } = this.props;
    const location = this.props.location || {};
    const query = location.query || {};

    if (!canViewBrowse) {
      return (
        <DemoRequest />
      )
    }

    const sidebarStyle = {}
    const mapStyle = {}
    const mainStyle = {}

    if (this.isMobile() || sidebar_hidden) {
      mapStyle.left = 0
      mapStyle.overflow = 'scroll'
      sidebarStyle.display = 'none'
    }

    return (<div className="main new-search" style={mainStyle}>
      <Measure onMeasure={({ height, width }) => this.setState({ filter_height: height })}>
        <FiltersBar
          onSort={(value) => this.onSort(value)}
          onFilter={this.onFilter.bind(this)}
          activeFilters={this.props.units.unit_filter}
          isBrowseView={true}
        />
      </Measure>
      <div className="main__side" style={{...sidebarStyle, top: filter_height}}>
        <Sidebar
          query={query.q}
          user={auth && auth.user}
          data={sidebarData}
          units={units}
          loading={!!!units.all_units}
          isLoadingMore={isLoadingMore}
          filters={filters}
          onLocationSearch={this.onLocationSearch.bind(this)}
          onFaceIdSearch={this.onFaceIdSearch.bind(this)}
          onUnitListingClick={this.onUnitListingClick.bind(this)}
          onSort={this.onSort.bind(this)}
          onFilter={this.onFilter.bind(this)}
          onLoadMore={this.onLoadMoreSidebar.bind(this)}
          isSearchView={true}
          isPublicView={this.isPublicView()}
          isAgencyView={this.isAgencyView()}
          mapbox={this.state.mapbox}
        />
      </div>
      <div className="main__map" style={{...mapStyle, top: filter_height}}>
        <NewMap
          user={auth && auth.user}
          units={units}
          markets={this.props.markets}
          filterUnits={filterUnits}
          source={mapSource}
          searchIsAddress={isAddress}
          searchPlacemarker={searchPlacemarker}
          onMove={this.onMapMove.bind(this)}
          onTimelineTab={() => { browserHistory.push(`/timeline${document.location.search}`); }}
          onMarkerClick={this.onMarkerClick.bind(this)}
          showCPM={!this.isAgencyView()}
          isSearchView={true}
          draw_tool={true}
          zoom={zoom}
          center={center}
          onMapboxCreated={this.setMapbox.bind(this)}
        />
      </div>
    </div>);
  }
}

const mapStateToProps = ({ units, markets, map, campaignRequest, currentUser, sessionId }, ownProps) => ({
  units,
  map,
  markets,
  campaignRequest,
  currentUser,
  sessionId,
});

export default connect(
  mapStateToProps,
  { updateCurrentCustomPOI, loadAllUnits, updateMarket, loadMarkets, filterUnits, triggerMapResize, updateMapPosition, setBounds, clearMap, sortUnits },
)(BrowseTab);
