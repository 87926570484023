import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  deletePop,
  updateShowPopOnOverview  
} from "../../../actions/campaign_design_actions";

const ASSETS = (window as any).ASSETS;
interface Props {
  unit: any;
  campaign: any;
  campaign_permissions: any;
  currentUser: any;
  deletePop: (campaignId, pop_id) => void;
  updateShowPopOnOverview: (campaignId, pop_id, show_pop_on_overview) => void;

}
class PopCartItem extends PureComponent<Props, any> {

  public render() {
    const { unit } = this.props;
    return unit.pop.map(p => {
      return (
        <div key={`pop-${p.id}`} className="design_card_item">
          <span style={{ marginRight: 5 }}>POP:</span>
          {this.renderPopLink(p)}
          <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>
          {this.renderDownloadLink(p)}
          <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>
          {this.renderDeleteLink(p)}
          <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>
          {this.renderPopShowOverview(p)}
          {p.source == 'api' &&
            <span className='badge badge-light' style={{marginLeft: 'auto'}}>api</span>
          }
        </div>
      );
    })
  }

  private renderPopLink({ asset_url, asset_name }) {
    return (
      <a target="_blank" href={asset_url}>
        <i className="fa fa-file-o margin-right" aria-hidden="true" />{asset_name}
      </a>
    )
  }

  private renderDownloadLink({ asset_url }) {
    return (
      <a style={{ fontWeight: 600 }} target="_blank" href={asset_url}>
        Download
      </a>
    )
  }

  private renderDeleteLink({ id }) {
    const { currentUser } = this.props;
    if (currentUser.is_admin) {
      return (
        <a style={{ marginLeft: "10px", color: "#db5f44" }} onClick={() => this.deletePop(id)}>
          Delete
        </a>
      );
    }
  }
  private deletePop(pop_id) {
    const confirm_delete = confirm("Are you sure?");
    if (confirm_delete){
      const { campaign } = this.props;
      this.props.deletePop(campaign.campaignId, pop_id);
    }
  }


  private renderPopShowOverview(pop) {
    const { currentUser, campaign } = this.props;
    const user_permissions = currentUser.permissions;
    const style = { display: 'flex',
                    alignItems: 'center',
                    width: '170px',
                    justifyContent: 'space-between'
    }
    if (currentUser.is_admin || user_permissions.can_toggle_show_pop_on_overview) {
      return (
        <span className='badge badge-light' style={style}>
          Show POP on Overview 
          <input
            style={{marginTop: '0px', height: '12px'}}
            type="checkbox"
            checked={pop.show_pop_on_overview}
            onChange={() => this.props.updateShowPopOnOverview(campaign.campaignId, pop.id, !pop.show_pop_on_overview)}
          />
        </span>
      );
    }
  }
}

export default connect(
  // @ts-ignore
  ({ currentUser }) => ({ currentUser }),
  { deletePop, updateShowPopOnOverview },
)(PopCartItem);
