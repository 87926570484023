import * as React from 'react';
import RevenueCharts from './RevenueCharts';
import OccupancyCharts from './OccupancyCharts';

export default class Charts extends React.Component<any, any> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="vendor_dash_charts">
        <div className="row">
          <div className="col-md-6">
            <RevenueCharts />
          </div>
          <div className="col-md-6">
            <OccupancyCharts />
          </div>
        </div>
      </div>
    )
  }
}
