import { get } from "../../utils/api";
const DASHBOARD_URL = "/api/v1/vendor_dashboard";

export const fetchOpenRfpCount = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_RFP_COUNT" });
  const resp = await $.get(`${DASHBOARD_URL}/open_rfps_count${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_OPEN_RFP_COUNT_SUCCESS",
    payload: {
      open_rfp_count: resp.open_rfp_count
    }
  });
};

export const fetchUnitRequestsCount = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_UNIT_REQUEST_COUNT" });
  const resp = await $.get(`${DASHBOARD_URL}/unit_requests_count${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_UNIT_REQUESTS_SUCCESS",
    payload: {
      unit_requests_count: resp.unit_requests_count
    }
  });
};

export const fetchHoldRequestsCount = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_HOLD_REQUEST_COUNT" });
  const resp = await $.get(`${DASHBOARD_URL}/hold_requests_count${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_HOLD_REQUESTS_SUCCESS",
    payload: {
      hold_requests_count: resp.hold_requests_count
    }
  });
};

export const fetchExpiringHolds = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_EXPIRING_HOLDS" });
  const resp = await $.get(`${DASHBOARD_URL}/expiring_holds${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_EXPIRING_HOLDS_SUCCESS",
    payload: {
      expiring_holds: resp.expiring_holds
    }
  });
};

export const fetchExpiringHoldsCount = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_EXPIRING_HOLDS_COUNT" });
  const resp = await $.get(`${DASHBOARD_URL}/expiring_holds_count${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_EXPIRING_HOLDS_COUNT_SUCCESS",
    payload: {
      expiring_holds_count: resp.expiring_holds_count
    }
  });
};

export const fetchPopsOutstanding = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_POPS_OUTSTANDING" });
  const resp = await $.get(`${DASHBOARD_URL}/pops_outstanding${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_POPS_OUTSTANDING_SUCCESS",
    payload: {
      pops_outstanding: resp.pops_outstanding
    }
  });
};

export const fetchPopsOutstandingCount = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_POPS_OUTSTANDING_COUNT" });
  const resp = await $.get(`${DASHBOARD_URL}/pops_outstanding_count${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_POPS_OUTSTANDING_COUNT_SUCCESS",
    payload: {
      pops_outstanding_count: resp.pops_outstanding_count
    }
  });
};

export const fetchStaleAvailability = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_STALE_AVAILABILITY" });
  const resp = await $.get(`${DASHBOARD_URL}/stale_availability${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_STALE_AVAILABILITY_SUCCESS",
    payload: {
      stale_availability: resp.stale_availability,
      last_flight_created_at: resp.last_flight_created_at
    }
  });
};

export const fetchMissingImageCount = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_MISSING_IMAGES" });
  const resp = await $.get(`${DASHBOARD_URL}/missing_images${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_MISSING_IMAGES_SUCCESS",
    payload: {
      missing_image_count: resp.missing_image_count
    }
  });
};

export const fetchProfileStrength = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_PROFILE_STRENGTH" });
  const resp = await $.get(`${DASHBOARD_URL}/profile_strength${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_PROFILE_STRENGTH_SUCCESS",
    payload: {
      profile_strength: resp.profile_strength
    }
  });
};

export const fetchUpcomingFlights = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_UPCOMING_FLIGHTS" });
  const resp = await $.get(`${DASHBOARD_URL}/upcoming_flights${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_UPCOMING_FLIGHTS_SUCCESS",
    payload: {
      upcoming_flights: resp.upcoming_flights,
      count: resp.count
    }
  });
};

export const fetchMonthRevenueData = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_MONTH_REVENUE_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/month_revenue${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_MONTH_REVENUE_DATA_SUCCESS",
    payload: {
      month_revenue: resp.month_revenue
    }
  });
};

export const fetchQuarterRevenueData = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_QUARTER_REVENUE_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/quarter_revenue${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_QUARTER_REVENUE_DATA_SUCCESS",
    payload: {
      quarter_revenue: resp.quarter_revenue
    }
  });
};

export const fetchYearRevenueData = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_YEAR_REVENUE_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/year_revenue${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_YEAR_REVENUE_DATA_SUCCESS",
    payload: {
      year_revenue: resp.year_revenue
    }
  });
};

export const fetchAllRevenueData = (supplier_id = null, offset = 0) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_ALL_REVENUE_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/all_revenue?offset=${offset}${supplier_id ? `&supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_ALL_REVENUE_DATA_SUCCESS",
    payload: {
      all_revenue: resp.all_revenue
    }
  });
};

export const fetchMonthOccupancyData = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_MONTH_OCCUPANCY_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/month_occupancy${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_MONTH_OCCUPANCY_DATA_SUCCESS",
    payload: {
      month_occupancy: resp.month_occupancy
    }
  });
};

export const fetchQuarterOccupancyData = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_QUARTER_OCCUPANCY_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/quarter_occupancy${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_QUARTER_OCCUPANCY_DATA_SUCCESS",
    payload: {
      quarter_occupancy: resp.quarter_occupancy
    }
  });
};

export const fetchYearOccupancyData = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_YEAR_OCCUPANCY_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/year_occupancy${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_YEAR_OCCUPANCY_DATA_SUCCESS",
    payload: {
      year_occupancy: resp.year_occupancy
    }
  });
};

export const fetchAllOccupancyData = (supplier_id = null, offset = 0) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_ALL_OCCUPANCY_DATA" });
  const resp = await $.get(`${DASHBOARD_URL}/all_occupancy?offset=${offset}${supplier_id ? `&supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_ALL_OCCUPANCY_DATA_SUCCESS",
    payload: {
      all_occupancy: resp.all_time_occupancy
    }
  });
};

export const fetchBookingYearOffsets = (supplier_id = null) => async (dispatch, getState) => {
  dispatch({ type: "BEGIN_FETCH_BOOKING_YEAR_OFFSETS" });
  const resp = await $.get(`${DASHBOARD_URL}/booking_year_offsets${supplier_id ? `?supplier_id=${supplier_id}` : ''}`);
  dispatch({
    type: "FETCH_BOOKING_YEAR_OFFSETS_SUCCESS",
    payload: {
      booking_year_offsets: resp.booking_year_offsets
    }
  });
};

export const setSupplier = (selected_supplier) => async (dispatch, getState) => {
  dispatch({
    type: "SET_SUPPLIER_SUCCESS",
    payload: { selected_supplier }
  });
};

export const showExpiringHoldsModal = () => async (dispatch, getState) => {
  dispatch({
    type: "SHOW_EXPIRING_HOLDS_MODAL"
  });
};

export const hideExpiringHoldsModal = () => async (dispatch, getState) => {
  dispatch({
    type: "HIDE_EXPIRING_HOLDS_MODAL"
  });
};

export const showPopsOutstandingModal = () => async (dispatch, getState) => {
  dispatch({
    type: "SHOW_POPS_OUTSTANDING_MODAL"
  });
};

export const hidePopsOutstandingModal = () => async (dispatch, getState) => {
  dispatch({
    type: "HIDE_POPS_OUTSTANDING_MODAL"
  });
};

export const resetDashboard = () => async (dispatch, getState) => {
  dispatch({
    type: "RESET_DASHBOARD"
  });
};
