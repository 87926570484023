import Reflux from 'reflux';
import moment from 'moment';
import momentRange from 'moment-range';
import { get, post } from '../utils/api';
import param from '../utils/objectToQuery';

export default Reflux.createStore({
  init() {
    this.state = {
      bounds: null,
      center: null,
      zoom: null,
      filters: {}
    }
  },

  setState(newState) {
    this.state = Object.assign({}, this.state, newState)
  },

  setBounds(bounds) {
    this.setState({bounds})
    this.trigger('search:bounds')
  },

  setView(center, zoom) {
    this.setState({center, zoom})
    this.trigger('search:view')
  },

  setFilters(params) {
    Object.keys(params).forEach(k => { if(params[k] === 'false') params[k] = false });
    this.state.filters = params
    this.trigger('search:filters')
  },

  getState() {
    return this.state;
  },

  getFilters() {
    return this.state.filters || {}
  },

  loadData(url, params) {

  }
});
