const initialState = {
  company_groups: [],
  companies: [],
  scrape: {},
  loading: false
}

const toggleLoading = (state) => {
  return !state.loading
}
  
export default (state = initialState, { type, company_groups = [], companies = [], progress, groupId}) => ({
  FETCH_COMPANY_GROUPS_SUCCESS: {
    ...state,
    company_groups: [ ...company_groups ]
  },
  FETCH_COMPANIES_SUCCESS: {
    ...state,
    companies: [ ...companies ]
  },
  SCRAPING_GROUP_SUCCESS: {
    ...state,
    scrape: { [groupId]: { ...progress } } 
  },
  POOLING_JOB: {
    ...state,
    scrape: { [groupId]: { ...progress } }
  },
  TOGGLE_LOADING: {
    ...state,
    loading: toggleLoading(state)
  }
}[type] || state)