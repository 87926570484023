import queryString from 'query-string';

export const get = (
  url: string,
  params: {} = {},
  token: string | null = null) => {
  const headers = defaultHeaders(token);

  if (params) {
    url = `${url}?${queryString.stringify(params)}`;
  }

  const fetch_options = {
    headers,
    credentials: 'same-origin'
  };
  return doRequest(url, fetch_options);
};

export const post = (
  url: string,
  data: {} | null = null,
  token: string | null = null) => {
  const headers = defaultHeaders(token);
  headers['Content-Type'] = 'application/json';

  const fetch_options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
    credentials: 'same-origin',
  };

  return doRequest(url, fetch_options)
};

export const put = (
  url: string,
  data: {} | null = null,
  token: string | null = null) => {
  const headers = defaultHeaders(token);
  headers['Content-Type'] = 'application/json';

  const fetch_options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data),
    credentials: 'same-origin',
  };

  return doRequest(url, fetch_options)
};

export const del = (
  url: string,
  data: {} | null = null,
  token: string | null = null) => {
  const headers = defaultHeaders(token);

  const fetch_options = {
    method: 'DELETE',
    headers: headers,
    credentials: 'same-origin',
  };

  return doRequest(url, fetch_options)
};

const doRequest = (url, fetch_options) => {
  return fetch(url, fetch_options).then(handleResponse)
    .catch(error => {
      if (error.name == 'ApiError') {
        return Promise.reject(error.response);
      } else {
        return Promise.reject(error.message);
      }
    });
}

const handleResponse = async (response) => {
  if (response.status === 204) return {};
  if (!response.ok) {
    try {
      const data = await response.json();
      throw new ApiError({ status: response.status, data });
    } catch (error) {
      throw error;
    }
  }

  return response.json();
};

const defaultHeaders = (token) => {
  const headers = {
    'Accept': 'application/json',
  };
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return headers;
}

interface ErrorResponse {
  status: number;
  data: Object;
}

class ApiError extends Error {
  response: ErrorResponse;
  constructor(response: ErrorResponse) {
    super();
    this.response = response;
    this.name = 'ApiError';
  }
}
