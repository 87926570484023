export type Market = {
  id: number
  center_lat: number
  center_lon: number
  highest_price: number
  highest_rate_card_price: number
  lowest_price: number
  lowest_rate_card_price: number
  median_cpm: number
  median_rate_card_cpm: number
  median_price: number
  median_rate_card_price: number
  name: string
  units_count: number
}

type Point = {
  type: 'Point',
  coordinates: number[]
}

export type MarketGeojson = {
  geometry: Point,
  properties: Market
}

export type MarketsGeojson = {
  type: 'FeatureCollection',
  features: MarketGeojson[]
}

export function parseMarket(market: any): Market | null {
  try {
    if (!market) throw "invalid market data";
    if (typeof market.name !== 'string') throw "invalid market name";

    return {
      id: parseOrThrow(parseInt, market.id),
      center_lat: parseOrThrow(parseFloat, market.center_lat),
      center_lon: parseOrThrow(parseFloat, market.center_lon),
      highest_price: parseOrThrow(parseFloat, market.highest_price),
      highest_rate_card_price: parseOrThrow(parseFloat, market.highest_rate_card_price),
      lowest_price: parseOrThrow(parseFloat, market.lowest_price),
      lowest_rate_card_price: parseOrThrow(parseFloat, market.lowest_rate_card_price),
      median_cpm: parseOrThrow(parseFloat, market.median_cpm),
      median_rate_card_cpm: parseOrThrow(parseFloat, market.median_rate_card_cpm),
      median_price: parseOrThrow(parseFloat, market.median_price),
      median_rate_card_price: parseOrThrow(parseFloat, market.median_rate_card_price),
      name: market.name,
      units_count: parseOrThrow(parseInt, market.units_count)
    };
  } catch (e) {
    console.error("error parsing market", market, e);
    return null;
  }
}

function parseOrThrow(parseFunc: (n: any) => number, toParse: any): number {
  const value = parseFunc(toParse);
  if (isNaN(value)) throw `invalid value to parse ${toParse}`;
  return value;
}
