import Reflux from 'reflux';

export default Reflux.createActions({
  "openUnit": {},
  "closeUnit": {},
  "openUnitID": {},
  "openPopup": {},
  "closePopup": {},
  "showMessage": {},
  "showError": {},
  "hideMessage": {},
  "sendMessage": {},
  "sentNoResultsRequest": {},
  "log": {},
  "logSearch": {},
  "sendFeedback": {},
  "userInitiatedSearch": {},
  "showSplash": {},
  "setContactPhoneNumber": {},
});
