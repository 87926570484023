import React, { Component } from 'react';

const MAX_LENGTH = 15;

class Orientation extends Component {

  prettyLabel(){
    let { orientation } = this.props;
    orientation = _.truncate(orientation, { 'length': MAX_LENGTH })
    return orientation;
  }

  render(){
    return (
      <li>
        Orientation {this.prettyLabel()}
      </li>
    )
  }

}

export default Orientation
