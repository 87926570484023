import PropTypes from "prop-types";
import React from "react";
import accounting from "accounting";

const header = (metric) => <div className={ `header ${metric}` }>{ metric }</div>;

function campaign(metric, before, during) {
  return (
    <div className="test content">
      { content("Experiment (OOH)", before.campaign[metric], during.campaign[metric], metric) }
    </div>
  );
}

function control(metric, before, during) {
  return (
    <div className="content">
      { content("Control", before.control[metric], during.control[metric], metric) }
    </div>
  );
}

function content (text, before, during, metric) {
  let beforeValue = before;
  let duringValue = during;
  if (metric === "ctr") {
    beforeValue = accounting.formatMoney(before, "", 2);
    duringValue = accounting.formatMoney(during, "", 2);
  }
  if (metric === "cpc") {
    beforeValue = accounting.formatMoney(before, "$", 2);
    duringValue = accounting.formatMoney(during, "$", 2);
  }
  return (
    <div>
      <div className="values">
        <p>{ text }</p>
        <p>Before: { beforeValue }</p>
        <p>During: { duringValue }</p>
      </div>
      { growth(before, during) }
    </div>
  );
}

function growth(before, during) {
  let value;
  before = parseFloat(before);
  during = parseFloat(during);
  if (before === 0) value = during > 0 ? "+" : "0%";
  else if (during === 0) value = "0%";
  else {
    value = (during / before - 1) * 100;
    value = accounting.formatMoney(value, "", 1);
    value = value > 0 ? `+${value}%` : `${value}%`;
  }

  return <div className={ "growth" }>{ value }</div>;
}

function lift(metric, before, during) {
  const value = liftValue(
    during.campaign[metric], before.campaign[metric], during.control[metric], before.control[metric]
  );
  return (
    <div className="content">
      <div>
        <div className="values">
          <p>Relative Lift</p>
        </div>
        <div className={`growth ${color(value, metric)}`}>{ value }</div>
      </div>
    </div>
  );
}
function liftValue(campaign, campaignBefore, control, controlBefore) {
  campaign = parseFloat(campaign);
  campaignBefore = parseFloat(campaignBefore);
  control = parseFloat(control);
  controlBefore = parseFloat(controlBefore);

  if (campaignBefore === 0 || controlBefore === 0) return "no data";

  const campaignGrowth = campaign / campaignBefore - 1;
  const controlGrowth = control / controlBefore - 1;

  if (campaignGrowth > 0 && controlGrowth <= 0) {
    return `> ${accounting.formatMoney(campaignGrowth * 100, "", 1)}%`;
  }
  if (controlGrowth > 0 && campaignGrowth <= 0) {
    return `< ${accounting.formatMoney(campaignGrowth * 100, "", 1)}%`;
  }
  let lift = (campaignGrowth / controlGrowth - 1) * 100;
  if(campaignGrowth < 0 && controlGrowth < 0) lift = -1 * lift;

  const signal = lift >= 0 ? "+" : "";
  return `${signal}${accounting.formatMoney(lift, "", 1)}%`;
}
function color(value, metric) {
  let isRed = value.indexOf("-") !== -1;
  if(metric === "cpc") isRed = !isRed;

  return isRed ? "red" : "green";
}

const Metric = ({metric, selected, before, during, onClick}) => {
  if (!before.campaign) return <div />;
  return (
    <div className={ `metric ${selected ? "selected" : ""}` } onClick={ onClick }>
      { header(metric) }
      { campaign(metric, before, during) }
      { control(metric, before, during) }
      { lift(metric, before, during) }
    </div>
  );
};

Metric.propTypes = {
  metric: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  before: PropTypes.object.isRequired,
  during: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default Metric;
