import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import createClass from 'create-react-class'
import PropTypes from 'prop-types';
import { Row, Grid, Col, Thumbnail } from 'react-bootstrap';
import UploadButton from '../../UploadButton'
import { uploadUnitImages, deleteUnitImage } from '../actions'
import { post } from "../../../utils/api";
import GlobalActions from '../../../actions/GlobalActions';

import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../../../../../../../app/assets/stylesheets/pro/campaigns/edit/images.css';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
class Images extends React.Component {
  // displayName: 'Inventory/Unit/Images'
  constructor(props) {
    super(props);
    this.props.onUpload();
    this.state = {
      error: null, 
      orderedImagesList: this.props.unit.images
    }
    this.onDragEnd = this.onDragEnd.bind(this);
    this.sendEmailToSupplier = this.sendEmailToSupplier.bind(this);
  }

  componentDidMount() {
    this.hasReloadedData = false;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.unit.images?.length !== this.state.orderedImagesList.length) {
      this.setState({ orderedImagesList: nextProps.unit.images });
    }
  }

  onDragEnd(result) {
    const { destination, source } = result;
    const unitImageId = result.draggableId;
    const { unit } = this.props;
    const newPosition = destination.index;

    // if dropped outside the list
    if (!destination) {
      return;
    }
    // if nothing moves or image moved back to original position, do nothing
    if  (
      unitImageId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    post(`/api/v1/units/${unit.id}/unit_images/${unitImageId}/reorder`, {new_position: newPosition})

    const orderedImagesList = reorder(
      this.state.orderedImagesList, 
      source.index,
      destination.index
    );

    this.setState({
      orderedImagesList,
    });

    this.props.afterReorder();
  }
  
  getInitialState() {
    return {
      error: null,
      upload_via_url: '',
      uploading_via_url: false
    }
  }

  onDelete(unitId, unitImageId) {
    if (confirm("Are you sure you want to delete this image?")) {
      this.props.deleteUnitImage(unitId, unitImageId)
      this.props.onDelete()
    }
  }

  uploadViaUrl() {
    this.upload(this.state.upload_via_url)
  }

  onUpload(unitId, upload, file) {
    this.upload(file.url)
  }

  upload(file_url) {
    const unitId = this.props.unit.id
    this.setState({ uploading_via_url: true }, async () => {
      try {
        await post(`/api/v1/units/${unitId}/unit_images`, { file_url });
        this.props.onUpload();
        this.setState({error: null, uploading_via_url: false, uploading_via_url: ''});
      } catch (error) {
        this.setState({ error, uploading_via_url: false });
      }
    })
  }

  async sendEmailToSupplier(unitId) {
    const supplierName = this.props.unit.supplier_name
    if (confirm(`This will send an email to the supplier: ${supplierName}. Proceed?`)) {
      try {
        await post('/agency_photo_requests', {unit_id: unitId});
        GlobalActions.showMessage('The supplier has been emailed.');
      } catch(e) {
        GlobalActions.showError('There was an error sending an email to the supplier.');
        throw(e);
      }
    }
  }

  render() {

    const { unit, uploadUnitImages, deleteUnitImage, user } = this.props
    // load existing images
    if (unit?.images?.length == 0 && this.hasReloadedData == false) this.props.onUpload(); 
    this.hasReloadedData = true;
    const images = this.state.orderedImagesList;

    return (
      <div className="unit-images" style={{ paddingTop:15 }}>
        {user && user.is_agency_admin &&
          <Row style={{ paddingBottom:15 }}>
            <Col sm={12}>
              <button className="btn btn-primary" onClick={() => this.sendEmailToSupplier(unit.id) }>Request New Photos</button>
            </Col>
          </Row>
        }
        {this.state.error && <Row>
          <Col sm={12} className="text-center">
            <div className="alert alert-danger">{this.state.error}</div>
          </Col>
        </Row>}
        <Row>
          <Col sm={5}>
            <UploadButton text="Drag images here or click to upload" isPublic={true} directory='uploads/unit_image/image' onComplete={(upload, file) => this.onUpload(unit.id, upload, file)} />
          </Col>
          {this.state.uploading_via_url && <Col sm={6}>Uploading...</Col>}
          {!this.state.uploading_via_url && <Col sm={5}>
            <input type="text"
              className="form-control"
              placeholder="Enter image url here to upload via link"
              value={this.state.upload_via_url}
              onChange={e => this.setState({ upload_via_url: e.target.value })} />
          </Col>}
          {!this.state.uploading_via_url && <Col sm={2}>
            <input type="submit" className="btn btn-default" value="Upload via url" onClick={() => this.uploadViaUrl()} />
          </Col>}
        </Row>
   
      <DragDropContext onDragEnd={this.onDragEnd}>    
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (  
            <div className="droppable_container" 
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
              > 
              {images.map((image, index) => ( 
                <Draggable key={image.id} draggableId={`${image.id}`} index={index}>
                  {(provided, snapshot) => {
                    if (snapshot.isDragging) {
                      provided.draggableProps.style.left = 140 * (index + 1);
                    }
                    return (       
                  <div className="draggable_item"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    >   
                        <span className="order">
                          {index+1}
                          {(index === 0 ? ' (Main)' : '')}
                        </span>   
                        <img className='img-thumbnail' src={image.url}/>
                         <i className="fas fa-trash-alt" onClick={this.onDelete.bind(this, unit.id, image.id)}></i>
                  </div>            
                    )
                  }}
                </Draggable>
                ))}       
               {provided.placeholder}             
            </div>     
          )}           
        </Droppable>   
      </DragDropContext>
    </div>
    )
  }
}

Images.propTypes = {
  unit: PropTypes.object.isRequired,
  uploadUnitImages: PropTypes.func.isRequired,
  deleteUnitImage: PropTypes.func.isRequired
};

export default connect(
  null,
  { uploadUnitImages, deleteUnitImage }
)(Images)
