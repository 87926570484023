import { get } from '../utils/api';
import { parseMarket } from '../models/market';

export const LOAD_MARKETS = "adquick/load-markets";
export const loadMarkets = () => async (dispatch) => {
  const markets = await get("/api/v1/market_overviews?cache=3");
  const ensureMarketIsArray = Array.isArray(markets) ? markets : new Array(markets);
  const parsedMarkets = ensureMarketIsArray.map(parseMarket).filter(m => m);
  dispatch({ type: LOAD_MARKETS, payload: { markets: parsedMarkets } });
}

export const LOAD_ALL_MARKETS = "adquick/load-all-markets";
export const loadAllMarkets = () => async (dispatch) => {
  const markets = await get("/api/v1/markets");
  dispatch({ type: LOAD_ALL_MARKETS, payload: markets });
}
