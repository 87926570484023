import React from 'react';
import { browserHistory } from 'react-router-legacy';
import createClass from 'create-react-class';
import { formatMoney } from 'accounting'
import GlobalActions from '../../../actions/GlobalActions';
import AuthStore from '../../../stores/AuthStore';
import Subheader from '../Subheader'
import Row from './Row'

export default createClass({
  displayName: 'Greylabel/Campaigns/index',

  getInitialState() {
    return {
      campaigns: []
    }
  },

  componentDidMount() {
    this.loadCampaigns();
  },

  loadCampaigns() {
    $.get('/api/v1/campaigns').then(response => {
      this.setState({ campaigns: response.results })
    })
  },

  renderHeader() {
    return (
      <Subheader />
    )
  },

  render() {
    return (
      <div>
        {this.renderHeader()}
        <div className="container create-campaign" style={{marginTop: 20}}>
          <h3 style={{marginBottom:20}}>Campaigns</h3>
          {this.state.campaigns.map(campaign =>
            <div>
              <Row campaign={campaign} user={AuthStore.state.user} />
            </div>
          )}
        </div>
      </div>
    )
  }
});

const errorStyle = {
  marginBottom: 20,
  borderColor: 'red',
}
