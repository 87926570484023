import React from 'react';

export default ({ should_map, mapped }) => {
  mapped = Object.values(mapped).filter(v => v);
  return (
    <div style={{ margin: '20px 0' }}>
      <h5> Required Mappings </h5>
      { should_map.map(name => mapping(name, mapped)) }
    </div>
  );
};

function mapping(name, mapped) {
  const isMapped = mapped.indexOf(name) > -1
  const className = isMapped ? 'btn-primary' : 'btn-danger';
  const icon = isMapped ? 'fa-check-circle' : 'fa-question-circle'

  return (
    <div key={ name } className={ `btn ${className}` } style={{ margin: "0 10px 10px 0" }}>
      <i className={ `fa ${icon}` } style={{ marginRight: "5px" }}/>
      { name }
    </div>
  );
}
