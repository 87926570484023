import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";

const POPUP_WIDTH = 275;
const UNGROUPED_OPTIONS = [
  { id: null, value: "All Units" },
  { id: "not_requested", value: "Not Requested", key: "not_requested" },
];

class AvailabilityFilters extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = Object.assign(
      {
        status: [],
        isExpanded: false,
      },
      this.props.initialValue,
    );
  }

  componentDidMount() {
    const { availability_statuses } = this.props;
    document.addEventListener("click", this.onToggle);
    this.setState({ status: [...UNGROUPED_OPTIONS, ...availability_statuses] });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.units.unit_filter !== prevProps.units.unit_filter) {
      const { availability_status } = this.props.units.unit_filter;
      this.setState({ active: availability_status });
    }
    if (this.props.availability_statuses !== prevProps.availability_statuses) {
      const { availability_statuses } = this.props;
      this.setState({ status: [...UNGROUPED_OPTIONS, ...availability_statuses] });
    }
  }

  groupedStatuses() {
    const { availability_statuses } = this.props;
    return _.groupBy(availability_statuses, status => status.group);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  getButtonLabel() {
    const { active } = this.state;
    const item = this.state.status.find(s => s.key === active);
    if (item && item.value != "All Units") {
      return item.value;
    }
    return "Availability";
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderUngrouped() {
    const ungrouped = this.state.status.filter(s => s.id == "not_requested" || s.id == null);
    return <ul>{this.renderUngroupedList(ungrouped)}</ul>;
  }

  renderUngroupedList(ungrouped) {
    return ungrouped.map((item, idx) => (
      <li
        key={`${item.value}-${idx}`}
        className={cs("list-status", { active: this.isActive(item) })}
        onClick={() => this.selectStatus(item)}
      >
        <img src={ASSETS["status_all"]} />
        {item.value}
        <i className="fa fa-check" />
      </li>
    ));
  }

  renderAllGroups() {
    const groups = this.groupedStatuses();
    return Object.keys(groups).map((group, idx) => (
      <ul key={`${group}-${idx}`}>
        <li className="group-title">{group}</li>
        {this.renderGroupList(groups[group])}
      </ul>
    ));
  }

  isActive({ key }) {
    const { active } = this.state;
    return key == active;
  }

  selectStatus(item) {
    this.setState({ active: item.key }, () => {
      this.handleFilter({ availability_status: item.key })
    });
  }

  handleFilter(filter) {
    const { filterUnits, handleQueryParams } = this.props
    filterUnits(filter)
    handleQueryParams(filter)
  }

  renderGroupList(group) {
    return group.map(list => (
      <li
        key={list.id}
        className={cs("list-status", { active: this.isActive(list) })}
        onClick={() => this.selectStatus(list)}
      >
        <img src={ASSETS[`status_${list.key}`]} />
        {list.value}
        <i className="fa fa-check" />
      </li>
    ));
  }

  renderPopup() {
    return (
      <div className="filter_popup availability_filters_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        {this.renderUngrouped()}
        {this.renderAllGroups()}
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ units, availability_statuses }) => ({
  units,
  availability_statuses,
});

export default connect(mapStateToProps, { filterUnits })(AvailabilityFilters);
