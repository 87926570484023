import React from "react";
import VendorMap from "../../Campaign/Vendors/VendorMap";
import FiltersBar from '../../Filters/FiltersBar';
import { connect } from "react-redux";
import {
  filterUnits,
  clearFilters
} from '../../../actions/UnitsActions';

class AddByMap extends React.Component {

  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
  }

  componentWillUnmount() {
    this.props.clearFilters();
  }

  onFilter(params) {
    const { filterUnits } = this.props;
    filterUnits(params);
  }

  render() {
    const { units, active } = this.props;
    const unit_filter = (units && units.unit_filter) ? units.unit_filter : [];

    return (
      <div>
        <FiltersBar
          isVendorMap={true}
          activeFilters={unit_filter}
          onFilter={this.onFilter}
        />
        <div
          className="col-xs-12"
          id="add-by-map-area"
          style={{height: $(window).height() - 200}}
        >
          <div className="col-xs-12 new-search">
            <VendorMap
              active={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ units }) => ({ units }),
  { clearFilters, filterUnits }
)(AddByMap);
