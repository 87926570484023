import {allDataSetsDataLoaded, allUnits} from "./Units";

export function hasKdbushIndex() {
  return window.adquickCampaignKdbushIndex != null;
}

export function clearKdbushIndex() {
  window.adquickCampaignKdbushIndex = null;
}

export function buildKdbushIndex(state) {
  try {
    if (!AppConfig.campaignSpatialIndexEnabled) return;
    if (!allDataSetsDataLoaded(state)) return;

    const points = allUnits(state).map(u => [u.lon, u.lat, u]);
    window.adquickCampaignKdbushIndex = new KDBush(points);
    DEBUG && console.log('KDBush index built.');
  } catch (e) {
    if (window.Rollbar) Rollbar.error('Could not build KDBush index.', e);
    console.error('Could not build KDBush index.', e);
    return
  }
}

export function getKdbushIndex() {
  return window.adquickCampaignKdbushIndex;
}