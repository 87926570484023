import React from "react";
import { connect } from "react-redux";
import { put } from "../../../utils/api"
import {
  fetchSalesUsers
} from "../../Inventory/actions";
import {
  fetchAllAdvertisers,
} from "../../Advertisers/actions";

import moment from "moment";
import DateRangePicker from "../../DatePicker/Range";
import ReactSelect from "react-select";
import CampaignStore from "../../../stores/CampaignStore";
import GlobalActions from "../../../actions/GlobalActions";
import CreateAdvertiser from '../../Advertisers/Create';
import UploadButton from '../../UploadButton';
import Tooltip from "rc-tooltip";
import isEmpty from "lodash/isEmpty";


const tool_tips_copy = {
  'hl_zc_obj': { label: 'Highlighted Zip Codes', copy: 'Add in zip codes to send to vendors and to highlight on campaign map for client.'},
  'am_obj' : { label: 'Account Manager', copy: 'Account Manager attached to the RFP. This user will be copied on all outbound emails from system to client and to vendors. ' },
}

class SummaryTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edited: false,
      advertiser_attrs: {
        id: null,
        name: null
      },
      campaign_attrs: {
        name: null,
        start_date: null,
        end_date: null,
        notes: null,
        sales_user_id: null,
        highlighted_regions: null,
        geography: null,
        branding_logo_url: null,
        pinned_message: null,
        spend_goal_enabled: null,
        spend_goal: null,
        spend_goal_message: null,
        is_open_for_browse: null,
      },
      markets: [],
      sales_user: {},
      show_advertiser_create: false
    };

    this.onSave = this.onSave.bind(this);
    this.onAdvertiserChange = this.onAdvertiserChange.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.clearBrandingLogoUrl = this.clearBrandingLogoUrl.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.fetchAllAdvertisers();
      this.props.fetchSalesUsers();
      this.buildCampaignStateFromProps();
    }, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    const { advertiser_attrs } = this.state;
    const { selectedAdvertiserId, campaign } = this.props;
    if(isEmpty(prevProps.campaign) && !isEmpty(campaign)) {
      this.buildCampaignStateFromProps();
    }
    if (selectedAdvertiserId && advertiser_attrs.id !== selectedAdvertiserId) {
      this.setState({
        advertiser_attrs: { ...advertiser_attrs, id: selectedAdvertiserId },
        edited: true
      });
    }
  }

  buildCampaignStateFromProps() {
    const { campaign } = this.props;
    const markets = campaign.geography ? campaign.geography.split(';').map( l => ({ label: l }) ) : [];
    this.setState({
      campaign_attrs: {
        name: campaign.name,
        start_date: campaign.start_date,
        end_date: campaign.end_date,
        sales_user_id: campaign.sales_user_id,
        notes: campaign.notes,
        contract_number: campaign.contract_number,
        highlighted_regions: campaign.highlighted_regions,
        geography: campaign.geography,
        budget: campaign.budget,
        branding_logo_url: campaign.branding_logo_url,
        pinned_message: campaign.pinned_message,
        spend_goal_enabled: campaign.spend_goal_enabled,
        spend_goal: campaign.spend_goal,
        spend_goal_message: campaign.spend_goal_message,
        is_open_for_browse: campaign.is_open_for_browse,
        send_campaign_view_email: campaign.send_campaign_view_email,
      },
      markets,
      advertiser_attrs: {
        ...campaign.advertiser
      }
    });
  }

  async onSave() {
    const { campaign_attrs, advertiser_attrs } = this.state;
    const campaign = _.merge(campaign_attrs, { advertiser: advertiser_attrs });
    try {
      const response = await put(`/api/v1/campaigns/${this.props.campaign.token}`, { campaign })
      GlobalActions.showMessage("Your changes to the campaign have been saved!");
      CampaignStore.updateCampaignData({ ...campaign_attrs });
      CampaignStore.trigger("campaign:reload");
      this.setState({ edited: false });
      window.location.reload();
    } catch (error) {
      console.log({ response });
      GlobalActions.showError("There was an error saving your campaign, please contact AdQuick");
    }
  }

  onAdvertiserChange(option) {
    const { advertiser_attrs } = this.state;
    this.setState({
      advertiser_attrs: {
        ...advertiser_attrs,
        id: option.value,
        name: option.label
      },
      edited: true
    })
  }

  onUploadComplete(upload, file) {
    const { campaign_attrs } = this.state;

    this.setState({
      campaign_attrs: {
        ...campaign_attrs,
        branding_logo_url: file.url
      },
      edited: true
    });
  }

  clearBrandingLogoUrl() {
    const { campaign_attrs } = this.state;
    this.setState({
      campaign_attrs:  {
        ...campaign_attrs,
        branding_logo_url: null
      },
      edited: true
    });
  }

  renderLabelTooltip(label, copy) {
    return (
      <Tooltip placement="right" overlay={copy}>
        <div className="form_label" style={{ width: 'fit-content'}}>
          {label}<i className="fa fa-info-circle" />
        </div>
      </Tooltip>
    );
  }

  renderClientLogoSection() {
    const { campaign_attrs } = this.state;
    const { branding_logo_url } = campaign_attrs;

    return (
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="col-sm-12">
            <div className="form_label">Client Logo</div>
            <UploadButton onComplete={this.onUploadComplete}>
              <button type="button" className="btn btn-default"  style={{width: '100%', height: '40px'}} onClick={(e) => { e.preventDefault(); }}>
                <i className="fa fa-upload" style={{ marginRight: 7.5 }} />
                Upload Image
              </button>
            </UploadButton>
            {(branding_logo_url) && <div className="client-logo-area" style={{marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
              <img src={branding_logo_url} style={{ width: 100, marginTop: 10 }}/>
              <a onClick={this.clearBrandingLogoUrl}>Remove logo</a>
            </div>}
          </div>
        </div>
    )
  }

  renderVendorSellerSection() {
    const { permissions } = this.props;
    const { campaign_attrs } = this.state;
    console.log(campaign_attrs.spend_goal_enabled)
    return (
      <div className="col-sm-12">
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label">Pinned Message</div>
          <textarea
            disabled={!!!permissions.can_edit_vendor_campaign}
            value={campaign_attrs.pinned_message}
            className="form-control"
            placeholder="Enter a message to be pinned to the campaign map page."
            onChange={e =>
              this.setState({
                campaign_attrs: { ...campaign_attrs, pinned_message: e.target.value },
                edited: true,
              })
            }
          />
        </div>
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label">Campaign Target</div>
          <div className="form-check" style={{display: 'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: '9px'}}>
            <input
              disabled={!!!permissions.can_edit_vendor_campaign}
              value={campaign_attrs.spend_goal_enabled}
              checked={campaign_attrs.spend_goal_enabled}
              type="checkbox"
              onChange={e =>
                this.setState({
                  campaign_attrs: { ...campaign_attrs, spend_goal_enabled: e.target.checked },
                  edited: true,
                })
              }
            />
            <label style={{ height: "9px" }}>Enable Campaign Target</label>
          </div>
        </div>
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label">Communication</div>
          <div className="form-check" style={{display: 'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: '9px'}}>
            <input
              disabled={!!!permissions.can_edit_vendor_campaign}
              value={campaign_attrs.send_campaign_view_email}
              checked={campaign_attrs.send_campaign_view_email}
              type="checkbox"
              onChange={e =>
                this.setState({
                  campaign_attrs: { ...campaign_attrs, send_campaign_view_email: e.target.checked },
                  edited: true,
                })
              }
            />
            <label style={{ height: "9px" }}>Send campaign viewed email</label>
          </div>
        </div>
        {campaign_attrs.spend_goal_enabled &&
            <div className="row" style={{marginBottom: '16px'}}>
              <div className="form_label">Campaign Budget Goal</div>
              <input
                disabled={!!!permissions.can_edit_vendor_campaign}
                value={campaign_attrs.spend_goal}
                className="form-control"
                type="number"
                placeholder="Enter amount"
                onChange={e =>
                  this.setState({
                    campaign_attrs: { ...campaign_attrs, spend_goal: e.target.value },
                    edited: true
                  })
                }
              /></div>}
        {campaign_attrs.spend_goal_enabled &&
              <div className="row" style={{marginBottom: '16px'}}>
                <div className="form_label">Campaign Budget Message</div>
                <textarea
                  disabled={!!!permissions.can_edit_vendor_campaign}
                  value={campaign_attrs.spend_goal_message}
                  className="form-control"
                  placeholder="Enter custom message"
                  type="textarea"
                  onChange={e =>
                    this.setState({
                      campaign_attrs: { ...campaign_attrs, spend_goal_message: e.target.value },
                      edited: true
                    })
                }
                />
              </div>
        }
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label">Access Control</div>
          <div className="form-check" style={{display: 'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: '9px'}}>
            <input
              disabled={!!!permissions.can_edit_vendor_campaign}
              value={campaign_attrs.is_open_for_browse}
              checked={campaign_attrs.is_open_for_browse}
              type="checkbox"
              onChange={e =>
                this.setState({
                  campaign_attrs: { ...campaign_attrs, is_open_for_browse: e.target.checked },
                  edited: true,
                })
              }
            />
            <label style={{ height: "9px" }}>Skip login requirement</label>
          </div>
        </div>
      </div>
    );
  }

  renderAdvertiserArea() {
    const { show_advertiser_create, advertiser_attrs } = this.state;
    const { advertisers, permissions} = this.props;
    const { id } = advertiser_attrs;
    const options = advertisers.map(a => {
      return { label: a.name, value: a.id }
    })
    const selected = options.find(o => o.value == id);
    const add_advertiser_element =
      <a onClick={e => this.setState({show_advertiser_create: !!!show_advertiser_create})}>
        {show_advertiser_create ? 'hide' : 'Add New Advertiser'}
      </a>

    return (
      <div className="col-md-12">
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label" style={{display: 'flex', justifyContent: 'space-between' }}>
            Advertiser {add_advertiser_element}
          </div>
          <ReactSelect
            disabled={!!!permissions.can_edit_vendor_campaign}
            value={selected}
            onChange={this.onAdvertiserChange}
            options={options}
            valueKey='id'
            labelKey='name'
            cache={false}
            onSelectResetsInput={false}
            onBlurResetsInput={false}
            isClearable
            placeholder={permissions.can_edit_vendor_campaign ? 'Select an Advertiser' : ''}
          />
        </div>
        {permissions.can_edit_vendor_campaign && <div className="row">
          {show_advertiser_create && <CreateAdvertiser />}
        </div>}
      </div>
    )
  }

  renderCampaignDetails() {
    const { permissions } = this.props;
    const { campaign_attrs } = this.state;
    const { sales_user_id } = campaign_attrs;
    const sales_users = this.props.sales_users.map(u => ({ label: u.name, value: u.id }));
    const sales_user = sales_users.find(su => su.value === sales_user_id);
    return (
      <div className="campaign_builder_column row">
        <h4 className="col-md-4" style={{marginTop: 30}}>
          <i className="fa fa-list" />
          <span>Campaign Details</span>
        </h4>
        <div className="col-md-8 form-section">
          {this.renderAdvertiserArea()}
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              <div className="form_label">Campaign Name</div>
              <input
                disabled={!!!permissions.can_edit_vendor_campaign}
                value={campaign_attrs.name}
                className="form-control"
                type="text"
                onChange={e =>
                  this.setState({
                    campaign_attrs: { ...campaign_attrs, name: e.target.value },
                    edited: true
                  })
                }
              />
            </div>
          </div>
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              <div className="form_label">Timeline</div>
              <DateRangePicker
                openDirection={"down"}
                disabled={!!!permissions.can_edit_vendor_campaign}
                start_date={campaign_attrs.start_date ? moment(campaign_attrs.start_date) : null}
                end_date={campaign_attrs.end_date ? moment(campaign_attrs.end_date) : null}
                onDatesChange={({ start_date, end_date }) =>
                  this.setState({
                    campaign_attrs: {
                      ...campaign_attrs,
                      start_date: start_date && start_date.format("YYYY-MM-DD"),
                      end_date: end_date && end_date.format("YYYY-MM-DD")
                    },
                    edited: true
                  })
                }
              />
            </div>
          </div>
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              <div className="form_label">Contract Number</div>
              <input
                disabled={!!!permissions.can_edit_vendor_campaign}
                value={campaign_attrs.contract_number}
                className="form-control"
                type="textarea"
                onChange={e =>
                  this.setState({
                    campaign_attrs: { ...campaign_attrs, contract_number: e.target.value },
                    edited: true
                  })
                }
              />
            </div>
          </div>
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              {this.renderLabelTooltip(tool_tips_copy.am_obj.label, tool_tips_copy.am_obj.copy)}
              <ReactSelect
                placeholder="Select a sales user"
                disabled={!!!permissions.can_edit_vendor_campaign}
                value={sales_user}
                onChange={opt =>
                  this.setState({
                    campaign_attrs: { ...campaign_attrs, sales_user_id: opt && opt.value },
                    edited: true
                  })
                }
                options={sales_users}
                isClearable={true}
              />
            </div>
          </div>
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              <div className="form_label">Budget</div>
              <input
                disabled={!!!permissions.can_edit_vendor_campaign}
                value={campaign_attrs.budget}
                className="form-control"
                type="textarea"
                onChange={e =>
                  this.setState({
                    campaign_attrs: { ...campaign_attrs, budget: e.target.value },
                    edited: true
                  })
                }
              />
            </div>
          </div>
          {this.renderClientLogoSection()}
          {permissions.can_view_summary_budget_and_spend && this.renderVendorSellerSection()}
        </div>
      </div>
    );
  }

  renderCampaignLocations() {
    const { permissions } = this.props;
    const { campaign_attrs } = this.state;
    return (
      <div className="campaign_builder_column row">
        <h4 className="col-md-4" style={{marginTop: 30}}>
          <i className="fa fa-location-arrow" />
          <span>Campaign Locations</span>
        </h4>
        <div className="col-md-8 form-section">
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              {this.renderLabelTooltip(tool_tips_copy['hl_zc_obj'].label, tool_tips_copy['hl_zc_obj'].copy)}
              <textarea
                disabled={!!!permissions.can_edit_vendor_campaign}
                value={campaign_attrs.highlighted_regions}
                className="form-control"
                onChange={e =>
                  this.setState({
                    campaign_attrs: { ...campaign_attrs, highlighted_regions: e.target.value },
                    edited: true
                  })
                }
                placeholder="90291, 91502, 12420"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { permissions } = this.props;
    const { edited } = this.state;
    return (
      <div className="campaign_builder" style={{ background: '#efefef', width: '100%', padding: '22px 111px 200px 129px', marginBottom: '0px' }}>
        <div id="campaign_summary_tab" className="campaign_builder_form">
          <div className="container">
            {this.renderCampaignDetails()}
            {this.renderCampaignLocations()}
            <div className="campaign_builder_column row">
              <div className="col-sm-4"></div>
              <div className="col-sm-8 form-section" style={{ background: 'transparent', boxShadow: 'none', padding: '0px', display: 'flex', justifyContent: 'center' }}>
                {permissions.can_edit_vendor_campaign && <button
                    className="btn btn-primary form-control"
                    type="submit"
                    disabled={!!!edited}
                    onClick={this.onSave}
                    style={{background: '#4A90E2', borderRadius: '62px', width: '644px', height: '40px', fontSize: '16px'}}
                    >
                    Save
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ advertisers_ui: { advertisers }, inventory: { selectedAdvertiserId, sales_users } }) => {
    return {
      advertisers,
      selectedAdvertiserId,
      sales_users
    };
  },
  {
    fetchAllAdvertisers,
    fetchSalesUsers
  }
)(SummaryTab);
