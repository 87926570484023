import "./index.css";

import "./index.css";
import accounting from 'accounting';
import Billboard from "@adquick/icons/custom/Billboard";
import FaRegTrashAlt from "@adquick/icons/fa/FaRegTrashAlt";
import _, { isEmpty } from "lodash";
import { Pagination } from "react-bootstrap";

import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalActions from '../../../actions/GlobalActions';
import { removeFromCart, loadCartUnits, getCartTotal } from "../../../actions/cart";
import FaceId from "../Catalog/FaceId";

interface RootState {
  cart: any;
}

type Props = {
  token: string;
};

const info_order = [
  "screen_type",
  "size",
  "start",
  "end",
  "duration",
  "total_impressions",
  "media_total",
  "production_and_install",
];

const formatInfo = (type, number) => {
  if (type === "media_total" || type === "production_and_install") {
    return accounting.formatMoney(number, "$", 0);
  } else if (type === "impressions") {
    return accounting.formatNumber(number);
  } else {
    return number;
  }
};

const UnitInfo = rowInfo => {
  return (
    <div className="mb-2.5 h-full w-[867px] flex flex-row items-center bg-white shadow-md rounded-lg p-3">
      <img className="row-info-image" src={rowInfo.rowInfo.image} />
      <div className="row-info-name-info-block">
        <div className="row-info-name-icon">
          <div className="row-info-name flex flex-row" id={rowInfo.rowInfo.unit_id}>
            <Billboard />
            <FaceId label={rowInfo.rowInfo.face_id} />
            {rowInfo.rowInfo.name}
          </div>
          <div
            className="row-info-name-trash"
            id={rowInfo.rowInfo.id}
            data-token={rowInfo.rowInfo.token}
            onClick={e => { rowInfo.handler(e) }}
          >
            <FaRegTrashAlt />
          </div>
        </div>
        <div className="row-info-label-block">
          {info_order.map((item, idx) => (
            <div className="row-info-label" key={idx}>
              <div className="row-label">{item === "production_and_install" ? "Production & Install" : item.replace(/_/g, " ")}
              </div>
              <div className="row-info">{formatInfo(item, rowInfo.rowInfo[item])}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const UnitsSummary: React.FunctionComponent<Props> = (props: Props) => {
  /* campaign token */
  const { token } = props;

  const cart = useSelector((state: RootState) => state.cart);
  const { cart_units, current_page, total_pages } = cart;
  const dispatch = useDispatch();

  const handleRemoveItem = e => {
    const cu_token = e.currentTarget.getAttribute("data-token") || "";
    dispatch(removeFromCart(token, cu_token));
    GlobalActions.showMessage("Unit Removed");
  };

  const handleModalPopup = e => {
    const id = e.currentTarget.getAttribute("id") || "";
    GlobalActions.openUnitID(id);
  };

  const handlePageSelect = (page) => {
    dispatch(loadCartUnits(token, page));
  }

  const renderCartUnits = () => {
    return cart_units.map((item, idx) => <UnitInfo key={idx} rowInfo={item} handler={handleRemoveItem} handleModal={handleModalPopup} />)
  }

  return (
    <div className="units-summary-container">
      <h4>Units</h4>
      <div>
        {renderCartUnits()}
      </div>
      {total_pages > 1 && (
        <Pagination
          bsSize="small"
          prev
          next
          last
          ellipsis
          boundaryLinks
          items={total_pages}
          maxButtons={5}
          activePage={current_page}
          onSelect={handlePageSelect}
        />
      )}
    </div>
  );
};

export default UnitsSummary;
