import * as React from 'react';
import { Modal } from 'react-bootstrap';
const ASSETS = (window as any).ASSETS;

export default class NeedHelpModal extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email || '',
      message: '',
      sending: false,
      sent: false
    };

    this.renderLoadingSpinner = this.renderLoadingSpinner.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderSentArea = this.renderSentArea.bind(this);
  }

  renderLoadingSpinner() {
    return (
      <div className="new_loading" style={{ textAlign: "center", paddingTop: 10 }}>
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20"></circle>
        </svg>
      </div>
    );
  }

  onSubmit() {
    const { email, message } = this.state;
    this.setState({ sending: true }, () => {
      $.ajax({
        method: "POST",
        url: "/need_help",
        data: {
          email: email,
          message: message
        }
      }).always((data) => {
        this.setState({
          sent: true,
          sending: false
        })
      });
    })
  }

  renderForm() {
    const { email, message } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <input
          className="input form-control"
          type="email"
          value={email}
          onChange={(e) => this.setState({ email: e.target.value })}
          placeholder="Enter email address..."
        />
        <textarea
          className="input form-control"
          placeholder="In a brief message please explain how we can help..."
          value={message}
          style={{ height: 250, resize: 'none' }}
          onChange={(e) => this.setState({ message: e.target.value })}
        />
        <button
          className="adstrap_button submit_button"
          onClick={this.onSubmit}
        >
          Submit
        </button>
      </form>
    )
  }

  renderSentArea() {
    return (
      <div className="support-message-sent">
        <div className="checkmark-area">
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>
        </div>
        <div className="text">
          We've received your help request and the team has been notified.
        </div>
      </div>
    )
  }

  render() {
    const { sending, sent } = this.state;
    const { show, onHide, onBack } = this.props;

    return (
      <Modal show={show} onHide={onHide} id="need-help-modal">
        <Modal.Header>
          <div className="need-help-header">
            <div className="back-icon-area" onClick={onBack}>
              <i className="fa fa-chevron-left back-icon" aria-hidden="true"/>
            </div>
            <img
              className="close-modal-x"
              src={ASSETS['x']}
              style={{ float: 'right', top: -30, right: -30 }}
              onClick={onHide}
            />
            <div className="header-text">
              How can we help?
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {!!!sent && <div>
            {sending ? this.renderLoadingSpinner() : this.renderForm()}
          </div>}
          {sent && <div>
            {this.renderSentArea()}
          </div>}
        </Modal.Body>
      </Modal>
    );
  }
};
