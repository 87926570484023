import React from 'react';
import createClass from 'create-react-class';
import Form from './Form'
import GlobalActions from '../../../actions/GlobalActions'

export default createClass({
  displayName: 'Settings/FlightTypes',

  getInitialState() {
    return {
      flightTypes: []
    }
  },

  componentDidMount() {
    this.loadFlightTypes()
  },

  loadFlightTypes() {
    $.get('/api/v1/flight_types').then(({ flight_types }) => {
      this.setState({ flightTypes: flight_types })
    })
  },

  onCreateFlightType(flight_type) {
    $.post('/api/v1/flight_types', { flight_type }).then(({ flight_types }) => {
      GlobalActions.showMessage('Added flight type!')
      this.setState({ flightTypes: flight_types })
    })
  },

  onUpdateFlightType(flightTypeId, flight_type) {
    $.post(`/api/v1/flight_types/${flightTypeId}`, { flight_type, _method: 'PUT' }).then(({ flight_types }) => {
      GlobalActions.showMessage('Updated flight type!')
      this.setState({ flightTypes: flight_types })
    })
  },

  onDeleteFlightType(flightTypeId) {
    if (confirm("Are you sure you want to delete this flight type?")) {
      $.post(`/api/v1/flight_types/${flightTypeId}`, { _method: 'DELETE' }).then(({ flight_types }) => {
        GlobalActions.showMessage('Deleted flight type!')
        this.setState({ flightTypes: flight_types })
      })
    }
  },

  render() {
    const { flightTypes } = this.state

    return (
      <div>
        <h3 style={{marginTop:0}}>Edit Flight Types</h3>
        <div>
          {flightTypes.map(flightType => <div className="panel panel-default">
            <div className="panel-body">
              <Form
                flightType={flightType}
                onSave={values => this.onUpdateFlightType(flightType.id, values)}
                onDelete={() => this.onDeleteFlightType(flightType.id)} />
            </div>
          </div>)}
        </div>

        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">Add Flight Type</h3>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-12">
                <Form onSave={this.onCreateFlightType} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})
