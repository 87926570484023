import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";

const POPUP_WIDTH = 220;

class HealthScoreFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {
      isExpanded: false,
      health_score_status: this.props.initialValue
        ? {
            label: this.props.initialValue,
            value: this.props.initialValue,
          }
        : null,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, supplier, initialValue } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }

    if (!!!supplier && prevProps.supplier) {
      this.setState({ health_score_status: null }, () => {
        this.onFilter();
      });
    }

    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ health_score_status: { label: initialValue, value: initialValue }})
    }
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onSelect(score) {
    const { health_score_status } = this.state;
    if (health_score_status && health_score_status.value === score.value) {
      this.setState({ health_score_status: null }, this.onFilter);
    } else {
      this.setState({ health_score_status: score }, this.onFilter);
    }
  }

  onFilter() {
    const { filterUnits, handleQueryParams } = this.props;

    this.setState({ isExpanded: false });
    const filter = this.handleHealthScoreFilter()
    filterUnits(filter);
    handleQueryParams(filter)
  }

  handleHealthScoreFilter() {
    const { health_score_status } = this.state
    return { health_score_status: !health_score_status ? null : health_score_status.value }
  }

  getButtonLabel() {
    const { health_score_status } = this.state;
    if (!health_score_status) return "Health Score";
    console.log({bbbbbbbb: health_score_status})
    // I HATE this, initial state and props should be in sync so we can directly grab the label from the object
    // not working on that now because apparently this map will be scrapped in the future
    const labels = {
      poor: "Poor",
      okay: "Okay",
      great: "Great"
    }
    return labels[health_score_status.label];
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  onClear() {
    this.setState({ health_score_status: null }, this.onFilter);
  }

  renderPopup() {
    const { health_score_status } = this.state;
    const { currentUser, supplier } = this.props;
    const healthScores = [
      {value: 'great', label: 'Great (8-10)'},
      {value: 'okay', label: 'Okay (5-7)'},
      {value: 'poor', label: 'Poor (1-4)'}
    ]
    return (
      <div className="filter_popup health_score_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>UNIT HEALTH</h4>
        {healthScores.length === 0 && <div style={{ paddingLeft: 10, paddingBottom: 10 }}>No results</div>}
        {healthScores.length === 0 && !!!supplier && currentUser && currentUser.is_admin && (
          <div style={{ paddingLeft: 10 }}>please select a vendor to use this filter</div>
        )}
        {health_score_status && (
          <a
            style={{
              float: "right",
              top: -32,
              paddingRight: 12,
              position: "relative",
              color: "#999999",
            }}
            onClick={this.onClear}
          >
            Clear
          </a>
        )}
        <ul className="ui">
          {healthScores.map(healthScore => {
            const onClick = () => {
              this.onSelect(healthScore);
            };
            const isActive = health_score_status && health_score_status.value === healthScore.value;
            return (
              <li key={healthScore.value} onClick={onClick} className={cs({ active: isActive })}>
                <i className={`fa fa-wave-pulse health-icon-${healthScore.value}`} />
                {healthScore.label}
                <i className="fa fa-check" />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { isExpanded, health_score_status } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: health_score_status || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ units, inventory: { orientations }, currentUser }) => ({
  units,
  orientations,
  currentUser,
});

export default connect(mapStateToProps, {
  filterUnits
})(HealthScoreFilter);
