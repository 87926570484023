import "./async-search-input.css";
import { Input, InputStyle } from "../Input";
import { Loading, LoadingSize } from "../../Loading";
import FaSearch from "@adquick/icons/fa/FaSearch";
import FaTimes from "@adquick/icons/fa/FaTimes";
import React, { ChangeEvent, MutableRefObject, ReactElement, useRef } from "react";

export interface AsyncSearchInputProps {
  id?: string;
  fetchOptions: (value: string) => Promise<Option[] | void>;
  defaultValue?: string;
  placeholder?: string;
  inputStyle?: InputStyle;
  onClearOptions?: () => void;
}

export interface Option {
  value: string | number;
  label: string | number;
  [key: string]: string | number;
}

export const makeOptionsGetter = (
  setLoading: (set: boolean) => void,
  fetchOptions: (value: string) => Promise<Option[] | void>,
) => async (value: string): Promise<Option[] | void> => {
  if (!value || value.length <= 3) {
    return [];
  }
  setLoading(true);
  const options = await fetchOptions(value);
  setTimeout(() => setLoading(false), 500);
  if (options) {
    return options;
  }
};

const clearInput = (input?: HTMLInputElement, callback?: () => void): void => {
  if (input?.value) {
    input.value = "";
    callback && callback();
  }
};

export const AsyncSearchInput = ({
  id,
  inputStyle = InputStyle.Line,
  defaultValue,
  placeholder,
  fetchOptions,
  onClearOptions,
}: AsyncSearchInputProps): ReactElement => {
  const [value, setValue] = React.useState(defaultValue || "");
  const [loading, setLoading] = React.useState(false);
  const input = useRef<HTMLInputElement>();
  const getOptions = makeOptionsGetter(setLoading, fetchOptions);

  return (
    <div className="asyncInput" style={{ marginBottom: "18px" }}>
      <div className="asyncInput__icon">
        <FaSearch />
      </div>
      <Input
        id={id}
        inputStyle={inputStyle}
        ref={input as MutableRefObject<HTMLInputElement>}
        style={{
          borderRadius: "4px",
          marginLeft: "10px",
          marginTop: "10px",
          width: "95%",
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
          setValue(event.target.value);
          getOptions(event.target.value);
        }}
        type="text"
        placeholder={placeholder || "Search DMAs, cities and zips..."}
        defaultValue={value}
      />
      {loading && (
        <span className="asyncInput__loading">
          <Loading size={LoadingSize.Tiny} />
        </span>
      )}
      {!loading && (
        <button className="asyncInput__clear" onClick={(): void => clearInput(input?.current, onClearOptions)}>
          <FaTimes />
        </button>
      )}
    </div>
  );
};
