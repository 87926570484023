import { get } from "../utils/api2";

export const GET_BACKGROUND_JOB_STATUS = "BACKGROUND_JOB.STATUS";
export const getBackgroundJobStatus = (job_ids) => async (dispatch) => {
  const jobs = await get("/api/v1/job_progress/search", { job_ids });
  dispatch({ type: GET_BACKGROUND_JOB_STATUS, payload: jobs });
};

export const notifyJobsCompletion = (job_ids) => async (dispatch) => {
  if (await isJobCompleted(job_ids, dispatch)) return;

  const interval = setInterval(async () => {
    if (await isJobCompleted(job_ids, dispatch)) {
      dispatch({ type: GET_BACKGROUND_JOB_STATUS, payload: []});
      clearInterval(interval);
    }
  }, 600);
};

async function isJobCompleted(job_ids, dispatch) {
  const jobs = await get("/api/v1/job_progress/search", { job_ids });
  dispatch({ type: GET_BACKGROUND_JOB_STATUS, payload: jobs});

  return jobs.length && !jobs.find(job => !job.is_completed);
}
