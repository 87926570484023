import React from 'react';
import PropTypes from 'prop-types';
import UploadButton from '../UploadButton';

export default class ContractForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
  }

  onUploadComplete(upload, file) {
    this.setState({
      file_url: file.url,
      file_name: file.name
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.onSubmit({
      file_url: this.state.file_url,
      title: this.refs.title.value
    });
  }

  render() {
    const { defaultValues, serverErrors, saving } = this.props;
    const { file_name, file_url } = this.state;

    return (<div className="login__form">
      {serverErrors && <div className="row">
        <div className="col-sm-12" style={{ color: 'red', padding: 15, paddingTop: 0 }}>
          {serverErrors.map(error => <div>{error}</div>)}
        </div>
      </div>}
      <form className="form" onSubmit={this.onSubmit}>
        <div className="form-group row">
          <div className="col-sm-12">
            <label className="col-2 col-form-label">File</label>
            <div className="col-10">
              <div style={{ width: 150, float: 'left' }}>
                <UploadButton onComplete={this.onUploadComplete} isPublic={false}>
                  <button type="button" className="btn btn-default" onClick={(e) => { e.preventDefault(); }}>
                    <i className="fa fa-upload" style={{ marginRight: 7.5 }} />
                    Upload Contract
                  </button>
                </UploadButton>
              </div>
              <div style={{ marginTop: 5, marginLeft: 10, float: 'left' }}>
                {file_name}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <label>Title</label>
            <div className="col-10">
              <input className="form-control" defaultValue={defaultValues.title} ref="title" />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <button className="btn btn_primary register__submit" type="submit" onClick={this.onSubmit} disabled={!file_url || saving}>
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>);
  }
};

ContractForm.propTypes ={
  onSubmit: PropTypes.func.isRequired
};
