import React, { Component } from 'react';
import { transitUnitPriceRange } from "../../UnitInfo";
import accounting, { formatNumber } from "accounting";
import UnitModel from "../../../models/unitModel";

const PRICE_INTERVAL = "4w";

class Price extends Component {
   // TODO: Verfiy if isPublicView to hide prices (as in Campaign/index)

  showPrice(){
    const { campaignHidePrices, hide_unit_prices } = this.props;
    if (!AppConfig.showPricing || campaignHidePrices || hide_unit_prices) return false;
    return true;
  }

  formattedPrice(){
    const { unit, campaign, isTransit } = this.props
    if(!unit.price) { return }
    const unitModel = new UnitModel(unit)
    const price = isTransit ? transitUnitPriceRange(unit) : unitModel.getMockPrice(campaign);
    return `${price} / ${PRICE_INTERVAL}`;
  }

  render(){
    if (!this.showPrice()) return null;
    return (
      <li>{this.formattedPrice()}</li>
    )
  }

}

export default Price
