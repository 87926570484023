import React from "react";
import moment from "moment";

const Shares = ({ shares }) => (
  <div style={{ marginBottom: 20 }}>
    <h4>Shared with:</h4>
    <div style={{ borderTop: "1px solid #eee" }}>
      {shares.map(share => (
        <div style={{ padding: 10, border: "1px solid #eee", borderTop: 0 }}  key={`share_${share.token}`}>
          <div style={{ float: "right" }}>
            {share.last_opened_at
              ? `Opened ${share.open_count} time${
                  share.open_count > 1 ? "s" : ""
                }, last opened ${moment(share.last_opened_at).fromNow()}`
              : "Never opened"}
          </div>
          <div>{share.to_email}</div>
        </div>
      ))}
    </div>
  </div>
);

export default Shares;
