import React from "react";
import { Modal } from "react-bootstrap";

import AddNotesModal from "./AddNotesModal";
import ChangeDatesModal from "./ChangeDatesModal";
import ChangePricingModal from "./ChangePricingModal";
import ChangeSpotsModal from "./ChangeSpotsModal";
import CreateBookingFlights from "./CreateBookingFlights";
import CreateHoldFlights from "./CreateHoldFlights";
import CreateMovableFlights from "./CreateMovableFlights";
import CreateProposalFlights from "./CreateProposalFlights";
import DeleteUnitsModal from "./DeleteUnitsModal";

interface Props {
  campaign_token: string;
  selected_units: any;
  show_booking_flights_modal: boolean;
  show_hold_flights_modal: boolean;
  show_movable_flights_modal: boolean;
  show_proposal_flights_modal: boolean;
  show_add_notes_modal: boolean;
  show_delete_units_modal: boolean;
  show_change_dates_modal: boolean;
  show_change_spots_modal: boolean;
  show_change_pricing_modal: boolean;
  setParentState: Function;
}

export default class Modals extends React.Component<Props, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      selected_units,
      show_booking_flights_modal,
      show_hold_flights_modal,
      show_movable_flights_modal,
      show_proposal_flights_modal,
      show_add_notes_modal,
      show_delete_units_modal,
      show_change_dates_modal,
      show_change_spots_modal,
      show_change_pricing_modal,
      campaign_token,
      setParentState,
    } = this.props;

    return (
      <div>
        <CreateBookingFlights
          show={show_booking_flights_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_booking_flights_modal: false })}
        />
        <CreateHoldFlights
          show={show_hold_flights_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_hold_flights_modal: false })}
        />
        <CreateMovableFlights
          show={show_movable_flights_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_movable_flights_modal: false })}
        />
        <CreateProposalFlights
          show={show_proposal_flights_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_proposal_flights_modal: false })}
        />
        <DeleteUnitsModal
          show={show_delete_units_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_delete_units_modal: false })}
        />
        <AddNotesModal
          show={show_add_notes_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_add_notes_modal: false })}
        />
        <ChangeDatesModal
          show={show_change_dates_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_change_dates_modal: false })}
        />
        <ChangeSpotsModal
          show={show_change_spots_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_change_spots_modal: false })}
        />
        <ChangePricingModal
          show={show_change_pricing_modal}
          campaign_token={campaign_token}
          unit_tokens={selected_units || []}
          onHide={() => setParentState({ show_change_pricing_modal: false })}
        />
      </div>
    );
  }
}
