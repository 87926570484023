import { connect } from 'react-redux';
import { formatMoney } from 'accounting';
import React from 'react'
import {
  formattedToValue,
  showBasics,
  showBudget,
  showDemographics,
  showGeography
} from './actions';

class Summary extends React.Component {

  renderSimpleGroupItems(title, array, click_function){
    return (
    <div className="sub-group">
      <div className="sub-group-title" onClick={click_function}>{title}<i className="fa fa-pencil-square-o" aria-hidden="true" /></div>
      <div className="sub-group-items">
        {array.map((item, index) => (<div key={index} className="sub-group-item">{item}</div>))}
      </div>
    </div>
    );
  }

  render() {
    const { selected_objectives, selected_media_types, spend_groups, selected_demographics } = this.props
    return(
      <div className="summary">
        <div className="summary-group">
          <div className="group-title">Campaign</div>
          {this.renderSimpleGroupItems('Goals', selected_objectives, this.props.showBasics)}
          {this.renderSimpleGroupItems('Media Types', selected_media_types, this.props.showBasics)}
        </div>
        <div className="summary-group">
          <div className="group-title">Audience<i className=""/></div>
          <div className="sub-group">
            <div className="sub-group-title" onClick={this.props.showDemographics} >Demographics <i className="fa fa-pencil-square-o" aria-hidden="true"/></div>
            <div className="sub-group-items">
              {
                Object.values(selected_demographics).map(demographic => (
                  <div key={demographic.id} className="sub-group-item">{ demographic.name }</div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="summary-group">
          <div className="group-title">Markets & Timing </div>
          <div className="sub-group">
            <div className="sub-group-title" onClick={this.props.showGeography} >Markets Added <i className="fa fa-pencil-square-o" aria-hidden="true"/></div>
            <div className="sub-group-items">
              {
                spend_groups.map(({location, id}) => (
                  <div key={id} className="sub-group-item">{ location.name }</div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    ) 
  }
}

export default connect(
  ({ planner }) => ({ ...planner }),
  { showBasics, showDemographics, showGeography, showBudget }
)(Summary)
