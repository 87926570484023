import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import React from 'react';
import createClass from 'create-react-class'

import { notifyJobsCompletion } from '../../../actions/BackgroundJobActions';
import CampaignGeopathSegmentForm from './CampaignGeopathSegmentForm'
import GlobalActions from '../../../actions/GlobalActions'
import SegmentType from './SegmentType'

const CampaignGeopathSegmentModal = createClass({
  displayName: 'CampaignGeopathSegmentModal',

  getInitialState() {
    return {
      segment_type: null,
      campaign_geopath_segment: {},
      errors: [],
      saving: false
    }
  },

  setSegmentType(segment_type) {
    this.setState({ segment_type, campaign_geopath_segment: {} });
  },

  componentDidUpdate(prevProps) {
    if (prevProps.show && !this.props.show) {
      this.setState(this.getInitialState());
      return;
    }

    if (!this.state.saving || !this.props.jobs.length) return;
    if (this.props.jobs.find(job => !job.is_completed)) return;

    this.props.updateGeopath();
    this.setState(this.getInitialState());
  },

  async onSubmit() {
    const { campaign_geopath_segment } = this.state

    this.setState({saving: true});
    const url = `/api/v1/campaigns/${this.props.campaign.token}/campaign_geopath_segments`;
    try {
      const {job_id, error} = await $.post(url, { campaign_geopath_segment });
      if (error) {
        GlobalActions.showError(response.error);
        this.setState({ saving: false });
      } else {
        this.props.notifyJobsCompletion(job_id)
      }
    } catch(error) {
      console.error(error);
      GlobalActions.showError("Error creating audience.");
      this.setState({ saving: false });
    }
  },

  onUpdateAttribute(field, value) {
    let campaign_geopath_segment = {...this.state.campaign_geopath_segment}
    campaign_geopath_segment[field] = value
    this.setState({ campaign_geopath_segment })
  },

  render() {
    const { show, onHide } = this.props;
    const { segment_type, campaign_geopath_segment, saving } = this.state

    if (!segment_type) {
      return (
        <Modal show={show} onHide={onHide} dialogClassName="geopath-segment-create-modal">
          <Modal.Header closeButton/>
          <Modal.Body>
            <SegmentType setSegmentType={this.setSegmentType}/>
          </Modal.Body>
        </Modal>
      )
    }


    return (
      <Modal show={show} onHide={onHide} animation={false} dialogClassName="geopath-segment-create-modal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <CampaignGeopathSegmentForm
            onSubmit={this.onSubmit}
            onUpdateAttribute={this.onUpdateAttribute}
            segment_type={segment_type}
            campaign_geopath_segment={campaign_geopath_segment}
            saving={saving} />
        </Modal.Body>
      </Modal>
    )
  }
})

export default connect(({jobs})=>({jobs}), {notifyJobsCompletion})(CampaignGeopathSegmentModal);
