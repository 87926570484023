import * as React from 'react';
import { connect } from 'react-redux';
import {
  fetchExpiringHoldsCount,
  showExpiringHoldsModal,
} from './actions';

class ExpiringHolds extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {};
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier)
    }
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  fetchData(supplier) {
    const { fetchExpiringHoldsCount } = this.props;
    fetchExpiringHoldsCount(supplier);
  }

  render() {
    const { expiring_holds_count, loading_expiring_holds_count, showExpiringHoldsModal } = this.props;

    return (
      <div id="expiring_holds" className="todo-card">
        {loading_expiring_holds_count && <div className="loading-spinner">
          <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        </div>}
        {!!!loading_expiring_holds_count && <div className={`count ${expiring_holds_count >= 10 ? 'high' : ''}`}>
          {expiring_holds_count}
        </div>}
        <div className="card-name">Expiring Holds</div>
        <button className="todo-button" onClick={showExpiringHoldsModal}>
          View
        </button>
      </div>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ vendor_dashboard: {
       expiring_holds_count,
       loading_expiring_holds_count,
       selected_supplier
     }
   }) => ({
     expiring_holds_count,
     loading_expiring_holds_count,
     selected_supplier
   }),
  {
    fetchExpiringHoldsCount,
    showExpiringHoldsModal
  }
)(ExpiringHolds);
