import { CSSProperties } from "react";

export const uploadButton = (): CSSProperties => ({
  width: "100%",
  background: "#4A90E2",
  borderRadius: 4,
  color: "#FFFFFF",
  border: "none",
  fontSize: 12,
  padding: 7
});

export const rejectButton = (): CSSProperties => ({
  background: "rgba(209, 19, 42, 0.11)",
  color: "#D1132A",
  fontSize: 12,
  borderColor: "rgb(255, 217, 222)"
});

export const approveButton = (): CSSProperties => ({
  background: "rgba(68, 172, 107, 0.1)",
  color: "#44AC6B",
  width: "100%",
  fontSize: 12,
  borderColor: "rgb(183, 210, 193)"
});

export const actions = (): CSSProperties => ({
  float: "right",
  display: "inline-flex",
  paddingRight: 20,
  marginBottom: 10
});

export const checkbox = (): CSSProperties => ({
  marginLeft: 20,
  marginTop: 10
});

export const orderActionContainer = (): CSSProperties => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-around",
  alignItems: "center"
});

export const orderActionInput = (): CSSProperties => ({
  flexGrow: 1,
  margin: "0 10px"
});

export const buttonList = (): CSSProperties => ({
  position: "absolute",
  width: "100%",
  zIndex: 10000,
  backgroundColor: "#fff",
  padding: "5px",
  border: "1px solid rgba(0,0,0,.15)",
  borderRadius: "4px",
  boxShadow: "0 6px 12px rgba(0,0,0,.175)"
});
