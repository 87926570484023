import React, { PureComponent } from "react";
import { Modal, Button } from "react-bootstrap";

export default class RemoveFlightsModal extends PureComponent<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { show, onHide, onSubmit, units } = this.props;
    const faceIds = units.map((unit, k) => <span key={k}>
        {k > 0 && ", "}
        <span className="face-id">#{unit.supplier_face_id}</span>
      </span>
    );

    return (
      <Modal show={show} onHide={onHide} dialogClassName="modal_large" className='campaign_timeline_modal'>
        <Modal.Header>
            <Modal.Title>Remove Flights</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-xs-12 modal-paragraph">
              Would you like to remove the selected flights?
            </div>
          </div>
          <div className="row highted-modal-paragraph-container">
            <div className="col-xs-12 modal-paragraph highlighted">
              {faceIds}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide} style={{ float: "left" }}>Close</Button>
          <Button
            disabled={false}
            bsStyle="danger"
            onClick={onSubmit}
            className="action-button"
          >
            Remove Flights
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
