import React, { Component } from 'react';
import moment from 'moment';
import ActivityLog from './ActivityLog'
import { post } from '../../../utils/api'
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import GlobalActions from '../../../actions/GlobalActions';
import { batchRestoreCampaignUnit } from './actions';
import { connect } from 'react-redux';

const desirableEvents = [
  'CAMPAIGN_UNIT_DESTROYED',
  'CAMPAIGN_UNIT_RESTORED',
  'CAMPAIGN_FAVORITED_UNIT',
  'CAMPAIGN_UNFAVORITED_UNIT',
  'CAMPAIGN_RECOMMENDED_UNIT',
  'CAMPAIGN_UNRECOMMENDED_UNIT'
]

class Group extends Component {

  constructor(props) {
    super(props);
    this.onRestore = this.onRestore.bind(this);
    this.state = {
      logs: props.activityLogs,
    }
  }
  componentDidMount() {
    this.setState({ groupedActionByTime: this.groupActionsByTime(this.state.logs) })
    this.setState({ groupedActionByUserAndType: this.groupedActionByUserAndType(this.state.logs)})

  }

  groupActionsByTime(activityLogs) {
    return _.groupBy(this.filteredActions(activityLogs), log => moment(log.created_at).format('LLL'))
  }

  groupedActionByUserAndType(logs) {
    return _.groupBy(logs, (item) => `${item.event_name}-${item.user_name}`)
  }

  filteredActions(activityLogs) {
    return _.filter(activityLogs, logs => desirableEvents.includes(logs.event_name))
  }

  confirmUndoLastAction() {
    const lastActionGroup = this.findLastActionGroup()
    const lastEvent = this.checkEndpoint(lastActionGroup[0])
    if(confirm(`Are you sure you want to ${lastEvent} ${lastActionGroup.length} units?`)) {
      this.undoLastAction()
    }
  }

  undoLastAction() {
    const lastActions = this.findLastActionGroup()
    if(_.isEmpty(lastActions)) { return }
    const endpoint = this.checkEndpoint(lastActions[0])
    const payload = {
      unit_ids: lastActions.map(action => action.unit_id),
      campaign_id: lastActions[0].campaign_id
    }
    this.callActivityApi(endpoint, payload)
  }

  findLastActionGroup() {
    const { groupedActionByTime } = this.state
    const lastActionKey = this.findLastActionKey()
    return groupedActionByTime[lastActionKey]
  }

  findLastActionKey() {
    const { groupedActionByTime } = this.state
    return _.findKey(groupedActionByTime)
  }

  async callActivityApi(endpoint, payload) {
    try {
      await post(`/api/v1/activities/${endpoint}`, payload)
      await this.props.fetchActivityLogs()
    } catch(error) {
      GlobalActions.showError(error)
    }
  }

  checkEndpoint({ event_name }) {
    const endpoint = {
      CAMPAIGN_UNIT_DESTROYED: 'restore',
      CAMPAIGN_UNIT_RESTORED: 'destroy'
    }
    return endpoint[event_name]
  }

  actionMessage(eventName, userName, unitCount) {
    const messages = {
      CAMPAIGN_UNITS_SORT: `${userName} sorted ${unitCount} units`,
      CAMPAIGN_SHARED: `${userName} shared proposal`,
      CAMPAIGN_ADD_ALL_TO_CART: `${userName} added all units to cart`,
      CAMPAIGN_VIEWED: 'viewed the campaign',
      CAMPAIGN_UNIT_DESTROYED: `${userName} removed ${unitCount} units from the campaign`,
      CAMPAIGN_UNIT_CLICK: `${userName} viewed ${unitCount} units`,
      CAMPAIGN_MAP_UNIT_CLICK: `${userName} viewed ${unitCount} units`,
      CAMPAIGN_LOCATION_SEARCH: `Searched for units`,
      CAMPAIGN_UNIT_RESTORED: 'Restored flight',
      CAMPAIGN_SHARE_LINK_OPENED: `${userName} opened share link`,
      CAMPAIGN_FAVORITED_UNIT: `${userName} favorited ${unitCount} units`,
      CAMPAIGN_UNFAVORITED_UNIT: `${userName} un-favorited ${unitCount} units`,
      CAMPAIGN_RECOMMENDED_UNIT: `${userName} recommended ${unitCount} units`,
      CAMPAIGN_UNRECOMMENDED_UNIT: `${userName} un-recommended ${unitCount} units`
    }
    return messages[eventName]
  }

  actions() {
    const noop = () => {};
    return {
      CAMPAIGN_UNITS_SORT: noop,
      CAMPAIGN_SHARED: noop,
      CAMPAIGN_ADD_ALL_TO_CART: noop,
      CAMPAIGN_VIEWED: noop,
      CAMPAIGN_UNIT_DESTROYED: log => (
        <ul className="nav nav-pills">
          {this.renderRestoreLink(log.data)}
        </ul>
      ),
      CAMPAIGN_UNIT_CLICK: noop,
      CAMPAIGN_MAP_UNIT_CLICK: noop,
      CAMPAIGN_LOCATION_SEARCH: noop,
      CAMPAIGN_UNIT_RESTORED: noop,
      CAMPAIGN_SHARE_LINK_OPENED: noop,
      CAMPAIGN_FAVORITED_UNIT: noop,
      CAMPAIGN_UNFAVORITED_UNIT: noop,
      CAMPAIGN_RECOMMENDED_UNIT: noop,
      CAMPAIGN_UNRECOMMENDED_UNIT: noop
    }
  }

  renderRestoreLink(data) {
    const padding = {padding: '4px 6px'}
    return <a style={padding} href='#' className='col-sm-2' onClick={(e) => this.onRestore(e, data)}>
      Restore All
    </a>
  }

  async onRestore(e, data) {
    e.preventDefault();
    const unit_ids = data.map( u => u.unit_id)
    const campaign_id = data[0].campaign_id
    if (confirm("Are you sure you want to restore this flight?")) {
      if (unit_ids && campaign_id) {
        await this.props.batchRestoreCampaignUnit({campaign_id, unit_ids});
        const updatedLogs = this.state.logs.map(log => {
          log.data.restored = true
          return log
        })
        this.setState({logs: updatedLogs})
        GlobalActions.showMessage('Flight restored!');
      } else {
        console.debug('cant find associated unit', log.event_name, log);
      }
    }
  }

  renderGroup(group, index) {
    const icons = {
      CAMPAIGN_UNITS_SORT: "fa fa-sort-alpha-asc",
      CAMPAIGN_SHARED: "fa fa-share-square-o",
      CAMPAIGN_ADD_ALL_TO_CART: "fa fa-cart-plus",
      CAMPAIGN_VIEWED: "fa fa-eye",
      CAMPAIGN_UNIT_DESTROYED: "fa fa-trash-o",
      CAMPAIGN_UNIT_CLICK: "fa fa-mouse-pointer",
      CAMPAIGN_MAP_UNIT_CLICK: "fa fa-map-marker",
      CAMPAIGN_LOCATION_SEARCH: "fa fa-search",
      CAMPAIGN_UNIT_RESTORED: "fa fa-undo",
      CAMPAIGN_SHARE_LINK_OPENED: "fa fa-share-square-o",
      CAMPAIGN_FAVORITED_UNIT: "fa fa-heart",
      CAMPAIGN_UNFAVORITED_UNIT: "fa fa-heart-o",
      CAMPAIGN_RECOMMENDED_UNIT: "fa fa-registered",
      CAMPAIGN_UNRECOMMENDED_UNIT: "fa fa-times-circle"
    }

    const iconClassName = (icons[group[1][0].event_name] || 'fa fa-circle-thin')

    const { restoreCampaignUnit, timeFormat, toggleUnitModal } = this.props
    const time = moment(group[1][0].created_at).format(this.props.timeFormat)
    const date = moment(group[1][0].created_at).format('M/D')
    const user = group[1][0].user_name
    const action = group[1][0].event_name
    return(
      <div>
          <div className='row'>
          <div className='col-sm-1'>{date}</div>
          <div className='col-sm-1'>{time}</div>
          <div className='col-sm-1'><i className={iconClassName} aria-hidden="true"></i></div>
          <div className='col-sm-5'>
            <a role="button" data-toggle="collapse" data-parent="#accordion" href={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
              {this.actionMessage(action, user, group[1].length)}
            </a>
          </div>
          {action == 'CAMPAIGN_UNIT_DESTROYED' && this.renderRestoreLink(group[1])}
      </div>
      <div id={`collapse${index}`} className="collapse" role="tabpanel" aria-labelledby={`headingOne${index}`}>
        {group[1].map((log) =>
                <ActivityLog
                key={`${log.id}-${log.data.restored}`}
                activity_log={log}
                restoreCampaignUnit={restoreCampaignUnit}
                timeFormat={timeFormat}
                toggleUnitModal={toggleUnitModal} />
              )}
      </div>
    </div>
    )
  }

  render() {
    const headingStyle = {
      fontSize: 21,
      margin: 0
    }

    const { heading } = this.props;
    const activityLogs = this.props.activityLogs || []
    const grouped = this.state.groupedActionByUserAndType || {}

    return(
      <div>
        {activityLogs.length > 0 &&
          <div style={{marginBottom: 40}}>
            <div style={{borderBottom: '1px solid #eee',display: 'flex',alignItems: 'center',paddingBottom: '10px',marginBottom: '24px',marginTop: '20px'}}>
              <h2 style={headingStyle}>{heading}</h2>
              <div style={{marginLeft: '15px'}}>
                <Tooltip
                  placement="top"
                  overlay={<span>Right now this only works for Destroy/Restore</span>}
                >
                  <button className="btn btn-sm btn-primary" onClick={() => this.confirmUndoLastAction()}>Undo last action</button>
                </Tooltip>
              </div>
            </div>
            {Object.entries(grouped).map((group, index) => this.renderGroup(group, `${this.props.group_label}-${index}`) )}
          </div>
        }
      </div>
    );
  }
}

export default connect(
  null,
  { batchRestoreCampaignUnit }
)(Group)
