import React from 'react';
import { connect } from 'react-redux';
import { toFixed } from 'accounting';
import axios from "axios";
import { debounce, get } from "lodash";
import ExpandedTargetSpend from './ExpandedTargetSpend';
import CollapsedTargetSpend from './CollapsedTargetSpend';

class TargetSpend extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
      totals: {}
    }
    this.toggle = this.toggle.bind(this)
    this.getTotals = debounce(this.getTotals.bind(this), 500)
  }

  componentDidMount() {
    this.getTotals()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favorite_unit_ids !== this.props.favorite_unit_ids) this.getTotals()
  }

  async getTotals() {
    const { data } = await axios.get(`/api/v1/campaigns/${this.props.campaign_id}/totals?only_favorites=true`)
    this.setState({ totals: data })
  }

  toggle() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const subtotal = this.state.totals.subtotal
    const { spend_goal, spend_goal_message, spend_goal_required, are_units_loaded } = this.props
    if (!are_units_loaded || subtotal == null || spend_goal == null) return null
    const percentage = `${toFixed(100 * subtotal / spend_goal, 1)}%`

    return this.state.expanded
      ? (
        <ExpandedTargetSpend
          onToggle={this.toggle}
          subtotal={subtotal}
          percentage={percentage}
          spend_goal={spend_goal}
          spend_goal_message={spend_goal_message}
          spend_goal_required={spend_goal_required}
        />
      ) : (
        <CollapsedTargetSpend
          onToggle={this.toggle}
          subtotal={subtotal}
          percentage={percentage}
          spend_goal={spend_goal}
          spend_goal_message={spend_goal_message}
          spend_goal_required={spend_goal_required}
        />
      )
  }
}

export default connect(
  (state) => {
    const campaign = state.campaign.campaign
    const are_units_loaded = get(state, 'units.all_units.length', 0) > 0
    const favorite_unit_ids = state.units.all_units.reduce(
      (favIds, unit) => unit.is_favorited ? `${favIds}-${unit._id}` : favIds,
      ''
    )
    return ({
      are_units_loaded,
      favorite_unit_ids,
      campaign_id: state.campaign.campaignId,
      spend_goal: campaign.spend_goal,
      spend_goal_message: campaign.spend_goal_message,
      spend_goal_required: campaign.spend_goal_required,
    })
  }
)(TargetSpend);
