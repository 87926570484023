import { get, post } from "../../utils/api";

const PLACES_API_URL = "/api/v1/campaigns";
const POIS_SEARCH_URL = "/api/v1/pois_search";

function campaignPoisUrl(token) {
  return `${PLACES_API_URL}/${token}/pois`;
}

export const fetchPlaces = ({ query, lat, lon, radius, campaign }) => async (dispatch, getState) => {
  const res = await get(
    `${POIS_SEARCH_URL}?lat=${lat}&lon=${lon}&radius=${radius}&campaign=${campaign}&query=${encodeURIComponent(query)}`,
  );
  if (res.data) {
    const { search_results } = res.data;
    dispatch({ type: "SEARCH_PLACES_SUCCESS", payload: { search_results } });
  } else {
    console.log("fetch places failed", res);
  }
};

export const loadFavorites = (params = {}) => async (dispatch, getState) => {
  const res = await get(campaignPoisUrl(params.campaign));
  if (res.data) {
    const { places } = res.data;
    dispatch({ type: "FETCH_PLACES_SUCCESS", payload: { places } });
  } else {
    console.log("fetch places failed", res);
  }
};

export const savePlaces = (places_data, campaign_id) => async (dispatch, getState) => {
  const res = await post(campaignPoisUrl(campaign_id), { places_data, places: { campaign_id } });
  if (res.data) {
    const { places } = res.data;
    dispatch({ type: "SAVE_PLACES_SUCCESS", payload: { places } });
  } else {
    console.log("save places failed", res);
  }
};

export const updateIcon = (poi_names, campaign_token, image_path) => async (dispatch, getState) => {
  const data = { poi_names, campaign_token, image_path };
  const res = await post(`${campaignPoisUrl(campaign_token)}/icons`, data);
  if (res.data) {
    const { places } = res.data;
    dispatch({ type: "SAVE_PLACES_SUCCESS", payload: { places } });
  } else {
    console.log("updating icon failed", res);
  }
};

export const updateIconById = (poi_ids, campaign_token, image_path) => async (dispatch, getState) => {
  const data = { poi_ids, campaign_token, image_path };
  const res = await post(`${campaignPoisUrl(campaign_token)}/icons`, data);
  if (res.data) {
    const { places } = res.data;
    dispatch({ type: "SAVE_PLACES_SUCCESS", payload: { places } });
  } else {
    console.log("updating icon failed", res);
  }
};

export const deleteIcon = (poi_ids, campaign_token) => async (dispatch, getState) => {
  const data = { poi_ids, campaign_token };
  const res = await post(`${campaignPoisUrl(campaign_token)}/icons/remove`, data);
  if (res.data) {
    const { places } = res.data;
    dispatch({ type: "SAVE_PLACES_SUCCESS", payload: { places } });
  } else {
    console.log("deleting icon failed", res);
  }
};

export const saveAllPlaces = campaign_id => async (dispatch, getState) => {
  const places_data = getState().places_ui.search_results;
  const res = await post(campaignPoisUrl(campaign_id), { places_data, places: { campaign_id } });
  if (res.data) {
    const { places } = res.data;
    dispatch({ type: "SAVE_PLACES_SUCCESS", payload: { places } });
  } else {
    console.log("save all places failed", res);
  }
};

export const removePlaces = (place_ids, campaign_id) => async (dispatch, getState) => {
  const params = { campaign_id, place_ids };
  const res = await post(`${campaignPoisUrl(campaign_id)}/remove`, params);
  if (res.status == 200) {
    dispatch({ type: "REMOVE_PLACES_SUCCESS", payload: { place_ids } });
  } else {
    console.log("remove places failed", res);
  }
};

export const removeAllPlaces = campaign_id => async (dispatch, getState) => {
  const places_data = getState().places_ui.places;
  const place_ids = places_data.map(p => p.id);
  const params = { campaign_id, place_ids };

  const res = await post(`${campaignPoisUrl(campaign_id)}/remove`, params);
  if (res.status == 200) {
    dispatch({ type: "REMOVE_PLACES_SUCCESS", payload: { place_ids } });
  } else {
    console.log("remove places failed", res);
  }
};

export const filterPlaces = place_names => dispatch => {
  dispatch({ type: "FILTER_PLACES_BY_NAME", payload: { place_names } });
};
