import React from 'react';
import createClass from 'create-react-class';

import UnitStore from '../stores/UnitStore';
import MapStore from '../stores/MapStore';
import AuthStore from '../stores/AuthStore';

import GlobalActions from '../actions/GlobalActions';
import UnitActions from '../actions/UnitActions';

const NoResultsFound = createClass({

  displayName: 'NoResultsFound',

  getInitialState() {
    return {
      user: AuthStore.getState().user,
      map: MapStore.getState(),
      units: UnitStore.getState(),
      errors: false,
      email: '',
      when: '',
      budget: '',
      phone: '',
      advertiser: '',
      geography: '',
      point_of_interest: '',
      demographic: '',
      comment: '',
    };
  },

  getDefaultProps() {
    return {};
  },

  componentDidMount() {
    GlobalActions.sentNoResultsRequest();
  },

  onSubmit(e) {
    e.preventDefault();

    const errors = [];
    if (this.state.email == '') errors.push('Please enter your email address.');
    if (this.state.phone == '') errors.push('Please enter your phone number.');
    // if (this.state.when == "") errors.push("Please enter your desired campaign dates.")

    if (errors.length == 0) {
      const data = {
        email: this.state.email,
        phone: this.state.phone,
        when: this.state.campaign_dates,
        budget: this.state.budget,
        geography: this.state.geography,
        point_of_interest: this.state.point_of_interest,
        demographic: this.state.demographic,
        comment: this.state.comment,
        advertiser: this.state.advertiser,
        filters: this.state.units.filters,
      };

      UnitActions.sendRequest(data);
      GlobalActions.openPopup('campaign_request_sent');
    } else {
      this.setState({ errors });
    }
  },

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  },

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  },

  handleWhenAdvertiseChange(e) {
    this.setState({ campaign_dates: e.target.value });
  },

  handleWhatBudgetChange(e) {
    this.setState({ budget: e.target.value });
  },

  handleAdvertiserChange(e) {
    this.setState({ advertiser: e.target.value });
  },

  handleGeographyChange(e) {
    this.setState({ geography: e.target.value });
  },

  handleInterestChange(e) {
    this.setState({ point_of_interest: e.target.value });
  },

  handleDemographicChange(e) {
    this.setState({ demographic: e.target.value });
  },

  handleCommentChange(e) {
    this.setState({ comment: e.target.value });
  },

  render() {
    const { searchQuery } = this.props;

    return (
      <div className="register no-results-found" onClick={e => e.stopPropagation()} style={{ textAlign: 'center', width: 500 }}>
        <h1 className="register__title">Awesome – you're one step away from the best options!</h1>
        <div className="no-bookable-message">
          <div className="empty-message">
            <p>Fill out the form below and you'll have options in your inbox within 48 hours!</p>
          </div>
        </div>
        <div className="login__form no-results-found-form">
          {this.state.errors && <div className="message message_error">{this.state.errors.map(error => <div>{error}</div>)}</div>}

          <form className="form form-horizontal" onSubmit={this.onSubmit}>
            <div className="form-group">
              <label className="col-sm-3">Your Email</label>
              <div className="col-sm-9">
                <input className="input form-control" placeholder="Email" onChange={this.handleEmailChange} />
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-3">Phone</label>
              <div className="col-sm-9">
                <input className="input form-control" placeholder="Phone Number" onChange={this.handlePhoneChange} />
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-3">Budget</label>
              <div className="col-sm-9">
                <select onChange={this.handleWhatBudgetChange}>
                  <option />
                  <option>Under $5,000</option>
                  <option>$5,000-10,000</option>
                  <option>$10,000-50,000</option>
                  <option>$50,000-200,000</option>
                  <option>I'm not sure</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-3">Dates</label>
              <div className="col-sm-9">
                <select onChange={this.handleWhenAdvertiseChange}>
                  <option />
                  <option>Immediately</option>
                  <option>Within the next 3 months</option>
                  <option>3 - 6 months out</option>
                  <option>6+ months in the future</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12">
                <label>What organization are you advertising for?</label>
                <input className="input" onChange={this.handleAdvertiserChange} />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12">
                <label>Geographic areas or points of interest</label>
                <input className="input" onChange={this.handleGeographyChange} placeholder="eg. grocery stores, gyms" />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12">
                <label>Target demographic or customer type</label>
                <input className="input" onChange={this.handleDemographicChange} placeholder="eg. female $100K salary" />
              </div>
            </div>

            <button className="btn btn_primary register__submit" type="submit">Submit</button>
          </form>
        </div>
      </div>
    );
  },
});

export default NoResultsFound;
