// @ts-check

import React from 'react';
import { get } from '../../../utils/api';
export class ApiResult extends React.Component {
  constructor(props) {
    super(props);
    this.ccoId = 36;
    this.outfrontId = 28;
    this.lamarId = 39;
    this.state = {
      ccoRequests: {
        availableUnits: 0,
        requestedUnits: 0,
        unavailableUnits: 0
      },
      outfrontRequests: {
        availableUnits: 0,
        requestedUnits: 0,
        unavailableUnits: 0
      },
      lamarRequests: {
        availableUnits: 0,
        requestedUnits: 0,
        unavailableUnits: 0
      }
    };
  }

  async componentDidMount() {
    const { campaign } = this.props;
    await this.fetchCampaignRequests(campaign.token);
  }

  /**
   * @param {String} campaignToken
   */
  async fetchCampaignRequests(campaignToken) {
    const ccoResponse = await get(`/api/v1/external_api_results/${campaignToken}/${this.ccoId}`);
    this.setState({
      ccoRequests: {
        availableUnits: ccoResponse.results.available_units,
        requestedUnits: ccoResponse.results.requested_units,
        unavailableUnits: ccoResponse.results.unavailable_units
      }
    });
    const outfrontRequests = await get(`/api/v1/external_api_results/${campaignToken}/${this.outfrontId}`);
    this.setState({
      outfrontRequests: {
        availableUnits: outfrontRequests.results.available_units,
        requestedUnits: outfrontRequests.results.requested_units,
        unavailableUnits: outfrontRequests.results.unavailable_units
      }
    });

    const lamarRequests = await get(`/api/v1/external_api_results/${campaignToken}/${this.lamarId}`);

    this.setState({
      lamarRequests: {
        availableUnits: lamarRequests.results.available_units,
        requestedUnits: lamarRequests.results.requested_units,
        unavailableUnits: lamarRequests.results.unavailable_units
      }
    });
  }

  /**
   * @param {number} supplierId
   */
  renderButtonGroup(supplierId) {
    return (
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-default dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ marginRight: '10px' }}
        >
          Actions <span className="caret" />
        </button>
        <ul className="dropdown-menu dropdown-menu-right">
          <li>
            <button
              className="download"
              data-action="click->external-api-task-download#call"
              data-supplier-id={supplierId}
              data-campaign-token={this.props.campaign.token}
            >
              Download units added via API
            </button>
          </li>
        </ul>
      </div>
    );
  }

  renderClearChannelSection() {
    return (
      <div className="row">
        <div className="campaign_requests col-xs-12">
          <div>
            <h5>Clear Channel</h5>
            <div className="campaign_request clearfix">
              <div className="col-xs-12 todo_card todo_completed">
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Total requested</small>
                    {this.state.ccoRequests.requestedUnits}
                  </div>
                </div>
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Available units</small>
                    {this.state.ccoRequests.availableUnits}
                  </div>
                </div>
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Unavailable units</small>
                    {this.state.ccoRequests.unavailableUnits}
                  </div>
                </div>
                {this.renderButtonGroup(this.ccoId)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLamarSection() {
    return (
      <div className="row">
        <div className="campaign_requests col-xs-12">
          <div>
            <h5>Lamar</h5>
            <div className="campaign_request clearfix">
              <div className="col-xs-12 todo_card todo_completed">
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Total requested</small>
                    {this.state.lamarRequests.requestedUnits}
                  </div>
                </div>
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Available units</small>
                    {this.state.lamarRequests.availableUnits}
                  </div>
                </div>
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Unavailable units</small>
                    {this.state.lamarRequests.unavailableUnits}
                  </div>
                </div>
                {this.renderButtonGroup(this.lamarId)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOutfrontSection() {
    return (
      <div className="row">
        <div className="campaign_requests col-xs-12">
          <div>
            <h5>Outfront</h5>
            <div className="campaign_request clearfix">
              <div className="col-xs-12 todo_card todo_completed">
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Total requested</small>
                    {this.state.outfrontRequests.requestedUnits}
                  </div>
                </div>
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Available units</small>
                    {this.state.outfrontRequests.availableUnits}
                  </div>
                </div>
                <div className="todo_completed_date col-xs-5">
                  <div className="col-md-8">
                    <small>Unavailable units</small>
                    {this.state.outfrontRequests.unavailableUnits}
                  </div>
                </div>
                {this.renderButtonGroup(this.outfrontId)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div data-controller="external-api-task-download">
        <div className="row" style={{ paddingTop: '15px' }}>
          <div className="new_h1 col-xs-9">External Api Results</div>
        </div>
        {this.renderClearChannelSection()}
        {this.renderOutfrontSection()}
        {this.renderLamarSection()}
      </div>
    );
  }
}
