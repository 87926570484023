import "./loading.css";
import React, { ReactElement } from "react";

export enum LoadingSize {
  Tiny = "9px",
  Small = "12px",
  Default = "16px",
  Medium = "20px",
  Big = "25px",
}

export interface LoadingProps {
  size?: LoadingSize;
}

const getLoadingSize = (size?: LoadingSize): LoadingSize => size || LoadingSize.Default;

export const BillboardLoading = ({ size }: LoadingProps): ReactElement => {
  const fontSize = getLoadingSize(size);
  return (
    <div className="ui-billboard-loading" style={{ fontSize }}>
      <div className="ui-billboard-loading__box">
        <div className="ui-billboard-loading__plane-border">
          <div className="ui-billboard-loading__plane"></div>
        </div>
        <div className="ui-billboard-loading__post"></div>
      </div>
    </div>
  );
};

export const Loading = ({ size }: LoadingProps): ReactElement => {
  const newSize = `calc(${getLoadingSize(size)} * 2)`;
  return <div className="ui-loading" style={{ width: newSize, height: newSize, zIndex: 10000 }}></div>;
};
