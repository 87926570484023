import React from 'react';
import DropzoneS3Uploader from './DropzoneS3Uploader';
import { Line } from 'rc-progress';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

const UploadButton = createClass({
  propTypes: {
    handleFileError: PropTypes.func,
    customDropzoneProps: PropTypes.object,
    onComplete: PropTypes.func.isRequired,
    onDrop: PropTypes.func,
    onProgress: PropTypes.func
  },

  getInitialState() {
    return {
      skipProgress: this.props.skipProgress || false
    };
  },

  handleFinishedUpload(upload) {
    const file = upload.file;
    file.url = `https://${AppConfig.s3Bucket}.s3.amazonaws.com/${upload.path}`;
    this.props.onComplete(upload, file);
    this.setState({ progress: null });
  },

  onProgress(progress, textState, file) {
    this.props.onProgress && this.props.onProgress(progress);
    this.setState({ progress });
  },

  onDrop(files, rejected_files) {
    if(Array.isArray(rejected_files) && rejected_files.length > 0) {
      const errors = rejected_files.map(file => `File ${file.name} was rejected, check file type and file size and try again.`);
      this.props.handleFileError && this.props.handleFileError(errors);
    }
    this.props.onDrop && this.props.onDrop();
    this.setState({ progress: 0 });
  },

  renderProgress() {
    const strokeColor = this.props.progressBarColor || "#44ac6b";
    return (<div style={{ width: '100%', float: 'left', height: 25, display: "inline" }}>
      <Line percent={this.state.progress} strokeWidth="4" strokeColor={strokeColor} />
    </div>);
  },

  handleProgress() {
    const { skipProgress, progress } = this.state;
    if(skipProgress) { return this.props.children; }
    if(progress) { return this.renderProgress(); }
    return this.props.children ? this.props.children : this.renderUploadButton();
  },

  renderUploadButton() {
    const buttonStyling = (this.props.type === 'button') ? "btn btn-default btn-green-fill btn-bold btn-lg" : "btn btn-default btn-green";
    const uploadText = this.props.text || 'Upload';
    return (
      <button className={buttonStyling}>{uploadText}</button>
    );
  },

  render() {
    const time = (new Date()).getTime();
    const customDropzoneProps = this.props.customDropzoneProps || {};
    const directory = this.props.directory ? this.props.directory : 'user_uploads'
    const uploaderProps = {
      style: { cursor: 'pointer' },
      maxFileSize: 1024 * 1024 * 1024, //1GB
      s3Url: `https://${AppConfig.s3Bucket}.s3.amazonaws.com/${directory}/${time}`,
      server: '',
      signingUrlQueryParams: { time },
    };

    return (
    <span style={this.props.customStyles}>
      <DropzoneS3Uploader
        type="span"
        onDrop={this.onDrop}
        onProgress={this.onProgress}
        isPublic={this.props.false}
        directory={this.props.directory}
        onFinish={this.handleFinishedUpload}
        {...uploaderProps}
        customDropzoneProps={customDropzoneProps}
        activeStyle={{ backgroundColor: "#eee" }}
        multiple={true}
      >
        {this.handleProgress()}
      </DropzoneS3Uploader>
    </span>);
  },
});

export default UploadButton;
