import * as React from 'react';
import { connect } from 'react-redux';
import { fetchProfileStrength } from './actions';
import { ProgressBar } from 'react-bootstrap';
import CountUp from 'react-countup';
import Tooltip from 'rc-tooltip';
const ASSETS = (window as any).ASSETS;

class ProfileCompleteness extends React.Component<any, any> {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier)
    }
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  fetchData(supplier) {
    const { fetchProfileStrength } = this.props;
    fetchProfileStrength(supplier);
  }

  strengthDetails() {
    const { profile_strength } = this.props;
    if (profile_strength) {
      const { overall_score } = profile_strength
      const levels = {
        beginner: '#dc3545',
        intermediate: '#ffc107',
        advanced: '#4A90E2',
        complete: '#44AC6B'
      };
      if (overall_score <= 25) {
        return { name: 'beginner', color: '#dc3545'};
      } else if (overall_score > 25 && overall_score <= 65) {
        return { name: 'intermediate', color: '#ffc107'};
      } else if (overall_score > 65 && overall_score <= 85) {
        return { name: 'advanced', color: '#4A90E2'};
      } else {
        return { name: 'complete', color: '#44AC6B'};
      }
    }
  }

  scheduleDemo() {
    window.open(
      "https://calendly.com/katie-adquick/30-minutes",
      "_blank"
    );
  }

  renderTooltipText() {
    const profile_strength = this.props.profile_strength || {};
    const without_images = profile_strength.without_images || 0;
    const without_price = profile_strength.without_price || 0;
    const without_impressions = profile_strength.without_impressions || 0;

    return (
      <div id="profile_completeness_tooltip">
        {(!!!profile_strength.avails ) && <div>
          <span className="tooltip-item">
            Unit availability hasn't been updated recently.
          </span>
          <br></br>
        </div>}
        {without_images > 0 && <div>
          <span className="tooltip-item">
            {without_images} {without_images === 1 ? 'unit is' : 'units are'} missing images.
          </span>
          <br></br>
        </div>}
        {without_price > 0 && <div>
          <span className="tooltip-item">
            {without_price} {without_price === 1 ? 'unit is' : 'units are'} missing pricing.
          </span>
          <br></br>
        </div>}
        {without_impressions > 0 && <div>
          <span className="tooltip-item">
            {without_impressions} {without_impressions === 1 ? 'unit is' : 'units are'} missing impressions.
          </span>
          <br></br>
        </div>}
        {((without_impressions + without_price + without_images) === 0 && profile_strength.avails) &&
          <span className="tooltip-item">
            <i className="fa fa-check-circle-o" aria-hidden="true"></i> 100% Complete
          </span>
        }
      </div>
    )
  }

  renderProgressBar(overall_score) {
    return (
      <ProgressBar
        now={overall_score === 0 ? 1 : overall_score}
        style={{
          height: 8,
          backgroundColor: '#EEEEEE'
        }}
      />
    )
  }

  renderLoadingSpinner() {
    return (
      <div className="strength-loading">
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    )
  }

  renderRightCard() {
    const { currentUser } = this.props;
    const header = currentUser.is_managed_supplier ?
      'Want to improve your score?' :
      'The AdQuick Guarantee';
    const explainer = currentUser.is_managed_supplier ?
      'Media owners with higher profile completedness have a higher chance of booking campaigns on AdQuick.' :
      'On average, Managed Vendors see an 11.3% increase in bookings on AdQuick. Become a Managed Vendor today.';

    return (
      <div className="card right">
        <div className="improve-header">
          {header}
        </div>
        <div className="improve-explainer">
          {explainer}
        </div>
        <button
          className="demo-button"
          onClick={this.scheduleDemo}
        >
          Schedule a demo call
        </button>
      </div>
    )
  }

  render() {
    const { loading_profile_strength } = this.props;
    const profile_strength = this.props.profile_strength || {};
    const overall_score = profile_strength.overall_score || 0;
    const strength_details = this.strengthDetails();

    return (
      <div id="profile_completeness">
        <div className="header">Profile Completeness</div>
        <div className="row" style={{marginLeft: 0, marginRight:0}}>
          <div className="col-md-8">
            <div className="card left">
              <div className="strength">
                Profile strength:
                {!!!loading_profile_strength && strength_details &&
                  <span className="strength-amount">{strength_details.name}</span>
                }
                {loading_profile_strength && this.renderLoadingSpinner()}
                {!!!loading_profile_strength && <span className={`strength-percent ${strength_details ? strength_details.name : 'beginner'}`}>
                  <CountUp end={overall_score} />%
                </span>}
              </div>
              <div className={`strength-bar-area ${strength_details ? strength_details.name : 'beginner'}`}>
                <div>
                  <Tooltip
                    placement="bottom"
                    overlay={this.renderTooltipText()}
                    overlayStyle={{
                      opacity: 1
                    }}
                    arrowContent={
                      <div className="rc-tooltip-arrow-inner"></div>
                    }
                  >
                    {this.renderProgressBar(overall_score)}
                  </Tooltip>
                </div>
                <div className="split split-1">
                </div>
                <div className="split split-2">
                </div>
                <div className="split split-3">
                </div>
                <div className="end-logo-area">
                  <img
                    className="end-mini-logo"
                    src={ASSETS['mini-logo']}
                  />
                </div>
                <div
                  className={`dot ${strength_details ? strength_details.name : 'beginner'}`}
                  style={{left: `${overall_score === 0 ? 0 : overall_score - 2}%`}}
                >
                  <img
                    className="mini-logo"
                    src={ASSETS['white-mini-logo']}
                  />
                </div>
              </div>
              <div className="text-blurb">
                see how your profile could increase your revenue
              </div>
              <div className="secondary-text-blurb">
                Media owners with a higher score increase their likelihood of booking
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {this.renderRightCard()}
          </div>
        </div>
      </div>
    );
  }
};

export default connect(
  ({
    // @ts-ignore
    currentUser,
    // @ts-ignore
    vendor_dashboard: {
      profile_strength,
      selected_supplier,
      loading_profile_strength
    }
  }) => ({
    profile_strength,
    selected_supplier,
    loading_profile_strength,
    currentUser
   }), {
     fetchProfileStrength
   }
)(ProfileCompleteness);
