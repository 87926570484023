import { LOAD_MEDIA_TYPES } from "../actions/media_type_actions";
import { TMediaType } from "../models/media_type";

interface Action {type: string; payload: any; }

export default(state: TMediaType[], action: Action): TMediaType[] => {
  switch (action.type) {
    case LOAD_MEDIA_TYPES: return action.payload.media_types;
    default: return state || [];
  }
};
