import React, { Component } from 'react';
import Card from './Card';

class UnitsGroup extends Component {

  renderUnits(){
    const {
      units,
      campaign_permissions,
      campaign,
      onUnitCardClick,
      activeUnitId,
      indexOffset,
      hide_unit_prices,
    } = this.props;

    return (
      units.map((unit, index) => {
        const idx = indexOffset + index;
        return <Card
          key={idx}
          idx={idx}
          unit={unit}
          campaign_permissions={campaign_permissions}
          campaign={campaign}
          onFavoriteUnit={this.props.onFavoriteUnit}
          onUnitCardClick={onUnitCardClick}
          activeUnitId={activeUnitId}
          hide_unit_prices={hide_unit_prices}
        />
      })
    )
  }

  render(){
    const { title } = this.props;
    return(
      <div className="campaign_catalog_group">
        <div className="row">
          <div className="col-md-12">
            <h4>{title}</h4>
          </div>
        </div>
        <div>
          <div className="cards_list">
            {this.renderUnits()}
          </div>
        </div>
      </div>
    )
  }
}

export default UnitsGroup;
