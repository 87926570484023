import React from 'react';
import { browserHistory } from 'react-router-legacy';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore'
import GlobalActions from '../../actions/GlobalActions'
import GlobalStore from '../../stores/GlobalStore'
import { formatMoney } from 'accounting'
import moment from 'moment'
import { put, get } from '../../utils/api';
import Loading from '../Loading';

const Company = createClass({
  displayName: 'Company',

  getInitialState() {
    return {
      company: null,
      domain: '',
      connection_company: ''
    }
  },

  componentDidMount() {
    this.fetchCompany()

    this.unsubscribeList = [
      GlobalStore.listen(this.onGlobalChange),
    ];
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  onGlobalChange(eventName) {
    if (eventName == "email_sent") {
      this.fetchCompany()
    }
  },

  fetchCompany() {
    $.get(`/api/v1/businesses/${this.props.companyId}`).then(company => this.setState({ company }))
  },

  async fetchCompanies(query) {
    const url = `/api/v1/businesses?q=${encodeURI(query)}`
    const companies = await get(url)
    return companies
  },

  askForIntro(connection) {
    GlobalActions.openPopup('compose_email', {
      email: connection.connector_email,
      company: connection.connected_to_company,
      first_degree_full_name: connection.connector_name,
      first_degree_first_name: connection.connector_first_name,
      first_degree_last_name: connection.connector_last_name,
      second_degree_full_name: connection.display_name,
      second_degree_first_name: connection.first_name,
      second_degree_last_name: connection.last_name,
      sendable_type: 'UserLinkedinConnection',
      sendable_id: connection.id
    })
  },

  composeToLinkedinProfile(linkedin_profile) {
    const { company } = this.state

    GlobalActions.openPopup('compose_email', {
      email: linkedin_profile.email,
      company: company.name,
      first_name: linkedin_profile.first_name,
      sendable_type: 'LinkedinProfile',
      sendable_id: linkedin_profile.id
    })
  },

  getEmail({ id, company_id }) {
    $.post('/api/v1/clearbit', {
      linkedin_profile_id: id,
      company_id: company_id || this.props.companyId
    }).then(response => {
      if (response.status == 200) {
        this.fetchCompany()
        GlobalActions.showMessage("Email found successfully!")
      } else {
        GlobalActions.showError("Could not find an email for this person")
      }
    })
      .catch(({ responseJSON }) => {
        GlobalActions.showError(responseJSON.error.message)
      })
  },

  redirectToProfile(identifier) {
    window.open(`https://linkedin.com/in/${identifier}`, '_blank');
  },

  renderLinkedinProfiles(profiles) {
    return (
      <table className="table" style={{ marginBottom: 0 }}>
        <thead>
          <tr style={{ fontWeight: 'bold' }}>
            <td>Name</td>
            <td>Title</td>
            <td>Email</td>
            <td width="100">Last Email At</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {profiles.map(linkedin_profile => <tr key={linkedin_profile.id}>
            <td style={{ cursor: 'pointer' }} onClick={() => this.redirectToProfile(linkedin_profile.identifier)}>{linkedin_profile.display_name}</td>
            <td>{linkedin_profile.title}</td>
            <td>{
              linkedin_profile.email ? <a onClick={(e) => this.openEditModal({id: linkedin_profile.id, name: linkedin_profile.display_name}, 'text')}>{linkedin_profile.email}</a> :
                <div>
                  <a onClick={(e) => this.openEditModal({id: linkedin_profile.id, name: linkedin_profile.display_name}, 'text')}>Define</a>
                  <button style={{ marginLeft: '10px' }} className="btn btn-primary btn-xs" onClick={() => this.getEmail(linkedin_profile)}>Clearbit</button>
                </div>
            }</td>
            <td>{linkedin_profile.emailed_at}</td>
            <td>
              {linkedin_profile.email &&
                <a href="#" onClick={() => this.composeToLinkedinProfile(linkedin_profile)}>Compose</a>
              }
            </td>
          </tr>)}
        </tbody>
      </table>
    )
  },

  openEditModal({ id, name }, inputType) {
    const type = {
      'select': {
        label: 'Companies',
        attribute: 'company_id',
        options: this.state.companies,
        onInputChange: this.fetchCompanies
      },
      'text': {
        label: 'Email',
        attribute: 'email'
      }
    }

    GlobalActions.openPopup('edit_email', {
      id,
      name,
      update: this.updateProfile,
      label: type[inputType].label,
      attribute: type[inputType].attribute,
      [inputType]: type[inputType]
    })
  },

  async updateProfile(id, payload) {
    try {
      await put(`/admin/linkedin_connections/${id}`, payload)
      this.fetchCompany()
    } catch (error) { }
  },

  connectedToParams({ connected_to_id, display_name, connected_to_company_id }) {
    return { id: connected_to_id, name: display_name, company_id: connected_to_company_id }
  },

  connectedByParams({ connector_id, connector_first_name, connector_company_id }) {
    return { id: connector_id, name: connector_first_name, company_id: connector_company_id}
  },

  renderConnections(connections) {
    return (
      <table className="table" style={{ marginBottom: 0 }}>
        <thead>
          <tr style={{ fontWeight: 'bold' }}>
            <td width="150">User</td>
            <td width="150">Connector</td>
            <td>Email</td>
            <td>Company</td>
            <td>Name</td>
            <td>Title</td>
            <td>Send email</td>
          </tr>
        </thead>
        <tbody>
          {connections.map(connection => <tr key={connection.id}>
            <td>{connection.user_name}</td>
            <td style={{ cursor: 'pointer' }} onClick={() => this.redirectToProfile(connection.connector_identifier)}>{connection.connector_name}</td>
            <td>{
              connection.connector_email ? <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'text')}>{connection.connector_email}</a> :
                <div>
                  <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'text')}>Define</a>
                  <button style={{ marginLeft: '10px' }} className="btn btn-primary btn-xs" onClick={() => this.getEmail(this.connectedByParams(connection))}>Clearbit</button>
                </div>
            }</td>
            <td>{connection.connector_company ? <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'select')}>{connection.connector_company}</a> : <a onClick={(e) => this.openEditModal(this.connectedByParams(connection), 'select')}>Define</a>}</td>
            <td style={{ cursor: 'pointer' }} onClick={() => this.redirectToProfile(connection.identifier)}>{connection.first_name} {connection.last_name}</td>
            <td>{connection.title}</td>
            <td>{connection.connector_email ? <a onClick={() => this.askForIntro(connection)}>Compose</a> : 'Compose'}</td>
          </tr>)}
        </tbody>
      </table>
    )
  },

  async updateDomain() {
    const { company, domain } = this.state
    try {
      await put(`/api/v1/businesses/${company.id}`, { domain })
      this.fetchCompany()
    } catch (error) { }
  },

  render() {
    const { company, domain } = this.state

    if (!company) {
      return <Loading/>
    }

    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <h1>{company.name}</h1>
          <div style={{ display: 'flex' }}>
            <input
              type="text"
              className="form-control"
              placeholder={company.domain || 'Company Email Domain'}
              style={{ width: '50%' }}
              onChange={e => this.setState({ domain: e.target.value })}
              value={domain}
            />
            <button style={{ marginLeft: '10px' }} className="btn btn-primary" onClick={this.updateDomain}>Update Email Domain</button>
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h4>Linkedin Connections</h4>
        </div>

        {this.renderConnections(company.connections)}

        <div style={{ marginTop: 20 }}>
          <h4>LinkedIn Profiles</h4>
          <span>(Updated Scraper 1/9/2019)</span>
        </div>

        {this.renderLinkedinProfiles(company.fixed_linkedin_profiles)}

        <h4>Uploaded Contacts</h4>

        {company.contacts.length == 0 ? "None" : ''}
        {company.contacts.length > 0 && <table className="table" style={{ marginBottom: 0 }}>
          <thead>
            <tr style={{ fontWeight: 'bold' }}>
              <td>User</td>
              <td>Contact Name</td>
              <td>Title</td>
              <td>Email</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {company.contacts.map(contact => <tr key={contact.id}>
              <td>{contact.user_name}</td>
              <td>{contact.display_name}</td>
              <td>{contact.title}</td>
              <td>{contact.email}</td>
              <td></td>
            </tr>)}
          </tbody>
        </table>}
      </div>
    )
  }
})

export default Company;
