import { connect } from "react-redux";
import { formatMoney } from "accounting";
import React, { Component } from "react";
import cs from "classnames";
import Tooltip from "rc-tooltip";

import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";
import { initial } from "lodash";

const POPUP_WIDTH = 275;

class PoiDistanceFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = Object.assign(
      {
        isExpanded: false,
        minPoiDistance: null, // last filtered by
        maxPoiDistance: null, // last filtered by
        minPoiDistanceLiveValue: "", // not-yet-applied value of input
        maxPoiDistanceLiveValue: "", // not-yet-applied value of input
      },
      this.props.initialValue,
    );
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, initialValue } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (initialValue.minPoiDistance !== prevProps.initialValue.minPoiDistance) { this.setState({ minPoiDistance: initialValue.minPoiDistance, minPoiDistanceLiveValue: initialValue.minPoiDistance })}
    if (initialValue.maxPoiDistance !== prevProps.initialValue.maxPoiDistance) { this.setState({ maxPoiDistance: initialValue.maxPoiDistance, maxPoiDistanceLiveValue: initialValue.maxPoiDistance })}
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onChange(event) {
    let minPoiDistanceLiveValue = this.minPoiDistanceInput.value;
    let maxPoiDistanceLiveValue = this.maxPoiDistanceInput.value;
    if (isNaN(parseFloat(minPoiDistanceLiveValue))) minPoiDistanceLiveValue = "";
    if (isNaN(parseFloat(maxPoiDistanceLiveValue))) maxPoiDistanceLiveValue = "";
    this.setState({ minPoiDistanceLiveValue, maxPoiDistanceLiveValue });
  }

  onFilter() {
    const { filterUnits, handleQueryParams } = this.props
    const filter = this.handlePoiDistanceFilter()
    _.defer(filterUnits, filter);
    handleQueryParams(filter);
  }

  handlePoiDistanceFilter() {
    const { currentUser } = this.props;
    const { minPoiDistanceLiveValue, maxPoiDistanceLiveValue } = this.state;

    const minPoiDistance = this.handlePriceValue(minPoiDistanceLiveValue);
    const maxPoiDistance = this.handlePriceValue(maxPoiDistanceLiveValue);

    this.setState({ minPoiDistance, maxPoiDistance, isExpanded: false });

    if (currentUser && currentUser.is_agency) {
      return { min_rate_card: minPoiDistance, max_rate_card: maxPoiDistance }
    }

    return { min_poi_distance: minPoiDistance, max_poi_distance: maxPoiDistance }
  }

  handlePriceValue(value) {
    if (isNaN(parseFloat(value))) { return null }
    return value
  }

  onClear() {
    this.setState(
      {
        minPoiDistance: null, // last filtered by
        maxPoiDistance: null, // last filtered by
        minPoiDistanceLiveValue: "", // not-yet-applied value of input
        maxPoiDistanceLiveValue: "", // not-yet-applied value of input
      },
      this.onFilter
    );
  }

  getButtonLabel() {
    const { minPoiDistance, maxPoiDistance } = this.state;
    const formattedMinPrice = minPoiDistance;
    const formattedMaxPrice = maxPoiDistance;
    if (!minPoiDistance && !maxPoiDistance) return "POI Distance";
    if (minPoiDistance && maxPoiDistance) return `${formattedMinPrice}–${formattedMaxPrice}`;
    if (minPoiDistance) return `${formattedMinPrice} miles+`;
    if (maxPoiDistance) return `Up to ${formattedMaxPrice} miles`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { minPoiDistanceLiveValue, maxPoiDistanceLiveValue } = this.state;
    return (
      <div className="filter_popup price_range_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>POI DISTANCE RANGE</h4>
        <div className="padded_content">
          <div className="range_inputs">
            <input
              type="number"
              min="0"
              value={minPoiDistanceLiveValue}
              onChange={this.onChange}
              placeholder="Min"
              ref={node => (this.minPoiDistanceInput = node)}
            />
            <input
              type="number"
              min="0"
              value={maxPoiDistanceLiveValue}
              onChange={this.onChange}
              placeholder="Max"
              ref={node => (this.maxPoiDistanceInput = node)}
            />
          </div>
          <p className="actions">
            <a className="clear hidden-xs" onClick={e => this.onClear()}>
              Clear
            </a>
            <a onClick={this.onFilter}>Apply</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive, isBrowseView} = this.props;
    const buttonLabel = this.getButtonLabel();
    if (!isExpanded && !isBrowseView) {
      return (
        <Tooltip
          placement="bottom"
          trigger={['hover']}
          overlayStyle={{ width: '200px', height: "200px"}}
          overlay={<span style={{ width: "fit-content" }}>Use filters to narrow down viewed units and easier/select plan inventory. </span>}
        >
          <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
            {buttonLabel}
            {isExpanded && this.renderPopup()}
          </li>
        </Tooltip>
      );
    } else {
      return (
        <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
          {buttonLabel}
          {isExpanded && this.renderPopup()}
        </li>
      );
    }
  }
}

export default connect(
  ({ currentUser }) => ({
    currentUser,
  }),
  { filterUnits },
)(PoiDistanceFilter);
