import cs from "classnames";
import React from "react";
import Async from "react-select/async";

import { isMobile } from "../../../utils/mobile";

const POPUP_WIDTH = 500;

export default class Market extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markets: [],
    };
    this.loadMarkets = this.loadMarkets.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.getButtonLabel = this.getButtonLabel.bind(this);
    this.getPopupPosition = this.getPopupPosition.bind(this);
    this.renderPopup = this.renderPopup.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  async loadMarkets(val) {
    if (val.length < 3) return [];
    const res = await $.get(`/api/v1/markets/search.json?query=${val}`);
    const { markets } = res.data;
    const market_data = JSON.parse(markets);
    market_data && market_data.length ? this.setState({ markets: market_data }) : this.setState({ markets: [] });
    return market_data;
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  getButtonLabel() {
    const filters = this.props.filters || {};
    const { market } = filters;
    if (!market || !market.length) return "Market";
    if (market.length == 1) return _.truncate(market[0].label, { length: 18 });
    if (market.length > 1) return `Market · ${market.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  onClear() {
    const { setFilter } = this.props;
    this.setState({ query: "" }, () => {
      setFilter("market", "");
    });
  }

  renderPopup() {
    const filters = this.props.filters || {};
    const { market } = filters;
    const { markets } = this.state;
    const { setFilter } = this.props;
    const clearButtonStyle = {
      color: "#999999",
      position: "relative",
      top: -32,
      float: "right",
      paddingRight: 12,
    };
    return (
      <div className="filter_popup market_popup" style={{ overflow: 'visible', [this.getPopupPosition()]: "-1px" }}>
        <h4>MARKET</h4>
        <a style={clearButtonStyle} onClick={this.onClear}>
          Clear
        </a>
        <div style={{ maxWidth: 380, marginLeft: 10 }}>
          <Async
            autoFocus="true"
            placeholder="Start typing to load markets"
            value={market}
            options={markets}
            isMulti
            loadOptions={this.loadMarkets}
            filterOptions={(options, filter, currentValues) => {
              return options;
            }}
            cacheOptions
            onChange={val => setFilter("market", val)}
          />
        </div>
      </div>
    );
  }

  render() {
    const filters = this.props.filters || {};
    const { market } = filters;
    const { isExpanded } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: (market && market.length > 0) || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}
