import * as React from 'react';

class GeoLocatedSurveys extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { old_analytics } = props
    const { survey_questions, survey_zip_codes, survey_demographics, survey_number_of_people, survey_budget } = old_analytics
    this.state = {
      survey_questions: {
        0: survey_questions[0] || '',
        1: survey_questions[1] || '',
        2: survey_questions[2] || '',
        3: survey_questions[3] || ''
      },
      survey_zip_codes: survey_zip_codes || '',
      survey_demographics: survey_demographics || '',
      survey_number_of_people: survey_number_of_people || '',
      survey_budget: survey_budget || ''
    }
    this.onSave = this.onSave.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onQuestionChange = this.onQuestionChange.bind(this)
  }

  componentDidMount() {
    this.props.scrollToTop()
  }

  onInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  renderQuestions() {
    const { survey_questions } = this.state
    const question_placeholders = {
      0: 'What is the first Real Estate company that comes to mind?',
      1: 'Select the companies you have heard of: CBRE, Century 21...',
      2: 'Additional Question',
      3: 'Additional Question'
    };

    return (
      <div>
        {Object.values(survey_questions).map((question, index) =>
          <div key={`survey-questions-${index}`} className="field">
            <label htmlFor="survey_questions">Question {index + 1}</label>
            <textarea
              className="form-control"
              name={`question-${index}`}
              defaultValue={survey_questions[index]}
              onChange={this.onQuestionChange}
              placeholder={question_placeholders[index]}
            />
          </div>
        )}
      </div>
    )
  }

  onQuestionChange(event) {
    const { target } = event
    const question = target.name.split('-')[1]
    const questions = {
      0: question === '0' ? target.value : this.state.survey_questions[0],
      1: question === '1' ? target.value : this.state.survey_questions[1],
      2: question === '2' ? target.value : this.state.survey_questions[2],
      3: question === '3' ? target.value : this.state.survey_questions[3]
    }
    this.setState({ survey_questions: questions })
  }

  async onSave() {
    const { survey_questions, survey_zip_codes, survey_demographics, survey_number_of_people, survey_budget  } = this.state
    const { oldSave, onSubmit, analytic, deleteStep, card } = this.props
    const payload = { survey_questions, survey_zip_codes, survey_demographics, survey_number_of_people, survey_budget }
    await oldSave(payload)
    await onSubmit(analytic)
    deleteStep(card.key)
  }

  render() {
    const { survey_zip_codes, survey_demographics, survey_number_of_people, survey_budget } = this.state
    return (
      <div className="analytics_custom_setup second_level">
        <div className="body_title">
          <h4>Brand Surveys</h4>
          <p>Enter up to 4 questions</p>
        </div>
        <div className="custom_body">
          {this.renderQuestions()}
        </div>
        <div className="body_button">
          <button className="primary-button" onClick={this.onSave}> Save</button>
        </div>
      </div>
    )
  }
}

export default GeoLocatedSurveys;