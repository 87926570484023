import React from "react";
import { Modal } from "react-bootstrap";

export default class ConfirmDeleteAttachmentModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onHide, attachment, onConfirm } = this.props;

    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Body>
          <div style={{ fontSize: 14 }}>
            Are you sure you want to delete{" "}
            <span style={{ color: "#212121", fontWeight: 500 }}>{attachment.filename}</span>?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ marginTop: 20 }}>
            <button style={{ margin: 10 }} className="btn btn-danger" onClick={() => onConfirm(attachment.id)}>
              Delete
            </button>
            <button style={{ margin: 10 }} className="btn btn-default" onClick={onHide}>
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
