import moment from "moment";
import React from "react";

import { get } from "../../../utils/api";

export default class MaintenanceLogs extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      maintenance_logs: [],
      log_page_count: 0,
      current_page: 1,
      loading: true,
    };
    this.fetchMaintenanceLogs = this.fetchMaintenanceLogs.bind(this);
  }

  componentDidMount() {
    this.fetchMaintenanceLogs();
  }

  async fetchMaintenanceLogs() {
    const { unit } = this.props;
    const { current_page } = this.state;
    const response = await get(`/api/v1/unit_maintenance_logs/${unit.id}?page=${current_page}`);
    this.setState({
      loading: false,
      maintenance_logs: response.maintenance_logs,
      log_page_count: response.total_pages,
    });
  }

  goToPage(page) {
    this.setState({ current_page: page, loading: true }, this.fetchMaintenanceLogs);
  }

  renderPagination() {
    const { log_page_count, current_page } = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          {Array.from(Array(log_page_count)).map((x, i) => {
            return (
              <div
                className="col-sm-1"
                style={{
                  margin: 5,
                  backgroundColor: 1 + i === current_page ? "rgb(225 225 225)" : "#ffffff",
                  padding: "5px 5px",
                  borderRadius: 5,
                  width: "fit-content",
                }}
                onClick={() => (1 + i === current_page ? {} : this.goToPage(i + 1))}
              >
                <a>{i + 1}</a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const style = this.props.style || {};
    const header_text_stype = {
      fontWeight: 600,
      fontSize: 13,
      color: "#555555",
    };
    const { maintenance_logs, loading } = this.state;

    if (maintenance_logs && maintenance_logs.length > 0) {
      return (
        <div style={{ ...style, background: "#F5F5F5", padding: "10px 25px", borderRadius: 8 }}>
          <div className="row" style={{ background: "#FFFFFF", borderRadius: 8, padding: 10 }}>
            <div style={header_text_stype} className="col-md-3">
              Status Change
            </div>
            <div style={header_text_stype} className="col-md-4">
              Maintenance Notes
            </div>
            <div style={header_text_stype} className="col-md-2">
              Change By
            </div>
            <div style={header_text_stype} className="col-md-3">
              Date / Time
            </div>
          </div>
          {maintenance_logs.map(log => {
            return (
              <div key={log.created_at} className="row" style={{ borderBottom: "solid 1px #ffffff", padding: 10 }}>
                <div className="col-md-3">
                  {log.status_from} -> {log.status_to}
                </div>
                <div className="col-md-4">
                  {log.fault_reason} - {log.note}
                </div>
                <div className="col-md-2">{log.user_name}</div>
                <div className="col-md-3">{moment(log.created_at).format("lll")}</div>
              </div>
            );
          })}
          {this.renderPagination()}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
