import React, { Component } from "react";
import {
  fetchPlaces,
  loadFavorites,
  savePlaces,
  saveAllPlaces,
  removePlaces,
  removeAllPlaces
} from "./actions";
import { connect } from "react-redux";

import PlacesStore from "../../stores/PlacesStore";
import Loading from "../Loading";

class PointsOfInterestListing extends Component {
  constructor() {
    super();
    this.state = {
      places: [],
      searched_places: [],
      query: "",
      radius: 8047,
    };
  }

  componentWillMount() {
    const campaign = this.getCampaign();
    this.props.loadFavorites({ campaign });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.loading) this.setState({ loading: false });
  }

  async search() {
    const { fetchPlaces, map } = this.props;
    const { query, radius } = this.state;
    const campaign = this.getCampaign();
    const { bounds } = map;
    const centerLat = (parseFloat(bounds[0][1]) + parseFloat(bounds[1][1])) / 2
    const centerLon = (parseFloat(bounds[0][0]) + parseFloat(bounds[1][0])) / 2

    if (query != "") {
      await fetchPlaces({
        query,
        lat: centerLat,
        lon: centerLon,
        radius,
        campaign
      });
      this.setState({ loading: false });
    }
  }

  getCampaign() {
    return this.props.campaign_token;
  }

  componentDidMount() {
    return;
  }

  componentWillUnmount() {
    if (this.state.places_updated) PlacesStore.triggerUpdate(this.props.places)
  }

  onSearch(e) {
    e.preventDefault();
    this.setState({ places: [], loading: true }, this.search);
  }

  savePlace(place) {
    this.props.savePlaces([place], this.getCampaign());
    this.setState({ places_updated: true });
  }

  async removePlace(place) {
    await this.props.removePlaces([place.id], this.getCampaign());
    PlacesStore.trigger('places:updated')
    this.setState({ places_updated: true });
  }

  saveAllPlaces(e) {
    e.preventDefault;
    if (this.props.search_results.length > 0) {
      const campaign_id = this.getCampaign();
      this.setState({ loading: true }, () => {
        this.props.saveAllPlaces(campaign_id);
        this.setState({ places_updated: true });
      });
    }
  }

  removeAllPlaces(e) {
    e.preventDefault;
    if (this.props.places.length > 0) {
      const campaign_id = this.getCampaign();
      this.setState({loading: true}, async () => {
        await this.props.removeAllPlaces(campaign_id);
        PlacesStore.trigger('places:updated')
        this.setState({places_updated: true});
      });
    }
  }

  renderSearchInputs() {
    const { radius } = this.state;
    const options = [
      { value: 1609, label: "1 mi" },
      { value: 3219, label: "2 mi" },
      { value: 8047, label: "5 mi" },
      { value: 16093, label: "10 mi" },
      { value: 32186, label: "20 mi" },
      { value: 50000, label: "30 mi" },
    ];

    return (
      <form className="form-inline" onSubmit={e => this.onSearch(e)}>
        <div className="col-xs-7">
          <input
            type="text"
            className="form-control col-xs-3"
            placeholder="e.g. schools, gyms, Starbucks"
            style={{ width: "300px" }}
            onChange={e => this.setState({ query: e.target.value })}
          />
        </div>
        <div className="col-xs-2 poi_radius_btn">
          <select
            className="form-control"
            onChange={(e) => this.setState({ radius: e.target.value })}
            value={radius}
          >
            {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
          </select>
        </div>
        <div className="col-xs-2">
          <input type="submit" className="btn btn-primary" value="Search" />
        </div>
      </form>
    );
  }

  renderCampaignPoiItems(pois) {
    return pois.map(poi => (
      <div key={poi.id} onClick={() => this.removePlace(poi)}>
        <i className="fa fa-minus-square poi_icon"/>
        <span className="poi_label">{poi.name}</span>
        <div className="poi_address">{poi.address}</div>
      </div>
    ));
  }

  renderSearchResultItems(search_result) {
    return search_result.map(poi => (
        <div key={poi.id} onClick={() => this.savePlace(poi)}>
          <i className="fa fa-plus-square poi_icon"/>
          <span className="poi_label">{poi.name}</span>
          <div className="poi_address">{poi.address}</div>
        </div>
    ));
  }

  render() {
    const { places, search_results, max_results_reached } = this.props;
    const { loading } = this.state;
    // if (!!!places) return <div />;

    return loading ? (
      <Loading/>
    ) : (
      <div className="poi_form">
        <div className="row col-xs-12 poi_search_inputs">{this.renderSearchInputs()}</div>
        { max_results_reached &&
          <div className="row col-xs-12">
            <p className="alert alert-warning">Max number of results reached. Refine your search to see more.</p>
          </div>
        }
        <div className="row poi_header_row col-xs-12">
          <div className="col-xs-6 poi_results_column" style={{ fontSize: 17, fontWeight: 600 }}>
            <span>
              Results{" "}
              <a className="poi_link" onClick={e => this.saveAllPlaces(e)}>
                Add all
              </a>
            </span>
          </div>
          <div
            className="col-xs-6 poi_added_column"
            style={{ fontSize: 17, fontWeight: 600 }}
          >
            <span>
              Added{" "}
              <a className="poi_link" onClick={e => this.removeAllPlaces(e)}>
                Remove all
              </a>
            </span>
          </div>
        </div>
        <div className="row col-xs-12 poi_search_results">
          <div className="col-xs-6 poi_results_column">
            {this.renderSearchResultItems(search_results)}
          </div>
          <div className="col-xs-6 poi_added_column">
            {this.renderCampaignPoiItems(places)}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ campaign, places_ui, map }) => ({
    map,
    campaign_token: campaign.campaign.token,
    places: places_ui ? places_ui.places : [],
    search_results: places_ui ? places_ui.search_results : [],
    max_results_reached: places_ui ? places_ui.max_results_reached : false,
  }),
  {
    fetchPlaces,
    loadFavorites,
    savePlaces,
    saveAllPlaces,
    removePlaces,
    removeAllPlaces
  }
)(PointsOfInterestListing);
