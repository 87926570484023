import React from 'react'
import createClass from 'create-react-class'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'

export default createClass({
  displayName: 'Single Date Picker',

  getInitialState() {
    return {
      date: this.props.date,
      focused: false,
      showClearDate: this.props.showClearDate,
    }
  },

  componentWillReceiveProps(nextProps) {
    const { date } = nextProps
    this.setState({ date })
  },

  onDateChange(date) {
    this.setState({ date, focused: false }, this.props.onDateChange({ date }))
  },

  render() {
    const { date, focused, showClearDate } = this.state
    const { showDefaultInputIcon, placeholder, small, block, isOutsideRange } = this.props;

    return (
      <SingleDatePicker
        date={date} // momentPropTypes.momentObj or null,
        id="date_input" // PropTypes.string.isRequired,
        onDateChange={this.onDateChange} // PropTypes.func.isRequired,
        focused={focused}
        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired,
        showClearDate={showClearDate}
        showDefaultInputIcon={!!showDefaultInputIcon}
        placeholder={placeholder}
        small={small}
        block={block}
        isOutsideRange={isOutsideRange}
      />
    )
  },
})
