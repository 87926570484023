import React, { PureComponent } from "react";
import moment from "moment";
import { ICampaignPermissions } from "../../../models/campaign";
import UploadPOPAction from "./UploadPOPAction";
import GlobalActions from "../../../actions/GlobalActions";
import GlobalStore from "../../../stores/GlobalStore";
import * as styleGen from "./styles";

interface IUploadPopWithPostedDateProps {
  unit?: any;
  units?: {}[];
  campaign_permissions: ICampaignPermissions;
  campaign_id: string;
  callback?: (response) => void;
  buttonChild?: React.ReactNode;
  customStyle?: any;
  buttonStyle: any;
}

interface IUploadPopWithPostedDateState {
  posted_date: moment.Moment | null;
  submitted: boolean;
  cancelled: boolean;
  native_event: any;
  target: any;
}

class UploadPopWithPostedDate extends PureComponent<
  IUploadPopWithPostedDateProps,
  IUploadPopWithPostedDateState
> {
  unsubscribeList: Array<Function>;

  constructor(props) {
    super(props);
    this.state = {
      posted_date: null,
      submitted: false,
      cancelled: false,
      native_event: null,
      target: null
    };
    this.onGlobalChange = this.onGlobalChange.bind(this);
  }

  public componentDidMount() {
    this.unsubscribeList = [GlobalStore.listen(this.onGlobalChange)];
  }

  public componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  private onGlobalChange(event_name) {
    const canSubmitFiles = !!this.state.posted_date && !this.state.cancelled;
    if (event_name == "popup:close" && canSubmitFiles) {
      return this.setState(
        { submitted: true },
        this.dispatchOpenFileForUploadEvent
      );
    }
    if (event_name == "popup:close" && !canSubmitFiles) {
      GlobalActions.showError("Upload cancelled.");
    }
  }

  private dispatchOpenFileForUploadEvent() {
    const { target, native_event } = this.state;
    const clonedNativeEvent = new MouseEvent("click", native_event);
    target && target.dispatchEvent(clonedNativeEvent);
    return this.setState({ submitted: false });
  }

  render() {
    const {
      unit,
      units,
      campaign_id,
      callback,
      customStyle,
      buttonStyle,
      buttonChild
    } = this.props;
    return (
      <UploadPOPAction
        progressBarColor={styleGen.uploadButton().background}
        unit={unit}
        units={units}
        campaign_id={campaign_id}
        callback={callback}
        customStyles={customStyle}
        posted_date={this.state.posted_date}
      >
        <button
          className="btn btn-default"
          style={buttonStyle}
          onClick={e => this.handleClick(e)}
        >
          {buttonChild ? buttonChild : "POP"}
        </button>
      </UploadPOPAction>
    );
  }

  private handleClick(e) {
    const { target, nativeEvent } = e;
    const canSkipModal = this.isPostedDateSelected() && !this.state.cancelled;
    if (canSkipModal) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    this.setState({ native_event: nativeEvent, target, cancelled: false });
    const onDatePick = this.onDatePick.bind(this);
    const onCancelUpload = this.onCancelUpload.bind(this);
    GlobalActions.openPopup("pick_calendar", {
      onDatePick,
      onCancelUpload
    });
  }

  private isPostedDateSelected() {
    const { submitted, posted_date } = this.state;
    return submitted && !!posted_date;
  }

  private onDatePick(posted_date) {
    this.setState({ posted_date });
  }

  private onCancelUpload() {
    this.setState({ cancelled: true });
  }
}

export default UploadPopWithPostedDate;
