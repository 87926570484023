import React, { PureComponent } from 'react'

class CampaignWarning extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { message, tooltip, style } = this.props
    return (
      <div className={`campaign-warning ${style}`}>
        <i className="exclamation fa fa-exclamation-triangle"></i> { message }
        { tooltip &&
          <div className="warning-tooltip">
            <div className="triangle"></div>
            <span>{ tooltip }</span>
          </div>
        }
      </div>
    )
  }
}

export default CampaignWarning