export interface ISupplierShippingAddress {
  [key: number]: Array<TSupplierAddress>
}

export type TSupplierAddress = {
  id: number;
  supplier_id: number;
  slug: string | null;
  address_line: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  country: string | null;
  attention: string | null;
  email: string | null;
  phone: string | null;
}

export type TSupplierAddressPayload = {
  supplier_id: number;
  shipping_addresses: TSupplierAddress;
}

export type TSupplierAddressAddPayload = {
  supplier_id: number;
  shipping_address: TSupplierAddress;
}

export function onLoadContacts(state: ISupplierShippingAddress, payload: TSupplierAddressPayload) {
  return {
    ...state,
    ...{ [payload.supplier_id]: payload.shipping_addresses as TSupplierAddress }
  }
}

export function onAddContact(state: ISupplierShippingAddress, payload: TSupplierAddressAddPayload) {
  const current_state = state[payload.supplier_id];
  return {
    ...state,
    ...{ [payload.supplier_id]: [...current_state, payload.shipping_address] }
  }
}
