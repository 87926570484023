import {
  FETCH_ACTIVITY_LOGS,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  RESTORE_CAMPAIGN_UNIT
} from './actions';

const initialState = {
  isFetching: false
}

export default (state = initialState, {type, payload}) => {
  switch(type) {
    case FETCH_ACTIVITY_LOGS:
      return {
        ...state,
        isFetching: true
      }
    case FETCH_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false
      }
    case RESTORE_CAMPAIGN_UNIT:
      return {
        ...state,
        activity_logs: mergeWithTodaysLogs(state, payload)
      }
    default: return state;
  }
}

function mergeWithTodaysLogs(state, payload) {
  // Puts newly created log (payload) at the beginning of TODAY's group
  return {
    ...state.activity_logs,
    ...{ TODAY: [payload.restored_activity_log, ...(state.activity_logs.TODAY || [])]}
  }
}
