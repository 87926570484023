import { formatNumber, unformat } from "accounting";
import Datetime from "react-datetime";
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import createClass from "create-react-class";
import moment from "moment";
import HowLongDropDown from "../../HowLongDropDown";

const  media_types = [
  { value: "all", label: "All" },
  { value:1, label:"Billboard" },
  { value:5, label:"Street Furniture" },
  { value:8, label:"Sports Venues" },
  { value:9, label:"Alternative" },
  { value:10, label:"Wallscape" },
  { value:11, label:"Wildposting" },
  { value:12, label:"Bus" },
  { value:13, label:"Rail" },
  { value:14, label:"Airport" },
  { value:15, label:"Retail" }
]

export default createClass({
  displayName: "Details",

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  componentDidMount() {
    this.props.setAttributes({ media_types: this.state.media_types })
  },

  getInitialState() {
    if (AppConfig.advertiserGreyLabel.subdomain === "compass") {
      return { media_types: 5 }
    }
    if (AppConfig.advertiserGreyLabel.subdomain === "clearchannel") {
      return { media_types: 1 }
    }
    return { media_types }
  },

  onMediaTypeChange(option) {
    if (option !== "all") option = option.replace("all,", "");
    if (!option || option.endsWith("all")) option = "all";

    this.setState({ media_types: option },
      this.props.setAttributes({ media_types: option })
    );
  },

  renderOptions() {
    const advertiserGreyLabel = AppConfig.advertiserGreyLabel;
    const isCompass =  advertiserGreyLabel.subdomain === "compass"
    const isClearChannel =  advertiserGreyLabel.subdomain === "clearchannel"
    if (isCompass) {
      return [{ value:5, label:"Street Furniture" }]
    }
    if (isClearChannel) {
      return [{ value:1, label:"Billboard" }]
    }
    return media_types
  },

  render() {
    const { campaign, errors } = this.props
    const startDate = campaign.start_date ? moment.utc(campaign.start_date).format("LL") : "";
    const endDate = campaign.end_date ? moment.utc(campaign.end_date).format("LL") : "";
    const errorStyle = { color: "red" };
    const advertiserGreyLabel = AppConfig.advertiserGreyLabel;
    const isMacdonald = advertiserGreyLabel.subdomain === "macdonaldmedia";
    const isCompass = advertiserGreyLabel.subdomain === "compass";

    return (
      <div>
        <h4>
          <i className="fa fa-calendar" />
          <span>Campaign Details</span>
        </h4>
        <div className="section">
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name" style={errors.name && errorStyle}>Campaign Name</span>
              <input type="text"
                className="form-control"
                onChange={e => this.props.setAttributes({ name: e.target.value })}
              />
            </div>
          </div>
          {isCompass && <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name">Agent Name(s)</span>
              <input type="text"
                className="form-control"
                value={campaign.agent_names ? campaign.agent_names : ""}
                onChange={e => this.props.setAttributes({ agent_names: e.target.value })}
              />
            </div>
          </div>}
          {isMacdonald && <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name">Advertiser Name</span>
              <input type="text"
                className="form-control"
                value={campaign.advertiser_name ? campaign.advertiser_name : ""}
                onChange={e => this.props.setAttributes({ advertiser_name: e.target.value })}
              />
            </div>
          </div>}
          {isCompass && <div className="greylabel-brand-checkbox">
            <label>
              <input type="checkbox" style={{marginRight:5}} onChange={e => this.props.setAttributes({brand: e.target.checked})}/>
              <span>Brand</span>
            </label>
          </div>}
          <div className="row subsection">
            <div className="col-sm-4">
              <span className="section-name" style={errors && errors.start_date ? errorStyle : {}}>Start date*</span>
              <Datetime
                dateFormat="LL"
                timeFormat={false}
                closeOnSelect
                value={startDate}
                inputProps={{ placeholder: "Select start date" }}
                onChange={date => this.props.setAttributes({ start_date: (date && date.format && date.format("LL") || date) })}
              />
            </div>
            <div className="col-sm-4">
              <HowLongDropDown campaign={campaign} setAttributes={this.props.setAttributes} />
            </div>
            <div className="col-sm-4">
              <span className="section-name" style={errors && errors.end_date ? errorStyle : {}}>End date*</span>
              <Datetime
                dateFormat="LL"
                timeFormat={false}
                closeOnSelect
                value={endDate}
                inputProps={{ placeholder: "Select end date" }}
                onChange={date => this.props.setAttributes({ end_date: (date && date.format && date.format("LL") || date) })}
              />
            </div>
          </div>
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name">Additional notes on timing</span>
              <input type="text"
                className="form-control"
                value={campaign.timing_notes}
                placeholder="Are these dates flexible?"
                onChange={e => this.props.setAttributes({ timing_notes: e.target.value })}
              />
            </div>
          </div>
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name" style={errors && errors.budget ? errorStyle : {}}>Budget</span>
              <span className="input-group">
                <span className="input-group-addon">$</span>
                <input type="text"
                  className="form-control"
                  value={campaign.budget || ""}
                  onChange={e => {
                    const budget = formatNumber(unformat(e.target.value));
                    this.props.setAttributes({ budget });
                  }}
                />
              </span>
            </div>
          </div>
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name">Media Types</span>
              <Select
                name="media_types"
                options={this.renderOptions()}
                value={this.state.media_types}
                onChange={this.onMediaTypeChange}
                isMulti
                placeholder="Choose your media types"
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
});
