import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { loadUnitScreenSubtypes } from "../../actions/media_type_actions";
import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";

const POPUP_WIDTH = 220;

class UnitScreenSubtypeFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = {
      isExpanded: false,
      selectedUnitScreenSubtypes: this.selectedUnitScreenSubtypesFromInitialValue(),
    };
  }

  componentDidMount() {
    const { loadUnitScreenSubtypes, campaign } = this.props;
    const campaignId = campaign && campaign.campaignId;
    document.addEventListener("click", this.onToggle);
    loadUnitScreenSubtypes(campaignId);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, unit_screen_subtypes, initialValue, units, loadUnitScreenSubtypes, campaign } = this.props
    const campaignId = campaign && campaign.campaignId

    if (!isActive && prevProps.isActive) { this.onClear() }
    if (units.all_units.length !== prevProps.units.all_units.length) { loadUnitScreenSubtypes(campaignId) }
    if (initialValue && unit_screen_subtypes !== prevProps.unit_screen_subtypes) {
      this.setState({ selectedUnitScreenSubtypes: unit_screen_subtypes.filter(mt => initialValue.includes(mt.name)) });
    }
    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ selectedUnitScreenSubtypes: unit_screen_subtypes.filter(mt => initialValue.includes(mt.name)) });
    }
  }

  selectedUnitScreenSubtypesFromInitialValue() {
    // Because we only store a simple string for each selected media type on Redux,
    // here we convert from simple values to complex values:.
    // before: ['bus']
    // after:  [{name: 'Bus', value: 'bus', count: 5, id: 12}]
    const { unit_screen_subtypes, initialValue } = this.props;

    if (!unit_screen_subtypes.length || !initialValue) return [];
    return initialValue.map(v => {
      return unit_screen_subtypes.find(mt => mt.name == v);
    });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear() {
    this.setState({ selectedUnitScreenSubtypes: [] }, this.onFilter);
  }

  onUnitScreenSubtypeSelect(option) {
    const { selectedUnitScreenSubtypes } = this.state;
    if (selectedUnitScreenSubtypes.includes(option)) {
      this.setState(
        { selectedUnitScreenSubtypes: selectedUnitScreenSubtypes.filter(screen_subtype => screen_subtype.name !== option.name) },
        this.onFilter,
      );
    } else {
      this.setState({ selectedUnitScreenSubtypes: selectedUnitScreenSubtypes.concat([option]) }, this.onFilter);
    }
  }

  onFilter() {
    const { selectedUnitScreenSubtypes } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    _.defer(filterUnits, { unit_screen_subtype: selectedUnitScreenSubtypes.map(type => type.name) });
    handleQueryParams({ 'unit_screen_subtype[]': selectedUnitScreenSubtypes.map(type => type.name) })
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedUnitScreenSubtypes } = this.state;
    if (!selectedUnitScreenSubtypes.length) return "Screen Subtype";
    if (selectedUnitScreenSubtypes.length == 1) return `${selectedUnitScreenSubtypes[0].name} only`;
    if (selectedUnitScreenSubtypes.length > 1) return `Screen Subtypes · ${selectedUnitScreenSubtypes.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { selectedUnitScreenSubtypes } = this.state;
    const { unit_screen_subtypes, campaign, isBrowseView } = this.props;
    const [present, notPresent] = unit_screen_subtypes.reduce(
      (result, mt) => {
        result[mt.count > 0 ? 0 : 1].push(mt);
        return result;
      },
      [[], []],
    );
    const showNotPresent = _.get(campaign, "permissions.can_add_units_near_pois", false) && notPresent.length;
    return (
      <div className="filter_popup unit_screen_subtype_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>SCREEN SUBTYPE</h4>
        <ul>
          {present.map(option => {
            const onClick = () => {
              this.onUnitScreenSubtypeSelect(option);
            };
            const isActive = selectedUnitScreenSubtypes.includes(option);
            return (
              <li key={option.name} onClick={onClick} className={cs({ active: isActive })}>
                {option.name}
                <i className="count">({option.count})</i>
                <i className="fa fa-check" />
              </li>
            );
          })}
          {showNotPresent && !isBrowseView && <li className="separator">Zero count</li>}
          {(showNotPresent || isBrowseView) &&
            notPresent.map(option => {
              const onClick = () => {
                this.onUnitScreenSubtypeSelect(option);
              };
              const isActive = selectedUnitScreenSubtypes.includes(option);
              return (
                <li key={option.name} onClick={onClick} className={cs({ secondary: !isBrowseView, active: isActive })}>
                  {option.name}
                  <i className="fa fa-check" />
                </li>
              );
            })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ campaign, unit_screen_subtypes, units }) => ({
  campaign,
  unit_screen_subtypes,
  units,
});

export default connect(mapStateToProps, { filterUnits, loadUnitScreenSubtypes })(UnitScreenSubtypeFilter);
