 import Reflux from 'reflux';
import moment from 'moment';
import momentRange from 'moment-range';
import UnitActions from '../actions/UnitActions';
import GlobalActions from '../actions/GlobalActions';
import MapStore from '../stores/MapStore';
import GlobalStore from '../stores/GlobalStore';
import { get, post, put } from '../utils/api';
import param from '../utils/objectToQuery';

const UNITS_URL = '/api/v1/units/';
const SUPPLIERS_URL = '/api/v1/suppliers'

export default Reflux.createStore({

  listenables: [UnitActions],

  init() {
    this.state = {
      units: AppConfig.preloadedUnits || [],
      selectedUnits: [],
      activeUnit: AppConfig.defaultUnit ? AppConfig.defaultUnit.id : null,
      activeUnitObject: AppConfig.defaultUnit,
      loading: true,
      filters: this.defaultFilters(),
      sortBy: 'week_total_cost',
    };
  },

  setState(newState) {
    this.state = Object.assign({}, this.state, newState)
  },

  getInitialState() {
    return this.state.selectedUnits;
  },

  defaultSort() {
    return 'week_total_cost'
  },

  defaultFilters() {
    return {
      campaign: '',
      type: 'billboard,street_furniture,transit,retail,alternative',
      tag:  null,
      nearby: null,
      nearby_distance: 0.5,
      date: {
        startDate: null,
        endDate: null,
        howLong: '',
      },
      superuser: false,
    }
  },

  onSendRequest(params, triggerActions) {
    return post('/api/v1/campaign_requests', params)
      .then(res  => { if (triggerActions) this.trigger('inventory:success') })
      .catch(res => { if (triggerActions) this.trigger('inventory:error')   });
  },

  onSetActiveUnit(id) {
    if (this.state.activeUnit != null && id == null) {
      if (window && window.location && window.location.pathname.startsWith('/units/')) {
        let url = '/search' + window.location.search

        if (typeof history.replaceState === "undefined") {
          location.href = url
        } else {
          history.replaceState({query: url}, null, url);
        }
      }
    }

    this.state.activeUnit = id;
    this.trigger('units:activeUnit');
  },

  onSetHoverUnit(id) {
    this.state.hoverUnit = id;
    this.trigger('units:hoverUnit');
  },

  onRemoveHoverUnit(id) {
    this.state.hoverUnit = null;
    this.trigger('units:hoverOutUnit');
  },

  clearLegacyUnitsShareFilter() {
    this.state.filters.units = null
  },

  generateParams(params = {}) {
    return {
      lat: params.lat || MapStore.getState().center.lat(),
      lng: params.lng || MapStore.getState().center.lng(),
      dist: params.radius || MapStore.getState().radius,
      locationName: params.locationName || MapStore.getLocation(),
      zoom: params.zoom || MapStore.getState().zoom,
      filters: this.state.filters,
      sortBy: this.state.sortBy
    }
  },

  generateUrl(params = {}) {
    const lat = params.lat || MapStore.getState().center.lat();
    const lng = params.lng || MapStore.getState().center.lng();
    const dist = params.radius || MapStore.getState().radius
    const locationName = params.locationName || MapStore.getLocation()
    const zoom = params.zoom || MapStore.getState().zoom

    const urlParams = {
      filters: this.state.filters,
      sortBy: this.state.sortBy,
      locationName: locationName,
      zoom: zoom,
    };

    let url = `?lat=${lat}&lng=${lng}&dist=${dist}`;
    url += '&' + param(urlParams);

    return url
  },

  loadUnit(unit_id, campaign_id=null, with_deleted=null, campaign_unit_token=null, callback) {
    const FETCH_TIMEOUT = 5000;
    let didTimeOut = false;
    let queryString = "?"
    if (campaign_id) queryString += `campaign_id=${campaign_id}`
    if (campaign_unit_token) queryString += `&campaign_unit_token=${campaign_unit_token}`
    if (with_deleted) queryString += '&with_deleted=true'

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        didTimeOut = true;
        reject(new Error('Request timed out'));
      }, FETCH_TIMEOUT);

      get(UNITS_URL + unit_id + queryString).
        then((response) => {
          clearTimeout(timeout);
          if (!didTimeOut) {
            resolve(response)
          }
        }).catch((err) => {
          if (didTimeOut) return;
          reject(err);
        })
    }).then((response) => {
      callback(this.formatUnits([response])[0])
    }).catch((err) => {
      console.error('Loading unit failed.', err);
      callback(null, err);
    })
  },

  loadSupplier(name, successCallback, errorCallback) {
    return get(SUPPLIERS_URL + '/' + name).then((response) => {
      response['units'] = this.formatUnits(response['units'])
      successCallback(response)
    }).catch((error) => {
      errorCallback(error)
    });
  },

  loadSuppliers(callback) {
    return get(SUPPLIERS_URL).then((response) => callback(response))
  },

  getUnit(id) {
    return this.state.units.find(unit =>
      unit._id === id
    )
  },

  formatUnits(units = []) {
    return units.map(unit => {
      const howLong = this.state.filters.date && this.state.filters.date.howLong ? this.state.filters.date.howLong : 4
      unit.week_total_cost = (unit.week_total_cost / 4) * howLong;
      unit.week_total_impressions = unit.week_total_impressions ? (unit.week_total_impressions / 4) * howLong : null;
      unit.type = unit.unit_type.toLowerCase();

      if (unit.reserved_from && unit.reserved_to) {
        unit.weeksInRange = moment.range(unit.reserved_from, unit.reserved_to).diff('weeks') + 1;
        unit.daysInRange = moment.range(unit.reserved_from, unit.reserved_to).diff('day') + 1;
      }
      return unit;
    })
  },

  getState() {
    return this.state;
  },

  updateUnit(unit_id, params) {
    return put(`/api/v1/units/${unit_id}`, params)
      .then(res => this.trigger('unit:update_success'))
      .catch(res => this.trigger('unit:update_error'));
  },

  addUnit(params) {
    return post(`/api/v1/units`, params)
      .then(res => {
        if (res.errors) {
          this.trigger('unit:create_error', res)
        } else {
          this.trigger('unit:create_success', res)
        }
      })
      .catch(res => this.trigger('unit:create_error', res));
  },

  updateFavoriteStatus(unit, campaignId) {
    let requestType;
    if (unit.is_favorited) {
      requestType = "POST"
    } else {
      requestType = "DELETE"
    }
    $.ajax({
      url: '/api/v1/favorites',
      type: requestType,
      data: {
        favorite: {
          unit_id: unit.id,
          campaign_id: campaignId,
          campaign_unit_id: unit.campaign_unit_token,
        }
      }
    })
  },

  updatePinStatus(unit, campaignId) {
    let requestType;
    if(unit.is_pinned) {
      requestType = "POST"
    } else {
      requestType = "DELETE"
    }

    $.ajax({
      url: `/api/v1/pinned_units/`,
      type: requestType,
      data: {
        favorite: {
          unit_id: unit.id,
          campaign_id: campaignId,
          campaign_unit_id: unit.campaign_unit_token,
        }
      }
    })
  },
});
