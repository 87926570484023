import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";

import { loadCampaignDesigns, uploadDesignAsset } from "../../../../actions/campaign_design_actions";
import UploadButton from "../../../UploadButton";
import AddPostingNoteModal from "../AddPostingNoteModal";
import BulkAddPostingNoteAction from "../BulkAddPostingNoteAction";
import BulkRemovePostingNoteAction from "../BulkRemovePostingNoteAction";
import BulkSetShippingAddressAction from "../BulkSetShippingAddressAction";
import BulkShippingAddressModal from "../BulkShippingAddressModal";
import * as styleGen from "../styles";
import Tooltip from "rc-tooltip";

interface BulkActionsProps {
  units: any[];
  unit_selection_map: any;
  status: string;
  campaign_permissions: any;
  campaign: any;
  loadCampaignDesigns: (campaign_id: string) => void;
  uploadDesignAsset: (campaign_id: string, units: {}[], upload: {}) => {};
  unselectAll: () => void;
  onToggleSelectAll: (selected: boolean) => void;
}

interface BulkActionsState {
  show_posting_note_modal: boolean;
  show_shipping_address_modal: boolean;
  is_dropdown_active: boolean;
}

class BulkActions extends React.Component<BulkActionsProps, BulkActionsState> {
  constructor(props) {
    super(props);
    this.state = {
      show_posting_note_modal: false,
      show_shipping_address_modal: false,
      is_dropdown_active: false
    };
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.onShowPostingModal = this.onShowPostingModal.bind(this);
    this.onHidePostingNoteModal = this.onHidePostingNoteModal.bind(this);
    this.onShowShippingAddressModal = this.onShowShippingAddressModal.bind(this);
    this.onHideShippingAddressModal = this.onHideShippingAddressModal.bind(this);
    this.onDropDownDomAction = this.onDropDownDomAction.bind(this);

  }

  public render() {
    const { units, unit_selection_map, campaign } = this.props;
    const { show_posting_note_modal, show_shipping_address_modal } = this.state;
    const all_selected = _.every(units, unit => unit_selection_map[unit.id]);
    const selected_units = this.selectedUnits();
    const supplier_id = this.uniqSupplierIdsFromSelectedUnits()[0]

    return (
      <div className="bulk_actions" style={styleGen.actions()}>
        {this.renderActionsDropdown()}
        <AddPostingNoteModal
          campaign={campaign}
          show={show_posting_note_modal}
          selected_units={selected_units}
          onHide={this.onHidePostingNoteModal}
        />
        <BulkShippingAddressModal
          campaign={campaign}
          onSubmit={() => {}}
          selected_units={selected_units}
          supplier_id={supplier_id}
          show={show_shipping_address_modal}
          onHide={this.onHideShippingAddressModal}
        />
        <input type="checkbox" onChange={this.selectAll} checked={all_selected} style={styleGen.checkbox()}></input>
      </div>
    );
  }

  private selectedUnits() {
    const { units, unit_selection_map } = this.props;
    return units.filter(unit => unit_selection_map[unit.id]);
  }

  onHidePostingNoteModal() {
    this.setState({ show_posting_note_modal: false });
  }

  onDropDownDomAction(){
    this.setState({ is_dropdown_active: !this.state.is_dropdown_active });
  }

  private renderBulkRemovePostingNoteButton() {
    const { campaign } = this.props;
    return (
      <BulkRemovePostingNoteAction
        disabled={this.submittedUnitsSelected()}
        selected_units={this.selectedUnits()}
        campaign={campaign}
      />
    );
  }

  private submittedUnitsSelected() {
    const { units, unit_selection_map } = this.props;
    const selected_unit_ids = Object.keys(unit_selection_map);

    return (
      units.filter(
        u => _.get(u, "design_assets[0].production_order.status") === "submitted" && selected_unit_ids.includes(u.id),
      ).length > 0
    );
  }

  private renderActionsDropdown() {
    const { campaign_permissions } = this.props;
    const { can_add_posting_note, can_upload_design_asset, can_set_shipping_address } = campaign_permissions;
    const selected_units = this.selectedUnits();
    const submitted_selected = this.submittedUnitsSelected();
    const disabled = selected_units.length > 0 ? "" : "disabled";

    return (
      <div
        className="btn-group"
        style={{
          float: "right",
          marginBottom: 10,
        }}
      >
        <div className="dropdown">
          {
            !this.state.is_dropdown_active && (
              <Tooltip
                placement="bottom"
                trigger={['hover']}
                overlayStyle={{ width: '200px', height: "200px" }}
                overlay={<span style={{ width: "fit-content" }}>Upload individual creative assets by clicking "upload - creative" or mass upload creatives by using the checkbox selection + actions menu (arrow).</span>}
              >
                <button className={`btn btn-default dropdown-toggle ${disabled}`} type="button" data-toggle="dropdown" onClick={this.onDropDownDomAction}>
                  Actions
                  <span className="caret" style={{ marginLeft: "5px", marginBottom: "2px" }} />
                </button>
              </Tooltip>
            )
          }
          {
            this.state.is_dropdown_active && (
              <button className={`btn btn-default dropdown-toggle ${disabled}`} type="button" data-toggle="dropdown" onMouseLeave={this.onDropDownDomAction}>
                Actions
                <span className="caret" style={{ marginLeft: "5px", marginBottom: "2px" }} />
              </button>
            )
          }
          <ul className="dropdown-menu dropdown-menu-left" style={{ padding: 5 }}>
            {can_add_posting_note && !!!submitted_selected && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderAddPostingNoteButton()}
              </li>
            )}
            {can_add_posting_note && !!!submitted_selected && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderBulkRemovePostingNoteButton()}
              </li>
            )}
            {can_set_shipping_address && !!!submitted_selected && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderSetShippingAddressButton()}
              </li>
            )}
            {can_upload_design_asset && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderUploadButton()}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }

  private selectAll(event) {
    this.props.onToggleSelectAll(event.target.checked);
  }

  private onShowPostingModal() {
    this.setState({
      show_posting_note_modal: true,
    });
  }

  private onShowShippingAddressModal() {
    if(!this.ensureUniqSupplierFromSelectedUnits()) { return alert("Select units from one supplier one. Addresses are tied to suppliers.") }
    this.setState({
      show_shipping_address_modal: true,
    });
  }

  private ensureUniqSupplierFromSelectedUnits() {
    return this.uniqSupplierIdsFromSelectedUnits().length == 1
  }

  private uniqSupplierIdsFromSelectedUnits() {
    return [...new Set(this.supplierIdsFromSelectedUnits())]
  }

  private supplierIdsFromSelectedUnits() {
    return this.selectedUnits().map(u => u.supplier_id)
  }

  private onHideShippingAddressModal() {
    this.setState({
      show_shipping_address_modal: false,
    });
  }

  private renderAddPostingNoteButton() {
    return <BulkAddPostingNoteAction disabled={this.submittedUnitsSelected()} showModal={this.onShowPostingModal} />;
  }

  private renderUploadButton(disabled = false) {
    return (
      <UploadButton
        key="upload_button"
        onComplete={this.onUploadComplete}
        progressBarColor={styleGen.uploadButton().background}
      >
        <button
          className="btn btn-default"
          style={styleGen.uploadButton()}
          onClick={e => e.preventDefault()}
          disabled={disabled}
        >
          <i className="fa fa-cloud-upload" style={{ marginRight: 5 }} /> Upload Creative
        </button>
      </UploadButton>
    );
  }

  private renderSetShippingAddressButton() {
    return (
      <BulkSetShippingAddressAction
        disabled={this.submittedUnitsSelected()}
        showModal={this.onShowShippingAddressModal}
      />
    );
  }

  private async onUploadComplete(upload, file) {
    const { campaign } = this.props;
    const campaign_id = campaign.campaignId;
    const selected_units = this.selectedUnits();

    const uploadInfo = {
      file_name: file.name,
      file_path: upload.path,
      file_size: file.size,
      file_type: file.type,
    };

    await this.props.uploadDesignAsset(campaign_id, selected_units, uploadInfo);
    this.props.loadCampaignDesigns(campaign_id);
    this.props.unselectAll();
  }
}

const mapStateToProps = ({}) => ({});
export default connect(
  mapStateToProps,
  {
    loadCampaignDesigns,
    uploadDesignAsset,
  },
  // @ts-ignore
)(BulkActions);
