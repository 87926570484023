import FaFileDownload from "@adquick/icons/fa/FaFileDownload";
import moment from "moment";
import React from "react";

import ConfirmDeleteAttachmentModal from "./ConfirmDeleteAttachmentModal";

export default class LeaseMaterials extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onCancelDelete = this.onCancelDelete.bind(this);
  }

  onConfirmDelete(attachment) {
    const { deletable } = this.props;
    if (!!!deletable) {
      return;
    }
    this.setState({
      confirm_delete_attachment: attachment,
    });
  }

  onCancelDelete() {
    this.setState({
      confirm_delete_attachment: null,
    });
  }

  render() {
    const { loading, attachments, deletable, deleteAttachment } = this.props;
    const { confirm_delete_attachment } = this.state;

    return (
      <div style={{ maxWidth: deletable ? 300 : 250 }}>
        {confirm_delete_attachment && deleteAttachment && (
          <ConfirmDeleteAttachmentModal
            attachment={confirm_delete_attachment}
            onConfirm={deleteAttachment}
            onHide={this.onCancelDelete}
          />
        )}
        {attachments.map(attachment => {
          return (
            <div
              className="row"
              key={`${attachment.id}`}
              style={{
                background: "#FAFAFA",
                borderRadius: 8,
                minHeight: 46,
                margin: "auto",
                marginBottom: 3,
              }}
            >
              <div
                className="col-xs-1"
                style={{
                  color: "#4A90E2",
                  fontSize: 16,
                  padding: "12px 10px 0px 15px",
                }}
              >
                <i className="fa fa-file-o" aria-hidden="true"></i>
              </div>
              <div className="col-xs-9" style={{ paddingTop: 5 }}>
                <div
                  style={{
                    color: "#4A90E2",
                    fontSize: 13,
                    fontWeight: 600,
                  }}
                >
                  {attachment.filename}
                </div>
                {attachment.created_at && (
                  <div style={{ color: "#858585", fontSize: 11 }}>
                    Uploaded {moment(attachment.created_at).format("L")}
                  </div>
                )}
              </div>
              <div
                className={`col-xs-${deletable ? "2" : "1"}`}
                style={{
                  display: "flex",
                }}
              >
                <a
                  rel="noopener noreferrer"
                  onClick={() => window.open(attachment.file_url)}
                  style={{
                    position: "relative",
                    left: -10,
                    marginTop: 15,
                    color: "#858585",
                    fontSize: 16,
                  }}
                >
                  <FaFileDownload />
                </a>
                {deletable && (
                  <a
                    onClick={() => this.onConfirmDelete(attachment)}
                    style={{ marginTop: 13, color: "#858585", fontSize: 16 }}
                  >
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
