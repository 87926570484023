import { useState, useEffect, MutableRefObject } from "react";

export const useOutsideClick = (ref: MutableRefObject<HTMLElement>, callback: (event: MouseEvent) => void): boolean => {
  const [isClickOutside, setClickOutside] = useState(false);

  const handler = (event: MouseEvent): void => {
    if (ref.current && event.target instanceof HTMLElement && !ref.current.contains(event.target)) {
      setClickOutside(true);
      callback(event);
    }
  };

  useEffect(() => {
    if (ref) {
      document.addEventListener("mousedown", handler);
    }
    return (): void => {
      if (ref) {
        document.removeEventListener("mousedown", handler);
      }
    };
  }, [ref]);
  return isClickOutside;
};
