import React from "react";
import { connect } from "react-redux";
import {
  addCampaign,
  selectCampaign,
  hideModal,
  fetchFlightTypes,
  fetchCampaigns
} from "../actions";
import {
  addFlightsAsync,
  addFlightsAsyncResponse,
  addFlights,
  resetFlightForm,
  updateFlight,
  fetchSalesUsers,
  selectSalesUser,
  deleteFlight
} from "./actions";
import {
  fetchAllAdvertisers,
  resetAdvertiser
} from "../../Advertisers/actions";
import { deselectUnits } from "../Unit/actions";
import validate from "validate.js";
import moment from "moment";
import DateRangePicker from "../../DatePicker/Range";
import DatePicker from "../../DatePicker/Single";
import ReactSelect from "react-select";
import SelectCreatable from "react-select/creatable";
import { ControlLabel, FormControl, FormGroup, Col, Form, Checkbox } from "react-bootstrap";
import CreateAdvertiser from "../../Advertisers/Create";
import Loading from "../../Loading";
import JobProgress from "../../../utils/JobProgress";
import GlobalActions from "../../../actions/GlobalActions";

class FlightForm extends React.Component {

  constructor(props) {
    super(props);
    this.constraints = {
      "campaign.id": {
        presence: {
          message: "can't be blank, please create campaign before saving"
        }
      },
      "campaign_unit.workflow_state": {
        presence: {}
      },
      "campaign_unit.start_date": {
        presence: {}
      },
      "campaign_unit.end_date": {
        presence: {}
      }
    };

    const campaign = {
      id: null,
      sales_user_id: null,
      name: ""
    };

    const campaign_unit = {
      start_date: moment().add(4, "weeks"),
      end_date: moment()
        .add(8, "weeks")
        .subtract(1, "day"),
      workflow_state: "",
      account_executive: "",
      comment: "",
      price: null,
      quantity: 1,
      artwork_received: false,
      auto_renew: false,
      contract_not_received: false,
      run_of_network: false,
      hold_expires_at: moment()
    };

    const selected_units = this.props.selected_units ? this.props.selected_units.filter(x => x) : [];
    const unit_price = (selected_units && selected_units.length === 1) ? selected_units[0].price : null;

    this.state = {
      errors: {},
      total_price: 0,
      price: unit_price,
      campaign,
      campaign_unit,
      saving: false,
      show_advertiser_create: false,
      advertiser_id: null,
      job_pooling: new JobProgress()
    };

    this.setStateFromProps = this.setStateFromProps.bind(this);
    this.validate = this.validate.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.syncPrices = this.syncPrices.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
    this.viewCampaign = this.viewCampaign.bind(this);
    this.viewConflicts = this.viewConflicts.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAddFlights = this.onAddFlights.bind(this);
    this.onUpdateFlight = this.onUpdateFlight.bind(this);
    this.getFlightData = this.getFlightData.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.isOptionUnique = this.isOptionUnique.bind(this);
    this.onHide = this.onHide.bind(this);
    this.addCampaign = this.addCampaign.bind(this);
    this.onAddCampaign = this.onAddCampaign.bind(this);
    this.onAdvertiserChange = this.onAdvertiserChange.bind(this);
    this.onCampaignChange = this.onCampaignChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.checkUnitProperty = this.checkUnitProperty.bind(this);
    this.scopedFlightTypes = this.scopedFlightTypes.bind(this);
    this.renderAdvertiserSection = this.renderAdvertiserSection.bind(this);
    this.renderDateRangePickerSection = this.renderDateRangePickerSection.bind(this);
    this.renderFlightTypeSection = this.renderFlightTypeSection.bind(this);
    this.campaignOptions = this.campaignOptions.bind(this);
    this.renderCampaignSection = this.renderCampaignSection.bind(this);
    this.renderAccountExecSection = this.renderAccountExecSection.bind(this);
    this.onHoldPriorityChange = this.onHoldPriorityChange.bind(this);
    this.onAddFlightsComplete = this.onAddFlightsComplete.bind(this);
  }

  componentWillMount() {
    const {
      flightTypes,
      fetchFlightTypes,
      resetFlightForm,
      fetchAllAdvertisers,
      campaigns,
      formType,
      user,
      fetchSalesUsers,
      resetAdvertiser,
      fetchCampaigns
    } = this.props;
    resetFlightForm();
    resetAdvertiser();
    if (!!!user.is_admin) fetchSalesUsers();
    fetchAllAdvertisers();

    if (!!!campaigns || campaigns.length === 0) {
      fetchCampaigns();
    }

    if (campaigns && formType !== "add") this.setStateFromProps();

    if (!!!flightTypes || !!!flightTypes.length) {
      fetchFlightTypes();
    }
  }

  setStateFromProps() {
    const { campaign_unit, formType, campaigns } = this.props;
    const { campaign_id } = campaign_unit;
    if (!campaign_id && formType == "edit") {
      this.setState({ campaign_unit });
      return;
    }
    const campaign = campaigns.find(c => c.id === campaign_id);
    this.setState({
      price: campaign_unit.price || 0,
      campaign: campaign || this.state.campaign,
      campaigns,
      campaign_unit
    });
  }

  componentWillReceiveProps(nextProps) {
    const show_advertiser_create = (nextProps.error && nextProps.error.name) ? true : false;
    if (this.state.price > 0 && this.state.total_price === 0)
      this.handlePriceChange({ price: this.state.campaign_unit.price });

    const { campaign } = this.state;
    let found_campaign = { ...campaign };
    let advertiser_id = this.state.advertiser_id;
    if ((nextProps.advertiser && nextProps.advertiser.id) && (!!!advertiser_id || advertiser_id !== nextProps.advertiser.id)) {
      found_campaign.advertiser_id = nextProps.advertiser.id
      this.setState({ campaign: found_campaign, show_advertiser_create, advertiser_id: nextProps.advertiser.id})
      advertiser_id = nextProps.advertiser.id
    } else {
      this.setState({advertiser_id: campaign.advertiser_id})
    }
    // set id if new campaign is created
    if (nextProps.selectedCampaignId) {
      found_campaign = { ...nextProps.campaigns.find(c => c.id === nextProps.selectedCampaignId), advertiser_id: advertiser_id };
      this.setState({ campaigns: nextProps.campaigns, campaign: found_campaign, advertiser_id})
    }

    this.setState({ show_advertiser_create });
  }

  validate() {
    const { price, total_price, campaign, advertiser_id } = this.state;
    const { campaigns } = this.props;
    const params = {
      ...this.props,
      ...this.state
    };
    if (!!!(price >= 0 && total_price >= 0)) {
      const errors = { name: "Prices must be numeric" };
      this.setState({ errors });
      return errors;
    }
    if (!!!advertiser_id){
      const errors = { name: "Advertiser is Required" };
      this.setState({ errors });
      return errors;
    }
    if (!!!campaigns.length || (campaign.id && !!!(campaign.name && campaign.name.length))) {
      const errors = { name: "Missing campaign data, may need to refresh" };
      this.setState({ errors });
      return errors;
    }
    const errors = validate(params, this.constraints);
    this.setState({ errors });
    return errors;
  }

  handlePriceChange(values) {
    this.setState({ errors: {} });
    const { start_date, end_date } = this.state.campaign_unit;
    const price_vals = {
      ...values,
      price: values.price && parseFloat(values.price),
      total_price: values.total_price && parseFloat(values.total_price)
    };
    this.syncPrices(price_vals, start_date, end_date);
  }

  syncPrices(values, start_date, end_date) {
    const num_days = moment(end_date).add(1, "day").diff(moment(start_date), "days");
    if (!values.price && !values.total_price) {
      if (values.dateChange && this.state.price > 0) {
        const total_price = (this.state.price / 4 / 7) * num_days;
        this.setState({ total_price });
      } else {
        this.setState({ total_price: 0 });
      }
    } else if (values.total_price) {
      const daily_price = values.total_price / num_days;
      const price = daily_price * 7 * 4;
      this.setState({ price: price.toFixed(2) });
    } else {
      const daily_price = values.price / 4 / 7;
      const total_price = daily_price * num_days;
      this.setState({ total_price: total_price.toFixed(2) });
    }
  }

  renderErrors() {
    const { errors } = this.state;
    const formattedErrors = [];

    for (const key in errors) {
      formattedErrors.push(errors[key]);
    }

    if (formattedErrors.length) {
      return formattedErrors.map(err => (
        <p className="message__row" key={err}>
          {err}
        </p>
      ));
    }
  }

  viewCampaign(e) {
    e.preventDefault();
    e.stopPropagation();
    window.open(`/campaigns/${this.state.campaign.id}`);
  }

  viewConflicts(e) {
    e.preventDefault();
    e.stopPropagation();
    const { conflicts, filterByFaceIds } = this.props;
    const face_ids = conflicts.map(conflict => conflict.supplier_face_id);
    filterByFaceIds({ face_ids });
    this.onHide();
  }

  onSubmit(e) {
    e.preventDefault();
    const errors = !!!["available", "proposed"].includes(this.state.campaign_unit.workflow_state) && this.validate();
    if (!errors) {
      const { formType } = this.props;
      if (formType === "edit"){
        const { onUnitDeselectAll } = this.props;
        if (onUnitDeselectAll) {
          onUnitDeselectAll();
        }
        this.onUpdateFlight();
      } else {
        this.onAddFlights();
      }
    }
  }

  async onAddFlights() {
    this.setState({ saving: true });
    const flight_data = this.getFlightData();
    const { addFlightsAsync } = this.props;
    const job_id = await addFlightsAsync(flight_data);
    this.state.job_pooling.startPolling(job_id, this.onAddFlightsComplete);
  }

  onAddFlightsComplete(response) {
    const { addFlightsAsyncResponse, deselectUnits } = this.props;
    const { data } = response;
    if (data.error) {
      this.setState({ saving: false });
      return GlobalActions.showError('There was an error adding your flights, please contact AdQuick');
    }
    addFlightsAsyncResponse(data);
    deselectUnits();
    this.setState({ saving: false });
  }

  onUpdateFlight() {
    const flight_data = this.getFlightData();
    const { updateFlight } = this.props;
    updateFlight(flight_data);
  }

  getFlightData() {
    const { price, campaign_unit, campaign } = this.state;
    const { hold_expires_at } = campaign_unit;
    const hold_expires_at_as_date = moment(hold_expires_at).toDate();
    const selected_units = this.props.selected_units ? this.props.selected_units.filter(x => x) : [];

    return {
      campaign,
      unit_ids: selected_units.map(u => u.id),
      campaign_unit: {
        ...campaign_unit,
        price,
        start_date: campaign_unit.start_date && campaign_unit.start_date.toDate(),
        end_date: campaign_unit.end_date && campaign_unit.end_date.toDate(),
        hold_expires_at: hold_expires_at ? hold_expires_at_as_date : null
      }
    };
  }

  onDelete(e) {
    e.preventDefault();
    const { deleteFlight, campaign_unit } = this.props;
    deleteFlight(campaign_unit);
    this.onHide();
  }

  isOptionUnique(prop) {
    const { option, options, valueKey, labelKey } = prop;
    return !!!options.find(opt => option[valueKey] === opt[valueKey]);
  }

  onHide(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    const { hideModal, deselectUnits, resetFlightForm } = this.props;
    hideModal();
    deselectUnits();
    resetFlightForm();
  }

  addCampaign(e) {
    e.preventDefault();
    e.stopPropagation();
    const { campaign, campaigns, errors } = this.state;
    const name = campaign && campaign.name;
    const { addCampaign } = this.props;

    if (name.length > 3 && campaigns.find(c => c.name == name))
      this.setState(
        { errors: { ...errors, name: "Campaign name must be unique" } },
        this.renderErrors()
      );
    else if (name.length > 3) addCampaign({ name });
    else
      this.setState(
        { errors: { ...errors, name: "Campaign name must be at least 3 characters" } },
        this.renderErrors()
      );
  }

  onAddCampaign(opt) {
    const { advertiser_id, campaign_unit } = this.state;
    const { workflow_state } = campaign_unit;
    const isContracted = workflow_state === 'booked' || workflow_state === 'movable';
    const selected = {name: opt};
    if (advertiser_id) {
      selected['advertiser_id'] = advertiser_id;
    }
    if (isContracted) {
      selected['status'] = 'contracted';
    }
    const { campaigns, addCampaign } = this.props;
    const campaign = campaigns.find(c => c.name === opt);
    if (!!!campaign) addCampaign(selected);
    else this.setState({ campaign: { ...this.state.campaign, id: campaign.id } });
  }

  onAdvertiserChange(option) {
    if (option) this.setState({ advertiser_id: option && option.value });
    else this.setState({ advertiser_id: null });
  }

  onCampaignChange(option) {
    const { campaign } = this.state;
    if (option && option.value !== campaign.id) {
      const selected_campaign = this.campaignOptions().find(c => c.id === option.value);
      this.setState({
        advertiser_id: (selected_campaign && selected_campaign.advertiser_id) || null,
        campaign: {
          ...campaign,
          id: option && option.value,
          name: option && option.label,
          advertiser_id: (selected_campaign && selected_campaign.advertiser_id) || null
        }
      });
    } else if (!option) {
      this.setState({
        campaign: {}
      });
    }
  }

  onDatesChange(start_date, end_date) {
    const { campaign_unit } = this.state;
    this.setState(
      { campaign_unit: { ...campaign_unit, start_date, end_date } },
      this.syncPrices({ dateChange: true }, start_date, end_date)
    );
  }

  checkUnitProperty(property) {
    const { formType, unit, units_updated, selected_units } = this.props;
    const filtered_selected_units = selected_units ? selected_units.filter(x => x) : [];
    if (formType == "edit") return unit[property];
    const combined_arrays = [...filtered_selected_units, ...(units_updated || [])];
    return _.some(combined_arrays, property);
  }

  scopedFlightTypes() {
    const { user, flightTypes, unit, selected_units } = this.props;
    const filtered_selected_units = selected_units ? selected_units.filter(x => x) : [];

    if (_.isEmpty(flightTypes)) return [];
    else if (user.is_supplier_sales) return flightTypes.filter(ft => ft.internal_type == "on_hold" && ft.internal_type != "available");
    else if (user.is_admin || this.checkUnitProperty("is_digital")) return flightTypes.filter(ft => ft.internal_type != "available");
    else if (unit && unit.has_quantity)
      return flightTypes.filter(ft => ft.internal_type != "movable" && ft.internal_type != "available");
    else if (filtered_selected_units[0] && filtered_selected_units[0].has_quantity)
      return flightTypes.filter(ft => ft.internal_type != "movable" && ft.internal_type != "available");
    else return flightTypes.filter(ft => ft.internal_type != "available");
  }

  renderAdvertiserSection() {
    const { campaign, show_advertiser_create, advertiser_id } = this.state;
    const { advertisers } = this.props;
    const advertiser_create_style = {
      marginLeft: 150,
      marginRight: 52,
      marginBottom: 10
    };

    const options = advertisers.map(a => { return {label: a.name, value: a.id }; })
    return (
      <div>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            Advertiser
          </Col>
          <Col sm={8} style={{marginBottom: -10}}>
            <ReactSelect
              placeholder="Select an advertiser"
              value={options.find(o => o.value === advertiser_id )}
              onChange={this.onAdvertiserChange}
              options={options}
              valueKey="id"
              labelKey="name"
              clearable={true}
              isOptionUnique={this.isOptionUnique}
            />
          </Col>
        </FormGroup>
        <div className="row" style={advertiser_create_style}>
          {show_advertiser_create && <CreateAdvertiser />}
        </div>
        <div className="row" style={advertiser_create_style}>
          <a onClick={e => this.setState({show_advertiser_create: !!!show_advertiser_create})}>
            {show_advertiser_create ? 'hide' : 'Add New Advertiser'}
          </a>
        </div>
      </div>
    )
  }

  renderDateRangePickerSection(start_date, end_date, workflow_state) {
    const additionalPresets = [
      {
        text: '24 weeks',
        length: 24,
        period: 'weeks'
      },
      {
        text: '52 weeks',
        length: 52,
        period: 'weeks'
      }
    ];

    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Flight Dates
        </Col>
        <Col sm={8}>
          <DateRangePicker
            start_date={start_date}
            end_date={end_date}
            disabled={workflow_state === "available"}
            additionalPresets={additionalPresets}
            onDatesChange={({ start_date, end_date }) =>
              this.onDatesChange(start_date, end_date)
            }
          />
        </Col>
      </FormGroup>
    )
  }

  renderFlightTypeSection() {
    const { campaign_unit } = this.state;
    const scoped_flight_types = this.scopedFlightTypes();
    const options = scoped_flight_types.map(s => { return {label: s.name, value: s.internal_type}; })
    const selected = options.find(o => o.value === campaign_unit.workflow_state) || {};
    return (
      <FormGroup className="flight-type">
        <Col componentClass={ControlLabel} sm={3}>
          Flight Type
        </Col>
        <Col sm={8}>
          <ReactSelect
            options={options}
            value={selected}
            valueKey="internal_type"
            labelKey="name"
            onChange={opt =>
              this.setState({
                campaign_unit: {
                  ...campaign_unit,
                  workflow_state: opt && opt.value
                }
              })
            }
          />
        </Col>
      </FormGroup>
    )
  }

  campaignOptions() {
    const campaigns = this.state.campaigns || this.props.campaigns || [];
    const { advertiser_id, campaign_unit } = this.state;

    if (advertiser_id) {
      return campaigns.filter((c) => {
        return c.advertiser_id === advertiser_id
      })
    } else {
      return campaigns;
    }
  }

  renderCampaignSection() {
    const { campaign, campaign_unit } = this.state;
    const campaign_options = this.campaignOptions();
    const options = campaign_options.map(c => { return {label: c.name, value: c.id}; });
    const selected = options.find(o => o.value === campaign.id);
    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Campaign
        </Col>
        <Col sm={8}>
          <SelectCreatable
            placeholder="Select or create a campaign"
            value={selected}
            onChange={this.onCampaignChange}
            options={options}
            valueKey="id"
            labelKey="name"
            promptTextCreator={e => `Create new campaign: ${e}`}
            onCreateOption={this.onAddCampaign}
            isClearable={true}
            isOptionUnique={this.isOptionUnique}
          />
        </Col>
      </FormGroup>
    )
  }

  renderAccountExecSection() {
    const { campaign } = this.state;
    const { sales_users } = this.props;
    const options = sales_users.map(u => { return {label: u.name, value: u.id}; });
    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Account Manager
        </Col>
        <Col sm={8}>
          <ReactSelect
            options={options}
            value={options.find(o => o.value === campaign.sales_user_id)}
            valueKey="id"
            labelKey="name"
            onChange={option =>
              this.setState({
                campaign: { ...campaign, sales_user_id: option && option.value }
              })
            }
          />
        </Col>
      </FormGroup>
    )
  }

  onHoldPriorityChange(hold_priority) {
    const { campaign_unit } = this.state;

    this.setState({
      campaign_unit: {
        ...campaign_unit,
        hold_priority: hold_priority ? hold_priority.value :  null
      }
    })
  }

  renderHoldPriority() {
    const { campaign_unit } = this.state;
    const options = [
      {label: '-', value: null},
      {label: '1st', value: 1},
      {label: '2nd', value: 2},
      {label: '3rd', value: 3}
    ];
    const hold_priority = campaign_unit.hold_priority ? options.find(o => o.value === campaign_unit.hold_priority) : {label: '-', value: null};

    return (
      <FormGroup>
        <Col componentClass={ControlLabel} sm={3}>
          Hold Priority
        </Col>
        <Col sm={8} style={{marginBottom: -10}}>
          <ReactSelect
            placeholder="Select a Priority"
            value={hold_priority}
            onChange={this.onHoldPriorityChange}
            options={options}
            clearable={true}
          />
        </Col>
      </FormGroup>
    )
  }

  render() {
    const {
      campaign_units_added,
      conflicts,
      user,
      loading_flight,
      flightTypes,
      formType
    } = this.props;
    const selected_units = this.props.selected_units ? this.props.selected_units.filter(x => x) : [];
    const has_quantity = this.checkUnitProperty("has_quantity");
    const is_digital = this.checkUnitProperty("is_digital");
    const { campaign_unit, campaign, total_price, price, saving, advertiser_id } = this.state;
    const {
      workflow_state,
      quantity,
      artwork_received,
      auto_renew,
      contract_not_received,
      run_of_network
    } = campaign_unit;
    const start_date = campaign_unit.start_date && moment(campaign_unit.start_date);
    const end_date = campaign_unit.end_date && moment(campaign_unit.end_date);
    const hold_expires_at = campaign_unit.hold_expires_at && moment(campaign_unit.hold_expires_at);
    const flight_type = flightTypes && flightTypes.find(ft => ft.internal_type == workflow_state);
    const workflow_state_friendly =
      workflow_state && flight_type ? flight_type.name : workflow_state;

    const boxStyles = {
      backgroundColor: "#f0f0f0",
      margin: "10px",
      padding: "10px"
    };

    const campaign_unit_from_props = this.props.campaign_unit;

    return (
      <Form horizontal>
        {saving || loading_flight ? (
          <Loading/>
        ) : (
          <div>
            <div className="clearfix">
              {this.renderErrors() && (
                <div className="message message_error">{this.renderErrors()}</div>
              )}
              {(typeof campaign_unit_from_props === "undefined" || campaign_unit_from_props.workflow_state !== "available") &&
                this.renderFlightTypeSection()
              }
              {(campaign_unit.workflow_state && campaign_unit.workflow_state !== "available") && <div>
                {this.renderAdvertiserSection()}
                {this.renderCampaignSection()}
              </div>}
              {(user.is_supplier_admin && !user.is_admin && campaign_unit.workflow_state !== "available") &&
                this.renderAccountExecSection()
              }
              {flight_type && flight_type.name === "Hold" && this.renderHoldPriority()}
              {flight_type && flight_type.name === "Hold" && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Hold Expiration Date
                  </Col>
                  <Col sm={8}>
                    <DatePicker
                      date={hold_expires_at}
                      onDateChange={({ date }) =>
                        this.setState({
                          campaign_unit: { ...campaign_unit, hold_expires_at: date }
                        })
                      }
                      showClearDate={true}
                    />
                  </Col>
                </FormGroup>
              )}
              {this.renderDateRangePickerSection(start_date, end_date, workflow_state)}
              {(user.is_supplier_admin || user.is_admin) && (
                <div>
                  {workflow_state !== "available" && (
                    <div>
                      <FormGroup>
                        <Col componentClass={ControlLabel} sm={3}>
                          4wk Price
                        </Col>
                        <Col sm={3}>
                          <FormControl
                            value={price}
                            onBlur={e => this.handlePriceChange({ price: e.target.value })}
                            onChange={e => this.setState({ price: e.target.value })}
                          />
                        </Col>
                        <Col componentClass={ControlLabel} sm={2}>
                          Total Price
                        </Col>
                        <Col sm={3}>
                          <FormControl
                            value={total_price}
                            onBlur={e => this.handlePriceChange({ total_price: e.target.value })}
                            onChange={e => this.setState({ total_price: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  )}
                  {(has_quantity || is_digital) && (
                    <FormGroup>
                      <Col componentClass={ControlLabel} sm={3}>{`${workflow_state_friendly} ${
                        is_digital ? "spots" : "Qty/wk"
                      }`}</Col>
                      <Col sm={8}>
                        <FormControl
                          value={quantity}
                          onChange={e =>
                            this.setState({
                              campaign_unit: { ...campaign_unit, quantity: e.target.value }
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                  )}
                </div>
              )}
              {!!!["available", "on_hold"].includes(workflow_state) && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Artwork Received
                  </Col>
                  <Col sm={8}>
                    <Checkbox
                      checked={artwork_received}
                      onChange={e =>
                        this.setState({
                          campaign_unit: { ...campaign_unit, artwork_received: e.target.checked }
                        })
                      }
                    />
                  </Col>
                </FormGroup>
              )}
              {workflow_state == "booked" && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Month-to-Month
                  </Col>
                  <Col sm={8}>
                    <Checkbox
                      checked={auto_renew}
                      onChange={e =>
                        this.setState({
                          campaign_unit: { ...campaign_unit, auto_renew: e.target.checked }
                        })
                      }
                    />
                  </Col>
                </FormGroup>
              )}
              {workflow_state == "booked" && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Contract Not Received
                  </Col>
                  <Col sm={8}>
                    <Checkbox
                      checked={contract_not_received}
                      onChange={e =>
                        this.setState({
                          campaign_unit: {
                            ...campaign_unit,
                            contract_not_received: e.target.checked
                          }
                        })
                      }
                    />
                  </Col>
                </FormGroup>
              )}
              {workflow_state == "booked" && (
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Run of Network
                  </Col>
                  <Col sm={8}>
                    <Checkbox
                      checked={run_of_network}
                      onChange={e =>
                        this.setState({
                          campaign_unit: { ...campaign_unit, run_of_network: e.target.checked }
                        })
                      }
                    />
                  </Col>
                </FormGroup>
              )}
            </div>

            <div className="clearfix">
              {selected_units.length > 0 && !campaign_unit.id && (
                <div>
                  <div
                    className="selected_units form-group"
                    style={{ backgroundColor: "#f0f0f0", margin: "10px", padding: "10px" }}
                  >
                    <h5 style={{ marginLeft: "15px" }}>
                      You're about to add flights to the following units:
                    </h5>
                    <ul style={{ padding: "10px", marginLeft: "20px", listStyleType: "disc" }}>
                      {selected_units.map(unit => {
                        return (
                          <li
                            style={{ marginTop: "1px", padding: "1px", listStyleType: "disc" }}
                            key={`selected-unit-${unit.id}-${unit.name}`}
                          >{`${unit.supplier_face_id} - ${unit.name}`}</li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {conflicts.length > 0 && (
                <div className="col-xs-6">
                  <h4>Face ids with conflicting dates:</h4>
                  <div style={boxStyles}>
                    {conflicts.map(conflict => {
                      const face_id = conflict.supplier_face_id;
                      return <div key={`conflict-${conflict.id}${face_id}`}>{face_id} </div>;
                    })}
                  </div>
                </div>
              )}
              {campaign_units_added.length > 0 && (
                <div className="col-xs-6">
                  <h4>
                    Face id
                    {formType == "edit" ? "" : "s"} of flight
                    {formType == "edit" ? " updated" : "s added"}
                  </h4>
                  <div style={boxStyles}>
                    {campaign_units_added.map(campaign_unit => {
                      const face_id = campaign_unit.supplier_face_id;
                      return (
                        <div key={`campaign_unit-${campaign_unit.id}${face_id}`}>{face_id} </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div>
              {conflicts.length > 0 && (
                <input
                  style={{ margin: "10px" }}
                  value="View Conflicts"
                  className="btn btn-warning"
                  onClick={e => this.viewConflicts(e)}
                />
              )}
              {user.is_supplier_admin && (
                <span>
                  {campaign_unit.id && (
                    <button
                      className="btn btn-primary"
                      style={{ margin: "10px" }}
                      type="submit"
                      disabled={this.state.saving}
                      onClick={this.onSubmit}
                      disabled={workflow_state === "available"}
                    >
                      Save
                    </button>
                  )}
                  {!campaign_unit.id && selected_units.length > 0 && (
                    <button
                      className="btn btn-primary"
                      style={{ margin: "10px" }}
                      type="submit"
                      disabled={selected_units.length === 0}
                      onClick={this.onSubmit}
                    >
                      {`Save for ${selected_units.length} units`}
                    </button>
                  )}
                </span>
              )}
              {campaign_unit.id && campaign_unit.workflow_state !== 'available' && (
                <button
                  className="btn btn-danger"
                  style={{ margin: "10px" }}
                  type="submit"
                  onClick={this.onDelete}
                >
                  Delete
                </button>
              )}
              {campaign && campaign.id && (
                <input
                  type="submit"
                  style={{ margin: "10px" }}
                  value="View Campaign"
                  className="btn btn-info"
                  onClick={e => this.viewCampaign(e)}
                />
              )}
              <input
                type="submit"
                style={{ margin: "10px" }}
                value="Close"
                className="btn btn-default"
                onClick={e => this.onHide(e)}
              />
            </div>
          </div>
        )}
        {user.is_hacker && (
          <div style={{ fontSize: "13px", color: "#aaa", paddingTop: "13px", clear: "both" }}>
            CU Token: {campaign_unit.token}
          </div>
        )}
      </Form>
    );
  }
};

export default connect(
  ({
    advertisers_ui: {
      advertiser_id,
      advertisers,
      advertiser
    },
    inventory: {
      campaign,
      campaigns,
      selectedCampaignId,
      conflicts,
      campaign_units_added,
      selected_units,
      loading_flight,
      sales_users,
      sales_user_id,
      units_updated,
      flightTypes
    }
  }) => {
    return {
      campaigns,
      selectedCampaignId,
      advertiser,
      advertisers,
      advertiser_id,
      conflicts,
      campaign_units_added,
      selected_units,
      loading_flight,
      sales_users,
      sales_user_id,
      units_updated,
      flightTypes
    };
  },
  {
    addCampaign,
    selectCampaign,
    fetchAllAdvertisers,
    addFlightsAsync,
    addFlightsAsyncResponse,
    addFlights,
    resetFlightForm,
    deselectUnits,
    updateFlight,
    hideModal,
    fetchSalesUsers,
    selectSalesUser,
    deleteFlight,
    fetchFlightTypes,
    resetAdvertiser,
    fetchCampaigns
  }
)(FlightForm);
