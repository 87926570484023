import React from 'react';
import { isMobile } from '../../../utils/mobile';
import { formatMoney } from 'accounting';
const POPUP_WIDTH = 275;

export default class CpmFilter extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {
      isExpanded: false,
      minCpmLiveValue: '',
      maxCpmLiveValue: ''
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onChange(event) {
    let minCpmLiveValue = this.minCpmInput.value;
    let maxCpmLiveValue = this.maxCpmInput.value;
    if (isNaN(parseFloat(minCpmLiveValue))) minCpmLiveValue = '';
    if (isNaN(parseFloat(maxCpmLiveValue))) maxCpmLiveValue = '';
    this.setState({ minCpmLiveValue, maxCpmLiveValue });
  }

  onFilter() {
    const { minCpmLiveValue, maxCpmLiveValue } = this.state;
    const { setFilter } = this.props;
    let minCpm = minCpmLiveValue;
    let maxCpm = maxCpmLiveValue;
    if (isNaN(parseFloat(minCpm))) minCpm = null;
    if (isNaN(parseFloat(maxCpm))) maxCpm = null;
    setFilter({ 'min_cpm': minCpm, 'max_cpm': maxCpm });
  }

  onClear(triggerFilter = false) {
    this.setState({
      minCpmLiveValue: '', // not-yet-applied value of input
      maxCpmLiveValue: '', // not-yet-applied value of input
    }, triggerFilter ? this.onFilter : null)
  }

  getButtonLabel() {
    const filters = this.props.filters || {};
    const { min_cpm, max_cpm } = filters;
    const formattedMinCpm = formatMoney(min_cpm);
    const formattedMaxCpm = formatMoney(max_cpm);
    if (!min_cpm && !max_cpm) return 'CPM'
    if (min_cpm && max_cpm) return `${formattedMinCpm}–${formattedMaxCpm} CPM`;
    if (min_cpm) return `${formattedMinCpm}+ CPM`;
    if (max_cpm) return `Up to ${formattedMaxCpm} CPM`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { minCpmLiveValue, maxCpmLiveValue} = this.state;
    return (
      <div className="filter_popup cpm_range_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>CPM RANGE</h4>
        <div className="padded_content">
          <p>All pricing is per 4 weeks</p>
          <div className="range_inputs">
            <input type="number" min="0" value={minCpmLiveValue} onChange={this.onChange} placeholder='Min' ref={node => this.minCpmInput = node}/>
            <input type="number" min="0" value={maxCpmLiveValue} onChange={this.onChange} placeholder='Max' ref={node => this.maxCpmInput = node}/>
          </div>
          <p className="actions">
            <a className="clear hidden-xs" onClick={e => this.onClear(true)}>Clear</a>
            <a onClick={this.onFilter}>Apply</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const { isExpanded } = this.state;
    const filters = this.props.filters || {};
    const { min_cpm, max_cpm } = filters;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={`${(min_cpm || max_cpm || isExpanded) ? 'active' : ''}`}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};
