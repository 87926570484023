import React, { Component } from 'react';
import Tooltip from "rc-tooltip";
import { paramsAdd, paramsRemove } from "../../utils/QueryString";

import cs from 'classnames';

const BOOKED_BY_DIFF = [
  {
    label: 'Next Booked: Furthest Date',
    short_label: 'Booked day',
    value: 'next_booked_diff',
    descending: true
  },
  {
    label: 'Next Booked: Closest Date',
    short_label: 'Booked day',
    value: '-next_booked_diff'
  }
];

const SORT_OPTIONS = [
  {
    label: 'Default',
    short_label: 'Default',
    value: 'default',
  },
  {
    label: 'Face ID',
    short_label: 'Face ID',
    value: 'supplier_face_id',
  },
  {
    label: 'CPM: Low to High',
    short_label: 'CPM',
    value: 'cpm',
  },
  {
    label: 'CPM: High to Low',
    short_label: 'CPM',
    value: '-cpm',
    descending: true,
  },
  {
    label: 'Price: Low to High',
    short_label: 'Price',
    value: 'week_total_cost',
  },
  {
    label: 'Price: High to Low',
    short_label: 'Price',
    value: '-week_total_cost',
    descending: true,
  },
  {
    label: 'Impressions: Low to High',
    short_label: 'Impressions',
    value: 'week_total_impressions',
  },
  {
    label: 'Impressions: High to Low',
    short_label: 'Impressions',
    value: '-week_total_impressions',
    descending: true,
  },
  {
    label: 'Size: Small to Large',
    short_label: 'Size',
    value: '-size',
  },
  {
    label: 'Size: Large to Small',
    short_label: 'Size',
    value: 'size',
    descending: true,
  },
  {
    label: 'Score: Low to High',
    short_label: 'Score',
    value: '-score',
  },
  {
    label: 'Score: High to Low',
    short_label: 'Score',
    value: 'score'
  }
];

const CAMPAIGN_OPTIONS = [
  {
    label: 'POI: Closest to farthest',
    short_label: 'Point of Interest',
    value: 'distance_from_point_of_interest',
    descending: true,
  },
  {
    label: 'POI: Farthest to closest',
    short_label: 'Point of Interest',
    value: '-distance_from_point_of_interest',
  }
]

const PERMISSION_BASED_OPTIONS = [
  {
    label: 'Unit Health: Low to High',
    short_label: 'Unit Health',
    value: 'final_score'
  },
  {
    label: 'Unit Health: High to Low',
    short_label: 'Unit Health',
    value: '-final_score',
    descending: true
  }
]

const POPUP_WIDTH = 220;

class SortBy extends Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    let allOptions = this.props.campaignToken === 'b0m9vvs67n' ? [...SORT_OPTIONS, ...BOOKED_BY_DIFF] : SORT_OPTIONS
    if (this.props.poiDistances !== null) {
      allOptions = [...allOptions, ...CAMPAIGN_OPTIONS]
    }

    // Only permission being checked is for the unit health score
    // If that changes in the future, this will need to be updated
    if (this.props.permissions['can_view_unit_health_score']) {
      allOptions = [...allOptions, ...PERMISSION_BASED_OPTIONS]
    }
    this.state = {
      isExpanded: false,
      allOptions: allOptions,
      sortedBy: this.findSortedByOptionValue(this.props.initialValue)
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props
    if (initialValue !== prevProps.initialValue) { this.updateSortedByFromInitialValue() }
  }

  updateSortedByFromInitialValue() {
    const { initialValue } = this.props
    this.setState({ sortedBy: this.findSortedByOptionValue(initialValue) })
  }

  findSortedByOptionValue(optionValue) {
    return [...SORT_OPTIONS, ...BOOKED_BY_DIFF].find(s => s.value === optionValue)
  }

  onSortBy(option) {
    const { onSort, handleQueryParams } = this.props;
    this.setState({ sortedBy: option })
    onSort(option.value);
    return handleQueryParams({ 'sort_by': option.value })
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    if (this.node.contains(event.target)) {
      this.setState({ isExpanded: !isExpanded })
    } else if (isExpanded) {
      this.setState({ isExpanded: false })
    }
  }

  getButtonLabel() {
    const { sortedBy } = this.state;
    if (!sortedBy) return 'Sort By…';
    const descending = sortedBy.descending;
    const iconClass = cs({
      'fa-regular': true,
      'fa-arrow-down-wide-short': descending,
      'fa-arrow-down-short-wide': !descending
    });
    return(
      <React.Fragment>
        <i className={iconClass}></i>
        <span className="text-label">Sort by {sortedBy.short_label}</span>
      </React.Fragment>
    )
  }

  renderDisabledPoiOption(option) {
    return <Tooltip placement="right" overlay="Please use the 'Calculate Nearest POI' Tool to sort">
          <li
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            {option.label}
          </li>
      </Tooltip>
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { sortedBy, allOptions } = this.state;

    return (
      <div className="filter_popup sort_by_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <ul>
          {allOptions.map((option) => {
            const onClick = () => { this.onSortBy(option); }
            const isActive = sortedBy == option;
            if (this.props.campaignToken !== null) {
              const hasCalculatedDistances = this.props.poiDistances.every(p => p !== null)
              if (option.short_label === "Point of Interest" && !hasCalculatedDistances) {
                return this.renderDisabledPoiOption(option)
              }
            }
            return <li
              key={option.value}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {option.label}
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { isExpanded, sortedBy } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs('sort_by', {active: isActive || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
}

export default SortBy;
