import accounting from "accounting";
import moment from "moment";
import groupBy from 'lodash/groupBy';
import UnitModel from "../models/unitModel";

import { TSupplier } from "./supplier";

export type UnitColor =
  | "#911884"
  | "#3494D3"
  | "#29A495"
  | "#29A495"
  | "#29A495"
  | "#C323CF"
  | "#462ACF"
  | "#462ACF"
  | "#462ACF"
  | "#462ACF"
  | "#462ACF"
  | "#32648A";

export type UnitType =
  | "billboard"
  | "street_furniture"
  | "retail"
  | "sports_venues"
  | "alternative"
  | "wallscape"
  | "transit"
  | "bus"
  | "rail"
  | "airport"
  | "taxi"
  | "wildposting";

export type UnitScreenType = "digital" | "static";

export type UnitDirection = null | "N" | "S" | "E" | "W" | "NE" | "SE" | "NW" | "SW";

export type UnitSupplierStatus =
  | null
  | "hold_expired"
  | "backup_hold"
  | "hold_adjusted"
  | "refresh_requested"
  | "refresh_unavailable"
  | "booking_approved"
  | "initial_adjusted"
  | "initial_unavailable"
  | "initial_requested"
  | "hold_unavailable"
  | "initial_approved"
  | "hold_requested"
  | "hold_approved"
  | "refresh_adjusted"
  | "refresh_approved"
  | "rfp_approved";

export type UnitWorkflowState = "booked" | "movable" | "on_hold" | "proposed" | "available";

export type Flight = {
  id: string;
  unit_id: string;
  start_date: moment.Moment | null;
  end_date: moment.Moment | null;
  workflow_state: UnitWorkflowState;
  price: number | null;
  is_favorited: boolean;
};

export type UnitTag = {
  tag: string;
  color: string;
};

export type BrowseUnit = {
  id: string;
  lon: number;
  lat: number;
  unit_type: UnitType;
  label: number | null;
  color: UnitColor;
  supplier: string;
  supplierName: string;
  supplier_name: string;
  lime: boolean;
  faceId: string;
  supplier_face_id: string;
  cpm: number | null;
  picture: string | null;
  thumbnail_url: string | null;
  image_file_link: string | null;
  size: string | null;
  screen_type: UnitScreenType;
  subtype: string | null;
  isPackage: boolean;
  is_package: boolean;
  package_id: string | null;
  is_package_child: boolean;
  direction: UnitDirection;
  price: number;
  rate_card: number;
  price_for_duration: string | null;
  total_price_for_duration: string | null;
  hasRestrictions: boolean;
  has_restrictions: boolean;
  is_managed: boolean;
  is_fully_submitted: boolean;
  name: string | null;
  impressions: number | null;
  week_total_impressions: number | null;
  impressions_eighteen: number | null;
  is_cannabis_friendly: boolean;
  attributes: Array<string>;
  previous_advertiser_ids: Array<number>;
  has_margin_agreement: boolean;
  is_broker: boolean;
  tab_panel_id: number | null;
};

export type CartUnit = {
  id: number;
  token: string;
  spots: number;
  size: string | null;
  start: string;
  end: string;
  duration: number;
  impressions: number | null;
  media_total: number | null;
  production_and_install: number | null;
  rate_card_price: number | null;
  unit_id: string | null;
  face_id: string | null;
  name: string | null;
  image_file_link: string | null;
};

export type CampaignUnit = {
  id: string;
  lon: number;
  lat: number;
  unit_type: UnitType;
  unit_subtype: string;
  label: number | null;
  color: UnitColor;
  supplier_id: number;
  supplier: string;
  supplierName: string;
  supplier_name: string;
  supplier_status: string | null;
  status_badge: string | null;
  lime: boolean;
  faceId: string;
  supplier_face_id: string;
  cpm: number | null;
  lower_cpm: number | null;
  picture: string | null;
  thumbnail_url: string | null;
  image_file_link: string | null;
  size: string | null;
  screen_type: UnitScreenType;
  screen: UnitScreenType;
  subtype: string | null;
  isPackage: boolean;
  is_package: boolean;
  package_id: string | null;
  is_package_child: boolean;
  direction: UnitDirection;
  price: number | null;
  value_program_price: number | null;
  value_program_price_for_duration: number | null;
  supplier_price: number | null;
  lower_price: number | null;
  higher_price: number | null;
  price_for_duration: string | null;
  supplier_installation_cost: number;
  supplier_production_cost: number;
  supplier_price_for_duration: string | null;
  total_supplier_price_for_duration: string | null;
  total_price_for_duration: string | null;
  total_value_program_price_for_duration: string | null;
  value_program_savings: string | null;
  hasRestrictions: boolean;
  has_restrictions: boolean;
  restr: boolean;
  is_managed: boolean;
  is_fully_submitted: boolean;
  is_broker: boolean;
  name: string | null;
  impressions: number | null;
  week_total_impressions: number | null;
  impressions_eighteen: number | null;
  is_cannabis_friendly: boolean;
  geojsonId: number | null;
  geojson_id: number | null;
  status: UnitSupplierStatus;
  workflow_state: UnitWorkflowState;
  suplier_status: UnitSupplierStatus;
  available: moment.Moment | null;
  available_formatted: string | null;
  start_date: moment.Moment | null;
  start_date_formatted: string | null;
  end_date: moment.Moment | null;
  end_date_formatted: string | null;
  hold_expires_at: moment.Moment | null;
  hold_expires_at_formatted: string | null;
  is_recommended: boolean;
  is_favorited: boolean;
  is_bonus_unit: boolean;
  in_cart: boolean;
  campaign_units: Array<Flight>;
  campaign_unit_token: string;
  restrictions_description: string | null;
  r_desc: string | null;
  distance_from_point_of_interest: number | null;
  production_cost: number | null;
  installation_cost: number | null;
  rate_card: number | null;
  rate_card_price: number | null;
  illuminated: string | null;
  tab_panel_id: number | null;
  _id: number;
  market_name: string;
  dma_id: number;
  tags: Array<UnitTag>;
  tags_as_string: string;
  attributes: Array<string>;
  design_asset_due_date: moment.Moment | null;
  design_asset_due_date_formatted: string | null;
  multi_flights: any[];
  quantity: number;
  reach_radius: number;
  next_booked_diff: number | null;
  next_booked_date: moment.Moment | null;
  next_booked_date_formatted: string | null;
  design_asset_posted_date: moment.Moment | null;
  design_asset_posted_date_formatted: string | null;
  design_asset_take_down_date: moment.Moment | null;
  design_asset_take_down_date_formatted: string | null;
  marked_still_posted_at: moment.Moment | null;
  spot_length: number | null;
  total_spots: number | null;
  has_margin_agreement: boolean;
  previous_advertiser_ids: Array<number>;
  supplier_address_id: number | null;
  notes: string;
  media_type: string;
  media_subtype: string;
  price_diff: number | null;
  start_date_diff: number | null;
  end_date_diff: number | null;
  production_cost_diff: number | null;
  final_score: number | null;
  score_status: 'poor' | 'okay' | 'great';
};

export function parseBrowseUnits(units: { public: Array<any>; private: Array<UnitPrivateData> }, useRateCardPriceOnBrowse: Boolean): Array<BrowseUnit> {
  const privateData = unitsPrivateData(units.private);
  //@ts-ignore
  return units.public.map(u => parseBrowseUnit(u, privateData.get(u.i), useRateCardPriceOnBrowse));
}

type UnitPrivateData = {
  i: string;
  price: number;
  cpm: number;
  supplier: string;
  supplier_name: string;
  alt_supplier_name: string;
  tab_panel_id: number;
};

function unitsPrivateData(unitData: Array<UnitPrivateData>): Map<string, UnitPrivateData> {
  return new Map<string, UnitPrivateData>(unitData.map(u => [u.i, u]) as Array<[string, UnitPrivateData]>);
}

declare global {
  interface Window { units: any }
}

type CampaignData = { [key:string]: { [id: string]: Array<CampaignUnit> | TSupplier } | boolean };


function campaignDataToMerge(campaignData: CampaignData): Array<{ [unit_id: string]: Array<CampaignUnit> }> {
  return Object.keys(campaignData)
    .filter(key => !['show_availability', 'suppliers'].includes(key))
    .map(key => campaignData[key])
    .flat();
}

function consolidateCampaignData(_id: string, campaignDatum: Array<{ [_id: string]: Array<CampaignUnit> }>): CampaignUnit {
  return campaignDatum
    .map(units => units[_id])
    .filter(Boolean)
    .flat()
    .reduce((acc, item) => ({ ...acc, ...item }), {}) as CampaignUnit;
}

function extractUnits(unitData: CampaignData) {
  const unit_ids = Object.keys(unitData.map);
  const unitsDataArray = campaignDataToMerge(unitData);
  const units = {};
  unit_ids.forEach(_id => {
    let unit = consolidateCampaignData(_id, unitsDataArray);
    unit = addSupplierData(unit, unitData.suppliers[unit.supplier_id][0]); // b/c of the groupBy this is an Array
    units[_id] = unit;
  });
  return units;
}

function extractCampaignUnits(campaignUnitData: CampaignData, units: {}, unitData: CampaignData) {
  const campaignUnitTokens = Object.keys(campaignUnitData.status);
  const campaignUnitsDataArray = campaignDataToMerge(campaignUnitData);
  const flights: Array<Flight> = [];
  let campaignUnits = campaignUnitTokens.map(token => {
    let campaignUnit = consolidateCampaignData(token, campaignUnitsDataArray);
    campaignUnit = {...campaignUnit, ...units[campaignUnit._id]};
    campaignUnit = parseCampaignUnit(campaignUnit as CampaignUnit, unitData.show_availability as boolean, unitData.show_price_for_duration as boolean);
    flights.push(toFlight(campaignUnit));

    return campaignUnit;
  });
  const flightsByUnit = groupBy(flights, "unit_id");
  campaignUnits = campaignUnits.map(campaignUnit => {
    return {...campaignUnit, campaign_units: flightsByUnit[campaignUnit.id]};
  });

  return campaignUnits;
}

function toFlight(campaignUnit: CampaignUnit) : Flight {
  return {
    id: campaignUnit.campaign_unit_token,
    unit_id: campaignUnit.id,
    start_date: campaignUnit.start_date ? moment.utc(campaignUnit.start_date) : null,
    end_date: campaignUnit.end_date ? moment.utc(campaignUnit.end_date) : null,
    workflow_state: campaignUnit.workflow_state,
    price: campaignUnit.price,
    is_favorited: campaignUnit.is_favorited,
  };
}

export function parseCampaignUnits(unitData: CampaignData, campaignUnitData: CampaignData,): Array<CampaignUnit> | any {
  const units = extractUnits(unitData);
  const campaignUnits = extractCampaignUnits(campaignUnitData, units, unitData);
  return campaignUnits;
}

function flat(units: CampaignUnit[] | CampaignUnit[][]) {
  if (units.flat) return units.flat();
  if (!units.length) return units;

  const head = units.pop();
  const tail = units;

  const item = Array.isArray(head) ? flat(head) : head;
  return flat(tail).concat(item);
}

function unitsById(units: Array<CampaignUnit>): Map<number, Array<CampaignUnit>> {
  const unitsMap = new Map<number, Array<CampaignUnit>>();

  units.forEach(u => {
    const campaign_units = unitsMap.get(u._id) || [];
    unitsMap.set(u._id, [...campaign_units, u]);
  });

  return unitsMap;
}

function addSupplierData(unit: CampaignUnit, supplier: TSupplier): CampaignUnit {
  if (!supplier || !unit.supplier_id || unit.supplier) return unit;

  unit.supplier = supplier.token;
  unit.supplier_name = supplier.name;
  unit.is_managed = !!supplier.is_managed;
  unit.is_fully_submitted = !!supplier.is_fully_submitted;
  unit.is_broker = supplier.is_broker_supplier;
  unit.has_margin_agreement = supplier.has_margin_agreement;
  return unit;
}

const adquick_base_url = "https://adquick.imgix.net/uploads/unit_image/image/";
const lamar_base_url = "http://apps.lamar.com/inventoryBrowser/photosheetimages/";
const outfront_base_url = "https://mapweb.outfrontmedia.com/handlers/image.ashx?t=photo&m=";
const cco_base_url = "https://go.cco.io/";
const adquick_default_url = "https://adquick-production.s3.amazonaws.com/uploads/unit_image/defaults/";
const adquick_pop_base_url = "https://adquick.imgix.net";

function parseBrowseUnit(unit: any, privateData: UnitPrivateData, useRateCardPriceOnBrowse: Boolean): BrowseUnit {
  if (privateData) {
    const unitModel = new UnitModel(unit)
    unit.price = useRateCardPriceOnBrowse ? unit.rate_card : privateData.price;
    unit.cpm = useRateCardPriceOnBrowse ? Number.parseFloat(unitModel.getCpm(unit.rate_card, unit.impressions)) : privateData.cpm;
    unit.supplier = privateData.supplier;
    unit.supplier_name = privateData.supplier_name;
    unit.alt_supplier_name = privateData.alt_supplier_name;
    unit.tab_panel_id = privateData.tab_panel_id;
  }
  unit.is_managed = !!unit.is_managed;
  unit.is_fully_submitted = !!unit.is_fully_submitted;
  unit.is_broker = unit.is_broker_supplier;

  return parseBasicUnitFields(unit, false) as BrowseUnit;
}

export function parseCampaignUnit(unit: CampaignUnit, showAvailability: boolean, showPriceForDuration: boolean): CampaignUnit {
  unit = parseBasicUnitFields(unit, showPriceForDuration);
  unit.reach_radius = metersToPixelsAtMaxZoom(305, unit.lat);
  unit.media_subtype = unit.unit_subtype
  unit.start_date = unit.start_date ? moment.utc(unit.start_date) : null;
  unit.end_date = unit.end_date ? moment.utc(unit.end_date) : null;
  unit.available = unit.available ? moment.utc(unit.available) : null;
  unit.hold_expires_at = unit.hold_expires_at ? moment.utc(unit.hold_expires_at) : null;
  unit.design_asset_due_date = unit.design_asset_due_date ? moment.utc(unit.design_asset_due_date) : null;
  unit.next_booked_date = unit.next_booked_date ? moment.utc(unit.next_booked_date) : null;
  unit.design_asset_posted_date = unit.design_asset_posted_date ? moment.utc(unit.design_asset_posted_date) : null;
  unit.design_asset_take_down_date = unit.design_asset_take_down_date
    ? moment.utc(unit.design_asset_take_down_date)
    : null;
  unit.marked_still_posted_at = unit.marked_still_posted_at ? moment.utc(unit.marked_still_posted_at) : null;

  // this is necessary for the Campaign Grid
  unit.start_date_formatted = unit.start_date ? unit.start_date.format("M/D/YYYY") : null;
  unit.end_date_formatted = unit.end_date ? unit.end_date.format("M/D/YYYY") : null;
  unit.available_formatted = unit.available ? unit.available.format("M/D/YYYY") : null;
  unit.hold_expires_at_formatted = unit.hold_expires_at ? unit.hold_expires_at.format("M/D/YYYY") : null;
  unit.design_asset_due_date_formatted = unit.design_asset_due_date
    ? unit.design_asset_due_date.format("M/D/YYYY")
    : null;
  unit.next_booked_date_formatted = unit.next_booked_date ? unit.next_booked_date.format("M/D/YYYY") : null;
  unit.design_asset_posted_date_formatted = unit.design_asset_posted_date
    ? unit.design_asset_posted_date.format("M/D/YYYY")
    : null;

  if(unit.design_asset_take_down_date) {
    unit.design_asset_take_down_date_formatted = unit.design_asset_take_down_date.format("M/D/YYYY")
  } else if (unit.marked_still_posted_at && !unit.design_asset_take_down_date){
    unit.design_asset_take_down_date_formatted = "Still Posted";
  } else {
    unit.design_asset_take_down_date_formatted = null;
  }

  unit.status_badge = statusBadge(unit, showAvailability);

  // We should have a way to use the values from the backend as we already have all this calculations there
  const total_price = priceForDuration(unit.quantity || 1, unit.price || 0, unit.start_date, unit.end_date);
  unit.value_program_price_for_duration = priceForDuration(unit.quantity || 1, unit.value_program_price || 0, unit.start_date, unit.end_date);
  const total_supplier_price = priceForDuration(unit.quantity || 1, unit.supplier_price || 0, unit.start_date, unit.end_date);
  unit.price_for_duration = total_price ? accounting.format(total_price, 2, "") : null;
  unit.supplier_price_for_duration = total_supplier_price ? accounting.format(total_supplier_price, 2, "") : null;
  unit.total_supplier_price_for_duration = accounting.format(((total_supplier_price || 0) + unit.supplier_production_cost + unit.supplier_installation_cost), 2, "");
  unit.total_price_for_duration = accounting.format((total_price || 0 ) + (unit.production_cost || 0) + (unit.installation_cost || 0), 2, "")

  unit.total_value_program_price_for_duration = accounting.format((unit.value_program_price_for_duration || 0) + (unit.production_cost || 0) + (unit.installation_cost || 0), 2, "")

  if (unit.total_supplier_price_for_duration && unit.total_value_program_price_for_duration) {
    unit.value_program_savings = accounting.format(parseFloat(unit.total_supplier_price_for_duration) - parseFloat(unit.total_value_program_price_for_duration), 2, "")
  } else {
    unit.value_program_savings = accounting.format("")
  }
  if (unit.lower_price && unit.impressions && unit.impressions > 0) {
    unit.lower_cpm = unit.lower_price / unit.impressions;
  }

  unit.geojsonId = unit.geojson_id;
  unit.status = unit.suplier_status;
  unit.tags = unit.tags || [];
  unit.tags_as_string = unit.tags.map(t => t.tag).join(", ")
  unit.attributes = unit.attributes || [];

  unit.screen_type = unit.screen;
  unit.has_restrictions = !!unit.restr;
  unit.restrictions_description = unit.r_desc;
  unit.is_cannabis_friendly = !!unit.cannab;
  unit.tab_panel_id = unit.tab_panel_id;
  return unit as CampaignUnit;
}

function parseBasicUnitFields(unit: any, showPriceForDuration: boolean): any {
  unit.label = showPriceForDuration ? unit.price_for_duration : unit.price;
  unit.color = colorForUnit(unit);
  unit.tagColor = tagColor(unit);
  unit.supplierName = unit.supplier_name;
  unit.lime = unit.supplier_name && unit.supplier_name.toLowerCase() === "lime";
  unit.faceId = unit.supplier_face_id;

  addPictureUrl(unit);

  unit.isPackage = unit.is_package;
  unit.hasRestrictions = unit.has_restrictions;
  unit.week_total_impressions = unit.impressions && unit.impressions / 4;
  unit.rate_card_price = unit.rate_card
  return unit;
}

function metersToPixelsAtMaxZoom(meters, latitude) {
  return meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
}

export const colorMap: { [k: string]: UnitColor } = {
  billboard: "#911884",
  street_furniture: "#3494D3",
  'street furniture': "#3494D3", // TODO normalize data, some units have this street type
  alternative: "#29A495",
  retail: "#29A495",
  sports_venues: "#29A495",
  wallscape: "#C323CF",
  transit: "#462ACF",
  bus: "#462ACF",
  rail: "#462ACF",
  airport: "#462ACF",
  taxi: "#462ACF",
  wildposting: "#32648A",
};

export function colorForUnit(unit): UnitColor {
  return colorMap[unit.unit_type] || colorMap["billboard"];
}

function tagColor(unit): string {
  switch (unit.tags?.length) {
    case 0:
      return "#4A90E2";
      break;
    case 1:
      return unit.tags[0].color;
      break;
    default:
      return "#828282"; // neutral color if multiple tags
  }
}

function addPictureUrl(unit): void {
  unit.is_default_image = unit.picture && unit.picture.startsWith("@D");
  unit.picture = pictureUrl(unit);
  unit.thumbnail_url = thumbnail_url(unit);
  unit.image_file_link = unit.picture;
}

function pictureUrl(unit): string | null {
  if (!unit.picture) return null;

  return unit.picture
    .replace(/^@P/, adquick_pop_base_url)
    .replace(/^@A/, adquick_base_url)
    .replace(/^@L/, lamar_base_url)
    .replace(/^@O/, outfront_base_url)
    .replace(/^@D/, adquick_default_url)
    .replace(/^@C/, cco_base_url);
}

function thumbnail_url(unit): string | null {
  return unit.picture && unit.picture.indexOf(adquick_base_url) === 0
    ? unit.picture.replace(/w=\d+/, "w=50").replace(/h=\d+/, "h=50")
    : unit.picture;
}

function priceForDuration(
  quantity: number,
  price: number,
  start_date: moment.Moment | null,
  end_date: moment.Moment | null,
) {
  if (!quantity || !price || !start_date || !end_date) return null;
  const days = moment.duration(end_date.diff(start_date)).asDays() + 1;
  const price_per_day = price / 4 / 7;
  return price_per_day * days * quantity;
}

export function statusBadge(unit: CampaignUnit, showAvailability: boolean): string | null {
  if (unit.in_cart) {
    return "cart";
  }

  if (showAvailability) {
    if (unit.supplier_status && unit.supplier_status.match(/_requested$/)) {
      return "updating";
    }
    if (unit.available) {
      return "available";
    }
  } else {
    if (unit.is_recommended) {
      return "recommended";
    }
  }

  return null;
}
