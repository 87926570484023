import { connect } from 'react-redux';
import React from 'react'
import BottomNav from './BottomNav';
import { savePlan, toggleMediaType, toggleObjective } from './actions';

class Basics extends React.Component {
  render() {
    return (
      <div className="section">
        <div className="row">
          <div className="col-md-4">
          <div className="subsection">
              <div className="heading">Objectives</div>
              <div className="subheading">Choose one or more objectives for your campaign</div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="choices">
                {this.props.objectives.map(choice =>
                  <div
                    key={`objective-${choice}`}
                    className={
                      `custom-check-group ${this.props.selected_objectives.includes(choice) ? 'is-checked': ''}`
                    }
                    onClick={ () => this.props.toggleObjective(choice) }
                  >
                    <span>{choice}</span>
                  </div>
                )}
            </div>
          </div>
        </div>
      <div className="row">
        <div className="col-md-4">
          <div className="subsection">
            <div className="heading">Media Types</div>
            <div className="subheading">Choose the outdoor media types you're interested in</div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="choices">
            {this.props.media_types.map(choice =>
            <div
              key={`media-type-${choice}`}
              className={
                `custom-check-group ${this.props.selected_media_types.includes(choice) ? 'is-checked' : ''}`
              }
              onClick={ () => this.props.toggleMediaType(choice) }
            >
              <span>{choice}</span>
            </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
      <div className="col-md-4"></div>  
        <div className="col-md-8">
          <BottomNav isReady={!_.isEmpty(this.props.selected_objectives) && !_.isEmpty(this.props.selected_media_types)}/>
        </div>
      </div>
      </div>
    )
  }
}

export default connect(
  ({ planner }) => ({ ...planner }),
  { toggleMediaType, toggleObjective, savePlan }
)(Basics)
