import React from "react";
import { connect } from "react-redux";

import { loadSupplierContacts } from "../actions";
import CampaignUnitListItem from "./CampaignUnitListItem";
import isEmpty from "lodash/isEmpty";

class CampaignUnitList extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      per_page: 20,
      limit: 20,
    };
    this.loadSupplierContacts = this.loadSupplierContacts.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.trackScrolling.bind(this), true);
    this.loadSupplierContacts();
  }

  async loadSupplierContacts() {
    const { loadSupplierContacts, campaign_token } = this.props;
    await loadSupplierContacts(campaign_token);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.trackScrolling.bind(this), true);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling(event) {
    const wrappedElement = document.getElementById("campaign-unit-list-items");
    if (wrappedElement && this.isBottom(wrappedElement)) {
      this.setState({ limit: this.state.limit + this.state.per_page });
      document.removeEventListener("scroll", this.trackScrolling);
    }
  }

  unitsToRender() {
    const { units } = this.props
    return units.slice(0, this.state.limit)
  }

  renderUnits() {
    return this.unitsToRender().map((unit, key) => {
      const campaignUnit = this.findCampaignUnitByUnitId(unit._id)
      const supplierContacts = this.findSupplierContactsByCampaignUnitId(campaignUnit)
      const campaignUnitId = isEmpty(campaignUnit) ? null : campaignUnit.id
      return (
        <CampaignUnitListItem
          key={`campaign-edit-unit-${unit.id}-${key}`}
          unit={unit}
          handleCheckBoxClick={this.props.handleCheckBoxClick}
          checkedStatus={this.props.checkedStatus(unit.campaign_unit_token)}
          campaign_token={this.props.campaign_token}
          campaign_permissions={this.props.campaign_permissions}
          onUnitListingClick={this.props.onUnitListingClick}
          removeCampaignUnits={this.props.removeCampaignUnits}
          supplier_contacts={supplierContacts}
          campaign_unit_id={campaignUnitId}
        />
      )
    }) 
  }

  findCampaignUnitByUnitId(unitId) {
    const { campaign_units } = this.props.unit_supplier_contacts || {}
    const campaignUnits = campaign_units || []
    return campaignUnits.find(cu => cu.unit_id === unitId) || {}
  }

  findSupplierContactsByCampaignUnitId({ id }) {
    const { unit_options } = this.props.unit_supplier_contacts || {}
    const unitOptions = unit_options || {}
    return unitOptions[id] || null
  }

  render() {
    return (
      <div className="campaign-unit-list-items">
        <div id="campaign-unit-list-items">{this.renderUnits()}</div>
      </div>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign }) => ({
    unit_supplier_contacts: campaign.campaign.unit_supplier_contacts,
  }),
  { loadSupplierContacts },
)(CampaignUnitList);
