import React, { Component } from 'react';

const MAX_LENGTH = 15;

class Size extends Component {

  prettyLabel(){
    let { isLime, size } = this.props;
    size = isLime ? 'Various' : size;
    size = _.truncate(size, { 'length': MAX_LENGTH })
    return size;
  }

  render(){
    return (
      <li>Size {this.prettyLabel()}</li>
    )
  }

}

export default Size
