import "./index.css";

import cs from 'classnames';
import React, { Component } from 'react';
import { connect } from "react-redux";

import { loadCampaignSupplierContracts } from '../../actions/campaign_supplier_contracts';
import Card from './Card';

class CampaignSupplierContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: null,
      filterByFavorite: false,
      filterByRecommended: false,
      filterByBooked: true,
      currentPage: 1,
      loading: false,
    };

    this.onBookingFilterChange = this.onBookingFilterChange.bind(this);
    this.onFavoritesChange = this.onFavoritesChange.bind(this);
    this.onRecommendedChange = this.onRecommendedChange.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
  }

  componentDidMount() {
    if (this.isFlagEnabled()) { return this.redirectToNewContractPage() }
    this.loadMore()
  }

  isFlagEnabled() {
    const { feature_flags } = this.props
    const newContractFlag = feature_flags.find(f => f.name == "vendor_contract_v2")
    if (!newContractFlag) { return }
    return newContractFlag.status
  }

  redirectToNewContractPage() {
    const { campaign } = this.props
    return window.location.assign(`/campaigns/${campaign.token}/manage/contracts_v2`)
  }

  onFavoritesChange() {
    const { filterByFavorite } = this.state;

    this.setState({
      filterByFavorite: !filterByFavorite,
      currentPage: 1,
    }, this.loadMore)
  }

  onBookingFilterChange() {
    const { filterByBooked } = this.state;
    this.setState({ 
      filterByBooked: !filterByBooked, 
      currentPage: 1,
    }, this.loadMore);
  }

  onRecommendedChange() {
    const { filterByRecommended } = this.state;

    this.setState({
      filterByRecommended: !filterByRecommended,
      currentPage: 1,
    }, this.loadMore);
  }

  renderFilters() {
    const { filterByFavorite, filterByRecommended, filterByBooked } = this.state;
    return (
      <div className="filter_contracts_buttons">
        <label
          className={cs({
            "filters__sort filters__favoritesOnly": true,
            "active filters__favoritesOnly_active": filterByBooked
          })}
          onClick={this.onBookingFilterChange}
        >
          Booked Units
        </label>
        <label
          className={cs({
            "filters__sort filters__favoritesOnly": true,
            "active filters__favoritesOnly_active": filterByFavorite
          })}
          onClick={this.onFavoritesChange}
        >
          <i className="fa fa-heart"></i>
          Favorites
        </label>
        <label
          className={cs({
            "filters__sort filters__recommendedOnly": true,
            "active filters__recommendedOnly_active": filterByRecommended
          })}
          onClick={this.onRecommendedChange}
        >
          <span className="badge badge_recommended">R</span>
          Recommended
        </label>
        <br/>
        <div style={{color: '#858585', fontStyle: 'italic', fontSize: '12px', padding: '16px 8px'}}>*Vendors with more than 500 booked units are hidden</div>
      </div>
    )
  }

  onLoadMore() {
    const { currentPage } = this.state; 
    this.setState({ currentPage: currentPage + 1, loading: true }, this.loadMore)
  }

  async loadMore() {
    const { filterByFavorite, filterByRecommended, filterByBooked } = this.state; 
    const { loadCampaignSupplierContracts, campaign } = this.props;
    await loadCampaignSupplierContracts(campaign.campaignId, this.state.currentPage, filterByFavorite, filterByRecommended, filterByBooked);
    this.setState({ loading: false});
  }

  renderPagination() {
    const { loading } = this.state;
    return (
    <button className="load_more_contracts" disabled={loading} onClick={this.onLoadMore}>{loading ? 'Loading...' : 'Load More' }</button>
    )
  }

  render() {
    const { filterByFavorite, filterByRecommended, filterByBooked } = this.state;
    const { campaign_supplier_contracts, user } = this.props;
    const groupedBySupplier = _.groupBy(campaign_supplier_contracts, contract => {
      return _.get(contract, "supplier.name", "Unknown");
    });
    const supplierNames = Object.keys(groupedBySupplier).sort(Intl.Collator().compare);
    return (
      <div id="supplier_contracts_list">
        {this.renderFilters()}
        <div className="supplier_contracts">
          {supplierNames.map((name, idx) => {
            const supplierContracts = groupedBySupplier[name];
            const [referenceContract, contracts] = _.partition(supplierContracts, c => c.is_reference);
            return (
              <Card
                referenceContract={referenceContract[0]}
                contracts={contracts}
                filterByFavorite={filterByFavorite}
                filterByRecommended={filterByRecommended}
                filterByBooked={filterByBooked}
                user={user}
                key={idx}
              />
            );
          })}
        </div>
        {supplierNames.length > 0 && this.renderPagination()}
      </div>
    );
  }
}

const mapStateToProps = ({ campaign, campaign_supplier_contracts, feature_flags }) => ({
  campaign,
  feature_flags,
  campaign_supplier_contracts
})

export default connect(
  mapStateToProps,
  {
    loadCampaignSupplierContracts
  }
)(CampaignSupplierContracts)
