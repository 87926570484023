import { connect } from 'react-redux';
import { formatNumber, formatMoney } from 'accounting';
import React from 'react';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import {
  budgetByShowingLevel,
  impressionsByShowingLevel
} from '../showing_level';
import {
  formatSendXImpressions,
  saveSpendGroup,
  updateLastTypedAt,
  updateSpendXImpressions
} from '../actions';
import Location from './Location';

class Plan extends React.Component {

  componentDidMount() {
    this.props.formatSendXImpressions(this.props);
  }

  updateSpendXImpressions(payload) {
    this.props.updateSpendXImpressions(payload);
    this.props.updateLastTypedAt();
    setTimeout(() => {
      this.props.saveSpendGroup(payload);
      this.props.formatSendXImpressions(payload)
    }, 950);
  }
  renderSpendXImpressions() {
    const { id, spend, target_impressions } = this.props;
    return (
      <div className="market-configuration-adjust-spend">
        <div className="spend-input-group">
          <div className="spend-label">Spend</div>
          <div className="spend-input">
            <NumberFormat value={spend || ''} 
              decimalScale={0}
              thousandSeparator={true} prefix={'$'} 
              onValueChange={(values) => {
                const {formattedValue, value, floatValue} = values;
                this.updateSpendXImpressions({id, spend: value});
              }}/>
            <div className="placeholder">{" / 4 weeks"}</div>
          </div>
        </div>
        <div className="spend-group-icon"><i className="fa fa-exchange"/></div>
        <div className="spend-input-group">
          <div className="spend-label">Target impressions</div>
          <div className="spend-input">
            <NumberFormat
              value={target_impressions || ''} 
              thousandSeparator={true}
              decimalScale={0}
              onValueChange={(values) => {
                const {formattedValue, value, floatValue} = values;
                this.updateSpendXImpressions({id, target_impressions: value});
              }}/>
            <div className="placeholder">{" / 4 weeks"}</div>
          </div>
        </div>
      </div>
    )
  }

  renderShowingLevels(){
    return (
      <div className="market-configuration-recommend-spend">
        <div className="recommend-spend-title">Recommended Spend</div>
        <div className="recommend-spend-show-level">
          { this.renderShowingLevel('Entry', 10.0) }
          { this.renderShowingLevel('Saturation', 25.0) }
          { this.renderShowingLevel('Domination', 50.0)}
        </div>
      </div>
    )
  }

  formatCash(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }

  renderShowingLevel(name, level){
    const { id, spend, target_impressions } = this.props;
    const { population, cpm } = this.props.location;
    const spend_level = _.ceil(budgetByShowingLevel(cpm, population, level));
    return (
      <div 
        className="showlevel" key={name} 
        onClick={()=>{this.updateSpendXImpressions({id, spend: spend_level})}}>
        <div className="title">{name}</div>
        <div className="spend-over">
          {`$${this.formatCash(spend_level)} / 4w`}
        </div> 
      </div>
    )
  }

  render() {
    return (
      <div className="market-configuration-info">
        { this.renderSpendXImpressions() }
        { this.renderShowingLevels() }
      </div>
    )
  }
}

export default connect(
  () => ({}),
  { updateSpendXImpressions, formatSendXImpressions, updateLastTypedAt, saveSpendGroup }
)(Plan);
