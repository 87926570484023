import { Grid } from "@adquick/ui";
import cs from "classnames";
import { isEmpty } from "lodash";
import React, { Component } from "react";

import GlobalActions from "../../../actions/GlobalActions";

interface ShippingAddressModalProps {
  onSubmit: () => any;
  popupOptions: any;
}

type TAddressField = {
  error: boolean;
  value: string;
};

interface ShippingAddressModalState {
  slug: TAddressField;
  attention: TAddressField;
  email: TAddressField;
  address_line: TAddressField;
  city: TAddressField;
  state: TAddressField;
  zip_code: TAddressField;
}

class ShippingAddressModal extends Component<ShippingAddressModalProps, ShippingAddressModalState> {
  constructor(props: ShippingAddressModalProps) {
    super(props);
    const {
      popupOptions: { address, pre_filled_address },
    } = this.props;

    this.state = {
      slug: {
        value: address.slug || pre_filled_address.name || "",
        error: isEmpty(address.slug) && isEmpty(pre_filled_address.name),
      },
      attention: {
        value: address.attention || pre_filled_address.attention || "",
        error: isEmpty(address.attention) && isEmpty(pre_filled_address.attention),
      },
      email: {
        value: address.email || pre_filled_address.email || "",
        error: isEmpty(address.email) && isEmpty(pre_filled_address.email),
      },
      address_line: {
        value: address.address_line || pre_filled_address.address_line || "",
        error: isEmpty(address.address_line) && isEmpty(pre_filled_address.address_line),
      },
      city: {
        value: address.city || pre_filled_address.city || "",
        error: isEmpty(address.city) && isEmpty(pre_filled_address.city),
      },
      state: {
        value: address.state || pre_filled_address.state || "",
        error: isEmpty(address.state) && isEmpty(pre_filled_address.state),
      },
      zip_code: {
        value: address.zip_code || pre_filled_address.zip_code || "",
        error: isEmpty(address.zip_code) && isEmpty(pre_filled_address.zip_code),
      },
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  private onClose() {
    GlobalActions.closePopup();
  }

  private onInputChange(event) {
    const target = event.target;
    this.validateField(target);
  }

  private validateField({ name, value }) {
    const isInvalid = isEmpty(value);
    //@ts-ignore
    this.setState({ [name]: { value, error: isInvalid } });
  }

  private renderTitle() {
    const { popupOptions } = this.props;
    const { address } = popupOptions;
    if (!isEmpty(address)) {
      return (
        <h4>
          <b>Edit {address.slug}</b>
        </h4>
      );
    }
    return (
      <h4>
        <b>New Shipping Address</b>
      </h4>
    );
  }

  private handleSubmitButtonText() {
    const { popupOptions } = this.props;
    const { address } = popupOptions;
    if (!isEmpty(address)) {
      return "Update";
    }
    return "Create";
  }

  private async handleSubmit() {
    const { popupOptions } = this.props;
    const { supplier_id, loadSupplierAddresses, createShippingAddress, updateShippingAddress, attachShippingAddressToProductionOrder, address } = popupOptions;
    const payload = this.formatPayload();
    if (this.isPayloadInvalid(payload)) {
      return GlobalActions.showError("The form is invalid, please check the error fields.");
    }
    if (!isEmpty(address)) {
      updateShippingAddress(supplier_id, address.id, payload);
      return this.onClose();
    }
    const { shipping_address } = await createShippingAddress(supplier_id, payload);
    attachShippingAddressToProductionOrder(shipping_address);
    return this.onClose();
  }

  private formatPayload() {
    return Object.keys(this.state).reduce((acc, key) => {
      acc[key] = this.state[key].value;
      return acc;
    }, {});
  }

  private isPayloadInvalid(payload) {
    const invalid: Array<string> = [];
    const keys = Object.keys(payload);
    keys.map(key => {
      if (isEmpty(payload[key])) {
        invalid.push(key);
      }
    });
    return !isEmpty(invalid);
  }

  public render() {
    const { slug, address_line, city, state, zip_code, attention, email } = this.state;
    return (
      <div className="default_modal_container" onClick={e => e.stopPropagation()}>
        <div className="default_modal">
          <div className="modal_header">
            {this.renderTitle()}
            <div className="modal_close">
              <div className="close_button" onClick={this.onClose}></div>
            </div>
          </div>
          <div className="modal_body" style={{ display: "grid", gridGap: "5px" }}>
            <div className="field">
              <label>Name</label>
              <input
                type="text"
                className={cs("form-control", { has_error: slug.error })}
                name="slug"
                value={slug.value}
                onChange={this.onInputChange}
              />
            </div>
            <div className="field">
              <label>Vendor</label>
              <input
                type="text"
                className={cs("form-control", { has_error: attention.error })}
                name="attention"
                value={attention.value}
                onChange={this.onInputChange}
              />
            </div>
            <div className="field">
              <label>Email</label>
              <input
                type="text"
                className={cs("form-control", { has_error: email.error })}
                name="email"
                value={email.value}
                onChange={this.onInputChange}
              />
            </div>
            <div className="field">
              <label>Address line</label>
              <input
                type="text"
                className={cs("form-control", {
                  has_error: address_line.error,
                })}
                name="address_line"
                value={address_line.value}
                onChange={this.onInputChange}
              />
            </div>
            <div className="field">
              <label>City</label>
              <input
                type="text"
                className={cs("form-control", { has_error: city.error })}
                name="city"
                value={city.value}
                onChange={this.onInputChange}
              />
            </div>
            <div className="field">
              <label>State</label>
              <input
                type="text"
                className={cs("form-control", { has_error: state.error })}
                name="state"
                value={state.value}
                onChange={this.onInputChange}
              />
            </div>
            <div className="field">
              <label>Zip code</label>
              <input
                type="text"
                className={cs("form-control", { has_error: zip_code.error })}
                name="zip_code"
                value={zip_code.value}
                onChange={this.onInputChange}
              />
            </div>
            <div className="modal_footer">
              <button className="primary-button" onClick={this.handleSubmit}>
                {this.handleSubmitButtonText()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShippingAddressModal;
