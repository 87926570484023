import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";

const POPUP_WIDTH = 180;

class FlightTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = Object.assign(
      {
        isExpanded: false,
        booked: false,
        held: false,
        movable: false,
        proposed: false
      },
      this.props.initialValue,
    );
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isActive !== this.props.isActive && !nextProps.isActive) {
      this.onClear();
    }
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear(triggerFilter = false) {
    this.setState(
      {
        booked: false,
        held: false,
        movable: false,
        proposed: false
      },
      triggerFilter ? this.onFilter : null,
    );
  }

  onFilter() {
    const { booked, held, movable, proposed } = this.state;
    const { filterUnits } = this.props;
    _.defer(filterUnits, { booked, held, movable, proposed });
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { booked, held, movable, proposed } = this.state;
    if (!booked && !held && !movable && !proposed) return "Flight Type";
    const labels = [
      ['Booked', booked],
      ['On Hold', held],
      ['Movable', movable],
      ['Proposed', proposed]
    ].map((type) => {
      if (type[1]) { return type[0]; }
      else { return ''; }
    }).filter(n => n)
    if (labels.length === 1 ) { return labels[0]; }
    else { return `${labels[0]} + ${labels.length - 1} more`; }
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { booked, held, movable, proposed } = this.state;
    return (
      <div className="filter_popup screen_type_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>Flight Type</h4>
        <ul>
          <li onClick={() => this.setState({booked: !!!booked}, this.onFilter)} className={cs({ active: booked })}>
            Booked
            <i className="fa fa-check" />
          </li>
          <li onClick={() => this.setState({held: !!!held}, this.onFilter)} className={cs({ active: held })}>
            On Hold
            <i className="fa fa-check" />
          </li>
          <li onClick={() => this.setState({movable: !!!movable}, this.onFilter)} className={cs({ active: movable })}>
            Movable
            <i className="fa fa-check" />
          </li>
          <li onClick={() => this.setState({proposed: !!!proposed}, this.onFilter)} className={cs({ active: proposed })}>
            Proposed
            <i className="fa fa-check" />
          </li>
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a className="clear hidden-xs" onClick={e => this.onClear(true)}>
              Clear
            </a>
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(null, { filterUnits })(FlightTypeFilter);
