import * as React from 'react';

import SegmentApiKey from '../../Segment/ApiKey';

const segmentIntegrationModal = (props) => (
  <div className="modal_default">
    <div className="modal_header">
      <h5>Segment Integration</h5>
    </div>
    <div className="modal_body">
      <div className="field">
        <div className="description">
          Use this API Key to setup the AdQuick destination on your Segment Platform.
        </div>
        <div className="description">
         You will be able to track all your Segment events.
        </div>
        <div className="description">
          <a href="https://adquick.com/docs/segment">
            You can click here to view the full setup documentation.
          </a>
        </div>
        <SegmentApiKey style={{margin: "10px 0"}}/>
      </div>
      <div className="modal_footer">
        <button className="primary-button" onClick={props.onSubmit}> Close</button>
      </div>
    </div>
  </div>
);
segmentIntegrationModal.displayName = "SegmentIntegrationModal";

export default segmentIntegrationModal;
