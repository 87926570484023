const SUPPLIER_TASK_API_URL = "/api/v1/supplier_tasks";
import { post } from "../../../utils/api";

const prefix = "requests/rfp_response"

export const COMPLETE_SUPPLIER_TASK = `${prefix}/COMPLETE_SUPPLIER_TASK`;
export const completeSupplierTask = (supplier_task_id) => async (dispatch, getState) => {
  try {
    let supplier_task = getState().adquick_requests_ui.rfp_response.supplier_task;
    if (supplier_task.completed) return supplier_task;

    supplier_task = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/completed`);

    dispatch({ type: COMPLETE_SUPPLIER_TASK, payload: supplier_task })
    return supplier_task;
  } catch (error) {
    console.log("Complete SupplierTask failed.", error);
    throw error;
  }
};
