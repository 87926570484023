import React from 'react';

class ToggleFilter extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    const { attributeName, setFilter } = this.props;
    const checked = this.isChecked();
    setFilter(attributeName, !!!checked);
  }

  isChecked() {
    const { filters, attributeName } = this.props;
    return filters[attributeName] === "true" || filters[attributeName] === true;
  }

  render() {
    const { toggleOnName, toggleOffName } = this.props;
    const checked = this.isChecked();

    return (
      <div className="toggle_filter">
        <div
          className={`toggle_button ${!!!checked ? 'selected' : ''}`}
          onClick={this.onToggle}
        >{toggleOffName}</div>
        <div
          className={`toggle_button ${checked ? 'selected' : ''}`}
          onClick={this.onToggle}
        >{toggleOnName}</div>
      </div>
    )
  }
};

export default ToggleFilter;
