import { post } from "../utils/api";

const prefix = "data_layers";
const DATA_LAYERS_API_URL = "/api/v1/data_layers";

export const CREATE_CUSTOM_BOUNDS = `${prefix}/CREATE_CUSTOM_BOUNDS`;
export const createCustomBounds = (campaign_id, coordinates) => async dispatch => {
  const response = await post(`${DATA_LAYERS_API_URL}/highlighted_area?campaign_id=${campaign_id}`, {
    area_type: "custom_bounds",
    coordinates,
  });
  const campaignDataLayerCreated = response[0];
  dispatch({ type: CREATE_CUSTOM_BOUNDS, payload: { campaignDataLayerCreated } });
};
