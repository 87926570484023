import React, { Component } from 'react';

class NextBooked extends Component {
  show(){
    const { diff } = this.props;
    return diff && diff > 0
  }

  formattedBookedDiff(){
    const { diff } = this.props;
    return `Next Booked: ${diff} days`;
  }

  render(){
    if (!this.show()) return null;
    return (
      <li>{this.formattedBookedDiff()}</li>
    )
  }

}

export default NextBooked
