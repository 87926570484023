import React, { Component } from 'react';

const MAX_LENGTH = 15;

class Size extends Component {

  label(){
    const { isLime, size } = this.props;
    const label = isLime ? 'Various' : size;
    return label;
  }

  prettyLabel(){
    return _.truncate(this.label(), { 'length': MAX_LENGTH }).replace(/[xX]/, "×");
  }

  render(){
    return (
      <li
        title={this.label()}
        style={{ fontSize: 19, color: "#273438" }}
      >
        {this.prettyLabel()}
      </li>
    )
  }

}

export default Size
