import React from 'react';
import { browserHistory } from 'react-router-legacy';
import GlobalActions from '../actions/GlobalActions';
import createClass from 'create-react-class';

export default createClass({
  displayName: 'ConfirmSubmitModal',

  getInitialState() {
    const options = this.props.options
    return {
      headerText: options.headerText || 'Are you Sure?',
      bodyText: options.bodyText || '',
      confirmButtonText: options.confirmButtonText || 'Submit',
      cancelButtonText: options.cancelButtonText || 'Cancel',
      data: options.data
    };
  },

  closePopup() {
    GlobalActions.closePopup();
  },

  onConfirm() {
    const options = this.props.options;
    const onConfirmCallback = options.onConfirmSubmit;
    onConfirmCallback(options.data);
    this.closePopup();
  },

  render() {
    const { headerText, bodyText, cancelButtonText, confirmButtonText } = this.state;

    return (
      <div className="confirm-submit-modal" onClick={e => e.stopPropagation()}>
        <div className="modal-heading">
          {headerText}
        </div>
        <div className="modal-default">
          <div>
            <p className="confirmation-modal-text">
              {bodyText}
            </p>
          </div>
          <div className="button-area">
            <button className="btn btn-default" type="cancel" onClick={this.closePopup}>
              {cancelButtonText}
            </button>
            <button className="btn btn_primary" type="submit" onClick={this.onConfirm}>
              {confirmButtonText}
            </button>
          </div>
        </div>
      </div>
    );
  },
});
