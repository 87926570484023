import React from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import {
  filterUnits,
} from '../actions/UnitsActions';
import Tooltip from "rc-tooltip";

class FavoritesFilter extends React.Component {

  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
  }

  onFilter() {
    const { isActive, filterUnits, handleQueryParams } = this.props;
    const filter = { only_favorites: !isActive }
    _.defer(filterUnits, filter)
    handleQueryParams(filter)
  }

  render() {
    const { isActive } = this.props;
    return (
      <Tooltip
        placement="bottom"
        trigger={['hover']}
        overlayStyle={{ width: '200px', height: "200px"}}
        overlay={<span style={{ width: "fit-content" }}>Click Master Favorites button to view total favorited cost, impressions and aggregate CPM.</span>}
      >
        <label
          className={cs({
            "filters__favoritesOnly": true,
            "active filters__favoritesOnly_active": isActive
          })}
          onClick={this.onFilter}
        >
          <i className="fa fa-heart"></i>
          <span className="text-label">Favorites</span>
        </label>
      </Tooltip>
    );
  }
};

export default connect(
  null,
  { filterUnits }
)(FavoritesFilter)
