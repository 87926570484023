import React, { Component } from 'react';

const MAX_LENGTH = 12;

class FaceId extends Component {

  prettyLabel(){
    let { label } = this.props;
    label = _.startCase(label);
    label = _.truncate(label, { 'length': MAX_LENGTH })
    return label;
  }

  render(){
    return (
      <span className="face_id">{this.prettyLabel()}</span>
    )
  }

}

export default FaceId
