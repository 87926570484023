import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import Tooltip from "rc-tooltip";

import UploadDesignAssetAction from "./UploadDesignAssetAction";
import {
  approveDesignAsset,
  deleteDesignAsset,
  rejectDesignAsset
} from "../../../actions/campaign_design_actions";
import {
  canReject,
  isRejected,
  isSubmitted
} from "../../../models/design_assets";

const ASSETS = (window as any).ASSETS;
interface Props {
  unit: any;
  campaign: any;
  campaign_permissions: any;
  currentUser: any;
  status: any;
  approveDesignAsset: (campaignId, design_asset_id) => void;
  rejectDesignAsset: (campaignId, design_asset_id, reason) => void;
  deleteDesignAsset: (campaignId, design_asset_id) => void;
}

class DesignAssetCardItem extends PureComponent<Props, any> {
  public render() {
    const { unit: { design_assets } } = this.props;
    return <div>{design_assets.map(asset => this.renderCard(asset))}</div>;
  }

  private renderCard(asset) {
    const { installer_name } = this.props.unit;
    const hasProductionOrder = asset.has_production_order;
    const pendingProductionOrder = asset.marked_for_production && hasProductionOrder;
    const hasInstaller = !!installer_name;
    return (
      <div key={`asset_${asset.id}`} className="design_card_item space_between">
        <div style={{display: 'flex'}}>
          <span style={{ marginRight: 5 }}>Design Asset:</span>
          {this.renderReplacementInfo(asset)}
          {this.renderDesignAssetLink(asset)}
          <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>
          {this.renderDownloadLink(asset)}
          {this.renderUploadButton(asset)}
          {this.renderApproveLink(asset)}
          {this.renderRejectLink(asset)}
          {this.renderDeleteLink(asset)}
          {(pendingProductionOrder || hasProductionOrder) && (
            <div
              style={{
                marginLeft: "10px",
                opacity: pendingProductionOrder ? 0.3 : 1
              }}
            >
              <img src={ASSETS["cg"]} />
            </div>
          )}
        </div>
        {hasInstaller && this.renderInstallerIcon()}
      </div>
    );
  }

  private renderInstallerIcon() {
    const { installer_name } = this.props.unit;
    return (
      <div className="installer_container">
        <i className="fas fa-user-cog" />
        <div className="tip">
          {installer_name}
        </div>
      </div>
    )
  }

  private renderReplacementInfo(asset) {
    const { uploaded_on, previous_upload_at } = asset;

    if (previous_upload_at) {
      return (
        <Tooltip
          placement="top"
          overlay={`Previous design was uploaded ${moment(
            previous_upload_at
          ).fromNow()}, then replaced ${moment(uploaded_on).fromNow()}`}
        >
          <span className="badge badge-light">
            <i className="fa fa-warning" /> Replacement
          </span>
        </Tooltip>
      );
    } else {
      return null;
    }
  }

  private renderDesignAssetLink(asset) {
    if (asset.asset_url) {
      return (
        <a target="_blank" href={asset.asset_url}>
          <i className="fa fa-file-o margin-right" aria-hidden="true" />
          {asset.asset_name}
        </a>
      );
    } else {
      return null;
    }
  }

  private renderDownloadLink(asset) {
    if (asset.asset_url) {
      return (
        <a style={{ fontWeight: 600 }} target="_blank" href={asset.asset_url}>
          Download
        </a>
      );
    } else {
      return null;
    }
  }

  private renderUploadButton(asset) {
    const { unit, campaign, campaign_permissions } = this.props;
    if (campaign_permissions.can_upload_design_asset) {
      return (
        <UploadDesignAssetAction
          asset={asset}
          unit={unit}
          campaign_id={campaign.campaignId}
        >
          <a
            style={{ marginLeft: 10, fontWeight: 600 }}
            onClick={e => e.preventDefault()}
          >
            Upload
          </a>
        </UploadDesignAssetAction>
      );
    }
  }

  private renderApproveLink(asset) {
    const { unit, campaign_permissions } = this.props;
    if (isRejected(unit) && campaign_permissions.can_approve_design_asset) {
      return (
        <a
          style={{ marginLeft: "10px", color: "#44AC6B" }}
          onClick={() => this.approveDesignAsset(asset.id)}
        >
          Approve
        </a>
      );
    }
  }

  private approveDesignAsset(design_asset_id) {
    const { campaign } = this.props;
    this.props.approveDesignAsset(campaign.campaignId, design_asset_id);
  }

  private renderRejectLink(asset) {
    const { unit, campaign_permissions } = this.props;
    if (
      canReject(unit) &&
      campaign_permissions.can_reject_design_asset
    ) {
      return (
        <a
          style={{ marginLeft: "10px", color: "#858585" }}
          onClick={() => this.rejectDesignAsset(asset.id)}
        >
          Reject
        </a>
      );
    }
  }

  private rejectDesignAsset(design_asset_id) {
    const reason = prompt(
      "Please inform the customer why you are rejecting this design asset:",
      ""
    );
    if (_.isEmpty(reason)) {
      return;
    }
    const { campaign } = this.props;
    this.props.rejectDesignAsset(campaign.campaignId, design_asset_id, reason);
  }

  private renderDeleteLink(asset) {
    const { campaign_permissions } = this.props;
    if (
      campaign_permissions.can_remove_design_asset &&
      isSubmitted(this.props.unit)
    ) {
      return (
        <a
          style={{ marginLeft: "10px", color: "#db5f44" }}
          onClick={() => this.deleteDesignAsset(asset.id)}
        >
          Delete
        </a>
      );
    }
  }

  private deleteDesignAsset(design_asset_id) {
    const confirm_delete = confirm("Are you sure?");
    if (confirm_delete) {
      const { campaign } = this.props;
      this.props.deleteDesignAsset(campaign.campaignId, design_asset_id);
    }
  }
}

export default connect(
  // @ts-ignore
  ({ currentUser }) => ({ currentUser }),
  { approveDesignAsset, deleteDesignAsset, rejectDesignAsset }
)(DesignAssetCardItem);
