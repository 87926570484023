import { CLEAR_SEGMENT, LOAD_SEGMENT, SELECT_METRIC } from '../actions/segment';

const initialState = {
  loaded: false,
  metrics: {},
  selected_metric: ''
};

export default (state = initialState, action) => {
  switch(action.type) {
    case CLEAR_SEGMENT: return initialState;
    case LOAD_SEGMENT: return { loaded: true, metrics: action.payload };
    case SELECT_METRIC: return { ...state, selected_metric: action.payload };
    default: return state;
  }
}
