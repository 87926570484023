import React from 'react';
import { browserHistory } from 'react-router-legacy';
import GlobalActions from '../actions/GlobalActions';
import createClass from 'create-react-class';
import { formatNumber } from 'accounting';

export default createClass({
  displayName: 'ConfirmGeniusCampaign',

  getInitialState() {
    return {
      name: '',
      saving: false
    };
  },

  componentDidUpdate(prevProps, prevState) {
    this.refs._input.focus();
  },

  onConfirm(e) {
    e.preventDefault();

    const { addSpendGroupToCampaign, spend_group_to_add, selected_demographics } = this.props.options
    const { spend } = spend_group_to_add
    const demographic_ids = Object.values(selected_demographics).map(d => d.id);
    this.setState({ saving: true })
    addSpendGroupToCampaign(
      demographic_ids,
      spend_group_to_add,
      this.state.name,
      {},
      spend
    )
  },

  updateCampaignName(name) {
    const { updateNewCampaignName } = this.props.options
    this.setState({ name })
    updateNewCampaignName(name)
  },

  render() {
    const { name, saving } = this.state

    return (
      <div className="modal-unit" style={{padding: '20px',margin: '0 auto'}} onClick={e => e.stopPropagation()}>
        <div className="modal-default">
          <form className="form" onSubmit={this.onConfirm} style={{display: 'grid', gridGap: '20px', width: 'auto'}}>
            <div>
              <label style={{marginBottom: '15px',fontSize: '16px'}}>Name your Campaign</label>
              <input
                type="text"
                className="form-control"
                placeholder="A descriptive campaign title"
                value={name}
                onChange={(e) => this.updateCampaignName(e.target.value)}
                disabled={saving}
                autoFocus="true"
                ref="_input"
              />
            </div>
            <div style={{display: 'flex'}}className="button-area">
              <button style={{flexGrow: 1,fontSize: '16px',padding: '10px'}} disabled={saving} className="btn btn_primary" type="submit">
                { !saving && <span>
                    Save Plan & View Campaign
                  </span>
                }
                { saving &&
                  <i className="fa fa-spinner fa-spin"></i>
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  },
});
