import React, { Component } from 'react';
import { paramsAdd, paramsRemove } from "../../utils/QueryString";

export default class FaceIdInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search_by_face_id: null
    }

    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    this.debounceSearch = _.debounce(() => { this.searchFaceId() }, 500)
  }

  componentDidUpdate(prevProps) {
    const { unit_filters: { search_by_face_id } } = this.props
    if (!search_by_face_id && prevProps.unit_filters.search_by_face_id) { this.setState({ search_by_face_id: null }, paramsRemove('search_by_face_id')) }
    if (search_by_face_id !== prevProps.unit_filters.search_by_face_id) { this.setState({ search_by_face_id }) }
  }

  onChange(e) {
    const { target: { value } } = e
    this.setState({ search_by_face_id: value }, this.debounceSearch() )
  }

  searchFaceId() {
    const { search_by_face_id } = this.state
    const { unit_filters, onFilter } = this.props
    const filter = {...unit_filters, ...{ search_by_face_id } }
    onFilter(filter)
    this.handleQueryParams(search_by_face_id)
  }

  handleQueryParams(query) {
    if (!!query) { return paramsAdd('search_by_face_id', query) }
    return paramsRemove('search_by_face_id')
  }

  render() {
    const { search_by_face_id } = this.state
    return (
      <div className="search_by_face_id_container">
        <input
          className="search_by_face_id_input"
          onChange={this.onChange}
          placeholder="Filter by Face ID"
          value={search_by_face_id || ""}
        />
      </div>
    )
  }
}

