import { connect } from 'react-redux';
import React, { Component } from 'react';

import { endUnitLoading } from '../../actions/UnitsActions';
import {
  toggleMapCluster,
  toggleMapSize,
  toggleMapTraffic,
  toggleSelectedMarkers,
  toggleSelectedPolygons,
  updateCurrentCustomPOI,
} from '../NewMap/actions';
import Controls from '../NewMap/Controls';
import MapBox from '../NewMap/MapBox';
import Markets from '../NewMap/Markets';
import Modals from '../NewMap/Modals';
import NewMapStore from '../../stores/NewMapStore';
import PlacesStore from '../../stores/PlacesStore';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = { initialized: false };
  }
  componentDidMount() {
    const mapProps = {
      rich_pins: this.props.rich_pins,
      draw_tool: this.props.draw_tool,
      user: this.props.user,
      bounds: this.props.map.bounds,
      onMove: this.props.onMove,
      onUnitClick: this.props.onMarkerClick,
      filterUnits: this.props.filterUnits,
      toggleSelectedMarkers: this.props.toggleSelectedMarkers,
      toggleSelectedPolygons: this.props.toggleSelectedPolygons,
      zoom: this.props.zoom,
      center: this.props.center,
      endUnitLoading: this.props.endUnitLoading
    };

    const mapbox = new MapBox('new-map', mapProps).init();
    this.mapbox = mapbox;
    if(typeof this.props.onMapboxCreated !== 'undefined') {
      this.props.onMapboxCreated(mapbox);
    }
    this.setState({ initialized: true });

    this.updateMap();
    if (this.props.loadPlacemarkers){
      this.unsubscribeList = [
        PlacesStore.listen(this.onPlaceChange.bind(this))
      ];
      this.loadCampaignPlacemarkers();
    }
  }

  onPlaceChange(event_name) {
    if (event_name === 'places:updated') {
      this.loadCampaignPlacemarkers();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.units != this.props.units || prevProps.map != this.props.map) {
      this.mapbox.zip_codes = this.props.zip_codes;
      this.updateMap();
    }
  }

  loadCampaignPlacemarkers() {
    const { campaign } = this.props;
    if (!campaign || !campaign.token) return;

    NewMapStore.loadCampaignPlacemarkers(campaign.token).then(placemarkers => {
      this.mapbox.placemarkers = placemarkers;
      this.mapbox.updateMap();
    });
  }

  updateMap() {
    this.mapbox.props = this.props.map;
    this.mapbox.units = this.props.units;
  }

  loading() {
    if (!this.props.map.loading) return <div/>;

    return (
      <div className="map-loading">
        <p>Loading...</p>
      </div>
    );
  }

  render() {
    const { initialized } = this.state;

    return (
      <div style={{ height:'100%',position:'relative'}}>
        <Modals { ...this.props }/>
        {!this.props.hideControls && <Controls { ...this.props }/>}
        <div className="new-map"  style={{ width: '100%', height: '100%' }}>
          { this.loading() }
          <div style={{ height: '100%' }} id='new-map'></div>
          {initialized && this.props.markets &&
            <Markets markets={this.props.markets} mapbox={this.mapbox}/>
          }
        </div>
      </div>
    );
  }
}

Map.displayName = 'Browse/Map';
export default connect(({ map }) => ({ map }), {
  toggleMapCluster,
  toggleMapSize,
  toggleMapTraffic,
  toggleSelectedMarkers,
  toggleSelectedPolygons,
  endUnitLoading,
  updateCurrentCustomPOI
})(Map);
