import produce from "immer";
import PropTypes from "prop-types";
import queryString from "query-string";
import React from "react";
import { Modal } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import { browserHistory } from "react-router-legacy";

import GlobalActions from "../../../actions/GlobalActions";
import MapActions from "../../../actions/MapActions";
import CampaignDataLayerModel from "../../../models/CampaignDataLayerModel";
import { sidebarUnits, withinBounds } from "../../../models/Units";
import MapStore from "../../../stores/MapStore";
import AddDataLayer from "./AddDataLayer";
import DataLayerLegend from "./DataLayerLegend";

const DISABLED_DATA_LAYERS = ["HighlightedArea"];

class DataLayers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign_data_layers: [],
      legend: null,
      visible_data_layer: null,
      loading_data_layer: false,
    };
  }

  componentWillMount() {
    this.loadDataLayers();
  }

  componentDidMount() {
    this.component_path = location.pathname;
    this.expandDataLayerSection();
    this.unsubscribe_list = [
      browserHistory.listen(this.onRouteChange.bind(this)),
      MapStore.listen(this.onMapStoreChange.bind(this)),
    ];
  }

  onMapStoreChange(value) {
    switch (value) {
      case "map:loaded":
        this.expandDataLayerSection();
        break;
    }
  }

  componentWillUnmount() {
    this.unsubscribe_list.map(fn => fn());
  }

  loadDataLayers() {
    $.get(`/api/v1/data_layers?campaign_id=${this.props.campaign.token}`).then(response => {
      const { campaign_data_layers } = response;
      this.setState({ campaign_data_layers: campaign_data_layers.map(cdl => new CampaignDataLayerModel(cdl)) });
    });
  }

  render() {
    if (this.props.loading && this.props.flag) {
      return (
        <ContentLoader height={30} width={536} speed={2} primaryColor="#afcee9" secondaryColor="#ecebeb">
          <rect x="13.84" y="6.67" rx="0" ry="0" width="217" height="14" />
        </ContentLoader>
      );
    }

    return (
      <div className="toggle-panel">
        {this.renderToggle()}
        {this.state.visibleToggle && this.renderDataLayers()}
      </div>
    );
  }

  renderToggle() {
    const visibleToggle = this.state.visibleToggle;
    return (
      <div className="toggle-more-options">
        <a onClick={() => this.toggleComponent()}>
          {visibleToggle ? <i className="fa fa-caret-down" /> : <i className="fa fa-caret-right" />}
          Data Layers
        </a>
      </div>
    );
  }

  toggleComponent() {
    this.setState({ visibleToggle: !this.state.visibleToggle });
  }

  renderDataLayers() {
    const { campaign } = this.props;
    const { campaign_data_layers, legend } = this.state;

    return (
      <div className="datalayers">
        <Modal show={this.state.addDemographic} onHide={this.onCloseAdd.bind(this)}>
          <AddDataLayer campaign={campaign} hide={this.onCloseAdd.bind(this)} onAdd={this.onAdd.bind(this)} />
        </Modal>

        <div className="add-datalayer">
          <div className="row">
            <div className="campaign-details col-sm-12 col-md-5">
              <button className="btn btn-default" onClick={() => this.setState({ addDemographic: true })}>
                Add Data Layer
              </button>
            </div>
            <div className="col-sm-12 col-md-7">
              <DataLayerLegend legend={legend} />
            </div>
          </div>
        </div>
        {campaign_data_layers.filter(dl => !DISABLED_DATA_LAYERS.includes(dl.type)).map(dl => this.renderDataLayer(dl))}
      </div>
    );
  }

  renderDataLayer(campaign_data_layer) {
    const checked = this.isSelected(campaign_data_layer);
    const loading = this.state.loading_data_layer && checked;

    return (
      <div key={campaign_data_layer.id} className="campaign-datalayer row">
        <div className="col-md-10">
          <div style={{ color: "gray" }}>{campaign_data_layer.type_label}</div>
          <div>
            <b>{`${campaign_data_layer.name}`}</b>
            {(campaign_data_layer.type == "Demographic" || campaign_data_layer.type == "GeopathSegment") &&
              this.renderShadignVariableSelect(campaign_data_layer)}
          </div>
          {campaign_data_layer.type == "Demographic" && <small>2020 US Census</small>}
        </div>
        <div className="col-md-2">
          {loading ? (
            <div className="blink_me" style={{ color: "gray", height: "25px" }}>
              Loading...
            </div>
          ) : (
            <div className="row checkbox-wrapper">
              <input
                type="checkbox"
                id={`check-${campaign_data_layer.id}`}
                onChange={event => {
                  this.onSelectDataLayer(event.target.checked, campaign_data_layer);
                }}
                value={campaign_data_layer.id}
                name="visibleDataLayer"
                checked={checked}
              />
              <label htmlFor={`check-${campaign_data_layer.id}`}>on/off</label>
            </div>
          )}
          <div className="row">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-default dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Actions <span className="caret"></span>
              </button>
              <ul className="dropdown-menu dropdown-menu-right">
                <li>
                  <a onClick={() => this.onRemove(campaign_data_layer)}>
                    <i className="fa fa-trash" />
                    Remove
                  </a>
                </li>
                <li>
                  <a onClick={() => this.onExport(campaign_data_layer.id)}>
                    <i className="fa fa-file-excel-o" />
                    Export
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderShadignVariableSelect(campaign_data_layer) {
    return (
      <select
        id="shading_variable"
        value={campaign_data_layer.shading_variable}
        style={{ marginLeft: 5 }}
        onChange={event => {
          this.onShadingVariableChange(event.target.value, campaign_data_layer);
        }}
      >
        {campaign_data_layer.available_data.map(data => (
          <option value={data.value} key={`${campaign_data_layer.id}-${data.value}`}>
            {data.label}
          </option>
        ))}
      </select>
    );
  }

  onShadingVariableChange(value, campaign_data_layer) {
    this.setState(
      produce(draft => {
        const campaign_data_layers = draft.campaign_data_layers;
        campaign_data_layers[
          campaign_data_layers.findIndex(cdl => cdl.id === campaign_data_layer.id)
        ].shading_variable = value;
      }),
    );
    campaign_data_layer.shading_variable = value;

    $.post(`/api/v1/campaigns/${this.props.campaign.token}/data_layers/${campaign_data_layer.id}`, {
      shading_variable: value,
      _method: "PATCH",
    });
    if (this.isSelected(campaign_data_layer)) this.onSelectDataLayer(true, campaign_data_layer);
  }

  isSelected(campaign_data_layer) {
    const visible_data_layer = this.state.visible_data_layer;
    return visible_data_layer != null && campaign_data_layer.id === visible_data_layer.id;
  }

  onCloseAdd() {
    this.setState({ addDemographic: false });
  }

  onAdd(campaign_data_layer) {
    GlobalActions.showMessage("Data layer added to campaign");
    this.state.campaign_data_layers.push(campaign_data_layer);
    this.setState({ campaign_data_layers: this.state.campaign_data_layers });
    this.onCloseAdd();
    this.onSelectDataLayer(true, campaign_data_layer);
  }

  onRemove(campaign_data_layer) {
    const params = {
      campaign_id: this.props.campaign.token,
      data_layer_type: "Demographic",
      _method: "DELETE",
    };

    $.post(`/api/v1/data_layers/${campaign_data_layer.id}`, params).then(response => {
      this.setState({
        campaign_data_layers: _.filter(this.state.campaign_data_layers, cdl => campaign_data_layer !== cdl),
      });
      GlobalActions.showMessage("Data Layer removed.");
      const should_hide = this.isSelected(campaign_data_layer);
      should_hide && this.onSelectDataLayer(false, campaign_data_layer);
    });
  }

  onExport(data_layer_id) {
    const { campaign } = this.props;
    window.open(`/api/v1/campaigns/${campaign.id}/data_layers/${data_layer_id}/export`);
  }

  onSelectDataLayer(checked, selected_data_layer) {
    const parsed = queryString.parse(location.search);

    if (checked) {
      parsed.data_layer = selected_data_layer.id;
    } else {
      parsed.data_layer = null;
      this.hideDataLayer();
    }

    browserHistory.push({
      pathname: this.component_path,
      search: `?${queryString.stringify(parsed)}`,
      state: selected_data_layer.id,
    });
  }

  onRouteChange({ pathname, state, query }) {
    const { campaign_data_layers } = this.state;

    if (!pathname.startsWith(this.component_path)) return;

    if (query.data_layer) {
      const selected_data_layer_id = state;
      const selected_data_layer = _.find(campaign_data_layers, cdl => cdl.id == selected_data_layer_id);
      this.showDataLayer(selected_data_layer);
    }
  }

  hideDataLayer() {
    this.setState({ visible_data_layer: null, legend: null });
    MapActions.showDataLayer(null, null);
    this.props.renderCustomBounds();
  }

  async showDataLayer(campaign_data_layer) {
    const unitsInBounds = sidebarUnits(withinBounds(this.props.units, this.props.bounds));
    const dmasInBounds = _.uniq(unitsInBounds.map(l => l.dma_id));
    console.log("DMAs in bounds", dmasInBounds);
    if (dmasInBounds.length > 20) {
      GlobalActions.showError(
        "Too many markets to load. Please zoom in to a smaller area before enabling a data layer.",
      );
      return;
    }
    this.setState({ loading_data_layer: true, visible_data_layer: campaign_data_layer });
    const key = campaign_data_layer.shading_variable + dmasInBounds;
    try {
      const data_layer_data = await this.loadDataLayerData(campaign_data_layer, dmasInBounds);
      campaign_data_layer.setData(key, data_layer_data.areas);
      MapActions.showDataLayer(campaign_data_layer, "Demographic");
      this.setState({ loading_data_layer: false, legend: campaign_data_layer.legend });
    } catch (e) {
      this.setState({ loading_data_layer: false, visible_data_layer: null });
      throw e;
    }
  }

  expandDataLayerSection() {
    const { campaign_data_layers } = this.state;
    const query_string_data_layer = queryString.parse(location.search)["data_layer"];
    const visible_data_layer =
      query_string_data_layer && _.find(campaign_data_layers, cdl => cdl.id == query_string_data_layer);
    if (campaign_data_layers.length) this.setState({ visibleToggle: true });
    if (visible_data_layer) this.onSelectDataLayer(true, visible_data_layer);
  }

  async loadDataLayerData(campaign_data_layer, dmasInBounds) {
    const { campaign } = this.props;
    const dmas = _.join(dmasInBounds, "|");
    return $.get(`/api/v1/data_layers/${campaign_data_layer.id}?campaign_id=${campaign.token}&dma_ids=${dmas}`);
  }
}

export default connect(({ map }) => ({ bounds: map.bounds }))(DataLayers);
