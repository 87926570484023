import { Loading, LoadingSize } from "../Loading";
import { User } from "../../types/user";
import NavBar from "../NavBar";
import React, { ReactElement, ReactNode } from "react";

// This component will check if the user is logged.

export interface PrivatePage {
  title: string;
  user: User | null;
  className: string;
  children: ReactNode | ReactElement | undefined;
  noNavbarBorder?: boolean;
  enableGuest?: boolean;
  showBar?: boolean | true;
}

export const PageLoading = (): ReactElement => (
  <div className="ui-billboard-loading--center">
    <Loading size={LoadingSize.Big} />
  </div>
);

export const PrivatePage = ({
  user,
  title,
  className,
  children,
  enableGuest,
  noNavbarBorder,
  showBar,
}: PrivatePage): ReactElement => {
  React.useEffect(() => {
    document.title = `${title} | AdQuick`;
    return (): void => {
      document.title = "AdQuick";
    };
  }, []);

  if (!user) {
    return <PageLoading />;
  }

  if ((!user || user.is_guest) && !enableGuest) {
    // @ts-ignore
    window.location = window.location.origin;
    return <Loading />;
  }

  return (
    <div className="private_wrapper">
      {showBar !== false && <NavBar noBorder={Boolean(noNavbarBorder)} user={user} />}
      <div className={className}>{children}</div>
    </div>
  );
};

export default PrivatePage;
