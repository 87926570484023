import React from 'react';
import createClass from 'create-react-class';
import { get, post } from '../../../utils/api';
import GlobalActions from '../../../actions/GlobalActions';
import AuthStore from '../../../stores/AuthStore';
import { browserHistory } from 'react-router-legacy';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import UrlParams from '../../../utils/UrlParams';

class VendorsRegistration extends React.Component {

  constructor(props) {
    super(props);
    const params = new UrlParams(location.search);

    this.state = {
      name: "",
      email: params.get('email') || "",
      phone: "",
      company: "",
      password: "",
      errors: {}
    }

    this.onInputChange = this.onInputChange.bind(this);
    this.onAuthChange = this.onAuthChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onInputChange(event) {
    const { target } = event;
    const { value, name } = target;
    this.setState({ [name]: value, errors: {}, signup_error: '' });
  }

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  requestReceived() {
    GlobalActions.showMessage("We have contacted your administrator for approval");
    browserHistory.push('/');
  }

  onAuthChange(event) {
    if (event === 'auth:signup') {
      const { company, email } = this.state;
      browserHistory.push("/vendors/setup")
    }
    if (event === 'auth:signup_error') {
      const { errors } = AuthStore.getState();
      this.setState({ signup_error: errors, showLogin: true });
    }
  }


  validate() {
    const { name, email, phone, password, company } = this.state;
    const phoneValid = (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im).test(phone);

    let errors = {
      email: (email.length === 0 || !email.includes("@")) ? "Valid email is required" : null,
      company: (company.length === 0) ? "required" : null,
      name: (/\w+\s+\w+/).test(name) ? null : "Full name required",
      phone: (phone.length === 0 || (phone.length > 0 && phoneValid)) ? null : "Invalid phone number.",
      password: (password.length > 5) ? null : "Password must be at least 6 characters"
    }

    this.setState({ errors });
    return errors
  }

  onSubmit() {
    const errors = this.validate();
    if (errors.name || errors.email || errors.company || errors.password) { return }
    const { name, email, company, phone, password } = this.state;

    AuthStore.onSignUp({
      name,
      email,
      phone,
      company,
      password,
      vendor_signup: true,
    });
  }

  renderProgressArea() {
    return (
      <div className="progress-area">
        <ProgressBar>
          <ProgressBar key={1} style={{backgroundColor: '#00b30c'}} now={33} label={"1. Create Account"} />
          <ProgressBar key={2} style={{backgroundColor: '#ffffff', color: '#000000'}} now={33} label={"2. Upload Inventory"} />
          <ProgressBar key={3} style={{backgroundColor: '#ffffff', color: '#000000'}} now={34} label={"3. Confirm & Submit"} />
        </ProgressBar>
      </div>
    )
  }

  render() {
    const { errors, name, email, company, phone, password, signup_error, submitted } = this.state;

    if (!submitted) { return (
      <div id="vendor-registration">
        <div className="vendor-registration-header">
          <div className="header_text">
            Media Owner Registration
          </div>
          <div className="header_description">
            It only takes a few minutes to register as a Media Owner with AdQuick. First, let’s set up your user profile.
          </div>
          {this.renderProgressArea()}
        </div>

        <div className="header">
          <i className="fa fa-user" aria-hidden="true"></i> User Profile
        </div>
        <div className="vendor-registration-box">

          <div className="form_fields">
            <div className="form-group">
              <label htmlFor="name">
                Name <span className="asterisk">*</span>
                {errors.name &&
                  <span className="error">
                    {errors.name}
                  </span>
                }
              </label>
              <input
                type="text"
                className={"form-control " + (errors.name ? "error" : "")}
                id="name"
                name="name"
                placeholder="Your full name"
                value={name}
                ref={(input) => this.firstInput = input}
                onChange={this.onInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="company">
                Company <span className="asterisk">*</span>
                {errors.company &&
                  <span className="error">
                    {errors.company}
                  </span>
                }
              </label>
              <input
                type="text"
                className={"form-control " + (errors.company ? "error" : "")}
                id="company"
                name="company"
                placeholder="Company Name"
                value={company}
                ref={(input) => this.firstInput = input}
                onChange={this.onInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">
                Phone
                {errors.phone &&
                  <span className="error">
                    {errors.phone}
                  </span>
                }
              </label>
              <input
                type="text"
                className={"form-control " + (errors.phone ? "error" : "")}
                id="phone"
                name="phone"
                placeholder="Your work phone number"
                value={phone}
                ref={(input) => this.firstInput = input}
                onChange={this.onInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">
                Email <span className="asterisk">*</span>
                {errors.email &&
                  <span className="error">
                    {errors.email}
                  </span>
                }
              </label>
                <input
                  type="text"
                  className={"form-control " + (errors.email ? "error" : "")}
                  id="email"
                  name="email"
                  placeholder="Your work email address"
                  value={email}
                  ref={(input) => this.firstInput = input}
                  onChange={this.onInputChange}
                />
            </div>

            <div className="form-group">
              <label htmlFor="password">
                Password <span className="asterisk">*</span>
                {errors.password &&
                  <span className="error">
                    {errors.password}
                  </span>
                }
              </label>
                <input
                  type="password"
                  className={"form-control " + (errors.password ? "error" : "")}
                  id="password"
                  name="password"
                  placeholder="Choose a secure Password"
                  value={password}
                  ref={(input) => this.firstInput = input}
                  onChange={this.onInputChange}
                />
            </div>
          </div>

          {signup_error &&
            <div className="signup-error">
              * {signup_error}
            </div>
          }

          <div className="clearfix">
            <input
              type="submit"
              className="sign-up"
              value="Next"
              onClick={this.onSubmit}
            />
          </div>
        </div>
      </div>)} else {
        return (
         <Submitted />
        )
     }
  }
}

export default connect(
  ({ currentUser }) => ({ currentUser }),
  {}
)(VendorsRegistration);
