import { connect } from "react-redux";
import React from "react";
import accounting from "accounting";
import createClass from "create-react-class";

const MarketOverview = createClass({
  getInitialState() {
    return { hide: false };
  },

  componentDidMount() {
    this.updateLocation();
  },

  componentDidUpdate(prevProps) {
    if (this.props.current_market !== prevProps.current_market) {
      this.updateLocation();
    }
  },

  useRateCardData() {
    const {
      currentUser: { use_rate_card_price_on_browse },
      isSearchView,
    } = this.props;
    return isSearchView && use_rate_card_price_on_browse;
  },

  toggleOverview() {
    this.setState({ hide: !this.state.hide });
  },

  async updateLocation() {
    if (!this.props.current_market || !this.props.markets) return;

    const overview = this.props.markets.find(m => m.id === this.props.current_market);
    this.setState({ ...overview });
  },

  render() {
    if (this.props.user && this.props.user.is_supplier) return <div />;

    return (
      <div className="market-overview">
        {this.toggleButton()}
        {this.overview()}
      </div>
    );
  },

  overview() {
    if (this.state.hide) return <div />;
    return (
      <div className="overview">
        {this.header()}
        <div className="items">
          {this.medianCPM()}
          {this.medianPrice()}
          {this.lowestPrice()}
          {this.totalLocations()}
        </div>
        {this.callToAction()}
      </div>
    );
  },

  header() {
    return (
      <div className="header">
        <i className="fa fa-map-marker" />
        {this.state.name}
      </div>
    );
  },

  medianCPM() {
    if (this.useRateCardData()) {
      return this.medianRateCardCPM();
    }
    const value = this.state.median_cpm ? accounting.formatMoney(this.state.median_cpm) : "−";
    return this.item(value, "Median CPM");
  },

  medianRateCardCPM() {
    const value = this.state.median_rate_card_cpm ? accounting.formatMoney(this.state.median_rate_card_cpm) : "−";
    return this.item(value, "Median CPM");
  },

  medianPrice() {
    if (this.useRateCardData()) {
      return this.medianRateCardPrice();
    }
    const value = this.state.median_price ? accounting.formatMoney(this.state.median_price) : "−";
    return this.item(value, "Median Price", "per 4 Weeks");
  },

  medianRateCardPrice() {
    const value = this.state.median_rate_card_price ? accounting.formatMoney(this.state.median_rate_card_price) : "−";
    return this.item(value, "Median Price", "per 4 Weeks");
  },

  lowestPrice() {
    if (this.useRateCardData()) {
      return this.lowestRateCardPrice();
    }
    const value = this.state.lowest_price ? accounting.formatMoney(this.state.lowest_price) : "−";
    return this.item(value, "Lowest Price", "per 4 Weeks");
  },

  lowestRateCardPrice() {
    const value = this.state.lowest_rate_card_price ? accounting.formatMoney(this.state.lowest_rate_card_price) : "−";
    return this.item(value, "Lowest Price", "per 4 Weeks");
  },

  totalLocations() {
    if (!this.state.units_count) return this.item("−", "Total Locations");

    let divider = 1;
    if (this.state.units_count > 10000) divider = 10000;
    else if (this.state.units_count > 1000) divider = 1000;
    else if (this.state.units_count > 100) divider = 100;
    else if (this.state.units_count > 10) divider = 10;

    const count = Math.floor(this.state.units_count / divider) * divider;

    return this.item(accounting.formatMoney(count, "", 0) + "+", "Total Locations");
  },

  item(content, description, period) {
    return (
      <div className="item">
        <div className="content">{content}</div>
        <div className="description">
          {description}
          {period && <span>{period}</span>}
        </div>
      </div>
    );
  },

  callToAction() {
    return <div />;
  },

  toggleButton() {
    const className = this.state.hide ? "fa fa-caret-right" : "fa fa-caret-down";
    return (
      <div className="toggle-more-options">
        <a onClick={this.toggleOverview}>
          <i className={className} />
          Market Overview
        </a>
        {this.miniOverview()}
      </div>
    );
  },

  miniOverview() {
    if (!this.state.hide) return <div />;

    return (
      <div className="mini-overview">
        {this.totalLocations()}
        {this.callToAction()}
      </div>
    );
  },
});

export default connect(
  // @ts-ignore
  ({ currentUser, current_market, markets }) => ({ currentUser, current_market, markets }),
  {},
)(MarketOverview);
