import React from 'react';
import createClass from 'create-react-class';
import cs from 'classnames';
import SortBy from '../../Filters/SortBy';
import {
  paramsAdd,
  paramsRemove,
} from "../../../utils/QueryString";
import GlobalActions from '../../../actions/GlobalActions';
import { post, get } from '../../../utils/api';

export default createClass({
  displayName: 'Sidebar/Header',

  getInitialState: async function() {
    this.getApiProposalStatus()
    return {isPartnerCampaign: false, proposalStatus: null};
  },

  async getApiProposalStatus() {
    const { campaignToken } = this.props;
    try {
      const data = await get(`/api/v1/campaigns/${campaignToken}/api_proposal_status`)
      return this.setState(
        {
          isPartnerCampaign: data.is_partner_campaign,
          proposalStatus: data.proposal_status,
          publicApiEnabled: data.enabled
        }
      )
    } catch (error) {
      GlobalActions.showError(error);
    }
  },

  async sendProposal() {
    const { campaignToken } = this.props;
    try {
      const data = await post(`/api/v1/campaigns/${campaignToken}/api_send_proposal`)
      return this.setState({proposalStatus: data.proposal_status})
    } catch (error) {
      GlobalActions.showError(error);
    }
  },


  distanceFromPOI() {
    const { units } = this.props;
    if(Array.isArray(units.all_units)) { return _.get(units, 'all_units').map(u => u.distance_from_point_of_interest) }
    const cacheKeys = Object.keys(units.all_units)
    return cacheKeys.map(k => units.all_units[k].units).flatten().map(u => u.distance_from_point_of_interest)
  },

  handleQueryParams(filter) {
    const keys = Object.keys(filter)
    keys.forEach(key => !!filter[key] ? paramsAdd(key, filter[key]) : paramsRemove(key))
  },

  async onFinalizeSelections() {
    const { campaignToken } = this.props;
    try {
      await post(`/api/v1/campaigns/${campaignToken}/send_finalized_selections_mailer`)
      GlobalActions.showMessage("The team has been notified of your finalization and will reach out shortly!")
    } catch (error) {
      GlobalActions.showError(error);
    }
  },

  renderAction(unitsCount) {
    if (this.state.isPartnerCampaign && this.state.publicApiEnabled) {
      // if (this.state.proposal_status == 'failed') {
      //   return this.renderErrorProposal()
      // }
      return this.renderSendProposal()
    }

    return this.renderFinalizeFavorites(unitsCount)
  },

  renderSendProposal() {
    return <a className="btn btn-default finalize-favorites" onClick={this.sendProposal}>
      Send Proposal
    </a>
  },

  renderErrorProposal() {
    return <a className="btn btn-default finalize-favorites" onClick={() => {}}>
      Error sending proposal, reach for support
    </a>
  },

  renderFinalizeFavorites(unitsCount) {
    if (unitsCount > 0) {
      return <a
        className="btn btn-default finalize-favorites"
        onClick={this.onFinalizeSelections}>
        Finalize Favorites
    </a>

    }
  },

  render() {
    const className = cs({ filters: true });
    const permissions = this.props.permissions || {};
    const { units, unitsCount, isMockCampaign, isAddingAllToCart, isVendorMap, onSort, campaignToken, disableCart } = this.props;
    const showAddAllToCart = !isMockCampaign &&
      permissions.can_view_add_all_to_cart &&
      unitsCount > 0;
    const sortedBy = _.get(units, 'sort_by', null);
    const isSorted = !!sortedBy && sortedBy != 'default';

    if(this.props.loading && this.props.flag) {
      return (
        <div style={{display:'flex',justifyContent:'space-between',padding:'10px 15px 0 15px'}}>
          <div style={{display:'flex'}}>
            <div style={{width:'140px',marginRight:'10px',height:'34px',border:'2px solid lightgray',borderRadius:'6px'}}></div>
            <div style={{width:'68px',height:'34px',border:'2px solid lightgray',borderRadius:'6px'}}></div>
          </div>
          <div style={{width:'120px',height:'34px',backgroundColor:'#44ac6b',borderRadius:'6px'}}></div>
        </div>
      )
    }

    return (
      <div className={className}>
        <div className="filters__head">
          <ul>
            {!isVendorMap && <SortBy
              campaignToken={campaignToken}
              permissions={permissions}
              onSort={onSort}
              isActive={isSorted}
              initialValue={sortedBy}
              poiDistances={this.distanceFromPOI()}
              handleQueryParams={this.handleQueryParams}
            />}
          </ul>

          <div className="filters__view-toggle">
            <i className={cs('fa', 'fa-list', { active: !this.props.showTimeline })} onClick={this.props.onShowMap} />
            <i className={cs('fa', 'fa-calendar', { active: this.props.showTimeline })} onClick={this.props.onShowTimeline} />
          </div>

          {
            unitsCount > 0 &&
            <div className="filters__unitCount hidden-sm">{`${unitsCount} ${unitsCount === 1 ? 'Unit' : 'Units' }`}</div>
          }

          { this.renderAction(unitsCount)}

          { showAddAllToCart && !disableCart &&
            <a
              className="btn btn-default add-all-to-cart-button"
              onClick={this.props.onAddAllToCart}
              disabled={isAddingAllToCart}>
              {isAddingAllToCart ? "Adding units to cart" : `Add ${unitsCount} To Cart`}
            </a>}
          {/* end left side */}
        </div>

      </div>
    );
  },
});
