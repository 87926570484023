import * as React from 'react';
import { Modal } from "react-bootstrap";

class ContractConfirmation extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.onContinue = this.onContinue.bind(this)
    this.onDecline = this.onDecline.bind(this)
  }

  async onContinue(e){
    const { type } = this.props;
    this.props.handleConfirmationClick();
    if (type === "client_contract") {
      this.props.onSubmit(e);
    } else if (type === "supplier_contract") {
      this.props.onSubmit(false);
    }
    
  }

  onDecline(){
    this.props.handleConfirmationClick();
  }

  render() {
    const { show_confirmation } = this.props;
    return (
      <Modal show={show_confirmation} animation={false} backdropClassName={"confirm-ulta-backdrop"} dialogClassName={"confirm-ulta-dialog"}>
        <Modal.Header>
          <Modal.Title>
            Confirmation: This is a legally binding document. Please confirm all data is correct before sending.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-sm-6">
              <button className="btn btn_primary register__submit" type="submit" onClick={(e) => { this.onContinue(e)}}>
                Confirm
              </button>
            </div>
            <div className="col-sm-6">
              <button className="btn btn-default register__submit" type="submit" onClick={this.onDecline}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

}

export default ContractConfirmation;
