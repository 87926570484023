import Reflux from 'reflux';
import { get, post } from '../utils/api';

export default Reflux.createStore({
  init() {
    this.state = {}
  },

  getState() {
    return this.state
  },

  log(event, topic, data = {}) {
    if (DEBUG) console.log('ActivityStore.log', event, topic, data)
    const activity = true
    return post('/api/v1/logs', { activity, event, topic, data })
  },

});
