import "./index.css";
import isEmpty from "lodash/isEmpty";
import FaShoppingCart from "@adquick/icons/fa/FaShoppingCart";
import { Button } from "@adquick/ui";
import React from "react";
import { useSelector } from 'react-redux'
import accounting from 'accounting';
import Tooltip from "rc-tooltip";
import UnitModel from "../../../models/unitModel";

type Props = {
  token: string;
  campaignPermissions: Record<string, boolean>;
  onCheckoutClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

interface RootState {
  cart: any;
  campaign: any;
}

const cart_items = (item, idx) => {
  return (
    <div className={item.class} key={idx}>
      <div className="label">{item.label}</div>
      <div className={`amount ${item.class}`}>{item.amount}</div>
    </div>
  );
};

const cpm_label = (enable_analytics_cpm, analytics_charge_method) => {
  return (
    <span>
      CPM
      {enable_analytics_cpm && analytics_charge_method === 'baked_in' && (
        <Tooltip overlay={<span>Media + Analytics</span>}>
          <span> <i className="far fa-info-circle" /></span>
        </Tooltip>
      )}
    </span>
  )
}

const Cart = (props: Props) => {
  const { onCheckoutClick, campaignPermissions } = props;
  const { totals } = useSelector((state: RootState) => state.cart);
  const { campaign } = useSelector((state: RootState) => state.campaign);
  const { enable_analytics_cpm, analytics_charge_method, analytics_cpm } = useSelector((state: RootState) => state.campaign.campaign);

  if (isEmpty(totals)) {
    return <div></div>;
  }

  const totalImpressions = totals.total_impressions;
  const weeklyImpressions = totals.total_average_weekly_impressions;
  const grandTotal = totals.total;
  const weeklySpend = totals.total_average_weekly_spend;
  const cpmValue = new UnitModel().getCpmWithAnalytics(enable_analytics_cpm, analytics_charge_method, totals.total_cpm, analytics_cpm)
  const cpmLabel = cpm_label(enable_analytics_cpm, analytics_charge_method)
  const count = totals.count;

  const info_items = {
    cpm: { amount: cpmValue, label: cpmLabel, class: "to-hide-small" },
    impressions: { amount: accounting.formatNumber(totalImpressions), label: "Impressions", class: "to-hide" },
    weeklyImpressions: { amount: accounting.formatNumber(weeklyImpressions), label: "Weekly Avg Impr", class: "to-hide" },
    totals: { amount: accounting.formatMoney(grandTotal, "$", 0), label: "Total", class: "" },
    weeklySpend: { amount: accounting.formatMoney(weeklySpend, "$", 0), label: "Weekly Spend", class: "" },
    cart: { amount: `${count} Units`, label: "Units in Cart", class: "blue" },
  };

  const canUseCorporationCheckout = campaignPermissions.can_use_corporation_checkout;
  const checkoutButtonText = canUseCorporationCheckout ? "Confirm Booking" : "Checkout";
  return (
    <div className="sticky-cart-wrapper">
      <div className="sticky-cart">
        <div className="sticky-cart-section section-cart">
          <FaShoppingCart />
          {["cart"].map((item, idx) => cart_items(info_items[item], idx))}
        </div>

        <div className="sticky-cart-section">
          {["impressions", "weeklyImpressions"].map((item, idx) => cart_items(info_items[item], idx))}
        </div>

        <div className="sticky-cart-section">
          {["cpm", "weeklySpend"].map((item, idx) => cart_items(info_items[item], idx))}
        </div>

        <div className="sticky-cart-section section-total-checkout">
          {["totals"].map((item, idx) => cart_items(info_items[item], idx))}
          {!campaign.disable_cart && (
            <Button onClick={onCheckoutClick}>{checkoutButtonText}</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
