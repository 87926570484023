import React from "react";
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";
import ContractForm from "./ContractForm";
import VendorContractForm from "./VendorContractForm";
import GlobalActions from "../../actions/GlobalActions";
import { loadCampaign } from '../Campaign/actions';

class AddContract extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saving: false
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    values.campaign_token = this.props.campaign_token;

    this.setState({ saving: true }, () => {
      $.post("/api/v1/contracts/receive_upload", values).then(response => {
        if (response.errors) {
          this.setState({ errors: response.errors });
        } else {
          GlobalActions.showMessage("Contract was saved!");
          GlobalActions.closePopup();
          // this.props.loadCampaign(this.props.campaign_token);
          window.location.reload();
        }
      });

      this.setState({ saving: false });
    });
  }

  render() {
    const { show, onHide, saving, user, campaign_token, unit_tokens, all_selected } = this.props;

    if (user && user.is_admin && this.props.upload_form === true) {
      return (
        <Modal show={show} onHide={onHide} animation={false}>
          <Modal.Header>
            <Modal.Title>Add Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContractForm
              onSubmit={this.onSubmit}
              saving={saving}
              defaultValues={{}}
              serverErrors={this.state.errors}
              unit_tokens={unit_tokens}
              all_selected={all_selected}
            />
          </Modal.Body>
        </Modal>
      )
    }

    return (
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header>
          <Modal.Title>Add Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(user.is_managed_supplier || user.is_admin || user.is_agency|| user.is_supplier_seller) ? (
            <VendorContractForm
              user={user}
              saving={saving}
              defaultValues={{}}
              serverErrors={this.state.errors}
              campaign_token={campaign_token}
              unit_tokens={unit_tokens}
              all_selected={all_selected}
            />
          ) : (
            <ContractForm
              onSubmit={this.onSubmit}
              saving={saving}
              defaultValues={{}}
              serverErrors={this.state.errors}
              unit_tokens={unit_tokens}
              all_selected={all_selected}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
};

export default connect(() => ({}), { loadCampaign })(AddContract);
