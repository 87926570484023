import React from "react";
import moment from "moment";
import { TAttachmentCard } from "../../../models/campaign";

interface IAttachmentCardProps {
  card: TAttachmentCard;
  renderCheckbox: boolean;
  renderDestroy: boolean;
  renderDescription: boolean;
  renderCreatedAt: boolean;
  renderCreatedBy: boolean;
  renderVisibility: boolean;
  isAdmin: boolean;
  onSelectCard?: (id) => void;
  destroyAttachment?: (id) => void;
  updateAttachment?:(id, payload)=> void;
  customClass?: Array<string>;
}

const AttachmentCard = ({
  card,
  onSelectCard,
  renderCheckbox,
  renderDestroy,
  renderDescription,
  renderCreatedAt,
  renderCreatedBy,
  renderVisibility,
  isAdmin,
  destroyAttachment,
  updateAttachment,
  customClass
}: IAttachmentCardProps) => {
  const {
    id,
    filename,
    description,
    created_at,
    created_by,
    visibility,
    file_url,
    checked,
    show_on_overview
  } = card;

  const renderCustomClass = () => {
    if(!customClass) { return; }
    return customClass.join(" ");
  }

  const cardClickHandler = (id) => {
    if (!onSelectCard) { return; }
    return onSelectCard(id)
  }

  const parseDate = (date) => {
    if(!date) { return; }
    return moment.utc(date).fromNow();
  }

  const handleDestroy = (e, id) => {
    e.stopPropagation();
    return destroyAttachment && destroyAttachment(id);
  }

  const openFile = (e) => {
    e.stopPropagation();
    return window.open(file_url, '_blank');
  }

  const handleShowOnOverview = (e, id, show_on_overview) => {
    e.stopPropagation();
    const newUpdate = !show_on_overview;
    return updateAttachment(id, {show_on_overview: newUpdate});
  }

  return (
    
    <div
      key={`card_${id}`}
      className={`card ${renderCustomClass()}${isAdmin ? ' with_overview': ''}`}
      onClick={() => cardClickHandler(id)}
    >
      { isAdmin && 
        <i 
          title="Show on Campaign Overview"
          className={(show_on_overview ? "fas fa-heart" : "far fa-heart")}
          onClick={(e) => {handleShowOnOverview(e, id, show_on_overview)}}
        ></i>
}
      <div
        className="name"
        onClick={(e) => openFile(e)}
      >
        {filename}
      </div>
      { renderDescription &&
        <div className="description">
          <div className="title">description</div>
          <div className="value">{description}</div>
        </div>
      }
      { renderCreatedAt &&
        <div className="created_at">
          <div className="title">date uploaded</div>
          <div className="value">{parseDate(created_at)}</div>
        </div>
      }
      { renderCreatedBy &&
        <div className="created_by">
          <div className="title">uploaded by</div>
          <div className="value">{created_by}</div>
        </div>
      }
      { renderVisibility &&
        <div className="visibility">
          <div className="title">visibility</div>
          <div className="value">{visibility}</div>
        </div>
      }
      { !renderVisibility &&
        <div></div>
      }

      <div className="controls">
        <div className="download" onClick={(e) => openFile(e)}>
          <div className="download_icon"></div>
          Download
        </div>
        { renderDestroy &&
          <div className="exclude" onClick={(e) => handleDestroy(e, id)}>
            <i className="fa fa-trash-o"></i>
          </div>
        }
        { renderCheckbox &&
          <div className="white_checkbox">
            <input type="checkbox" checked={checked}/>
            <span className="checkmark"></span>
          </div>
        }
      </div>
    </div>
  );
}

export default AttachmentCard;
