import * as React from 'react';
import OpenRfps from './OpenRfps';
import UnitRequests from './UnitRequests';
import HoldRequests from './HoldRequests';
import ExpiringHolds from './ExpiringHolds';
import PopsOutstanding from './PopsOutstanding';

export default class ToDos extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="todo_section">
        <div className="header">To Do</div>
        <div className="row" style={{marginLeft: -5, marginRight: 0}}>
          <div className="col-md-5ths">
            <OpenRfps />
          </div>
          <div className="col-md-5ths">
            <UnitRequests />
          </div>
          <div className="col-md-5ths">
            <HoldRequests />
          </div>
          <div className="col-md-5ths">
            <ExpiringHolds />
          </div>
          <div className="col-md-5ths">
            <PopsOutstanding />
          </div>
        </div>
      </div>
    );
  }
};
