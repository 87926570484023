import * as React from 'react';
import { PureComponent } from 'react';
import { Pagination, DropdownButton, MenuItem, Modal } from 'react-bootstrap'
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactSelect from 'react-select';
import Details from './Details';
import CreateAdvertiser from './Create';

import {
  showCreateAdvertiserModal,
  hideCreateAdvertiserModal,
  fetchAdvertisers,
  selectAdvertiser,
  setFilter,
} from './actions';

class Advertisers extends PureComponent<any, any> {

  constructor(props) {
    super(props);
    this.onClearQuery = this.onClearQuery.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onCreateAdvertiser = this.onCreateAdvertiser.bind(this);
    this.onSourceFilterChange = this.onSourceFilterChange.bind(this);
    this.debouncedUpdateFilters = _.debounce(this.debouncedUpdateFilters.bind(this), 500);
  }

  fetchAdvertisers() {
    const { fetchAdvertisers, page, per_page, query, lead_source } = this.props;
    fetchAdvertisers({
      page,
      query,
      per_page,
      lead_source: (lead_source ? lead_source.value : null)
    });
  }

  componentWillMount() {
    this.fetchAdvertisers();
  }

  componentDidUpdate(prevProps) {
    const { filters_changed } = this.props;

    if (filters_changed) {
      this.fetchAdvertisers();
    }
  }

  debouncedUpdateFilters() {
    const { setFilter, page, per_page, query, lead_source } = this.props;
    setFilter({page, per_page, query, lead_source, filters_changed: true});
  }

  onSearchChange(e) {
    const { setFilter, per_page, lead_source } = this.props;
    setFilter({query: e.target.value, per_page, page: 1, lead_source});
    this.debouncedUpdateFilters();
  }

  onSourceFilterChange(lead_source) {
    const { setFilter, query, per_page } = this.props
    setFilter({query, per_page, page: 1, lead_source});
    this.debouncedUpdateFilters();
  }

  renderSourceFilter() {
    const { lead_source } = this.props;
    const lead_source_options = [
      {label: 'Internal', value: 'internal'},
      {label: 'Yelp', value: 'yelp'}
    ]

    return (
      <div className="sorting-dropdown" style={{width: '50%'}}>
        <ReactSelect
          className="selectInput"
          placeholder="Filter by source"
          value={lead_source}
          options={lead_source_options}
          onChange={this.onSourceFilterChange}
        />
      </div>
    )
  }

  onClearQuery() {
    const { setFilter, per_page, lead_source } = this.props;
    setFilter({ page: 1, query: '', per_page, lead_source });
    this.debouncedUpdateFilters();
  }

  renderSearch(){
    const { query } = this.props;
    const clear_hidden = (!!!query || query === '');

    return (
      <div style={{width: '50%', display: 'flex'}}>
        <div className="search-bar-area" style={{minWidth: '98%'}}>
          <input
            type="text"
            className="form-control"
            placeholder="Search advertisers"
            value={query}
            onChange={this.onSearchChange}
          />
        </div>
        <div
          className={`clear-search-bar noselect ${clear_hidden ? 'hide' : ''}`}
          onClick={this.onClearQuery}
        >
          clear
        </div>
      </div>
    )
  }

  renderPagination() {
    const per_page_options = [10, 25, 50, 100];
    const { total_pages, per_page, page, query, lead_source, setFilter } = this.props;

    return (
      <div className="pagination-area" style={{height: 60}}>
        <div className="col-sm-2">
          <div className="pagination">
            <DropdownButton title={per_page || "10"} id="bg-nested-dropdown">
              {per_page_options.map((size_per_page) =>
                <MenuItem
                  key={`per-page-${size_per_page}`}
                  onClick={() => setFilter({per_page: size_per_page, page: 1, query, lead_source, filters_changed: true})}
                >{size_per_page}</MenuItem>
              )}
            </DropdownButton>
          </div>
        </div>
        <div className="col-sm-10">
          <Pagination
            bsSize="small"
            prev
            next
            last
            ellipsis
            boundaryLinks
            items={total_pages}
            maxButtons={5}
            activePage={parseInt(page) || 1}
            onSelect={(page) => { setFilter({page: page, per_page, query, lead_source, filters_changed: true}); }} />
        </div>
      </div>
    )
  }

  onRowClick(advertiser) {
    const { selectAdvertiser } = this.props;
    selectAdvertiser(advertiser);
  }

  renderAdvertiser(advertiser, index) {
    const selected_advertiser = this.props.advertiser || {};
    const selected_id = selected_advertiser.id;
    const selected = selected_id && (advertiser.id === parseInt(selected_id)) ? 'selected' : '';
    const lead_source_class = advertiser.lead_source ? advertiser.lead_source.toLowerCase() : 'internal';

    return (
      <div
        className={(index % 2 === 0) ? `listing ${selected}` : `listing stripe ${selected}`}
        key={advertiser.id}
        onClick={() => this.onRowClick(advertiser)}
      >
        <div className="name">
          {advertiser.name}
          <span className={`advertiser-lead-source ${lead_source_class}`}>
            {advertiser.lead_source}
          </span>
        </div>
        <div className={`details ${selected}`}>
          {advertiser.campaigns_count} Campaigns • {advertiser.contracted_campaigns} Contracted • {advertiser.proposed_campaigns} Proposed
        </div>
      </div>
    )
  }

  renderCreateAdvertiserModal() {
    const { show_create_advertiser_modal, hideCreateAdvertiserModal } = this.props;
    return (
      <Modal
        show={show_create_advertiser_modal}
        onHide={() => hideCreateAdvertiserModal()}
      >
        <Modal.Header>
          <h4>New Advertiser</h4>
        </Modal.Header>
        <Modal.Body>
          <CreateAdvertiser />
        </Modal.Body>
        <Modal.Footer>
          <a onClick={() => hideCreateAdvertiserModal()} className="btn btn-default">Cancel</a>
        </Modal.Footer>
      </Modal>
    )
  }

  onCreateAdvertiser() {
    const { showCreateAdvertiserModal } = this.props;
    showCreateAdvertiserModal()
  }


  renderNewAdvertiserButton() {
    return (
      <div style={{width: '50%'}}>
        <button className="new-advertiser" onClick={this.onCreateAdvertiser}>
          + New Advertiser
        </button>
      </div>
    )
  }


  render() {
    const { currentUser, advertisers, advertiser } = this.props;
    const isManagedSupplier = currentUser && currentUser.is_managed_supplier;
    const isAdmin = currentUser && currentUser.is_admin;

    if (isAdmin || isManagedSupplier){
      return (
        <div id="advertisers-crm">
          <div className="col-md-4 advertiser-column">
            {this.renderCreateAdvertiserModal()}
            <div style={{marginBottom: 19, display: 'flex'}}>
              <h3 style={{width: '50%'}} className="header">Advertisers</h3>
              {this.renderNewAdvertiserButton()}
            </div>
            <div style={{display: 'flex'}}>
              {advertisers && this.renderSearch()}
              {advertisers && this.renderSourceFilter()}
            </div>
            {advertisers && advertisers.map((advertiser, index) =>
              this.renderAdvertiser(advertiser, index)
            )}
            {advertisers && this.renderPagination()}
          </div>
          {advertiser &&
            <Details advertiser={advertiser}/>
          }
          {!!!advertiser && <div className="col-md-8 empty-column">
            <div className="text">
              Select an Advertiser
            </div>
          </div>}
        </div>
      )
    } else {
      return (
        <div></div>
      )
    };
  }
};

export default connect(
  // @ts-ignore
  ({ advertisers_ui: {
       advertisers,
       advertiser,
       total_pages,
       per_page,
       page,
       query,
       lead_source,
       filters_changed,
       show_create_advertiser_modal
     },
     // @ts-ignore
     currentUser
   }) => ({
     advertisers,
     advertiser,
     total_pages,
     per_page,
     page,
     query,
     lead_source,
     filters_changed,
     show_create_advertiser_modal,
     currentUser
  }), {
    showCreateAdvertiserModal,
    hideCreateAdvertiserModal,
    fetchAdvertisers,
    selectAdvertiser,
    setFilter
  }
)(Advertisers);
