import React from 'react';
import cs from 'classnames';
import { browserHistory } from 'react-router-legacy';
import { formatMoney } from 'accounting'
import moment from 'moment';
import createClass from 'create-react-class';
import { Modal, Button, FormControl } from 'react-bootstrap';
import PropTypes from "prop-types";
import Datetime from 'react-datetime';

const nycNeighborhoods = [
  "TriBeCa",
  "Lower East Side"
]

export default createClass({
  displayName: 'Details',

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  render() {
    const { campaign, errors } = this.props
    const startDate = campaign.start_date ? moment.utc(campaign.start_date).format('LL') : ''
    const endDate = campaign.end_date ? moment.utc(campaign.end_date).format('LL') : ''
    const errorStyle = { color: 'red' };

    return (
      <div>
        <h4>
          <i className="fa fa-bar-chart" />
          <span>Measure Impact</span>
        </h4>
        <div className="section">
          <div className="row subsection">
            <div className="col-sm-12">
              <label>
                <input type="checkbox" style={{marginRight:10}} onChange={e => this.props.setAttributes({mobile_ads: e.target.checked})}/>
                <span>
                  Geofenced Mobile Ads
                  <span style={{display:'block',fontWeight:'normal'}}>
                    {"Run mobile ads that will appear on users' phones when they are near one of your outdoor ad units. "}
                  </span>
                </span>
              </label>

              <label style={{marginTop:20}}>
                <input type="checkbox" style={{marginRight:10}} onChange={e => this.props.setAttributes({surveys: e.target.checked})}/>
                <span>
                  Pre/Post Awareness Surveys
                  <span style={{display:'block', fontWeight:'normal'}}>
                    {"Ask up to 4 questions to participants in your target zip codes and demographics."}
                  </span>
                </span>
              </label>

              <label style={{marginTop:20}}>
                <input type="checkbox" style={{marginRight:10}} onChange={e => this.props.setAttributes({sms: e.target.checked})}/>
                <span>
                  SMS Direct Response
                  <span style={{display:'block',fontWeight:'normal'}}>
                    {"We support SMS direct response if you'd like to use this feature in your campaign."}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  },
});
