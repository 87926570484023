import { withRouter } from 'react-router-legacy';
import { connect } from "react-redux";
import Measure from "react-measure";
import React from "react";
import moment from "moment";
import {
  viewMoreResults,
  loadCampaignUnits,
  loadAllCampaignUnits,
  createHolds,
  createBookings,
  clearCampaignUnits,
  submitBookingsJob,
  removeFlights
} from "../Campaign/CampaignUnits/actions";

import { getMonday } from "../../utils/dateCalculator";
import { toggleUnitPopup } from "../NewMap/actions";
import DigitalListItem from "./DigitalListItem";
import Header from "./Header";
import ListItem from "./ListItem";
import RemoveFlightsModal from "./RemoveFlightsModal";
import GlobalActions from "../../actions/GlobalActions";

class VendorListings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      position: 0,
      unitsSelected: false,
      limit: 20,
      per_page: 20,
      selected_unit_ids: [],
      loading_more: false,
      show_remove_flights_modal: false
    };

    this.scrollHandler = this.scrollHandler.bind(this);
    this.isBottom = this.isBottom.bind(this);
    this.setFirstAvailableFlight = this.setFirstAvailableFlight.bind(this);
    this.onListClick = this.onListClick.bind(this);
    this.onListOver = this.onListOver.bind(this);
    this.onListOut = this.onListOut.bind(this);
    this.getNextDates = this.getNextDates.bind(this);
    this.getStartDate = this.getStartDate.bind(this);
    this.onLeft = this.onLeft.bind(this);
    this.onRight = this.onRight.bind(this);
    this.onUnitSelect = this.onUnitSelect.bind(this);
    this.onUnitSelectAll = this.onUnitSelectAll.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentWillMount() {
    const { loadAllCampaignUnits, params } = this.props;
    const { campaign_id } = params;
    loadAllCampaignUnits(params.campaign_id)
    this.setFirstAvailableFlight(campaign_id);

    $(window).scroll(this.scrollHandler);
  }

  componentWillUnmount() {
    $(window).off("scroll", this.scrollHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading_more, show_remove_flights_modal } = prevState;
    const { units_updated, booked_campaign_token, loadAllCampaignUnits, params } = this.props;
    const new_loading_more = this.props.loading_more;
    const prev_units_updated = prevProps.units_updated;
    const clear_after_update = {};
    if (units_updated != prev_units_updated) {
      if (booked_campaign_token != prevProps.booked_campaign_token) {
        loadAllCampaignUnits(params.campaign_id);
      }
      clear_after_update.selected_unit_ids = [];
      if (show_remove_flights_modal) {
        clear_after_update.show_remove_flights_modal = false;
        GlobalActions.showMessage("Flights removed");
        this.setState(clear_after_update);
      }
    }
    if (loading_more && !!!new_loading_more) {
      this.setState({ loading_more: false });
    }
  }

  scrollHandler(e) {
    var $el = $(".fixedElement");
    var isPositionFixed = $el.css("position") == "fixed";

    if ($(window).scrollTop() > 175 && !isPositionFixed) {
      $(".fixedElement").css({ position: "fixed", top: "0px" });
    }
    if ($(window).scrollTop() < 175 && isPositionFixed) {
      $(".fixedElement").css({ position: "static", top: "0px" });
    }
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  setFirstAvailableFlight(campaign_id) {
    const { units } = this.props;
    let flights = [];
    units.forEach(
      unit =>
        (flights = flights.concat(
          unit.campaign_units.filter(flight => flight.campaign_id == campaign_id)
        ))
    );
    const firstFlightStartDate = flights.map(flight => flight.start_date).sort()[0];
    this.setState({ firstFlightStartDate });
  }

  onListClick(unit_id) {
    this.props.onUnitListingClick(unit_id);
  }

  onListOver(unit) {
    this.props.toggleUnitPopup(unit.id);
  }

  onListOut(unit) {
    this.props.toggleUnitPopup();
  }

  getNextDates() {
    const { filters } = this.props;

    const defaultCount = 12;
    const startDate = this.getStartDate();
    const intervalsCount = filters.endDate
      ? moment()
          .range(filters.startDate, filters.endDate)
          .diff("week")
      : defaultCount;
    const dateObjects = [];

    for (let i = this.state.position; i < this.state.position + intervalsCount; i++) {
      const date = moment(startDate)
        .add(i, "week")
        .toDate();
      dateObjects.push(date);
    }

    return dateObjects;
  }

  getStartDate() {
    const { filters } = this.props;
    const { firstFlightStartDate } = this.state;
    let startDate;
    if (filters.startDate) {
      startDate = moment(filters.startDate);
    } else if (firstFlightStartDate) {
      startDate = moment(firstFlightStartDate).subtract(1, "week");
    } else {
      startDate = getMonday(new Date());
    }
    return startDate;
  }

  onLeft() {
    this.setState({ position: this.state.position - 1 });
  }

  onRight() {
    this.setState({ position: this.state.position + 1 });
  }

  onUnitSelect(unit_id, is_selected) {
    const { selected_unit_ids } = this.state;
    is_selected
      ? this.setState({ selected_unit_ids: [...selected_unit_ids, unit_id] })
      : this.setState({
          selected_unit_ids: [...selected_unit_ids.filter(uid => uid != unit_id)]
        });
  }

  onUnitSelectAll(e) {
    const is_selected = e.target.checked;
    const { units } = this.props;
    is_selected
      ? this.setState({ selected_unit_ids: units.map(u => u.id), unitsSelected: true })
      : this.setState({
          selected_unit_ids: [],
          unitsSelected: false
        });
  }

  async loadMore() {
    const { viewMoreResults, params, offset } = this.props;
    const { campaign_id } = params;
    viewMoreResults(campaign_id, offset, true);
  }

  render() {
    const dates = this.getNextDates();
    const headers = dates;
    const {
      dimensions,
      selected_unit_ids,
      show_remove_flights_modal,
      loading_more
    } = this.state;
    const {
      periodForecast,
      units,
      createHolds,
      createBookings,
      params,
      available_units,
      unavailable_units,
      booked_campaign_token,
      more_results_count,
      job_id,
      submitBookingsJob,
      removeFlights,
      clearCampaignUnits,
      currentUser
    } = this.props;
    const { campaign_id } = params;
    const { width, height } = dimensions;
    const leftWidth = 300;
    const rightWidth = 150;
    const middleWidth = width - leftWidth - rightWidth;
    const startDate = dates[0];
    const endDate = moment(dates[dates.length - 1]).add(1, "week");
    const selected_units = units.filter(u => selected_unit_ids.includes(u.id));
    const full_unavailable_units =
      unavailable_units &&
      unavailable_units.length &&
      units.filter(u =>
        unavailable_units
          .map(unavailable => unavailable.supplier_face_id)
          .includes(u.supplier_face_id)
      );
    const disable_buttons = !!!selected_unit_ids || !!!selected_unit_ids.length;

    return (
      <div className="timeline">
        <RemoveFlightsModal
          units={selected_units}
          show={show_remove_flights_modal}
          onSubmit={() => removeFlights(campaign_id, selected_units)}
          onHide={() => {
            this.setState({ show_remove_flights_modal: false }, () => clearCampaignUnits())
          }}
        />
        <div className="sticky" style={{ position: "sticky", top: 0, backgroundColor: "#ffffff", zIndex: 800 }}>
          <div style={{ marginTop: "10px", marginBottom: "10px", minHeight: 34, display: "inline-block" }}>
          </div>
          {currentUser.is_supplier_admin && (
            <span>
              <div className="timeline-action-buttons">
                <button
                  className="btn timeline-remove-button"
                  disabled={disable_buttons}
                  onClick={() => this.setState({ show_remove_flights_modal: true })}
                >
                  Remove Flights
                </button>
              </div>
            </span>
          )}
          <Header
            headers={headers}
            leftWidth={leftWidth}
            middleWidth={middleWidth}
            rightWidth={rightWidth}
            height={height}
            onLeft={this.onLeft}
            onRight={this.onRight}
            onUnitSelectAll={this.onUnitSelectAll}
            unitsSelected={this.state.unitsSelected}
            is_campaign_timeline_view={true}
            periodForecast={periodForecast}
            interval={"week"}
          />
        </div>
        <Measure onMeasure={dimensions => this.setState({ dimensions })}>
          <div className="timeline__list" id="timeline-list-items" style={{willChange: 'transform'}}>
            {units.map((unit, index) => {
              return (
                <li
                  className="timeline__item"
                  key={`unit-${unit.id}-${index}`}
                  onMouseOver={() => this.onListOver(unit)}
                  onMouseOut={() => this.onListOut(unit)}
                >
                  <DigitalListItem
                    permissions={this.props.permissions}
                    editable={true}
                    headers={headers}
                    startDate={startDate}
                    endDate={endDate}
                    leftWidth={leftWidth}
                    middleWidth={middleWidth}
                    rightWidth={rightWidth}
                    height={height}
                    unit={unit}
                    howLong={4}
                    width={this.state.dimensions.width}
                    onShowEditFlightModal={() => {}}
                    onUnitSelect={this.onUnitSelect}
                    selectedUnitIds={selected_unit_ids}
                    is_campaign_timeline_view={true}
                  />
                </li>
              );
            })}
          </div>
        </Measure>
        {!!!loading_more && more_results_count > 0 && units.length > 0 && (
          <button
            className="view-more-results"
            onClick={() => {
              this.setState({ loading_more: true }, this.loadMore);
            }}
          >
            {more_results_count >= 100 ? "Load 100 More" : `Load ${more_results_count} More`}
          </button>
        )}
        {loading_more && (
          <button className="view-more-results" disabled={true}>
            <i className="fa fa-spinner fa-spin" aria-hidden="true" /> Loading More...
          </button>
        )}
      </div>
    );
  }
};

export default withRouter(
  connect(
    ({
      campaign_units_ui: {
        units,
        units_updated,
        available_units,
        unavailable_units,
        booked_campaign_token,
        more_results_count,
        offset,
        job_id
      },
      currentUser
    }) => {
      return {
        units,
        units_updated,
        available_units,
        unavailable_units,
        booked_campaign_token,
        more_results_count,
        offset,
        job_id,
        currentUser
      };
    },
    {
      loadCampaignUnits,
      loadAllCampaignUnits,
      createHolds,
      createBookings,
      toggleUnitPopup,
      viewMoreResults,
      clearCampaignUnits,
      submitBookingsJob,
      removeFlights
    }
  )(VendorListings)
);
