import React, { useState } from "react";

const ScrapeCompanyForm = ({ linkedin_users, scrapeLinkedinCompany }) => {
  const [company_name, setCompany] = useState("");
  const [keywords, setKeywords] = useState("");
  const [role, setRole] = useState("Marketing");
  const [facet_network, setFacetNetwork] = useState("");
  const [pages_to_scrape, setPagesToScrape] = useState(1);
  const [user_id, setUserId] = useState("");

  const updateMethod = (field, value) => {
    const fields = {
      company_name: setCompany,
      keywords: setKeywords,
      role: setRole,
      facet_network: setFacetNetwork,
      pages_to_scrape: setPagesToScrape,
      user_id: setUserId
    }
    return fields[field](value);
  }

  const onInputChange = (e) => {
    const { value, name } = e.target;
    updateMethod(name, value);
  }

  const renderLinkedinUsersOptions = () => {
    return linkedin_users.map(u => <option key={u.id} value={u.id}>{u.name}</option>)
  }

  const onSubmit = () => {
    const payload = {
      company_name,
      keywords,
      role,
      facet_network,
      pages_to_scrape,
      user_id
    };
    scrapeLinkedinCompany(payload);
  }

  return (
    <div
      style={{
        padding:"15px",
        background:"rgb(238, 238, 238)",
        width:"500px",
        marginBottom:"20px",
        borderRadius:"6px"
      }}
    >
      <h2>Scrape Company</h2>
      <div>
        <div className="form-group">
          <label>Company</label>
          <input
            className="form-control"
            name="company_name"
            value={company_name}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label>Keywords</label>
          <input
            className="form-control"
            name="keywords"
            value={keywords}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label>Title (Leave blank for all roles)</label>
          <input
            className="form-control"
            name="role"
            value={role}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label>Connection</label>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="facet_network"
                value="S"
                onChange={onInputChange}
              />
              2nd Degree
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="facet_network"
                value="O"
                onChange={onInputChange}
              />
              3rd+ Degree
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="facet_network"
                value="S|O"
                onChange={onInputChange}
              />
              2nd & 3rd+ Degree
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="facet_network"
                value={null}
                onChange={onInputChange}
              />
              No Degree Constraint
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Pages To Scrape (10 results per page)</label>
          <input
            className="form-control"
            name="pages_to_scrape"
            value={pages_to_scrape}
            onChange={onInputChange}
          />
        </div>
        <div className="form-group">
          <label>LinkedIn Token</label>
          <select
            className="form-control"
            name="user_id"
            onChange={onInputChange}
          >
            <option>Select user</option>
            {renderLinkedinUsersOptions()}
          </select>
        </div>
        <button
          className="btn btn-primary"
          onClick={onSubmit}
        >
          Scrape
        </button>
      </div>
    </div>
  )
}

export default ScrapeCompanyForm;