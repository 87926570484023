import { put, post } from "../../../utils/api";
import GlobalActions from "../../../actions/GlobalActions";

export const customExportToExcel = (campaign_token, template_id, export_filter) => async (dispatch, getState) => {
  const { protocol, host } = window.location;
  const options = export_filter ? { export_filter } : {};
  const url = `${protocol}//${host}/api/v1/campaigns/${campaign_token}/campaign_exports/${template_id}/custom_format`;
  const { job_id } = await put(url, options)
  dispatch({
    type: 'EXPORT_CAMPAIGN_BEGIN',
    payload: { job_id, export_type: 'Excel' }
  });
};

export const showExportProgressModal = () => (dispatch, getState) => {
  dispatch({ type: 'SHOW_EXPORT_PROGRESS_MODAL' })
};

export const hideExportProgressModal = () => (dispatch, getState) => {
  dispatch({ type: 'HIDE_EXPORT_PROGRESS_MODAL' })
};

export const onExportComplete = () => (dispatch, getState) => {
  dispatch({ type: 'EXPORT_CAMPAIGN_COMPLETE' })
};

export const standardExportToExcel = (campaign_id, export_filter) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_EXPORT_STATE'})
  const options = export_filter ? { export_filter } : {};
  try {
    const { job_id } = await put(`/api/v1/campaigns/${campaign_id}/campaign_exports/standard_format`, options)
    dispatch({
      type: 'EXPORT_CAMPAIGN_BEGIN',
      payload: { job_id, export_type: 'Excel' }
    });
  } catch (error) {
    dispatch({
      type: 'EXPORT_CAMPAIGN_ERROR',
      payload: error
    })
  }
};

export const exportSupplierTaskHolds = (campaign_id) => async (dispatch, getState) => {
  try {
    const { job_id } = await put(`/api/v1/campaigns/${campaign_id}/campaign_exports/supplier_task_units_bg?task_type=hold`)
    dispatch({
      type: 'EXPORT_HOLDS_BEGIN',
      payload: { job_id }
    });
    GlobalActions.showMessage(
      "Export started, file will download when ready."
    );
  } catch (error) {
    dispatch({
      type: 'EXPORT_HOLDS_ERROR',
      payload: error
    })
  }
};

export const onHoldExportComplete = () => (dispatch, getState) => {
  dispatch({ type: 'EXPORT_HOLDS_COMPLETE' });
};

export const exportToPdf = (campaign_id, export_gen_options) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_EXPORT_STATE'})
  try {
    const { job_id, message } = await post(`/api/v1/campaigns/${campaign_id}/pdf_export`, export_gen_options);
    message.length > 1 && GlobalActions.showError(message);
    dispatch({
      type: 'EXPORT_CAMPAIGN_BEGIN',
      payload: { job_id, export_type: 'PDF' }
    });
  } catch (error) {
    GlobalActions.showError(error);
    dispatch({
      type: 'EXPORT_CAMPAIGN_ERROR',
      payload: error
    })
  }
};

export const exportToPpt = (campaign_id, export_gen_options) => async (dispatch, getState) => {
  dispatch({ type: 'RESET_EXPORT_STATE'})

  try {
    const { job_id, message } = await post(`/api/v1/campaigns/${campaign_id}/pptx_export`, export_gen_options);
    message.length > 1 && GlobalActions.showError(message);
    dispatch({
      type: 'EXPORT_CAMPAIGN_BEGIN',
      payload: { job_id, export_type: 'PPTX' }
    });
  } catch (error) {
    GlobalActions.showError(error);
    dispatch({
      type: 'EXPORT_CAMPAIGN_ERROR',
      payload: error
    })
  }
};
