import React from 'react';
import createClass from 'create-react-class';
import { post } from '../../utils/api';
import { get } from '../../utils/api';
import GlobalActions from '../../actions/GlobalActions';
import AuthStore from '../../stores/AuthStore';
import ExperimentStore from '../../stores/ExperimentStore'
import Success from './Success'

const DemoRequest = createClass({
  displayName: 'DemoRequest/index',

  getInitialState() {
    return ({
      initalState: true,
      source: "",
      fields: {
        email: { value: '', placeholder: 'Your work email address', display: true, required: true },
        name: { value: '', placeholder: 'Your name', required: true },
        company_name: { value: '', placeholder: 'Your company name', required: true },
        job_title: { value: '', placeholder: 'Your job title'}
      },
      clearbit_submitted: false,
      submitted: false,
      errors: {},
      experiments: ExperimentStore.getState()
    })
  },

  componentDidUpdate(prevProps, prevState) {
    const { errors, submitted, fields } = this.state
    if(submitted) { this.debouncedDemoFlow = () => {} }
    if (prevState.initalState) { return; }
    this.debouncedDemoFlow()
  },

  componentDidMount() {
    const ref_email = this.props.location && this.props.location.query && this.props.location.query.email;
    const source = this.props.location && this.props.location.query && this.props.location.query.source;
    AuthStore.listen(this.onAuthChange)

    if (ahoy) ahoy.track("Demo Request Pageview")

    const user = AuthStore.userInState()
    const { fields } = this.state;

    if (user && !!!user.guest && user.email && user.email.length > 4 && !!!user.submitted) {
      this.setState({ fields: { ...fields, email: { value: user.email } } }, () => {
        this.onClearbitSubmit(true);
      })
      return;
    }
    const email = user && user.email || this.state.fields.email.value
    get(`/api/v1/demo_requests/previous_progress?email=${email}`)
      .then(({ demo_request }) => {
        this.setState({
          fields: {
            ...this.state.fields,
            name: {...this.state.fields.name, value: demo_request.name || ""},
            email: {...this.state.fields.email, value: demo_request.email || ref_email || ""},
            company_name: {...this.state.fields.company_name, value: demo_request.company_name || ""},
          },
          initalState: false,
          submitted: demo_request.submitted_at && demo_request.submitted_at.length > 0,
          source: source
        })
      })
    this.debouncedDemoFlow = _.debounce(this.initalizeDemoFlow, 1000);
  },

  onAuthChange() {
    if (AuthStore.isLoggedIn()) {
      const user = AuthStore.getState().user;
      if (user && user.email.includes("@compass.com")) {
        window.location = 'https://compass.adquick.com';
      } else {
        window.location = 'https://www.adquick.com';
      }
    }
  },

  onInputChange({ target }) {
    const { value, name } = target
    const field = Object.assign({}, this.state.fields[name], {value})
    this.setState({ fields: {...this.state.fields, [name]: {...field} } })
  },

  validate(email_only=false) {
    const { fields } = this.state
    const { name, email, company_name } = fields

    const errors = {}
    if((email.value.length < 1 || !email.value.includes("@"))) { errors.email = "Please fill out a valid email address" }
    if (!!!errors.email && !!!email_only) {
      if(name.value.length < 1) { errors.name = "Please fill out your name" }
      if(company_name.value.length < 1 ) { errors.company_name = "Please fill out your company name" }
    }

    this.setState({ errors })
    return errors
  },

  async onClearbitSubmit(initial_load=false) {
    const { fields, clearbit_submitted } = this.state
    const errors = this.validate(true)

    if (!!!errors.email && !clearbit_submitted) {
      const { person, company } = await get(`/api/v1/clearbit?email=${fields.email.value}`)
      this.fillFieldsFromClearbit(person, company, initial_load)
    }
  },

  async fillFieldsFromClearbit(person, company, initial_load=false) {
    let name, company_name, title;
    if (person) {
      company_name = person.company_name
      title = person.title
      name = this.fillPersonName(person)
    }
    const company__name = company_name || company && company.name || ''
    const fields = {
      ...this.state.fields,
      name: { ...this.state.fields.name, value: name || '', display: !name},
      company_name: { ...this.state.fields.company_name, value: company__name || '', display: !company__name },
      job_title: { ...this.state.fields.job_title, value: title || '', display: !title }
    }
    const submitted = fields.name.value.length > 1 && fields.email.value.length > 1 && fields.company_name.value.length > 1
    this.setState({ fields: {...fields }, clearbit_submitted: true, submitted }, () => {
      if (submitted && initial_load)
        this.onSubmit(initial_load)
    })
  },

  fillPersonName({ full_name, first_name, last_name }) {
    if (full_name) { return full_name }
    if (first_name && last_name) { return `${first_name} ${last_name}`}
    return null
  },

  onSubmit(initial_load=false) {
    const { clearbit_submitted } = this.state
    if(!clearbit_submitted) {
      return this.onClearbitSubmit(initial_load)
    }
    const errors = this.validate();
    if (_.isEmpty(errors)) {
      this.saveChanges(initial_load)
      if (ahoy) ahoy.track("Demo Request Submitted")
    }
  },

  async initalizeDemoFlow() {
    const payload = this.demoFormPayload()
    try {
      const result = await post(`/api/v1/demo_requests/create_or_update/`, payload)
      this.setState({ demo_request_id: result.demo_request_id })
    } catch (error) {
      this.setState({ errors: { email: error.replace('INVALID_EMAIL_ADDRESS: Email:','') } });
    }
  },

  demoFormPayload(submitted = false) {
    const { fields } = this.state
    return {
      email: fields.email.value,
      name: fields.name.value,
      company_name: fields.company_name.value,
      job_title: fields.job_title.value,
      submitted: submitted
    }
  },

  saveChanges(initial_load=false) {
    const payload = this.demoFormPayload(true)
    post(`/api/v1/demo_requests/create_or_update/`, payload)
      .then(result => {
        const { fields } = this.state;
        const { company_name, name, email } = fields;
        const submitted = name.value.length > 1 && email.value.length > 1 && company_name.value.length > 1
        if (result.error && result.error.length > 0) {
          if (result.error.includes("email")){
            this.setState({ errors: { email: result.error.replace('INVALID_EMAIL_ADDRESS: Email:','') } });
          }
        } else this.setState({ demo_request_id: result.demo_request_id, submitted })
      })
      .catch(err => {
        throw(err);
      })
  },

  onSignIn() {
    window.location.assign("/login");
  },

  renderFields() {
    const { fields, errors } = this.state
    return _.map(fields, (field,label) => {
      if(field.display) {
        return (<div className="form-group" key={label}>
          <label htmlFor={label}>
            {this.renderFieldLabel(label)} {field.required && <span className="asterisk">*</span>}
            {errors[label] &&
              <span className="error">
                {errors[label]}
              </span>
            }
          </label>
          <input
            type="text"
            className={"form-control " + (errors[label] ? "error" : "")}
            id={label}
            name={label}
            placeholder={field.placeholder}
            value={field.value}
            ref={(input) => this.firstInput = input}
            onChange={this.onInputChange}
          />
        </div>)
      }
    })
  },

  renderFieldLabel(label) {
    return _.flow(
      field => _.startCase(field),
      field => _.capitalize(field)
     )(label)
  },

  render() {
    const { submitted, experiments, fields } = this.state;
    const mainHeader = "Get a Demo";
    const mainDescription = "Let us know how to get in touch and our team will reach out to schedule a personal demo. We're excited to show you how AdQuick will revolutionize your outdoor campaigns!";
    const submittedHeader = "Thanks! We'll be in touch soon.";
    const submittedDesc1 = "A member of our team will contact you to schedule your personalized demo.";
    const submittedDesc2 = "We can't wait to show you the future of outdoor advertising!";

    const experiment = _.get(experiments, 'homepage4') == 'v3' ? 'v3' : 'v4'
    const email = fields.email.value
    const is_personal_email = (/@(gmail.com|outlook.com|hotmail.com|yahoo.com|inbox.com|icloud.com|mail.com|aol.com|me.com|comcast.net|verizon.net|sbcglobal.net|bellsouth.net|ymail.com|cox.net|yahoo.co.uk)/).test(email);

    return (
      <div id="demo-request">

        <div className="demo_header">
          <div className="header_image">
            <img src={ASSETS['onboarding-pins']} />
          </div>
          {!submitted && <div className="header_text">
            {mainHeader}
          </div>}
          {!submitted && <div className="header_description">
            {mainDescription}
          </div>}
          {(submitted && experiment == 'v4' && !!!is_personal_email) && <div>
            <Success location={this.props.location} {...this.state.fields} />
          </div>}
          {(submitted && (experiment == 'v3' || is_personal_email)) && <div>
              <div className="submitted_header_text">
                {submittedHeader}
              </div>
              <div className="submitted_header_description">{submittedDesc1}</div>
              <div className="submitted_header_description">{submittedDesc2}</div>
          </div>}
          {false &&
            <i className="demo-request-spinner fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          }
        </div>


        {!submitted && initialState && <div className="get_demo_box">

          <div className="header">
            Contact Info
          </div>

          <div className="form_fields">
            {this.renderFields()}
          </div>

          <div className="clearfix">
            <input
              type="submit"
              disabled={submitted}
              className="get_demo_button"
              value="Get my Demo"
              onClick={() => this.onSubmit(false)}
            />
            <div className="demo-signin-link-area">
              <a className="demo-signin-link" onClick={this.onSignIn}>
                Already have an account? Login here
              </a>
            </div>
          </div>
        </div>}
      </div>
    )
  }
})

export default DemoRequest;
