import React from 'react';
import createClass from 'create-react-class';
import moment from 'moment';
import PropTypes from "prop-types";

import { get } from '../../../utils/api';
import SimpleMap from '../../SimpleMap';

export default createClass({
  displayName: 'Details',

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      showMap: false,
      notification: ''
    };
  },

  showMapNotification() {
    this.setState({
      notification: "Your coordinates were saved"},
      this.clearNotificationCallback
    )
  },

  clearNotificationCallback() {
    setTimeout(() => {
      this.setState({notification: ""})
    }, 8000);
  },

  async loadLocation(location_id) {
    const { location } = await get(`/api/v1/locations/${location_id}`)
    this.setState({ location })
  },

  toggleMap() {
    const showMap = this.state.showMap;
    if (showMap) {
      this.props.setAttributes({geo_json_features: {}})
    }
    this.setState({showMap: !!!showMap})
  },

  render() {
    const { campaign, errors, locations } = this.props
    const startDate = campaign.start_date ? moment.utc(campaign.start_date).format('LL') : ''
    const endDate = campaign.end_date ? moment.utc(campaign.end_date).format('LL') : ''
    const errorStyle = { color: 'red' };
    const advertiserGreyLabel = AppConfig.advertiserGreyLabel;
    const isCompass = advertiserGreyLabel.name === "Compass";
    const isClearChannel = advertiserGreyLabel.name === "Clear Channel Outdoor"
    const marketLevel = (isCompass || isClearChannel) ? 'neighborhood' : 'dma';

    return (
      <div>
        <h4>
          <i className="fa fa-location-arrow" />
          <span>Campaign Locations</span>
        </h4>
        <div className="section">
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name" style={(errors.neighborhood || errors.tip) && errorStyle}>
                { (isCompass || isClearChannel) ? 'NYC Neighborhood' : 'Markets'}
              </span>
              <select
                className="form-control"
                onChange={e => {
                  const attributes = {};
                  attributes[marketLevel] = e.target.value;
                  this.props.setAttributes(attributes);
                  if (marketLevel == 'neighborhood') this.loadLocation(e.target.value);
                }}
              >
                <option value=""></option>
                {locations.map(({id, name}) => <option key={name} value={id}>{name}</option>)}
              </select>
            </div>
          </div>
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name" style={errors.location_notes && errorStyle}>Additional notes on location</span>
              <textarea
                className="form-control"
                defaultValue={campaign.location_notes}
                onChange={e => this.props.setAttributes({ location_notes: e.target.value })}
                placeholder=""
                style={{ height: 50 }}
              />
            </div>
          </div>
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name">Points of Interest</span>
              <input type="text"
                className="form-control"
                value={campaign.points_of_interest}
                placeholder="ie. competitor locations, corporate offices, high profile listings"
                onChange={e => this.props.setAttributes({ points_of_interest: e.target.value })}
              />
            </div>
          </div>
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name">Demographics to reach</span>
              <input type="text"
                className="form-control"
                value={campaign.demographic}
                placeholder="ie. Household Income, Gender, Race"
                onChange={e => this.props.setAttributes({ demographic: e.target.value })}
              />
            </div>
          </div>
          <div className="row subsection">
            <div className="col-sm-12">
              <span className="section-name" style={(errors.neighborhood || errors.tip) && errorStyle}>Specify where you want your options on a map</span>
              <div className="show-map-button" onClick={this.toggleMap}>
                {this.state.showMap ? 'Hide Map' : 'Show Map'}
              </div>
              {this.state.showMap && <SimpleMap
                campaign={campaign}
                errors={errors}
                setAttributes={this.props.setAttributes}
                lat={(isCompass || isClearChannel) ? 40.7678 : 40.0161}
                lon={(isCompass || isClearChannel) ? -73.9718 : -97.1815}
                width={"100%"}
                height={500}
                renderDrawTool={true}
                zoom={(isCompass || isClearChannel) ? 10 : 3}
                showZoomControls={true}
                setState={this.setState}
                showMapNotification={this.showMapNotification}
                campaign_area={ this.state.location ? {area: {features: [this.state.location] }} : null }
              />}
              {this.state.showMap &&
                  <div className="map-notification-area">
                    {this.state.notification &&
                        <div className="alert alert-success">
                          {this.state.notification}
                        </div>
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  },
});
