export const Keys = {
  ENTER:     13,
  ESC:       27,
  LEFT:      37,
  UP:        38,
  RIGHT:     39,
  DOWN:      40,
  F:         70,
  T:         84,
}

class CampaignCarouselNavigation {

  constructor(units) {
    this.units = units;
    this.activeIndex = undefined;
  }

  next() {
    if (this.activeIndex == undefined) return this.startFromTop();
    if (this.activeIndex < (this.units.length - 1)) this.activeIndex++;
    return this.currentUnit();
  }

  prev() {
    if (this.activeIndex == undefined) return this.startFromTop();
    if (this.activeIndex > 0) this.activeIndex--;
    return this.currentUnit();
  }

  startFromTop() {
    this.activeIndex = 0;
    return this.currentUnit();
  }

  currentUnit() {
    return this.units[this.activeIndex]
  }

  jump(index) {
    this.activeIndex = index;
    return this.currentUnit()
  }

}

export default CampaignCarouselNavigation;
