import { combineReducers } from 'redux';
import { createForms } from 'react-redux-form';

import activity from '../components/Campaign/Activity/reducer';
import adquick_requests_index from '../components/Requests/reducer'
import advertisers_ui from '../components/Advertisers/reducer';
import all_markets from './all_markets';
import availability_statuses from './availability_statuses';
import campaign from '../components/Campaign/reducer'
import campaign_analytics from '../components/Campaign/NewAnalytics/Setup/reducer';
import campaign_builder from '../components/Vendors/CampaignBuilder/reducer'
import campaign_export from '../components/Campaign/Export/reducer';
import campaign_requests from '../components/Requests/CampaignRequests/reducer'
import campaign_suppliers from './campaign_suppliers';
import campaign_units from '../components/Campaign/CampaignUnits/reducer'
import campaigns from '../components/Campaigns/reducer'
import comments from '../components/CampaignUnitComments/reducer';
import companies from '../components/Companies/reducer'
import current_market from './current_market';
import design_assets from './design_assets';
import shipping_addresses from './shipping_addresses';
import feature_flags from '../components/FeatureFlags/reducer'
import filters_bar from './filters_bar';
import greylabel from './greylabel'
import inventory from '../components/Inventory/reducer'
import issues from '../components/Campaign/Manage/reducer';
import jobs from './background_job';
import map from '../components/NewMap/reducer';
import markets from './markets';
import media_types from './media_types';
import unit_screen_subtypes from './unit_screen_subtypes';
import media_subtypes from './media_subtypes';
import campaign_supplier_contracts from './campaign_supplier_contracts';
import cart from './cart';
import places from '../components/Places/reducer';
import planner from '../components/Planner/reducer';
import pops from './pops';
import rfp_response from '../components/Requests/RFP/reducer';
import segment from './segment';
import supplier_tasks from './supplier_tasks';
import suppliers from './suppliers';
import unit_tags from './unit_tags';
import units from './units';
import userReducer, * as fromUser from '../features/User/reducer';
import vendor_dashboard from '../components/VendorDashboard/reducer';
import supplier_addresses from './supplier_addresses';
import dialog from '../components/Campaign/Mobile/reducer';

const newCampaignRequest = {
  name: '',
  email: '',
  phoneNumber: '',
  timeFrame: '',
  duration: '',
  budget: '',
  locationPreference: '',
  poiPreference: '',
  targetDemographic: '',
  advertiser: '',
  notes: ''
}

export default (props) => (
  combineReducers({
    feature_flags,
    companies,
    greylabel,
    campaign,
    campaign_suppliers,
    campaign_units_ui: campaign_units,
    campaigns,
    units,
    markets,
    all_markets,
    current_market,
    filters_bar,
    media_types,
    media_subtypes,
    unit_screen_subtypes,
    campaign_supplier_contracts,
    cart,
    unit_tags,
    suppliers,
    map,
    inventory,
    planner,
    comments,
    activity,
    jobs,
    issues,
    campaign_export,
    places_ui: places,
    supplier_tasks,
    availability_statuses,
    adquick_requests_ui: combineReducers({
      index: adquick_requests_index,
      campaign_requests,
      rfp_response,
    }),
    campaign_builder_ui: campaign_builder,
    pops,
    design_assets,
    shipping_addresses,
    advertisers_ui,
    vendor_dashboard,
    currentUser: userReducer,
    campaign_analytics,
    sessionId: (state = null, action) => state,
    segment,
    supplier_addresses,
    dialog,
    ui: {},
    ...createForms({
      campaignRequest: newCampaignRequest,
    })
  })
)
