const initialState = {
  campaign_exporting: false,
  show_export_progress_modal: false,
  job_id: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "EXPORT_CAMPAIGN_BEGIN": {
      const { job_id, export_type } = action.payload;
      return {
        ...state,
        job_id,
        export_type,
        campaign_exporting: true,
      };
    }
    case "EXPORT_CAMPAIGN_COMPLETE": {
      return {
        ...state,
        campaign_exporting: false,
        show_export_progress_modal: false,
        job_id: null
      };
    }
    case "SHOW_EXPORT_PROGRESS_MODAL": {
      return {
        ...state,
        show_export_progress_modal: true,
      };
    }
    case "HIDE_EXPORT_PROGRESS_MODAL": {
      return {
        ...state,
        show_export_progress_modal: false,
      };
    }
    case "EXPORT_CAMPAIGN_ERROR": {
      return {
        ...state,
        error: action.payload
      }
    }
    case "EXPORT_HOLDS_BEGIN": {
      const { job_id } = action.payload;
      return {
        ...state,
        job_id,
        holds_exporting: true,
      };
    }
    case "EXPORT_HOLDS_COMPLETE": {
      return {
        ...state,
        holds_exporting: false,
        job_id: null
      };
    }
    case "RESET_EXPORT_STATE": {
      return {
        ...initialState
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
