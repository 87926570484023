import * as React from "react";
import { PaddingProperty } from "csstype";
import Theme from "../../theme";

export type AlignType = "left" | "center" | "right" | undefined;

export enum BoxRadius {
  Default = "8px",
  None = "0",
}
interface IStripedTableProps {
  active?: boolean;
  useHover?: boolean;
  align?: AlignType;
  inline?: boolean;
  padding?: PaddingProperty<string>;
  margin?: string;
  width?: string;
  height?: string;
  radius?: BoxRadius;
  children: React.ReactNode;
}

interface IStripedTableState {
  isHover?: boolean;
}

class StripedTable extends React.Component<IStripedTableProps, IStripedTableState> {

  private initialState: IStripedTableState = {
    isHover: false,
  };

  constructor(props: IStripedTableProps) {
    super(props);
    this.state = this.initialState;
  }

  public render() {
    const { active, align, padding, margin, width, height, children, inline, useHover, radius } = this.props;
    return (
      <div
        onMouseEnter={() => this.setState({ isHover: Boolean(useHover && true) })}
        onMouseLeave={() => this.setState({ isHover: Boolean(useHover && false) })}
        style={StripedTableStyle(padding, margin, width, height, active, inline, align, this.state.isHover, radius)}>
        {children}
      </div>
    );
  }
}

const StripedTableStyle = (
  padding?: string,
  margin: string = "0",
  width?: string,
  height?: string,
  active?: boolean,
  inline?: boolean,
  align: AlignType = "left",
  isHover: boolean = false,
  radius: BoxRadius = BoxRadius.Default,
) => ({
  display: inline ? "inline-block" : "block",
  background: active ? "#F1F6FD" : "#FFF",
  border: `1px solid ${active || isHover ? "#4A90E2" : "#FFF"}`,
  cursor: isHover ? "pointer" : "auto",
  borderRadius: radius,
  textAlign: align,
  color: active ? "#4A90E2" : "#333",
  boxShadow: Theme.SHADOW_DEFAULT,
  transition: "0.3s ease",
  transitionProperty: "background, border, color",
  width: width || "auto",
  height: height || "auto",
  padding: padding || "18px",
  margin,
} as React.CSSProperties);

export default StripedTable;
