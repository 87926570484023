import React, { Component } from "react";
import DateRangePicker from "../../DatePicker/Range";
import GlobalActions from "../../../actions/GlobalActions";

interface ICalendarPickerProps{
  popupOptions: any;
  onDatePick: (date_picked) => void;
}

interface ICalendarPickerState {
  selected: null;
}

class CalendarPickerModal extends Component<ICalendarPickerProps,ICalendarPickerState> {
  constructor(props) {
    super(props);
    this.state = {
      selected: null
    }
    this.handleSelect = this.handleSelect.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  public render() {
    const center = {display:'flex',justifyContent: 'center'}
    return (
      <div className="default_modal_container" onClick={e => e.stopPropagation()}>
        <div style={{width: 330}} className="default_modal">
          <div 
            style={{justifyContent:"space-evenly"}}
            className="modal_header"
          >
            <h5>Select Posted Date</h5>
            <div className="modal_close">
              <div className="close_button" onClick={this.onCancel}></div>
            </div>
          </div>
          <div className="modal_body">
            <div style={center} className="field">
              <DateRangePicker
                onSelect={this.handleSelect}
                value={this.state.selected}
                singleDateRange={true}
                selectionType="single"
              />
            </div>
            <div className="modal_footer">
              <button
                className="primary-button"
                onClick={this.onClose}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onClose() {
    if(!this.state.selected) {
      return GlobalActions.showError('Select the day when the unit was posted!');
    }
    GlobalActions.closePopup();
  }

  private onCancel() {
    const { popupOptions } = this.props
    const { onCancelUpload } = popupOptions;
    onCancelUpload();
    GlobalActions.closePopup();
    return GlobalActions.showError('Upload cancelled.');
  }

  private handleSelect(value) {
    const { popupOptions } = this.props
    const { onDatePick } = popupOptions;
    this.setState({ selected: value })
    onDatePick(value);
  }
}

export default CalendarPickerModal;
