import React from 'react';
import { customIconMapping, customIcons } from './functions/iconFuncs'

const Icon = ({ type, value, isPackage, secondaryStyle }) => {
  let packageLabel = null;
  if (isPackage) { packageLabel = <span className="label label-white marker-inner-label">PKG</span> };

  if (customIcons.includes(type)) {
    return (<div className={ `marker marker-points marker-${type}` } style={{ background: '#fff', color: '#CC0000' }}>
      <img src={customIconMapping[type]} style={{ width: 16, height: 16 }} />
      <span style={{ paddingLeft: 4, paddingTop: 3 }}>{value}</span>
    </div>);
  } else if (type == 'points') {
    return (<div className="marker marker-points">
      <i className="fa fa-thumb-tack" />
      <span>{value}</span>
    </div>);
  } else if (type == 'point') {
    return (<div className="marker marker-point">
      <i className="fa fa-thumb-tack" />
      <span>{value}</span>
    </div>);
  } else if (type == 'other') {
    return (<div className="marker marker-point">
      <span>{value}</span>
    </div>);
  }

  let mainClassName = `marker marker-svg marker-${type}`;
  if (secondaryStyle === "face_id") {
    mainClassName += " marker-face-id"
  }

  return (
    <div className={mainClassName}>
      <svg className="svg-icon"><use xlinkHref={`#icon_${type}`} /></svg>
      <span className="svg-value">{value}</span>
      {packageLabel}
    </div>
  );
}

export default Icon;
