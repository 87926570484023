import React, { Component } from "react";
import { connect } from "react-redux";

import GlobalActions from "../../actions/GlobalActions";
import * as Icons from "../../models/icons";
import PlacesStore from "../../stores/PlacesStore";
import { updateIcon } from "../Places/actions";
import UploadButton from "../UploadButton";

class AddCustomPOILogoModal extends Component {
  get selectedPois() {
    const pois = this.props.selectedPois;
    if (!pois?.length) return [];
    return pois.map(p => p.label);
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedNames: this.selectedPois,
    };
  }

  onNameSelect(option) {
    const { selectedNames } = this.state;
    if (selectedNames.includes(option)) {
      this.setState({ selectedNames: selectedNames.filter(name => name !== option) });
    } else {
      this.setState({ selectedNames: selectedNames.concat([option]) });
    }
  }

  checkSelectedPois() {
    const { selectedNames } = this.state;
    if (!selectedNames.length) {
      GlobalActions.showError("You need to select at least one POI from the list");
      return;
    }
  }

  async assignIconToPoi(path) {
    this.checkSelectedPois();
    const { updateIcon, campaign, onHide } = this.props;
    await updateIcon(this.state.selectedNames, campaign, path);
    PlacesStore.trigger("places:updated");
    GlobalActions.showMessage("Successfully added custom icon for POIs");
    onHide && onHide();
  }

  renderPlacesGroupedByName() {
    const { places } = this.props;
    const groupedByName = _.groupBy(places, "name");
    return (
      <ul className="poi_listing_for_logo">
        <li>
          <h4>All POIs in your campaign</h4>
        </li>
        {Object.keys(groupedByName)
          .sort(Intl.Collator().compare)
          .map(name => {
            const onClick = e => {
              this.onNameSelect(name);
            };
            return (
              <li key={name}>
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.selectedNames.includes(name)}
                    name={name}
                    onChange={onClick}
                  />
                  <span>{name}</span>
                  <i className="count">({groupedByName[name].length})</i>
                </label>
              </li>
            );
          })}
      </ul>
    );
  }

  renderPlaceholder() {
    return (
      <div style={{ padding: "15px" }}>
        <p>There are no POIs on this campaign yet.</p>
      </div>
    );
  }

  renderIcons(path, i) {
    return (
      <a key={i} href="" onClick={() => this.assignIconToPoi(path)}>
        <img src={`${Icons.url}${path}`} height="24" />
      </a>
    );
  }

  renderContent() {
    const { selectedNames } = this.state;
    return (
      <div className="custom-poi-container">
        <div className="left">{this.renderPlacesGroupedByName()}</div>
        <div className="right">
          <h4>Choose an icon</h4>
          <div className="icons">{Icons.poiIcons.map(this.renderIcons.bind(this))}</div>
          <div className="ui">
            <h4 className="pt-32">Or upload your own logo</h4>
          </div>
          <UploadButton key="upload_button" onComplete={({ path }) => this.assignIconToPoi(path)}>
            <div>
              <p>Please use only PNG images with transparency, size 60x60px</p>
              <button className="btn btn-default" onClick={e => e.preventDefault()} disabled={!selectedNames.length}>
                <i className="fa fa-cloud-upload" /> Upload logo for selected POIs
              </button>
            </div>
          </UploadButton>
        </div>
      </div>
    );
  }

  render() {
    const { places } = this.props;
    const isPoisEmpty = !places || places.length == 0;

    return isPoisEmpty ? this.renderPlaceholder() : this.renderContent();
  }
}

export default connect(
  ({ campaign, places_ui }) => ({
    campaign_token: campaign.campaign.token,
    places: places_ui && places_ui.places ? places_ui.places.filter(p => p.saved) : [],
  }),
  {
    updateIcon,
  },
)(AddCustomPOILogoModal);
