import "./styles.css";
import "rc-slider/assets/index.css";
import * as React from "react";
import RcSlider from "rc-slider";

export type AlignType = "left" | "center" | "right" | undefined;

interface ISliderProps {
  defaultValue?: number;
  onChange: (newVal: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

interface ISliderState {
  isHover?: boolean;
}

const railStyle = {
  backgroundColor: "#e9e9e9",
};

const trackStyle = {
  backgroundColor: "#4A90E2",
};

const handleStyle = {
  width: "14px",
  height: "14px",
  background: "#4A90E2",
  border: "1px solid #FFFFFF",
};

class Slider extends React.Component<ISliderProps, ISliderState> {
  private initialState: ISliderState = {
    isHover: false,
  };

  constructor(props: ISliderProps) {
    super(props);
    this.state = this.initialState;
  }

  public render() {
    const { min, max, step, defaultValue, onChange } = this.props;

    return (
      <RcSlider
        min={min}
        max={max}
        value={defaultValue}
        step={step}
        onChange={onChange}
        railStyle={railStyle}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
      />
    );
  }
}

export default Slider;
