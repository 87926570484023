export const availabilityStatus = {
  booked: ['booking_approved'],
  on_hold: ['hold_adjusted', 'hold_approved'],
  hold_requested: ['hold_requested'],
  hold_expired: ['hold_expired'],
  backup_hold: ['backup_hold'],
  availability_requested: ['initial_requested'],
  available: ['rfp_approved', 'initial_adjusted', 'initial_approved', 'refresh_adjusted', 'refresh_approved', 'hold_adjusted', 'hold_approved', 'api_approved'],
  unavailable: ['initial_unavailable', 'refresh_unavailable', 'hold_unavailable', 'api_unavailable'],
  refresh_requested: ['refresh_requested']  ,
  available_refreshed: ['refresh_adjusted', 'refresh_approved'],
  unavailable_refreshed_rejected: ['refresh_unavailable'],
  unavailable_hold_rejected: ['hold_unavailable']
}
