import { connect } from "react-redux";
import Fuse from "fuse.js";
import React, { Component } from "react";
import cs from "classnames";

import { filterUnits } from "../../actions/UnitsActions";
import { loadSupplierOptions } from "../../actions/supplier_actions";
import { sidebarUnits, withinBounds } from '../../models/Units'
import _ from "lodash";

const POPUP_WIDTH = 220;

class SuppliersFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.debouncedSearch = _.debounce(this.searchBySupplierName, 200);
    this.onClear = this.onClear.bind(this);
    this.findNear = this.findNear.bind(this);
    this.state = {
      isExpanded: false,
      activeSuppliers: [],
      narrowedList: null,
      suppliers: [],
      suppliersFromQueryString: []
    };
  }

  async componentDidMount() {
    const { loadSupplierOptions, campaign } = this.props;
    const campaignId = campaign && campaign.campaignId;
    document.addEventListener("click", this.onToggle);
    await loadSupplierOptions(campaignId);
    this.setState({ suppliers: this.props.suppliers }, this.filterSuppliersFromQueryString)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { initialValue, isActive } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ suppliersFromQueryString: initialValue })
    }
  }

  filterSuppliersFromQueryString() {
    const { suppliers } = this.props
    const { suppliersFromQueryString } = this.state
    if (!suppliersFromQueryString) { return }
    this.setState({ activeSuppliers: suppliers.filter(s => suppliersFromQueryString.includes(s.value)) })
  }

  searchBySupplierName(query) {
    const options = {
      keys: ["label"],
      minMatchCharLength: 3,
      threshold: 0.4,
      maxPatternLength: 20,
    };
    const fuse = new Fuse(this.props.suppliers, options);
    this.setState({ narrowedList: fuse.search(query) });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target) || this.node == event.target;
    if (!isExpanded && clickedWithinElement) { return this.manageSuppliersState() }
    return this.setState({ isExpanded: false, narrowedList: null });
  }

  async manageSuppliersState() {
    await this.findNear()

    const unitsInBounds = sidebarUnits(withinBounds(this.props.units, this.props.map.bounds));
    const sidebarUnitsSuppliers = [...new Set(unitsInBounds.map((u) => u.supplierName))]
    const suppliers = this.props.suppliers.filter(sup => sidebarUnitsSuppliers.includes(sup.label))
    const filteredOutSuppliers = this.props.suppliers.filter(sup => !sidebarUnitsSuppliers.includes(sup.label))
    
    this.setState({ suppliers, filteredOutSuppliers, isExpanded: true, narrowedList: null })
  }

  onClear() {
    this.setState({ activeSuppliers: [] }, this.onFilter);
  }

  async findNear() {
    const { map } = this.props
    const { loadSupplierOptions, campaign } = this.props;
    const campaignId = campaign && campaign.campaignId;

    const bounds = {min_lon: map.bounds[0][0], min_lat: map.bounds[0][1], max_lon: map.bounds[1][0], max_lat: map.bounds[1][1]}
    await loadSupplierOptions(campaignId, bounds);
  }

  onSupplierSelect(option) {
    const { activeSuppliers } = this.state;
    if (!activeSuppliers.find(m => m.value === option.value)) {
      return this.setState({ activeSuppliers: activeSuppliers.concat([option]) }, this.onFilter)
    }
    return this.setState({ activeSuppliers: activeSuppliers.filter(m => m.value !== option.value) }, this.onFilter)
  }

  onFilter() {
    const { activeSuppliers } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    const supplier = !activeSuppliers || !activeSuppliers.length ? null : activeSuppliers.map(s => s.value);

    this.setState({ isExpanded: false });
    _.defer(filterUnits, { supplier: supplier });
    handleQueryParams({ 'supplier[]': supplier })
  }

  getButtonLabel() {
    const { activeSuppliers } = this.state;
    const truncate = string => _.truncate(string, { length: 18 });
    if (!activeSuppliers || !activeSuppliers.length) return "Vendor";
    if (activeSuppliers.length === 1) return truncate(_.first(activeSuppliers).label);
    return `Vendors: ${activeSuppliers.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { activeSuppliers, narrowedList, filteredOutSuppliers } = this.state;
    const { suppliers } = this.state;
    const suppliersList = narrowedList && narrowedList.length ? narrowedList : suppliers;
    const haveFilteredOutSuppliers = filteredOutSuppliers.length > 0 && suppliersList.length > 0
    return (
      <div className="filter_popup suppliers_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>VENDOR</h4>
        {activeSuppliers && !!activeSuppliers.length && (
          <a
            style={{
              float: "right",
              top: -32,
              paddingRight: 12,
              position: "relative",
              color: "#999999",
            }}
            onClick={this.onClear}
          >
            Clear
          </a>
        )}
        <input
          autoFocus={true}
          type="text"
          className="narrow_list"
          onChange={e => this.debouncedSearch(e.target.value)}
          placeholder="Filter by name"
        />
        <ul>
          {this.renderSupplierList(suppliersList)}
          {haveFilteredOutSuppliers && <li className="separator">Filtered Out Vendors</li>}
          {this.renderSupplierList(filteredOutSuppliers)}
        </ul>
      </div>
    );
  }

  renderSupplierList(supplierList) {
    return supplierList.map(supplier => this.renderSupplierOption(supplier))
  }

  renderSupplierOption(supplier) {
    return (
      <li
        key={supplier.value}
        onClick={this.onSupplierSelect.bind(this, supplier)}
        className={cs({ active: this.isSupplierActive(supplier) })}
      >
        {_.truncate(supplier.label, { length: 18 })}
        {supplier.count && <i className="count">({supplier.count})</i>}
        <i className="fa fa-check" />
      </li>
    )
  }

  isSupplierActive(supplier) {
    const { activeSuppliers } = this.state
    return activeSuppliers.find(s => s.value === supplier.value)
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ campaign, suppliers, map }) => ({
  campaign,
  suppliers,
  map
});

export default connect(mapStateToProps, { filterUnits, loadSupplierOptions })(SuppliersFilter);
