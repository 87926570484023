import * as React from 'react';
import { get } from '../../../../utils/api'
import cs from 'classnames'
import GlobalActions from "../../../../actions/GlobalActions"

class GoogleAdwordsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      step1: 'Click on the button below to give us authorization to access you Adwords account.',
      step2: 'Click on the button below to add Adquick as a manager on you Google Adwords account.'
    }
    this.authorizeAnalytics = this.authorizeAnalytics.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  authorizeAnalytics() {
    const { token, onUpdate, analytic } = this.props
    if(analytic.authorized) { return }
    const url = `/auth/adwords_access?return_to=/campaigns/${token}/analytics/configure&campaign_token=${token}`
    onUpdate(analytic, { authorized: true })
    return window.location.href = url
  }

  onClose() {
    GlobalActions.closePopup();
  }

  goToDocs() {
    window.open('/docs/google_adwords', '_blank');
  }

  render() {
    const { analytic } = this.props
    return (
      <div className="modal_big">
        <div className="modal_close">
          <div className="close_button" onClick={this.onClose}></div>
        </div>
        <div className="modal_header">
          <div className="google_adwords"></div>
          <h5>Google Adwords</h5>
        </div>
        <div className="modal_body" style={{padding: '1em 1.5em'}}>
          <div className="modal_subtitle">
            <h4>2 Step Setup</h4>
          </div>
          <div className="steps">
            <div className={cs('step', {'disabled': analytic.authorized })}>
              <div className="title">
                {analytic && analytic.authorized &&
                  <div className="step_checked"><i className="fa fa-check"></i></div>
                }
                <div className="step_badge"><span className="step_count">1</span></div>
                <h5>Step 1 Authorization</h5>
              </div>
              <div className="description">
              </div>
              <button className="primary-button" onClick={this.authorizeAnalytics}>Authorize</button>
            </div>
            <div className={cs('step', {'disabled': !analytic.authorized })}>
              <div className="title">
                <div className="step_badge"><span className="step_count">2</span></div>
                <h5>Step 2 Add Manager Access</h5>
              </div>
              <div className="description">
                We need manager access in order to read your Google AdWords data and provide a lift report.
              </div>
              <button className="primary-button" onClick={this.goToDocs} >View Instructions</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default GoogleAdwordsModal;