import accounting from "accounting";
import mapboxgl from "mapbox-gl";

import UnitModel from "../../models/unitModel";

export default class UnitPopups {
  constructor(map, user, campaign_permissions, hide_prices, isCampaignView, campaign) {
    this.map = map;
    this.user = user;
    this.popups = [];
    this.campaign_permissions = campaign_permissions || {};
    this.hide_prices = hide_prices;
    this.isCampaignView = isCampaignView;
    this.campaign = campaign;
  }

  async show(unit, coordinates, sourceName = null, offset = 10) {
    const popup = new mapboxgl.Popup({ closeButton: false, offset: offset });

    if (!coordinates) {
      if (!sourceName) return;
      coordinates = new PopupCoordinates(unit, this.map, sourceName).get();
      if (!coordinates) return;
    }
    popup.setLngLat(coordinates);

    const description = await this.unitDescription(unit);
    if (!description) return;
    popup.setHTML(description);

    popup.addTo(this.map);
    this.popups.push(popup);
    return popup;
  }

  hide() {
    while (this.popups.length) {
      const popup = this.popups.pop();
      popup.remove();
    }
  }

  renderFaceId(unit) {
    const { _id, faceId, supplier_id } = unit.properties;
    const is_ibo_unit = supplier_id === 1835;

    if (this.campaign && (this.campaign.is_mock || this.campaign.hide_face_ids)) {
      return _id;
    }
    if (is_ibo_unit && faceId) {
      return faceId.split("-")[0];
    }
    return faceId;
  }

  unitDescription(unit) {
    const { supplier_name, faceId } = unit.properties;
    const { can_view_supplier_name } = this.campaign_permissions;
    const unitModel = new UnitModel(unit);
    const price =
      this.campaign.show_price_for_duration
      ? unitModel.getPriceForDuration(unit.properties)
      : this.user.permissions.agency_browse_type == "range"
      ? `${unitModel.getPrice(unit.properties, { lower: "lower_price", higher: "higher_price" })}/4w`
      : `${unitModel.getMockPrice(this.campaign)}/4w`;
    const cpm = unitModel.getMockCpm(this.campaign);
    const { properties } = unit;
    const { is_recommended, is_favorited, is_managed, is_fully_submitted, is_broker } = properties;

    let direction;

    if (supplier_name == "Intersection") {
      if (faceId.endsWith("L")) {
        direction = "Left";
      } else if (faceId.endsWith("R")) {
        direction = "Right";
      } else if (faceId.endsWith("H")) {
        direction = "Street - Horizontal";
      } else if (faceId.endsWith("V")) {
        direction = "Street - Vertical";
      }
    }

    const recFavAreaOffsetStyle = properties.picture ? "position:absolute; margin-top: 6px; margin-left: 10px;" : "";
    const supplierNamelength = supplier_name
      ? supplier_name.split(" ").sort((a, b) => {
          return b.length - a.length;
        })[0].length
      : 0;
    const faceIdLength = faceId ? faceId.length : 0;
    const faceIdVendorStyle = faceIdLength + supplierNamelength > 17 ? "" : "display:flex;";
    return `
      <div class="new_map_unit_popup">
        ${is_recommended || is_favorited ? `<div class="rec-fav-area" style="${recFavAreaOffsetStyle}">` : ""}
          ${is_recommended ? '<div class="recommended">Recommended</div>' : ""}
          ${is_favorited ? '<i class="fa fa-heart favorite" aria-hidden="true"></i>' : ""}
        ${is_recommended || is_favorited ? "</div>" : ""}
        ${
          properties.picture
            ? `<img class="picture" src="${properties.picture}"></img>`
            : ""
        }
        <div>
          ${`<div class="face-id-vendor" style="${faceIdVendorStyle}">`}
            ${properties.faceId ? `<span class="face_id">${this.renderFaceId(unit)}</span>` : ""}
            ${
              (!this.isCampaignView || can_view_supplier_name) && supplier_name
                ? `<div class="vendor">
              <span class="vendor-name">${supplier_name}</span>
              ${is_managed ? `<img src="${window.ASSETS.managed_vendor}">` : ""}
              ${!is_managed && is_fully_submitted ? `<img src="${window.ASSETS.fully_submitted_vendor}">` : ""}
              ${is_broker ? `<img src="${window.ASSETS.broken_vendor}">` : ""}
            </div>`
                : ""
            }
          ${"</div>"}
          ${properties.is_package ? '<span className="title">Package</span>' : ""}
          ${
            properties.rate_card && !properties.price && !this.hide_prices
              ? `<div style="display:flex;">
            <div class="title">Rate Card</div>
            <div class="data">${accounting.formatNumber(properties.rate_card)}</div>
          </div>`
              : ""
          }
          ${
            price && !this.hide_prices
              ? `<div style="display:flex;">
            <div class="price">${price}</div>
          </div>`
              : ""
          }
          ${
            properties.size
              ? `<div style="display:flex;">
            <div class="title">Size</div>
            <div class="data">${properties.size}</div>
          </div>`
              : ""
          }
          ${
            properties.cpm
              ? `<div style="display:flex;">
            <div class="title">CPM</div>
            <div class="data">${cpm}</div>
          </div>`
              : ""
          }
          ${
            direction
              ? `<div style="display:flex;">
            <div class="title">Direction</div>
            <div class="data">${direction}</div>
          </div>`
              : ""
          }
        </div>
      </div>
    `;
  }
}

class PopupCoordinates {
  constructor(unit, map, sourceName) {
    this.map = map;
    this.unit = unit;
    this.sourceName = sourceName;
  }

  get() {
    if (this.isUnitVisible()) {
      return this.unit.geometry.coordinates;
    }
    const closerCluster = this.closerCluster(this.visibleClusters(this.clusters()));
    return closerCluster && closerCluster.geometry.coordinates;
  }

  isUnitVisible() {
    return !!this.units().find(u => this.unit.properties.id === u.properties.id);
  }

  units() {
    return this.map.getLayer("unit-markers") ? this.map.queryRenderedFeatures({ layers: ["unit-markers"] }) : [];
  }

  clusters() {
    return this.map.getLayer("cluster-markers") ? this.map.queryRenderedFeatures({ layers: ["cluster-markers"] }) : [];
  }

  visibleClusters(clusters) {
    return (clusters || []).filter(c => {
      if (!(c && c.geometry && c.geometry.coordinates)) return false;

      const [lng, lat] = c.geometry.coordinates;
      return (
        lat > this.bounds()._sw.lat &&
        lat < this.bounds()._ne.lat &&
        lng > this.bounds()._sw.lng &&
        lng < this.bounds()._ne.lng
      );
    });
  }

  closerCluster(clusters) {
    const unitCoordinates = this.unit.geometry.coordinates;

    if (!clusters || !clusters.length) return;

    return clusters
      .map(cluster => {
        const [lng, lat] = cluster.geometry.coordinates;
        const distance = Math.sqrt(Math.pow(lng - unitCoordinates[0], 2) + Math.pow(lat - unitCoordinates[1], 2));
        return { cluster, distance };
      })
      .sort((a, b) => a.distance - b.distance)[0].cluster;
  }

  bounds() {
    return this.map.getBounds();
  }
}
