import React from 'react';
import DateRangePicker from "../../DatePicker/Range";
import DateSinglePicker from "../../DatePicker/Single";
import ReactSelect from "react-select";
import moment from 'moment';

class CampaignUnitForm extends React.Component {
  constructor(props) {
    super(props)

    this.confirmDeleteFlight = this.confirmDeleteFlight.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {};
  }

  componentDidMount() {
    this.buildUnitState()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.loading != this.props.loading) { this.buildUnitState() }
  }

  buildUnitState() {
    const { campaign_unit } = this.props;
    const start_date = campaign_unit.start_date ? moment.utc(campaign_unit.start_date) : null;
    const end_date = campaign_unit.end_date ? moment.utc(campaign_unit.end_date) : null;
    const design_asset_due_date = campaign_unit.design_asset_due_date ? moment.utc(campaign_unit.design_asset_due_date) : null;

    this.setState({
      start_date,
      end_date,
      token: campaign_unit.campaign_unit_token,
      price: campaign_unit.price,
      production_cost: campaign_unit.production_cost,
      installation_cost: campaign_unit.installation_cost,
      quantity: campaign_unit.quantity,
      cpm: campaign_unit.cpm,
      forced_production: campaign_unit.forced_production,
      design_asset_due_date,
      artwork_pre_approval: campaign_unit.artwork_pre_approval,
      billing_cycle: campaign_unit.billing_cycle,
      cycle_cost: campaign_unit.cycle_cost
    })
  }

  campaignUnit() {
    return this.state
  }

  isPriceDisabled() {
    const { billing_cycle } = this.state
    return billing_cycle == 'monthly'
  }

  render() {
    const { index, onDeleteCampaignUnit, isTalon } = this.props;
    const {
      start_date,
      end_date,
      quantity,
      price,
      cpm,
      production_cost,
      installation_cost,
      design_asset_due_date,
      forced_production,
      artwork_pre_approval,
      billing_cycle,
      cycle_cost
    } = this.state;

    const booleanOptions = this.booleanOptions();

    return (
      <div>
        <div className="form-group">
          <span style={{
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '15px',
            letterSpacing: '0.15em',
            textTransform: 'uppercase',
            color: '#858585'
          }}>
            Flight {index}
          </span>
          {onDeleteCampaignUnit &&
            <a onClick={this.confirmDeleteFlight}>
              <i
                className="fa fa-trash-o"
                aria-hidden="true"
                style={{float: 'right', color: '#858585'}}>
              </i>
            </a>
          }
        </div>
        {isTalon &&
          <div className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label>Cycle Type</label>
                <ReactSelect
                  value={this.billingCycles().find(o => o.value === billing_cycle )}
                  onChange={(option) => this.handleSelectChange(option, 'billing_cycle')}
                  options={this.billingCycles()}
                  isClearable
                />
              </div>
              <div className="col-md-6">
                <label>Cycle Cost</label>
                <input
                  type="text"
                  className="form-control"
                  name="cycle_cost"
                  defaultValue={cycle_cost}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>
        }
        <div className="form-group">
          <div className="row">
            <div className="col-md-4 SmallDatePicker">
              <label>Flight (Dates)</label>
              <DateRangePicker
                start_date={start_date}
                end_date={end_date}
                onDatesChange={
                  ({start_date, end_date}) => this.setState({start_date, end_date})
                }
              />
            </div>
            <div className="col-md-4">
              <label>4 Week Price</label>
              <input
                type="text"
                className="form-control"
                name="price"
                defaultValue={price}
                disabled={this.isPriceDisabled()}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>CPM</label>
              <input
                type="text"
                className="form-control"
                name="cpm"
                defaultValue={cpm}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-4">
              <label>Production Cost</label>
              <input
                type="text"
                className="form-control"
                name="production_cost"
                defaultValue={production_cost}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>Installation Cost</label>
              <input
                type="text"
                className="form-control"
                name="installation_cost"
                defaultValue={installation_cost}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>Number of Digital Spots</label>
              <input
                type="text"
                className="form-control"
                name="quantity"
                defaultValue={quantity}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-4 SmallDatePicker">
              <label>Artwork Deadline</label>
              <DateSinglePicker
                date={design_asset_due_date}
                placeholder="Artwork Deadline"
                onDateChange={({ date }) => this.setState({ design_asset_due_date: date }) }
                showClearDate
                small
                block
              />
            </div>
            <div className="col-md-4">
              <label>Forced Production</label>
              <ReactSelect
                value={booleanOptions.find(o => o.value === forced_production )}
                onChange={(option) => this.handleSelectChange(option, 'forced_production')}
                options={booleanOptions}
                isClearable
              />
            </div>
            <div className="col-md-4">
              <label>Artwork Pre Approval</label>
              <ReactSelect
                value={booleanOptions.find(o => o.value === artwork_pre_approval )}
                onChange={(option) => this.handleSelectChange(option, 'artwork_pre_approval')}
                options={booleanOptions}
                isClearable
              />
            </div>
          </div>
        </div>
        <hr/>
      </div>
    );
  }

  handleInputChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSelectChange(option, field) {
    if (!option) { return this.setState({ [field]: null }); }
    this.setState({ [field]: option.value });
  }

  confirmDeleteFlight() {
    if (confirm('Are you sure you want to delete this flight?')) {
      this.props.onDeleteCampaignUnit(this.state.token);
    }
  }

  booleanOptions() {
    return [
      {value: true, label: 'Yes'},
      {value: false, label: 'No'}
    ]
  }

  billingCycles() {
    return [
      {value: '4_week', label: '4 Week'},
      {value: 'monthly', label: 'Monthly'}
    ]
  }
}

export default CampaignUnitForm;
