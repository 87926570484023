import FaCheck from "@adquick/icons/fa/FaCheck";
import React from "react";
import { connect } from "react-redux";

import NewMapActions from "../../actions/NewMapActions";
import NewMapStore from "../../stores/NewMapStore";
import PointsOfInterestModal from "../PointsOfInterestModal";
import { toggleUnitReach } from "./actions";

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeedbackBar: false,
      showPlacesBox: false,
      hasTransitRoutes: false,
      showTransitRoutes: true,
      regionsHidden: true,
      showDesignAssets: false,
      showPrice: true,
      showFaceId: false,
      showDropDown: false,
    };
  }

  componentDidMount() {
    this.unsubscribeList = [NewMapStore.listen(this.onNewMapStoreChange.bind(this))];
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  showTogglePrice() {
    // showing this only for vote.org people
    return this.props.campaign && this.props.user.email.endsWith("vote.org");
  }

  toggleDesignAssets() {
    NewMapActions.toggleDesignAssets();
    this.setState({ showDesignAssets: !this.state.showDesignAssets });
  }

  togglePOIs() {
    this.setState({ showPlacesBox: !!!this.state.showPlacesBox });
  }

  toggleTransitLayer() {
    NewMapActions.toggleTransitLayer();
    this.setState({ showTransitRoutes: !this.state.showTransitRoutes });
  }

  togglePrice() {
    NewMapActions.togglePrice();
    this.setState({ showPrice: !this.state.showPrice });
  }

  toggleFaceId() {
    NewMapActions.toggleFaceId();
    this.setState({ showFaceId: !this.state.showFaceId });
  }

  toggleDropDownClick() {
    this.setState({ showDropDown: !this.state.showDropDown });
  }

  toggleShowPlaceBox() {
    this.setState({ showPlacesBox: !this.state.showPlacesBox });
  }

  onNewMapStoreChange(event) {
    if (event === "map:loadUnitGeojson") this.setState({ hasTransitRoutes: true });
  }

  renderControlItem(control_item, idx) {
    if (!control_item.show_flag) {
      return;
    }
    return (
      <button
        key={idx}
        style={{ "--icon": `"\\${control_item.unicode_icon}"` }}
        className={`map-control ${control_item.on_flag ? "active" : ""}`}
        onClick={control_item.on_click}
      >
        {control_item.label}
      </button>
    );
  }

  render() {
    const hasRegions = false;
    const { user, campaign, onAddNearbyUnits, isSearchView } = this.props;
    const supplier_id = campaign ? campaign.supplier_id : null;
    const guestUserOnSupplierCampaign = user.is_guest && supplier_id;
    const control_items = [
      {
        label: "Show Expanded Map",
        on_click: this.props.toggleMapSize,
        show_flag: isSearchView,
        on_flag: this.props.map.full_screen,
        unicode_icon: "f31d",
      },
      {
        label: "Show Cluster",
        on_click: this.props.toggleMapCluster,
        show_flag: true,
        on_flag: this.props.map.cluster,
        unicode_icon: "f77e",
      },
      {
        label: "Show Traffic",
        on_click: this.props.toggleMapTraffic,
        show_flag: !!!user.is_supplier && !!!guestUserOnSupplierCampaign,
        on_flag: this.props.map.traffic,
        unicode_icon: "f1b9",
      },
      {
        label: "Show POIs",
        on_click: this.props.toggleMapPlacemarkers,
        show_flag:
          campaign && user && (user.is_managed_supplier || !!!user.is_supplier) && !!!guestUserOnSupplierCampaign,
        on_flag: this.props.map.show_placemarkers,
        unicode_icon: "f3c5",
      },
      {
        label: "Show Reach",
        on_click: this.props.toggleUnitReach,
        show_flag: campaign,
        on_flag: this.props.show_units_reach,
        unicode_icon: "f2f8",
      },
      {
        label: "Show Transit",
        on_click: this.toggleTransitLayer.bind(this),
        show_flag: this.state.hasTransitRoutes && !!!user.is_supplier && !!!guestUserOnSupplierCampaign,
        on_flag: this.state.showTransitRoutes,
        unicode_icon: "f85a",
      },
      {
        label: "Show Price",
        on_click: this.togglePrice.bind(this),
        show_flag: this.showTogglePrice(),
        on_flag: this.state.showPrice,
        unicode_icon: "f2e8",
      },
      {
        label: "Show FaceID",
        on_click: this.toggleFaceId.bind(this),
        show_flag: campaign && !campaign.hide_face_ids && (user.is_admin || user.is_agency),
        on_flag: this.state.showFaceId,
        unicode_icon: "f029",
      },
      {
        label: "Show Regions",
        on_click: this.toggleRegionLayer,
        show_flag: hasRegions,
        on_flag: this.state.regionsHidden,
        unicode_icon: "f024",
      },
      {
        label: "Add Units Near POIs",
        on_click: this.props.onAddNearbyUnits,
        show_flag: user && (user.is_admin || user.is_supplier) && onAddNearbyUnits,
        on_flag: false,
        unicode_icon: "f78a",
      },
      {
        label: "Show Design Assets",
        on_click: this.toggleDesignAssets.bind(this),
        show_flag: false && !isSearchView,
        on_flag: this.state.showDesignAssets,
        unicode_icon: "f8c4",
      },
    ];

    return (
      <div className="map-controls hidden-xs">
        {(user.is_managed_supplier || !!!user.is_supplier) && (
          <div className="placemarker_modal_container">
            <PointsOfInterestModal show={this.state.showPlacesBox} onHide={this.togglePOIs.bind(this)} />
          </div>
        )}
        <div className="map-control-show" onClick={this.toggleDropDownClick.bind(this)}>
          Show
        </div>
        <div className={`map-control-dropdown ${this.state.showDropDown ? "show" : ""}`}>
          {control_items.map((item, idx) => this.renderControlItem(item, idx))}
        </div>
      </div>
    );
  }
}

export default connect(({ map }) => ({ ...map }), { toggleUnitReach })(Controls);
