import "./GuestUserMenu.css";
import * as React from "react";
import { NavBarConfig } from "./index";

const GuestUserMenu = ({ config }: { config: NavBarConfig }) => {
  const signInUrl = config?.loginUrl ? config?.loginUrl : `/sign-in?redirect_uri=/programmatic`;
  return (
    <div className="GuestUserMenu">
      <ul>
        <li>
          <a id="header_buy" href="/">
            Buy
          </a>
        </li>
        <li>
          <a id="header_sell" href="/media-owners">
            Sell
          </a>
        </li>
        <li>
          <a id="header_analytics" href="/analytics">
            Analytics
          </a>
        </li>
        <li>
          <a id="header_sign_in" href={signInUrl}>
            Log in
          </a>
        </li>
        {!config?.disableGetStarted && (
          <li>
            <a id="header_sign_up" href="/sign-up">
              Get Started
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
GuestUserMenu.displayName = "NavBar/GuestUserMenu";
export default GuestUserMenu;
