import React, { PureComponent } from 'react'
import { get, post } from '../../utils/api'
import GlobalActions from '../../actions/GlobalActions'
import _ from "lodash"

export default class RequestPriceModal extends PureComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      authorized: false,
      authorization: {},
      user: props.user
    }

    this.checkAuthorization = this.checkAuthorization.bind(this)
    this.submitAuthorization = this.submitAuthorization.bind(this)
  }

  public async componentDidMount() {
    const { token } = this.props.campaign
    const { authorization } = await get(`/api/v1/campaigns/${token}/request_authorization`)
    this.setState({ authorized: !!authorization, authorization })
  }
  
  public checkAuthorization() {
    const { authorized } = this.state
    this.setState({ authorized: !authorized })
  }

  public async submitAuthorization() {
    const { token } = this.props.campaign
    const { authorization } = await post(`/api/v1/campaigns/${token}/request_authorization`)
    this.setState({ authorization })
  }

  public renderAuthorizedState() {
    return (
      <div className="modal-request-price">
        <div className="modal-title">
          <h4>Request Pricing & Availability</h4>
        </div>

        <div className="note authorized">
          <i className="fa fa-check"></i>
          <div>
            <p>Thanks! We’ve received your request and will be in touch shortly with current pricing & availability.</p>
            <p>In the meantime, our team will reach out if we have any questions about the final campaign brief.</p>
          </div>
        </div>
      </div>
    )
  }

  public renderGuestUserView() {
    return (
      <div className="modal-request-price" onClick={e => e.stopPropagation()}>
        <div className="modal-title">
          <h4>Request Pricing & Availability</h4>
        </div>
        <div>
          <div className="note">
            <i className="fa fa-info-circle info"></i>
            <span>In order to share pricing & availability information sign up for AdQuick !</span>
          </div>
        </div>
        <div className="modal-footer">
          <a className="sign-up-modal-link" href="https://www.adquick.com/signup?redirect_uri=%2Fpicker">
            <button className="signup-modal-button "> 
              Sign Up
            </button>
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { authorized, authorization } = this.state

    if(!_.isEmpty(authorization)) {
      return this.renderAuthorizedState()
    }

    if(this.state.user.is_guest) {
      return this.renderGuestUserView()
    }

    return (
      <div className="modal-request-price" onClick={e => e.stopPropagation()}>
        <div className="modal-title">
          <h4>Request Pricing & Availability</h4>
        </div>
        <div>
          <div className="note">
            <i className="fa fa-info-circle info"></i>
            <span>In order to share pricing & availability information, our media partners require that we get express permission to do so.</span>
          </div>
        </div>
        <div className="modal-footer">
          <label>
            <input type="checkbox" checked={authorized} onChange={this.checkAuthorization}/>
            I authorize AdQuick to request pricing & availability from media owners on my behalf.
          </label>
          <button disabled={!authorized} onClick={this.submitAuthorization} className="btn btn-primary">Submit Request</button>
        </div>
      </div>
    )
  }


}
