import { CLEAR_CAMPAIGN } from '../components/Campaign/actions';

const initialState = [];

export default (state = initialState, action) => {
  switch(action.type) {
      case CLEAR_CAMPAIGN: return initialState;
      default: return state;
  }
}
