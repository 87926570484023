import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { addFlights, resetFlightForm } from "./actions";
import { hideModal } from "../actions";
import ReactSelect from "react-select";
import Loading from "../../Loading";
import { ControlLabel, FormControl, FormGroup, Col, Form } from "react-bootstrap";
import moment from "moment";
import DateRangePicker from "../../DatePicker/Range";

class AddFlightsToCampaign extends React.Component {

  constructor(props) {
    super(props);
    const campaign_unit = {
      start_date: moment().add(4, "weeks"),
      end_date: moment()
        .add(8, "weeks")
        .subtract(1, "day"),
      workflow_state: "proposed",
      account_executive: "",
      quantity: 1
    };

    this.state = {
      errors: {},
      campaign_id: null,
      campaign_unit,
      saving: false
    };
    this.renderErrors = this.renderErrors.bind(this);
    this.getFlightData = this.getFlightData.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onAddFlights = this.onAddFlights.bind(this);
    this.onSelectCampaign = this.onSelectCampaign.bind(this);
  }

  renderErrors() {
    const { errors } = this.state;
    const formattedErrors = [];

    for (const key in errors) {
      formattedErrors.push(errors[key]);
    }

    if (formattedErrors.length) {
      return formattedErrors.map(err => (
        <p className="message__row" key={err}>
          {err}
        </p>
      ));
    }
  }

  getFlightData() {
    const { campaign_unit, campaign_id } = this.state;
    const selected_units = this.props.selected_units ? this.props.selected_units.filter(x => x) : [];

    return {
      campaign: { id: campaign_id },
      unit_ids: selected_units && selected_units.length ? selected_units.map(u => u.id) : [],
      campaign_unit: {
        ...campaign_unit,
        start_date: campaign_unit.start_date.toDate(),
        end_date: campaign_unit.end_date.toDate()
      }
    };
  }

  onDatesChange(start_date, end_date) {
    const { campaign_unit } = this.state
    this.setState({ campaign_unit: { ...campaign_unit, start_date, end_date } })
  }

  onHide(e) {
    e && e.preventDefault()
    e && e.stopPropagation()
    const { hideModal, resetFlightForm } = this.props;
    hideModal();
    resetFlightForm();
  }

  onAddFlights(e) {
    e.preventDefault();
    const { campaign_id } = this.state;
    const { addFlights, onUnitDeselectAll } = this.props;

    if (!!!campaign_id) {
      const errors = { name: "Please select campaign" };
      this.setState({ errors });
    } else {
      const flight_data = this.getFlightData();
      addFlights(flight_data);
      onUnitDeselectAll();
      this.onHide();
    }
  }

  onSelectCampaign(campaign) {
    const { campaigns } = this.props;
    const selected_campaign = campaigns.filter((c) => {return c.id === campaign.value}).pop();
    const { start_date, end_date } = selected_campaign;

    if (start_date && end_date) {
      const { campaign_unit } = this.state
      this.setState({
        campaign_unit: {
          ...campaign_unit,
          start_date: moment(start_date),
          end_date: moment(end_date)
        },
        campaign_id: campaign && campaign.value
      });
    } else {
      this.setState({ campaign_id: campaign && campaign.value });
    }
  }

  render() {
    const { campaigns } = this.props;
    const selected_units = this.props.selected_units ? this.props.selected_units.filter(x => x) : [];
    const selectedLength = selected_units.length;
    const { campaign_unit, campaign_id, saving } = this.state;
    const start_date =
      campaign_unit.start_date && moment(campaign_unit.start_date);
    const end_date = campaign_unit.end_date && moment(campaign_unit.end_date);
    const campaign_options = campaigns.map(c => ({ label: c.name, value: c.id }));

    return (
      <Modal show={this.props.show} onHide={this.props.onAddHoldsHide}>
        <Modal.Header>
          <Modal.Title>
            {selectedLength === 0
              ? "Select units on inventory page to add these units to a campaign"
              : `Add ${selectedLength} units to campaign`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add_to_campaign">
            <Form horizontal>
              {saving ? (
                <Loading/>
              ) : (
                <div>
                  {this.renderErrors() && (
                    <div className="message message_error">
                      {this.renderErrors()}
                    </div>
                  )}
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Campaign
                    </Col>
                    <Col sm={8}>
                      <ReactSelect
                        placeholder="Select a campaign"
                        value={campaign_options.find(o => o.value === campaign_id)}
                        onChange={this.onSelectCampaign}
                        options={campaign_options}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Flight Dates
                    </Col>
                    <Col sm={8}>
                      <DateRangePicker
                        start_date={start_date}
                        end_date={end_date}
                        onDatesChange={({ start_date, end_date }) =>
                          this.onDatesChange(start_date, end_date)
                        }
                      />
                    </Col>
                  </FormGroup>
                  <button
                    className="btn btn-primary"
                    style={{ margin: "10px" }}
                    type="submit"
                    disabled={this.state.saving || !!!campaign_id}
                    onClick={e => this.onAddFlights(e)}
                  >
                    Save
                  </button>
                  <input
                    type="submit"
                    style={{ margin: "10px" }}
                    value="Close"
                    className="btn btn-default"
                    onClick={e => this.onHide(e)}
                  />
                </div>
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
};

export default connect(
  ({ inventory: { campaigns, selected_units } }) => {
    return { campaigns, selected_units };
  },
  { addFlights, hideModal, resetFlightForm }
)(AddFlightsToCampaign);
