import React, { Component } from "react";
import { browserHistory } from "react-router-legacy";

export default class ReviewCampaignButton extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  onFinalStep() {
    return window.location.pathname.includes("/unit_info");
  }

  onButtonClick() {
    if (this.onFinalStep()) {
      const { campaign } = this.props;
      window.location.href = `/campaigns/${campaign.token}`;
    } else {
      const { goToStep } = this.props;
      goToStep(3);
    }
  }

  render() {
    const { campaign, disabled } = this.props;
    const buttonText = this.onFinalStep() ? "Finish" : "Next";

    if (campaign && campaign.token) {
      return (
        <button
          className="btn btn-success float-right review-campaign"
          onClick={this.onButtonClick.bind(this)}
          style={{ width: 162, maxHeight: 42 }}
          disabled={disabled}
        >
          {buttonText}
        </button>
      );
    } else {
      return null;
    }
  }
}
