import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import { unitTypeIconMapping } from '../Map/functions/iconFuncs';
import { colorMap } from '../../stores/NewMapStore';

const MAX_ZOOM = 16.8;
const MIN_ZOOM = 0;
const INITIAL_ZOOM = 14;
const ACCESS_TOKEN = AppConfig.mapboxAccessToken;
const CONTAINER = "unit-map";
const MAP_STYLE = AppConfig.mapboxDefaultStyle;

class Map extends Component {

  constructor(props) {
    super(props);
    mapboxgl.accessToken = ACCESS_TOKEN;
  }

  renderMap() {
    const { unit } = this.props;
    const center = [unit.lon, unit.lat];
    const map = new mapboxgl.Map({
      container: CONTAINER,
      style:   MAP_STYLE,
      zoom:    INITIAL_ZOOM,
      maxZoom: MAX_ZOOM,
      minZoom: MIN_ZOOM,
      center:  center,
    });
    const marker = new mapboxgl.Marker(this.markerElement()).setLngLat(center).addTo(map)
  }

  markerElement() {
    const { unit } = this.props;
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = `url(${unitTypeIconMapping[unit.unit_type]})`;
    el.style.backgroundSize = '70%';
    el.style.borderRadius = '15px';
    el.style.backgroundColor = colorMap[unit.unit_type];
    el.style.borderColor = '#ffffff';
    el.style.borderWidth = '2px';
    el.style.borderStyle = 'solid';
    el.style.width = '30px';
    el.style.height = '30px';
    el.style.backgroundPosition = 'center';
    el.style.backgroundRepeat = 'no-repeat';
    return el;
  }

  componentDidMount() {
    this.renderMap();
  }

  render(){
    return (
      <div id="unit-map" style={{ width: '100%', height: '400px', marginTop: '10px' }}>
      </div>
    )
  }

}

export default Map
