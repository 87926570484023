import { format } from 'timeago.js';
import { connect } from 'react-redux';
import React from 'react';
import moment from 'moment';

import { initialUnitRequest } from '../Campaign/actions';
import ActivityStore from '../../stores/ActivityStore';
import AuthStore from '../../stores/AuthStore';
import GlobalActions from '../../actions/GlobalActions';
import {
  addUnitToCart,
  removeFromCart,
} from '../../actions/cart';


class UnitAvailability extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      unit: this.props.unit,
      campaign: this.props.campaign,
      changingCart: false,
      waitingForDateRange: false,
      startDate: this.props.unit.start_date,
      endDate: this.props.unit.end_date
    };
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if(oldProps.unit !== newProps.unit) {
      this.setState(Object.assign(this.state, {unit: newProps.unit}));
    }
  }

  sendUnitRequest(type) {
    const { unit, startDate, endDate } = this.state;
    const campaign = this.state.campaign;
    const campaignId = campaign.token;
    const params = {
      campaign_id: campaignId,
      unit_ids: [unit.campaign_unit_token],
      type: type,
      debounced: true,
      start_date: startDate,
      end_date: endDate
    }

    $.post(`/api/v1/campaigns/${campaignId}/unit_requests`, params, (response) => {
      this.props.initialUnitRequest(unit.id, startDate, endDate);
      GlobalActions.showMessage(
        "Please make sure to request availability on 2 to 3 backup units for each unit you are interested in. This way if your units aren't available you don't need to send a second request.",
        'info',
        true
      )
    }).fail((data) => {
      if (data.responseJSON && data.responseJSON.error) {
        GlobalActions.showError(
          "Sorry, there was a problem. Our engineering team has been notified and we'll email you when the issue is resolved."
        );
      } else {
        GlobalActions.showError('There was a problem requesting this unit.');
      }
      throw data;
    })
  }

  requestAvailability() {
    const type = this.refreshRequired() ? 'refresh' : 'initial';
    this.sendUnitRequest(type)
  }

  onRequestAvailability(e) {
    e.stopPropagation();
    const { permissions } = this.props
    const currentUser = AuthStore.getState().user
    const notLoggedIn = (!currentUser || currentUser.is_guest)
    if(notLoggedIn && permissions.can_be_browsed) {
      GlobalActions.openPopup('register', { allow_signup: true, returnTo: window.location.pathname });
      return;
    }
    this.displayCalendarPopup(this.onDateRangeConfirm.bind(this), 'Request Availability for Dates', 'Request')
  }

  displayCalendarPopup(onDateSelect, title, confirmButtonLabel) {
    this.setState({ waitingForDateRange: true })
    const { unit } = this.props;
    const { campaign } = this.state;

    const start_date = unit.start_date || (campaign.start_date && moment(campaign.start_date)) || moment();
    const end_date = unit.end_date || (campaign.end_date && moment(campaign.end_date)) || moment();

    GlobalActions.openPopup('calendar', {
      title: title,
      confirmButtonLabel: confirmButtonLabel,
      onDateSelect: onDateSelect,
      onHide: this.onCalendarHide.bind(this),
      startDate: start_date.format('LL'),
      endDate: end_date.format('LL'),
      isIntersectionStaticKiosk: this.isIntersectionStaticKiosk()
    })
  }

  isIntersectionStaticKiosk() {
    const { unit } = this.state;
    const { supplierName, screen_type, unit_type } = unit;
    if (supplierName == "Intersection" &&
        screen_type == "digital" &&
        unit_type == "street_furniture") return true;
    return false;
  }

  onCalendarHide(){
    this.setState({ waitingForDateRange: false })
    GlobalActions.closePopup();
  }

  onDateRangeConfirm(startDate, endDate) {
    this.setState({ waitingForDateRange: false })
    let newUnit = Object.assign(this.state.unit, {
      supplier_status: this.refreshRequired() ? 'refresh_requested' : 'initial_requested',
      supplier_requested_at: moment(new Date().getTime()).format('l')
    })
    this.setState({
      unit: newUnit,
      startDate: startDate.format('LL'),
      endDate: endDate.format('LL')
    }, this.requestAvailability.bind(this));
  }

  onAddToCartDateRangeConfirm(startDate, endDate) {
    const { campaign, unit, addUnitToCart } = this.props;

    this.logActivity('CAMPAIGN_ADD_UNIT_TO_CART');
    addUnitToCart(campaign.token, unit.campaign_unit_token, startDate, endDate).then(res =>
      this.setState({ changingCart: false, waitingForDateRange: false }),
    );
  }

  formatDate(date) {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }

    return moment.utc(date.format('YYYY-MM-DD'));
  }

  logActivity(event, params = {}) {
    ActivityStore.log(event, { campaign: this.state.campaign.campaign_id }, params);
  }

  onAddToCart(e) {
    e.stopPropagation();
    const { permissions } = this.props
    const currentUser = AuthStore.getState().user
    const notLoggedIn = (!currentUser || currentUser.is_guest)
    if (notLoggedIn) {
      GlobalActions.openPopup('register', { allow_signup: true, returnTo: window.location.pathname });
      return;
    }
    this.displayCalendarPopup(this.onAddToCartDateRangeConfirm.bind(this), 'Confirm your dates before adding to cart', 'Add to Cart')
  }

  onRemoveFromCart(e) {
    e.stopPropagation();
    const { campaign, unit, removeFromCart } = this.props;
    this.setState({changingCart: true})
    this.logActivity('CAMPAIGN_REMOVE_UNIT_FROM_CART');
    removeFromCart(campaign.token, [unit.campaign_unit_token])
    .then(res => this.setState({changingCart: false}));
  }

  statusFromSupplierStatus() {
    const { available, supplier_status } = this.state.unit;
    // Note: if we request a refresh or a hold, we want (for now) to hide that from the user
    const unavailable = ((/_unavailable/).test(supplier_status));
    const availability_requested = ((/_requested/).test(supplier_status));
    return ({
      available: available,
      unavailable: unavailable,
      availability_requested: availability_requested
    })
  }

  daysSinceMarkedAsAvailable(){
    const marked_available_at = this.state.unit.available;
    const now = moment(new Date())
    const difference = moment.duration(now.diff(marked_available_at));
    return Math.floor(difference.asDays());
  }

  refreshRequired(){
    return this.daysSinceMarkedAsAvailable() > 7
  }

  render() {
    const { unit, changingCart, waitingForDateRange, startDate, endDate } = this.state;
    const { isInCart } = this.props;
    const { supplier_status, supplier_requested_at } = unit;
    const { start_date } = unit;
    const { available, availability_requested, unavailable } = this.statusFromSupplierStatus();
    const daysUntilRefreshIsRequired = 7 - this.daysSinceMarkedAsAvailable();
    const refreshRequired = this.refreshRequired();

    return (
      <div className="unit-availability">

        {unavailable &&
          <div>
            <div className="availability-unavailable">
              <span className="strong">
                Unit Unavailable
              </span>
              {available &&
                <p className="last-updated">
                  Last updated {format(available)}
                </p>
              }
            </div>
          </div>
        }

        {availability_requested &&
          <div className="availability-requested">
            {supplier_requested_at &&
              <div className="requested-ago">
                {moment().diff(supplier_requested_at, 'days')}d
              </div>
            }
            {supplier_status === 'initial_requested' &&
              <span className="strong">
                Availability Requested
              </span>
            }
            {supplier_status === 'refresh_requested' &&
              <span className="strong">
                Refresh Requested
              </span>
            }
            <p>
              ({moment(startDate).format('MMM Do')} to {moment(endDate).format('MMM Do')})<br/>
              will update within 72 hours
            </p>
          </div>
        }

        {!supplier_status &&
          <button className="btn btn-block request-availability-button" onClick={this.onRequestAvailability.bind(this)}>
            {!waitingForDateRange &&
              <span>
                <i className="fa fa-calendar" aria-hidden="true"></i>
                Request Availability
              </span>
            }
            {waitingForDateRange && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
          </button>
        }

        {available && !refreshRequired &&
          <div>
            {start_date &&
              <div className="availability-available">
                <div className="available-on">
                  <span className="strong">
                    Available {moment(start_date).format('L')}
                  </span>
                  <p className="last-updated">
                    {daysUntilRefreshIsRequired} days left until refresh required
                  </p>
                </div>
              </div>
            }
            <div className="approved-buttons-area">
              {!isInCart &&
                <button className="btn btn-primary btn-block add-to-cart-button" onClick={this.onAddToCart.bind(this)} disabled={changingCart}>
                  {!changingCart && <span>Add to Cart</span>}
                  {changingCart && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
                </button>
              }

              {isInCart &&
                <button className="btn btn-block remove-from-cart-button" onClick={this.onRemoveFromCart.bind(this)} disabled={changingCart}>
                  {!changingCart && <i className="fa fa-shopping-cart" aria-hidden="true"></i>}
                  {!changingCart && <span>Remove</span>}
                  {changingCart && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
                </button>
              }
            </div>
          </div>
        }

        {available && refreshRequired && !availability_requested &&
          <button className="btn btn-block refresh-availability-button" onClick={this.onRequestAvailability.bind(this)}>
            {!waitingForDateRange &&
              <span>
                <i className="fa fa-refresh" aria-hidden="true"></i>
                Refresh Availability
              </span>
            }
            {waitingForDateRange && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
          </button>
        }

      </div>
    )
  }
};

export default connect(null, {
  initialUnitRequest,
  addUnitToCart,
  removeFromCart,
})(UnitAvailability);
