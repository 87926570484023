import _ from "lodash";
import * as React from "react";
import { PureComponent } from "react";

const ASSETS = (window as any).ASSETS;
interface SupplierProps {
  is_fully_submitted: boolean;
  is_managed: boolean;
  is_broker: boolean;
  name: string;
}
interface SupplierNameProps {
  user: any;
  supplier: SupplierProps;
  className: string | undefined;
}
export default class SupplierName extends PureComponent<SupplierNameProps, any> {

  constructor(props) {
    super(props);
  }

  public render() {
    const { name, is_managed, is_fully_submitted } = this.props.supplier;
    const { className } = this.props;
    return (
      <span className={className} title={this.iconTitle()}>
        <span className="inline-block">{name}</span>
        { (is_managed || is_fully_submitted) && this.icon() }
        { this.brokerIcon() }
      </span>
    );
  }

  private iconTitle(): string {
    const { is_managed, is_fully_submitted } = this.props.supplier;

    if (is_managed) {
      return `managed ${this.broker()}`;
    }
    if (is_fully_submitted) {
      return `fully submitted ${this.broker()}`;
    }
    return `regular ${this.broker()}`;
  }

  private icon() {
    const { is_managed, is_fully_submitted } = this.props.supplier;
    if (is_managed) {
      return <img src={ASSETS.managed_vendor} style={{ width: "14px", height: "14px", marginLeft: 5 }}/>;
    } else if (is_fully_submitted) {
      return <img src={ASSETS.fully_submitted_vendor} style={{ width: "14px", height: "14px", marginLeft: 5 }}/>;
    }
    return "";
  }

  private broker() {
    const { user } = this.props
    const { is_broker } = this.props.supplier
    if (!is_broker || user.is_supplier) { return }
    return "broker"
  }

  private brokerIcon() {
    const { user } = this.props
    const { is_broker } = this.props.supplier
    if (!is_broker || user.is_supplier) { return }
    return <img src={ASSETS.broken_vendor} className="ml-[5px] w-[14px] h-[14px] inline-block"/>;
  }

}
