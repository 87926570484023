import param from '../../utils/objectToQuery';

const namespace = 'marketplace/campaigns/'

export const SET_FILTERS = namespace + 'SET_FILTERS'
export const SHOW_ARCHIVED = namespace + 'SHOW_ARCHIVED'
export const FETCH_ROWS = namespace + 'FETCH_ROWS'
export const SELECT_ROWS = namespace + 'SELECT_ROWS'
export const FETCH_ROWS_SUCCESS = namespace + 'FETCH_ROWS_SUCCESS'
export const ADD_ROW = namespace + 'ADD_ROW'
export const ADD_ROW_SUCCESS = namespace + 'ADD_ROW_SUCCESS'
export const UPDATE_ROW = namespace + 'UPDATE_ROW'
export const UPDATE_ROW_SUCCESS = namespace + 'UPDATE_ROW_SUCCESS'
export const UPDATE_ROWS = namespace + 'UPDATE_ROWS'
export const UPDATE_ROWS_SUCCESS = namespace + 'UPDATE_ROWS_SUCCESS'
export const DELETE_ROWS = namespace + 'DELETE_ROWS'

const rootUrl = '/api/v1/campaigns'

export const setFilters = filters => dispatch => {
  dispatch({
    type: SET_FILTERS,
    payload: { filters }
  })

  dispatch(fetchRows())
}

export const showArchived = () => dispatch => {
  dispatch({ type: SHOW_ARCHIVED })
  dispatch(fetchRows())
}

export const fetchRows = () => async (dispatch, getState) => {
  dispatch({
    type: FETCH_ROWS
  })

  const { filters } = getState().campaigns

  DEBUG && console.log("Fetching campaigns:", {filters});

  await fetchCampaigns(filters, dispatch);
}
const fetchCampaigns = async (filters, dispatch) => {
  const url = `${rootUrl}?${param(filters)}`

  await $.get(url).then(response => {
    dispatch({
      type: FETCH_ROWS_SUCCESS,
      payload: {
        perPage: response.per_page,
        my_campaigns_count: response.my_campaigns_count,
        totalCount: response.total_count,
        totalPages: response.total_pages,
        results: response.results,
      }
    })
  })
}

export const updateRow = (row, fieldName, value) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ROW,
    payload: { row, fieldName, value }
  })

  const url = `${rootUrl}/${row.id}`
  let lead = {}
  lead[fieldName] = value

  $.post(url, { lead, _method: 'put' }).then(response => {
    dispatch({ type: UPDATE_ROW_SUCCESS })
    dispatch(fetchRows())
  })
}

export const updateRows = (rowIds, values) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ROWS,
    payload: { rowIds, values }
  })

  const url = rootUrl + '/mass_update'
  const params = { row_ids: rowIds, lead: values }

  $.post(url, params).then(response => {
    dispatch({ type: UPDATE_ROWS_SUCCESS })
    dispatch(fetchRows())
  })
}

export const addRow = (row) => (dispatch, getState) => {
  dispatch({
    type: ADD_ROW,
    payload: { row }
  })

  $.post(rootUrl, { campaign: row }).then(response => {
    dispatch({ type: ADD_ROW_SUCCESS })
    dispatch(fetchRows())
  })
}

export const selectRows = (rows) => (dispatch) => {
  dispatch({
    type: SELECT_ROWS,
    payload: { rows }
  })
}

export const deleteSelectedRows = () => (dispatch, getState, state) => {
  const selectedIds = getState().campaigns.selected

  dispatch({
    type: DELETE_ROWS,
    payload: { ids: selectedIds }
  })

  $.post(rootUrl, { tokens: selectedIds, _method: 'delete' }).then(response => {
    dispatch(fetchRows())
  })
}
