import React from "react";
import accounting from "accounting";
import { connect } from "react-redux";
import cs from "classnames";
import moment from "moment";
import createClass from "create-react-class";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import Favorite from "../Campaign/Favorite";
import AuthStore from "../../stores/AuthStore";
import UnitModel from "../../models/unitModel";

import { showAddFlightModal, showBatchAddFlightsModal } from "../Inventory/actions";
import { text_for_flight, list_item_classes } from "./list_item_helper";

const ListItem = createClass({
  displayName: "Timeline/ListItem",
  marginForFlight: 28,

  getInitialState() {
    return {
      unitModel: new UnitModel(this.props.unit)
    };
  },


  onAddFlight() {
    this.props.onUnitSelect(this.props.unit.id, true, true);
    this.props.showBatchAddFlightsModal(this.props.unit);
  },

  onEditFlight(campaign_unit) {
    const { editable, clickable, onShowEditFlightModal } = this.props;
    if (!editable || !clickable || campaign_unit.workflow_state === "available") {
      return;
    }
    onShowEditFlightModal(this.props.unit, campaign_unit);
  },

  onDetailsClick() {
    if (this.props.onUnitDetailsClick) {
      this.props.onUnitDetailsClick(this.props.unit.id);
    }
  },

  getOverlappingFlights(campaign_units) {
    if (!campaign_units) return [];
    const { unit } = this.props;

    if (unit.has_quantity) {
      return this.sortOverlaps(campaign_units);
    } else {
      return this.sortOverlaps(
        campaign_units.filter(campaign_unit => campaign_unit.workflow_state == "on_hold")
      );
    }
  },

  sortOverlaps(scoped_campaign_units) {
    let overlaps = [];
    scoped_campaign_units.forEach(campaign_unit1 => {
      scoped_campaign_units.forEach(campaign_unit2 => {
        if (campaign_unit1 === campaign_unit2) return;
        // overlap if  (StartA <= EndB)  and  (EndA >= StartB)
        if (
          moment.utc(campaign_unit1.start_date) < moment.utc(campaign_unit2.end_date) &&
          moment.utc(campaign_unit1.end_date) > moment.utc(campaign_unit2.start_date)
        ) {
          if (!overlaps.includes(campaign_unit1)) {
            overlaps.push(campaign_unit1);
          }
        }
      });
    });
    return overlaps.sort((campaign_unit1, campaign_unit2) => {
      if (moment.utc(campaign_unit1.start_date) < moment.utc(campaign_unit2.start_date)) {
        return -1;
      } else if (
        campaign_unit1.start_date == campaign_unit2.start_date &&
        campaign_unit1.workflow_state == "available" &&
        campaign_unit2.workflow_state != "available"
      ) {
        return -1;
      } else if (
        campaign_unit1.start_date == campaign_unit2.start_date &&
        moment.utc(campaign_unit1.created_at).valueOf() <
          moment.utc(campaign_unit2.created_at).valueOf()
      ) {
        return -1;
      }
      return 1;
    });
  },

  renderFlights(campaign_units, overlappingFlights, rowHeight) {
    const {
      unit,
      middleWidth,
      startDate,
      endDate,
      editable,
      is_campaign_timeline_view,
      has_quantity
    } = this.props;
    const maxWidth = middleWidth;

    if (campaign_units && campaign_units.length > 0) {
      const days = moment.range(startDate, endDate).diff("day");
      const dayWidth = maxWidth / days;

      return campaign_units.map(campaign_unit => {
        // don't render flights without start/end dates
        if (campaign_unit.workflow_state == "hold_released") return;
        if (!campaign_unit.start_date || !campaign_unit.end_date) return;

        // don't render flights that are outside of the view
        const flightStartDate = moment.utc(campaign_unit.start_date);
        const flightEndDate = moment.utc(campaign_unit.end_date);
        if (moment.utc(startDate).isAfter(flightEndDate)) return;

        // calculate indentation
        const daysFromStartOfTimeline = moment
          .range(startDate, campaign_unit.start_date)
          .diff("day");
        let indent =
          dayWidth *
          (daysFromStartOfTimeline == 0 ? daysFromStartOfTimeline : daysFromStartOfTimeline + 1);

        // calculate day range since beginning of viewport date
        let rangeDays = moment
          .range(
            flightStartDate.isBefore(moment.utc(startDate)) ? startDate : campaign_unit.start_date,
            flightEndDate.isAfter(moment.utc(endDate)) ? endDate : campaign_unit.end_date
          )
          .diff("day");
        if (flightStartDate.isBefore(moment.utc(startDate))) {
          rangeDays += 1;
        }

        let width = dayWidth * (rangeDays + 1);
        if (indent < 0) indent = 0;
        if (width + indent >= maxWidth) width = maxWidth - indent;

        const classNames = list_item_classes(unit, campaign_unit);

        // if multiple holds, then multiply marginTop by marginForFlight
        let marginTop;
        const overlapPosition = overlappingFlights.indexOf(campaign_unit);

        if (campaign_unit.workflow_state === "on_hold" && !!!unit.has_quantity) {
          marginTop = 37.5;
        } else {
          marginTop = 10;
        }

        if (overlapPosition > 0) {
          marginTop = marginTop + this.marginForFlight * overlapPosition;
        }

        const show_campaign_timeline_proposed_style =
          is_campaign_timeline_view && campaign_unit.workflow_state == "proposed";

        const flightStyle = {
          marginLeft: indent,
          width,
          overflow: "hidden",
          height: show_campaign_timeline_proposed_style ? rowHeight - 20 : 23,
          marginTop: marginTop,
          opacity: show_campaign_timeline_proposed_style ? 0.6 : 1,
          zIndex: show_campaign_timeline_proposed_style ? 20 : 100
        };

        if (indent > maxWidth || width == 0) flightStyle.display = "none"; // hide it if the flight is going off the screen

        if (flightEndDate.isSameOrAfter(endDate)) {
          flightStyle.borderTopRightRadius = 0;
          flightStyle.borderBottomRightRadius = 0;
        }

        if (flightStartDate.isSameOrBefore(startDate)) {
          flightStyle.borderTopLeftRadius = 0;
          flightStyle.borderBottomLeftRadius = 0;
        }

        const { bar_text, tooltip_text } = text_for_flight(unit, campaign_unit);
        const startDateText = moment.utc(campaign_unit.start_date).format("MMM D");
        const endDateText = moment.utc(campaign_unit.end_date).format("MMM D");
        const overlayComment = (
          <div>
            {tooltip_text} ({startDateText} - {endDateText})
          </div>
        );

        const flightElement = (
          <div
            className={classNames}
            style={flightStyle}
            onClick={this.onEditFlight.bind(this, campaign_unit)}
            key={campaign_unit.id}
          >
            <div>
              {(editable && !!!show_campaign_timeline_proposed_style) && <span className="text-center">{bar_text}</span>}
              <div style={{ float: "left", opacity: 0.7 }}>{startDateText}</div>
              <div style={{ float: "right", opacity: 0.7 }}>{endDateText}</div>
            </div>
          </div>
        );

        if (this.props.isInventoryView) {
          return (
            <Tooltip
              placement="top"
              overlay={overlayComment}
              key={`campaign_unit-${campaign_unit.id}`}
            >
              {flightElement}
            </Tooltip>
          );
        } else {
          return flightElement;
        }
      });
    }
  },

  renderDateMarkers() {
    let index = 0;
    const { headers, middleWidth, height } = this.props;
    const partWidth = middleWidth / headers.length;

    return headers.map(header => {
      const markerStyle = {
        width: 1,
        height,
        backgroundColor: "#E6EDF0",
        left: (middleWidth / headers.length) * index,
        position: "absolute",
        zIndex: 1
      };

      index += 1;
      return (
        <div style={{ position: "relative", width: 0, height: 0 }} key={index}>
          <div style={markerStyle} />
        </div>
      );
    });
  },

  renderTimelineItemContent(unit) {
    const { howLong } = this.props;
    const user = AuthStore.state.user;

    if (user.is_admin || user.is_agency) {
      return (
        <div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data timelineItem__supplier_name text-muted">
              {unit.supplier_name}
            </div>
          </div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data timelineItem__size">
              {unit.size && (
                <span>
                  <span className="text-muted">Size</span> {unit.size}
                </span>
              )}
            </div>
            <div className="timelineItem__text_data timelineItem__impressions">
              {unit.impressions && unit.impressions > 0 ? (
                <span className="text-muted">
                  {accounting.format(unit.impressions)} imp/{howLong}w
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else if (user.is_supplier_admin) {
      return (
        <div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data timelineItem__size">
              {unit.size && (
                <span>
                  <span className="text-muted">Size</span> {unit.size}
                </span>
              )}
            </div>
            <div className="timelineItem__text_data" style={{ float: "right" }} />
          </div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data">
              {unit.cpm && (
                <span>
                  <span className="text-muted">CPM </span>
                  {this.state.unitModel.getMockCpm(this.props.campaign)}
                </span>
              )}
            </div>
            <div className="timelineItem__text_data timelineItem__impressions">
              {unit.impressions && unit.impressions > 0 ? (
                <span className="text-muted">
                  {accounting.format(unit.impressions)} imp/{howLong}w
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="timelineItem__row">
          <div className="timelineItem__text_data timelineItem__size">
            {unit.size && (
              <span>
                <span className="text-muted">Size</span> {unit.size}
              </span>
            )}
          </div>
          <div className="timelineItem__text_data timelineItem__impressions">
            {unit.week_total_impressions && unit.week_total_impressions > 0 ? (
              <span className="text-muted">
                {accounting.format(unit.week_total_impressions)} imp/{howLong}w
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  },

  renderFaceId() {
    const { campaign, unit } = this.props
    if(campaign && (campaign.is_mock || campaign.hide_face_ids)) { return unit._id }
    return unit.supplier_face_id
  },

  renderUnitName() {
    const { campaign } = this.props
    if (campaign && campaign.is_mock) { return false }
    return true
  },

  showTimeLinePricing() {
    const { campaign } = this.props
    if (!_.isEmpty(campaign) && _.get(campaign, 'is_mock')) { return false }
    return true
  },

  render() {
    const {
      unit,
      howLong,
      middleWidth,
      rightWidth,
      leftWidth,
      editable,
      filters,
      is_campaign_timeline_view,
      isInventoryView,
      isInCart
    } = this.props;

    const selected =
        this.props.selectedUnitIds && unit && this.props.selectedUnitIds.includes(unit.id);

    const className = cs(`timelineItem_${unit.unit_type}`, "timelineItem", {
      timelineItem_active: this.props.isActive,
      "direct-match":
        filters &&
        filters.q &&
        unit.supplier_face_id &&
        filters.q == unit.supplier_face_id.toString(),
      "timelineItem__selected": selected
    });

    const thumbnailWidth = 50;
    const permissions = this.props.permissions || {};

    const styles = {
      thumbnail: {
        backgroundImage: `url(${unit.thumbnail_url})`,
        border: "1px solid #eee",
        width: thumbnailWidth,
        marginTop: "11px"
      },
      flights: {
        float: "left",
        width: middleWidth,
        position: 'relative'
      },
      pricing: {
        width: rightWidth
      },
      leftSide: {
        float: "left",
        width: leftWidth
      }
    };

    let overlappingFlights = this.getOverlappingFlights(unit.campaign_units);

    let rowHeight = 70;
    if (overlappingFlights.length > 0) {
      rowHeight = rowHeight + (overlappingFlights.length - 1) * this.marginForFlight;
    }

    let actionButton, selectedCheckbox;
    if (editable) {
      actionButton = (
        <div
          className="btn btn-default btn-sm"
          style={{ margin: "auto", marginTop: 18 }}
          onClick={this.onAddFlight}
        >
          <i className="fa fa-plus" />
        </div>
      );

      selectedCheckbox = (
        <div
          className="btn btn-default btn-sm"
          style={{ margin: "auto", marginTop: 18 }}
          onClick={e => this.props.onUnitSelect(unit.id, !!!selected)}
        >
          {selected === true ? (
            <i className="fa fa-check-square-o" />
          ) : (
            <i className="fa fa-square-o" />
          )}
        </div>
      );
    } else if (permissions.can_book_unit) {
      actionButton = (
        <div className="btn btn-primary btn-sm" style={{ margin: "auto" }}>
          Add To Cart
        </div>
      );
    }

    const unavailableStyle = {
      width: "100%",
      height: rowHeight,
      opacity: 0.8,
      background: "#fff",
      textAlign: "center",
      paddingTop: 20,
      zIndex: 999,
      position: "absolute",
      color: "gray",
      fontWeight: "bold"
    };

    return (
      <div className={className} style={{ height: rowHeight }}>
        {unit.unavailable && (
          <div style={unavailableStyle}>
            This unit is partially unavailable due to a booking by another customer.
          </div>
        )}

        <div className="unit-details-area" style={styles.leftSide} onClick={this.onDetailsClick}>
          {(!unit.thumbnail_url && !!!is_campaign_timeline_view) && (
            <div className="timelineItem__image no_image" style={styles.thumbnail}>
              <i className="fa fa-image" style={{ marginRight: 0 }} />
            </div>
          )}
          {unit.thumbnail_url && <div className="timelineItem__image" style={styles.thumbnail} />}
          <div className="timelineItem__content">
            {this.props.isCampaignView && permissions.can_view_favorites && (
              <div className="timelineItem__favorite">
                <Favorite
                  isFavorited={unit.is_favorited}
                  unitId={unit.id}
                  onFavoriteUnit={this.props.onFavoriteUnit}
                  showCallback={false}
                />
              </div>
            )}
            <h2 className="timelineItem__title">
              {(isInCart && !!!isInventoryView) && <i className="fa fa-check" />}
              {unit.supplier_face_id && (
                <span className="listItem__face_id">{this.renderFaceId()}</span>
              )}
              {this.renderUnitName() &&
                unit.name
              }
            </h2>
            {this.renderTimelineItemContent(unit)}
          </div>
        </div>
        <div className="timelineItem__flights" style={styles.flights}>
          {this.renderDateMarkers()}
          {this.renderFlights(unit.campaign_units, overlappingFlights, rowHeight)}
        </div>
        {this.showTimeLinePricing() &&
          <div className="timelineItem__pricing" style={styles.pricing}>
            {is_campaign_timeline_view ? (
              <div>
                <input
                  type="checkbox"
                  checked={selected}
                  onClick={e => this.props.onUnitSelect(unit.id, !!!selected)}
                  style={{ display: "inline-block", margin: 30 }}
                />
              </div>
            ) : (
              <div>
                {AppConfig.showPricing && !editable && (
                  <div className="timelineItem__price">
                    {accounting.formatMoney(unit.price)}/{howLong}w
                  </div>
                )}
                <div className="timelineItem__actions" style={{ width: "100%", textAlign: "center" }}>
                  <span style={{ verticalAlign: "middle", marginLeft: 10 }}>{actionButton}</span>
                  <span style={{ verticalAlign: "middle", marginLeft: 10 }}>{selectedCheckbox}</span>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    );
  }
});

ListItem.propTypes = {
  unit: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  middleWidth: PropTypes.number.isRequired,
  rightWidth: PropTypes.number.isRequired,
  leftWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default connect(
  null,
  { showAddFlightModal, showBatchAddFlightsModal }
)(ListItem);
