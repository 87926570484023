import { IHighlightedArea, IDistrict, IMarket, IFile } from "./Modal";
import { DEFAULT_COLOR, AREA_TYPES } from "./constants";

export const buildHighlightedArea = (
  dataLayer,
  value: string | IDistrict[] | IMarket[] | IFile[],
  color: string = DEFAULT_COLOR
) => ({
  _showColorPicker: false,
  color,
  value,
  visible: dataLayer.visible,
  id: dataLayer.id,
  name: dataLayer.name,
  data_layer_id: dataLayer.data_layer_id,
  area_type: dataLayer.area_type,
} as IHighlightedArea);

export const extractHighlightedArea = (dataLayer) => buildHighlightedArea(
  dataLayer,
  dataLayer.name,
  dataLayer.color
);

export const hasOtherDataLayersSet = (data_layers) => {
  if (!data_layers || !data_layers.campaign_data_layers) return [];
  return data_layers.campaign_data_layers.some((data_layer) => data_layer.type !== "HighlightedArea");
}

export const extractHighlightedAreas = (data_layers) => {
  if (!data_layers || !data_layers.campaign_data_layers) return [];
  return data_layers.campaign_data_layers
    .filter(dataLayer => dataLayer.type === "HighlightedArea")
    .map(extractHighlightedArea);
};

export const getAreaName = (area_type: string): string => {
  const area = AREA_TYPES.find(area => area.type === area_type);
  return area ? area.name : "";
}
