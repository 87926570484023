import * as React from "react";
import GlobalActions from "../../../actions/GlobalActions";

interface Props {
  showModal: Function;
  disabled: boolean;
}

interface State {}

class BulkSetShippingAddressAction extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  onClick() {
    const { showModal } = this.props;
    showModal();
  }

  showMessage() {
    GlobalActions.showError(
      "One or more selected units have already been sent to production and the shipping address cannot be updated.",
    );
  }

  public render() {
    const { disabled } = this.props;
    const button_style = {
      color: "#555555",
      width: "100%",
      fontSize: 12,
    };

    return (
      <button
        key="set_shipping_address"
        onClick={disabled ? this.showMessage : this.onClick}
        className={`btn btn-default ${disabled ? "disabled" : ""}`}
        style={button_style}
      >
        Set Shipping Address
      </button>
    );
  }
}

export default BulkSetShippingAddressAction;
