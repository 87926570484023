import React from 'react';
import AuthStore from '../../stores/AuthStore';
import ConfirmAccountModal from './ConfirmAccountModal';
import SignUpModal from './SignUpModal';
import SignUpSuccessModal from './SignUpSuccessModal';

export default class SignupWizard extends React.Component {

  constructor(props) {
    super(props);
    const { show, step, title } = this.props;
    const auth = AuthStore.getState();
    this.state = {
      auth: auth,
      step: step || this.step(),
      show: show,
      title: title
    };
    this.loadUnitCount = this.loadUnitCount.bind(this);
    this.onHide = this.onHide.bind(this);
    this.step = this.step.bind(this);
  }

  step() {
    const { user } = AuthStore.getState();

    if (!user || (user && user.is_guest)) {
      return 'sign_up';
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ step: this.step(), show: nextProps.show });
  }

  componentDidMount() {
    this.unsubscribeList = [];
    this.loadUnitCount();
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  loadUnitCount() {
    const { bounds, query } = this.props;
    if (!bounds || !query) return;

    $.get(`/api/v1/search/count_only?bounds=${bounds}`).then((response) => {
      this.setState({ unitCount: response.count });
    });
  }

  onHide() {
    const { force } = this.props;
    if (!force) {
      this.setState({ step: null });
    }
  }

  render() {
    const { user } = this.state.auth;
    const { returnTo, query, force, onHide, showSaveTen } = this.props;
    const { step, show, unitCount } = this.state;
    return (<div>
      <SignUpModal
        query={query}
        unitCount={unitCount}
        show={show && step == 'sign_up'}
        force={force}
        onHide={force ? (() => {}) : onHide}
        openLogin={() => { this.setState({ step: 'login' }); }}
        onSignup={() => { this.setState({ step: 'success' }); }}
        returnTo={returnTo}
        showSaveTen={showSaveTen}
        force={force}
        allow_signup={true}
      />
      <ConfirmAccountModal
        show={show && step == 'confirm_account'}
        onHide={this.onHide}
        user={user}
        returnTo={returnTo}
      />
      <SignUpSuccessModal
        show={show && step == 'success'}
        onHide={() => { this.setState({ step: null }); }}
        user={user}
        returnTo={returnTo}
      />
    </div>);
  }
};
