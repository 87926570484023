import React from 'react';
import createClass from 'create-react-class';

const LocationTab = createClass({

  displayName: 'LocationTab',

  render() {
    const { address_description, loc, image_street_view_link } = this.props.unit;

    return (<div className="locationTab">
      <div className="locationTab__streetview" ref="streetview" style={{ marginTop: 10 }}>
        <iframe width="600" height="350" src={image_street_view_link} allowFullScreen frameBorder="0" />
      </div>
    </div>);
  },
});

export default LocationTab;
//
// export default GoogleApiComponent({
//   apiKey: 'AIzaSyCP6CTJdBd5ii1P4GPrimN3EY_84y5BSuY',
// })(LocationTab);
