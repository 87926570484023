import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { createCustomBounds } from "../../actions/data_layers";
import GlobalActions from "../../actions/GlobalActions";
import { favoriteUnits, toggleRecommendUnits, unfavoriteUnits } from "../../actions/UnitsActions";
import { mapUnits, poisWithinPolygon } from "../../models/Units";
import NewMapStore from "../../stores/NewMapStore";
import PlacesStore from "../../stores/PlacesStore";
import { get, patch, post } from "../../utils/api";
import JobProgress from "../../utils/JobProgress";
import { loadCampaignUnits, removeCampaignUnits, removeCampaignTags, removeActiveTags } from "../Campaign/actions";
import AddCustomPOILogo from "../Campaign/AddCustomPOILogo";
import CopyPOIs from "../Map/CopyPOIs";
import CopyUnits from "../Map/CopyUnits";
import FindVendors from "../Map/FindVendors";
import Tags from "../Map/Tags";

import RemoveUnits from "../Map/RemoveUnits";
import { deleteIcon, removePlaces, updateIconById } from "../Places/actions";

class DrawToolsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLink: null,
      open: false,
      job: new JobProgress(),
    };

    this.onOutsideClick = this.onOutsideClick.bind(this);
    this.onRemoveTagsComplete = this.onRemoveTagsComplete.bind(this);
    // this.onRemoveUnitsComplete = this.onRemoveUnitsComplete.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onOutsideClick);
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener("mousedown", this.onOutsideClick);
  }

  get perms() {
    return this.props.permissions;
  }

  get selectedUnits() {
    const units = mapUnits(this.props.units);
    return units ? units.features : [];
  }

  get selectedUnitIds() {
    return this.selectedUnits.map(unit => unit.options?.id || unit.properties.id);
  }

  get selectedCampaignUnitIds() {
    return this.selectedUnits.map(unit => unit.options?.campaign_unit_token || unit.properties.campaign_unit_token);
  }

  get showList() {
    return !this.isActive("Find Vendors") && !this.isActive("Add POI Logo") && !this.isActive("Add POI logo or icon");
  }

  get selectedPois() {
    const { placemarkers } = NewMapStore.getState();
    const all_pois = map(placemarkers.features, f => f.properties);
    const selected_pois = poisWithinPolygon(all_pois, this.props.map.selected_polygons);
    return selected_pois;
  }

  get isAnyTagActive() {
    return this.props.units.unit_filter.tags.length > 0
  }

  setActive(link) {
    this.setState({ activeLink: link });
  }

  isActive(link) {
    return this.state.activeLink === link ? "active" : "";
  }

  async onAdd() {
    addLoadingStep("Adding units");
    const unit_filter = this.props.units.unit_filter || {};
    const { added_units_count } = await post(
      `/api/v1/campaigns/${this.props.campaign.token}/add_units_to_existing_campaign`,
      {
        coordinates: this.props.map.selected_coordinates,
        filters: unit_filter,
      },
    );
    GlobalActions.showMessage(`${added_units_count} new units were added to this campaign.`);
    await this.props.loadCampaignUnits(this.props.campaign.token);
    removeLoadingStep("Adding units");
    this.closeModal();
  }

  async onToggleRecommend(action) {
    const loadingLabel = `${capitalize(action)}ing units`;
    addLoadingStep(loadingLabel);
    await this.props.toggleRecommendUnits(this.selectedUnitIds, this.props.campaign.token, action);
    removeLoadingStep(loadingLabel);
  }

  async onFavorite() {
    addLoadingStep("Adding to favorites");
    const campaign_unit_ids = this.selectedCampaignUnitIds;
    const unit_ids = this.selectedUnitIds;
    await this.props.favoriteUnits(campaign_unit_ids, unit_ids, this.props.campaign.token, true);
    removeLoadingStep("Adding to favorites");
  }

  async onUnfavorite() {
    addLoadingStep("Removing from favorites");
    const campaign_unit_ids = this.selectedCampaignUnitIds;
    const unit_ids = this.selectedUnitIds;
    await this.props.unfavoriteUnits(campaign_unit_ids, unit_ids, this.props.campaign.token, true);
    removeLoadingStep("Removing from favorites");
  }

  async onRemoveTags() {
    const unit_ids = this.selectedUnitIds;
    if (confirm(`Are you sure you want to remove all tags for ${unit_ids.length} units?`)) {
      addLoadingStep("Removing tags");
      const { job_id } = await post(`/api/v1/campaigns/${this.props.campaign.token}/batch_remove_tags`, { unit_ids });
      this.interval = this.state.job.startPolling(job_id, ({ data }) => this.onRemoveTagsComplete({ data, unit_ids }));
    }
  }

  async onRemoveTagsComplete({ data, unit_ids }) {
    const { error } = data;
    if (error) return GlobalActions.showError(error);
    await this.props.removeCampaignTags(unit_ids);
    GlobalActions.showMessage(`All tags removed for ${unit_ids.length} units.`);
    removeLoadingStep("Removing tags");
    this.closeModal();
  }

  async onRemoveActiveTags() {
    const unit_ids = this.selectedUnitIds;
    const tagNames = this.props.units.unit_filter.tags.map(tag => tag.tag)
    if (confirm(`Are you sure you want to remove the active tags for ${unit_ids.length} units? Tags to be removed: ${tagNames.join(', ')}.`)) {
      addLoadingStep("Removing active tags");
      const { job_id } = await post(`/api/v1/campaigns/${this.props.campaign.token}/batch_remove_tags`, { unit_ids, tags: tagNames });
      this.interval = this.state.job.startPolling(job_id, ({ data }) => this.onRemoveActiveTagsComplete({ data, unit_ids, tagNames }));
    }
  }

  async onRemoveActiveTagsComplete({ data, unit_ids, tagNames }) {
    const { error } = data;
    if (error) return GlobalActions.showError(error);
    await this.props.removeActiveTags(unit_ids, tagNames);
    GlobalActions.showMessage(`Active tags removed for ${unit_ids.length} units.`);
    removeLoadingStep("Removing active tags");
    this.closeModal();
  }

  async onAddToPackage() {
    const response = await get(`/api/v1/campaigns/${this.props.campaign.token}/package_units`);
    const packages = response.units.map(u => {
      return {
        value: u.id,
        label: u.name,
      };
    });
    this.setState({ availablePackages: packages });
  }

  async onCreateCustomBounds() {
    addLoadingStep("Saving Custom Bounds");
    const coordinates = this.props.map.selected_polygons[0]?.geometry?.coordinates;
    await this.props.createCustomBounds(this.props.campaign.token, coordinates);
    this.props.updateDataLayers();
    removeLoadingStep("Saving Custom Bounds");
  }

  async removePois() {
    addLoadingStep("Removing POIs");
    const poi_ids = this.selectedPois.map(p => p.id);
    await this.props.removePlaces(poi_ids, this.props.campaign.token);
    PlacesStore.trigger("places:updated");
    GlobalActions.showMessage(`${poi_ids.length} POI(s) removed.`);
    removeLoadingStep("Removing POIs");
    this.closeModal();
  }

  async removePoisLogos() {
    addLoadingStep("Removing POIs Logos");
    const poi_ids = this.selectedPois.map(p => p.id);
    await this.props.deleteIcon(poi_ids, this.props.campaign.token);
    PlacesStore.trigger("places:updated");
    GlobalActions.showMessage("POIs logos successfully deleted");
    removeLoadingStep("Removing POIs Logos");
    this.closeModal();
  }

  onOutsideClick(event) {
    if (this.modalRef && !this.modalRef.contains(event.target)) {
      this.closeModal();
    }
  }

  onRemovePois() {
    if (isEmpty(this.selectedPois)) {
      return GlobalActions.showError("No POIs selected.");
    }

    if (confirm(`Are you sure you want to remove ${this.selectedPois.length} POIs?`)) {
      return this.removePois(this.selectedPois);
    }
  }

  onRemovePoisLogo() {
    if (isEmpty(this.selectedPois)) {
      return GlobalActions.showError("No POIs selected.");
    }

    if (confirm(`Are you sure you want to remove ${this.selectedPois.length} POIs custom logos?`)) {
      return this.removePoisLogos(this.selectedPois);
    }
  }

  makeButtonClickAction(label, onClick) {
    return event => {
      this.setActive(label);
      onClick && onClick(event);
    };
  }

  renderButton(label, icon, onClick) {
    return (
      <li>
        <button className={this.isActive(label)} onClick={this.makeButtonClickAction(label, onClick)}>
          <i className={`fal ${icon}`}></i>
          {label}
        </button>
      </li>
    );
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.props.map.selected_markers = null;
    this.setActive(null);
    this.setState({ open: false });
    this.props.filterUnits({ draw: null })
    this.props.selectCoordinates([]);
  }

  async addChildrenToPackage(e) {
    addLoadingStep("Adding Units to Package");
    const campaign_unit_tokens = this.selectedCampaignUnitIds;
    const selected_package = e.value;

    await patch(
      `/api/v1/campaigns/${this.props.campaign.token}/import_to_package/${selected_package}`,
      { campaign_unit_tokens },
    );
    removeLoadingStep("Adding Units to Package");
    await this.props.loadCampaignUnits(this.props.campaign.token);
    this.closeModal();
  }

  renderPackageSelect() {
    return (
      <Select
        placeholder="Select package"
        options={this.state.availablePackages}
        onChange={e => this.addChildrenToPackage(e)}
        simpleValue
      />
    );
  }

  render() {
    if (this.props.map.selected_markers && !this.state.open) this.openModal();
    if (!this.state.open) return null

    return (
      <div
        ref={node => (this.modalRef = node)}
        data-controller={"draw-tool-modal"}
        data-draw-tool-modal-selected-tokens-value={JSON.stringify(this.selectedCampaignUnitIds)}
        data-draw-tool-modal-campaign-token-value={this.props.campaign.token}
        className={`draw-tools-modal ${this.state.open ? "open" : ""}`}
      >
        {this.showList && <>
          <h4>{this.selectedUnits.length} Units in bounds</h4>
          <div className="lists">
            <ul>
              <li>
                <h5>Quick Actions</h5>
              </li>
              {this.perms.can_recommend_units && <>
                {this.renderButton("Recommend", "fa-registered", () => this.onToggleRecommend("recommend"))}
                {this.renderButton("Unrecommend", "fa-times-circle", () => this.onToggleRecommend("unrecommend"))}
              </>}
              <li>
                <hr />
              </li>
              {this.perms.can_favorite_units && <>
                {this.renderButton("Favorite", "fa-heart", () => this.onFavorite())}
                {this.renderButton("Unfavorite", "fa-heart-broken", () => this.onUnfavorite())}
              </>}
              <li>
                <hr />
              </li>
              {this.perms.can_edit_tags && <>
                {this.renderButton("Add Tag", "fa-tag")}
                {this.renderButton("Remove All Tags", "fa-file-times", () => this.onRemoveTags())}
                {this.isAnyTagActive && this.renderButton("Remove Active Tags", "fa-file-times", () => this.onRemoveActiveTags())}
              </>}
              {this.renderButton("Copy Units", "fa-copy")}
              {this.renderButton("Copy Units + Tags", "fa-copy")}
              {this.renderButton("Copy POIs", "fa-copy")}
              {this.renderButton("Remove Units", "fa-trash-alt")}
              {this.renderButton("Add Units to Package", "fa-cubes", () => this.onAddToPackage())}
              <li>
                <button
                  className={this.isActive("Create Flights")}
                  onClick={this.makeButtonClickAction("Create Flights", () => this.closeModal())}
                  data-action={"click->draw-tool-modal#openFlightModal"}
                >
                  <i className={`fal fa-layer-plus`}></i>
                  {"Create Flights"}
                </button>
              </li>
            </ul>
            <ul>
              <li>
                <h5>Custom Bounds</h5>
              </li>
              {this.props.permissions.can_add_additional_units_from_map &&
                this.renderButton("Find & Add Units", "fa-plus-circle", () => this.onAdd())}
              {this.perms.can_send_rfps && this.renderButton("Find Vendors", "fa-cubes")}
              <li>
                <hr />
              </li>
              {this.renderButton("Add POI logo or icon", "fa-file-image")}
              {this.renderButton("Remove POIs", "fa-map-marker-alt-slash", () => this.onRemovePois())}
              {this.renderButton("Remove POIs Logo", "fa-eraser", () => this.onRemovePoisLogo())}
              <li>
                <hr />
              </li>
              {this.renderButton("Save Custom Bounds", "fa-shapes", () => this.onCreateCustomBounds())}
            </ul>
          </div>
        </>}
        <div className="bottom">
          {this.isActive("Add Tag") && this.perms.can_edit_tags && <>
            <hr />
            <div className="add-tag">
              <Tags campaign={this.props.campaign} unitIds={this.selectedUnitIds} onHide={() => ""} />
            </div>
          </>}
          {this.isActive("Copy Units") && (
            <CopyUnits
              unit_ids={this.selectedUnitIds}
              from_campaign_id={this.props.campaign.token}
              onCancel={() => this.setActive(null)}
              onHide={() => this.setActive(null)}
            />
          )}
          {this.isActive("Copy Units + Tags") && (
            <CopyUnits
              unit_ids={this.selectedUnitIds}
              from_campaign_id={this.props.campaign.token}
              onCancel={() => this.setActive(null)}
              onHide={() => this.setActive(null)}
              include_tags={true}
            />
          )}
          {this.isActive("Copy POIs") && (
            <CopyPOIs
              poi_ids={this.selectedPois.map(p => p.id)}
              from_campaign_id={this.props.campaign.token}
              onCancel={() => this.setActive(null)}
              onHide={() => this.setActive(null)}
            />
          )}
          {this.isActive("Remove Units") && (
            <div className="remove-unit">
              <RemoveUnits
                hasRequests={this.props.campaign_permissions.show_unit_removal_reason}
                from_campaign_id={this.props.campaign.token}
                unit_ids={this.selectedUnitIds}
                onCancel={() => this.setActive(null)}
                onHide={() => this.setActive(null)}
              />
            </div>
          )}
          {this.isActive("Add POI logo or icon") && (
            <div className="custom_poi_logo_modal--drawtools">
              <AddCustomPOILogo
                campaign={this.props.campaign.token}
                selectedPois={this.selectedPois}
                map={this.props.map}
                onHide={() => this.closeModal()}
              />
            </div>
          )}
          {this.isActive("Find Vendors") && (
            <FindVendors
              campaign={this.props.campaign}
              boundingBox={this.props.map.selected_bounds}
              onCancel={() => this.setActive(null)}
              onHide={() => ""}
            />
          )}
          {this.isActive("Add Units to Package") && this.renderPackageSelect()}
        </div>
      </div>
    );
  }
}

export default connect(
  ({ units, campaign: { campaign } }) => ({ units, campaign }),
{
  createCustomBounds,
  favoriteUnits,
  unfavoriteUnits,
  updateIconById,
  deleteIcon,
  removePlaces,
  loadCampaignUnits,
  removeCampaignUnits,
  removeCampaignTags,
  removeActiveTags,
  toggleRecommendUnits,
})(DrawToolsModal);
