import {
  SHOW_PROMPT,
  GET_STARTED,
  SHARE_CAMPAIGN,
  LEARN_MORE,
  REQUEST_HOLD,
  CONTACT_EXECUTIVE,
  CLEAR_DIALOG,
  SHOW_TOAST,
} from "./actions";

const initialState = {
  prompt: null,
  acknowledgement: null,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SHOW_PROMPT:
      return {
        ...state,
        ...{ prompt: action.payload, acknowledgement: null }
      };
    case SHOW_TOAST:
      return {
        ...state,
        ...{ prompt: null, acknowledgement: action.payload }
      };
    case CLEAR_DIALOG:
      return {
        ...state,
        ...{ prompt: null, acknowledgement: null }
      };
    default:
      return state;
  }
}
