import { get, post, put, del } from "../../utils/api";
import GlobalActions from '../../actions/GlobalActions';

const ADVERTISERS_URL = "/api/v2/advertisers";
const CRM_URL = "/api/v1/crm";

export const createAdvertiser = (user, advertiser) => async(dispatch, getState) => {
  const res = await post(ADVERTISERS_URL, {...advertiser, supplier_id: user.supplier_id})
  if (res.status === 200) {
    const advertisers = getState().advertisers_ui && getState().advertisers_ui.advertisers
    dispatch({ type: 'CREATE_ADVERTISER_SUCCESS', payload: {
      advertiser: res.advertiser,
      advertisers,
      advertiser_id: res.advertiser_id
    }});
    dispatch({ type: 'HIDE_ADVERTISER_CREATE_MODAL' });
    GlobalActions.showMessage('Advertiser Created');
  } else {
    dispatch({ type: 'CREATE_ADVERTISER_FAILURE', payload: res });
    console.log('create advertiser failed', res)
  }
};

export const fetchAllAdvertisers = () => async (dispatch, getState) => {
  dispatch({
    type: "FETCH_DEFAULT_BACKGROUND"
  });
  const advertisers = await $.get("/api/v1/advertisers");

  dispatch({
    type: "FETCH_DEFAULT_BACKGROUND_SUCCESS",
    payload: advertisers
  });
};

export const fetchAdvertisers = (filters) => async (dispatch, getState) => {
  dispatch({ type: "FETCH_DEFAULT_BACKGROUND" });
  const res = await $.get(CRM_URL, filters);

  if (res.status === 200) {
    dispatch({
      type: "FETCH_DEFAULT_BACKGROUND_SUCCESS",
      payload: res
    });
  }
};

export const fetchAllPreviousAdvertisers = () => async (dispatch) => {
  dispatch({ type: "FETCH_ALL_PREVIOUS_ADVERTISERS" });
  const { previous_advertisers } = await get(`${ADVERTISERS_URL}/previous_advertisers`);
  return previous_advertisers;
}

export const fetchAdvertiserCampaigns = (advertiser, filters) => async (dispatch, getState) => {
  dispatch({ type: "FETCH_DEFAULT_BACKGROUND" });
  const res = await $.get(`${CRM_URL}/campaigns?a_id=${advertiser.id}`, filters);

  dispatch({
    type: "FETCH_ADVERTISER_CAMPAIGNS_SUCCESS",
    payload: res
  });
};

export const selectAdvertiser = (advertiser) => (dispatch, getState) => {
  dispatch({
    type: 'SELECT_ADVERTISER',
    payload: {
      advertiser
    }
  })
};

export const setFilter = (filters) => (dispatch, getState) => {
  dispatch({
    type: 'SET_FILTERS',
    payload: {
      ...filters
    }
  })
};

export const setCampaignsFilter = (filters) => (dispatch, getState) => {
  dispatch({
    type: 'SET_CAMPAIGN_FILTERS',
    payload: {
      ...filters
    }
  })
};

export const confirmDeleteCampaign = (campaign_for_deletion) => (dispatch, getState) => {
  dispatch({
    type: 'CONFIRM_DELETE_CAMPAIGN',
    payload: {
      campaign_for_deletion
    }
  });
};

export const confirmDeleteAdvertiser = (advertiser_for_delete) => (dispatch, getState) => {
  dispatch({
    type: 'CONFIRM_DELETE_ADVERTISER',
    payload: {
      advertiser_for_delete
    }
  });
};

export const hideCreateAdvertiserModal = () => (dispatch, getState) => {
  dispatch({ type: 'HIDE_ADVERTISER_CREATE_MODAL' });
};

export const showCreateAdvertiserModal = () => (dispatch, getState) => {
  dispatch({ type: 'SHOW_ADVERTISER_CREATE_MODAL' });
};

export const cancelConfirmDeleteCampaign = () => (dispatch, getState) => {
  dispatch({ type: 'CANCEL_CONFIRM_DELETE_CAMPAIGN' });
};

export const cancelConfirmDeleteAdvertiser = () => (dispatch, getState) => {
  dispatch({ type: 'CANCEL_CONFIRM_DELETE_ADVERTISER' });
};

export const showEditAdvertiserModal = () => (dispatch, getState) => {
  dispatch({ type: 'SHOW_ADVERTISER_EDIT_MODAL' });
};

export const hideEditAdvertiserModal = () => (dispatch, getState) => {
  dispatch({ type: 'HIDE_ADVERTISER_EDIT_MODAL' });
};

export const updateAdvertiser = (advertiser, user) => async (dispatch, getState) => {
  dispatch({ type: 'UPDATE_ADVERTISER_BEGIN' });
  const res = await put(`/api/v2/a/${advertiser.id}`, {...advertiser, supplier_id: user.supplier_id})
  if (res.status === 200) {
    dispatch({ type: 'UPDATE_ADVERTISER_SUCCESS', payload: {
      advertiser: res.advertiser
    }});
    dispatch({ type: 'HIDE_ADVERTISER_EDIT_MODAL' });
    GlobalActions.showMessage('Advertiser Updated');
    fetchAdvertisers();
  } else {
    const { errors } = res;
    dispatch({ type: 'UPDATE_ADVERTISER_FAILURE', payload: { errors } });
    console.log('create advertiser failed', res)
  }
};

export const deleteCampaign = (campaign_for_deletion) => async (dispatch, getState) => {
  dispatch({ type: 'DELETE_CAMPAIGN_START' });
  const res = await $.ajax({
    url: `/api/v1/campaigns/${campaign_for_deletion.token}`,
    type: "DELETE"
  })
  dispatch({
    type: 'DELETE_CAMPAIGN_SUCCESS',
    payload: {
      campaign_for_deletion
    }
  })
  GlobalActions.showMessage('Campaign Deleted');
};

export const deleteAdvertiser = (advertiser) => async (dispatch, getState) => {
  dispatch({ type: 'DELETE_ADVERTISER_START' });
  const res = await $.ajax({
    url: `/api/v2/a/${advertiser.id}`,
    type: "DELETE"
  })
  dispatch({
    type: 'DELETE_ADVERTISER_SUCCESS',
    payload: {
      advertiser
    }
  })
  GlobalActions.showMessage('Advertiser Deleted');
};

export const resetAdvertiser = () => (dispatch, getState) => {
  dispatch({ type: 'RESET_ADVERTISER' })
};
