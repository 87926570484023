import { connect } from "react-redux";
import React from "react";
import Form from "./Form";
import AddByMap from "./AddByMap";
import { browserHistory } from 'react-router-legacy';
import AuthStore from "../../../stores/AuthStore";
import {
  loadBuilderCampaignUnits,
  resetBuilderState,
  loadCampaignData,
  loadFaceIds,
  goToStep
} from "./actions";
import {
  loadCampaign,
  clearCampaign
} from "../../Campaign/actions";
import ReviewUnits from "./ReviewUnits";
import ReviewCampaignButton from "./ReviewCampaignButton";
import GlobalActions from '../../../actions/GlobalActions';
import Header from './Header';
import BreadCrumbs from './BreadCrumbs';

class CampaignBuilder extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    const { resetBuilderState, clearCampaign } = this.props;
    clearCampaign();
    resetBuilderState();
  }

  async componentDidMount() {
    const {
      step,
      campaign,
      goToStep,
      routeParams,
      loadFaceIds,
      loadCampaign,
      campaign_token,
      resetBuilderState,
      clearCampaign,
      loadBuilderCampaignUnits
    } = this.props;

    loadBuilderCampaignUnits(campaign_token);
    clearCampaign();
    resetBuilderState();

    const current_step = this.getStepFromParams();

    if (routeParams && routeParams.campaign_builder_token) {
      this.props.loadCampaignData(routeParams.campaign_builder_token);
    }

    goToStep(current_step);
    loadFaceIds();
  }

  getStepFromParams() {
    const { params } = this.props;
    if (params.campaign_builder_token && params.step === "unit_info") return 3;
    else if (params.campaign_builder_token) return 2;
    else return 1;
  }

  componentDidUpdate(prevProps) {
    const {
      step,
      params,
      campaign,
      routeParams,
      loadCampaign,
      campaign_token,
      campaign_builder
    } = this.props;

    if (routeParams && routeParams.campaign_builder_token && !!!campaign) {
      this.props.loadCampaignData(routeParams.campaign_builder_token);
    }

    if (step !== prevProps.step || (!!!prevProps.campaign_builder && campaign_builder)) {
      if (routeParams && routeParams.campaign_builder_token) {
        this.setUrl(step, routeParams.campaign_builder_token);
      } else {
        const token = campaign_builder ? campaign_builder.token : ''
        this.setUrl(step, token)
      }
    }

    if ((!!!campaign || campaign && !!!campaign.token) && campaign_token) {
      loadCampaign(campaign_token);
    }
  }

  componentWillReceiveProps(nextProps) {
    // if we just updated the campaign details, reload the campaign data.
    if (this.props.step === 1.5 && nextProps.step !== 1.5) {
      const { loadCampaign, campaign_token} = this.props;
      loadCampaign(campaign_token);
    }
  }

  setUrl(step, campaign_builder_token) {
    switch (step) {
      case 1:
        return browserHistory.push(`/campaign_builder`);
      case 2:
        return browserHistory.push(
          `/campaign_builder/${campaign_builder_token}`
        );
      case 3:
        return browserHistory.push(
          `/campaign_builder/${campaign_builder_token}/unit_info`
        );
      case 4:
        return window.location = `/campaigns/${this.props.campaign_token}`;
    }
  }

  nextStep(campaign_builder_token) {
    const { goToStep } = this.props;
    goToStep(this.props.step + 1);
  }

  async onAddOrUpdateUnits(action = null) {
    if (action == "updating") {
      GlobalActions.showMessage("Flights successfully updated");
    } else {
      GlobalActions.showMessage("Units successfully added to the campaign");
    }
  }

  renderStep(campaign_builder_token) {
    const {
      step,
      campaign,
      goToStep,
      units_added,
      campaign_token,
      campaign_dates,
      supplier_face_ids
    } = this.props;
    if (!!!supplier_face_ids && step == 2) return <div />;
    if (!!!units_added && step == 4) return <div />;

    switch (step) {
      case 1:
        return <Form goToStep={goToStep} step={step}/>;
      case 1.5:
        return <Form goToStep={goToStep} step={step} editing={true} units_added={units_added}/>;
      case 2:
        return (
          <AddByMap
            onSubmit={this.onAddOrUpdateUnits.bind(this)}
            supplier_face_ids={supplier_face_ids}
            campaign_token={campaign_token}
            campaign_dates={campaign_dates}
            campaign_builder_token={campaign_builder_token}
            campaign={campaign}
            units_added={units_added}
          />
        );
      case 3:
        return (
          <ReviewUnits
            isBuilderView={true}
            onSubmit={this.onAddOrUpdateUnits.bind(this)}
            supplier_face_ids={supplier_face_ids}
            campaign_dates={campaign_dates}
            campaign_builder_token={campaign_builder_token}
            campaign_token={campaign_token}
            campaign={campaign}
            units_added={units_added}
          />
        );
    }
  }

  renderLoginAsSupplier() {
    return (
      <h5 className="container" style={{ marginTop: 25 }}>
        Please login as supplier to use campaign builder
      </h5>
    )
  }

  render() {
    const { currentUser, campaign_builder, units_added, goToStep, step, campaign } = this.props;
    const campaign_builder_token = campaign_builder
      ? campaign_builder.token
      : null;
    const disable_step_3 =
      !!!campaign_builder_token || !!!units_added || !!!units_added.length;
    const isNotSupplier = !!!currentUser || currentUser.is_guest || currentUser.is_admin;

    if(isNotSupplier) { return this.renderLoginAsSupplier(); }

    return (
      <div
        className="campaign_builder"
        style={{
          background: '#efefef',
          width: '100%',
          minHeight: '1000px',
          padding: '22px 5px',
          marginBottom: '0px'
        }}
      >
        {(step && step !== 1 && step !== 1.5) &&
          <Header
            campaign={campaign}
            goToStep={goToStep}
            units_added={units_added}
            step={step}
          />
        }
        {(step && step !== 1 && step !== 1.5) &&
          <div className="breadcrumbs col-xs-12">
            <BreadCrumbs goToStep={goToStep} step={step} disable_last_step={!!!units_added || units_added.length === 0}/>
          </div>
        }
        <div className="row render_step">
          {this.renderStep(campaign_builder_token)}
        </div>
      </div>
    )
  }
}

export default connect(
  ({
    campaign_builder_ui: {
      step,
      campaign_builder,
      campaign_token,
      units_added,
      campaign_dates,
      supplier_face_ids
    },
    campaign: { campaign },
    currentUser
  }) => ({
    step,
    campaign_builder,
    campaign_token,
    units_added,
    campaign_dates,
    campaign,
    supplier_face_ids,
    currentUser
  }),
  {
    loadBuilderCampaignUnits,
    resetBuilderState,
    loadCampaignData,
    clearCampaign,
    loadCampaign,
    loadFaceIds,
    goToStep
  }
)(CampaignBuilder);
