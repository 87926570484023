import { browserHistory } from 'react-router-legacy';
import React from 'react';
import createClass from 'create-react-class';

import AuthStore from '../../../stores/AuthStore';
import Form from './Form';
import GlobalActions from '../../../actions/GlobalActions';
import Subheader from '../Subheader'
import { get } from '../../../utils/api'

export default createClass({
  displayName: 'Greylabel/CampaignRequest/index',

  getInitialState() {
    const search = location.search;
    const emailFromParams = search ? search.split('email=').pop().split('&').shift() : null;
    const campaign = emailFromParams ? {email: emailFromParams} : {};
    return {
      campaign: campaign,
      errors: {},
      saving: false,
      locations: []
    }
  },

  componentWillMount() {
  },

  componentDidMount() {
    this.loadLocations()
  },

  async loadLocations() {
    const { advertiser } = this.props
    if(advertiser.subdomain === 'compass' || advertiser.subdomain === 'clearchannel') {
      return await get(`/api/v1/locations?type=066&metro_area_id=31035620`)
        .then(locations => this.setState({ locations }))
    }
    return await get(`/api/v1/locations/by_market`)
        .then(locations => this.setState({ locations }))
  },

  onCampaignUpdate(campaign) {
    this.setState({ campaign })
  },

  sendPost() {
    const { campaign } = this.state
    const params = {
      campaign,
      advertiser_subdomain: this.props.advertiser.subdomain,
      complete: true
    }

    if (Array.isArray(campaign.geo_json_features)) {
      campaign.geo_json_features = JSON.stringify(campaign.geo_json_features);
    }

    $.post(`/api/v1/campaign_requests`, params).then(response => {
      window.scrollTo(0, 0)
      if (response.token) {
        browserHistory.push(`/team/campaigns/thanks`)
      } else {
        GlobalActions.showMessage('There was an error saving your campaign request', 'error');
      }
    });
  },

  confirmSubmitModal(event) {
    event.preventDefault();
    const popupOptions = {
      onConfirmSubmit: this.onSubmit,
      headerText: "Confirm campaign details",
      bodyText: "Please double-check your campaign details for accuracy."
    }
    GlobalActions.openPopup('confirm-submit', popupOptions)
  },

  onSubmit() {
    this.setState({saving: true}, () => this.validateAndSubmit())
  },

  emailErrorMessage(error) {
    const message = {
      not_valid: 'Must be a valid email address',
      invalid_domain: 'Must be a valid email address'
    }

    return { email: message[error] }
  },

  isValidEmail(email) {
    const regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([com]{2,4})+$/
    const isEmail = regex.test(email)
    if(!isEmail) { return Promise.reject(this.emailErrorMessage('not_valid')) }
    return Promise.resolve(email)
  },

  isValidEmailDomain(email) {
    const { advertiser } = this.props
    const domain = email.split('@')[1]
    const isValidAdvertiserDomain = domain.includes(advertiser.subdomain, 0)
    const isAdQuickDomain = domain.includes('adquick', 0)
    const isValid = isValidAdvertiserDomain || isAdQuickDomain

    // if(!isValid) {
    //   return Promise.reject(this.emailErrorMessage('invalid_domain'))
    // } else {
    //   return Promise.resolve(email)
    // }

    if (domain == '') {
      return Promise.reject(this.emailErrorMessage('invalid_domain'))
    } else {
      return Promise.resolve(email)
    }
  },

  async validateEmail(address) {
    try {
      const validEmail = await this.isValidEmail(address)
      const isValidDomain = await this.isValidEmailDomain(validEmail)
      isValidDomain && this.cleanFieldFromErrorState('email')
    } catch(errors) {
      this.updateErrorsState(errors)
    }
  },

  updateErrorsState(new_errors, saving = false) {
    const errors = Object.assign({}, this.state.errors, new_errors)
    this.setState({ errors, saving })
  },

  cleanFieldFromErrorState(field) {
    const stateError = this.state.errors
    const clearState = Object.assign({}, this.state.errors, delete stateError[field])
    this.setState({ errors: clearState })
  },

  cleanFieldsFromErrorState(fields) {
    const errors = _.reduce(fields, (clean, value) => {
      Object.assign({}, clean, delete this.state.errors[value])
      return clean
    }, {})
    this.setState({ errors })
  },

  validateAndSubmit() {
    const { campaign } = this.state;
    const { user } = AuthStore.state;
    const errors = {};

    [
      ['email', 'Email'],
      ['start_date', 'Start Date'],
      ['end_date', 'End Date'],
      ['name', 'Campaign Name']
    ].forEach(field => {
      if (!campaign[field[0]]) {
        errors[field[0]] = field[1];
      } else {
        this.cleanFieldFromErrorState(field[0])
      }
    });

    if(campaign.neighborhood == null && !campaign.geo_json_features) {
      errors.tip = "Select a neighborhood OR draw a location in the map"
    } else if (campaign.neighborhood == null) {
      errors.neighborhood = "Select a neighborhood"
    } else if (!campaign.geo_json_features) {
      errors.geo_json = "Draw a location in the map"
    }

    if (campaign.neighborhood || campaign.geo_json_features){
      const locationErrors = ['tip', 'neighborhood', 'geo_json']
      locationErrors.forEach(e => Object.assign({}, errors, delete errors[e]))
      this.cleanFieldsFromErrorState(['tip', 'neighborhood', 'geo_json'])
    }

    if (_.isEmpty(errors)) {
      this.sendPost()
    } else {
      window.scrollTo(0, 0)
    }

    this.updateErrorsState(errors)
  },

  renderHeader() {
    return (
      <Subheader />
    )
  },

  renderForm() {
    return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          {Object.values(this.state.errors).length > 0 && <div>
            <div className="section" style={errorStyle}>
              <b>Please fill out all required fields.</b>
              <ul>
                {Object.values(this.state.errors).map((text, idx) =>
                  <li key={idx}>&bull; {text}</li>
                )}
              </ul>
            </div>
          </div>}
          <Form
            {...this.props}
            style={{margin:'auto'}}
            campaign={this.state.campaign}
            onSubmit={this.confirmSubmitModal}
            onCampaignUpdate={this.onCampaignUpdate}
            saving={this.state.saving}
            errors={this.state.errors}
            locations={this.state.locations}
            validateEmail={this.validateEmail}
          />
        </div>
      </div>
    )
  },

  renderSuccess() {
    return (
      <div>
        <div className="alert alert-success text-center">
          <div><b>Thanks!</b></div>
          <div>We will email options within 48 hours.</div>
        </div>

        <div className="text-center">
          <img src={ASSETS.tina_fey} style={{marginTop:20}}/>
        </div>
      </div>
    )
  },

  render() {
    return (
      <div>
        {this.renderHeader()}
        <div className="container create-campaign" style={{ background: '#E5E5E5', width: '100%', height:'100%', padding: '44px 111px 200px 129px' }}>
          {this.renderForm()}
          <div id="contact-connor">
            Need help? Email support@adquick.com
          </div>
          <img id="contact-connor-img" src="/images/connor.png"></img>
        </div>
      </div>
    )
  }
});

const errorStyle = {
  marginBottom: 20,
  borderColor: 'red',
}
