import React, { Component } from "react";
import validate from 'validate.js';
import { post } from "../../../../utils/api";
import GlobalActions from "../../../../actions/GlobalActions";
import DateRangePicker from '../../../DatePicker/Range';
import Loading from '../../../Loading';

type TDateConstraint = {
  [key: string]: {
    presence: boolean
  }
}

type TConstraintError = {
  [key: string]: Array<String>
}

interface IMassUpdateModalProps {
  unit_ids: Array<String>
  campaign_token: string
  onHide: () => void
  loadCampaignUnits: (token: string) => void
}

interface IMassUpdateModalState {
  constraints: TDateConstraint
  start_date: any
  end_date: any
  errors: TConstraintError
  saving: boolean
}

class MassUpdateDateModal extends Component<IMassUpdateModalProps,IMassUpdateModalState> {
  constructor(props) {
    super(props);
    this.state = {
      constraints: {
        start_date: {
          presence: true,
        },
        end_date: {
          presence: true,
        },
      },
      start_date: null,
      end_date: null,
      errors: {},
      saving: false
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    const { start_date, end_date, errors, saving } = this.state;
    if(saving) { return <Loading />; }
    return (
      <form onSubmit={this.onSubmit}>
        {errors && this.renderErrors()}
        <div>
          <label>Select Dates</label>
        </div>
        <DateRangePicker
          start_date={start_date}
          end_date={end_date}
          onDatesChange={({ start_date, end_date }) => this.onDatesChange(start_date, end_date)}
        />
        <div className="form-group">
          You will be updating {this.props.unit_ids.length} units.
        </div>
        <div className="form-group">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={this.onSubmit}
          >
            Save
          </button>
        </div>
      </form>
    )
  }

  private renderErrors() {
    const { errors } = this.state;
    const errorKeys = Object.keys(errors);
    const formattedErrors = errorKeys.map(key => errors[key]).flat();
    return formattedErrors.map((err,idx) => this.renderError(err, idx));
  }

  private renderError(err, idx) {
    return <p className="message__row" key={`error_${idx}`}>{err}</p>;
  }

  private onDatesChange(start_date, end_date) {
    this.setState({ start_date, end_date });
  }

  private onSubmit(e) {
    e.preventDefault();
    const errors = validate(this.state, this.state.constraints);
    if (errors) { return this.setState({ errors }); }

    return this.updateUnits();
  }

  private async updateUnits() {
    const { campaign_token, unit_ids, onHide, loadCampaignUnits } = this.props;
    const { start_date, end_date } = this.state;

    const payload = {
      campaign_id: campaign_token,
      unit_ids,
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }

    try {
      this.setState({ saving: true });
      await post(`/api/v1/campaign_units/mass_update`, payload);
      await loadCampaignUnits(campaign_token);
      GlobalActions.showMessage("Successfully updated the selected units!");
      onHide();
    } catch (errors) {
      this.setState({ errors, saving: false });
    }
  }
}

export default MassUpdateDateModal;