import React  from "react";
import PropTypes from "prop-types";
import createClass from "create-react-class";

export default createClass({
  displayName: "Signup",

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  getInitialState() {
    const unbranded = this.props.advertiser.name === 'AdQuick';
    return {
      unbranded: unbranded
    }
  },

  componentWillMount() {
    const { validateEmail } = this.props
    this.debouncedValidation = _.debounce(e => {
      validateEmail(this.props.campaign.email)
    }, 300)
 },

 onChange(e) {
   e.persist()
   this.props.setAttributes({ email: e.target.value })
 },

  render() {
    const { advertiser, campaign, errors } = this.props;
    const errorStyle = errors.email ? { color: 'red' } : {};
    const { emailFromParams, unbranded } = this.state;

    return (
      <div>
        <h4>
          <i className="fa fa-envelope-o" />
          <span>Email Address</span>
        </h4>
        <div className="section">
          <div className="row subsection">
            <div className="col-sm-12">
              {unbranded && <span className="section-name" style={errorStyle}>Your email address</span>}
              {!!!unbranded && <span className="section-name" style={errorStyle}>Your @{advertiser.email_domain} email address</span>}
              <input type="email"
                className="form-control"
                value={campaign.email}
                onChange={e => this.onChange(e)}
                onBlur={e => this.debouncedValidation()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
});
