import { browserHistory } from 'react-router-legacy';

export const visitUrl = function (event, url, forceHttp = false) {
  if (event.metaKey) {
    window.open(url);
  } else if (forceHttp) {
    document.location = url;
  } else {
    browserHistory.push(url);
  }
};
