import { connect } from 'react-redux';
import React from 'react'
import { toggleSpendGroup } from '../actions';
import EstimatedResults from './EstimatedResults';
import Plan from './Plan'
import SimpleMap from '../../SimpleMap';

class MarketConfiguration extends React.Component {

  render() {

    const { location, target_impressions, spend } = this.props;
    return (
      <div className="market-configuration-panel" key={this.props.id}>
        <div className="market-configuration-top-box">
          <div className="market-configuration-title">{location.name}</div>
          <a className="market-configuration-remove-link"
            onClick={() => this.props.toggleSpendGroup(this.props.location.id)}>
            - Remove
          </a>
        </div>
        <div className="market-configuration-bottom-box">
          <div className="market-configuration-map-container">
             <SimpleMap width={232} height={194} lat={location.lat} lon={location.lon} radius_in_miles={10}/>
          </div>
          <Plan {...this.props} />
        </div>
        <EstimatedResults location={location} target_impressions={target_impressions} spend={spend} />
      </div>
    )
  }
}

export default connect(
  () => ({}),
  { toggleSpendGroup }
)(MarketConfiguration);
