import React from 'react';
import createClass from 'create-react-class';
import GlobalActions from '../../actions/GlobalActions';
import AuthStore from '../../stores/AuthStore';
import CampaignStore from '../../stores/CampaignStore';

export default createClass({

  getInitialState() {
    return ({
      data: {},
    });
  },

  componentDidMount() {
    this.unsubscribeList = [AuthStore.listen(this.onAuthChange)];
    this.loadCampaign();
  },

  componentWillUnMount() {
    this.unsubscribeList.map(fn => fn());
  },

  onAuthChange(data) {
    const user = AuthStore.state.user;
    // todo: add login and signup buttons that let the user login and request access
  },

  loadCampaign() {
    const _this = this;
    const { campaign_id } = this.props.params

    CampaignStore.preloadCampaign(campaign_id, {}, (data) => {
      _this.setState({ data })
    }, (error) => {
      if (window.Rollbar) Rollbar.error('Error preloading campaign', error, { campaign_id, params })
      console.error('Error preloading campaign', error, { campaign_id, params })
    })
  },

  handleLoginClick() {
    window.location.assign("/login");
  },

  handleSignUpClick() {
    GlobalActions.openPopup('register');
  },

  render() {
    const campaign = this.state.data.campaign || {};

    return (
      <div className="campaign_login">
        <div className="header">
          <img src={ASSETS.logo} />
        </div>
        <div className="box">
          <div className="title_area">
            <div className="vendor_login">
              vendor sign in required
            </div>
            <div className="title">
              {campaign.name}
            </div>
          </div>
          <div className="create_or_login clearfix">
            <div className="lock">
              <i className="fa fa-lock"></i>
            </div>
            <div className="must_be_logged_in">
              You must be logged in respond to the RFP. Please click the link in the email we sent you to automatically log-in.
            </div>
            <div className="text_1">
              Create an Account or Login
            </div>
            <div className="text_2">
              to add or edit units on {campaign.name}
            </div>
            <div className="buttons">
              <button className="btn btn-primary" onClick={this.handleLoginClick}>Login</button>
              <button className="btn btn-primary" onClick={this.handleSignUpClick}>Sign Up</button>
            </div>
          </div>
        </div>
      </div>
    )
  }


});
