import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

class Header extends Component {

  renderHeaderLogo() {
    return (
      <a href='/'>
        <img className="header__logo" src={ASSETS.logo} />
      </a>
    )
  }

  render() {
    return (
      <div id="mobile-header">
        <Navbar.Header>
          <Navbar.Brand>
            {this.renderHeaderLogo()}
          </Navbar.Brand>
        </Navbar.Header>
      </div>
    )
  }
}

const mapStateToProps = null;

export default connect(
  mapStateToProps,
  {}
)(Header)
