import cs from 'classnames';
import Tooltip from "rc-tooltip";
import React, { Component } from 'react';
import { GithubPicker } from "react-color";
import { connect } from 'react-redux';
import GlobalActions from '../../actions/GlobalActions';
import {
  filterUnits
} from '../../actions/UnitsActions';
import { isMobile } from '../../utils/mobile';
import {
  deleteUnitTag, updateUnitTagName, refreshUnitTags
} from '../Campaign/actions';
import { paramsAdd, paramsRemove} from "../../utils/QueryString";

const POPUP_WIDTH = 275;

class TagsFilter extends Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onToggleColorPicker = this.onToggleColorPicker.bind(this);
    this.onToggleEditing = this.onToggleEditing.bind(this);
    this.onOperatorChange = this.onOperatorChange.bind(this);
    this.onExcludingSearch = this.onExcludingSearch.bind(this)
    this.onDone = this.onDone.bind(this);
    this.state = {
      isExpanded: false,
      activeTags: this.props.initialValue || [],
      editing: false,
      editChanges: {},
      displayColorPicker: false,
      currentlyEditing: null,
      unitTags: [],
      useAnd: false,
      tagsNegativeSearch: false
    }
  }

  async componentDidMount() {
    const { standaloneMode } = this.props;
    await this.getUnitTags()
    if (!standaloneMode) document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    const { standaloneMode } = this.props;
    if (!standaloneMode) document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, units } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (units.unit_filter.tags && units.unit_filter.tags !== prevProps.units.unit_filter.tags) {
      this.setState({ activeTags: units.unit_filter.tags })
    }
  }

  handleQueryParams(filter) {
    /* copy pasted this here cause this component it initialized directly on the Sidebar */
    const keys = Object.keys(filter)
    keys.forEach(key => !!filter[key] ? paramsAdd(key, filter[key]) : paramsRemove(key))
  }

  async getUnitTags() {
    this.props.refreshUnitTags()
    this.setState({unitTags: []})
  }

  updateLocalUnit(id, value, field) {
    const campaignTags = _.get(this.props.campaign, 'campaign.unit_tags', [])
    const tags = campaignTags.map(tag => {
      if (tag.id == id) {
        tag[field] = value
        return tag
      }
      return tag
    })
    this.setState({unitTags: tags})
  }

  async onToggle(event) {
    const { isExpanded, editing } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && !editing && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onClear() {
    this.setState({ activeTags: [] }, this.onFilter)
  }

  onTagSelect(tag) {
    const { activeTags } = this.state;
    const isTagActive = !!activeTags.find(at => at.tag === tag.tag && at.color === tag.color)

    if (isTagActive) {
      const activeTagsWithoutSelected = activeTags.filter(at => `${at.color}${at.tag}` !== `${tag.color}${tag.tag}`)
      this.setState({ activeTags: activeTagsWithoutSelected }, this.onFilter);
    } else {
      const activeTagsWithSelected = activeTags.concat([tag])
      this.setState({ activeTags: activeTagsWithSelected }, this.onFilter);
    }
  }

  onFilter() {
    const { useAnd, activeTags, tagsNegativeSearch } = this.state;
    const { filterUnits } = this.props;

    _.defer(filterUnits, { tags: activeTags, useAnd, tagsNegativeSearch });
    this.handleQueryParams({ 'tags[]': this.handleActiveQueryString().map(t => JSON.stringify(t)), useAnd, tagsNegativeSearch })
  }

  handleActiveQueryString() {
    const { activeTags } = this.state
    return activeTags.map(t => Object.assign({}, {color: t.color, tag: t.tag} ))
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  async onUpdateTag(e, id) {
    e.preventDefault();
    const { updateUnitTagName, campaign } = this.props;
    const tags = this.state.unitTags
    const oldTag = _.get(campaign, 'campaign.unit_tags', []).find(t => t.id == id).oldTag

    const {tag, color} = tags.find(t => t.id == id)

    if (confirm(`Are you sure you want to update the tag ${oldTag}?`)) {
      await updateUnitTagName({tag: tag, color: color}, oldTag);
      this.exitEditing(); // Clear changes so we can close the edit window
      GlobalActions.showMessage(`Tag successfully updated`);
    }
  }

  async onDeleteTag(e, tag) {
    e.preventDefault();
    const { deleteUnitTag } = this.props;

    if (confirm(`Are you sure you want to delete the tag ${tag}?`)) {
      try {
        await deleteUnitTag(tag);
        this.exitEditing();
        GlobalActions.showMessage(`Tag ${tag} was successfully deleted`);
      } catch (e) {
        GlobalActions.showError(e);
      }
    }
  }

  onToggleColorPicker(e, id) {
    e.preventDefault();
    this.setState({ displayColorPicker: !this.state.displayColorPicker, currentlyEditing: id })
  }

  async onToggleEditing(e) {
    const { editing } = this.state;
    const isEditing = this.state.unitTags.some(t => t.tag !== t.oldTag || t.color !== t.oldColor)
    if(!isEditing || confirm('You have unsaved changes. Are you sure you want to discard them?')) {
      let doneEditing = false;
      if (editing) {
        doneEditing = true;
      }
      this.setState({ editing: !editing })
      if (doneEditing) {
        await this.exitEditing()
      }
    }
  }

  onOperatorChange(e) {
    const { useAnd } = this.state
    this.setState({ useAnd: !useAnd }, this.onFilter);
  }

  onExcludingSearch(e) {
    const { tagsNegativeSearch } = this.state;
    this.setState({ tagsNegativeSearch: !tagsNegativeSearch }, this.onFilter);
  }

  async exitEditing() {
    await this.getUnitTags()
  }

  getButtonLabel() {
    const { activeTags } = this.state;
    if (_.isEmpty(activeTags)) return 'Tags';
    return (
      <span>
        <i className="fa fa-tag"/>
        {activeTags.length == 1 ? _.truncate(activeTags[0].tag, {length: 18}) : `Tags · ${activeTags.length}`}
      </span>
    )
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { activeTags, editing, displayColorPicker, currentlyEditing, editChanges, useAnd, tagsNegativeSearch } = this.state;
    const { campaign, standaloneMode } = this.props;
    const { permissions } = campaign;
    let tags
    if (this.state.unitTags.length > 0) {
      tags = this.state.unitTags
    } else {
      tags = _.get(campaign, 'campaign.unit_tags', [])
    }
    const classNames = cs({
      filter_popup: !standaloneMode,
      tags_popup: !standaloneMode,
      standalone_tags: !!standaloneMode,
      editing,
    })
    const styles = !standaloneMode ? { [this.getPopupPosition()]: '-1px' } : {};
    return (
      <div className={classNames} style={styles}>
        {standaloneMode && (
          <div className="sidebar-tags-heading flex-space-between flex-container-align-center">
            <label>Tags</label>
            <section className="ui">
              <SideBySideButtons labelLeft="exc" labelRight="inc" isOn={tagsNegativeSearch} onClick={this.onExcludingSearch} />
              <Tooltip
                placement="bottom"
                overlayStyle={{ width: '250px', height: "200px"}}
                overlay={
                  <span style={{ width: "fit-content" }}>
                    <p>
                      <span className="emphasis">EXC</span> shows units that exclude the selected tag.
                    </p>
                    <p>
                      <span className="emphasis">INC</span> only shows units that include the  selected tag.
                    </p>
                  </span>}
              >
                <i className="fal fa-question-circle question-mark"></i>
              </Tooltip>
              <SideBySideButtons labelLeft="and" labelRight="or" isOn={useAnd} onClick={this.onOperatorChange} />
              <Tooltip
                placement="bottom"
                overlayStyle={{ width: '250px', height: "200px"}}
                overlay={
                  <span style={{ width: "fit-content" }}>
                    <p>
                      <span className="emphasis">AND</span> only shows units that meet every tag (more restrictive).
                    </p>
                    <p>
                      <span className="emphasis">OR</span> shows units that meet any tag (less restrictive).
                    </p>
                  </span>}
              >
                <i className="fal fa-question-circle question-mark"></i>
              </Tooltip>
            </section>
          </div>
        )}
        {permissions.can_edit_tags && !isMobile() && <div className="padded_content">
          <p className="edit">
            {editing &&
              <a onClick={this.onToggleEditing}>Done</a>
            }
            {!editing &&
              <Tooltip
              placement="bottom"
              trigger={['hover']}
              overlayStyle={{ width: '200px', height: "200px"}}
              overlay={<span style={{ width: "fit-content" }}>Delete, rename or change color of tags.</span>}
              >
                <a onClick={this.onToggleEditing}>Edit</a>
              </Tooltip>
            }
          </p>
        </div>}
        <h4>TAGS</h4>
        {!editing && <ul className="list_with_color_swatches">
          {tags.map((tag, idx) => {
            const isActive = !!activeTags.find(at => at.tag === tag.tag && at.color === tag.color)
            return <li
              key={idx}
              onClick={() => this.onTagSelect(tag)}
              className={cs({active: isActive})}
              title={tag.tag}
              style={{'--bgcolor': tag.color || '#4A90E2'}}
              >
                {_.truncate(tag.tag, {length: standaloneMode ? 20 : 32})}
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>}
        {editing && <ul>
          {tags.map((tag, idx) => {
            const color = _.get(editChanges, [tag.id, 'color'], tag.color);
            return (
              <li key={idx}>
                <a className="swatch" style={{backgroundColor: color}} onClick={e => this.onToggleColorPicker(e, tag.id)}>
                  {displayColorPicker && currentlyEditing === tag.id && <div className="color_popover">
                    <GithubPicker
                      color={color}
                      onChangeComplete={(color) => this.updateLocalUnit(tag.id, color.hex, 'color' )}
                      colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#EB144C', '#F78DA7']}
                    />
                  </div>}
                </a>
                <input value={tag.tag} onChange={(e) => this.updateLocalUnit(tag.id, e.target.value, 'tag' )} type="text"/>
                <a onClick={e => this.onUpdateTag(e, tag.id)}><i className="fa fa-check-circle"/></a>
                <a onClick={e => this.onDeleteTag(e, tag.tag)}><i className="fa fa-times-circle"/></a>
            </li>
          )})}
        </ul>}
        {(standaloneMode && !!activeTags.length) &&
          <div className="actions"><a className="clear hidden-xs" onClick={e => this.onClear()}>Clear</a></div>
        }
        {!editing && !standaloneMode && <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>}
      </div>
    )
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive, standaloneMode, campaign } = this.props;
    const buttonLabel = this.getButtonLabel();
    const tags = _.get(campaign, 'campaign.unit_tags', [])
    if (!tags.length) return <li ref={node => this.node = node} className="empty"/>;
    if (standaloneMode) return this.renderPopup();
    return (
      <li ref={node => this.node = node} className={cs({active: isActive || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
}

class SideBySideButtons extends Component {
  render() {
    const { labelLeft, labelRight, onClick, isOn} = this.props;

    return (
      <div className="toggle_buttons" onClick={onClick}>
        <button className={cs({active: isOn})}>{labelLeft}</button>
        <button className={cs({active: !isOn})}>{labelRight}</button>
      </div>
    )
  }
}

const mapStateToProps = ({ campaign, units }) => ({
  campaign, units,
});

export default connect(
  mapStateToProps,
  { filterUnits, updateUnitTagName, deleteUnitTag, refreshUnitTags }
 )(TagsFilter)
