import React from 'react';
import cs from 'classnames';
const POPUP_WIDTH = 220;
const SORT_OPTIONS = [
  { label: 'Face ID',
     short_label: 'Face ID',
     value: 'supplier_face_id' },
  { label: 'CPM: Low to High',
    short_label: 'CPM',
    value: 'cpm' },
  { label: 'CPM: High to Low',
    short_label: 'CPM',
    value: '-cpm',
    descending: true },
  { label: 'Price: Low to High',
    short_label: 'Price',
    value: 'week_total_cost' },
  { label: 'Price: High to Low',
    short_label: 'Price',
    value: '-week_total_cost',
    descending: true },
  { label: 'Impressions: Low to High',
    short_label: 'Impressions',
    value: 'week_total_impressions' },
  { label: 'Impressions: High to Low',
    short_label: 'Impressions',
    value: '-week_total_impressions',
    descending: true }
];

export default class Sort extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      sortedBy: {
        label: 'Face ID',
        short_label: 'Face ID',
        value: 'supplier_face_id'
      }
    };

    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.sortBy === null) {
      this.setState({
        sortedBy: {
          label: 'Face ID',
          short_label: 'Face ID',
          value: 'supplier_face_id'
        }
      });
    }
  }

  onSortBy(option) {
    const { setFilter } = this.props;
    this.setState({ sortedBy: option }, () => {
      setFilter('sortBy', option.value);
    });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    if (this.node.contains(event.target)) {
      this.setState({ isExpanded: !isExpanded })
    } else if (isExpanded) {
      this.setState({ isExpanded: false })
    }
  }

  onClear() {
    const { setFilter } = this.props;
    this.setState({ sortedBy: null }, () => {
      setFilter('sortBy', null);
    });
  }

  getButtonLabel() {
    const { sortedBy } = this.state;
    if (!sortedBy) return 'Sort By…';
    return `Sort by ${sortedBy.short_label}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { sortedBy } = this.state;

    return (
      <div className="filter_popup sort_by_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <ul>
          {SORT_OPTIONS.map((option) => {
            const onClick = () => {
              this.onSortBy(option);
            }
            const isActive = sortedBy == option;
            return <li
              key={option.value}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {option.label}
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { isExpanded, sortedBy } = this.state;
    const descending = sortedBy && sortedBy.descending;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs('sort_by', {active: sortedBy || isExpanded, descending: !!descending})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};
