import React from 'react';
import { browserHistory } from 'react-router-legacy';
import createClass from 'create-react-class';
import AuthStore from '../../../stores/AuthStore';
import PropTypes from "prop-types";

import Signup from './Signup'
import Details from './Details'
import Locations from './Locations'
import Metrics from './Metrics'
import MobileAds from './MobileAds'
import Sms from './Sms'
import Surveys from './Surveys'
import AdditionalNotes from './AdditionalNotes'

export default createClass({
  displayName: 'Campaign/Create/Form',

  propTypes: {
    campaign: PropTypes.object,
    onCampaignUpdate: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      user: AuthStore.getState().user,
    };
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];
  },

  onAuthChange() {
    this.setState({ user: AuthStore.getState().user });
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  setAttributes(attributes) {
    const campaign = { ...this.props.campaign, ...attributes }
    this.props.onCampaignUpdate(campaign)
  },

  render() {
    const { user } = this.state;
    const { campaign, errors, validateEmail } = this.props

    return (
      <form className="campaign-request-form" onSubmit={this.onSubmit}>
        <div className="row form-group">
          <div className="col-sm-12">
            <Signup
              {...this.props}
              campaign={campaign}
              errors={errors}
              setAttributes={this.setAttributes}
              validateEmail={validateEmail}
            />
          </div>
          <div className="col-sm-12" style={{marginTop: 30}}>
            <Details
              campaign={campaign}
              errors={errors}
              setAttributes={this.setAttributes}
            />
          </div>
          <div className="col-sm-12" style={{marginTop: 30}}>
            <Locations
              locations={this.props.locations}
              campaign={campaign}
              errors={errors}
              setAttributes={this.setAttributes}
            />
          </div>
          <div className="col-sm-12" style={{marginTop: 30}}>
            <Metrics
              campaign={campaign}
              errors={errors}
              setAttributes={this.setAttributes}
            />
          </div>
          {campaign.mobile_ads &&
            <div className="col-sm-12" style={{marginTop: 30}}>
              <MobileAds
                campaign={campaign}
                errors={errors}
                setAttributes={this.setAttributes}
              />
            </div>
          }
          {campaign.surveys &&
            <div className="col-sm-12" style={{marginTop: 30}}>
              <Surveys
                campaign={campaign}
                errors={errors}
                setAttributes={this.setAttributes}
              />
            </div>
          }
          {campaign.sms &&
            <div className="col-sm-12" style={{marginTop: 30}}>
              <Sms
                campaign={campaign}
                errors={errors}
                setAttributes={this.setAttributes}
              />
            </div>
          }
          <div className="col-sm-12" style={{marginTop: 30}}>
            <AdditionalNotes
              campaign={campaign}
              errors={errors}
              setAttributes={this.setAttributes}
            />
          </div>
          <div className="col-sm-12" style={{marginTop: 30}}>
            <input
              type="submit"
              className="btn btn-primary btn-submit"
              onClick={this.props.onSubmit}
              disabled={this.props.saving}
              value="Save & Continue"
            />
          </div>
        </div>
      </form>
    );
  },
});
