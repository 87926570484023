import * as React from 'react';
import {
  fetchMonthOccupancyData,
  fetchQuarterOccupancyData,
  fetchYearOccupancyData
} from './actions';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { LineChart } from 'react-chartkick'
import AllOccupancyChart from './AllOccupancyChart';

class OccupancyCharts extends React.Component<any, any> {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier)
    }
  }

  fetchData(supplier) {
    const {
      fetchMonthOccupancyData,
      fetchQuarterOccupancyData,
      fetchYearOccupancyData,
    } = this.props;
    fetchMonthOccupancyData(supplier);
    fetchQuarterOccupancyData(supplier);
    fetchYearOccupancyData(supplier);
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner">
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    )
  }

  renderMonthOccupancyTab() {
    const { loading_month_occupancy, month_occupancy } = this.props;

    return (
      <Tab eventKey="month" title="This Month">
        {(!!!loading_month_occupancy && month_occupancy && Object.keys(month_occupancy).length > 0) &&
          <LineChart
            data={month_occupancy}
            suffix="%"
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_month_occupancy && this.renderLoadingSpinner()}
      </Tab>
    );
  }

  renderQuarterOccupancyTab() {
    const { loading_quarter_occupancy, quarter_occupancy } = this.props;

    return (
      <Tab eventKey="quarter" title="This Quarter">
        {(!!!loading_quarter_occupancy && quarter_occupancy && Object.keys(quarter_occupancy).length > 0) &&
          <LineChart
            data={quarter_occupancy}
            suffix="%"
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_quarter_occupancy && this.renderLoadingSpinner()}
      </Tab>
    );
  }

  renderYearOccupancyTab() {
    const { loading_year_occupancy, year_occupancy } = this.props;

    return (
      <Tab eventKey="year" title="This Year">
        {(!!!loading_year_occupancy && year_occupancy && Object.keys(year_occupancy).length > 0) &&
          <LineChart
            data={year_occupancy}
            suffix="%"
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_year_occupancy && this.renderLoadingSpinner()}
      </Tab>
    );
  }

  renderAllOccupancyTab() {
    return (
      <Tab eventKey="all" title="All Time">
        <AllOccupancyChart />
      </Tab>
    )
  }

  render() {
    return (
      <div id="occupancy_chart">
        <div className="chart_header">
          Occupancy
        </div>
        <div className="chart_area">
          <Tabs defaultActiveKey="month" id="uncontrolled-tab-example">
            {this.renderMonthOccupancyTab()}
            {this.renderQuarterOccupancyTab()}
            {this.renderYearOccupancyTab()}
            {this.renderAllOccupancyTab()}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: {
      month_occupancy,
      quarter_occupancy,
      year_occupancy,
      loading_month_occupancy,
      loading_quarter_occupancy,
      loading_year_occupancy,
      selected_supplier
    }
  }) => ({
    month_occupancy,
    quarter_occupancy,
    year_occupancy,
    loading_month_occupancy,
    loading_quarter_occupancy,
    loading_year_occupancy,
    selected_supplier
  }),
  {
    fetchMonthOccupancyData,
    fetchQuarterOccupancyData,
    fetchYearOccupancyData,
  }
)(OccupancyCharts);
