import * as React from "react";
import styled from "styled-components";
import Theme from "../../../theme";

export interface ILabelProps {
  children: React.ReactNode | string;
}

export const Label = ({ children }: ILabelProps) => {
  return <LabelWrapper>{children}</LabelWrapper>;
};

export const LabelWrapper = styled.label`
  display: block;
  font-weight: ${Theme.TEXT_LABEL.fontWeight};
  font-size: ${Theme.TEXT_LABEL.fontSize};
  line-height: ${Theme.TEXT_LABEL.lineHeight};
`;

export default Label;
