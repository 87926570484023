import React, { Component } from "react";
import accounting from 'accounting';
import createClass from 'create-react-class';
import moment from 'moment';
import {
  impressionsTotal,
  advertiserFeeData
} from '../../../utils/campaignGeopathCalculator';
import { connect } from "react-redux";

class Summary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showTotal: true
    };
    this.pricePerDay = this.pricePerDay.bind(this);
    this.priceForDuration = this.priceForDuration.bind(this);
    this.daysReserved = this.daysReserved.bind(this);
    this.impressionsForDuration = this.impressionsForDuration.bind(this);
    this.impressionsPerDay = this.impressionsPerDay.bind(this);
    this.unitQuantity = this.unitQuantity.bind(this);
  }

  priceForDuration(unit, type){
    const reserved = this.daysReserved(unit)
    const perDay = this.pricePerDay(unit)
    const unitQuantity = this.unitQuantity(unit)
    return this.daysReserved(unit) * this.pricePerDay(unit) * this.unitQuantity(unit);
  }

  pricePerDay(unit) {
    if(unit.price) {
      return unit.price / 4.0 / 7.0;
    } else {
      return 0;
    }
  }

  unitQuantity(unit) {
    const spots = unit.quantity && unit.quantity > 0 ? unit.quantity : 1;
    return spots;
  }

  daysReserved(unit){
    if (
      unit.start_date &&
      unit.end_date &&
      unit.start_date.format &&
      unit.end_date.format &&
      unit.start_date.format() === unit.end_date.format()
    ) {
      return 1;
    }
    if (unit.start_date && unit.end_date && new Date(unit.start_date) < new Date(unit.end_date)) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(unit.start_date);
      const secondDate = new Date(unit.end_date);
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
      return diffDays;
    }
    return 0;
  }

  pricingData(units_added) {
    const monthPrice = units_added.map(unit => (unit.price || 0) * (unit.quantity > 0 ? unit.quantity : 1)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const weekPrice = monthPrice / 4;
    const cost = weekPrice * 4;
    const price = parseFloat(cost);
    const totalPrice = units_added.map(unit => this.priceForDuration(unit, 'price')).reduce((a, b) => a + b, 0);
    return { monthPrice, weekPrice, cost, price, totalPrice };
  }

  prodInstallData(units_added) {
    const production = units_added.map(unit => (unit.production_cost || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const installation = units_added.map(unit => (unit.installation_cost || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const production_install = parseFloat(installation) + parseFloat(production);
    return { production, installation, production_install };
  }

  impressionsForDuration(unit) {
    return parseInt(this.impressionsPerDay(unit) * this.daysReserved(unit));
  }

  impressionsPerDay(unit) {
    if (!unit.impressions) return 0;
    return unit.impressions / 4.0 / 7.0;
  }

  impressionData(units_added, price, totalPrice) {
    const monthImpressions = units_added.map(unit => (unit.impressions || 0)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const weekImpressions = monthImpressions / 4;
    const impressions = weekImpressions * 4;
    const totalImpressions = units_added.map(unit => this.impressionsForDuration(unit)).reduce((a, b) => a + b, 0);
    const cpm = impressions > 0 && price > 0 ? price / (impressions / 1000) : 0;
    const totalCpm = totalImpressions > 0 && totalPrice > 0 ? totalPrice / (totalImpressions / 1000) : '';
    return { monthImpressions, weekImpressions, impressions, totalImpressions, cpm, totalCpm };
  }

  calculateTotals() {
    const units_added = this.props.units_added || [];
    const filtered_units = units_added.filter(n => n);

    const { monthPrice, weekPrice, cost, price, totalPrice } = this.pricingData(filtered_units);
    const { production, installation, production_install } = this.prodInstallData(filtered_units);
    const { monthImpressions, weekImpressions, impressions, totalImpressions, cpm, totalCpm } = this.impressionData(filtered_units, price, totalPrice);
    const only4week = totalPrice <= 0;

    return { only4week, price, totalPrice, impressions, totalImpressions, cpm, totalCpm, production_install };
  }

  renderImpressions(impressions4week, totalImpressions, only4week) {
    const units_added = this.props.units_added || [];
    const filtered_units = units_added.filter(n => n);
    const { showTotal } = this.state;
    const message = (this.state.showTotal && !only4week) ? "Total Impressions" : "4 Week Impressions";
    const count = impressionsTotal(this.state.showTotal, impressions4week, totalImpressions, only4week);

    return (
      <div>
        <div className="detail-amount">
          {filtered_units.length > 0 ?
            accounting.format(count) :
            <span style={{color:'gray'}}>−</span>
          }
        </div>
        <div className="detail-name">{message}</div>
      </div>
    );
  }

  renderPrice(price) {
    const units_added = this.props.units_added || [];
    const filtered_units = units_added.filter(n => n);
    return (
      <div>
        <div className="detail-amount">
          {filtered_units.length > 0 ?
            accounting.formatMoney(price) :
            <span style={{color:'gray'}}>−</span>
          }
        </div>
      </div>
    );
  }

  aggregateCPM(cpm, totalCpm, only4week) {
    if (this.state.showTotal && !only4week) {
      return accounting.formatMoney(totalCpm)
    } else {
      return accounting.formatMoney(cpm)
    }
  }

  renderPriceInfoTooltip(price, calculatedFee, production_install) {
    const { showPriceInfoTooltip } = this.state;
    const feeAsPercentage = "3.9";
    return (
      <div className={showPriceInfoTooltip ? "price-info-tooltip" : "price-info-tooltip "}>
        <p className="price-info-tooltip-detail">Unit Costs<span className="right">{accounting.formatMoney(price)}</span></p>
        <p className="price-info-tooltip-detail">3.9%Fee<span className="right">+ {accounting.formatMoney(calculatedFee)}</span></p>
        <p className="price-info-tooltip-detail">Production & Install<span className="right">+ {accounting.formatMoney(production_install)}</span></p>
      </div>
    )
  }

  render() {
    const { units_added } = this.props;
    const filtered_units = units_added.filter(n => n);
    const { only4week, price, totalPrice, impressions, totalImpressions, cpm, totalCpm, production_install } = this.calculateTotals();
    const { calculatedFee } = advertiserFeeData(totalPrice, price, production_install, this.state.showTotal);

    if (filtered_units && filtered_units.length > 0){
      return (
        <div id="vendor-summary-area">
          <div className="campaign-details" style={{minHeight: 80}}>
            <div className="col-md-4 cost" style={price == 0 ? { height: '80px' } : {}}>
              <div className="campaign-border">
                <div>{this.renderPrice(totalPrice)}</div>
              </div>
              <div className="detail-cmt">
                <span className="additional-cost-area">{`+ ${accounting.formatMoney(production_install)} production & install`}</span>
              </div>
            </div>
            <div className="col-md-4 prod-install" style={price == 0 ? { height: '80px' } : {}}>
              <div className="campaign-border">
                {this.renderImpressions(impressions, totalImpressions, only4week)}
              </div>
              <div className="detail-cmt" />
            </div>
            <div className="col-md-4" style={price == 0 ? { height: '80px' } : {}}>
              <div className="detail-amount">
                {this.aggregateCPM(cpm, totalCpm, only4week)}
              </div>
              <div className="detail-name">Aggregate CPM</div>
              <div className="detail-cmt" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div></div>
      )
    };
  }

};

export default connect(
  ({
    campaign_builder_ui: { units_added }
  }) => ({ units_added }), {}
)(Summary);
