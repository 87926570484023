import * as React from "react";
import Plus from "@adquick/icons/fa/FaPlus";
import Times from "@adquick/icons/fa/FaTimes";
import styled from "styled-components";

interface MenuItemProps {
  title?: string;
  titleIcon?: string;
  included: boolean;
  id: string;
  onClickHandler: (id: string, included: boolean) => any;
}

const checkIcon = (included: boolean): React.ReactNode => (included ? <Times /> : <Plus />);

export default class CheckableLineItem extends React.Component<MenuItemProps, any> {
  private contentRef: React.Ref<any>;

  constructor(props: MenuItemProps) {
    super(props);
    this.state = { included: true };
    this.onClick = this.onClick.bind(this);
  }

  public componentDidMount(): void {
    this.contentRef = React.createRef();
  }

  public render(): React.ReactElement {
    const { title, included, id } = this.props;

    return (
      <Wrapper included={included} onClick={(e): void => this.onClick(id, included)}>
        <ContentWrapper>
          <Title included={included}>{title}</Title>
          <RightIconWrapper>{checkIcon(included)}</RightIconWrapper>
        </ContentWrapper>
      </Wrapper>
    );
  }

  private onClick(id: string, included: boolean): void {
    const { onClickHandler } = this.props;
    this.setState({ included: !!!this.state.included }, onClickHandler(id, included));
  }
}

interface Wrapper {
  included: boolean;
}

const Wrapper = styled.div<Wrapper>`
  cursor: pointer;
  min-height: 32px;
  width: 285px;
  border-radius: 4px;
  padding: 0 10px;
  margin: 10px 0;
  position: relative;
  align-items: center;
  margin-left: 10px;
  ${({ included }): string =>
    included
      ? `
    background: rgba(74, 144, 226, 0.06);
  `
      : `background: #FFF;`}
`;

const Title = styled.h1<Wrapper>`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  display: inline-block;
  margin: 0;
  margin-left: 0;
  max-width: 247px;
  ${({ included }): string =>
    included
      ? `
    color: #4a90e2;
  `
      : `color: #858585;`}
`;

const RightIconWrapper = styled.span`
  float: right;
  transform: translateY(2px);
  height: 14px;
  width: 14px;
  font-size: 14px;
  margin-top: 1px;
  color: var(--primary);
`;

const ContentWrapper = styled.div`
  padding-top: 5px;
`;
