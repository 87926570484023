import { Button } from "@adquick/ui";
import FaShoppingCart from "@adquick/icons/fa/FaShoppingCart";
import React, { useEffect, useState, useReducer } from "react";
import { browserHistory } from 'react-router-legacy';


type Props = {
  token: string;
};

const EmptyCart = (props: Props) => {
  const { token } = props;
  const handleExploreClick = (): void => {
    window.location.href = `/campaigns/${token}`;
  };
  return (
    <div className="empty-units">
      <FaShoppingCart />
      <div className="empty-units-title">Your Cart is Empty</div>
      <div className="empty-units-msg"> Add inventory to your cart to build your campaign</div>
      <Button onClick={handleExploreClick}>Explore Campaign Units</Button>
    </div>
  );
};

export default EmptyCart;
