import CampaignDataLayerModel from "../../../models/CampaignDataLayerModel";

interface HighlightedAreasDataLayer {
  highlighted_areas: any
}

class HighlightedAreasDataLayer extends CampaignDataLayerModel {

  constructor(highlighted_areas) {
    super({ id:  null, type: "HighlightedRegion" });
    this.highlighted_areas = highlighted_areas;
  }

  public dataLayer() {
    return { areas: this.featureCollection() };
  }

  public featureCollection() {
    const mapAreas = (highlighted_area) => {
      if (!highlighted_area.areas) {
        return []
      }
      return highlighted_area.areas.map(area => ({
        type: 'Feature',
        properties: {
          ...area,
          opacity: 0.4,
          fillColor: highlighted_area.color,
          color: highlighted_area.color,
        },
        geometry: {
          coordinates: area.coordinates,
          type: 'MultiPolygon',
        }
      }))
    };

    return {
      type: 'FeatureCollection',
      features: this.highlighted_areas.map(mapAreas).flat()
    };
  }
}

export default HighlightedAreasDataLayer;
