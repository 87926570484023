import moment from "moment";
// @ts-ignore
import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import { fetchExpiringHolds, hideExpiringHoldsModal, resetDashboard } from "./actions";

const ASSETS = (window as any).ASSETS;

class ExpiringHoldsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.onHide = this.onHide.bind(this);
    this.renderExpiringHold = this.renderExpiringHold.bind(this);
    this.renderHeaders = this.renderHeaders.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier, show_expiring_holds_modal, fetchExpiringHolds } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      fetchExpiringHolds(selected_supplier);
    }
    if (!!!prevProps.expiring_holds && !!!prevProps.show_expiring_holds_modal && show_expiring_holds_modal) {
      fetchExpiringHolds(selected_supplier);
    }
  }

  onHide() {
    const { hideExpiringHoldsModal } = this.props;
    hideExpiringHoldsModal();
  }

  componentWillUnmount() {
    const { resetDashboard } = this.props;
    resetDashboard();
  }

  onRowClick(expiring_hold) {
    window.location.href = `/inventory?query=${expiring_hold.supplier_face_id}`;
  }

  renderExpiringHold(expiring_hold) {
    return (
      <div key={expiring_hold.supplier_face_id} className="item_row" onClick={() => this.onRowClick(expiring_hold)}>
        <div className="section">
          <div className="mobile-only-col-name">Face ID:</div>
          <div className="face_id_col">{expiring_hold.supplier_face_id}</div>
        </div>

        <div className="campaign_section">
          <div className="mobile-only-col-name">Campaign Name:</div>
          <div className="campaign_name_col">{expiring_hold.campaign_name}</div>
        </div>

        <div className="section">
          <div className="mobile-only-col-name">Start Date:</div>
          <div className="start_date_col">
            {expiring_hold.start_date && moment(expiring_hold.start_date).format("MM/DD/YYYY")}
          </div>
        </div>

        <div className="section">
          <div className="mobile-only-col-name">End Date:</div>
          <div className="end_date_col">
            {expiring_hold.end_date && moment(expiring_hold.end_date).format("MM/DD/YYYY")}
          </div>
        </div>

        <div className="section">
          <div className="mobile-only-col-name">Hold Exp Date:</div>
          <div className="hold_expires_at_col">
            {expiring_hold.hold_expires_at && moment(expiring_hold.hold_expires_at).format("MM/DD/YYYY")}
          </div>
        </div>

        <div className="view_unit_link_col">
          <a href={`/inventory?face_id=${expiring_hold.supplier_face_id}`}>View Unit</a>
        </div>
      </div>
    );
  }

  renderHeaders() {
    return (
      <div className="item_headers">
        <div className="col_header">Face ID</div>
        <div className="campaign_col_header">Campaign Name</div>
        <div className="col_header">Start Date</div>
        <div className="col_header">End Date</div>
        <div className="col_header">Hold Exp Date</div>
      </div>
    );
  }

  render() {
    const { show_expiring_holds_modal, expiring_holds, loading_expiring_holds } = this.props;

    return (
      <Modal show={show_expiring_holds_modal} onHide={this.onHide} className="expiring_holds_modal">
        <Modal.Header>
          <div className="header-area">
            <div className="modal-header-text">Expiring Holds</div>
            <div className="close-modal-area">
              <div className="close-modal" onClick={this.onHide}>
                Close
                <img className="close-modal-x" src={ASSETS["x"]} style={{ float: "right" }} />
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {loading_expiring_holds && (
            <div className="loading-spinner">
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          )}
          <div className="item_area">
            {expiring_holds && this.renderHeaders()}
            {expiring_holds &&
              expiring_holds.map(expiring_hold => {
                return this.renderExpiringHold(expiring_hold);
              })}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ vendor_dashboard: { show_expiring_holds_modal, expiring_holds, selected_supplier, loading_expiring_holds } }) => ({
    show_expiring_holds_modal,
    expiring_holds,
    selected_supplier,
    loading_expiring_holds,
  }),
  {
    fetchExpiringHolds,
    hideExpiringHoldsModal,
    resetDashboard,
  },
)(ExpiringHoldsModal);
