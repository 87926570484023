import React from 'react'
import mapboxgl from 'mapbox-gl';
import turf from '@turf/turf';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';

const accessToken = AppConfig.mapboxAccessToken;

export default class SimpleMap extends React.Component {
  constructor(props) {
    super(props);

    mapboxgl.accessToken = accessToken;
    this.setAttributes = this.props.setAttributes;
    this.html_id = `simple-map-${btoa(Math.random()).substr(5, 5)}`;
    this.showMapNotification = this.props.showMapNotification;
    this.campaign_area = this.props.campaign_area;
  }

  componentDidMount() {
    const { zip_code, metro_area, campaign_area, point_coordinates } = this.props;
    this.createMap();

    this.mapbox.on("load", () => {
      this.mapLoaded = true;

      if (this.hasCoordinates()) {
        this.addGeoJsonLayer();
      }
      if (campaign_area) {
        this.addMapAreaLayer();
      }
      if (point_coordinates) {
        this.addPoint();
      }
    });

    this.mapbox.on("draw.create", this.onGeoJsonChange.bind(this));
  }

  hasCoordinates() {
    const { zip_code, metro_area } = this.props;
    return (zip_code && zip_code.coordinates) || (metro_area && metro_area.coordinates);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.campaign_area) {
      this.removeMapAreaLayer();
    }

    if (this.props.campaign_area) {
      this.campaign_area = this.props.campaign_area;
      this.addMapAreaLayer();
    }
  }

  removeMapAreaLayer() {
    this.campaign_area.area.features.forEach(feature => {
      if (this.mapbox.getLayer(feature.id)) this.mapbox.removeLayer(feature.id);
      if (this.mapbox.getSource(feature.id)) this.mapbox.removeSource(feature.id);
    });
  }

  addMapAreaLayer() {
    if (!this.campaign_area || !this.campaign_area.area || !this.campaign_area.area.features) {
      return;
    }
    if (!this.mapLoaded) {
      return;
    }

    const features = this.campaign_area.area.features;

    features.forEach(feature => {
      const layer = {
        id: feature.id,
        type: "fill",
        source: {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: feature.geometry,
          },
        },
        layout: {},
        paint: {
          "fill-color": "#007a2a",
          "fill-opacity": 0.5,
        },
      };
      this.mapbox.addLayer(layer);
    });
  }

  onGeoJsonChange() {
    const features = this.mapbox.mapDrawer.getAll().features;
    this.setAttributes({ geo_json_features: features });
    if (this.showMapNotification) {
      this.showMapNotification();
    }
  }

  createMap() {
    const {
      disableScrollZoom,
      location,
      zip_code,
      metro_area,
      renderDrawTool,
      showZoomControls,
      bounds,
      nonInteractive,
      coordinates,
    } = this.props;

    this.mapbox = new mapboxgl.Map({
      container: this.html_id,
      style: this.props.mapStyle || "mapbox://styles/fahimf/cjy0g4rbh0y1d1cledi9pf3nk",
      center: [this.props.lon, this.props.lat],
      zoom: this.props.zoom ? this.props.zoom : 8,
      interactive: this.props.nonInteractive ? false : true,
    });
    if (this.props.pinCenter) {
      this.pinCenter(this.mapbox);
    }
    if (disableScrollZoom) {
      this.mapbox.scrollZoom.disable();
    }
    const generalLocation = location || zip_code || metro_area;
    const coords = generalLocation && generalLocation.coordinates;
    if (coords) {
      this.mapbox.coordinates = coords.sort((a, b) => {
        return a.length < b.length ? 1 : -1;
      })[0];
    }

    if (renderDrawTool) {
      this.mapbox.mapDrawer = this.createDrawControls();
    }
    if (showZoomControls) {
      this.mapbox.addControl(new mapboxgl.NavigationControl());
    }
    if (bounds) {
      this.mapbox.fitBounds(
        [
          [bounds.min_lon, bounds.min_lat],
          [bounds.max_lon, bounds.max_lat],
        ],
        { padding: 10 },
      );
    }
  }

  clearMap() {
    this.mapbox.mapDrawer.deleteAll();
    this.onGeoJsonChange();
  }

  renderClearMapButton() {
    return (
      <div className="map-control" onClick={this.clearMap.bind(this)}>
        Clear Map
      </div>
    );
  }

  createDrawControls() {
    const mapDrawer = new MapboxDraw({
      modes: { draw_rectangle: DrawRectangle, ...MapboxDraw.modes },
      displayControlsDefault: false,
      controls: { polygon: true },
    });

    this.mapbox.addControl(mapDrawer, "bottom-right");
    return mapDrawer;
  }

  pinCenter() {
    const center = [this.props.lon, this.props.lat];
    const el = document.createElement("img");
    el.className = "seo-unit-marker";

    const marker = new mapboxgl.Marker(el).setLngLat(center).addTo(this.mapbox);
  }

  addPoint() {
    const geoJson = {
      id: "points",
      type: "symbol",
      source: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: this.props.point_coordinates,
              },
              properties: {
                title: this.props.point_title,
                icon: "attraction",
              },
            },
          ],
        },
      },
      layout: {
        "icon-image": "{icon}-15",
        "text-field": "{title}",
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-offset": [0, 0.6],
        "text-anchor": "top",
      },
      paint: {
        "text-color": "red",
      },
    };

    this.mapbox.addLayer(geoJson);
  }

  addGeoJsonLayer() {
    const coordinates = this.coordinates;
    const geoJsonBorder = {
      id: "geoJsonLayer2",
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: coordinates,
          },
        },
      },
      layout: {},
      paint: {
        "line-color": "#4a056f",
        "line-opacity": 0.8,
      },
    };
    const geoJsonFill = {
      id: "geoJsonLayer",
      type: "fill",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: coordinates,
          },
        },
      },
      layout: {},
      paint: {
        "fill-color": "#740fab",
        "fill-opacity": 0.5,
      },
    };
    const coordString = turf.lineString(coordinates[0]);
    const bbox = turf.bbox(coordString);
    this.addLayer(geoJsonFill);
    this.addLayer(geoJsonBorder);
    this.fitBounds(bbox, { padding: 50 });
  }

  render() {
    const width = this.props.width ? this.props.width : 180;
    const height = this.props.height ? this.props.height : 237;
    const { renderDrawTool } = this.props;

    return (
      <div>
        <div className="simple-map-area" style={{ width: width, height: height }} id={this.html_id}></div>
        {renderDrawTool && this.renderClearMapButton()}
      </div>
    );
  }
}
