import React, { Component } from "react";
import moment from "moment";

import DateRangePicker from "../DatePicker/Range";

export default class UpdateUnit extends Component<any, any> {
  constructor(props) {
    super(props);

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
    this.onSpotsChange = this.onSpotsChange.bind(this);
  }

  onDatesChange({ start_date, end_date }) {
    const { unit, onChange } = this.props;
    onChange({
      ...unit,
      start_date: this.formatDate(start_date),
      end_date: this.formatDate(end_date),
    });
  }

  onPriceChange(e) {
    const { unit, onChange } = this.props;
    onChange({
      ...unit,
      price: e.target.value,
    });
  }

  onSpotsChange(e) {
    const { unit, onChange } = this.props;
    onChange({
      ...unit,
      quantity: e.target.value,
    });
  }

  formatDate(_date) {
    return _date ? moment(_date).format("YYYY-MM-DD") : null;
  }

  render() {
    const { unit } = this.props;
    const { start_date, end_date, price, quantity, screen_type } = unit;
    const marginTop = "18px";
    const spots_disabled = screen_type === "static";

    return (
      <div key={unit.token} className="row striped_row">
        <div
          className="col-xs-2"
          style={{
            marginTop,
            marginLeft: "10px",
            fontSize: "16px",
          }}
        >
          {unit.supplier_face_id}
        </div>
        <div className="col-xs-5" style={{ marginTop: "10px" }}>
          <DateRangePicker
            start_date={start_date ? moment(start_date) : null}
            end_date={end_date ? moment(end_date) : null}
            onDatesChange={this.onDatesChange}
            small={true}
            showDefaultInputIcon={true}
          />
        </div>
        <div className="col-xs-2" style={{ marginTop: "13px" }}>
          <input type="number" pattern="[0-9]*" className="form-control" value={price} onChange={this.onPriceChange} />
        </div>
        <div className="col-xs-2" style={{ marginTop: "13px" }}>
          <input
            type="number"
            pattern="[0-9]*"
            disabled={spots_disabled}
            className={`form-control ${spots_disabled ? "disabled" : ""}`}
            placeholder={spots_disabled ? "N/A" : quantity}
            value={quantity}
            onChange={spots_disabled ? () => {} : this.onSpotsChange}
          />
        </div>
        {unit.is_dirty && (
          <span className="col-xs-1" style={{ marginTop, opacity: 0.6 }}>
            updated
          </span>
        )}
      </div>
    );
  }
}
