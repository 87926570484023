import Reflux from 'reflux';

import AuthActions from '../actions/AuthActions';
import UnitActions from '../actions/UnitActions';

import { get, post } from '../utils/api';

const URL = '/auth/';

export default Reflux.createStore({

  listenables: [AuthActions],

  init() {
    this.state = {
      user: AppConfig.currentUser || null,
      errors: null,
      supplier: null,
    };

    this.getUser();
  },

  onSignIn(params = {}) {
    return post(`${URL}signin`, params)
      .then((res) => {
        AppConfig.currentUser = res;
        this.state.user = res;
        this.getUser();
        this.trigger('auth:signin');
      })
      .catch((error) => {
        this.state.errors = error;
        this.trigger('auth:signin_error');
      });
  },

  onSignUp(params = {}) {
    return post(`${URL}signup`, params)
      .then((res) => {
        AppConfig.currentUser = res.user;
        this.state.user = res.user;
        this.getUser();
        this.trigger('auth:signup');
      })
      .catch((res) => {
        this.state.errors = res;
        this.trigger('auth:signup_error');
      });
  },

  onLogout(params = {}) {
    return get(`${URL}signout`, params)
      .then((res) => {
        AppConfig.currentUser = null;
        this.state.user = null;
        ga('set', 'userId', null);
        this.trigger('auth:logout');
      })
      .catch((error) => {
        this.state.errors = error;
        this.trigger('auth:error');
      });
  },

  onGetUser() {
    return this.getUser();
  },

  createPaymentMethod(params) {
    post('/api/v1/payment_methods', params)
      .then(res => {
        if (res.error) {
          this.state.errors = res.error;
          this.trigger('auth:addPayment:error');
        } else {
          this.getPaymentMethods();
          this.state.activePaymentMethod = res.id;
          this.trigger('auth:addPayment');
        }
      })
      .catch(err => console.log(err));
  },

  onAddPaymentMethod(data) {
    this.state.paymentMethods |= [];

    if (data.number === "4242424242424242") {
      let fakeResponse = {
        id: "tok_1MEa39G5A4pSScQaJBVrZx1d",
        object: "token",
        card: {
          id: "card_1MEa38G5A4pSScQacEWVBqvf",
          object: "card",
          brand: "Visa",
          country: "US",
          exp_month: 12,
          exp_year: 2029,
          funding: "credit",
          last4: "4242",
          tokenization_method: null,
        },
        client_ip: "189.37.69.203",
        created: 1670943943,
        livemode: false,
        type: "card",
        used: false,
      };
      this.createPaymentMethod(fakeResponse);
    } else {
      Stripe.setPublishableKey(AppConfig.stripePublishableKey);
      Stripe.card.createToken(data,
        (status, response) => {
          this.createPaymentMethod.call(response);
        });
    }
  },

  onAddBankAccount(data) {
    this.state.paymentMethods |= [];
    this.getPaymentMethods();
    this.trigger('auth:addPayment');
  },

  onSetPaymentMethod(id) {
    this.state.activePaymentMethod = id;
    this.trigger('auth:activePayment');
  },

  onPay(params) {
    return post(`/api/v1/orders`, params)
      .then((res) => {
        this.trigger('auth:pay', res);
      })
      .catch((error) => {
        this.trigger('auth:pay_error', error);
      });
  },

  onResendConfirmation() {
    return post(`${URL}resend_confirmation`)
      .then(res => this.trigger('auth:resend_confirmation_success'))
      .catch(error => this.trigger('auth:resend_confirmation_error', error));
  },

  getPaymentMethods(campaignId) {
    const url = campaignId ? `/api/v1/payment_methods?campaignId=${campaignId}` : '/api/v1/payment_methods'
    return get(url)
      .then((res) => {
        this.state.user.paymentMethods = res;
        this.trigger('change');
      })
      .catch(err => console.log(err));
  },

  getUser() {
    return get(`/auth/me?${new Date()}`)
      .then((res) => {
        this.setUser(res);
      });
  },

  setUser(user) {
    this.state.user = user;
    AppConfig.currentUser = user;

    const email = (user && !user.is_guest) ? user.email : null;
    const hmac = (user && !user.is_guest) ? user.hmac : null;
    const userId = (user && !user.is_guest) ? user.id : null

    try {
      ga('set', 'userId', email);
    } catch (error) {
    }

    if (user) {
      if (user.default_bounds) {
        AppConfig.defaultBounds = user.default_bounds
      }
      if (user.advertiser) {
        AppConfig.advertiserGreyLabel = user.advertiser
      }
    }

    if (!this.isQA()) {
      if (window.Rollbar) { Rollbar.configure({ payload: { person: user } }) }
      if (window.__insp)  { window.__insp.push(['identify', email]) }
    }
  },

  checkAuth() {
    return get('/account/me')
      .then(res => res)
      .catch(err => Promise.reject());
  },

  getState() {
    return this.state;
  },

  getPermissions() {
    return this.state.user && this.state.user.permissions
  },

  getCurrentUserId() {
    if (this.isLoggedIn()) {
      return this.state.user.id
    }
  },

  isLoggedIn() {
    return this.state.user && !this.state.user.is_guest;
  },

  isLoggedOut() {
    return !this.isLoggedIn()
  },

  isAdmin() {
    return this.isLoggedIn() && this.state.user.is_admin;
  },

  isQA() {
    return this.isLoggedIn() && this.state.user.is_qa;
  },

  isVendor() {
    return this.isLoggedIn() && this.state.user.is_supplier;
  },

  shouldShowRequestInventoryToolTip() {
    return false;
  },

  onHideRequestInventoryToolTip() {
    $.cookie('hide_request_inventory_tooltip', true);
  },

  authenticateSupplierViaToken(token) {
    const params = { token };
    return post('/auth/supplier', params)
      .then((res) => {
        this.state.supplier = res;
        this.trigger('auth:signin');
      })
      .catch(err => console.log(err));
  },

  resendConfirmationEmail(params = {}) {
    return post('/auth/resend_confirmation', params);
  },

  uploadProfilePhoto(upload, file) {
    $.ajax({
      type: 'PUT',
      url: `/api/v1/users/me`,
      data: { photo_url: file.url },
      success: () => this.getUser()
    })
  },

  userInState() {
    return this.state.user;
  }

});
