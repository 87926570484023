import React, { Component } from "react";
import { connect } from 'react-redux';
import UploadButton from "../../../UploadButton";
import GlobalActions from "../../../../actions/GlobalActions";
import { loadCampaignDesigns } from "../../../../actions/campaign_design_actions";
import { post } from "../../../../utils/api";

interface IMUSSMP {
  unit_ids: Array<String>
  campaign_token: string
  onHide: () => void
  loadCampaignUnits: (token: string) => void
  loadCampaignDesigns: (token: string) => void
}

interface IMUSSMS {
  url: string
  error: string | null
}

class MassUpdateSpecSheetModal extends Component<IMUSSMP,IMUSSMS> {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      error: null
    }
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.onSaveSpecSheet = this.onSaveSpecSheet.bind(this);
  }

  public render() {
    return (
      <div>
        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    const { error, url } = this.state;
    return (
      <div style={{ padding: 30 }}>
        {error &&
          <div className="alert alert-danger">{error}</div>
        }
        <div className="form-group row">
          <div className="col-sm-6">
            <label>
              Spec Sheet URL:
            </label>
            <input
              className="form-control"
              value={url}
              name="url"
              type="text"
              onChange={(e) => this.onInputChange("url", e.target.value)}/>
          </div>
          <div className="col-sm-6">
            <label>
              Upload:
            </label>
            <UploadButton
              onComplete={this.onUploadComplete}
            >
              <button
                className="btn btn-default"
                onClick={(e) => e.preventDefault()}
              >
                <i
                  className="fa fa-file-o"
                  aria-hidden="true"
                  style={{marginRight: 5}}
                ></i>
                Click to Upload or Drop File Here
              </button>
            </UploadButton>
          </div>
        </div>
        <div className="form-group" style={{textAlign: "center", padding: "10px"}}>
          <button
            className="btn btn-primary"
            style={{width:"40%",padding:"10px"}}
            onClick={this.onSaveSpecSheet}
          >
            Save
          </button>
        </div>
      </div>
    )
  }

  private onInputChange(attribute, input_value) {
    //@ts-ignore
    this.setState({
      [attribute]: input_value,
    });
  }

  private onUploadComplete(upload, file) {
    this.setState({ url: file.url });
  }

  private async onSaveSpecSheet() {
    const { unit_ids, campaign_token, onHide} = this.props;
    const payload = {
      unit_ids,
      campaign_id: campaign_token,
      file_url: this.state.url
    }
    try {
      this.validateFileUrl();
      await post(`/api/v1/spec_sheets/mass_assign_by_cu`, payload);
      await this.props.loadCampaignDesigns(campaign_token);
      await this.props.loadCampaignUnits(campaign_token);
      GlobalActions.showMessage("Successfully assigned spec sheet!");
      onHide();
    } catch (error) {
      this.setState({ error });
    }
  }

  private validateFileUrl() {
    const { url } = this.state;
    try {
      const parsedUrl = new URL(url);
      if (parsedUrl.protocol == 'http:') { throw 'Must be https' }
    } catch (error) {
      throw "Must be a valid spec sheet https url (E.g: https://my-domain.com/file')";
    }
  }
}

export default connect(
  null,
  { loadCampaignDesigns }
)(MassUpdateSpecSheetModal)
