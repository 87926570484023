import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapLoader from '../MapLoader';
import Header from './Header';
import Title from './Title';
import ActionsMenu from './ActionsMenu';
import List from './List';
import AuthStore from '../../../stores/AuthStore';
import {
  filterUnits,
} from '../../../actions/UnitsActions';
import { clearMap, setBounds, updateMapPosition } from '../../NewMap/actions';

class MobileCampaign extends Component {

  constructor(props) {
    super(props);
    this.onMapMove = this.onMapMove.bind(this);
    this.state = {
      auth: AuthStore.getState(),
    }
  }

  onMapMove(position) {
    const bounds = position.bounds;
    const boundsString = [bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast()].join(',');
    const { setBounds } = this.props;
    setBounds(boundsString);
    this.setState({ bounds: boundsString });
  }

  setMapbox(mapbox) {
    this.setState({ mapbox: mapbox });
  }

  onMarkerClick(params) {
    // TODO: scroll list to clicked unit
    return true
  }

  renderMap() {
    return (
      <div id="mobile-campaign">
        {/*<Header />*/}
        <div id="mobile-map">
          <MapLoader
            showLoadingIndicator={true}
            loading={!this.props.campaign.campaign || !this.props.units.all_units}
            campaign={this.props.campaign.campaign}
            units={this.props.units}
            permissions={this.props.campaign.permissions}
            bounds={this.props.map.bounds}
            campaign_permissions={this.props.campaign.permissions}
            user={this.state.auth && this.state.auth.user}
            adwords_zip_codes={[]}
            onMove={this.onMapMove}
            onMarkerClick={this.onMarkerClick}
            showTips={false}
            timestamp={this.props.units.timestamp}
            filterUnits={this.props.filterUnits}
            rich_pins={false}
            draw_tool={false}
            sidebar_popup={false}
            isCampaignView={true}
            onMapboxCreated={() => { return true }}
            padding={20}
            showUnitOrientation={false}
            cluster={false}
          />
        </div>
        <Title />
        <ActionsMenu user={this.state.auth && this.state.auth.user} />
        <List hide_unit_prices={this.props.hide_unit_prices} />
      </div>
    )
  }

  render() {
    return this.renderMap()
  }
}

const mapStateToProps = ({
    map,
    campaign,
    units
  }) => ({
  map,
  campaign,
  units
})

export default connect(
  mapStateToProps,
  {
    setBounds,
    filterUnits,
  }
)(MobileCampaign)
