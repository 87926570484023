import { connect } from "react-redux";
import React, { Component } from "react";
import moment from "moment";
import { uploadPop } from "../../../actions/campaign_design_actions";
import { TUploadPop } from "../../../models/design_assets";
import UploadButton from "../../UploadButton";
import GlobalActions from "../../../actions/GlobalActions";

interface UploadDesignAssetActionProps {
  unit?: any;
  posted_date: moment.Moment | null;
  campaign_id: string;
  progressBarColor?: string | number;
  customStyles?: object;
  units?: {}[];
  uploadPop: TUploadPop;
  callback?: (response) => void;
  children: React.ReactNode;
}

interface UploadDesignAssetActionState {
  show_modal: boolean;
  run_callback: boolean;
}

class UploadPOPAction extends Component<
  UploadDesignAssetActionProps,
  UploadDesignAssetActionState
> {
  constructor(props) {
    super(props);
    this.state = {
      show_modal: false,
      run_callback: this.props.callback ? true : false
    };
    this.onUploadComplete = this.onUploadComplete.bind(this);
  }

  public render() {
    const { children, progressBarColor, customStyles } = this.props;

    return (
      <UploadButton
        onComplete={this.onUploadComplete}
        progressBarColor={progressBarColor}
        customStyles={customStyles}
      >
        {children}
      </UploadButton>
    );
  }

  private async onUploadComplete(upload, file) {
    const { unit, units, campaign_id, callback, posted_date } = this.props;
    if (!posted_date) {
      return GlobalActions.showError(
        "Select the posted date on the previous modal"
      );
    }
    const uploadInfo = {
      file_name: file.name,
      file_path: upload.path,
      file_size: file.size,
      file_type: file.type
    };
    const unitsToSend = units ? units : [unit];
    await this.props.uploadPop(
      campaign_id,
      unitsToSend,
      uploadInfo,
      posted_date
    );
    //@ts-ignore
    this.state.run_callback && callback("posted");
  }
}

const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { uploadPop })(UploadPOPAction);
