import React, { PureComponent } from "react";

interface Props {
  shipping_address: any;
}

class ShippingAddress extends PureComponent<any, any> {
  public render() {
    const { shipping_address } = this.props;
    const { address_line, city, state, zip_code } = shipping_address;
    const address_string = `${address_line || ""} ${city || ""} ${state || ""} ${zip_code || ""}`;
    if (address_string.trim().length === 0) {
      return <div></div>;
    } else {
      return (
        <div id="shipping_address_container">
          <i className="fa fa-truck fa-flip-horizontal" aria-hidden="true"></i> {address_string}
        </div>
      );
    }
  }
}

export default ShippingAddress;
