import React, { Component } from "react";
import { connect } from "react-redux";
import GlobalActions from "../../../actions/GlobalActions";
import JobProgress from "../../../utils/JobProgress";
import isEmpty from "lodash/isEmpty";
import AttachmentCard from "./AttachmentCard";
import ExampleAttachmentCard from "./ExampleAttachmentCard";
import {
  getAttachments,
  createAttachment,
  destroyAttachment,
  downloadAttachments,
  updateAttachment
} from "./actions"

class CampaignAttachments extends Component<any,any> {
  constructor(props) {
    super(props);
    this.state = {
      loadingSteps: [],
      cards: [],
      job_polling: new JobProgress,
      all_checked: false,
      zipping: false
    }
    this.onSelectCard = this.onSelectCard.bind(this);
    this.onDestroyAttachment = this.onDestroyAttachment.bind(this);
    this.onUpdateAttachment = this.onUpdateAttachment.bind(this);
    this.openUploadModal = this.openUploadModal.bind(this);
    this.onCompleteZipFile = this.onCompleteZipFile.bind(this);
    this.downloadSelected = this.downloadSelected.bind(this);
    this.onToggleAllCards = this.onToggleAllCards.bind(this);
    this.onCreateAttachment = this.onCreateAttachment.bind(this);
  }

  addLoadingStep(name) {
    const steps = this.state.loadingSteps.slice();
    steps.push(name);
    this.setState({ loadingSteps: steps });
    return this.state.loadingSteps;
  }

  removeLoadingStep(name) {
    const steps = this.state.loadingSteps.slice();
    this.setState({ loadingSteps: steps.filter(step => step !== name) });
    return this.state.loadingSteps;
  }

  public async componentDidMount() {
    await this.onGetAttachments();
    //@ts-ignore
    window.addLoadingStep = this.addLoadingStep.bind(this);
    //@ts-ignore
    window.removeLoadingStep = this.removeLoadingStep.bind(this);
  }

  private async onGetAttachments() {
    const { campaign_id } = this.props;
    const attachments = await getAttachments(campaign_id);
    this.setState({ cards: attachments });
  }

  private async onCreateAttachment(payload) {
    const { cards } = this.state;
    const { campaign_id } = this.props;
    const attachment = await createAttachment(campaign_id, payload);
    return this.setState({
      cards: [
        ...cards,
        attachment
      ]
    });
  }

  private async onDestroyAttachment(id) {
    const { campaign_id } = this.props;
    try {
      await destroyAttachment(campaign_id, id);
      await this.onGetAttachments();
    } catch (error) {
      GlobalActions.showError(error);
      console.log(error);
    }
  }

  private async onUpdateAttachment(id, payload) {
    const { campaign_id } = this.props;
    try {
      await updateAttachment(campaign_id, id, {attachment: payload});
      await this.onGetAttachments();
    } catch (error) {
      GlobalActions.showError(error);
      console.log(error);
    }
  }


  private async downloadSelected() {
    const { campaign_id } = this.props;
    const { cards, job_polling } = this.state;
    const selected = cards.filter(c => c.checked);
    if(isEmpty(selected)) { return; }
    const ids = selected.map(c => c.id).join(",");
    const job_id = await downloadAttachments(campaign_id, ids);
    return this.setState({ zipping: true }, () => {
      job_polling.startPolling(job_id, this.onCompleteZipFile)
    })
  }

  private onCompleteZipFile({ data }) {
    const { error, file_url } = data;
    this.setState({ zipping: false });
    if(error) { return; }
    window.open(file_url);
  }

  stepLoading() {
    const css = Boolean(this.state.loadingSteps.length) ? "is_loading" : "";

    return (
      <div id="map-step-loading" className={`ui map-step-loading ${css}`}>
        <div className="step-loading__container">
          {this.state.loadingSteps.map((step, i) => (
            <div className="step-loading__step" key={`loading-step-${i}`}>
              <i className="fal fa-spinner-third fa-spin"></i>
              {step}
            </div>
          ))}
        </div>
      </div>
    );
  }

  public render() {

    const { all_checked, zipping } = this.state;
    const { user, campaign_permissions } = this.props
    const can_add_attachments = user.is_supplier || user.is_admin || user.is_agency
    return (
      <div className="attachments_container">
        {this.stepLoading()}
        <div className="header">
          <div className="title">
            <h3>All Attachments</h3>
          </div>
          { can_add_attachments && (
              <div className="actions">
                <button className="primary_round_button"
                  onClick={this.openUploadModal}
                >
                  + New Attachment
              </button>
              </div>
            )
          }
        </div>
        <div className="bulk_actions">
          <span onClick={this.downloadSelected}>
            {zipping ? "Creating zip file..." : "Download All"}
          </span>
          <div onClick={this.onToggleAllCards}>
            {this.renderCheckbox(all_checked)}
          </div>
        </div>
        <div className="cards">
          {!campaign_permissions.disable_example_attachment &&
            < ExampleAttachmentCard />
          }
          {this.sortedCards().map(card => this.renderCard(card))}
        </div>
      </div>
    )
  }

  private onToggleAllCards() {
    const { all_checked } = this.state;
    this.setState({
      cards: this.toggleCards(!all_checked),
      all_checked: !all_checked
    });
  }

  private toggleCards(checked) {
    return this.state.cards.reduce((cards, card) => {
      card['checked'] = checked;
      cards.push(card);
      return cards;
    }, []);
  }

  private sortedCards() {
    const { cards } = this.state;
    return cards.sort((prev, next) => next.id - prev.id);
  }

  private openUploadModal() {
    GlobalActions.openPopup(
      "upload_attachment",
      {
        onCreateAttachment: this.onCreateAttachment
      }
    )
  }

  private renderCard(card) {
    const { user } = this.props
    const isAdmin = user.is_admin
    return (
      <AttachmentCard
        card={card}
        onSelectCard={this.onSelectCard}
        destroyAttachment={this.onDestroyAttachment}
        updateAttachment={this.onUpdateAttachment}
        renderCheckbox
        renderDestroy
        renderDescription
        renderCreatedAt
        renderCreatedBy
        renderVisibility
        isAdmin={isAdmin}
      />
    )
  }

  private renderCheckbox(checked) {
    return (
      <div className="white_checkbox">
        <input type="checkbox" checked={checked}/>
        <span className="checkmark"></span>
      </div>
    )
  }

  private onSelectCard(card_id) {
    const { cards } = this.state;
    const selected = cards.find(attachment => attachment.id === card_id);
    return this.setState({
      cards: [
        ...cards.filter(attachment => attachment.id !== card_id),
        this.onCheckCard(selected)
      ]
    })
  }

  private onCheckCard(card) {
    return {
      ...card,
      checked: !card.checked
    }
  }
}

export default connect(
  (state: any) => ({ 
    campaign_id: state.campaign.campaignId
  }),
  null
)(CampaignAttachments)
