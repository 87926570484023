import { formatNumber, unformat } from 'accounting';
import React from 'react';
import createClass from 'create-react-class';
import PropTypes from "prop-types";

export default createClass({
  displayName: 'Surveys',

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      survey_questions: {
        0: '',
        1: '',
        2: '',
        3: ''
      }
    };
  },

  onQuestionChange(event) {
    const questions = {
      0: event.target.name === '0' ? event.target.value : this.state.survey_questions[0],
      1: event.target.name === '1' ? event.target.value : this.state.survey_questions[1],
      2: event.target.name === '2' ? event.target.value : this.state.survey_questions[2],
      3: event.target.name === '3' ? event.target.value : this.state.survey_questions[3]
    }
    this.setState({survey_questions: questions}, this.props.setAttributes({ survey_questions: questions }))
  },

  renderQuestions(index) {
    const survey_questions = this.state.survey_questions
    const question_placeholders = {
      0: 'What is the first Real Estate company that comes to mind?',
      1: 'Select the companies you have heard of: CBRE, Century 21...',
      2: 'Additional Question',
      3: 'Additional Question'
    };

    return (
      <div>
        {Object.values(survey_questions).map((question, index) =>
          <div key={`survey-questions-${index}`}>
            <label htmlFor="survey_questions">Question {index + 1}</label>
            <textarea
              className="form-control"
              name={index}
              defaultValue={question}
              onChange={this.onQuestionChange}
              placeholder={question_placeholders[index]}
              style={{ height: 50 }}
            />
          </div>
        )}
      </div>
    )
  },

  render() {
    const { survey_zip_codes, survey_demographics, survey_number_of_people, survey_budget } = this.state;

    return (
      <div id="campaign-request-surveys">
        <h4 className="section_header">
          <i className="fa fa-check-square-o" aria-hidden="true"></i> Surveys
        </h4>
        <div className="section">
          <div>
            <span className="description_paragraph">Enter up to 4 questions</span>
            {this.renderQuestions()}
            <div className="line_break"></div>
            <label htmlFor="survey_zip_codes">Target Zip Codes</label>
            <textarea
              className="form-control"
              defaultValue={survey_zip_codes}
              onChange={e => this.props.setAttributes({ survey_zip_codes: e.target.value }) }
              placeholder="10011, 10065, 11215, 11365, 10075"
              style={{ height: 50 }}
            />
            <label htmlFor="survey_demographics">Target Survey Demographics</label>
            <textarea
              className="form-control"
              defaultValue={survey_demographics}
              onChange={e => this.props.setAttributes({ survey_demographics: e.target.value }) }
              placeholder="Aged Between 20-35, Household Income between $49,000-$149,000"
              style={{ height: 50 }}
            />
            <label htmlFor="survey_number_of_people">Number of people to reach</label>
            <textarea
              className="form-control"
              defaultValue={survey_number_of_people}
              onChange={e => this.props.setAttributes({ survey_number_of_people: e.target.value }) }
              placeholder="3,000"
              style={{ height: 50 }}
            />
            <label htmlFor="survey_budget">Budget</label>
            <span className="input-group">
              <span className="input-group-addon">$</span>
              <input
                className="form-control"
                value={survey_budget || ''}
                onChange={e => {
                  const survey_budget = formatNumber(unformat(e.target.value));
                  this.setState({ survey_budget });
                  this.props.setAttributes({ survey_budget });
                }}
                placeholder="1,000"
              />
            </span>
          </div>
        </div>
      </div>
    )
  },
});
