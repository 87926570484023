import queryString from 'query-string';

import {
  SET_FILTERS,
  SHOW_ARCHIVED,
  FETCH_ROWS,
  FETCH_ROWS_SUCCESS,
  SELECT_ROWS
} from './actions';

const initialState = {
  filters: {
    mode: 'cards',
    status: 'initial'
  },
  results: [],
  selected: [],
  isFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {
  case SHOW_ARCHIVED:
    return {
      ...state,
      filters: {
        mode: 'cards',
        deleted_at: true
      }
    }
  case SELECT_ROWS:
    return {
      ...state,
      ...action.payload
    }
  case SET_FILTERS:
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload.filters
      }
    }
  case FETCH_ROWS:
    return {
      ...state,
      isFetching: true,
    }
  case FETCH_ROWS_SUCCESS:
    return {
      ...state,
      ...action.payload,
      isFetching: false
    }
  default:
    return state
  }
}
