import {
  CLEAR_CAMPAIGN,
  LOAD_CAMPAIGN,
  UPDATE_CAMPAIGN_TAGS,
  BEGIN_UPDATE_CAMPAIGN_TAGS,
  UPDATE_CAMPAIGN_MEDIA_TYPES,
  UPDATE_CAMPAIGN_SCREEN_TYPE,
  UPDATE_UNIT_TAG_NAME,
  DELETE_UNIT_TAG,
  SET_HIGHLIGHTED_ZIPS,
  LOAD_DEMOGRAPHICS,
  LOAD_SUPPLIER_CONTACTS,
  SET_SUPPLIER_CONTACT,
  UPDATE_CAMPAIGN_UNIT, END_UPDATE_CAMPAIGN_TAGS, REFRESH_UNIT_TAGS,
  LOAD_CART_REACH_FREQUENCY
} from './actions';

const initialState = {
  campaignId: null,
  sort_by: 'default',
  loading: false,
  campaign: {},
  permissions: {},
  demographics: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CAMPAIGN:
      return initialState;
    case LOAD_CAMPAIGN:
      return setCampaign(state, action.payload);
    case BEGIN_UPDATE_CAMPAIGN_TAGS:
      return {...state, saving_campaign_tags: true};
    case END_UPDATE_CAMPAIGN_TAGS:
      return {...state, saving_campaign_tags: false};
    case UPDATE_CAMPAIGN_TAGS:
      return updateCampaignTags(state, action.payload);
    case REFRESH_UNIT_TAGS:
      return updateCampaignTags(state, action.payload);
    case UPDATE_CAMPAIGN_MEDIA_TYPES:
      return updateCampaignMediaTypes(state, action.payload);
    case UPDATE_CAMPAIGN_SCREEN_TYPE:
      return updateCampaignScreenType(state, action.payload);
    case 'CAMPAIGN_TOGGLE_LOADING':
      return toggleCampaignLoading(state);
    case UPDATE_UNIT_TAG_NAME:
      return updateCampaignTags(state, action.payload);
    case DELETE_UNIT_TAG:
      return updateCampaignTags(state, action.payload);
    case SET_HIGHLIGHTED_ZIPS:
      return {...state, campaign: {...state.campaign, highlighted_regions: action.payload}};
    case LOAD_DEMOGRAPHICS:
      return {...state, demographics: action.payload};
    case LOAD_SUPPLIER_CONTACTS:
      return {...state, campaign: {...state.campaign, unit_supplier_contacts: action.payload}};
    case SET_SUPPLIER_CONTACT:
      return updateUnitSupplierContact(state, action.payload);
    case UPDATE_CAMPAIGN_UNIT:
      return {...state};
    case LOAD_CART_REACH_FREQUENCY:
      return { ...state, reachAndFrequency: action.payload.reachAndFrequency };
    default:
      return state;
  }
};

function toggleCampaignLoading(state) {
  return {
    ...state,
    loading: !state.loading
  };
}

function setCampaign(state, payload) {
  return {
    ...state,
    ...payload
  };
}

function updateCampaignTags(state, {unit_tags}) {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      unit_tags
    },
    saving_campaign_tags: false
  };
}

function updateCampaignMediaTypes(state, new_types) {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      ...new_types
    }
  };
}

function updateCampaignScreenType(state, data) {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      ...data
    }
  };
}

function updateUnitSupplierContact(state, payload) {
  const {contact_id, campaign_unit_id} = payload;

  return {
    ...state,
    campaign: {
      ...state.campaign,
      unit_supplier_contacts: {
        unit_options: {
          ...state.campaign.unit_supplier_contacts.unit_options,
          [campaign_unit_id]: [
            state.campaign.unit_supplier_contacts.unit_options[campaign_unit_id][0],
            parseInt(contact_id)
          ]
        },
        campaign_units: [
          ...state.campaign.unit_supplier_contacts.campaign_units
        ]
      }
    }
  };
}
