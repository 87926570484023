import { connect } from "react-redux";
import * as React from "react";
import GlobalActions from "../../../actions/GlobalActions";
import { bulkRemovePostingNote } from "../../../actions/campaign_design_actions";

interface Props {
  bulkRemovePostingNote: Function;
  selected_units: { campaign_unit_token: string }[];
  campaign: any;
  disabled: boolean;
}
interface State {
  removing: boolean;
}

class BulkRemovePostingNoteAction extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      removing: false
    };
    this.onClick = this.onClick.bind(this);
    this.removeUnits = this.removeUnits.bind(this);
    this.showMessage = this.showMessage.bind(this);
  }

  async onClick() {
    const { removing } = this.state;
    if (removing) return;
    this.setState(
      {
        removing: true
      },
      this.removeUnits
    );
  }

  async removeUnits() {
    const { bulkRemovePostingNote } = this.props;
    const { campaign, selected_units } = this.props;
    const campaign_id = campaign.campaignId;
    const campaign_unit_ids = selected_units.map(u => u.campaign_unit_token);
    await bulkRemovePostingNote(campaign_id, campaign_unit_ids);
    this.setState({ removing: false }, () => {
      GlobalActions.showMessage(
        `Posting note removed from ${campaign_unit_ids.length} campaign units.`
      );
    });
  }

  showMessage() {
    GlobalActions.showError(
      "One or more selected units have already been sent to production and the posting note cannot be updated."
    );
  }

  public render() {
    const { removing } = this.state;
    const { disabled } = this.props;
    const button_style = {
      color: "#555555",
      width: "100%",
      fontSize: 12
    };

    return (
      <button
        key="remove_posting_note"
        onClick={removing || disabled ? this.showMessage : this.onClick}
        className={`btn btn-default ${removing || disabled ? "disabled" : ""}`}
        style={button_style}
      >
        Remove Posting Note
      </button>
    );
  }
}

export default connect(({}) => ({}), { bulkRemovePostingNote })(
  BulkRemovePostingNoteAction
);
