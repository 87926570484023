import * as React from 'react';
import { Modal } from 'react-bootstrap';
import GlobalActions from '../../actions/GlobalActions';
import NeedHelpModal from './NeedHelpModal';
const ASSETS = (window as any).ASSETS;

export default class ResetPasswordModal extends React.Component<any, any> {

  constructor(props) {
    super(props);
    const email = this.props.email || '';
    this.state = {
      email: email,
      validEmail: true,
      email_sent: false,
      sending: false,
      re_sent: false,
      magic_link_sent: false,
      need_help: false
    }
    this.onHide = this.onHide.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onHelpBack = this.onHelpBack.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onNeedHelp = this.onNeedHelp.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.onOpenSignup = this.onOpenSignup.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.sendMagicLink = this.sendMagicLink.bind(this);
    this.renderMagicLinkSent = this.renderMagicLinkSent.bind(this);
    this.onResendConfirmation = this.onResendConfirmation.bind(this);
    this.renderLoadingSpinner = this.renderLoadingSpinner.bind(this);
    this.renderSendConfirmationSection = this.renderSendConfirmationSection.bind(this);
  }

  onNeedHelp() {
    this.setState({
      need_help: true
    })
  }

  onHide() {
    const { onHide } = this.props;
    this.setState({
      email: '',
      validEmail: true,
      email_sent: false,
      sending: false,
      re_sent: false,
      magic_link_sent: false,
      need_help: false
    });
    onHide();
  }

  sendMagicLink() {
    const { email } = this.state;
    if (this.validateEmail()){
      this.setState({ sending: true }, () => {
        $.ajax({
          method: "POST",
          url: "/api/v1/users/send_magic_link",
          data: {
            email: email
          }
        }).always(() => {
          this.setState({
            magic_link_sent: true,
            sending: false
          })
        });
      })
    } else {
      this.setState({ validEmail: false });
    }
  }

  onEmailChange(e) {
    const { validEmail } = this.state;

    this.setState({
      email: e.target.value,
      validEmail: (!!!validEmail ? this.validateEmail() : true),
      email_sent: false,
      sending: false,
      re_sent: false
    });
  }

  validateEmail() {
    const { email } = this.state;
    const match = email.match(/\S+@\S+\.\S/);
    return match;
  }

  onLogin(e) {
    window.location.assign("/login");
    e.preventDefault();
  }

  onResendConfirmation(e) {
    this.setState({ re_sent: true }, () => {
      this.onSubmit(e);
    })
    e.preventDefault();
  }

  onOpenSignup(e) {
    const { onOpenSignup } = this.props;
    const popupOptions = this.props.popupOptions || {};

    if (onOpenSignup) {
      onOpenSignup();
    } else {
      window.location.href = '/sign-up';
    }
    e.preventDefault();
  }

  onSubmit(e) {
    const { email, sending } = this.state;

    if (this.validateEmail()) {
      this.setState({ sending: true }, () => {
        $.ajax({
          method: "POST",
          url: "/users/password.json",
          data: {
            user: {
              email: email
            },
          }
        }).always((data) => {
          this.setState({
            email_sent: true,
            sending: false
          })
        });
      })
    } else {
      this.setState({
        validEmail: false
      })
    }
    e.preventDefault();
  }

  renderMagicLinkSent() {
    const { show } = this.props;
    const { email } = this.state;

    return (
      <Modal show={show} onHide={this.onHide} id="reset-password-modal">
        <Modal.Header>
          {this.renderHeader("Check your email")}
        </Modal.Header>
        <Modal.Body>
          <div className="magic-link-sent-body">
            We've sent a magic sign in link to the following email address:
            <div className="email-sent-to">
              {email}
            </div>
          </div>
          <button className="adstrap_button still-need-help" onClick={this.onNeedHelp}>
            Still need help?
          </button>
        </Modal.Body>
        <Modal.Footer>
          {this.renderFooter()}
        </Modal.Footer>
      </Modal>
    );
  }

  renderHeader(text) {
    const headerText = text || "Don't know your password?";
    const { onBack } = this.props;

    return (
      <div>
        <div className="back-icon-area" onClick={onBack}>
          <i className="fa fa-chevron-left back-icon" aria-hidden="true"/>
        </div>
        <img
          className="close-modal-x"
          src={ASSETS['x']}
          style={{ float: 'right' }}
          onClick={this.onHide}
        />
        <div className="header-text">
          {headerText}
        </div>
      </div>
    );
  }

  renderSendConfirmationSection() {
    const { email_sent, re_sent } = this.state;

    return (
      <div className="send-confirmed">
        {email_sent && <div className="email-sent">
          We've emailed password reset instructions to the email entered.
          If you have not received instructions, please check that you entered your email correctly.
        </div>}
        {(email_sent && !!!re_sent )&& <div className="link-area">
          <a className="modal-link" onClick={this.onResendConfirmation}>
            Resend password reset instructions
          </a>
        </div>}
      </div>
    );
  }

  renderLoadingSpinner() {
    return (
      <div className="new_loading" style={{ textAlign: "center", paddingTop: 10 }}>
        <svg viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20"></circle>
        </svg>
      </div>
    );
  }

  renderForm() {
    const { email, email_sent, validEmail, sending } = this.state;

    return (
      <div>
        {!!!validEmail && <div className="email-error">
          Please enter a valid email address.
        </div>}
        <form onSubmit={this.onSubmit}>
          <div className="email-label">
            Your email address <span style={{color: 'red'}}>*</span>
          </div>
          <input
            className={`form-control email ${validEmail ? '' : 'error'}`}
            placeholder="Work email address"
            value={email}
            onChange={(e) => this.onEmailChange(e)}
          />
          <div className="contact-us-blurb">
            We'll send you a link to change your password. If you still need help, <a onClick={this.onNeedHelp}>contact us</a>.
          </div>
          <button
            disabled={email_sent || sending}
            className={`adstrap_button submit-button ${(email_sent || sending) ? 'disabled' : ''}`}
            onClick={this.onSubmit}
          >
            Request a password reset
          </button>
          <div className="send-magic-area">
            <a className="send-me-magic" onClick={this.sendMagicLink}>Send me a magic link instead</a>
          </div>
          {sending && this.renderLoadingSpinner()}
          {this.renderSendConfirmationSection()}
        </form>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className="link-area">
        <div className="no-account">
          Don't have an account yet? <a onClick={(this.onOpenSignup)} id="modal-link">Sign up here</a>
        </div>
      </div>
    );
  }

  onHelpBack() {
    this.setState({
      need_help: false
    })
  }

  render() {
    const { magic_link_sent, need_help, email } = this.state;
    const { show } = this.props;

    if (need_help) {
      return (
        <NeedHelpModal
          show={show}
          onHide={this.onHide}
          email={email}
          onBack={this.onHelpBack}
        />
      )
    } else {
      return (
        <div>
          {magic_link_sent && this.renderMagicLinkSent()}
          {!!!magic_link_sent && <Modal show={show} onHide={this.onHide} id="reset-password-modal">
            <Modal.Header>
              {this.renderHeader(null)}
            </Modal.Header>
            <Modal.Body>
              {this.renderForm()}
            </Modal.Body>
            <Modal.Footer>
              {this.renderFooter()}
            </Modal.Footer>
          </Modal>}
        </div>
      );
    }
  }
};
