import { Button } from "@adquick/ui";
import accounting from 'accounting';
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { browserHistory } from 'react-router-legacy';
import "./index.css";

interface RootState {
  cart: any;
  campaign: any;
}

interface CartItems {
  [key: string] : {
    amount: Number
    label: string
    class: string
  }
}

const generateCartItems = (campaignTotals: Record<string, number>, enable_analytics_cpm: boolean, analytics_charge_method: string): CartItems => {
  const fee_display = campaignTotals["adquick_service_fee"] > 0 ? campaignTotals["fee_formatted"] : 0;
  const cart_items = {
    total_rate_card: {
      amount: campaignTotals["total_rate_card"], label: "Total Rate Card", class: "summary-text text-small-underline", format: 'money'
    },
    total_estimate: {
      amount: campaignTotals["media_cost"], label: "AdQuick Total (Estimate)", class: "summary-text", format: 'money'
    },
    aq_savings: {
      amount: campaignTotals["aq_savings"], label: "AdQuick Savings", class: "summary-text text-small-blue text-small-bold", format: 'money'
    },
    typical_fees: {
      amount: campaignTotals["typical_fees"], label: "Typical Fees (15%)", class: "summary-text text-small-underline", format: 'money'
    },
    adquick_fees: {
      amount: campaignTotals["adquick_service_fee"], label: `AdQuick Fee`, class: "summary-text", format: 'money'
    },
    fee_savings: {
      amount: campaignTotals["fee_savings"], label: "Fee Savings", class: "summary-text text-small-bold text-small-blue", format: 'money'
    },
    total_savings: {
      amount: campaignTotals["aq_savings"], label: `Total Savings`, class: "summary-text text-large-blue-bold savings-total", format: 'money'
    },
    media_costs: {
      amount: campaignTotals["media_cost"], label: enable_analytics_cpm && analytics_charge_method === 'baked_in' ? "Media & Analytics" : "Media Cost", class: "summary-text text-large", format: 'money'
    },
    prod_install: {
      amount: campaignTotals["prod_install"], label: "Production & Install", class: "summary-text text-large", format: 'money'
    },
    adquick_fee: {
      amount: campaignTotals["adquick_service_fee"], label: `AdQuick Fee`, class: "summary-text text-large-bold", format: 'money'
    },
    grand_total: {
      amount: campaignTotals["total"], label: "Grand Total", class: "summary-text text-large-blue-bold", format: 'money'
    },
    add_ons: campaignTotals["add_ons"]?.map(add_on => ({
      amount: add_on.total_price, label: add_on.name, class: "summary-text text-large", format: 'money'
    })),
    total_analytics: {
      amount: campaignTotals["total_analytics"], label: "Analytics", class: "summary-text text-large", numberStyle: analytics_charge_method === 'crossed_out' ? "text-line-through" : undefined, format: 'money'
    },
    total_estimated_impressions: {
      amount: campaignTotals["total_impressions"], label: "Total Estimated Imps", class: "summary-text text-large"
    },
    aggregate_cpm: {
      amount: campaignTotals["total_cpm"], label: "Aggregate CPM", class: "summary-text text-large", format: 'money'
    },
    total_average_weekly_impressions: {
      amount: campaignTotals["total_average_weekly_impressions"], label: "Total Average Weekly Imps", class: "summary-text text-large"
    },
    total_average_weekly_spend: {
      amount: campaignTotals["total_average_weekly_spend"], label: "Total Average Weekly Spend", class: "summary-text text-large", format: 'money'
    }
  };
  return cart_items;
}

const GenerateTable = (input): ReactElement => {
  return (
    <table>
      {input.table_array.map((item, idx) => (
        <tr key={idx} className={item.class}>
          <td>{item.label}</td>
          <td className={item.numberStyle} style={{ textAlign: "right" }}>
            {item.format === 'money' ? accounting.formatMoney(item.amount, "$", 2) : accounting.formatNumber(item.amount)}
          </td>
        </tr>
      ))}
    </table>
  );
};

const renderSavingsTable = (setShow, show: boolean, cart_items: CartItems, hide_adquick_savings: boolean) => {
  const savingsAreNegative = cart_items["total_savings"]["amount"] <= 0;

  if (savingsAreNegative || hide_adquick_savings) { return }

  return <div className="savings-table">
    <div className="savings-title-hide">
      <div className="summary-title">AdQuick Savings</div>
      <div
        className="summary-hide"
        onClick={() => {
          setShow(!show);
        }}
      >
        {show ? <i class='fa fa-angle-down' /> : <i class='fa fa-angle-up' />}
      </div>
    </div>
    {show && (
      <div className="summary-savings-tables-list">
        {<GenerateTable table_array={[cart_items["total_rate_card"], cart_items["total_estimate"]]} />}
        <hr />
        {<GenerateTable table_array={[cart_items["adquick_fees"]]} />}
        <hr />
      </div>
    )}
    <div className="savings-table-total-savings">
      <GenerateTable table_array={[cart_items["total_savings"]]} />
    </div>
  </div>
}

const renderSummaryTable = (cart_items: CartItems, hide_adquick_fee: boolean, enable_analytics_cpm: boolean, analytics_charge_method: string) => {
  const add_ons_array = cart_items["add_ons"];
  const priceSummaryArray = [
    cart_items["media_costs"],
    enable_analytics_cpm && (analytics_charge_method === 'line_item' || analytics_charge_method === 'crossed_out') && cart_items["total_analytics"],
    cart_items["prod_install"],
    !hide_adquick_fee && cart_items["adquick_fee"],
  ].filter(i => !!i)

  return <div className="summary-table">
    <p className="summary-title">Price Summary</p>
    <GenerateTable table_array={priceSummaryArray} />
    <hr />
    {!!add_ons_array.length && ([
        <GenerateTable table_array={add_ons_array} />,
        <hr />
      ])}
    <GenerateTable table_array={[cart_items["grand_total"]]} />
  </div>
}

const renderAdditionalDetails = (cart_items: CartItems) => {
  const tableItems = [
    cart_items["total_estimated_impressions"],
    cart_items["aggregate_cpm"],
    cart_items["total_average_weekly_impressions"],
    cart_items["total_average_weekly_spend"]
  ]

  return(
    <div className="summary-table additional-details-section">
      <p className="summary-title">Additional Details</p>

      <GenerateTable table_array={tableItems} />
    </div>
  )
}

const renderReachAndFrequency = (reachAndFrequency) => {
  if (reachAndFrequency == null || reachAndFrequency.length === 0) {
    return <div/>
  }

  // the conditional "reachAndFrequency.length === 3" isn't ideal because there might be exactly 3 reach and frequencies but not more
  // but to improve it we would have to make an additional count query, store it in the state, then use it in this conditional here
  return(
    <div className="summary-table">
      <p className="summary-title">Reach & Frequency</p>

      <ul className='reach-frequency-list'>
        {reachAndFrequency.map((reachAndFrequencyObject) => (
          <li>
            <span className='city'>
              {reachAndFrequencyObject.market_name}
            </span>
            <span className='reach'>
              Total Reach
              <strong>{accounting.formatNumber(reachAndFrequencyObject.reach)}%</strong>
            </span>
            <span className='frequency'>
              Avg Frequency
              <strong>{accounting.formatNumber(reachAndFrequencyObject.frequency)}</strong>
            </span>
          </li>
        ))}
      </ul>

      {reachAndFrequency.length === 3 && (<a href='#' className='view-all-reach-frequency-button'>View All Reach & Frequency</a>)}
    </div>
  )
}

const Summary = () => {
  const [show, setShow] = React.useState(true);
  const cart = useSelector((state: RootState) => state.cart);
  const campaign = useSelector((state: RootState) => state.campaign);
  const reachAndFrequency = campaign.reachAndFrequency;
  const { enable_analytics_cpm, analytics_charge_method } = campaign.campaign
  const campaignTotals: Record<string, number> = cart.totals;
  const cart_items = generateCartItems(campaignTotals, enable_analytics_cpm, analytics_charge_method);
  const hide_adquick_savings = !campaign.permissions.can_view_adquick_checkout_savings
  const hide_adquick_fee = !campaign.permissions.can_view_adquick_fee
  const can_use_new_checkout = campaign.permissions.can_use_new_checkout

  // handle the feature flag for this section
  const handleCheckoutClick = (): void => {
    if (can_use_new_checkout) {
      return window.location.assign(`/campaigns/${campaign.campaign.token}/checkout/billing`)
    }else {
      browserHistory.push(`/checkout/${campaign.campaign.token}/billing`);
    }
  };

  const cartRefreshButton = () => {
    if (campaign.permissions.can_use_corporation_checkout) {
      let url = `/api/v1/campaigns/${campaign.campaign.token}/cart_refresh/new`
      return <turbo-frame id={`campaign_cart_refresh_${campaign.campaign.token}`} loading="lazy"src={url}>
        Loading...
      </turbo-frame>
    }

    return <Button onClick={handleCheckoutClick}>Confirm Order</Button>
  }

  const checkoutButtonText = campaign.permissions.can_use_corporation_checkout ? "Submit Booking Request" : "Confirm Order";
  if (cart.isFetchingTotal) {
    return (
      <div>
        <p className="summary-header">Order Summary</p>
        <div className="order-summary-container">
          <div className="summary-table">
            <p className="summary-title">Fetching your summary data</p>
            <div className="summary-text">
              We're fetching your summary data and will soon be displayed here.
            </div>
          </div>
          {campaign.permissions.can_checkout && !campaign.permissions.can_use_corporation_checkout &&
            <Button onClick={handleCheckoutClick}>{checkoutButtonText}</Button>
          }
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <p className="summary-header">Order Summary</p>

        <div className="order-summary-container">
          {renderSavingsTable(setShow, show, cart_items, hide_adquick_savings)}
          {renderSummaryTable(cart_items, hide_adquick_fee, enable_analytics_cpm, analytics_charge_method)}
          {campaign.permissions.can_checkout &&
            cartRefreshButton()
          }
        </div>

        <div className="order-summary-container additional-details-container">
          {renderAdditionalDetails(cart_items)}
          {renderReachAndFrequency(reachAndFrequency)}
        </div>
      </div>
    );
  }
};

export default Summary;
