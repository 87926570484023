import Chart from "chart.js";
import Chartkick from "chartkick";
import React from 'react';
import createClass from 'create-react-class';

Chartkick.addAdapter(Chart);

export default createClass({

  componentDidMount() {
    this.renderCharts();
  },

  componentDidUpdate() {
    this.renderCharts();
  },

  renderChart(id, data) {
    if (!data || data.length !== 2) return;

    let max = Math.max(
      ...Object.values(data[0].data),
      ...Object.values(data[1].data),
    )

    const step = Math.round(max / 5);
    max = max % step == 0 ? max : max + step - (max % step);

    const library = {
      layout: {
        padding: {
          left: 0,
          right: 15,
          top: 15,
          bottom: 15
        }
      },
      scales: {
        yAxes: [{
          // display: false,
          ticks: {
            padding: 10,
            stepSize : step
          },
          gridLines: {
            display: true,
            drawBorder: false,
          }
        }],
        xAxes: [{
          display: false
        }]
      }
    }
    const options = { points: true, max: max, legend: false, colors: ["#4a90e2", "#f5a623"], library: library }
    new Chartkick.LineChart(id, data, options);
  },

  renderCharts() {
    this.renderChart("adwords-during-chart", this.during());
    this.renderChart("adwords-before-chart", this.before());
  },

  during() {
    return this.formatData(this.props.during);
  },

  before() {
    return this.formatData(this.props.before);
  },

  formatData(report) {
    if (!report) return;

    return [
      { name: "Experiment", data: this.extractMetric(report.campaign) },
      { name: "Control", data: this.extractMetric(report.control) }
    ];
  },

  extractMetric(dataset) {
    return dataset.days.reduce((acc, day) => {
      acc[day.day] = day[this.props.metric];
      return acc;
    }, {});
  },

  render() {
    return (
      <div className="charts">
        { this.props.children }
        <div className="chart-block">
          <p>During Outdoor Campaign</p>
          <div id="adwords-during-chart" className="chart" />
          { this.renderDates(this.props.during) }
        </div>
        <div className="chart-block">
          <p>Before Outdoor Campaign</p>
          <div id="adwords-before-chart" className="chart" />
          { this.renderDates(this.props.before) }
        </div>
      </div>
    );
  },

  start(report) {
    return report && this.props.dateFormat(report.start_at);
  },

  end(report) {
    return report && this.props.dateFormat(report.end_at);
  },

  renderDates(report) {
    return (
      <div className="dates">
        <div>
          <p>{ this.start(report) }</p>
        </div>
        <div>
          <p>{ this.end(report) }</p>
        </div>
      </div>
    );
  }

});
