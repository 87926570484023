import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showPrompt,
  onClose,
  sendInquiry,
  shareCampaign,
  GET_STARTED,
  SHARE_CAMPAIGN,
  LEARN_MORE,
  REQUEST_HOLD,
  CONTACT_EXECUTIVE,
 } from './actions';
import Prompt from './Prompt';
import Toast from './Toast';

const PROMPT_OPTIONS = {
  [GET_STARTED]: {
    type: GET_STARTED,
    title: 'Get started with AdQuick',
    fields: [
      {
        type: 'text',
        name: 'email',
        label: 'Email',
        required: true
      }
    ],
    acknowledgement: {
      title: 'Thank you for reaching out!',
      message: 'Someone from our team will be reaching out to you shortly!'
    }
  },
  [SHARE_CAMPAIGN]: {
    type: SHARE_CAMPAIGN,
    title: 'Share this campaign',
    fields: [
      {
        type: 'text',
        name: 'email',
        label: 'Your Email',
        required: true
      },
      {
        type: 'text',
        name: 'recipient_email',
        label: 'Recipient Email',
        required: true
      }
    ],
    acknowledgement: {
      title: 'Thank you for sharing!',
    }
  },
  [LEARN_MORE]: {
    type: LEARN_MORE,
    title: 'Learn more about AdQuick',
    fields: [
      {
        type: 'text',
        name: 'email',
        label: 'Your Email',
        required: true
      },
      {
        type: 'textarea',
        name: 'question',
        label: 'Anything you’d like to ask us?'
      }
    ],
    acknowledgement: {
      title: 'Thank you for reaching out!',
      message: 'Someone from our team will be reaching out to you shortly!'
    }
  },
  [REQUEST_HOLD]: {
    type: REQUEST_HOLD,
    title: 'Request a Hold',
    fields: [
      {
        type: 'text',
        name: 'email',
        label: 'Your Email',
        required: true
      }
    ],
    acknowledgement: {
      title: 'Hold Requested!',
      message: 'Someone from our team will be reaching out to you shortly!'
    }
  },
  [CONTACT_EXECUTIVE]: {
    type: CONTACT_EXECUTIVE,
    title: 'Get in contact with us!',
    fields: [
      {
        type: 'text',
        name: 'email',
        label: 'Your Email',
        required: true
      },
      {
        type: 'textarea',
        name: 'inquiry',
        label: 'What would you like to tell us?',
        required: true
      }
    ],
    acknowledgement: {
      title: 'Thank you for reaching out!',
      message: 'Someone from our team will be reaching out to you shortly!'
    }
  },
}

class ActionsMenu extends Component {

  constructor(props) {
    super(props);
    this.onShowPrompt = this.onShowPrompt.bind(this);
    this.sendInquiry = this.sendInquiry.bind(this);
    this.state = {
      email: this.props.user?.email,
    }
  }

  sendInquiry(type, data) {
    const { campaignId } = this.props.campaign;
    const { acknowledgement } = PROMPT_OPTIONS[type];
    if (type === SHARE_CAMPAIGN) {
      this.props.shareCampaign(campaignId, data, acknowledgement);
    } else {
      this.props.sendInquiry(campaignId, type, data, acknowledgement);
    }
  }

  onShowPrompt(type) {
    const { email } = this.state;
    const { showPrompt } = this.props;

    let options = PROMPT_OPTIONS[type];
    let skipPrompt = false;
    if (email) {
      skipPrompt = !_.filter(options.fields, (f) => { return f.name != 'email' }).length
    }
    skipPrompt ? this.sendInquiry(type, { email: email }) : showPrompt(options);
  }

  renderMenu() {
    const { campaign } = this.props;

    if (campaign.is_mock) {
      return this.renderMockCampaignMenu();
    } else {
      return this.renderNonMockCampaignMenu();
    }
  }

  renderMockCampaignMenu() {
    return ([
      <div key="get_started" className="item get_started" onClick={() => this.onShowPrompt(GET_STARTED)}>Get Started</div>,
      <div key="share_campaign" className="item share_campaign" onClick={() => this.onShowPrompt(SHARE_CAMPAIGN)}>Share Campaign</div>,
      <div key="learn_more" className="item learn_more" onClick={() => this.onShowPrompt(LEARN_MORE)}>Learn More</div>
    ])
  }

  renderNonMockCampaignMenu() {
    const { currentUser } = this.props;
    //remove hold request for supplier users and add a blank div 
    const hold_element = (currentUser && currentUser.is_supplier) ? <div></div> : <div key="request_hold" className="item request_hold" onClick={() => this.onShowPrompt(REQUEST_HOLD)}>Request Hold</div>
    let menu_component_array = [
      <div key="share_campaign" className="item share_campaign" onClick={() => this.onShowPrompt(SHARE_CAMPAIGN)}>Share Campaign</div>,
      hold_element,
      <div key="contact_sales" className="item contact_sales" onClick={() => this.onShowPrompt(CONTACT_EXECUTIVE)}>Contact Account Executive</div>
    ];
    
    return menu_component_array;
  }

  renderDialog() {
    const { email } = this.state;
    const { dialog } = this.props;
    if (!_.isEmpty(dialog.prompt)) {
      return <Prompt onSubmit={this.sendInquiry} onClose={this.props.onClose} email={email} />
    } else if (!_.isEmpty(dialog.acknowledgement)) {
      return <Toast onClose={this.props.onClose} />
    }
  }

  render() {
    return (
      <div id="mobile-actions">
        {this.renderMenu()}
        {this.renderDialog()}
      </div>
    )
  }
}

const mapStateToProps = ({
    currentUser,
    campaign,
    dialog,
  }) => ({
    currentUser,
    campaign,
    dialog,
});

export default connect(
  mapStateToProps,
  {
    showPrompt,
    onClose,
    sendInquiry,
    shareCampaign,
  }
)(ActionsMenu)
