import React from 'react';
import createClass from 'create-react-class'
import { Modal } from 'react-bootstrap';
import GlobalActions from '../../actions/GlobalActions'
import GlobalStore from '../../stores/GlobalStore'
import { Panel, Tabs, Tab } from 'react-bootstrap';

const ComposeEmail = createClass({
  displayName: 'ComposeEmail',

  getInitialState() {
    const { options } = this.props

    return {
      email: options.email,
      subject: options.subject || '',
      body: options.body || '',
      templates: [],
      selectedTemplate: null,
      isSending: false,
      activeTab: 'html_body'
    }
  },

  componentDidMount() {
    this.loadTemplates()
  },

  loadTemplates() {
    $.get('/api/v1/email_templates').then(templates => this.setState({ templates }))
  },

  onSubmit(e) {
    e.preventDefault()

    const { options } = this.props
    const { email, subject, html_body, text_body, file_attachment, selectedTemplate } = this.state

    const message = {
      email,
      subject,
      html_body,
      text_body,
      file_attachment,
      sendable_id: options.sendable_id,
      sendable_type: options.sendable_type,
      email_template_id: selectedTemplate.id,
      contact_ids: options.contactIds ? options.contactIds.toArray() : null
    }

    this.setState({isSending: true}, () => {
      $.post('/api/v1/messages', { message }).then(response => {
        if (response.errors) {
          this.setState({ errors: response.errors })
        } else {
          GlobalStore.trigger('email_sent')
          GlobalActions.showMessage("Message sent!")
          GlobalActions.closePopup()
        }

        this.setState({ isSending: false })
      })
    })
  },

  onChangeTemplateId(templateId) {
    const selectedTemplate = this.state.templates.find(t => t.id == templateId)
    const { options } = this.props
    const { subject, html_body, text_body, file_attachment } = selectedTemplate

    let customFields = []
    if (html_body && html_body.includes('{custom_message}')) {
      customFields.push('custom_message')
    }

    let filled_html_body = html_body
    let filled_text_body = text_body
    let filled_subject = subject

    Object.keys(options).forEach(key => {
      const regexp = new RegExp(`{${key}}`, 'g')
      filled_html_body = filled_html_body.replace(regexp, options[key])
      filled_text_body = filled_text_body.replace(regexp, options[key])
      filled_subject = filled_subject.replace(regexp, options[key])
    })

    this.setState({
      selectedTemplate,
      subject: filled_subject,
      html_body: filled_html_body,
      text_body: filled_text_body,
      file_attachment,
      customFields
    })
  },

  render() {
    const { show, onHide, options } = this.props
    const { email, subject, customMessage, templates, selectedTemplate, customFields, activeTab, html_body, text_body, isSending, file_attachment } = this.state

    const hasRecipient = email || (options.contactIds && options.contactIds.length > 0)
    const valid = hasRecipient && subject

    return (
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header>
          <Modal.Title>Compose Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={this.onSubmit}>
            {(options.contactIds && options.contactIds.length > 0) ? <div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <label>Bulk emailing {options.contactIds.length} contacts</label>
                </div>
              </div>
            </div> :
            <div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <label>Email</label>
                  <input className="form-control" defaultValue={email} onChange={e => this.setState({ email: e.target.value })} />
                </div>
              </div>
            </div>}
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Template</label>
                <select className="form-control" onChange={e => this.onChangeTemplateId(e.target.value)}>
                  <option></option>
                  {templates.map(template => <option key={template.id} value={template.id}>{template.name}</option>)}
                </select>
              </div>
            </div>
            {selectedTemplate && <div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <label>Subject</label>
                  <input className="form-control" defaultValue={subject} onChange={e => this.setState({ subject: e.target.value })} />
                </div>
              </div>
              {customFields.includes('custom_message') && <div className="form-group row" style={{height:375}}>
                <div className="col-sm-12">
                  <label>Custom Message</label>
                  <textarea className="form-control" style={{height:100}} defaultValue={customMessage} onChange={e => this.setState({ customMessage: e.target.value})} />
                </div>
              </div>}
              <Tabs activeKey={activeTab} onSelect={activeTab => this.setState({activeTab})} id="edit-unit-tabs">
                <Tab title="HTML Body" eventKey="html_body">
                  <textarea className="form-control" style={{height:400}} value={html_body} onChange={e => this.setState({ html_body: e.target.value })} />
                </Tab>
                <Tab title="Text Body" eventKey="text_body">
                  <textarea className="form-control" style={{height:400}} value={text_body} onChange={e => this.setState({ text_body: e.target.value })} />
                </Tab>
                <Tab title="Variables" eventKey="variables">
                  <table className="table">
                    <tbody>
                      {Object.keys(options).map(key => (
                        <tr>
                          <td>{key}</td>
                          <td>{options[key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Tab>
              </Tabs>
              {file_attachment && <div className="form-group row" style={{margin:15}}>
                <a href={`/assets/emails/${file_attachment}`} target="_blank">
                  <i className="fa fa-paperclip" />
                  <span style={{marginLeft:5}}>{file_attachment}</span>
                </a>
              </div>}
              <div className="form-group row" style={{marginTop:10}}>
                <div className="col-sm-12">
                  <button className="btn btn_primary register__submit" type="submit" onClick={this.onSubmit} disabled={!valid || isSending}>Send</button>
                </div>
              </div>
            </div>}
          </form>
        </Modal.Body>
      </Modal>
    )
  }
})

export default ComposeEmail
