import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { connect } from 'react-redux';
import {
  fetchRows,
  fetchCampaigns,
  setFilters,
  hideModal,
  viewMoreResults,
  viewAllResults,
  filterByFaceIds,
  addToCampaign,
  fetchFlightTypes,
  showEditFlightModal,
  selectCampaign
} from './actions';
import { setSelectedUnits } from './Unit/actions';
import ResultsTable from './ResultsTable';
import Toolbar from './Toolbar';
import Timeline from './Timeline';
import AuthStore from '../../stores/AuthStore';
import GlobalActions from '../../actions/GlobalActions';
import FiltersBar from './Filters/FiltersBar';
import Loading from '../Loading';
import Measure from "react-measure";

class Inventory extends React.Component {

  constructor(props) {
    super(props);
    let campaign_id, query
    if (this.props.location && this.props.location.query) {
      campaign_id = this.props.location.query.campaign
      query = this.props.location.query.query
    };

    this.state = {
      isLoggedIn: AuthStore.isLoggedIn(),
      supplierUsers: [],
      selectedUnitIds: [],
      campaign_id: campaign_id,
      query: query,
      periodForecast: null,
      user: AuthStore.getState().user,
      loading: false,
    };
    this.onUnitSelect = this.onUnitSelect.bind(this);
    this.onUnitSelectAll = this.onUnitSelectAll.bind(this);
    this.onUnitDeselectAll = this.onUnitDeselectAll.bind(this);
    this.onShowEditFlightModal = this.onShowEditFlightModal.bind(this);
    this.onAuthChange = this.onAuthChange.bind(this);
    this.onViewAll = this.onViewAll.bind(this);
  }

  componentWillUnmount() {
    document.title = window.AppConfig.defaultTitle;
  }

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ]
    if (AuthStore.isLoggedIn()) {
      this.props.fetchCampaigns()
      this.props.fetchFlightTypes()
      this.props.setFilters({
        campaign: this.state.campaign_id,
        query: this.state.query
      });
    }
    document.title = 'Inventory | AdQuick';

    const params = this.props.params || {};
    const unitId = params.unit_id;
    if (unitId) {
      GlobalActions.openUnitID(unitId, { onPageLoad: true, isSearchView: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isFetching) this.setState({ loading: false })
  }

  onUnitSelect(unitId, isSelected, reset = false) {
    const { setSelectedUnits } = this.props
    const { selectedUnitIds } = this.state
    let units_to_select = [...selectedUnitIds]

    if (reset) units_to_select = [unitId]
    else if (isSelected === true) units_to_select.push(unitId)
    else units_to_select = selectedUnitIds.filter(selectedUnitId => selectedUnitId != unitId)

    this.setState({ selectedUnitIds: units_to_select }, setSelectedUnits(units_to_select))
  }

  onUnitSelectAll(isSelected) {
    const { setSelectedUnits, units } = this.props
    let units_to_select = []
    if (isSelected === true) units_to_select = units.map(u => u.id)

    this.setState({ selectedUnitIds: units_to_select }, setSelectedUnits(units_to_select))
  }

  onUnitDeselectAll() {
    const { setSelectedUnits } = this.props
    this.setState({ selectedUnitIds: [] }, setSelectedUnits([]));
  }

  onShowEditFlightModal(unit, campaign_unit) {
    const { selectCampaign, showEditFlightModal } = this.props
    const { user } = this.state
    if (user.is_supplier_sales && campaign_unit.workflow_state != 'on_hold') return
    selectCampaign(campaign_unit.campaign_id)
    showEditFlightModal(unit, campaign_unit)
  }

  onAuthChange() {
    this.setState({ isLoggedIn: AuthStore.isLoggedIn() })

    if (AuthStore.isLoggedIn()) {
      this.props.fetchRows()
    } else {
      GlobalActions.openPopup('login')
    }
  }

  onViewAll() {
    const { viewAllResults } = this.props;
    this.setState({ loading: true }, viewAllResults());
  }

  render() {
    const {
      filters,
      setFilters,
      units,
      isFetching,
      isFetchingUnits,
      more_results_count,
      limit,
      viewMoreResults,
      flightTypes,
      selected_units,
    } = this.props
    const { selectedUnitIds, user, loading } = this.state


    let timelineWrapperClassName = "timeline_container col-sm-12"
    if (isFetching || isFetchingUnits) timelineWrapperClassName += " loading_blank"

    const isLoading = loading || isFetchingUnits
    const filters_height = this.state.filters_height || 49;
    const showViewAll = (user.is_supplier_admin || user.is_supplier_sales) && more_results_count > 0;

    if (this.state.isLoggedIn) {
      return (
        <div id="inventory_toolbar">
          <div
            className="sticky"
            style={{
              position: "sticky",
              padding: 15,
              paddingBottom: 0,
              marginTop: 0,
              top: 0,
              zIndex: 900,
              backgroundColor: '#ffffff',
              height: (filters_height + 63)
            }}>
            <Toolbar
              selectedUnits={selected_units}
              onDeleteFlight={this.onDeleteFlight}
              conflicts={this.state.conflicts}
              holdsAdded={this.state.holdsAdded}
              filterByFaceIds={this.props.filterByFaceIds}
              user={AuthStore.getState().user}
              flightTypes={flightTypes}
              supplierToken={this.props.supplier && this.props.supplier.token}
              onUnitSelectAll={this.onUnitSelectAll}
              onUnitDeselectAll={this.onUnitDeselectAll}
              onViewAll={this.onViewAll}
              showViewAll={showViewAll}
            />
            <Measure onMeasure={({ height, width }) => this.setState({ filters_height: height })}>
              <FiltersBar
                onFilter={filters => setFilters(filters)}
                filters={filters || {}}
                activeFilters={[]}
              />
          </Measure>
          </div>
          <div className="container-fluid page-inventory">
            <div className="row">
              {isLoading ? <Loading/> : <div className={timelineWrapperClassName} style={{ marginBottom: '30px' }}>
                <div>
                  {filters.mode == 'timeline' &&
                    <Timeline
                      selectedUnitIds={selectedUnitIds}
                      onUnitSelect={this.onUnitSelect}
                      onUnitSelectAll={this.onUnitSelectAll}
                      onShowEditFlightModal={this.onShowEditFlightModal}
                      periodForecast={this.state.periodForecast}
                      units={units}
                      filters={filters}
                    />
                  }
                  {filters.mode == 'grid' && <ResultsTable {...this.props} />}
                  {(units.length == 0 && !isFetching) &&
                    <div style={{ textAlign: 'center', padding: 20 }}>
                      There were no units that matched your query
                    </div>
                  }
                </div>
                {more_results_count > 0 && <div className="row" style={{ padding: '0 15px 0 15px' }}>
                  <div className="view-more-results" onClick={() => { this.setState({ loading: true }, viewMoreResults()) }}>
                    View {limit} More ({more_results_count} more results)
                  </div>
                </div>}
                {showViewAll && <div className="row" style={{ padding: '0 15px 0 15px' }}>
                  <div className="view-more-results" onClick={this.onViewAll}>
                    View All Results
                  </div>
                </div>}
              </div>}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="text-center" style={{ padding: 50 }}>Please login</div>
      )
    }
  }
};

Inventory.propTypes = {
  fetchRows: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired
};

export default connect(
  ({ inventory }) => ({ ...inventory }),
  {
    fetchRows, fetchCampaigns, setFilters, hideModal, viewMoreResults, viewAllResults,
    filterByFaceIds, addToCampaign, fetchFlightTypes, showEditFlightModal, selectCampaign,
    setSelectedUnits,
  },
)(Inventory)
