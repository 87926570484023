export default class WaitTyper {

  constructor(wait_time = 1000) {
    this.wait_time = wait_time;
  }

  onStopTyping(callback) {
    this.last_type_at = new Date().getTime();

    setTimeout(() => {
      if (this.hasStopTyping()) callback();
    }, this.wait_time);
  }

  hasStopTyping() {
    return new Date().getTime() - this.last_type_at >= this.wait_time
  }
}
