import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const configureStore = (initialState, middlewares) => {
  const store = createStore(
    rootReducer(initialState),

    initialState,
    composeWithDevTools(
      applyMiddleware(...middlewares),
    ),
  );
  return store;
};

export default configureStore;
