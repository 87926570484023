import createClass from "create-react-class";
import React from "react";
import Async from "react-select/async";

// docs:
// https://developers.google.com/maps/documentation/javascript/places-autocomplete
// https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
// https://docs.mapbox.com/mapbox.js/api/v3.0.1/l-mapbox-geocoder/
declare const AppConfig: { mapboxAccessToken: string };

const accessToken = AppConfig.mapboxAccessToken;

export function toCampaign(markets) {
  return {
    geography: markets.map(m => m.label).join(";"),
    places: markets.map(m => {
      return {
        name: m.label,
        place_type: m.value.place_type,
        place_id: m.value.place_id,
        center: m.value.center,
        geometry: m.value.geometry
      };
    })
  };
}

declare const L: any;

export default createClass({
  displayName: "MarketSearch",
  selectInput: null,

  getInitialState() {
    return {
      value: this.props.value,
    };
  },

  componentDidMount() {
    this.props.shouldAutoFocus && this.selectInput.focus();
    if (typeof L === "undefined" || !accessToken) {
      return;
    }
    L.mapbox.accessToken = accessToken;
    this.mapboxGeocoder = L.mapbox.geocoder("mapbox.places");
  },

  async getOptions(input) {
    if (!input) { return []; }
    return new Promise((resolve, reject) => {
      this.mapboxGeocoder.query(
        { query: input, types: ["postcode", "district", "place", "locality", "neighborhood", "address"] },
        (err, res) => {
          if (err != null) {
            if (DEBUG) {
              console.log("Mapbox Places Error:", err);
            }
            reject(err);
            return;
          }
          if (DEBUG) {
            console.log("Mapbox Places:", res);
          }
          const results = res.results.features.map(feature => {
            return {
              label: feature.place_name,
              value: {
                name: feature.place_name,
                place_id: feature.id,
                place_type: feature.place_type,
                center: feature.center,
                geometry: feature.geometry,
              },
            };
          });
          resolve(results);
        },
      );
    });
  },

  render() {
    return (
      <div className="market_search">
        <Async
          disabled={this.props.disabled}
          className="selectInput"
          clearable={false}
          defaultOptions={true}
          placeholder="Search for a market location like a city or state"
          value={this.props.markets}
          isMulti
          onChange={this.props.onMarketsChange}
          loadOptions={this.getOptions}
          arrowRenderer={(): null => null}
          ref={input => {
            this.selectInput = input;
          }}
        />
      </div>
    );
  },
});
