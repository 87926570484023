import React from 'react';
import { Modal } from 'react-bootstrap';
import { post } from "../../utils/api";
import { connect } from 'react-redux';
import Select from "react-select";
import { removeCampaignUnits } from "../Campaign/actions";
import GlobalActions from '../../actions/GlobalActions';
import JobProgress from '../../utils/JobProgress';

const Reasons = [
  { label: 'Location', value: 'location' },
  { label: 'Price', value: 'price' },
  { label: 'Image Quality', value: 'image_quality' },
  { label: 'Wrong for this Campaign', value: 'wrong_for_this_campaign' },
  { label: 'Incomplete Data', value: 'incomplete_data' }
];
class RemoveUnits extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      removal_reason: "",
      job: new JobProgress()
    };
    this.onRemoveUnits = this.onRemoveUnits.bind(this);
    this.onRemoveUnitsComplete = this.onRemoveUnitsComplete.bind(this);
  }

  async onRemoveUnits() {
    const { unit_ids, from_campaign_id } = this.props;
    const { removal_reason } = this.state;

    addLoadingStep("Removing units");
    const { job_id } = await post(`/api/v1/campaigns/${from_campaign_id}/batch_remove_units`, { unit_ids, removal_reason });

    this.interval = this.state.job.startPolling(job_id, this.onRemoveUnitsComplete);
  }

  async onRemoveUnitsComplete({ data }) {
    const { onHide } = this.props
    const { removed_count, packages_removed_count, error } = data;
    if (error) return GlobalActions.showError(error);
    await this.props.removeCampaignUnits(this.props.unit_ids);
    GlobalActions.showMessage(`${removed_count} unit(s) removed. ${packages_removed_count} package(s) removed.`);
    removeLoadingStep("Removing units");
    onHide();
  }

  render() {
    const { unit_ids, hasRequests } = this.props;

    if (hasRequests) {
      return (
        <div>
          <Modal.Header className="text-center" closeButton>
            <b>Are you sure you want to remove {unit_ids.length} unit(s)?</b>
          </Modal.Header>
          <Modal.Body>
            <Select
              placeholder="Select a removal reason"
              required={true}
              options={ Reasons }
              onChange={removal_reason => this.setState({ removal_reason: removal_reason.value })}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.onRemoveUnits}
              className="btn btn-primary">Remove
            </button>
            <button onClick={this.props.onCancel} className="btn btn-danger" style={{float:'left'}}>Cancel</button>
          </Modal.Footer>
        </div>
      )
    } else {
        return (
          <div>
            <Modal.Header className="text-center" closeButton>
              <b>Are you sure you want to remove {unit_ids.length} unit(s)?</b>
            </Modal.Header>
            <Modal.Footer>
              <button
                onClick={this.onRemoveUnits}
                className="btn btn-primary">Remove
              </button>
              <button onClick={this.props.onCancel} className="btn btn-danger" style={{float:'left'}}>Cancel</button>
            </Modal.Footer>
          </div>
        )
      }
    }
  }

const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { removeCampaignUnits })(RemoveUnits);
