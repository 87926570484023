import React from 'react';
import createClass from 'create-react-class';
import { post } from '../../utils/api';
import { Modal } from 'react-bootstrap';
import SelectCampaign from '../SelectCampaign'
import GlobalStore from '../../stores/GlobalStore'
import JobProgress from '../../utils/JobProgress'

const METADATA_URL = '/api/v1/metadata';

const AddUnitsFromMarkers = createClass({
  displayName: 'AddUnitsFromMarkers',

  getInitialState() {
    return {
      campaign: null,
      markers: this.props.markers || [],
      addedToCampaign: false,
      saving: false,
      job_pooling: new JobProgress()
    };
  },

  componentWillUnmount(){
    if (this.interval) {
      clearInterval(this.interval)
    }
  },

  async onAddToCampaign() {
    const { markers, campaign } = this.state
    const unit_ids = this.findUnitsOnMarkers(markers)

    this.setState({ saving: true })

    const { job_id } = await post(`/api/v1/campaigns/${campaign.value}/batch_add_units`, { unit_ids })
    this.interval = this.state.job_pooling.startPolling(job_id, this.onJobComplete)
  },

  onJobComplete(response) {
    const { data } = response
    this.setState({
      addedToCampaign: true,
      saving: false,
      unitAddedCount: data.total_imported,
      unitSkippedCount: data.total_failed
    })
  },

  async onUpdateCannabisFriendly(flow) {
    const { markers } = this.state
    const unit_ids = this.findUnitsOnMarkers(markers)
    await post(`/api/v1/units/mark_as_cannabis_friendly`, { unit_ids, flow })
    const message = `${unit_ids.length} units ${this.feedbackMessage(flow)}`
    GlobalStore.onShowMessage(message)
  },

  feedbackMessage(flow) {
    const flowMessage = {
      friendly: 'marked as cannabis friendly',
      not_friendly: 'marked as not cannabis friendly'
    }
    return flowMessage[flow]
  },

  findUnitsOnMarkers(markers)  {
    return markers.map(marker => {
      if(marker.options) return marker.options.id
      return marker.properties.id
    })
  },

  render() {
    const { markers, addedToCampaign, campaign, unitAddedCount, unitSkippedCount, saving } = this.state;
    const campaign_id = campaign && campaign.value;
    return (
        <Modal.Body className="modal-body-padding">
          <h4>Add {markers.length} units to a campaign</h4>
          <p className='modal-description'>Select the campaign you want to add the units to.</p>
          <label>Campaign</label>
          {!addedToCampaign && <SelectCampaign campaign={campaign} onSelectCampaign={campaign => this.setState({campaign})} />}
          {addedToCampaign && <div className="color-green">
            <div><i className="fa fa-check" /> Added {unitAddedCount} units to the campaign</div>
            {!!parseInt(unitSkippedCount) && <div style={{marginTop: '5px'}}><i className="fa fa-exclamation" /> Skipped {unitSkippedCount} units</div>}
          </div>}
          {false &&
            <button className="btn btn-default" onClick={() => this.onUpdateCannabisFriendly('friendly')} style={{marginRight:5}}>Mark as cannabis friendly</button>
          }
          {false &&
            <button className="btn btn-default" onClick={() => this.onUpdateCannabisFriendly('not_friendly')} style={{marginRight:5}}>Mark as not cannabis friendly</button>
          }
          <div className='modal-actions'>
            {!addedToCampaign && <button onClick={this.props.onHide} className="btn" disabled={saving}>Cancel</button>}
            {!addedToCampaign && <button onClick={this.onAddToCampaign} className="btn btn-primary" disabled={campaign == null || saving}>{saving ? "Saving..." : "Add"}</button>}
            {addedToCampaign && <a href={`/campaigns/${campaign_id}`} target="_blank" className="btn" style={{marginRight:5}}>Open Campaign</a>}
            {addedToCampaign && <button onClick={this.props.onHide} className="btn">Done</button>}
          </div>
        </Modal.Body>
    );
  },
});

export default AddUnitsFromMarkers;
