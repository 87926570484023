const API_URL = "/api/v1/campaigns";
const SUPPLIER_TASK_API_URL = "/api/v1/supplier_tasks";
import { get, post, put, del } from "../../../utils/api";

const prefix = "requests/campaign_requests";
export const FETCHING_SUPPLIER_TASKS = `${prefix}/FETCHING_SUPPLIER_TASKS`

export const SET_CAMPAIGN_ID = `${prefix}/SET_CAMPAIGN_ID`
export const setCampaignId = campaign_id => async (dispatch, getState) => {
  dispatch({ type: SET_CAMPAIGN_ID, payload: { campaign_id } });
};

export const FETCH_SUPPLIER_TASKS_SUCCESS = `${prefix}/FETCH_SUPPLIER_TASKS_SUCCESS`
export const fetchSupplierTasks = campaign_token => async (dispatch, getState) => {
  dispatch({type: FETCHING_SUPPLIER_TASKS })
  const campaign_id = campaign_token || getState().adquick_requests_ui.campaign_id;
  const res = await get(`${API_URL}/${campaign_id}/supplier_tasks`);
  if (res.data) {
    const { supplier_tasks } = res.data;
    dispatch({
      type: FETCH_SUPPLIER_TASKS_SUCCESS,
      payload: { supplier_tasks }
    });
  } else {
    console.log("fetch supplier tasks failed", res);
  }
};

export const ADD_SUPPLIER_TASK_SUCCESS = `${prefix}/ADD_SUPPLIER_TASK_SUCCESS`
export const createSupplierTask = params => async (dispatch, getState) => {
  const { campaign_id } = getState().adquick_requests_ui;
  const res = await post(`${API_URL}/${campaign_id}/supplier_tasks`, {
    supplier_task: params.task,
    supplier_ids: params.supplier_ids
  });
  if (res.data) {
    const { supplier_tasks } = res.data;
    dispatch({ type: ADD_SUPPLIER_TASK_SUCCESS, payload: { supplier_tasks } });
  } else {
    console.log("add supplier task failed", res);
  }
};

export const UPDATE_SUPPLIER_TASK_SUCCESS = `${prefix}/UPDATE_SUPPLIER_TASK_SUCCESS`
export const updateSupplierTask = params => async (dispatch, getState) => {
  const { campaign_id } = getState().adquick_requests_ui;
  const res = await put(`${API_URL}/${campaign_id}/supplier_tasks/${params.task.id}`, {
    supplier_task: params.task
  });
  if (res.data) {
    const { supplier_task } = res.data;
    dispatch({ type: UPDATE_SUPPLIER_TASK_SUCCESS, payload: { supplier_task } });
  } else {
    console.log("update supplier task failed", res);
  }
};

export const DELETE_SUPPLIER_TASK_SUCCESS = `${prefix}/DELETE_SUPPLIER_TASK_SUCCESS`
export const deleteSupplierTask = supplier_task_id => async (dispatch, getState) => {
  const { campaign_id } = getState().adquick_requests_ui;
  const res = await del(`${API_URL}/${campaign_id}/supplier_tasks/${supplier_task_id}`);
  if (res.status == "200") {
    dispatch({ type: DELETE_SUPPLIER_TASK_SUCCESS, payload: { supplier_task_id } });
  } else {
    console.log("delete supplier task failed", res);
  }
};

export const COMPLETE_SUPPLIER_TASK = `${prefix}/COMPLETE_SUPPLIER_TASK`;
export const completeSupplierTask = supplier_task_id => async (dispatch, getState) => {
  const supplier_tasks = getState().adquick_requests_ui.campaign_requests.supplier_tasks;
  const current_supplier_task = supplier_tasks.filter(st => st.id === supplier_task_id);
  if (current_supplier_task.completed) return current_supplier_task;
  try {
    const supplier_task = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/completed`);
    dispatch({ type: COMPLETE_SUPPLIER_TASK, payload: { supplier_task }})
    dispatch({ type: UPDATE_SUPPLIER_TASK_SUCCESS, payload: { supplier_task } })
    return supplier_task;
  } catch (error) {
    console.log("Complete SupplierTask failed.", error);
    throw error;
  }
};

export const COMPLETE_ALL = `${prefix}/COMPLETE_ALL_CAMPAIGN_REQUESTS`;
export const completeAllOpenSupplierTasks = (campaign_token) => async (dispatch, getState) => {
  try {
    const response = await post(`${API_URL}/${campaign_token}/supplier_tasks/complete_all`,);

    dispatch({ type: COMPLETE_ALL, payload: response })
    return response;
  } catch (error) {
    console.log("Complete all SupplierTasks failed.", error);
    throw error;
  }
};

export const CANCEL_SUPPLIER_TASK = `${prefix}/CANCEL_SUPPLIER_TASK`;
export const cancelSupplierTask = supplier_task_id => async (dispatch, getState) => {
  const supplier_tasks = getState().adquick_requests_ui.campaign_requests.supplier_tasks;
  const current_supplier_task = supplier_tasks.filter(st => st.id === supplier_task_id);
  if (current_supplier_task.canceled) return current_supplier_task;
  try {
    const supplier_task = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/cancel`);
    dispatch({ type: CANCEL_SUPPLIER_TASK, payload: { supplier_task }})
    dispatch({ type: UPDATE_SUPPLIER_TASK_SUCCESS, payload: { supplier_task } })
    return supplier_task;
  } catch (error) {
    console.log("Canceling SupplierTask failed.", error);
    throw error;
  }
};

export const RELEASE_SUPPLIER_TASK = `${prefix}/RELEASE_SUPPLIER_TASK`;
export const releaseSupplierTask = supplier_task_id => async (dispatch, getState) => {
  const supplier_tasks = getState().adquick_requests_ui.campaign_requests.supplier_tasks;
  const current_supplier_task = supplier_tasks.filter(st => st.id === supplier_task_id);
  try {
    const supplier_task = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/release`);
    dispatch({ type: RELEASE_SUPPLIER_TASK, payload: { supplier_task }})
    dispatch({ type: UPDATE_SUPPLIER_TASK_SUCCESS, payload: { supplier_task } })
    return supplier_task;
  } catch (error) {
    console.log("Releasing SupplierTask failed.", error);
    throw error;
  }
};

export const CHANGE_SUPPLIER_TASK_ASSIGNEE = `${prefix}/CHANGE_SUPPLIER_TASK_ASSIGNEE`;
export const changeAssignee = (supplier_task_id, user_id) => async (dispatch, getState) => {
  const supplier_tasks = getState().adquick_requests_ui.campaign_requests.supplier_tasks;
  const current_supplier_task = supplier_tasks.filter(st => st.id === supplier_task_id);
  try {
    const supplier_task = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/change_assignee`, {
      user_id: user_id
    });
    dispatch({ type: CHANGE_SUPPLIER_TASK_ASSIGNEE, payload: { supplier_task }})
    dispatch({ type: UPDATE_SUPPLIER_TASK_SUCCESS, payload: { supplier_task } })
    return supplier_task;
  } catch (error) {
    console.log("Changing SupplierTask assignee failed.", error);
    throw error;
  }
};
