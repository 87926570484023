export default class SquareControl {

  constructor(mapDrawer) {
    this.mapDrawer = mapDrawer;
    this.active = false;
  }

  onAdd(map){
    this.map = map;
    this.container = document.createElement('div');
    //mapboxgl-ctrl class is needed for the control to be clickable
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group mapbox-ctrl-square-control';

    const icon = document.createElement('button');
    icon.className = 'square-control';
    this.container.appendChild(icon);

    this.container.onclick = this.onClick.bind(this);
      
    return this.container;
  }

  onClick() {
    if(this.mapDrawer.getMode() == 'draw_rectangle') {
      this.mapDrawer.changeMode('simple_select');
    } else {
      this.mapDrawer.changeMode('draw_rectangle')
    }

    this.active = !this.active;
    if(this.active) {
      this.map.boxZoom.disable();
      this.map.dragPan.disable();
    } else {
      this.map.boxZoom.enable();
      this.map.dragPan.enable();
    }
  }

  onRemove(){
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

}

