import { LOAD_MARKETS } from '../actions/markets_actions';
import { Market } from '../models/market';

type Action = {type: string, payload: any};

export default(state: Market[] | null, action: Action): Market[] | null => {
  switch(action.type) {
    case LOAD_MARKETS: return action.payload.markets;
    default: return state || null;
  }
}
