import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { getDesignAssetsIds, getPopIds } from "../../../../models/design_assets";
import { get } from "../../../../utils/api";
import JobProgress from "../../../../utils/JobProgress";
import * as styleGen from "../styles";
import TakeDownDatesModal from "./TakeDownDatesModal";
import BulkPostedUnitDetails from "../BulkPostedUnitDetails";

interface BulkActionsProps {
  units: any[];
  unit_selection_map: any;
  status: string;
  campaign_permissions: any;
  campaign: any;
  pops: Array<{
    id: number;
    campaign_unit_token: string;
    pop: Array<{ id: number }>;
  }>;
  unselectAll: () => void;
  onToggleSelectAll: (selected: boolean) => void;
}

interface BulkActionsState {
  show_takedown_date_modal: boolean;
  loading: boolean;
  job_polling: {
    startPolling: (job_id: string, callback: any) => void;
  };
}

class BulkActions extends Component<BulkActionsProps, BulkActionsState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show_takedown_date_modal: false,
      job_polling: new JobProgress(),
    };

    this.downloadSelectedPops = this.downloadSelectedPops.bind(this);
    this.downloadSelectedDesignAssets = this.downloadSelectedDesignAssets.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.onHideTakedownModal = this.onHideTakedownModal.bind(this);
    this.onShowTakedownModal = this.onShowTakedownModal.bind(this);
    this.onCompleteZipFile = this.onCompleteZipFile.bind(this);
    this.toogleLoading = this.toogleLoading.bind(this);
  }

  public render() {
    const { units, unit_selection_map } = this.props;
    const all_selected = _.every(units, unit => unit_selection_map[unit.id]);

    return (
      <div className="bulk_actions" style={styleGen.actions()}>
        {this.renderActionsDropdown()}
        {this.renderTakedownDatesModal()}
        <input type="checkbox" onChange={this.selectAll} checked={all_selected} style={styleGen.checkbox()}></input>
      </div>
    );
  }

  private toogleLoading() {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  private selectedUnits() {
    const { units, unit_selection_map } = this.props;
    return units.filter(unit => unit_selection_map[unit.id]);
  }

  private renderActionsDropdown() {
    const { campaign_permissions } = this.props;
    const { can_set_takedown_dates, can_operate_install } = campaign_permissions;
    const selected_units = this.selectedUnits();
    const disabled = selected_units.length > 0 ? "" : "disabled";

    return (
      <div
        className="btn-group"
        style={{
          float: "right",
          marginBottom: 10,
        }}
      >
        <div className="dropdown">
          <button
            className={`btn btn-default dropdown-toggle ${disabled}`}
            type="button"
            data-toggle="dropdown">
            {this.state.loading ? "Processing..." : "Actions" }
            <span className="caret" style={{ marginLeft: "5px", marginBottom: "2px" }} />
          </button>
          <ul className="dropdown-menu dropdown-menu-left">
            <li className="dropdown-item">
              {this.renderDownload("Download Selected POPs", this.downloadSelectedPops)}
            </li>
            <li className={"dropdown-item"}>
              {this.renderDownloadDesignAssets()}
            </li>
            {can_set_takedown_dates &&
              <li className={"dropdown-item"}>
                <a onClick={this.onShowTakedownModal}>Update Take Down Dates</a>
              </li>
            }
            {can_operate_install &&
              <li className={"dropdown-item"}>
                {this.renderBulkPostedUnitDetails()}
              </li>
            }
          </ul>
        </div>
      </div>
    );
  }

  onShowTakedownModal() {
    this.setState({ show_takedown_date_modal: true });
  }

  onHideTakedownModal() {
    this.setState({ show_takedown_date_modal: false });
  }

  renderBulkPostedUnitDetails() {
    const { campaign: { campaignId } } = this.props;
    const selectedUnits = this.selectedUnits();
    const campaignUnitTokens = selectedUnits.map(u => u.campaign_unit_token);
    return (
      <BulkPostedUnitDetails
        toggleLoading={this.toogleLoading}
        campaignToken={campaignId}
        campaignUnitTokens={campaignUnitTokens}
      />
    )
  }

  private renderTakedownDatesModal() {
    const selected_units = this.selectedUnits();
    const { campaign } = this.props;
    const { show_takedown_date_modal } = this.state;

    return (
      <TakeDownDatesModal
        campaign={campaign}
        show={show_takedown_date_modal}
        onHide={this.onHideTakedownModal}
        selected_units={selected_units}
      />
    );
  }

  private selectAll(event) {
    this.props.onToggleSelectAll(event.target.checked);
  }

  private renderDownload(label, onButtonClick) {
    const { campaign_permissions } = this.props;
    const { can_view_download_all_pops } = campaign_permissions;

    if (can_view_download_all_pops) {
      return (
        <a onClick={onButtonClick} target="_blank">
          <i className="fa fa-cloud-download" style={{ marginRight: 5 }} /> {label}
        </a>
      );
    } else {
      return <div className="download_all" />;
    }
  }

  private renderDownloadDesignAssets() {
    return this.renderDownload("Download Selected Design Assets", this.downloadSelectedDesignAssets);
  }

  private downloadSelectedPops() {
    this.downloadZipFile("pops");
  }

  private downloadSelectedDesignAssets() {
    this.downloadZipFile("assets");
  }

  async downloadZipFile(type) {
    const url = this.downloadEndpoints(type);
    const ids = this.fileTypeIds(type);
    const endpoint = ids.length > 0 ? `${url}?ids=${ids.join(",")}` : url;
    const { job_id } = await get(endpoint);
    this.toogleLoading();
    this.state.job_polling.startPolling(job_id, this.onCompleteZipFile);
  }

  downloadEndpoints(endpoint) {
    const { campaign: { campaignId } } = this.props;
    const endpoints = {
      assets: `/api/v1/campaigns/${campaignId}/design_assets/download_zip`,
      pops: `/api/v1/campaigns/${campaignId}/proof_of_postings/download_zip`
    }
    return endpoints[endpoint];
  }

  fileTypeIds(type) {
    const selected_units = this.selectedUnits();
    const file_ids = {
      assets: getDesignAssetsIds,
      pops: getPopIds
    }
    return file_ids[type](selected_units);
  }

  onCompleteZipFile({ data }) {
    const { error } = data;
    this.toogleLoading();
    if (error) {
      return;
    }
    window.open(data.file_url);
  }
}

const mapStateToProps = ({ pops }) => ({
  pops,
});

export default connect(mapStateToProps, {})(BulkActions);
