import _ from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";

import {
  getPostedOnString,
  getUploadedOnString,
  isSubmitted,
  uploadTitle,
  isAwaitingPop,
  isPosted
} from "../../../models/design_assets";

class DueDate extends PureComponent<any, any> {
  private header_style = {
    fontSize: 11,
    marginBottom: 5
  };

  private past_due_header_style = {
    ...this.header_style,
    color: "#D1132A",
    fontWeight: 600
  };

  private due_date_style: React.CSSProperties = {
    background: "#FFF3E0",
    color: "#9E6B17",
    borderRadius: 4,
    padding: "6px 5px",
    textAlign: "center",
    fontSize: "13px"
  };

  private past_due_date_style: React.CSSProperties = {
    ...this.due_date_style,
    background: "rgba(209, 19, 42, 0.11)",
    color: "#D1132A"
  };

  private uploaded_on_style: React.CSSProperties = {
    ...this.due_date_style,
    background: "rgba(68, 172, 107, 0.1)",
    color: "#44AC6B"
  };

  private defaultDueDate() {
    const { unit, isCompass } = this.props;
    const isStaticJcd =
      unit.supplierName === "JCDecaux" && unit.screen === "static";
    const daysPriorToStartDate = isCompass && isStaticJcd ? 19 : 14;

    return moment(unit.start_date)
      .clone()
      .subtract(daysPriorToStartDate, "d");
  }

  public render() {
    const { unit } = this.props;

    if (isPosted(unit)) {
      return this.renderPostingDate(unit.pop[0]);
    } else if (isAwaitingPop(unit)) {
      const start_date =
        unit.start_date && unit.start_date.clone
          ? unit.start_date
          : moment(unit.start_date);
      const due_date = start_date.clone().add(1, "d");
      return this.renderDueDate(due_date);
    } else if (isSubmitted(unit)) {
      return this.renderUploadedOnDate(unit.design_assets[0]);
    } else {
      //incomplete
      const due_date = unit.design_asset_due_date
        ? moment(unit.design_asset_due_date)
        : this.defaultDueDate();
      return this.renderDueDate(due_date);
    }
  }

  private renderPostingDate(asset: any) {
    return (
      <ul>
        <li style={this.header_style}>Posted Date</li>
        <li style={this.uploaded_on_style} title={uploadTitle(asset)}>
          {getPostedOnString(asset)}
        </li>
      </ul>
    );
  }

  private renderUploadedOnDate(asset: any) {
    return (
      <ul>
        <li style={this.header_style}>Uploaded on</li>
        <li style={this.uploaded_on_style} title={uploadTitle(asset)}>
          {getUploadedOnString(asset)}
        </li>
      </ul>
    );
  }

  private renderDueDate(due_date) {
    const is_past_due = due_date.isBefore(moment());
    if (is_past_due) {
      return (
        <ul>
          <li style={this.past_due_header_style}>
            <i className="fa fa-exclamation-triangle margin-right" />
            Past Due
          </li>
          <li style={this.past_due_date_style}>
            {due_date.format("MM/DD/YYYY")}
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li style={this.header_style}>Due Date</li>
          <li style={this.due_date_style}>{due_date.format("MM/DD/YYYY")}</li>
        </ul>
      );
    }
  }
}

export default DueDate;
