import DemographicDataLayer from './DemographicDataLayer';
import HighlightedRegionDataLayer from './HighlightedRegionDataLayer';
import CustomDataLayer from './CustomDataLayer';

class CampaignDataLayerModel {

  constructor(campaign_data_layer) {
    const { id, type, type_label, name, shading_variable, available_data, color } = campaign_data_layer;

    this.id = id;
    this.type = type;
    this.type_label = type_label;
    this.name = name;
    this.color = color;

    this._shading_variable = shading_variable;
    this._available_data = available_data;
    this._data = {};
    this._legend = {};

    if (type == "Demographic"){
      this._renderer = new DemographicDataLayer();
    } else if (type == "HighlightedRegion"){
      this._renderer = new HighlightedRegionDataLayer(color);
    } else if (type == "CustomDataLayer" || type == "GeopathSegment"){
      this._renderer = new CustomDataLayer();
    }
  }

  get data() {
    return this._data;
  }

  get available_data() {
    return this._available_data;
  }

  get shading_variable() {
    return this._shading_variable;
  }

  set shading_variable(value) {
    this._shading_variable = value;
  }

  setData(key, data) {
    this._key = key;
    this._data = data;
    this._legend = this.buildDataLayerLegend(this.data, this.shading_variable);
  }

  get legend() {
    return this._legend;
  }

  hasData() {
    return this.data != null && this.data.length > 0;
  }

  buildDataLayerLegend(data, shading_variable) {
    return this._renderer.buildDataLayerLegend(data, shading_variable)
  }

  dataLayer() {
    return this._renderer.dataLayer(this.data, this.shading_variable, this.legend, this.color);
  }
}

export default CampaignDataLayerModel;
