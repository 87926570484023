import CheckableLineItem from "../CheckableLineItem/index";
import FaFolderOpen from "@adquick/icons/fa/FaFolderOpen";
import Grid from "../Grid/index";
import React, { Component, ReactElement } from "react";
import Theme from "../../theme";
import styled from "styled-components";

interface MediaType {
  name: string;
  id: string;
}
interface MultiSelectProps {
  included: MediaType[];
  excluded: MediaType[];
  onLineItemClick: (media_type: MediaType, included: boolean) => void;
  onIncludeAll: () => void;
  onExcludeAll: () => void;
  itemName: string;
}

function makeUppercase(str: string): string {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

const renderTitle = (includeString: string, count: number, props: MultiSelectProps): ReactElement => {
  const { onExcludeAll, onIncludeAll } = props;
  return (
    <div>
      <TitleSpan bold={true}>{includeString}</TitleSpan>
      <TitleSpan bold={false} style={{ paddingLeft: "5px" }}>
        ({count})
      </TitleSpan>
      <LinkSpan
        bold={false}
        float="right"
        disabled={count === 0}
        onClick={includeString === "Included" ? onExcludeAll : onIncludeAll}
      >
        {`${includeString === "Included" ? "Exclude" : "Include"} all`}
      </LinkSpan>
    </div>
  );
};

const renderEmpty = (includeString: string, props: MultiSelectProps): ReactElement => {
  return (
    <EmptyColumn>
      <EmptyTitle>{renderTitle(makeUppercase(`${includeString}d`), 0, props)}</EmptyTitle>
      <EmptyContentWrapper>
        <FaFolderOpen />
        <Bold>{`No ${props.itemName} ${includeString}d`}</Bold>
        <Description>{`To narrow down your options, click on a ${props.itemName} to ${includeString} it.`}</Description>
      </EmptyContentWrapper>
    </EmptyColumn>
  );
};

export default class MultiSelect extends Component<MultiSelectProps> {
  constructor(props: MultiSelectProps) {
    super(props);
  }

  public render(): ReactElement {
    const { included, excluded, onLineItemClick } = this.props;
    const columns = 2;
    const columnsSize = 1;
    const gap = 0;
    const width = "660px";
    const padding = "0px";

    return (
      <Grid
        columns={`repeat(${columns}, ${columnsSize}fr)`}
        gap={`${gap}px`}
        width={width}
        padding={padding}
        className="multi-select-wrapper"
      >
        {included.length === 0 ? (
          renderEmpty("include", this.props)
        ) : (
          <Column>
            <Title>{renderTitle("Included", included.length, this.props)}</Title>
            {included
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(obj => (
                <CheckableLineItem
                  id={`${obj.id}`}
                  key={`${obj.id}-included`}
                  included={true}
                  title={obj.name}
                  onClickHandler={(): void => onLineItemClick(obj, true)}
                />
              ))}
          </Column>
        )}
        {excluded.length === 0 ? (
          renderEmpty("exclude", this.props)
        ) : (
          <Column>
            <Title>{renderTitle("Excluded", excluded.length, this.props)}</Title>
            {excluded
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(obj => (
                <CheckableLineItem
                  id={`${obj.id}`}
                  key={`${obj.id}-excluded`}
                  included={false}
                  title={obj.name}
                  onClickHandler={(): void => onLineItemClick(obj, false)}
                />
              ))}
          </Column>
        )}
      </Grid>
    );
  }
}

interface Title {
  bold: boolean;
  float?: string;
  disabled?: boolean;
}

interface Link {
  bold: boolean;
  float?: string;
  disabled?: boolean;
}

const Column = styled.div`
  margin: 0px;
  padding: 10px;
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  background: #fff;

  &:first-child {
    border-right: 1px solid #eee;
  }

  &:last-child {
    background: var(--almost-white-hue2);
  }
`;

const EmptyColumn = styled.div`
  padding: 0;
  margin: 0px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  min-width: 285px;
  background: #fafafa;
  position: relative;
  background: #fff;

  &:first-child {
    border-right: 1px solid #eee;
  }

  &:last-child {
    background: var(--almost-white-hue2);
  }
`;

const EmptyContentWrapper = styled.span`
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  padding: 0 50px 20px 50px;
  position: absolute;
  top: 200px;
`;

const Bold = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #333;
  margin: 5px;
`;

const TitleSpan = styled.span<Title>`
  font-size: 13px;
  line-height: 16px;
  ${({ bold }): string =>
    bold
      ? `
    font-weight: 600;
  `
      : `font-weight: normal;`}
  ${({ float }): string =>
    float
      ? `
    float: ${float};
  `
      : `float: none;`}
  ${({ disabled }): string =>
    disabled
      ? `
      color: #CCC;
  `
      : `color: #333;`}
`;

const LinkSpan = styled.span<Link>`
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  padding-top: 2px;
  padding-right: 20px;
  ${({ float }): string =>
    float
      ? `
    float: ${float};
  `
      : `float: none;`}
  ${({ disabled }): string =>
    disabled
      ? `
      color: #CCC;
  `
      : `color: ${Theme.BLUE}`}
`;

const Description = styled.p`
  font-size: 13px;
  line-height: 16px;
  color: #777;
`;

const EmptyTitle = styled.div`
  padding-left: 15px;
  margin: 10px 10px 0 10px;
`;

const Title = styled.div`
  padding-left: 15px;
  margin: 0px;
  width: 100%;
`;
