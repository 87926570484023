var Reflux = require('reflux');

export default Reflux.createActions([
  'signUp',
  'signIn',
  'logout',
  'getUser',
  'addPaymentMethod',
  'addBankAccount',
  'setPaymentMethod',
  'pay',
  'hideRequestInventoryToolTip',
  'resendConfirmation'
]);
