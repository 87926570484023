import React from 'react';
import createClass from 'create-react-class';
import { get, post } from '../../utils/api';
import { Modal } from 'react-bootstrap';
import { browserHistory } from 'react-router-legacy';
import CampaignStore from '../../stores/CampaignStore'
import GlobalActions from '../../actions/GlobalActions'
import SelectCampaign from '../SelectCampaign'
import JobProgress from '../../utils/JobProgress'

const CopyUnits = createClass({
  displayName: 'CopyUnits',

  getInitialState() {
    return {
      saving: false,
      campaign: null,
      job_pooling: new JobProgress()
    };
  },

  async onCopy(unit_ids) {
    try {
      const { campaign } = this.state
      const { from_campaign_id, include_tags } = this.props
      const campaign_id = campaign && campaign.value;
      const { job_id } = await post(`/api/v1/campaigns/${campaign_id}/copy_units`, { from_campaign_id, unit_ids, include_tags });
      this.state.job_pooling.startPolling(job_id, this.onCopyUnitsComplete)
    } catch(e) {
      GlobalActions.showError('There was an error copying units');
      throw(e);
    }
  },

  onCopyUnitsComplete({ data }) {
    const { success, error } = data
    if (success) { GlobalActions.showMessage('The units have been copied to the selected campaign') }
    if (error) { GlobalActions.showError(error) }
    this.props.onHide()
  },

  onSave() {
    const { campaign, unit_ids } = this.props
    const { market } = this.state

    if (confirm("This will copy units into the selected campaign. Continue?")) {
      this.setState({saving: true})
      this.onCopy(unit_ids)
    }
  },

  onSelectCampaign(campaign) {
    this.setState({ campaign })
  },

  render() {
    const { saving, loading, error, campaign } = this.state;
    const { unit_ids, include_tags } = this.props

    return (
      <div>
        <Modal.Header className="text-center" closeButton>
          <b>Copy {unit_ids.length} Units</b>
          {include_tags &&
            <i> (including tags)</i>
          }
        </Modal.Header>
        <Modal.Body>
          <SelectCampaign onSelectCampaign={this.onSelectCampaign} campaign={campaign} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.onSave} disabled={saving} className="btn btn-primary">{saving ? "Copying..." : "Copy"}</button>
          <button onClick={this.props.onCancel} className="btn btn-danger" style={{float:'left'}}>Cancel</button>
        </Modal.Footer>
      </div>
    )
  },
});

export default CopyUnits;
