import { points, pointsWithinPolygon } from "@turf/turf";

import Filter from "../components/NewMap/Filter";
import {DATA_SETS} from "../components/Campaign/actions";
import {getKdbushIndex, hasKdbushIndex} from "./CampaignSpatialIndex";

export function hasUnits(store) {
  const { all_units } = store;
  if (!all_units) return false;
  if (Array.isArray(all_units)) return !!all_units.length;
  return !!Object.values(all_units).find(v => v && v.units.length);
}

export function sidebarUnits(store, no_dupes = false) {
  let units = filteredUnits(store);
  const { audience_enabled, audience_units } = store.unit_filter;
  if (no_dupes) { units = remove_dupes(units)}
  if (audience_enabled) { units = units.filter(u => audience_units.includes(u._id)) }
  return unitsSort(units, store.sort_by);
}

export function sidebarUnitsWithNoOp(store, no_dupes = false) {
  let units = filteredUnits(store);
  if(no_dupes) { units = remove_dupes(units)}
  units = noOp(units); // DO NOT REMOVE THIS LINE!! IT'LL BREAK THE CHECKBOXES ON Campaign > Manage > Units
  return unitsSort(units, store.sort_by);
}

export function allUniqueUnits(store) {
  return remove_dupes(allUnits(store))
}

function noOp(units) {
  return units.filter(_ => true);
}

export function listViewUnits(store, groupBy = 'tag') {
  const units = sidebarUnits(store, true);
  const groupedUnits = _.groupBy(units, (unit) => {
    if (groupBy === 'market') {
      return _.get(unit, 'market_name') || "Unknown"
    } else {
      return _.get(unit, 'tags[0]', 'Untagged')
    }
  });
  return groupedUnits;
}

export function cartMapUnits(store){
  const units = sidebarUnits(store, true);
  const cart_units = units.filter((unit) => unit.in_cart);
  return cart_units;
}

export function mapUnits(store) {
  return {
    type: 'FeatureCollection',
    features: remove_dupes(filteredUnits(store)).map(unit => (
      {
        geometry: {
          type: 'Point',
          coordinates: [unit.lon, unit.lat]
        },
        properties: {
          _id: unit._id,
          id: unit.id,
          faceId: unit.faceId,
          campaign_unit_token: unit.campaign_unit_token,
          supplier_id: unit.supplier_id,
          supplier_name: unit.supplier_name,
          price: unit.price,
          price_for_duration: unit.price_for_duration,
          rate_card_price: unit.rate_card_price,
          lower_price: unit.lower_price,
          higher_price: unit.higher_price,
          cpm: unit.cpm,
          size: unit.size,
          picture: unit.picture,
          is_package: unit.is_package,
          is_recommended: unit.is_recommended,
          is_favorited: unit.is_favorited,
          is_managed: unit.is_managed,
          is_fully_submitted: unit.is_fully_submitted,
          is_broker: unit.is_broker,
          color: unit.color,
          unit_type: unit.unit_type,
          direction: unit.direction,
          status_badge: unit.status_badge,
          supplier_status: unit.normalized_status,
          label: unit.label
        }
      }
    ))
  };
}

export function allUnits(store) {
  if (!store.all_units) return [];

  return Array.isArray(store.all_units) ?
    store.all_units :
    Object.values(store.all_units).reduce((acc, p) => acc.concat(p.units), []);
}

export function campaignUnitsLoaded(store) {
  return Array.isArray(store.all_units);
}

export function allDataSetsDataLoaded(store) {
  return DATA_SETS.difference(store.loadedDataSets).size === 0;
}

function spatialIndexFind(bounds) {
  if (!hasKdbushIndex()) return;

  const west = Number(bounds[0][0]);
  const south = Number(bounds[0][1]);
  const east = Number(bounds[1][0]);
  const north = Number(bounds[1][1]);

  const kdbushIndex = getKdbushIndex();
  const unitIndexes = kdbushIndex.range(west, south, east, north);
  const unitsWithin = unitIndexes.map(i => kdbushIndex.points[i][2]);

  return unitsWithin;
}

export function withinBounds(store, bounds) {
  if (!bounds) return store;
  if (!Array.isArray(bounds)) return store;

  const all_units = allUnits(store);
  const unitsWithin = spatialIndexFind(bounds) || all_units.filter(unit => { return unitWithinBounds(bounds, unit); });

  return {
    ...store,
    all_units: unitsWithin,
  }
}

export function poisWithinPolygon(pois, polygons) {
  return pois.filter(poi => !_.isEmpty(pointsWithinPolygon(points([[poi.lon, poi.lat]]), polygons[0]).features));
}

export function availableSizes({ all_units }) {
  if (_.isEmpty(all_units)) return [];
  if (!Array.isArray(all_units)) return getUnitSizesFromBrowse(all_units)
  return getUnitSizesFromCampaign(all_units)
}

export function availableSizesRaw({ all_units }) {
  if (_.isEmpty(all_units)) return [];
  if (!Array.isArray(all_units)) return getUnitSizesFromBrowseRaw(all_units)
  return getUnitSizesFromCampaignRaw(all_units)
}

export function availableOrientations({ all_units }) {
  if (_.isEmpty(all_units)) return [];
  return getUnitOrientationsFromCampaign(all_units)
}

function getUnitSizesFromBrowse(all_units) {
  const partitionKeys = Object.keys(all_units)
  const units = all_units[partitionKeys[0]].units
  const cleanedNonParsedSizes = units.filter(u => u.width != null && u.width != 0)
  return uniqueSizes(cleanedNonParsedSizes)
}

function getUnitSizesFromBrowseRaw(all_units) {
  const partitionKeys = Object.keys(all_units)
  const units = all_units[partitionKeys[0]].units
  const cleanedNonParsedSizes = units.filter(u => u.size != null && u.size != 0)
  return cleanedNonParsedSizes.map(u => {
    return {value: u.size, label: u.size}
  })
}

function getUnitSizesFromCampaign(all_units) {
  const cleanNonParsedSizes = all_units.filter(u => u.width != null && u.width != 0)
  return uniqueSizes(cleanNonParsedSizes)
}

function getUnitSizesFromCampaignRaw(all_units) {
  return all_units.map(u => {
    return {value: u.size, label: u.size}
  })
}

function getUnitOrientationsFromCampaign(all_units) {
  const cleanNonParsedOrientations = all_units.filter(u => u.orientation != null && _.trim(u.orientation) != '')
  return uniqueOrientations(cleanNonParsedOrientations)
}

function uniqueSizes(units) {
  return _.flow(
    unit => unit.map(unit => Object.assign({}, { size: unit.size, area: unit.height * unit.width })),
    sizes => _.uniqBy(sizes, 'size'),
    sizes => _.orderBy(sizes, ['area'], ['asc']),
    sizes => sizes.map(u => ({ value: u.size, label: u.size }))
  )(units)
}

function uniqueOrientations(units) {
  return _.flow(
    unit => unit.map(unit => unit.orientation),
    orientations => orientations.sort(),
    orientations => _.countBy(orientations),
    orientations => Object.keys(orientations).map(o => ({ value: o, label: o, count: orientations[o]}))
  )(units)
}

function unitWithinBounds(bounds, unit) {
  const west = Number(bounds[0][0]) * 100000;
  const south = Number(bounds[0][1]) * 100000;
  const east = Number(bounds[1][0]) * 100000;
  const north = Number(bounds[1][1]) * 100000;
  const lat = Number(unit.lat) * 100000;
  const lon = Number(unit.lon) * 100000;

  return (((lat + 1) >= south && (lat - 1) <= north) && ((lon + 1) >= west && (lon - 1) <= east));
}

export function allPartitions(store) {
  if (!!!store.all_units || Array.isArray(store.all_units)) return {};
  return store.all_units;
}

function filteredUnits(store) {
  return unitFilter(store).filter(allUnits(store));
}

function unitFilter(store) {
  return new Filter(store.unit_filter || {});
}

function unitsSort(units, key) {
  key = key || 'default'
  const sort = key.startsWith('-') ? -1 : 1;

  if (key === 'cpm' && units[0] && units[0].lower_price) key = 'lower_cpm'
  if (key === 'price' && units[0] && units[0].lower_price) key = 'lower_price'

  if (key === 'default') return sortDefault(units)
  if (key === 'availability') return sortByAvailability(units);
  if (key === 'supplier_face_id') return sortByFaceId(units);
  if (key === 'size' || key === '-size') return sortBySize(units, sort)
  if (key === 'score' || key === '-score') return sortByScore(units, sort)
  if (key == 'next_booked_diff' || key == '-next_booked_diff') return sortByBookedDiff(units, sort)

  key = key.replace('-', '')
    .replace('week_total_cost', 'price')
    .replace('week_total_impressions', 'impressions')

    return sortByKey(units, key, sort)
}

/**
 * Sorts an array using multiple sorter functions in just one iteration.
 * It's more efficient than iterating an array once for each sorter function.
 * For example, to sort by name and age:
 *
 * people.sort(multipleSort(
 *    (a, b) => a.age - b.age,
 *    (a, b) => a.name.localeCompare(b.name)
 * ))
 *
 * @param {array} sorters - an array of sorter functions.
 * @returns a sorter function that returns the first non-zero result among all sorter functions.
 */
function multipleSort(sorters) {
  return function _multipleSort(a, b) {
    return sorters.reduce(
      (result, sorter) =>  result === 0 ? sorter(a, b) : result,
      0
    )
  }
}

// @param {number} direction - 1 for descending, -1 for ascending.
function sortByKey(units, key, direction) {
  return units.sort(sortByKeyAB(key, direction));
}

// @param {number} direction - 1 for descending, -1 for ascending.
function sortByKeyAB(key, direction) {
  return function _sortByKeyAB(a, b) {
    if (!a[key]) return 1;
    else if (!b[key]) return -1;
    else return (Number(a[key]) - Number(b[key])) * direction;
  }
}

function sortDefault(units) {
  return units.sort(multipleSort([
    sortByUnitTypeAB(),
    sortBySizeAB(),
    sortByCPM_AB(-1)
  ]))
}

// @param {number} direction - 1 for descending, -1 for ascending.
function sortByUnitType(units, direction = 1) {
  return units.sort(sortByUnitTypeAB(direction))
}

// @param {number} direction - 1 for descending, -1 for ascending.
function sortByUnitTypeAB(direction = 1) {
  const bestUnitTypes = ['billboard', 'wallscape', 'windowscape', 'wildposting']
  const unitTypeRank = (unit_type) => bestUnitTypes.includes(unit_type) ? 1 : 0
  return function _sortByUnitTypeAB(a, b) {
    return direction * (unitTypeRank(b.unit_type) - unitTypeRank(a.unit_type))
  }
}

// @param {number} direction - 1 for descending, -1 for ascending.
function sortByCPM(units, direction) {
  return units.sort(sortByCPM_AB(direction))
}

// @param {number} direction - 1 for descending, -1 for ascending.
function sortByCPM_AB(direction = 1) {
  return function _sortByCPM_AB(a, b) {
    const cpmA = a.cpm || a.lower_cpm || 0
    const cpmB = b.cpm || b.lower_cpm || 0
    return direction * (cpmB - cpmA)
  }
}

function sortByFaceId(units) {
  return units.sort((a,b) => {
    if (a.supplier_face_id < b.supplier_face_id) return -1;
    if (a.supplier_face_id > b.supplier_face_id) return 1;
    return 0;
  });
}

function sortByAvailability(units) {
  return units.sort((a, b) => {
    if(a.available) {
      if(!b.available) return -1;
      else a.available.format('x') - b.available.format('x');
    }
    else if(b.available) return 1;
    else if(a.supplier_status && a.supplier_status.endsWith('_requested')) return -1;
    else if(b.supplier_status && b.supplier_status.endsWith('_requested')) return 1;
    else if(a.supplier_status && a.supplier_status.endsWith('_unavailable')) return -1;
    else if(b.supplier_status && b.supplier_status.endsWith('_unavailable')) return 1;
    else return 0;
  });
}

// @param {number} direction - 1 for descending, -1 for ascending.
export function sortBySize(units, direction) {
  return units.sort(sortBySizeAB(direction))
}

// @param {number} direction - 1 for descending, -1 for ascending.
export function sortBySizeAB(direction = 1) {
  return function _sortBySizeAB(a, b) {
    if (!a.width || !a.height || !b.width || !b.height) return undefined
    const areaA = a.width * a.height
    const areaB = b.width * b.height
    return direction * (areaB - areaA)
  }
}

export function sortByScore(units, sort) {
  const sortOrder = sort === 1 ? 'desc' : 'asc'
  return _.orderBy(units, ['score'], [sortOrder])
}

function sortByBookedDiff(units, sort) {
  const sortOrder = sort === 1 ? 'desc' : 'asc'
  return _.orderBy(units, ['next_booked_diff'], [sortOrder])
}

const remove_dupes = (units) => {
  return _.uniqBy(units, '_id')
}
