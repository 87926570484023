import React from 'react';
import validate from 'validate.js';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

import GlobalActions from '../actions/GlobalActions';
import UploadButton from './UploadButton';

export default createClass({
  displayName: 'AddUnitForm',

  propTypes: {
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
  },

  constraints: {
    name: { presence: {} },
    unit_type: { presence: {} },
    lat: { presence: {} },
    lon: { presence: {} },
  },

  getInitialState() {
    return {
      unit_type: this.props.defaultValues.unit_type,
      photo_url: this.props.defaultValues.photo,
      campaign_id: this.props.campaignId,
      name: this.props.defaultValues.name
    };
  },

  componentDidMount() {
    const supplier_campaign = this.props.supplier_campaign;

    $.get('/api/v1/search/options', { supplier_campaign }, (response) => {
      this.setState({
        campaigns: response.campaigns,
        suppliers: response.suppliers,
      });
    }).fail((error) => {
      console.log(error);
    });
  },

  onUploadComplete(upload, file) {
    this.setState({ photo_url: file.url });
  },

  onSubmit(e) {
    e.preventDefault();

    const default_supplier = this.state.suppliers && this.state.suppliers.length === 1 && this.state.suppliers[0].value;

    const params = {
      unit_type: this.state.unit_type,
      supplier_face_id: this.refs.id ? this.refs.id.value : null,
      photo: this.state.photo_url,
      name: this.state.name,
      price: this.refs.price ? this.refs.price.value : null,
      lat: this.refs.latitude.value,
      lon: this.refs.longitude.value,
      address: this.refs.address.value,
      market: this.refs.market.value,
      description: this.refs.description.value,
      campaign_id: this.state.campaign_id,
      supplier_id: this.state.supplier_id || default_supplier,
    };

    let errors = validate(params, this.constraints);
    if (params.unit_type != 'place_marker') {
      if (params.price == '' || params.price == null) {
        if (!errors) errors = {};
        errors.price = true;
      }

      if (params.supplier_face_id == '' || params.supplier_face_id == null) {
        if (!errors) errors = {};
        errors.supplier_face_id = true;
      }

      if (params.supplier_id == '' || params.supplier_id == null) {
        if (!errors) errors = {};
        errors.supplier_id = true;
      }
    }

    this.setState({ errors });

    if (!errors) {
      this.props.onSubmit(params);
    }
  },

  renderCampaignSelect() {
    return (
      <select className="form-control" onChange={(event) => { this.setState({ campaign_id: event.target.value }); }} defaultValue={this.props.campaignId}>
        { this.state.campaigns && this.state.campaigns.length > 1 && <option />}
        {(this.state.campaigns || []).map(campaign => <option key={campaign.value} value={campaign.value}>{campaign.label}</option>)}
      </select>
    );
  },

  renderVendorSelect() {
    const oneSupplier = this.state.suppliers && this.state.suppliers.length === 1;
    const defaultValue = oneSupplier && this.state.suppliers[0].value;
    return (
      <select
        className="form-control"
        onChange={ event => this.setState({ supplier_id: event.target.value }) }
      >
        { !oneSupplier && <option />}
        {(this.state.suppliers || []).map(supplier => <option key={supplier.value} value={supplier.value}>{supplier.label}</option>)}
      </select>
    )},

  render() {
    const errors = this.state.errors || {};
    const { defaultValues, serverErrors } = this.props;

    return (<div className="login__form">
      {serverErrors && <div className="row">
        <div className="col-sm-12" style={{ color: 'red', padding: 15, paddingTop: 0 }}>
          {serverErrors.map(error => <div>{error}</div>)}
        </div>
      </div>}
      <form className="form" onSubmit={this.onSubmit}>
        <div className="form-group row">
          <div className="col-sm-6">
            <label className="col-2 col-form-label">Unit Type</label>
            <div className="col-10">
              <select className="form-control" onChange={(event) => { this.setState({ unit_type: event.target.value }); }}>
                <option />
                <option value="place_marker">Place Marker</option>
                <option value="billboard">Billboard</option>
                <option value="transit">Transit</option>
                <option value="street_furniture">Street Furniture</option>
                <option value="retail">Retail</option>
                <option value="wallscape">Wallscape</option>
                <option value="wildposting">Wildposting</option>
                <option value="alternative">Alternative</option>
              </select>
            </div>
          </div>
          {!!this.state.unit_type && <div className="col-sm-6">
            <label className="col-2 col-form-label" style={errors.photo ? {color: 'red'} : {}}>Photo*</label>
            <div className="col-10">
              {this.state.photo_url && <img src={this.state.photo} style={{ width: 50, height: 50, float: 'right' }} />}
              <div style={{ width: 150, float: 'left' }}>
                <UploadButton onComplete={this.onUploadComplete} isPublic={true}>
                  <button
                    type="button"
                    className="btn btn-default"
                    style={errors.photo ? {border: '1px solid red', color: 'red'} : {}}
                    onClick={ e => e.preventDefault() }
                  >
                    <i className="fa fa-upload" style={{ marginRight: 7.5 }} />
                    Upload Photo
                  </button>
                </UploadButton>
              </div>
            </div>
          </div>}
        </div>
        {!!this.state.unit_type && <div>
          {this.state.unit_type != 'place_marker' && <div className="form-group row">
            <div className="col-sm-6" style={errors.price ? { color: 'red' } : {}}>
              <label className="col-2 col-form-label">Price*</label>
              <div className="col-10">
                <input
                  className="form-control"
                  defaultValue={defaultValues.price}
                  type="text" ref="price" style={errors.price ? { border: '1px solid red' } : {}}
                />
              </div>
            </div>
            <div className="col-sm-6" style={errors.supplier_face_id ? { color: 'red' } : {}}>
              <label className="col-2 col-form-label">Face ID*</label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  ref="id"
                  defaultValue={defaultValues.supplier_face_id}
                  style={errors.price ? { border: '1px solid red' } : {}} />
              </div>
            </div>
          </div>}
          {this.state.unit_type != 'place_marker' && <div className="form-group row">
            <div className="col-sm-12" style={errors.supplier_id ? { color: 'red' } : {}}>
              <label className="col-2 col-form-label">Vendor*</label>
              <div className="col-10">
                {this.renderVendorSelect()}
              </div>
            </div>
          </div>}
          <div className="form-group row">
            <div className="col-sm-6">
              <label className="col-2 col-form-label" style={errors.lat ? { color: 'red' } : {}}>Latitude*</label>
              <div className="col-10">
                <input
                  className="form-control"
                  defaultValue={defaultValues.lat}
                  type="text" ref="latitude" style={errors.lat ? { border: '1px solid red' } : {}}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <label className="col-2 col-form-label" style={errors.lon? { color: 'red' } : {}}>Longitude*</label>
              <div className="col-10">
                <input
                  className="form-control"
                  defaultValue={defaultValues.lon}
                  type="text" ref="longitude" style={errors.lon ? { border: '1px solid red' } : {}}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6" style={errors.name ? { color: 'red' } : {}}>
              <label className="col-2 col-form-label">Location Name</label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  ref="name"
                  value={this.state.name}
                  onChange={(e) => this.setState({name: e.target.value})}
                  style={errors.name ? { border: '1px solid red' } : {}} />
              </div>
            </div>
            <div className="col-sm-6" style={errors.market ? { color: 'red' } : {}}>
              <label className="col-2 col-form-label">Market</label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  ref="market"
                  value={this.state.market}
                  onChange={(e) => this.setState({market: e.target.value})}
                  style={errors.market ? { border: '1px solid red' } : {}} />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Address</label>
              <div className="col-10">
                <input className="form-control" type="text" ref="address" defaultValue={defaultValues.address} />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Description</label>
              <div className="col-10">
                <textarea className="form-control" defaultValue={defaultValues.description} ref="description" />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Add To Campaign</label>
              <div className="col-10">
                {this.renderCampaignSelect()}
              </div>
            </div>
          </div>
          <button className="btn btn_primary register__submit" type="submit" onClick={this.onSubmit}>Save</button>
        </div>}
      </form>
    </div>);
  },
});
