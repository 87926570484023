import { SupplierOption } from "../models/supplier";
import { del, get, post } from "../utils/api";

const prefix = "suppliers";
const SUPPLIERS_API_URL = "/api/v2/suppliers"

export const LOAD_SUPPLIER_OPTIONS = `${prefix}/LOAD_SUPPLIER_OPTIONS`;
export const loadSupplierOptions = (campaign_token, bounds) => async (dispatch, getState) => {
  const { current_market } = getState()
  let params = { campaign: campaign_token, current_market_id: current_market }
  if (bounds !== null) {
    params = { ...params, ... bounds}
  }
  const res = await get(`${SUPPLIERS_API_URL}/filter_options`, null, params);
  const suppliers: SupplierOption[] = res.data.suppliers;
  dispatch({ type: LOAD_SUPPLIER_OPTIONS, payload: { suppliers } });
};
