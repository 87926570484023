import Reflux from 'reflux';
import MapActions from '../actions/MapActions';

var savedPositions = {}

export default Reflux.createStore({

  listenables: [MapActions],

  init() {
    this.state = {
      bounds: null,
      center: AppConfig.defaultCenter,
      zoom: null,
      filters: {},
      popupUnitId: null,
      prevPopupUnitId: null,
      selectedMarkers: null,
      visibleDataLayer: null,
    }
  },

  setState(newState) {
    this.state = Object.assign({}, this.state, newState)
  },

  onShowUnitPopup(unitId, geocode) {
    if(unitId) this.state.popupUnitId = unitId;
    if(geocode) this.state.popupUnitGeocode = geocode;
    this.trigger('map:showUnitPopup');
  },

  onHideUnitPopup(unitId) {
    // need to store it as prevPopupUnitId so we can find the marker to turn it off
    this.state.prevPopupUnitId = unitId;
    this.trigger('map:hideUnitPopup');
  },

  onLoadPosition(key) {
    if (savedPositions[key]) {
      this.onSetCenter(savedPositions[key].center)
      this.onSetZoom(savedPositions[key].zoom)
    }
  },

  onSavePosition(key) {
    savedPositions[key] = {
      center: this.state.center,
      zoom: this.state.zoom
    }
  },

  onSetZoom(zoom) {
    this.state.zoom = zoom
    this.trigger('map:zoom')
  },

  onSetCenter(coords) {
    this.state.center = coords;
    this.trigger('map:center');
  },

  onSetViewport(viewport) {
    this.state.viewport = viewport
    this.trigger('map:viewport');
  },

  onSetLocation(locationName) {
    this.state.locationName = locationName;
    this.trigger('map:location');
  },

  onUpdateMap(params) {
    this.state = Object.assign({}, this.state, params);
    this.trigger('map:update');
  },

  onCloseInfobox() {
    this.trigger('map:closeInfobox');
  },

  onFitToMarkerBounds() {
    this.trigger('map:fitToMarkerBounds')
  },

  onSelectMarkers(params) {
    this.state = Object.assign({}, this.state, params);
    this.trigger('map:selectMarkers');
  },

  onToggleTraffic() {
    this.trigger('map:toggleTraffic');
  },

  onShowDataLayer(campaign_data_layer, dataLayerType) {
    this.state.visibleDataLayer = campaign_data_layer;
    this.state.dataLayerType = dataLayerType;
    this.trigger('map:showDataLayer');
  },

  loaded(){
    this.trigger('map:loaded');
  },

  getState() {
    return this.state;
  },

  getLocation() {
    return this.state.locationName
  }

});
