import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';
import { distanceInWords } from 'date-fns';

const HELLOSIGN = 'hellosign';
const UPLOAD = 'upload';

class StateBadge extends Component {

  label() {
    const { contract } = this.props;
    const {
      canceled_at,
      type,
      in_negotiation,
      all_signed,
      partially_signed,
    } = contract;

    if (!!canceled_at) return 'Canceled';
    if (in_negotiation) { return 'Negotiating'; }

    const signed_label = all_signed

    switch(type) {
      case HELLOSIGN: return all_signed ? 'All Signed' : (partially_signed ? 'Part. Signed' : 'Sent');
      case UPLOAD:    return 'Uploaded';
      default:        return 'Pending Sig.';
    }
  }

  createdTooltip() {
    const { contract } = this.props;
    const { created_at, created_by, type } = contract;

    const action = ((type) => {
      switch(type) {
        case HELLOSIGN: return 'Sent';
        case UPLOAD:    return 'Uploaded';
        default:        return 'Created';
      }
    })(type);

    return (<div>
      {`${action} by ${created_by}`}
      <br/>
      {`${distanceInWords(created_at, new Date())} ago`}
    </div>);
  }

  canceledTooltip() {
    const { contract } = this.props;
    const { canceled_at, canceled_by } = contract;
    return (<div>
      {`Canceled by ${canceled_by}`}
      <br/>
      {`${distanceInWords(canceled_at, new Date())} ago`}
    </div>);
  }

  tooltipText() {
    const { contract } = this.props;
    const { canceled_at } = contract;
    if (!canceled_at) {
      return this.createdTooltip();
    } else {
      return this.canceledTooltip();
    }
  }

  badgeClassName() {
    const { contract } = this.props;
    const { canceled_at, in_negotiation, pricing_confirmed_at } = contract;
    if (!!canceled_at) { return 'danger'; }
    if (!!pricing_confirmed_at) { return 'success'; }
    if (in_negotiation) { return 'negotiating'; }
    return 'success';
  }

  render(){
    const { contract } = this.props;
    const { canceled_at } = contract;

    return (
      <Tooltip
        placement="bottom"
        overlay={this.tooltipText()}>
        <span className={
          `badge badge-${this.badgeClassName()}`
        }>{this.label()}</span>
      </Tooltip>
    )
  }

}

export default StateBadge
