import React from 'react';
import { browserHistory } from 'react-router-legacy';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore'
import GlobalActions from '../../actions/GlobalActions'
import { BootstrapTable } from 'react-bootstrap-table';
import { formatMoney } from 'accounting'
import moment from 'moment'

const Contracts = createClass({
  displayName: 'Contracts',

  getInitialState() {
    return {
      isLoggedIn: AuthStore.isLoggedIn(),
      contracts: [],
    }
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ]

    this.fetchData()
  },

  onAuthChange() {
    this.setState({ isLoggedIn: AuthStore.isLoggedIn() })
    if (AuthStore.isLoggedIn()) {
      this.fetchData()
    } else {
      GlobalActions.openPopup('login')
    }
  },

  fetchData() {
    $.get('/api/v1/contracts').then((contracts) => {
      this.setState({contracts})
    })
  },

  render() {
    const { isLoggedIn, contracts } = this.state

    const contractStyle = {
      marginBottom: 10,
      border: '1px solid #eee',
      padding: 5,
      fontSize: 15,
    }

    if (isLoggedIn) {
      return (
        <div className="container">
          <h1>Contracts</h1>
          {contracts.map(contract => <div style={contractStyle}>
            <div><b>{contract.campaign && contract.campaign.name}</b></div>
            <div>{contract.signer_name} {contract.signer_email}</div>
            <div>{moment(contract.created_at).format('LLL')}</div>
            <div>
              {contract.campaign && <a onClick={() => browserHistory.push(`/campaigns/${contract.campaign.token}`) }>View Campaign</a>}
              {contract.file_url && <a href={contract.file_url} target="_blank" style={{marginLeft:20}}>View Contract</a>}
              {contract.signed_contract_file && <a href={contract.signed_contract_file} target="_blank" style={{marginLeft:20}}>View Signed Contract</a>}
            </div>
          </div>)}
        </div>
      );
    } else {
      return (
        <div className="text-center" style={{ padding: 50 }}>Please login</div>
      )
    }
  }
})

export default Contracts;
