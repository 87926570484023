import createClass from 'create-react-class';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  createCustomBounds
} from '../../actions/data_layers';
import GlobalActions from '../../actions/GlobalActions';
import {
  favoriteUnits,
  toggleRecommendUnits,
  unfavoriteUnits
} from '../../actions/UnitsActions';
import UploadButton from '../../components/UploadButton';
import { poisWithinPolygon } from '../../models/Units';
import NewMapStore from '../../stores/NewMapStore';
import PlacesStore from "../../stores/PlacesStore";
import { post } from '../../utils/api';
import JobProgress from '../../utils/JobProgress';
import { loadCampaignUnits, removeCampaignUnits } from '../Campaign/actions';
import { deleteIcon, removePlaces, updateIconById } from "../Places/actions";
import CopyUnits from './CopyUnits';
import FindVendors from './FindVendors';
import Tags from './Tags';


const EditUnitsFromMarkers = createClass({
  displayName: 'EditUnitsFromMarkers',

  getInitialState() {
    return {
      markers: this.props.markers || [],
      saving: false,
      favoriting: false,
      unfavorting: false,
      job_pooling: new JobProgress()
    };
  },

  componentWillUnmount(){
    if (this.interval) {
      clearInterval(this.interval)
    }
  },

  getSelectedUnitIds() {
    return this.state.markers.map(marker => {
      if(marker.options) return marker.options.id
      return marker.properties.id
    })
  },

  getSelectedCampaignUnitIds() {
    return this.state.markers.map(marker => {
      if(marker.options) return marker.options.campaign_unit_token
      return marker.properties.campaign_unit_token
    })
  },

  getSelectedPois() {
    const { placemarkers } = NewMapStore.getState()
    const polygon = this.props.map.selected_bounds;
    const all_pois = _.map(placemarkers.features, f => f.properties)
    const selected_pois = poisWithinPolygon(all_pois, this.props.map.selected_polygons);
    return selected_pois;
  },

  onRemovePois() {
    const pois = this.getSelectedPois()
    if(_.isEmpty(pois)) {
      return GlobalActions.showError('No POIs selected.');
    }

    if(confirm(`Are you sure you want to remove ${pois.length} POIs?`)) {
      return this.removePois(pois);
    }
  },

  async removePois(pois) {
    const { removePlaces, campaign } = this.props
    this.setState({ removing_pois: true })
    const poi_ids = pois.map(p => p.id)
    await removePlaces(poi_ids, campaign.token)
    PlacesStore.trigger('places:updated')
    GlobalActions.showMessage(`${poi_ids.length} POI(s) removed.`)
    this.setState({ removing_pois: false })
  },

  onRemovePoisCustomLogo() {
    const pois = this.getSelectedPois()
    if(_.isEmpty(pois)) {
      return GlobalActions.showError('No POIs selected.');
    }

    if(confirm(`Are you sure you want to remove ${pois.length} POIs custom logos?`)) {
      return this.removePoisCustomLogos(pois);
    }
  },

  onUpdatePoisCustomLogo() {
    const pois = this.getSelectedPois()
    if(_.isEmpty(pois)) {
      this.setState({updating_pois_custom_logo: false})
      return GlobalActions.showError('No POIs selected.');
    }
    this.setState({updating_pois_custom_logo: true})
  },

  async onUploadComplete(upload, file) {
    const pois = this.getSelectedPois()
    if (!pois.length) {
      GlobalActions.showError('You need to select at least one POI from the list');
      return;
    }
    const { updateIconById, campaign } = this.props;
    await updateIconById(pois.map(p => p.id), campaign.id, upload.path);
    PlacesStore.trigger('places:updated');
    GlobalActions.showMessage('Successfully added custom icon for POIs');
  },

  showUpdatingPois() {
    const pois = this.getSelectedPois()
    return <div>
      <h4>Updating logos for the following POIs</h4>
      <div style={ {overflowY: 'auto', maxHeight: '200px'}}>
        {pois.map(p => <p>{p.label}</p>)}
      </div>
      <UploadButton key="upload_button" isPublic={false} onComplete={this.onUploadComplete} customDropzoneProps={{inputProps: {style: {display: "none"}}}}>
        <div style={{ border: "1px dashed #4A90E2", borderRadius: "8px", cursor: "pointer"}}>
          <p>Please use only PNG images with transparency, size 60x60px</p>
          <button className="btn btn-default btn-block" onClick={(e) => e.preventDefault()} disabled={!pois.length}>
            <i className="fa fa-cloud-upload"/> Upload logo for selected POIs
          </button>
        </div>
      </UploadButton>
    </div>
  },

  async onCreateCustomBounds() {
    const { campaign, createCustomBounds, map, updateDataLayers } = this.props;
    const coordinates = _.get(map, 'selected_polygons[0].geometry.coordinates')
    this.setState({ creating_custom_bounds: true })
    await createCustomBounds(campaign.token, coordinates);
    this.setState({ creating_custom_bounds: false })
    updateDataLayers();
  },

  async removePoisCustomLogos(pois) {
    const { deleteIcon, campaign } = this.props
    this.setState({ removing_pois_custom_logo: true })
    const poi_ids = pois.map(p => p.id)
    await deleteIcon(poi_ids, campaign.token)
    PlacesStore.trigger('places:updated')
    GlobalActions.showMessage('POIs logos successfully deleted')
    this.setState({ removing_pois_custom_logo: false })
  },

  async onToggleRecommend(flow) {
    this.setState({ saving: true });
    await this.props.toggleRecommendUnits(this.getSelectedUnitIds(), this.props.campaign.token, flow);
    this.props.onHide();
    this.setState({ saving: false });
  },

  async onFavorite() {
    this.setState({ favoriting: true })
    const campaign_unit_ids = this.getSelectedCampaignUnitIds()
    const unit_ids = this.getSelectedUnitIds()
    await this.props.favoriteUnits(campaign_unit_ids, unit_ids, this.props.campaign.token, true);
    this.props.onHide();
    this.setState({ favoriting: false });
  },

  async onUnFavorite() {
    this.setState({ unfavoriting: true })
    const campaign_unit_ids = this.getSelectedCampaignUnitIds()
    const unit_ids = this.getSelectedUnitIds()
    await this.props.unfavoriteUnits(campaign_unit_ids, unit_ids, this.props.campaign.token, true)
    this.props.onHide()
    this.setState({ unfavoriting: false })
  },

  async onRemove() {
    const unit_ids = this.getSelectedUnitIds();
    if (confirm(`Are you sure you want to remove ${unit_ids.length} units?`)) {
      const { campaign } = this.props
      this.setState({ removing: true })

      const { job_id } = await post(`/api/v1/campaigns/${campaign.token}/batch_remove_units`, { unit_ids })
      this.interval = this.state.job_pooling.startPolling(job_id, this.onRemoveUnitsComplete)
    }
  },

  async onRemoveUnitsComplete({ data }) {
    const { removed_count, packages_removed_count, error } = data
    if(error) { return this.handleRemoveUnitsError(error); }
    const unit_ids = this.getSelectedUnitIds();
    await this.props.removeCampaignUnits(unit_ids);
    this.props.onHide();
    this.setState({ removing: false });
    GlobalActions.showMessage(`${removed_count} unit(s) removed. ${packages_removed_count} package(s) removed.`)
  },

  handleRemoveUnitsError(error) {
    this.setState({ removing: false }, () => GlobalActions.showError(error));
  },

  async onAdd() {
    const { campaign, units } = this.props;
    const { selected_coordinates } = this.props.map
    const unit_filter = units.unit_filter || {};
    this.setState({ adding: true })

    const { added_units_count } = await $.post(
      `/api/v1/campaigns/${campaign.token}/add_units_to_existing_campaign`,
      { coordinates: selected_coordinates, filters: unit_filter }
    )
    GlobalActions.showMessage(`${added_units_count} new units were added to this campaign.`)

    this.props.onHide()
    await this.props.loadCampaignUnits(campaign.token);

    this.setState({adding: false})
  },

  onFindVendors() {
    this.setState({ showFindVendors: true })
  },

  onCopy() {
    this.setState({ showCopy: true })
  },

  render() {
    const { markers, removing, saving, favoriting, copying, adding, removing_pois, removing_pois_custom_logo, unfavoriting, creating_custom_bounds, updating_pois_custom_logo } = this.state;
    const { campaign, user, boundingBox } = this.props
    const unit_ids = markers.map(marker => (marker.options ? marker.options.id : marker.properties.id))

    if (this.state.showFindVendors) {
      return (
        <FindVendors
          campaign={campaign}
          boundingBox={boundingBox}
          onCancel={() => this.setState({ showFindVendors: false })}
          onHide={this.props.onHide}
        />
      )
    } else if (this.state.showCopy) {
      return (
        <CopyUnits
          unit_ids={unit_ids}
          from_campaign_id={campaign.token}
          onCancel={() => this.setState({ showCopy: false })}
          onHide={this.props.onHide}
        />
      )
    } else {
      return (
        <div>
          <Modal.Header className="text-center" closeButton>
            <b>Apply changes to {markers.length} units</b>
          </Modal.Header>
          <Modal.Body>
            {this.props.permissions.can_edit_tags &&
              <Tags campaign={this.props.campaign} unitIds={unit_ids} onHide={this.props.onHide} />
            }
            <hr/>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {this.props.permissions.can_add_additional_units_from_map &&
                <button style={{marginRight:5,marginTop:10}} onClick={this.onAdd} disabled={adding} className="btn btn-default">{adding ? "Finding And Adding Units..." : "Find And Add Units In Selected Area"}</button>
              }
              {this.props.permissions.can_send_rfps && <span style={{marginRight:5,marginTop:10}}>
                <button onClick={this.onFindVendors} className="btn btn-default">Find Vendors</button>
              </span>}
              {this.props.permissions.can_recommend_units &&
              [
                <button key="recommend" onClick={() => this.onToggleRecommend('recommend')} disabled={saving} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{saving ? "Processing..." : "Recommend"}</button>,
                <button key="unrecommend" onClick={() => this.onToggleRecommend('unrecommend')} disabled={saving} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{saving ? "Processing..." : "Unrecommend"}</button>
              ]
              }

              {this.props.permissions.can_favorite_units &&
                <button onClick={this.onFavorite} disabled={favoriting} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{favoriting ? "Favoriting..." : "Favorite"}</button>
              }

              {this.props.permissions.can_favorite_units &&
                <button onClick={this.onUnFavorite} disabled={unfavoriting} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{unfavoriting ? "Unfavoriting..." : "Unfavorite"}</button>
              }

              <button onClick={this.onRemove} disabled={removing} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{removing ? "Removing..." : "Remove"}</button>

              <button onClick={this.onCopy} disabled={copying} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{copying ? "Processing..." : "Copy"}</button>
              <button onClick={this.onRemovePois} disabled={removing_pois} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{removing_pois ? "Removing POIs" : "Remove POIs"}</button>
              <button onClick={this.onRemovePoisCustomLogo} disabled={removing_pois_custom_logo} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{removing_pois_custom_logo ? "Removing POIs Logo" : "Remove POIs Logo"}</button>
              <button onClick={this.onUpdatePoisCustomLogo} disabled={updating_pois_custom_logo} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{removing_pois_custom_logo ? "Updating POIs Logo" : "Update POIs Logo"}</button>
              <button onClick={this.onCreateCustomBounds} disabled={creating_custom_bounds} className="btn btn-default" style={{marginRight:5, marginTop:10}}>{creating_custom_bounds ? "Saving Custom Bounds" : "Create Custom Bounds"}</button>
            </div>
          </Modal.Body>
          <Modal.Body>
            {updating_pois_custom_logo && this.showUpdatingPois()}
          </Modal.Body>
          <Modal.Footer>
            <span>
              <button onClick={this.props.onHide} className="btn btn-default">Cancel</button>
            </span>
          </Modal.Footer>
        </div>
      );
    }
  }
});

export default connect(
  ({ map, places_ui, units }) => ({ map, places_ui, units }),
  {
    removeCampaignUnits,
    loadCampaignUnits,
    toggleRecommendUnits,
    favoriteUnits,
    removePlaces,
    deleteIcon,
    unfavoriteUnits,
    createCustomBounds,
    updateIconById,
  })(EditUnitsFromMarkers);
