import React from 'react';
import cs from 'classnames';
import { connect } from 'react-redux';
import { isMobile } from '../../../utils/mobile';
import { fetchSizes } from '../actions';
const POPUP_WIDTH = 220;

class Size extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.renderClearButton = this.renderClearButton.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {
      isExpanded: false,
      selectedSizes: []
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
    const { fetchSizes } = this.props;
    fetchSizes();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters.supplier !== this.props.filters.supplier) {
      const { fetchSizes } = this.props;
      fetchSizes(nextProps.filters.supplier);
    };

    if (nextProps.filters && nextProps.filters.size === null) {
      this.setState({ selectedSizes: [] });
    };

    const { selectedSizes } = this.state;
    const filters = this.props.filters || {};
    if (selectedSizes.length === 0 && filters.size && !this.props.sizes && nextProps.sizes) {
      this.setState({ selectedSizes: Array.isArray(filters.size) ? filters.size : filters.size.split(',') });
    }
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onClear() {
    const { setFilter } = this.props;
    this.setState({ selectedSizes: [] }, () => {
      setFilter('size', null);
    });
  }

  onSizeSelect(option) {
    const { setFilter } = this.props;
    const { selectedSizes } = this.state;
    if (selectedSizes.includes(option)) {
      const sizes = selectedSizes.filter((size) => size !== option );
      this.setState({ selectedSizes: sizes }, () => {
        setFilter('size', sizes);
      });
    } else {
      const size = selectedSizes.concat([option]);
      this.setState({ selectedSizes: size}, () => {
        setFilter('size', size);
      });
    }
  }

  getButtonLabel() {
    const { selectedSizes } = this.state;
    if (!selectedSizes.length) return 'Size';
    if (selectedSizes.length == 1) return `${selectedSizes[0]}`;
    if (selectedSizes.length > 1) return `Sizes · ${selectedSizes.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderClearButton() {
    const { selectedSizes } = this.state;

    if (selectedSizes.length > 0) {
      return (
        <a
          style={{
            float: 'right',
            top: -32,
            paddingRight: 12,
            position: 'relative',
            color: '#999999'
          }}
          onClick={this.onClear}
        >Clear</a>
      )
    } else {
      return (<div></div>);
    }
  }

  renderPopup() {
    const { selectedSizes } = this.state;
    const sizes = this.props.sizes || [];

    return (
      <div className="filter_popup sizes_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>SIZE</h4>
        {this.renderClearButton()}
        <ul>
          {sizes.map((size) => {
            const onClick = () => {
              this.onSizeSelect(size.value);
            }
            const isActive = selectedSizes.includes(size.value);
            return <li
              key={size.value}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {size.value}
                <i className="count">({size.count})</i>
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { isExpanded, selectedSizes } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs({active: selectedSizes.length > 0 || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};

export default connect(
  ({ inventory: { sizes } }) => ({
    sizes
  }),
  { fetchSizes }
)(Size);
