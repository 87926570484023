import React from "react";
import Setup from "./Setup";
import GoogleDashboard from "./Dashboards/Google";

const DEFAULT_PROVIDER = "google";

const route_components = {
  // @ts-ignore TODO anderson
  setup: (props: INewAnalyticsProps) => <Setup {...props} />,
  // @ts-ignore TODO anderson
  configure: (props: INewAnalyticsProps) => <Setup {...props} />,
  google: (props: INewAnalyticsProps) => <GoogleDashboard {...props} />,
};

export interface INewAnalyticsProps {
  permissions: any[];
  campaign: { token: string };
  direct_responses: any[];
  query_params: { provider: string };
  step: any;
}

const getProvider = ({ provider }): string => provider || DEFAULT_PROVIDER;

const NewAnalytics = (props: INewAnalyticsProps) => {
  const { step, query_params, campaign } = props;
  const route = step || getProvider(query_params);

  if (!props.query_params.provider || !route_components[route]) {
    props.query_params.provider = DEFAULT_PROVIDER;
  }

  if (campaign.token === "dlot9y17p6" || campaign.token === "ynbrf4tejq") {
    const render = route_components[route];
    return render ? render(props) : route_components[DEFAULT_PROVIDER](props);
  } else {
    return route_components.configure(props);
  }


};

export default NewAnalytics;
