import React from 'react';
import cs from 'classnames';
import { Modal } from 'react-bootstrap';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore';

export default createClass({
  getInitialState() {
    return {
      campaign: this.props.campaign,
      auth: AuthStore.getState(),
    };
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];
  },

  onAuthChange() {
    this.setState({ auth: AuthStore.getState() });
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  onResend() {
    AuthStore.resendConfirmationEmail({ return_to: this.props.returnTo });
    this.setState({ resent: true });
  },

  render() {
    const { user } = this.state.auth;
    const title = this.props.title || 'Please check your email to verify your account.';

    return (<Modal show={this.props.show} onHide={this.props.onHide} className="confirm-account-modal">
      <Modal.Body>
        <img src={ASSETS['white-logo']} style={{ width: 110, marginBottom: 20 }} />
        <h1>
          <i className="fa fa-envelope" style={{ marginRight: 10 }} />
          {title}
        </h1>
        <div style={{ paddingTop: 10 }}>
          {this.state.resent ? <div>Resent! Check your email inbox in a few minutes.</div> : <div>
            {"Didn't receive a confirmation email? "}
            <button className="btn btn-link" style={{ padding: 0, color: 'white', textDecoration: 'underline' }} onClick={this.onResend}>Resend</button>
          </div>}
        </div>
      </Modal.Body>
    </Modal>);
  },
});
