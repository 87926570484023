import { connect } from "react-redux";
import React, { Component } from "react";
import _ from "lodash";
import { 
  fetchProductionOrders,
  refreshProductionOrder,
  cancelProductionOrderItem,
  deleteProductionOrder,
  submitProductionOrder,
  submitProductionOrderBulk
} from "../../../../actions/campaign_design_actions";
import { loadSupplierAddresses } from "../../../../actions/supplier_contacts_actions";
import { bulkUpdateUnit } from "../../../Inventory/Unit/actions";
import { ProductionOrderType } from "../../../../models/production_orders";
import { ISupplierShippingAddress } from "../../../../models/supplier_address";
import { CampaignUnit } from "../../../../models/Units";
import ProductionOrder from './ProductionOrder'

interface ICircleGraphicsProps {
  units_in_status: CampaignUnit[];
  campaign: any;
  campaign_permissions: any;
  supplier_addresses: ISupplierShippingAddress;
  fetchProductionOrders: (campaignId) => Promise<ProductionOrderType[]>;
  refreshProductionOrder: (production_order_id) => any;
  cancelProductionOrderItem: (production_order_id) => any;
  deleteProductionOrder: (production_order_id) => any;
  submitProductionOrder: (production_order_id) => any;
  loadSupplierAddresses: (supplier_id) => any;
  submitProductionOrderBulk: (oder_id, payload) => any;
  bulkUpdateUnit: (unit_ids, payload) => void;
}

interface ICircleGraphicsState {
  orders: ProductionOrderType[]
}

class CircleGraphics extends Component<ICircleGraphicsProps, ICircleGraphicsState> {
  constructor(props) {
    super(props);
    this.state = {
      orders: []
    }
    this.updateProductionOrders = this.updateProductionOrders.bind(this);
  }

  public componentDidUpdate(prevProps) {
    const { units_in_status } = this.props;
    if(units_in_status != prevProps.units_in_status) {
      return this.getShippingContacts();
    }
  }

  async componentDidMount() {
    this.updateProductionOrders();
    this.getShippingContacts()
  }

  private getShippingContacts() {
    const { units_in_status } = this.props;
    const uniq_suppliers = _.uniq(units_in_status.map(u => u.supplier_id));
    uniq_suppliers.map(supplier_id => this.loadSupplierContactData(supplier_id));
  }

  private async loadSupplierContactData(supplier_id) {
    const { loadSupplierAddresses } = this.props;
    loadSupplierAddresses(supplier_id);
  }

  private renderProductionOrders() {
    const { campaign, campaign_permissions, supplier_addresses } = this.props
    const { orders } = this.state
    return orders.map(order => 
      <ProductionOrder
        key={`order_${order.id}`}
        order={order}
        units={this.getUnitsFromOrder(order)}
        supplier_contacts={supplier_addresses}
        campaign={campaign}
        campaign_permissions={campaign_permissions}
        fetchProductionOrders={this.updateProductionOrders}
        refreshProductionOrder={this.props.refreshProductionOrder}
        cancelProductionOrderItem={this.props.cancelProductionOrderItem}
        deleteProductionOrder={this.props.deleteProductionOrder}
        submitProductionOrder={this.props.submitProductionOrder}
        submitProductionOrderBulk={this.props.submitProductionOrderBulk}
        bulkUpdateUnit={this.props.bulkUpdateUnit}
      />
    )
  }

  private async updateProductionOrders() {
    const { fetchProductionOrders, campaign: { token } } = this.props;
    const orders = await fetchProductionOrders(token);
    this.setState({ orders });
  }

  private getUnitsFromOrder({ items }) {
    const { units_in_status } = this.props;
    const order_items = items.map(item => item.campaign_unit_token);
    return units_in_status.filter(unit => order_items.includes(unit.campaign_unit_token));
  }

  public render() {
    return (
      <div className="campaign_designs container">
        {this.renderProductionOrders()}
      </div>
    )
  }
}

export default connect(
  ( state: any ) => ({
    supplier_addresses: state.supplier_addresses
  }),
  { 
    fetchProductionOrders,
    refreshProductionOrder,
    cancelProductionOrderItem,
    deleteProductionOrder,
    submitProductionOrder,
    loadSupplierAddresses,
    submitProductionOrderBulk,
    bulkUpdateUnit
  }
)(CircleGraphics)