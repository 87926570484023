import cs from "classnames";
import Fuse from "fuse.js";
import React from "react";
import { connect } from "react-redux";
import { filterUnits } from "../../actions/UnitsActions";
import { fetchAdvertiserCampaigns, fetchAllPreviousAdvertisers } from "../Advertisers/actions";
import isEmpty from "lodash/isEmpty";

const POPUP_WIDTH = 220;

class PreviousAdvertiserFilter extends React.Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
    this.debouncedSearch = _.debounce(this.searchByAdvertiserName, 200);

    this.state = {
      isExpanded: false,
      activeAdvertisers: [],
      narrowedList: [],
      previous_advertisers: []
    };
  }

  async componentDidMount() {
    const { fetchAllPreviousAdvertisers } = this.props;
    document.addEventListener("click", this.onToggle);
    const previous_advertisers = await fetchAllPreviousAdvertisers();
    this.setState({ previous_advertisers });
  }

  componentDidUpdate(prevProps) {
    const { isActive } = this.props;
    if (!isActive && prevProps.isActive) { this.onClear() }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onClear() {
    this.setState(
      {
        activeAdvertisers: [],
        narrowedList: null,
      },
      this.onFilter
    );
  }

  searchByAdvertiserName(query) {
    const options = {
      keys: ["name"],
      minMatchCharLength: 3,
      threshold: 0.4,
      maxPatternLength: 20,
    };
    const fuse = new Fuse(this.state.previous_advertisers, options);
    this.setState({ narrowedList: fuse.search(query) });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false, narrowedList: null });
    }
  }

  onAdvertiserSelect(advertiser) {
    const { activeAdvertisers } = this.state;
    const isSelected = activeAdvertisers.find(a => a.id === advertiser.id);
    if (isSelected) { return this.removeAdvertiser(advertiser); }
    this.setState({
      activeAdvertisers: [...activeAdvertisers, advertiser]
    }, this.onFilter);
  }

  removeAdvertiser(advertiser) {
    const { activeAdvertisers } = this.state;
    this.setState({
      activeAdvertisers: activeAdvertisers.filter(a => a != advertiser )
    }, this.onFilter);
  }

  onFilter() {
    const { activeAdvertisers } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    const advertisers = isEmpty(activeAdvertisers) ? null : activeAdvertisers.map(a => a.id);
    this.setState({ isExpanded: false }, () => {
      _.defer(filterUnits, { previous_advertiser: advertisers });
      handleQueryParams({ 'previous_advertiser[]': advertisers })
    });
  }

  getButtonLabel() {
    const { activeAdvertisers } = this.state;
    if (isEmpty(activeAdvertisers)) return "Previous Advertiser";
    if(activeAdvertisers.length === 1) {
      return _.truncate(activeAdvertisers[0].name, { length: 18 });
    }
    return `Previous Advertisers: ${activeAdvertisers.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const {
      activeAdvertisers,
      narrowedList,
      previous_advertisers
    } = this.state;
    const advertisersList = !isEmpty(narrowedList) ? narrowedList : previous_advertisers;
    const clearStyle = {
      float: "right",
      top: -32,
      paddingRight: 12,
      position: "relative",
      color: "#999999",
    };

    return (
      <div className="filter_popup suppliers_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>PREVIOUS ADVERTISER</h4>
        <a style={clearStyle} onClick={this.onClear}>
          Clear
        </a>
        <input
          autoFocus="true"
          type="text"
          className="narrow_list"
          onChange={e => this.debouncedSearch(e.target.value)}
          placeholder="Filter by name"
        />
        <ul>
          {
            advertisersList.map(advertiser => {
              const onClick = () => {
                this.onAdvertiserSelect(advertiser);
              };
              const isActive = activeAdvertisers.includes(advertiser);
              return (
                <li key={advertiser.id} onClick={onClick} className={cs({ active: isActive })}>
                  {_.truncate(advertiser.name, { length: 18 })}
                  {advertiser.count && <i className="count">({advertiser.count})</i>}
                  <i className="fa fa-check" />
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li
        ref={node => (this.node = node)}
        className={cs({ active: this.props.isActive || isExpanded })}
      >
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(
  null,
  {
    fetchAllPreviousAdvertisers,
    filterUnits,
  },
)(PreviousAdvertiserFilter);
