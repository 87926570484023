import * as React from "react";
import Grid from "../Grid";
import { BlockSizeProperty } from "csstype";

const MOBILE_WIDTH_MAX = 820; // px
const SIDENAV_WIDTH = 290;
const SIDENAV_PADDING = 22;
const FULL_HEADER_HEIGHT = 213;

export enum SidenavWidth {
  Default = SIDENAV_WIDTH,
  Programmatic = 350,
}
export interface SidenavWrapperProps {
  children: React.ReactNode;
  gap?: BlockSizeProperty<string>;
  padding?: BlockSizeProperty<string>;
  sidenavWidth?: SidenavWidth;
  useClickToggle?: boolean;
  isOpen?: boolean;
  enableOverflow?: boolean;
}

export interface SidenavWrapperState {
  mobileOpen: boolean;
  isMobile: boolean;
  isOpen?: boolean;
}

const gridStateStyle = (isOpen: boolean, sidenavWidth?: number): React.CSSProperties => {
  return {
    transform: `translateX(-${isOpen ? 0 : sidenavWidth || SIDENAV_WIDTH}px)`,
    transition: "transform 0.15s ease-out",
  };
};

class SidenavWrapper extends React.Component<SidenavWrapperProps, SidenavWrapperState> {
  constructor(props: SidenavWrapperProps) {
    super(props);

    this.state = {
      mobileOpen: false,
      isMobile: false,
      isOpen: props.useClickToggle && props.isOpen,
    };
  }

  private get style(): React.CSSProperties {
    if (this.props.enableOverflow) {
      return {
        ...gridStateStyle(this.state.isOpen as boolean, this.props.sidenavWidth),
        width: `${this.sidenavWidth}px`,
        position: "absolute",
        zIndex: 3,
      };
    }

    if (this.props.useClickToggle) {
      return gridStateStyle(this.state.isOpen as boolean, this.props.sidenavWidth);
    }

    return this.state.isMobile ? gridStateStyle(this.state.mobileOpen, this.props.sidenavWidth) : {};
  }

  private get sidenavWidth(): number {
    return this.props.sidenavWidth || SidenavWidth.Default;
  }

  private get columns(): string {
    if (this.props.useClickToggle) {
      return `${this.sidenavWidth}px calc(100% + ${SIDENAV_PADDING}px)`;
    } else {
      return this.state.isMobile
        ? `${this.sidenavWidth}px calc(100% - ${SIDENAV_PADDING * 2}px)`
        : `${this.sidenavWidth}px auto`;
    }
  }

  private get mobileCheck(): boolean {
    return window.innerWidth < MOBILE_WIDTH_MAX;
  }

  public componentDidUpdate(): void {
    if (this.props.useClickToggle && this.props.isOpen !== this.state.isOpen) {
      this.setState({ isOpen: this.props.isOpen as boolean });
    }
  }

  public componentDidMount(): void {
    this.updateMobileState();
    if (this.props.useClickToggle) {
      this.setState({ isOpen: this.props.isOpen as boolean });
    }
    window.addEventListener("resize", () => this.updateMobileState());
  }
  public componentWillUnmount(): void {
    window.removeEventListener("resize", () => this.updateMobileState());
  }

  public render(): React.ReactElement {
    if (this.props.enableOverflow) {
      const children = React.Children.toArray(this.props.children);
      return (
        <div className="ui-sw--overflow">
          <div className="ui-sidenav__nav" style={this.style}>
            {children[0]}
          </div>
          <div className="ui-sidenav__content">{children[1]}</div>
        </div>
      );
    }
    return (
      <section style={this.style} className="analytics__dashboard">
        <Grid
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => this.behaviourHandler(event)}
          onMouseLeave={(): void => this.closeSidenav()}
          onMouseMove={(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => this.behaviourHandler(event)}
          gap={this.props.gap}
          padding={this.props.padding}
          columns={this.columns}
        >
          {this.props.children}
        </Grid>
      </section>
    );
  }

  private updateMobileState(): void {
    this.setState({ isMobile: this.mobileCheck });
  }

  private behaviourHandler(event: React.MouseEvent<HTMLDivElement>): void {
    if (!this.state.isMobile) {
      return;
    }

    if (this.isPositionOut(event.pageX)) {
      this.closeSidenav();
    }

    if (this.isPositionInner(event.pageX, event.pageY)) {
      this.openSidenav();
    }
  }

  private isPositionInner(x: number, y: number): boolean {
    const verticalCheck = y > FULL_HEADER_HEIGHT;
    const horizontalCheck = x < SIDENAV_PADDING * 2;
    return verticalCheck && horizontalCheck;
  }

  private isPositionOut(x: number): boolean {
    return x > SIDENAV_WIDTH + SIDENAV_PADDING;
  }

  private openSidenav(): void {
    this.setState({ mobileOpen: true });
  }

  private closeSidenav(): void {
    this.setState({ mobileOpen: false });
  }
}

export default SidenavWrapper;
