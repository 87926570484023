import React, { Component } from "react";
import cs from "classnames";
import GlobalActions from "../../../actions/GlobalActions";
import UploadButton from "../../UploadButton";

class UploadAttachmentModal extends Component<any,any> {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
      uploading: false,
      completed: false,
      file_url: null,
      filename: null,
      path: null,
      saving_file: false,
      error: null,
      progress: 0,
      description: null
    }
    this.onClose = this.onClose.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.saveFile = this.saveFile.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  public render() {
    return (
      <div
        className="default_modal_container"
        onClick={e => e.stopPropagation()}
      >
        <div className="default_modal modal_small">
          <div
            className="modal_header"
            style={{color:"#858585",justifyContent: "flex-end"}}
          >
            <div className="modal_close" onClick={this.onClose}>
              <div className="close_button"></div>
            </div>
          </div>
          {this.renderError()}
          <div className="modal_body"
            style={{
              display:"flex",
              flexDirection:"column",
              alignItems:"center"}}
            >
            <UploadButton
              onComplete={this.onUploadComplete}
              onDrop={this.onDrop}
              isPublic={false}
              onProgress={this.onProgress}
              skipProgress={true}
            >
              {this.renderUploadCard()}
            </UploadButton>
            <div
              className="form-group"
              style={{
                margin:0,
                width:"374px",
                marginTop:"32px"
              }}
            >
              <label className="control-label">
                Description (Required)
              </label>
              <textarea
                style={{resize: "none"}}
                className="form-control"
                defaultValue={this.state.description}
                onChange={this.onInputChange}
                placeholder="Add file description here"
                rows={3}
              />
            </div>
          </div>
          <div className="modal_footer">
            <button
              disabled={!this.readyToSubmit()}
              className="primary-button"
              onClick={this.saveFile}
            >
              {this.renderButtonText()}
            </button>
          </div>
        </div>
      </div>
    )
  }

  private readyToSubmit() {
    const { file_url, saving_file, description } = this.state;
    return (!file_url || saving_file || !!description)
  }

  private renderButtonText() {
    const { saving_file } = this.state;
    if(saving_file) { return "Uploading..."; }
    return "Upload";
  }

  private onClose() {
    GlobalActions.closePopup();
  }

  private onInputChange(e) {
    const value = e.target.value;
    this.setState({ description: value });
  }

  private renderUploadCard() {
    const { dragging } = this.state;
    return (
      <div className="upload_image_card">
        <div className="title">
          <h6>Add Attachments</h6>
          <div className="subtitle">
            Drop your files here, or browse your computer.
          </div>
        </div>
        <div
          className={cs("dashed_container", {"dragging": dragging})}
          style={{height:"220px"}}
          onDragOver={this.onDragOver.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          onDrop={this.onDragLeave.bind(this)}
        >
          {this.renderDropFile()}
          {this.renderUploadProgress()}
        </div>
      </div>
    )
  }

  private renderDropFile() {
    const { uploading, completed } = this.state;
    if (uploading) { return; }
    if (completed) { return this.renderCompletedState(); }
    return (
      <div className="center">
        <div className="drag_icon"></div>
        <h6 style={{color:"#858585"}}>Drag and drop here or browse</h6>
        <div className="description">
          Supports: PDF, Doc, PPT, PPTX, XLS, XLSX
        </div>
      </div>
    )
  }

  private renderCompletedState() {
    const { filename } = this.state;
    return (
      <div className="center">
        <div
          className="file_icon"
          style={{
            textAlign:"center",
            fontSize:"32px",
            color:"#858585"
          }}
        >
          <i className="fa fa-file-o"></i>
        </div>
        <h6 style={{color:"#858585"}}>{filename}</h6>
      </div>
    );
  }

  private renderUploadProgress() {
    const { progress, uploading } = this.state;
    if(!uploading) { return; }
    return (
      <div
        className="upload_progress"
        style={{
          width:"95%",
          border:0
        }}
      >
        <div
          className="progression"
          style={{
            width: `${progress}%`,
            backgroundColor:"unset"
          }}
        >
          <div className="upload_details">
            <span className="status">
              {this.renderUploadStatus()}
            </span>
            <span className="download_progress">
              {`${progress}%`}
            </span>
          </div>
          <div className="line"></div>
        </div>
      </div>
    )
  }

  private renderUploadStatus() {
    const { completed } = this.state;
    if(completed) { return "Completed"; }
    return "Uploading";
  }

  private onUploadComplete(upload, file) {
    return this.setState({
      file_url: file.url,
      filename: file.name,
      path: upload.path,
      completed: true,
      uploading: false
    });
  }

  private onDrop() {
    this.setState({ uploading: true, progress: 0 })
  }

  private onProgress(progress) {
    this.setState({ progress })
  }

  private onDragOver(e) {
    this.setState({ dragging: true });
  }

  private onDragLeave(e) {
    this.setState({ dragging: false });
  }

  private async saveFile() {
    const { file_url, description, path } = this.state;
    const { popupOptions: { onCreateAttachment } } = this.props;
    try {
      this.setState({ saving_file: true });
      await onCreateAttachment({ file_url, description, path });
      this.setState({ saving_file: false });
      this.onClose();
    } catch (error) {
      this.setState({ error, saving_file: false });
    }
  }

  private renderError() {
    const { error } = this.state;
    if(!error) { return; }
    return (
      <div className="alert alert-danger">
        <i
          className="fa fa-exclamation-circle"
          style={{fontSize:"18px",marginRight:"10px"}}
        ></i>
        {error}
      </div>
    )
  }
}

export default UploadAttachmentModal;
