import React from 'react';
import createClass from 'create-react-class';

import GlobalActions from '../actions/GlobalActions';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';
import SplashForm from './SplashForm';

export default createClass({
  getInitialState() {
    const auth = AuthStore.getState();
    return ({ auth });
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  onAuthChange(event) {
    const auth = AuthStore.getState();
    this.setState({ ...auth });

    if (event === 'auth:signup') {
      this.trackConversion();
      GlobalActions.showMessage('Welcome to AdQuick! Thank you for becoming a member.');
      GlobalActions.closePopup();
    }

    if (event == 'auth:error') {
      const { errors } = AuthStore.getState();
      this.setState({ errors });
    }
  },

  onOpenLogin() {
    window.location.assign("/login");
  },

  trackConversion() {
    // google
    const google_conversion_id = 878432214;
    const google_conversion_label = 'Ck7HCPSLjGwQ1p_vogM';
    const image = new Image(1, 1);
    image.src = `//www.googleadservices.com/pagead/conversion/${google_conversion_id}/?label=${google_conversion_label}&script=0`;

    // quora
    if (qp) qp('track', 'Generic');
  },

  onSubmit(data) {
    if (data.email != '') {
      AuthStore.onSignUp(data);
    }
  },

  loginWithGoogle() {
    document.location = '/auth/google';
  },

  onForgot() {
    document.location = '/users/password/new';
  },

  render() {
    const googleBtn = {
      border: '1px solid #e14929',
      backgroundColor: '#e14929',
      marginTop: 10,
    };

    return (
      <div className="modal-splash" onClick={e => e.stopPropagation()}>
        {!AppConfig.isEmbed && <div className="logo"><img src={ASSETS.logo} width="129" height="20" /></div>}
        {/* <h1 className="title">Outdoor advertising has never been so easy</h1>*/}
        <SplashForm onSubmit={this.onSubmit} errors={this.state.errors} />
        <div className="skip">
          <a className="register__link" onClick={this.onOpenLogin}>Already have an account?</a><br />
          <a className="register__link" onClick={this.onForgot}>Forgot your password?</a>
        </div>
        <center style={{ marginTop: 15 }}>
          <img src={ASSETS['google-login']} style={{ cursor: 'pointer' }} onClick={this.loginWithGoogle} />
        </center>
      </div>
    );
  },
});
