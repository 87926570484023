import React from "react";

const campaignGeopathSegments = (props) => {
  console.log(props)
  const {campaign_geopath_segments, selected_segment_id, removeSegment, selectSegment} = props
  return <div className="accordion">
    {campaign_geopath_segments.map(geopath_segment => {
      const {demographics, display_name} = geopath_segment;
      const selected = selected_segment_id === geopath_segment.geopath_segment_id
      return (
        <div key={geopath_segment.id} className="panel panel-default geopath-segment">
          <div className={`panel-heading ${selected ? "selected-segment" : ''}`} onClick={() => selectSegment(geopath_segment.geopath_segment_id)}>
            { geopath_segment.name }
            { selected &&
              <i className="fa fa-trash" onClick={(e) => { removeSegment(geopath_segment.id); e.stopPropagation(); }}/>
            }
          </div>
          { selected &&
            <div className="panel-body">
              <ul>
                {demographics.age && <li>Age<span>{demographics.age}</span></li>}
                {demographics.race && <li>Race<span>{demographics.race}</span></li>}
                {demographics.gender && <li>Gender<span>{demographics.gender}</span></li>}
                {demographics.hhi && <li>Household income<span>{demographics.hhi}</span></li>}
                {<li>Segment<span>`{display_name}`</span></li>}
              </ul>
            </div>
          }
        </div>
      );
    })}
  </div>
};
campaignGeopathSegments.displayName = "CampaignGeopathSegments";

export default campaignGeopathSegments;
