import { get } from '../../utils/api';

export const FETCH_COMMENTS = 'COMMENTS.FETCH_COMMENTS';
export const fetchComments = (campaign_id) => async (dispatch) => {
  const comments = await get(`/api/v1/campaigns/${campaign_id}/campaign_units/comments`);
  dispatch({ type: FETCH_COMMENTS, payload: comments})
}

export const ADD_COMMENT = 'COMMENTS.ADD_COMMENT';
export const addComment = (campaign_id, campaign_unit_token, comment_body) => async (dispatch) => {
  const data = {
    comment_body,
    campaign_unit_token,
  }
  if (!comment_body.trim()) return false;
  const new_comment = await $.post(`/api/v1/campaigns/${campaign_id}/campaign_units/comments`, data)
  dispatch({ type: ADD_COMMENT, payload: new_comment });
}

export const DELETE_COMMENT = 'COMMENTS.DELETE_COMMENT';
export const deleteComment = (campaign_id, campaign_unit_token, comment_id) => async (dispatch) => {
  const data = {
    campaign_unit_token,
  }

  const deletedComment = await $.ajax({
    url: `/api/v1/campaigns/${campaign_id}/campaign_units/comments/${comment_id}`,
    type: 'DELETE',
    data: data,
  })
  dispatch({ type: DELETE_COMMENT, payload: deletedComment });
}
