import moment from "moment";
import React, { PureComponent } from "react";

interface Props {
  campaign: any;
  unit: any;
}

class TakeDownDate extends PureComponent<any, any> {
  private header_style = {
    fontSize: 11,
    marginBottom: 5
  };

  private take_down_style: React.CSSProperties = {
    background: "rgba(74, 144, 226, 0.1)",
    color: "#4A90E2",
    borderRadius: 4,
    padding: "6px 5px",
    textAlign: "center",
    fontSize: "13px"
  };

  private past_due_take_down_style: React.CSSProperties = {
    ...this.take_down_style,
    background: "rgba(209, 19, 42, 0.11)",
    color: "#D1132A"
  };

  private pending_take_down_style: React.CSSProperties = {
    ...this.take_down_style,
    background: "#FFF3E0",
    color: "#9E6B17"
  };

  private renderNoTakeDownDate() {
    const { campaign, unit } = this.props;
    const { marked_still_posted_at } = unit;
    const { end_date } = campaign.campaign;

    const today = moment();
    const campaign_end_date = end_date ? moment(end_date) : null;
    const days_past_end = campaign_end_date
      ? today.diff(campaign_end_date, "days")
      : 0;
    const is_past_due = days_past_end > 14;

    if (is_past_due && !!!marked_still_posted_at) {
      return (
        <ul>
          <li style={this.header_style}>Take Down Date</li>
          <li style={this.past_due_take_down_style}>Past Due</li>
        </ul>
      );
    } else {
      // pending or marked as still up
      return (
        <ul>
          <li style={this.header_style}>Take Down Date</li>
          <li style={this.pending_take_down_style}>Pending</li>
        </ul>
      );
    }
  }

  public render() {
    const { unit } = this.props;
    const { take_down_date } = unit;

    if (!!!take_down_date) {
      return this.renderNoTakeDownDate();
    } else {
      return (
        <ul>
          <li style={this.header_style}>Take Down Date</li>
          <li style={this.take_down_style}>
            {moment(take_down_date).format("MM/DD/YYYY")}
          </li>
        </ul>
      );
    }
  }
}

export default TakeDownDate;
