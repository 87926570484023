import { REFIT_UNITS, SET_UNIT_LOADING, TOGGLE_UNIT_LOCK } from '../../actions/UnitsActions';
import { LOAD_CAMPAIGN } from '../Campaign/actions';
import {
  CLEAR_MAP,
  SELECT_COORDINATES,
  SET_BOUNDS,
  SET_BOUNDS_DIRTY,
  UNSET_BOUNDS_DIRTY,
  TOGGLE_MAP_CLUSTER,
  TOGGLE_MAP_SIZE,
  TOGGLE_MAP_TRAFFIC,
  TOGGLE_SELECTED_MARKERS,
  TOGGLE_SELECTED_POLYGONS,
  TOGGLE_UNIT_POPUP,
  TOGGLE_UNIT_REACH,
  TRIGGER_MAP_RESIZE,
  UPDATE_MAP_POSITION,
  UPDATE_CURRENT_CUSTOM_POI,
  TOGGLE_MAP_PLACEMARKERS
} from './actions';

const initialState = {
  lock: false,
  loading: true,
  cluster: true,
  show_placemarkers: true,
  full_screen: false,
  map_resize: false,
  traffic: false,
  selected_markers: false,
  show_units_reach: false,
  selected_coordinates: [],
  bounds: [[-127, 15], [-64, 58]],
  current_custom_poi: false,
};

export default (state = initialState, action) => {
  console.log(action.type, action.payload)
  switch(action.type) {
    case TOGGLE_UNIT_LOCK: return {...state, lock: !state.lock};
    case SET_UNIT_LOADING: return {...state, loading: action.payload};
    case TOGGLE_MAP_CLUSTER: return { ...state, cluster: !state.cluster };
    case TOGGLE_MAP_PLACEMARKERS: return { ...state, show_placemarkers: !state.show_placemarkers };
    case TOGGLE_MAP_SIZE: return { ...state, full_screen: !state.full_screen };
    case TRIGGER_MAP_RESIZE: return { ...state, map_resize: !state.map_resize };
    case TOGGLE_MAP_TRAFFIC: return { ...state, traffic: !state.traffic };
    case TOGGLE_SELECTED_MARKERS: return selectedMarkers(state, action.payload);
    case TOGGLE_SELECTED_POLYGONS: return selectedPolygons(state, action.payload);
    case UPDATE_MAP_POSITION: return updatePosition(state, action.payload);
    case UPDATE_CURRENT_CUSTOM_POI: return { ...state, current_custom_poi: action.payload};
    case TOGGLE_UNIT_POPUP: return unitPopup(state, action.payload);
    case TOGGLE_UNIT_REACH: return { ...state, show_units_reach: !state.show_units_reach };
    case SELECT_COORDINATES: return { ...state, selected_coordinates: action.payload.coordinates };
    case SET_BOUNDS_DIRTY: return { ...state, bounds_dirty: true };
    case UNSET_BOUNDS_DIRTY: return { ...state, bounds_dirty: false };
    case SET_BOUNDS: return { ...state, bounds: setBounds(action.payload) };
    case REFIT_UNITS: return { ...state, refit_units: !state.refit_units };
    case CLEAR_MAP: return initialState;
    case LOAD_CAMPAIGN: return setCampaignBounds(state, action.payload);
    default: return state;
  }
};

function setBounds({bounds}) {
  return [
    [parseFloat(bounds[0][0]), parseFloat(bounds[0][1])],
    [parseFloat(bounds[1][0]), parseFloat(bounds[1][1])]
  ];
}

function selectedMarkers(state, {selected_bounds}) {
  return {
    ...state,
    selected_markers: !state.selected_markers,
    selected_bounds: selected_bounds
  }
}

function selectedPolygons(state, {selected_polygons}) {
  return {
    ...state,
    // selected_markers: !state.selected_markers, // defer state switch to selectedMarkers reducer
    selected_polygons: selected_polygons,
  };
}

function updatePosition(state, {position}) {
  if (position.bounds) {
    const b = position.bounds.split(',');
    const bounds = [[b[1], b[0]], [b[3], b[2]]];

    return {
      ...state,
      bounds
    }
  } else if (position.center) {
    return {
      ...state,
      position
    }
  }

  return state;
}

function unitPopup(state, {unit_id}) {
  return {
    ...state,
    unit_popup: {
      unit_id
    }
  };
}

function setCampaignBounds(state, {bounds}) {
  return {
    ...state,
    bounds: [[bounds.min_lon, bounds.min_lat], [bounds.max_lon, bounds.max_lat]]
  }
}
