import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";

const POPUP_WIDTH = 275;

class NextBookedDaysFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = Object.assign(
      {
        isExpanded: false,
        minDiff: null, // last filtered by
        maxDiff: null, // last filtered by
      },
      this.props.initialValue,
    );
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, initialValue } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (initialValue.minDiff !== prevProps.initialValue.minDiff) { this.setState({ minDiff: initialValue.minDiff })}
    if (initialValue.maxDiff !== prevProps.initialValue.maxDiff) { this.setState({ maxDiff: initialValue.maxDiff })}
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onChange(event) {
    const minDiff = this.minDiffInput.value;
    const maxDiff = this.maxDiffInput.value;
    this.setState({ minDiff, maxDiff });
  }

  onFilter() {
    const { minDiff, maxDiff } = this.state;
    const { filterUnits, handleQueryParams } = this.props;

    const filter = { min_diff: minDiff, max_diff: maxDiff }

    this.setState({ isExpanded: false });
    _.defer(filterUnits, filter);
    handleQueryParams(filter)
  }

  onClear() {
    this.setState(
      {
        minDiff: null,
        maxDiff: null,
      },
      this.onFilter
    );
  }

  getButtonLabel() {
    const { minDiff, maxDiff } = this.state;
    if (!minDiff && !maxDiff) return "Next Booked";
    if (minDiff && maxDiff) return `${minDiff}–${maxDiff} Days`;
    if (minDiff) return `${minDiff}+ Days`;
    if (maxDiff) return `Up to ${maxDiff} Days`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { minDiff, maxDiff } = this.state;
    return (
      <div className="filter_popup cpm_range_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>NEXT BOOKED DAYS RANGE </h4>
        <div className="padded_content">
          <p>Days from end of flight until next booked</p>
          <div className="range_inputs">
            <input
              type="number"
              min="0"
              value={minDiff}
              onChange={this.onChange}
              placeholder="Min"
              ref={node => (this.minDiffInput = node)}
            />
            <input
              type="number"
              min="0"
              value={maxDiff}
              onChange={this.onChange}
              placeholder="Max"
              ref={node => (this.maxDiffInput = node)}
            />
          </div>
          <p className="actions">
            <a className="clear hidden-xs" onClick={e => this.onClear(true)}>
              Clear
            </a>
            <a onClick={this.onFilter}>Apply</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(null, { filterUnits })(NextBookedDaysFilter);
