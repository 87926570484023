var Reflux = require('reflux');

export default Reflux.createActions({
  "setCenter": {},
  "setZoom": {},
  "setLocation": {},
  "setViewport": {},
  "updateMap": {},
  "closeInfobox": {},
  "fitToMarkerBounds": {},
  "loadPosition": {},
  "savePosition": {},
  "showUnitPopup": {},
  "hideUnitPopup": {},
  "selectMarkers": {},
  "toggleTraffic": {},
  "showDataLayer": {},
  "loaded": {}
});
