import React from 'react';
import createClass from 'create-react-class';


const Icons = createClass({

  displayName: 'Icons',

  render() {
    return (
      <svg style={{ position: 'absolute', width: 0, height: 0 }} width="0" height="0" className="icon">
        <defs>
          <symbol id="icon_street_furniture" viewBox="0 0 16 16">
            <path d="M2 10h1v3H2v-3zm11 0h1v3h-1v-3zM1 7.495C1 7.222 1.22 7 1.498 7h13.004c.275 0 .498.216.498.495v2.01c0 .273-.22.495-.498.495H1.498C1.223 10 1 9.784 1 9.505v-2.01zM2 3.49c0-.27.23-.49.5-.49h11c.276 0 .5.215.5.49V7H2V3.49z" />
          </symbol>

          <symbol id="icon_billboard" viewBox="0 0 16 16">
            <path className="icon" d="M7 10h2v4H7v-4zM2 2h12v8H2V2zm8-1h1v1h-1V1zM5 1h1v1H5V1z" />
          </symbol>

          <symbol id="icon_transit" viewBox="0 0 16 16">
            <path d="M4 13h2v2H4v-2zm6 0h2v2h-2v-2zM4 3h8v1H4V3zm1-1h6v1H5V2zM3 4h10v9H3V4zm9 4H4V5h8v3zm-2 2h2v2h-2v-2zm-6 0h2v2H4v-2z" />
          </symbol>

          <symbol id="icon_alternative" viewBox="0 0 16 16">
            <path d="M3.5 10C4.328 10 5 9.328 5 8.5S4.328 7 3.5 7 2 7.672 2 8.5 2.672 10 3.5 10zM8 10c.828 0 1.5-.672 1.5-1.5S8.828 7 8 7s-1.5.672-1.5 1.5S7.172 10 8 10zm4.5 0c.828 0 1.5-.672 1.5-1.5S13.328 7 12.5 7 11 7.672 11 8.5s.672 1.5 1.5 1.5z" />
          </symbol>

          <symbol id="icon_retail" viewBox="0 0 16 16">
            <path d="M3.5 10C4.328 10 5 9.328 5 8.5S4.328 7 3.5 7 2 7.672 2 8.5 2.672 10 3.5 10zM8 10c.828 0 1.5-.672 1.5-1.5S8.828 7 8 7s-1.5.672-1.5 1.5S7.172 10 8 10zm4.5 0c.828 0 1.5-.672 1.5-1.5S13.328 7 12.5 7 11 7.672 11 8.5s.672 1.5 1.5 1.5z" />
          </symbol>

          <symbol id="icon_search" viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          </symbol>

          <symbol id="icon_cart" viewBox="0 0 18 18">
            <path d="M5.57 4.57h9.138c.556 0 .924.44.822.984l-.917 4.892c-.102.543-.63.983-1.182.983h-8L3.713 3.713H5.43l.14.857zM2 2h3.037l.392 1.714H2V2zm4.323 13.714c.947 0 1.714-.78 1.714-1.74 0-.963-.767-1.688-1.714-1.688s-1.714.725-1.714 1.687c0 .962.766 1.74 1.713 1.74zm6.737-.047c.947 0 1.714-.768 1.714-1.715 0-.946-.767-1.714-1.714-1.714s-1.714.768-1.714 1.714c0 .947.767 1.715 1.714 1.715z" />
          </symbol>

        </defs>
      </svg>
    );
  },
});

export default Icons;
