import React from 'react';
import createClass from 'create-react-class';


export default createClass({

  getInitialState() {
    return {
      comment: this.props.comment || '',
      present: !!this.props.comment
    }
  },

  componentWillReceiveProps(nextProps) {
    console.log('update', nextProps);
    this.setState({
      comment: nextProps.comment || '',
      present: !!nextProps.comment
    });
  },

  render() {
    return (
      <div className="adword_report_note">
        { this.admin() }
        { this.user() }
      </div>
    );
  },

  admin() {
    if (!this.props.user.admin) return <div/>;

    if (this.state.present) {
      return this.adminShow();
    }
    else {
      return this.adminEdit();
    }
  },

  adminShow() {
    return (
      <div className="comment">
        <div className="content">{ this.state.comment }</div>
        <button onClick={ () => this.setState({ present: false })}>Edit</button>
        <button onClick={ () => this.props.deleteComment(this.props.metric) }>Delete</button>
      </div>
    );
  },

  adminEdit() {
    return (
      <div className="form-group" style={{ display: 'flex' }}>
        <textarea
          className="form-control"
          rows="1"
          value={this.state.comment}
          onChange={ (e) => this.setState({ comment: e.target.value }) }
          style={{ marginRight: '10px' }}
        />
        <button
          onClick={ () => this.props.updateComment(this.props.metric, this.state.comment) }
          className="btn btn-primary"
          style={{ backgroundColor: '#337ab7', borderColor: '#2e6da4' }}>
          Save Comment
        </button>
      </div>
    );
  },

  user() {
    if (this.props.user.admin || !this.props.metric) return <div/>;
    return <div className="comment">{ this.props.comment }</div>;
  }

});

