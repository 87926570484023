import Spiderifier from 'mapboxgl-spiderifier';
import mapboxgl from 'mapbox-gl';
import { statusBadge } from '../../models/Unit';
import { colors } from "./avail_colors";
export default class Spider {

  constructor(map, showAvailability, popups, onClick, highlighter) {
    this.map = map;
    this.showAvailability = showAvailability;
    this.popups = popups;
    this.onClick = onClick;
    this.highlighter = highlighter;
  }

  load() {
    this.spiderifier = new Spiderifier(this.map, {
      animate: true,
      animationSpeed: 100,
      onClick: (_, pin) => this.onClick(pin.feature),
      initializeLeg: this.initializeLeg.bind(this)
    });
  }

  initializeLeg(leg) {
    const pin = leg.elements.pin;
    pin.id = leg.feature.id;
    pin.parentElement.className += ` ${leg.feature.direction}`;
    this.addStatus(pin, leg.feature);
    this.addFavorited(pin, leg.feature);

    let color
    if (leg.feature.supplier_status) {
      color = colors[leg.feature.supplier_status]
    } else {
      color = leg.feature.color
    }
    pin.style['background-color'] = color;

    pin.addEventListener('mouseenter', () => {
      this.highlighter({ properties: leg.feature });
      this.showPopup(leg);
    });
    pin.addEventListener('mouseleave', () => {
      this.highlighter();
      this.popups.hide.bind(this.popups)();
    });

  }

  addStatus(pin, feature) {
    if (!feature.status_badge) return;
    pin.parentElement.append(this.createBadge("top", feature.status_badge));
  }

  addFavorited(pin, feature) {
    if (!feature.is_favorited) return;
    pin.parentElement.append(this.createBadge("bottom", "favorited"));
  }

  createBadge(position, name){
    const node = document.createElement("div");
    node.className = `unit-badge ${position} ${name}`;
    return node;
  }

  async showPopup(leg) {
    if (this.stopPopups) return;
    const coordinates = [leg.mapboxMarker.getLngLat().lng, leg.mapboxMarker.getLngLat().lat];
    const popup = await this.popups.show({ properties: leg.feature }, coordinates, null, this.popupOffset(leg));
    leg.mapboxMarker.setPopup(popup);
  }

  popupOffset(leg) {
    // manual adjustments into the offset since we changes the default css for spiderfy
    const offset = Spiderifier.popupOffsetForSpiderLeg(leg);
    const { bottom, top, right, left } = offset;
    const bottom_left = offset['bottom-left'];
    const bottom_right = offset['bottom-right'];
    const top_left = offset['top-left'];
    const top_right = offset['top-right'];

    bottom[1] = bottom[1] - 5;
    top[1] = top[1] + 5;
    right[0] = right[0] - 5;
    left[0] = left[0] + 5;
    bottom_left[1] = bottom_left[1] - 5;
    bottom_right[1] = bottom_right[1] - 5;
    top_left[1] = top_left[1] + 5;
    top_right[1] = top_right[1] + 5;

    return {
      top,
      bottom,
      right,
      left,
      'top-left': top_left,
      'top-right': top_right,
      'bottom-left': bottom_left,
      'bottom-right': bottom_right
    };
  }

  close() {
    this.spiderifier.unspiderfy();
  }

  open(point, features) {
    this.stopPopups = true;
    setTimeout(() => this.stopPopups = false, 150);
    this.spiderifier.spiderfy(point, features);
  }

}
