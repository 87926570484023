import React from "react";
import { connect } from "react-redux";
import {
  fetchSuppliers,
  fetchCurrentUser,
  fetchSalesUsers,
  fetchFaultReasons
} from "./actions";
import { Modal } from "react-bootstrap";
import { post } from "../../utils/api";
import GlobalActions from "../../actions/GlobalActions";
import { Button, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Select from "react-select";
import Async from 'react-select/async';
import DateRangePicker from "../DatePicker/Range";
import SingleDatePicker from "../DatePicker/Single";
import moment from "moment";
import validate from "validate.js";
import AuthStore from "../../stores/AuthStore";
import JobProgress from "../../utils/JobProgress";

class ReportsModal extends React.Component {

  constructor(props) {
    super(props);
    const { currentUser, suppliers, sales_users, flight_types } = props;
    const all_statuses = [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
      { value: "retired", label: "Retired" }
    ];
    this.state = {
      selectedReport: { value: null, label: "" },
      start_date: moment().subtract(1, "weeks"),
      end_date: moment(),
      errors: {},
      currentUser: currentUser,
      suppliers: suppliers,
      sales_users: sales_users,
      selectedSupplier: null,
      sales_user: null,
      selectedFlightTypes: flight_types,
      face_id: null,
      running: false,
      report_url: null,
      selectedStatuses: all_statuses,
      job_polling: new JobProgress(),
      fault_reasons: [],
      selectedFaultReasons: null,
      unit_status: null
    };

    this.setStartDate  = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setSelectedFlightTypes = this.setSelectedFlightTypes.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.validate = this.validate.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
    this.onReportComplete = this.onReportComplete.bind(this);
    this.fetchFaultReasons = this.fetchFaultReasons.bind(this);
    this.debouncedLoadOptions = _.debounce(this.getAdvertiser.bind(this), 500);
    this.changeAdvertiser = this.changeAdvertiser.bind(this)
  }

  async componentWillMount() {
    const { currentUser } = this.state;
    const { fetchSalesUsers, fetchSuppliers } = this.props;
    if (!currentUser) this.setState({ currentUser: AuthStore.getState().user });
    this.fetchFaultReasons();
    fetchSalesUsers();
    if (currentUser && currentUser.is_admin) {
      fetchSuppliers();
    }
  }

  async getAdvertiser(q) {
    const response = await $.get(`/api/v1/advertisers`, {q: q})
    this.setState({advertisers: response.advertisers})
    const options = response.advertisers.map(a => Object.assign({}, { label: a.name, value: a.id }));
    return options;
  }

  changeAdvertiser(data) {
    this.setState({ selectedAdvertiser: {label: data.label, value: data.value} });
  }

  async fetchFaultReasons() {
    const { fetchFaultReasons } = this.props;
    const fault_reasons = await fetchFaultReasons();
    this.setState({
      fault_reasons
    });
  }

  validate() {
    const params = {
      ...this.props,
      ...this.state
    };
    const errors = validate(params, this.constraints);
    this.setState({ errors });
    return errors;
  }

  renderErrors() {
    const { errors } = this.state;
    const formattedErrors = [];

    for (const key in errors) formattedErrors.push(errors[key]);

    if (formattedErrors.length)
      return formattedErrors.map(err => (
        <p className="message__row" key={err}>
          {err}
        </p>
      ));
  }

  setStartDate(name) {
    if (name === "activity_log") {
      return moment().subtract(1, "weeks");
    } else if (name === "upcoming_flights") {
      return moment().startOf("isoWeek").add(1, "week");
    } else {
      return moment();
    }
  }

  setEndDate(name) {
    if (name == "activity_log") {
      return moment();
    } else if (name == "utilization") {
      return moment().add(12, "weeks");
    } else {
      return moment().add(4, "weeks");
    }
  }

  setSelectedFlightTypes(name) {
    const { flight_types } = this.props;
    if (!flight_types) return [];
    if (["utilization", "revenue_report", "upcoming_flights"].includes(name)) {
      return flight_types.filter(ft => ["booked", "movable"].includes(ft.internal_type));
    } else {
      return flight_types.filter(ft =>
        ["booked", "on_hold", "hold", "movable"].includes(ft.internal_type)
      );
    }
  }

  onNameChange(selectedReport) {
    if (!selectedReport) return false;
    const start_date = this.setStartDate(selectedReport.value);
    const end_date = this.setEndDate(selectedReport.value);
    const selectedFlightTypes = this.setSelectedFlightTypes(selectedReport.value);
    this.setState({ selectedReport, errors: {}, start_date, end_date, selectedFlightTypes });
  }

  onReportComplete(job) {
    const { data: { report_url } } = job;
    this.setState({ running: false });
    if (report_url) {
      GlobalActions.showMessage("Report complete");
      window.open(report_url, "_blank");
    } else {
      GlobalActions.showError("There was an issue generating this report");
    }
  }

  async generateReport(e) {
    this.setState({ running: true })
    e.preventDefault();
    let errors = this.validate() || [];
    const {
      selectedAdvertiser,
      selectedReport,
      selectedSupplier,
      selectedFlightTypes,
      selectedStatuses,
      selectedFaultReasons,
      start_date,
      end_date,
      currentUser,
      sales_user,
      face_id,
      job_polling
    } = this.state;
    if (!!!start_date) errors.push("invalid start date");
    if (!!!selectedAdvertiser && selectedReport.value === "billing_report") errors.push("must select an advertiser end date")
    if (!!!end_date && selectedReport.value != "upcoming_flights") errors.push("invalid end date");
    if (errors && errors.length) {
      this.setState({ errors, running: false });
      return false;
    }
    if (!currentUser) return false;
    const supplier_id = selectedSupplier ? selectedSupplier.id : currentUser.supplier_id;
    const sales_user_id = sales_user ? sales_user.id : null;
    const params = {
      report: {
        name: selectedReport.value,
        supplier_id,
        sales_user_id,
        face_id,
        flight_types: selectedFlightTypes,
        statuses: selectedStatuses,
        fault_reasons: selectedFaultReasons,
        advertiser_id: selectedAdvertiser?.value,
        start_date: moment(start_date).toDate(),
        end_date: moment(end_date).toDate(),
        unit_status: this.state.unit_status?.value
      }
    };
    try {
      const res = await post("/api/v1/reports", params);
      const { job_id } = res;
      job_polling.startPolling(job_id, this.onReportComplete);
    } catch (error) {
      this.setState({ running: false });
    }
  }

  renderStatusSelect(name) {
    const { selectedStatuses } = this.state;
    return (
      <FormGroup>
        <ControlLabel>{name} Status</ControlLabel>
        <Select
          isMulti={true}
          value={selectedStatuses}
          onChange={updatedStatuses =>
            this.setState({ selectedStatuses: updatedStatuses })
          }
          options={[
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
            { value: "retired", label: "Retired" }
          ]}
        />
      </FormGroup>
    )
  }

  renderFaultReasonSelect() {
    const { selectedFaultReasons, fault_reasons } = this.state;
    const options = fault_reasons.map((fr) => {
      return { value: fr, label: fr }
    });
    options.unshift({ value: "All", label: "All Fault Reasons" });

    return (
      <FormGroup>
        <ControlLabel>Fault Reason</ControlLabel>
        <Select
          isMulti={true}
          value={selectedFaultReasons}
          onChange={updatedFaultReasons =>
            this.setState({ selectedFaultReasons: updatedFaultReasons })
          }
          options={options}
        />
      </FormGroup>
    )
  }

  renderFlightTypeSelect() {
    const { selectedFlightTypes } = this.state;
    const { flight_types } = this.props;
    const filtered_flight_types =
      flight_types &&
      flight_types.filter(ft => !["available", "proposed"].includes(ft.internal_type));
    return (
      <FormGroup>
        <ControlLabel>Flight Types</ControlLabel>
        <Select
          isMulti={true}
          value={selectedFlightTypes}
          onChange={updatedFlightTypes => this.setState({selectedFlightTypes: updatedFlightTypes})}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.internal_type}
          options={filtered_flight_types}
        />
      </FormGroup>
    )
  }

  renderSalesUserSelect() {
    const { sales_user } = this.state;
    const { sales_users } = this.props;

    return (
      <FormGroup>
        <ControlLabel>Sales Executive</ControlLabel>
        <Select
          value={sales_user}
          onChange={sales_user => this.setState({ sales_user })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          options={sales_users}
        />
      </FormGroup>
    )
  }

  renderUnitStatusSelect(name) {
    if (!name) return
    if (!["inventory_export", "utilization"].includes(name)) {
      return
    }
    return (
      <FormGroup>
        <ControlLabel>Unit Status</ControlLabel>
        <Select
          value={this.state.unit_status}
          onChange={unit_status => this.setState({ unit_status })}
          options={[{value: 'all', label: 'All'}, {value: 'active', label: 'Active'}, {value: 'inactive', label: 'Inactive'}, {value: 'retired', label: 'Retired'}]}
        />
      </FormGroup>
    )
  }

  renderSupplierSelect() {
    const { selectedSupplier } = this.props;
    const { suppliers } = this.props;
    return (
      <FormGroup>
        <ControlLabel>Supplier</ControlLabel>
        <Select
          value={selectedSupplier}
          onChange={selectedSupplier => this.setState({ selectedSupplier, errors: {} })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          options={suppliers}
        />
      </FormGroup>
    )
  }
  renderAdvertiserSelect() {
    return (
      <FormGroup>
        <ControlLabel>Advertiser</ControlLabel>
        <Async
          id="advertisers_select"
          placeholder="Type to search for and advertiser"
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          value={this.state.selectedAdvertiser}
          onChange={this.changeAdvertiser}
          loadOptions={inputValue => this.getAdvertiser(inputValue)}
        />
      </FormGroup>
    )
  }

  render() {
    const {
      selectedReport,
      currentUser,
      start_date,
      end_date,
      face_id,
      running,
      report_url
    } = this.state;
    const name = selectedReport && selectedReport.value;
    const report_options = [
      { value: "activity_log", label: "Activity Log" },
      { value: "time_space", label: "Time Space" },
      { value: "upcoming_flights", label: "Upcoming Flights" },
      { value: "inventory_export", label: "Inventory Export" },
      { value: "revenue_report", label: "Revenue Report" },
      { value: "utilization", label: "Utilization Percentage" },
      { value: "holds_report", label: "Holds Report" },
      { value: "history_by_face_id", label: "Flight History by Face ID" },
      { value: "maintenance", label: "Maintenance" },
      { value: "leases", label: "Leases" },
      { value: "lease_revenue", label: "Lease Revenue" },
      { value: "royalties_report", label: "Royalties Report" },
      { value: "billing_report", label: "Billing Report" },
    ];
    if (currentUser && currentUser.is_admin) {
      report_options.push(
        { value: "occupancy", label: "Occupancy Report (excludes digital)" }
      );
    }

    return (
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>Generate Reports</Modal.Title>
            {running && <div className="message">
              <div className="loading-spinner">
                <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> Your report is running...
              </div>
            </div>}
        </Modal.Header>
        {(!!!running && !!!report_url) && <Modal.Body>
          {this.renderErrors() && (
            <FormGroup>
              <div className="message message_error">{this.renderErrors()}</div>
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>Report</ControlLabel>
            <Select
              value={selectedReport}
              valueKey={"name"}
              onChange={this.onNameChange}
              options={report_options}
            />
          </FormGroup>
          {currentUser && currentUser.is_admin && name && !!!["occupancy", "leases", "billing_report"].includes(name) &&
            this.renderSupplierSelect()
          }
          {name === "billing_report" && this.renderAdvertiserSelect()}
          {name === "revenue_report" && this.renderSalesUserSelect()}
          {this.renderUnitStatusSelect(name)}
          {name && !!!["upcoming_flights", "occupancy", "maintenance", "leases", "inventory_export"].includes(name) && (
            <FormGroup>
              <DateRangePicker
                start_date={start_date}
                end_date={end_date}
                onDatesChange={({ start_date, end_date }) =>
                  this.setState({ start_date, end_date })
                }
              />
            </FormGroup>
          )}
          {(name === 'history_by_face_id') && <FormGroup>
            <ControlLabel>Face ID</ControlLabel>
            <FormControl
              value={face_id}
              onChange={e => this.setState({ face_id: e.target.value })}
            />
          </FormGroup>}
          {name === "upcoming_flights" &&
            <FormGroup>
              <SingleDatePicker
                date={start_date}
                onDateChange={({ date }) => this.setState({ start_date: date })}
              />
            </FormGroup>
          }
          {(name && !!!["holds_report", "occupancy", "maintenance", "leases", "lease_revenue", "inventory_export", "billing_report"].includes(name)) &&
            this.renderFlightTypeSelect()
          }
          {(name === 'maintenance') && this.renderStatusSelect("Unit")}
          {(name === 'maintenance') && this.renderFaultReasonSelect()}
          {(name === 'leases' || name === 'lease_revenue') && this.renderStatusSelect("Lease")}
        </Modal.Body>}
        <Modal.Footer>
          {(!!!running && !!!report_url) && <Button
            style={{ margin: "10px", float: "left" }}
            bsStyle="primary"
            onClick={e => this.generateReport(e)}
          >
            Generate Report
          </Button>}
          <Button
            style={{ margin: "10px" }}
            className="btn btn-default"
            onClick={this.props.onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default connect(
  ({ inventory, currentUser }) => ({
    suppliers: inventory.suppliers,
    currentUser,
    sales_users: inventory.sales_users,
    flight_types: inventory.flightTypes
  }),
  { fetchSuppliers, fetchCurrentUser, fetchSalesUsers, fetchFaultReasons }
)(ReportsModal);
