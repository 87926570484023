import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { numberFormatter, dateFormatter, dollarFormatter } from '../../../utils/formatters'
import moment from 'moment'
import { browserHistory } from 'react-router-legacy';
import { formatMoney } from 'accounting'
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap'

const statusLabel = status => {
  if (status == "request") {
    return "default"
  } else if (status == "proposal") {
    return "primary"
  } else if (status == "contracted") {
    return "success"
  } else if (status == "canceled") {
    return "danger"
  } else if (status == "dead_proposal") {
    return "warning"
  }
}

const Card = createClass({
  displayName: 'Card',

  getInitialState() {
    return {
      show_delete_confirmation: false,
      saving: false
    };
  },

  openCampaign(e) {
    const { campaign, user } = this.props
    const url = `/campaigns/${campaign.token}`
    browserHistory.push(url)
  },

  onDeleteConfirm() {
    this.setState({ saving: true })
    $.ajax(`/api/v1/campaigns/${this.props.campaign.token}.json`, { method: 'DELETE' }).then((response) => {
      if (response.status === 200)
        this.setState({ show_delete_confirmation: false, saving: false }, this.props.onDelete())
      else console.log(`couldn't delete campaign ${this.props.campaign.token}, ${response.status}`)
    })
  },

  render() {
    const { campaign, user } = this.props
    const { show_delete_confirmation, saving } = this.state

    return (
      <div className="card" onClick={this.openCampaign} style={{marginBottom:15}}>
        {(user && (user.is_admin || user.is_supplier)) && <div>
          <Modal
            show={show_delete_confirmation}
            onHide={this.onDeleteConfirm}>
            <Modal.Header>
              <h4>Are you sure you want to delete campaign {campaign.name}?</h4>
            </Modal.Header>
            <Modal.Body>
              <p>Note that deleting this campaign will also delete all flights associated with this campaign</p>
            </Modal.Body>
            <Modal.Footer>
              <button disabled={saving} className="btn btn-danger" type="button" onClick={this.onDeleteConfirm}>Delete</button>
              <a onClick={() => this.setState({ show_delete_confirmation: false })} className="btn btn-default">Close</a>
            </Modal.Footer>
          </Modal>
        </div>}
        <div style={{ fontSize: 16, marginBottom: 3, fontWeight: 600 }}>
          <a onClick={this.openCampaign}>{campaign.name}</a>
          {(campaign.status == "canceled" || campaign.status == "contracted") &&
            <span className={`label label-${statusLabel(campaign.status)}`} style={{ marginLeft: 10 }}>{campaign.status}</span>}
        </div>
        <div>
          {campaign.units_count} units {!campaign.supplier_id && <span>from {campaign.vendors_count} vendors.</span>}
          {campaign.favorites_count > 0 && <span style={{ color: '#D21C29', marginLeft: 10 }}>
            <i className="fa fa-heart" /> {campaign.favorites_count} units favorited
          </span>}
          {campaign.views_count > 0 && <span style={{ color: '#8E9FA3', marginLeft: 10 }}>
            <i className="fa fa-eye" /> {campaign.views_count} views
          </span>}
        </div>
        <div>
          Value: {formatMoney(campaign.four_week_value)}
        </div>
        <div>
          <div style={{ fontSize: 12, marginTop: 5, color: 'gray' }}>
            Created at {moment(campaign.created_at).format('LL')}
            {(campaign.user.name || campaign.user.email) &&
              <span> by {campaign.user.name || campaign.user.email}</span>}
          </div>
        </div>
      </div>
    )
  }
})

Card.propTypes = {
  campaign: PropTypes.object.isRequired
};

export default Card;
