const Theme = {
  MAIN_TOOLBAR_HEIGHT: "50px",

  // Each color theme has it's own color and complementary color.
  // Helps to create some components ex.: Button, text -> color, background -> complementary

  PRIMARY_COLOR: { color: "#4A90E2", comp: "#FFF", hover: "#2974CC" },
  PRIMARY_TRANSPARENT_COLOR: { color: "#4A90E2", comp: "transparent", hover: "transparent" },
  PRIMARY_LIGHT_COLOR: { color: "#F1F6FD", comp: "#4A90E2", hover: "#F1F6FD" },
  PRIMARY_ALPHA_COLOR: { color: "rgba(74, 144, 226, 0.07)", comp: "transparent", hover: "transparent" },
  PRIMARY_WHITE_COLOR: { color:"#FFF", comp: "#2974CC", hover: "#E6E6E6" },
  ACCENT_COLOR: { color: "#44AC6B", comp: "#FFF", hover: "#368754" },
  ACCENT_LIGHT_COLOR: { color: "#DAEBF7", comp: "#44AC6B", hover: "#44AC6B" },
  FACEBOOK_COLOR: { color: "#4465B1", comp: "#FFF", hover: "#4465B1" },
  WARN_COLOR: { color: "#F52323", comp: "#FFF", hover: "#FFF" },
  NEUTRO_COLOR: { color: "#EEE", comp: "#4A90E2", hover: "#4A90E2" },
  DISABLED_COLOR: { color: "#EEE", comp: "#FFF", hover: "#FFF" },
  DISABLED_DARK_COLOR: { color: "#CCC", comp: "#FFF", hover: "#EEE" },
  SHADOW_COLOR: { color: "transparent", comp: "#858585", hover: "#858585" },
  SHADOW_LINK_COLOR: { color: "transparent", comp: "#858585", hover: "transparent" },
  SUCCESS_COLOR: { color: "#44AC6B", comp: "#fff", hover: "#3A915C" },

  BG_NEUTRO_COLOR: "#EFEFEF",

  MAIN_HEADER_HEIGHT: 50,
  CONTENT_PADDING: "1.2rem",

  SHADOW_DEFAULT: "0px 2px 8px rgba(0, 0, 0, 0.06)",
  SHADOW_LONG: "0px 2px 6px rgba(0, 0, 0, 0.1)",
  SHADOW_LIGHT_RIGHT: "0px 1px 4px rgba(0, 0, 0, 0.08)",

  TEXT_DEFAULT: {
    iconHeight: "16px",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "normal",
  },
  TEXT_LABEL: {
    iconHeight: "14px",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: 600,
  },
  TEXT_TITLE: {
    iconHeight: "16px",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 600,
  },
  TEXT_HEADLINE: {
    iconHeight: "18px",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 500,
  },
  TEXT_SUBHEAD: {
    iconHeight: "16px",
    fontSize: "16px",
    lineHeight: "42px",
    fontWeight: 500,
  },
  TEXT_SMALL: {
    iconHeight: "14px",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "normal",
  },
  TEXT_BIG: {
    iconHeight: "24px",
    fontSize: "36px",
    lineHeight: "44px",
    fontWeight: 600,
  },

  INPUT_LINE: {
    border: "1px solid #DDDDDD",
    color: "#333",
    background: "#FFF",
  },
  INPUT_LINELESS: { border: "none", color: "#333", background: "#FFF" },
  INPUT_GREY: { border: "none", color: "#333", background: "#F5F5F5" },

  BLUE: "#4A90E2",
  GREEN: "#44AC6B",
  RED: "#D1132A",
  DARK: "#858585",
};

export default Theme;
