import * as React from "react";

interface Props {
  toggleModal: Function
}

interface State {}

class BulkUpdateInstallerAction extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  private onClick(e) {
    this.props.toggleModal();
  }

  public render() {
    const button_style = {
      color: "#555555",
      width: "100%",
      fontSize: 12,
      marginBottom: "5px"
    };

    return (
      <button
        key="add_posting_note"
        onClick={this.onClick}
        className={"btn btn-default"}
        style={button_style}
      >
        Update Installer
      </button>
    );
  }
}

export default BulkUpdateInstallerAction;
