import React from 'react';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore'
import FlightTypes from './FlightTypes'

export default createClass({
  displayName: 'Settings',

  getInitialState() {
    return {
      user: AuthStore.getState().user,
    }
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ]
  },

  renderNav() {
    return (
      <ul className="nav nav-pills nav-stacked">
        <li className="active"><a onClick={() => {}}>Flight Types</a></li>
      </ul>
    )
  },

  render() {
    return (
      <div className="container">
        <h1 style={{paddingBottom:10}}>Settings</h1>

        <div className="row">
          <div className="col-md-2">
            {this.renderNav()}
          </div>
          <div className="col-md-10">
            <FlightTypes />
          </div>
        </div>
      </div>
    )
  }
})
