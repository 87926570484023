export default class CustomPointOfInterest {

  constructor(mapDrawer) {
    this.mapDrawer = mapDrawer;
    this.active = false;
  }

  onAdd(map){
    this.map = map;
    this.container = document.createElement('div');
    //mapboxgl-ctrl class is needed for the control to be clickable
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    const icon = document.createElement('button');
    icon.className = 'custom-point-of-interest-control';
    this.container.appendChild(icon);

    this.container.onclick = this.onClick.bind(this);

    return this.container;
  }

  onClick() {
    if(this.mapDrawer.getMode() == 'custom_point_of_interest') {
      this.mapDrawer.changeMode('simple_select');
    } else {
      this.mapDrawer.changeMode('custom_point_of_interest')
    }

    this.active = !this.active;
  }

  onRemove(){
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

}
