import React from 'react';
import ReactDataGrid from 'react-data-grid';
import Select from 'react-select';
import createClass from 'create-react-class';
import update from 'immutability-helper';
import moment from 'moment';
import _ from 'lodash';

import { get, post } from '../../utils/api';
import { visitUrl } from '../../utils/visitUrl';
import AuthStore from '../../stores/AuthStore';
import GlobalActions from '../../actions/GlobalActions';
import RequiredMapping from './RequiredMapping';
import Loading from '../Loading';
import JobProgress from '../../utils/JobProgress';

const UnitImporter = createClass({
  displayName: 'UnitImporter',

  getInitialState() {
    return({
      campaign: {},
      user: AuthStore.getState().user,
      file_name: null,
      file_url: null,
      supplier_name: null,
      supplier_id: null,
      inventory_group_id: null,
      campaign_name: null,
      uploaded_by_email: null,
      updated_at: null,
      indexToRemove: "",
      new_column_hame: "",
      remove_column_hame: "",
      file_id: this.props.params.file_id,
      rows: [],
      columns: [],
      headers: [],
      column_map: {},
      errors: null,
      showApproveHeadersButton: false,
      headersApproved: false,
      isSaving: false,
      isLoading: true,
      unpacking: false,
      data_request: "",
      current_row: null,
      import_multi_flights: false,
      disable_hemisphere_validation: false,
      override_unit_prices: true
    })
  },

  requiredMappings: [
    'start_date',
    'end_date',
    'supplier_face_id',
    'screen_type',
    'unit_type',
    'lat',
    'lon',
    'size',
    'impressions',
    'price',
    'installation_cost',
    'production_cost'
  ],

  componentDidMount() {
    this.loadData();
  },

  componentWillUnmount() {
    document.title = AppConfig.defaultTitle;
  },

  async loadData() {
    const response = await get(`/api/v1/unit_imports/${this.state.file_id}`)
    document.title = "UI | " + response.file_name;

    if (response.status !== "new") {
      this.pollForStatus();
    }

    this.setState({
      isLoading: false,
      file_name: response.file_name,
      file_url: response.file_url,
      supplier_name: response.supplier_name,
      supplier_id: response.supplier_id,
      inventory_group_id: response.inventory_group_id,
      campaign_name: response.campaign_name,
      campaign_id: response.campaign_id,
      uploaded_by_email: response.uploaded_by_email,
      updated_at: moment(response.updated_at).format("M/D/YY H:m:s"),
      claimed_by: response.claimed_by,
      headers: response.headers,
      columns: this.createColumns(response.headers),
      rows: response.rows,
      column_map_options: this.createColumnMapOptions(response.column_options),
      column_map: this.createColumnMap(response.headers),
      data_requests: response.data_requests,
      complete_supplier_task: true,
    })

    if (_.isEmpty(response.rows)) {
      this.unpackFile();
    }
  },

  async unpackFile() {
    this.setState({ unpacking: true });
    const response = await post(`/api/v1/unit_imports/${this.state.file_id}/unpack`)

    const job_progress = new JobProgress();
    job_progress.startPolling(
      response.job_id,
      this.onUnpackFinished.bind(this)
    );
  },

  onUnpackFinished(job_completion_result) {
    const file = job_completion_result.data.file;

    this.setState({
      unpacking: false,
      file_name: file.file_name,
      file_url: file.file_url,
      supplier_name: file.supplier_name,
      supplier_id: file.supplier_id,
      inventory_group_id: file.inventory_group_id,
      campaign_name: file.campaign_name,
      campaign_id: file.campaign_id,
      uploaded_by_email: file.uploaded_by_email,
      updated_at: moment(file.updated_at).format("M/D/YY H:m:s"),
      claimed_by: file.claimed_by,
      headers: file.headers,
      columns: this.createColumns(file.headers),
      rows: file.rows,
      column_map_options: this.createColumnMapOptions(file.column_options),
      column_map: this.createColumnMap(file.headers),
      data_requests: file.data_requests,
    })
  },

  createColumnMap(headers) {
    let map = {}
    headers.forEach(header =>  {
      map[header.name] = header.value
    })
    return map;
  },

  createColumnMapOptions(list) {
    return list.map(item => { return { label: item, value: item }})
  },

  columnWidthForText(text) {
    return Math.max(text.length * 11, 120);
  },

  createColumns(headers) {
    if (!headers || headers.length == 0) return [];
    let result = headers.map((header) => {
      return {
        key: header.name,
        name: header.name,
        editable: true,
        resizable: true,
        width: this.columnWidthForText(header.name),
      }
    })
    result.unshift({
      key: "index",
      name: "Row",
      editable: false,
      resizble: false,
      width: 50,
    })
    return result;
  },

  rowGetter(i) {
    return this.state.rows[i];
  },

  handleGridRowsUpdated({ fromRow, toRow, updated }) {
    let rows = this.state.rows.slice();

    for (let i = fromRow; i <= toRow; i++) {
      let rowToUpdate = rows[i];
      let updatedRow = update(rowToUpdate, {$merge: updated});
      rows[i] = updatedRow;
    }
    this.setState({ rows });
  },

  serverData() {
    let data = {
      rows: this.state.rows,
      column_map: this.state.column_map,
    };
    if (this.state.headersApproved) data["headers_approved"] = true;
    if (this.state.complete_supplier_task) data["complete_supplier_task"] = true;
    if (this.state.import_multi_flights) data["import_multi_flights"] = true;
    if (this.state.override_unit_prices) data["override_unit_prices"] = true;
    if (this.state.disable_hemisphere_validation) data["disable_hemisphere_validation"] = true;
    return data;
  },

  onSave() {
    this.setState({ errors: null, success: null, isSaving: true });
    $.ajax({
      url: `/api/v1/unit_imports/${this.state.file_id}`,
      method: "PATCH",
      data: this.serverData(),
      success: (response) => {
        GlobalActions.showMessage("Changes saved!");
        this.setState({ isSaving: false })
        this.loadData();
      },
      error: (response) => {
        this.setState({ errors: response.responseJSON.errors, isSaving: false })
      }
    })
  },

  onSaveColumnMaps() {
    this.setState({ errors: null, success: null, isSaving: true });
    $.ajax({
      url: `/api/v1/unit_imports/${this.state.file_id}`,
      method: "PATCH",
      data: {column_map: this.state.column_map},
      success: (response) => {
        GlobalActions.showMessage("Changes saved!");
        this.setState({ isSaving: false })
        this.loadData();
      },
      error: (response) => {
        this.setState({ errors: response.responseJSON.errors, isSaving: false })
      }
    })
  },

  onResetProcessing() {
    this.setState({ errors: null, success: null, isSaving: false, resetting: true });
    $.ajax({
      url: `/api/v1/unit_imports/${this.state.file_id}/unstuck`,
      method: "POST",
      success: (response) => {
        this.setState({ errors: null, success: "Ready to try again", isSaving: false, stuck_processing: false, resetting: false });
      },
      error: (response) => {
        const errorString = response.responseJSON.errors;
        this.setState({ errors: errorString, isSaving: false });
      },
    });
  },

  onApproveHeadersAndImport() {
    this.setState({ headersApproved: true }, this.onImport)
  },

  onImport(e) {
    $("html").animate({ scrollTop: 0 }, 300);
    this.setState({ errors: null, success: null, isSaving: true });
    $.ajax({
      url: `/api/v1/unit_imports/${this.state.file_id}/import`,
      method: "POST",
      data: this.serverData(),
      success: (response) => {
        console.log("Job started...")
        this.pollForStatus();
      },
      error: (response) => {
        const errorString = response.responseJSON.errors;
        this.setState({ errors: errorString, isSaving: false })
      }
    })
  },

  pollForStatus() {
    $.ajax({
      url: `/api/v1/unit_imports/${this.state.file_id}/check_import_status`,
      success: (response) => {
        if (response.status == "processed") {
          this.setState({ isSaving: false, success: response.results, current_row: null })
        } else if (response.status == "processing") {
          this.setState({ isSaving: true, current_row: response.current_row, stuck_processing: response.stuck_processing });
          setTimeout(this.pollForStatus, 1500);
        }
      },
      error: (response) => {
        const errorString = response.responseJSON.errors || "Unknown error - grab Vic or Luiz";
        const showApproveHeadersButton = this.checkForExtraHeadersError(errorString);
        this.setState({
          errors: errorString,
          showApproveHeadersButton: showApproveHeadersButton,
          isSaving: false,
          current_row: null
        })
      }
    })
  },

  onAddColumn() {
    const { new_column_name } = this.state;
    if (!new_column_name) {
      GlobalActions.showError("You must select a column to add.");
      return;
    }
    let columns = update(this.state.columns, {$push:
      [{
        key: new_column_name,
        name: new_column_name,
        editable: true,
        resizble: true,
        width: this.columnWidthForText(new_column_name),
      }]
    })
    let rows = this.state.rows.map(row => {
      row[new_column_name] = "";
      return row;
    })
    this.setState({ columns: columns, new_column_name: "", rows: rows });
    GlobalActions.showMessage("Column added.");
  },

  onRemoveColumn() {
    let index;
    let i = 0;
    this.state.columns.forEach(columnObject => {
      if (columnObject.key === this.state.remove_column_name) index = i;
      i++;
    })

    const columns = update(this.state.columns, {$splice: [[index, 1]]});

    const rows = this.state.rows.map(row => {
      delete row[this.state.remove_column_name];
      return row;
    })

    this.setState({ columns: columns, remove_column_name: "", rows: rows });

    GlobalActions.showMessage("Column removed.");
  },

  onAddRow() {
    let newRow = {};
    this.state.headers.forEach(header => {
      newRow[header.name] = "";
    })
    newRow["index"] = this.state.rows.length;
    let rows = this.state.rows;
    rows.push(newRow);
    this.setState({ rows: this.resetIndexes(rows)})
    GlobalActions.showMessage("Row added.");
  },

  resetIndexes(rows) {
    let i = 0;
    return rows.map(row => {
      row["index"] = i;
      i += 1;
      return row
    })
  },

  onRemoveRow() {
    if (!this.state.indexToRemove) return;
    let rows = this.state.rows;
    rows.splice(this.state.indexToRemove, 1);
    this.setState({ rows: this.resetIndexes(rows), indexToRemove: "" });
    GlobalActions.showMessage("Row removed.");
  },

  onColumnMapChange(header, option) {
    let new_map = this.state.column_map;
    new_map[header] = (option && option.value) || null;
    this.setState({ column_map: new_map });
  },

  onRequestDataChange(event) {
    this.setState({ data_request: event.target.value });
  },

  async onRequestData() {
    await post(`/api/v1/unit_imports/${this.state.file_id}/data_requests`, { message: this.state.data_request });
    this.setState({ data_requests: [{message: this.state.data_request}].concat(this.state.data_requests) });
  },

  checkForExtraHeadersError(errorString) {
    if (!errorString) return false
    return errorString.includes("Extra") && !errorString.includes("Missing")
  },

  render() {
    const {
      unpacking,
      isLoading,
      file_name,
      file_url,
      supplier_name,
      supplier_id,
      inventory_group_id,
      campaign_name,
      campaign_id,
      uploaded_by_email,
      updated_at,
      claimed_by,
      rows,
      headers,
      columns,
      column_map_options,
    } = this.state;

    const inventoryGroupUrl = `/admin/inventory_groups/${inventory_group_id}`;
    const inventoryGroupName = `Inventory Group ${inventory_group_id}`;
    const add_column_options = (column_map_options || []).filter(c => (
      !columns.find(cc => cc.key === c.value)
    ));

    if (isLoading) {
      return <Loading >
        <span>Loading file...</span>
      </Loading>;
    }

    if (unpacking) {
      return <Loading >
        <span>Unpacking file. This may take a while, please wait...</span>
      </Loading>;
    }

    return (
      <div id="unit_importer">

        <ol className="breadcrumb" style={{top: -5}}>
          <li><a href="/admin/inventory_groups">All Groups</a></li>
          {inventory_group_id &&
              <li><a href={inventoryGroupUrl}>{inventoryGroupName}</a></li>
          }
          <li className="active">{file_name}</li>
        </ol>

        <h2>Unit Importer - {file_name}</h2>

        <div>

          <div className="file_info">

            {inventory_group_id &&
                <div>
                  Inventory Group:
                  {" "}<a className="bold" href={inventoryGroupUrl}>{inventoryGroupName}</a>
                </div>
            }

            {file_name &&
                <div>
                  Download:
                  {" "}<a className="bold" href={file_url} target="_blank">{file_name}</a>
                </div>
            }

            {supplier_name &&
                <div>
                  Vendor Name:
                  {" "}<a className="bold" href={`/admin/suppliers/${supplier_id}`}>{supplier_name}</a>
                </div>
            }

            <div>
              Campaign Name:{" "}
              {campaign_name ?
                  <a className="bold" onClick={(e) => { visitUrl(e, `/campaigns/${campaign_id}`) }}>{campaign_name}</a>
                  :
                  "Not attached to a campaign"
              }
            </div>

            <div>Uploaded By: {uploaded_by_email}</div>
            { this.state.user.is_admin && claimed_by && <div>Claimed By: { claimed_by }</div> }

            <div>Last updated at: {updated_at}</div>

          </div>

          { (this.state.isSaving && this.state.current_row) &&
              <div className="alert alert-info">Importing... Current row: {this.state.current_row}</div>
          }
          { (this.state.isSaving && !this.state.current_row && !this.state.stuck_processing) &&
              <div className="alert alert-info">Saving...</div>
          }

          { (this.state.stuck_processing) &&
          <div className="alert alert-warning">
            This file has been processing longer than expected. You can reset the file state and try again.
            <button className="btn btn-warning" style={{marginLeft: '10px'}} disabled={this.state.resetting} onClick={this.onResetProcessing}>Reset</button>
          </div>
          }

          {this.state.success &&
              <div className="alert alert-success">{this.state.success}</div>
          }

          {this.state.errors &&
              <div className="alert alert-danger">
                {this.state.errors}
                {this.state.showApproveHeadersButton &&
                    <div className="approve_headers">
                      <div className="btn btn-warning" onClick={this.onApproveHeadersAndImport}>Approve Extra Headers and Import Units</div>
                    </div>
                }
              </div>
          }

          <div className="column_mappings">
            <h4>Map Headers</h4>

            <RequiredMapping should_map={ this.requiredMappings } mapped={ this.state.column_map } />

            {headers.map(header => {
              return (
                <div className="item" key={header.name}>
                  <label>{header.name}</label>
                  <Select
                    options={column_map_options}
                    value={column_map_options.find(o => o.value == this.state.column_map[header.name])}
                    onChange={(value) => { this.onColumnMapChange(header.name, value)}}
                    isClearable
                  />
                </div>
              )})
            }

            <div className="buttons">
              <div className="btn btn-primary" onClick={this.onSaveColumnMaps}>Save Column Maps</div>
              <div className="btn btn-primary" onClick={this.onSave}>Save Data and Column Maps</div>
              <div className="btn btn-warning" onClick={this.onImport}>Save Everything and Import Units</div>
              <label>
                <input
                  type="checkbox"
                  checked={this.state.complete_supplier_task}
                  onChange={(e) => { this.setState({ complete_supplier_task: e.target.checked })}}
                  style={{ marginRight: "5px" }}
                />
                Mark supplier task complete after import
              </label>
              <label style={{marginLeft:'10px'}}>
                <input
                  type="checkbox"
                  checked={this.state.import_multi_flights}
                  onChange={(e) => { this.setState({ import_multi_flights: e.target.checked })}}
                  style={{ marginRight: "5px" }}
                />
                Multi flight import
              </label>
              <label style={{marginLeft:'10px'}} title="If unchecked unit prices will be updated only if not already set. If its a new unit, it will be created with prices. Campaign unit prices are always updated.">
                <input
                  type="checkbox"
                  checked={this.state.override_unit_prices}
                  onChange={(e) => { this.setState({ override_unit_prices: e.target.checked })}}
                  style={{ marginRight: "5px" }}
                />
                Force Override Unit Prices
              </label>
              <label style={{marginLeft:'10px'}} title="If checked, it'll disable lat/lon validations, allowing the import of units outside of the NW hemisphere.">
                <input
                  type="checkbox"
                  checked={this.state.disable_hemisphere_validation}
                  onChange={(e) => { this.setState({ disable_hemisphere_validation: e.target.checked })}}
                  style={{ marginRight: "5px" }}
                />
                Allow import outside of NW hemisphere
              </label>
            </div>
          </div>
          <div className="unit_data">
            <h4>Unit Data</h4>

            {(columns.length > 0) ?
                <ReactDataGrid
                  enableCellSelect={true}
                  columns={columns}
                  rowGetter={this.rowGetter}
                  rowsCount={rows.length}
                  minHeight={550}
                  onGridRowsUpdated={this.handleGridRowsUpdated}
                />
                :
                <div className="alert alert-warning">
                  There was a problem parsing the file. Something is probably wrong with the file format.
                  See if you can open it in Excel then Save As xls or xlsx and then re-upload and try again. If that fails then ping an engineer.
                </div>
            }

            <div className="sheet_actions">
              <div className="btn btn-default" onClick={this.onAddRow}>Add Row</div>

              <input
                type="text"
                placeholder="row to remove"
                value={this.state.indexToRemove}
                className="form-control"
                onChange={(e) => { this.setState({ indexToRemove: e.target.value }) }}
              />
              <button className="btn btn-default form_button" onClick={this.onRemoveRow}>Remove Row</button>

              <select
                placeholder="column to add"
                className="form-control"
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ new_column_name: e.target.value })
                }}
              >
                <option>Select column</option>
                {add_column_options.map((o, idx) => <option key={`option-${idx}`} value={o.value}>{o.label}</option>)}
              </select>
              <button className="btn btn-default form_button" onClick={this.onAddColumn}>Add Column</button>

              <input
                type="text"
                placeholder="column to remove"
                value={this.state.remove_column_name}
                className="form-control"
                onChange={(e) => { this.setState({ remove_column_name: e.target.value }) }}
              />
              <button className="btn btn-default form_button" onClick={this.onRemoveColumn}>Remove Column</button>
            </div>
            { this.data_request() }
          </div>
        </div>
      </div>
    )
  },

  data_request() {
    if (!this.state.user.is_admin) return <div/>
    return (
      <div className="data_request">
        <h4>Request Data</h4>
        <textarea className="form-control" value={this.state.data_request} onChange={this.onRequestDataChange}/>
        <button className="btn btn-primary form_button" onClick={this.onRequestData}>Send Message</button>
        { this.sent_data_requests() }
      </div>
    );
  },

  sent_data_requests() {
    if (!this.state.data_requests || !this.state.data_requests.length) return <ul/>
    return (
      <div>
        <h4>Sent Messages</h4>
        <ul>
          { this.state.data_requests.map(this.sent_data_request) }
        </ul>
      </div>
    );
  },

  sent_data_request(data_request, i) {
    return (
      <li key={`data-request-${i}`} className="data_request_item">
        <p className="data_request_message">
          {
            data_request.message.split(/\r\n|\r|\n/g).map((line, j) => (
              <span key={ `dr-line-${i}-${j}` }>{line}<br/></span>
            ))
          }
        </p>
        <i className={ `fa ${data_request.fulfilled ? 'fa-check-circle' : 'fa-question-circle'}`}/>
        <p className="data_request_updated_at">{ data_request.updated_at || 'just now' }</p>
      </li>
    );
  }
})

export default UnitImporter;
