import React from 'react';
import PropTypes from 'prop-types';
import abbreviate from "number-abbreviate";
import { formatMoney } from 'accounting';

class ExpandedTargetSpend extends React.Component {
  render() {
    const { spend_goal, spend_goal_message, spend_goal_required, subtotal, percentage, onToggle } = this.props
    const remaining = Math.max(0, spend_goal - subtotal)

    return (
      <div className={`target-spend ${spend_goal_required ? 'required' : ''}`}>
        <div className="target-spend-shadow" />
        <div className="target-spend-container">
          <div className="target-spend-header">
            <div className="target-spend-title">
              <i className="fa fa-bullseye-arrow" />
              {spend_goal_required && "Required "}
              Target Budget
            </div>
            <button className="target-spend-toggle" onClick={onToggle}>
              <i className="fal fa-chevron-down" />
            </button>
          </div>
          {spend_goal_message && <div className="target-spend-message">{spend_goal_message}</div>}
          <div className="target-spend-totals">
            <div>
              <i className="fa fa-heart" />
              <strong>
                {formatMoney(subtotal)}
                {" "}({percentage})
              </strong>
            </div>
            <div>
              {formatMoney(remaining)}
              {" to "}
              <span title={formatMoney(spend_goal)}>
                ${abbreviate(spend_goal, 1).toUpperCase()}
              </span>
              {" goal"}
            </div>
          </div>
          <progress value={subtotal} max={spend_goal} />
        </div>
      </div>
    );
  }
}

ExpandedTargetSpend.propTypes = {
  spend_goal: PropTypes.number.isRequired,
  spend_goal_required: PropTypes.bool.isRequired,
  spend_goal_message: PropTypes.string,
  subtotal: PropTypes.number.isRequired,
  percentage: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default ExpandedTargetSpend
