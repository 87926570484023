import { LOAD_MEDIA_SUBTYPES } from "../actions/media_type_actions";
import { TMediaSubtype } from "../models/media_type";

interface Action {type: string; payload: any; }

export default(state: TMediaSubtype[], action: Action): TMediaSubtype[] => {
  switch (action.type) {
    case LOAD_MEDIA_SUBTYPES: return action.payload.media_subtypes;
    default: return state || [];
  }
};
