import React from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import { isMobile } from '../../../utils/mobile';
import { loadMediaTypes } from "../../../actions/media_type_actions";
const POPUP_WIDTH = 220;

class MediaType extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.onClear = this.onClear.bind(this);
    const filters = this.props.filters || {};
    this.state = {
      isExpanded: false,
      selectedMediaTypes: []
    };
  }

  componentDidMount() {
    const { loadMediaTypes } = this.props;
    document.addEventListener("click", this.onToggle);
    loadMediaTypes();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.type === null) {
      this.setState({ selectedMediaTypes: [] });
    }
    const { media_types } = this.props;
    if (media_types.length === 0 && nextProps.media_types && nextProps.media_types.length > 0) {
      const filters = this.props.filters || {};
      const types = filters.type ? filters.type.split(',') : [];
      const selected = nextProps.media_types.filter((type) => {
        return types.includes(type.value);
      });
      if (selected.length > 0) {
        this.setState({ selectedMediaTypes: selected });
      };
    }
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onMediaTypeSelect(option) {
    const { selectedMediaTypes } = this.state;
    if (selectedMediaTypes.includes(option)) {
      this.setState({ selectedMediaTypes: selectedMediaTypes.filter((mediaType) => mediaType.value !== option.value )}, this.onFilter);
    } else {
      this.setState({ selectedMediaTypes: selectedMediaTypes.concat([option])}, this.onFilter);
    }
  }

  onFilter() {
    const { selectedMediaTypes } = this.state;
    const { setFilter } = this.props;
    setFilter({ 'type': selectedMediaTypes.map(type => type.value) });
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedMediaTypes } = this.state;
    if (!selectedMediaTypes.length) return 'Media type';
    if (selectedMediaTypes.length == 1) return `${selectedMediaTypes[0].name} only`;
    if (selectedMediaTypes.length > 1) return `Media Types · ${selectedMediaTypes.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const { selectedMediaTypes } = this.state;
    const { media_types } = this.props;
    const [present, notPresent] = media_types.reduce((result, mt) => {
      result[mt.count > 0 ? 0 : 1].push(mt);
      return result;
    }, [[], []]);
    return (
      <div className="filter_popup media_type_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>MEDIA TYPE</h4>
        <ul>
          {present.map((option) => {
            const onClick = () => {
              this.onMediaTypeSelect(option);
            }
            const isActive = selectedMediaTypes.includes(option);
            return <li
              key={option.value}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {option.name}
                <i className="count">({option.count})</i>
                <i className="fa fa-check" />
            </li>;
          })}
          {notPresent.map((option) => {
            const onClick = () => {
              this.onMediaTypeSelect(option);
            }
            const isActive = selectedMediaTypes.includes(option);
            return <li
              key={option.value}
              onClick={onClick}
              className={cs({active: isActive})}
              >
                {option.name}
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a className="clear hidden-xs" onClick={this.onClear}>Clear</a>
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    )
  }

  onClear() {
    this.setState({
      selectedMediaTypes: [],
      isExpanded: false
    }, this.onFilter);
  }

  render() {
    const { isExpanded, selectedMediaTypes } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();

    return (
      <li ref={node => this.node = node} className={cs({active: selectedMediaTypes.length > 0 || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};

const mapStateToProps = ({ media_types }) => ({ media_types });

export default connect(
  mapStateToProps,
  { loadMediaTypes }
 )(MediaType)
