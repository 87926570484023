import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import geojson from "geojson";
import * as lodash from "lodash";
import mapboxgl, { GeoJSONSource, GeoJSONSourceRaw } from "mapbox-gl";
import { RulerControl } from "mapbox-gl-controls";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import * as _ from "lodash";
import Spiderifier from 'mapboxgl-spiderifier';

import MapActions from "../../actions/MapActions";
import { colorForUnit } from "../../models/Unit";
import { mapUnits } from "../../models/Units";
import NewMapStore, { directions, unitTypes } from "../../stores/NewMapStore";
import PlacesStore from "../../stores/PlacesStore";
import { unitAvailabilityMapping, unitTypeIconMapping } from "../Map/functions/iconFuncs";
import CustomPointOfInterestControl from "./CustomPointOfInterestControl";
import CustomPointOfInterestMode from "./CustomPointOfInterestMode";
import MapLoadListener from "./MapLoadListener";
import Spider from "./Spider";
import SquareControl from "./SquareControl";
import UnitPopups from "./UnitPopups";
import ZipCodePopups from "./ZipCodePopups";
import { PackageChildrenSpider } from "./PackageChildrenSpider";
import { colors } from "./avail_colors";

const DEBUG = (window as any).DEBUG;
const AppConfig = (window as any).AppConfig;
const accessToken = AppConfig.mapboxAccessToken;

export type DataLayer = {
  areas: geojson.FeatureCollection | null;
  lines: geojson.FeatureCollection | null;
};
export default class Mapbox {
  mapLoadListener: MapLoadListener;
  _props: any;
  _units: any[];
  _selected_tags_for_filter: any;
  _placemarkers: any;
  _zip_codes: DataLayer;
  _campaign_area: DataLayer;
  container: string;
  loadUnitTypeIcons: boolean;
  loadUnitDirectionIcons: boolean;
  shouldFitUnits: boolean;
  tinyPins: boolean;
  campaign_permissions: any;
  streetStyle: string;
  trafficStyle: string;
  styles: string[];
  cluster: boolean;
  clusterRadius: number;
  miniClusterRadius: number;
  clusterMaxZoom: number;
  miniClusterMaxZoom: number;
  placemarkerClusterMaxZoom: number;
  maxZoom: number;
  padding: number;
  showUnitOrientation: boolean;
  loaded: boolean;
  showDesignAssets: boolean;
  showPrice: boolean;
  showFaceId: boolean;
  showUnitGeojson: boolean;
  designAssetPopups: any[];
  currentZoom: number;
  map: mapboxgl.Map;
  mapDrawer: any;
  unitPopups: any;
  zipCodePopups: any;
  spider: any;
  packageChildrenSpider: any;
  hasUnitLayers: boolean;
  shouldUpdateUnits: boolean;
  shouldUpdatePlacemarkers: boolean;
  shouldUpdateZipCodes: boolean;
  hasPlacemarkerLayers: boolean;
  activeGeojsons: any[];
  shouldUpdateCampaignArea: boolean;
  data_layer: any;
  geojsons: any[];
  geojsonLoaded: true;
  _firstLabelID: any;
  _firstRoadID: any;
  unitsUpdateCallbacks: Array<(MapBox) => void>;
  toggleCustomPOIFormModal: any;
  updateCurrentCustomPOI?: any;
  debounceUpdate: any;
  audienceUnits: any;
  audienceEnabled: boolean;
  childrenCoords: any;
  markers: any;
  markersOnScreen: any;

  constructor(container, props) {
    mapboxgl.accessToken = accessToken;
    const { hidePrices, campaign, isCampaignView, user } = props;

    this.props = props;
    this.container = container;
    this.loadUnitTypeIcons = props.rich_pins;
    this.loadUnitDirectionIcons = lodash.get(props, "showUnitOrientation", true);
    this.shouldFitUnits = true;
    this.tinyPins = !props.rich_pins;
    this.campaign_permissions = props.campaign_permissions || {};
    this.toggleCustomPOIFormModal = props.toggleCustomPOIFormModal;
    this.updateCurrentCustomPOI = props.updateCurrentCustomPOI?.bind(this);

    // this.streetStyle = 'mapbox://styles/fahimf/ciy3a2bo8007d2srjbkjw9e8y';
    this.streetStyle = AppConfig.mapboxDefaultStyle;
    this.trafficStyle = "mapbox://styles/fahimf/cizq3knvy004t2sp6tvkpbuk1";
    this.styles = [this.streetStyle, this.trafficStyle];

    this.cluster = lodash.get(props, "cluster", true);
    this.clusterRadius = 15;
    this.miniClusterRadius = 1;
    this.clusterMaxZoom = 17;
    this.miniClusterMaxZoom = 17;
    this.placemarkerClusterMaxZoom = 1;
    this.maxZoom = 16.8;
    this.padding = props.padding || 100;

    this.loaded = false;
    this.showDesignAssets = false;
    this.showPrice = !hidePrices;
    this.showFaceId = false;
    this.showUnitGeojson = false;
    this.designAssetPopups = [];

    this.childrenCoords = null;

    this.currentZoom = 8;

    this.map = this.createMap();
    (window as any).map = this.map;
    this.markers = {};
    this.markersOnScreen = {};
    this.mapDrawer = this.createDrawControls();

    this.unitPopups = new UnitPopups(this.map, user, this.campaign_permissions, hidePrices, isCampaignView, campaign);
    this.zipCodePopups = new ZipCodePopups(this.map);

    this.addNavigation();
    this.spider = new Spider(
      this.map,
      this.props.showAvailability,
      this.unitPopups,
      this.props.onUnitClick,
      this.toggleUnitHighlight.bind(this),
    );
    this.hasUnitLayers = false;
    this.activeGeojsons = [];
    this.unitsUpdateCallbacks = [];
    this.addTooltip = this.addTooltip.bind(this);
    this.togglePOILayer = this.togglePOILayer.bind(this);
  }

  createMap() {
    const map = new mapboxgl.Map({
      container: this.container,
      style: this.style(),
      zoom: this.currentZoom,
      maxZoom: this.maxZoom,
      minZoom: this.props.minZoom || 0,
      preserveDrawingBuffer: true,
    });
    map.dragRotate.disable();
    map.fitBounds(this.props.bounds, { duration: 0, padding: this.padding });
    this.mapLoadListener = new MapLoadListener(map);
    return map;
  }

  updateMarkers() {
    const newMarkers = {};
    const features = this.map.querySourceFeatures('units');

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {
      const coords = feature.geometry.coordinates;
      const props = feature.properties;
      if (!props) continue;
      if (!props.cluster) continue;
      const id = `${props.cluster_id}-${props.available}-${props.unavailable}-${props.pending}`;

      let marker = this.markers[id]
      if (!marker) {
        const el = this.createDonutChart(props);
        marker = this.markers[id] = new mapboxgl.Marker({
          element: el
        }).setLngLat(coords);
      }
      newMarkers[id] = marker

      if (!this.markersOnScreen[id]) marker.addTo(this.map);
    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) this.markersOnScreen[id].remove();
    }
    this.markersOnScreen = newMarkers;
  }

  donutSegment(start, end, r, r0, color, stroke = true) {
    let drawStroke
    if (stroke) {
      drawStroke = `stroke="white" stroke-width="2"`
    } else {
      drawStroke = ''
    }
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
      } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
      } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
      }" fill="${color}" ${drawStroke} />`;
  }

  createDonutChart(props: { available: number, unavailable: number, pending: number }) {
    const offsets: number[] = [];
    const counts = [
      props.unavailable,
      props.available,
      props.pending
    ];
    let total = 0;
    for (const count of counts) {
      offsets.push(total);
      total += count;
    }
    // const fontSize =
    //   total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
    const fontSize = 10
    // const r =
    //   total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    // const r = 16
    let r: number
    if (total < 10000 && total > 999) {
      r = 22
    } else if (total < 1000 && total > 99) {
      r = 16
    } else if (total < 100 && total > 9) {
      r = 14
    } else if (total < 10) {
      r = 12
    } else {
      r = 6
    }

    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html: string

    if (total == props.available) {
      // html = this.drawSolidMarker(w, fontSize, r, r0, total, 'available')
      html = this.drawMixedMarker(w, fontSize, r, r0, total, counts, offsets, false)
    }
    else if (total == props.unavailable) {
      // html = this.drawSolidMarker(w, fontSize, r, r0, total, 'unavailable')
      html = this.drawMixedMarker(w, fontSize, r, r0, total, counts, offsets, false)
    }
    else if (total == props.pending) {
      // html = this.drawSolidMarker(w, fontSize, r, r0, total, 'pending')
      html = this.drawMixedMarker(w, fontSize, r, r0, total, counts, offsets, false)
    }
    else {
      html = this.drawMixedMarker(w, fontSize, r, r0, total, counts, offsets, true)
    }

    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
  }

  drawSolidMarker(w: number, fontSize: number, r: number, r0: number, total: number, color: 'available' | 'unavailable' | 'pending') {
    let html = `<div>
    <svg pointer-events="none" width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="${colors[color]}" stroke="white"stroke-width="2" pointer-events="none" />
    <text dominant-baseline="central" transform="translate(${r}, ${r})" fill="white">
    ${total.toLocaleString()}
    </text>
    </svg>
    </div>`;

    return html
  }

  drawMixedMarker(w: number, fontSize: number, r: number, r0: number, total: number, counts: number[], offsets: number[], stroke: boolean) {
    let html = `<div>
    <svg pointer-events="none" width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;
    const markerColors = [colors['unavailable'], colors['available'], colors['pending']];
    for (let i = 0; i < counts.length; i++) {
      html += this.donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        markerColors[i],
        stroke
      );
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" pointer-events="none" />
    <text dominant-baseline="central" transform="translate(${r}, ${r})">
    ${total.toLocaleString()}
    </text>
    </svg>
    </div>`;
    return html
  }

  filterByAudience(audienceUnitIds) {
    this.props.audienceUnits = audienceUnitIds;
    this.updateMap();
  }

  toggleAudience(enabled) {
    this.props.audienceEnabled = enabled;
    this.updateMap();
  }

  addNavigation() {
    this.map.addControl(new mapboxgl.NavigationControl({ showZoom: true, showCompass: false }));
  }

  createDrawControls() {
    const { isCampaignView } = this.props;
    if (!this.props.draw_tool) return;
    const mapDrawer = new MapboxDraw({
      modes: {
        draw_rectangle: DrawRectangle,
        custom_point_of_interest: CustomPointOfInterestMode,
        ...MapboxDraw.modes,
      },
      displayControlsDefault: false,
      controls: { polygon: true },
    });

    this.map.addControl(mapDrawer, "top-left");
    this.map.addControl(new SquareControl(mapDrawer), "top-left");
    isCampaignView && this.addTooltip("square");
    isCampaignView && this.addTooltip("poly");

    if (this.updateCurrentCustomPOI) {
      this.map.addControl(new CustomPointOfInterestControl(mapDrawer), "top-left");
      isCampaignView && this.addTooltip("poi");
    }

    this.map.addControl(
      new RulerControl({
        units: "miles",
        labelFormat: n => `${n.toFixed(2)} miles`,
      }),
      "top-left",
    );
    isCampaignView && this.addTooltip("ruler");

    return mapDrawer;
  }

  addTooltip(type) {
    let copy = "";
    let selector = "";
    switch (type) {
      case "square":
        copy =
          "Use the rectangle or polygon draw tool to:  \n1. Add units from browse \n2. Find vendors and RFP in a specific area. \n3. Add/remove units \n4. Recommend/unrecommend/favorite and unfavorite units. \n5. Remove POIs/remove POI logo \n6. Create a custom bounds/highlighted area. \n7. Tag inventory to group together with other units.";
        selector = ".mapbox-ctrl-square-control";
        break;
      case "poi":
        copy = "Create a custom POI";
        selector = ".custom-point-of-interest-control";
        break;
      case "ruler":
        copy = "Measure distances on your map.";
        selector = ".mapboxgl-ctrl-ruler";
        break;
      case "poly":
        copy =
          "Use the rectangle or polygon draw tool to:  \n1. Add units from browse \n2. Find vendors and RFP in a specific area. \n3. Add/remove units \n4. Recommend/unrecommend/favorite and unfavorite units. \n5. Remove POIs/remove POI logo \n6. Create a custom bounds/highlighted area. \n7. Tag inventory to group together with other units.";
        selector = ".mapbox-gl-draw_polygon";
    }
    if (copy != "" && selector != "") {
      const dom_element = document.querySelector(selector);
      if (dom_element) {
        dom_element.setAttribute("title", copy);
      }
    }
  }

  style() {
    this.styles = this.styles.reverse();
    return this.styles[1];
  }

  async fitUnits() {
    if (!this.shouldFitUnits) return;
    DEBUG && console.log("fitting units now");
    this.shouldFitUnits = false;

    const units = this.units;
    let features = [];

    if (units && units.features && units.features.length) {
      features = features.concat(units.features);
    } else if (this.placemarkers && this.placemarkers.features) {
      features = features.concat(this.placemarkers.features);
    }

    this.fitFeatures(features);
  }

  fitFeatures(features) {
    if (!features || !features.length) return;

    const bounds = new mapboxgl.LngLatBounds();

    features.forEach(feature => bounds.extend(feature.geometry.coordinates));

    this.map.fitBounds(bounds, { padding: this.padding, maxZoom: this.maxZoom, duration: 0 });
  }

  toggleTraffic() {
    this.map.on(
      "style.load",
      (() => {
        this.shouldUpdateUnits = true;
        this.shouldUpdatePlacemarkers = true;
        this.shouldUpdateZipCodes = true;
        this.hasUnitLayers = false;
        this.hasPlacemarkerLayers = false;
        this.updateMap();
      }).bind(this),
    );

    this.map.setStyle(this.style());
  }

  async toggleDesignAssets() {
    if (this.showDesignAssets) {
      this.showDesignAssets = false;
      let popup;
      while ((popup = this.designAssetPopups.pop())) {
        popup.remove();
      }
      return;
    }

    this.showDesignAssets = true;

    const assets = NewMapStore.getState().designAssets;
    const geojson = this.units;

    const units = {};
    if (geojson && geojson.features) {
      geojson.features.reduce((acc, unit) => {
        acc[unit.properties.id] = unit;
        return acc;
      }, units);
    }

    for (const asset of assets) {
      const unit = units[asset.id];

      if (unit) {
        const popup = new mapboxgl.Popup({ closeOnClick: false, closeButton: false })
          .setLngLat(unit.geometry.coordinates)
          .setHTML(`<img src="${asset.url}"/>`)
          .addTo(this.map);
        this.designAssetPopups.push(popup);
      }
    }
  }

  async waitMapLoad() {
    await this.mapLoadListener.waitMapLoad();
  }

  init() {
    this.map.on("load", this.onLoad.bind(this));
    return this;
  }

  set units(units) {
    if (units == this._units) return;
    this.shouldUpdateUnits = true;
    this._units = units;
    this.updateMap();
  }

  get units() {
    return mapUnits(this._units);
  }

  set placemarkers(features) {
    this.shouldUpdatePlacemarkers = true;
    this._placemarkers = features;
  }

  get placemarkers() {
    return this._placemarkers;
  }

  set zip_codes(features) {
    this.shouldUpdateZipCodes = true;
    this._zip_codes = features;
  }

  get zip_codes() {
    return this._zip_codes;
  }

  set campaign_area(features) {
    this.shouldUpdateCampaignArea = true;
    this._campaign_area = features;
  }

  get campaign_area() {
    return this._campaign_area;
  }

  set selected_tags_for_filter(tags) {
    if (_.isEqual(tags, this._selected_tags_for_filter)) return;
    this.shouldUpdateUnits = true;
    this._selected_tags_for_filter = tags;
    this.updateMap();
  }

  get selected_tags_for_filter() {
    return this._selected_tags_for_filter;
  }

  onLoad() {
    this.loaded = true;
    MapActions.loaded();
    this.spider.load();
    this.updateMap();
    MapActions.setCenter(this.map.getCenter());
    if (this.props.center) {
      const zoom = this.props.zoom || AppConfig.defaultZoom;
      this.map.flyTo({ center: [this.props.center[1], this.props.center[0]], zoom: zoom });
    }

    this.map.on("moveend", this.updatePosition.bind(this));

    this.debounceUpdate = lodash.debounce(this.updatePosition.bind(this), 500);
    this.map.on("wheel", this.debounceUpdate.bind(this));

    this.map.on("zoomstart", () => {
      this.spider.close();
      if (this.packageChildrenSpider) {
        this.packageChildrenSpider.close()
      }
      this.removeUnitPopup();
    });

    this.map.on("draw.create", this.selectMarkers.bind(this));

    this.map.on("click", this.closeSpider.bind(this));
    this.map.on("click", "add-location-pin", this.onLocationPinClick.bind(this));
    this.map.on(
      "click",
      "unit-markers",
      (e: mapboxgl.MapLayerMouseEvent) => e.features && this.props.onUnitClick(e.features[0].properties),
    );
    this.map.on("click", "placemarker", this.flyToPoint.bind(this));

    // this.map.on('custom_point_of_interest:add', function() { alert("cpoi")});
    ["cluster-markers", "placemarker-cluster"].forEach(point =>
      this.map.on("click", point, this.flyToCluster.bind(this)),
    );

    ["unit-markers"].forEach(point => this.map.on("mouseenter", point, this.showUnitPopup.bind(this)));
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });


    ["unit-markers"].forEach(point =>
      this.map.on("mouseleave", point, () => {
        if (this.map.getLayer("highlighted-children")) this.map.removeLayer("highlighted-children");
      }),
    );
    [
      "cluster-markers",
      "add-location-pin",
      "placemarker-cluster",
      "placemarker",
      "zipcodes-areas-layer",
      "data-layer-areas-layer",
    ].forEach(point => this.map.on("mouseenter", point, this.cursorPointer.bind(this)));

    ["unit-markers"].forEach(point => this.map.on("mouseleave", point, this.removeUnitPopup.bind(this)));
    [
      "cluster-markers",
      "add-location-pin",
      "placemarker-cluster",
      "placemarker",
      "zipcodes-areas-layer",
      "data-layer-areas-layer",
    ].forEach(point => this.map.on("mouseleave", point, this.defaultCursor.bind(this)));

    this.map.on("click", "data-layer-areas-layer", this.toggleDataLayerPopup.bind(this));
    this.map.on("custom_point_of_interest:add", this.addCustomPointOfInterest.bind(this));
  }

  togglePrice() {
    this.showPrice = !this.showPrice;
    this.updateUnitsPriceLayer(this.unitsSource().id);
  }

  toggleFaceId() {
    this.showFaceId = !this.showFaceId;
    this.updateUnitsFaceIdLayer(this.unitsSource().id);
    this.updateUnitsPriceLayer(this.unitsSource().id);
  }

  closeSpider(e) {
    const clickOnCluster =
      this.map.getLayer("cluster-markers") &&
      this.map.queryRenderedFeatures(e.point, { layers: ["cluster-markers"] }).length > 0;
    const clickOnClusterUnit =
      e.originalEvent &&
      e.originalEvent.target &&
      e.originalEvent.target.className &&
      e.originalEvent.target.className.indexOf("default-spider-pin") >= 0;
    if (!clickOnCluster && !clickOnClusterUnit) {
      this.spider.close();
      if (this.packageChildrenSpider) {
        this.packageChildrenSpider.close()
      }
    }
  }

  flyToPoint(e) {
    const increment = this.map.getZoom() < 14 ? 3.5 : 1;
    console.log("zoom:", this.map.getZoom(), "->", this.map.getZoom() + increment);
    this.map.flyTo({ center: e.lngLat, zoom: this.map.getZoom() + increment });
  }

  async flyToCluster(e) {
    const cluster = e.features[0].properties;
    const zoom = this.map.getZoom();
    if (
      (zoom > 13.6 && cluster.point_count <= 10) ||
      (zoom > 12 && cluster.point_count <= 5) ||
      cluster.point_count <= 3 ||
      zoom > 15.5
    ) {
      const units = await this.findLeaves(cluster);
      this.spider.open(
        e.lngLat,
        units.map(f => f.properties),
      );
    } else {
      this.spider.close();
      if (this.packageChildrenSpider) {
        this.packageChildrenSpider.close()
      }
      this.flyToPoint(e);
    }
  }

  async findLeaves(cluster): Promise<any> {
    const source = this.map.getSource(this.unitsSource().id);
    return new Promise((resolve, reject) =>
      (source as mapboxgl.GeoJSONSource).getClusterLeaves(cluster.cluster_id, 20, 0, (error, features) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(features);
      }),
    );
  }

  async showUnitPopup(e, use_unit_location = false) {
    this.cursorPointer();
    const unit = await this.unitFromEvent(e);
    const coordinates = use_unit_location ? unit.geometry.coordinates : null;

    if (unit && (this.props.sidebar_popup || this.map.getZoom() > 11)) {
      this.toggleUnitHighlight(unit);
      this.unitPopups.show(unit, coordinates, this.unitsSource().id);
    }
  }
  showClusterPopup(e, popup) {
    this.map.getCanvas().style.cursor = 'pointer';

    // Copy coordinates array.
    const coordinates = e.features[0].geometry.coordinates.slice();
    const props = e.features[0].properties;
    const htmlTemplate = `
      <h3> <strong>${props.available + props.unavailable + props.pending} units </strong></h3>
      <ul class='mapbox-avails-popup'>
        <li class='custom-bullet available'> ${props.available} Available
        <li class='custom-bullet pending'> ${props.pending} Pending
        <li class='custom-bullet unavailable'> ${props.unavailable} Unavailable
      </ul>
    `
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    // Populate the popup and set its coordinates
    // based on the feature found.
    popup.setLngLat(coordinates).setHTML(htmlTemplate).addTo(this.map);
  }

  async removeUnitPopup() {
    this.defaultCursor();
    this.unitPopups.hide();
    this.toggleUnitHighlight(null);
  }

  async unitFromEvent(e) {
    if (e.features) {
      return e.features[0];
    } else {
      const units = this.units;
      if (!units || !units.features) return;

      return (
        units.features.find(unit => unit.properties.id === e) ||
        units.features.find(unit => unit.properties.package_id === e)
      );
    }
  }

  async toggleUnitHighlight(unit) {
    if (this.map.getLayer("highlighted-unit-markers")) this.map.removeLayer("highlighted-unit-markers");
    if (this.map.getLayer("highlighted-cluster-markers")) this.map.removeLayer("highlighted-cluster-markers");

    if (!unit) return;

    if (this.map.getLayer("unit-markers")) {
      const highlighted_units = this.map.queryRenderedFeatures(undefined, { layers: ["unit-markers"] }).filter(u => {
        if (!u.properties) return false;
        return unit.properties.id === u.properties.id;
      });

      if (highlighted_units.length) {
        if (this.map.getLayer("highlighted-unit-markers")) this.map.removeLayer("highlighted-unit-markers");
        if (unit.properties.is_package) this.highlightChildrenUnits(unit.properties._id);
        this.setSource("highlighted-units", { type: "FeatureCollection", features: highlighted_units });
        this.map.addLayer(
          {
            id: "highlighted-unit-markers",
            type: "circle",
            source: "highlighted-units",
            paint: {
              "circle-color": { type: "identity", property: "color" },
              "circle-opacity": this.tinyPins ? 0.8 : 0,
              "circle-stroke-width": 3,
              "circle-stroke-color": "#FEC700",
              "circle-radius": this.tinyPins ? 6 : 12,
            },
          },
          this.map.getLayer("unit-prices") ? "unit-prices" : undefined,
        );
      }
    }

    if (this.map.getLayer("cluster-markers")) {
      const cluster_filter = this.map
        .queryRenderedFeatures(undefined, { layers: ["cluster-markers"] })
        .map(async cluster => {
          const leaves = await this.findLeaves(cluster.properties);
          if (leaves.find(u => unit.properties.id === u.properties.id)) {
            return cluster;
          }
        });

      const highlighted_clusters = (await Promise.all(cluster_filter)).filter(c => c);

      if (!highlighted_clusters.length) return;

      if (this.map.getLayer("highlighted-cluster-markers")) this.map.removeLayer("highlighted-cluster-markers");
      this.setSource("highlighted-clusters", { type: "FeatureCollection", features: highlighted_clusters });
      this.map.addLayer(
        {
          id: `highlighted-cluster-markers`,
          type: "circle",
          source: "highlighted-clusters",
          paint: {
            "circle-color": "#FFF",
            "circle-opacity": 0,
            "circle-stroke-width": 3,
            "circle-stroke-color": "#FEC700",
            "circle-radius": [
              "step",
              ["get", "point_count"],
              this.tinyPins ? 8 : 12,
              10,
              this.tinyPins ? 10 : 14,
              100,
              this.tinyPins ? 12 : 16,
            ],
          },
        },
        "unit-markers",
      );
    }
  }

  toggleDataLayerPopup(e) {
    const feature = this.data_layer.areas.features.find(z => z.properties.id === e.features[0].properties.id);
    this.zipCodePopups.toggle(feature);
  }

  addCustomPointOfInterest(event) {
    if (this.updateCurrentCustomPOI) {
      this.updateCurrentCustomPOI(event.lngLat);
    }
    if (this.toggleCustomPOIFormModal) {
      this.toggleCustomPOIFormModal();
    }
  }

  removeZipCodePopup(e) {
    this.defaultCursor();
    this.zipCodePopups.remove();
  }

  async selectMarkers(draw) {
    const coordinates = draw.features[0].geometry.coordinates[0].map(point => point.map(c => c));
    await this.props.filterUnits({ draw: coordinates });
    this.props.selectCoordinates && this.props.selectCoordinates(_.flatten(coordinates));

    this.props.toggleSelectedPolygons(draw.features);

    const selected_bounds = [
      _.sortBy(coordinates, v => v[1])[0][1],
      _.sortBy(coordinates, v => v[0])[0][0],
      _.sortBy(coordinates, v => -1 * v[1])[0][1],
      _.sortBy(coordinates, v => -1 * v[0])[0][0],
    ];

    this.props.toggleSelectedMarkers(selected_bounds);
    this.mapDrawer.delete(draw.features[0].id);
  }

  cursorPointer() {
    this.map.getCanvas().style.cursor = "pointer";
  }

  defaultCursor() {
    this.map.getCanvas().style.cursor = "";
  }

  unitsSource() {
    let clusterRadius: number
    if (this.campaign_permissions.show_dynamic_markers) {
      clusterRadius = 80
    } else {
      clusterRadius = this.clusterRadius
    }

    if (this.props.cluster) {
      return {
        id: "units",
        clusterMaxZoom: this.clusterMaxZoom,
        clusterRadius: clusterRadius,
      };
    } else {
      return {
        id: "units-mini",
        clusterMaxZoom: this.miniClusterMaxZoom,
        clusterRadius: this.miniClusterRadius,
      };
    }
  }

  set props(props) {
    const previousProps = this._props || {};
    this._props = {
      ...previousProps,
      ...props,
    };

    this.componentDidUpdate(previousProps);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.map_resize != this.props.map_resize) {
      this.map.resize();
    }

    if (previousProps.cluster != this.props.cluster) {
      this.shouldUpdateUnits = true;
      this.updateMap();
    }

    if (previousProps.show_placemarkers != this.props.show_placemarkers) {
      this.togglePOILayer(this.props.show_placemarkers);
    }

    if (previousProps.traffic !== undefined && previousProps.traffic != this.props.traffic) {
      this.toggleTraffic();
    }

    if (previousProps.selected_markers === true && previousProps.selected_markers !== this.props.selected_markers) {
      this.props.filterUnits({ draw: null });
    }

    if (previousProps.bounds != this.props.bounds && this.props.bounds && this.loaded) {
      this.map.fitBounds(this.props.bounds, { duration: 0 });
    }

    if (previousProps.position != this.props.position && this.loaded) {
      this.map.jumpTo(this.props.position);
    }

    if (previousProps.unit_popup != this.props.unit_popup) {
      this.removeUnitPopup();
      const { unit_id } = this.props.unit_popup;
      if (unit_id) this.showUnitPopup(unit_id);
    }

    if (previousProps.refit_units != this.props.refit_units) {
      this.shouldFitUnits = true;
    }
  }

  get props() {
    return this._props;
  }

  endUnitLoading() {
    const action = this.props.endUnitLoading;
    if (!action) return;

    function callback(e) {
      if (e.target && e.target.loaded()) {
        action();
        e.target.off("render", callback);
      }
    }

    this.map.on("render", callback);
  }

  onUnitsUpdate(callback: (Mapbox) => void) {
    this.unitsUpdateCallbacks.push(callback);
  }

  triggerUnitsUpdate() {
    this.unitsUpdateCallbacks.forEach(c => c(this));
  }

  togglePOILayer(showLayer) {
    if (!this.loaded) return;
    if (!this.map.getLayer('placemarker')) return;

    if (showLayer) {
      return this.map.setLayoutProperty('placemarker', 'visibility', 'visible')
    } else {
      return this.map.setLayoutProperty('placemarker', 'visibility', 'none')
    }
  }
  async updateMap() {
    if (!this.loaded) return;

    if (this.zip_codes && this.shouldUpdateZipCodes) {
      this.shouldUpdateZipCodes = false;
      this.updateDataLayer();
    }
    if (this.campaign_area && this.campaign_area.areas && this.shouldUpdateCampaignArea) {
      this.shouldUpdateCampaignArea = false;
      this.updateDataLayer();
    }

    if (this.units && this.shouldUpdateUnits) {
      let units = this.units;

      if (this.props.audienceEnabled) {
        // filtering units by audience
        units = {
          ...units,
          features: this.units.features.filter(f => this.props.audienceUnits.includes(f.properties._id)),
        };
      }

      this.fitUnits();

      if (this.loadUnitTypeIcons) {
        await Promise.all(unitTypes.map(type => this.addImage(type)));
      }

      if (this.loadUnitDirectionIcons) {
        await Promise.all(Object.keys(directions).map(key => this.addImage(directions[key])));
      }

      if (units && units.features && !units.features.length) {
        this.props.endUnitLoading();
      }
      const unitsSource = this.unitsSource();
      const available = ['==', ['get', 'supplier_status'], 'available'];
      const unavailable = ['==', ['get', 'supplier_status'], 'unavailable'];
      const pending = ['==', ['get', 'supplier_status'], 'pending'];
      const clusterProps = {
        available: ['+', ['case', available, 1, 0]],
        unavailable: ['+', ['case', unavailable, 1, 0]],
        pending: ['+', ['case', pending, 1, 0]],
      }
      if (units && units.features && units.features.length) {
        this.setSource(unitsSource.id, units, unitsSource.clusterMaxZoom, unitsSource.clusterRadius, clusterProps);
        this.removeUnitLayers();
        this.addUnitLayers(unitsSource.id);
        this.endUnitLoading();
        this.updateGeojson();
      } else {
        this.removeUnitLayers();
        this.setSource(unitsSource.id, units, unitsSource.clusterMaxZoom, unitsSource.clusterRadius, clusterProps);
      }
      this.triggerUnitsUpdate();
      this.shouldUpdateUnits = false;
    }

    if (this.placemarkers && this.shouldUpdatePlacemarkers) {
      this.shouldUpdatePlacemarkers = false;
      if (this.placemarkers.features && this.placemarkers.features.length) {
        const icons = Array.from(new Set(this.placemarkers.features.map(f => f.properties.icon))).map(
          async icon => await this.addImage(icon),
        );
        await Promise.all(icons);

        this.setSource("placemarkers", this.placemarkers, this.placemarkerClusterMaxZoom, this.miniClusterRadius);
        this.addPlacemarkerLayers();
        this.fitUnits();
      } else {
        this.removePlacemarkerLayer();
      }
    }
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    if (this.campaign_permissions.show_dynamic_markers) {
      this.map.on('render', () => {
        if (this.map.getSource('units') && this.map.isSourceLoaded('units')) {

          this.updateMarkers()
        };
        if (this.map.getLayer('cluster-markers')) {
          this.map.setPaintProperty('cluster-markers', 'circle-opacity', 0)
          this.map.setPaintProperty('cluster-markers', 'circle-stroke-opacity', 0)
        }
        if (this.map.getLayer('cluster-count')) {
          this.map.setPaintProperty('cluster-count', 'text-opacity', 0)
        }
      });

      this.map.on('mouseenter', 'cluster-markers', (e) => { this.showClusterPopup(e, popup) })
    }

    this.map.on('mouseleave', 'cluster-markers', (e) => {
      this.map.getCanvas().style.cursor = '';
      popup.remove()
     })
  }

  async updateGeojson() {
    if (!this.geojsons) return;

    if (!this.geojsonLoaded && this.geojsons.length > 0) {
      DEBUG && console.log("Loading geojsons", this.geojsons);
      this.geojsons.forEach(({ id, geojson }) => this.setSource(`geojson-unit-${id}`, geojson));
      this.geojsonLoaded = true;
    }

    const units = this.units;
    const geojsons = NewMapStore.state.show_transit_layer
      ? this.geojsons.filter(({ id, geojson }) => geojson && units.features.find(u => u.properties._id === id))
      : [];

    const activeGeojsons = geojsons.map(g => g.id).sort();
    if (_.isEqual(this.activeGeojsons, activeGeojsons)) return;

    const toRender = _.difference(activeGeojsons, this.activeGeojsons);
    const toRemove = _.difference(this.activeGeojsons, activeGeojsons);

    DEBUG && console.log("removing geojsons", { toRemove });
    toRemove.forEach(id => {
      if (this.map.getLayer(`geojson-line-${id}`)) this.map.removeLayer(`geojson-line-${id}`);
    });

    if (!this.showUnitGeojson) return; // return before add layer

    DEBUG && console.log("rendering geojsons", { toRender });
    geojsons.forEach(g => {
      if (!toRender.find(r => r === g.id)) return;

      this.map.addLayer(
        {
          id: `geojson-line-${g.id}`,
          type: "line",
          source: `geojson-unit-${g.id}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#8281ab",
            "line-width": 2,
          },
        },
        this.firstLabelID,
      );
    });

    this.activeGeojsons = activeGeojsons;
  }

  removeDataLayers() {
    if (this.map.getLayer("data-layer-lines-layer")) this.map.removeLayer("data-layer-lines-layer");
    if (this.map.getLayer("data-layer-areas-layer")) this.map.removeLayer("data-layer-areas-layer");
  }

  updateDataLayer(data_layer: DataLayer | null = null) {
    DEBUG && console.log("updateDataLayer", { data_layer }, { zip_codes: this.zip_codes });
    this.removeDataLayers();

    if (!data_layer) {
      if (this.campaign_area && this.campaign_area.areas) {
        this.campaign_area.areas.features.forEach(
          feature =>
            (feature.properties = {
              color: "black",
              opacity: 0.3,
            }),
        );
        data_layer = this.campaign_area;
      } else if (this.zip_codes) {
        data_layer = this.zip_codes;
      }
    }

    if (!data_layer) return;

    this.data_layer = data_layer;

    const { areas, lines } = data_layer;
    if (areas && areas.features && areas.features.length) {
      this.setSource("data-layer-areas", areas);

      this.map.addLayer(
        {
          id: "data-layer-areas-layer",
          type: "fill",
          source: "data-layer-areas",
          layout: {},
          paint: {
            "fill-color": { type: "identity", property: "color" },
            "fill-opacity": { type: "identity", property: "opacity" },
          },
        },
        this.firstRoadID,
      );
    }

    if (lines && lines.features && lines.features.length) {
      this.setSource("data-layer-lines", lines);

      this.map.addLayer({
        id: "data-layer-lines-layer",
        type: "line",
        source: "data-layer-lines",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#777",
          "line-width": 1,
        },
      });
    }
  }

  async addImage(image) {
    let url;
    if (image.startsWith("http")) {
      // POIs images come from database as URLs
      url = image;
    } else {
      // Unit types and availability images are mappings
      url = unitTypeIconMapping[image] || unitAvailabilityMapping[image];
    }

    if (!url) {
      DEBUG && console.warn(`missing url for icon ${image}`, this.placemarkers);
      return;
    }

    if (this.map.hasImage(image)) return;

    return new Promise(resolve => {
      const img = new (window as any).Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        if (!this.map.hasImage(image)) {
          this.map.addImage(image, img);
        }
        DEBUG && console.log(`loaded image ${image}`);
        resolve(img);
      };
      img.onerror = err => {
        console.error("error loading image to map", url, image, err);
        resolve();
      };
      img.src = url;
    });
  }

  setSource(source, data, clusterMaxZoom: number | null = null, clusterRadius: number | null = null, clusterProperties: any = {}) {
    if (!data) {
      DEBUG && console.log("data is empty, skipping...", source);
      return;
    }

    DEBUG && console.log("set source to map", source, data, clusterMaxZoom, clusterRadius, clusterProperties);
    const mapSource = this.map.getSource(source);

    if (mapSource) {
      this.updateSource(source, data);
      return;
    }

    if (data && data.features.length) {
      DEBUG && console.log("add source", source, data, clusterMaxZoom, clusterRadius, clusterProperties);
      this.addSource(source, data, clusterMaxZoom, clusterRadius, clusterProperties);
    }
  }

  updateSource(source, data) {
    if (data && data.features.length) {
      DEBUG && console.log("update source", source, data);
      (this.map.getSource(source) as mapboxgl.GeoJSONSource).setData(data as string);
    } else {
      DEBUG && console.log("remove source", source, data);
      this.map.removeSource(source);
    }
  }

  addSource(source, data, clusterMaxZoom, clusterRadius, clusterProperties) {
    let geojson: any;

    if (clusterMaxZoom) {
      geojson = {
        type: "geojson",
        data: data,
        buffer: 1,
        cluster: true,
        clusterMaxZoom: clusterMaxZoom,
        clusterRadius: clusterRadius || 15,
        clusterProperties: clusterProperties || {}
      };
    } else {
      geojson = {
        type: "geojson",
        data: data,
        buffer: 1,
      };
    }

    this.map.addSource(source, geojson);
  }

  onLocationPinClick(e) {
    PlacesStore.onLocationPin({
      lat: e.lngLat.lat,
      lon: e.lngLat.lng,
      details: JSON.parse(e.features[0].properties.place),
    });
  }

  removeZipCodeLayers() {
    if (this.map.getLayer("zipcodes-areas-layer")) this.map.removeLayer("zipcodes-areas-layer");
    if (this.map.getLayer("zipcodes-lines-layer")) this.map.removeLayer("zipcodes-lines-layer");
  }

  get firstLabelID() {
    if (!this._firstLabelID) {
      this._firstLabelID = this.findLayerID(
        l => l.type === "symbol" && l["source-layer"] && l["source-layer"].indexOf("_label") > -1,
      );
    }
    return this._firstLabelID;
  }

  get firstRoadID() {
    if (!this._firstRoadID) {
      this._firstRoadID = this.findLayerID(l => l.type === "symbol");
    }
    return this._firstRoadID;
  }

  findLayerID(finder) {
    const layers = this.map.getStyle().layers;
    const symbol = layers && layers.find(finder);

    return symbol && symbol.id;
  }

  addUnitLayers(source) {
    if (this.hasUnitLayers) return;
    DEBUG && console.log("adding unit layers");
    this.hasUnitLayers = true;

    this.addUnitMarkerLayers(source);
    this.addUnitClusterLayers(source);
  }

  addPlacemarkerLayers() {
    if (this.hasPlacemarkerLayers) return;
    DEBUG && console.log("adding placemarker layers");
    this.hasPlacemarkerLayers = true;
    this.addPlacemarkerLayer();
  }

  removeUnitLayers() {
    const layers = [
      "cluster-markers",
      "cluster-shadows",
      "cluster-count",
      "unit-markers",
      "unit-icons",
      "unit-prices",
      "unit-direction-north",
      "unit-direction-south",
      "unit-direction-east",
      "unit-direction-west",
      "unit-direction-northeast",
      "unit-direction-northwest",
      "unit-direction-southeast",
      "unit-direction-southwest",
      "units-recommended-layer",
      "units-available-layer",
      "units-requested-layer",
      "units-cart-layer",
      "units-favorited-layer",
    ];

    layers.forEach(layer => this.map.getLayer(layer) && this.map.removeLayer(layer));
    this.hasUnitLayers = false;
  }

  addUnitClusterLayers(source) {
    if (this.map.getLayer("cluster-markers")) return;

    this.map.addLayer(
      {
        id: "cluster-markers",
        type: "circle",
        source: source,
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#911884",
          "circle-opacity": 0.8,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#FFFFFF",
          "circle-radius": [
            "step",
            ["get", "point_count"],
            this.tinyPins ? 8 : 12,
            10,
            this.tinyPins ? 10 : 14,
            100,
            this.tinyPins ? 12 : 16,
          ],
        },
      },
      "unit-markers",
    );

    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: source,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["Inter Bold"],
        "text-size": 11,
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": "#FFFFFF",
      },
    });
  }

  addUnitMarkerLayers(source) {
    // here
    if (this.map.getLayer("unit-markers")) return;

    const tags = this.selected_tags_for_filter;
    let circleColor
    if (this.campaign_permissions.show_dynamic_markers) {
      circleColor = [
        'match',
        ['get', 'supplier_status'],
        'available',
        '#44AC6B',
        'unavailable',
        '#858585',
        'pending',
        '#911884',
        /* fallback */ '#911884'
        ]
    } else {
        // If no Tag is filtered, use unit color.
        // If only one Tag is filtered, use the tag color.
        // If more than one Tag is filtered, use the unit tagColor (it's grey when unit has multiple tags)
        // P.S. I'm sorry for the following line :(
      circleColor = _.isEmpty(tags) ? ["get", "color"] : tags.length > 1 ? ["get", "tagColor"] : tags[0].color
    }
    this.map.addLayer({
      id: "unit-markers",
      type: "circle",
      source: source,
      filter: ["!has", "point_count"],
      paint: {
        'circle-color': circleColor,
        "circle-opacity": 0.95,
        "circle-radius": this.tinyPins ? 4 : 12,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#FFFFFF",
      },
    });

    this.addUnitDirections(source);

    if (!this.tinyPins) {
      this.map.addLayer({
        id: "unit-icons",
        type: "symbol",
        source: source,
        filter: ["!has", "point_count"],
        layout: {
          "icon-image": "{unit_type}",
          "icon-size": 0.5,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
        },
      });

      if (this.campaign_permissions.package_children_enabled) {
        this.addChildPackagesLayer();
      }
      this.updateUnitsPriceLayer(source);
      this.updateUnitsFaceIdLayer(source);
    }
  }

  addUnitDirections(source) {
    [
      { name: "north", code: "N", offset: [1, -30] },
      { name: "south", code: "S", offset: [1, 30] },
      { name: "east", code: "E", offset: [30, 1] },
      { name: "west", code: "W", offset: [-30, 1] },
      { name: "northeast", code: "NE", offset: [23, -23] },
      { name: "northwest", code: "NW", offset: [-23, -23] },
      { name: "southeast", code: "SE", offset: [23, 23] },
      { name: "southwest", code: "SW", offset: [-23, 23] },
    ].forEach(direction =>
      this.map.addLayer({
        id: `unit-direction-${direction.name}`,
        type: "symbol",
        source: source,
        filter: ["==", ["get", "direction"], direction.code],
        layout: {
          "icon-offset": direction.offset,
          "icon-image": direction.name,
          "icon-size": this.tinyPins ? 0.3 : 0.5,
          "icon-rotation-alignment": "map",
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
        },
      }),
    );
  }

  updateUnitsPriceLayer(source) {
    if (this.map.getLayer("unit-prices")) this.map.removeLayer("unit-prices");
    if (!this.showPrice || this.showFaceId) return;

    this.map.addLayer({
      id: "unit-prices",
      type: "symbol",
      source: source,
      filter: ["all", ["!", ["has", "point_count"]], [">", ["to-number", ["get", "label"]], 0]],
      layout: {
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "text-field": [
          "concat",
          ["case", ["to-boolean", ["get", "is_package"]], "PKG - ", ""],
          "$",
          [
            "case",
            [">=", ["to-number", ["get", "label"]], 1000],
            ["concat", ["to-string", ["round", ["/", ["to-number", ["get", "label"]], 1000]]], "K"],
            ["to-string", ["get", "label"]],
          ],
        ],
        "text-font": ["Inter Semi Bold"],
        "text-size": 10,
        "text-justify": "center",
        "text-offset": [0.0, 1.1],
        "text-anchor": "top",
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 2,
        "text-halo-blur": 1,
      },
    });
  }

  updateUnitsFaceIdLayer(source) {
    if (this.map.getLayer("unit-faceid")) this.map.removeLayer("unit-faceid");
    if (!this.showFaceId) return;

    this.map.addLayer({
      id: "unit-faceid",
      type: "symbol",
      source: source,
      layout: {
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "text-field": ["get", "faceId"],
        "text-font": ["Inter Semi Bold"],
        "text-size": 10,
        "text-justify": "center",
        "text-offset": [0.0, 1.1],
        "text-anchor": "top",
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 2,
        "text-halo-blur": 1,
      },
    });
  }

  removePlacemarkerLayer() {
    ["placemarker-cluster", "placemarker"].forEach(layer => {
      if (this.map.getLayer(layer)) this.map.removeLayer(layer);
    });
    this.hasPlacemarkerLayers = false;
  }

  addPlacemarkerLayer() {
    this.map.addLayer({
      id: "placemarker-cluster",
      type: "symbol",
      source: "placemarkers",
      filter: ["has", "point_count"],
      layout: {
        "icon-image": "{icon}",
        "icon-size": 0.8,
        "icon-allow-overlap": true,
      },
    });

    this.map.addLayer({
      id: "placemarker",
      type: "symbol",
      source: "placemarkers",
      filter: ["!has", "point_count"],
      layout: {
        "icon-image": "{icon}",
        "icon-size": 0.5,
        "icon-allow-overlap": true,
        "text-field": "{label}",
        "text-font": ["Inter Semi Bold"],
        "text-size": 12,
        "text-justify": "center",
        "text-offset": [0.0, 0.8],
        "text-anchor": "top",
        "text-optional": true,
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 2,
        "text-halo-blur": 1,
      },
      minzoom: 1,
      maxzoom: 17,
    });
  }

  updatePosition() {
    if (!this.props.onMove) return;

    this.props.onMove({
      center: this.map.getCenter(),
      zoom: this.map.getZoom(),
      bounds: this.map.getBounds(),
    });
  }

  // Get parent of the package on mouse over
  async getPackageParent(parentId) {
    const features = this.map.queryRenderedFeatures(undefined, { layers: ["unit-markers"] });
    const clusters = this.map.queryRenderedFeatures(undefined, { layers: ["cluster-markers"] });
    const source = await Promise.all(
      clusters.map(async cluster => {
        return await this.findLeaves(cluster.properties);
      }),
    );
    const flatFeatures = source.concat(features).flat();
    return flatFeatures.find(f => {
      if (f.properties) {
        return f.properties._id == parentId;
      }
    });
  }

  highlightPackageUnit(feature: mapboxgl.MapboxGeoJSONFeature | undefined) {
    if (feature) {
      this.toggleUnitHighlight(feature);
    }
  }

  async highlightPackageParent(e: mapboxgl.MapMouseEvent) {
    const parent = await this.getPackageParent(e);
    if (parent) {
      this.highlightPackageUnit(parent);
    }
  }

  highlightChildrenUnits(id) {
    if (this.map.getLayer("highlighted-children")) this.map.removeLayer("highlighted-children");
    this.map.addLayer({
      id: "highlighted-children",
      type: "circle",
      source: 'package_children_source',
      minzoom: 7,
      filter: ['==', 'parentId', id],
      paint: {
        "circle-color": { type: "identity", property: "color" },
        "circle-opacity": 0,
        "circle-stroke-width": 3,
        "circle-stroke-color": "#FEC700",
        "circle-radius": 6,
      },
    });
  }

  filteredPackageUnitIds(): Number[] {
    if (!this.childrenCoords) return [];

    const visibleParentUnitsId = this.units.features.map(u => u.properties._id);
    const campaignParenUnitsId = this.childrenCoords.map(u => u.id);
    return campaignParenUnitsId.filter(e => visibleParentUnitsId.includes(e));
  }

  getPackageFeature(unit) {
    return c => ({
      type: "Feature",
      properties: {
        parentId: unit.id,
        friendlyId: unit.friendly_id,
        parentColor: colorForUnit(unit),
      },
      geometry: {
        type: "Point",
        coordinates: [c.lon, c.lat],
      },
    });
  }

  async addChildPackagesLayer() {
    const childrenGeoJson = await (await fetch(`/api/v1/campaigns/${this.props.campaign.token}/children_geojson`)).json();
    const packageChildrenLayer = this.map.getSource('package_children_source') as mapboxgl.GeoJSONSource
    this.childrenCoords = childrenGeoJson.features.map(c => {
      return {
        id: c.properties.parentId
      }
    })
    const filteredIds = this.filteredPackageUnitIds()
    const filteredSource = childrenGeoJson.features.filter(c => filteredIds.includes(c.properties.parentId))
    if (packageChildrenLayer) {
      packageChildrenLayer.setData({type: 'FeatureCollection', features: filteredSource})
    } else {
      this.map.addSource('package_children_source', {
        type: 'geojson',
        data: `/api/v1/campaigns/${this.props.campaign.token}/children_geojson`,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 30 // Radius of each cluster when clustering points (defaults to 50)
      });
    }
    if (this.map.getLayer("package_children")) this.map.removeLayer("package_children");

    this.map.addLayer({
      id: 'package_children',
      type: 'circle',
      source: 'package_children_source',
      minzoom: 7,
      filter: ['has', 'point_count'],
      paint: {
        "circle-color": "#911884",
        "circle-opacity": 0.8,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#FFFFFF",
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          7,
          40,
          10,
          60,
          12
        ]
      },
    });

    if (this.map.getLayer("package_children_single")) this.map.removeLayer("package_children_single");

    this.map.addLayer({
      id: 'package_children_single',
      type: 'circle',
      source: 'package_children_source',
      minzoom: 7,
      filter: ['!has', 'point_count'],
      paint: {
        "circle-opacity": 0.95,
        "circle-radius": 4,
        "circle-stroke-width": 2,
        "circle-stroke-color": ["get", "parentColor"],
        "circle-color": ["get", "parentColor"]
      },
    });

    // when clicking on a child unit trigger the click event on the parent unit (open the edit modal)
    this.map.on("click", 'package_children_single', async (e: mapboxgl.MapLayerMouseEvent) => {
      let friendlyId
      if (e.features) {
        friendlyId = e.features[0].properties?.friendlyId
      }
      if (friendlyId) {
        this.props.onUnitClick({id: friendlyId});
      }
    });

    // highlight the whole package layer and its parent on mouse over
    this.map.on("mouseenter", 'package_children_single', async e => {
      if (e.features) {
        if (!e.features[0].properties) return
        this.highlightPackageParent(e.features[0].properties.parentId);
      }
    });

    this.map.on("mouseleave", 'package_children_single', e => {
      this.removeUnitPopup();
      if (this.map.getLayer("highlighted-children")) this.map.removeLayer("highlighted-children");
      if (this.map.getLayer("highlighted-unit-markers")) this.map.removeLayer("highlighted-unit-markers");
      if (this.map.getLayer("highlighted-cluster-markers")) this.map.removeLayer("highlighted-cluster-markers");
    });

    if (this.map.getLayer("children-cluster-count")) this.map.removeLayer("children-cluster-count");

    this.map.addLayer({
      id: 'children-cluster-count',
      type: 'symbol',
      source: 'package_children_source',
      filter: ['has', 'point_count'],
      minzoom: 7,
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      },
      paint: {
        "text-color": "#FFFFFF"
      }
    });

    if (!this.packageChildrenSpider) {
      this.packageChildrenSpider = new PackageChildrenSpider(this.map, this.props.onUnitClick, this.highlightPackageParent.bind(this), this.removeUnitPopup.bind(this), this.highlightChildrenUnits.bind(this))
    }
    const spider = this.packageChildrenSpider
    this.map.on('click', 'package_children', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['package_children']
      });
      spider.close();
      if (!features.length) {
        return;
      } else {
        const source = this.map.getSource('package_children_source') as GeoJSONSource
        if (!features[0].properties) return
        source.getClusterLeaves(
          features[0].properties.cluster_id, 100, 0, function(err, leafFeatures){
            if (err) {
              return console.error('error while getting leaves of a cluster', err);
            }
            const markers = _.map(leafFeatures, function(leafFeature){
              return leafFeature.properties;
            });
            spider.spiderfy(features[0].geometry.coordinates, markers);
          }
        );
      }
    })
  }
}
