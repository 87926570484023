const initialState = {
  open_rfp_count: null,
  unit_requests_count: null,
  hold_requests_count: null,
  expiring_holds_count: null,
  pops_outstanding_count: null,
  selected_supplier: null,
  show_expiring_holds_modal: false,
  show_pops_outstanding_modal: false,
  loading_profile_strength: false,
  profile_strength: null,
  stale_availability: null,
  last_flight_created_at: null,
  loading_stale_availability: null,
  upcoming_flights: null,
  loading_upcoming_flights: false,
  month_occupancy: null,
  quarter_occupancy: null,
  year_occupancy: null,
  all_occupancy: null,
  loading_month_occupancy: false,
  loading_quarter_occupancy: false,
  loading_year_occupancy: false,
  loading_all_occupancy: false,
  month_revenue: null,
  quarter_revenue: null,
  year_revenue: null,
  all_revenue: null,
  loading_month_revenue: false,
  loading_quarter_revenue: false,
  loading_year_revenue: false,
  loading_all_revenue: false,
  booking_year_offsets: null,
  loading_booking_year_offsets: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "BEGIN_FETCH_RFP_COUNT": {
      return {
        ...state,
        loading_open_rfp_count: true
      }
    }
    case "FETCH_OPEN_RFP_COUNT_SUCCESS": {
      const { open_rfp_count } = action.payload;
      return {
        ...state,
        open_rfp_count,
        loading_open_rfp_count: false
      };
    }
    case "BEGIN_FETCH_UNIT_REQUEST_COUNT": {
      return {
        ...state,
        loading_unit_requests_count: true
      }
    }
    case "FETCH_UNIT_REQUESTS_SUCCESS": {
      const { unit_requests_count } = action.payload;
      return {
        ...state,
        unit_requests_count,
        loading_unit_requests_count: false
      };
    }
    case "BEGIN_FETCH_HOLD_REQUEST_COUNT": {
      return {
        ...state,
        loading_hold_requests_count: true
      }
    }
    case "FETCH_HOLD_REQUESTS_SUCCESS": {
      const { hold_requests_count } = action.payload;
      return {
        ...state,
        hold_requests_count,
        loading_hold_requests_count: false
      };
    }
    case "BEGIN_FETCH_EXPIRING_HOLDS_COUNT": {
      return {
        ...state,
        loading_expiring_holds_count: true
      }
    }
    case "FETCH_EXPIRING_HOLDS_COUNT_SUCCESS": {
      const { expiring_holds_count } = action.payload;
      return {
        ...state,
        expiring_holds_count,
        loading_expiring_holds_count: false
      };
    }
    case "BEGIN_FETCH_EXPIRING_HOLDS": {
      return {
        ...state,
        loading_expiring_holds: true
      }
    }
    case "FETCH_EXPIRING_HOLDS_SUCCESS": {
      const { expiring_holds } = action.payload;
      return {
        ...state,
        expiring_holds,
        loading_expiring_holds: false
      };
    }
    case "BEGIN_FETCH_POPS_OUTSTANDING": {
      return {
        ...state,
        loading_pops_outstanding: true
      }
    }
    case "FETCH_POPS_OUTSTANDING_SUCCESS": {
      const { pops_outstanding } = action.payload;
      return {
        ...state,
        pops_outstanding,
        loading_pops_outstanding: false
      };
    }
    case "BEGIN_FETCH_POPS_OUTSTANDING_COUNT": {
      return {
        ...state,
        loading_pops_outstanding_count: true
      }
    }
    case "FETCH_POPS_OUTSTANDING_COUNT_SUCCESS": {
      const { pops_outstanding_count } = action.payload;
      return {
        ...state,
        pops_outstanding_count,
        loading_pops_outstanding_count: false
      };
    }
    case "BEGIN_FETCH_STALE_AVAILABILITY": {
      return {
        ...state,
        loading_stale_availability: true
      }
    }
    case "FETCH_STALE_AVAILABILITY_SUCCESS": {
      const { stale_availability, last_flight_created_at } = action.payload;
      return {
        ...state,
        stale_availability,
        last_flight_created_at,
        loading_stale_availability: false
      };
    }
    case "BEGIN_FETCH_MISSING_IMAGES": {
      return {
        ...state,
        loading_missing_image_count: true
      }
    }
    case "FETCH_MISSING_IMAGES_SUCCESS": {
      const { missing_image_count } = action.payload;
      return {
        ...state,
        missing_image_count,
        loading_missing_image_count: false
      };
    }
    case "SET_SUPPLIER_SUCCESS": {
      const { selected_supplier } = action.payload;
      return {
        ...state,
        selected_supplier
      };
    }
    case "SHOW_EXPIRING_HOLDS_MODAL": {
      return {
        ...state,
        show_expiring_holds_modal: true
      };
    }
    case "HIDE_EXPIRING_HOLDS_MODAL": {
      return {
        ...state,
        show_expiring_holds_modal: false
      };
    }
    case "SHOW_POPS_OUTSTANDING_MODAL": {
      return {
        ...state,
        show_pops_outstanding_modal: true
      };
    }
    case "HIDE_POPS_OUTSTANDING_MODAL": {
      return {
        ...state,
        show_pops_outstanding_modal: false
      };
    }
    case "BEGIN_FETCH_PROFILE_STRENGTH": {
      return {
        ...state,
        loading_profile_strength: true
      };
    }
    case "FETCH_PROFILE_STRENGTH_SUCCESS": {
      const { profile_strength } = action.payload;
      return {
        ...state,
        profile_strength,
        loading_profile_strength: false
      };
    }
    case "BEGIN_FETCH_UPCOMING_FLIGHTS": {
      return {
        ...state,
        loading_upcoming_flights: true
      };
    }
    case "FETCH_UPCOMING_FLIGHTS_SUCCESS": {
      const { upcoming_flights, count } = action.payload;
      return {
        ...state,
        upcoming_flights,
        count,
        loading_upcoming_flights: false
      };
    }
    case "BEGIN_FETCH_MONTH_REVENUE_DATA": {
      return {
        ...state,
        loading_month_revenue: true
      };
    }
    case "FETCH_MONTH_REVENUE_DATA_SUCCESS": {
      const { month_revenue } = action.payload;
      return {
        ...state,
        month_revenue,
        loading_month_revenue: false
      };
    }
    case "BEGIN_FETCH_QUARTER_REVENUE_DATA": {
      return {
        ...state,
        loading_quarter_revenue: true
      };
    }
    case "FETCH_QUARTER_REVENUE_DATA_SUCCESS": {
      const { quarter_revenue } = action.payload;
      return {
        ...state,
        quarter_revenue,
        loading_quarter_revenue: false
      };
    }
    case "BEGIN_FETCH_YEAR_REVENUE_DATA": {
      return {
        ...state,
        loading_year_revenue: true
      };
    }
    case "FETCH_YEAR_REVENUE_DATA_SUCCESS": {
      const { year_revenue } = action.payload;
      return {
        ...state,
        year_revenue,
        loading_year_revenue: false
      };
    }
    case "BEGIN_FETCH_ALL_REVENUE_DATA": {
      return {
        ...state,
        loading_all_revenue: true
      };
    }
    case "FETCH_ALL_REVENUE_DATA_SUCCESS": {
      const { all_revenue } = action.payload;
      return {
        ...state,
        all_revenue : {
          ...state.all_revenue,
          ...all_revenue
        },
        loading_all_revenue: false
      };
    }
    case "BEGIN_FETCH_MONTH_OCCUPANCY_DATA": {
      return {
        ...state,
        loading_month_occupancy: true
      };
    }
    case "FETCH_MONTH_OCCUPANCY_DATA_SUCCESS": {
      const { month_occupancy } = action.payload;
      return {
        ...state,
        month_occupancy,
        loading_month_occupancy: false
      };
    }
    case "BEGIN_FETCH_QUARTER_OCCUPANCY_DATA": {
      return {
        ...state,
        loading_quarter_occupancy: true
      };
    }
    case "FETCH_QUARTER_OCCUPANCY_DATA_SUCCESS": {
      const { quarter_occupancy } = action.payload;
      return {
        ...state,
        quarter_occupancy,
        loading_quarter_occupancy: false
      };
    }
    case "BEGIN_FETCH_YEAR_OCCUPANCY_DATA": {
      return {
        ...state,
        loading_year_occupancy: true
      };
    }
    case "FETCH_YEAR_OCCUPANCY_DATA_SUCCESS": {
      const { year_occupancy } = action.payload;
      return {
        ...state,
        year_occupancy,
        loading_year_occupancy: false
      };
    }
    case "BEGIN_FETCH_ALL_OCCUPANCY_DATA": {
      return {
        ...state,
        loading_all_occupancy: true
      };
    }
    case "FETCH_ALL_OCCUPANCY_DATA_SUCCESS": {
      const { all_occupancy } = action.payload;
      return {
        ...state,
        all_occupancy: {
          ...state.all_occupancy,
          ...all_occupancy
        },
        loading_all_occupancy: false
      };
    }
    case "BEGIN_FETCH_BOOKING_YEAR_OFFSETS": {
      return {
        ...state,
        loading_booking_year_offsets: true
      };
    }
    case "FETCH_BOOKING_YEAR_OFFSETS_SUCCESS": {
      const { booking_year_offsets } = action.payload;
      return {
        ...state,
        booking_year_offsets,
        loading_booking_year_offsets: false
      };
    }
    case "RESET_DASHBOARD": {
      return {
        ...state,
        ...initialState
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
