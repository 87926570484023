import Chart from "chart.js";
import Chartkick from "chartkick";
import { connect } from 'react-redux';
import React from 'react';
import { get } from '../../../utils/api';

import Loading from '../../Loading';
import { isEmpty, pick } from 'lodash';

type Campaign = {
  id: number;
  name: string;
  campaign_id: string;
}

type Stat = {
  facebook_ad_campaign_id: number;
}

type Props = {
  campaignId: string;
  advertiser_id: number;
}

type State = {
  campaigns: Campaign[];
  stats: Stat[];
  selectedCampaign: string;
}

type ChartData = {
  name: string;
  data: object[];
}

Chartkick.addAdapter(Chart);

class FacebookAnalytics extends React.Component<Props, State> {
  _metrics?: string[];

  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      stats: [],
      selectedCampaign: ''
    }
  }

  componentWillMount() {
    this.loadFacebookEvents(this.props.campaignId);
  }

  componentDidMount() {
    this.renderCharts();
  }

  componentDidUpdate() {
    this.renderCharts();
  }

  isActive() {
    return !isEmpty(this.state.campaigns)
  }

  async loadFacebookEvents(campaignId) {
    const { campaigns, stats } = await get(`/api/v1/campaigns/${campaignId}/facebook_analytics_reports`)
    this.setState({ campaigns, stats })
  }

  render() {
    if (!this.isActive()) return <Loading/>;

    return (
        <div className="container-fluid" style={{marginTop: "20px"}}>
          <div className="row">
            <div className="form-group">
              <select className="form-control" onChange={(e) => this.selectCampaign(e.target.value)}>
                {this.campaignOptions()}
              </select>
            </div>
          </div>
          <div id="segment-metric-chart" className="row" style={{ height: '350px', marginTop: '10px' }}/>
        </div>
    );
  }

  selectCampaign(campaignName) {
    this.setState({ selectedCampaign: campaignName }, () => this.renderCharts())
  }

  campaignOptions() {
    const { campaigns } = this.state
    return campaigns.map(ca => <option key={ca.campaign_id}>{ca.name}</option>);
  }

  renderCharts() {
    const { selectedCampaign, campaigns, stats } = this.state;
    if (!this.isActive()) return;
    const campaign = campaigns.find(ca => ca.name == selectedCampaign) || campaigns[0];
    const data = stats.filter(stat => stat.facebook_ad_campaign_id === campaign.id);

    if (isEmpty(data)) return;
    const chartData = this.filterChartData(data)

    const library = {
      layout: {
        padding: {
          left: 0,
          right: 15,
          top: 15,
          bottom: 15
        }
      },
      scales: {
        yAxes: [{
          // display: false,
          ticks: {
            padding: 10,
            // stepSize : step
          },
          gridLines: {
            display: true,
            drawBorder: false,
          }
        }],
        xAxes: [{
          display: true
        }]
      }
    }
    // const options = { points: true, max: max, legend: false, colors: ["#4a90e2", "#f5a623"], library: library }
    const options = { points: true, legend: false, library }
    new Chartkick.LineChart("segment-metric-chart", chartData, options);
  }

  filterChartData(data) {
    const dataKeys = pick(data[0], ["impressions", "reach", "clicks", "conversions", "cpc", "cpm", "cpp", "ctr", "spend"]);
    const allDates = data.map(d => d['date']);
    return this.reduceChartData(data, dataKeys, allDates);
  }

  reduceChartData(data, dataKeys, allDates) {
    return Object.keys(dataKeys).reduce((acc: ChartData[], value) => {
      const dataObject = {
        name: value,
        data: this.reduceDailyChartData(data, value, allDates)
      };
      acc.push(dataObject);
      return acc
    }, [])
  }

  reduceDailyChartData(data, dataKey, allDates) {
    return allDates.reduce((date_acc, date_value) => {
      const dateData = data.find(d => d['date'] === date_value) || {};
      date_acc[date_value] = dateData[dataKey] || 0;
      return date_acc
    }, {})
  }
}

export default connect(
  // @ts-ignore
  ({ campaign }) => ({
    campaignId: campaign.campaignId,
    advertiser_id: campaign.campaign.advertiser_id
  }), {})(FacebookAnalytics);
