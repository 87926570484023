import "@adquick/ui/css/index.css";

import "./customgrids.css";

import { Button, ButtonColor, Loading, LoadingSize, Modal, ModalBody, ModalFooter } from "@adquick/ui";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import JobProgress from "../../../utils/JobProgress";
import Message from "../../Message";
import { loadCampaignUnits } from "../actions";
import NewCustomGridModal from "./NewCustomGridModal";

const ASSETS = (window as any).ASSETS;

interface Props {
  campaign: any;
  user_permissions: any;
  loadCampaignUnits: (string) => void;
}

interface BackroundJob {
  running: boolean;
  job_id: string;
}

interface State {
  customGridsIds: Array<number>;
  spreadSheetUrls: Array<string>;

  showNewCustomGridPane: boolean;
  showNewCustomGridModal: boolean;

  backroundJob: BackroundJob;
  jobProgress: JobProgress;

  synced_at?: string;

  message: {
    show: boolean;
    text: string;
  };
}

class CustomGrids extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      customGridsIds: [],
      spreadSheetUrls: [],

      showNewCustomGridPane: false,
      showNewCustomGridModal: false,

      backroundJob: {
        running: false,
        job_id: "",
      },
      jobProgress: new JobProgress(),

      message: {
        show: false,
        text: "",
      },
    };

    this.onNewCustomGrids = this.onNewCustomGrids.bind(this);
    this.onDelCustomGrids = this.onDelCustomGrids.bind(this);
    this.onSyncCustomGrids = this.onSyncCustomGrids.bind(this);

    this.onAuthorize = this.onAuthorize.bind(this);

    // callbacks to track outstanding background jobs (e.g.: new/sync custom grid)
    this.onStartPolling = this.onStartPolling.bind(this);
    this.onBackgroundJob = this.onBackgroundJob.bind(this);

    // callbacks to be passed to NewCustomGridModal
    this.onCreateNewCustomGrid = this.onCreateNewCustomGrid.bind(this);
    this.onCancelNewCustomGrid = this.onCancelNewCustomGrid.bind(this);
  }

  componentDidMount(): void {
    const { campaignId, name } = this.props.campaign;

    $.get(`/api/v1/campaigns/${campaignId}/campaign_google_sheets`).done(campaignGoogleSheets => {
      const spreadSheetUrls = campaignGoogleSheets.map(({ spreadsheet_url }) => spreadsheet_url);
      const customGridsIds = campaignGoogleSheets.map(({ id }) => id);
      const synced_ats = campaignGoogleSheets.map(({ synced_at }) => synced_at);
      const showNewCustomGridPane = !!!spreadSheetUrls.length;

      this.setState({
        customGridsIds,
        showNewCustomGridPane,
        spreadSheetUrls,
        backroundJob: {
          running: false,
          job_id: "",
        },
        synced_at: synced_ats[0],
      });
    });

    this.setState({
      backroundJob: {
        running: true,
        job_id: "",
      },
    });
  }

  onNewCustomGrids(): void {
    this.setState({ showNewCustomGridModal: true });
  }

  onDelCustomGrids(): void {
    const { campaignId } = this.props.campaign;

    if (confirm("Are you sure you want to delete this Custom Grid?")) {
      this.state.customGridsIds.forEach(id => {
        $.ajax(`/api/v1/campaigns/${campaignId}/campaign_google_sheets/${id}`, { method: "DELETE" }).then(() => {
          this.setState({
            spreadSheetUrls: [],
            showNewCustomGridPane: true,
            synced_at: undefined,
          });
        });
      });
    }
  }

  onSyncCustomGrids(): void {
    const { campaignId } = this.props.campaign;
    const { customGridsIds } = this.state;

    customGridsIds.forEach(id => {
      $.post(`/api/v1/campaigns/${campaignId}/campaign_google_sheets/${id}/sync`)
        .done(({ job_id }) => this.onStartPolling({ job_id, text: "Campaign units information saved" }))
        .fail(({ status }) => {
          console.log(`Error importing customgrigs ${status}`);

          this.setState({
            backroundJob: {
              running: false,
              job_id: "",
            },
          });
        });
    });

    this.setState({
      backroundJob: {
        running: true,
        job_id: "",
      },
    });
  }

  onAuthorize(): void {
    const { campaignId } = this.props.campaign;
    // DO NOT HOST THIS IN AN IFRAME UNTIL WE HAVE A DEMO FOR GOOGLE APP (THEY REQUIRE THE URL TO BE CLEARLY VISIBLE)
    window.location.href = `/auth/sheets_access?return_to=/campaigns/${campaignId}/custom_grids&campaign_token=${campaignId}`;
  }

  onStartPolling({ job_id, text }): void {
    this.setState({
      backroundJob: {
        running: true,
        job_id: job_id,
      },
      message: {
        show: false,
        text,
      },
    });

    if (this.state.jobProgress.pollingInterval) {
      this.state.jobProgress.stop;
    }
    this.state.jobProgress.startPolling(job_id, this.onBackgroundJob);
  }

  onBackgroundJob({ data: { synced_at } }): void {
    this.state.jobProgress.stop;
    this.setState({
      backroundJob: {
        running: false,
        job_id: "",
      },
      message: {
        ...this.state.message,
        show: true,
      },
      synced_at,
    });
    this.props.loadCampaignUnits(this.props.campaign.campaignId);
  }

  onCancelNewCustomGrid(): void {
    this.setState({ showNewCustomGridModal: false });
  }

  onCreateNewCustomGrid({ spreadsheet_url, id, job_id }): void {
    this.onStartPolling({
      job_id,
      text: "New Custom Grid created",
    });

    this.setState({
      showNewCustomGridModal: false,
      showNewCustomGridPane: false,
      spreadSheetUrls: this.state.spreadSheetUrls.concat(spreadsheet_url),
      customGridsIds: [id],
      backroundJob: {
        running: true,
        job_id,
      },
    });
  }

  renderAuthorizationPane(): JSX.Element {
    return (
      <div className="customgrids_tab__customgrid">
        <div className="customgrids_tab__pane">
          <i className="fa fa-lock fa-5x" />
          <label className="customgrids_tab__pane__label--header">Your custom grid is locked</label>
          <label className="customgrids_tab__pane__label--text">
            Authorize your account first to create custom grids
          </label>
          <div className="customgrids_tab__pane__button">
            <img src={ASSETS["google-login"]} style={{ cursor: "pointer" }} onClick={this.onAuthorize} />
          </div>
        </div>
      </div>
    );
  }

  renderNewCustomGridPane(): JSX.Element {
    const { showNewCustomGridModal } = this.state;
    const { campaign } = this.props.campaign;

    return (
      <div className="customgrids_tab__customgrid">
        <div className="customgrids_tab__pane">
          <i className="fa fa-file fa-5x" />
          <label className="customgrids_tab__pane__label--header">Your custom grid is empty</label>
          <label className="customgrids_tab__pane__label--text">Customize and add additional columns</label>
          <div className="customgrids_tab__pane__button">
            <Button onClick={this.onNewCustomGrids}>Create a new custom grid</Button>
          </div>
          {showNewCustomGridModal && (
            <NewCustomGridModal
              showNewCustomGridModal={showNewCustomGridModal}
              campaign={campaign}
              onCancelNewCustomGrid={this.onCancelNewCustomGrid}
              onCreateNewCustomGrid={this.onCreateNewCustomGrid}
            />
          )}
        </div>
      </div>
    );
  }

  renderIFrame(spreadSheetUrl): JSX.Element {
    return (
      <div className="customgrids_tab__customgrid__iframe__container">
        <iframe src={spreadSheetUrl}></iframe>
      </div>
    );
  }

  renderCustomGrid(): JSX.Element {
    const { spreadSheetUrls, backroundJob, message, synced_at } = this.state;
    const disableSyncCustomGrid = backroundJob.running;
    const disableDelCustomGrid = backroundJob.running;

    return (
      <div className="customgrids_tab__customgrid">
        <div className="customgrids_tab__customgrid__header">
          <div className="customgrids_tab__customgrid__header__loading" hidden={!backroundJob.running}>
            <Loading size={LoadingSize.Default} />
          </div>
          <div className="customgrids_tab__customgrid__header__buttons">
            {synced_at && (
              <div className="customgrids_tab__customgrid__header__synced_at">
                <label>Synced at:</label> {moment(synced_at).format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </div>
            )}
            <div className="customgrids_tab__customgrid__header__buttons__sync">
              <Button
                onClick={this.onSyncCustomGrids}
                disabled={disableSyncCustomGrid}
                color={disableSyncCustomGrid ? ButtonColor.Disabled : ButtonColor.Default}
              >
                Sync
              </Button>
            </div>
            <div className="customgrids_tab__customgrid__header__buttons__del">
              {!disableDelCustomGrid && (
                <i className="fa fa-trash-o fa-2x" aria-hidden="true" onClick={this.onDelCustomGrids}></i>
              )}
            </div>
          </div>
        </div>
        {spreadSheetUrls.map(spreadSheetUrl => this.renderIFrame(spreadSheetUrl))}
        {message.show && <Message text={message.text} type="success" />}
      </div>
    );
  }

  render(): JSX.Element {
    const { showNewCustomGridPane } = this.state;
    const { can_edit_custom_grids } = this.props.user_permissions;

    return (
      <div className="customgrids_tab">
        {can_edit_custom_grids
          ? showNewCustomGridPane
            ? this.renderNewCustomGridPane()
            : this.renderCustomGrid()
          : this.renderAuthorizationPane()}
      </div>
    );
  }
}

export default connect(undefined, { loadCampaignUnits })(CustomGrids);
