import axios from "axios";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash'
import createClass from 'create-react-class';

import { loadCampaignUnits } from './actions';
import GlobalActions from '../../actions/GlobalActions';
import PlacesStore from '../../stores/PlacesStore';
import JobProgress from '../../utils/JobProgress';


const AddUnitsNearPlacemarkers = createClass({
  propTypes: {
    campaign: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      available_units_only: false,
      distance_in_miles: 1.0,
      per_poi_unit_limit: '',
      job_pooling: new JobProgress(),
      saving: false,
    };
  },

  async onSubmit(e) {
    e.preventDefault();
    const { units } = this.props;
    const unit_filter = units.unit_filter || {};
    const { distance_in_miles, per_poi_unit_limit, available_units_only} = this.state;
    const params = { distance_in_miles, filters: unit_filter, per_poi_unit_limit, available_units_only };
    const distance = _.toNumber(distance_in_miles)
    if (isNaN(distance)) {
      GlobalActions.showError('Distance in miles is invalid');
      return;
    } else if (distance > 1000) {
      GlobalActions.showError('Distance in miles is too large');
      return;
    }
    this.setState({ saving: true });

    axios.post(`/api/v1/campaigns/${this.props.campaign}/add_units_near_placemarkers`, params).then(({ data }) => {
        this.state.job_pooling.startPolling(data.job_id, this.onJobComplete);
      })
      .catch(error => {
        const { response } = error;
        switch (response.status) {
          case 409:
            GlobalActions.showMessage(response.data.error, "warning", true);
            break;
          case 422:
            GlobalActions.showMessage(response.data.error, "error", true);
            break;
          default:
            GlobalActions.showError("Something unexpected happened");
        }
        this.setState({ saving: false });
      });
  },

  onJobComplete(response) {
    const { data } = response
    const { error } = data
    if (error) {
      this.setState({ saving: false });
      return GlobalActions.showError(error)
    }
    const addedCount = data.added_count;
    this.setState(this.getInitialState());
    GlobalActions.showMessage(`Added ${addedCount} units near points of interest to the campaign!`);
    PlacesStore.onAddNearbyUnits();
    this.props.loadCampaignUnits(this.props.campaign);
    this.props.onHide();
  },

  boltIcon() {
    return <span class="fa-stack small" style={{fontSize: '0.5em'}}>
    <i style={{verticalAlign: 'middle', color: '#F5A623'}} class="fas fa-circle fa-stack-2x"></i>
    <i style={{verticalAlign: 'middle'}} class="fas fa-bolt fa-stack-1x fa-inverse"></i>
  </span>
  },

  instantBookInput() {
    if (!this.props.userPermissions.can_see_instant_book_inputs) { return }
    return (
    <div style={{overflow:'hidden'}}>
      <div className="form-group" style={{ paddingRight: 10 }}>
        <input type="checkbox" checked={this.state.available_units_only} onChange={(e) => { this.setState({ available_units_only: !!!this.state.available_units_only }); }} />
        <label style={{marginLeft: 5 }}>Only include <strong> {this.boltIcon()} Instant Book </strong> units</label>
      </div>
    </div>
    )
  },


  render() {
    return (<Modal show={this.props.show} onHide={this.props.onHide}>
      <Modal.Header>
        <div style={{fontWeight: 600, fontSize: '16px', marginLeft: '48px', marginRight: '48px'}}>Add Units Near POIs</div>
        <div style={{paddingTop: '16px', marginLeft: '48px', marginRight: '48px'}}>Currently enabled filters will affect unit selection and transit units will be excluded.</div>
      </Modal.Header>
      <Modal.Body>
        {this.state.saving ? <div className="searchTab__loading" style={{ margin: 'auto' }} /> : <form className="form" onSubmit={this.onSubmit}>
          <div style={{overflow:'hidden'}}>
            <div className="form-group" style={{ paddingRight: 10 }}>
              <label>Distance from unit (in miles)</label>
              <input className="form-control"
                type="input"
                defaultValue={this.state.distance_in_miles}
                placeholder="Radius from unit in miles"
                onChange={(e) => { this.setState({ distance_in_miles: e.target.value }); }}
              />
            </div>

            <div className="form-group" style={{ paddingRight: 10 }}>
              <label>Limit units per POI</label>
              <input className="form-control" type="input" defaultValue={this.state.per_poi_unit_limit} placeholder="eg. 5. Leave blank for no limit" onChange={(e) => { this.setState({ per_poi_unit_limit: e.target.value }); }} />
            </div>
          </div>

          {this.instantBookInput()}

          <div className="alert alert-warning" style={{fontWeight: 400, fontSize: '12px', marginTop: '10px'}}>
            <ul>
              <li>* Uses currently enabled search filters </li>
              <li>* Excludes transit </li>
            </ul>
          </div>
        </form>}
      </Modal.Body>
      <Modal.Footer>
        <a onClick={this.props.onHide} className="btn btn-link">Cancel</a>
        <button style={{backgroundColor: '#4A90E2'}} disabled={this.state.saving} className="btn btn_primary" type="submit" onClick={this.onSubmit}>Add</button>
      </Modal.Footer>
    </Modal>);
  },
});

export default connect(({ units })=>({ units }), { loadCampaignUnits })(AddUnitsNearPlacemarkers);
