import React from 'react';
import accounting from "accounting";

class Addons extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state ={
      showList : false
    }
    this.handleListShow =this.handleListShow.bind(this);
    this.handleListShow =this.handleListShow.bind(this);
  }

  handleListShow(){
    this.setState({showList: !this.state.showList})
  }

  renderIcon(){
    if(this.state.showList){
      return <i className="fal fa-angle-down"></i>
    } else {
      return <i className="fal fa-angle-right"></i>
    }
  }

  renderListItems(){
    const add_ons_list = this.props.add_ons_list;
    const tokenString = `${this.props.token}`
    return (
      <div className="add-ons-list">
        {add_ons_list.map((item, i) => {
          return (
            <div className="add-on-list-item" key={item.id}>
              <div className="id">{item.id}</div>
              <div 
                className="type"
                data-campaign-header-add-on-id={item.id}
                data-campaign-header-campaign-token={tokenString}
                data-controller="campaign-header"
                data-action="click->campaign-header#openAddonModal"
              >{item.name}</div>
              <div className="date">
                {`${item.start_date} - ${item.end_date}`}
              </div>
              <div className="price">{accounting.formatMoney(item.client_price)}</div>
            </div>
          )}
        )}
        <div className="totals">Total&nbsp;<b>{accounting.formatMoney(this.props.add_ons_total)}</b></div>
      </div>
    ); 
  }

  render(){
    // if(this.state.count > 0) {
    //   return <div></div>;
    // }
    return(
      <div className="add-ons-section">
        <div className='add-ons-content'>
          <div className="add-ons-header closed">
            <div className="count">
              {`Add-on(${this.props.add_ons_count})`}
            </div>
            <div className="carrot" onClick={this.handleListShow}>
              {this.renderIcon()}
            </div>
          </div>
          {this.state.showList && this.renderListItems()}
        </div>
      </div>
    )
  }
}
export default Addons;