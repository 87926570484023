import React from "react";
import { BootstrapTable } from 'react-bootstrap-table';
import { get } from "../../../utils/api";
import { formatMoney } from 'accounting'
import Loading from '../../Loading';

const priceFormatter = val => formatMoney(val)

export default class PricingTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      by_market: [],
      by_vendor: [],
      loading: true
    };
  }

  componentDidMount() {
    this.loadSummary()
  }

  async loadSummary() {
    const { campaign_id } = this.props;
    const { by_market, by_vendor } = await get(`/api/v1/campaigns/${campaign_id}/pricing_table`);
    this.setState({ by_market, by_vendor, loading: false })
  }

  render() {
    const { by_market, by_vendor, loading } = this.state

    if (loading) {
      return <Loading/>
    }

    return (
      <div className="container-fluid">
        <div style={{width:'100%',overflow:'hidden'}} />
        <h3>Summary By Market</h3>
        <BootstrapTable data={by_market} hover striped>
          <TableHeaderColumn dataField='dma_name' isKey hidden />
          <TableHeaderColumn dataField='dma_name' dataSort>Market (DMA)</TableHeaderColumn>
          <TableHeaderColumn dataField='units' dataSort>Total Units</TableHeaderColumn>
          <TableHeaderColumn dataField='digital' dataSort>Digital Units</TableHeaderColumn>
          <TableHeaderColumn dataField='static' dataSort>Static Units</TableHeaderColumn>
          <TableHeaderColumn dataField='min' dataFormat={priceFormatter} dataSort>Price Min</TableHeaderColumn>
          <TableHeaderColumn dataField='max' dataFormat={priceFormatter} dataSort>Price Max</TableHeaderColumn>
          <TableHeaderColumn dataField='median' dataFormat={priceFormatter} dataSort>Price Median</TableHeaderColumn>
          <TableHeaderColumn dataField='big3_count' dataSort>Big 3 Units</TableHeaderColumn>
          <TableHeaderColumn dataField='big3_median' dataFormat={priceFormatter} dataSort>Big 3 Price Median</TableHeaderColumn>
          <TableHeaderColumn dataField='other_count' dataSort>Indie Units</TableHeaderColumn>
          <TableHeaderColumn dataField='other_median' dataFormat={priceFormatter} dataSort>Indie Price Median</TableHeaderColumn>
        </BootstrapTable>

        <h3>Summary By Vendor</h3>
        <BootstrapTable
          data={by_vendor}
          hover
          striped
          options={{sortName: 'unit_count', sortOrder: 'desc'}}>
          <TableHeaderColumn dataField='supplier_id' isKey hidden />
          <TableHeaderColumn dataField='supplier_name' dataSort>Vendor</TableHeaderColumn>
          <TableHeaderColumn dataField='unit_count' dataSort>Unit Count</TableHeaderColumn>
          <TableHeaderColumn dataField='median_price' dataFormat={priceFormatter} dataSort>Median Price</TableHeaderColumn>
          <TableHeaderColumn dataField='total_4_week_price' dataFormat={priceFormatter} dataSort>4/wk Price from this campaign</TableHeaderColumn>
          {/*<TableHeaderColumn dataField='total_4_week_price' dataFormat={priceFormatter} dataSort>4/wk Price from old prices</TableHeaderColumn>*/}
          <TableHeaderColumn dataField='historical_margin' dataSort>Supplier historical margin</TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}
