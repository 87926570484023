import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import GlobalActions from "../../../actions/GlobalActions";
import { updateSpots } from "./actions";

class ChangeSpotsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      spots: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { updateSpots, unit_tokens, campaign_token } = this.props;
    const { spots } = this.state;
    updateSpots(unit_tokens, campaign_token, spots);
  }

  componentDidUpdate(prevProps) {
    const { saving_spots, onHide } = this.props;
    if (!!!saving_spots && prevProps.saving_spots) {
      GlobalActions.showMessage("Flight spots saved");
      onHide();
    }
  }

  renderBody() {
    const { saving_spots, onHide } = this.props;
    const { spots } = this.state;

    return (
      <Modal.Body>
        {saving_spots && (
          <div>
            <div className="loading-spinner">
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        )}
        {!!!saving_spots && (
          <div>
            <div className="form-group row">
              <div className="col-sm-12">
                <div className="title">Spots</div>
                <input
                  className="form-control"
                  value={spots}
                  type="number"
                  onChange={e => this.setState({ spots: e.target.value })}
                />
              </div>
            </div>
            <div className="button-area">
              <button className="confirmation_button btn btn-success" onClick={this.onSubmit}>
                Submit
              </button>
              <button className="cancel_button btn btn-default" onClick={onHide}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    );
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="flight_action_modal">
        <Modal.Header>
          <Modal.Title>Change Number of Spots</Modal.Title>
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { saving_spots } }) => ({ saving_spots }),
  { updateSpots },
)(ChangeSpotsModal);
