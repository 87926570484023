import { get, post, put, del } from '../../utils/api'
import { browserHistory } from 'react-router-legacy'
import moment from 'moment'

const toggleLoading = () => ({
  type: 'TOGGLE_LOADING',
})

const fetchGroupSuccess = (company_groups) => ({
  type: 'FETCH_COMPANY_GROUPS_SUCCESS',
  company_groups
})

const deleteGroupSuccess = (group_id) => ({
  type: 'DELETE_COMPANY_GROUP_SUCCESS',
  group_id
})

const fetchCompaniesSuccess = (companies) => ({
  type: 'FETCH_COMPANIES_SUCCESS',
  companies
})

const fetchCompaniesFromGroupSuccess = (group_id, companies) => ({
  type: 'FETCH_COMPANIES_SUCCESS',
  group_id,
  companies
})

const addCompanieToGroupSuccess = (group_id, company) => ({
  type: 'ADD_COMPANY_TO_GROUP_SUCCESS',
  group_id,
  company
})

const deleteCompanieFromGroupSuccess = (group_id, company) => ({
  type: 'DELETE_COMPANY_FROM_GROUP_SUCCESS',
  group_id,
  company
})

const scrapeGroup = (id) => ({
  type: 'SCRAPING_GROUP',
  id
})

const scrapeGroupSuccess = (groupId, { job_id }) => ({
  type: 'SCRAPING_GROUP_SUCCESS',
  groupId,
  progress: job_id
})

const pollingJob = (job_id, groupId, progress) => ({
  type: 'POOLING_JOB',
  job_id,
  groupId,
  progress
})

export const asyncFetchGroups = () => async (dispatch) => {
  const company_groups = await get('/api/v1/company_groups')
  dispatch(fetchGroupSuccess(company_groups))
}

export const asyncDeleteGroup = (group_id) => async (dispatch) => {
  const company_group = await del(`/api/v1/company_groups/${group_id}`)
  dispatch(deleteGroupSuccess(group_id))
  browserHistory.push('/businesses')
}

export const asyncFetchCompanies = (url) => async (dispatch) => {
  dispatch(toggleLoading())
  const companies = await get(url)
  companies.map(c => {
    if(c.last_linkedin_scrape_at !== null) {
      c.last_linkedin_scrape_at = moment(c.last_linkedin_scrape_at).format('L')
    }
  })
  dispatch(fetchCompaniesSuccess(companies))
  dispatch(toggleLoading())
}

export const asyncFetchCompaniesFromGroup = (group_id) => async (dispatch) => {
  const companies = await get(`/api/v1/businesses?&group_id=${group_id}`)
  dispatch(fetchCompaniesFromGroupSuccess(group_id, companies))
}

export const asyncAddCompanieToGroup = (group_id, companies) => async (dispatch) => {
  await put(`/api/v1/company_groups/${group_id}`, { companies })
  dispatch(addCompanieToGroupSuccess(group_id, companies))
  dispatch(asyncFetchCompaniesFromGroup(group_id))
}

export const asyncDeleteCompanieFromGroup = (group_id, companies) => async (dispatch) => {
  await post(`/api/v1/company_groups/${group_id}/delete`, { companies })
  dispatch(deleteCompanieFromGroupSuccess(group_id, companies))
  dispatch(asyncFetchCompaniesFromGroup(group_id))
}

export const asyncScrapeGroup = (id, roles) => async (dispatch) => {
  dispatch(scrapeGroup(id))
  const job_id = await post(`/api/v1/company_groups/${id}/scrape`, { roles })
  dispatch(scrapeGroupSuccess(id, { job_id } ))
  dispatch(asyncFetchGroups())
}

export const asyncPoller = (job_id, group_id) => async (dispatch) => {
  const progress = await get(`/api/v1/job_progress/${job_id}`)
  progress['job_id'] = job_id
  dispatch(pollingJob(job_id, group_id, progress ))
  dispatch(asyncFetchGroups())
}
