import React from 'react';
import createClass from 'create-react-class';
import { connect } from 'react-redux';
import {
  addRow,
  fetchSuppliers,
  fetchGeo,
  fetchSpecSheets,
  uploadUnitImages,
  deleteUnitImage
} from './actions';
import { Modal } from 'react-bootstrap';
import UnitForm from './Unit/UnitForm';
import { required } from '../../utils/validation';
import GlobalActions from '../../actions/GlobalActions';

class NewUnitModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const { fetchSuppliers, fetchGeo, fetchSpecSheets } = this.props;
    fetchSuppliers();
    fetchGeo();
    fetchSpecSheets();
  }

  onSubmit(values) {
    const { addRow, onHide } = this.props;
    addRow(values);
    onHide();
  }

  render() {
    const {
      show, onHide, suppliers, geo, specSheets, currentUser, supplierToken
    } = this.props;

    return (
      <Modal show={show} bsSize="large" animation={false}>
        <Modal.Header className="clearfix">
          <Modal.Title>{`Create New Unit`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="clearfix">
          <UnitForm
            specSheets={specSheets || []}
            geoJsons={geo || []}
            unit={{}}
            onSubmit={this.onSubmit}
            onHide={onHide}
            saving={false}
            isCreate={true}
            supplierToken={supplierToken}
            suppliers={suppliers || []}
            user={currentUser}
          />
        </Modal.Body>
      </Modal>
    );
  }
};

export default connect(
  ({ inventory, currentUser }) => ({ ...inventory, currentUser }),
  { addRow, fetchSuppliers, fetchGeo, fetchSpecSheets }
)(NewUnitModal)
