import React from 'react';
import moment from 'moment';
import cs from 'classnames';
import momentRange from 'moment-range';
import accounting from 'accounting';
import createClass from 'create-react-class';
import { get } from '../../utils/api';

export default createClass({
  displayName: 'CampaignInstagram',

  getInitialState() {
    return ({
      data: [],
      hashtags: [],
      selectedHashtag: {}
    });
  },

  componentDidMount() {
    this.loadData()
  },

  async loadData() {
    const { campaign } = this.props
    const { data } = await get(`/api/v1/campaigns/${campaign.token}/instagram_photos`)
    this.setState({ data: _.uniqBy(data, 'code') })
    this.getHashTags()
  },

  getHashTags() {
    const { data } = this.state
    const hashtags =_.flow(
      info => info.map(i => i.hashtag),
      info => _.uniq(info)
    )(data)
    this.setState({ hashtags })
  },

  selectHashtag(hashtag) {
    this.setState({ selectedHashtag: hashtag })
  },

  renderHashTags() {
    const { hashtags, selectedHashtag } = this.state
    const setAllTags = () => {
      this.setState({ selectedHashtag: {} })
    };
    return (
      <ul className="hashtags_container">
        <li onClick={setAllTags} className={cs({ active: _.isEmpty(selectedHashtag) })}>All hashtags</li>
        {hashtags.map((tag, idx) => <li className={cs({ active: selectedHashtag == tag })} onClick={() => this.selectHashtag(tag)} key={`tag-${tag}-${idx}`}>{`#${tag}`}</li>)}
      </ul>
    )
  },

  renderAnalyticsOverview() {
    const posts = this.filterByHashtag()
    const post_count = posts.length
    const reach =  this.getReach(posts)
    const media_value = 0
    const like_count = this.getTotalLikes(posts)
    const comments_count = this.getTotalComments(posts)
    return { post_count, reach, media_value, like_count, comments_count }
  },

  filterByHashtag() {
    const { data, selectedHashtag } = this.state
    if(_.isEmpty(selectedHashtag)) { return data }
    return _.filter(data, post => post.hashtag == selectedHashtag)
  },

  getTotalLikes(data) {
    return data.reduce((acc, value) => { return acc + value['likes_count']}, 0)
  },

  getTotalComments(data) {
    return data.reduce((acc, value) => { return acc + value['comments_count']}, 0)
  },

  getReach(data) {
    return data.reduce((acc, value) => { return acc + value['liker_average_follow_count']}, 0)
  },

  renderTopPosts() {
    const posts = this.filterByHashtag()
    const top_posts = _.flow(
      p => _.orderBy(p, ['likes_count'], ['desc']),
      p => _.slice(p, 0, 4)
      )(posts)
    return top_posts.map((post, idx) => {
      return (
        <div key={`${post['hashtag']}-post-${idx}`} className="instagram__post_card">
          <div className="head">
            <div onClick={this.goToPostPage.bind(this, post.id)} className="user">{`@${post['user']}`}</div>
            <div className="date">{`${this.getDiffPostedAt(post)} days ago`}</div>
          </div>
          <div className="image">
            <img src={post.image_url} />
          </div>
          <div
            className="summary"
            style={{width: "auto", margin:0}}
          >
            <div className="likes">
              <i className="fa fa-heart"></i>
              {post['likes_count']}
            </div>
            <div className="comment">
              <i className="fa fa-comment"></i>
              {post['comments_count']}
            </div>
            <div className="shares">
              <i className="fa fa-users"></i>
              {`${accounting.format(post['liker_average_follow_count'])}`}
            </div>
          </div>
          {!_.isEmpty(post['comments']) &&
            this.renderTopPhotosComments(post['comments'])
          }
        </div>
      )
    })
  },

  renderTopPhotosComments(comments) {
    if (_.isEmpty(comments)) { return }
    const commentsSample = _.slice(comments, 0, 5)
    return commentsSample.map((comment, idx) => {
      return (
        <div  key={`${comment.posted_by}-${idx}`} className="comments">
          <span className="user">{comment.posted_by}</span>
          <span className="comment">{comment.comment}</span>
        </div>
      )
    })
  },

  getDiffPostedAt({ posted_at }) {
    const now = moment()
    const posted = moment(posted_at)
    return now.diff(posted, 'days')
  },

  goToPostPage(post_id) {
    const current_post = this.state.data.find(p => p.id == post_id)
    window.open(`https://www.instagram.com/p/${current_post['code']}`, '_blank')
  },

  returnNewStateWithUpdatedCard(card) {
    const { data } = this.state
    return data.reduce((acc, value) => {
      if (value.id == card.id) {
        acc.push(card)
      }
      else {
        acc.push(value)
      }
      return acc
    }, [])
  },

  renderGalleryCards() {
    const posts = this.filterByHashtag()
    const onHover = (post_id) => {
      const current_post = this.state.data.find(p => p.id == post_id)
      current_post.show_hover = true
      this.setState({ data: this.returnNewStateWithUpdatedCard(current_post) })
    }
    const onHoverLeave = (post_id) => {
      const current_post = this.state.data.find(p => p.id == post_id)
      current_post.show_hover = false
      this.setState({ data: this.returnNewStateWithUpdatedCard(current_post) })
    }
    return posts.map((post,idx) => {
      return (
        <div 
          key={`${post['hashtag']}-image-${idx}`}
          className="instagram_gallery_card"
          onMouseEnter={onHover.bind(this, post.id)}
          onMouseLeave={onHoverLeave.bind(this, post.id)}
        >
          {post['show_hover'] && <div onClick={this.goToPostPage.bind(this, post.id)} className="hover_card">
            <div className="hover_card_header">
              <div>
                <p>{`@${post['user']}`}</p>
                <p className="hover_card_header_date">{`${this.getDiffPostedAt(post)} days ago`}</p>
              </div>
              <i className="fa fa-external-link"></i>
            </div>
            <div className="hover_card_data">
              <div className="like">
                <i className="fa fa-heart"></i>
                {accounting.format(post['likes_count'])}
              </div>
              <div className="comment">
                <i className="fa fa-comment"></i>
                {post['comments_count']}
              </div>
              <div className="shares">
                <i className="fa fa-users"></i>
                {`${accounting.format(post['liker_average_follow_count'])}`}
              </div>
            </div>
          </div>}
          <img src={post['image_url']} />
        </div>
      )
    })
  },

  render() {
    const { post_count, reach, media_value, like_count, comments_count } = this.renderAnalyticsOverview()
    return (
      <div className="instagram_container">
        <div className="instagram__header">
          <div className="section_container">
            <h2>Analytics Report</h2>
            <h3 className="title">Instagram Image Recognition</h3>
            <hr />
            <div className="instagram__hastags">
              <div className="title_container">
                <h4 className="title">
                  <i className="fa fa-hashtag"></i>
                  Hashtags
                </h4>
                <p className="info">Click to filter results by tag</p>
              </div>
              {this.renderHashTags()}
            </div>
          </div>
        </div>
        <div className="section_container">
          <div className="instagram__analytics_overview">
            <div className="title_container">
              <h4 className="title">
                <i className="fa fa-pie-chart"></i>
                Analytics Overview
              </h4>
            </div>
            <div className="overview_container">
              <div className="instagram_overview_card posts">
                <div className="icon">
                  <i className="fa fa-instagram "></i>
                </div>
                <div className="info">{accounting.format(post_count)}</div>
                <div className="card_name">Posts Found</div>
              </div>
              <div className="instagram_overview_card reach">
                <div className="icon">
                  <i className="fa fa-users"></i>
                </div>
                <div className="info">{accounting.format(reach)}</div>
                <div className="card_name">Follower Reach</div>
              </div>
              {/* <div className="instagram_overview_card media_value">
                <div className="icon">
                  <i className="fa fa-chevron-up"></i>
                </div>
                <div className="info">{media_value}</div>
                <div className="card_name">Est. Earned Media Value</div>
              </div> */}
              <div className="instagram_overview_card likes">
                <div className="icon">
                  <i className="fa fa-heart"></i>
                </div>
                <div className="info">{accounting.format(like_count)}</div>
                <div className="card_name">Likes</div>
              </div>
              <div className="instagram_overview_card comments">
                <div className="icon">
                  <i className="fa fa-comment"></i>
                </div>
                <div className="info">{accounting.format(comments_count)}</div>
                <div className="card_name">Comments</div>
              </div> 
            </div>
          </div>
          <div className="instagram__top_photos">
            <div className="title_container">
              <h4 className="title">
                <i className="fa fa-line-chart"></i>
                Top Posts
              </h4>
            </div>
            <div className="top_posts_container">
              {this.renderTopPosts()}
            </div>
          </div>
          <div className="instagram__gallery">
            <div className="title_container">
              <h4 className="title">
                <i className="fa fa-image"></i>
                Gallery
              </h4>
            </div>
            <div className="instagram_gallery_container">
              {this.renderGalleryCards()}
            </div>
          </div>
        </div>
      </div>
    );
  },
});


