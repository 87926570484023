import React, { Component } from "react";
import { connect } from "react-redux";
import cs from "classnames";
import isEmpty from "lodash/isEmpty";
import PlacesStore from "../../stores/PlacesStore";

const POPUP_WIDTH = 220;

class PoiFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      selectedPois: this.selectedPoisFromInitialValue()
    }
    this.onToggle = this.onToggle.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillMount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target) || this.node == event.target;
    if (!isExpanded && clickedWithinElement) { return this.setState({ isExpanded: true }); }
    return this.setState({ isExpanded: false });
  }

  closePopup(e) {
    e.preventDefault();
    this.setState({ isExpanded: false});
  }

  selectedPoisFromInitialValue() {
    const { initialValue } = this.props;
    const pois = this.uniqPoisByName();
    return pois.filter(p => initialValue.includes(p));
  }

  buttonLabel() {
    const { selectedPois } = this.state;
    if(isEmpty(selectedPois)) { return "Pois"; }
    if(selectedPois.length == 1) { return selectedPois[0]; }
    if(selectedPois.length > 1) { return `Pois · ${selectedPois.length}`; }
  }

  uniqPoisByName() {
    const { pois } = this.props
    return [...new Set(pois.map(p => p.name))].sort()
  }

  renderPopup() {
    const pois = this.uniqPoisByName()
    return (
      <div className="filter_popup poi_popup" style={{left: '-1px'}}>
        <h4>POIS</h4>
        <ul className="poi_list">
          {pois.map(poi => this.renderPoiItem(poi))}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.closePopup}>Done</a>
          </p>
        </div>
      </div>
    )
  }

  renderPoiItem(poi) {
    const isSelected = this.isPoiSelected(poi);
    const parsedName = poi.toLowerCase().split(" ").join("-")
    return (
      <li
        key={`${parsedName}_${Math.floor(Math.random() * 10000)}`}
        onClick={() => this.onSelect(poi)}
        className={cs({ active: isSelected })}
      >
        {poi}
        <i className="fa fa-check"></i>
      </li>
    )
  }

  isPoiSelected(poi) {
    const { selectedPois } = this.state;
    return !!selectedPois.find(p => p == poi);
  }

  onSelect(poi) {
    const { selectedPois } = this.state;
    const isSelected = this.isPoiSelected(poi);
    if (isSelected) { return this.updateSelectedPois(selectedPois.filter(p => p != poi)) }
    return this.updateSelectedPois([...selectedPois, poi]);
  }

  updateSelectedPois(selectedPois) {
    return this.setState({ selectedPois }, () => this.runFilters());
  }

  runFilters() {
    this.props.filterPlaces(this.state.selectedPois.map(p => p))
    PlacesStore.onFilterByLabel();
  }

  render() {
    return (
      <li
        ref={node => (this.node = node)}
        className={cs({ active: this.props.isActive })}
      >
        {this.buttonLabel()}
        {this.state.isExpanded && this.renderPopup()}
      </li>
    )
  }
}

const mapStateToProps = ({ places_ui }) => ({
  pois: places_ui.places
});

export default connect(mapStateToProps)(PoiFilter);