import { browserHistory } from 'react-router-legacy';
import { formatMoney, formatNumber, unformat } from 'accounting';

import { STEPS } from './reducer';
import { budgetByImpressions, impressionsByBudget } from './showing_level';
import { get } from "../../utils/api";

export const TOGGLE_MEDIA_TYPE = 'PLANNER.TOGGLE-MEDIA-TYPE';
export const toggleMediaType = (media_type) => (dispatch) => {
  dispatch({ type: TOGGLE_MEDIA_TYPE, payload: { media_type } });
}

export const TOGGLE_OBJECTIVE = 'PLANNER.TOGGLE-OBJECTIVE';
export const toggleObjective = (objective) => (dispatch) => {
  dispatch({ type: TOGGLE_OBJECTIVE, payload: { objective } });
}

export const SAVE_PLAN = 'PLANNER.SAVE_PLAN';
export const savePlan = (redirect = true) => async (dispatch, getState) => {
  const {
    token,
    selected_objectives,
    selected_media_types,
    selected_demographics,
    spend_groups
  } = getState().planner;

  const demographic_ids = Object.values(selected_demographics).map(d => d.id);

  const { plan, errors } = await $.post('/api/v1/planners', {
    token,
    demographic_ids,
    objectives: selected_objectives,
    media_types: selected_media_types
  });

  if (spend_groups.length > 0) {
    dispatch(loadSpendGroups())
  }

  if (errors) {
    console.error(errors);
  } else if (plan) {
    if(redirect) { browserHistory.push(`/old_planner/${plan.token}`); }
    dispatch({ type: SAVE_PLAN, payload: { saving: false, token: plan.token } });
  }
}

export const LOAD_SPEND_GROUPS = 'PLANNER.LOAD_SPEND_GROUPS';
export const loadSpendGroups = () => async (dispatch, getState) => {
  const { token } = getState().planner;
  const { spend_groups } = await $.get(`/api/v1/planners/${token}/spend_groups`)
  dispatch({ type: LOAD_SPEND_GROUPS, payload: { spend_groups } })
}

export const LOAD_PLAN = 'PLANNER.LOAD_PLAN';
export const loadPlan = (token) => async (dispatch) => {
  const { plan } = await $.get(`/api/v1/planners/${token}`);
  dispatch({ type: LOAD_PLAN, payload: { plan } });
}

export const demographicIDs = (selected_demographics) => {
  if ( !!!selected_demographics ) return;
  return Object.values(selected_demographics).map( ({ id }) => id );
}

export const FETCH_DEMOGRAPHICS = 'PLANNER.FETCH_DEMOGRAPHICS';
export const fetchDemographics = () => async (dispatch) => {
  const { demographics } = await get(
    `/api/v1/demographics`,
    null,
    {source: "census", group_by: "topic"}
  );
  dispatch({ type: FETCH_DEMOGRAPHICS, payload: { demographics } });
}

export const UPDATE_SELECTED_DEMOGRAPHICS = 'PLANNER.UPDATE_SELECTED_DEMOGRAPHICS';
export const updateSelectedDemographics = (topic, demographic) => (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_DEMOGRAPHICS, payload: { topic, demographic } });
}

export const FETCH_GEO_RECOMMENDATIONS = 'PLANNER.FETCH_GEO_RECOMMENDATIONS';
export const fetchGeoRecommendations = ({ location_id } = {}) => async (dispatch, getState) => {
  const demographic_ids = demographicIDs(getState().planner.selected_demographics);
  if (!demographic_ids) return;
  const { recommendations } = await $.get(
    '/api/v1/geo_recommendations',
    { demographic_ids, location_id }
  );
  dispatch({ type: FETCH_GEO_RECOMMENDATIONS, payload: { recommendations } });
}

export const UPDATE_GEOGRAPHIES = 'PLANNER.UPDATE_GEOGRAPHIES';
export const updateGeographies = (geography_filter) => async (dispatch, getState) => {
  const { geographies } = await $.get('/api/v1/geographies', { geography: geography_filter });

  dispatch({ type: UPDATE_GEOGRAPHIES, payload: { geographies } });
  return new Promise((resolve, reject) => resolve(geographies))
}

export const UPDATE_LOCATION_FILTER = 'PLANNER.UPDATE_LOCATION_FILTER';
export const updateLocationFilter = (location_filter) => (dispatch) => {
  dispatch({ type: UPDATE_LOCATION_FILTER, payload: { location_filter } });
}

export const UPDATE_LAST_TYPE_AT = 'PLANNER.UPDATE_LAST_TYPE_AT';
export const updateLastTypedAt = (last_typed_at = Date.now()) => (dispatch) => {
  dispatch({ type: UPDATE_LAST_TYPE_AT, payload: { last_typed_at } });
}

export const TOGGLE_SPEND_GROUP_TO_PLAN = 'PLANNER.TOGGLE_SPEND_GROUP_TO_PLAN';
export const toggleSpendGroup = (location_id) => async (dispatch, getState) => {
  const { token } = getState().planner;
  const { spend_group } = await $.post(`/api/v1/planners/${token}/spend_groups`, { location_id });

  dispatch({ type: TOGGLE_SPEND_GROUP_TO_PLAN, payload: { spend_group } });
}

export const TOGGLE_ADD_TO_CAMPAIGN = 'PLANNER.TOGGLE_ADD_TO_CAMPAIGN';
export const toggleAddToCampaign = () => (dispatch, getState) => {
  const { add_to_campaign } = getState().planner;
  dispatch({ type: TOGGLE_ADD_TO_CAMPAIGN, payload: { add_to_campaign: !add_to_campaign } });
}

export const formattedToValue = (formatted_value) => {
  return parseFloat(unformat((formatted_value || 0).toString().split(' ')[0]));
}

export const UPDATE_SPEND_GROUP = 'PLANNER.UPDATE_SPEND_GROUP';
export const updateSpendXImpressions = ({id, spend, target_impressions}) => (dispatch, getState) => {
  if (!spend && !target_impressions) { return }
  const { planner: { spend_groups } } = getState();
  const old_spend_group = spend_groups.find(s => s.id == id);
  if (!old_spend_group) return;

  const { cpm } = old_spend_group.location;

  if (spend) {
    spend = spend;
    // if (isNaN(spend)) return;
    target_impressions = impressionsByBudget(cpm, spend);
  } else if (target_impressions) {
    target_impressions = target_impressions;
    // if (isNaN(target_impressions)) return;
    spend = budgetByImpressions(cpm, target_impressions);
  }
  const spend_group = { ...old_spend_group, spend, target_impressions };
  dispatch({ type: UPDATE_SPEND_GROUP, payload: { spend_group } });
}

export const updateAddedLocations = ({id, added_location_ids}) => (dispatch, getState) => {
  const { planner: { spend_groups } } = getState()
  const old_spend_group = spend_groups.find(s => s.id == id)
  const spend_group = { ...old_spend_group, added_location_ids }
  dispatch({ type: UPDATE_SPEND_GROUP, payload: { spend_group } })
}

export const formatSendXImpressions = ({id}) => (dispatch, getState) => {
  const { planner: { spend_groups, last_typed_at, token } } = getState();

  if (last_typed_at && (Date.now() - last_typed_at) < 900) return;

  const old_spend_group = spend_groups.find(s => s.id == id);
  if (!old_spend_group) return;
  const { spend, target_impressions } = old_spend_group;

  const spend_group = {
    ...old_spend_group,
    spend: spend,
    target_impressions: target_impressions,
  }
  dispatch({ type: UPDATE_SPEND_GROUP, payload: { spend_group } });

}

export const saveSpendGroup = ({id}) => (dispatch, getState) => {
  const { planner: { spend_groups, last_typed_at, token } } = getState();
  if ((Date.now() - last_typed_at) < 900) return;

  const spend_group = spend_groups.find(g => g.id === id);
  $.ajax(
    `/api/v1/planners/${token}/spend_groups/${spend_group.id}`,
    {
      data: { spend_group },
      method: 'PUT'
    });
}

export const TOGGLE_ADD_SPEND_GROUP_TO_CAMPAIGN = 'PLANNER.TOGGLE_ADD_SPEND_GROUP_TO_CAMPAIGN';
export const toggleAddSpendGroupToCampaign = (spend_group_to_add) => (dispatch) => {
  dispatch({
    type: TOGGLE_ADD_SPEND_GROUP_TO_CAMPAIGN,
    payload: {
      saving_spend_group_to_campaign: false,
      spend_group_added_to_campaign: false,
      campaign: '',
      new_campaign_name: '',
      spend_group_to_add
    }
  });
}

export const UPDATE_CAMPAIGN_START_DATE = 'PLANNER.UPDATE_CAMPAIGN_START_DATE';
export const updateCampaignStartDate = (campaign_start_date) => (dispatch) => {
  dispatch({ type: UPDATE_CAMPAIGN_START_DATE, payload: { campaign_start_date } });
}

export const UPDATE_CAMPAIGN_END_DATE = 'PLANNER.UPDATE_CAMPAIGN_END_DATE';
export const updateCampaignEndDate = (campaign_end_date) => (dispatch) => {
  dispatch({ type: UPDATE_CAMPAIGN_END_DATE, payload: { campaign_end_date } });
}

export const UPDATE_NEW_CAMPAIGN_NAME = 'PLANNER.UPDATE_NEW_CAMPAIGN_NAME';
export const updateNewCampaignName = (new_campaign_name) => (dispatch) => {
  dispatch({ type: UPDATE_NEW_CAMPAIGN_NAME, payload: { new_campaign_name } });
}

export const SELECT_CAMPAIGN = 'PLANNER.SELECT_CAMPAIGN';
export const selectCampaign = (campaign) => (dispatch) => {
  dispatch({ type: SELECT_CAMPAIGN, payload: { campaign } });
}

export const ADD_SPEND_GROUP_TO_CAMPAIGN = 'PLANNER.ADD_SPEND_GROUP_TO_CAMPAIGN';
export const addSpendGroupToCampaign = (new_campaign_name) => async (dispatch, getState) => {
  dispatch({ type: ADD_SPEND_GROUP_TO_CAMPAIGN, payload: { saving_spend_group_to_campaign: true } });
  
  const { planner: { spend_groups, selected_demographics, campaign_start_date, campaign_end_date, add_to_campaign } } = getState(); 
  const spend_groups_ids = spend_groups.map((item) => item.id)
  const demographic_ids = Object.values(selected_demographics).map(d => d.id);
  const campaign_token = getState().planner.campaign;
  const campaign_name = add_to_campaign ? '' : new_campaign_name;

  const { campaign, job_ids } = await $.post(
    '/api/v1/geo_recommendations',
    { demographic_ids, campaign_name, spend_group_ids: spend_groups_ids , campaign_start_date, campaign_end_date, campaign_token}
  );
  dispatch({ type: ADD_SPEND_GROUP_TO_CAMPAIGN, payload: { spend_group_added_to_campaign: true, campaign: campaign, job_ids } });
}

export const SET_STEP = 'PLANNER.SET_STEP';
export const showBasics = () => (dispatch) => {
  dispatch({ type: SET_STEP, payload: { step: STEPS.basics } });
}
export const showDemographics = () => (dispatch) => {
  dispatch({ type: SET_STEP, payload: { step: STEPS.demographics } });
}
export const showGeography = () => (dispatch) => {
  dispatch({ type: SET_STEP, payload: { step: STEPS.geography } });
}
export const showRecommendations = () => (dispatch) => {
  dispatch({ type: SET_STEP, payload: { step: STEPS.recommendations } });
}
export const showBudget = () => (dispatch) => {
  dispatch({ type: SET_STEP, payload: { step: STEPS.budget } });
}
