import queryString from "query-string";
import moment from "moment";
import _ from 'lodash';

import flightReducer from "./Flight/reducer";
import unitReducer from "./Unit/reducer";

import {
  SET_FILTERS,
  FILTER_BY_FACE_IDS,
  FILTER_BY_FACE_IDS_SUCCESS,
  FETCH_ROWS,
  FETCH_ROWS_SUCCESS,
  ADD_DEFAULT,
  ADD_DEFAULT_SUCCESS,
  ADD_ROW,
  ADD_ROW_SUCCESS,
  FETCH_SUPPLIERS,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_SALES_USERS,
  FETCH_SALES_USERS_SUCCESS,
  FETCH_GEO,
  FETCH_GEO_SUCCESS,
  FETCH_SPEC_SUCCESS,
  SHOW_MODAL,
  HIDE_MODAL,
  UPLOAD_IMAGE_SUCCESS,
  DELETE_IMAGE_SUCCESS,
  ADD_TO_CAMPAIGN,
  ADD_CAMPAIGN_SUCCESS,
  ADD_TO_CAMPAIGN_SUCCESS,
  FETCH_MORE_SUCCESS,
  FETCH_ALL,
  FETCH_ALL_SUCCESS,
  FETCH_DEFAULT_SUCCESS,
  FETCH_FLIGHT_TYPES,
  FETCH_FLIGHT_TYPES_SUCCESS,
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CONTRACTS_SUCCESS,
  DESTROY_CONTRACT_SUCCESS,
  ADD_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_SUCCESS,
  CONTRACT_CAMPAIGN_SUCCESS,
  CONTRACT_CAMPAIGN_RESOLVE,
  SELECT_CAMPAIGN,
  FETCH_DEFAULT,
  REMOVE_UNIT_SUCCESS,
  CLEAR_ORIENTATIONS,
  FETCH_ORIENTATIONS,
  FETCH_ORIENTATIONS_SUCCESS,
  FETCH_SIZES,
  FETCH_SIZES_SUCCESS
} from "./actions";

const initialState = {
  filters: {
    mode: "timeline",
    interval: "week",
    limit: 15,
    offset: 0
  },
  units: [],
  campaigns: [],
  suppliers: [],
  advertisers: [],
  selected_units: [],
  conflicts: [],
  campaign_units_added: [],
  sales_users: [],
  sales_user_id: null,
  isFetching: true,
  modal: null,
  selectedCampaignId: null,
  tags_loading: false,
  unit_tags: []
};

function actionIsValid(state, timestamp) {
  return timestamp >= state.timestamp;
}

export default (state = initialState, action) => {
  // check for actions in other reducers
  const flight_state = flightReducer(state, action);
  if (flight_state) return flight_state;

  const unit_state = unitReducer(state, action);
  if (unit_state) return unit_state;

  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload.filters
        }
      };
    case ADD_DEFAULT:
    case FETCH_DEFAULT:
    case FETCH_ROWS:
      return {
        ...state,
        isFetchingUnits: true,
        timestamp: action.payload.timestamp
      }
    case FILTER_BY_FACE_IDS:
    case ADD_TO_CAMPAIGN:
    case FETCH_CAMPAIGNS:
    case FETCH_ALL:
    case FETCH_ORIENTATIONS:
    case FETCH_SIZES:
    case FETCH_FLIGHT_TYPES:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_ROWS_SUCCESS:
      if (!!!state.timestamp || actionIsValid(state, action.payload.timestamp)) {
        return {
          ...state,
          ...action.payload,
          isFetchingUnits: false
        }
      } else {
        return state;
      }
    case FILTER_BY_FACE_IDS_SUCCESS:
    case ADD_TO_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false
      };
    case ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        campaign: action.payload,
        campaigns: [...state.campaigns, action.payload],
        selectedCampaignId: action.payload.id
      };
    case ADD_DEFAULT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload
      };
    case SELECT_CAMPAIGN:
      return {
        ...state,
        selectedCampaignId: action.payload.selectedCampaignId
      };
    case FETCH_DEFAULT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false
      };
    case FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: [...action.payload],
        isFetching: false
      };
    case FETCH_SALES_USERS_SUCCESS:
      return {
        ...state,
        sales_users: [...action.payload],
        isFetching: false
      };
    case FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false
      };
    case ADD_ROW_SUCCESS:
      return {
        ...state,
        results: [...state.results, action.payload],
        isFetching: false
      };
    case FETCH_SUPPLIERS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_SALES_USERS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_GEO_SUCCESS:
      return {
        ...state,
        geo: action.payload,
        isFetching: false
      };
    case FETCH_SPEC_SUCCESS:
      return {
        ...state,
        specSheets: action.payload,
        isFetching: false
      };
    case FETCH_FLIGHT_TYPES_SUCCESS:
      return {
        ...state,
        flightTypes: action.payload.flight_types,
        isFetching: false
      };
    case CLEAR_ORIENTATIONS:
      return {
        ...state,
        orientations: []
      };
    case FETCH_ORIENTATIONS_SUCCESS:
      return {
        ...state,
        orientations: action.payload.orientations,
        isFetching: false
      };
    case FETCH_SIZES_SUCCESS:
      return {
        ...state,
        sizes: action.payload.sizes,
        isFetching: false
      };
    case FETCH_MORE_SUCCESS:
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        units: state.units.concat(action.payload.units)
      };
    case SHOW_MODAL:
      return {
        ...state,
        modal: action.payload
      };
    case HIDE_MODAL:
      return {
        ...state,
        modal: null
      };
    case UPLOAD_IMAGE_SUCCESS:
      if(!state.modal) { return { ...state }; }
      return {
        ...state,
        modal: {
          ...state.modal,
          unit: {
            ...state.modal.unit,
            images: state.modal.unit.images.concat(action.payload.unitImage)
          }
        }
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          unit: {
            ...state.modal.unit,
            images: state.modal.unit.images.filter(image => image.id != action.payload.unitImageId)
          }
        }
      };
    case FETCH_CONTRACTS_SUCCESS: {
      const { contracts } = action.payload;
      return {
        ...state,
        contracts,
        destroyed_contract_id: null,
        updated_contract_id: null,
        added_contract_id: null,
      };
    }
    case DESTROY_CONTRACT_SUCCESS: {
      const { id } = action.payload;
      const contracts = state.contracts.filter(contract => contract.id !== id);
      return {
        ...state,
        contracts,
        destroyed_contract_id: id,
      };
    }
    case ADD_CONTRACT_SUCCESS: {
      const { contract } = action.payload;
      return {
        ...state,
        added_contract_id: contract.id,
        destroyed_contract_id: null,
        updated_contract_id: null
      };
    }
    case UPDATE_CONTRACT_SUCCESS: {
      const { contract } = action.payload;
      const contracts = state.contracts.filter(_contract => _contract.id !== contract.id) || [];
      return {
        ...state,
        contracts: [...contracts, { ...contract }],
        added_contract_id: null,
        destroyed_contract_id: null,
        updated_contract_id: contract.id,
      };
    }
    case CONTRACT_CAMPAIGN_SUCCESS: {
      const { contract } = action.payload;
      const contracts = state.contracts.filter(_contract => _contract.id !== contract.id);
      return {
        ...state,
        contracts: [...contracts, { ...contract, discrepancies: null }],
        updated_contract_id: contract.id,
        destroyed_contract_id: null,
        added_contract_id: null,
      };
    }
    case REMOVE_UNIT_SUCCESS: {
      const units = state.units.filter(unit => unit.id !== action.payload.id);
      return {
        ...state,
        units
      };
    }
    case "FETCH_DEFAULT_BACKGROUND":
      return { ...state };
    case "FETCH_DEFAULT_BACKGROUND_SUCCESS":
      return { ...state, ...action.payload };
    case "FETCH_OPEN_USER_SUPPLIER_TASKS":
      return { ...state, supplier_tasks: action.payload.supplier_tasks };
    default:
      return state;
  }
};
