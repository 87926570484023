import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";
import CheckboxFilter from "../CheckboxFilter";

const POPUP_WIDTH = 400;

class MoreFiltersList extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = {
      isExpanded: false,
      selectedFilters: this.props.initialValue,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, initialValue } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (initialValue !== prevProps.initialValue) { this.setState({ selectedFilters: initialValue })}
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onCheckboxSelect(name, checked) {
    this.setState(
      prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          [name]: checked,
        },
      }),
      this.onFilter,
    );
  }

  onFilter() {
    const { selectedFilters } = this.state;
    const { filterUnits, handleQueryParams } = this.props;

    _.defer(filterUnits, selectedFilters);
    handleQueryParams(selectedFilters)
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  onClear() {
    this.setState({ selectedFilters: this.props.initialValue }, this.onFilter);
  }

  getButtonLabel() {
    return `${'\uf142'}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { selectedFilters } = this.state;
    const { currentUser, campaign, isBrowseView } = this.props;
    const isAdmin = currentUser && currentUser.is_admin;
    const isAgency = currentUser && currentUser.is_agency;
    const isSupplier = currentUser && currentUser.is_supplier;
    const isCampaignView = !isBrowseView;

    return (
      <div className="filter_popup more_filters_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <div>
          <div>
            <h4>Pricing</h4>
            <div className="checkbox_group">
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="With pricing only"
                attributeName="only_units_with_pricing"
              />
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="Without pricing only"
                attributeName="only_units_without_pricing"
              />
              {isAdmin &&
                <CheckboxFilter
                  filters={selectedFilters}
                  setFilter={this.onCheckboxSelect}
                  label="Price greater than rate card"
                  attributeName="price_greater_than_rate_card"
                />
              }
            </div>
            <hr />
          </div>
          <div>
            <h4>Photos</h4>
            <div className="checkbox_group">
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="With photos only"
                attributeName="only_units_with_picture"
              />
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="Without photos only"
                attributeName="only_units_without_picture"
              />
            </div>
            <hr />
          </div>
          <div>
            <h4>Packages</h4>
            <div className="checkbox_group">
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="Packages only"
                attributeName="packages_only"
              />
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="Exclude packages"
                attributeName="exclude_packages"
              />
            </div>
            <hr />
          </div>
        </div>
        {(isAdmin || isAgency) &&
          <div>
            <h4>Vendor Related</h4>
            <div className="checkbox_group">
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="Managed vendors only"
                attributeName="has_managed_supplier"
              />
              {(isAdmin) && (
                <CheckboxFilter
                  filters={selectedFilters}
                  setFilter={this.onCheckboxSelect}
                  label="Has margin agreement only"
                  attributeName="has_margin_agreement"
                />
              )}
              {(isAdmin) && (
                <CheckboxFilter
                  filters={selectedFilters}
                  setFilter={this.onCheckboxSelect}
                  label="Instant Book only"
                  attributeName="instant_book"
                />
              )}
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="Broker vendors only"
                attributeName="has_broker_supplier"
              />
              <CheckboxFilter
                filters={selectedFilters}
                setFilter={this.onCheckboxSelect}
                label="Exclude Broker vendors"
                attributeName="has_not_broker_supplier"
              />
            </div>
            <hr />
          </div>
        }
        <div>
          <h4>Attribution</h4>
          <div className="checkbox_group">
            <CheckboxFilter
              filters={selectedFilters}
              setFilter={this.onCheckboxSelect}
              label="Has attribution"
              attributeName="has_attribution"
            />
            <CheckboxFilter
              filters={selectedFilters}
              setFilter={this.onCheckboxSelect}
              label="No attribution"
              attributeName="no_attribution"
            />
            <CheckboxFilter
              filters={selectedFilters}
              setFilter={this.onCheckboxSelect}
              label="Unknown attribution"
              attributeName="unknown_attribution"
            />
            <CheckboxFilter
              filters={selectedFilters}
              setFilter={this.onCheckboxSelect}
              label="AdQuick-verified attribution"
              attributeName="aq_verified_attribution"
            />
          </div>
          <hr />
        </div>

        <h4>Other</h4>
        <div className="checkbox_group">
          {isCampaignView && (
            <CheckboxFilter
              filters={selectedFilters}
              setFilter={this.onCheckboxSelect}
              label="Without tags only"
              attributeName="only_units_without_tags"
            />
          )}
          <CheckboxFilter
            filters={selectedFilters}
            setFilter={this.onCheckboxSelect}
            label="Cannabis friendly only"
            attributeName="only_cannabis_friendly"
          />
          {!isSupplier && isCampaignView && (
            <CheckboxFilter
              filters={selectedFilters}
              setFilter={this.onCheckboxSelect}
              label="On hold only"
              attributeName="on_hold"
            />
          )}
          <CheckboxFilter
            filters={selectedFilters}
            setFilter={this.onCheckboxSelect}
            label="Without restrictions only"
            attributeName="exclude_units_with_restrictions"
          />
          <CheckboxFilter
            filters={selectedFilters}
            setFilter={this.onCheckboxSelect}
            label="With rate card pricing only"
            attributeName="only_units_with_rate_card_pricing"
          />
          {(isAdmin || isAgency) && (
            <CheckboxFilter
              filters={selectedFilters}
              setFilter={this.onCheckboxSelect}
              label="Geopath Audited"
              attributeName="geopath_audited"
            />
          )}
          <CheckboxFilter
            filters={selectedFilters}
            setFilter={this.onCheckboxSelect}
            label="Exclude Favorites"
            attributeName="exclude_favorites"
          />
          <CheckboxFilter
            filters={selectedFilters}
            setFilter={this.onCheckboxSelect}
            label="Exclude Recommended"
            attributeName="exclude_recommended"
          />
        </div>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
     </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ 'more-filter': true, active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ currentUser, campaign }) => ({
  currentUser,
  campaign
});

export default connect(mapStateToProps, { filterUnits })(MoreFiltersList);
