import { get, post, put, patch } from "../../../../utils/api";

export const GET_CAMPAIGN_ANALYTICS = "campaign_analytics/get-campaign-analytics";
export const getCampaignAnalytics = () => async (dispatch, getState) => {
  const { campaign } = getState();
  const res = await get(`/api/v1/campaigns/${campaign.token}/analytics`);
  dispatch(getCampaignAnalyticsSuccess(res));
}

const getCampaignAnalyticsSuccess = ({ campaign_analytics, options, goals }) => dispatch => {
  dispatch({ type: "GET_CAMPAIGN_ANALYTICS_SUCCESS", payload: { campaign_analytics, options, goals } });
}

export const createCampaignAnalytics = (payload) => async (dispatch, getState) => {
  const { campaign } = getState();
  await post(`/api/v1/campaigns/${campaign.token}/analytics`, payload);
  dispatch({ type: "CREATE_CAMPAIGN_ANALYTICS_SUCCESS" });
  dispatch(getCampaignAnalytics());
}

export const createCampaignAnalyticsGoals = (payload) => async (dispatch, getState) => {
  const { campaign } = getState();
  await post(`/api/v1/campaigns/${campaign.token}/analytics/analytics_goals`, payload);
  dispatch({ type: "CREATE_CAMPAIGN_ANALYTICS_GOALS_SUCCESS"});
  dispatch(getCampaignAnalytics());
}

export const completeCampaignAnalytic = ({ analytics_option_id }) => async (dispatch, getState) => {
  const { campaign } = getState();
  await patch(`/api/v1/campaigns/${campaign.token}/analytics/${analytics_option_id}/complete`);
  dispatch({ type: "COMPLETE_CAMPAIGN_ANALYTICS_SUCCESS" });
  dispatch(getCampaignAnalytics());
}

export const updateCampaignAnalytic = ({ analytics_option_id }, payload) => async (dispatch, getState) => {
  const { campaign } = getState();
  await patch(`/api/v1/campaigns/${campaign.token}/analytics/${analytics_option_id}/update`, payload);
  dispatch({ type: "UPDATE_CAMPAIGN_ANALYTICS_SUCCESS" });
  dispatch(getCampaignAnalytics());
}

