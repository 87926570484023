import * as React from "react";
import _ from "lodash";
import Card from "../Components/Card";
import { Header } from "@adquick/ui";
import cs from "classnames";
import { connect } from "react-redux";
import { get, put } from "../../../../utils/api";
import GeoLocatedSurveys from "../CustomSetupPages/GeoLocatedSurveys";
import GeofencedMobileAds from "../CustomSetupPages/GeofencedMobileAds";
import {
  getCampaignAnalytics,
  createCampaignAnalytics,
  completeCampaignAnalytic,
  updateCampaignAnalytic,
  createCampaignAnalyticsGoals,
} from "./actions";

interface ICampaignAnalyticsOption {
  analytics_card_id: string | number;
  analytics_option_id: string | number;
}

interface INewAnalyticsProps {
  getCampaignAnalytics: () => void;
  completeCampaignAnalytic: () => void;
  updateCampaignAnalytic: () => void;
  switchToAnalyticResults: () => void;
  createCampaignAnalyticsGoals: ({ analytics_goal_ids }) => void;
  createCampaignAnalytics: ({ analytics_cards, analytics_goal_ids }) => void;
  campaign: { token: string };
  campaign_analytics: { campaign_analytics: any, goals: any, options: any };
  currentUser: any;
}

class Setup extends React.Component<INewAnalyticsProps, any> {

  public steps = [
    { id: "campaign_goals", order: 0 },
    { id: "choose_options", order: 1 },
    { id: "final_setup", order: 2 },
  ];

  constructor(props) {
    super(props);
    this.state = {
      steps: this.steps,
      currentStep: this.steps[0],
      selectedGoals: [],
      selectedOptions: [],
      goals: [],
      options: [],
      recurrent: false,
      old_analytics: {}
    };
    this.onCheck = this.onCheck.bind(this);
    this.validateCurrentStep = this.validateCurrentStep.bind(this);
    this.addStep = this.addStep.bind(this);
    this.deleteStep = this.deleteStep.bind(this);
    this.oldSaveAnalytics = this.oldSaveAnalytics.bind(this);
  }

  public async componentDidMount() {
    await this.getAnalyticsOptions();
    await this.getOldAnalytics();
    this.recoverFromState();
    this.recoverGoals();
  }

  public async getOldAnalytics() {
    const { campaign } = this.props;
    const old_analytics = await get(`/api/v1/campaigns/${campaign.token}/analytics/old_analytics`);
    this.setState({ old_analytics });
  }

  public async oldSaveAnalytics(payload) {
    const { campaign } = this.props;
    const response = await put(`/api/v1/campaigns/${campaign.token}/analytics`, payload);
    return response;
  }

  public componentDidUpdate(prevProps, prevState) {
    const { steps, currentStep } = this.state;
    const isReturningSetup = !_.isEqual(prevProps.campaign_analytics, this.props.campaign_analytics);
    const didCurrentStepChanged = prevState.currentStep !== currentStep;
    if (isReturningSetup) {
      const { campaign_analytics, goals } = this.props.campaign_analytics
      if(_.isEmpty(campaign_analytics) && !_.isEmpty(goals)) { return this.recoverGoals(); }
      this.recoverFromState();
    }
    if (didCurrentStepChanged) {
      const filteredSteps = steps.filter((step: { id: number }) => step.id !== currentStep.id);
      this.scrollToTop();
      this.setState({ steps: [...filteredSteps, { ...currentStep, visited: true }] });
    }
  }

  public async getAnalyticsOptions() {
    const { goals, options } = await get("/api/v1/analytics/setup");
    this.setState({ goals, options }, this.props.getCampaignAnalytics);
  }

  public onCreateCampaignAnalytics() {
    const { selectedOptions, selectedGoals } = this.state;
    const analytics_goal_ids = selectedGoals.map((goal: { id: number }) => goal.id);
    const mapOptions = (option: any): ICampaignAnalyticsOption => ({
      analytics_card_id: option.id,
      analytics_option_id: option.analytics_option_id,
    });
    const analytics_cards = _.uniqBy(selectedOptions, "key").map(mapOptions);
    this.props.createCampaignAnalytics({ analytics_cards, analytics_goal_ids });
  }

  public onCreateCampaignAnalyticsGoals() {
    const { selectedGoals } = this.state;
    const analytics_goal_ids = selectedGoals.map((goal: { id: number }) => goal.id);
    this.props.createCampaignAnalyticsGoals({ analytics_goal_ids })
  }

  public addStep(step) {
    const { steps } = this.state;
    const stepAlreadyExists = !!steps.find((s: { id: number }) => s.id === step.id);
    if (stepAlreadyExists) {
      return this.goToStep(step);
    }
    this.setState({ steps: [...steps, step] }, () => this.goToStep(step));
  }

  public deleteStep(step) {
    const { steps } = this.state;
    this.setState({ steps: steps.filter((s: { id: number }) => s.id !== step) });
  }

  public recoverGoals() {
    const { goals, campaign_analytics } = this.props.campaign_analytics
    if (_.isEmpty(goals) || !_.isEmpty(campaign_analytics)) { return; }
    const checkedGoals = goals.map((o: any) => this.checkItem(o));
    const filterGoals = (goal) => !checkedGoals.map((g) => g && g.id).includes(goal.id);
    const mapSteps = (s: any) => s.id === "campaign_goals" ? { ...s, visited: true, checked: true } : s;
    this.setState({
      steps: this.state.steps.map(mapSteps),
      goals: [...this.state.goals.filter(filterGoals), ...checkedGoals],
      selectedGoals: checkedGoals,
      recurrent: true,
    }, () => this.goToStep({ id: "choose_options", order: 1 }))
  }

  public recoverFromState() {
    const { campaign_analytics, goals, options } = this.props.campaign_analytics;
    if (_.isEmpty(campaign_analytics)) { return; }
    const checkedGoals = goals.map((o: any) => this.checkItem(o));
    const checkedOptions = options.map((o: any) => this.checkItem(o));
    const mapSteps = (s: any) => s.id === "final_setup" ? s : { ...s, visited: true, checked: true };
    const filterGoals = (goal) => !checkedGoals.map((g) => g && g.id).includes(goal.id);
    const filterOptions = (goal) => !checkedOptions.map((g) => g.id).includes(goal.id);
    this.setState({
      steps: this.state.steps.map(mapSteps),
      goals: [...this.state.goals.filter(filterGoals), ...checkedGoals],
      options: [...this.state.options.filter(filterOptions), ...checkedOptions],
      selectedOptions: checkedOptions,
      selectedGoals: checkedGoals,
      campaignAnalytics: campaign_analytics,
      recurrent: true,
    }, () => this.goToStep({ id: "final_setup", order: 2 }));
  }

  public capitalizedSteps(step) {
    return _.capitalize(_.upperCase(step));
  }

  public checkCurrentStep(step) {
    const { currentStep, steps } = this.state;
    return _.snakeCase(step.id) === currentStep.id;
  }

  public renderBreadcrump() {
    const { steps } = this.state;
    return (
      <div>
        <ol>
          {_.orderBy(steps, "order").map((s, idx) =>
            <li onClick={() => this.goToStep(s)}
              className={cs({ active: this.checkCurrentStep(s), checked: s.checked, visited: s.visited })}
              key={`step-${idx}`}>
              {s.checked && <i className="fa fa-check-circle-o checked"></i>}
              {this.capitalizedSteps(s.id)}
            </li>,
          )}
        </ol>
      </div>
    );
  }

  public goToStep(step) {
    const { recurrent, steps } = this.state;
    if (!step.checked && !recurrent) { return; }
    return this.setState({
      currentStep: step, steps: steps.filter((currentStep) => this.steps.some((s) => s.id === currentStep.id)),
    });
  }

  public checkItem(item) {
    if (!item) { return; }
    if (Array.isArray(item)) { return item.map((i) => Object.assign({}, i, { checked: !i.checked })); }
    return { ...item, checked: !item.checked };
  }

  public onCheck({ id, key }, thing) {
    const toCheck = this.state[thing];
    const selected = thing === "options" ? toCheck.filter((c) => c.key === key) : toCheck.find((c) => c.id === id);
    const updated = this.checkItem(selected);
    if (thing === "options") {
      return this.setState({
        options: toCheck.map((c) => {
          const selectedOption = updated.find((u) => c.id === u.id);
          const match = !!selectedOption;
          return match ? selectedOption : c;
        }),
      }, () => this.selectChecked(updated, "options"));
    }
    this.setState({ [thing]: toCheck.map((c) => c.key === key ? { ...updated } : c) }, () => {
      return this.selectChecked(updated, thing);
    });
  }

  public selectChecked(item, thing) {
    const control = {
      options: "selectedOptions",
      goals: "selectedGoals",
    };

    const currentThing = control[thing];
    const currentState = this.state[currentThing];
    const isAlreadySelected = currentState.find((c) => c.key === (Array.isArray(item) ? item[0].key : item.key));
    if (!!isAlreadySelected) {
      return this.setState({ [currentThing]: currentState.filter((s) => s.key !== isAlreadySelected.key) });
    }
    if (Array.isArray(item)) { return this.setState({ [currentThing]: [...currentState, ...item] }); }
    this.setState({ [currentThing]: [...currentState, item] });
  }

  public nextStep() {
    const { currentStep } = this.state;
    if (currentStep.id === "final_setup") { return; }
    const steps = {
      campaign_goals: { id: "choose_options", order: 1 },
      choose_options: { id: "final_setup", order: 2 },
    };
    return this.setState({
      currentStep: steps[currentStep.id] || { id: "campaign_goals", order: 0 },
    });
  }

  public markCurrentStepAsChecked() {
    const { currentStep, steps } = this.state;
    if(currentStep.id === "campaign_goals") { this.onCreateCampaignAnalyticsGoals(); }
    if (currentStep.id === "choose_options") { this.onCreateCampaignAnalytics(); }
    this.setState({
      steps: steps.map((step: { id: number }) => step.id === currentStep.id ? { ...currentStep, checked: true } : step),
    }, this.nextStep);
  }

  public renderFinalStep() {
    const { currentUser, campaign, campaign_analytics: { campaign_analytics, goals } } = this.props;
    const { selectedOptions, old_analytics } = this.state;
    const clonedOptions = _.cloneDeep(selectedOptions);
    const allOptions = selectedOptions.filter((o) => goals.map((g) => g.id).includes(o.analytics_goal_id));
    const finalOptions = _.uniqBy(clonedOptions.map((o) => _.omit(o, "checked")), "key");
    const groupedOptions = _.groupBy(allOptions, "analytics_option_id");
    const optionsGroupedByCommonGoal = _.groupBy(groupedOptions,
      (o) => _.orderBy(o, "analytics_goal_id").map((p) => p.analytics_goal_id));
    const userPermissions = currentUser.permissions;
    return _.orderBy(Object.keys(optionsGroupedByCommonGoal), (k) => k.length, ["desc"]).map((g) => {
      const optionsGoalsIds = g.toString().split(",");
      const sectionGoalsTitles = optionsGoalsIds.map((id) => goals.find((goal) => goal.id == id)).map((g) => g.text);
      const cards = _.uniqBy(optionsGroupedByCommonGoal[g].flat(), "analytics_option_id")
        .map((o) => o.analytics_option_id)
        // @ts-ignore
        .map((id) => finalOptions.find((f) => f.analytics_option_id == id));

      return (
        <div key={`goal-group${g}}`} className="card_section">
          <div className="body_subtitle">
            <h5 style={{ lineHeight: 1.4 }}>{this.renderFinalStepTitle(sectionGoalsTitles)}</h5>
          </div>
          <div className="cards">
            {_.compact(cards).map((card, idx) => {
              return (
                <Card
                  // @ts-ignore
                  key={`${card.id}-${idx}`}
                  analytics={campaign_analytics}
                  old_analytics={old_analytics}
                  token={campaign.token}
                  card={card}
                  onSelect={() => ({})}
                  finalStep={true}
                  reminder={true}
                  onComplete={this.props.completeCampaignAnalytic}
                  onUpdate={this.props.updateCampaignAnalytic}
                  addStep={this.addStep}
                  userPermissions={userPermissions}
                />
              );
            })}
          </div>
        </div>
      );
    });
  }

  public renderFinalStepTitle(selectedOptionsGoals) {
    const fixedTitle = "Measure lift in";
    const lastItem = selectedOptionsGoals.slice(selectedOptionsGoals.length - 1);
    const items = selectedOptionsGoals.slice(0, selectedOptionsGoals.length - 1);
    const titleFormatByLength = {
      1: `${fixedTitle} ${selectedOptionsGoals[0].toLowerCase()}`,
      2: `${fixedTitle} ${selectedOptionsGoals.join(" and ").toLowerCase()}`,
      other: `${fixedTitle} ${items.join(", ").toLowerCase()} and ${lastItem.toString().toLowerCase()}`,
    };
    const formattedTitle = titleFormatByLength[selectedOptionsGoals.length];
    return formattedTitle || titleFormatByLength.other;
  }

  public renderOptionGroups() {
    const { options, goals, selectedGoals } = this.state;
    const optionsByGoal = options.filter((option) => selectedGoals.map((g) => g.id).includes(option.analytics_goal_id));
    const groupedOptions = _.groupBy(optionsByGoal, "analytics_goal_id");
    const sections = Object.keys(groupedOptions);
    return sections.map((section, i) => {
      return (
        <div key={`analytic_option_${i}`} className="card_section">
          <div className="body_subtitle">
            <h5>{this.renderSectionSubtitle(goals.find((g) => g.id === Number(section)))}</h5>
          </div>
          <div className="cards">
            {_.orderBy(groupedOptions[section], "id").map(
              (o, cardIdx) => <Card key={`${o.id}-${cardIdx}`} card={o} onSelect={this.onCheck} thing="options" />,
            )}
          </div>
        </div>
      );
    });
  }

  public renderSectionSubtitle(section) {
    const sectionSubtitle = {
      web_convertion: "Measure lift in web conversions",
      app_convertion: "Measure lift in app conversions",
      app_install: "Measure lift in app installs",
      brand_awareness: "Measure lift in brand awareness",
      store_visit: "Measure lift in store vists",
      direct_response: "Measure lift in direct responses",
      halo_effect: "Measure the halo effect of digital ads",
      social_media_activity: "Measure lift in social activity",
      other: "Others",
    };
    return sectionSubtitle[section.key];
  }

  public validateCurrentStep() {
    const { currentStep } = this.state;
    const validate = {
      campaign_goals: "selectedGoals",
      choose_options: "selectedOptions",
    };
    const toValidate = validate[currentStep.id];
    if (_.isEmpty(this.state[toValidate])) { return; }
    return this.markCurrentStepAsChecked();
  }

  public proceedStep({ id }) {
    const { selectedGoals, selectedOptions } = this.state;
    if (id === "campaign_goals") { return !_.isEmpty(selectedGoals); }
    if (id === "choose_options") { return !_.isEmpty(selectedOptions); }
  }

  public renderCustomSetupPages() {
    const { campaign, completeCampaignAnalytic, campaign_analytics: { campaign_analytics } } = this.props;
    const { currentStep, selectedOptions, old_analytics } = this.state;
    const card = selectedOptions.find((o) => o.key === currentStep.id);
    const campaign_analytic = campaign_analytics.find((c) => c.analytics_card_id === card.id);
    const pages = {
      surveys: <GeoLocatedSurveys
        scrollToTop={this.scrollToTop.bind(this)}
        analytic={campaign_analytic}
        old_analytics={old_analytics}
        token={campaign.token}
        card={card}
        onSubmit={completeCampaignAnalytic}
        oldSave={this.oldSaveAnalytics.bind(this)}
        deleteStep={this.deleteStep.bind(this)} />,
      geofenced_mobile_ads: <GeofencedMobileAds
        scrollToTop={this.scrollToTop.bind(this)}
        analytic={campaign_analytic}
        old_analytics={old_analytics}
        token={campaign.token}
        card={card}
        onSubmit={completeCampaignAnalytic}
        oldSave={this.oldSaveAnalytics.bind(this)}
        deleteStep={this.deleteStep.bind(this)} />,
    };
    return pages[currentStep.id];
  }

  public scrollToTop() {
    const app = document.getElementById("app");
    if (app) {
      console.log(app.scrollTop);
      app.scrollTo(0, 0);
      console.log(app.scrollTop);
    }
  }

  public renderContinueButton() {
    const { currentStep } = this.state;
    return currentStep.id === "campaign_goals" || currentStep.id === "choose_options";
  }

  public render() {
    const { currentStep } = this.state;
    return (
      <div className="analytics_container analytics_common">
        <Header
          title="Analytics Setup"
          faIcon="fa-magic">
          <div className="breadcrumb">
            {this.renderBreadcrump()}
          </div>
          <div className="header_buttons">
          </div>
        </Header>
        <div className="analytics_body">
          <div className="second_level">
            <div className="hero" onClick={this.props.switchToAnalyticResults}>
              Go to Analytics Dashboard
              </div>
            {currentStep.id === "campaign_goals" &&
              <div>
                <div className="body_title">
                  <p>Let's setup your campaign analytics.</p>
                  <h4>What are your primary goals for this campaign?</h4>
                </div>
                <div className="card_section">
                  <div className="cards">
                    {_.orderBy(this.state.goals, "id")
                      .map((g, idx) => <Card key={`${g.id}-${idx}`} card={g} onSelect={this.onCheck} thing="goals" />)
                    }
                  </div>
                </div>
              </div>
            }
            {currentStep.id === "choose_options" &&
              <div>
                <div className="body_title">
                  <h4>Based on your goals, we recommend the following options.</h4>
                  <p className="step_2_subtitle">Let us know which analytics options you're interested in.</p>
                </div>
                {this.renderOptionGroups()}
              </div>
            }
            {currentStep.id === "final_setup" &&
              <div>
                <div className="body_title">
                  <h4>You're well on your way to measuring your campaign.</h4>
                  <p>Next, let's review & setup your analytics options.</p>
                </div>
                {this.renderFinalStep()}
                {/* <div className="notification_reminder">
                  <div className="sleep_bell"></div>
                  <p>Prefer to configure analytics another time?</p>
                  <div>
                    <button className="reminder_button">Remind me later</button>
                  </div>
                </div> */}
              </div>
            }
          </div>
          {(currentStep.id === "surveys" || currentStep.id === "geofenced_mobile_ads") &&
            this.renderCustomSetupPages()
          }
          {this.renderContinueButton() &&
            <div className="body_button">
              <button
                className={cs("btn", { proceed: this.proceedStep(currentStep) })}
                onClick={this.validateCurrentStep}>
                Continue
                </button>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapProps = ({ campaign_analytics, currentUser }) => ({
  campaign_analytics,
  currentUser
});

const mapActions = {
  getCampaignAnalytics,
  createCampaignAnalytics,
  completeCampaignAnalytic,
  updateCampaignAnalytic,
  createCampaignAnalyticsGoals
};

export default connect(mapProps, mapActions)(Setup);
