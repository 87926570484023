import React from 'react';
import { connect } from 'react-redux';
import createClass from 'create-react-class';
import { Modal } from 'react-bootstrap';
import { loadCampaignUnits } from '../../Campaign/actions';
import MassUpdateDateModal from "./Modals/MassUpdateDateModal";
import MassUpdateSpecSheetModal from "./Modals/MassUpdateSpecSheetModal";
import MassUpdateProductionCostModal from "./Modals/MassUpdateProductionCostModal";
import MassUpdatePricingModal from "./Modals/MassUpdatePricingModal";

const MassUpdateModal = createClass({
  displayName: 'MassUpdateModal',

  render() {
    const { modal, onHide, show } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>{this.renderTitle(modal)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody(modal)}
        </Modal.Body>
      </Modal>
    )
  },

  renderTitle(modal) {
    const titles = {
      mass_update: "Mass Update Dates",
      spec_sheet_mass_update: "Mass Assign Spec Sheets",
      production_cost_mass_update: "Mass Update Production Cost",
      campaign_unit_pricing_mass_update: "Mass Assign Media Cost"
    }
    return titles[modal];
  },

  renderBody(modal) {
    const bodies = {
      mass_update: this.renderMassUpdateDateModal,
      spec_sheet_mass_update: this.renderMassSpecSheetUpdateModal,
      production_cost_mass_update: this.renderMassUpdateProductionCostModal,
      campaign_unit_pricing_mass_update: this.renderMassUpdatePricingModal
    }
    return bodies[modal]();
  },

  renderMassUpdateDateModal() {
    return (
      <MassUpdateDateModal
        unit_ids={this.props.unit_ids}
        campaign_token={this.props.campaign_token}
        onHide={this.props.onHide}
        loadCampaignUnits={this.props.loadCampaignUnits}
      />
    )
  },

  renderMassSpecSheetUpdateModal() {
    return (
      <MassUpdateSpecSheetModal
        unit_ids={this.props.unit_ids}
        campaign_token={this.props.campaign_token}
        onHide={this.props.onHide}
        loadCampaignUnits={this.props.loadCampaignUnits}
      />
    )
  },

  renderMassUpdateProductionCostModal() {
    return (
      <MassUpdateProductionCostModal
        unit_ids={this.props.unit_ids}
        campaign_token={this.props.campaign_token}
        onHide={this.props.onHide}
        loadCampaignUnits={this.props.loadCampaignUnits}
      />
    )
  },

  renderMassUpdatePricingModal() {
    return (
      <MassUpdatePricingModal
        unit_ids={this.props.unit_ids}
        campaign_token={this.props.campaign_token}
        onHide={this.props.onHide}
        loadCampaignUnits={this.props.loadCampaignUnits}
      />
    )
  }
});

export default connect(null, { loadCampaignUnits })(MassUpdateModal)
