import "core-js";
import "regenerator-runtime/runtime";

import ahoy from "ahoy.js";
import jQuery from "jquery";
import lodash from "lodash";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React from "react";
import ReactDOM from "react-dom";
import Redbox from "redbox-react";
import thunk from "redux-thunk";

import ColorTool from "./marketplace/color_tool";
import Root from "./marketplace/Root";
import configureStore from "./marketplace/store";

import "preline/dist/preline.js";

// exporting libs to the global context;
window.jQuery = jQuery;
window.$ = jQuery;
window.ahoy = ahoy;
window.ColorTool = new ColorTool();
window._ = lodash;

const params = QueryString.parse(window.location.search);

if (params.enableColors) {
  setTimeout(window.ColorTool.start, 2000);
}

const store = configureStore(initialState, [thunk]);

const consoleErrorReporter = ({ error }) => {
  console.error(error); // eslint-disable-line no-console
  return <Redbox error={error} />;
};

consoleErrorReporter.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

const appElm = document.getElementById("app");

if (appElm && !appElm.innerHTML) {
  ReactDOM.render(
    <Root store={store} />,
    appElm, //eslint-disable-line no-undef
  );
  if (ahoy) ahoy.trackAll();
}
