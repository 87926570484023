import React from 'react';
import createClass from 'create-react-class';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { LocalForm, Control } from 'react-redux-form';

export default createClass({
  displayName: 'Settings/FlightTypeForm',

  getInitialState() {
    return {
      saving: false,
      deleting: false
    }
  },

  componentDidMount() {
  },

  onDelete(e) {
    this.props.onDelete()
    e.preventDefault()
  },

  render() {
    const { saving, deleting, flightTypes, showAddFlightTypeForm } = this.state
    const initialState = this.props.flightType || {}

    return (
      <LocalForm initialState={initialState} onSubmit={this.props.onSave}>
        <div className="row">
          <div className="col-md-4">
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <Control.text model=".name" component={FormControl} required />
            </FormGroup>
          </div>
          <div className="col-md-4">
            <FormGroup>
              <ControlLabel>Color</ControlLabel>
              <Control.text model=".color" placeholder="#000000" component={FormControl} required />
            </FormGroup>
          </div>
          <div className="col-md-4">
            <FormGroup>
              <ControlLabel>Availability</ControlLabel>
              <label style={{marginTop:5,display:'block'}}>
                <Control.checkbox model=".makes_unit_unavailable" component={FormControl}
                  style={{boxShadow:'none',height:'auto',width:'auto',float:'left',marginRight:10,marginTop:6}} />
                <span>Makes Unit Unavailable</span>
              </label>
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <input type="submit" value={saving ? "Saving..." : "Save"} className="btn btn-primary" disabled={saving} />
            {this.props.onDelete && <button className="btn btn-danger" disabled={deleting} style={{marginLeft:10}} onClick={this.onDelete}>
              {deleting ? "Deleting..." : "Delete"}
            </button>}
          </div>
        </div>
      </LocalForm>
    )
  }

});
