import React from "react";
import { Modal, Button } from "react-bootstrap";
import PointsOfInterestListing from "./Places/PointsOfInterestListing";

class PointsOfInterestModal extends React.Component {

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header>
          <Modal.Title>Add / Remove Points of Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PointsOfInterestListing />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default PointsOfInterestModal;
