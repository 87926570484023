import React from 'react';
import { Modal } from 'react-bootstrap';
import AuthStore from '../../stores/AuthStore';
import LoginModal from './LoginModal';
import GoogleButton from './GoogleButton'
import GlobalActions from '../../actions/GlobalActions';

export default class SignUpModal extends React.Component {

  constructor(props) {
    super(props);
    const auth = AuthStore.getState();
    this.state = {
      auth: auth,
      errors: '',
      email: '',
      phone: '',
      password: '',
      step: 1,
      invalidEmail: false
    };

    this.renderTitle = this.renderTitle.bind(this);
    this.trackConversion = this.trackConversion.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAuthChange = this.onAuthChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }

  componentDidMount() {
    this.unsubscribeList = [AuthStore.listen(this.onAuthChange)];
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  onAuthChange(event) {
    const auth = AuthStore.getState();
    this.setState({ auth: AuthStore.getState() });
    if (event === 'auth:signup') {
      const { onSignup } = this.props;
      this.trackConversion();
      if (onSignup) {
        onSignup();
      }
    }

    if (event == 'auth:signup_error') {
      const { errors } = AuthStore.getState();
      this.setState({ errors });
    }
  }

  trackConversion() {
    const google_conversion_id = 878432214;
    const google_conversion_label = 'Ck7HCPSLjGwQ1p_vogM';
    const image = new Image(1, 1);
    image.src = `//www.googleadservices.com/pagead/conversion/${google_conversion_id}/?label=${google_conversion_label}&script=0`;
  }

  onSubmit(e) {
    const { email, phone, password, step } = this.state;
    e.preventDefault();
    if (step === 1) {
      if (this.validateEmail()) {
        this.setState({ step: 2 });
      } else {
        this.setState({ invalidEmail: true })
      }
    } else {
      AuthStore.onSignUp({
        email: email,
        phone: phone,
        password: password
      });
    }
  }

  renderTitle() {
    const { step } = this.state;
    const { unitCount, showSaveTen, query } = this.props;
    if (showSaveTen) {
      return "Sign up to claim your 10% discount* and get started on your first campaign!";
    } else if (unitCount == null || unitCount == 0) {
      return "Welcome to AdQuick!";
    } else {
      return (
        <div>
          <div>We found {unitCount} ad spaces in</div>
          <div><b>{query}</b></div>
        </div>);
    }
  }

  validateEmail() {
    const { email } = this.state;
    const match = email.match(/\S+@\S+\.\S/);
    return match;
  }

  onEmailChange(e) {
    const { invalidEmail } = this.state;

    this.setState({ email: e.target.value }, () => {
      if (invalidEmail && this.validateEmail()) {
        this.setState({ invalidEmail: false});
      }
    })
  }

  render() {
    const { force, allow_signup, onHide, returnTo, showSaveTen, show } = this.props;
    const { errors, email, password, auth, step, invalidEmail } = this.state;
    const { user } = auth;

    return (<div>
      <Modal
        show={show}
        onHide={() => this.setState({ step: 1 }, onHide)}
        className="sign-up-modal"
        animation={!window.mobileCheck()}>
        <Modal.Header>
          {step === 2 && <div
            className="back-icon-area"
            onClick={() => this.setState({step: 1})}
          >
            <i className="fa fa-chevron-left back-icon" aria-hidden="true"/>
          </div>}
          {!force && <img
            className="close-modal-x"
            src={ASSETS['x']}
            style={{ float: 'right' }}
            onClick={() => this.setState({ step: 1 }, onHide)}
          />}
          {step === 1 && <div className="tagline">
            {this.renderTitle()}
          </div>}
          {step === 2 && <div className="tagline">
            <div>First things first!</div>
            <div>Let's create your free AdQuick account.</div>
          </div>}
        </Modal.Header>
        <Modal.Body>
          {errors && <div className="message message_error">{errors}</div>}
          {step === 1 && <GoogleButton />}
          {step === 1 && <div className="or-section">
            <div className="line"></div>
            <div className="text">or</div>
            <div className="line"></div>
          </div>}

          {invalidEmail && <div className="email-error">
            Please enter a valid email address.
          </div>}

          <form onSubmit={this.onSubmit}>
            <div className="input-area">
              <input
                value={email}
                className={`form-control email ${invalidEmail ? 'error' : ''}`}
                name="email"
                type="input"
                placeholder="Work Email Address"
                onChange={this.onEmailChange}
                style={(errors && errors.match(/email/) ? { borderColor: 'red' } : {})}
                autoFocus
              />
            </div>

            {step === 2 && <div className="input-area">
              <input
                value={password}
                className="form-control password"
                name="password"
                type="password"
                placeholder="Create a Password"
                onChange={(e) => { this.setState({ password: e.target.value }); }}
              />
            </div>}

            <div style={{ marginBottom: 0 }}>
              <button
                className="adstrap_button submit-button"
                type="submit"
                onClick={this.onSubmit}
              >{step === 1 ? "Continue" : "Get Started"}</button>
            </div>
          </form>
          <div className="links">
            <a onClick={() => {
              this.setState({ step: 1 }, () => {
                GlobalActions.openPopup('login')
              })
            }}>
              Are you trying to sign in?
            </a>
          </div>
          {showSaveTen && <div className="comment" style={{ paddingTop: "10px" }}>
              *The 10% off coupon is limited to a maximum total discount of $1,000.
          </div>}
        </Modal.Body>
      </Modal>
    </div>);
  }
};
