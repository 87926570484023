import { connect } from 'react-redux';
import React from 'react';

type FeatureFlag = {
  name: string
  status: boolean
}

type Props = {
  advertiser_id?: number;
  feature_flags?: FeatureFlag[];
  header?: boolean;
  style?: any;
}

class SegmentApiKey extends React.Component<Props, {}> {
  apiKey() {
    if (!this.props.advertiser_id) return '';
    return btoa("advertiser:::" + this.props.advertiser_id);
  }

  shouldRender() {
    if (!this.props.feature_flags) return false;
    return !!this.props.feature_flags.find(f => f.name === "segment_integration" && f.status);
  }

  render() {
    if (!this.shouldRender()) return <div/>;
    return (
      <div className="analytics_section segment" style={this.props.style}>
        {this.props.header && <div className="section_header">
          <i className="fa fa-pie-chart" aria-hidden="true"></i>
          Segment
        </div>}
        <div className="analytics_box">
          <div className="title">
            Segment API key
          </div>
          <input className="form-control" type="text" value={this.apiKey()}/>
        </div>
      </div>
    );
  }
}

// @ts-ignore
export default connect(({feature_flags, campaign}) => ({feature_flags, advertiser_id: campaign.advertiser.id}), {})(SegmentApiKey)
