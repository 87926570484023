import * as React from "react";
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { get, put } from "../../../utils/api";
import GlobalActions from "../../../actions/GlobalActions";

interface Props {
  show: boolean;
  onHide: Function;
  campaign: any;
  supplier_id: null | number;
  selected_units: { campaign_unit_token: string }[];
  loadCampaignDesigns: Function;
}

interface State {
  installers: Array<any>;
  installer: any;
  saving: boolean;
}

class BulkUnitInstallerModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      installers: [],
      installer: null,
      saving: false
    };
    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.fetchInstallerOptions();
  }

  async fetchInstallerOptions() {
    const { supplier_id } = this.props;
    if (supplier_id != null) {
      const response = await get(`/api/v1/suppliers/${supplier_id}/installers`);
      const installers = response.map(i => Object.assign({}, {id: i.id, label: i.name}));
      this.setState({installers});
    }
  }

  onHide() {
    this.props.onHide();
  }

  async submit() {
    const { selected_units, campaign: { token } } = this.props;
    const campaign_unit_tokens = selected_units.map(u => u.campaign_unit_token);
    const installer_id = this.state.installer.id;
    const payload = { campaign_unit_tokens, installer_id };
    try {
      await put(`/api/v1/campaigns/${token}/campaign_units/update_installer`, payload);
      GlobalActions.showMessage("Campaign units installer updated successfully");
      await this.props.loadCampaignDesigns(token);
      this.setState({ saving: false });
      this.onHide();
    } catch (error) {
      this.setState({ saving: false });
      GlobalActions.showError(error);
    }
  }

  private onSubmit() {
    this.setState({ saving: true }, this.submit);
  }

  private handleSelectChange(installer) {
    this.setState({ installer });
  }

  renderBody() {
    const { saving, installer, installers } = this.state;
    return (
      <Modal.Body>
        <Select 
          name="installer" 
          options={installers} 
          onChange={this.handleSelectChange} 
          value={installer}
          placeholder="Search for a installer"
        />
        <button
          className="btn btn-success form-control"
          disabled={!installer || saving}
          onClick={this.onSubmit}
          style={{
            marginTop: 10
          }}
        >
          Save
        </button>
      </Modal.Body>
    );
  }

  render() {
    const { show } = this.props;

    return (
      <Modal
        className="add_posting_note_modal"
        show={show}
        onHide={this.onHide}
      >
        <Modal.Header>
          <div className="header-area">
            <div className="modal-header-text">Update Installer</div>
            <div className="close-modal-area">
              <div className="close-modal" onClick={this.onHide}>
                
              </div>
            </div>
          </div>
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  }
}

export default BulkUnitInstallerModal
