import React from 'react';

export default class LeaseCode extends React.Component {
  constructor(props) {
    super(props);
    const filters = this.props.filters || {};
    const lease_code = filters.lease_code || '';
    this.state = {
      lease_code
    };
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onLeaseCodeChange = this.onLeaseCodeChange.bind(this);
  }

  onClear() {
    const { setFilter } = this.props;
    this.setState({ lease_code: '' }, () => {
      setFilter('lease_code', '');
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.lease_code === '') {
      this.setState({ lease_code: ''});
    }
  }

  onSubmitSearch() {
    const { setFilter } = this.props;
    const lease_code = this.state.lease_code && this.state.lease_code.trim();
    setFilter({ 'lease_code': lease_code });
  }

  onLeaseCodeChange(e) {

    this.setState({lease_code: e.target.value}, () => {
      this.onSubmitSearch();
    });
  }

  render() {
    const { lease_code } = this.state;
    const showClear = lease_code && lease_code !== '';

    return (
      <li className="filter_by_lease_code">
        <div className="search_by_lease_code_container">
          <input
            className="search_by_lease_code_input"
            onChange={this.onLeaseCodeChange}
            value={lease_code}
            placeholder="Lease Code"
          />
          {showClear && <img
            className="search-clear"
            src={ASSETS['x']}
            onClick={this.onClear}
          />}
        </div>
      </li>
    )
  }
};
