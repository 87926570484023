import cs from "classnames";
import Fuse from "fuse.js";
import React from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";
import { fetchAllAdvertisers } from "../Advertisers/actions";

const POPUP_WIDTH = 220;

class AdvertiserFilter extends React.Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClear = this.onClear.bind(this);
    this.debouncedSearch = _.debounce(this.searchByAdvertiserName, 200);
    this.state = Object.assign(
      {
        isExpanded: false,
        activeAdvertiser: null,
        narrowedList: null,
        is_loaded: false,
      },
      this.props.initialValue,
    );
  }

  async componentDidMount() {
    const { fetchAllAdvertisers } = this.props;
    document.addEventListener("click", this.onToggle);
    await fetchAllAdvertisers();
    this.setState({ is_loaded: true });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
  }

  onClear() {
    this.setState(
      {
        activeAdvertiser: null,
        narrowedList: null,
      },
      this.onFilter
    );
  }

  searchByAdvertiserName(query) {
    const options = {
      keys: ["name"],
      minMatchCharLength: 3,
      threshold: 0.4,
      maxPatternLength: 20,
    };
    const fuse = new Fuse(this.props.advertisers, options);
    this.setState({ narrowedList: fuse.search(query) });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onAdvertiserSelect(advertiser) {
    const { activeAdvertiser } = this.state;
    if (activeAdvertiser === advertiser) {
      this.setState({ activeAdvertiser: null }, this.onFilter);
    } else {
      this.setState({ activeAdvertiser: advertiser }, this.onFilter);
    }
  }

  onFilter() {
    const { activeAdvertiser } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    const advertiser = !activeAdvertiser ? null : activeAdvertiser.id;
    this.setState({ isExpanded: false }, () => {
      _.defer(filterUnits, { advertiser: advertiser });
      handleQueryParams({ 'advertiser[]': advertiser })
    });
  }

  getButtonLabel() {
    const { activeAdvertiser } = this.state;
    if (!activeAdvertiser) return "Advertiser";
    return _.truncate(activeAdvertiser.name, { length: 18 });
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { activeAdvertiser, narrowedList, is_loaded } = this.state;
    const { advertisers } = this.props;
    const advertisersList = narrowedList && narrowedList.length ? narrowedList : advertisers;
    const clearStyle = {
      float: "right",
      top: -32,
      paddingRight: 12,
      position: "relative",
      color: "#999999",
    };

    return (
      <div className="filter_popup suppliers_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>ADVERTISER</h4>
        <a style={clearStyle} onClick={this.onClear}>
          Clear
        </a>
        <input
          autoFocus="true"
          type="text"
          className="narrow_list"
          onChange={e => this.debouncedSearch(e.target.value)}
          placeholder="Filter by name"
        />
        <ul>
          {is_loaded &&
            advertisersList.map(advertiser => {
              const onClick = () => {
                this.onAdvertiserSelect(advertiser);
              };
              const isActive = activeAdvertiser === advertiser;
              return (
                <li key={advertiser.id} onClick={onClick} className={cs({ active: isActive })}>
                  {_.truncate(advertiser.name, { length: 18 })}
                  {advertiser.count && <i className="count">({advertiser.count})</i>}
                  <i className="fa fa-check" />
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  render() {
    const { isExpanded, activeAdvertiser } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: activeAdvertiser || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(
  ({ advertisers_ui: { advertisers } }) => {
    return { advertisers };
  },
  {
    fetchAllAdvertisers,
    filterUnits,
  },
)(AdvertiserFilter);
