import React from "react";
import createClass from "create-react-class";
import { connect } from "react-redux";
import cs from "classnames";
import {
  setFilters,
  showBatchAddFlightsModal,
  showAddFlightsToCampaignModal,
  showAddUnitsToRfpModal,
  showUpdateAvailsModal,
  hideModal,
  showUploadSpecSheet,
  showUpdateAttributes
} from "./actions";
import { fetchUnitTagOptions } from '../Inventory/Unit/actions'
import { setSelectedUnits } from "./Unit/actions";
import AddFlightsModal from "./Flight/AddFlightsModal";
import EditFlightModal from "./Flight/EditFlightModal";
import AddFlightsToCampaign from "./Flight/AddFlightsToCampaign";
import NewUnitModal from "./NewUnitModal";
import ReportsModal from "./ReportsModal";
import UpdateAvailsModal from "./UpdateAvailsModal";
import UploadSpecSheetsModal from "./UploadSpecSheetsModal";
import UpdateAttributesModal from './UpdateAttributesModal'
import AddUnitsToRfpModal from "./AddUnitsToRfpModal";

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
      filters: {}
    }
    this.onHide = this.onHide.bind(this);
    this.filterByFaceIds = this.filterByFaceIds.bind(this);
  }

  onHide() {
    this.setState({ showModal: null });
  }

  renderModals() {
    const {
      modal,
      selected_units,
      onDeleteFlight,
      flightTypes,
      onUnitSelectAll,
      onUnitDeselectAll,
      supplierToken,
      user
    } = this.props;
    const { showModal } = this.state;

    return (
      <div className="modal-area">
        {modal && modal.name == "edit_flight" && (
          <EditFlightModal
            show={modal && modal.name == "edit_flight"}
            campaign_unit={modal ? modal.campaign_unit : {}}
            selectedUnits={[modal.unit]}
            onDelete={onDeleteFlight}
            flightTypes={flightTypes}
            filterByFaceIds={this.filterByFaceIds}
            onUnitSelectAll={onUnitSelectAll}
            user={user}
          />
        )}
        {modal && modal.name == "holds" && (
          <AddFlightsModal
            show={modal && modal.name == "holds"}
            selectedUnits={selected_units}
            flightTypes={flightTypes}
            filterByFaceIds={this.filterByFaceIds}
            onUnitSelectAll={onUnitSelectAll}
            onUnitDeselectAll={onUnitDeselectAll}
            user={user}
          />
        )}
        {modal && modal.name == "add_to_campaign" && (
          <AddFlightsToCampaign
            show={modal && modal.name == "add_to_campaign"}
            filterByFaceIds={this.filterByFaceIds}
            onUnitSelectAll={onUnitSelectAll}
            onUnitDeselectAll={onUnitDeselectAll}
            user={user}
          />
        )}
        <NewUnitModal
          show={showModal == "unit"}
          onHide={this.onHide}
          supplierToken={supplierToken}
        />
        <ReportsModal
          show={showModal == "reports"}
          onHide={this.onHide}
        />
        {modal && modal.name == "update_avails" && (
          <UpdateAvailsModal
            show={modal && modal.name == "update_avails"}
            selectedUnits={selected_units}
          />
        )}
        {modal && modal.name == "upload_spec_sheet" && (
          <UploadSpecSheetsModal
            show={modal && modal.name == "upload_spec_sheet"}
            selectedUnits={selected_units}
          />
        )}
        {modal && modal.name == "update_attributes" && (
          <UpdateAttributesModal
            show={modal && modal.name == "update_attributes"}
            selectedUnits={selected_units}
          />
        )}
        {modal && modal.name == "add_to_rfp" && (
          <AddUnitsToRfpModal
            show={modal && modal.name == "add_to_rfp"}
            selectedUnits={selected_units}
            user={this.props.user}
          />
        )}
      </div>
    )
  }

  filterByFaceIds(data) {
    this.props.filterByFaceIds(data);
    this.onHide();
  }

  renderActionsDropdown() {
    const {
      user,
      selected_units,
      showBatchAddFlightsModal,
      showAddFlightsToCampaignModal,
      showUpdateAvailsModal,
      showUploadSpecSheet,
      showUpdateAttributes,
      showAddUnitsToRfpModal
    } = this.props;

    const disabled = (selected_units.length === 0 ? 'disabled' : '');

    return (
      <div className="btn-group">
         <div className="dropdown">
          <button
            className={`btn btn-default dropdown-toggle ${disabled}`}
            type="button"
            data-toggle="dropdown">
            Actions
            <span
              className="caret"
              style={{ marginLeft: "5px", marginBottom: "2px" }}
            />
          </button>
          <ul className="dropdown-menu dropdown-menu-right" style={{zIndex: 1001}}>
            {!!!user.is_supplier_sales &&
              <li className="dropdown-item">
                <a onClick={showBatchAddFlightsModal}>Add Flights</a>
              </li>
            }
            <li className="dropdown-item">
              <a onClick={showAddFlightsToCampaignModal}>
                Add To Campaign
              </a>
            </li>
            <li className="dropdown-item">
              <a onClick={showAddUnitsToRfpModal}>
                Add To RFP
              </a>
            </li>
            <li className="dropdown-item">
              <a onClick={showUpdateAvailsModal}>Update Availability</a>
            </li>
            <li className="dropdown-item">
              <a onClick={showUploadSpecSheet}>Upload Spec Sheet</a>
            </li>
            <li className="dropdown-item">
              <a onClick={showUpdateAttributes}>Edit Attributes</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { filters, total_results_count, user, setFilters, onViewAll, showViewAll } = this.props;
    const { showModal } = this.state;
    const { permissions } = user;

    return (
      <div>
        {this.renderModals()}
        <div className="row" style={{ marginBottom: 15 }}>
          <div className="col-sm-12 text-right">
            <div className="btn-group" style={{ marginRight: 10 }}>
              {total_results_count ? `${total_results_count} units` : ""}
            </div>
            {showViewAll &&
              <button className="btn btn-default toolbar_view_all_results" onClick={onViewAll}>
                View All Results
              </button>
            }
            {filters.mode == "timeline" && (
              <div className="btn-group" style={{ marginRight: 10 }}>
                <button
                  type="button"
                  className={cs("btn", "btn-default", {
                    active: filters.interval == "week"
                  })}
                  onClick={() => setFilters({ interval: "week" })}
                >
                  <i className="fa fa-grid" /> Weekly
                </button>
                <button
                  type="button"
                  className={cs("btn", "btn-default", {
                    active: filters.interval == "month"
                  })}
                  onClick={() => setFilters({ interval: "month" })}
                >
                  <i className="fa fa-clock" /> Monthly
                </button>
              </div>
            )}
            {user.is_admin && (
              <div className="btn-group" style={{ marginRight: 10 }}>
                <button
                  type="button"
                  className={cs("btn", "btn-default", {
                    active: filters.mode == "grid"
                  })}
                  onClick={() => setFilters({ mode: "grid" })}
                >
                  <i className="fa fa-grid" /> Grid
                </button>
                <button
                  type="button"
                  className={cs("btn", "btn-default", {
                    active: filters.mode == "timeline"
                  })}
                  onClick={() => setFilters({ mode: "timeline" })}
                >
                  <i className="fa fa-clock" /> Timeline
                </button>
              </div>
            )}

            {(permissions.can_view_reports) && (
              <button
                type="button"
                className={cs("btn", "btn-default")}
                onClick={() => this.setState({ showModal: "reports" })}
              >
                <i className="fa fa-clock" /> Reports
              </button>
            )}

            <button
              className="btn btn-success"
              style={{ marginRight: "10px", marginLeft: "10px" }}
              onClick={() => this.setState({ showModal: "unit" })}
            >
              + New Unit
            </button>
            {!!!user.is_supplier_contact && this.renderActionsDropdown()}
          </div>
        </div>
      </div>
    );
  }
};

export default connect(
  ({ inventory }) => ({ ...inventory }), {
    setFilters,
    showBatchAddFlightsModal,
    showAddUnitsToRfpModal,
    showAddFlightsToCampaignModal,
    showUpdateAvailsModal,
    showUploadSpecSheet,
    showUpdateAttributes,
    fetchUnitTagOptions,
    hideModal,
    setSelectedUnits
  })(Toolbar);
