import {Button} from "react-bootstrap";
import React from 'react';
import _ from 'lodash';
import createClass from 'create-react-class';

import { del, get } from '../../../utils/api2';
import CampaignGeopathSegmentModal from "./CampaignGeopathSegmentModal";
import CampaignGeopathSegments from "./CampaignGeopathSegments";
import GlobalActions from '../../../actions/GlobalActions';
import Loading from '../../Loading';

const GeopathTable = createClass({
  displayName: 'Campaign/GeopathTable/index.js',

  getInitialState() {
    return {
      showPopup: false,
      error: null,
      isLoaded: false,
      measures: [],
      campaign_geopath_segments: []
    };
  },

  componentDidMount() {
    this.loadGeopath();
  },

  async loadGeopath() {
    try {
      const measures_promise = get(`/api/v1/campaigns/${this.props.campaign.token}/geopath_measures`)
      const segments_promise = get(`/api/v1/campaigns/${this.props.campaign.token}/campaign_geopath_segments`)

      const { geopath_measures } = await measures_promise;
      const { campaign_geopath_segments } = await segments_promise;

      this.setState({
        error: null,
        isLoaded: true,
        measures: geopath_measures,
        showPopup: false,
        campaign_geopath_segments
      })
    } catch (error) {
      this.setState({
        isLoaded: true,
        showPopup: false,
        error: error
      })
    }
  },

  openPopup() {
    this.setState({showPopup: true})
  },

  closePopup() {
    this.setState({showPopup: false});
  },

  async removeSegment(segment_id) {
    try {
      await del(`/api/v1/campaigns/${this.props.campaign.token}/campaign_geopath_segments/${segment_id}`)
      this.loadGeopath();
    } catch(error) {
      GlobalActions.showError("Error removing audience.");
    }
  },

  selectSegment(segment_id) {
    if (this.state.selected_segment_id === segment_id) {
      this.setState({selected_segment_id: null});
    } else {
      this.setState({selected_segment_id: segment_id});
    }
  },

  renderMarket(measure) {
    if (!measure) return <></>

    const {market_name, measures} = measure;
    if (!measures) return <></>

    const { pop_target_inmkt, freq_avg, reach_pct, trp } = measures;

    if (!pop_target_inmkt || !freq_avg || !reach_pct || !trp) {
      return <></>
    }

    return (
      <div className="panel-body geopath-segment-market">
        <h2 className="panel-title">{market_name}</h2>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-2">
            <div className="small">Total Population</div>
            {pop_target_inmkt.toLocaleString(navigator.language)}
          </div>
          <div className="col-md-2">
            <div className="small">Total Frequency (avg)</div>
            {freq_avg.toFixed(2)}
          </div>
          <div className="col-md-2">
            <div className="small">Total Reach</div>
            {reach_pct.toFixed(2)}%
          </div>
          <div className="col-md-2">
            <div className="small">GRP</div>
            {trp.toFixed(2)}
          </div>
        </div>
      </div>
    )
  },

  renderSegments(measures) {
    measures = (measures || []).filter(m => (
      m.measures && m.measures.pop_target_inmkt && m.measures.freq_avg && m.measures.reach_pct && m.measures.trp
    ))
    if (!measures.length) return <></>

    return (
      <ul className="list-group">
        <li className="list-group-item">
          <div className="row small geopath-segment-metric-names">
            <div className="col-md-4">
              Audience
            </div>
            <div className="col-md-2">
              Target Population
            </div>
            <div className="col-md-2">
              Target Frequency (avg)
            </div>
            <div className="col-md-2">
              Target Reach
            </div>
            <div className="col-md-2">
              TRP
            </div>
          </div>
        </li>
        {measures.map(measure => (
          <li key={measure.id} className="list-group-item">
            <div className={`row ${measure.geopath_segment_id == this.state.selected_segment_id ? 'selected-segment' : ''}`}>
              <div className="col-md-4">
                {measure.geopath_segment_name}
              </div>
              <div className="col-md-2">
                {measure.measures.pop_target_inmkt.toLocaleString(navigator.language)}
              </div>
              <div className="col-md-2">
                {measure.measures.freq_avg.toFixed(2)}
              </div>
              <div className="col-md-2">
                {measure.measures.reach_pct.toFixed(2)}%
              </div>
              <div className="col-md-2">
                {measure.measures.trp.toFixed(2)}
              </div>
            </div>
          </li>
        ))}
      </ul>
    )
  },

  render() {
    const {error, isLoaded, measures} = this.state;
    const market_measures = _.groupBy(measures, "market_name");
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      return (
        <div className="container-fluid geopath-table">
          <div className="row header-row">
            <div className="col-md-4">
              <Button bsStyle="primary" onClick={this.openPopup}>
                Add Audience
              </Button>
              <CampaignGeopathSegmentModal
                show={this.state.showPopup}
                onHide={this.closePopup}
                updateGeopath={this.loadGeopath}
                campaign={this.props.campaign}
              />
            </div>
            <div className="col-md-8">
              <p className="text-center" style={{marginTop: "22px"}}><i>Currently showing data for <strong>all units</strong> on the campaign</i>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <CampaignGeopathSegments
                campaign_geopath_segments={this.state.campaign_geopath_segments}
                selected_segment_id={this.state.selected_segment_id}
                removeSegment={this.removeSegment}
                selectSegment={this.selectSegment}
              />
            </div>
            <div className="col-md-8 geopath-measures">
              {Object.keys(market_measures).map(key => (
                <div key={key} className="panel panel-default geopath-market">
                  {this.renderMarket(market_measures[key].shift())}
                  {this.renderSegments(market_measures[key])}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }

});
export default GeopathTable;
