import React, { Component } from "react";

export default class BreadCrumbs extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { step, goToStep, disable_last_step } = this.props;
    const currentStepStyle = {
      backgroundColor: '#25466E',
      color: '#ffffff'
    };

    return (
        <div className="progress-area">
          <div
            className={`progress-section left ${step !== 1 && step !== 1.5 ? 'clickable' : ''}`}
            style={(step === 1 || step === 1.5) ? currentStepStyle : {}}
            onClick={step !== 1 ? () => goToStep(1.5) : () => {}}>
            1. Campaign Details
          </div>
          <div
            className={`progress-section center ${step !== 1 && step !== 2 ? 'clickable' : ''}`}
            style={step === 2 ? currentStepStyle : {}}
            onClick={step !== 1 ? () => goToStep(2) : () => {}}>
            2. Add Units
          </div>
          <div
            className={`progress-section right ${(step === 1 || disable_last_step) ? '' : 'clickable'}`}
            style={step === 3 ? currentStepStyle : {}}
            onClick={(step === 1 || disable_last_step) ? () => {} : () => goToStep(3)}>
            3. Finalize & Submit
          </div>
        </div>
    );
  }
}
