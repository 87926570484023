import React from 'react';
import { connect } from 'react-redux';

class Subheader extends React.Component {
  render() {
    let advertiser = (this.props.greylabel || {}).advertiser || {}
    let fontFamily, headerFontWeight

    if (advertiser.name == 'Compass') {
      fontFamily = 'Compass Serif, Serif, Times'
      headerFontWeight = 400
    } else {
      fontFamily = "Inter, 'Helvetica Neue', Helvetica, Arial"
      headerFontWeight = 600
    }

    return (
      <div style={{
          background: `url(${advertiser.header_background_url}) rgba(255, 255, 255, 0.8)`,
          backgroundColor: advertiser.name === 'AdQuick' ? '#f1f1f1' : '',
          height: 300,
          width: '100%',
          textAlign: 'center',
          marginTop: '-20px',
          paddingTop: 90,
          fontFamily: fontFamily
        }} className="hero-brand">
        <h1 style={{
          fontWeight: headerFontWeight,
          fontSize: 50,
          lineHeight: '1.2em',
          color: advertiser.header_text_color || '#313338',
        }}>
          {advertiser.header_title}
        </h1>
        <h3 style={{
          fontWeight: 400,
          fontSize: 15,
          lineHeight: '1.5em',
          color: advertiser.header_text_color || '#313338',
          fontFamily: fontFamily
        }}>
          Find and book outdoor advertising with ease – powered by AdQuick.
        </h3>
      </div>
    )
  }
}

const errorStyle = {
  marginBottom: 20,
  borderColor: 'red',
}

export default connect(
  ({ greylabel }) => ({ greylabel }),
  null,
)(Subheader);
