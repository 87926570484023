import React from 'react';
import createClass from 'create-react-class';

const CheckboxFilter = ({ label, attributeName, filters, setFilter}) => (
  <div className="checkbox_filter">
    <label style={{cursor: 'pointer'}}>
      <input
        type="checkbox"
        checked={filters[attributeName] == "true" || filters[attributeName] == true}
        defaultValue={filters[attributeName]}
        onChange={e => setFilter(attributeName, e.target.checked)} />
      <span style={{ marginLeft: 5 }}>{label}</span>
    </label>
  </div>
)

export default CheckboxFilter;
