import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { round_2_decimals } from '../utils/math'

export default class CustomDataLayer {

  buildDataLayerLegend(data, shading_variable) {
    if (data && data.length == 0) return null;

    const no_data = {
      text: 'No Data',
      color: '#d8d8d8'
    };

    const scores = data.map(datum => datum[shading_variable])
    const min = Math.min(...scores)
    const max = Math.max(...scores)

    if ( max <= min ) return [no_data];

    const distance = max - min;
    const range_size = (distance / 5.0000)
    const legend = [];
    const suffix = "";

    let start = min
    let end = start + range_size;
    while(end <= max) {
      legend.push({
        start: round_2_decimals(start),
        end: round_2_decimals(end)
      });
      start = end;
      end = end + range_size;
    }

    if ( legend.length == 0 ) return [no_data];

    legend[legend.length-1].end = max;

    legend[0].text = `< ${legend[0].end}${suffix}`;
    legend[0].color = '#ffffb2';

    legend[1].text = `${legend[1].start}${suffix} - ${legend[1].end}${suffix}`;
    legend[1].color = '#fecc5c';

    legend[2].text = `${legend[2].start}${suffix} - ${legend[2].end}${suffix}`;
    legend[2].color = '#fd8d3c';

    legend[3].text = `${legend[3].start}${suffix} - ${legend[3].end}${suffix}`;
    legend[3].color = '#f03b20';

    legend[4].text = `> ${legend[4].start}${suffix}`;
    legend[4].color = '#bd0026';

    return [no_data].concat(legend);
  }

  dataLayer(data, shading_variable, legend, color) {
    return { areas: this.featureCollection(data, shading_variable, legend, color) };
  }

  featureCollection(data, shading_variable, legend, color) {
    console.log("shading, ", shading_variable)
    const features = data.map(datum => {
      const color = color || this.calculateColor(datum, shading_variable, legend);
      return {
        type: 'Feature',
        properties: {
          ...datum,
          opacity: 0.4,
          fillColor: color,
          color: color,
          popup_content: this.renderPopup(datum, shading_variable)
        },
        geometry: {
          coordinates: datum.coordinates,
          type: 'MultiPolygon',
        }
      }
    });

    return {
      type: 'FeatureCollection',
      features: features
    };
  }

  calculateColor(datum, shading_variable, legend) {
    const inRange = (number, start, end) => {
      return number >= Math.min(start, end) && number <= Math.max(start, end)
    }

    const score = datum[shading_variable];
    const no_data = legend[0];

    if (score == null || score == 0) { return no_data.color; }

    const shading_key = _.find(legend, (sk) => {
      return inRange(score, sk.start, sk.end);
    })
    if (shading_key){
      return shading_key.color;
    } else {
      if (window.Rollbar) Rollbar.error('Error calculating DataLayer color.', 'No shading_key matches the score.', { legend: legend, datum })
      console.error('Error calculating DataLayer color.', 'No shading_key matches the score.', { legend: legend, datum })
      return 'black';
    }
  }

  renderPopup(datum, shading_variable) {
    return ReactDOMServer.renderToString(
      <div className="text-left" key={`popup-${datum.label}`}>
        <div><b>{datum.title}</b></div>
        <div>Score: {datum.score}</div>
      </div>
    )
  }

}
