import { LOAD_UNIT_SCREEN_SUBTYPES } from "../actions/media_type_actions";
import { TUnitScreenSubtype } from "../models/media_type";

interface Action {type: string; payload: any; }

export default(state: TUnitScreenSubtype[], action: Action): TUnitScreenSubtype[] => {
  switch (action.type) {
    case LOAD_UNIT_SCREEN_SUBTYPES: return action.payload.unit_screen_subtypes;
    default: return state || [];
  }
};
