import React from 'react';
import createClass from 'create-react-class';

import GlobalActions from '../actions/GlobalActions';
import UnitActions from '../actions/UnitActions';
import AuthStore from '../stores/AuthStore';
import GlobalStore from '../stores/GlobalStore';

const Main = createClass({

  displayName: 'Main',

  componentDidMount() {
    if (AppConfig.isFirstLogin) {
      GlobalActions.openPopup('change_password');
    }

    if (this.props.location.pathname.startsWith('/request')) {
      GlobalActions.openPopup('campaign_request');
    } else if (this.props.location.pathname.startsWith('/contact')) {
      GlobalActions.openPopup('contact_us');
    }
  },

  render() {
    return this.props.children;
  },
});

export default Main;
