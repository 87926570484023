import React from 'react';
import createClass from 'create-react-class';
import PropTypes from "prop-types";

export default createClass({
  displayName: 'Sms',

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      sms_keywords: "",
      sms_default_response: ""
    };
  },

  onInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState( { [name]: value }, this.props.setAttributes({ [name]: value }) );
  },

  smsResponseLength() {
    return this.state.sms_default_response.length;
  },

  render() {
    const smsResponseLength = this.smsResponseLength();

    return (
      <div id="campaign-request-sms">
        <h4 className="section_header">
          <i className="fa fa-comment" aria-hidden="true"></i> SMS Direct Response
        </h4>
        <div className="section ">
          <div className="analytics_box">
            <div className="description sms_description">
              <div className="description_paragraph">
                We support SMS direct response if you'd like to use this feature in your campaign.
              </div>
              <div className="description_paragraph">
                Example: "Text 'fitness' to 477-11 to get your first class free!".
              </div>
            </div>

            <div className="section-title">
              Keywords
            </div>
            <div className="description">
              Let us know your requested keywords. Separate multiple keywords with commas.
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="sms_keywords"
                value={this.state.sms_keywords}
                onChange={this.onInputChange}
                placeholder='e.g. "fitness"'
              />
            </div>

            <div className="section-title">
              Automatic Response Message
            </div>
            <div className="description">
              160 character limit. ({smsResponseLength}/160)
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                name="sms_default_response"
                value={this.state.sms_default_response}
                onChange={this.onInputChange}
                placeholder='e.g. a link to claim the offer'
                maxLength="160"
              />
            </div>
          </div>
        </div>
      </div>
    )
  },
});
