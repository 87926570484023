import param from "../../utils/objectToQuery";
import GlobalActions from "../../actions/GlobalActions";
import {post, put, get} from "../../utils/api";

const namespace = "marketplace/inventory/";
export const FETCH_DEFAULT = namespace + "FETCH_DEFAULT";
export const FETCH_DEFAULT_SUCCESS = namespace + "FETCH_DEFAULT_SUCCESS";
export const SET_FILTERS = namespace + "SET_FILTERS";
export const FILTER_BY_FACE_IDS = namespace + "FILTER_BY_FACE_IDS";
export const FILTER_BY_FACE_IDS_SUCCESS = namespace + "FILTER_BY_FACE_IDS_SUCCESS";
export const FETCH_ROWS = namespace + "FETCH_ROWS";
export const FETCH_ROWS_SUCCESS = namespace + "FETCH_ROWS_SUCCESS";
export const REMOVE_UNIT_SUCCESS = namespace + "REMOVE_UNIT_SUCCESS";
export const ADD_DEFAULT = namespace + "ADD_DEFAULT";
export const ADD_DEFAULT_SUCCESS = namespace + "ADD_DEFAULT_SUCCESS";
export const ADD_ROW = namespace + "ADD_ROW";
export const ADD_ROW_SUCCESS = namespace + "ADD_ROW_SUCCESS";
export const UPDATE_ROW = namespace + "UPDATE_ROW";
export const UPDATE_ROW_SUCCESS = namespace + "UPDATE_ROW_SUCCESS";
export const UPDATE_ROWS = namespace + "UPDATE_ROWS";
export const UPDATE_ROWS_SUCCESS = namespace + "UPDATE_ROWS_SUCCESS";
export const SHOW_MODAL = namespace + "SHOW_MODAL";
export const HIDE_MODAL = namespace + "HIDE_MODAL";
export const UPLOAD_IMAGE_SUCCESS = namespace + "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = namespace + "UPLOAD_IMAGE_FAILURE";
export const DELETE_IMAGE_SUCCESS = namespace + "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = namespace + "DELETE_IMAGE_FAILURE";
export const ADD_CAMPAIGN_SUCCESS = namespace + "ADD_CAMPAIGN_SUCCESS";
export const FETCH_CAMPAIGNS = namespace + "FETCH_CAMPAIGNS";
export const FETCH_CAMPAIGNS_SUCCESS = namespace + "FETCH_CAMPAIGNS_SUCCESS";
export const ADD_TO_CAMPAIGN = namespace + "ADD_TO_CAMPAIGN";
export const ADD_TO_CAMPAIGN_SUCCESS = namespace + "ADD_TO_CAMPAIGN_SUCCESS";
export const SELECT_CAMPAIGN = namespace + "SELECT_CAMPAIGN";
export const VIEW_MORE_RESULTS = namespace + "VIEW_MORE_RESULTS";
export const FETCH_MORE_SUCCESS = namespace + "FETCH_MORE_SUCCESS";
export const FETCH_ALL = namespace + "FETCH_ALL";
export const FETCH_ALL_SUCCESS = namespace + "FETCH_ALL_SUCCESS";
export const FETCH_SUPPLIERS = namespace + "FETCH_SUPPLIERS";
export const FETCH_SUPPLIERS_SUCCESS = namespace + "FETCH_SUPPLIERS_SUCCESS";
export const FETCH_SALES_USERS = namespace + "FETCH_SALES_USERS";
export const FETCH_SALES_USERS_SUCCESS = namespace + "FETCH_SALES_USERS_SUCCESS";
export const FETCH_GEO = namespace + "FETCH_GEO";
export const FETCH_GEO_SUCCESS = namespace + "FETCH_GEO_SUCCESS";
export const FETCH_SPEC = namespace + "FETCH_SPEC";
export const FETCH_SPEC_SUCCESS = namespace + "FETCH_SPEC_SUCCESS";
export const FETCH_FLIGHT_TYPES = namespace + "FETCH_FLIGHT_TYPES";
export const FETCH_FLIGHT_TYPES_SUCCESS = namespace + "FETCH_FLIGHT_TYPES_SUCCESS";
export const FETCH_CONTRACTS_SUCCESS = namespace + "FETCH_CONTRACTS_SUCCESS";
export const CLEAR_ORIENTATIONS = namespace + "CLEAR_ORIENTATIONS";
export const FETCH_ORIENTATIONS = namespace + "FETCH_ORIENTATIONS";
export const FETCH_ORIENTATIONS_SUCCESS = namespace + "FETCH_ORIENTATIONS_SUCCESS";
export const FETCH_SIZES = namespace + "FETCH_SIZES";
export const FETCH_SIZES_SUCCESS = namespace + "FETCH_SIZES_SUCCESS";
export const DESTROY_CONTRACT_SUCCESS = namespace + "DESTROY_CONTRACT_SUCCESS";
export const ADD_CONTRACT_SUCCESS = namespace + "ADD_CONTRACT_SUCCESS";
export const UPDATE_CONTRACT_SUCCESS = namespace + "UPDATE_CONTRACT_SUCCESS";
export const CONTRACT_CAMPAIGN_SUCCESS = namespace + "CONTRACT_CAMPAIGN_SUCCESS";
export const CONTRACT_CAMPAIGN_RESOLVE = namespace + "CONTRACT_CAMPAIGN_RESOLVE";

export const fetchCurrentUser = () => (dispatch, getState) => {
  dispatch({
    type: `${namespace}FETCH_CURRENT_USER`,
    payload: {currentUser: getState().currentUser}
  });
};

export const addCampaign = campaignPayload => (dispatch, getState) => {
  let campaign = {
    name: campaignPayload.name,
    advertiser_id: campaignPayload.advertiser_id,
    start_date: campaignPayload.startDate && campaignPayload.startDate.toDate(),
    end_date: campaignPayload.endDate && campaignPayload.endDate.toDate()
  };

  if (campaignPayload.status) campaign.status = campaignPayload.status;
  if (campaignPayload.advertiser_id) campaign.advertiser_id = campaignPayload.advertiser_id;

  post("/api/v1/campaigns", {campaign}).then(response => {
    if (response.status == 200) {
      const advertiser_id = response.campaign.advertiser && response.campaign.advertiser.id;
      const payload = {name: response.campaign.name, id: response.token, advertiser_id: advertiser_id};
      dispatch({type: ADD_CAMPAIGN_SUCCESS, payload});
    }
  });
};

export const fetchCampaigns = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_CAMPAIGNS
  });

  const url = "/api/v1/campaigns/autocomplete";

  $.get(url).then(response => {
    dispatch({
      type: FETCH_CAMPAIGNS_SUCCESS,
      payload: {campaigns: response.data}
    });
  });
};

export const setFilters = filters => dispatch => {
  filters.offset = 0;
  dispatch({
    type: SET_FILTERS,
    payload: {filters}
  });

  dispatch(fetchRows());
};

export const selectCampaign = selectedCampaignId => dispatch => {
  dispatch({
    type: SELECT_CAMPAIGN,
    payload: {selectedCampaignId}
  });
};

export const filterByFaceIds = ({face_ids, supplier_ids}) => dispatch => {
  dispatch({
    type: FILTER_BY_FACE_IDS
  });

  const url = `/api/v1/search/unit_listing.json?${param({face_ids, supplier_ids})}`;

  $.get(url).then(response => {
    dispatch({
      type: FILTER_BY_FACE_IDS_SUCCESS,
      payload: {...response}
    });
  });
};

export const viewMoreResults = () => (dispatch, getState) => {
  dispatch({type: VIEW_MORE_RESULTS});

  let {filters} = getState().inventory;
  filters.offset = parseInt(filters.limit) + parseInt(filters.offset);

  const url = `/api/v1/inventory.json?${param(filters)}`;
  $.get(url).then(response => {
    dispatch({
      type: FETCH_MORE_SUCCESS,
      payload: {...response}
    });
  });
};

export const viewAllResults = () => (dispatch, getState) => {
  dispatch({type: FETCH_ALL});

  let {filters} = getState().inventory;
  filters.offset = parseInt(filters.limit) + parseInt(filters.offset);
  filters.limit = 1000;
  filters.view_all = true;

  const url = `/api/v1/inventory.json?${param(filters)}`;
  $.get(url).then(response => {
    dispatch({
      type: FETCH_ALL_SUCCESS,
      payload: {...response}
    });
  });
};

export const setValidity = filters => dispatch => {
  dispatch({
    type: SET_VALIDITY,
    payload: true
  });
};

export const fetchRows = () => (dispatch, getState) => {
  const timestamp = Date.now();
  dispatch({
    type: FETCH_ROWS,
    payload: {timestamp}
  });

  const {filters} = getState().inventory;
  const url = `/api/v1/inventory.json?${param(filters)}`;

  $.get(url).then(response => {
    dispatch({
      type: FETCH_ROWS_SUCCESS,
      payload: {...response, timestamp}
    });
  });
};

export const updateRow = (rowId, values) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ROW,
    payload: {rowId, values}
  });

  const url = `/api/v1/units/${rowId}.json`;

  $.post(url, {unit: values, _method: "put"}).then(response => {
    dispatch({type: UPDATE_ROW_SUCCESS});
    dispatch(fetchRows());
  });
};

export const updateRows = (rowIds, values) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ROWS,
    payload: {rowIds, values}
  });

  const url = "/api/v1/units/mass_update";
  const params = {row_ids: rowIds, lead: values};

  $.post(url, params).then(response => {
    dispatch({type: UPDATE_ROWS_SUCCESS});
    dispatch(fetchRows());
  });
};

export const addRow = row => (dispatch, getState) => {
  dispatch({
    type: ADD_ROW,
    payload: {row}
  });

  const url = "/api/v1/units";
  $.post(url, {unit: row}).then(response => {
    if (response.errors) {
      GlobalActions.showError(`Invalid unit data: ${response.errors.join(", ")}`)
      return;
    } else {
      GlobalActions.showMessage('Unit created!');
      GlobalActions.openPopup("edit_unit", {unit: response, initialTab: "images"});
      dispatch({type: ADD_ROW_SUCCESS});
      dispatch(fetchRows());
    }
  });
};

export const showAddFlightModal = unit => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "add_flight",
      unit
    }
  });
};

export const fetchOpenUserSupplierTasks = (user_id) => async (dispatch, getState) => {
  const response = await get(`/api/v1/users/${user_id}/open_supplier_tasks`);
  if (response.status === 200) {
    dispatch({
      type: 'FETCH_OPEN_USER_SUPPLIER_TASKS',
      payload: { supplier_tasks: response.supplier_tasks }
    });
  }
}

export const showAddUnitsToRfpModal = unit => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "add_to_rfp",
      unit
    }
  });
};

export const fetchUnit = unit_id => (dispatch, getState) => {
  const {units} = getState().inventory;
  const unit = units.find(u => u.id == unit_id);

  dispatch({
    type: "FETCH_UNIT_SUCCESS",
    payload: {
      unit
    }
  });
};

export const showEditFlightModal = (unit, campaign_unit) => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "edit_flight",
      unit,
      campaign_unit
    }
  });
};

export const showAddUnitModal = () => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "add_unit"
    }
  });
};

export const showModal = modal_name => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: modal_name
    }
  });
};

export const showEditUnitModal = (unit, initialTab, campaignId) => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "edit_unit",
      unit,
      initialTab: initialTab,
      campaignId: campaignId
    }
  });

  GlobalActions.closePopup(); // adding this here because on the campaign tab, the popups overlay (Bootstrap vs. Custom)
  GlobalActions.openPopup("edit_unit", {unit, initialTab, campaignId});
};

export const showBatchAddFlightsModal = unit => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "holds",
      unit
    }
  });
};

export const showAddFlightsToCampaignModal = unit => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "add_to_campaign",
      unit
    }
  });
};

export const showUpdateAvailsModal = unit => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "update_avails",
      unit
    }
  });
};

export const showUploadSpecSheet = unit => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "upload_spec_sheet",
      unit
    }
  });
};

export const showUpdateAttributes = unit => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    payload: {
      name: "update_attributes",
      unit
    }
  });
};

export const hideModal = () => dispatch => {
  dispatch({type: HIDE_MODAL});

  GlobalActions.closePopup();
};

export const uploadUnitImages = (unitId, upload, file) => async dispatch => {
  try {
    const unitImage = await post(`/api/v1/units/${unitId}/unit_images`, {file_url: file.url});
    dispatch({type: UPLOAD_IMAGE_SUCCESS, payload: {unitImage}});
  } catch (error) {
    dispatch({type: UPLOAD_IMAGE_FAILURE});
    throw error;
  }
};

export const deleteUnitImage = (unitId, unitImageId) => dispatch => {
  $.ajax({
    type: "DELETE",
    url: `/api/v1/units/${unitId}/unit_images/${unitImageId}`,
    success: () => {
      dispatch({
        type: DELETE_IMAGE_SUCCESS,
        payload: {unitId, unitImageId}
      });

      dispatch(fetchRows());
    }
  }).fail(() => {
    dispatch({
      type: DELETE_IMAGE_FAILURE
    });
  });
};

export const fetchSuppliers = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_SUPPLIERS
  });

  const {suppliers} = getState().inventory;
  if (suppliers && suppliers.length > 0) {
    dispatch({
      type: FETCH_SUPPLIERS_SUCCESS,
      payload: suppliers
    });
  } else {
    const url = "/api/v1/suppliers";
    $.get(url).then(response => {
      dispatch({
        type: FETCH_SUPPLIERS_SUCCESS,
        payload: response
      });
    });
  }
};

export const fetchSalesUsers = () => async (dispatch, getState) => {
  dispatch({
    type: FETCH_SALES_USERS
  });

  const {sales_users} = getState().inventory;
  if (sales_users && sales_users.length > 0) {
    dispatch({
      type: FETCH_SALES_USERS_SUCCESS,
      payload: sales_users
    });
  } else {
    const url = "/api/v1/users/sales_users";
    const response = await $.get(url);
    if (!!!response.users) return;
    const sales_users = response.users.map(u => {
      return {id: u.id, name: u.name_or_email};
    });
    dispatch({
      type: FETCH_SALES_USERS_SUCCESS,
      payload: sales_users
    });
  }
};

export const fetchFaultReasons = () => async dispatch => {
  const response = await get("/api/v1/reports/fault_reasons");
  return response.fault_reasons;
};

export const fetchGeo = () => dispatch => {
  dispatch({
    type: FETCH_GEO
  });

  const url = "/api/v1/geojsons";

  $.get(url).then(response => {
    dispatch({
      type: FETCH_GEO_SUCCESS,
      payload: response
    });
  });
};

export const fetchSpecSheets = () => dispatch => {
  dispatch({
    type: FETCH_SPEC
  });

  const url = "/api/v1/geojsons";

  $.get(url).then(response => {
    dispatch({
      type: FETCH_SPEC_SUCCESS,
      payload: response
    });
  });
};

export const fetchFlightTypes = () => dispatch => {
  dispatch({
    type: FETCH_FLIGHT_TYPES
  });

  const url = "/api/v1/flight_types";

  $.get(url).then(response => {
    dispatch({
      type: FETCH_FLIGHT_TYPES_SUCCESS,
      payload: response
    });
  });
};

export const addToCampaign = (campaign, unit_ids) => dispatch => {
  dispatch({
    type: ADD_TO_CAMPAIGN
  });

  const values = {campaign, unit_ids};
  $.post(`/api/v1/campaigns/${campaign.id}/batch_add_units`, values, response => {
    GlobalActions.showMessage(`Adding ${response.units_count} units to campaign...`);
    dispatch({
      type: ADD_TO_CAMPAIGN_SUCCESS,
      payload: response
    });
  });
};

export const fetchContracts = (campaign_token, params) => async dispatch => {
  const contracts = await $.get(`/api/v1/campaigns/${campaign_token}/contracts${params}`);
  dispatch({
    type: FETCH_CONTRACTS_SUCCESS,
    payload: {contracts}
  });
};

export const requestNewLink = contract => async dispatch => {
  const res = await $.get(`/api/v1/contracts/request_new_link?contract_id=${contract.id}`);
  return res.data.new_link;
};

export const removeUnit = id => async dispatch => {
  const response = await $.ajax({url: `/api/v1/units/${id}/unit_visibility`, type: "DELETE"});
  if (response.status == 200) {
    dispatch({
      type: REMOVE_UNIT_SUCCESS,
      payload: {id}
    });
  } else GlobalActions.showError(`Error removing unit`);
  return response;
};

export const destroyContract = id => async dispatch => {
  try {
    const response = await $.ajax({url: `/api/v1/contracts/${id}`, type: "DELETE"});
    dispatch({ type: DESTROY_CONTRACT_SUCCESS, payload: {id} });
  } catch (error) {
    const { message } = error.responseJSON
    GlobalActions.showError(message);
  }
};

export const addContract = contract => async dispatch => {
  const res = await post("/api/v1/contracts/vendor_create", contract);
  if (res.data) {
    const { contract } = res.data;
    dispatch({
      type: ADD_CONTRACT_SUCCESS,
      payload: { contract }
    });
  } else if (res.error) GlobalActions.showError(res.error);
  else {
    console.log({res})
    GlobalActions.showError("An error occurred, please contact AdQuick")
  }
  ;
};

export const updateContract = ({campaign_token, payload}) => async dispatch => {
  try {
    const {contract} = await put(`/api/v1/campaigns/${campaign_token}/contracts/${payload.id}`, {contract: payload});
    dispatch({type: UPDATE_CONTRACT_SUCCESS, payload: {contract}});
    GlobalActions.showMessage("Contract updated successfully");
    GlobalActions.closePopup();
  } catch (error) {
    GlobalActions.showError(error);
  }
};

export const cancelSignatureRequest = (contract_id) => async (dispatch) => {
  try {
    const {contract} = await put(`/api/v1/contracts/${contract_id}/cancel_request`);
    dispatch({type: UPDATE_CONTRACT_SUCCESS, payload: {contract}});
    GlobalActions.showMessage("Signature request canceled successfully");
  } catch (error) {
    if (error == true){
      GlobalActions.showError(error);
    } else {
      GlobalActions.showError("There was an error processing your request");
    }
  }
}

export const contractCampaign = (campaign_token, contract) => async dispatch => {
  const res = await $.post(`/api/v1/campaigns/${campaign_token}/contracts/book_campaign`, contract);
  if (res.status == 200) {
    const {contract} = res.data;
    dispatch({
      type: CONTRACT_CAMPAIGN_SUCCESS,
      payload: {contract}
    });
    GlobalActions.showMessage("Campaign is now booked!");
  } else if (res.error) {
    GlobalActions.showError(res.error);
  } else {
    console.log({res})
    GlobalActions.showError("An error occurred, please contact AdQuick");
  }
};

export const fetchOrientations = (supplier) => dispatch => {
  dispatch({
    type: FETCH_ORIENTATIONS
  });
  const url = `/api/v1/inventory/orientation_options?supplier=${supplier || ''}`;
  $.get(url).then(response => {
    dispatch({
      type: FETCH_ORIENTATIONS_SUCCESS,
      payload: response
    });
  });
};

export const clearOrientations = () => dispatch => {
  dispatch({
    type: CLEAR_ORIENTATIONS
  });
};

export const fetchSizes = (supplier) => dispatch => {
  dispatch({
    type: FETCH_SIZES
  });
  const url = `/api/v1/inventory/size_options?supplier=${supplier || ''}`;
  $.get(url).then(response => {
    dispatch({
      type: FETCH_SIZES_SUCCESS,
      payload: response
    });
  });
};

export const updateAvails = unit_ids => async (dispatch, getState) => {
  try {
    const res = await $.post('/api/v1/campaign_units/update_avails', {unit_ids});
    GlobalActions.showMessage('Avails are being updated. Refresh to see.');
  } catch (e) {
    GlobalActions.showError('There was an error updating avails, please contact AdQuick');
    throw e;
  }
}

