const initialState = {
  campaign_analytics: [],
  goals: [],
  options: []
};

export default (state = initialState, action) => {
  switch (action.type) {
      case "GET_CAMPAIGN_ANALYTICS_SUCCESS": 
        return loadCampaignAnalytics(state, action.payload);
      default: return state;
  }
};

function loadCampaignAnalytics(state, { campaign_analytics, options, goals }) {
  return {
    ...state,
    campaign_analytics,
    goals,
    options
  };
}
