import { Modal } from 'react-bootstrap';
import React from 'react';

import DemoRequest from './DemoRequest';

export default ({show, onHide}) => (
  <Modal
    show={show}
    onHide={onHide}
    className="sign-up-modal campaign-request-modal"
    animation={!window.mobileCheck()}
    id="campaign-request-modal">
    <DemoRequest />
  </Modal>
);
