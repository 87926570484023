import { LOAD_UNIT_TAGS } from "../actions/unit_tag_actions";
import { UnitTag } from "../models/unit_tag";

interface Action {type: string; payload: any; }

export default(state: UnitTag[], action: Action): UnitTag[] => {
  switch (action.type) {
    case LOAD_UNIT_TAGS: return action.payload.unit_tags;
    default: return state || [];
  }
};
