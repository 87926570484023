import * as React from 'react';
import _ from 'lodash';
import cs from 'classnames';
import { get } from '../utils/api'

class HelpForm extends React.Component<any, any> {

  baseState = {
    error_message: "",
    success_message: "",
    request_type: {},
    priority: {},
    feature_id: null,
    eta: "",
    details: "",
    solutions: "",
    who: "",
    link: "",
    screenshot: "",
    user_id: "",
    isSubmitting: false,
    submitted: false,
    features: [],
    priorities: [],
    issue_types: []
  }

  constructor(props) {
    super(props);
    this.state = this.baseState
    this.onInputChange = this.onInputChange.bind(this)
    this.onFeatureChange = this.onFeatureChange.bind(this)
    this.onPriorityChange = this.onPriorityChange.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onRequestTypeChange = this.onRequestTypeChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getSupportAttributes()
  }

  checkValid() {
    this.setState({ error_message: "" })
    const required_fields = ['request_type', 'feature_id', 'eta', 'details', 'link']
    let error_message;
    let missing_fields: string[] = [];
    required_fields.map(field => { if (this.fieldHasError(field)) missing_fields.push(field) })
    if (missing_fields.length > 0) {
      error_message = `The following required fields are missing: ${missing_fields.join(", ")}`
      this.setState({ error_message: error_message })
      return false
    } else {
      return true
    }
  }

  async getSupportAttributes() {
    const { attributes: { features, priorities, issue_types } } = await get("/api/v1/support_tickets/attributes")
    this.setState({ features, priorities, issue_types })
  }

  async onSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true, submitted: true })
    if (!this.checkValid()) {
      this.setState({ isSubmitting: false })
      return;
    }
    try {
      // @ts-ignore
      const inspectlet_id = window.__insp ? window.__insp.sid : null
      const params = Object.assign({}, this.state, { inspectlet_id })
      const result = await $.post('/api/v1/support_tickets', params)
      if (result.response_message) {
        this.setState({ success_message: result.response_message, isSubmitting: false })
      }
    } catch {
      this.setState({ error_message: "There was a problem submitting your request, please ping #support.", isSubmitting: false })
    }
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  onFeatureChange(value) {
    this.setState({ feature_id: value });
  }

  onPriorityChange(value) {
    const { priorities } = this.state
    const priority = priorities.find(f => f.id == value )
    this.setState({ priority: { id: priority.id, value: priority.name } })
  }

  onRequestTypeChange(value) {
    const { issue_types } = this.state
    const request_type = issue_types.find(i => i.id == value )
    this.setState({ request_type: { id: request_type.id, value: request_type.name } })
  }

  onSelectChange({ target }) {
    const { name, value } = target
    const flow = {
      priority: this.onPriorityChange,
      feature: this.onFeatureChange,
      request_type: this.onRequestTypeChange
    }
    return flow[name](value)
  }

  renderFeatureList() {
    const { features } = this.state
    const ordered = _.orderBy(features, ['value'], ['asc'])
    return ordered.map((f, idx) => <option key={`feature-${idx}`} value={f.id}>{f.value}</option>)
  }

  renderRequestType() {
    const { issue_types } = this.state
    return issue_types.map((i, idx) => <option key={`issuetype-${idx}`} value={i.id}>{i.name}</option>)
  }

  resetPreviousForm() {
    return {
      error_message: "",
      success_message: "",
      request_type: {},
      feature: {},
      priority: {},
      eta: "",
      details: "",
      solutions: "",
      who: "",
      link: "",
      screenshot: "",
      user_id: "",
      submitted: false
    }
  }

  fieldHasError(field) {
    return _.isEmpty(this.state[field]);
  }

  render() {
    const { submitted, request_type, eta, priority, feature, details, solutions, link } = this.state

    if (this.state.success_message) {
      return (
        <div className="help_form">
          <h1>Engineering Request Form</h1>
          <div className="alert alert-success">
            Your request has been successfully submitted!
            <br />
            <br />
            <div dangerouslySetInnerHTML={{ __html: this.state.success_message }}></div>
          </div>
          <a onClick={() => {this.setState(this.resetPreviousForm())}}>Submit another request</a>
        </div>
      )
    }

    return (
      <div className="help_form">

        <h1>Engineering Request Form</h1>

        <form>

          {this.state.error_message &&
            <div className="alert alert-danger">{this.state.error_message}</div>
          }

          <div className={cs('form-group', {'has-error': submitted && this.fieldHasError('request_type')})}>
            <label>What type of request is this?*</label>
            <select className="form-control" name="request_type" onChange={this.onSelectChange}>
              <option>Choose one</option>
              {this.renderRequestType()}
            </select>
          </div>

          <div className={cs('form-group', {'has-error': submitted && this.fieldHasError('eta')})}>
            <label>When must this be completed by?</label>
            <select className="form-control" name="eta" onChange={this.onInputChange}>
              <option>Choose one</option>
              <option value="asap">ASAP - Blocker for platform access, demo, RFPs, or checkout</option>
              <option value="48hs"> Less than 2 days - Bug interfering with client or vendor operations </option>
              <option value="5_7_days">1 week - Bug interfering with admin operations</option>
              <option value="no_timeline">No timeline - Do at some point, but just nice to know</option>
            </select>
          </div>

          <div className={cs('form-group', {'has-error': submitted && this.fieldHasError('feature_id')})}>
            <label>What feature is this related to?*
              <div className="sublabel">Use this field so we can send a direct SMS for the responsible or the team.</div>
            </label>

            <select className="form-control" name="feature" onChange={this.onSelectChange}>
              <option>Choose one</option>
              {this.renderFeatureList()}
            </select>
          </div>

          <div className={cs('form-group', {'has-error': submitted && this.fieldHasError('link')})}>
            <label>Link*</label>
            <input type="text" className="form-control" name="link" onChange={this.onInputChange} />
          </div>

          <div className={cs('form-group', {'has-error': submitted && this.fieldHasError('details')})}>
            <label>Request Details (additional links, user information, steps to reproduce, etc.)*</label>
            <textarea className="form-control" name="details" onChange={this.onInputChange} />
          </div>

          <div className="form-group">
            <label>Screenshot or gif (use CloudApp)</label>
            <input type="text" className="form-control" name="screenshot" onChange={this.onInputChange} />
          </div>

          <div className="form-group">
            {this.state.isSubmitting ?
              <input type="submit" className="adstrap_button" value="Submitting..." disabled />
              :
              <input type="submit" className="adstrap_button" value="Submit" onClick={this.onSubmit} />
            }
          </div>
        </form>
      </div>
    )
  }

}

export default HelpForm;
