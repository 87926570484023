import React from 'react';
import ReactDOMServer from 'react-dom/server';
import mapboxgl from 'mapbox-gl';
import polylabel from '@mapbox/polylabel'
import accounting from 'accounting';

export default class ZipCodePopups {

  constructor(map) {
    this.map = map;
    this.popups = {};
  }

  toggle(zip_code) {
    if(!!zip_code.properties) { return }
    if (this.popups[zip_code.properties.id]) {
      this.remove()
    } else {
      this.show(zip_code);
    }
  }

  show(zip_code) {
    if (this.popups[zip_code.properties.id]) return;
    this.remove();

    const description = this.zipCodeDescription(zip_code.properties);
    if (!description) return;

    const polygon = zip_code.geometry.type === "MultiPolygon" ? zip_code.geometry.coordinates[0] : zip_code.geometry.coordinates
    const coordinates = polylabel(polygon, 1.0);
    if (!coordinates) return;

    const popup = new mapboxgl.Popup({ closeButton: false });
    popup.setLngLat(coordinates).setHTML(description).addTo(this.map);

    this.popups[zip_code.properties.id] = popup;
  }

  remove() {
    for (let zip in this.popups) {
      if (this.popups.hasOwnProperty(zip)) {
        this.popups[zip].remove();
      }
    }
    this.popups = {};
  }

  zipCodeDescription(zip_code) {
    if (zip_code.popup_content) return zip_code.popup_content;

    const score_text = this.scoreText(zip_code.score, zip_code.score_type);

    return ReactDOMServer.renderToString(
      <div className="text-left">
        <div><b>{zip_code.zip_code}</b></div>
        <div>{zip_code.place_name}, {zip_code.state_abbreviation}</div>
        {score_text ? <div>{score_text}</div> : ''}
        {zip_code.clicks || zip_code.clicks === 0 ? <div>{zip_code.clicks} clicks</div> : ''}
        {zip_code.cost ? <div>{zip_code.cost} cost</div> : ''}
        {zip_code.ctr || zip_code.ctr === 0 ? <div>{zip_code.ctr} CTR</div> : ''}
        {zip_code.cpc || zip_code.cpc === 0 ? <div>{zip_code.cpc} CPC</div> : ''}
        {zip_code.impressions || zip_code.impressions === 0 ? <div>{zip_code.impressions} impressions</div> : ''}
        {zip_code.conversions || zip_code.conversions === 0 ? <div>{zip_code.conversions} conversions</div> : ''}
      </div>
    );
  }

  scoreText(score, scoreType = null) {
    let scoreText;
    if (score) {
      if (scoreType == "n") {
        scoreText = accounting.formatNumber(score)
      } else {
        if (score.toFixed().toString().length > 2) {
          scoreText = "$" + score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          scoreText = score + "%";
        }
      }
    }
    return scoreText
  }
}
