import * as React from 'react';
import _ from 'lodash';
import cs from 'classnames';
import GlobalActions from "../../../../actions/GlobalActions";
import { post } from '../../../../utils/api';

class Card extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.setupOrAuthorize = this.setupOrAuthorize.bind(this)
    this.isCompleted = this.isCompleted.bind(this)
  }

  setupOrAuthorize() {
    const { card } = this.props
    if(card.button_text === 'authorize') { return this.authorizeAnalytics() }
    if(card.button_text === 'setup') { return this.onOpenModal() }
  }

  onOpenModal() {
    const { onComplete, onUpdate, card, token, addStep, old_analytics } = this.props
    const analytic = this.findCardOnAnalytics()
    if(card.key == 'surveys' || card.key == 'geofenced_mobile_ads') { return addStep({id: card.key}) }
    GlobalActions.openPopup("analytics_setup", { onComplete, onUpdate, analytic, card, token, old_analytics });
  }

  authorizeAnalytics() {
    const { token, card, onComplete } = this.props
    const options = {
      google_analytics: 'analytics_access',
      segment_integration: '',
      apps_flyer: '',
      branch: '',
      google_adwords: 'adwords_access',
      visit_tracking: ''
    }
    if(card.key === 'facebook_ads' || card.key === 'instagram_ads') {
      return this.facebookFlow()
    }
    const analytic = this.findCardOnAnalytics()
    onComplete(analytic)
    const url = `/auth/${options[card.key]}?return_to=/campaigns/${token}/analytics/configure&campaign_token=${token}`
    return window.location.href = url
  }

  facebookFlow() {
    const { onComplete, token } = this.props
    // @ts-ignore
    FB.login(async (response) => {
      const analytic = this.findCardOnAnalytics()
      //onComplete(analytic, {})
      await post('/auth/facebook_callback', response.authResponse)
      return await post(`/api/v1/campaigns/${token}/facebook_analytics_reports`)
    })
  }

  isCompleted() {
    if(!this.props.analytics) { return false }
    const analytic = this.findCardOnAnalytics()
    return _.get(analytic, 'completed')
  }

  isUnderConstruction() {
    const { userPermissions } = this.props
    const userFlagToSkipCallbacks = ( userPermissions && userPermissions.can_skip_campaign_analytics_callback)
    if(!this.props.analytics || userFlagToSkipCallbacks) { return false }
    const analytic = this.findCardOnAnalytics()
    return [7,8,13,14].includes(analytic.analytics_option_id)
  }

  findCardOnAnalytics() {
    const { card, analytics } = this.props    
    return analytics.find(a => a.analytics_card_id == card.id) || {}
  }

  render() {
    const { card, onSelect, finalStep, reminder, thing } = this.props;
    const isCardCompleted = this.isCompleted();
    const isCardUnderConstruction = this.isUnderConstruction();
    const cardClassNames = cs("card", {
      selected: card.checked,
      completed: isCardCompleted,
      short: finalStep,
    });

    return (
      <div className={cardClassNames}
        onClick={() => onSelect(card, thing) }>
        {reminder && !isCardCompleted && 
          <div className="reminder"></div>
        }
        <div>
          {!finalStep &&
            <label className="checkbox-label">
              <input type="checkbox" checked={card.checked} onChange={() => card.checked = !card.checked} />
              <span className="checkbox-custom rectangular"></span>
            </label>
          }
        </div>
        <div style={{marginLeft: finalStep ? 0 : "20px"}}>
          <div className="title">
            {card.icon && <div className="analytics_fa_icon"><i className={`fa ${card.icon}`}></i></div>}
            {!card.icon && <div className={`analytics_icon ${card.key}`}></div>}
            <h5>{card.text}</h5>
          </div>
          {!finalStep && card.description &&
            <div className="description" dangerouslySetInnerHTML={{ __html: card.description }} />
          }
        </div>
        {finalStep &&
          <div className="buttons">
            {!isCardCompleted &&
              <button className="primary-button" onClick={this.setupOrAuthorize}>{card.button_text}</button>
            }
            {isCardCompleted && !isCardUnderConstruction &&
              <div className="completed_container">
                <span className="setup_completed">
                  <i className="fa fa-check-circle-o"></i>
                  Setup Complete
                </span>
                {card.button_text === "setup" &&
                  <span className="btn_edit" onClick={this.setupOrAuthorize}>Edit</span>
                }
              </div>
            }
            {isCardUnderConstruction &&
              <span className="under_construction">
                <div className="analytics_icon construction_truck"></div>
                Coming Soon
              </span>
            }
          </div>
        }
        {!finalStep &&
          <div className="labels">
            {card.suggestion &&
              <span className="suggestion">{card.suggestion}</span>
            }
            {card.price &&
              <span className="tag">{card.price}</span>
            }
            {card.recommended && 
              <span className="recommended">Recommended</span>
            }
          </div>
        }
      </div>
    )
  }
}

export default Card;