import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";

import AddCustomPOILogo from "./AddCustomPOILogo";

class AddCustomPOILogoModal extends Component {
  constructor(props) {
    super(props);
    this.onHide = this.onHide.bind(this);
  }

  onHide() {
    const { onHide } = this.props;
    onHide();
  }

  render() {
    const { campaign_token, places } = this.props;
    return (
      <Modal show={this.props.show} onHide={this.onHide} className="custom_poi_logo_modal">
        <Modal.Header>
          <Modal.Title>Add POI logo or icon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCustomPOILogo campaign={campaign_token} places={places} onHide={this.onHide} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="primary-blue" onClick={this.onHide} style={{width: '50%'}}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(({ campaign, places_ui }) => ({
  campaign_token: campaign.campaign.token,
  places: places_ui && places_ui.places ? places_ui.places.filter(p => p.saved) : [],
}))(AddCustomPOILogoModal);
