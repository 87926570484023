import { connect } from "react-redux";
import { formatMoney } from "accounting";
import React, { Component } from "react";
import cs from "classnames";

import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";

const POPUP_WIDTH = 275;

class CpmFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = Object.assign(
      {
        isExpanded: false,
        minCpm: null, // last filtered by
        maxCpm: null, // last filtered by
        minCpmLiveValue: "", // not-yet-applied value of input
        maxCpmLiveValue: "", // not-yet-applied value of input
      },
      this.props.initialValue,
    );
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, initialValue } = this.props
    if (!isActive && prevProps.isActive ) { this.onClear() }
    if (initialValue.minCpm !== prevProps.initialValue.minCpm) { this.setState({ minCpm: initialValue.minCpm, minCpmLiveValue: initialValue.minCpm })}
    if (initialValue.maxCpm !== prevProps.initialValue.maxCpm) { this.setState({ maxCpm: initialValue.maxCpm, maxCpmLiveValue: initialValue.maxCpm })}
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onChange(event) {
    let minCpmLiveValue = this.minCpmInput.value;
    let maxCpmLiveValue = this.maxCpmInput.value;
    if (isNaN(parseFloat(minCpmLiveValue))) minCpmLiveValue = "";
    if (isNaN(parseFloat(maxCpmLiveValue))) maxCpmLiveValue = "";
    this.setState({ minCpmLiveValue, maxCpmLiveValue });
  }

  onFilter() {
    const { minCpmLiveValue, maxCpmLiveValue } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    const min_cpm = this.handleCpmValue(minCpmLiveValue);
    const max_cpm = this.handleCpmValue(maxCpmLiveValue);
    
    const filter = { min_cpm, max_cpm } 
    this.setState({ minCpm: min_cpm, maxCpm: max_cpm, isExpanded: false });
    _.defer(filterUnits, filter);
    handleQueryParams(filter)
  }

  handleCpmValue(value) {
    if (isNaN(parseFloat(value))) { return null }
    return value
  }

  onClear() {
    this.setState(
      {
        minCpm: null, // last filtered by
        maxCpm: null, // last filtered by
        minCpmLiveValue: "", // not-yet-applied value of input
        maxCpmLiveValue: "", // not-yet-applied value of input
      },
      this.onFilter
    );
  }

  getButtonLabel() {
    const { minCpm, maxCpm } = this.state;
    const formattedMinCpm = formatMoney(minCpm);
    const formattedMaxCpm = formatMoney(maxCpm);
    if (!minCpm && !maxCpm) return "CPM";
    if (minCpm && maxCpm) return `${formattedMinCpm}–${formattedMaxCpm} CPM`;
    if (minCpm) return `${formattedMinCpm}+ CPM`;
    if (maxCpm) return `Up to ${formattedMaxCpm} CPM`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { minCpmLiveValue, maxCpmLiveValue } = this.state;
    return (
      <div className="filter_popup cpm_range_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>CPM RANGE</h4>
        <div className="padded_content">
          <p>All pricing is per 4 weeks</p>
          <div className="range_inputs">
            <input
              type="number"
              min="0"
              value={minCpmLiveValue}
              onChange={this.onChange}
              placeholder="Min"
              ref={node => (this.minCpmInput = node)}
            />
            <input
              type="number"
              min="0"
              value={maxCpmLiveValue}
              onChange={this.onChange}
              placeholder="Max"
              ref={node => (this.maxCpmInput = node)}
            />
          </div>
          <p className="actions">
            <a className="clear hidden-xs" onClick={e => this.onClear()}>
              Clear
            </a>
            <a onClick={this.onFilter}>Apply</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded, minCpm, maxCpm } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(null, { filterUnits })(CpmFilter);
