import * as React from "react";

// TODO: Refactor this to use theme colors instead.

const NOTIFICATION_THEME = {
  alert: { bgColor: "#FFF3E0", faColor: "#F5A623", color: "#70511E", faIcon: "exclamation-triangle" },
  warn: { bgColor: "#FFE0E0", faColor: "#F52323", color: "#701E1E", faIcon: "times-circle" },
  success: { bgColor: "#C4ECD3", faColor: "#44AC6B", color: "#1E6739", faIcon: "check" },
};

export enum NotifyType {
  Warn = "warn",
  Alert = "alert",
  Success = "success",
}

export const Title = ({ children }: { children: React.ReactNode }) =>
  <h3 style={titleStyle()}>{children}</h3>;

export interface IGroup {
  children: React.ReactNode;
  title: string;
}

export const Group = ({ children, title }: IGroup) => (
  <div style={groupStyle()}>
    <h4 style={groupTitleStyle()}>{title}</h4>
    {children}
  </div>
);

export const Separator = () => <hr style={separatorStyle()} />;

export const Sidenav = ({ children }: { children: React.ReactNode }) => (
  <div style={sidenavStyle()}>
    {children}
  </div>
);

export interface IButtonProps {
  active?: boolean;
  dot?: NotifyType;
}

export interface IButtonState {
  hover: boolean;
}

export class Button extends React.Component<IButtonProps, IButtonState> {
  constructor(props: IButtonProps) {
    super(props);
    this.state = { hover: false };
  }
  public render() {
    const { active, children } = this.props;
    return <button
      onMouseOver={() => this.setState({ hover: true })}
      onMouseOut={() => this.setState({ hover: false })}
      style={buttonStyle(this.state.hover, active)}>
      {this.props.dot && <div style={notifyDotStyle(this.props.dot)}></div>}
      {children}
    </button>;
  }
}

export interface INotifyProps {
  children: React.ReactNode;
  type: NotifyType;
}

export const Notify = ({ children, type }: INotifyProps) => (
  <div style={notifyStyle(type)}>
    <i style={notifyFaStyle(type)} className={`fa fa-${NOTIFICATION_THEME[type].faIcon}`}></i>
    {children}
  </div>
);

const notifyStyle = (type: NotifyType) => ({
  background: NOTIFICATION_THEME[type].bgColor,
  color: NOTIFICATION_THEME[type].color,
  borderRadius: "8px",
  lineHeight: "32px",
  padding: "0 16px",
  fontSize: "13px",
  ...horizontalMargin,
  marginBottom: "12px",
} as React.CSSProperties);

const notifyFaStyle = (type: NotifyType) => ({
  color: NOTIFICATION_THEME[type].faColor,
  marginRight: "10px",
} as React.CSSProperties);

const notifyDotStyle = (type: NotifyType) => ({
  display: "inline-block",
  width: "8px",
  height: "8px",
  borderRadius: "4px",
  background: NOTIFICATION_THEME[type].faColor,
  transform: "translate(-10px, -2px)",
  verticalAlign: "middle",
} as React.CSSProperties);

const sidenavStyle = () => ({
  height: "auto",
  background: "#FFF",
  borderRadius: "8px",
  padding: "28px 0",
} as React.CSSProperties);

const titleStyle = () => ({
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "17px",
  color: "#000",
  marginBottom: "17px",
  ...horizontalPadding,
} as React.CSSProperties);

const buttonStyle = (hover: boolean, active?: boolean) => ({
  display: "block",
  width: "calc(100% - 30px - 12px)",
  lineHeight: "32px",
  color: active ? "#FFF" : (hover ? "#06366b" : "#4A90E2"),
  fontSize: "15px",
  fontWeight: active ? 500 : "normal",
  background: active ? "#4A90E2" : "transparent",
  transitionProperty: "color, background",
  borderRadius: active ? "4px" : 0,
  transition: ".3s ease-in-out",
  textAlign: "left",
  margin: "0 21px",
  padding: "0 10px",
} as React.CSSProperties);

const groupStyle = () => ({
  paddingBottom: "18px",
} as React.CSSProperties);

const groupTitleStyle = () => ({
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "15px",
  letterSpacing: "0.15em",
  textTransform: "uppercase",
  color: "#858585",
  marginTop: "10px",
  marginBottom: "7px",
  ...horizontalPadding,
} as React.CSSProperties);

const separatorStyle = () => ({
  border: 0,
  margin: "20px 0",
  borderBottom: "1px solid #EEEEEE",
} as React.CSSProperties);

const horizontalPadding = {
  padding: "0 21px 0 31px",
};

const horizontalMargin = {
  margin: "0 21px 0 31px",
};

export default {
  Sidenav,
  Button,
  Title,
  Group,
  Separator,
  Notify,
  NotifyType,
};
