import { connect } from 'react-redux';
import React from 'react'
import BottomNav from '../BottomNav';
import moment from 'moment';
import MarketConfiguration from './MarketConfiguration';
import Datetime from 'react-datetime';
import Summary from '../Summary';
import {
  addSpendGroupToCampaign,
  selectCampaign,
  toggleAddSpendGroupToCampaign,
  updateNewCampaignName,
  updateCampaignStartDate,
  updateCampaignEndDate,
} from '../actions';

class Geos extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selected_weeks: ""
    }
    this.handleClick = this.handleClick.bind(this);
    this.renderHowLongButtons = this.renderHowLongButtons.bind(this);
    this.renderDates = this.renderDates.bind(this);
  }

  handleClick(e){
    e.preventDefault();
    this.setState({selected_weeks: e.target.dataset.value});
  }

  renderHowLongButtons(){
    const howLongOptions = [
      { label: "4 weeks", value: 4 },
      { label: "8 weeks", value: 8 },
      { label: "12 weeks", value: 12 },
      { label: "24 weeks", value: 24 },
      { label: "32 weeks", value: 32 },
      { label: "40 weeks", value: 40 },
      { label: "48 weeks", value: 48 },
      { label: "52 weeks", value: 52 },
    ]
    return(
      <div className="week-values-panel">
        { howLongOptions.map((item, index) => <div className="week-value" key={item.label} onClick={this.handleClick} data-value={item.value}>{item.label}</div>) }
      </div>
     )
  }

  renderDates(){
    return (
      <div className="planner-dates-panel">
        <Datetime
          dateFormat="MMM D, YYYY"
          timeFormat={false}
          closeOnSelect
          defaultValue={this.props.campaign_start_date || ''}
          inputProps={{ placeholder: 'Projected Start Date' }}
          onChange={date => this.props.updateCampaignStartDate((date && date.format && date.format("MMM D, YYYY") || date))}
          />
        <div className="planner-dates-icon"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></div>
        <Datetime
          dateFormat="MMM D, YYYY"
          timeFormat={false}
          closeOnSelect
          value={this.props.campaign_end_date || '' }
          inputProps={{ placeholder: 'Projected End Date' }}
          onChange={date => this.props.updateCampaignEndDate((date && date.format && date.format('MMM D, YYYY') || date))}
        />
       </div>
     )
  }

  render() {
    const { spend_groups } = this.props
    const is_spend_groups_ready = !this.props.spend_groups.some(group => group['spend'] <= 0 || '');

    return (
      <div className="section">
        <div className="row">
          <div className="col-md-4">
            <div className="subsection">
              <div className="heading">Configure Markets</div>
              <div className="subheading">Choose the spend level for each of your markets</div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="configure-locations">
              {spend_groups.map(spend_group =>
                <MarketConfiguration key={`spend-group-${spend_group.id}`} {...spend_group}/>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="subsection">
              <div className="heading">Campaigns Dates</div>
              <div className="subheading">Select the dates your campaign is going to run</div>
            </div>
          </div>
          <div className="col-md-8">
            <div>
              {this.renderDates()}
            </div>
          </div>
        </div>
        <div className="bottom-last-footer">
          <div className="section">
            <div className="row">
              <div className="col-md-4">
                <div className="subsection">
                  <div className="heading">Plan Summary</div>
                  <div className="subheading">Review the campaign details and save your campaign</div>
                </div>
              </div>
              <div className="col-md-8">
                <Summary />
                <BottomNav />
             </div>
            </div>
           </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ planner }) => ({ ...planner }), 
  { toggleAddSpendGroupToCampaign, selectCampaign, addSpendGroupToCampaign, updateNewCampaignName, updateCampaignStartDate, updateCampaignEndDate, }
)(Geos)
