import { Panel, Tabs, Tab, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { get, put } from '../../../utils/api';
import { updateRow } from '../actions';
import { createCampaignUnit, loadCampaignUnits, removeCampaignUnitByToken, saveCampaignUnit } from '../../Campaign/actions';
import GlobalActions from '../../../actions/GlobalActions';
import Images from './Images';
import SpecSheetTab from './SpecSheetTab';
import Tags from './Tags';
import UnitForm from './UnitForm';
import MaintenanceTab from './MaintenanceTab';
import LeaseTab from './LeaseTab';
import Loading from '../../Loading';
import CampaignUnitEditTab from "./CampaignUnitEditTab";

class EditModal extends React.Component {

  constructor(props) {
    super(props);
    const initialTab = this.props.initialTab ? this.props.initialTab : 'details';
    this.state = {
      unit: null,
      specSheets: [],
      geoJsons: [],
      activeTab: initialTab,
      saving: false,
      campaign_unit: null,
      loading: true,
      dupe_error: null
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitCampaignUnits = this.onSubmitCampaignUnits.bind(this);
    this.onDeleteCampaignUnit = this.onDeleteCampaignUnit.bind(this);
    this.saveCampaignUnit = this.saveCampaignUnit.bind(this);
    this.afterAssignSpecSheet = this.afterAssignSpecSheet.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  campaign() {
    const { campaign } = this.props;
    return campaign && campaign.campaign;
  }

  isTalon() {
    const campaign = this.campaign();
    return campaign && campaign.agency_id == 123;
  }

  campaignToken() {
    const campaign = this.campaign();
    return campaign && campaign.token;
  }

  async componentDidMount() {
    await this.loadData();
  }

  afterAssignSpecSheet(spec_sheet) {
    this.setState({
      unit: {
        ...this.state.unit,
        spec_sheet,
      },
    });
  }

  async loadUnit(unit_id) {
    const unit = await get(`/api/v1/units/${unit_id}`, null,{ campaign_id: this.props.campaignId });
    unit.campaign_unit_token = this.props.unit.campaign_unit_token
    unit.multi_flight = this.props.unit.multi_flight
    this.setState({ unit });
  }

  async loadCampaignUnit(campaign_unit_token) {
    const { status, data } = await get(`/api/v1/campaign_units/${campaign_unit_token}`);
    if (status === 200) {
      this.setState({ campaign_unit: data });
    }
  }

  async loadSpecSheets(supplier_id) {
    const { records } = await get(`/api/v2/spec_sheets?supplier_id=${supplier_id}`);
    this.setState({ specSheets: records });
  }

  async loadGeoJsons() {
    const geoJsons = await get('/api/v1/geojsons');
    this.setState({ geoJsons });
  }

  async loadData() {
    const { unit } = this.props;
    if (!!unit) {
      await this.loadUnit(unit.id);
      await this.loadSpecSheets(unit.supplier_id);
    }
    if (!!unit && unit.campaign_unit_token) {
      await this.loadCampaignUnit(this.props.unit.campaign_unit_token);
    }
    await this.loadGeoJsons();
    this.setState({ loading: false });
  }

  async saveUnit(unit) {
    const res = await put(`/api/v1/units/${this.props.unit.id}`, { unit });

    if (res.errors) {
      GlobalActions.showError(`Error saving unit: ${res.errors.join(',')}`)
    }
    else if (res.dupe_error) {
      this.setState({ dupe_error: res.dupe_error });
      GlobalActions.showError(`Error saving unit: possible duplicate found. Please review the unit details and try again.`)
    }
    else {
      this.loadData();
      GlobalActions.showMessage('Unit details were saved!');
    }
    this.setState({ saving: false });
  }

  onSubmit(unit) {
    this.setState({ saving: true }, () => {
      this.saveUnit(unit);
    });
  }

  async saveCampaignUnit(campaign_unit) {
    const {createCampaignUnit, saveCampaignUnit} = this.props;
    const cu_token = campaign_unit.token;

    if (cu_token) {
      await saveCampaignUnit(cu_token, campaign_unit);
    } else {
      await createCampaignUnit(campaign_unit);
    }

    this.setState({ saving: false }, this.loadData);
  }

  onSubmitCampaignUnits(campaignUnits) {
    this.setState({ saving: true, loading: true }, () => {
      campaignUnits.forEach((campaign_unit) => this.saveCampaignUnit(campaign_unit) );
    });
  }

  onDeleteCampaignUnit(campaign_unit_token) {
    this.props.removeCampaignUnitByToken(campaign_unit_token);
    this.loadData();
  }

  renderUnitSearchLink() {
    const dupe_error = this.state.dupe_error;
    if(dupe_error) {
      return (
        <div>
          <p>
            {dupe_error.message} <a href={dupe_error.url} target="_blank">Admin search page</a>
          </p>
          <p>Try replacing the current unit with the correct one, then reach for support so the units can be merged</p>
        </div>
      )
    }
  }

  renderModalBody() {
    const { user, onHide } = this.props;
    const { activeTab, saving, loading, specSheets, geoJsons, unit, campaign_unit } = this.state;
    const campaign_units = unit && unit.multi_flights ? unit.multi_flights : [unit];
    if(loading) { return <Loading/> }
    return (
      <Tabs
        activeKey={activeTab}
        onSelect={activeTab => this.setState({ activeTab })}
        id="edit-unit-tabs"
      >
        <Tab
          title="Unit"
          eventKey="details"
          disabled={!this.checkTab('details')}
        >
          <Panel style={{ border: 0 }}>
            <UnitForm
              geoJsons={geoJsons}
              unit={unit}
              user={user}
              onSubmit={this.onSubmit}
              loadData={this.loadData}
              saving={saving} />
          </Panel>
          {this.renderUnitSearchLink()}
        </Tab>
        <Tab
          title="Attributes"
          eventKey="tags"
          disabled={!this.checkTab('tags')}
        >
          <Panel style={{ border: 0 }}>
            <Tags unit_id={unit.id}/>
          </Panel>
        </Tab>
        {campaign_unit && <Tab
          title="Campaign Unit"
          eventKey="campaign_unit_details"
          disabled={!this.checkTab('cu_details')}
        >
          <Panel style={{ border: 0 }}>
            <CampaignUnitEditTab
              campaign_units={campaign_units}
              onSubmit={this.onSubmitCampaignUnits}
              onDeleteCampaignUnit={this.onDeleteCampaignUnit}
              isTalon={this.isTalon()}
              user={user}
              saving={saving}
              loading={loading} />
          </Panel>
        </Tab>}
        <Tab
          title="Images"
          eventKey="images"
          disabled={!this.checkTab('images')}
        >
          <Images unit={unit} user={user} onDelete={this.loadData} onUpload={this.loadData} afterReorder={ () => {this.campaignToken() && this.props.loadCampaignUnits(this.campaignToken())} } />
        </Tab>
        <Tab
          title="Spec Sheets"
          eventKey="spec_sheet"
          disabled={!this.checkTab('spec_sheet')}
        >
          <SpecSheetTab
            unit_id={unit.id}
            verified={!!(unit.spec_sheet_verified_at)}
            spec_sheets={specSheets}
            current_spec_sheet={unit.spec_sheet}
            afterAssignSpecSheet={this.afterAssignSpecSheet}
            loadData={this.loadData}
            is_admin={user.is_admin}
            auditData={unit.spec_sheet_audit}
          />
        </Tab>
        {user && (user.is_supplier_admin || user.is_admin) &&
          <Tab
            title="Maintenance"
            eventKey="maintenance"
          >
            <MaintenanceTab unit={unit} onHide={onHide} />
          </Tab>
        }
        {user && (user.is_supplier_admin || user.is_admin) &&
          <Tab
            title="Lease"
            eventKey="lease"
          >
            <LeaseTab unit={unit} onHide={onHide} />
          </Tab>
        }
      </Tabs>
    )
  }

  checkTab(tab) {
    const { user } = this.props;
    const isAgency = user.is_agency;
    const isAgencyAdmin = user.is_agency_admin;
    const isAdmin = user.is_admin || user.is_supplier_admin || user.is_supplier_seller;
    const tabs = {
      details: isAdmin,
      tags: isAdmin,
      cu_details: isAdmin || isAgency,
      images: isAdmin || isAgencyAdmin,
      spec_sheet: isAdmin
    }
    return tabs[tab];
  }

  render() {
    const { show, onHide } = this.props; 
    const { unit } = this.state;

    return (
      <Modal show={show} onHide={onHide} bsSize="large" animation={false}>
        <Modal.Header>
          <Modal.Title>{unit ? `Edit ${unit.name}` : 'Edit Unit' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderModalBody()}
        </Modal.Body>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  unit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    campaign_unit_token: PropTypes.string,
    supplier_id: PropTypes.number
  }).isRequired
};

export default connect(
  ({ campaign }) => ({ campaign }),
  { updateRow, saveCampaignUnit, removeCampaignUnitByToken, createCampaignUnit, loadCampaignUnits }
)(EditModal);
