import {
  SET_CAMPAIGN_ID,
  FETCHING_SUPPLIER_TASKS,
  FETCH_SUPPLIER_TASKS_SUCCESS,
  ADD_SUPPLIER_TASK_SUCCESS,
  UPDATE_SUPPLIER_TASK_SUCCESS,
  DELETE_SUPPLIER_TASK_SUCCESS
 } from "./actions";

const initialState = {
  isFetching: false,
  campaign_id: null,
  supplier_tasks: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_ID: {
      const { campaign_id } = action.payload;
      return {
        ...state,
        campaign_id
      };
    }
    case FETCHING_SUPPLIER_TASKS: {
      return {
        ...state,
        isFetching: true
      }
    }
    case FETCH_SUPPLIER_TASKS_SUCCESS: {
      const { supplier_tasks } = action.payload;
      return {
        ...state,
        supplier_tasks,
        isFetching: false
      };
    }
    case ADD_SUPPLIER_TASK_SUCCESS: {
      const added_supplier_tasks = action.payload.supplier_tasks;
      const { supplier_tasks } = state;

      return {
        ...state,
        supplier_tasks: supplier_tasks.concat(added_supplier_tasks)
      };
    }
    case UPDATE_SUPPLIER_TASK_SUCCESS: {
      const { supplier_task } = action.payload;
      const { supplier_tasks } = state;
      const idx = supplier_tasks.findIndex(st => st.id === supplier_task.id);
      supplier_tasks[idx] = supplier_task;
      return {
        ...state,
        supplier_tasks: [...supplier_tasks]
      };
    }
    case DELETE_SUPPLIER_TASK_SUCCESS: {
      const { supplier_task_id } = action.payload;
      const { supplier_tasks } = state;
      return {
        ...state,
        supplier_tasks: supplier_tasks.filter(st => st.id !== supplier_task_id)
      };
    }
    default: {
      return state;
    }
  }
}
