import React from 'react';
import createClass from 'create-react-class';
import { get, put } from '../../utils/api';
import PopTile from './PopTile';

const PopsTab = createClass({
  displayName: 'PopsTab',

  getInitialState() {
    return {
      unit: this.props.unit,
      campaign_unit_token: this.props.campaignUnitToken,
      loading: true,
    }
  },

  componentWillMount() {
    this.getPops();
  },

  getPops() {
    const { campaign_unit_token } = this.state;
    const campaign_unit_url = `/api/v1/proof_of_postings/all_for_campaign_unit?campaign_unit_token=${campaign_unit_token}`;
    const url = campaign_unit_token ? campaign_unit_url : null;

    if (url) {
      get(url)
        .then(result => {
          this.setState({
            pops: result.pops,
            loading: false,
          })
        })
    }
  },

  loadingSpinner() {
    const { loading } = this.state;
    return (
      <div id="pops-tab">
        {loading && <div className="loading-spinner">
          <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        </div>}
      </div>
    )
  },

  renderInfoSection() {
    const about_text = `Select a proof of posting image to show for this unit`

    return (
      <div className="pop-info-section">
        <i className="fa fa-info-circle" aria-hidden="true"></i>{about_text}
        {this.showSelectedImage()}
      </div>
    )
  },

  renderNoPops() {
    const text = 'This unit has no proof of posting images available.'

    return (
      <div className="pop-none-available">
        <i className="fa fa-info-circle" aria-hidden="true"></i>{text}
      </div>
    )
  },

  onImageSelect(e) {
    const { campaign_unit_token } = this.state;
    const image_url = e.target.src;
    const url = `/api/v1/campaign_units/${campaign_unit_token}/simple_update.json`
    const params = {
      campaign_unit: {
        image: image_url
      }
    }
    put(url, params).then((response) => {
      this.setState({
        unit: response.data.campaign_unit,
      })
    })
  },

  clearImage() {
    const { campaign_unit_token } = this.state;
    const url = `/api/v1/campaign_units/${campaign_unit_token}/simple_update.json`
    const params = {
      campaign_unit: { image: null }
    }
    put(url, params).then((response) => {
      this.setState({
        unit: response.data.campaign_unit,
      })
    })
  },

  showSelectedImage() {
    const { unit, selected_image } = this.state;
    const image_url = unit.image

    if (image_url) {
      return (
        <div className="selected-pop-image-area">
          <img className="selected-pop-image" src={image_url}></img>
          <button onClick={this.clearImage}>Clear</button>
        </div>
      )
    } else {
      return (
        <div className="selected-pop-image">
          No image selected
        </div>
      )
    }

  },

  render() {
    const { unit, loading, pops, campaign_unit_token } = this.state;
    const any_pops = pops && pops.length > 0;

    return (
      <div className="pops-tab">
        <div className="spinner-area">
          {this.loadingSpinner()}
        </div>
        {(!loading && any_pops) && this.renderInfoSection()}
        {(!loading && !any_pops) && this.renderNoPops()}
        {any_pops && <div className="tiles">
          {pops && pops.map((pop, index) =>
            <PopTile
              pop={pop}
              key={index}
              unit={unit}
              campaignUnitToken={campaign_unit_token}
              onImageSelect={this.onImageSelect}
            />
          )}
        </div>}
      </div>
    );
  }
});

export default PopsTab;
