import React from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import moment from 'moment';
import DateSearch from './DateSearch';
import Price from './Price';
import Cpm from './Cpm';
import ScreenType from './ScreenType';
import ActiveStatus from './ActiveStatus';
import MediaType from './MediaType';
import UnitTags from './UnitTags';
import FaceId from './FaceId';
import LeaseCode from './LeaseCode';
import Supplier from './Supplier';
import Advertiser from './Advertiser';
import Campaign from './Campaign';
import Market from './Market';
import Sort from './Sort';
import Orientation from './Orientation';
import MoreFilters from './MoreFilters';
import Size from './Size';
import UrlParams from '../../../utils/UrlParams';

class FiltersBar extends React.Component {

  constructor(props) {
    super(props);
    const params = new UrlParams(location.search);
    this.state = {
      filters: {
        startDate: params.get('startDate') ? moment(params.get('startDate')) : null,
        endDate: params.get('endDate') ? moment(params.get('endDate')) : null,
        partial_date_match: params.get('partial_date_match'),
        include_holds: params.get('include_holds'),
        min_price: params.get('min_price'),
        max_price: params.get('max_price'),
        min_cpm: params.get('min_cpm'),
        max_cpm: params.get('max_cpm'),
        screen_type: params.get('screen_type'),
        type: params.get('type'),
        unit_tag: params.get('unit_tag'),
        supplier: params.get('supplier'),
        query: params.get('query'),
        advertiser: params.get('advertiser'),
        campaign: params.get('campaign'),
        market: params.get('market'),
        sortBy: params.get('sortBy'),
        orientation: params.get('orientation'),
        size: params.get('size'),
        only_units_with_pricing: params.get('only_units_with_pricing'),
        only_units_without_pricing: params.get('only_units_without_pricing'),
        only_units_with_picture: params.get('only_units_with_picture'),
        only_units_without_picture: params.get('only_units_without_picture'),
        packages_only: params.get('packages_only'),
        exclude_packages: params.get('exclude_packages'),
        only_cannabis_friendly: params.get('only_cannabis_friendly'),
        exclude_units_with_restrictions: params.get('exclude_units_with_restrictions'),
        only_units_with_rate_card_pricing: params.get('only_units_with_rate_card_pricing'),
        stale_availability: params.get('stale_availability'),
        has_attribution: params.get('has_attribution'),
        no_attribution: params.get('no_attribution')
      }
    };
    this.setFilter = this.setFilter.bind(this);
    this.onClearAll = this.onClearAll.bind(this);
    this.setUrlParams = this.setUrlParams.bind(this);
  }

  componentDidMount() {
    if (location.search) {
      this.onFilterUnits(false);
    }
  }

  setFilter(nameOrObject, value, liveFilter = true) {
    const filters = Object.assign({}, this.state.filters);
    if (typeof nameOrObject === "object") {
      for (let key in nameOrObject) {
        filters[key] = nameOrObject[key];
      }
    } else {
      filters[nameOrObject] = value;
    }
    this.setState({ filters }, liveFilter ? this.onFilterUnits : null);
  }

  setUrlParams() {
    const filters = this.state.filters || {};
    let paramString = Object.entries(filters).map(([key, val]) => {
      if (val === null || val === '' || (Array.isArray(val) && val.length === 0) || val === false || key === 'market'){
        return null;
      }
      if ((key === 'unit_tag') && Array.isArray(val)) {
        return `${key}=${val.map((v) => v.value).join(',')}`;
      }
      if (key === 'orientation') {
        if (typeof(val) === 'string') {
          return `${key}=${val}`;
        }
        return `${key}=${val.value}`;
      }
      if (key === 'startDate' || key === 'endDate') {
        return `${key}=${val.toString()}`;
      }
      return `${key}=${val}`;
    }).filter((i) => i !== null).join('&');
    history.pushState(null, null, `?${paramString}`);
  }

  onClearAll() {
    this.setFilter({
      'startDate': null,
      'endDate': null,
      'min_price': null,
      'max_price': null,
      'min_cpm': null,
      'max_cpm': null,
      'screen_type': null,
      'type': null,
      'unit_tag': null,
      'supplier': null,
      'query': '',
      'advertiser': null,
      'campaign': null,
      'market': null,
      'sortBy': null,
      'orientation': null,
      'size': null,
      'only_units_with_pricing': null,
      'only_units_without_pricing': null,
      'only_units_with_picture': null,
      'only_units_without_picture': null,
      'packages_only': null,
      'exclude_packages': null,
      'only_cannabis_friendly': null,
      'exclude_units_with_restrictions': null,
      'only_units_with_rate_card_pricing': null,
      'stale_availability': null,
      'partial_date_match': null,
      'include_holds': null,
      'verified_only': null,
      'unverified_only': null,
      'has_attribution': null,
      'no_attribution': null
    });
  }

  onFilterUnits(setParams=true) {
    const filters = Object.assign({}, this.state.filters);
    if (filters.unit_tag) {
      if (Array.isArray(filters.unit_tag)) {
        filters.unit_tag = filters.unit_tag.map(tag => tag.value);
      } else {
        filters.unit_tag = filters.unit_tag.split(',');
      }
    };
    if (filters.orientation) {
      if (typeof(filters.orientation) === "object") {
        filters.orientation = filters.orientation.value
      }
    };
    if (filters.size && Array.isArray(filters.size)) {
      filters.size = filters.size.join(',');
    };
    if (filters.market) filters.market = filters.market.map(market => market.value);
    if (filters.startDate) filters.startDate = moment(filters.startDate).format();
    if (filters.endDate) filters.endDate = moment(filters.endDate).format();
    this.props.onFilter(filters);
    if (setParams) {
      this.setUrlParams();
    };
  }

  render() {
    const { currentUser, units } = this.props;
    const isAdmin = currentUser ? currentUser.is_admin : false;
    const { filters } = this.state;
    return (
      <div className="campaign-tab__filters" ref={node => this.node = node}>
        <div className="filters_bar">
          <ul className="filters_bar_list" id="filters_bar_list">
            <Sort setFilter={this.setFilter} filters={filters} />
            <DateSearch setFilter={this.setFilter} filters={filters} />
            <Price setFilter={this.setFilter} filters={filters} />
            <Cpm setFilter={this.setFilter} filters={filters} />
            <MediaType setFilter={this.setFilter} filters={filters} />
            <ScreenType setFilter={this.setFilter} filters={filters} units={units} />
            <ActiveStatus setFilter={this.setFilter} filters={filters} />
            <Size setFilter={this.setFilter} filters={filters} />
            <Orientation setFilter={this.setFilter} filters={filters} />
            {isAdmin &&
              <Supplier setFilter={this.setFilter} filters={filters} />
            }
            <UnitTags setFilter={this.setFilter} filters={filters} />
            <Advertiser setFilter={this.setFilter} filters={filters} />
            <Campaign setFilter={this.setFilter} filters={filters} />
            <Market setFilter={this.setFilter} filters={filters} />
            <MoreFilters setFilter={this.setFilter} filters={filters} />
            <li className="clear_filters">
              <a onClick={this.onClearAll}>Reset filters</a>
            </li>
            <FaceId setFilter={this.setFilter} filters={filters} />
            <LeaseCode setFilter={this.setFilter} filters={filters} />
          </ul>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ currentUser, inventory: { units } }) => ({ currentUser, units }), {}
)(FiltersBar)
