import React, { Component } from 'react';
import { BootstrapTable } from 'react-bootstrap-table';
import { numberFormatter, dollarFormatter } from '../../../utils/formatters';
import GlobalActions from '../../../actions/GlobalActions';

const errorCellFormatter = (cell, row, property, rowIdx) => {
  if (property == "price" && cell) cell = dollarFormatter(cell, row);
  if (property == "floor_price" && cell) cell = dollarFormatter(cell, row);
  if (property == "production_cost" && cell) cell = dollarFormatter(cell, row);
  if (property == "inventory_file_price" && cell) cell = dollarFormatter(cell, row);
  if (property == "impressions" && cell) cell = numberFormatter(parseInt(cell), row);
  if (!row.fields_with_errors.includes(property)) return cell;
  const value = cell || `missing ${property}`
  return `<span style='color: #D21C29'>${value}</span>`
}

const viewUnitLink = (cell, row, callback, rowIdx) => {
  return <a onClick={() => {
    const id = row.unit_id;
    GlobalActions.openUnitID(id);
  }}>View unit</a>
}

const unitImporterLink = (cell, row, callback, rowIdx) => {
  return <a target="_blank" href={`/unit_importer/${row.inventory_file_id}`}>Unit Importer</a>
}

class IssueGroup extends Component {

  constructor(props) {
    super(props);
  }

  deleteUnitLink(cell, row, callback) {
    const unitId = row.unit_id;
    const { campaignId, hasRequests } = this.props;
    if (hasRequests) {
      return (
        <a
          data-remove-units-campaign-id-value={campaignId}
          data-remove-units-unit-id-value={unitId}
          data-remove-units-use-stimulate-value={false}
          data-controller="remove-units"
          data-action="click->remove-units#openRemovalReasonModal"
        >
          Delete Unit 
        </a>
      )
    } else {
      return (
        <a onClick={(e) => this.handleDeleteUnit(e, row)}>
          Delete unit
        </a>
      )
    }
  }

  handleDeleteUnit(e, { campaign_unit_token }) {
    const { deleteCampaignUnit } = this.props;
    e.preventDefault();
    const confirmed = confirm("Are you sure you want to remove this unit from the campaign?")
    if(confirmed) { return deleteCampaignUnit(campaign_unit_token) }
  }

  render() {
    const headingStyle = { margin: '24px 0' };

    const { heading, columns, fetchQaIssues, campaignId } = this.props;
    const issues = this.props.issues || []
    const options = {
      noDataText: 'There are no issues with the campaign for this group.'
    };

    const inventory_file_id_column = columns.find((c) => c.property === "inventory_file_id");

    return(
      <div className='issueGroup' style={{marginBottom: 40}}>
        <h4 style={headingStyle}>{heading}</h4>
        <BootstrapTable
          data={issues}
          options={options}
          keyField='unit_id'
          bordered={false}>
          {columns.map((column) =>
            <TableHeaderColumn
              key={column.property}
              dataField={column.property}
              hidden={column.hidden || false }
              dataFormat={errorCellFormatter}
              formatExtraData={column.property}
              width={column.width || '150'}
              dataAlign={column.dataAlign || 'left'}>{column.label}</TableHeaderColumn>
          )}
          <TableHeaderColumn
            dataFormat={viewUnitLink}
            formatExtraData={() => fetchQaIssues(campaignId)}
            dataAlign='center'>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.deleteUnitLink.bind(this)}
            formatExtraData={() => fetchQaIssues(campaignId)}
            dataAlign='center'>
          </TableHeaderColumn>
          { inventory_file_id_column &&
            <TableHeaderColumn
              dataFormat={unitImporterLink}
              dataAlign='center'></TableHeaderColumn>
          }
        </BootstrapTable>
      </div>
    );
  }
}

export default IssueGroup;
