import React from 'react';
import createClass from 'create-react-class';
import { Carousel } from 'react-bootstrap'
import { browserHistory } from 'react-router-legacy'
import { Helmet } from 'react-helmet';

const push = browserHistory.push

const CompanyDescription = () => (
  <div>
    <p><b>About AdQuick</b></p>
    <ul>
      <li>Our mission is to make it easy to buy and sell outdoor advertising anywhere in the world.</li>
      <li>Garry Tan, Alexis Ohanian and Tony Hsieh's VC firms <a href="https://techcrunch.com/2017/07/14/adquick-seed-funding/">invested in our $1.1M seed round</a> in March 2017.</li>
      <li>We're in sunny, Venice, CA. {"You'll love it here."}</li>
      <li>We've retained all of our major clients with each buying multiple times. They include Lyft, Instacart, Peloton, H&R Block & more.</li>
    </ul>
  </div>
)

const Slideshow = () => (
  <div style={{height:410,overflow:'hidden'}}>
    <Carousel>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/venice-1.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/venice-2.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/venice-4.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/office-1.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/office-2.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/office-3.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/instacart.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/peloton.jpg" /></div></Carousel.Item>
      <Carousel.Item><div className="carousel-item"><img src="/_careers/brightcellar.png" /></div></Carousel.Item>
    </Carousel>
  </div>
)

const CareersIndex = ({ params }) => {
  if (params.job == "hacker") {
    const bullets = [
      "Our tech stack is Rails 4, Webpack, React, Postgres, Heroku.",
      "3+ years experience in running production systems, ability to iterate quickly and sense of ownership.",
      "Strong focus on user experience and putting the customer first."
    ]

    return (
      <Job position="Full Stack Hacker" bullets={bullets} />
    )
  } else if (params.job == "marketing") {
    const bullets = [
      "You've been embedded in the ad/marketing agency world and can make use of your rolodex of contacts immediately.",
      "You can position and sell the benefits of AdQuick: efficiency, cost savings, metrics in a lens that resonates with agencies.",
      "You’re independent and voracious - we’re a start-up with no brand name (yet!). You are hell bent on changing that and will shake every necessary branch to get this done.",
      "You have a technical perspective, or at least love using new tech products. Much of your role will be communicating product needs to help make AdQuick an agency’s best friend",
      "You’re analytical and love measuring and quantifying impacts of marketing spend.",
      "You have experience and/or are interested in being the face of AdQuick at relevant marketing events and expos.",
      "You like to have fun and have a great sense of humor! This is perhaps the most important factor of all, as we’re currently a small team and personality fit is huge!"
    ]

    return (
      <Job position="Head of Marketing/Agency Partnerships" bullets={bullets} />
    )
  } else if (params.job == "choose") {
    const bullets = [
      "Other roles aren't a perfect fit, but you're interested in AdQuick? Perfect - let us know how you think you can help. There's always room for smart, mission-driven, folks who are ready to make a huge impact."
    ]

    return (
      <Job position="Choose your own adventure" bullets={bullets} />
    )
  } else {
    return (
      <div>
        <div className="openings">
          <h1 style={{marginBottom:15}}>Careers at AdQuick</h1>
          <p>
            <b>Current Openings</b>
          </p>
          <p>
            <b className="opening" onClick={() => push('/careers/hacker')}>Full Stack Hacker</b>
            <span>Los Angeles, CA</span>
          </p>
          <p>
            <b className="opening" onClick={() => push('/careers/marketing')}>Head of Marketing/Agency Partnerships</b>
            <span>Los Angeles, CA or New York, NY</span>
          </p>
          <p>
            <b className="opening" onClick={() => push('/careers/choose')}>Choose your own adventure</b>
            <span>Los Angeles, CA</span>
          </p>
        </div>
        <div>
          <p>
            <a className="more-jobs" href="/jobs">View more jobs & job details on AngelList</a>
          </p>
        </div>
      </div>
    )
  }
}

const Job = ({ position, bullets }) => (
  <div>
    <p style={{marginTop:20}}>
      <a href="#" onClick={() => push("/careers")}><i className="fa fa-arrow-left" /> Careers</a>
    </p>
    <h1 style={{marginBottom:15}}>{position}</h1>
    <CompanyDescription />
    <hr />
    <p><b>About the role</b></p>
    <ul>{bullets.map((bullet) => <li>{bullet}</li>)}</ul>
    <p>If {"you're interested"}, please email us with your LinkedIn profile and/or try one of these:</p>
    <ul>
      <li>Go through AdQuick.com and send us product feedback on our user flows with recommendations.</li>
      <li>If {"you're"} a hacker, use inspector to check out /api/v1/units. Download some data and do something fun with it.</li>
    </ul>
    <p><b className="text-large">Contact us <a href="mailto:campaign@adquick.com">campaign@adquick.com</a></b></p>
    <hr />
    <p><b>More reasons why to join AdQuick</b></p>
    <ul>
      <li>We are changing an entire industry.</li>
      <li>Making lives better for marketers and media owners across the world.</li>
      <li>Hard challenges and fun people.</li>
      <li>True startup phase. Very few people. You can run fast and have a lot of responsibility.</li>
      <li>We've made something that people want and we will keep making it better.</li>
      <li>Venice, CA.</li>
      <li>Every major client has retained. Net negative churn.</li>
      <li>We're working on things that have an effect on the real physical world.</li>
      <li>People love our product. {"We're handling buys"} that range from $500 to $200K+.</li>
    </ul>
    <p><b>A little more about AdQuick</b></p>
    <ul>
      <li><a href="https://techcrunch.com/2017/07/14/adquick-seed-funding/" target="_blank">TechCrunch Seed Funding Announcement</a></li>
      <li><a href="https://news.adquick.com/drake-saved-6k-in-instagram-spend-using-adquick-heres-how-eb109b8d06f0" target="_blank">How we used image recognition to measure ROI for Drake/OVO's campaign</a></li>
      <li><a href="https://news.adquick.com/the-slow-journey-to-the-adquick-launch-7d66ebc756df" target="_blank">The Long, Slow Journey to the AdQuick Launch</a></li>
      <li>We've been testing drone footage for high value billboards. <a href="https://player.vimeo.com/video/228174062" target="_blank">Check out a sample.</a></li>
    </ul>
    <hr />
    <p><b>Photos of our neighborhood and previous campaigns</b></p>
    <Slideshow />
  </div>
);

const Careers = (props) => (
  <div className="container careers">
    <Helmet>
      <title>Careers | AdQuick</title>
      <link rel="amphtml" href="https://amp.adquick.com/amp/careers.html"/>
    </Helmet>
    <CareersIndex {...props} />
  </div>
)

export default Careers
