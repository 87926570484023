import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import Async from "react-select/async";
import createClass from 'create-react-class';
import validate from 'validate.js';

import GlobalActions from '../../../actions/GlobalActions';
import Loading from '../../Loading';

export default createClass({
  displayName: 'CampaignGeopathSegmentForm',

  getInitialState() {
    return {
      campaign_geopath_segment: this.props.campaign_geopath_segment,
      geopath_segments: []
    };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      campaign_geopath_segment: this.props.campaign_geopath_segment,
    })
  },

  getGeopathSegments(input) {
    return $.get("/api/v1/geopath_segments", {q: input}).then((response) => {
      const segments = response['geopath_segments'] || [];
      const options = segments.map(({name, id}) => ({label: name, value: id}));
      this.setState({ geopath_segments: options });
      return options;
    })
  },

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit()
  },

  render() {
    const { segment_type, campaign_geopath_segment, saving } = this.props
    const { name, geopath_segment_id, age, hhi, race, gender } = campaign_geopath_segment
    const valid = name
    const age_options = [
      {label: '18-20', value: '18-20'},
      {label: '21-24', value: '21-24'},
      {label: '25-29', value: '25-29'},
      {label: '30-34', value: '30-34'},
      {label: '35-39', value: '35-39'},
      {label: '40-44', value: '40-44'},
      {label: '45-49', value: '45-49'},
      {label: '50-54', value: '50-54'},
      {label: '55-59', value: '55-59'},
      {label: '60-64', value: '60-64'},
      {label: '65-69', value: '65-69'},
      {label: '70-74', value: '70-74'},
      {label: '75-79', value: '75-79'},
      {label: '80-84', value: '80-84'},
      {label: '85+', value: '85+'},
    ]
    const hhi_options = [
      {label: '<$25k', value: '<$25k'},
      {label: '$25k-$50k', value: '$25k-$50k'},
      {label: '$50k-$75k', value: '$50k-$75k'},
      {label: '$75k-$100k', value: '$75k-$100k'},
      {label: '$100k-$150k', value: '$100k-$150k'},
      {label: '$150k+', value: '$150k+'}
    ]
    const race_options = [
      {label: 'Asian', value: 'Asian'},
      {label: 'Black', value: 'Black'},
      {label: 'Hispanic', value: 'Hispanic'},
      {label: 'White', value: 'White'}
    ]
    const gender_options = [
      {label: 'Female', value: 'Female'},
      {label: 'Male', value: 'Female'},
    ]

    return (<div className="geopath-segment-form">
      <form className="form" onSubmit={this.onSubmit}>
        <div className="form-group row">
          <div className="col-sm-12">
            <label>Audience Title</label>
            <input className="form-control" value={name || ""} onChange={e => this.props.onUpdateAttribute('name', e.target.value)} />
          </div>
        </div>
        { segment_type == "psycographic" &&
          <div className="form-group row">
            <div className="col-sm-12">
              <label>Target Psychographic</label>
              <Async
                className="selectInput"
                placeholder="Search for Psychographic"
                loadOptions={this.getGeopathSegments}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                value={this.state.geopath_segments.find(o => o.value === geopath_segment_id)}
                onChange={e => this.props.onUpdateAttribute('geopath_segment_id', e.value)}
              />
            </div>
          </div>
        }
        { segment_type == "demographic" &&
          <div>
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Target Demographic</label>
                <Select
                  className="selectInput"
                  placeholder="Age"
                  options={age_options}
                  value={age_options.find(o => o.value === age)}
                  onChange={e => this.props.onUpdateAttribute('age', e.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Select
                  className="selectInput"
                  placeholder="HHI"
                  options={hhi_options}
                  value={hhi_options.find(o => o.value === hhi)}
                  onChange={e => this.props.onUpdateAttribute('hhi', e.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Select
                  className="selectInput"
                  placeholder="Race"
                  options={race_options}
                  value={race_options.find(o => o .value === race)}
                  onChange={e => this.props.onUpdateAttribute('race', e.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <Select
                  className="selectInput"
                  placeholder="Gender"
                  options={gender_options}
                  value={gender_options.find(o => o.value === gender)}
                  onChange={e => this.props.onUpdateAttribute('gender', e.value)}
                />
              </div>
            </div>
          </div>
        }
        <div className="form-group row">
          <div className="col-sm-12">
            <input className="btn btn-primary" type="submit" onClick={this.onSubmit} disabled={!valid || saving} value="Generate" />
          </div>
        </div>
        { saving && <Loading />}
      </form>
    </div>);
  },
});
