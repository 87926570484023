/* UNIT ACTIONS **/
import GlobalActions from '../../../actions/GlobalActions'
import { get, post, del, put } from '../../../utils/api';

export const fetchUnitTagOptions = () => async (dispatch, getState) => {
  const response = await get('/api/v1/unit_tags/options_for_select');
  if (response.status === 200) {
    dispatch({
      type: 'FETCH_UNIT_TAG_OPTIONS',
      payload: { unit_tag_options: response.options_for_select }
    });
  }
}

export const clearUnitTags = () => (dispatch, getState) => {
  dispatch({ type: 'CLEAR_UNIT_TAGS' })
}

export const fetchUnitTags = (unit_id) => async (dispatch, getState) => {
  const response = await get(`/api/v1/unit_tags/${unit_id}`);
  if (response.status === 200) {
    dispatch({
      type: 'FETCH_UNIT_TAGS',
      payload: { unit_tags: response.unit_tags }
    });
  }
}

export const createUnitTags = (unit_id, tags) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'CREATE_UNIT_TAGS_BEGIN' });
    const custom_tags = tags.map(tag => tag.value);
    const payload = { unit_id, custom_tags };
    const response = await post("/api/v1/unit_tags/set_for_unit", payload);
    dispatch({ type: 'CREATE_UNIT_TAGS_SUCCESS', payload: { unit_tags: response.unit_tags } });
    GlobalActions.showMessage("Unit tags updated");
  } catch (error) {
    console.log(error);
  }
}

export const setSelectedUnits = selected_unit_ids => (dispatch, getState) => {
  dispatch({
    type: 'SET_SELECTED_UNITS',
    payload: { selected_unit_ids },
  })
}

export const selectAllUnits = () => (dispatch, getState) => {
  dispatch({ type: 'SELECT_ALL_UNITS' })
}

export const deselectUnits = () => (dispatch, getState) => {
  dispatch({ type: 'DESELECT_UNITS' })
}

export const createTag = (tag) => async (dispatch) => {
  try {
    dispatch({ type: "CREATE_TAG", payload: { tag } });
    await post("/api/v1/custom_tags", { tag });
    dispatch(fetchUnitTagOptions());
  } catch (error) {
    dispatch({ type: "CREATE_TAG_ERROR", payload: error });
  }
}

export const setMaintenanceDetails = (data) => async (dispatch) => {
  return await post(`/api/v1/units/${data.unit_id}/set_maintenance`, {
    status: data.status,
    fault_reason: data.fault_reason ? data.fault_reason.value : null,
    fault_reason_other: data.fault_reason_other,
    retired_at: data.retired_at,
    maintenance_notes: data.maintenance_notes
  });
}

export const fetchUnitLease = (unit_id) => async (dispatch, getState) => {
  return await get(`/api/v1/unit_leases/${unit_id}`);
}

export const saveUnitLease = (data) => async (dispatch) => {
  return await post(`/api/v1/unit_leases`, {
    id: data.unit_id,
    name: data.name,
    code: data.code,
    start_date: data.start_date,
    end_date: data.end_date,
    retired_at: data.retired_at,
    notes: data.notes,
    status: data.status
  });
}

export const createLeaseAttachment = (file_url, unit_lease_id) => async (dispatch) => {
  return await post(`/api/v1/unit_leases/${unit_lease_id}/unit_lease_attachments`, { file_url });
}

export const detroyLeaseAttachment = (unit_lease_id, attachment_id) => async (dispatch) => {
  return await del(`/api/v1/unit_leases/${unit_lease_id}/unit_lease_attachments/${attachment_id}`);
}

export const fetchLeaseAttachments = (unit_lease_id) => async (dispatch) => {
  return await get(`/api/v1/unit_leases/${unit_lease_id}/unit_lease_attachments`);
}

export const deleteUnitTag = (unit_id, tag_id) => async (dispatch) => {
  try {
    dispatch({ type: "DESTROY_UNIT_TAG", payload: { unit_id, tag_id } });
    await del(`/api/v1/unit_tags/${unit_id}/${tag_id}`);
    dispatch(fetchUnitTags(unit_id));
    dispatch(fetchUnitTagOptions());
  } catch (error) {
    dispatch({ type: "DESTROY_UNIT_TAG_ERROR", payload: error });
  }
}

export const updateUnit = (unit_id, params) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_UNIT", payload: { unit_id, params } });
    await put(`/api/v1/units/${unit_id}`, { unit: params })
  } catch (error) {
    console.log(error)
  }
}

export const bulkUpdateUnit = (unit_ids, params) => async (dispatch) => {
  try {
    dispatch({ type: "BULK_UPDATE_UNITs", payload: { unit_ids, params } });
    await post(`/api/v1/units/bulk_update`, { unit_ids, ...params })
  } catch (error) {
    console.log(error)
  }
}
