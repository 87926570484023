import "./button.css";
import * as React from "react";
import Theme from "../../theme";
import { invert as invertColor } from "../../helpers/color";
import { FontSizeProperty, FontWeightProperty } from "csstype";

export enum ButtonColor {
  Default = "PRIMARY_COLOR",
  PrimaryLight = "PRIMARY_LIGHT_COLOR",
  PrimaryTransparent = "PRIMARY_TRANSPARENT_COLOR",
  PrimaryWhite = "PRIMARY_WHITE_COLOR",
  FacebookBlue = "FACEBOOK_COLOR",
  Accent = "ACCENT_COLOR",
  AccentLight = "ACCENT_LIGHT_COLOR",
  Warn = "WARN_COLOR",
  Neutro = "NEUTRO_COLOR",
  Disabled = "DISABLED_COLOR",
  DisabledDark = "DISABLED_DARK_COLOR",
  Shadow = "SHADOW_COLOR",
  Success = "SUCCESS_COLOR",
  ShadowLink = "SHADOW_LINK_COLOR",
}

export enum ButtonSize {
  Big = 15,
  Default = 14,
  Small = 10,
  Tiny = 7,
}
export interface IButtonProp {
  className?: string;
  children: React.ReactNode | string;
  faIcon?: string;
  faIconFontSize?: string;
  Icon?: React.FunctionComponent;
  color?: ButtonColor;
  size?: ButtonSize;
  width?: string;
  wide?: boolean;
  invert?: boolean;
  disabled?: boolean;
  fontSize?: FontSizeProperty<string>;
  fontWeight?: FontWeightProperty;
  removeBorder?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onMouseOver?: (event: React.MouseEvent) => void;
  onMouseOut?: (event: React.MouseEvent) => void;
  noMargin?: boolean;
}

export interface IButtonState {
  isHover?: boolean;
}

export class Button extends React.Component<IButtonProp, IButtonState> {
  constructor(props: IButtonProp) {
    super(props);
    this.state = {};
  }

  private get mouseEvents() {
    return {
      onClick: (event: React.MouseEvent) => this.props.onClick && this.props.onClick(event),
      onMouseOver: (event: React.MouseEvent) => {
        if (this.props.onMouseOver) {
          this.props.onMouseOver(event);
        }
        this.setState({ isHover: true });
      },
      onMouseOut: (event: React.MouseEvent) => {
        if (this.props.onMouseOut) {
          this.props.onMouseOut(event);
        }
        this.setState({ isHover: false });
      },
    };
  }

  private get faIcon() {
    return <i style={faIconStyle(this.props)} className={`fa ${this.props.faIcon}`}></i>;
  }

  public render() {
    const { Icon, color, className: buttonClasses } = this.props;
    return (
      <button
        {...this.mouseEvents}
        disabled={this.props.disabled}
        style={buttonStyle(this.props, this.state.isHover)}
        className={`button-color-${color} ${buttonClasses}`}
      >
        {Icon && (
          <div className="ui-button__icon-wrapper">
            <Icon />
          </div>
        )}
        {this.props.faIcon && this.faIcon}
        {this.props.children}
      </button>
    );
  }
}

const buttonStyle = (
  {
    color = ButtonColor.Default,
    size = ButtonSize.Default,
    invert = false,
    disabled = false,
    removeBorder = true,
    fontSize,
    width,
    wide,
    fontWeight,
    noMargin
  }: IButtonProp,
  isHover?: boolean,
): React.CSSProperties => {
  const paddingWidth = wide ? size * 2.2 : size;
  const colorRule = Theme[color];
  const colorTheme = invert ? invertColor(colorRule) : colorRule;
  if (disabled) {
    isHover = false;
  }
  let lineHeight = "20px";
  if (size == ButtonSize.Big) lineHeight = "30px";
  if (size == ButtonSize.Small) lineHeight = "14px";
  return {
    display: "inline",
    outline: "none",
    padding: `${size / 2.8}px ${paddingWidth}px`,
    fontSize: fontSize || `${size}px`,
    color: colorTheme.comp,
    border: `1px solid ${isHover && removeBorder ? colorTheme.comp : removeBorder ? colorTheme.color : colorTheme.comp}`,
    background: isHover ? colorTheme.hover : colorTheme.color,
    borderRadius: "17px",
    verticalAlign: "middle",
    cursor: disabled ? "normal" : "pointer",
    fontWeight: fontWeight || 500,
    margin: `0 ${noMargin ? 0 : (size / 2)}px`,
    transitionProperty: "background, color",
    transition: "0.3s ease",
    ...{ lineHeight },
    ...(size === ButtonSize.Big ? { borderRadius: "40px" } : {}),
    ...(width ? { width } : {}),
  };
};

const faIconStyle = (
  {
    size = ButtonSize.Default,
    faIconFontSize
  }: IButtonProp
) => ({
  fontSize: `${faIconFontSize || "0.8em"}`,
  marginRight: `${size / 2.3}px`
});

export default Button;
