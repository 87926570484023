import Theme from "../../../theme";
import styled from "styled-components";

export enum InputStyle {
  Line = "INPUT_LINE",
  Lineless = "INPUT_LINELESS",
  Grey = "INPUT_GREY",
}

export enum InputSize {
  Default = "14px",
  Big = "16px",
}

export interface InputWrapperProps {
  inputSize?: InputSize;
  inputStyle?: InputStyle;
}

const getTheme = (props: InputWrapperProps): { [key: string]: string } => Theme[props.inputStyle || InputStyle.Line];

export const Input = styled.input<InputWrapperProps>`
  background: ${(props): string => getTheme(props).background};
  border: ${(props): string => getTheme(props).border};
  color: ${(props): string => getTheme(props).color};
  border-radius: 4px;
  line-height: 40px;
  font-size: ${(props): string => props.inputSize || InputSize.Default};
  padding: 0 15px;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
`;

export default Input;
