import accounting from "accounting";
import { get, isEqual, pick } from "lodash";
import Tooltip from "rc-tooltip";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";

import { updateProductionOrderItem } from "../../../actions/campaign_design_actions";
import GlobalActions from "../../../actions/GlobalActions";
import { createShippingAddress, updateShippingAddress } from "../../../actions/supplier_contacts_actions";
import { ISupplierShippingAddress, TSupplierAddress } from "../../../models/supplier_address";
import { updateUnit } from "../../Inventory/Unit/actions";

const AppConfig = (window as any).AppConfig;

interface Props {
  updateProductionOrderItem: (order_id, payload) => any;
  createShippingAddress: (supplier_id, payload) => any;
  updateShippingAddress: (supplier_id, address_id, payload) => any;
  updateUnit: (unit_id, params) => any;
  campaignId: any;
  unit: any;
  order: any;
  supplier_addresses: ISupplierShippingAddress;
  shipping_addresses: any;
  fetchProductionOrders: (campaignId) => void;
}

interface State {
  ship_to_contact_id: number | null;
  product_id: string | null;
  product_description: string | null;
  shipping_method_id: string | null;
  shipping_method_description: string | null;
  refreshing: boolean;
  canceling: boolean;
  submitting: boolean;
  deleting: boolean;
}

class ProductionOrderCardItem extends Component<Props, State> {
  default_shipping_method: { value: number; label: string } = {
    value: 60,
    label: "UPS Ground",
  };
  default_product: { value: number; label: string } = {
    value: 26,
    label: "Vinyl Flex 7.5 oz (most common)",
  };

  constructor(props: Props) {
    super(props);
    const { order, unit } = this.props;
    const item = this.getOrderItem(unit, order);
    this.state = {
      ship_to_contact_id: item.ship_to_contact_id || null,
      product_id: item.product_id || this.default_product.value,
      product_description: item.product_description || this.default_product.label,
      shipping_method_id: item.shipping_method_id || this.default_shipping_method.value,
      shipping_method_description: item.shipping_method_description || this.default_shipping_method.label,
      refreshing: false,
      canceling: false,
      submitting: false,
      deleting: false,
    };
    this.handleShippingChangeOption = this.handleShippingChangeOption.bind(this);
    this.handleProductOption = this.handleProductOption.bind(this);
    this.handleShippingMethodOption = this.handleShippingMethodOption.bind(this);
    this.attachShippingAddressToProductionOrder = this.attachShippingAddressToProductionOrder.bind(this);
  }

  public componentDidUpdate(prevProps, prevState) {
    const { unit, order } = this.props;
    if (!isEqual(this.getOrderItem(unit, order), this.getOrderItem(prevProps.unit, prevProps.order))) {
      this.updateCurrentState();
    }
  }

  public getOrderItem(unit, order) {
    const { design_assets } = unit;
    return order.items.find(item => item.design_asset_id == design_assets[0].id);
  }

  public getOrder() {
    const {
      unit: { design_assets },
    } = this.props;
    const design_asset = design_assets[0];
    return design_asset.production_order;
  }

  public render() {
    return <div className="design_card_items">{this.renderCardBody()}</div>;
  }

  private getShippingAddresses() {
    const { supplier_addresses, unit } = this.props;
    const { supplier_id } = unit;
    return supplier_addresses[supplier_id] || [];
  }

  private updateCurrentState() {
    const { order, unit } = this.props;
    const item = this.getOrderItem(unit, order);
    this.setState({
      ship_to_contact_id: item.ship_to_contact_id,
      product_id: item.product_id,
      product_description: item.product_description,
      shipping_method_id: item.shipping_method_id,
      shipping_method_description: item.shipping_method_description,
    });
  }

  private renderCardBody() {
    const { order } = this.props;
    if (order.status !== "new") {
      return this.renderDetails();
    }
    return this.renderForm();
  }

  private renderForm() {
    const { unit, order } = this.props;
    const { ship_to_contact_id, product_id, shipping_method_id } = this.getOrderItem(unit, order);
    return (
      <div className="production_order_form">
        <div className="input_group_row">
          <div className="input_group_item" style={{ flexBasis: "32%" }}>
            <label>
              Shipping address
              <a target="#" style={{ marginLeft: 7, fontSize: 10 }} onClick={e => this.openShippingAddressModal()}>
                <i className="fa fa-plus-circle margin-right-sm" />
                {ship_to_contact_id ? `Edit address` : `Add new address`}
              </a>
            </label>
            <ReactSelect
              placeholder="Choose one"
              value={this.shippingAddressOptions().find(o => o.value === parseInt(ship_to_contact_id))}
              options={this.shippingAddressOptions()}
              onChange={option => this.handleChange("ship_to_contact_id", option)}
            />
            {ship_to_contact_id && (
              <span style={{ marginBottom: 0 }} className="help-block">
                {this.showSelectedAddress()}
              </span>
            )}
          </div>
          <div className="input_group_item" style={{ flexBasis: "34%" }}>
            <label>
              Product
              <a style={{ marginLeft: 7, fontSize: 10 }} href={AppConfig.circleGraphicsProductDescriptions}>
                <i className="fa fa-download margin-right-sm" />
                Download product descriptions
              </a>
            </label>
            <ReactSelect
              placeholder="Choose one"
              value={this.productOptions().find(o => o.value === parseInt(product_id))}
              options={this.productOptions()}
              onChange={option => this.handleChange("product_id", option)}
            />
          </div>

          <div className="input_group_item" style={{ flexBasis: "34%" }}>
            <label>
              Shipping Method
              <Tooltip overlay={this.renderShippingTooltip()}>
                <a>
                  <i className="fa fa-info-circle margin-left" />
                </a>
              </Tooltip>
            </label>
            <ReactSelect
              placeholder="Choose one"
              value={this.shippingMethodOptions().find(o => o.value === parseInt(shipping_method_id))}
              options={this.shippingMethodOptions()}
              onChange={option => this.handleChange("shipping_method_id", option)}
            />
          </div>
        </div>
      </div>
    );
  }

  private async handleChange(key, option) {
    const { updateProductionOrderItem, updateUnit, fetchProductionOrders, unit, order, campaignId } = this.props;
    const options = {
      ship_to_contact_id: this.handleShippingChangeOption,
      product_id: this.handleProductOption,
      shipping_method_id: this.handleShippingMethodOption,
    };
    const item = this.getOrderItem(unit, order);
    const payload = this.buildPayload(key, option);
    await updateProductionOrderItem(item.id, payload);
    await updateUnit(unit.id, { supplier_address_id: option.value })
    await fetchProductionOrders(campaignId)
    return options[key](option);
  }

  private buildPayload(key, option) {
    const payload = pick(this.state, [
      "ship_to_contact_id",
      "product_id",
      "product_description",
      "shipping_method_id",
      "shipping_method_description",
    ]);
    if (!option) {
      return Object.assign(payload, { [key]: null });
    }
    if (key == "product_id") {
      return Object.assign(payload, {
        product_id: option.value,
        product_description: option.label,
      });
    }
    if (key == "shipping_method_id") {
      return Object.assign(payload, {
        shipping_method_id: option.value,
        shipping_method_description: option.label,
      });
    }
    return Object.assign(payload, { ship_to_contact_id: option.value });
  }

  private handleShippingChangeOption(option) {
    if (!option) {
      return this.setState({ ship_to_contact_id: null });
    }
    this.setState({ ship_to_contact_id: option.value });
  }

  private productOptions() {
    return [
      { value: 911, label: "Eco-Flexx 3.0" },
      { value: 165, label: "PE-Lamar Poster Flex" },
      { value: 61, label: "Tri-Vis Louvers" },
      { value: 26, label: "Vinyl Flex 7.5 oz (most common)" },
      { value: 253, label: "Vinyl Flex LW 7.5 oz" },
      { value: 108, label: "PE-Eco-Poster Single Sheet" },
      { value: 352, label: "Vinyl Flex HW 13 oz" },
      { value: 62, label: "Vinyl Backlit-Standard" },
      { value: 276, label: "Vinyl Banner-LW 7.5 oz" },
      { value: 360, label: "Promo-Bulletin Insert w/ Frame" }
    ];
  }

  private shippingMethodOptions() {
    return [
      { value: 60, label: "UPS Ground" },
      { value: 62, label: "UPS 2DA" },
      { value: 80, label: "UPS NDA" },
    ];
  }

  private shippingAddressOptions() {
    const addresses = this.getShippingAddresses();
    return addresses.map((c: TSupplierAddress) => ({
      value: c.id,
      label: `${c.address_line}, ${c.city} ${c.state}`,
    }));
  }

  private handleProductOption(option) {
    if (!option) {
      return this.setState({ product_id: null, product_description: null });
    }
    this.setState({
      product_id: option.value,
      product_description: option.label,
    });
  }

  private handleShippingMethodOption(option) {
    if (!option) {
      return this.setState({
        shipping_method_id: null,
        shipping_method_description: null,
      });
    }
    this.setState({
      shipping_method_id: option.value,
      shipping_method_description: option.label,
    });
  }

  private showSelectedAddress() {
    const { ship_to_contact_id } = this.state;
    const { address_line, state, zip_code } = (this.findShippingAddress(
      ship_to_contact_id,
    ) as unknown) as TSupplierAddress;
    return `${address_line}, ${state} ${zip_code}`;
  }

  private findShippingAddress(ship_to_contact_id) {
    const addresses = this.getShippingAddresses();
    return addresses.find((a: TSupplierAddress) => a.id === ship_to_contact_id) || ({} as TSupplierAddress);
  }

  private openShippingAddressModal() {
    const { ship_to_contact_id } = this.state;
    const { createShippingAddress, updateShippingAddress, unit, shipping_addresses } = this.props;
    const address = this.findShippingAddress(ship_to_contact_id) || {};
    const supplier_id = unit && unit.supplier_id;
    const pre_filled_address = shipping_addresses[unit.supplier_address_id] || {};
    GlobalActions.openPopup("shipping_address", {
      createShippingAddress,
      updateShippingAddress,
      attachShippingAddressToProductionOrder: this.attachShippingAddressToProductionOrder,
      address,
      supplier_id,
      pre_filled_address,
    });
  }

  attachShippingAddressToProductionOrder({ id }) {
    this.handleChange('ship_to_contact_id', { value: id })
  }

  private renderShippingTooltip() {
    return (
      <ul>
        <li>UPS Ground Shipping ($0.084/sq feet and $15 min)</li>
        <li>2DA Shipping ($0.167/sq feet and $20 min)</li>
        <li>NDA Shipping ($0.188/sq feet and $30 min)</li>
      </ul>
    );
  }

  private renderDetails() {
    const { unit, order } = this.props;
    const item = this.getOrderItem(unit, order);
    return (
      <div className="production_order_details">
        <div>
          <div className="detail_label">Ship to</div>
          <div className="detail_value">{item.recipient_name}</div>
          <div className="detail_value">
            <span style={{ fontWeight: 400 }}>{item.recipient_email}</span>
          </div>
          <div className="detail_value">{this.fullAddress(item)}</div>
        </div>
        <div>
          <div className="detail_label">Shipping</div>
          <div className="detail_value">
            {item.shipping_method_id} - {item.shipping_method_description}
          </div>
          <div className="detail_label">Product ID</div>
          <div className="detail_value">
            {item.product_id} - {item.product_description}
          </div>
        </div>
        <div>
          <div className="detail_label">Unit Cost</div>
          <div className="detail_value">{accounting.formatMoney(item.unit_cost)}</div>
          <div className="detail_label">Market</div>
          <div className="detail_value">{unit.market_name}</div>
        </div>
      </div>
    );
  }

  private fullAddress(item) {
    return `${item.recipient_address_1}
${item.recipient_address_2}
${item.recipient_city}, ${item.recipient_state} ${item.recipient_postal_code}`;
  }
}

export default connect(
  (state: any) => ({
    supplier_addresses: state.supplier_addresses,
    shipping_addresses: state.shipping_addresses,
  }),
  {
    updateProductionOrderItem,
    createShippingAddress,
    updateShippingAddress,
    updateUnit
  },
)(ProductionOrderCardItem);
