import React from 'react';
import GlobalActions from '../actions/GlobalActions';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';
import SplashForm from './SplashForm';

export default class ContactModal extends React.Component {

  constructor(props) {
    super(props);
    const auth = AuthStore.getState();
    this.state = {
      auth
    }

    this.onAuthChange = this.onAuthChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderCloseModalIcon = this.renderCloseModalIcon.bind(this);
  }

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  onAuthChange(event) {
    const auth = AuthStore.getState();
    this.setState({ ...auth });
  }

  onSubmit(event) {
    const name = this.refs.name.value;
    const email = this.refs.email.value;
    const message = this.refs.message.value;

    GlobalActions.sendMessage({ name, email, message });
    event.preventDefault();
  }

  renderCloseModalIcon() {
    const closeIconStyle = this.props.closeIconStyle || {
      float: 'right',
      top: -35,
      right: -35
    };

    return (
      <img
        className="close-modal-x"
        src={ASSETS['x']}
        style={closeIconStyle}
        onClick={() => {
          GlobalActions.closePopup();
        }}
      />
    )
  }

  render() {
    return (
      <div className="modal-splash" onClick={e => e.stopPropagation()}>
        {this.renderCloseModalIcon()}
        <div className="logo"><img src={ASSETS.logo} width="129" height="20" /></div>
        <form className="form" onSubmit={this.onSubmit} >
          <input className="input" ref="name" type="text" placeholder="Your name" />
          <input className="input" ref="email" type="email" placeholder="Email address" />
          <textarea className="input" ref="message" placeholder="Your message" style={{ height: 250 }} />
          <button className="btn btn_primary register__submit" type="submit" onClick={this.onSubmit}>Send</button>
        </form>
      </div>
    );
  }
};
