import React from 'react';
import mapboxgl from 'mapbox-gl';

import { mapUnits } from '../../models/Units';
import MapBox from './MapBox';

type Props = {
  show_units_reach: boolean
  mapbox: MapBox
  units: any
}

export default class UnitReach extends React.Component<Props, {}> {
  map: mapboxgl.Map
  unitsReachLayer: string = 'units-reach-layer'
  noClusteredUnits: string = 'noClusteredUnits'

  async componentDidMount() {
    this.map = this.props.mapbox.map;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.units != prevProps.units) {
      if(this.source()) this.setSource();
    }
    if (this.props.show_units_reach != prevProps.show_units_reach) {
      if(!this.source()) this.setSource();
      this.updateUnitReachLayer();
    }
  }

  componentWillUnmount() {
    if (this.map.getLayer(this.unitsReachLayer)) this.map.removeLayer(this.unitsReachLayer);
  }

  async setSource() {
    await this.props.mapbox.waitMapLoad();
    this.props.mapbox.setSource(this.noClusteredUnits, mapUnits(this.props.units));
  }

  async updateUnitReachLayer() {
    await this.props.mapbox.waitMapLoad();
    this.hideUnitReachLayer();
    this.showUnitReachLayer();
  }

  source() {
    return this.map.getSource(this.noClusteredUnits);
  }

  hideUnitReachLayer() {
    if (this.props.show_units_reach) return;
    if (this.map.getLayer(this.unitsReachLayer)) this.map.removeLayer(this.unitsReachLayer);
  }

  showUnitReachLayer() {
    if (!this.props.show_units_reach) return;
    if (this.map.getLayer(this.unitsReachLayer)) return;

    this.map.addLayer({
      id: this.unitsReachLayer,
      type: "heatmap",
      source: this.noClusteredUnits,
      filter: ["!has", "point_count"],
      "paint": {
        // Increase the heatmap weight based on frequency and property magnitude
        "heatmap-weight": 1,
        // Increase the heatmap color weight weight by zoom level
        // heatmap-intensity is a multiplier on top of heatmap-weight
        "heatmap-intensity": 1,
        // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
        // Begin color ramp at 0-stop with a 0-transparancy color
        // to create a blur-like effect.
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0, "hsla(225, 73%, 57%, 0)",
          0.1, "hsla(225, 73%, 57%, 0.8)",
          0.2, "hsla(225, 73%, 57%, 0.6)",
          0.7, "hsla(225, 73%, 57%, 0.5)",
          1, "hsla(225, 73%, 57%, 0.4)",
        ],
        // Adjust the heatmap radius by zoom level
        "heatmap-radius": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          0, 1,
          20, ['get', 'reach_radius']
        ],
        // Reduce opacity by zoom level
        "heatmap-opacity": [
          "interpolate",
          ["linear"],
          ["zoom"],
          0, 0,
          6, 1,
          8, 1,
          20, 0.4
        ],
      }
    }, this.beforeLayer());
  }

  beforeLayer() {
    if (this.map.getLayer('cluster-markers')) return 'cluster-markers';
    if (this.map.getLayer('unit-markers')) return 'unit-markers';
  }
  render() {
    return <div />;
  }
}
