import React from "react";
const file_url = "https://adquick-public.s3.us-west-2.amazonaws.com/Design+Tips+for+OOH.pdf";
class ExampleAttachmentCard extends React.Component {
  render() {
    return (
      <div id="example-attachment-card" key="card_example" className="card">
        <div
          className="name"
          onClick={e => {
            e.stopPropagation();
            return window.open(file_url, "_blank");
          }}
        >
          OOH Design.pdf
        </div>
        <div className="description">
          <div className="title">description</div>
          <div className="value">Helpful tips for OOH Design</div>
        </div>
        <div className="created_at">
          <div className="title">date uploaded</div>
          <div className="value">---</div>
        </div>
        <div className="created_by">
          <div className="title">uploaded by</div>
          <div className="value">Ted Day-Fratto</div>
        </div>
        <div></div>
        <div className="controls">
          <div
            className="download"
            onClick={e => {
              e.stopPropagation();
              return window.open(file_url, "_blank");
            }}
          >
            <div className="download_icon"></div>
            Download
          </div>
          <div
            className="exclude"
            onClick={e => {
              const doc = document.getElementById("example-attachment-card");
              if (doc) {
                doc.remove();
              }
            }}
          >
            <i className="fa fa-trash-o"></i>
          </div>
          <div className="white_checkbox">
            <input type="checkbox" />
            <span className="checkmark"></span>
          </div>
        </div>
      </div>
    );
  }
}
export default ExampleAttachmentCard;
