import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { canSendToProduction, getDesignAssetsIds, groupByMarket } from "../../../models/design_assets";
import {exportToProductionGrid} from "../../../actions/campaign_design_actions";
import { get, post } from "../../../utils/api";
import { markCampaignUnitsAsReadyToInstall } from "../../Campaign/actions";
import { sendToInstallers } from "../../../actions/campaign_design_actions";
import BulkPostedUnitDetails from "./BulkPostedUnitDetails";
import BulkSentToInstallersAction from "./BulkSentToInstallersAction";
import BulkSetShippingAddressAction from "./BulkSetShippingAddressAction";
import BulkShippingAddressModal from "./BulkShippingAddressModal";
import GlobalActions from "../../../actions/GlobalActions";
import JobProgress from "../../../utils/JobProgress";
import Market from "./Market";

interface UnitsInStatusProps {
  key: string;
  status: string;
  total_unit_count: number;
  units_in_status: any;
  campaign: any;
  campaign_permissions: any;
  activeUnitId: string;
  sendToProduction: (campaign_id: string, design_asset_ids: number[]) => void;
  exportToProductionGrid: any;
  loadCampaignPops: any;
  sendToInstallers: () => void;
  markCampaignUnitsAsReadyToInstall: () => void;
}

interface UnitsInStatusState {
  loading: boolean;
  expanded: boolean;
  show_shipping_address_modal: boolean;
  unit_selection_map: {};
  job_polling: {
    startPolling: (job_id: string, callback: any) => void;
    stop: any;
  };
}

const ASSETS = (window as any).ASSETS;

class UnitsInStatus extends Component<UnitsInStatusProps, UnitsInStatusState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expanded: true,
      show_shipping_address_modal: false,
      unit_selection_map: {},
      job_polling: new JobProgress(),
    };
    this.onUnitSelect = this.onUnitSelect.bind(this);
    this.onUnitBulkSelect = this.onUnitBulkSelect.bind(this);
    this.onExportToProductionGrid = this.onExportToProductionGrid.bind(this);
    this.onExportReady = this.onExportReady.bind(this);
    this.toogleLoading = this.toogleLoading.bind(this);
    this.onDownloadSpecSheetLinks = this.onDownloadSpecSheetLinks.bind(this);
    this.onDownloadSpecsComplete = this.onDownloadSpecsComplete.bind(this);
    this.onShowShippingAddressModal = this.onShowShippingAddressModal.bind(this);
    this.onHideShippingAddressModal = this.onHideShippingAddressModal.bind(this);
    this.selectAllUnits = this.selectAllUnits.bind(this);
    this.onImportLamarPOPs = this.onImportLamarPOPs.bind(this);
    this.onImportLamarPOPsComplete = this.onImportLamarPOPsComplete.bind(this);
    this.triggerMissingPops = this.triggerMissingPops.bind(this);
    this.triggerMissingTakeDownDates = this.triggerMissingTakeDownDates.bind(this);
    this.onDownloadCreatives = this.onDownloadCreatives.bind(this);
    this.sendPostingInstructions = this.sendPostingInstructions.bind(this);
  }

  private renderSendToProductionButton() {
    return (
      <button
        className="btn btn-default send_to_production"
        style={{ fontSize: 13, width: "100%" }}
        onClick={() => this.onSendToProduction()}
      >
        <div style={{ display: "inline", verticalAlign: "text-bottom" }}>
          <img src={ASSETS["cg"]} />
        </div>{" "}
        Send to Production
      </button>
    );
  }

  private enableGlobalBulkAction() {
    const { unit_selection_map } = this.state;
    return Object.keys(unit_selection_map).filter(k => unit_selection_map[k]).length > 0;
  }

  private renderDownloadSpecSheetsButton() {
    return (
      <button
        className="btn btn-default"
        style={{
          color: "rgb(85, 85, 85)",
          width: "100%",
          fontSize: 12,
        }}
        onClick={this.onDownloadSpecSheetLinks}
      >
        Download Spec Sheets
      </button>
    );
  }

  private async onDownloadSpecSheetLinks() {
    const { job_polling } = this.state;
    const { campaign: { campaignId } } = this.props;
    const selectedUnits = this.filterSelectedUnitsFromCheckedUnits();
    const url = `/api/v1/campaigns/${campaignId}/design_assets/download_spec_sheets_links`;
    const ids = selectedUnits.map(u => {
      return u._id;
    });
    const endpoint = ids.length > 0 ? `${url}?ids=${ids.join(",")}` : url;
    const { job_id } = await get(endpoint);
    this.toogleLoading();
    job_polling.startPolling(job_id, this.onDownloadSpecsComplete);
  }

  onDownloadSpecsComplete({ data }) {
    const { error } = data;
    this.toogleLoading();
    if (error) { return; }
    window.open(data.file_link);
  }

  private renderActionsDropdown() {
    const { loading } = this.state;
    const { campaign_permissions } = this.props;
    const disabled = this.enableGlobalBulkAction() ? "" : "disabled";

    return (
      <div className="btn-group">
        <div className="dropdown">
          <button className={`btn btn-default dropdown-toggle ${disabled}`} type="button" data-toggle="dropdown">
            {loading ? "Processing..." : "Actions"}
            <span className="caret" style={{ marginLeft: "5px", marginBottom: "2px" }} />
          </button>
          <ul className="dropdown-menu dropdown-menu-left" style={{ padding: 5 }}>
            {campaign_permissions.can_operate_install && (
              <li className="dropdown-item">{this.renderSentToInstallerButton()}</li>
            )}
            {campaign_permissions.can_operate_install && (
              <li className="dropdown-item">
                <button
                  className={"btn btn-default"}
                  style={{
                    color: "#555555",
                    width: "100%",
                    fontSize: 12,
                    marginBottom: "5px",
                  }}
                >
                  {this.renderPostedUnitDetails()}
                </button>
              </li>
            )}
            <li className="dropdown-item">
              <button
                className={"btn btn-default"}
                style={{
                  color: "#555555",
                  width: "100%",
                  fontSize: 12,
                  marginBottom: "5px",
                }}
                onClick={this.triggerMissingPops}
              >
                Trigger Missing POPs
              </button>
            </li>
            <li className="dropdown-item">
              <button
                className={"btn btn-default"}
                style={{
                  color: "#555555",
                  width: "100%",
                  fontSize: 12,
                  marginBottom: "5px",
                }}
                onClick={this.triggerMissingTakeDownDates}
              >
                Trigger Missing Takedown Dates
              </button>
            </li>
            {campaign_permissions.can_add_posting_note &&
              <li className="dropdown-item" style={{ marginBottom: 5 }}>
                <BulkSetShippingAddressAction
                  disabled={!this.enableGlobalBulkAction()}
                  showModal={this.onShowShippingAddressModal}
                />
              </li>
            }
            <li className="dropdown-item">{this.renderExportProductionGridButton()}</li>
            <li className="dropdown-item">{this.renderDownloadSpecSheetsButton()}</li>
            {campaign_permissions.can_operate_install &&
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                <button
                  className={"btn btn-default"}
                  style={{
                    color: "#555555",
                    width: "100%",
                    fontSize: 12
                  }}
                  onClick={this.sendPostingInstructions}
                >
                  Send Posting Instructions
                </button>
              </li>
            }
            {this.showSendToProductionAction() && (
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                {this.renderSendToProductionButton()}
              </li>
            )}
            {this.showDownloadCreative() &&
              <li className="dropdown-item" style={{ marginTop: 5 }}>
                <button
                  className="btn btn-default send_to_production"
                  style={{ fontSize: 13, width: "100%" }}
                  onClick={this.onDownloadCreatives}
                >
                  <i className="fa fa-cloud-download" style={{ marginRight: 5 }} />
                  Download Design Assets
                </button>
              </li>
            }
          </ul>
        </div>
      </div>
    );
  }

  async sendPostingInstructions() {
    const { campaign: { campaignId } } = this.props;
    const selectedUnits = this.filterSelectedUnitsFromCheckedUnits();
    const campaign_unit_tokens = selectedUnits.map(u => u.campaign_unit_token)
    try {
      const { message } = await post(`/api/v1/campaigns/${campaignId}/campaign_units/posting_instruction`, { campaign_unit_tokens })
      GlobalActions.showMessage(message);
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  onShowShippingAddressModal() {
    if (this.uniqueSelectedSuppliers().length > 1) { return alert("Select units from one supplier one. Addresses are tied to suppliers.") }
    this.setState({ show_shipping_address_modal: true });
  }

  onHideShippingAddressModal() {
    this.setState({ show_shipping_address_modal: false })
  }

  uniqueSelectedSuppliers() {
    return [... new Set(this.filterSelectedUnitsFromCheckedUnits().map(u => u.supplier_id))]
  }

  renderSentToInstallerButton() {
    const selectedUnits = this.filterSelectedUnitsFromCheckedUnits();
    return (
      <BulkSentToInstallersAction
        campaign={this.props.campaign}
        sentToInstallers={this.props.sendToInstallers}
        selectedUnits={selectedUnits}
        markAsReadyToInstall={this.props.markCampaignUnitsAsReadyToInstall}
      />
    );
  }

  renderPostedUnitDetails() {
    const { campaign: { campaignId } } = this.props;
    const selectedUnits = this.filterSelectedUnitsFromCheckedUnits();
    return (
      <BulkPostedUnitDetails
        toggleLoading={this.toogleLoading}
        campaignUnitTokens={selectedUnits.map(u => u.campaign_unit_token)}
        campaignToken={campaignId}
      />
    );
  }

  toogleLoading() {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  renderExportProductionGridButton() {
    return (
      <button
        className="btn btn-default"
        style={{
          color: "rgb(85, 85, 85)",
          width: "100%",
          fontSize: 12,
          marginBottom: 5,
        }}
        onClick={() => this.onExportToProductionGrid()}
      >
        Export to Production Grid
      </button>
    );
  }

  private onExportReady({ data }) {
    const { error } = data;
    const { job_polling } = this.state;
    if (error) {
      GlobalActions.showError("There was an issue exporting to production grid");
      job_polling.stop();
      return;
    }
    window.open(data.file_link);
  }

  private async onExportToProductionGrid() {
    const {
      campaign: { campaignId },
      exportToProductionGrid,
    } = this.props;
    const { job_polling } = this.state;
    GlobalActions.showMessage("Your production grid export will download when complete.");
    const selected_units = this.filterSelectedUnitsFromCheckedUnits();
    const campaign_unit_tokens = selected_units.map(u => u.campaign_unit_token);
    const job_id = await exportToProductionGrid(campaignId, campaign_unit_tokens);
    job_polling.startPolling(job_id, this.onExportReady);
  }

  async triggerMissingPops() {
    const { campaign: { campaignId } } = this.props;
    const selectedUnits = this.filterSelectedUnitsFromCheckedUnits();
    const campaign_unit_tokens = selectedUnits.map(u => u.campaign_unit_token);
    try {
      await post(`/api/v1/campaigns/${campaignId}/proof_of_postings/trigger_missing_pop`, { campaign_unit_tokens })
      GlobalActions.showMessage("A missing POP email was trigger for the selected contacts")
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  async triggerMissingTakeDownDates() {
    const { campaign: { campaignId } } = this.props;
    const selectedUnits = this.filterSelectedUnitsFromCheckedUnits();
    const campaign_unit_tokens = selectedUnits.map(u => u.campaign_unit_token);
    try {
      await post(`/api/v1/campaigns/${campaignId}/proof_of_postings/trigger_missing_takedown_dates`, { campaign_unit_tokens })
      GlobalActions.showMessage("A missing take down notes email was trigger for the selected contacts")
    } catch (error) {
      GlobalActions.showError(error);
    }
  }

  public render() {
    const { units_in_status, status, total_unit_count, campaign, campaign_permissions } = this.props;
    if (!units_in_status || !total_unit_count) { return <section/>; }
    return (
      <section id={status}>
        <BulkShippingAddressModal
          campaign={campaign}
          onSubmit={() => {}}
          selected_units={this.filterSelectedUnitsFromCheckedUnits()}
          supplier_id={this.uniqueSelectedSuppliers()[0] as number}
          show={this.state.show_shipping_address_modal}
          onHide={this.onHideShippingAddressModal}
        />
        <div className="campaign_designs container">
          <div style={{ display: 'flex', alignItems: 'center'}}>
            {this.renderActionsDropdown()}
            <div>
              <label style={{margin: 0, marginLeft: 10}}>
                Select All
                <input style={{margin: 0, marginLeft: 5}} onClick={this.selectAllUnits} type="checkbox" />
              </label>
            </div>
            {campaign_permissions.can_import_lamar_pops &&
              this.renderImportLamarPOPsAction()
            }
          </div>
          {this.renderMarkets()}
        </div>
      </section>
    )
  }

  renderImportLamarPOPsAction() {
    const { loading } = this.state;
    const disabled = loading ? "disabled" : "";
    return (
        <div style={{marginLeft: 'auto'}}>
          <button className={`btn btn-default ${disabled}`} type="button" onClick={this.onImportLamarPOPs}>
            {loading ? "Processing..." : "Import POPs from APIs"}
          </button>
        </div>
    );
  }

  async onImportLamarPOPs() {
    const { job_polling } = this.state;
    const { campaign: { campaignId } } = this.props;
    const url = `/api/v1/campaigns/${campaignId}/design_assets/import_lamar_pops`;
    const { job_id } = await post(url);
    this.toogleLoading();
    job_polling.startPolling(job_id, this.onImportLamarPOPsComplete);
  }

  onImportLamarPOPsComplete({ data }) {
    const { error } = data;
    if (error) { return; }
    this.toogleLoading();
    GlobalActions.showMessage("APIs POPs imported.");
    window.location.reload();
  }

  selectAllUnits(e) {
    const { units_in_status } = this.props
    const selected_units = units_in_status.reduce((acc, u) => {
      acc[u.id] = e.target.checked
      return acc
    }, {})
    this.onUnitBulkSelect(selected_units)
  }

  renderMarkets() {
    const units_by_market = groupByMarket(this.props.units_in_status);
    const units = this.getUnits(units_by_market);
    const unit_count = units.length;
    if (unit_count == 0) { return this.renderAllGood() }
    return <div>{this.renderUnitsByMarket(units_by_market)}</div>
  }

  renderAllGood() {
    return (
      <div>
        <p style={{textAlign: "center",fontSize: "20px",padding: "64px"}}>
          All good here! Select another status above ☝
        </p>
      </div>
    )
  }

  private showSendToProductionAction() {
    const { unit_selection_map } = this.state;
    const { status, campaign_permissions } = this.props;
    const haveSelectedUnits = Object.keys(unit_selection_map).filter(k => unit_selection_map[k]).length > 0;
    const isSumbmittedState = status === "submitted";
    const havePermissions = campaign_permissions.can_create_production_order;
    const selected_units = this.filterSelectedUnitsFromCheckedUnits();
    const unitsCanBeSentToProduction = _.every(
      selected_units,
      unit => unit.design_assets && canSendToProduction(unit.design_assets[0]),
    );
    return havePermissions && haveSelectedUnits && isSumbmittedState && unitsCanBeSentToProduction;
  }

  private async onSendToProduction() {
    const { campaign: { campaignId } } = this.props;
    const selected_units = this.filterSelectedUnitsFromCheckedUnits();
    const design_asset_ids = getDesignAssetsIds(selected_units);
    await this.props.sendToProduction(campaignId, design_asset_ids);
  }

  private showDownloadCreative() {
    const { unit_selection_map } = this.state;
    const { status } = this.props;
    const haveSelectedUnits = Object.keys(unit_selection_map).filter(k => unit_selection_map[k]).length > 0;
    const isSumbmittedState = status === "submitted";
    const selected_units = this.filterSelectedUnitsFromCheckedUnits();
    const unitsHaveCreatives = _.every(
      selected_units,
      unit => unit.design_assets && unit.design_assets.length > 0
    );

    return haveSelectedUnits && isSumbmittedState && unitsHaveCreatives
  }

  private async onDownloadCreatives() {
    const { campaign: { campaignId } } = this.props;
    const selected_units = this.filterSelectedUnitsFromCheckedUnits();
    const design_asset_ids = getDesignAssetsIds(selected_units);
    const url = `/api/v1/campaigns/${campaignId}/design_assets/download_zip`;
    const { job_id } = await post(url, {ids: design_asset_ids});
    GlobalActions.showMessage("Your zip files is being processed");
    this.state.job_polling.startPolling(job_id, this.onCompleteCreativeZip);
  }

  onCompleteCreativeZip({ data }) {
    const { error } = data;
    if (error) { return; }
    window.open(data.file_url);
  }

  private filterSelectedUnitsFromCheckedUnits() {
    const { units_in_status } = this.props;
    const { unit_selection_map } = this.state;
    const checked_units = Object.keys(unit_selection_map).filter(s => unit_selection_map[s]);
    return units_in_status.filter(u => checked_units.find(i => i == u.id));
  }

  private renderUnitsByMarket(units_by_market: any) {
    return Object.keys(units_by_market).sort().map(market => this.renderMarket(units_by_market, market));
  }

  renderMarket(unitsByMarket, market) {
    const { campaign, campaign_permissions, status } = this.props;
    return (
      <Market
        key={`${status}_${market}`}
        status={status}
        market={market}
        units={this.addCheckedStateToMarketUnits(unitsByMarket[market])}
        campaign_permissions={campaign_permissions}
        campaign={campaign}
        onUnitSelect={this.onUnitSelect}
        onUnitBulkSelect={this.onUnitBulkSelect}
      />
    )
  }

  addCheckedStateToMarketUnits(marketUnits) {
    const { unit_selection_map } = this.state
    return marketUnits.reduce((units, unit) => {
      unit.selected = unit_selection_map[unit.id]
      units.push(unit)
      return units
    }, [])
  }

  private getUnits(units_by_market) {
    return _.flatten(Object.values(units_by_market));
  }

  private onUnitSelect(unit, isSelected) {
    const { unit_selection_map } = this.state;
    this.setState({
      unit_selection_map: {
        ...unit_selection_map,
        [unit.id]: isSelected,
      },
    });
  }

  private onUnitBulkSelect(selected_units) {
    this.setState({
      unit_selection_map: this.updateSelectedUnitState(selected_units),
    });
  }

  private updateSelectedUnitState(selected_units) {
    const { unit_selection_map } = this.state;
    const newSelectedState = _.cloneDeep(unit_selection_map);
    return Object.keys(selected_units).reduce((acc, value) => {
      acc[value] = selected_units[value];
      return acc;
    }, newSelectedState);
  }
}

export default connect(({}) => ({}), {
  exportToProductionGrid,
  sendToInstallers,
  markCampaignUnitsAsReadyToInstall,
  // @ts-ignore
})(UnitsInStatus);
