import {
  FETCH_CAMPAIGNS_WITH_OPEN_REQUESTS,
  FETCH_CAMPAIGNS_WITH_COMPLETED_REQUESTS,
  FETCH_BOOKINGS,
  FETCHING } from "./actions";

const initialState = {
  status: 'open',
  isFetching: false,
  open: {
    filters: {
      page: 1,
      per_page: 10
    },
    results: []
  },
  completed: {
    filters: {
      page: 1,
      per_page: 10
    },
    results: []
  },
  booked: {
    filters: {
      page: 1,
      per_page: 10
    },
    results: []
  }
}
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case FETCH_CAMPAIGNS_WITH_OPEN_REQUESTS: return updateOpenRequests(state, action);
    case FETCH_CAMPAIGNS_WITH_COMPLETED_REQUESTS: return updateCompletedRequests(state, action);
    case FETCH_BOOKINGS: return updateBookings(state, action);
    default: {
      return state;
    }
  }
}

const updateOpenRequests = (state, action) => {
  const open = {
    ...state.open,
    ...action.payload
  }
  return {
    ...state,
    open,
    isFetching: false,
  }
}

const updateCompletedRequests = (state, action) => {
  const completed = {
    ...state.completed,
    ...action.payload
  }
  return {
    ...state,
    completed,
    isFetching: false,
  }
}

const updateBookings = (state, action) => {
  const booked = {
    ...state.booked,
    ...action.payload
  }
  return {
    ...state,
    booked,
    isFetching: false,
  }
}
