import * as React from 'react';
import { connect } from 'react-redux';
import Comment from './Comment';
import { addComment, deleteComment } from '../CampaignUnitComments/actions';
import GlobalActions from '../../actions/GlobalActions';

class Comments extends React.Component<any, any> {

  constructor(props) {
    super(props)
    const recipients = props.recipients || [];
    this.state = {
      new_comment_body: '',
      showRecipients: false,
      recipients
    }
    this.onSave = this.onSave.bind(this)
  }

  onSave(e) {
    e.preventDefault();
    const { campaign_token, campaign_unit_token, addComment } = this.props;
    const { new_comment_body } = this.state;
    addComment(campaign_token, campaign_unit_token, new_comment_body)
    this.setState({new_comment_body: ''})
  }

  async onDeleteComment(commentId) {
    const { campaign_token, campaign_unit_token, deleteComment } = this.props;
    deleteComment(campaign_token, campaign_unit_token, commentId);
  }

  onRecipientsToggle(){
    this.setState({ showRecipients: !this.state.showRecipients })
  }

  handleAuthModals(e, modal) {
    const { onClose } = this.props
    e.preventDefault()
    onClose()
    return GlobalActions.openPopup(modal, { force: false })
  }

  render() {
    const { showRecipients, recipients } = this.state;
    const { campaign_unit_token } = this.props;
    const comments = this.props.comments ? this.props.comments.filter((comment) =>
      comment.campaign_unit_id === campaign_unit_token
    ) : [];

    const { user } = this.props;

    return (
      <div className="unit_modal_comments">

        <div className="section_title">
          Unit Comments
        </div>

        {comments.length === 0 &&
          <div className="empty_state">
            Write a comment to share notes or ask questions.
          </div>
        }


        {recipients.length > 0 && <div className="recipients_list">
          <a onClick={(e) => { this.onRecipientsToggle() }}>
            { this.state.showRecipients ?
                <i className="fa fa-caret-down" />
              :
                <i className="fa fa-caret-right" />
            }
            Recipients
           </a>
          {showRecipients && <ul>
            {recipients.map((recipient, index) =>
              <li key={index}>{recipient}</li>
            )}
           </ul>}
        </div>}

        {comments.map((comment) => {
          return (
            <Comment
              key={comment.created_at}
              createdAt={comment.created_at}
              authorName={comment.author}
              authorId={comment.author_id}
              body={comment.body}
              currentUser={user}
              onDeleteComment={(commentId) => this.onDeleteComment(commentId)}
              commentId={comment.id}
            />
          )}
        )}

        {!user.is_guest &&
          <div className="new_comment">
            <textarea
              id="new_comment_textarea"
              className="form-control"
              placeholder="Add a comment..."

              value={this.state.new_comment_body}
              onChange={(e) => { this.setState({ new_comment_body: e.target.value }) }}
            />
            <input
              type="submit"
              className="btn btn-default"
              value="Add comment"
              onClick={this.onSave}
            />
          </div>
        }

        {user.is_guest &&
          <div style={{borderTop: '1px solid #EEEEEE',marginTop:'10px'}}>
            <div className="guest_message">
              <p>You must be signed in to comment.</p>
              <p className="divider">
                <a href="https://www.adquick.com/signup?redirect_uri=%2Fpicker">Sign Up</a>
                |
                <a href="https://www.adquick.com/login">Sign In</a>
              </p>
            </div>
          </div>
        }

      </div>
    )
  }

}

export default connect(
  // @ts-ignore
  ({ comments }) => ({ ...comments }),
  { addComment, deleteComment }
 )(Comments)
