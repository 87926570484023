import * as React from "react";
import styled from "styled-components";

import { User } from "../../types/user";

type Props = {
  user: User;
};

// @ts-ignore
const assets = window.ASSETS as {
  noAvatar: string;
};

const Container = styled.div`
  margin-left: auto;
  padding: 10px 0;
  font-size: 13px;
  display: flex;
  align-items: center;
`;

const Avatar = styled.img.attrs({
  alt: "avatar image",
  'aria-label': "profile link"
})`
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

const UserMenu = ({ user }: Props) => {
  return (
    <Container>
      <Avatar src={user.avatar_url || assets.noAvatar} />
      {user.first_name}
    </Container>
  );
};
UserMenu.displayName = "NavBar/UserMenu";
export default UserMenu;
