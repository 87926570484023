import { browserHistory } from 'react-router-legacy';

const namespace = 'marketplace/inventory/'

export const LOCATION_CHANGE_HACK = 'marketplace/LOCATION_CHANGE_HACK'

export const push = path => dispatch => {
  dispatch({
    type: LOCATION_CHANGE_HACK,
    payload: { path }
  })

  browserHistory.push(path)
}
