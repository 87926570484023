import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import ContentLoader from 'react-content-loader';
import React from 'react';

import { sidebarUnits, withinBounds } from '../../models/Units';
import { toggleUnitPopup } from '../NewMap/actions';
import AuthStore from '../../stores/AuthStore';
import ListItemExpanded from './ListItemExpanded';
import SearchStore from '../../stores/SearchStore';

class Listing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: AuthStore.getState().user,
      units: this.units(),
      scoreFlagEnabled: this.isUnitScoreFlagEnabled()
    };
  }

  componentDidMount() {
    this.unsubscribeList = [AuthStore.listen(this.onAuthChange.bind(this))];
  }

  onAuthChange() {
    this.setState({ user: AuthStore.getState().user });
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  componentDidUpdate(prevProps) {
    if (
      isEqual(prevProps.bounds, this.props.bounds) &&
      isEqual(prevProps.limit, this.props.limit) &&
      isEqual(prevProps.units, this.props.units)
    ) {
      return;
    }
    this.setState({ units: this.units() });
  }

  onListClick(unit_id) {
    this.props.onUnitListingClick(unit_id);
  }

  onListOver(unit) {
    this.props.toggleUnitPopup(unit.id);
  }

  onListOut() {
    this.props.toggleUnitPopup();
  }

  isUnitScoreFlagEnabled() {
    const { feature_flags, campaign } = this.props
    const flag = feature_flags.find(f => f.name == 'unit_scores')
    return (flag && flag['status']) || (campaign && campaign.can_show_scores)
  }

  renderListItemComponent(unit, index) {
    const { demographics, loading, flag } = this.props;
    const { user, scoreFlagEnabled } = this.state;
    const demographic = demographics && demographics.by_unit ? demographics.by_unit[unit.id] : null;
    const points = this.state.points || [];

    if(loading && flag) {
      return (
        <ContentLoader
          rtl
          height={289}
          width={228}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="3.84" y="2.81" rx="0" ry="0" width="222.95" height="136.5" />
          <rect x="2.84" y="155.67" rx="0" ry="0" width="220.46" height="20.02" />
          <rect x="76.84" y="187.67" rx="0" ry="0" width="146" height="17.16" />
          <rect x="76.84" y="218.67" rx="0" ry="0" width="146" height="17.16" />
          <rect x="76.84" y="247.67" rx="0" ry="0" width="146" height="17.16" />
        </ContentLoader>
      );
    }

    return (
      <ListItemExpanded
        key={unit.id}
        campaign={this.props.campaign}
        permissions={this.props.permissions}
        demographic={demographic}
        unit={unit}
        howLong={4}
        isActive={unit.id === this.props.activeUnit}
        isInCart={unit.in_cart}
        isSearchView={this.props.isSearchView}
        isPublicView={this.props.isPublicView}
        isAgencyView={this.props.isAgencyView}
        isCampaignView={this.props.isCampaignView}
        onFavoriteUnit={this.props.onFavoriteUnit}
        onToggleRecommendUnit={this.props.onToggleRecommendUnit}
        showTips={this.props.showTips}
        index={index}
        pointOfInterest={points.find(p => p.id == unit.nearby_point_of_interest_slug)}
        user={user}
        scoreFlagEnabled={scoreFlagEnabled}
      />
    );
  }

  renderExpandedItem(unit, index) {
    return (
      <div
        className="listing__list-item-expanded"
        key={`${index}-unit-${unit.id}`}
        onClick={this.onListClick.bind(this, unit.id)}
        onMouseEnter={this.onListOver.bind(this, unit)}
        onMouseLeave={this.onListOut.bind(this, unit)}
      >
        {this.renderListItemComponent(unit, index)}
      </div>
    );
  }

  renderExpandedList(itemsToRender) {
    // Group units into two categories
    // The "Available" section is a bit misleading since we dont know yet
    // We only know about the "unavailable" units because we already hit the avails api for those
    const groupedUnits = itemsToRender.reduce((acc, unit) => {
      const key = unit.supplier_status === 'api_unavailable' ? 'unavailable' : 'available';
      acc[key] = acc[key] || [];
      acc[key].push(unit);
      return acc;
    }, {});

    if (!groupedUnits.unavailable || groupedUnits.unavailable.length === 0) {
      return itemsToRender.map((item, index) => {
        return this.renderExpandedItem(item, index);
      })
    }

    return (
      <div className="listing__container">
        {/* Only render Available section if there are available units */}
        {groupedUnits.available?.length > 0 && (
          <div className="listing__column">
            <div className="listing__section-header">
              <h3 className="listing__section-title">Available</h3>
            </div>
            <div className="listing__scroll-container">
              {groupedUnits.available.map((item, index) =>
                this.renderExpandedItem(item, index)
              )}
            </div>
          </div>
        )}

        {/* Only render Unavailable section if there are unavailable units */}
        {groupedUnits.unavailable?.length > 0 && (
          <div className="listing__column">
            <div className="listing__section-header">
              <h3 className="listing__section-title">Unavailable</h3>
            </div>
            <div className="listing__scroll-container">
              {groupedUnits.unavailable.map((item, index) =>
                this.renderExpandedItem(item, index)
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  units() {
    DEBUG && console.log("sidebar units updated. rerendering...");
    return sidebarUnits(withinBounds(this.props.units, this.props.bounds), true).slice(0, this.props.limit);
  }

  noItemsToRender() {
    const { campaign, units } = this.props
    const hasUnitsInState = !_.isEmpty(units.all_units)
    const campaignMessage = hasUnitsInState ? "No units found" : "There are no units on this campaign"
    const browseMessage = !this.props.current_market ? "Select a market" :
      hasUnitsInState ? "No units found" : "There were no search results";
    return (
      <div>
        <div style={{padding:15}}>
          {campaign ? campaignMessage : browseMessage}
        </div>
      </div>
    )
  }

  renderLoadingList() {
    return Array.from({ length: 10 }).map((_, index) => (
      <div key={index} className="listing__list-item-expanded listing__list-item-expanded--loading">
        <div className='listItemExpanded'>
          <div>
            <div className='listItemExpanded__image default_image placeholder_image' />
          </div>
          <div style={{ marginTop: 16 }}>
            <div className="listItemExpanded__loadingPlaceholder" />
            <div className="listItemExpanded__loadingPlaceholder" style={{ width: '70%' }} />
          </div>
          <div style={{ marginTop: 16, display: 'flex', gap: 16, width: '100%' }}>
            <div style={{ flex: 1 }}>
              <div className="listItemExpanded__loadingPlaceholder" style={{ width: '60%' }} />
              <div className="listItemExpanded__loadingPlaceholder" style={{ width: '50%' }} />
              <div className="listItemExpanded__loadingPlaceholder" style={{ width: '40%' }} />
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div className="listItemExpanded__loadingPlaceholder" style={{ width: '70%' }} />
              <div className="listItemExpanded__loadingPlaceholder" style={{ width: '80%' }} />
              <div className="listItemExpanded__loadingPlaceholder" style={{ width: '30%' }} />
            </div>
          </div>
        </div>
      </div>
    ))
  }

  render() {
    const { loading } = this.props;
    const { units } = this.state;

    return (<div>
      <ul className="listing__list">
        {loading && this.renderLoadingList()}
        {!loading && _.isEmpty(units) && this.noItemsToRender()}
        {!_.isEmpty(units) && this.renderExpandedList(units)}
      </ul>
    </div>);
  }
};

export default connect(({current_market, map, feature_flags})=>({current_market, bounds: map.bounds, feature_flags }), {toggleUnitPopup })(Listing);
