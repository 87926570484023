import * as React from "react";
import { Link, browserHistory } from "react-router-legacy";
import Select from "react-select";

export default class ExportTemplateEdit extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const { params } = props;
    this.state = {
      id: params.id,
      name: "",
      isDefault: false,
      columnMap: [["", "", ""]],
      isEditing: params.id != null,
      showSaved: false,
      errors: "",
      valueOptions: [{ label: "", value: "" }],
      isLoading: true,
    };
    this.onAddColumn = this.onAddColumn.bind(this);
    this.onSave = this.onSave.bind(this);
    this.validate = this.validate.bind(this);
    this.onDeleteTemplate = this.onDeleteTemplate.bind(this);
    this.onDuplicateTemplate = this.onDuplicateTemplate.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  componentDidMount() {
    const { isEditing, id } = this.state;
    if (isEditing) {
      $.ajax({
        url: `/api/v1/export_templates/${id}`,
        success: response => {
          this.setState({
            columnMap: response.column_map,
            name: response.name,
            isDefault: response.is_default, 
            isLoading: false,
          });
        },
      });
    }
    $.ajax({
      url: "/api/v1/export_templates/column_options",
      success: response => {
        this.setState({ valueOptions: response });
        if (!isEditing) this.setState({ isLoading: false });
      },
    });
  }

  redirectToEdit(id) {
    browserHistory.push(`/export_templates/${id}`);
  }

  columnMapJSON() {
    const data = $("form.export_template_form").serializeArray();
    let columns = Array({});
    data.splice(1).forEach((f) => { 
      let [idx, name] = f.name.split('-'); 
      let index = parseInt(idx);
      columns[index] = {...columns[index], ...{[name]: f.value}}
    });
    return columns;
  }

  onSave(e) {
    e.preventDefault();

    this.setState({ errors: "" });
    const errors = this.validate();
    if (errors) {
      this.setState({ errors: errors });
      return;
    }

    const { isEditing, id, name, isDefault } = this.state;

    let method, url;
    if (isEditing) {
      method = "PATCH";
      url = `/api/v1/export_templates/${id}`;
    } else {
      method = "POST";
      url = `/api/v1/export_templates`;
    }

    $.ajax({
      method: method,
      url: url,
      data: {
        export_template: {
          name,
          column_map: this.columnMapJSON(),
          is_default: isDefault,
        },
      },
      success: response => {
        if (!isEditing) {
          this.redirectToEdit(response.id);
        }
        this.setState({ showSaved: true, isEditing: true, id: response.id });
        setTimeout(() => {
          this.setState({ showSaved: false });
        }, 5000);
      },
    });
  }

  validate() {
    let errors = "";
    const { name } = this.state;
    if (!name) {
      errors = "Please enter a name.";
    }
    return errors;
  }

  onAddColumn(e) {
    e.preventDefault();
    const { columnMap } = this.state;
    this.setState({
      columnMap: [...columnMap, ["", "", ""]],
    });
  }

  onValueChange(val, index) {
    const newColumnMap = this.state.columnMap;
    newColumnMap[index][1] = val.value;
    this.setState({ columnMap: newColumnMap });
  }

  onRemove(index, e) {
    const newColumnMap = this.state.columnMap;
    newColumnMap.splice(index, 1);
    this.setState({ columnMap: newColumnMap });
  }

  onDeleteTemplate() {
    const result = window.confirm("Are you sure you want to delete this template? This action is not reversible.");
    const { id } = this.state;
    if (result) {
      $.ajax({
        method: "DELETE",
        url: `/api/v1/export_templates/${id}`,
        success: () => {
          browserHistory.push("/export_templates?deleted=true");
        },
      });
    }
  }

  onDuplicateTemplate() {
    const result = window.confirm("Are you sure you want to duplicate this template?");
    const { id } = this.state;
    if (result) {
      $.ajax({
        method: "POST",
        url: `/api/v1/export_templates/${id}/duplicate`,
        success: () => {
          browserHistory.push("/export_templates");
        },
      });
    }
  }

  render() {
    const { name, columnMap, isEditing, showSaved, errors, isLoading, valueOptions, isDefault } = this.state;
    const title = isEditing ? "Edit Template" : "New Template";
    const submitText = isEditing ? "Save Changes" : "Create Template";

    return (
      <div className="container export_templates_edit">
        <ol className="breadcrumb">
          <li>
            <Link to="/export_templates">Export Templates</Link>
          </li>
          <li className="active">{title}</li>
        </ol>
        <h4 className="export_template_header">{title}</h4>

        <form className={"export_template_form " + (isLoading ? "loading_blank" : "")}>
          {showSaved && <div className="alert alert-success">Your changes have been saved.</div>}
          {errors && <div className="alert alert-danger">{errors}</div>}

          <div className="form-group clearfix">
            <div className="name">
              <label>Template Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
              />
            </div>
            <div className="is_default">
              <input
                type="checkbox"
                name="name"
                checked={!!isDefault}
                onChange={e => {
                  this.setState({ isDefault: e.target.checked });
                }}
              />
              <label>Use as default (Excel only)</label>
            </div>
          </div>
          <div className="column_map_form">
            <div className="title">Columns</div>
            <div className="labels clearfix">
              <div className="header">Headers</div>
              <div className="value">Values</div>
              <div className="grouping_column">Grouping Column (optional)</div>
            </div>
            {columnMap.map((mapping, index) => {
              return (
                <div key={`${index}-${mapping[0]}`} className="form-group clearfix">
                  <div className="header">
                    <span className="numeral">{index + 1}.</span>
                    <input type="text" name={`${index}-header`} className="form-control" defaultValue={mapping[0]} />
                  </div>
                  <div className="value">
                    <Select
                      options={valueOptions}
                      name={`${index}-value`}
                      value={valueOptions.find(o => o.value === mapping[1])}
                      onChange={val => this.onValueChange(val, index)}
                      placeholder="Select a value"
                      clearable={false}
                    />
                  </div>
                  <div className="grouping_column">
                    <input type="text" name={`${index}-grouping_column`} className="form-control" defaultValue={mapping[2]} placeholder="e.g. Unit (to group unit related columns)" />
                  </div>
                  <div className="remove">
                    <a onClick={this.onRemove.bind(this, index)}>remove</a>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="add_column">
            <button onClick={this.onAddColumn} className="btn btn-info">
              + Add Column
            </button>
          </div>

          <div className="submit">
            <button onClick={this.onSave} className="btn btn-primary">
              {submitText}
            </button>
            <a onClick={this.onDeleteTemplate} className="delete_template">
              delete template
            </a>
          </div>
          <div className="submit">
            <a onClick={this.onDuplicateTemplate}>
              duplicate template
            </a>
          </div>
        </form>
      </div>
    );
  }
}
