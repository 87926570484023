import * as React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import {
  updateTakedownDates,
  markStillPosted
} from "../../../../actions/campaign_design_actions";
import GlobalActions from "../../../../actions/GlobalActions";
import DateRangePicker from "../../../DatePicker/Range";
const ASSETS = (window as any).ASSETS;

interface Props {
  show: boolean;
  onHide: Function;
  updateTakedownDates: Function;
  markStillPosted: Function;
  campaign: any;
  selected_units: { campaign_unit_token: string }[];
}

interface State {
  take_down_date: any;
  saving: boolean;
}

class TakeDownDatesModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      take_down_date: null,
      saving: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.saveDates = this.saveDates.bind(this);
    this.onStillPosted = this.onStillPosted.bind(this);
    this.markStillPosted = this.markStillPosted.bind(this);
  }

  private saveDates() {
    this.setState({ saving: true }, this.onSubmit);
  }

  async onSubmit() {
    const { take_down_date } = this.state;
    const {
      campaign,
      selected_units,
      updateTakedownDates,
      onHide
    } = this.props;
    const campaign_id = campaign.campaignId;
    const campaign_unit_ids = selected_units.map(u => u.campaign_unit_token);
    await updateTakedownDates(
      campaign_id,
      campaign_unit_ids,
      take_down_date.toDate()
    );
    this.setState({ saving: false }, () => {
      GlobalActions.showMessage("Take down dates updated.");
      onHide();
    });
  }

  private onDateChange(value) {
    this.setState({ take_down_date: value });
  }

  private renderLoadingSpinner() {
    return <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>;
  }

  async markStillPosted() {
    const { campaign, selected_units, markStillPosted, onHide } = this.props;
    const campaign_id = campaign.campaignId;
    const campaign_unit_ids = selected_units.map(u => u.campaign_unit_token);
    await markStillPosted(campaign_id, campaign_unit_ids);
    this.setState({ saving: false }, () => {
      GlobalActions.showMessage("The selected units were marked as still up.");
      onHide();
    });
  }

  private onStillPosted() {
    this.setState({ saving: true }, this.markStillPosted);
  }

  private renderBody() {
    const { onHide } = this.props;
    const { take_down_date, saving } = this.state;

    return (
      <div
        className="default_modal_container"
        onClick={e => e.stopPropagation()}
      >
        <div style={{ width: 330 }} className="default_modal">
          <div
            style={{justifyContent:"space-evenly"}}
            className="modal_header"
          >
            <h5>Select Take Down Date</h5>
            <div className="modal_close">
              <div className="close_button" onClick={() => onHide()}></div>
            </div>
          </div>
          <div className="modal_body">
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
              className="field"
            >
              <DateRangePicker
                onSelect={this.onDateChange}
                value={take_down_date}
                singleDateRange={true}
                selectionType="single"
              />
            </div>
            <div className="modal_footer" style={{ display: "grid" }}>
              <button
                className="primary-button"
                onClick={saving ? () => {} : this.saveDates}
              >
                {saving ? this.renderLoadingSpinner() : "Submit"}
              </button>
              <div className="still_posted_link_area">
                <a className="units-still-posted" onClick={this.onStillPosted}>
                  Units are Still Up
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal
        className="update_take_down_dates_modal"
        show={show}
        onHide={onHide}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(({}) => ({}), { updateTakedownDates, markStillPosted })(
  TakeDownDatesModal
);
