import { TMediaSubtype, TMediaType, TUnitScreenSubtype } from "../models/media_type";
import { del, get, post } from "../utils/api";

const prefix = "media_types";
const MEDIA_TYPES_API_URL = "/api/v1/media_types"
const MEDIA_SUBTYPES_API_URL = "/api/v1/media_subtypes"
const UNIT_SCREEN_SUBTPES_API_URL = "/api/v1/media_types/screen_subtypes"

export const LOAD_MEDIA_TYPES = `${prefix}/LOAD_MEDIA_TYPES`;
export const LOAD_MEDIA_SUBTYPES = 'media_subtypes/LOAD_MEDIA_SUBTYPES';
export const LOAD_UNIT_SCREEN_SUBTYPES = 'media_subtypes/LOAD_UNIT_SCREEN_SUBTYPES';
export const loadMediaTypes = (campaign_token) => async (dispatch, getState) => {
  const res = await get(MEDIA_TYPES_API_URL, null, {campaign: campaign_token});
  const media_types: TMediaType[] = res.data.media_types;
  dispatch({ type: LOAD_MEDIA_TYPES, payload: { media_types } });
};

export const loadMediaTypesWithSubtypes = () => async (dispatch, getState) => {
  const res = await get(MEDIA_TYPES_API_URL, null, {include_subtypes: true});
  const media_types: TMediaType[] = res.data.media_types;
  dispatch({ type: LOAD_MEDIA_TYPES, payload: { media_types } });
};

export const loadOnlySubtypes = (campaign_token) => async (dispatch, getState) => {
  const res = await get(MEDIA_SUBTYPES_API_URL, null, {campaign: campaign_token})
  const media_subtypes: TMediaSubtype[] = res.data
  dispatch({ type: LOAD_MEDIA_SUBTYPES, payload: { media_subtypes } })
}

export const loadUnitScreenSubtypes = (campaign_token) => async (dispatch, getState) => {
  const res = await get(UNIT_SCREEN_SUBTPES_API_URL, null, {campaign: campaign_token})
  const unit_screen_subtypes: TUnitScreenSubtype[] = res.data.screen_subtypes
  dispatch({ type: LOAD_UNIT_SCREEN_SUBTYPES, payload: { unit_screen_subtypes } })
}
