import React from 'react';

class Favorite extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isHovering: false
    }
    this.isFavorited = this.isFavorited.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.styles = this.styles.bind(this);
    this.iconClasses = this.iconClasses.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.isFavorited !== this.props.isFavorited ||
      nextState.isHovering !== this.state.isHovering
  }

  isFavorited() {
    return this.props.isFavorited;
  }

  handleMouseEnter() {
    this.setState({ isHovering: true });
  }

  handleMouseLeave() {
    this.setState({ isHovering: false });
  }

  handleClick(e) {
    e.stopPropagation();

    return this.props.onFavoriteUnit(this.props.unitId, this.props.campaignUnitId, this.isFavorited(), this.props.showCallback);
  }

  styles() {
    const css = {
      general: {
        color: "#9b9b9b",
        cursor: "pointer",
      },
      hover: {
        color: "#d2122a",
      },
      favorited: {
        color: "#d2122a",
      }
    }
    if (this.state.isHovering) {
      return Object.assign(css.general, css.hover);
    } else if (this.isFavorited()) {
      return Object.assign(css.general, css.favorited);
    } else {
      return css.general;
    }
  }

  iconClasses() {
    let heartClass = (this.isFavorited()) ? "fas fa-heart" : "far fa-heart";
    return (heartClass += " favorite");
  }

  render() {
    const className = this.iconClasses();
    return (<i
      style={this.styles()}
      className={className}
      onClick={(e) => {this.handleClick(e)}}
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
    ></i>)
  }
}

export default Favorite;
