import _ from 'lodash'

const initialState = {
  units_added: [],
  loading_units: false,
  loading_availability: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_CAMPAIGN_BUILDER_SUCCESS": {
      const { campaign_builder, campaign_token, campaign_dates } = action.payload;
      return {
        ...state,
        campaign_builder,
        campaign_token,
        campaign_dates,
        step: 2
      };
    }
    case "LOAD_CAMPAIGN_BUILDER_SUCCESS": {
      const { campaign_builder, units_added, campaign_token, campaign_dates } = action.payload;
      return {
        ...state,
        campaign_builder,
        units_added: _.sortBy(units_added, 'supplier_face_id'),
        campaign_token,
        campaign_dates,
      };
    }
    case "CAMPAIGN_BUILDER_ADD_UNITS_SUCCESS": {
      const { units_added, units_not_found } = action.payload;
      return {
        ...state,
        units_added: _.sortBy(units_added, 'supplier_face_id'),
        units_not_found,
      };
    }
    case "CAMPAIGN_BUILDER_REMOVE_UNIT_SUCCESS": {
      const { units } = action.payload;
      return {
        ...state,
        units_added: _.sortBy(units, 'supplier_face_id'),
      };
    }
    case "CAMPAIGN_BUILDER_LOAD_ZIP_CODES": {
      const { zip_codes } = action.payload;
      return {
        ...state,
        zip_codes,
      };
    }
    case "CAMPAIGN_BUILDER_RESET_MAP_STATE": {
      return {
        ...state,
        units_added: null,
        campaign: null,
        campaign_token: null,
        campaign_dates: null
      };
    }
    case "CAMPAIGN_BUILDER_GO_TO_STEP": {
      const { step } = action.payload;
      return {
        ...state,
        step: step,
      };
    }
    case "CAMPAIGN_BUILDER_LOAD_FACE_IDS": {
      const { supplier_face_ids } = action.payload;

      return {
        ...state,
        supplier_face_ids: supplier_face_ids.map((face_id, i) => {
          return { id: i, title: face_id };
        })
      };
    }
    case "FILER_SUPPLIER_FACE_IDS": {
      const { exclusions } = action.payload;
      const supplier_face_ids = _.filter(action.payload.supplier_face_ids, (id) => {
        return !!!(_.includes(exclusions, id.title));
      })

      return {
        ...state,
        supplier_face_ids
      };
    }
    case "CAMPAIGN_BUILDER_LOAD_CAMPAIGN_UNITS_STARTED": {
      return {
        ...state,
        loading_units: true
      }
    }
    case "CAMPAIGN_BUILDER_LOAD_CAMPAIGN_UNITS_SUCCESS": {
      const { campaign_units } = action.payload;

      return {
        ...state,
        units_added: _.sortBy(campaign_units, "supplier_face_id"),
        loading_units: false
      };
    }
    case "CAMPAIGN_BUILDER_LOAD_AVAILABILITY_STARTED": {
      return {
        ...state,
        loading_availability: true,
      };
    }
    case "CAMPAIGN_BUILDER_LOAD_AVAILABILITY_SUCCESS": {
      const { campaign_units } = action.payload;

      const units_with_avails = state.units_added.map(u => {
        const avail = campaign_units.find(a => a.campaign_unit_token === u.campaign_unit_token);
        return { ...u, available: avail.available };
      });
      return {
        ...state,
        units_added: _.sortBy(units_with_avails, "supplier_face_id"),
        loading_availability: false,
      };
    }
    case "CAMPAIGN_BUILDER_CREATE_FLIGHTS_BEGIN": {
      return {
        ...state,
        creating_flights: true
      };
    }
    case "CAMPAIGN_BUILDER_CREATE_FLIGHTS_SUCCESS": {
      const { unit_tokens, flight_type } = action.payload;
      return {
        ...state,
        creating_flights: false,
        units_added: state.units_added.map((u) => {
          if (unit_tokens.includes(u.campaign_unit_token)) {
            return { ...u, workflow_state: flight_type };
          }
          return u;
        })
      }
    }
    case "CAMPAIGN_BUILDER_ADD_NOTES_BEGIN": {
      return {
        ...state,
        adding_notes: true
      };
    }
    case "CAMPAIGN_BUILDER_ADD_NOTES_SUCCESS": {
      const { campaign_units } = action.payload;

      return {
        ...state,
        units_added: _.sortBy(campaign_units, "supplier_face_id"),
        adding_notes: false
      };
    }
    case "CAMPAIGN_BUILDER_UPDATE_DATES_BEGIN": {
      return {
        ...state,
        saving_dates: true
      };
    }
    case "CAMPAIGN_BUILDER_UPDATE_DATES_SUCCESS": {
      const { unit_tokens, start_date, end_date } = action.payload;
      return {
        ...state,
        saving_dates: false,
        units_added: state.units_added.map((u) => {
          if (unit_tokens.includes(u.campaign_unit_token)) {
            return { ...u, start_date, end_date };
          }
          return u;
        })
      }
    }
    case "CAMPAIGN_BUILDER_UPDATE_CONFLICTS": {
      return {
        ...state,
        saving_dates: false,
        creating_flights: false
      };
    }
    case "CAMPAIGN_BUILDER_UPDATE_SPOTS_BEGIN": {
      return {
        ...state,
        saving_spots: true
      };
    }
    case "CAMPAIGN_BUILDER_UPDATE_SPOTS_SUCCESS": {
      const { campaign_units } = action.payload;
      return {
        ...state,
        units_added: _.sortBy(campaign_units, "supplier_face_id"),
        saving_spots: false
      };
    }
    case "CAMPAIGN_BUILDER_UPDATE_PRICING_BEGIN": {
      return {
        ...state,
        saving_pricing: true
      };
    }
    case "CAMPAIGN_BUILDER_UPDATE_PRICING_SUCCESS": {
      const { campaign_units } = action.payload;
      return {
        ...state,
        units_added: _.sortBy(campaign_units, "supplier_face_id"),
        saving_pricing: false
      };
    }
    case "CAMPAIGN_BUILDER_DELETE_UNITS_BEGIN": {
      return {
        ...state,
        deleting_units: true
      };
    }
    case "CAMPAIGN_BUILDER_DELETE_UNITS_SUCCESS": {
      const { campaign_units } = action.payload;

      return {
        ...state,
        units_added: _.sortBy(campaign_units, "supplier_face_id"),
        deleting_units: false
      };
    }
    case "LOAD_MEDIA_TYPE_OPTIONS": {
      const { media_types } = action.payload;
      const media_type_options = media_types.map((media_type) => {
        return {
          label: media_type.name,
          value: media_type.name.toLowerCase().split(" ").join("_")
        }
      });

      return {
        ...state,
        media_type_options
      };
    }
    case "LOAD_MEDIA_SUBTYPE_OPTIONS": {
      const { media_subtypes } = action.payload;
      return {
        ...state,
        media_subtypes
      };
    }
    case "UPDATE_CAMPAIGN_BUILDER_SUCCESS": {
      const {
        campaign_builder,
        campaign_dates,
        campaign_token
      } = action.payload;
      return {
        ...state,
        step: 2,
        campaign_token,
        campaign_builder,
        campaign_dates
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
