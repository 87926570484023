import * as React from "react";
import { connect } from "react-redux";

import { fetchUnitRequestsCount } from "./actions";

class UnitRequests extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.respondToRequests = this.respondToRequests.bind(this);
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier);
    }
  }

  respondToRequests() {
    window.location.href = "/adquick_requests";
  }

  fetchData(supplier) {
    const { fetchUnitRequestsCount } = this.props;
    fetchUnitRequestsCount(supplier);
  }

  render() {
    const { unit_requests_count, loading_unit_requests_count } = this.props;

    return (
      <div id="unit_requests" className="todo-card">
        {loading_unit_requests_count && (
          <div className="loading-spinner">
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          </div>
        )}
        {!!!loading_unit_requests_count && (
          <div className={`count ${unit_requests_count >= 10 ? "high" : ""}`}>{unit_requests_count}</div>
        )}
        <div className="card-name">Unit Requests</div>
        <button className="todo-button" onClick={this.respondToRequests}>
          View
        </button>
      </div>
    );
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: { unit_requests_count, loading_unit_requests_count, selected_supplier },
  }) => ({
    unit_requests_count,
    loading_unit_requests_count,
    selected_supplier,
  }),
  {
    fetchUnitRequestsCount,
  },
)(UnitRequests);
