import { PackageUnit } from "../models/rfp";
import { del, get, post, put } from "../utils/api";

const prefix = "requests/rfp_response";
const SUPPLIER_TASK_API_URL = "/api/v1/supplier_tasks";

export const FETCH_RFP_PACKAGE_UNITS = `${prefix}/FETCH_RFP_PACKAGE_UNITS`;
export const fetchRFPPackageUnits = (supplier_task_id) => async (dispatch, getState) => {
  const package_units: PackageUnit[] = await get(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/package_units`);
  dispatch({ type: FETCH_RFP_PACKAGE_UNITS, payload: package_units });
  return package_units;
};

export const SAVE_RFP_PACKAGE_UNIT = `${prefix}/SAVE_RFP_PACKAGE_UNIT`;
export const saveRFPPackageUnit = (
  supplier_task_id: number,
  unit: PackageUnit,
  inventory_file_id: number | null) => async (dispatch, getState) => {
  const { grid_data } = getState().adquick_requests_ui.rfp_response;
  try {
    const package_unit = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/package_units`, { unit, inventory_file_id, grid_data: JSON.stringify(grid_data) });
    dispatch({ type: SAVE_RFP_PACKAGE_UNIT, payload: package_unit });
    return package_unit;
  } catch (error) {
    throw error;
  }
};

export const updateRFPPackageUnit = (supplier_task_id: number, unit: PackageUnit) => async (dispatch, getState) => {
  try {
    const package_unit = await put(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/package_units/${unit.id}`, unit);
    dispatch({ type: SAVE_RFP_PACKAGE_UNIT, payload: package_unit });
    return package_unit;
  } catch (error) {
    throw error;
  }
};

export const DELETE_RFP_PACKAGE_UNIT = `${prefix}/DELETE_RFP_PACKAGE_UNIT`;
export const deleteRFPPackageUnit = (supplier_task_id, campaign_unit_id) => async (dispatch, getState) => {
  const unit = await del(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/package_units/${campaign_unit_id}`);
  dispatch({ type: DELETE_RFP_PACKAGE_UNIT, payload: unit });

  return true;
}

export const LOAD_SUPPLIER_PACKAGES_LIST = `${prefix}/LOAD_SUPPLIER_PACKAGES_LIST`;
export const loadSupplierPackages = (supplier_task_id) => async (dispatch, getState) => {
  const package_units: any[] = await get(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/supplier_packages`);
  dispatch({ type: LOAD_SUPPLIER_PACKAGES_LIST, payload: package_units });
  return package_units;
};

export const LOAD_SUPPLIER_PACKAGE = `${prefix}/LOAD_SUPPLIER_PACKAGE`;
export const loadSupplierPackage = (supplier_task_id, unit_id) => async (dispatch, getState) => {
  const package_unit: PackageUnit = await get(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/supplier_packages/${unit_id}`);
  dispatch({ type: LOAD_SUPPLIER_PACKAGE, payload: package_unit });
  return package_unit;
};
