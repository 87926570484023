import Tooltip from "rc-tooltip";
import moment from "moment";
// @ts-ignore
import * as React from "react";
import { connect } from "react-redux";

import { fetchUpcomingFlights } from "./actions";

const ASSETS = (window as any).ASSETS;

class UpcomingFlights extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      show_all: false,
    };
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier);
    }
  }

  fetchData(supplier) {
    const { fetchUpcomingFlights } = this.props;
    fetchUpcomingFlights(supplier);
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner">
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    );
  }

  renderTooltipText() {
    return <div className="initiated_by_aqquick">This booking was initiated by AqQuick RFP.</div>;
  }

  onCampaignClick(campaign) {
    if (!!!campaign.is_aq_campaign) {
      window.location.href = `/campaigns/${campaign.token}/design_assets`;
    }
  }

  renderUpcomingFlightRows() {
    const { upcoming_flights, count, loading_upcoming_flights } = this.props;
    const { show_all } = this.state;

    if (upcoming_flights && !!!loading_upcoming_flights) {
      const flights_for_render = show_all ? upcoming_flights : upcoming_flights.slice(0, 10);
      return (
        <div className={`upcoming_flights_rows ${flights_for_render.length === count ? "all" : ""}`}>
          {flights_for_render.map(campaign => (
            <div key={campaign.token} className="campaign_row" onClick={() => this.onCampaignClick(campaign)}>
              <div className="aq-logo-area">
                {campaign.is_aq_campaign && (
                  <Tooltip
                    placement="bottom"
                    overlay={this.renderTooltipText()}
                    overlayStyle={{
                      opacity: 1,
                    }}
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                  >
                    <div>
                      <img className="mini-logo" src={ASSETS["mini-logo"]} />
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="campaign__name">{campaign.name}</div>
              <div className="campaign__a">{campaign.advertiser}</div>
              <div className="campaign__account_exec">{campaign.account_exec}</div>
              <div className="campaign__start_date">{moment(campaign.start_date).format("MM/DD/YYYY")}</div>
              <div className="campaign__end_date">{moment(campaign.end_date).format("MM/DD/YYYY")}</div>
              <div className="campaign__units">{campaign.units}</div>
              <div className="campaign__total_value">{campaign.total_value}</div>
            </div>
          ))}
          {!!!loading_upcoming_flights && upcoming_flights.length === 0 && (
            <div className="no_upcoming">No upcoming flights</div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  renderLoadAllButton() {
    const { count } = this.props;
    const { show_all } = this.state;

    if (!!!show_all && count > 10) {
      return (
        <div className="load-all-area">
          <button
            className="load-all-button"
            onClick={() => {
              this.setState({ show_all: true });
            }}
          >
            Load All ({count})
          </button>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  renderUpcomingFlightsTable() {
    const { loading_upcoming_flights } = this.props;
    return (
      <div className="upcoming_flights_table">
        <div className="table_header_area">
          <div className="table_header campaign_name">Campaign Name</div>
          <div className="table_header a">Advertiser</div>
          <div className="table_header account_exec">Account Manager</div>
          <div className="table_header start_date">Start Date</div>
          <div className="table_header end_date">End Date</div>
          <div className="table_header units"># of Units</div>
          <div className="table_header total_value">Total Value</div>
        </div>
        {this.renderUpcomingFlightRows()}
        {loading_upcoming_flights && <div className="upcoming_flights_table">{this.renderLoadingSpinner()}</div>}
        {this.renderLoadAllButton()}
      </div>
    );
  }

  renderSectionHeader() {
    const { currentUser } = this.props;
    const is_managed = currentUser.is_managed_supplier;

    return (
      <div className="upcoming_flights_header">
        {`${is_managed ? "" : "AdQuick "}Upcoming Booked/Movable Flights`}
        <span className="next_2_weeks">Next 2 Weeks</span>
        <button
          className="new_campaign_button"
          onClick={() => {
            if (is_managed) {
              window.location.href = "/campaign_builder";
            } else {
              window.location.href = "/campaigns/new";
            }
          }}
        >
          + New Campaign
        </button>
      </div>
    );
  }

  render() {
    return (
      <div id="upcoming_flights">
        {this.renderSectionHeader()}
        {this.renderUpcomingFlightsTable()}
      </div>
    );
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: { upcoming_flights, loading_upcoming_flights, selected_supplier, count },
    // @ts-ignore
    currentUser,
  }) => ({
    upcoming_flights,
    loading_upcoming_flights,
    selected_supplier,
    count,
    currentUser,
  }),
  { fetchUpcomingFlights },
)(UpcomingFlights);
