import { FETCH_COMMENTS, ADD_COMMENT, DELETE_COMMENT } from './actions';

const initialState = {
 comments: [],
}

export default (state = {}, {type, payload}) => {
  switch(type) {
    case FETCH_COMMENTS: {
      return {
        ...state,
        comments: payload
      }
    }
    case ADD_COMMENT: {
      const newComment = payload;
      const { comments } = state;

      return {
        ...state,
        comments: comments.concat(newComment)
      };
    }
    case DELETE_COMMENT: {
      const deletedComment = payload;

      return {
        ...state,
        comments: state.comments.filter(comment => comment.id != deletedComment.id)
      };
    }
    default: return state;
  }
}
