import { formatNumber, formatMoney } from 'accounting';
import React from 'react';

import { formattedToValue } from '../actions';
import { showingLevelByImpressions, unitsByBudget } from '../showing_level';

class EstimatedResults extends React.Component {

  showLevel() {
    const { target_impressions, location: { population } } = this.props;
    return Math.min(Math.max(parseInt(showingLevelByImpressions(population, formattedToValue(target_impressions))), 0), 100.0);
  }

  showLevelAnalysis() {
    const show_level = this.showLevel();
    if (show_level >= 50) return "Your budget size is on the domination level for this geo."
    if (show_level >= 25) return "Your budget size is on the saturation level for this geo."
    if (show_level >= 10) return "Your budget size is on the entry level for this geo."
    return "Your budget is low for a geo of this size."
  }

  render() {
    const {
      target_impressions,
      spend,
      location: { cpm, population, median_price, median_impressions, name }
    } = this.props;
    const impressions = formattedToValue(target_impressions);
    const budget = formattedToValue(spend);
    const show_level = this.showLevel();
    return (
      <div className="market-configuration-results">
        <div className="market-configuration-estimated-display">
          <div className="market-configuration-estimated-displaylevel">
            <div className="market-configuration-estimated-displaylevel-top">
              <div className="title">Estimated 4-Week Results</div>
              <div className="stats-block">
                <div className="block">
                  <p className="bold-info">{formatNumber(impressions)}</p>
                  <p className="info">4 week impressions</p>
                </div>
                <div className="block">
                  <p className="bold-info">{formatNumber(unitsByBudget(median_price, budget))}</p>
                  <p className="info">Campaign Units</p>
                </div>
              </div>
            </div>
            <hr className="pseudo-border"/>
            <div className="market-configuration-estimated-display-level-bottom">
              <div className="showing-block">
                <div className="title">DISPLAY LEVEL</div>
                <div className="bold-info">{formatNumber(show_level, 1) } display</div>
              </div>
              <div className="showing_bar">
                <div className="background">
                  <div className="fill" style={{ width: `${show_level > 100 ? 100 : show_level}%` }} />
                </div>
                <div className="analysis-text">{this.showLevelAnalysis()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="market-configuration-geosummary">
          <div className="title">GEO SUMMARY</div>
          <div className="bold-info">{name.split(',')[0]}</div>
          <div className="info">Population: {formatNumber(population)}</div>
          <div className="info divider">-</div>
          <div className="market-configuration-geosummary-unit-stats">
            <p className="info">Median Unit Price: {formatMoney(median_price)} / 4w</p>
            <p className="info">Median Impressions: {formatNumber(median_impressions)} / 4w</p>
            <p className="info">Median CPM: {formatMoney(cpm)}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default EstimatedResults;
