import React, { Component } from 'react';
import UploadButton from "../UploadButton";

const HELLOSIGN = 'hellosign';
const UPLOAD = 'upload';

class ContractTypeBadge extends Component {

  constructor(props) {
    super(props);
  }

  label() {
    const { type, isDone } = this.props;

    switch(type) {
      case HELLOSIGN: return 'HelloSign';
      case UPLOAD:
        return isDone ? 'Done Uploading' : 'Upload Contract';
    }
  }

  badgeClassName() {
    const { type } = this.props;

    switch(type) {
      case HELLOSIGN: return 'hellosign';
      case UPLOAD:    return 'upload';
    }
  }

  render(){
    const { type, onUploadComplete, isDone } = this.props;

    if (type === UPLOAD && !isDone) {
      return (
        <UploadButton key="upload_button" onComplete={onUploadComplete}>
          <div>
            <span onClick={this.onClick} className={`badge badge-${this.badgeClassName()}`}>{this.label()}</span>
          </div>
        </UploadButton>
      )
    } else {
      return (
        <span onClick={this.onClick} className={`badge badge-${this.badgeClassName()}`}>{this.label()}</span>
      )
    }
  }

}

export default ContractTypeBadge
