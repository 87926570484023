import { connect } from 'react-redux';
import React from 'react'

import {
  updateGeographies,
  updateLastTypedAt,
  updateLocationFilter
} from '../actions';

class GeoSearch extends React.Component {

  componentDidMount() {
    this.searchInput.focus();
  }

  options() {
    if (!this.props.geographies) return;
    return this.props.geographies.map(({ name, type, location_id }) => ({ label: `${name} (${type})`, value: location_id }) );
  }

  keyDown(event) {
    if (event.keyCode === 13) {
      this.fetchGeographies();
      return;
    }
    this.props.updateLastTypedAt(Date.now());
  }

  fetchGeographies() {
    const { updateGeographies } = this.props
    const { filter } = this.state;
    updateGeographies(filter)
  }

  render() {

    return (
      <div className="geo_search">
        <div className="search_box">
          <input
            type="text"
            autoFocus={false}
            className="form-control geo_search_input"
            ref={(input) => { this.searchInput = input; }}
            onChange={(e) => { let state = {}; state['filter'] = e.target.value; this.setState(state) }}
            placeholder="Search for a metro area, city"
            onKeyDown={this.keyDown.bind(this)}
          />
          <button
            onClick={this.fetchGeographies.bind(this)}
            className="btn btn-default"
          >
            Search
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    planner: {
      location_filter,
      geographies,
      selected_demographics
    }
  }) => ({
    location_filter,
    geographies,
    selected_demographics
  }),
  {updateGeographies, updateLocationFilter, updateLastTypedAt}
)(GeoSearch);
