// NOTE: whenever you change these functions, also consider updating the corresponding functions in:
// packages/workspace/@adquick/plus/src/controllers/application_controller.js
import QueryString from "query-string"

export const stringifyQueryState = (queryState) => {
  return QueryString.stringify(queryState, { arrayFormat: 'bracket' });
}

export const params = () => {
  return QueryString.parse(window.location.search, {arrayFormat: 'bracket', parseBooleans: true });
}

export const paramsGet = params

export const paramsAdd = (key, val, dispatchEvent = true) => {
  const state = { ...params() };
  const stateForUrl = { ...state, [key]: val };
  history.replaceState(state, null, `?${stringifyQueryState(stateForUrl)}`);
  if (dispatchEvent) dispatchCustomEvent(params())
}

export const paramsRemove = (key, dispatchEvent = true) => {
  const state = { ...params() };
  delete state[key];
  const stateForUrl = { ...state };
  history.replaceState(state, null, `?${stringifyQueryState(stateForUrl)}`);
  if (dispatchEvent) dispatchCustomEvent(params());
}

export const paramsAddToArray = (key, value) => {
  this.paramsAdd(key, [].concat(this.params[key]).concat(value).filter(i => !!i))
}

export const paramsRemoveFromArray = (key, value) => {
  const state = { ...params() };
  const stateForUrl = { ...state, ...{[key]: state[key].filter(v => v != value )} }
  history.replaceState(state, null, `?${stringifyQueryState(stateForUrl)}`);
  dispatchCustomEvent(params())
}

export const hasParam = (key) => {
  return Object.keys(params()).includes(key)
}

export const hasArrayParam = (key, value) => {
  return hasParam(key) && params()[key].includes(value)
}

export const paramsClear = () => {
  history.replaceState(null, null, location.pathname)
  dispatchCustomEvent(params())
}

const dispatchCustomEvent = (params) => {
  window.dispatchEvent(new CustomEvent("adquick:history:replacestate", { detail: { params: params } }))
}
