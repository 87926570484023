import React from 'react';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore';
import MapStore from '../../stores/MapStore';
import UnitStore from '../../stores/UnitStore';
import GlobalActions from '../../actions/GlobalActions';
import { get, post } from '../../utils/api';
import accounting from 'accounting';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux'
import AddUnitForm from '../AddUnitForm';

const METADATA_URL = '/api/v1/metadata';

const LocationPinModal = createClass({
  getInitialState() {
    return {
    };
  },

  componentDidMount() {
    this.unsubscribeList = [
      UnitStore.listen(this.onUnitChange),
      AuthStore.listen(this.onAuthChange),
    ];
  },

  onAuthChange() {
    this.setState({ auth: AuthStore.getState() });
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  onUnitChange(eventName, response) {
    if (eventName == 'unit:create_success') {
      this.props.onHide();
      GlobalActions.showMessage('Unit saved!');
      GlobalActions.openUnitID(response.id);
    } else if (eventName == 'unit:create_error') {
      this.setState({ errors: response.errors })
      console.debug('error', response);
    }
  },

  onSubmit(params) {
    UnitStore.addUnit({ unit: params });
  },

  render() {
    let { lat, lon, details } = this.props;
    if (!details) details = {}

    const defaultValues = {
      lat,
      lon,
      address: details.formatted_address,
      name: details.name,
    };

    const campaignId = document.location.href.match(/campaign/)  ? this.props.campaignId : null

    return (
      <div>
        <Modal.Header className="text-center" closeButton>{details.name || "Add Point On Map"}</Modal.Header>
        <Modal.Body>
          <AddUnitForm onSubmit={this.onSubmit} defaultValues={defaultValues} campaignId={campaignId} serverErrors={this.state.errors} />
        </Modal.Body>
      </div>
    );
  },
});

export default connect(
  ({ campaign }) => ({ campaignId: campaign.campaignId }),
  null
)(LocationPinModal)
