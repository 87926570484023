import React from 'react';
class Recommend extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
    this.isRecommended = this.isRecommended.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.iconClasses = this.handleClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.isRecommended !== this.props.isRecommended
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loading: false })
  }

  isRecommended() {
    return this.props.isRecommended;
  }

  async handleClick(e) {
    e.stopPropagation();
    const { unitId, onToggleRecommendUnit } = this.props
    const flow = this.isRecommended() ? 'unrecommend' : 'recommend'
    this.setState({ loading: true }, () => onToggleRecommendUnit(unitId, flow))
  }

  iconClasses() {
    return this.isRecommended() ? 'recommended' : 'unrecommended';
  }

  render() { 
    
    const className = this.isRecommended() ? 'recommended' : 'unrecommended';
    return (
      <div className={className} onClick={this.handleClick}>
        <span className="recommended-text">R</span>
      </div>
    )
  }
}

export default Recommend;
