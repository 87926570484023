import { connect } from 'react-redux';
import React from 'react'
import accounting from 'accounting';
import BottomNav from '../BottomNav';
import { showDemographics, toggleSpendGroup, fetchGeoRecommendations } from '../actions';
import GeoSearch from './GeoSearch';
import Tooltip from "rc-tooltip";
class Geographies extends React.Component {

  componentDidMount(){
    this.props.fetchGeoRecommendations();
  }

  planHasLocation(location_id) {
    return this.props.spend_groups.some(g => g.location.id === location_id);
  }

  renderGroupedLocations() {
    const { geographies } = this.props
    if ( geographies.length < 1 ) { return }
    const groupedByType = _.groupBy(geographies, 'type');
    //take out zipcodes for now becuase not needed for v1
    //******************************************
    delete groupedByType["zip code"]
    //******************************************
    const types = _.keys(groupedByType);
    return types.map((type, idx) => this.renderLocationData(groupedByType[type]))
  }

  renderRecomendations() {
    const { recommendations } = this.props;
    if (!recommendations) return <div/>;
    const { metro_areas, places, zip_codes } = recommendations;
    const sorted_metro_areas = Object.values(metro_areas).sort(this.sortByScore.bind(this));
    sorted_metro_areas.length > 15 && (sorted_metro_areas.length = 15);
    const metro_location_parse = sorted_metro_areas.filter((item, index) => {
      if (!_.find(this.props.spend_groups, ['location.id', item.location_id])){
        return item;
      }
    });
    return metro_location_parse.map((group, idx) => 
      {
        const tool_tip = this.renderDemographics(group)
        return (
          <tr key={idx} className="place level_1">
            <td className="location_name"><span>{group.location_name}</span></td>
            <td className="attention">{accounting.formatMoney(group.cpm)}</td>
            {!_.isEmpty(this.props.selected_demographics) ? <td className="attention">{this.score(group)}</td> : <td>N/A</td>}
            <td>{accounting.formatNumber(group.location_population)}</td>
            {tool_tip}
            <td
              className={`${this.planHasLocation(group.location_id) ? 'remove' : 'add'}`}
              onClick={() => this.props.toggleSpendGroup(group.location_id)}
            ><i className="fa fa-plus" aria-hidden="true"></i></td>
          </tr>
        )
      }
    );
  }

  sortByScore(a, b) {
    return this.score(a) - this.score(b);
  }

  score(location) {
    if (!!!location.demographics || !!!location.demographics.length || !!!location.cpm) return parseFloat(location.cpm);
    const value = parseFloat(location.cpm) / location.demographics.reduce((acc, d) => d.percentage / 100 * acc, 1);
    return value.toFixed(2);
  }

  renderDemographics(location) {
    return (
      Object.values(this.props.selected_demographics).map(demo => {
        const demographic = location.demographics.find(d => d.name === demo.name)
        if(!demographic){
          return; 
        }
        return <td>{`${accounting.toFixed(demographic.percentage, 2)}% `}</td>
      })
    );
  }

  renderLocationData(location) {
    const { spend_groups } = this.props;
    const { selected_demographics } = this.props;

    const location_parse = location.filter((item, index) => {
      if (!_.find(spend_groups, ['location.id', item.location_id])){
        return item;
      }
    });

    const reference = Object.keys(selected_demographics).map(key => { 
      const temp_object = { [key]: selected_demographics[key].lookup_key}
      return temp_object;
    });

    return location_parse.map((group, idx) =>
      <tr key={idx} className="place level_1">
        <td className="location_name"><span>{group.name}</span></td>
        <td className="attention">{accounting.formatMoney(group.cpm)}</td>
        <td>{accounting.formatMoney(group.median_price)}</td>
        <td>{accounting.formatNumber(group.population)}</td>
        {
          reference.map(demo => {
            let temp = Object.entries(demo)
            if (temp[0][1] === "hispanic"){
              return <td>{`${group.demographics["hispanic_info"].hispanic}%`}</td>;
            } else {
              return <td>{`${group.demographics[temp[0][0]][temp[0][1]]}%`}</td>;
            }
          })
        }
        <td
          className={`${this.planHasLocation(group.location_id) ? 'remove' : 'add'}`}
          onClick={() => this.props.toggleSpendGroup(group.location_id)}
        ><i className="fa fa-plus" aria-hidden="true"></i></td>
      </tr>
    )
  }

  renderSpendGroupsData(){
    const { spend_groups } = this.props;

    return spend_groups.map((item, index) => {
      return (
        <tr key={index} className="place level_1">
          <td className="location_name group_data">
            <i className="fa fa-check-circle-o" aria-hidden="true"></i>
            <span>{item.location.name}</span>
          </td>
          <td className="attention">{accounting.formatMoney(item.location.cpm)}</td>
          <td>{accounting.formatMoney(item.location.median_price)}</td>
          <td>{accounting.formatNumber(item.location.population)}</td>
          {this.renderDemographicData(item)}
          <td className={`${this.planHasLocation(item.location.id) ? 'remove' : 'add'}`} onClick={() => this.props.toggleSpendGroup(item.location.id)}>
            <i className="fa fa-minus" aria-hidden="true"></i>
          </td>
        </tr>
      )
    });
  }

  renderDemographicData(spend_group) {
    const { selected_demographics } = this.props;
    const demographic_keys = Object.keys(selected_demographics);
    return demographic_keys.map(key => <td>{`${spend_group.demographic_data[key]}%`}</td>)
  }

  render() {
    const { selected_demographics, geographies, spend_groups } = this.props;
    const demographics = Object.keys(selected_demographics).map(key => selected_demographics[key].name)
    const top_header = ['Geography', 'CPM', 'Median Price', 'Population'].concat(demographics).concat(' ');
    const bottom_header = ['Recommended Markets', 'CPM', 'Demo Score', 'Population'].concat(demographics).concat(' ');
    const heading = (<thead>{top_header.map((item, key) =>(<th key={key} colSpan="1">{item}</th>))}</thead>);
    const heading2 = (<thead>{bottom_header.map((item, key) =>(<th key={key} colSpan="1">{item}</th>))}</thead>);
    const heading3 = (<thead>{['Geography', 'CPM', 'Median Price', 'Population', ''].map((item, key) => (<th key={key} colSpan="1">{item}</th>))}</thead>);
    return (
      <div className="section">
        <div className="subsection">
          <div className="heading" style={{ fontSize: "24px" }}>Add Markets</div>
          <div className="subheading">Select which markets you’d like to add.</div>
        </div>
        <div className="row" style={{marginTop: "25px"}}>
          <div className="col-md-12 geo-panel" style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
            <GeoSearch />
            <table className="geo_recommendations first" style={{ tableLayout: "fixed"}}>
              {geographies.length > 0 && heading}
              {geographies.length > 0 ? <tbody>{this.renderGroupedLocations()}</tbody> : <div className="empty-search">Search results will appear here.</div>}
              {geographies.length > 0 && heading2}
              {geographies.length > 0 ? <tbody>{this.renderRecomendations()}</tbody> : ""}
            </table>
          </div>
        </div>
        <div className="subsection" style={{ paddingTop: "37px", paddingBottom: "0px"}}>
          <div className="heading" style={{fontSize: "18px"}}>Markets Added</div>
        </div>
        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-md-12 geo-panel-2" style={{ display: "flex", justifyContent: "center" }}>
            <table className="geo_recommendations">
              {spend_groups.length > 0 && heading}
              {spend_groups.length > 0 ? <tbody>{this.renderSpendGroupsData()}</tbody> : <div className="empty-search">Markets will appear here when you add them.</div> }
            </table>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-4"></div> */}
          <div className="col-md-12" style={{ display: "flex", justifyContent: "center" }}>
            <BottomNav />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({planner}) => ({...planner}),
  { toggleSpendGroup, showDemographics,fetchGeoRecommendations }
)(Geographies)
