import React from 'react';
import validate from 'validate.js';
import cs from 'classnames';
import createClass from 'create-react-class';

import GlobalActions from '../actions/GlobalActions';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';

export default createClass({
  displayName: 'AddCreditCardModal',

  constraints: {
    name: {
      presence: {},
    },

    card: {
      presence: {},
      format: /^(34|37|4|5[1-5]).*$/,
      length(value) {
        if (value) {
          // Amex
          if ((/^(34|37).*$/).test(value)) return { is: 15 };
          // Visa, Mastercard
          if ((/^(4|5[1-5]).*$/).test(value)) return { is: 16 };
        }

        // Unknown card, don't validate length
        return false;
      },
    },

    expiration: {
      presence: {},
      format: {
        pattern: /\d{2}\/\d{2}/,
        message: 'should be MM/YY',
      },
    },

    cvc: {
      presence: {},
      // length: {
      //   is: 3,
      // },
      numericality: true,
    },

    zip: {
      presence: {},
    },
  },

  getInitialState() {
    return ({
      addButtonDisabled: false,
      activePayment: null,
      name: '',
      card: '',
      expiration: '',
      cvc: '',
      zip: '',
      errors: {},
    });
  },

  componentDidMount() {
    AuthStore.listen(this.handleAuthChange);
  },

  handleAuthChange(e) {
    if (e === 'auth:addPayment:error') {
      const errors = AuthStore.getState().errors;
      this.setState({
        addButtonDisabled: false,
        errors,
      });
    } else {
      GlobalActions.closePopup();
    }
  },

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  },

  handleNumberChange(e) {
    this.setState({ card: e.target.value });
  },

  handleExpirationChange(e) {
    this.setState({ expiration: e.target.value });
  },

  handleCvcChange(e) {
    this.setState({ cvc: e.target.value });
  },

  handleZipChange(e) {
    this.setState({ zip: e.target.value });
  },

  validate() {
    const errors = validate(this.state, this.constraints);
    this.setState({ errors });

    return errors;
  },

  renderErrors() {
    const errors = this.state.errors;
    const formattedErrors = [];

    for (const key in errors) {
      formattedErrors.push(errors[key]);
    }

    if (formattedErrors.length) {
      return formattedErrors.map(err => <p className="message__row" key={err}>{err}</p>);
    }
  },

  addPaymentMethod(event) {
    const errors = this.validate();
    if (!errors) {
      this.setState({ addButtonDisabled: true });

      const methodData = {
        number: this.state.card,
        exp_month: this.state.expiration.split('/')[0],
        exp_year: this.state.expiration.split('/')[1],
        cvv: this.state.cvv,
      };
      AuthActions.addPaymentMethod(methodData);
    }

    event.preventDefault();
  },


  render() {
    const nameError = this.state.errors && this.state.errors.name;
    const cardError = this.state.errors && this.state.errors.card;
    const expirationError = this.state.errors && this.state.errors.expiration;
    const cvcError = this.state.errors && this.state.errors.cvc;
    const zipError = this.state.errors && this.state.errors.zip;

    return (<div className="campaignPaymentModal" onClick={e => e.stopPropagation()} style={{ width: 482 }}>
      <h1 className="title">Add Credit Card</h1>

      <div className="campaign__content">
        <div className="campaignPayment__newType">
          <form className="form campaignPayment__form">
            {this.renderErrors() &&
              <div className="form__row">
                <div className="message message_error">
                  {this.renderErrors()}
                </div>
              </div>}

            <div className={`form__row ${nameError ? 'error' : ''}`}>
              <label className="label_inline campaignPayment__label">Name on card</label>
              <div className="campaignPayment__input-wrap">
                <input type="text" className="input campaignPayment__input" onChange={this.handleNameChange} />
              </div>
            </div>

            <div className={`form__row ${cardError ? 'error' : ''}`}>
              <label className="label_inline campaignPayment__label">Card number</label>
              <div className="campaignPayment__input-wrap">
                <input type="text" className="input campaignPayment__input" onChange={this.handleNumberChange} />
              </div>
            </div>

            <div className="form__row">
              <div className={`campaignPayment__half-row ${expirationError ? 'error' : ''}`} style={{ width: 150 }}>
                <label className="campaignPayment__label campaignPayment__label_small">Expiration</label>
                <div className="campaignPayment__input-wrap campaignPayment__input-wrap_small">
                  <input type="text" className="input campaignPayment__input" placeholder="MM/YY" onChange={this.handleExpirationChange} />
                </div>
              </div>
              <div className={`campaignPayment__half-row ${cvcError ? 'error' : ''}`}>
                <label className="campaignPayment__label campaignPayment__label_small">CVC</label>
                <div className="campaignPayment__input-wrap campaignPayment__input-wrap_small">
                  <input type="password" className="input campaignPayment__input" onChange={this.handleCvcChange} />
                </div>
              </div>
            </div>

            <div className={`form__row ${zipError ? 'error' : ''}`}>
              <label className="campaignPayment__label">Zip Code</label>
              <div className="campaignPayment__input-wrap campaignPayment__input-wrap_small">
                <input type="text" className="input campaignPayment__input" onChange={this.handleZipChange} />
              </div>
            </div>
          </form>

          <div style={{ margin: '20px 0 20px 0' }}>
            <button className="btn btn-primary" onClick={this.addPaymentMethod} disabled={this.state.addButtonDisabled}>Add Credit Card</button>
          </div>

          <div style={{ color: 'gray' }}>
            Secured over SSL with <a href="https://stripe.com" target="_blank" className="link"> Stripe</a>
          </div>
        </div>
      </div>
    </div>);
  },
});
