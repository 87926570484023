import React from 'react';
import UnitStore from '../stores/UnitStore';
import SearchStore from '../stores/SearchStore';
import UnitInfo from './UnitInfo';
import UnitPopupError from './UnitPopupError';
import Comments from './UnitInfo/Comments';
import Warning from './Campaign/Warning';
import Loading from "./Loading";

class UnitPopup extends React.Component {

  constructor(props) {
    super(props);

    let unitOrPackage;
    if (this.props._package) {
      unitOrPackage = this.props._package;
    } else {
      unitOrPackage = this.props.unit;
    }

    this.state = {
      unit: this.props.unit,
      loading: true,
      error: false,
      _package: this.props._package,
      unitOrPackage: unitOrPackage,
    };

    this.loadUnit = this.loadUnit.bind(this);
  }

  componentDidMount() {
    // document.addEventListener('packageModalOpened', this.props.onClose)
    this.loadUnit()
  }

  componentWillUnmount() {
    // document.removeEventListener('packageModalOpened', this.props.onClose)
  }

  loadUnit(event = null, newUnitId = null) {
    const unitId = newUnitId || (this.state.unit && this.state.unit.id) || this.props.unit_id;
    const campaignId = this.props.campaignId;
    const withDeleted = this.props.withDeleted;
    const campaignUnitToken = (this.state.unit && this.state.unit.campaign_unit_token)
    this.setState({ loading: true, error: false });

    UnitStore.loadUnit(unitId, campaignId, withDeleted, campaignUnitToken, (unit, err) => {
      if (err) return this.handleLoadUnitError(err);

      let newState = { unit, loading: false };
      if (unit.parent_package) {
        newState._package = unit.parent_package
        newState.unitOrPackage = unit.parent_package;
      } else {
        newState.unitOrPackage = unit;
      }

      this.setState(newState);

      if (this.props.options && this.props.options.onPageLoad) {
        SearchStore.setView([unit.lat, unit.lon], 16);
      }
    });
  }

  handleLoadUnitError(err) {
    if (window.Rollbar) Rollbar.error('unitPopup', err)
    this.setState({ loading: false, error: true })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.unit) {
      const newUnitId = nextProps.unit.id;
      if (newUnitId != this.state.unit.id) {
        this.loadUnit(null, newUnitId)
      } else {
        const unitOrPackage = nextProps._package ? nextProps._package : nextProps.unit;
        this.setState({ unitOrPackage })
      }
    }
  }

  isFavorited() {
    if(this.props.activityView) { return }
    return _.flow(
      units => units.filter(u => u.id == this.state.unitOrPackage.id),
      units => units.map(u => u.campaign_units),
      campaign_units => campaign_units.reduce((acc, val) => acc.concat(val), []),
      campaign_units => campaign_units.filter(u=> u && u.is_favorited),
      campaign_units => !_.isEmpty(campaign_units)
    )(this.props.units)
  }

  isBonus() {
    if(this.props.activityView) { return }
    return _.flow(
      units => units.filter(u => u.id == this.state.unitOrPackage.id),
      units => units.map(u => u.campaign_units),
      campaign_units => campaign_units.reduce((acc, val) => acc.concat(val), []),
      campaign_units => campaign_units.filter(u=> u && u.is_bonus_unit),
      campaign_units => !_.isEmpty(campaign_units)
    )(this.props.units)
  }

  renderUnitInfo() {
    const options = this.props.options || {};

    return (
      <UnitInfo
        unit={this.state.unit}
        campaign={this.props.campaign}
        permissions={this.props.permissions}
        units={this.props.units}
        _package={this.state._package}
        user={this.props.user}
        options={options}
        onFavoriteUnit={this.props.onFavoriteUnit}
        isFavorited={this.props.isCampaignView ? this.isFavorited() : false}
        onPinUnit={this.props.onPinUnit}
        isPinned={this.state.unitOrPackage && this.state.unitOrPackage.is_pinned}
        onToggleRecommendUnit={this.props.onToggleRecommendUnit}
        onToggleBonusUnit={this.props.onToggleBonusUnit}
        isBonus={this.state.unitOrPackage && this.state.unitOrPackage.is_bonus_unit}
        isRecommended={this.state.unitOrPackage && this.state.unitOrPackage.is_recommended}
        onClose={this.props.onClose}
        isSearchView={options.isSearchView}
        activeTab={this.props.activeTab}
        isCartView={this.props.isCartView}
        isActivityView={this.props.activityView}
        isVendorMapView={options.isVendorMapView}
        loadUnit={this.loadUnit}
      />
    )
  }

  getCommentRecipients() {
    if (!this.state.unit || !this.state.unit.recipients) return [];
    return this.state.unit.recipients;
  }

  render() {
    const { loading, error } = this.state;
    const showComments = this.props.permissions && this.props.permissions.can_view_unit_comments && !!!error
    const showRecipients = showComments && this.props.permissions && this.props.permissions.can_view_unit_comments_recipients;
    const campaign_token = this.props.campaignId;
    const campaign_unit_token = this.state.unit && this.state.unit.campaign_unit_token;
    const recipients = showRecipients ? this.getCommentRecipients() : [];
    const currentCampaign = this.props.campaign && this.props.campaign.campaign || null

    return (
      <div
        className="modal-unit clearfix"
        style={{margin: 'auto'}}
        onClick={e => e.stopPropagation()}
      >
        {(!loading && showComments) &&
          <Comments
            campaign_token={campaign_token}
            campaign_unit_token={campaign_unit_token}
            recipients={recipients}
            user={this.props.user}
            onClose={this.props.onClose}
          />
          }
        {loading &&
          <Loading/>
        }
        {error && <UnitPopupError onRetry={this.loadUnit.bind(this)} /> }
        {!loading && !error && this.renderUnitInfo()}
      </div>
    );
  }
};

export default UnitPopup;
