import accounting from 'accounting';
import mapboxgl from 'mapbox-gl';

import { Market } from '../../models/market';

export default class MarketPopup {
  map: mapboxgl.Map;
  popup: mapboxgl.Popup;

  constructor(map) {
    this.map = map;
  }

  show(market: Market) {
    this.hide();
    this.popup = new mapboxgl.Popup({ closeButton: false, offset: 10 });
    this.popup.setLngLat([market.center_lon, market.center_lat]);
    this.popup.setHTML(this.content(market));

    this.popup.addTo(this.map);
  }

  hide() {
    if (!this.popup) return;
    this.popup.remove();
  }

  content(market) {
    return `
      <div class="new_map_unit_popup">
          <p class="header">${market.name}</p>
          <span class="title">Unit Count</span><span class="data">${market.units_count}<span><br/>
          ${market.median_cpm ? `<span class="title">Median CPM</span><span class="data">${market.median_cpm}<span><br/>` : ''}
          ${market.median_price ? `<span class="title">Median Price</span><span class="data">
            ${accounting.formatMoney(market.median_price, '$', 0)}
          <span><br/>` : ''}
      </div>
    `
  }
}
