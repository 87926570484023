import React from 'react';
import createClass from 'create-react-class';
import { get, post } from '../../../utils/api';
import GlobalActions from '../../../actions/GlobalActions';
import AuthStore from '../../../stores/AuthStore';
import { browserHistory } from 'react-router-legacy';
import DropzoneS3Uploader from '../../DropzoneS3Uploader.jsx'
import { ProgressBar } from 'react-bootstrap';

export default class VendorsSetup extends React.Component {

  constructor(props) {
    super(props);

    const user = AuthStore.getState()
    this.state = {
      user: user,
      file_paths: [],
      uploading: false,
      submitting: false,
      confirm: false,
    }

    this.onDoneUploading = this.onDoneUploading.bind(this);
    this.renderSectionOne = this.renderSectionOne.bind(this);
    this.renderSectionTwo = this.renderSectionTwo.bind(this);
    this.renderSectionThree = this.renderSectionThree.bind(this);
    this.renderUploadInventory = this.renderUploadInventory.bind(this);
    this.renderDoneUploadingButton = this.renderDoneUploadingButton.bind(this);
    this.loadingSpinner = this.loadingSpinner.bind(this);
    this.onContactUs = this.onContactUs.bind(this);
    this.handleFinishedUpload = this.handleFinishedUpload.bind(this);
    this.uploaderContent = this.uploaderContent.bind(this);
    this.onDropzoneDrop = this.onDropzoneDrop.bind(this);
    this.renderDropzone = this.renderDropzone.bind(this);
    this.removeUploadedFile = this.removeUploadedFile.bind(this);
    this.renderUploadedFilesSection = this.renderUploadedFilesSection.bind(this);
    this.renderConfirmationArea = this.renderConfirmationArea.bind(this);
    this.renderNextButton = this.renderNextButton.bind(this);
    this.renderProgressArea = this.renderProgressArea.bind(this);
  }

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];
  }

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  }

  renderSectionOne() {
    const { currentSampleFileUrl, currentUploadTemplateUrl } = AppConfig

    return (
      <div className="section">
        <div className="section-number">1</div>
        <a className="btn download-button" href={currentUploadTemplateUrl}>
          <i className="fa fa-download" aria-hidden="true"></i> Download our inventory template (.xls)
        </a>
      </div>
    )
  }

  renderSectionTwo() {
    return (
      <div className="section">
        <div className="section-number">2</div>
        <div className="section-text">
          <div className="strong">Format your inventory to match our template.</div>
          <ul>
            <li>Please <span className="strong">include production & install costs</span> for every unit.</li>
            <li>Please <span className="strong">include photo sheets & spec sheets</span> for every unit — these may be uploaded as separate PDFs or Image files. Ensure filenames match Face IDs.</li>
          </ul>
        </div>
      </div>
    )
  }

  onContactUs(e) {
    GlobalActions.openPopup('contact_us');
    e.preventDefault();
  }

  renderSectionThree() {
    return (
      <div className="section">
        <div className="section-number">3</div>
          <div className="section-text">
            <div className="strong">Upload your inventory files below.</div>
            <ul>
              <li>Double-check your files for accuracy before uploading.</li>
              <li>Need some help? Don’t hestitate to <a onClick={this.onContactUs}>contact us</a></li>
            </ul>
          </div>
      </div>
    )
  }

  renderUploadInventory() {
    return (
      <div className="vendor-upload-inventory">
        <div className="upload-inventory-notice-area">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please follow these steps carefully to ensure correct upload of your inventory:
        </div>

        <div className="upload-inventory-main-area">
          {this.renderSectionOne()}
          {this.renderSectionTwo()}
          {this.renderSectionThree()}
        </div>
      </div>
    )
  }

  loadingSpinner() {
    return (
      <i className="fa fa-cog fa-spin" aria-hidden="true"></i>
    )
  }

  renderDoneUploadingButton() {
    const { submitting } = this.state;
    return (
      <div className="done-uploading-button-area">
        {(!submitting) && <button className="done-uploading-button" onClick={this.onDoneUploading}>
          Confirm and Submit
        </button>}
        {(submitting) && <button className="done-uploading-button disabled">
          {this.loadingSpinner()}
        </button>}
      </div>
    )
  }

  onDoneUploading() {
    this.setState({
      submitting: true
    })
    const url = `/api/v1/inventory_groups/create_from_s3`
    const { file_paths } = this.state;
    if (file_paths.length > 0) {
      const params = {
        paths: file_paths
      }
      post(url, params).then(result => {
        GlobalActions.showMessage("You have successfully registered for the platform and your inventory is being processed!");
        window.location.href = "/"
      })
    }
  }

  handleFinishedUpload(result) {
    const new_path = result.path;
    const file_paths = this.state.file_paths.concat(new_path)
    this.setState({
      file_paths: file_paths,
      uploading: false,
    })
  }

  uploaderContent() {
    const { uploading } = this.state;
    return (
      <div className="uploader">
        {!uploading && <div className="uploader-inner-section">
          <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
          <i className="fa fa-file-excel-o" aria-hidden="true"></i>
          <i className="fa fa-file-image-o" aria-hidden="true"></i>
          <div className="strong">Drop files here</div>
          or <span className="underline">click to upload</span>
        </div>}
        {uploading && <div className="uploader-inner-section">
          uploading...
          {this.loadingSpinner()}
        </div>}
      </div>
    );
  }

  onDropzoneDrop() {
    this.setState({
      uploading: true,
    })
  }

  renderDropzone() {
    const time = (new Date()).getTime();
    const uploaderProps = {
      style: { cursor: 'pointer' },
      maxFileSize: 1024 * 1024 * 50,
      server: '',
      s3Url: `https://${AppConfig.s3Bucket}.s3.amazonaws.com/user_uploads/${time}`,
      signingUrlQueryParams: { time },
    };
    return (
      <DropzoneS3Uploader
        onDrop={this.onDropzoneDrop}
        onFinish={this.handleFinishedUpload}
        {...uploaderProps}>
        {this.uploaderContent()}
      </DropzoneS3Uploader>
    )
  }

  removeUploadedFile(file_path) {
    const file_paths = this.state.file_paths.filter(f_path =>
      f_path !== file_path
    )
    if (file_paths.length === 0) {
      this.setState({ file_paths, confirm: false })
    } else {
      this.setState({ file_paths })
    }

  }

  renderUploadedFilesSection() {
    const { file_paths, confirm } = this.state;
    return (
      <div className="uploaded-files-area">
        {!confirm && <div className="upload-inventory-subheader">
          <i className="fa fa-upload" aria-hidden="true"></i> Upload Inventory
        </div>}
        {file_paths.map((file_path, index) => (
          <div className="uploaded-file" key={index}>
            <i className="fa fa-trash" aria-hidden="true" onClick={e => this.removeUploadedFile(file_path)}></i>{file_path.includes("/undefined") ? file_path.split("/undefined").pop() : file_path.split("/").pop()}
          </div>
        ))}
        {confirm && <a className="upload-more-files" onClick={() => {this.setState({confirm: false})}}>Upload more files</a>}
      </div>
    )
  }

  renderConfirmationArea() {
    return (
      <div id="vendor-setup">
        <div className="vendor-setup">
          <div className="vendor-setup-header">
            <div className="header_text">
              Thank you for signing up for AdQuick!
            </div>
            <div className="header_description">
              We're hard at work finding you RFPs -- you'll receive an email from us once we find one that matches your great inventory.
            </div>
            <div className="header_description" style={{maxWidth: 500}}>
              In the meantime, don't hesitate to reach out to vendors@adquick.com with questions.
            </div>
            {this.renderProgressArea()}
          </div>
        </div>
        {this.renderUploadedFilesSection()}
        {this.renderDoneUploadingButton()}
      </div>
    )
  }

  renderNextButton() {
    const { file_paths } = this.state;
    return (
      <div className="next-button-area">
        {(file_paths && file_paths.length > 0) &&
          <div className="next-button next-button" onClick={() => {this.setState({confirm: true})}}>Next</div>
        }
      </div>
    )
  }

  renderProgressArea() {
    const { confirm } = this.state;

    if (confirm) {
      return (
        <div className="progress-area">
          <ProgressBar>
            <ProgressBar key={1} style={{backgroundColor: '#ffffff', color: '#000000'}} now={33} label={"1. Create Account"} />
            <ProgressBar key={2} style={{backgroundColor: '#ffffff', color: '#000000'}} now={33} label={"2. Upload Inventory"} />
            <ProgressBar key={3} style={{backgroundColor: '#00b30c'}} now={34} label={"3. Confirm & Submit"} />
          </ProgressBar>
        </div>
      )
    } else {
      return (
        <div className="progress-area">
          <ProgressBar>
            <ProgressBar key={1} style={{backgroundColor: '#ffffff', color: '#000000'}} now={33} label={"1. Create Account"} />
            <ProgressBar key={2} style={{backgroundColor: '#00b30c'}} now={33} label={"2. Upload Inventory"} />
            <ProgressBar key={3} style={{backgroundColor: '#ffffff', color: '#000000'}} now={34} label={"3. Confirm & Submit"} />
          </ProgressBar>
        </div>
      )
    }
  }

  render() {
    const { user, file_paths, confirm } = this.state;

    if (confirm) {
      return this.renderConfirmationArea();
    } else {
      return (
        <div>
          <div id="vendor-setup">
            <div className="vendor-setup-header">
              <div className="header_text">
                Media Owner Registration
              </div>
              <div className="header_description" style={{maxWidth: 550}}>
                Next, let's upload your inventory to the AdQuick platform.
              </div>
              {this.renderProgressArea()}
            </div>
            {this.renderUploadInventory()}
            {this.renderUploadedFilesSection()}
            {this.renderDropzone()}
            {this.renderNextButton()}
          </div>
        </div>
      )
    }
  }
}
