import Reflux from 'reflux';
import ExperimentActions from '../actions/GlobalActions';
import { get, post, put, del } from '../utils/api';

export default Reflux.createStore({

  listenables: [ExperimentActions],

  init() {
    this.state = AppConfig.experiments || {};
  },

  getState() {
    return this.state;
  },

  getExperiments() {
    return get("/api/v1/experiments")
      .then(result => {
        this.trigger('experiments:loaded', result)
        this.state = result
      })
      .catch(error => this.trigger('experiments:error', error));
  },

  renderCtaVariant(variant) {
    const cta = {
      get_started: 'Get Started',
      schedule_a_demo: 'Schedule a Demo',
      try_it_free: 'Try It Free',
      get_10_off: 'Get 10% Off'
    }
    return cta[variant] || 'Get a Demo'
  },

});
