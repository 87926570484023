import React from "react";
import CampaignUnitPopup from "../CampaignUnitPopup.jsx";

interface ViewUnitProps {
  unit: any;
  currentUser: any;
}

interface ViewUnitState {
  isModalOpen: boolean;
}

export default class ViewUnit extends React.Component<ViewUnitProps, ViewUnitState> {

  constructor(props) {
    super(props);
    this.state = { isModalOpen: false }
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(state => ({ isModalOpen: !state.isModalOpen }))
  }

  renderUnitModal() {
    const { unit, currentUser } = this.props;
    return (
      <CampaignUnitPopup
        unit={unit}
        _package={null}
        user={currentUser}
        onClose={this.toggleModal}
        isCampaignView
      />
    );
  }

  render() {
    return <>
      <a onClick={this.toggleModal}>View Unit</a>
      {this.state.isModalOpen && this.renderUnitModal()}
    </>
  }
}
