import { CampaignSupplierContract } from "../models/campaign_supplier_contract";
import { del, get, post } from "../utils/api";

const prefix = "campaign_supplier_contracts";
const CAMPAIGN_SUPPLIER_CONTRACTS_API_URL = "/api/v1/campaign_supplier_contracts";

export const LOAD_CAMPAIGN_SUPPLIER_CONTRACTS = `${prefix}/LOAD_CAMPAIGN_SUPPLIER_CONTRACTS`;
export const loadCampaignSupplierContracts = (campaign_token, page, filterByFavorite, filterByRecommended, filterByBooked) => async (dispatch, getState) => {
  const res = await get(CAMPAIGN_SUPPLIER_CONTRACTS_API_URL, null, { campaign_id: campaign_token, page, only_favorites: filterByFavorite, only_recommended: filterByRecommended, only_booked: filterByBooked });
  const campaign_supplier_contracts: CampaignSupplierContract[] = res.data.campaign_supplier_contracts;
  dispatch({ type: LOAD_CAMPAIGN_SUPPLIER_CONTRACTS, payload: { resetList: page == 1, campaign_supplier_contracts } });
};

export const CREATE_CAMPAIGN_SUPPLIER_CONTRACT = `${prefix}/CREATE_CAMPAIGN_SUPPLIER_CONTRACT`;
export const createCampaignSupplierContract = (campaign_token, supplier_id, contract, do_not_send = false, is_counter_signer, contract_template) => async (
  dispatch,
  getState,
) => {
  const { items, items_changes } = contract;
  if (!!!contract.id) {
    contract.items_attributes = applyItemChanges(items, items_changes);
  }
  const res = await post(CAMPAIGN_SUPPLIER_CONTRACTS_API_URL, {
    contract,
    supplier_id,
    campaign_id: campaign_token,
    do_not_send,
    is_counter_signer,
    contract_template,
  });
  const { campaign_supplier_contract } = res;
  dispatch({ type: CREATE_CAMPAIGN_SUPPLIER_CONTRACT, payload: { campaign_supplier_contract } });
};

export const RESET_CAMPAIGN_SUPPLIER_CONTRACT_PRICING = `${prefix}/RESET_CAMPAIGN_SUPPLIER_CONTRACT_PRICING`;
export const resetContractItemPricing = contract => async (dispatch, getState) => {
  const res = await post(`${CAMPAIGN_SUPPLIER_CONTRACTS_API_URL}/${contract.token}/reset_pricing`);
  const { campaign_supplier_contract } = res;
  dispatch({ type: RESET_CAMPAIGN_SUPPLIER_CONTRACT_PRICING, payload: { campaign_supplier_contract } });
};

export const CANCEL_CAMPAIGN_SUPPLIER_CONTRACT = `${prefix}/CANCEL_CAMPAIGN_SUPPLIER_CONTRACT`;
export const cancelCampaignSupplierContract = (campaign_token, contract_id) => async (dispatch, getState) => {
  const res = await post(`${CAMPAIGN_SUPPLIER_CONTRACTS_API_URL}/${contract_id}/cancel`, {
    campaign_id: campaign_token,
  });
  const { campaign_supplier_contract } = res;
  dispatch({ type: CANCEL_CAMPAIGN_SUPPLIER_CONTRACT, payload: { campaign_supplier_contract } });
};

export const DESTROY_CAMPAIGN_SUPPLIER_CONTRACT = `${prefix}/DESTROY_CAMPAIGN_SUPPLIER_CONTRACT`;
export const destroyCampaignSupplierContract = (campaign_token, contract_id) => async (dispatch, getState) => {
  const res = await del(`${CAMPAIGN_SUPPLIER_CONTRACTS_API_URL}/${contract_id}`, {
    campaign_id: campaign_token,
  });
  dispatch({ type: DESTROY_CAMPAIGN_SUPPLIER_CONTRACT, payload: { contract_id } });
};

export const DISCOUNT_CAMPAIGN_SUPPLIER_CONTRACT = `${prefix}/DISCOUNT_CAMPAIGN_SUPPLIER_CONTRACT`;
export const applyDiscount = (campaign_token, contract_id, supplier_id, data) => async (dispatch, getState) => {
  const { items, items_changes } = data;
  if (!!!contract_id) {
    data.items_attributes = applyItemChanges(items, items_changes);
  }
  const res = await post(`${CAMPAIGN_SUPPLIER_CONTRACTS_API_URL}/${contract_id}/apply_discount`, {
    campaign_id: campaign_token,
    supplier_id: supplier_id,
    contract: data,
  });
  const { campaign_supplier_contract } = res;
  dispatch({ type: DISCOUNT_CAMPAIGN_SUPPLIER_CONTRACT, payload: { campaign_supplier_contract } });
};

export const SEND_PRICE_PROPOSAL = `${prefix}/SEND_PRICE_PROPOSAL`;
export const sendPriceProposal = (campaign_token, contract_id, supplier_id, items, signer_ids) => async (
  dispatch,
  getState,
) => {
  const res = await post(`${CAMPAIGN_SUPPLIER_CONTRACTS_API_URL}/${contract_id}/send_price_proposal`, {
    campaign_id: campaign_token,
    supplier_id: supplier_id,
    contract: {
      id: contract_id,
      signer_ids,
    },
    items,
  });
  const { campaign_supplier_contract } = res;
  dispatch({ type: SEND_PRICE_PROPOSAL, payload: { campaign_supplier_contract } });
};

export const confirmPricing = contract_token => async (dispatch, getState) => {
  const res = await post(`${CAMPAIGN_SUPPLIER_CONTRACTS_API_URL}/${contract_token}/confirm_pricing`, {});
};

function applyItemChanges(originalList, itemChanges) {
  const changedTokens = Object.keys(itemChanges);
  const updatedItems = changedTokens.map(function(cuToken) {
    const item = originalList.find(function(item) {
      return item.token === cuToken;
    });
    const updatedItem = { ...item, ...itemChanges[cuToken] };
    return updatedItem;
  });
  const notChanged = originalList.filter(function(item) {
    return !changedTokens.includes(item.token);
  });
  const updatedList = notChanged.concat(updatedItems);
  return updatedList;
}
