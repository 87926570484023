import React, { Component } from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import { isMobile } from '../../utils/mobile';
import moment from 'moment';
import ToggleFilter from '../ToggleFilter';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE } from 'react-dates/lib/constants';
import { filterUnits } from '../../actions/UnitsActions';

const POPUP_WIDTH = 620;
const PRESETS = [
  {
    text: 'Any length',
    length: 0,
    period: 'days'
  },
  {
    text: '4 weeks',
    length: 4,
    period: 'weeks'
  },
  {
    text: '8 weeks',
    length: 8,
    period: 'weeks'
  },
  {
    text: '12 weeks',
    length: 12,
    period: 'weeks'
  },
  {
    text: '16 weeks',
    length: 16,
    period: 'weeks'
  }
];


class DateRangeFilter extends Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onDone = this.onDone.bind(this);
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
    this.setDatesFromCampaign = this.setDatesFromCampaign.bind(this);
    this.state = Object.assign({
      isExpanded: false,
      focusedInput: START_DATE,
      startDate: null,
      endDate: null,
      partial_date_match: false,
      include_holds: false,
      selectedPreset: 'Any length',
    }, this.props.initialValue);
  }

  setDatesFromCampaign() {
    const { campaign } = this.props;
    const startDate = (campaign && campaign.campaign && campaign.campaign.start_date) ? moment(campaign.campaign.start_date) : null;
    const endDate = (campaign && campaign.campaign && campaign.campaign.end_date) ? moment(campaign.campaign.end_date) : null;
    this.onDatesChange({startDate, endDate});
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
    const { campaign, isVendorMap } = this.props;
    if (campaign && isVendorMap) {
      this.setDatesFromCampaign();
    }
  }

  componentDidUpdate(prevProps) {
    const { campaign, isActive, units: { unit_filter } } = this.props;
    if (campaign && campaign.campaign && (prevProps.campaign.campaign.id !== campaign.campaign.id)) {
      this.setDatesFromCampaign();
    }
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (unit_filter.startDate !== prevProps.units.unit_filter.startDate) { this.setState({ startDate: unit_filter.startDate })}
    if (unit_filter.endDate !== prevProps.units.unit_filter.endDate) { this.setState({ endDate: unit_filter.endDate })}
    if (unit_filter.partial_date_match !== prevProps.units.unit_filter.partial_date_match) { this.setState({ partial_date_match: unit_filter.partial_date_match })}
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onDatesChange({startDate, endDate}) {  
    this.setState({ startDate, endDate }, this.onFilter);
  }

  onCheckboxSelect(name, checked) {
    this.setState({ [name]: checked }, this.onFilter);
  }

  searchWithinCampaign() {
    return !!_.get(this.props, 'campaign.campaignId', null) && !!!this.props.isVendorMap;
  }

  onFilter() {
    this.handleFilters()
  }

  handleFilters() {
    const { filterUnits, handleQueryParams } = this.props
    const { startDate, endDate, partial_date_match, include_holds } = this.state;

    const filter = {
      startDate: startDate ? moment(startDate).format() : null,
      endDate: endDate ? moment(endDate).format() : null,
      partial_date_match,
      include_holds,
      search_within_campaign: this.searchWithinCampaign()
    }

    filterUnits(filter)
    handleQueryParams(filter)
  }

  onFocusChange(focusedInput) {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  onClear() {
    this.setState({
      startDate: null,
      endDate: null,
      partial_date_match: false,
      include_holds: false,
      selectedPreset: 'Any length',
    }, this.onFilter)
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  onPresetSelected(selectedPreset) {
    const { text, length, period } = selectedPreset;
    if (text == 'Any length') {
      this.setState({ selectedPreset: text })
    } else {
      let { startDate } = this.state;
      startDate = startDate ? moment(startDate) : this.findNextMonday();
      const endDate = moment(startDate).add(length, period).subtract(1, 'day');
      this.setState({ selectedPreset: text }, this.onDatesChange({ startDate, endDate }));
    }
  }

  findNextMonday() {
    return moment().startOf('isoWeek').add(1, 'week')
  }

  getButtonLabel() {
    const { startDate, endDate} = this.state;
    if (!startDate || !endDate) return 'Date';
    return `${moment(startDate).format('MMM D')} - ${moment(endDate) .format('MMM D')}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderHeader() {
    const { selectedPreset } = this.state;

    return (
      <div className="date_range_poupup_header">
        <h4>How long?</h4>
        {PRESETS.map((preset, idx) => {
          return (
            <a className={cs({active: selectedPreset == preset.text})}
              key={idx}
              onClick={() => this.onPresetSelected(preset)}
            >
              {preset.text}
            </a>
          )
        })}
      </div>
    )
  }

  renderPopup() {
    const { startDate, endDate, focusedInput, partial_date_match, include_holds } = this.state;
    const { isVendorMap } = this.props;
    const isCampaignView = isVendorMap ? false : !!_.get(this.props, 'campaign.campaignId', null);

    return (
      <div className="filter_popup date_range_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        {this.renderHeader()}

        <DayPickerRangeController
          startDate={startDate ? moment(startDate) : null}
          endDate={endDate ? moment(endDate) : null}
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          numberOfMonths={isMobile() ? 1 : 2}
          hideKeyboardShortcutsPanel={true}
          noBorder={true}
          enableOutsideDays={false}
          //isOutsideRange={day => !isInclusivelyAfterDay(day, moment())}
        />
        <div className="padded_content">
          <div className="toggle-filters">
            <ToggleFilter
              filters={{ partial_date_match }}
              setFilter={this.onCheckboxSelect}
              attributeName="partial_date_match"
              toggleOffName="Full Availability"
              toggleOnName="All Availability"
            />
            {!isCampaignView &&
              <ToggleFilter
                filters={{ include_holds }}
                setFilter={this.onCheckboxSelect}
                attributeName="include_holds"
                toggleOffName="Exclude Held Units"
                toggleOnName="Include Held Units"
              />
            }
          </div>
          <div className="actions">
            <div>
              <a className="clear hidden-xs" onClick={(e) => this.onClear(true)}>Clear</a>
              <a onClick={this.onDone}>Done</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs({active: isActive || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
}

const mapStateToProps = ({ campaign, units }) => ({
  campaign, units,
});

export default connect(
  mapStateToProps,
  { filterUnits }
 )(DateRangeFilter)
