import { connect } from "react-redux";
import Fuse from "fuse.js";
import React, { Component } from "react";
import cs from "classnames";

import { availableSizesRaw } from "../../models/Units";
import { filterUnits } from "../../actions/UnitsActions";

const POPUP_WIDTH = 220;

class SizesFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.debouncedSearch = _.debounce(this.searchBySizeName, 200);
    this.state = {
      isExpanded: false,
      selectedSizes: this.props.initialValue || [],
      narrowedList: null,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { isActive, initialValue } = this.props
    if (!isActive && prevProps.isActive) { this.onClear(); }
    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ selectedSizes: initialValue })
    }
  }

  searchBySizeName(query) {
    const options = {
      keys: ["label"],
      minMatchCharLength: 2,
      threshold: 0.2,
      maxPatternLength: 20,
      tokenize: true,
      tokenSeparator: / +x +/g,
    };
    const fuse = new Fuse(availableSizesRaw(this.props.units), options);
    this.setState({ narrowedList: fuse.search(query) });
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false, narrowedList: null });
    }
  }

  onClear() {
    this.setState({ selectedSizes: [] }, this.onFilter);
  }

  onSizeSelect(option) {
    const { selectedSizes } = this.state;
    if (selectedSizes.includes(option)) {
      this.setState({ selectedSizes: selectedSizes.filter(size => size !== option) }, this.onFilter);
    } else {
      this.setState({ selectedSizes: selectedSizes.concat([option]) }, this.onFilter);
    }
  }

  onFilter() {
    const { selectedSizes } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    _.defer(filterUnits, { size: selectedSizes.length ? selectedSizes : null });
    handleQueryParams({ 'size[]': selectedSizes })
  }

  getButtonLabel() {
    const { selectedSizes } = this.state;
    if (!selectedSizes.length) return "Size";
    if (selectedSizes.length == 1) return `${selectedSizes[0]}`;
    if (selectedSizes.length > 1) return `Sizes · ${selectedSizes.length}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { selectedSizes, narrowedList } = this.state;
    const { units } = this.props;
    const grouped = Object.entries(_.countBy(availableSizesRaw(units), 'value'))
    const list = grouped.map(e => {
      return {
        value: e[0],
        label: e[0],
        count: e[1]
      }
    })

    const sizes = narrowedList && narrowedList.length ? narrowedList : list;
    return (
      <div className="filter_popup sizes_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>SIZE</h4>
        <input
          autoFocus={true}
          type="text"
          className="narrow_list"
          onChange={e => this.debouncedSearch(e.target.value)}
          placeholder="Filter by size"
        />
        <ul>
          {sizes.map(size => {
            const onClick = () => {
              this.onSizeSelect(size.value);
            };
            const isActive = selectedSizes.includes(size.value);
            return (
              <li key={size.value} onClick={onClick} className={cs({ active: isActive })}>
                {size.label}
                <i className="count">({size.count})</i>
                <i className="fa fa-check" />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

const mapStateToProps = ({ units }) => ({
  units,
});

export default connect(mapStateToProps, { filterUnits })(SizesFilter);
