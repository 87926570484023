import * as React from 'react';

class DirectAttributionModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { onSubmit } = this.props
    return (
      <div className="modal_default">
        <div className="modal_header">
          <div className="direct_attribution"></div>
          <h5>Adquick Direct Attribution <b>Setup Instructions</b></h5>
        </div>
        <div className="modal_body">
          <div className="field">
            <label>Step 1</label>
            <textarea className="form-control"/>
          </div>
          <div className="field">
            <label>Step 2</label>
            <textarea className="form-control"/>
          </div>
          <div className="modal_footer">
            <button className="primary-button" onClick={onSubmit}> Complete Setup</button>
          </div>
        </div>
      </div>
    )
  }
}

export default DirectAttributionModal;