import React from 'react';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore'

export default createClass({
  displayName: 'Campaign/SendCampaignLink',

  getInitialState() {
    return {
      saving: false,
      errors: '',
      email: '',
      notice: '',
      subject: '',
      from: '',
      emailType: 'normal_campaign_link',
      user: null,
      message: this.emailMessage(),
    };
  },

  componentDidMount() {
    const user = AuthStore.state.user;
    this.setState({
      subject: this.emailSubject(this.state.emailType),
      from: `${user.name} <${user.email}>`,
    })
  },

  onSubmit(e) {
    e.preventDefault();
    this.setState({ errors: '' })
    const { subject, email, message, emailType, from } = this.state;
    if (!subject || !message || !email || !from) {
      this.setState({ errors: "All fields are required" });
      return;
    }
    if (message.match(/NAME/)) {
      this.setState({ errors: "Whoops, don't forget to fill in NAME." });
      return;
    }
    if (message.match(/AREA/)) {
      this.setState({ errors: "Whoops, don't forget to fill in AREA." });
      return;
    }

    const params = {
      campaign_id: this.props.data.campaign.token,
      share: {
        recipient_email: email,
        message,
        subject,
        email_type: emailType,
        from,
      },
    };

    this.setState({ saving: true });

    $.post('/api/v1/campaign_links', params).then((response) => {
      this.setState({
        saving: false,
        notice: `We emailed the campaign to ${response.emails}.`
      });
    }).catch((error) => {
      this.setState({ errors: error.message, saving: false });
      if (DEBUG) console.log(error);
    });
  },

  handleEmailTypeChange(e) {
    const emailType = e.target.value;
    this.setState({
      emailType: emailType,
      subject: this.emailSubject(emailType),
      message: this.emailMessage(emailType)
    })
  },

  emailSubject(type='normal_campaign_link') {
    let subject;
    const campaign = this.props.data.campaign;
    if (type === 'normal_campaign_link') {
      subject = `AdQuick <> ${campaign.advertiser} | Your proposal is ready to view!`
    } else if (type === 'small_campaign_rfp') {
      subject = `AdQuick <> ${campaign.advertiser} | Here's your campaign link`
    } else if (type === 'small_campaign_mock') {
      subject = `AdQuick <> ${campaign.advertiser} | Here's your campaign link`
    }
    return subject;
  },

  emailMessage(type='normal_campaign_link') {
    let message;
    if (type === 'normal_campaign_link') {
      message = `Hey NAME,

Here is a link to review your Out of Home options in AREA:

{share_link "${this.props.data.campaign.name}"}

You can call me directly at 954-557-8763 if you'd like to discuss further details, or <a href="https://calendly.com/jay-47">schedule time on my calendar</a> if that's easier. Thanks!

--
Jay Howard
Head of Sales
954-557-8763
`
    } else if (type === 'small_campaign_rfp') {
        message = `Hey NAME,

Thanks for filling out a campaign request with AdQuick. We are currently reaching out to vendors to get outdoor advertising options for your campaign in AREA at the guaranteed lowest price.

When we get back units, you’ll receive an email, and the units will appear on your campaign here: {share_link "${this.props.data.campaign.name}"}

You can call me directly at 301-641-5501 if you’d like to discuss further details, or <a href=“https://calendly.com/connor-47“>schedule time on my calendar</a> if that’s easier. Thanks!

--
Connor Burden
AdQuick.com
301-641-5501
`
    } else if (type === 'small_campaign_mock') {
      message = `Hey NAME,

I’ve created a mock campaign for you to review. It doesn't include availability, but you can view some of the options we have in AREA.

This will allow you to get a sense for the market and compare options based on price, impressions, location, etc.

View your campaign here: {share_link "${this.props.data.campaign.name}"}

You can call me directly at 301-641-5501 if you’d like to discuss further details, or <a href=“https://calendly.com/connor-47“>schedule time on my calendar</a> if that’s easier. Thanks!

--
Connor Burden
AdQuick.com
301-641-5501
`
    }
    return message;
  },

  render() {
    const { saving } = this.state;
    return (
      <div>
        <h2>Send Campaign Link</h2>
        {saving && <div className="searchTab__loading" />}
        {!saving && <form onSubmit={this.onSubmit}>
          {this.state.errors && <div className="message message_error">{this.state.errors}</div>}
          {this.state.notice && <div className="alert alert-success">{this.state.notice}</div>}

          <div style={{ marginBottom: 10 }}>
            <label>Email Type</label>
            <select value={this.state.emailType} className="form-control" onChange={this.handleEmailTypeChange}>
              <option value="normal_campaign_link">normal_campaign_link</option>
              <option value="small_campaign_rfp">small_campaign_rfp</option>
              <option value="small_campaign_mock">small_campaign_mock</option>
            </select>
          </div>

          <div style={{ marginBottom: 10 }}>
            <label>From</label>
            <input value={this.state.from} className="form-control" type="input" placeholder="Email" onChange={(e) => { this.setState({ from: e.target.value }); }} style={(this.state.errors && this.state.errors.match(/email/) ? { borderColor: 'red' } : {})} />
          </div>

          <div style={{ marginBottom: 10 }}>
            <label>To Emails (separate multiple with commas)</label>
            <input value={this.state.email} className="form-control" type="input" placeholder="Email" onChange={(e) => { this.setState({ email: e.target.value }); }} style={(this.state.errors && this.state.errors.match(/email/) ? { borderColor: 'red' } : {})} />
          </div>

          <div style={{ marginBottom: 10 }}>
            <label>Subject</label>
            <input value={this.state.subject} className="form-control" type="input" placeholder="Subject" onChange={(e) => { this.setState({ subject: e.target.value }); }} style={(this.state.errors && this.state.errors.match(/subject/) ? { borderColor: 'red' } : {})} />
          </div>

          <div style={{ marginBottom: 10 }}>
            <label>Message</label>
            <textarea value={this.state.message} style={{ height: 500 }} className="form-control" type="input" placeholder="Write a message" onChange={(e) => { this.setState({ message: e.target.value }); }} />
          </div>
          <div style={{ marginBottom: 0 }}>
            <button className="btn btn-primary" type="submit">Send Email(s)</button>
          </div>
        </form>}
      </div>
    )
  }

});
