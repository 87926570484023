import React from 'react';
import createClass from 'create-react-class';
import DropzoneS3Uploader from '../DropzoneS3Uploader.jsx'
import UploadStore from '../../stores/UploadStore';
import PlacesStore from '../../stores/PlacesStore';
import JobProgress from '../../utils/JobProgress';
import { Line } from 'rc-progress';

export default createClass({
  displayName: 'ImportPlaceMarkersModal',

  getInitialState() {
    return {
      progress: 0,
      job_pooling: new JobProgress(),
      import_result: {}
    };
  },

  componentDidMount() {
    this.unsubscribeList = [
      UploadStore.listen(this.onUpload)
    ]
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  render() {
    return (
      <div>
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.hide}><span aria-hidden="true">&times;</span></button>
          <h4 className="modal-title">Import POIs</h4>
        </div>
        <div className="modal-body">
          <p>You can upload place markers to the map by importing a CSV file with the following headers:</p>
          <p>name, address, city, state, zip (<a href="https://s3.amazonaws.com/adquick-production/examples/place_marker_import.csv" target="_blank">Example template</a>)</p>
          <p>Or</p>
          <p>name, latitude, longitude (<a href="https://adquick-production.s3.amazonaws.com/examples/placemarker_import_example_latlng.csv" target="_blank">Example template</a>)</p>
          <div className={this.state.import_state == "started" ? "loading_blank" : ""}>
            {this.uploader()}
          </div>
          {this.success()}
          {this.failures()}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.props.hide}>Done</button>
        </div>
      </div>
    );
  },

  uploader() {
    const time = (new Date()).getTime();
    const uploaderProps = {
      style: { cursor: 'pointer' },
      maxFileSize: 1024 * 1024 * 50,
      server: '',
      s3Url: `https://${AppConfig.s3Bucket}.s3.amazonaws.com/user_uploads/${time}`,
      signingUrlQueryParams: { time },
    };

    return (
      <DropzoneS3Uploader
        onDrop={this.onDrop}
        onProgress={this.onProgress}
        onFinish={this.handleFinishedUpload}
        accept=".csv,.xls"
        {...uploaderProps}>
        {this.uploaderContent()}
      </DropzoneS3Uploader>
    );
  },

  uploaderContent() {
    if (this.state.progress) {
      return <Line percent={this.state.progress} strokeWidth="4" strokeColor="#44ac6b" />;
    } else {
      return (
        <div style={{ padding: 20, marginBottom: 10, border: '1px dotted gray', background: '#eee' }}>
          <div className="btn btn-primary">Upload CSV, XLSX, or XLS file</div>
          <span style={{ marginLeft: 10 }}>
            You can also drag and drop into this box.
          </span>
        </div>
      );
    }
  },

  success() {
    if (this.state.import_state != "completed") {
      return;
    }

    const count = this.state.import_result.imported || 0;
    if (count > 0) {
      return (
        <div className="alert alert-success" role="alert">
          <p><strong>{ count } POIs</strong> successfully added!</p>
        </div>
      );
    }
  },

  failures() {
    if (this.state.import_state === "failed") {
      const errorMessage = this.state.error
        ? this.state.error.includes('utf-8')
          ? "Encoding error: to resolve, paste values into a new excel sheet, export new CSV file, and re-upload."
          : this.state.error
        : "Plese check the file format";
      return (
        <div className="alert alert-danger" role="alert">
          <p>POIs import <strong>failed</strong>. {errorMessage}</p>
        </div>
      );
    } else if (this.state.import_state === "completed") {
      const failures = this.state.import_result.failed;

      if (failures && failures.length > 0) {
        return (
          <div>
              <p>
                We were <strong>unable to import</strong> the following { failures.length > 1 ? "rows" : "row" }:
              </p>
            { failures.map((row, i) => { return this.failure(i, row) }) }
          </div>
        );
      }
    } else {
      return;
    }
  },

  failure(i, row) {
    if (!row.errors) {
      return
    }
    return (
    <div className="alert alert-danger" role="alert" key={i}>
      <p>
        <strong>Errors</strong>: {row.errors.join(", ")}
      </p>
      <p>
        <strong>Row</strong>: {row.name}, { row.address }, {row.city}, { row.state }, { row.zip }
      </p>
    </div>)
  },

  onProgress(progress) {
    this.setState({ progress });
  },

  onDrop() {
    this.setState({ progress: 0, import_state: "waiting" });
  },

  handleFinishedUpload(upload) {
    const file = upload.file;
    this.setState({ progress: null });
    this.setState({ import_state: "started" });
    UploadStore.uploadPlaceMarkFile(upload, file, this.props.campaign_id);
  },

  onUpload(event_name, data) {
    const { job_pooling } = this.state
    if (!data.placemark_upload) {
      return
    }

    if (event_name == 'upload:uploaded') {
      this.setState(
        { status: 'importing', job_id: data.job_id },
        () => job_pooling.startPolling(data.job_id, this.onJobComplete)
      )
    }
    else if (event_name == 'upload:error') {
      console.error("error importing placemarkers", data);
      const error = data.error && data.error.length > 0 ? data.error : null;
      this.setState({ import_state: 'failed', error: error });
    }
  },

  onJobComplete(response) {
    const { completed_tasks, failed_tasks, total_tasks, data } = response
    if (completed_tasks == total_tasks) {
      this.setState({ import_state: 'completed', import_result: data })
      PlacesStore.onImportPlaces();
    }
    if (failed_tasks == total_tasks) {
      this.setState({ import_state: 'failed', import_result: data, error: _.capitalize(response.data.error) })
    }
  }

})
