import React, { Component } from 'react';
import moment from "moment";
import cs from 'classnames';

class Comment extends Component {

  render() {
    const { createdAt, authorName, authorId, body, currentUser, onDeleteComment, commentId } = this.props;
    const byMe = currentUser && currentUser.id === authorId;
    return (
      <div className={cs('comment', { by_me: byMe })}>
        {byMe &&
          <i className="delete" onClick={() => onDeleteComment(commentId)}>×</i>
        }
        <div className="header">
          <div className="author">{authorName}</div>
          <div className="time_ago">{moment(createdAt).fromNow()}</div>
        </div>
        <div className="body">
          {body}
        </div>
      </div>
    )
  }

}

export default Comment;
