import React, { Component } from "react";
import ChartJs from "chart.js";
import uniqueId from "lodash/uniqueId";
import moment from "moment";

const DEFAULT_HEIGHT = 400;

const DEFAULT_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
};

export interface IAnalyticsTick {
  x: string;
  y: number;
}

export interface IChartProps {
  type: "Line" | "Bar" | "Area";
  options?: (data: object) => object;
  data: any;
  highlight?: string;
}

class Chart extends Component<IChartProps> {

  private id: string = uniqueId(`${this.props.type.toLowerCase()}_chart_`);
  private chart: any;
  private context2D: any;
  private options: object;

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    this.options = {
      ...DEFAULT_OPTIONS,
      ...(this.props.options ? this.props.options(this.props.data) : {}),
    };

    this.context2D = (document as any).getElementById(this.id).getContext("2d");
    this.renderChart();

    if (this.props.highlight) {
      // creating highlight area on chart
      // TODO: make getControllerWithHighlight work with any type of chart, for now works just for "line"
      this.drawHighlight(this.props.highlight);
    }
  }

  public render() {
    return <div style={{ height: `${DEFAULT_HEIGHT}px` }}>
      <canvas height="100%" id={this.id}></canvas>
    </div>;
  }

  private renderChart() {
    this.chart = new ChartJs(this.context2D, {
      type: this.props.type.toLowerCase(),
      data: this.props.data,
      options: this.options,
    });
  }

  private drawHighlight(id) {
    // TODO: draw a blue rectangle on "during" data
  }
}

// Helpers

export const createDataSet = (label: string, data: IAnalyticsTick[], color?: string) => ({
  label,
  data,
  fill: false,
  lineTension: 0,
  borderColor: color,
  borderWidth: 2,
});

export const formatDate = (date): string => moment(date).format("L");

export const buildData = (chartConfig) => {
  const newConfig = { ...chartConfig };
  const firstSet = chartConfig.datasets[0];
  newConfig.labels = firstSet.data.map((tick: IAnalyticsTick) => formatDate(tick.x));
  return newConfig;
};

export default Chart;
