import React, { Component } from 'react';
import _ from "lodash";
import * as MediaTypes from '../../../models/media_types';
import * as DesignAssets from '../../../models/design_assets';
import UnitsGroup from './UnitsGroup';

interface MarketProps {
  key: string
  status: string
  market: string
  units: any
  campaign: any
  campaign_permissions: any
  onUnitSelect: (unit, isSelected) => void
  onUnitBulkSelect: (selected_units) => void
}
interface MarketState {
  expanded: boolean
}
class Market extends Component<MarketProps, MarketState> {

  constructor(props){
    super(props);
    this.toggleExpandMarket = this.toggleExpandMarket.bind(this);
    this.state = {
      expanded: true
    }
  }

  public render(){
    const { market, units } = this.props;
    const { expanded } = this.state;
    return(
      <div className="campaign_designs_group">
        <div className="row">
          <div className="col-md-12 group_header" style={{marginTop: '24px'}}>
            <h4 style={{ cursor: "default"}}>
              { expanded ? <i className="fa fa-caret-down margin-right group_arrow"/> : <i className="fa fa-caret-right margin-right group_arrow"/> }
              <span style={{ cursor: "pointer"}} onClick={this.toggleExpandMarket}>{market}</span>
              <span className="count">
                ({units.length})
              </span>
            </h4>
          </div>
        </div>
        <div style={{ display: expanded ? "block" : "none" }}>
          { this.renderGroups() }
        </div>
      </div>
    )
  }

  private toggleExpandMarket() {
    this.setState( (state) => ({ expanded: !state.expanded }) );
  }

  private renderGroups() {
      const { campaign, campaign_permissions, units, status } = this.props;
      const units_by_unit_type = DesignAssets.groupByUnitType(units);

      const groups = Object.keys(units_by_unit_type).map((unit_type, index) => {
        const units_in_unit_type = units_by_unit_type[unit_type];
        const units_by_size = DesignAssets.groupBySize(units_in_unit_type);

        return Object.keys(units_by_size).map((size, index) => {
          const units_in_size = units_by_size[size];
          const title = `${MediaTypes.prettyLabel(unit_type)} - ${size}`;
          return (
            <UnitsGroup
              key={title}
              status={status}
              title={title}
              units={units_in_size}
              campaign={campaign}
              campaign_permissions={campaign_permissions}
              onUnitSelect={this.props.onUnitSelect}
              onUnitBulkSelect={this.props.onUnitBulkSelect}
            />
          );
        });
      });

      return _.flatten(groups);
  }
}

export default Market
