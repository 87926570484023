import * as React from "react";
import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { post } from "../../../utils/api";
import { connect } from "react-redux";
import cs from "classnames";
import UploadButton from "../../UploadButton";

import GlobalActions from "../../../actions/GlobalActions";
import { fetchSupplierTasks } from "../CampaignRequests/actions";

class BulkActionModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      supplier_message: null,
      subject: null,
      email_thread: false,
      error_message: "Supplier message is required for this action.",
      missing_message: false,
      submitting: false,
      dragging: false,
      uploading: false,
      completed: false,
      filename: null,
      path: null,
      error: null,
      progress: 0,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
    this.saveSupplierMessage = this.saveSupplierMessage.bind(this);
    this.saveSubject = this.saveSubject.bind(this);
    this.saveEmailThreadSetting = this.saveEmailThreadSetting.bind(this);
    this.canSubmitWithoutMessage = this.canSubmitWithoutMessage.bind(this);
    this.requireMessageToSubmit = this.requireMessageToSubmit.bind(this);
    // attachment methods
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onProgress = this.onProgress.bind(this);
  }

  requestBulkActionsPath() {
    const { campaign_token } = this.props;
    return `/api/v1/campaigns/${campaign_token}/request_bulk_actions`;
  }

  requestBulkActionsPayload() {
    const { supplier_tasks } = this.props;
    const { supplier_message: message, subject, email_thread, path } = this.state;
    const supplier_task_ids = supplier_tasks.map(task => task.id);
    return {
      supplier_task_ids,
      message,
      subject,
      email_thread,
      path,
    }
  }

  saveSupplierMessage(e) {
    const value = e.currentTarget.value;
    this.setState({ supplier_message: value, missing_message: false });
  }

  saveSubject(e) {
    const value = e.currentTarget.value;
    this.setState({ subject: value });
  }

  saveEmailThreadSetting(e) {
    const value = e.currentTarget.checked;
    this.setState({ email_thread: value });
  }

  async submit() {
    const { fetchSupplierTasks, campaign_token, action } = this.props;
    const actions = {
      remind: this.canSubmitWithoutMessage,
      reopen: this.canSubmitWithoutMessage,
      cancel: this.canSubmitWithoutMessage,
      decline: this.requireMessageToSubmit,
      contact: this.requireMessageToSubmit
    }
    try {
      await actions[action]();
      await fetchSupplierTasks(campaign_token);
      this.setState({ submitting: false, filename: null, path: null, dragging: false, completed: false, progress: 0 }, this.onHide);
      GlobalActions.showMessage("Vendor has been notified.");
    } catch (error) {
      this.setState({ submitting: false });
      GlobalActions.showError(error);
    }
  }

  onSubmit() {
    this.setState({
      submitting: true,
      missing_message: false
    }, () => this.submit());
  }

  onHide() {
    const { onHide } = this.props;
    this.setState({
      supplier_message: null,
      missing_message: false,
      subject: null,
      email_thread: false,
    }, onHide);
  }

  renderHeader() {
    const { action } = this.props;
    const headers = {
      remind: "Send Reminders",
      reopen: "Reopen Selected Tasks",
      cancel: "Cancel Selected Tasks",
      decline: "Decline Selected Tasks",
      contact: "Contact Vendors"
    }
    return headers[action];
  }

  render() {
    const { show, action } = this.props;
    const { missing_message, error_message } = this.state;
    const showCustomMessageField = action !== 'remind';
    return (
      <Modal show={show} onHide={this.onHide} centered="true">
        <Modal.Body>
          <div id="decline_task_modal">
            <div className="decline_header">
              {this.renderHeader()}
            </div>
            {showCustomMessageField &&
              <div style={{marginTop: 20}}>
                {action === 'contact' &&
                  <React.Fragment>
                    <label>Subject for email <span> – Optional</span></label>
                    <input type="text"
                      className="form-control"
                      onChange={this.saveSubject}
                    />
                    <br/>
                  </React.Fragment>
                }
                <div className={cs("form-group", { 'has-error': missing_message })}>
                  <label>Custom message to supplier:</label>
                  <textarea
                    className="form-control"
                    onChange={this.saveSupplierMessage}
                  />
                  <br/>
                </div>
                {action === 'contact' &&
                  <React.Fragment>
                    <UploadButton
                      onComplete={this.onUploadComplete}
                      onDrop={this.onDrop}
                      isPublic={false}
                      onProgress={this.onProgress}
                      skipProgress={true}
                    >
                      {this.renderUploadCard()}
                    </UploadButton>
                    <br/>
                    <label>
                      <input type="checkbox" onChange={this.saveEmailThreadSetting} style={{marginRight: "8px"}} />
                      Start email thread with vendor
                      <span style={{display: "inline-block", marginLeft: "4px"}}>(Adds you to CC list)</span>
                    </label>
                  </React.Fragment>
                }
                {missing_message &&
                  <span className="help-block">{error_message}</span>
                }
              </div>
            }
            <div className="submit_button_area row">
              <Button
                disabled={!this.readyToSubmit()}
                className="submit_button"
                onClick={this.onSubmit}
              >
                {this.renderButtonText()}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  async canSubmitWithoutMessage() {
    const { action } = this.props;
    const path = `${this.requestBulkActionsPath()}/${action}`;
    await post(path, this.requestBulkActionsPayload());
  }

  async requireMessageToSubmit() {
    if(!this.state.supplier_message) { return this.handleMessageRequiredness() }
    const { action } = this.props;
    const path = `${this.requestBulkActionsPath()}/${action}`;
    await post(path, this.requestBulkActionsPayload());
  }

  handleMessageRequiredness() {
    const errorMessage = this.state.error_message;
    this.setState({ missing_message: true });
    throw errorMessage;
  }

  readyToSubmit() {
    const { submitting, uploading } = this.state;
    return (!uploading && !submitting)
  }

  renderButtonText() {
    const { uploading } = this.state;
    if(uploading) { return "Uploading..."; }
    return "Submit";
  }

  onClose() {
    GlobalActions.closePopup();
  }

  renderUploadCard() {
    const { dragging } = this.state;
    return (
      <div className="upload_image_card">
        <div className="title">
          <label>Add Attachments <span>– Optional</span></label>
          <div className="subtitle">
            Drop your files here, or browse your computer.
          </div>
        </div>
        <div
          className={cs("dashed_container", {"dragging": dragging})}
          style={{height:"220px"}}
          onDragOver={this.onDragOver.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          onDrop={this.onDragLeave.bind(this)}
        >
          {this.renderDropFile()}
          {this.renderUploadProgress()}
        </div>
      </div>
    )
  }

  renderDropFile() {
    const { uploading, completed } = this.state;
    if (uploading) { return; }
    if (completed) { return this.renderCompletedState(); }
    return (
      <div className="center">
        <div className="drag_icon"></div>
        <h6 style={{color:"#858585"}}>Drag and drop here or browse</h6>
        <div className="description">
          Supports: PDF, Doc, PPT, PPTX, XLS, XLSX
        </div>
      </div>
    )
  }

  renderCompletedState() {
    const { filename } = this.state;
    return (
      <div className="center">
        <div
          className="file_icon"
          style={{
            textAlign:"center",
            fontSize:"32px",
            color:"#858585"
          }}
        >
          <i className="fa fa-file-o"></i>
        </div>
        <h6 style={{color:"#858585"}}>{filename}</h6>
      </div>
    );
  }

  renderUploadProgress() {
    const { progress, uploading } = this.state;
    if(!uploading) { return; }
    return (
      <div
        className="upload_progress"
        style={{
          width:"95%",
          border:0
        }}
      >
        <div
          className="progression"
          style={{
            width: `${progress}%`,
            backgroundColor:"unset"
          }}
        >
          <div className="upload_details">
            <span className="status">
              {this.renderUploadStatus()}
            </span>
            <span className="download_progress">
              {`${progress}%`}
            </span>
          </div>
          <div className="line"></div>
        </div>
      </div>
    )
  }

  renderUploadStatus() {
    const { completed } = this.state;
    if(completed) { return "Completed"; }
    return "Uploading";
  }

  onUploadComplete(upload, file) {
    return this.setState({
      filename: file.name,
      path: upload.path,
      completed: true,
      uploading: false
    });
  }

  onDrop() {
    this.setState({ uploading: true, progress: 0 })
  }

  onProgress(progress) {
    this.setState({ progress })
  }

  onDragOver(e) {
    this.setState({ dragging: true });
  }

  onDragLeave(e) {
    this.setState({ dragging: false });
  }

  renderError() {
    const { error } = this.state;
    if(!error) { return; }
    return (
      <div className="alert alert-danger">
        <i
          className="fa fa-exclamation-circle"
          style={{fontSize:"18px",marginRight:"10px"}}
        ></i>
        {error}
      </div>
    )
  }
}

export default connect(
  () => {
    return {};
  },
  {
    fetchSupplierTasks
  },
)(BulkActionModal);
