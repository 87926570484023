import * as React from 'react';
import { PureComponent } from 'react';
import { Pagination, DropdownButton, MenuItem, Modal } from 'react-bootstrap'
import { connect } from 'react-redux';
import { browserHistory } from 'react-router-legacy';
import ReactSelect from 'react-select';
import _ from 'lodash';
import moment from 'moment';
import Edit from './Edit';

import {
  hideEditAdvertiserModal,
  showEditAdvertiserModal,
  fetchAdvertiserCampaigns,
  confirmDeleteAdvertiser,
  cancelConfirmDeleteAdvertiser,
  deleteAdvertiser,
  setCampaignsFilter,
  confirmDeleteCampaign,
  cancelConfirmDeleteCampaign,
  deleteCampaign
} from './actions';

class Details extends PureComponent<any, any> {

  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onCreateCampaign = this.onCreateCampaign.bind(this);
    this.onEditAdvertiser = this.onEditAdvertiser.bind(this);
    this.onClearCampaignQuery = this.onClearCampaignQuery.bind(this);
    this.confirmDeleteAdvertiser = this.confirmDeleteAdvertiser.bind(this);
    this.debouncedUpdateFilters = _.debounce(this.debouncedUpdateFilters.bind(this), 500);
  }

  fetchCampaigns() {
    const { advertiser, fetchAdvertiserCampaigns, campaigns_per_page, campaigns_page, campaigns_query } = this.props;
    const campaigns_sort_by = this.props.campaigns_sort_by || {};
    fetchAdvertiserCampaigns(
      advertiser,
      {
        campaigns_per_page,
        campaigns_page,
        campaigns_query,
        campaigns_sort_by: campaigns_sort_by.value
      }
    );
  }

  componentWillMount() {
    this.fetchCampaigns();
  }

  componentDidUpdate() {
    const { campaign_filters_changed } = this.props;
    if (campaign_filters_changed) {
      this.fetchCampaigns();
    }
  }

  debouncedUpdateFilters() {
    const { campaigns_per_page, campaigns_sort_by, campaigns_query, campaigns_page, setCampaignsFilter } = this.props;
    setCampaignsFilter({
      campaign_filters_changed: true,
      campaigns_query,
      campaigns_per_page,
      campaigns_page,
      campaigns_sort_by
    });
  }

  onFilterChange(e) {
    const { campaigns_per_page, campaign_filters_changed, campaigns_sort_by, setCampaignsFilter } = this.props;
    setCampaignsFilter({
      campaigns_query: e.target.value,
      campaigns_page: 1,
      campaign_filters_changed,
      campaigns_per_page,
      campaigns_sort_by
    });
    this.debouncedUpdateFilters();
  }

  renderSearch(){
    const { campaigns_query } = this.props;
    const clear_hidden = (!!!campaigns_query || campaigns_query === '') ? 'hide' : '';

    return (
      <div style={{display: 'flex'}}>
        <div className="search-bar-area" style={{ minWidth: '100%' }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search campaigns"
            value={campaigns_query}
            onChange={this.onFilterChange}
          />
        </div>
        <div
          className={`clear-search-bar noselect ${clear_hidden ? 'hide' : ''}`}
          onClick={this.onClearCampaignQuery}
        >
          clear
        </div>
      </div>
    )
  }

  onClearCampaignQuery() {
    const { setCampaignsFilter, campaigns_per_page, campaigns_sort_by } = this.props;
    setCampaignsFilter({
      campaigns_page: 1,
      campaigns_query: '',
      campaigns_per_page,
      campaigns_sort_by
    });
    this.debouncedUpdateFilters();
  }

  renderPagination() {
    const per_page_options = [10, 25, 50, 100];
    const { campaigns_total_pages, campaigns_per_page, campaigns_page, campaigns_query, campaigns_sort_by, setCampaignsFilter } = this.props;

    return (
      <div className="pagination-area">
        <div className="col-sm-2">
          <div className="pagination">
            <DropdownButton title={campaigns_per_page || "10"} id="bg-nested-dropdown">
              {per_page_options.map((size_per_page) =>
                <MenuItem
                  key={`per-page-${size_per_page}`}
                  onClick={() => setCampaignsFilter({campaigns_per_page: size_per_page, campaigns_page: 1, campaign_filters_changed: true, campaigns_query, campaigns_sort_by})}
                >{size_per_page}</MenuItem>
              )}
            </DropdownButton>
          </div>
        </div>
        <div className="col-sm-10">
          <Pagination
            bsSize="small"
            prev
            next
            last
            ellipsis
            boundaryLinks
            items={campaigns_total_pages}
            maxButtons={5}
            activePage={parseInt(campaigns_page) || 1}
            onSelect={(page) => { setCampaignsFilter({campaigns_page: page, campaigns_per_page, campaign_filters_changed: true, campaigns_query, campaigns_sort_by}); }} />
        </div>
      </div>
    )
  }

  onRowClick(campaign) {
    window.location.href = `/campaigns/${campaign.token}`;
  }

  onCreateCampaign() {
    const { currentUser } = this.props;
    currentUser && currentUser.is_managed_supplier ? browserHistory.push('/campaign_builder') : window.location.href = '/campaigns/new';
  }

  renderSortingDropdown() {
    const { campaigns_page, campaigns_per_page, campaigns_query, campaigns_sort_by, setCampaignsFilter } = this.props;
    const sort_by_options = [
      {label: 'Sort By Name ↑', value: 'name-asc'},
      {label: 'Sort By Name ↓', value: 'name-desc'},
      {label: 'Sort By Created Date ↑', value: 'created_at-asc'},
      {label: 'Sort By Created Date ↓', value: 'created_at-desc'},
      {label: 'Sort By Last Updated ↑', value: 'updated_at-asc'},
      {label: 'Sort By Last Updated ↓', value: 'updated_at-desc'},
      {label: 'Sort By Start Date ↑', value: 'start_date-asc'},
      {label: 'Sort By Start Date ↓', value: 'start_date-desc'},
      {label: 'Sort By End Date ↑', value: 'end_date-asc'},
      {label: 'Sort By End Date ↓', value: 'end_date-desc'}
    ]

    return (
      <div className="sorting-dropdown">
        <ReactSelect
          className="selectInput"
          placeholder="Sort By"
          value={campaigns_sort_by}
          options={sort_by_options}
          onChange={(sort_option) => setCampaignsFilter({campaigns_sort_by: sort_option, campaigns_query, campaigns_per_page, campaigns_page, campaign_filters_changed: true })}
        />
      </div>
    )
  }

  onDeleteCampaign(e, row) {
    const  { confirmDeleteCampaign } = this.props;
    e.stopPropagation();
    e.preventDefault();
    confirmDeleteCampaign(row);
  }

  renderDeleteCampaignModal() {
    const { show_delete_campaign_confirmation, deleting_campaign, campaign_for_deletion, cancelConfirmDeleteCampaign, deleteCampaign } = this.props;
    return (
      <Modal
        show={show_delete_campaign_confirmation}
        onHide={() => cancelConfirmDeleteCampaign()}
      >
        <Modal.Header>
          <h4>Are you sure you want to delete campaign {campaign_for_deletion.name}?</h4>
        </Modal.Header>
        <Modal.Body>
          <p>Note that deleting this campaign will also delete all flights associated with this campaign</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" type="button" disabled={deleting_campaign} onClick={() => deleteCampaign(campaign_for_deletion)}>Delete</button>
          <a onClick={() => cancelConfirmDeleteCampaign()} className="btn btn-default">Close</a>
        </Modal.Footer>
      </Modal>
    )
  }

  renderModals() {
    return (
      <div>
        {this.renderDeleteCampaignModal()}
        {this.renderDeleteAdvertiserModal()}
        {this.renderEditAdvertiserModal()}
      </div>
    )
  }

  confirmDeleteAdvertiser() {
    const { confirmDeleteAdvertiser, advertiser } = this.props;
    confirmDeleteAdvertiser(advertiser);
  }

  renderDeleteAdvertiserIcon() {
    return (
      <div className="delete-advertiser-icon">
        <i
          className="fa fa-trash-o"
          aria-hidden="true"
          onClick={this.confirmDeleteAdvertiser}>
        </i>
      </div>
    )
  }

  renderDeleteAdvertiserModal() {
    const { show_delete_advertiser_confirmation, deleting_advertiser, advertiser, cancelConfirmDeleteAdvertiser, deleteAdvertiser } = this.props;
    return (
      <Modal
        show={show_delete_advertiser_confirmation}
        onHide={() => cancelConfirmDeleteAdvertiser()}
      >
        <Modal.Header>
          <h4>Are you sure you want to delete advertiser {advertiser.name}?</h4>
        </Modal.Header>
        <Modal.Footer>
          <button className="btn btn-danger" type="button" disabled={deleting_advertiser} onClick={() => deleteAdvertiser(advertiser)}>Delete</button>
          <a onClick={() => cancelConfirmDeleteAdvertiser()} className="btn btn-default">Close</a>
        </Modal.Footer>
      </Modal>
    )
  }

  renderDeleteButton(campaign) {
    const { currentUser } = this.props;
    if (currentUser && currentUser.permissions.can_create_campaign) {
      return (
        <div className="delete-campaign-button btn btn-default" onClick={(e) => this.onDeleteCampaign(e, campaign)}>
          <i className="fa fa-trash" /> Remove
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  renderCampaign(campaign, index) {
    return (
      <div
        className={(index % 2 === 0) ? "listing" : "listing stripe"}
        key={campaign.id}
        onClick={() => this.onRowClick(campaign)}
      >
        <div className="name">
          {campaign.name}
          <span className={`campaign-status ${campaign.status}`}>{campaign.status}</span>
        </div>
        {this.renderDeleteButton(campaign)}
        <div className="details">
          <span>{campaign.units_count} Units • Start {this.formatDate(campaign.start_date)} • End {this.formatDate(campaign.end_date)}</span>
          <span>Created {this.formatDate(campaign.created_at)} • Updated {this.formatDate(campaign.updated_at)}</span>
        </div>
      </div>
    );
  }

  renderCampaignsArea() {
    const { campaigns, campaign_filters_changed } = this.props;

    return (
      <div>
        {(campaigns && campaigns.length === 0) && <div>
          No Campaigns
        </div>}

        {campaigns && campaigns.length > 0 &&
          campaigns.map((campaign, index) => this.renderCampaign(campaign, index))
        }

        {(campaigns && campaigns.length > 0) &&
          this.renderPagination()
        }
      </div>
    )
  }

  formatDate(date) {
    const formatted_date = moment(date).format('L');
    if (formatted_date === 'Invalid date') {
      return '';
    } else {
      return formatted_date;
    }
  }

  renderNewCampaignButton() {
    return (
      <div className="right-button-container">
        <button className="new-campaign" onClick={this.onCreateCampaign}>
          + New Campaign
        </button>
      </div>
    )
  }

  renderEditAdvertiserModal() {
    const { advertiser, hideEditAdvertiserModal, show_edit_advertiser_modal } = this.props;
    return (
      <Modal
        show={show_edit_advertiser_modal}
        onHide={() => hideEditAdvertiserModal()}
      >
        <Modal.Header>
          <h4>Editing {advertiser.name}</h4>
        </Modal.Header>
        <Modal.Body>
          <Edit />
        </Modal.Body>
        <Modal.Footer>
          <a onClick={() => hideEditAdvertiserModal()} className="btn btn-default">Cancel</a>
        </Modal.Footer>
      </Modal>
    )
  }

  onEditAdvertiser() {
    const { showEditAdvertiserModal } = this.props;
    showEditAdvertiserModal();
  }

  renderEditAdvertiserButton() {
    return (
      <div className="right-button-container">
        <button className="edit-advertiser" onClick={this.onEditAdvertiser}>
          <i className="fa fa-pencil" aria-hidden="true"></i> Edit Details
        </button>
      </div>
    )
  }

  renderAdvertiserInfo() {
    const { advertiser } = this.props;
    return (
      <div className="advertiser-info">
        {advertiser.advertiser_type && <div className="detail-spot">
          <div className="detail">{advertiser.advertiser_type}</div>
          <div className="dot">•</div>
        </div>}
        {advertiser.url && <div className="detail-spot">
          <div className="detail">{advertiser.url}</div>
          <div className="dot">•</div>
        </div>}
        {advertiser.phone && <div className="detail-spot">
          <div className="detail">{advertiser.phone}</div>
          <div className="dot">•</div>
        </div>}
        {advertiser.address && <div className="detail-spot">
          <div className="detail">{advertiser.address}</div>
        </div>}
      </div>
    );
  }

  render() {
    const { advertiser } = this.props;
    const lead_source_class = advertiser.lead_source ? advertiser.lead_source.toLowerCase() : 'internal';
    const lead_source_style = {
      marginTop: 10,
      marginLeft: 10,
      fontWeight: 600
    };

    return (
      <div className="col-md-8 campaign-column">
        {this.renderModals()}
        <div style={{display: 'flex'}}>
          {advertiser && <div style={{width: '50%', display: 'flex'}}>
            {this.renderDeleteAdvertiserIcon()}
            <h4>{advertiser.name}</h4>
            <span
              className={`advertiser-lead-source ${lead_source_class}`}
              style={lead_source_style}
            >{advertiser.lead_source}</span>
          </div>}
          <div style={{width: '50%'}}>
            {advertiser && this.renderNewCampaignButton()}
            {advertiser && this.renderEditAdvertiserButton()}
          </div>
        </div>
        {advertiser && this.renderAdvertiserInfo()}
        <div className="row">
          <div className="col-md-6">
            {this.renderSearch()}
          </div>
          <div className="col-md-6">
            {this.renderSortingDropdown()}
          </div>
        </div>
        {this.renderCampaignsArea()}
      </div>
    );
  }
};

export default connect(
  // @ts-ignore
  ({ advertisers_ui: {
      show_edit_advertiser_modal,
      advertiser,
      campaigns,
      campaigns_total_pages,
      campaigns_per_page,
      campaigns_page,
      campaigns_query,
      campaigns_sort_by,
      show_delete_advertiser_confirmation,
      advertiser_for_deletion,
      deleting_advertiser,
      show_delete_campaign_confirmation,
      campaign_for_deletion,
      deleting_campaign,
      campaign_filters_changed
    // @ts-ignore
    }, currentUser
  }) => ({
    show_edit_advertiser_modal,
    advertiser,
    campaigns,
    campaigns_total_pages,
    campaigns_per_page,
    campaigns_page,
    campaigns_query,
    campaigns_sort_by,
    show_delete_campaign_confirmation,
    campaign_for_deletion,
    deleting_campaign,
    show_delete_advertiser_confirmation,
    advertiser_for_deletion,
    deleting_advertiser,
    campaign_filters_changed,
    currentUser
  }),
  {
    confirmDeleteAdvertiser,
    cancelConfirmDeleteAdvertiser,
    deleteAdvertiser,
    hideEditAdvertiserModal,
    showEditAdvertiserModal,
    fetchAdvertiserCampaigns,
    setCampaignsFilter,
    confirmDeleteCampaign,
    cancelConfirmDeleteCampaign,
    deleteCampaign
   }
)(Details);
