import moment from "moment";
import * as React from "react";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import { connect } from "react-redux";

import { updateDates } from "./actions";

class ChangeDatesModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
    };

    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.renderModalBody = this.renderModalBody.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { saving_dates, onHide } = this.props;
    if (!!!saving_dates && prevProps.saving_dates) {
      onHide();
    }
  }

  onSubmit() {
    const { updateDates, unit_tokens, campaign_token } = this.props;
    const { start_date, end_date } = this.state;
    updateDates(unit_tokens, campaign_token, start_date, end_date);
  }

  onStartDateChange(start_date) {
    this.setState({ start_date });
  }

  renderModalBody() {
    const { onHide, saving_dates } = this.props;
    const { start_date, end_date } = this.props;

    return (
      <Modal.Body>
        {saving_dates && (
          <div>
            <div className="loading-spinner">
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        )}
        {!!!saving_dates && (
          <div>
            <div className="form-group row">
              <div className="col-md-6">
                <div>Start Date</div>
                <Datetime
                  dateFormat="LL"
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{ placeholder: "Start Date" }}
                  onChange={start_date => this.setState({ start_date: moment(start_date) })}
                  value={start_date}
                />
              </div>
              <div className="col-md-6">
                <div>End Date</div>
                <Datetime
                  dateFormat="LL"
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{ placeholder: "End Date" }}
                  onChange={end_date => this.setState({ end_date: moment(end_date) })}
                  value={start_date}
                />
              </div>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                className="btn btn-success"
                onClick={this.onSubmit}
                style={{
                  marginRight: 10,
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                Submit
              </button>
              <button
                className="btn btn-default"
                onClick={onHide}
                style={{
                  marginRight: 10,
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    );
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="flight_action_modal">
        <Modal.Header>
          <Modal.Title>Change Flight Dates</Modal.Title>
        </Modal.Header>
        {this.renderModalBody()}
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { saving_dates } }) => ({ saving_dates }),
  { updateDates },
)(ChangeDatesModal);
