export function impressionsByBudget(cpm, budget) {
  return parseInt(budget / cpm * 1000);
}

export function unitsByBudget(price, budget) {
  return parseInt(budget / price);
}

export function budgetByImpressions(cpm, impressions) {
  return (impressions * cpm) / 1000;
}

export function impressionsByShowingLevel(population, showing_level) {
  return parseInt((showing_level * population * 28) / 100, 10);
}

export function budgetByShowingLevel(cpm, population, showing_level) {
  return budgetByImpressions(cpm, impressionsByShowingLevel(population, showing_level));
}

export function showingLevelByImpressions(population, impressions) {
  return (impressions * 100) / (population * 28);
}
