import Chart from "chart.js";
import Chartkick from "chartkick";
import { connect } from 'react-redux';
import React from 'react';

import { clearSegment, loadSegment, selectMetric } from '../../../actions/segment';
import ApiKey from './ApiKey';
import Loading from '../../Loading';

type Segment = {
  loaded: boolean;
  metrics: {[key: string]: Event[]};
  selected_metric: string;
}

type Event = {
  city: string;
  date: string;
  event_name: string;
  event_type: string;
  total: number;
}

type Props = {
  campaignId: string;
  advertiser_id: number;
  segment: Segment;
  clearSegment: Function;
  loadSegment: Function;
  selectMetric: Function;
}

Chartkick.addAdapter(Chart);

class SegmentAnalytics extends React.Component<Props, {}> {
  _metrics?: string[];

  componentWillMount() {
    this.props.clearSegment();
    this.props.loadSegment(this.props.campaignId);
  }

  componentDidMount() {
    this.renderCharts();
  }

  componentDidUpdate() {
    this.renderCharts();
  }

  isActive() {
  return Object.keys(this.props.segment.metrics).length > 0;
  }

  metrics() {
    if (this._metrics) return this._metrics;

    let metrics = Object.keys(this.props.segment.metrics);

    const size = metrics.length;
    metrics = metrics.filter(m => m !== "Page Views").sort();

    if (size > metrics.length) {
      metrics = ["Page Views"].concat(metrics);
    }

    this.props.selectMetric(metrics[0] || '')

    this._metrics = metrics;
    return metrics;
  }

  render() {
    if (!this.props.segment.loaded) return <Loading/>;

    if (!this.isActive()) {
      return (
          <div className="analytics_section segment" style={{marginTop: "15px"}}>
            <div className="section_header"><i className="fa fa-pie-chart"></i>Segment</div>
            <h4 style={{marginBottom: "20px"}}>Setup the AdQuick destination on your Segment Account with your API Key</h4>
            <h5 style={{marginBottom: "20px"}}>If you already setup the integration, check again in a few minutes 😃</h5>
            <ApiKey header={false} />
          </div>
      );
    }

    return (
        <div className="container-fluid" style={{marginTop: "20px"}}>
          <div className="row">
            <div className="form-group">
              <select className="form-control" value={this.props.segment.selected_metric} onChange={ e => this.props.selectMetric(e.target.value) }>
                {this.renderMetricOptions()}
              </select>
            </div>
          </div>
          <div id="segment-metric-chart" className="row" style={{ height: '350px', marginTop: '10px' }}/>
        </div>
    );
  }

  renderMetricOptions() {
   return this.metrics().map(m => <option key={m} value={m}>{m}</option>);
  }

  renderCharts() {
    if (!this.isActive()) return;

    const data = this.props.segment.metrics[this.props.segment.selected_metric];
    // const metric = data.reduce((acc,d) => {
    //   const entry = acc[d.city] || [];
    //   entry[d.date] = d.total;
    //   acc[d.city] = entry;
    //   return acc
    // }, {});
    // const chartData = Object.keys(metric).map(name => ({name, data: metric[name]}))
      //
    if (!data) return;

    const chartData = [{
      name: this.props.segment.selected_metric,
      data: data.reduce((acc,d) => {
        acc[d.date] = d.total;
        return acc;
      }, {})
    }];

    const library = {
      layout: {
        padding: {
          left: 0,
          right: 15,
          top: 15,
          bottom: 15
        }
      },
      scales: {
        yAxes: [{
          // display: false,
          ticks: {
            padding: 10,
            // stepSize : step
          },
          gridLines: {
            display: true,
            drawBorder: false,
          }
        }],
        xAxes: [{
          display: true
        }]
      }
    }
    // const options = { points: true, max: max, legend: false, colors: ["#4a90e2", "#f5a623"], library: library }
    const options = { points: true, legend: false, library }
    new Chartkick.LineChart("segment-metric-chart", chartData, options);
  }
}

export default connect(
  // @ts-ignore
  ({ campaign, segment }) => ({
    campaignId: campaign.campaignId,
    advertiser_id: campaign.campaign.advertiser_id,
    segment
  }), { clearSegment, loadSegment, selectMetric })(SegmentAnalytics);
