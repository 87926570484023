import * as React from "react";
import numeral from "numeral";
import WhiteBox from "../WhiteBox";
import TextIcon from "../TextIcon";
import Theme from "../../theme";
import { Loading, LoadingSize } from "../Loading";
export interface HighlightBoxProps {
  percentage?: number;
  number?: number;
  currency?: number;
  isLoading?: boolean;
  children: React.ReactNode;
}

const HighlightBox = ({ percentage, children, number, currency, isLoading }: HighlightBoxProps): React.ReactElement => {
  const renderContent = (): React.ReactElement => (
    <>
      {percentage && `${percentage}%`}
      {number && numeral(number).format("0.0a")}
      {currency && numeral(currency).format("($ 0.00 a)")}
    </>
  );

  return (
    <WhiteBox align="center" inline={true}>
      <TextIcon theme={Theme.TEXT_BIG}>
        {isLoading ? <Loading size={LoadingSize.Small} /> : renderContent()}
      </TextIcon>
      <br />
      {children}
    </WhiteBox>
  );
};

export default HighlightBox;
