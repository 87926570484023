import React from 'react';
import createClass from 'create-react-class';
import { get, post } from '../../utils/api';
import { Modal } from 'react-bootstrap';
import { browserHistory } from 'react-router-legacy';
import CampaignStore from '../../stores/CampaignStore'
import GlobalActions from '../../actions/GlobalActions'
import SupplierName from "../SupplierName";
import Loading from "../Loading";

const FindVendors = createClass({
  displayName: 'FindVendors',

  getInitialState() {
    return {
      saving: false,
      loading: true,
      results: [],
      error: ''
    };
  },

  componentDidMount() {
    this.loadVendors()
  },

  loadVendors() {
    const { campaign } = this.props

    if (campaign.media_types.length == 0) {
      this.setState({ vendor_error: "You must specify media types", loading: false })
      return
    } else if (!campaign.advertiser) {
      this.setState({ vendor_error: "You did not specify the advertiser in the RFP" })
    } else if (campaign.budget == "") {
      this.setState({ vendor_error: "You did not specify the budget in the RFP" })
    }

    $.get(`/api/v1/suppliers/search?bounds=${this.props.boundingBox}&campaign=${campaign.token}`).then(({ results, market }) => {
      this.setState({ results, market, loading: false })
    }).fail(() => {
      GlobalActions.showError('There was an error. Please try again.');
      this.props.onHide()
    })
  },

  async onSendRFP(user_ids, force_send = false, market_id) {
    const { campaign } = this.props
    if(!!!campaign.start_date || !!!campaign.end_date) {
      return this.setState({error: 'Please fill in start and end dates before sending the RFP'})
    }
    try {
      await post(`/api/v1/proposals`, { campaign_id: campaign.token, user_ids, force_send, market_id });
      GlobalActions.showMessage('The RFP has been sent to the selected vendors');
    } catch(e) {
      GlobalActions.showError('There was an error sending the RFPs');
      throw(e);
    }
  },

  onSave() {
    const { market, error } = this.state
    const market_id = market && market.id;

    if (confirm("This action will email the vendors. Are you sure you filled in all the RFP details?")) {
      this.setState({saving: true})
      const user_ids = $('.user_checkbox')
        .filter((i, checkbox) => checkbox.checked)
        .map((i, checkbox) => checkbox.id.substr("users_".length))
        .toArray()

      this.onSendRFP(user_ids, false, market_id)
      !_.isEmpty(error) && this.props.onHide()
    }
  },

  onResultClick({ user }) {
    const elem = $(`#users_${user.id}`)[0]
    elem.checked = !elem.checked
  },

  renderResult(result) {
    return (
      <div className="row" style={{cursor:'pointer', marginBottom: 5}} onClick={() => this.onResultClick(result)} key={result.index}>
        <div className="col-sm-1">
          {result.user &&
            <input type="checkbox" defaultChecked name={`users[${result.user.id}]}`} className="user_checkbox" id={`users_${result.user.id}`} />}
        </div>
        <div className="col-sm-11">
          <div><SupplierName className="bold" supplier={result.supplier}/> ({result.unit_count} known units)</div>
          <div>{result.user ? `${result.user.name} - ${result.user.email}` : 'No Contact'}</div>
        </div>
      </div>
    )
  },

  render() {
    const { saving, loading, results, vendor_error, error } = this.state;

    return (
      <div>
        <Modal.Header className="text-center" closeButton>
          <b>Find Vendors</b>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loading/>}
          {!loading && <div style={{maxHeight:400,overflow:'scroll'}}>
            {vendor_error && <div className="error" style={{color:'red',marginBottom:10}}><b>{vendor_error}</b></div>}

            <div style={{marginBottom:15,color:'green'}}>
              <b>Found {results.length} results</b> (scroll to see them all if there are >8)
            </div>

            {results.map(result => this.renderResult(result))}
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.onSave} disabled={saving} className="btn btn-primary" style={{float:'left'}}>{saving ? "Sending..." : "Send RFP"}</button>
          <button onClick={this.props.onCancel} className="btn btn-danger">Cancel</button>
          {error &&
            <p style={{padding: '5px', borderRadius: '5px', marginTop: '10px'}} className="bg-danger text-danger">{error}</p>
          }
        </Modal.Footer>
      </div>
    )
  },
});

export default FindVendors;
