import React from "react";
import { browserHistory, withRouter } from 'react-router-legacy';
import GlobalActions from "../../actions/GlobalActions";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import {
  fetchContracts,
  destroyContract,
  updateContract,
  cancelSignatureRequest,
  requestNewLink
} from "../Inventory/actions";
import ContractCampaign from "../Contracts/ContractCampaign";
import RequestSignature from "../Contracts/RequestSignature";
import { Modal, Button } from "react-bootstrap";
import Loading from '../Loading';

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      current_contract: null,
      saving: false,
      show_contract_campaign: false,
      show_request_signature: false,
      is_counter_signer: false,
    };

    this.onAgencyContract = this.onAgencyContract.bind(this);
    this.workflowStateTransform = this.workflowStateTransform.bind(this);
    this.navigateToManageUnits = this.navigateToManageUnits.bind(this)
  }

  componentDidMount(){
    const { user } = this.props;
    const can_send_counter_signature = _.get(user, 'permissions.can_send_counter_signature');
    this.setState({ is_counter_signer: can_send_counter_signature });
  }

  componentWillMount() {
    const { fetchContracts, campaign_token, location } = this.props;
    const { query } = location;
    const signer_email = query && query.signer_email && query.signer_email.trim().replace(' ', '+')
    const params = query && query.signer_email ? `?signer_email=${signer_email}` : "";
    fetchContracts(campaign_token, params);
  }

  componentWillReceiveProps(nextProps) {
    const { saving } = this.state;
    if (nextProps.destroyed_contract_id) {
      GlobalActions.showMessage("Contract removed");
    } else if (nextProps.updated_contract_id) {
      // temporary fix to bug in reducer
      window.location.reload();
    }

    if (saving) this.setState({ saving: false });

    const { contracts, location } = nextProps;
    const { query } = location;
    if (contracts && query && query.signer_email) {
      const signer_email = query.signer_email.trim().replace(' ', '+')
      const contract = contracts.filter(c => (c.signer_email === signer_email && c.id === query.contract_id))[0];
      this.openHelloSign(contract);
    }
  }

  removeContract(id) {
    this.setState({ saving: true }, async () => {
      await this.props.destroyContract(id);
      this.setState({ saving: false })
    });
  }

  showRequestSignature(show_request_signature, current_contract) {
    this.setState({ show_request_signature: !!!show_request_signature, current_contract });
  }

  hideContractCampaign() {
    this.setState({ show_contract_campaign: false });
  }

  hideRequestSignature() {
    this.setState({ show_request_signature: false });
  }

  navigateToManageUnits() {
    const { campaign_token } = this.props
    if (this.managedVendorAccess()) { return this.navigateToVendorManageUnits() }
    window.location.assign(`/campaigns/${campaign_token}/manage/units`);
  }

  onAgencyContract() {
    const { campaign_token } = this.props;
    browserHistory.push(`/campaigns/${campaign_token}/manage/units?contracting=true`)
  }

  navigateToVendorManageUnits() {
    const { campaign_token } = this.props;
    window.location.assign(`/campaigns/${campaign_token}/summary/manage_units`);
  }

  onClickViewSignedContract(contract) {
    window.open(contract.signed_contract_file.url || contract.signed_contract_file);
  }

  onClickViewContract(contract) {
    window.open(contract.file_url);
  }

  openHelloSign(contract) {
    if (contract && contract.workflow_state !== 'signed') {
      try {
        HelloSign.init(AppConfig.helloSignClientId);

        HelloSign.open({
          skipDomainVerification: AppConfig.helloSignTestMode,
          url: contract.signature_request_url,
          allowCancel: true,
          messageListener: this.onHelloSignMessage.bind(this),
          container: this.helloSignRef
        });
      } catch (e) {
        console.log({ contracting_error: e });
        GlobalActions.showError("There was an error signing your contract, please contact AdQuick");
      }
    } else {
      GlobalActions.showMessage("This contract has already been signed");
    }
  }

  async requestNewLink() {
    const { contracts, location, requestNewLink } = this.props;
    const { query } = location;
    const signer_email = query.signer_email.trim().replace(' ', '+')
    const contract = contracts.filter(c => (c.signer_email === signer_email && c.id === query.contract_id))[0];
    const new_link = await requestNewLink(contract);
    this.setState({ tried_new_link: true }, () => {
      HelloSign.open({
        skipDomainVerification: AppConfig.helloSignTestMode,
        url: new_link,
        allowCancel: true,
        messageListener: this.onHelloSignMessage.bind(this),
        container: this.helloSignRef
      });
    });
  }

  onHelloSignMessage(data) {
    if (data.event === "error" && data.description.includes("invalid or expired.")) {
      const { tried_new_link } = this.state;
      if (!!!tried_new_link) {
        HelloSign.close();
        this.requestNewLink();
      }
    }
    if (data.event == "signature_request_signed") {
      HelloSign.close();
      GlobalActions.showMessage("Contract signed!");
      browserHistory.push(this.props.location.pathname);
    }
  }

  onCancelContract(contract_id) {
    const { cancelSignatureRequest } = this.props;
    cancelSignatureRequest(contract_id);
  }

  managedVendorAccess() {
    const { user } = this.props;
    return user && (user.is_supplier_admin || user.is_supplier_sales);
  }

  supplierSellerAccess() {
    const { user } = this.props;
    return user && user.is_supplier_seller;
  }

  allPermittedUserTypes() {
    const { user } = this.props;
    return user && (user.is_admin || user.is_agency || this.managedVendorAccess() || this.supplierSellerAccess())
  }

  notAdmin() {
    const { user } = this.props;
    return this.managedVendorAccess() || user.is_agency || this.supplierSellerAccess();
  }

  workflowStateTransform(contract) {
    if (this.allPermittedUserTypes()) {
      const workflow_state = contract.workflow_state;
      if (workflow_state === "new") {
        return <span style={{ color: "#c9302c", float: "right", fontWeight: "800" }}> Draft - Not Sent </span>;
      } else {
        return (
          <span style={{ color: "#777", float: "right" }}>
            <span style={{ fontWeight: "600" }}>Contract status: </span>
            {contract.workflow_state}
          </span>
        );
      }
    } else {
      return <></>;
    }
  }

  renderButtons() {
    const { user, campaign_token } = this.props;
    const managed_vendor_access = this.managedVendorAccess();

    return (
      <div>
        {this.allPermittedUserTypes() &&
          <button
            className="btn btn-primary generate_contract_button"
            style={{
              float: 'right',
              marginTop: 20,
              borderRadius: 20
            }}
            onClick={this.navigateToManageUnits}
          >
            Generate New Contract
          </button>
        }

        {(user && user.is_admin) && <button
          className="btn btn-info"
          style={{
            float: "right",
            marginTop: 20,
            marginRight: 10,
            borderRadius: 20
          }}
          onClick={() => GlobalActions.openPopup(
            'add_contract',
            { upload_form: true, campaign_token }
          )}
        >
          Upload Contract
        </button>}

        {this.notAdmin() && (
          <button
            className="btn btn-info"
            style={{
              float: "right",
              marginTop: 20,
              marginRight: 15,
              borderRadius: 20
            }}
            onClick={(e) => {
              this.setState({ show_contract_campaign: !!!e.target.value })
            }}
          >
            Upload Contract
          </button>
        )}
      </div>
    )
  }

  render() {
    const { campaign_token, user, campaign_status } = this.props;
    const contracts =
      this.props.contracts &&
      this.props.contracts.sort(function compare(a, b) {
        var dateA = new Date(a.updated_at);
        var dateB = new Date(b.updated_at);
        return dateB - dateA;
      });
    const { saving, show_contract_campaign, show_request_signature, current_contract, is_counter_signer } = this.state;
    const managed_vendor_access = this.managedVendorAccess();

    if (saving) return (<Loading />);

    return (
      <div className="row direct-response-dashboard" ref={this.helloSignRef}>
        {this.renderModals(user, show_contract_campaign, campaign_token, show_request_signature, current_contract)}
        <div className="col-md-12">
          {this.renderButtons()}
          <h2 style={{ paddingBottom: 10, marginBottom: 10 }}>Contracts</h2>
          {managed_vendor_access && campaign_status !== "proposal" && (
            <div style={{ marginBottom: "20px", fontSize: "14px", color: "#777" }}>
              <span style={{ fontWeight: "600" }}>Status: </span>
              {campaign_status}
            </div>
          )}

          {!!!contracts && <div className="empty_contracts"></div>}

          {contracts && contracts.map((contract) =>
            <div key={contract.id} style={{padding: 20, border: "1px solid #ccc", marginBottom: 10}}>
              <div>
                <div>
                  <b>
                    {contract.title || `${contract.signer_name} - ${contract.signer_email}`}
                  </b>
                    {this.workflowStateTransform(contract)}
                </div>
                <div style={{marginBottom: 10}}>
                  {moment(contract.created_at).format("LLL")}
                </div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "start"}}>
                  { this.notAdmin() && !contract.signature_request_id && !is_counter_signer && (
                    <button className="btn btn-info" onClick={this.showRequestSignature.bind(this, show_request_signature, contract)}>
                      Request E-Signature
                    </button>)
                  }
                  {contract.signed_contract_file &&
                    <button
                      className="btn btn-default"
                      onClick={this.onClickViewSignedContract.bind(this, contract)}
                    >
                      View Signed Contract
                    </button>
                  }
                  {contract.file_url && (
                    <button
                      className="btn btn-default"
                      onClick={this.onClickViewContract.bind(this, contract)}
                      style={contract.signed_contract_file ? {marginLeft: "10px"} : {}}
                    >
                      View Contract
                    </button>
                  )}
                  {contract.signature_request_id &&
                    <button
                      className="btn btn-danger"
                      onClick={this.onCancelContract.bind(this, contract.token)}
                      style={{marginLeft: "10px"}}
                    >
                      Cancel Contract
                    </button>
                  }
                  {this.allPermittedUserTypes() && (
                    <div
                      onClick={this.removeContract.bind(this, contract.id)}
                      style={{
                        marginLeft: "auto",
                        display: "inline-block",
                        fontSize: "20px",
                        cursor: "pointer"
                      }}
                    >
                      <i className="fa fa-trash-o"></i>
                    </div>
                  )}
                </div>

              </div>
            </div>
          )}
          {(!!!contracts || contracts.length == 0) && (
            <div>There are no contracts saved to this campaign.</div>
          )}
        </div>
      </div>
    );
  }

  renderModals(user, show_contract_campaign, campaign_token, show_request_signature, current_contract) {
    return (
      <>
        {this.allPermittedUserTypes() && (
          <div>
            <ContractCampaign
              show={show_contract_campaign}
              campaign_token={campaign_token}
              onHide={this.hideContractCampaign.bind(this)}
            />
            <RequestSignature
              show={show_request_signature}
              campaign_token={campaign_token}
              current_contract={current_contract}
              onHide={this.hideRequestSignature.bind(this)}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    ({ inventory }) => ({
      contracts: inventory.contracts,
      destroyed_contract_id: inventory.destroyed_contract_id,
      updated_contract_id: inventory.updated_contract_id
    }),
    {
      fetchContracts,
      destroyContract,
      updateContract,
      requestNewLink,
      cancelSignatureRequest
    }
  )(Contracts)
);
