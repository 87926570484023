import React from 'react';
import accounting from 'accounting';
import { Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { visitUrl } from '../../utils/visitUrl';
import moment from 'moment';
import UnitModel from "../../models/unitModel";
import SupplierName from "../SupplierName";
import UnitImpressions from "../UnitImpressions";
import Favorite from '../Campaign/Favorite';
import GlobalActions from '../../actions/GlobalActions';
import _, { isNull, method } from 'lodash'
import { get } from '../../utils/api';
import Tooltip from "rc-tooltip";

class InfoTab extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      media_subtype: '',
      unitModel: new UnitModel(this.props.unit)
    }
    this.onImageFailed = this.onImageFailed.bind(this);
    this.renderImagesOrVideo = this.renderImagesOrVideo.bind(this);
    this.showSupplierName = this.showSupplierName.bind(this);
    this.supplierContactInfo = this.supplierContactInfo.bind(this);
    this.renderImpressions = this.renderImpressions.bind(this);
    this.renderFaceId = this.renderFaceId.bind(this);
    this.renderMultipleFligths = this.renderMultipleFligths.bind(this);
    this.renderPreviousAdvertisers = this.renderPreviousAdvertisers.bind(this);
    this.renderAttributionStatus = this.renderAttributionStatus.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.renderSpecSheet = this.renderSpecSheet.bind(this);
  }

  async componentWillMount() {
    const { unit } = this.props
    if (!!!unit.media_subtype_id) return
    const res = await get(`/api/v1/media_subtypes/${unit.media_subtype_id}`);
    this.setState({ ...res.data });
  }

  onImageFailed() {
    this.refs.image.classList.add('infoTab__image_empty');
  }

  renderImagesOrVideo() {
    const { image_urls, video_embed_code, images } = this.props.unit;

    if (video_embed_code) {
      return <div className="unit-embedded-video" dangerouslySetInnerHTML={{ __html: video_embed_code }} />;
    } else if (image_urls.length == 1) {
      return (
        <div className="infoTab__image-wrap">
          <img className="infoTab__image" src={image_urls[0]} />
        </div>
      )
    } else if (image_urls.length > 0) {
      return (<Carousel>
        {images.map((image) =>
          <Carousel.Item key={image.position}>
            <div className="infoTab__image-wrap">
              <img className="infoTab__image" src={image.url} />
            </div>
          </Carousel.Item>)}
      </Carousel>);
    }
  }

  showSupplierName() {
    const { supplier_name } = this.props.unit;
    const { is_admin, is_supplier } = this.props.user;
    const { campaign, isAgencySearch } = this.props;

    if (!_.isEmpty(campaign) && !campaign.permissions.can_view_supplier_name) return false;
    return !!supplier_name
  }

  supplierContactInfo() {
    const { campaign_units } = this.props.unit;
    if (!campaign_units.length) return '';
    const supplierContact = campaign_units[0].supplier_contact;
    if (_.isEmpty(supplierContact)) {
      return 'No contact assigned'
    } else {
      return `${supplierContact.name} (${supplierContact.email})`
    }
  }

  renderImpressions() {
    const { unit, campaign: { campaign }, user} = this.props;
    const isGeopathPanel = !!unit.tab_panel_id
    const impressions = unit.impressions;
    const weekly_impressions = unit.week_total_impressions;
    const impression_eighteen = unit.impressions_eighteen;
    const impression_eighteen_weekly = unit.impressions_eighteen_per_week;
    const total_impressions_for_duration = unit.total_impressions_for_duration;
    const showPriceForDuration = (campaign && campaign.show_price_for_duration) || false;
    const showImpressionsEighteen = user.permissions.can_view_impressions_18_plus;
    if (!impressions && !weekly_impressions) {
      return <span />
    }

    return (
      <div>
        {!!impressions && !showPriceForDuration &&
          <UnitImpressions
            label="Total 4 Week Impressions:"
            impressions={impressions}
            fromGeopath={isGeopathPanel}
          />
        }

        {!!total_impressions_for_duration && showPriceForDuration &&
          <UnitImpressions
            label="Total Impressions:"
            impressions={total_impressions_for_duration}
            fromGeopath={isGeopathPanel}
          />
        }
        {!!weekly_impressions &&
          <UnitImpressions
            label="Weekly Impressions:"
            impressions={weekly_impressions}
            fromGeopath={isGeopathPanel}
          />
        }

        {!!impression_eighteen && showImpressionsEighteen &&
          <UnitImpressions
            label="18+ Impressions:"
            impressions={impression_eighteen}
            fromGeopath={isGeopathPanel}
          />
        }

        {!!impression_eighteen_weekly && showImpressionsEighteen &&
          <UnitImpressions
            label="Weekly 18+ Impressions:"
            impressions={impression_eighteen_weekly}
            fromGeopath={isGeopathPanel}
          />
        }

      </div>
    )
  }

  renderFaceId(campaign) {
    const { unit } = this.props
    if(campaign && (campaign.is_mock || campaign.hide_face_ids)) { return `${unit._id}` }
    return `${unit.supplier_face_id}`
  }

  renderMultipleFligths() {
    const { campaign_units } = this.props
    const units = campaign_units.filter(u => u.start_date && u.end_date)

    if(_.isEmpty(units)) { return }
    return (
      <div className="infoTab__unit-multi-flight">
        <span className="infoTab__title">Available Dates</span>
        <div style={{display:'flex',flexDirection:'column',width:'100%',marginTop:'10px'}}>
          {units.map(fu => {
            return (
              <div key={fu.id} style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'10px'}}>
                <div style={{display:'flex'}}>
                  <Favorite
                    isFavorited={fu.is_favorited}
                    unitId={this.props.unit.id}
                    campaignUnitId={fu.id}
                    onFavoriteUnit={this.props.onFavoriteUnit}
                    showCallback={false}
                  />
                  <div className="infoTab__flight-date" style={{marginLeft:'5px'}}>
                    {moment.utc(fu.start_date).format("MMM DD")}
                  </div>
                </div>
                <div className="infoTab__flight-arrow"></div>
                <div className="infoTab__flight-date">
                  {moment.utc(fu.end_date).format("MMM DD")}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderPreviousAdvertisers(value) {
    const { campaign } = this.props
    if(campaign && campaign.campaign &&  campaign.campaign.is_mock) {
      return (
        <span>visible after <a onClick={() =>  GlobalActions.openPopup('request-price', { campaign: campaign.campaign })}>requesting availability</a></span>
      )
    }
    return value.map(v => _.capitalize(v.name)).join(", ");
  }

  renderAttributionStatus(has_attribution) {
    if (has_attribution == null) {
      return 'unknown';
    } else if (has_attribution == true) {
      return 'has attribution';
    } else if (has_attribution == false) {
      return 'no attribution';
    }
  }
// attribution tooltip based on status
  renderTooltip() {
    const { unit } = this.props
    if (unit.has_attribution == true) {
      return (
         <Tooltip
          placement="right"
          trigger={['hover']}
          overlayStyle={{ width: '200px', height: "200px", zIndex: '10000000000' }}
          overlay={<span style={{ width: "fit-content" }}>“Has Attribution” means the unit is digital with precise play logs available or static (excluding airport, transit, & alternative media types) so it is viable for web, app, and footfall attribution. All units can provide causal lift analysis, brand lift study, and other “measurable” offerings regardless of attribution status.</span>}
        >
          <i className="fal fa-info-circle" style={{ marginLeft: '5px' }}></i>
        </Tooltip>
      )}
    else if (unit.has_attribution == false) {
      return (
        <Tooltip
          placement="right"
          trigger={['hover']}
          overlayStyle={{ width: '200px', height: "200px", zIndex: '10000000000' }}
          overlay={<span style={{ width: "fit-content" }}>"No Attribution" means the unit is digital without precise play logs available or an airport, transit, or alternative media type. All units can provide provide causal lift analysis, brand lift study, and other “measurable” offerings regardless of attribution status.</span>}
        >
          <i className="fal fa-info-circle" style={{ marginLeft: '5px' }}></i>
        </Tooltip>
        )}
    else if (unit.has_attribution == null) {
      return (
        <Tooltip
          placement="right"
          trigger={['hover']}
          overlayStyle={{ width: '200px', height: "200px", zIndex: '10000000000' }}
          overlay={<span style={{ width: "fit-content" }}>“Unknown” means that we don’t know if the digital unit has precise play logs available for web, app, and footfall attribution purposes. All units can provide provide causal lift analysis, brand lift study, and other “measurable” offerings regardless of attribution status.</span>}
        >
          <i className="fal fa-info-circle" style={{ marginLeft: '5px' }}></i>
        </Tooltip>
        )}
  }

  renderSpecSheet(campaign, spec_sheet_link, is_admin, spec_sheet_verified_at) {
    if(campaign && !campaign.is_mock) { 
      return (<a href={spec_sheet_link} target="_blank">Download Spec Sheet{(!!spec_sheet_verified_at && is_admin) ? ' (Verified)' : ''}</a>);
    }
  }

  render() {
    const {
      price, rate_card_price, floor_price, size, cpm, unit_type,
      description, rationale, direction_facing, supplier_name, supplier_face_id, zip,
      inventory_file_id, spot_length, loop_length, total_spots, quantity, hold_expires_at,
      spec_sheet_link, screen_type, is_managed, is_fully_submitted, previous_advertisers, orientation, next_booked_date,
      distance_from_point_of_interest, spec_sheet_verified_at, venue, is_broker, is_package, has_attribution
    } = this.props.unit;

    const { media_subtype } = this.state
    const { isAgencySearch, user } = this.props;
    const is_supplier = user ? user.is_supplier : false;
    const is_admin = user ? user.is_admin : false;
    const showSupplierName = this.showSupplierName();
    const { campaign } = this.props;

    const currentCampaign = campaign ? campaign.campaign : {}
    const can_view_supplier_contact = campaign && campaign.permissions ? campaign.permissions.can_view_supplier_contact : false;
    return (
      <div className="infoTab">
        <div className="infoTab__specs">
          <div className="infoTab__spec">
            {size && <div className="infoTab__size">Size: <span className="infoTab__spec-value">{size}</span></div>}
            <div className="infoTab__type">Unit Type: <span className="infoTab__spec-value">{unit_type}</span></div>
            {screen_type && <div className="infoTab__type">Screen Type: <span className="infoTab__spec-value">{screen_type}</span></div>}
            {media_subtype && <div className="infoTab__type">Subtype: <span className="infoTab__spec-value">{media_subtype}</span></div>}
            {direction_facing && <div className="infoTab__type">Direction: <span className="infoTab__spec-value">{direction_facing}</span></div>}
            {showSupplierName &&
              <div className="infoTab_-size">
                Company: <SupplierName className="infoTab__spec-value" user={this.props.user} supplier={{name: supplier_name, is_managed: is_managed, is_fully_submitted: is_fully_submitted, is_broker: is_broker}}/>
              </div>
            }
            {supplier_face_id && <div className="infoTab_-size">Face ID: <span className="infoTab__spec-value">{this.renderFaceId(currentCampaign)}</span></div>}
            {can_view_supplier_contact && <div className="infoTab_-size">Contact: <span className="infoTab__spec-value">{this.supplierContactInfo()}</span></div>}
            {previous_advertisers && <div className="infoTab_-size">Previous Advertisers: <span className="infoTab__spec-value">{this.renderPreviousAdvertisers(previous_advertisers)}</span></div>}
            {spec_sheet_link && this.renderSpecSheet(currentCampaign, spec_sheet_link, is_admin, spec_sheet_verified_at)}
            {(user.permissions.can_view_inventory_management && is_supplier) &&
              <div>
                <br />
                <div className="bold">Admin Price Info</div>
                {<div className="infoTab_-size">Rate Card Price: <span className="infoTab__spec-value">{rate_card_price ? accounting.formatMoney(rate_card_price) : "Not set"}</span></div>}
                {<div className="infoTab_-size">Target Price: <span className="infoTab__spec-value">{price ? accounting.formatMoney(price) : "Not set"}</span></div>}
                {<div className="infoTab_-size">Floor Price: <span className="infoTab__spec-value">{floor_price ? accounting.formatMoney(floor_price) : "Not set"}</span></div>}
              </div>
            }
            {hold_expires_at && <div className="infoTab_-size">Hold Expires at: <span className="infoTab__spec-value">{moment(hold_expires_at).format("M/D/YY")}</span></div>}
          </div>

          <div className="infoTab__spec">
            {this.renderImpressions()}
            {(!user.permissions.hide_unit_prices && (AppConfig.showPricing && cpm && !isAgencySearch)) ?
              <div className="infoTab__cpm">
                CPM: <span className="infoTab__spec-value">{this.state.unitModel.getMockCpm(currentCampaign)}</span>
              </div> : ''}
            {(currentCampaign && next_booked_date) &&
              <div>
                Next Booked: <span className="infoTab__spec-value">{moment.utc(next_booked_date).format("MM/DD/YYYY")} ({this.props.getNextBookedByDiff()} days)</span>
              </div>
            }
            {orientation &&
              <div>
                Orientation: <span className="infoTab__spec-value">{orientation}</span>
              </div>
            }
            {zip ?
              <div className="infoTab__cpm">
                Zip Code: <span className="infoTab__spec-value">{zip}</span>
              </div> : ''}
            {spot_length ?
              <div className="infoTab__spot_length">
                Spot Length: <span className="infoTab__spec-value">{spot_length} seconds</span>
              </div> : ''}
            {loop_length ?
              <div className="infoTab__loop_length">
                Loop Length: <span className="infoTab__spec-value">{loop_length > 60 ? `${_.round(loop_length / 60, 2)} minutes` : `${loop_length} seconds`}</span>
              </div> : ''}
            {(quantity && total_spots) ?
              <div className="infoTab__spots">
                Spots Proposed: <span className="infoTab__spec-value">{`${quantity}${total_spots ? `/${total_spots}` : ''}`}</span>
              </div> : ''}
            {
              <div className="infoTab__spots">
                Attribution: <span className="infoTab__spec-value">{this.renderAttributionStatus(has_attribution)}</span>
                <span>{this.renderTooltip()}</span>
              </div>
            }
            {distance_from_point_of_interest ?
              <div className="infoTab__spots">
                Distance from closest POI: <span className="infoTab__spec-value">{accounting.format(parseFloat(distance_from_point_of_interest), 2)} miles</span>
              </div> : ''}

            {(is_admin && inventory_file_id) ?
              <div>
                <br />
                <div className="bold">Admin File Info</div>
                <a onClick={(e) => { visitUrl(e, `/unit_importer/${inventory_file_id}`) }}>
                  Unit Importer
                </a>
              </div>
            : ''}
          </div>
        </div>

        { currentCampaign && !this.props.isCartView &&
          this.renderMultipleFligths()
        }

        {venue && <div className="infoTab__description">
          <span className="infoTab__title">Venue: </span>
          <span>{venue}</span>
        </div>}

        {description && <div className="infoTab__description">
          <span className="infoTab__title">Unit Description: </span>
          <span>{description}</span>
        </div>}

        {/* custom message InWindow asked us to show for self-service browse users */}
        {this.props.unit.supplier_id == 309 &&
          <div className="infoTab__description">
          <span className="infoTab__title">Vendor Terms:</span>
          <span>{" "}All proposed locations are not on hold and subject to prior sale. Holds to contract may be requested for 72 hours and are subject to IWO and/or landlord approval. Landlord has first right of refusal (for commercial purposes only) up to 30 days of start date. Should the property be commercially leased a mutually agreeable location will be provided. All creative is subject to landlord approval. Production and installation must be facilitated by IWO at the quoted rates.
          </span>
        </div>
        }

        {(rationale) && <div className="infoTab__rationale">
          <span className="infoTab__title">Campaign Unit Rationale:</span> {rationale}
        </div>}
        {this.renderImagesOrVideo()}
      </div>
    );
  }
};

InfoTab.propTypes = {
  unit: PropTypes.object.isRequired
};

export default InfoTab;
