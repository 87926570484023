import React from 'react';
import cs from 'classnames';
import { browserHistory } from 'react-router-legacy';
import { formatMoney } from 'accounting'
import moment from 'moment';
import createClass from 'create-react-class';
import { Modal, Button, FormControl } from 'react-bootstrap';
import Datetime from 'react-datetime';
import PropTypes from "prop-types";

export default createClass({
  displayName: 'AdditionalNotes',

  propTypes: {
    campaign: PropTypes.object,
    errors: PropTypes.object,
    setAttributes: PropTypes.func.isRequired,
  },

  render() {
    const { campaign, errors } = this.props
    const errorStyle = { color: 'red' };

    return (
      <div>
        <h4>
          <i className="fa fa-edit" />
          <span>Additional Notes</span>
        </h4>
        <div className="section">
          <textarea
            className="form-control"
            defaultValue={campaign.supplier_facing_notes}
            onChange={e => this.props.setAttributes({ supplier_facing_notes: e.target.value })}
            placeholder="Please enter additional notes here."
            style={{ height: 50 }}
          />
        </div>
      </div>
    )
  },
});
