import _ from "lodash";
import moment from "moment";

import { hasStarted } from "./campaign_units";
import { sidebarUnits } from "./Units";

const date_only_format = "MM/DD/YYYY";
const full_date_format = "MM/DD/YYYY h:mm";

export type TUploadPop = {
  (campaign_id: string, units: {}[], upload: {}, posted_date: moment.Moment): Promise<void>;
};

export type TLoadCampaignDesigns = {
  (campaign_id: string): Promise<void>;
};

export const state_map = {
  new: "incomplete",
  incomplete: "incomplete",
  submitted: "submitted",
  uploaded: "submitted",
  approved: "submitted",
  proof_posted: "submitted",
  rejected: "rejected",
  posted: "posted",
  pop_posted: "posted",
};

export const status_to_title = {
  rejected: "Rejected",
  incomplete: "Incomplete",
  submitted: "Submitted",
  all: "All",
  circlegraphics: "Circle Graphics",
  posted: "Posted",
};
export const statuses = Object.keys(status_to_title);

export function designsTabUnits(unit_store, design_assets, pops, proofs, unit_load_limit) {
  const designs = _.groupBy(design_assets, d => {
    return _.get(d, "campaign_unit_token");
  });

  const pops_by_campaign_unit = _.groupBy(pops, d => {
    return _.get(d, "campaign_unit_token");
  });

  const proofs_by_campaign_unit = _.groupBy(proofs, p => {
    return _.get(p, "campaign_unit_token");
  });

  let sidebar_units = sidebarUnits(unit_store, true);
  if (unit_load_limit) {
    sidebar_units = sidebar_units.slice(0, unit_load_limit);
  }

  const units = sidebar_units.map(unit => {
    const design = designs[unit.campaign_unit_token] && designs[unit.campaign_unit_token][0];
    const pop = pops_by_campaign_unit[unit.campaign_unit_token] && pops_by_campaign_unit[unit.campaign_unit_token][0];
    const proofs =
      proofs_by_campaign_unit[unit.campaign_unit_token] && proofs_by_campaign_unit[unit.campaign_unit_token][0];
    return { ...unit, ...design, ...pop, ...proofs };
  });

  return units;
}

export function groupByStatus(designs_tab_units) {
  const unitsGroupedByStatus = _.groupBy(designs_tab_units, unit => {
    return getStatusForGrouping(unit);
  });
  return unitsGroupedByStatus;
}

export function groupByMarket(designs_tab_units) {
  const unitsGroupedByMarket = _.groupBy(designs_tab_units, unit => {
    return _.get(unit, "market_name", "Unknown Market");
  });
  return unitsGroupedByMarket;
}

export function groupByUnitType(designs_tab_units) {
  const unitsGroupedByUnitType = _.groupBy(designs_tab_units, unit => {
    return _.get(unit, "unit_type", "Unknown Unit Type");
  });
  return unitsGroupedByUnitType;
}

export function groupBySize(designs_tab_units) {
  const unitsGroupedBySize = _.groupBy(designs_tab_units, unit => {
    return _.get(unit, "size", "Unknown Size");
  });
  return unitsGroupedBySize;
}

export function withProductionOrder(designs_tab_units) {
  return designs_tab_units.filter(
    unit => !_.isEmpty(unit.design_assets) && unit.design_assets.find(a => a.has_production_order)
  );
}

export function isRejected(unit: any): boolean {
  const { design_assets, design_asset_status } = unit;
  return !_.isEmpty(design_assets) && design_asset_status === "rejected";
}

export function isIncomplete(unit: any): boolean {
  const { design_assets, design_asset_status } = unit;
  return _.isEmpty(design_assets) || design_asset_status === "new";
}

export function isSubmitted(unit: any): boolean {
  const { design_asset_status } = unit;
  return ["uploaded", "proof_posted", "approved"].includes(design_asset_status);
}

export function isPosted(unit: any): boolean {
  const { design_assets, design_asset_status } = unit;
  return design_assets && design_asset_status === "pop_posted";
}

export function isAwaitingPop(unit: any): boolean {
  return hasStarted(unit) && _.isEmpty(unit.pop);
}

export function canReject(unit: any): boolean {
  return isSubmitted(unit) && !proofPosted(unit);
}

export function proofPosted(unit: any): boolean {
  const { proofs } = unit;
  return !_.isEmpty(proofs);
}

export function canSendToProduction(design_asset: any): boolean {
  return design_asset && _.isEmpty(design_asset.production_order);
}

export function isMarkedForProduction(design_asset: any): boolean {
  return design_asset && !!design_asset.marked_for_production;
}

export function getRejectionString(unit: any): string {
  if (!isRejected(unit)) {
    return "";
  }
  const rejectedAsset = getRejectAssetFromAssets(unit);
  const rejected_at_formated = moment.utc(rejectedAsset.rejected_at).format(full_date_format);
  return `Rejected by ${rejectedAsset.rejected_by} on ${rejected_at_formated}`;
}

export function getUploadedOnString(asset: any, date_format: string = date_only_format): string {
  const uploaded_on = asset && asset.uploaded_on && moment.utc(asset.uploaded_on);
  const format = date_format || date_only_format;

  return uploaded_on ? uploaded_on.format(format) : "";
}

export function getPostedOnString(asset: any, date_format: string = date_only_format): string {
  const posted_on = asset && asset.posted_date && moment.utc(asset.posted_date);
  const format = date_format || date_only_format;

  return posted_on ? posted_on.format(format) : "";
}

export function uploadTitle(design_asset) {
  const uploaded_by = design_asset && design_asset.uploaded_by && `Uploaded by ${design_asset.uploaded_by}`;
  return `${uploaded_by} on ${getUploadedOnString(design_asset, full_date_format)}`;
}

export function getStatusForGrouping(unit: any): string {
  const { design_assets, design_asset_status } = unit;
  const noStatusAndNoAssets = !design_asset_status && _.isEmpty(design_assets);
  if (noStatusAndNoAssets) {
    return state_map.new;
  }
  return state_map[design_asset_status] || state_map.new;
}

export function getDesignAssetsIds(units: any[]) {
  return units.map(u => u.design_assets.map(d => d.id)).flat();
}

export function getPopIds(units: any[]) {
  return units.map(u => u.pop.map(p => p.id)).flat();
}

export function getRejectAssetFromAssets(unit: any) {
  return unit.design_assets.find(a => a.workflow_state === "rejected");
}

export function getFlightDates(unit: any, date_format: string = date_only_format): string {
  const format = date_format || date_only_format;
  const start_date = unit.start_date && unit.start_date.format ? unit.start_date.format(format) : unit.start_date;
  const end_date = unit.end_date && unit.end_date.format ? unit.end_date.format(format) : unit.end_date;
  return ` ${start_date} - ${end_date}`;
}
