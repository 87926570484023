import React from 'react';
import createClass from 'create-react-class';
import AuthStore from '../../../stores/AuthStore'
import { BootstrapTable } from 'react-bootstrap-table';
import * as Actions from '../../Companies/actions'
import { connect } from 'react-redux';
import { get } from '../../../utils/api'

const AccountNameProgress = createClass({
  displayName: 'AccountNameProgress',

  getInitialState() {
    return {
      isLoggedIn: AuthStore.isLoggedIn(),
      user: AuthStore.getState().user,
      progress: []
    }
  },

  componentDidMount() {
    this.fetchGroupProgress()
  },

  onSubmit(e) {
    this.fetchGroupProgress()
    e.preventDefault()
  },

  async fetchGroupProgress() {
    const { params } = this.props
    const { company_group_id } = params
    const progress = await get(`/api/v1/company_groups/${company_group_id}/named_account_progress`)
    this.setState({ progress })
  },

  render() {
    const { isLoggedIn, progress } = this.state

    if (isLoggedIn) {
      return (
        <div className="container create-campaign">
          <h1>Account Name Progress</h1>

          <div className="row">
            <div className="col-md-12">
              <BootstrapTable
                data={progress}
                striped
                hover
                options={{}}>
                <TableHeaderColumn isKey dataField="company_name" dataSort>Company Name</TableHeaderColumn>
                <TableHeaderColumn dataField="email_domain" dataSort>Email Domain</TableHeaderColumn>
                <TableHeaderColumn dataField="account_owner" dataSort>Account Owner</TableHeaderColumn>
                <TableHeaderColumn dataField="scraped_linkedin_profiles" dataSort dataFormat={value => value || 0}>Scraped Profiles Count</TableHeaderColumn>
                <TableHeaderColumn dataField="scanned_email_communication" >Scanned Email Communication</TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center" style={{ padding: 50 }}>Please login</div>
      )
    }
  }
})

const mapProps = ({ companies }) => ({
  companies
})

const mapActions = {
  getGroups: Actions.asyncFetchGroups,
  getCompanies: Actions.asyncFetchCompanies
}

export default connect(mapProps, mapActions)(AccountNameProgress);
