import React, { Component } from "react";
import { connect } from "react-redux";

import { dollarFormatter } from "../../utils/formatters";

interface Props {
  campaign: any;
  items: any;
  applyChanges: Function;
}

class PriceAdjustment extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      price_edit: null,
      prod_edit: null,
      install_edit: null,
    };
    this.getTotal = this.getTotal.bind(this);
    this.campaignTotal = this.campaignTotal.bind(this);
    this.setPriceRef = this.setPriceRef.bind(this);
    this.setProdRef = this.setProdRef.bind(this);
    this.setInstallRef = this.setInstallRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onPriceClick = this.onPriceClick.bind(this);
    this.onProdClick = this.onProdClick.bind(this);
    this.onInstallClick = this.onInstallClick.bind(this);
    this.priceRef = React.createRef();
    this.prodRef = React.createRef();
    this.installRef = React.createRef();
    this.afterApply = this.afterApply.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  private priceRef;
  setPriceRef(node) {
    this.priceRef = node;
  }

  onPriceClick() {
    const price = this.getTotal("supplier_price");
    this.setState({
      editing_price: true,
      price_unsaved: price,
      starter_price: price,
    });
  }

  private prodRef;
  setProdRef(node) {
    this.prodRef = node;
  }

  onProdClick() {
    const prod = this.getTotal("supplier_production_cost");
    this.setState({
      editing_prod: true,
      prod_unsaved: prod,
      starter_prod: prod,
    });
  }

  private installRef;
  setInstallRef(node) {
    this.installRef = node;
  }

  onInstallClick() {
    const install = this.getTotal("supplier_installation_cost");
    this.setState({
      editing_install: true,
      install_unsaved: install,
      starter_install: install,
    });
  }

  handleClickOutside(event) {
    const { editing_price, editing_prod, editing_install } = this.state;

    if (editing_price || editing_prod || editing_install) {
      const new_edit_states = {
        editing_price: this.priceRef && this.priceRef.contains ? this.priceRef.contains(event.target) : false,
        editing_prod: this.prodRef && this.prodRef.contains ? this.prodRef.contains(event.target) : false,
        editing_install: this.installRef && this.installRef.contains ? this.installRef.contains(event.target) : false,
      };
      this.setState({ ...new_edit_states }, this.afterFocusChange);
    }
  }

  afterFocusChange() {
    const { applyChanges } = this.props;
    const {
      price_unsaved,
      prod_unsaved,
      install_unsaved,
      starter_price,
      starter_prod,
      starter_install,
      editing_price,
      editing_prod,
      editing_install,
    } = this.state;

    if (editing_price || editing_prod || editing_install) {
      return;
    }
    const price_percent = (parseFloat(starter_price) - parseFloat(price_unsaved)) / parseFloat(starter_price);
    const prod_percent = (parseFloat(starter_prod) - parseFloat(prod_unsaved)) / parseFloat(starter_prod);
    const install_percent = (parseFloat(starter_install) - parseFloat(install_unsaved)) / parseFloat(starter_install);
    applyChanges(price_percent, prod_percent, install_percent, this.afterApply);
  }

  afterApply() {
    this.setState({
      price_unsaved: null,
      prod_unsaved: null,
      install_unsaved: null,
      starter_price: null,
      starter_prod: null,
      starter_install: null,
    });
  }

  getTotal(key) {
    const { items } = this.props;
    return items
      .reduce((acc, item) => {
        return acc + item[key];
      }, 0)
      .toFixed(2);
  }

  getFormattedTotal(key) {
    return <div className="total-column-inner editable">{dollarFormatter(this.getTotal(key))}</div>;
  }

  campaignTotal() {
    const { items } = this.props;
    const total = items
      .reduce((acc, item) => {
        return acc + item.supplier_price + item.supplier_production_cost + item.supplier_installation_cost;
      }, 0)
      .toFixed(2);

    return <div className="total-column-inner">{dollarFormatter(total)}</div>;
  }

  renderPriceField() {
    const { editing_price, price_unsaved } = this.state;

    if (editing_price) {
      return (
        <div className="total-column">
          <input
            style={{
              maxWidth: "100%",
              border: "1px solid #CCCCCC",
              borderRadius: 4,
            }}
            type="number"
            name="supplier_price"
            value={price_unsaved}
            onChange={e =>
              this.setState({
                price_unsaved: e.target.value,
              })
            }
            ref={this.setPriceRef}
          />
        </div>
      );
    }

    return (
      <div className="total-column" onClick={this.onPriceClick}>
        {this.getFormattedTotal("supplier_price")}
      </div>
    );
  }

  renderProdField() {
    const { editing_prod, prod_unsaved } = this.state;

    if (editing_prod) {
      return (
        <div className="total-column">
          <input
            style={{
              maxWidth: "100%",
              border: "1px solid #CCCCCC",
              borderRadius: 4,
            }}
            type="number"
            name="supplier_production_cost"
            value={prod_unsaved}
            onChange={e =>
              this.setState({
                prod_unsaved: e.target.value,
              })
            }
            ref={this.setProdRef}
          />
        </div>
      );
    }

    return (
      <div className="total-column" onClick={this.onProdClick}>
        {this.getFormattedTotal("supplier_production_cost")}
      </div>
    );
  }

  renderInstallField() {
    const { editing_install, install_unsaved } = this.state;

    if (editing_install) {
      return (
        <div className="total-column">
          <input
            style={{
              maxWidth: "100%",
              border: "1px solid #CCCCCC",
              borderRadius: 4,
            }}
            type="number"
            name="supplier_installation_cost"
            value={install_unsaved}
            onChange={e =>
              this.setState({
                install_unsaved: e.target.value,
              })
            }
            ref={this.setInstallRef}
          />
        </div>
      );
    }

    return (
      <div className="total-column" onClick={this.onInstallClick}>
        {this.getFormattedTotal("supplier_installation_cost")}
      </div>
    );
  }

  render() {
    return (
      <div id="campaign_supplier_contract_totals">
        <div className="totals-row">
          <div className="total-header">Total</div>
          {this.renderPriceField()}
          {this.renderProdField()}
          {this.renderInstallField()}
        </div>
        <div className="campaign-totals-row">
          <div className="total-header" style={{ color: "#4A90E2" }}>
            Total
          </div>
          <div className="total-column">{this.campaignTotal()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({ campaign });
export default connect(mapStateToProps, {})(PriceAdjustment);
