import { del, get, post, put } from "../../../utils/api";

export const getAttachments = async campaign_id => {
  const { attachments } = await get(`/api/v1/campaigns/${campaign_id}/campaign_attachments`);
  return attachments;
};

export const createFulfilllmentAttachment = async (campaign_id, payload) => {
  const fulfillment_payload = {
    ...payload,
    source: "fulfillment_attachment",
  };
  const { attachment } = await post(`/api/v1/campaigns/${campaign_id}/campaign_attachments`, {
    attachment: fulfillment_payload,
  });
  return attachment;
};

export const createAttachment = async (campaign_id, payload) => {
  const { attachment } = await post(`/api/v1/campaigns/${campaign_id}/campaign_attachments`, { attachment: payload });
  return attachment;
};

export const destroyAttachment = async (campaign_id, attachment_id) => {
  await del(`/api/v1/campaigns/${campaign_id}/campaign_attachments/${attachment_id}`);
};

export const downloadAttachments = async (campaign_id, attachment_ids) => {
  const { job_id } = await get(
    `/api/v1/campaigns/${campaign_id}/campaign_attachments/download_zip?ids=${attachment_ids}`,
  );
  return job_id;
};

export const updateAttachment = async (campaign_id, attachment_id, payload) => {
  await put(`/api/v1/campaigns/${campaign_id}/campaign_attachments/${attachment_id}`, payload);
};
