import param from '../../utils/objectToQuery';
import { post } from "../../utils/api";


const SUPPLIER_TASK_API_URL = "/api/v1/supplier_tasks";
const RFPS_API_URL = "/api/v1/rfps";

const prefix = "requests/index";

export const FETCHING = "FETCHING";
export const FETCH_CAMPAIGNS_WITH_OPEN_REQUESTS = "FETCH_CAMPAIGNS_WITH_OPEN_REQUESTS";
export const FETCH_CAMPAIGNS_WITH_COMPLETED_REQUESTS = "FETCH_CAMPAIGNS_WITH_COMPLETED_REQUESTS";
export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
const action_by_type = {
  open: FETCH_CAMPAIGNS_WITH_OPEN_REQUESTS,
  completed: FETCH_CAMPAIGNS_WITH_COMPLETED_REQUESTS,
  booked: FETCH_BOOKINGS
};
export const fetchCampaignsWithSupplierTasks = (status, show_loading = true) => (dispatch, getState) => {
  if (show_loading) {
    dispatch({ type: FETCHING });
  }

  const { filters } = getState().adquick_requests_ui.index[status];
  const url = `/api/v1/campaigns/supplier_tasks.json?${param(filters)}&status=${status}`;

  $.get(url).then(response => {
    dispatch({
      type: action_by_type[status],
      payload: {
        total_count: response.total_count,
        total_pages: response.total_pages,
        results: response.results,
      }
    })
  })
}

export const REOPEN_SUPPLIER_TASK = `${prefix}/REOPEN_SUPPLIER_TASK`;
export const reopenSupplierTask = (supplier_task_id) => async (dispatch, getState) => {
  try {
    const supplier_task = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/reopen`);

    dispatch({ type: REOPEN_SUPPLIER_TASK, payload: supplier_task })
    return supplier_task;
  } catch (error) {
    console.log("Reopen SupplierTask failed.", error);
    throw error;
  }
};

export const DECLINE_SUPPLIER_TASK = `${prefix}/DECLINE_SUPPLIER_TASK`;
export const declineSupplierTask = (supplier_task_id, declined_reason) => async (dispatch, getState) => {
  try {
    const supplier_task = await post(`${SUPPLIER_TASK_API_URL}/${supplier_task_id}/decline`, { declined_reason });

    dispatch({ type: DECLINE_SUPPLIER_TASK, payload: { supplier_task, declined_reason } })
    return supplier_task;
  } catch (error) {
    console.log("Decline SupplierTask failed.", error);
    throw error;
  }
};

export const SEND_RFP_REMINDER = `${prefix}/SEND_RFP_REMINDER`;
export const sendReminder = (supplier_task_id) => async (dispatch, getState) => {
  try {
    const supplier_task = await post(`${RFPS_API_URL}/${supplier_task_id}/reminders`);

    dispatch({ type: SEND_RFP_REMINDER, payload: supplier_task })
    return supplier_task;
  } catch (error) {
    console.log("Send RFP reminders failed.", error);
    throw error;
  }
}
