import React from 'react';
import createClass from 'create-react-class';

import AuthStore from '../../../stores/AuthStore';
import GlobalActions from '../../../actions/GlobalActions';
import Shares from './Shares'
import { post } from '../../../utils/api'

export default createClass({
  displayName: 'Campaign/Share',

  getInitialState() {
    return {
      auth: AuthStore.getState(),
      saving: false,
      errors: '',
      email: '',
      message: '',
      includeAttachments: false,
      shares: []
    };
  },

  componentDidMount() {
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange),
    ];

    this.loadShares()
  },

  componentWillUnmount() {
    this.unsubscribeList.map(fn => fn());
  },

  onAuthChange() {
    this.setState({ auth: AuthStore.getState() });
  },

  loadShares() {
    const { token } = this.props.data.campaign
    $.get(`/api/v1/campaigns/${token}/shares`).then(shares => this.setState({ shares }))
  },

  validateRecipientEmail(email) {
    const regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/
    const isEmail = regex.test(email)
    if(email.length < 1) { return Promise.reject(this.emailErrorMessage('empty')) }
    if(!isEmail) { return Promise.reject(this.emailErrorMessage('invalid')) }
    return Promise.resolve(email)
  },

  validateMessage(message) {
    if (message.length < 1) { return Promise.reject({ message: 'Please enter a message.' }) }
    return Promise.resolve(message)
  },

  emailErrorMessage(error) {
    const output = {
      empty: "Please enter the recipient's email.",
      invalid: "Please enter a valid email address."
    }
    return { message: output[error] }
  },

  async onSubmit(e) {
    e.preventDefault();
    this.setState({ saving: true });

    const { campaign } = this.props.data;
    const { email, message, includeAttachments } = this.state;
    const params = {
      share: {
        recipient_email: email,
        message,
        include_excel_attachment: includeAttachments
      },
      shareable_type: 'Campaign',
      shareable_id: campaign.token,
    };

    try {
      await this.validateRecipientEmail(email)
      await this.validateMessage(message)
      const response = await post('/api/v1/shares', params)
      this.setState({ share: response.share, saving: false, email: '', message: '' });
      GlobalActions.showMessage(`We emailed the campaign to ${response.share.recipient_email}.`);
      this.loadShares();
      this.setState({ errors: null });
    } catch (error) {
      console.log(error);
      this.setState({ saving: false, errors: error.message },() => { GlobalActions.showMessage(error.message) });
      if (DEBUG) console.log(error);
    }
  },

  render() {
    const { saving, shares } = this.state;
    const { user } = this.state.auth;
    const is_supplier = user && user.is_supplier;
    const can_view_shared_with = this.props.data.permissions.can_view_shared_with

    return (
      <div>
        {saving && <div className="searchTab__loading" />}
        {!saving && <form onSubmit={this.onSubmit}>
          {this.state.errors && <div className="message message_error">{this.state.errors}</div>}
          <div style={{ marginBottom: 10 }}>
            <label>Recipient Email</label>
            <input value={this.state.email} className="form-control" type="input" placeholder="Email" onChange={(e) => { this.setState({ email: e.target.value.trim() }); }} style={(this.state.errors && this.state.errors.match(/email/) ? { borderColor: 'red' } : {})} />
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Message</label>
            <textarea value={this.state.message} style={{ height: 150 }} className="form-control" type="input" placeholder="Write a message" onChange={(e) => { this.setState({ message: e.target.value }); }} />
          </div>
          {is_supplier && <div style={{ marginBottom: 10 }}>
            <label>
              <input value={this.state.includeAttachments} type="checkbox" placeholder="Subject" onChange={e => this.setState({ includeAttachments: e.target.checked })} style={{marginRight:10}}/>
              Include spreadsheet as attachment
            </label>
          </div>}
          <div style={{ marginBottom: 0 }}>
            <button className="btn btn-primary" type="submit" disabled={saving} onClick={this.onSubmit}>Send</button>
            <div className='btn btn-default' onClick={this.props.onHide} style={{ float: 'right' }}>Close</div>
          </div>
        </form>}
        { can_view_shared_with && shares.length > 0 && <Shares shares={shares} />}
      </div>
    );
  },
});
