import { SET_CURRENT_USER } from "./actions";

const initialState = {
  permissions: {},
  is_guest: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      const { currentUser } = action.payload;
      return { ...state, ...currentUser };
    }
    default:
      return state;
  }
};

// selectors
export const getCurrentUser = state => state.currentUser;
export const getSessionId = state => state.sessionId;
