import * as React from 'react';

class DirectResponsesModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const { sms_default_response, sms_keywords } = props.direct_response
    this.state = {
      sms_keywords: sms_keywords || '',
      sms_default_response: sms_default_response || ''
    }
    this.onSave = this.onSave.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  onInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  smsResponseLength() {
    return this.state.sms_default_response.length;
  }

  async onSave() {
    const { sms_keywords, sms_default_response } = this.state
    const { oldSave, onSubmit } = this.props
    const payload = { sms_keywords, sms_default_response }
    await oldSave(payload)
    await onSubmit()
  }

  render() {
    return (
      <div className="modal_default">
        <div className="modal_header">
          <h5>Adquick Direct Resonses SMS</h5>
        </div>
        <div className="modal_body">
          <div className="field">
            <label>Keywords</label>
            <div className="description">
              Let us know your requested keywords. Separate multiple keywords with commas.
            </div>
            <input
              type="text"
              className="form-control"
              name="sms_keywords"
              value={this.state.sms_keywords}
              onChange={this.onInputChange}
              placeholder='e.g. "fitness"'
            />
          </div>
          <div className="field">
            <label>Automatic Response Message</label>
            <div className="description">
              160 character limit. ({this.smsResponseLength()}/160)
            </div>
            <textarea
              className="form-control"
              name="sms_default_response"
              value={this.state.sms_default_response}
              onChange={this.onInputChange}
              placeholder='e.g. a link to claim the offer'
              maxLength={160}
            />
          </div>
          <div className="modal_footer">
            <button className="primary-button" onClick={this.onSave}> Close</button>
          </div>
        </div>
      </div>
    )
  }
}

export default DirectResponsesModal;