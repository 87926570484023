import * as React from "react";
import { connect } from "react-redux";
import ToDos from "./ToDos";
import SupplierFilter from "./SupplierFilter";
import ExpiringHoldsModal from "./ExpiringHoldsModal";
import PopsOutstandingModal from "./PopsOutstandingModal";
import StaleAvailability from "./StaleAvailability";
import UnitsMissingImages from "./UnitsMissingImages";
import ProfileCompleteness from "./ProfileCompleteness";
import UpcomingFlights from "./UpcomingFlights";
import Charts from "./Charts";

class VendorDashboard extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentUser } = this.props;

    if (
      currentUser &&
      (currentUser.is_admin || (currentUser.permissions && currentUser.permissions.can_view_supplier_dashboard))
    ) {
      return (
        <div id="vendor_dashboard">
          <ExpiringHoldsModal />
          <PopsOutstandingModal />
          <SupplierFilter />
          <ToDos />
          {(currentUser.is_admin || currentUser.is_managed_supplier) && <StaleAvailability />}
          <UnitsMissingImages />
          <ProfileCompleteness />
          <UpcomingFlights />
          {(currentUser.is_admin || currentUser.is_managed_supplier) && <Charts />}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default connect(
  // @ts-ignore
  ({ currentUser }) => ({ currentUser }),
  {},
)(VendorDashboard);
