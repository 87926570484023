import cs from "classnames";
import _ from "lodash";
import React from "react";

import MaintenanceLogs from "../Inventory/Unit/MaintenanceLogs";
import StatusBadge from "../StatusBadge";
import AdminLeaseSubtab from "./AdminLeaseSubtab";

export default class AdminTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      active: "maintenance",
    };
  }

  renderTabs() {
    const { tabs, active } = this.state;
    return (
      <ul className="tabs">
        <li
          className={cs({ item_active: "maintenance" === active })}
          onClick={() => this.setState({ active: "maintenance" })}
        >
          Maintenance
        </li>
        <li
          style={{ marginLeft: 10 }}
          className={cs({ item_active: "lease" === active })}
          onClick={() => this.setState({ active: "lease" })}
        >
          Lease
        </li>
      </ul>
    );
  }

  renderMaintenanceTab() {
    const { unit } = this.props;
    const section_label_style = {
      fontWeight: 600,
      fontSize: 14,
      color: "#333333",
      margin: "30px 0px 12px 24px",
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div style={section_label_style}>Unit Status</div>
            <StatusBadge status={unit.status} style={{ marginLeft: 24 }} />
          </div>
          <div className="col-md-6">
            <div style={section_label_style}>Fault Reason</div>
            {unit.fault_reason && (
              <div
                style={{
                  marginLeft: 24,
                  background: "#F5F5F5",
                  borderRadius: 4,
                  height: 32,
                  maxWidth: "83%",
                  padding: "7px 8px",
                }}
              >
                {unit.fault_reason}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={section_label_style}>Maintenance Notes</div>
            {unit.maintenance_notes && <div style={{ marginLeft: 24, paddingRight: 70 }}>{unit.maintenance_notes}</div>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={section_label_style}>Maintenance Logs</div>
            <MaintenanceLogs unit={unit} style={{ margin: 35 }} />
          </div>
        </div>
      </div>
    );
  }

  renderCurrentTab() {
    const { active } = this.state;
    const { unit } = this.props;
    if (active === "maintenance") {
      return this.renderMaintenanceTab();
    } else if (active === "lease") {
      return <AdminLeaseSubtab unit={unit} />;
    }
  }

  render() {
    return (
      <div className="admin_tab">
        <div className="admin_subtabs">{this.renderTabs()}</div>
        {this.renderCurrentTab()}
      </div>
    );
  }
}
