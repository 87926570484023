import React from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { connect } from "react-redux";
import { updateMarket } from '../../actions/current_market_actions'
import { loadAllUnits } from '../../actions/UnitsActions'

class LocationInput extends React.Component {

  constructor(props) {
    super(props);

    this.geocoderRef = React.createRef();
    this.state = {
      value: this.props.value
    };
  }

  componentDidMount() {
    if (document.location.pathname == '/') {
      this.geocoderRef.current.focus();
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { campaignId } = this.props

    if (typeof this.mapboxGeocoder === 'undefined' && this.props.mapbox !== undefined) {
      const mapboxAccessToken = AppConfig.mapboxAccessToken;
      this.mapboxGeocoder = new MapboxGeocoder({ accessToken: mapboxAccessToken, placeholder: this.props.placeholder, mapboxgl: window.mapboxgl });
      this.geocoderRef.current.appendChild(this.mapboxGeocoder.onAdd(this.props.mapbox.map));
      this.mapboxGeocoder.on("result", async (r) => {
        if (!!campaignId) { return }
        if (typeof r.result.center === 'undefined') {
          return
        }

        await this.props.updateMarket([r.result.center[0], r.result.center[1]])
        await this.props.loadAllUnits([r.result.center[0], r.result.center[1]])
      })
    }
  }

  render() {
    return (
      <div className="search-container">
        <div className="geocoder" ref={this.geocoderRef} />
      </div>
    );
  }
};

const mapStateToProps = ({ campaign: { campaignId} }) => ({
  campaignId,
});

export default connect(
  mapStateToProps,
  { updateMarket, loadAllUnits },
)(LocationInput);
