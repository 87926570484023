import axios from "axios";
import React from "react";
import { connect } from 'react-redux';
import createClass from "create-react-class";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import GlobalActions from "../../actions/GlobalActions";
import PlacesStore from "../../stores/PlacesStore";
import JobProgress from '../../utils/JobProgress';
import { loadCampaignUnits } from './actions';
import ZipCodesInput from '../ZipCodesInput';

const AddUnitsByZipCodeModal = createClass({
  propTypes: {
    campaign: PropTypes.string.isRequired
  },

  getInitialState() {
    return {
      zip_codes: "",
      available_units_only: false,
      job_pooling: new JobProgress(),
      saving: false,
    };
  },

  async onSubmit(e) {
    e.preventDefault();
    const { units } = this.props;
    const unit_filter = units.unit_filter || {};
    const { zip_codes, available_units_only } = this.state;
    const params = { zip_codes, available_units_only, filters: unit_filter };
    this.setState({ saving: true });

    axios.post(`/api/v1/campaigns/${this.props.campaign}/add_units_by_zip_codes`, params).then(({ data }) => {
      this.state.job_pooling.startPolling(data.job_id, this.onJobComplete);
    }).catch((error) => {
      const { response } = error;
      if (response.status === 409){
        GlobalActions.showMessage(response.data.error, "warning", true);
      } else {
        GlobalActions.showError("Something unexpected happened");
      }
      this.setState({ saving: false });
    });
  },

  onJobComplete(response) {
    const { data } = response;
    const { error } = data;
    if (error) {
      this.setState({ saving: false });
      return GlobalActions.showError(error)
    }
    const addedCount = data.added_count;
    this.setState(this.getInitialState());
    if (addedCount > 0) {
      GlobalActions.showMessage(`Added ${addedCount} units within the given zip code(s).`);
      PlacesStore.onAddNearbyUnits();
      this.props.loadCampaignUnits(this.props.campaign);
    } else {
      GlobalActions.showMessage('No units to add within the given zip code(s). Try a different one.', 'info');
    }
    this.props.onHide();
  },

  boltIcon() {
    return <span class="fa-stack small" style={{fontSize: '0.5em'}}>
      <i style={{verticalAlign: 'middle', color: '#F5A623'}} class="fas fa-circle fa-stack-2x"></i>
      <i style={{verticalAlign: 'middle'}} class="fas fa-bolt fa-stack-1x fa-inverse"></i>
    </span>
  },

  instantBookInput() {
    if (!this.props.userPermissions.can_see_instant_book_inputs) { return }
    return <div className="form-group" style={{ paddingRight: 10, paddingTop: 20 }}>
      <input type="checkbox" checked={this.state.available_units_only} onChange={(e) => { this.setState({ available_units_only: !!!this.state.available_units_only }); }} />
      <label style={{marginLeft: 5 }}>Only include <strong> {this.boltIcon()} Instant Book </strong> units</label>
    </div>
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header>
          <div style={{fontWeight: 600, fontSize: '16px', marginLeft: '48px', marginRight: '48px'}}>Add Units By Zip Code</div>
        </Modal.Header>
        <Modal.Body>
          {this.state.saving ? (
            <div className="searchTab__loading" style={{ margin: "auto" }} />
          ) : (
              <form onSubmit={this.onSubmit}>
                <ZipCodesInput
                  zip_codes={this.state.zip_codes}
                  onChange={zip_codes => this.setState({ zip_codes })}
                />

                {this.instantBookInput()}

                <div className="alert alert-warning" style={{ fontWeight: 400, fontSize: '12px', marginTop: '10px' }}>
                  * uses currently enabled search filters
              </div>
              </form>
            )}
        </Modal.Body>
        <Modal.Footer>
          <a onClick={this.props.onHide} className="btn btn-link">Cancel</a>
          <button style={{backgroundColor: '#4A90E2'}} disabled={this.state.saving} className="btn btn_primary" type="submit" onClick={this.onSubmit}>Add</button>
        </Modal.Footer>
      </Modal>
    );
  }
});

export default connect(({ units }) => ({ units }), { loadCampaignUnits })(AddUnitsByZipCodeModal);
