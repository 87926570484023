import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import UploadButton from "../UploadButton";
import { contractCampaign } from "../Inventory/actions";

class ContractCampaign extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      file_name: null,
      file_url: null,
      contract: null,
      title: null,
      errors: {},
      submitting: false
    };
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onUploadComplete(upload, file) {
    const { errors } = this.state;

    this.setState({
      file_url: file.url,
      file_name: file.name,
      errors: { ...errors, file_name: null }
    });
  }

  validate() {
    const { file_name, file_url, title } = this.state;
    const errors = {
      file_name: file_name ? null : 'Please Upload a Contract',
      title: title ? null : 'Contract Name is Required'
    };

    this.setState({ errors });

    if (errors.file_name || errors.title){
      return false;
    } else {
      return true;
    }
  }

  onSubmit() {
    const { contractCampaign, campaign_token, onHide } = this.props;
    const { file_name, file_url, title } = this.state;
    if (this.validate()) {
      this.setState({ submitting: true })
      const contract_params = { contract: { file_url, file_name, title } };
      contractCampaign(campaign_token, contract_params);
    };
  }

  render() {
    const { show, onHide } = this.props;
    const { file_name, errors, submitting } = this.state;
    return (
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header>
          <Modal.Title>Upload Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Contract Name</label>
                {(errors.title) && <span style={{color: 'red'}}>
                  {` * ${errors.title}`}
                </span>}
                <div className="col-10">
                  <input
                    className="form-control"
                    onChange={e => this.setState({ title: e.target.value, errors: {...errors, title: null} })}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <label className="col-2 col-form-label">Upload Contract</label>
                  {(errors.file_name) && <span style={{color: 'red'}}>
                    {` * ${errors.file_name}`}
                  </span>}
                  <div className="col-10">
                    <div style={{ width: 150, float: "left" }}>
                      <UploadButton onComplete={this.onUploadComplete}>
                        <button type="button" className="btn btn-default">
                          <i className="fa fa-upload" style={{ marginRight: 7.5 }} />
                          Upload Contract
                        </button>
                      </UploadButton>
                    </div>
                    <div style={{ marginTop: 5, marginLeft: 10, float: "left" }}>{file_name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" type="submit" disabled={submitting} onClick={this.onSubmit}>
            Upload
          </button>
          <button className="btn btn-default" type="submit" onClick={onHide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default connect(
  ({}) => ({}),
  { contractCampaign }
)(ContractCampaign);
