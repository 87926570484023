import { get } from "../../../utils/api";

export const FETCH_QA_ISSUES_SUCCESS = 'FULFILLMENT.FETCH_QA_ISSUES_SUCCESS';

export const fetchQaIssues = (campaign_id, is_managed) => async (dispatch, getState) => {
  let url
  if (is_managed) {
    url = `/managed_vendor/${campaign_id}/qa_issues`
  } else {
    url = `/api/v1/campaigns/${campaign_id}/fulfillment_dashboard/qa_issues`
  }
  const { issues, total_issues_count } = await get(url)
  dispatch({ type: FETCH_QA_ISSUES_SUCCESS, payload: {issues, total_issues_count} })
}
