import React from 'react';
import { browserHistory } from 'react-router-legacy';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import AuthStore from '../../stores/AuthStore'
import GlobalActions from '../../actions/GlobalActions'
import { formatMoney } from 'accounting'
import moment from 'moment'
import Company from './Company'
import Group from '../CompanyGroups/Group'
import { BootstrapTable } from 'react-bootstrap-table';
import param from '../../utils/objectToQuery';
import queryString from 'query-string';
import * as Actions from './actions'
import { connect } from 'react-redux';
import Loading from '../Loading';

const Companies = createClass({
  displayName: 'Companies',

  getInitialState() {
    return {
      isLoggedIn: AuthStore.isLoggedIn(),
      user: AuthStore.getState().user,
      companies: [],
      filters: {},
      query: '',
      group_id: '',
      loading: true
    }
  },

  componentDidMount() {
    const { getGroups } = this.props
    this.unsubscribeList = [
      AuthStore.listen(this.onAuthChange)
    ]

    getGroups()

    const { company_group_id } = this.props.location.query
    if (company_group_id) {
      $.get(`/api/v1/company_groups/${company_group_id}`).then(company_group => {
        this.openGroup(company_group)
      })
    } else {
      this.fetchCompanies()
    }
  },

  onAuthChange() {
    this.setState({ isLoggedIn: AuthStore.isLoggedIn() })

    if (AuthStore.isLoggedIn()) {
      this.fetchCompanies()
    } else {
      GlobalActions.openPopup('login')
    }
  },

  setCompany(company) {
    browserHistory.push(`/businesses/${company.id}`)
  },

  fetchCompanies() {
    const { getCompanies } = this.props
    const { filters, query, group_id } = this.state
    let url = '/api/v1/businesses?'
    if (this.state.companyId) {
      url += `&company_id=${this.state.companyId}`
    }
    if (filters.first_degree_only) {
      url += `&first_degree_only=${filters.first_degree_only}`
    }
    url += `&group_id=${group_id}`
    url += `&q=` + encodeURI(query)

    this.setState({ loading: true })
    getCompanies(url)
    this.setState({ loading: false })
  },

  fetchCompanyGroups() {
    $.get('/api/v1/company_groups').then(company_groups => this.setState({ company_groups }))
  },

  toggleFilter(name) {
    let { filters } = this.state
    this.setFilter(name, filters[name] ? false : true)
  },

  setFilter(name, value) {
    let { filters } = this.state
    filters[name] = value
    this.setState({ filters }, this.fetchCompanies)
  },

  openGroup(group) {
    browserHistory.push(`/businesses?company_group_id=${group.id}`)

    if (group.id == this.state.group_id) {
      this.setState({
        group_id: '',
        group: null
      }, () => this.fetchCompanies())
    } else {
      this.setState({
        group_id: group.id,
        group,
      }, () => this.fetchCompanies())
    }
  },

  onSubmit(e) {
    const { query } = this.state
    this.fetchCompanies()
    e.preventDefault()
  },

  onCreateGroupClicked() {
    browserHistory.push('/company_groups/new')
  },

  renderCompanyGroups() {
    const { companies } = this.props
    return (
      <div>
        <b>Groups</b>
        <ul>
          {companies.company_groups.map((group, idx) => (
            <li
              key={idx}
              onClick={() => this.openGroup(group)}
              style={{cursor:'pointer',paddingBottom:2.5,marginBottom:2.5,borderTop:'1px solid #eee',display:'flex',justifyContent:'space-between'}}
            >
              {group.id == this.state.group_id ? "* " : ''}
              {group.name}
              <div>
                <div onClick={() => browserHistory.push(`/company_groups?id=${group.id}`)}>
                  <i className="fa fa-pencil" />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <button className="btn btn-default" onClick={this.onCreateGroupClicked}>
          Create Group
        </button>
      </div>
    )
  },

  renderCompanyList() {
    const { companies }  = this.props
    const { loading, query, group_id } = this.state

    if (companies.loading) {
      return <Loading/>
    }

    return (
      <div>
        <form className="row" style={{marginBottom:10}} onSubmit={this.onSubmit}>
          <div className="col-md-6">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search for company or person..."
                onChange={e => this.setState({query: e.target.value})}
                value={query}
              />
              <span className="input-group-btn">
                <button className="btn btn-default">Search</button>
              </span>
            </div>
          </div>
          {/*<div className="col-md-6">
            LinkedIn Token
            <i className="fa fa-check color-green" />
          </div>*/}
        </form>

        {group_id &&
          <Group hideDelete showProgressButton group={companies.company_groups.find(g => g.id === group_id)} companies={companies.companies} scrape={companies.scrape} />
        }

        <BootstrapTable
          data={companies.companies}
          striped
          hover
          options={{
            onRowClick: (company) => this.setCompany(company)
          }}>
          <TableHeaderColumn isKey dataField="id" hidden>Company ID</TableHeaderColumn>
          <TableHeaderColumn dataField="name" dataSort>Name</TableHeaderColumn>
          <TableHeaderColumn dataField="last_linkedin_scrape_at" dataSort>Last Scrape</TableHeaderColumn>
          <TableHeaderColumn dataField="domain" dataSort>Domain</TableHeaderColumn>
          <TableHeaderColumn dataField="linkedin_profiles_count" dataSort dataFormat={value => value || 0}>Profiles Count</TableHeaderColumn>
          <TableHeaderColumn dataField="first_degree_connections_count" dataSort width='180px' >1st Degree</TableHeaderColumn>
          <TableHeaderColumn dataField="second_degree_connections_count" dataSort width='180px' >2nd Degree</TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  },

  render() {
    const { isLoggedIn } = this.state

    const companyStyle = {
      marginBottom: 10,
      border: '1px solid #eee',
      padding: 5,
      fontSize: 15,
    }

    if (isLoggedIn) {
      const companyId = this.props.params.company_id

      return (
        <div className="create-campaign linkedin-tool" style={{marginLeft:20,marginRight:20}}>
          {companyId && (
            <Modal
              show={true}
              onHide={() => browserHistory.push('/businesses')}
              animation={false}
              dialogClassName="modal-linkedin-company"
            >
              <Modal.Body>
                <Company companyId={companyId} />
              </Modal.Body>
            </Modal>
          )}

          <h2>Companies</h2>
          <hr />

          <div className="row">
            <div className="col-md-2">
              {this.renderCompanyGroups()}
            </div>

            <div className="col-md-10">
              {this.renderCompanyList()}
              <p style={{ color: '#ccc', fontSize: 10, marginTop: 10 }}>
                Limited to 1000 results
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center" style={{ padding: 50 }}>Please login</div>
      )
    }
  }
})

const mapProps = ({ companies }) => ({
  companies
})

const mapActions = {
  getGroups: Actions.asyncFetchGroups,
  getCompanies: Actions.asyncFetchCompanies
}

export default connect(mapProps, mapActions)(Companies);
