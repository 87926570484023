import React, { PureComponent } from 'react'
import moment from 'moment'
import GlobalActions from "../../actions/GlobalActions";

export default class BookByWarning extends PureComponent {
  constructor(props) {
    super(props)
  }

  renderToExpireMessage(dayDiff, endDate) {
    const bookToday = 'Today is the last day to book'
    const daysToBook = `${dayDiff} ${dayDiff >= 2 ? 'days' : 'day'} left to book`
    const message = dayDiff > 0 ? daysToBook : bookToday
    return <span>
      <p>
        <b>{`${message}`}</b>
        <span className="detail"> — after that, this inventory may be unavailable.</span>
      </p>
      <p>
        <span><b>We recommend booking by {`${endDate.format('M/D')}`}</b> to secure your placement. Availability for OOH inventory can change quickly. Please note that these units are not on hold.</span>
      </p>
    </span>
  }

  renderMessage() {
    const { campaign } = this.props;
    const endDate = moment(campaign.book_by);
    const now = endDate.isBefore(moment()) ? moment().endOf('days') : moment().endOf('days').subtract(1,'days');
    const dayDiff = Math.ceil(endDate.diff(now,'days',true));
    const expired = dayDiff < 0 ? true : false;

    if(!expired) {
      return {
        message: this.renderToExpireMessage(dayDiff, endDate),
        style: 'info',
      }
    }
    return {
      message: <span><b>This campaign is past its book by date</b><span className="detail"> — we highly recommend finalizing your plan as soon as possible.</span></span>,
      style: 'error'
    }
  }

  render() {
    const message = this.renderMessage()
    GlobalActions.showMessage(message.message, message.style, true);

    return <></>;
  }
}
