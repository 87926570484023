import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formatNumber } from 'accounting';
import _ from 'lodash'
import React from 'react';
import {
  selectCampaign,
  addSpendGroupToCampaign,
  updateNewCampaignName,
  showBudget,
  toggleAddToCampaign,
} from './actions';
import SelectCampaign from '../SelectCampaign';


class AddUnitsFromLocation extends React.Component {

  addSpendGroupToCampaign() {
    const campaign_name = this.props.new_campaign_name || '';
    this.props.addSpendGroupToCampaign(
      campaign_name
    )
  }

  onHide(){
    this.props.add_to_campaign && this.props.toggleAddToCampaign();
    !this.props.spend_group_added_to_campaign && this.props.showBudget();
  }

  renderNewCampaignForm(){
    return (
      <div className='form-group'>
        <label htmlFor='new-campaign-id'>Create a new campaign</label>
        <input
          className='form-control'
          id='new-campaign-id'
          placeholder="Enter the campaign name"
          value={this.props.new_campaign_name}
          onChange={(e) => this.props.updateNewCampaignName(e.target.value)}/>
      </div>
    )
  }

  renderAddtoCampaign(){
    return (
      <div className='form-group'>
        <label>Select a campaign</label>
        <SelectCampaign
          campaign={this.props.campaign}
          onSelectCampaign={(campaign) => this.props.selectCampaign(campaign)} />
      </div>
    )
  }

  renderBody() {
    return (
      <div className="planner-modal-input-body">
      {this.props.add_to_campaign ? this.renderAddtoCampaign() : this.renderNewCampaignForm()}
      </div>
    );
  }

  render() {
    const spend_group = this.props.spend_group_to_add;
    const units_to_add = _.round(this.props.spend_groups.reduce((prev, cur) => {return prev + ( cur.spend / parseInt(cur.location.median_price)) }, 0));
    return (
      <Modal
        show={true}
        onHide={this.onHide.bind(this)}
      >
       <Modal.Header className="text-center" closeButton>
         <div className="modal-unit-adds">
           {this.props.add_to_campaign ? `Add ${units_to_add} units to a campaign` : 'Give your campaign a name and check out the results!'}
         </div>
       </Modal.Header>
        <Modal.Body>
          { this.renderBody()}
          {
            this.props.location_added_to_campaign &&
            <div className="color-green">
              <i className="fa fa-check" />
              Adding units from {spend_group.location.location_name} to the campaign...
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
         <div  className="planner-modal-buttons">
          {
            !this.props.spend_group_added_to_campaign &&
            <button
              onClick={this.addSpendGroupToCampaign.bind(this)}
              className="planner-modal-button"
              disabled={(!this.props.new_campaign_name && !this.props.campaign) || this.props.saving}>
              {this.props.saving_spend_group_to_campaign ? "Saving..." : "Save Campaign"}
            </button>
          }
          </div>
          {
            this.props.spend_group_added_to_campaign &&
            <div  className="planner-modal-buttons">
              <a
                href={`/campaigns/${this.props.campaign.token}`}
                className="planner-modal-button btn btn-default"
                >
                Go To Campaign
              </a>
            </div>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  ({ planner }) => ({ ...planner }),
  { selectCampaign, addSpendGroupToCampaign, updateNewCampaignName, showBudget, toggleAddToCampaign }
)(AddUnitsFromLocation);
