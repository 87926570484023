import mapboxgl from "mapbox-gl";

const CustomPointOfInterestMode = {
  // When the mode starts this function will be called.
  onSetup: function(opts) {

  },
  // support mobile taps
  onTap: function(state, e) {
    this.onClick(state, e);
  },
  // Whenever a user clicks on the map, Draw will call `onClick`
  onClick: function(state, e) {
    new mapboxgl.Marker(this.markerElement()).setLngLat([e.lngLat.lng,e.lngLat.lat]).addTo(this.map)
    this.map.fire('custom_point_of_interest:add', e);
  },

  markerElement() {
    const el = document.createElement('div');
    el.className = 'marker custom_point_of_interest_marker';
    el.style.backgroundImage = `url(${window.PLACEMARKER_ICON_MAP.default})`;
    el.style.backgroundSize = '70%';
    el.style.borderRadius = '15px';
    el.style.borderColor = '#ffffff';
    el.style.borderWidth = '2px';
    el.style.borderStyle = 'solid';
    el.style.width = '30px';
    el.style.height = '30px';
    el.style.backgroundPosition = 'center';
    el.style.backgroundRepeat = 'no-repeat';
    return el;
  },

  onTrash: function(state) {
    this.changeMode("simple_select");
  },
};

export default CustomPointOfInterestMode;
