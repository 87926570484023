import createClass from "create-react-class";
import React from "react";
import { connect } from "react-redux";
import Creatable from "react-select/creatable";

import { addCampaignTag, deleteUnitTag } from "../Campaign/actions";
import GlobalActions from "../../actions/GlobalActions";

const Tags = createClass({
  displayName: "UnitInfo/Tags",

  getInitialState() {
    const { unit } = this.props;
    const currentCampaign = this.props.campaign && this.props.campaign.campaign;
    const possibleTags = currentCampaign
      ? currentCampaign.unit_tags.map(tag => ({ label: tag.tag, value: tag.tag }))
      : [];

    let tags;
    if (unit && unit.tags) {
      tags = unit.tags.map(tag => ({ label: tag.tag, value: tag.tag }));
    } else {
      tags = [];
    }

    return {
      possibleTags,
      tags,
    };
  },

  async onChange(tags) {
    if (tags == null) {
      tags = [];
    }
    const { unit, campaign, addCampaignTag, deleteUnitTag, loadUnit } = this.props;
    const currentCampaign = campaign.campaign;

    const unitTags = unit.tags.map(t => t.tag);
    const inputTags = tags.map(tag => tag.value);

    // tags that are in the input but not in the unit
    const tagsToAdd = inputTags.filter(t => !unitTags.includes(t));

    // tags that are in the unit but not in the input
    const tagsToRemove = unitTags.filter(t => !inputTags.includes(t));

    DEBUG && console.log("tagsToAdd", tagsToAdd, "tagsToRemove", tagsToRemove);

    try {
      let state_tags = this.state.tags;
      for (const tag of tagsToRemove) {
        await deleteUnitTag(tag, unit.id);
        state_tags = state_tags.filter(t => !tagsToRemove.includes(t.label)); // filter out removed tags
      }
      if (tagsToAdd.length > 0) {
        const {new_tags} = await addCampaignTag(currentCampaign.token, {unit_ids: [unit.id], tags: tagsToAdd});
        // add added tags to state
        state_tags = state_tags.concat(new_tags.map(tag => ({label: tag.tag, value: tag.tag})));
      }
      loadUnit();
      this.setState({
        tags: state_tags,
      });
    } catch (e) {
      GlobalActions.showError(e);
    }
  },

  render() {
    const { unit } = this.props;

    return (
      <div className="clearfix" style={{ display: "flex" }}>
        <div style={{ float: "left", marginRight: "5px", width: "100%" }}>
          <Creatable
            placeholder="Add a tag"
            options={this.state.possibleTags}
            value={this.state.tags}
            onChange={this.onChange}
            promptTextCreator={text => `Add tag "${text}"`}
            isMulti
          />
        </div>
      </div>
    );
  },
});

export default connect(() => ({}), { addCampaignTag, deleteUnitTag })(Tags);
