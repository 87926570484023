import { LOAD_SUPPLIER_OPTIONS } from "../actions/supplier_actions";
import { SupplierOption } from "../models/supplier";

interface Action {type: string; payload: any; }

export default(state: SupplierOption[], action: Action): SupplierOption[] => {
  switch (action.type) {
    case LOAD_SUPPLIER_OPTIONS: return action.payload.suppliers;
    default: return state || [];
  }
};
