import React from 'react';
import createClass from 'create-react-class';

export default createClass({
  onAuthorizeAdWords() {
    const token = this.props.campaign.token;
    document.location = `/auth/adwords_access?return_to=/campaigns/${token}/campaign_analytics&campaign_token=${token}`;
  },

  render() {
    return (
      <div className="analytics_section google_adwords">
        <div className="section_header">
          <i className="fa fa-search" aria-hidden="true"></i>
          Google AdWords
        </div>
        <div className="analytics_box">
          <div className="title">
            Google AdWords API Authorization
          </div>

          {this.props.google_adwords_enabled &&
              <div className="alert alert-success">
                Google AdWords has been successfully authorized. Follow the instructions in the email from adwords to finish the integration. For more information <a href="https://adquick.quip.com/GS2LAUFkeV3o">click here</a>.
              </div>
          }
          <div className="description">
            <div className="description_paragraph">
              Quantify the "halo effect"
              driven by outdoor advertising on your Google AdWords campaigns.
            </div>
            <div className="description_paragraph">
              As outdoor advertising impressions drive up your brand's awareness,
              your online campaign can be more efficient.
              CTR and conversion rates can increase, while CPC can decrease.
            </div>
          </div>
          <div className="buttons">
            <div className="analytics_button analytics_button_primary" onClick={this.onAuthorizeAdWords}>
              Authorize Google AdWords
            </div>
          </div>
        </div>
      </div>
    )
  }
});
