import { SET_MARKET } from '../actions/current_market_actions';

type Action = {type: string, payload: number};

export default(state: number | null, action: Action): number | null => {
  switch(action.type) {
      case SET_MARKET: return action.payload || state;
      default: return state || null;
  }
}
