import _ from 'lodash';

export default (state, action) => {
  switch (action.type) {
    case 'DEFAULT': {
      return {
        ...state,
        loading_flight: true,
      };
    }
    case 'DEFAULT_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        loading_flight: false
      };
    }
    case 'UPDATE_FLIGHT_SUCCESS': {
      const { campaign_units_added, conflicts, unit, campaign } = action.payload;
      const { units, campaigns } = state;

      if (campaign && campaign.id) {
        const idx = campaigns.findIndex(c => c.id === campaign.id);
        campaigns[idx] = campaign;
      }

      const new_units = units.map((u) => {
        if (unit && unit.id === u.id) {
          return unit;
        } else {
          return u;
        }
      });

      return {
        ...state,
        campaigns,
        units: new_units,
        campaign_units_added,
        conflicts,
        loading_flight: false
      };
    }
    case 'ADD_FLIGHTS_SUCCESS': {
      const { campaign_units_added, conflicts, units_updated, campaign } = action.payload;
      const { units, campaigns } = state;

      if (campaign && campaign.id) {
        const idx = campaigns.findIndex(c => c.id === campaign.id);
        campaigns[idx] = campaign;
      }

      const new_units = units.map((u) => {
        const idx = units_updated.findIndex((updated) =>
          updated.id === u.id
        );
        if (idx > -1) {
          return units_updated[idx];
        } else {
          return u;
        }
      });

      return {
        ...state,
        campaigns,
        units: new_units,
        campaign_units_added,
        units_updated,
        conflicts,
        loading_flight: false,
      };
    }
    case 'DELETE_FLIGHT_SUCCESS': {
      const updated_unit = action.payload.unit;
      const { units } = state;
      const idx = units.findIndex(u => u.id === updated_unit.id);
      units[idx] = updated_unit;
      const new_units = _.cloneDeep(units);

      return {
        ...state,
        units: new_units,
        loading_flight: false,
      };
    }
    case 'RESET_FLIGHT_FORM': {
      return {
        ...state,
        selectedCampaignId: null,
        campaign: {},
        conflicts: [],
        campaign_units_added: [],
        units_updated: [],
        loading_flight: false,
      };
    }
    default:
      return false;
  }
}
