import * as React from 'react';
import { connect } from 'react-redux';
import {
  updateAdvertiser,
} from './actions';

class Edit extends React.Component<any, any> {

  constructor(props) {
    super(props);

    const advertiser = this.props.advertiser || {};
    this.state = {
      ...advertiser,
      details_updated: false
    };
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.detailsUpdated = this.detailsUpdated.bind(this);
  }

  onUpdateClick(e) {
    const { updateAdvertiser, currentUser } = this.props;
    const { name, address, advertiser_type, url, id, phone, contact_email } = this.state;
    const advertiser = { id, name, address, advertiser_type, url, phone, contact_email };
    updateAdvertiser(advertiser, currentUser);
    this.setState({ details_updated: false });
    e.preventDefault();
  }

  onInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: (value === '' ? null : value)
    }, this.detailsUpdated);
  }

  updateButtonText() {
    const { advertiser_updating } = this.props;

    if (advertiser_updating) {
      return ( <i className="fa fa-cog fa-spin" aria-hidden="true"></i> );
    } else {
      return ('Update');
    }
  }

  detailsUpdated() {
    const { advertiser } = this.props;
    if ((this.state.name !== advertiser.name) ||
        (this.state.address !== advertiser.address) ||
        (this.state.advertiser_type !== advertiser.advertiser_type) ||
        (this.state.phone !== advertiser.phone) ||
        (this.state.contact_email !== advertiser.contact_email) ||
        (this.state.url !== advertiser.url)) {
      this.setState({details_updated: true});
    } else {
      this.setState({details_updated: false});
    }
  }

  render() {
    const errors = this.props.errors || {};
    const { name, address, advertiser_type, url, phone, contact_email, details_updated } = this.state;
    const { advertiser_updating } = this.props;
    const button_disabled = (advertiser_updating || !!!details_updated)

    return (
      <div id="advertiser-edit">
        <label htmlFor="name">
          Name
          {(!!!details_updated && errors.name) &&
            <span className="error details">
              {errors.name.title}
            </span>
          }
        </label>
        <input
          type="text"
          className={"form-control " + ((!!!details_updated && errors.name) && errors.name.title ? "error" : "")}
          id="name"
          name="name"
          placeholder="Name"
          value={name || ''}
          onChange={this.onInputChange}
          disabled={advertiser_updating}
        />

        <label htmlFor="email">
          Email
          {(!!!details_updated && errors.contact_email) &&
            <span className="error details">
              {errors.contact_email.title}
            </span>
          }
        </label>
        <input
          type="text"
          className={"form-control " + ((!!!details_updated && errors.contact_email) && errors.contact_email.title ? "error" : "")}
          id="contact_email"
          name="contact_email"
          placeholder="Email"
          value={contact_email || ''}
          onChange={this.onInputChange}
          disabled={advertiser_updating}
        />

        <label htmlFor="address">
          Address
          {(!!!details_updated && errors.address) &&
            <span className="error details">
              {errors.address.title}
            </span>
          }
        </label>
        <input
          type="text"
          className={"form-control " + ((!!!details_updated && errors.address) && errors.address.title ? "error" : "")}
          id="address"
          name="address"
          placeholder="Address"
          value={address || ''}
          onChange={this.onInputChange}
          disabled={advertiser_updating}
        />

        <label htmlFor="phone">
          Phone
          {(!!!details_updated && errors.phone) &&
            <span className="error details">
              {errors.phone.title}
            </span>
          }
        </label>
        <input
          type="text"
          className={"form-control " + ((!!!details_updated && errors.phone) && errors.phone.title ? "error" : "")}
          id="phone"
          name="phone"
          placeholder="Phone"
          value={phone || ''}
          onChange={this.onInputChange}
          disabled={advertiser_updating}
        />

        <label htmlFor="advertiser_type">
          Advertiser Type
          {(!!!details_updated && errors.advertiser_type) &&
            <span className="error details">
              {errors.advertiser_type.title}
            </span>
          }
        </label>
        <input
          type="text"
          className={"form-control " + ((!!!details_updated && errors.advertiser_type) && errors.advertiser_type.title ? "error" : "")}
          id="advertiser_type"
          name="advertiser_type"
          placeholder="Advertiser type"
          value={advertiser_type || ''}
          onChange={this.onInputChange}
          disabled={advertiser_updating}
        />

        <label htmlFor="url">
          URL
          {(!!!details_updated && errors.url) &&
            <span className="error details">
              {errors.url.title}
            </span>
          }
        </label>
        <input
          type="text"
          className={"form-control " + ((!!!details_updated && errors.url) && errors.url.title ? "error" : "")}
          id="url"
          name="url"
          placeholder="URL"
          value={url || ''}
          onChange={this.onInputChange}
          disabled={advertiser_updating}
        />

        <button
          style={{marginTop: 8}}
          className={`form-control ${button_disabled ? '' : 'btn-success'}`}
          onClick={this.onUpdateClick}
          disabled={button_disabled}
        >
          {this.updateButtonText()}
        </button>
      </div>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ advertisers_ui: {
       advertiser,
       advertiser_updating,
       errors
     },
     // @ts-ignore
     currentUser
   }) => ({
    advertiser,
    advertiser_updating,
    errors,
    currentUser
  }), { updateAdvertiser }
)(Edit);
