import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { isRejected } from "../../../../models/design_assets";
import * as styleGen from "../styles";
import UploadDesignAssetAction from "../UploadDesignAssetAction";

interface ActionProps {
  unit: any;
  campaign_permissions: any;
  campaign_id: string;
}
class Action extends PureComponent<ActionProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { unit, campaign_permissions } = this.props;

    if (isRejected(unit)) {
      if (campaign_permissions.can_upload_design_asset) {
        return this.renderUploadButton();
      }
    }

    return this.renderNoActionButton();
  }

  private renderNoActionButton() {
    return (
      <button className="btn btn-default" disabled={true} style={styleGen.uploadButton()}>
        -
      </button>
    );
  }

  private renderUploadButton(disabled = false) {
    const { unit, campaign_id } = this.props;
    return (
      <UploadDesignAssetAction
        progressBarColor={styleGen.uploadButton().background}
        unit={unit}
        campaign_id={campaign_id}
      >
        <button
          className="btn btn-default"
          style={styleGen.uploadButton()}
          onClick={e => e.preventDefault()}
          disabled={disabled}
        >
          <i className="fa fa-cloud-upload" style={{ marginRight: 5 }} /> Upload
        </button>
      </UploadDesignAssetAction>
    );
  }
}

const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, {})(Action);
