import React from 'react';
import { connect } from "react-redux";

import {
  createAdvertiser,
} from "./actions";

class CreateAdvertiser extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      advertiser: this.props.advertiser || {},
      name: this.props.advertiser ? this.props.advertiser.name : ''
    };
  }

  onNameChange(e) {
    const name = e.target.value;
    this.setState({
      advertiser: {
        name
      },
      name
    })
  }

  onCreateClick(e, user, advertiser, createAdvertiser) {
    createAdvertiser(user, advertiser);
    e.preventDefault();
  }

  render() {
    const { advertiser, name } = this.state;
    const { createAdvertiser, currentUser } = this.props;
    const errors = this.props.errors || {};

    return (
      <div id="advertisers-new">
        <div className="form-box">
          <div className="form_fields">
            <div className="form-group">
              <label htmlFor="name">
                Name
                {errors.name &&
                  <span className="error">
                    {errors.name.title}
                  </span>
                }
              </label>
              <input
                type="text"
                className={"form-control " + (errors.name && errors.name.title ? "error" : "")}
                id="name"
                name="name"
                placeholder="Advertiser Name"
                value={name}
                ref={(input) => this.firstInput = input}
                onChange={e => this.onNameChange(e)}
              />
            </div>
            {(!!!advertiser.name || (advertiser.name && advertiser.name.trim().length === 0)) &&
              <div
                className="submit-button"
                style={{cursor: 'not-allowed', paddingTop: 8}}
              >
                Create Advertiser
              </div>
            }
            {(advertiser.name && advertiser.name.trim().length > 0) &&
              <div
                style={{paddingTop: 8}}
                className="submit-button"
                onClick={(e) => this.onCreateClick(e, currentUser, advertiser, createAdvertiser)}
              >
                Create Advertiser
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ advertisers_ui: { advertiser, errors }, currentUser }) => ({ advertiser, errors, currentUser }),
  { createAdvertiser }
)(CreateAdvertiser);
