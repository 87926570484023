import accounting from "accounting";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import queryString from "query-string";
import Reflux from "reflux";

import NewMapActions from "../actions/NewMapActions";
import { calculateColor, calculateOpacity } from "../components/Map/functions/zipCodeFuncs";
import { get } from "../utils/api";
import objectToQuery from "../utils/objectToQuery";
import CampaignStore from "./CampaignStore";

const API_URL = "/api/v1/maps";
const MAP_API_URL = "/api/v1/map";

export default Reflux.createStore({
  listenables: [NewMapActions],

  initial_state() {
    return {
      price: true,
      faceId: false,
      partitions: {},
      currentPartitions: [],
      units: [],
      zipCodes: {},
      campaign_area: {},
      adwords: {},
      show_transit_layer: true,
      unitsByDate: {},
    };
  },

  init() {
    this.state = this.initial_state();
  },

  reset() {
    this.state = this.initial_state();
  },

  getBounds() {
    if (this.state.bounds) return this.state.bounds;

    if (this.state.is_campaign) {
      const bounds = _.get(CampaignStore.state.campaignResponse, "bounds");

      if (bounds) {
        this.state.bounds = new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(bounds.min_lon, bounds.min_lat),
          new mapboxgl.LngLat(bounds.max_lon, bounds.max_lat),
        );
      } else {
        this.state.bounds = new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(-131.6, 3.3),
          new mapboxgl.LngLat(-64.8, 62.2),
        );
      }
      return this.state.bounds;
    }

    let bounds = queryString.parse(location.search).bounds;
    bounds = bounds && bounds.split(",").map(b => parseFloat(b));

    if (!bounds || (bounds && bounds.length != 4 && bounds.find(b => isNaN(b)))) {
      this.state.bounds = new mapboxgl.LngLatBounds(new mapboxgl.LngLat(-119, 33.7), new mapboxgl.LngLat(-118, 34.3));
    } else {
      this.state.bounds = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(bounds[1], bounds[0]),
        new mapboxgl.LngLat(bounds[3], bounds[2]),
      );
    }
    return this.state.bounds;
  },

  center() {
    return Object.values(this.getBounds().getCenter());
  },

  getState() {
    return this.state;
  },

  onToggleCluster() {
    this.trigger("map:toggleCluster");
  },

  onTogglePrice() {
    this.state.price = !this.state.price;
    this.trigger("map:togglePrice");
  },

  onToggleFaceId() {
    this.state.faceId = !this.state.faceId;
    this.trigger("map:toggleFaceId");
  },

  async onZoomOut() {
    this.trigger("map:zoomOut");
  },

  async onToggleDesignAssets() {
    if (!this.state.campaignID) return;

    if (!this.state.designAssets) {
      this.state.designAssets = await this.loadDesignAssets(this.state.campaignID);
    }
    this.trigger("map:toggleDesignAssets");
  },

  onToggleTransitLayer() {
    this.state.show_transit_layer = !this.state.show_transit_layer;
    this.trigger("map:toggleTransitLayer");
  },

  async loadAdwords(campaign_id) {
    let adwords = this.state.adwords[campaign_id];

    if (!adwords) {
      adwords = (await get(`/api/v1/campaigns/${this.state.campaignID}/adwords_zip_codes`)).adwords;
    }

    const metric = this.state.adwords_metric || "cpc";
    const areas = parseZipCodeAreas(adwords, metric);
    const lines = parseZipCodeLines(adwords);

    return {
      areas: { features: areas, type: "FeatureCollection" },
      lines: { features: lines, type: "FeatureCollection" },
    };
  },

  async hideAdwords(campaign_id) {
    this.state.show_adwords = false;
    this.trigger("map:hideAdwords");
  },

  async showAdwords(campaign_id, metric) {
    this.state.show_adwords = true;
    this.state.adwords_metric = metric;
    this.trigger("map:showAdwords");
  },

  async legacyLoadAdwords(campaign_id) {
    if (campaign_id) this.state.campaignID = campaign_id;
    if (!this.state.campaignID) return;

    if (!this.state.adwords_zip_codes) {
      const { adwords } = await get(`/api/v1/campaigns/${this.state.campaignID}/adwords_zip_codes`);
      this.state.adwords_zip_codes = adwords;
    }

    return this.state.adwords_zip_codes;
  },

  async loadUnits() {
    const partitions = await allPartitions.bind(this)();
    if (!partitions) {
      return false;
    }
    const features = partitions.reduce((acc, units) => acc.concat(units), []);
    this.state.units = { type: "FeatureCollection", features };
    return this.state.units;
  },

  async loadCampaignUnits(campaignID) {
    this.state.campaignID = campaignID;
    const mapData = await get(`/api/v1/campaigns/${campaignID}/map`);
    const features = parseMapData(mapData);
    this.state.units = { type: "FeatureCollection", features };

    this.trigger("map:loadUnitGeojson");
    return this.state.units;
  },

  async loadCampaignUnitGeojsons(units) {
    units = Array.isArray(units) ? units : [];
    const geojsonIds = this.getUniqueGeojsonIds(units);
    const geojsons = this.fetchGeojson(geojsonIds, units);
    return Promise.all(geojsons);
  },

  getUniqueGeojsonIds(units) {
    return _.flow(
      units => units.filter(u => u.geojsonId),
      units => units.map(u => u.geojsonId),
      geojsonIds => _.uniq(geojsonIds),
    )(units);
  },

  fetchGeojson(ids, units) {
    return ids.map(async geoId => {
      const unit = units.find(u => u.geojsonId == geoId);
      try {
        const geojson = await get(`/api/v1/geojsons/${geoId}`);
        return { id: unit._id, geojson };
      } catch (e) {
        console.error("error loading geojson", geoId, e);
        return { id: unit._id, geojson: null };
      }
    });
  },

  async loadCampaignPlacemarkers(campaignID) {
    try {
      this.state.campaignID = campaignID;
      const data = await get(`/api/v1/campaigns/${campaignID}/placemarkers?new_map=true`);
      const features = parsePlacemarkData(data);
      this.state.placemarkers = { type: "FeatureCollection", features };

      return this.state.placemarkers;
    } catch (err) {
      console.error("error fetching campaign placemarkers", err);
    }
  },

  async loadDesignAssets(campaignID) {
    try {
      return get(`/api/v1/campaigns/${campaignID}/map_design_assets`);
    } catch (err) {
      console.error("error loading design assets", err);
      return [];
    }
  },

  async loadZipCodes(campaign_id) {
    try {
      if (this.state.zipCodes[campaign_id]) return this.state.zipCodes[campaign_id];

      const { zip_codes } = await get(`/api/v1/data_layers/highlighted_zip_codes?campaign_id=${campaign_id}`);
      if (!zip_codes || !zip_codes.length) return null;

      const areas = parseZipCodeAreas(zip_codes);
      const lines = parseZipCodeLines(zip_codes);

      this.state.zipCodes[campaign_id] = {
        areas: { features: areas, type: "FeatureCollection" },
        lines: { features: lines, type: "FeatureCollection" },
      };

      return this.state.zipCodes[campaign_id];
    } catch (err) {
      console.error("error loading zip codes", campaign_id, err);
      return;
    }
  },

  async loadCampaignArea(campaign_id) {
    try {
      if (this.state.campaign_area[campaign_id] !== undefined) return this.state.campaign_area[campaign_id];

      const areas = await get(`/api/v1/data_layers/campaign_area?campaign_id=${campaign_id}`);
      this.state.campaign_area[campaign_id] = { areas };

      return this.state.campaign_area[campaign_id];
    } catch (err) {
      console.error("error loading campaign area", campaign_id, err);
      return;
    }
  },

  async filterByDate(start_date, end_date, flight_types, partial_date_match, include_holds) {
    const query = objectToQuery({ start_date, end_date, flight_types, partial_date_match, include_holds }, false);
    let url = `${MAP_API_URL}/filter?${query}`;

    this.state.unitsByDate[query] = this.state.unitsByDate[query] || get(url);
    return this.state.unitsByDate[query];
  },

  async filterByAdvertiser(advertiser) {
    return get(`${MAP_API_URL}/filter?a_id=${advertiser}`);
  },

  async filterByCampaign(campaign) {
    return get(`${MAP_API_URL}/filter?campaign=${campaign}`);
  },

  async filterByOrientation(orientation) {
    return get(`${MAP_API_URL}/filter?orientation=${encodeURIComponent(orientation)}`);
  },

  async onHoldUnits(campaign_id) {
    return get(`${MAP_API_URL}/filter?${objectToQuery({ campaign_id, on_hold: true })}`);
  },
});

async function allPartitions() {
  const parts = await partitions.bind(this)();
  const currentPartitions = parts.map(({ partition }) => partition);
  if (
    _.difference(currentPartitions, this.state.currentPartitions).length ||
    _.difference(this.state.currentPartitions, currentPartitions).length
  ) {
    this.state.currentPartitions = currentPartitions;
    return Promise.all(parts.map(part => loadPartition.call(this, part, parts.length)));
  } else {
    return Promise.resolve(false);
  }
}

async function loadPartition({ partition, cache }, partitionCount) {
  if (this.state.partitions[partition]) return this.state.partitions[partition];

  try {
    const data = await get(`${API_URL}?part=${partition}&cache=${cache}`);
    const parsedData = parseMapData(data);
    this.state.partitions[partition] = parsedData;
    return parsedData;
  } catch (error) {
    console.error(`Error fetching partition ${partition}`, error);
    return [];
  }
}

export async function partitions(b, filter = "") {
  try {
    const query = `bounds[south]=${b.getSouth()}&bounds[east]=${b.getEast()}&bounds[north]=${b.getNorth()}&bounds[west]=${b.getWest()}&unit_filter=${filter}`;
    return await get(`${MAP_API_URL}/partitions?${query}`);
  } catch (error) {
    DEBUG && console.error("error fetching unit keys", error);
    return [];
  }
}

export const colorMap = {
  billboard: "#911884",
  street_furniture: "#3494D3",
  alternative: "#29A495",
  retail: "#29A495",
  sports_venues: "#29A495",
  wallscape: "#C323CF",
  transit: "#462ACF",
  bus: "#462ACF",
  rail: "#462ACF",
  airport: "#462ACF",
  taxi: "#462ACF",
  wildposting: "#32648A",
};

export const unitTypes = [
  "billboard",
  "street_furniture",
  "retail",
  "sports_venues",
  "alternative",
  "wallscape",
  "transit",
  "bus",
  "rail",
  "airport",
  "taxi",
  "wildposting",
];

export const directions = {
  N: "north",
  E: "east",
  S: "south",
  W: "west",
  NE: "northeast",
  NW: "northwest",
  SE: "southeast",
  SW: "southwest",
};

const typeForAcronym = {
  B: "billboard",
  F: "street_furniture",
  R: "retail",
  A: "alternative",
  W: "wallscape",
  T: "transit",
  P: "wildposting",
};

export function parseMapData(data) {
  return data.map(unitData => {
    const version = Array.isArray(unitData[0]) ? 0 : 1;

    const adquick_base_url = "https://adquick.imgix.net/uploads/unit_image/image/";
    const lamar_base_url = "http://apps.lamar.com/inventoryBrowser/photosheetimages/";
    const picture = (unitData[version + 8] || "").replace(/^@A/, adquick_base_url).replace(/^@L/, lamar_base_url);
    const thumbnail_url =
      picture.indexOf(adquick_base_url) !== 0 ? picture : picture.replace(/w=\d+/, "w=50").replace(/h=\d+/, "h=50");

    const type = typeForAcronym[unitData[version + 2]] || unitData[version + 2];
    const coordinates = version === 0 ? unitData[0] : [unitData[0], unitData[1]];
    const campaign_units = unitData[28] && JSON.parse(unitData[28]);

    const price = unitData[version === 0 ? 14 : 4] && parseFloat(unitData[version === 0 ? 14 : 4]);
    const start_date = unitData[23] && moment.utc(unitData[23]);
    const end_date = unitData[24] && moment.utc(unitData[24]);
    const design_asset_due_date = unitData[40] && moment.utc(unitData[40]);
    const hold_expires_at = unitData[25] && moment.utc(unitData[25]);
    const production_cost = unitData[31] && parseFloat(unitData[31]);
    const installation_cost = unitData[32] && parseFloat(unitData[32]);
    const quantity = unitData[37] && parseInt(unitData[37]);

    return {
      id: unitData[version + 1],
      lon: coordinates[0],
      lat: coordinates[1],
      type: type,
      unit_type: type,
      label: unitData[version + 3] === 0 ? undefined : unitData[version + 3],
      color: colorMap[type],
      supplier: unitData[version + 4],
      supplierName: unitData[version + 5],
      supplier_name: unitData[version + 5],
      lime: unitData[version + 5] && unitData[version + 5].toLowerCase() === "lime",
      faceId: unitData[version + 6],
      supplier_face_id: unitData[version + 6],
      cpm: unitData[version + 7] && parseFloat(unitData[version + 7]),
      picture: picture,
      thumbnail_url: thumbnail_url,
      image_file_link: picture,
      size: unitData[version + 9],
      screen_type: unitData[version + 10],
      subtype: unitData[version + 11],
      isPackage: !!unitData[version + 12],
      is_package: !!unitData[version + 12],
      package_id: unitData[version + 12],
      is_package_child: unitData[version + 12] && unitData[version + 12] != unitData[version + 1],
      direction: directions[unitData[version + 13]],
      price,
      price_for_duration: accounting.format(priceForDuration(quantity, price, start_date, end_date), 2, ""),
      total_price_for_duration: accounting.format(
        priceForDuration(quantity, price, start_date, end_date) + (production_cost || 0) + (installation_cost || 0),
        2,
        "",
      ),
      hasRestrictions: !!unitData[15],
      has_restrictions: !!unitData[15],
      is_managed: !!unitData[16],
      name: unitData[17],
      impressions: unitData[18] && parseInt(unitData[18]),
      week_total_impressions: unitData[18] && parseInt(parseInt(unitData[18]) / 4),
      is_cannabis_friendly: unitData[19],
      geojsonId: unitData[20] && parseInt(unitData[20]),
      status: unitData[21],
      supplier_status: unitData[21],
      available: unitData[22] && moment.utc(unitData[22]),
      start_date,
      end_date,
      hold_expires_at,
      is_recommended: !!unitData[26],
      is_favorited: !!unitData[27],
      campaign_units,
      campaign_unit_token: campaign_units && campaign_units[0] && campaign_units[0].id,
      restrictions_description: unitData[29],
      distance_from_point_of_interest: unitData[30] && parseFloat(unitData[30]),
      production_cost,
      installation_cost,
      rate_card_price: unitData[33] && parseFloat(unitData[33]),
      illuminated: unitData[34],
      tab_panel_id: unitData[35],
      _id: unitData[36],
      market_name: unitData[38],
      tags: unitData[39] && JSON.parse(unitData[39]),
      design_asset_due_date,
    };
  });
}

function priceForDuration(quantity, price, start_date, end_date) {
  if (!quantity || !price || !start_date || !end_date) return null;
  const days = moment.duration(end_date.diff(start_date)).asDays() + 1;
  const price_per_day = price / 4 / 7;
  return price_per_day * days * quantity;
}

function parsePlacemarkData(data) {
  const mapData = parseMapData(data);

  return mapData.map(placemarkData => {
    return {
      geometry: {
        type: "Point",
        coordinates: [placemarkData.lon, placemarkData.lat],
      },
      properties: {
        ...placemarkData,
        icon: placemarkData.type || "default",
      },
    };
  });
}

function parseZipCodeAreas(data, metric = "score") {
  const maxScore = Math.max(...data.map(zip_code => zip_code[metric]));
  return data
    .map(zip_code => {
      if (!zip_code.coordinates) return;

      let properties = {
        ...zip_code,
        color: calculateColor(zip_code, maxScore, metric),
        opacity: calculateOpacity(zip_code, maxScore, metric),
      };
      delete properties.coordinates;

      let coordinates = zip_code.coordinates;
      if (!zip_code.coordinates[0] || !zip_code.coordinates[0][0]) {
        console.error("invalid coordinates fro zip code", zip_code);
        coordinates = [];
      } else if (!Array.isArray(zip_code.coordinates[0][0])) {
        coordinates = [[zip_code.coordinates]];
      } else if (!Array.isArray(zip_code.coordinates[0][0][0])) {
        coordinates = [zip_code.coordinates];
      }

      let geojson = {
        type: "Feature",
        properties: properties,
        geometry: {
          type: "MultiPolygon",
          coordinates,
        },
      };
      return geojson;
    })
    .filter(a => a);
}

function parseZipCodeLines(data) {
  return data
    .map(zip_code => {
      if (!zip_code.coordinates) return;
      return {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: zip_code.coordinates,
        },
      };
    })
    .filter(l => l);
}
