import React from "react";
import { connect } from "react-redux";
import createClass from "create-react-class";
import { Modal } from "react-bootstrap";
import GlobalActions from "../../actions/GlobalActions";
import { updateContract } from "../Inventory/actions";

const RequestSignature = createClass({
  displayName: "RequestSignature",

  getInitialState() {
    return {
      saving: false,
      signer_name: '',
      signer_email: '',
      message: ''
    };
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps.current_contract });
  },

  componentWillMount() {
    this.setState({ ...this.props.current_contract });
  },

  onSubmit() {
    const { updateContract, campaign_token, current_contract } = this.props;
    const { signer_name, signer_email, message } = this.state;
    const params = {
      campaign_token,
      payload: {
        message,
        signer_email,
        customer_name: signer_name,
        signer_name,
        id: current_contract.id,
        signature_request: true
      }
    };
    updateContract(params)
  },

  render() {
    const { show, onHide } = this.props;
    const { signer_name, signer_email, message, saving } = this.state;
    return (
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header>
          <Modal.Title>Request E-Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Signer Name</label>
                <div className="col-10">
                  <input
                    className="form-control"
                    value={signer_name}
                    onChange={e => this.setState({ signer_name: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Signer Email</label>
                <div className="col-10">
                  <input
                    className="form-control"
                    value={signer_email}
                    onChange={e => this.setState({ signer_email: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <label>Note</label>
                <div className="col-10">
                  <input
                    className="form-control"
                    value={message}
                    onChange={e => this.setState({ message: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {signer_name && signer_name.length && signer_email && signer_email.length && (
            <button className="btn btn-primary" type="submit" onClick={this.onSubmit}>
              Send Request
            </button>
          )}
          <button className="btn btn-default" type="submit" onClick={onHide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
});

export default connect(
  ({ inventory }) => ({
    contract: inventory.contract,
    conflicts: inventory.conflicts
  }),
  { updateContract }
)(RequestSignature);
