import { del, get, post } from "../utils/api";
import GlobalActions from "../actions/GlobalActions";
import JobProgress from "../utils/JobProgress";

const prefix = "cart";

const jobProgress = new JobProgress();

export const TOGGLE_IN_CART_UNITS = `${prefix}/TOGGLE_IN_CART_UNITS`;
export const IS_ADDING_ALL_TO_CART = `${prefix}/IS_ADDING_ALL_TO_CART`;
export const FAILED_ADDING_ALL_TO_CART = `${prefix}/FAILED_ADDING_ALL_TO_CART`;
export const ADDED_TO_CART = `${prefix}/ADDED_TO_CART`;
export const IS_REMOVING_ALL_FROM_CART = `${prefix}/IS_REMOVING_ALL_FROM_CART`;
export const REMOVED_FROM_CART = `${prefix}/REMOVED_FROM_CART`;

export const LOAD_CART = `${prefix}/LOAD_CART`;
export const TOOGLE_LOADING_CART = `${prefix}/TOOGLE_LOADING_CART`;
export const loadCartUnits = (campaign_id, page = null) => async (dispatch, getState) => {
  const {
    cart: { current_page },
  } = getState();
  const pageToFetch = page || current_page;
  const url = `${campaignCartUrl(campaign_id)}?page=${pageToFetch}`;
  dispatch({ type: TOOGLE_LOADING_CART });
  const res = await get(url);
  dispatch({ type: LOAD_CART, payload: res });
  dispatch({ type: TOOGLE_LOADING_CART });
};

export const addUnitsToCart = (campaign_id, cu_tokens = [], add_all = false) => async dispatch => {
  try {
    if (add_all) {
      dispatch({ type: IS_ADDING_ALL_TO_CART });
    }
    const { unit_ids } = await post(`${campaignCartUrl(campaign_id)}/add`, { cu_tokens, add_all });
    dispatch({ type: ADDED_TO_CART });
    dispatch({ type: TOGGLE_IN_CART_UNITS, payload: { unit_ids, in_cart: true } });
    await dispatch(loadCartUnits(campaign_id));
    await dispatch(getCartTotal(campaign_id));
  } catch (error) {
    dispatch({ type: FAILED_ADDING_ALL_TO_CART });
    GlobalActions.showError(error);
  }
};

export const addUnitToCart = (campaign_id, cu_token = [], start_date = null, end_date = null) => async dispatch => {
  const { unit_ids } = await post(`${campaignCartUrl(campaign_id)}/add`, {
    cu_tokens: [cu_token],
    start_date,
    end_date,
  });
  dispatch({ type: ADDED_TO_CART });
  dispatch({ type: TOGGLE_IN_CART_UNITS, payload: { unit_ids, in_cart: true } });
  await dispatch(loadCartUnits(campaign_id));
  await dispatch(getCartTotal(campaign_id));
};

export const CLEAR_CART = `${prefix}/CLEAR_CART`;
export const removeFromCart = (campaign_id, cu_tokens = [], remove_all = false) => async dispatch => {
  if (remove_all) {
    dispatch({ type: IS_REMOVING_ALL_FROM_CART });
  }
  const { unit_ids } = await del(`${campaignCartUrl(campaign_id)}/remove`, { cu_tokens, remove_all });
  if (remove_all) {
    dispatch({ type: CLEAR_CART, payload: null });
  }
  dispatch({ type: REMOVED_FROM_CART });
  dispatch({ type: TOGGLE_IN_CART_UNITS, payload: { unit_ids, in_cart: false } });
  await dispatch(loadCartUnits(campaign_id));
  await dispatch(getCartTotal(campaign_id));
};

export const FETCHING_CART_TOTAL = `${prefix}/FETCHING_TOTAL`;
const startFetchingTotal = () => async dispatch => {
  dispatch({ type: FETCHING_CART_TOTAL });
};

export const FETCH_CART_TOTAL = `${prefix}/FETCH_TOTAL`;
export const getCartTotal = campaign_id => async (dispatch, getState) => {
  const {
    cart: { isFetchingTotal },
  } = getState();
  if (isFetchingTotal) {
    return;
  }
  await dispatch(startFetchingTotal());
  const response = await get(`${campaignCartUrl(campaign_id)}/total`);
  const job_id = response.job_id;
  if (job_id) {
    jobProgress.startPolling(job_id, response => {
      const { data } = response;
      dispatch({ type: FETCH_CART_TOTAL, payload: { totals: data } });
    });
  } else {
    dispatch({ type: FETCH_CART_TOTAL, payload: { totals: null } });
  }
};

function campaignCartUrl(campaign_token) {
  return `/api/v1/campaigns/${campaign_token}/cart`;
}
