import * as React from 'react';
import {
  fetchMonthRevenueData,
  fetchQuarterRevenueData,
  fetchYearRevenueData,
  fetchAllRevenueData,
  fetchBookingYearOffsets
} from './actions';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { LineChart } from 'react-chartkick'
import moment from 'moment';

class RevenueCharts extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {};
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const {
      selected_supplier,
      booking_year_offsets,
      fetchAllRevenueData
    } = this.props;

    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier)
      fetchBookingYearOffsets(selected_supplier);
    }

    if ( booking_year_offsets && !!!prevProps.booking_year_offsets ||
         booking_year_offsets !== prevProps.booking_year_offsets
       ) {
      booking_year_offsets.forEach((offset) => {
        fetchAllRevenueData(selected_supplier, offset);
      });
    }
  }

  fetchData(supplier) {
    const {
      fetchMonthRevenueData,
      fetchQuarterRevenueData,
      fetchYearRevenueData,
      fetchBookingYearOffsets
    } = this.props;
    fetchBookingYearOffsets(supplier);
    fetchMonthRevenueData(supplier);
    fetchQuarterRevenueData(supplier);
    fetchYearRevenueData(supplier);
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner">
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    )
  }

  renderMonthTab() {
    const { loading_month_revenue } = this.props;
    const month_data = this.monthData();
    return (
      <Tab eventKey="month" title="This Month">
        {(!!!loading_month_revenue && month_data) &&
          <LineChart
            data={month_data}
            prefix="$"
            thousands=","
            round={2}
            zeros={true}
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_month_revenue && this.renderLoadingSpinner()}
      </Tab>
    )
  }

  monthData() {
    const { loading_month_revenue, month_revenue } = this.props;

    if (!!!loading_month_revenue && month_revenue && Object.keys(month_revenue).length > 0) {
      let month_data = {};
      Array.from({length: moment().daysInMonth()},(_,i)=> {
        if (month_revenue[i + 1]) {
          month_data[i + 1] = month_revenue[i + 1].map((day) => {
            return day.amount
          }).reduce((acc, amount) => {
            return acc + amount;
          });
        } else {
          month_data[i + 1] = 0;
        }
      });
      return month_data;
    }
  }

  renderQuarterTab() {
    const { loading_quarter_revenue } = this.props;
    const quarter_data = this.quarterData();

    return (
      <Tab eventKey="quarter" title="This Quarter">
        {(!!!loading_quarter_revenue && quarter_data) &&
          <LineChart
            data={quarter_data}
            prefix="$"
            thousands=","
            round={2}
            zeros={true}
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_quarter_revenue && this.renderLoadingSpinner()}
      </Tab>
    )
  }

  quarterData() {
    const { loading_quarter_revenue, quarter_revenue } = this.props;

    if (!!!loading_quarter_revenue && quarter_revenue && Object.keys(quarter_revenue).length > 0) {
      let quarter_data = {};
      Array.from({length:3},(_,i)=> {
        if (quarter_revenue[i + 1]) {
          quarter_data[i + 1] = quarter_revenue[i + 1].map((month) => {
            return month.amount
          }).reduce((acc, amount) => {
            return acc + amount;
          });
        } else {
          quarter_data[i + 1] = 0;
        }
      });
      return quarter_data;
    }
  }

  renderYearTab() {
    const { loading_year_revenue } = this.props;
    const year_data = this.yearData();

    return (
      <Tab eventKey="year" title="This Year">
        {(!!!loading_year_revenue && year_data) &&
          <LineChart
            data={year_data}
            prefix="$"
            thousands=","
            round={2}
            zeros={true}
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_year_revenue && this.renderLoadingSpinner()}
      </Tab>
    )
  }

  yearData() {
    const { loading_year_revenue, year_revenue } = this.props;

    if (!!!loading_year_revenue && year_revenue && Object.keys(year_revenue).length > 0) {
      let year_data = {};
      Array.from({length:12},(_,i)=> {
        if (year_revenue[i + 1]) {
          year_data[i + 1] = year_revenue[i + 1].map((month) => {
            return month.amount
          }).reduce((acc, amount) => {
            return acc + amount;
          });
        } else {
          year_data[i + 1] = 0;
        }
      });
      return {
        'Jan': year_data[1],
        'Feb': year_data[2],
        'Mar': year_data[3],
        'Apr': year_data[4],
        'May': year_data[5],
        'Jun': year_data[6],
        'Jul': year_data[7],
        'Aug': year_data[8],
        'Sep': year_data[9],
        'Oct': year_data[10],
        'Nov': year_data[11],
        'Dec': year_data[12]
      }
    }
  }

  renderAllTab() {
    const { loading_all_revenue, all_revenue } = this.props;

    return (
      <Tab eventKey="all" title="All Time">
        {(!!!loading_all_revenue && all_revenue) &&
          <LineChart
            data={all_revenue}
            prefix="$"
            thousands=","
            round={2}
            zeros={true}
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_all_revenue && this.renderLoadingSpinner()}
      </Tab>
    )
  }

  render() {
    return (
      <div id="revenue_chart">
        <div className="chart_header">
          Revenue
        </div>
        <div className="chart_area">
          <Tabs defaultActiveKey="month" id="uncontrolled-tab-example">
            {this.renderMonthTab()}
            {this.renderQuarterTab()}
            {this.renderYearTab()}
            {this.renderAllTab()}
          </Tabs>
        </div>
      </div>
    )
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: {
      month_revenue,
      quarter_revenue,
      year_revenue,
      all_revenue,
      loading_month_revenue,
      loading_quarter_revenue,
      loading_year_revenue,
      loading_all_revenue,
      selected_supplier,
      booking_year_offsets,
      loading_booking_year_offsets
    }
  }) => ({
    month_revenue,
    quarter_revenue,
    year_revenue,
    all_revenue,
    loading_month_revenue,
    loading_quarter_revenue,
    loading_year_revenue,
    loading_all_revenue,
    selected_supplier,
    booking_year_offsets,
    loading_booking_year_offsets
  }),
  {
    fetchMonthRevenueData,
    fetchQuarterRevenueData,
    fetchYearRevenueData,
    fetchAllRevenueData,
    fetchBookingYearOffsets
  }
)(RevenueCharts);
