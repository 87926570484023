import * as React from "react";
import styled from "styled-components";
import Theme from "../../theme";

export enum ToolbarSize {
  Auto = "auto",
  Default = "48px",
  Programmatic = "70px",
}

export enum ToolbarShadow {
  Default = "none",
  Primary = "SHADOW_DEFAULT",
  Long = "SHADOW_LONG",
}

export enum ContentAlign {
  Left = "left",
  Right = "right",
  Center = "center",
}

export enum ContentPadding {
  None = 0,
  ForButtons = "8px",
  ForTitle = "15px",
  ForHeadline = "24px",
}

export interface IToolbarContentProp {
  children: React.ReactNode | string;
  area?: string;
  align?: ContentAlign;
  padding?: ContentPadding;
}

export interface IToolbarProps {
  children: React.ReactNode | string;
  height?: ToolbarSize;
  shadow?: ToolbarShadow;
}

const Toolbar = styled.div<IToolbarProps>`
  background: #fff;
  height: ${props => props.height || ToolbarSize.Default};
  box-shadow: ${props => (props.shadow && Theme[props.shadow]) || ToolbarShadow.Default};
  position: relative;
  z-index: 3;
`;

export const Content = ({
  children,
  area,
  align = ContentAlign.Left,
  padding = ContentPadding.None,
}: IToolbarContentProp) => (
  <div className="programmatic-toolbar-content" style={{ textAlign: align, padding, gridArea: area }}>
    {children}
  </div>
);

export default Toolbar;
