import React from 'react'
import { connect } from 'react-redux';
import _ from 'lodash'
import { STEPS } from './reducer';
import {
  showBasics,
  showBudget,
  showDemographics,
  showGeography,
} from './actions';

class ProgressTracker extends React.Component {

  isStepReady(STEPS) {
    let return_object = {}
    switch (STEPS) {
     case 'BASICS':
       return_object = { isReady: (!_.isEmpty(this.props.selected_objectives) && !_.isEmpty(this.props.selected_media_types)) , returnFunction: this.props.showBasics, name: 'Campaign Goals'};
       break;
     case 'DEMOGRAPHICS':
       return_object = { isReady: !_.isEmpty(this.props.selected_demographics) , returnFunction: this.props.showDemographics, name: 'Audience' };
       break;
     case 'GEOGRAPHY':
       return_object = { isReady: this.props.spend_groups.length > 0 , returnFunction: this.props.showGeography, name: 'Add Markets' };
       break;
     case 'BUDGET': 
       return_object = { isReady: (this.props.spend_groups.length > 0 && !this.props.spend_groups.some(group => group['spend'] <= 0 || '')) , returnFunction: this.props.showBudget, name: 'Configure and Save' };
     break;
    }
    return return_object;
  }

  render() {
    const icon = <i className="fa fa-check-circle-o" aria-hidden="true"></i>
    return (
      <div className="progress-tracker">
        <ul className="breadcrumb-planner">
          {['BASICS','DEMOGRAPHICS','GEOGRAPHY','BUDGET'].map((item, index ) => {
            let current_step_obj = this.isStepReady(item);
            let current_step = this.props.step === item;
            return (
              <li key={index}
                className={`crumb ${current_step ? 'current ' : ''}${current_step_obj.isReady  ? 'ready' : ''}`} 
                onClick={current_step_obj.returnFunction}>{current_step_obj.isReady ? icon : ''}
                <a href="#"/>{current_step_obj.name}
              </li>
            )
          })}
        </ul>
      </div>
    );
  }
}

export default connect(
  ({ planner }) => ({ ...planner }),
  { showBasics, showDemographics, showGeography, showBudget }
)(ProgressTracker)
