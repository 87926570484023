import React, { Component } from "react";
import cs from "classnames";

import UploadButton from "../../UploadButton";

class LeaseAttachmentDropzone extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      completed: false,
      file_url: null,
      filename: null,
      saving_file: false,
      error: null,
      progress: 0,
    };
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.saveFile = this.saveFile.bind(this);
  }

  private renderUploadCard() {
    return (
      <div
        className="upload_lease_attachment"
        style={{
          height: 85,
          width: 300,
          borderRadius: 5,
          textAlign: "center",
          paddingTop: 32,
          border: "1px dashed #CCCCCC",
          cursor: "pointer",
        }}
      >
        <div style={{ color: "#3b3939" }}>
          <span style={{ color: "#4A90E2", fontWeight: 600 }}>Drag & drop files</span> or{" "}
          <span style={{ color: "#4A90E2" }}>click to upload</span>
        </div>
        {this.renderUploadProgress()}
      </div>
    );
  }

  private renderUploadProgress() {
    const { progress, uploading } = this.state;
    if (!uploading) {
      return;
    }
    return (
      <div
        className="upload_progress"
        style={{
          width: "95%",
          border: 0,
        }}
      >
        <div
          className="progression"
          style={{
            width: `${progress}%`,
            backgroundColor: "unset",
          }}
        >
          <div className="upload_details">
            <span className="status">{this.renderUploadStatus()}</span>
            <span className="download_progress">{`${progress}%`}</span>
          </div>
          <div className="line"></div>
        </div>
      </div>
    );
  }

  private renderUploadStatus() {
    const { completed } = this.state;
    if (completed) {
      return "Completed";
    }
    return "Uploading";
  }

  private onUploadComplete(upload, file) {
    return this.setState(
      {
        file_url: file.url,
        filename: file.name,
        completed: true,
        uploading: false,
      },
      this.saveFile,
    );
  }

  private onDrop() {
    this.setState({ uploading: true, progress: 0 });
  }

  private onProgress(progress) {
    this.setState({ progress });
  }

  private async saveFile() {
    const { file_url } = this.state;
    const { onCreateAttachment } = this.props;
    try {
      this.setState({ saving_file: true });
      await onCreateAttachment({ file_url });
      this.setState({ saving_file: false });
    } catch (error) {
      this.setState({ error, saving_file: false });
    }
  }

  public render() {
    return (
      <div style={{ maxWidth: 300, marginTop: 9 }}>
        <UploadButton
          onComplete={this.onUploadComplete}
          onDrop={this.onDrop}
          onProgress={this.onProgress}
          skipProgress={true}
        >
          {this.renderUploadCard()}
        </UploadButton>
      </div>
    );
  }
}

export default LeaseAttachmentDropzone;
