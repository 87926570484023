import React from 'react'
import moment from 'moment';

export default class Tasks extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rows: []
    }
  }

  componentDidMount() {
    this.loadTasks()
  }

  loadTasks() {
    $.get('/api/v1/supplier_tasks/overview').then(rows => {
      this.setState({ rows })
    })
  }

  onRowClick(row) {
    console.log('clicked', row)
  }

  render() {
    return (
      <div style={{padding:'0 20px 0 20px'}}>
        <h2>Campaign Execution Overview</h2>

        <table className="table table-bordered">
          <thead>
            <tr style={{fontWeight:'bold'}}>
              <td className="col-md-4">Campaign</td>
              <td className="col-md-1">RFPs</td>
              <td className="col-md-1">Initials</td>
              <td className="col-md-1">Refreshes</td>
              <td className="col-md-1">Holds</td>
              <td className="col-md-1">Design Assets</td>
              <td className="col-md-1">POP</td>
              <td className="col-md-2">Created At</td>
            </tr>
          </thead>
          <tbody>
            {this.state.rows.map(row => (
              <tr onClick={this.onRowClick.bind(this, row)}>
                <td className="col-md-4">{row.campaign.name}</td>
                <td className="col-md-1">{`${row.rfp.complete}/${row.rfp.total}`}</td>
                <td className="col-md-1">{`${row.initial.complete}/${row.initial.total}`}</td>
                <td className="col-md-1">{`${row.refresh.complete}/${row.refresh.total}`}</td>
                <td className="col-md-1">{`${row.hold.complete}/${row.hold.total}`}</td>
                <td className="col-md-1"></td>
                <td className="col-md-1"></td>
                <td className="col-md-2">{moment(row.campaign.created_at).format("YYYY-MM-DD HH:mm")}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    )
  }
}
