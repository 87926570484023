import * as React from "react";
import styled from "styled-components";

const DEFAULT_COLUMNS = "auto";
const DEFAULT_ROWS = "auto";
const DEFAULT_PADDING = "22px";
const DEFAULT_ALIGN = "auto";
const DEFAULT_WIDTH = "auto";
const DEFAULT_GAP = "32px";

export interface IGridProps {
  children: React.ReactNode | React.ReactNode[];
  width?: string;
  columns?: string;
  areas?: string;
  gap?: string;
  rows?: string;
  padding?: string;
  style?: React.CSSProperties;
  alignItems?: any;
  className?: string;
  height?: string;
  refProp?: React.Ref<HTMLInputElement>;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Grid = ({
  children,
  areas,
  width = DEFAULT_WIDTH,
  columns = DEFAULT_COLUMNS,
  gap = DEFAULT_GAP,
  rows = DEFAULT_ROWS,
  padding = DEFAULT_PADDING,
  alignItems = DEFAULT_ALIGN,
  onClick,
  onMouseMove,
  onMouseEnter,
  onMouseLeave,
  className,
  height,
  refProp
}: IGridProps) => {
  return (
    <GridWrapper
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...{
        width,
        columns,
        gap,
        rows,
        padding,
        alignItems,
        areas,
        className,
        height,
        ref: refProp
      }}
    >
      {children}
    </GridWrapper>
  );
};

export const GridWrapper = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: ${props => props.columns};
  grid-template-rows: ${props => props.rows};
  grid-template-areas: "${props => props.areas}";
  grid-gap: ${props => props.gap};
  align-items: ${props => props.alignItems};
  width: ${props => props.width};
  padding: ${props => props.padding};
`;

export default Grid;
