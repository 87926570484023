import { get, post, put } from "../utils/api2";
import GlobalActions from "./GlobalActions";

export const LOAD_SHIP_TO_CONTACTS = "marketplace/supplier_contacts/load_supplier_addresses";
export const loadSupplierAddresses = supplier_id => async (dispatch, getState) => {
  if (!supplier_id) {
    return;
  }
  try {
    const { shipping_addresses } = await get(`/api/v1/suppliers/${supplier_id}/ship_to_contacts`, { supplier_id });
    const to_reduce = { supplier_id, shipping_addresses };
    dispatch({ type: LOAD_SHIP_TO_CONTACTS, payload: to_reduce });
  } catch (error) {
    GlobalActions.showError("There has been an error while loading designs for the campaign.");
    throw error;
  }
};

export const CREATE_SHIPPING_ADDRESS = "marketplace/supplier_contacts/create_shipping_address";
export const createShippingAddress = (supplier_id, payload) => async (dispatch, getState) => {
  try {
    const response = await post(`/api/v1/suppliers/${supplier_id}/create_shipping_address`, payload);
    dispatch({ type: CREATE_SHIPPING_ADDRESS, payload: response });
    dispatch(loadSupplierAddresses(supplier_id));
    GlobalActions.showMessage("Shipping address created successfully!");
    return response;
  } catch (error) {
    GlobalActions.showError("There has been an error while creating shipping address.");
    throw error;
  }
};

export const UPDATE_SHIPPING_ADDRESS = "marketplace/supplier_contacts/update_shipping_address";
export const updateShippingAddress = (supplier_id, address_id, payload) => async (dispatch, getState) => {
  try {
    const shipping_address = await put(`/api/v1/suppliers/${supplier_id}/update_shipping_address/${address_id}`, {
      address: payload,
    });
    dispatch(loadSupplierAddresses(supplier_id));
    return GlobalActions.showMessage("Shipping address updated successfully!");
  } catch (error) {
    GlobalActions.showError("There has been an error while updating shipping address.");
    throw error;
  }
};
