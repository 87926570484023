import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import accounting from "accounting";

const TimelineHeader = ({
  isInventoryView,
  is_campaign_timeline_view,
  middleWidth,
  rightWidth,
  headers,
  onLeft,
  onRight,
  height,
  onUnitSelectAll,
  unitsSelected,
  interval,
  periodForecast
}) => {
  const flightsStyle = {
    width: middleWidth
  };

  const pricingStyle = {
    width: rightWidth,
    color: "gray",
    fontSize: 12
  };

  let index = 0;
  const dates = headers;
  const partWidth = middleWidth / dates.length;

  const markersView = dates.map(date => {
    const markerStyle = {
      width: 1,
      height,
      backgroundColor: "#E6EDF0",
      left: partWidth * (index + 1),
      position: "absolute"
    };

    index += 1;
    return (
      <div style={{ position: "relative", width: 0, height: 0 }} key={index}>
        <div style={markerStyle} />
      </div>
    );
  });

  const datesView = dates.map(date => {
    const dateStyle = {
      float: "left",
      width: partWidth,
      textAlign: "center",
      padding: 7
    };

    return (
      <div style={dateStyle} key={date.toLocaleString()}>
        {interval === "week"
          ? `${date.getMonth() + 1}/${date.getDate()}`
          : moment.monthsShort(date.getMonth())}
      </div>
    );
  });

  const start_year = dates[0] && moment(dates[0]).format("YYYY");

  return (
    <div className="timelineItem timelineHeader">
      <div className="timelineItem__image" />
      <div
        className="timelineItem__content"
        style={{ padding: 0, color: "gray", fontSize: 12, height: "100%" }}
      >
        <div
          style={{
            width: 20,
            height: "100%",
            float: "right",
            background: "#eee",
            padding: "6px 0 0 5px"
          }}
          onClick={onLeft}
        >
          <i className="fa fa-arrow-left" />
        </div>
        {(isInventoryView || is_campaign_timeline_view) && (
          <span>
            {/* disabling for now */}
            {(false && periodForecast) &&
              <span className="timelineItem__forecast">
                Current period forecast: {accounting.formatMoney(periodForecast, "$", 0)}
              </span>
            }
            {(!!!periodForecast) &&
              <span className="timelineItem__year">{start_year}</span>
            }
          </span>
        )}
      </div>
      <div className="timelineItem__flights" style={flightsStyle}>
        {markersView}
        {datesView}
      </div>
      <div className="timelineItem__pricing" style={pricingStyle}>
        <div
          style={{
            width: 20,
            height: "100%",
            float: "left",
            background: "#eee",
            padding: "6px 0 0 4px"
          }}
          onClick={onRight}
        >
          <i className="fa fa-arrow-right" />
        </div>
        {isInventoryView && (
          <div
            className="btn btn-default btn-xs"
            style={{ marginTop: 3, marginRight: 39, float: "right" }}
            onClick={onUnitSelectAll}
          >
            {unitsSelected === true ? (
              <i className="fa fa-check-square-o" />
            ) : (
              <i className="fa fa-square-o" />
            )}
          </div>
        )}
        {is_campaign_timeline_view && (
          <div
            className="btn btn-default btn-xs"
            style={{ marginTop: 3, marginRight: 39, float: "right" }}
          >
            <div>
              <input
                type="checkbox"
                checked={unitsSelected}
                onClick={onUnitSelectAll}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TimelineHeader.propTypes = {
  headers: PropTypes.array.isRequired,
  onLeft: PropTypes.func.isRequired,
  onRight: PropTypes.func.isRequired,
  middleWidth: PropTypes.number.isRequired,
  rightWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default TimelineHeader;
