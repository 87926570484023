import { connect } from 'react-redux';
import React from 'react';

import { endUnitLoading } from '../../actions/UnitsActions';
import * as NewMapActions from '../NewMap/actions';
import Controls from '../NewMap/Controls';
import Map from '../NewMap/Map';
import Modals from '../NewMap/Modals';

const MapLoader = (props) => map(props);

MapLoader.displayName = "Campaign/MapLoader";

function map(props) {
  if (!AppConfig.mapboxAccessToken) {
    return (<div></div>);
  }

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <Modals { ...props } />
      <Controls { ...props } />
      <Map { ...props } />
    </div>
  );
}

export default connect(({ map }) => ({ map, bounds: map.bounds }), { ...NewMapActions, endUnitLoading })(MapLoader);
