import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import GlobalActions from "../../../actions/GlobalActions";
import { deleteUnits } from "./actions";

class DeleteUnitsModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { deleting_units, onHide } = this.props;

    if (!!!deleting_units && prevProps.deleting_units) {
      GlobalActions.showMessage("Units deleted");
      onHide();
    }
  }

  onSubmit() {
    const { deleteUnits, unit_tokens, campaign_token } = this.props;
    deleteUnits(unit_tokens, campaign_token);
  }

  render() {
    const { show, onHide, deleting_units, unit_tokens } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="flight_action_modal">
        <Modal.Header>
          <Modal.Title>Delete Units</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleting_units && (
            <div>
              <div className="loading-spinner">
                <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
              </div>
            </div>
          )}
          {!!!deleting_units && (
            <div>
              <div className="confirmation-text">
                {unit_tokens.length === 1 && `The selected unit will be removed from the campaign.`}
                {unit_tokens.length > 1 && `${unit_tokens.length} selected units will be removed from the campaign.`}
              </div>
              <div className="button-area">
                <button className="confirmation_button btn btn-success" onClick={this.onSubmit}>
                  Confirm
                </button>
                <button className="cancel_button btn btn-default" onClick={onHide}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { deleting_units } }) => ({ deleting_units }),
  { deleteUnits },
)(DeleteUnitsModal);
