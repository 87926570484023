import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import GlobalActions from '../../actions/GlobalActions'
import { BootstrapTable } from 'react-bootstrap-table';
import { numberFormatter, dateFormatter, dollarFormatter } from '../../utils/formatters'

const thumbnailFormatter = (value, row) => (
  value ?
    <img src={value} style={{ width: '100%', height: '100%' }} /> :
    <div className="no_image" style={{ padding: 0 }}><i className="fa fa-image" /></div>
)

const ResultsTable = createClass({
  getInitialState() {
    return {}
  },

  onSelect(row, isSelected, e) {
    let { selected } = this.state

    if (isSelected) {
      selected.push(row.id)
    } else {
      selected = selected.filter((id) => row.id != id)
    }

    this.setState({ selected })
  },

  onSelectAll(isSelected, rows) {
    this.setState({ selected: rows.map((row) => row.id) })
  },

  trClassName(row, rowIndex) {
    const { filters } = this.props

    if (filters.q && row.supplier_face_id && row.supplier_face_id.toString() == filters.q && rowIndex == 0) {
      return 'direct-match'
    } else {
      return ''
    }
  },

  render() {
    const {
      units,
      total_results_count,
      currentUser,
      modal,
      hideModal
    } = this.props

    const options = {
      onRowClick: unit => GlobalActions.openUnitID(unit.id),
    }

    const fetchInfo = {
      dataTotalSize: total_results_count
    }

    const selectRow = {
      mode: 'checkbox',
      onSelect: this.onSelect,
      onSelectAll: this.onSelectAll
    }

    return (
      /*selectRow={selectRow}*/
      <BootstrapTable
        data={units}
        options={options}
        trClassName={this.trClassName}
        hover
        striped>
        <TableHeaderColumn dataField='id' isKey hidden />
        {/*<TableHeaderColumn width='50'  dataField='thumbnail_url' dataFormat={thumbnailFormatter} editable={false}></TableHeaderColumn>*/}
        <TableHeaderColumn width='150' dataField='supplier_name' dataSort hidden={(!currentUser || !currentUser.is_admin || !currentUser.is_agency)}>Vendor</TableHeaderColumn>
        <TableHeaderColumn width='100' dataField='supplier_face_id' dataSort>Face ID</TableHeaderColumn>
        <TableHeaderColumn width='250' dataField='name' dataSort>Name</TableHeaderColumn>
        <TableHeaderColumn width='150' dataField='price' dataFormat={dollarFormatter} dataSort>4 Week Price</TableHeaderColumn>
        <TableHeaderColumn width='150' dataField='impressions' dataFormat={numberFormatter} dataSort>4 Week Impressions</TableHeaderColumn>
        <TableHeaderColumn width='150' dataField='market' dataSort>Market</TableHeaderColumn>
        <TableHeaderColumn width='150' dataField='state' dataSort>State</TableHeaderColumn>
        <TableHeaderColumn width='150' dataField='unit_type' dataSort>Unit Type</TableHeaderColumn>
        <TableHeaderColumn width='150' dataField='screen_type' dataSort>Screen Type</TableHeaderColumn>
      </BootstrapTable>
    );
  }
})

ResultsTable.propTypes = {
};

export default ResultsTable;
