import { del, get, post, put } from "../utils/api2";
import GlobalActions from "./GlobalActions";

export const LOAD_CAMPAIGN_SHIPPING_ADDRESSES = "marketplace/campaign/load_shipping_addresses";
export const loadCampaignShippingAddresses = campaign_id => async (dispatch, getState) => {
  const shipping_addresses = await get(`/api/v1/shipping_addresses`, { campaign_id });
  dispatch({ type: LOAD_CAMPAIGN_SHIPPING_ADDRESSES, payload: shipping_addresses });
};

export const BULK_SET_DESIGN_ASSET_SHIPPING = "marketplace/campaign/bulk_set_shipping";
export const bulkSetShippingAddress = (campaign_id, shipping_address, campaign_unit_ids) => async (
  dispatch,
  getState,
) => {
  const response = await post(`/api/v1/campaigns/${campaign_id}/design_assets/bulk_update_shipping`, {
    shipping_address,
    campaign_unit_ids,
  });
  if (response.status == 200) {
    dispatch({ type: BULK_SET_DESIGN_ASSET_SHIPPING, payload: response });
  }
};
