var Reflux = require('reflux');

export default Reflux.createActions([
  'loadUnit',
  'filterUnits',
  'sortUnits',
  'getReservedDates',
  'sendRequest',
  'loadCart',
  'setActiveUnit',
  'setHoverUnit',
  'removeHoverUnit',
  'addAllToCart'
]);
