import accounting from 'accounting';
import moment from 'moment'

export const numberFormatter = (cell, row) => (
  cell ? cell.toLocaleString() : cell
)

export const dateFormatter = (cell, row) => (
  moment(row.created_at).format('LLL')
)

export const dollarFormatter = (cell, row) => (
  cell ? accounting.formatMoney(cell) : cell
)
