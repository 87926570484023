import React from 'react';
import createClass from 'create-react-class';

import CampaignStore from '../../stores/CampaignStore';
import NewMapStore from '../../stores/NewMapStore';

export default createClass({
  getInitialState() {
    return {
      adwords_status: false,
      enabled: NewMapStore.state.show_adwords,
      metric: 'cpc'
    };
  },

  async componentDidMount() {
    const adwords_status = await CampaignStore.hasAdwords(this.props.campaign.token)
    this.setState({ adwords_status });
  },

  toggle() {
    this.setState({ enabled: !this.state.enabled }, this.triggerMapUpdate);
  },

  onMetricChange(event) {
    this.setState({ metric: event.target.value }, this.triggerMapUpdate);
  },

  triggerMapUpdate() {
    if (this.state.enabled) NewMapStore.showAdwords(this.props.campaign.id, this.state.metric);
    else NewMapStore.hideAdwords();
  },

  render() {
    if (this.state.adwords_status !== 'ready') return <div/>;

    return (
      <section style={{ padding: '0 20px 10px 10px' }}>
        <div className="checkbox">
          <label>
            <input type="checkbox" name="adwords" value={ this.state.enabled } onClick={ this.toggle }/>
            Adwords
          </label>
          { this.metrics() }
        </div>
      </section>
    );
  },

  metrics() {
    if (!this.state.enabled) return <div/>

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>Metric:</span>
        <div style={{ marginLeft: '10px', marginTop: '-1px' }}>
          {
            ['cpc', 'ctr', 'clicks', 'impressions', 'conversions'].map(metric => (
            <label key={ `adword-metric-${metric}` } className="radio-inline">
              <input
                type="radio"
                name="adwords-metric"
                value={ metric }
                checked={ metric === this.state.metric }
                onChange={ this.onMetricChange }
              />
              { metric }
            </label>
            ))
          }
        </div>
      </div>
    );
  }
});
