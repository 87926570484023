import * as React from "react";
import { post } from "../../../utils/api";
import JobProgress from "../../../utils/JobProgress";
import GlobalActions from "../../../actions/GlobalActions";

interface Props {
  toggleLoading: Function;
  campaignUnitTokens: Array<String>;
  campaignToken: String;
}

interface State {
  jobPolling: {
    startPolling: (job_id: string, callback: any) => void;
  }
}

class BulkPostedUnitDetails extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      jobPolling: new JobProgress()
    }
    this.onClick = this.onClick.bind(this);
    this.onDetailsReportCompleted = this.onDetailsReportCompleted.bind(this);
  }

  private onClick(e) {
    this.props.toggleLoading();
    this.onDownloadPostedDetails();
  }

  async onDownloadPostedDetails() {
    const endPoint = `/api/v1/campaigns/${this.props.campaignToken}/campaign_units/export_posted_details`;
    const payload = {
      campaign_unit_tokens: this.props.campaignUnitTokens
    }
    const { job_id } = await post(endPoint, payload);
    this.state.jobPolling.startPolling(job_id, this.onDetailsReportCompleted);
  }

  onDetailsReportCompleted({ data }) {
    const { error } = data;
    this.props.toggleLoading();
    if (error) { return GlobalActions.showError("Something went wrong"); }
    window.open(data.url);
  }

  public render() {
    return (
      <a onClick={this.onClick}>
        Download Posted Unit Details
      </a>
    );
  }
}

export default BulkPostedUnitDetails;
