import {
  DELETE_RFP_PACKAGE_UNIT,
  FETCH_RFP_PACKAGE_UNITS,
  LOAD_SUPPLIER_PACKAGES_LIST,
  SAVE_RFP_PACKAGE_UNIT,
} from "../actions/rfp_packages_actions";

const initialState = {
  current_rfp: {
    package_units: [],
    supplier_packages: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RFP_PACKAGE_UNITS:
      return setCurrentPackageUnits(state, action.payload);
    case SAVE_RFP_PACKAGE_UNIT:
      return addPackageToPackageUnits(state, action.payload);
    case DELETE_RFP_PACKAGE_UNIT:
      return deletePackageUnit(state, action.payload);
    case LOAD_SUPPLIER_PACKAGES_LIST:
      return setSupplierPackages(state, action.payload);
    default:
      return state;
  }
};

function setCurrentPackageUnits(state, package_units) {
  return {
    ...state,
    current_rfp: {
      ...state.current_rfp,
      package_units,
    },
  };
}

function addPackageToPackageUnits(state, package_unit) {
  const filtered_units = state.current_rfp.package_units.filter((u) => (u.id !== package_unit.id));
  return {
    ...state,
    current_rfp: {
      ...state.current_rfp,
      package_units: [
        ...filtered_units,
        package_unit,
      ],
    },
  };
}

function deletePackageUnit(state, package_unit) {
  const filtered_units = state.current_rfp.package_units.filter((u) => (u.id !== package_unit.id));
  return {
    ...state,
    current_rfp: {
      ...state.current_rfp,
      package_units: filtered_units,
    },
  };
}

function setSupplierPackages(state, supplier_packages) {
  return {
    ...state,
    current_rfp: {
      ...state.current_rfp,
      supplier_packages,
    },
  };
}
