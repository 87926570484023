import _ from "lodash";

import {
  CLEAR_CART,
  LOAD_CART,
  TOOGLE_LOADING_CART,
  FETCHING_CART_TOTAL,
  FETCH_CART_TOTAL,
  IS_ADDING_ALL_TO_CART,
  ADDED_TO_CART,
  IS_REMOVING_ALL_FROM_CART,
  FAILED_ADDING_ALL_TO_CART,
  REMOVED_FROM_CART
} from "../actions/cart";

interface Action {
  type: string;
  payload: any;
}

const initialState = {
  cart_units: [],
  add_ons: [],
  total_pages: 1,
  current_page: 1,
  total_entries: 0,
  totals: {},
  isFetchingTotal: false,
  isAddingAllToCart: false,
  isRemovingAllFromCart: false,
  isFetchingCart: false
};

export default (state = initialState, { payload, type }: Action): any => {
  switch (type) {
    case LOAD_CART:
      return {
        ...state,
        total_pages: parseInt(payload.total_pages),
        current_page: parseInt(payload.current_page),
        cart_units: payload.cart_units,
        add_ons: payload.add_ons,
        total_entries: payload.total_entries
      }
    case TOOGLE_LOADING_CART:
      return {
        ...state,
        isFetchingCart: !state.isFetchingCart
      }
    case IS_ADDING_ALL_TO_CART:
      return {
        ...state,
        isAddingAllToCart: true
      }
    case ADDED_TO_CART:
      return {
        ...state,
        isAddingAllToCart: false
      }
    case FAILED_ADDING_ALL_TO_CART:
      return {
        ...state,
        isAddingAllToCart: false
      }
    case IS_REMOVING_ALL_FROM_CART:
      return {
        ...state,
        isRemovingAllFromCart: true
      }
    case REMOVED_FROM_CART:
      return {
        ...state,
        isRemovingAllFromCart: false
      }
    case FETCHING_CART_TOTAL:
      return {
        ...state,
        isFetchingTotal: true
      }
    case FETCH_CART_TOTAL:
      return {
        ...state,
        totals: payload.totals,
        isFetchingTotal: false
      };
    case CLEAR_CART:
      return initialState;
    default:
      return state;
  }
};
