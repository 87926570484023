import React from 'react';
import { isMobile } from '../../../utils/mobile';
import cs from 'classnames';
const POPUP_WIDTH = 180;

export default class ScreenType extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    const filters = this.props.filters || {};
    this.state = {
      isExpanded: false,
      selectedScreenTypes: filters.screen_type ? filters.screen_type.split(',') : []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters && nextProps.filters.screen_type === null) {
      this.setState({
        selectedScreenTypes: []
      });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onClear() {
    this.setState({ selectedScreenTypes: [] })
  }

  onScreenTypeSelect(option) {
    const { selectedScreenTypes } = this.state;
    if (selectedScreenTypes.includes(option)) {
      this.setState({
        selectedScreenTypes: selectedScreenTypes.filter((screenType) => screenType !== option )
      }, this.onFilter);
    } else {
      this.setState({
        selectedScreenTypes: selectedScreenTypes.concat([option])
      }, this.onFilter);
    }
  }

  onFilter() {
    const { selectedScreenTypes } = this.state;
    const { setFilter } = this.props;
    setFilter({
      'screen_type': !!selectedScreenTypes.length ? selectedScreenTypes.join(',') : null
    });
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedScreenTypes } = this.state;
    if (!selectedScreenTypes.length) return 'Screen type';
    if (selectedScreenTypes.length == 1) return `${_.capitalize(selectedScreenTypes[0])} only`;
    if (selectedScreenTypes.length > 1) return 'Digital + Static';
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  countUnitScreenType(screenType) {
    const { units } = this.props;
    return units.filter(u => u.screen_type == screenType).length
  }

  renderPopup() {
    const { selectedScreenTypes } = this.state;
    const screen_types = ['static', 'digital']
    return (
      <div className="filter_popup screen_type_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <h4>SCREEN TYPE</h4>
        <ul>
          {screen_types.map((option) => {
            const isActive = selectedScreenTypes.includes(option);
            const count = this.countUnitScreenType(option)
            return <li
              key={option}
              onClick={() => this.onScreenTypeSelect(option)}
              className={cs({active: isActive})}
              >
                {_.capitalize(option)}
                <i className="count">({count})</i>
                <i className="fa fa-check" />
            </li>;
          })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const { isExpanded, selectedScreenTypes } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={`${(selectedScreenTypes.length > 0 || isExpanded) ? 'active' : ''}`}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};
