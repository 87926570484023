import React, { Component } from 'react';
import CampaignUnitForm from "./CampaignUnitForm";
import GlobalActions from "../../../actions/GlobalActions";


class CampaignUnitEditTab extends Component {
  constructor(props) {
    super(props);
    this.prepareCampaignUnits(props.campaign_units);

    const rationale = props.campaign_units && props.campaign_units.length > 0 ? props.campaign_units[0].rationale : '';
    const cancellation_policy = props.campaign_units && props.campaign_units.length > 0 ? props.campaign_units[0].cancellation_policy : '';
    this.state = {
      rationale,
      creating: false,
      cancellation_policy,
      is_bonus_unit: null,
      newCampaignUnit: null
    }

    this.onSave = this.onSave.bind(this);
    this.onAddFlight = this.onAddFlight.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  componendDidUpdate(prevProps) {
    if (prevProps.campaign_units && prevProps.campaign_units.length != this.props.campaign_units.length) {
      this.prepareCampaignUnits(this.props.campaign_units);
      this.setState({creating: false})
    }
  }

  prepareCampaignUnits(campaign_units) {
    this.child = {};
    campaign_units.forEach((cu) => {
      this.child[cu.campaign_unit_token] = React.createRef();
    });
  }

  onInputChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value });
  }

  onCheckboxChange(e) {
    const { name, checked } = e.target
    this.setState({ [name]: checked });
  }

  async onSave(event) {
    event.preventDefault();
    const { rationale, cancellation_policy, is_bonus_unit} = this.state;
    const campaign_units = [];
    for (let token in this.child) {
      const campaignUnit = this.child[token].current.campaignUnit();
      campaign_units.push({...campaignUnit, rationale, cancellation_policy, bonus_unit: is_bonus_unit })
    }
    const newCampaignUnit = this.newCampaignUnitRef ? this.newCampaignUnitRef.current.campaignUnit() : null;
    if (newCampaignUnit) {
      const unit_id = this.unitId();
      campaign_units.push({...newCampaignUnit, unit_id: unit_id, rationale, cancellation_policy, bonus_unit: is_bonus_unit });
      this.resetNewCampaignUnit();
      this.setState({creating: true})
    }
    await this.props.onSubmit(campaign_units);
    this.setState({ creating: false })
  }

  unitId() {
    return this.props.campaign_units[0]._id;
  }

  isPackage() {
    return this.props.campaign_units[0].is_package
  }

  onAddFlight() {
    const campaign_unit = this.props.campaign_units[0];
    const newCampaignUnit = {
      start_date: campaign_unit.start_date,
      end_date: campaign_unit.end_date,
      campaign_unit_token: null,
      price: campaign_unit.price,
      production_cost: campaign_unit.production_cost,
      installation_cost: campaign_unit.installation_cost,
      quantity: campaign_unit.quantity,
      cpm: campaign_unit.cpm
    };
    this.setNewCampaignUnit(newCampaignUnit)
  }

  setNewCampaignUnit(newCampaignUnit) {
    this.setState({newCampaignUnit});
    this.newCampaignUnitRef = React.createRef();
  }

  resetNewCampaignUnit() {
    this.setState({newCampaignUnit: null});
    this.newCampaignUnitRef = null;
  }

  renderSubmitButton() {
    const { saving } = this.props
    if (saving) { return <input type="submit" value="Saving..." className="btn btn-primary disabled" disabled /> }
    return <input type="submit" value="Save" className="btn btn-primary" />
  }

  renderBonusUnit() {
    if (!this.props.user.admin) { return }

    return <div className="row">
      <div className="form-group col-md-12">
       <label>Bonus Unit</label>
       <select name="is_bonus_unit" id="is_bonus_unit" onChange={this.onInputChange}>
        <option value={null}></option>
        <option value={true}>Set unit as bonus</option>
        <option value={false}>Unset unit as bonus</option>
       </select>
      </div>
    </div>
  }

  render() {
    const { campaign_units, user, onDeleteCampaignUnit, isTalon, loading } = this.props;
    const { rationale, cancellation_policy, newCampaignUnit, creating } = this.state;
    return (
      <div>
        <form onSubmit={this.onSave}>
          <div className="form-group">
            <div className="row">
              <div className="col-md-12">
                <label>Rationale</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <textarea
                  name="rationale"
                  style={{height: 108, width: '100%', border: '1px solid #CCCCCC', borderRadius: '4px', padding: '10px'}}
                  value={rationale || ''}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <hr/>
            <div className="row">
              <div className="form-group col-md-12">
                <label>Cancellation Policy</label>
                <textarea
                  name="cancellation_policy"
                  style={{height: 108, width: '100%', border: '1px solid #CCCCCC', borderRadius: '4px', padding: '10px'}}
                  value={cancellation_policy || ''}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            {this.renderBonusUnit()}
          </div>
          <hr/>

          {campaign_units.map((campaign_unit, i) => {
            return <CampaignUnitForm
              ref={this.child[campaign_unit.campaign_unit_token]}
              key={campaign_unit.campaign_unit_token}
              index={i + 1}
              campaign_unit={campaign_unit}
              user={user}
              onDeleteCampaignUnit={onDeleteCampaignUnit}
              isTalon={isTalon}
              loading={this.props.loading}
            />
          })}

          {newCampaignUnit &&
            <CampaignUnitForm
              ref={this.newCampaignUnitRef}
              key={"new"}
              index={'New'}
              campaign_unit={newCampaignUnit}
              user={user}
              isTalon={isTalon}
              loading={this.props.loading}
            />
          }

          {!newCampaignUnit && !creating && !this.isPackage() &&
            <div className="row" style={{textAlign: 'center'}}>
              <a onClick={this.onAddFlight}>+ Add Flight</a>
            </div>
          }

          {creating &&
            <div className="row" style={{textAlign: 'center'}}>
              <span>Creating flight...</span>
            </div>
          }

          <div className="row" style={{float: 'right', marginTop: 30}}>
            <div className="col-md-12">
              <span>
                <button className="btn btn-default" style={{ marginRight: 20 }} onClick={(e) => { GlobalActions.closePopup(); e.preventDefault(); }}>Close</button>
              </span>
              {this.renderSubmitButton()}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CampaignUnitEditTab;
