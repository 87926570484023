import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import { createProposals } from "./actions";

class CreateProposalFlights extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderModalBody = this.renderModalBody.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { createProposals, unit_tokens, onHide, campaign_token } = this.props;
    createProposals(unit_tokens, campaign_token);
  }

  componentDidUpdate(prevProps) {
    const { creating_flights, onHide } = this.props;
    if (!!!creating_flights && prevProps.creating_flights) {
      onHide();
    }
  }

  renderModalBody() {
    const { unit_tokens, onHide, creating_flights } = this.props;
    const countText = `${unit_tokens.length} selected`;

    if (creating_flights) {
      return (
        <div>
          <div className="loading-spinner">
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          </div>
        </div>
      );
    }

    return (
      <Modal.Body>
        <div>
          <div className="confirmation-text">
            Create proposal flights for {countText} unit{`${unit_tokens.length > 1 ? "s" : ""}`}?
          </div>
          <div className="button-area">
            <button className="confirmation_button btn btn-success" onClick={this.onSubmit}>
              Submit
            </button>
            <button className="cancel_button btn btn-default" onClick={onHide}>
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    );
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="flight_action_modal">
        <Modal.Header>
          <Modal.Title>Create Proposal Flights</Modal.Title>
        </Modal.Header>
        {this.renderModalBody()}
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { creating_flights } }) => ({ creating_flights }),
  { createProposals },
)(CreateProposalFlights);
