import React from 'react';
import moment from 'moment';
import momentRange from 'moment-range';
import accounting from 'accounting';
import createClass from 'create-react-class';

import GlobalActions from '../../actions/GlobalActions';
import CampaignStore from '../../stores/CampaignStore';

const DirectResponses = createClass({
  displayName: 'DirectResponses',

  renderDirectResponses() {
    const { direct_responses } = this.props

    if (!direct_responses || direct_responses.length == 0) {
      return (<tr key={1}>
        <td colSpan="3" style={{ textAlign: 'center', paddingTop: 30, fontWeight: '600' }}>
          No responses yet.
        </td>
      </tr>);
    }

    return direct_responses.map((response) => {
      const time = moment.utc(response.created_at).format('LLLL');
      const phone = response.phone.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3-$4');
      return (<tr key={response.id}>
        <td>{time}</td>
        <td>{phone}</td>
        <td>{response.region}</td>
      </tr>);
    });
  },

  render() {
    const { direct_responses } = this.props
    return (<div sytle={{marginTop: 10}} className="row direct-response-dashboard">
      <div className="col-md-12">
        <h4>Direct Responses</h4>
        <table className="campaignNew__list table">
          <thead>
            <tr style={{ fontWeight: '600' }}>
              <td width="33%">Time</td>
              <td width="33%">Phone Number</td>
              <td width="33%">Area Code Region</td>
            </tr>
          </thead>
          <tbody>
            {this.renderDirectResponses()}
          </tbody>
        </table>
      </div>
    </div>);
  },
});

export default DirectResponses;
