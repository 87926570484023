import * as React from "react";
import { CSSProperties } from "styled-components";
import Grid from "../Grid";

const TABLE_ROW_PADDING = "0 17px";

const tableStyle = (): CSSProperties =>
  ({
    margin: "0 auto",
    fontSize: "13px",
  } as React.CSSProperties);

const tableHeaderStyle = (): CSSProperties =>
  ({
    backgroundColor: "#EEE",
    minHeight: "48px",
    color: "#555",
    fontWeight: 600,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    alignItems: "center",
    display: "flex",
    padding: TABLE_ROW_PADDING,
  } as React.CSSProperties);

const tableRowStyle = (bgColor = "#FFF"): CSSProperties =>
  ({
    backgroundColor: bgColor,
    minHeight: "40px",
    alignItems: "center",
    display: "flex",
    padding: TABLE_ROW_PADDING,
  } as React.CSSProperties);

export type TableData = string | Element;

export interface Table {
  data: TableData[][];
  config: object;
  columns?: string;
  onLineEnter?: (row: TableData[], index: number) => void;
  onLineLeave?: (row: TableData[], index: number) => void;
}

const Table = ({ data, config, columns, onLineEnter, onLineLeave }: Table): React.ReactElement => {
  const columnsCount = data[0].length;
  return (
    <div style={tableStyle()}>
      {data.map((row, i) => {
        const isFirstRow = i === 0;
        const stripedColor = i % 2 ? "#FFF" : "#FAFAFA";

        return (
          <div
            onMouseEnter={(): void => onLineEnter && onLineEnter(row, i)}
            onMouseLeave={(): void => onLineLeave && onLineLeave(row, i)}
            key={`row_${i}`}
            style={isFirstRow ? tableHeaderStyle() : tableRowStyle(stripedColor)}
          >
            <Grid
              gap="20px"
              alignItems="center"
              width="100%"
              columns={columns || `5fr repeat(${columnsCount - 1}, 1fr)`}
              padding="0"
            >
              {row.map((columnData, j) => (
                <div key={`r_${i}_c_${j}`} className={config[j]?.className} style={i !== 0 ? config[j] : {}}>
                  {columnData}
                </div>
              ))}
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default Table;
