import React, { Component } from 'react';
import { connect } from 'react-redux';

class Title extends Component {
  render() {
    const { campaign } = this.props;
    return (
      <div id="mobile-title">
        <div id="campaign-name">
          <h4>{_.truncate(campaign.name, 20)}</h4>
        </div>
        <div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
    campaign,
    units
  }) => ({
  campaign,
  units
})

export default connect(
  mapStateToProps,
  {}
)(Title)
