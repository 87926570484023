export type Path = string;

export type Url = string;

export type Icons = Path[];

export const url: Url = "https://adquick.imgix.net/";

export const poiIcons: Icons = [
  "icons/red-pin.svg",
  "icons/red-star.svg",
  "icons/pink-pin.svg",
  "icons/pink-star.svg",
  "icons/orange-pin.svg",
  "icons/orange-star.svg",
  "icons/salmon-pin.svg",
  "icons/salmon-star.svg",
  "icons/yellow-pin.svg",
  "icons/yellow-star.svg",
  "icons/green-pin.svg",
  "icons/green-star.svg",
  "icons/teal-pin.svg",
  "icons/teal-star.svg",
  "icons/blue-pin.svg",
  "icons/blue-star.svg",
  "icons/indigo-pin.svg",
  "icons/indigo-star.svg",
  "icons/aegean-pin.svg",
  "icons/aegean-star.svg",
  "icons/lilac-pin.svg",
  "icons/lilac-star.svg",
  "icons/wine-pin.svg",
  "icons/wine-star.svg"
];



