import React, { Component } from "react";
import ReactSelect from 'react-select';
import some from "lodash/some";
import every from "lodash/every";
import uniq from "lodash/uniq";
import * as styleGen from "../styles";
import BulkOptionSelector from "./BulkOptionSelector";
import { ProductionOrderType, SelectOption } from "../../../../models/production_orders";
import { CampaignUnit } from "../../../../models/Units";
import { ISupplierShippingAddress } from "../../../../models/supplier_address";

interface IProductionOrderBulkActionsProps {
  campaignId: any;
  expanded: boolean;
  order: ProductionOrderType;
  selected_items: {};
  units: CampaignUnit[];
  supplier_contacts: ISupplierShippingAddress;
  onSelectAll: (event) => void;
  onSubmitBulk: (order_id, payload) => void;
  bulkUpdateUnit: (unit_ids, payload) => void;
  fetchProductionOrders: (campaign_id) =>any;
}

interface IProductionOrderBulkActionsState {
  applying: boolean;
  supplier: SelectOption | null;
  shipping_to: SelectOption | null;
  product: SelectOption | null;
  product_description: string | null;
  shipping_method: SelectOption | null;
  shipping_method_description: string | null;
}

class ProductionOrderBulkActions extends Component<IProductionOrderBulkActionsProps, IProductionOrderBulkActionsState> {
  constructor(props) {
    super(props);
    this.state = {
      applying: false,
      supplier: null,
      shipping_to: null,
      product: null,
      product_description: null,
      shipping_method: null,
      shipping_method_description: null
    }
    this.onSupplierChange = this.onSupplierChange.bind(this);
    this.onSubmitBulkAction = this.onSubmitBulkAction.bind(this);
    this.onShippingOptionChange = this.onShippingOptionChange.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.onShippingMethodChange = this.onShippingMethodChange.bind(this);
  }

  public render() {
    const { expanded, onSelectAll } = this.props;
    const customStyles = {
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
    return (
      <div className="col-md-12" style={{ display: expanded ? "block" : "none" }}>
        <div className="bulk_actions" style={{...styleGen.actions(), ...customStyles }}>
          { this.isSomeSelected() && this.renderActionButtons() }
          <input type="checkbox" onChange={onSelectAll} checked={this.isAllSelected()} style={styleGen.checkbox()}></input>
        </div>
      </div>
    )
  }

  private isSomeSelected() {
    const { selected_items } = this.props;
    return some(selected_items, item => item);
  }

  private isAllSelected() {
    const { units, selected_items } = this.props;
    return every(units, u => selected_items[u.id]);
  }

  private renderActionButtons() {
    const { supplier, shipping_to, product, shipping_method } = this.state;
    return (
      <div style={styleGen.orderActionContainer()}>
          <BulkOptionSelector
            label="Supplier"
            options={this.getSupplierOptions()}
            selected={supplier}
            onChange={this.onSupplierChange}
          />
          <BulkOptionSelector
            label="Shipping address"
            options={this.getSupplierShippingOptions()}
            selected={shipping_to}
            onChange={this.onShippingOptionChange}
          />
          <BulkOptionSelector
            label="Product"
            options={this.getProductOptions()}
            selected={product}
            onChange={this.onProductChange}
          />
          <BulkOptionSelector
            label="Shipping Method"
            options={this.getShippingMethodOptions()}
            selected={shipping_method}
            onChange={this.onShippingMethodChange}
          />
          <div style={styleGen.orderActionInput()}>
            <button className="btn btn-default" style={{...styleGen.uploadButton(), marginTop: '20px' }} onClick={this.onSubmitBulkAction}>
              <i className="fa fa-cloud-upload" style={{marginRight: 10}}/>
              {this.renderApplyButtonText()}
            </button>
          </div>
      </div>
    )
  }

  private renderApplyButtonText() {
    const { applying } = this.state;
    if(applying) { return "Applying bulk changes..."; }
    return "Apply All"
  }

  private getSupplierOptions() {
    const { units } = this.props;
    const uniq_suppliers = uniq(units.map(u => u.supplier_id));
    const options = uniq_suppliers.reduce((acc: Array<Object>, value) => {
      const unit = units.find(u => u.supplier_id === value) as CampaignUnit;
      acc.push({ label: unit.supplier_name, value: unit.supplier_id });
      return acc;
    }, [])
    return options as Array<SelectOption>;
  }

  private onSupplierChange(supplier) {
    if(!supplier) { return this.setState({ supplier: null }); }
    this.setState({ supplier });
  }

  private getSupplierShippingOptions() {
    const { supplier } = this.state;
    const { supplier_contacts } = this.props;
    if(!supplier) { return [] }
    const addresses  = supplier_contacts[supplier.value];
    return addresses.map(a => { return { label: `${a.address_line}, ${a.city} ${a.state}`, value: a.id } }) as Array<SelectOption>;
  }

  private onShippingOptionChange(shipping_to) {
    if(!shipping_to) { return this.setState({ shipping_to: null }); }
    this.setState({ shipping_to });
  }

  private getProductOptions() {
    const options = [
      { value: 911, label: "Eco-Flexx 3.0" },
      { value: 165, label: "PE-Lamar Poster Flex" },
      { value: 61, label: "Tri-Vis Louvers" },
      { value: 26, label: "Vinyl Flex 7.5 oz (most common)" },
      { value: 253, label: "Vinyl Flex LW 7.5 oz" },
      { value: 108, label: "PE-Eco-Poster Single Sheet" },
      { value: 352, label: "Vinyl Flex HW 13 oz" },
      { value: 62, label: "Vinyl Backlit-Standard" },
      { value: 276, label: "Vinyl Banner-LW 7.5 oz" }
    ] 
    return options as Array<SelectOption>
  }

  private onProductChange(product) {
    if(!product) { return this.setState({ product: null, product_description: null }); }
    this.setState({ product, product_description: product.label });
  }

  private getShippingMethodOptions() {
    const options = [
      { value: 60, label: "UPS Ground" },
      { value: 62, label: "UPS 2DA" },
      { value: 80, label: "UPS NDA" }
    ]
    return options as Array<SelectOption>
  }

  private onShippingMethodChange(shipping_method) {
    if(!shipping_method) { return this.setState({ shipping_method: null, shipping_method_description: null }); }
    this.setState({ shipping_method, shipping_method_description: shipping_method.label });
  }

  private async onSubmitBulkAction() {
    const { order, onSubmitBulk, bulkUpdateUnit, fetchProductionOrders, campaignId } = this.props;
    this.setState({ applying: true });
    const { 
      supplier,
      shipping_to,
      product,
      product_description,
      shipping_method,
      shipping_method_description
    } = this.state;
    const unit_slugs = this.getUnitSlugsFromSelectedCards()
    const order_item_ids = this.getOrderItemIdsFromSelectedCampaignUnitTokens()
    const payload = {
      supplier_id: supplier && supplier.value,
      ship_to_contact_id: shipping_to && shipping_to.value,
      product_id: product && product.value,
      product_description,
      shipping_method_id: shipping_method && shipping_method.value,
      shipping_method_description,
      unit_slugs,
      order_item_ids
    }
    try {
      await onSubmitBulk(order.id, payload);
      if (shipping_to) { await bulkUpdateUnit(unit_slugs, { supplier_address_id: shipping_to.value }) }
      await fetchProductionOrders(campaignId)
      this.setState({ applying: false });
    } catch (error) {
      this.setState({ applying: false });
    }
  }

  getUnitSlugsFromSelectedCards() {
    const { selected_items } = this.props
    return Object.keys(selected_items).filter(slug => !!selected_items[slug])
  }

  getCampaignUnitTokensFromSelectedUnitSlugs() {
    const { units } = this.props
    const selectedUnitSlugs = this.getUnitSlugsFromSelectedCards()
    return units.filter(u => selectedUnitSlugs.includes(u.unit_slug)).map(u => u.campaign_unit_token)
  }

  getOrderItemIdsFromSelectedCampaignUnitTokens() {
    const { order } = this.props;
    const selectedCampaignUnitTokens = this.getCampaignUnitTokensFromSelectedUnitSlugs()
    return order.items.filter(i => selectedCampaignUnitTokens.includes(i.campaign_unit_token)).map(i => i.id)
  }

}

export default ProductionOrderBulkActions