import React from 'react';
import createClass from 'create-react-class';
import _ from 'lodash';
import cs from 'classnames';
import { toFixed, formatNumber, formatMoney } from 'accounting';

const DemographicsTab = createClass({
  displayName: 'DemographicsTab',

  getInitialState() {
    return {
      tabs: [],
      market_name: "",
      demographics: {},
      "economics/housing": {},
      "education/misc": {},
      segments: {},
      active: 'demographics',
      loading: true
    }
  },

  componentDidMount() {
    $.get(`/api/v1/units/${this.props.unit.id}`).then(unit => {
      const { demographics_data: {market_name, tabs, demographics} } = unit;
      this.setState({
        tabs,
        demographics,
        market_name,
        "economics/housing": unit.demographics_data['economics/housing'],
        "education/misc": unit.demographics_data['education/misc'],
        loading: false
      });
    })
  },

  onTabChange(tab) {
    this.setState({ active: tab })
  },

  renderTabs() {
    const { tabs, active } = this.state;
    return (
      <ul className="tabs">
        {tabs.map((tab, idx) => (
          <li
            key={`tab_${idx}`}
            className={cs({
              item_active: tab === active,
              segment: tab === 'segments'
            })}
            onClick={this.onTabChange.bind(this, tab)}>
          {this.displayTabName(tab)}
        </li>
        ))}
      </ul>
    )
  },

  displayTabName(tab) {
    return tab.split('/').map(t => _.capitalize(t)).join(' / ')
  },

  renderSections() {
    const { active, loading } = this.state;
    if(loading) { return }
    const currentSection = this.state[active];
    const sections = Object.keys(currentSection);
    return (
      sections.map((section, idx) => (
        <div key={`section_${idx}`} className="section">
          <p className="section_title">{this.renderSectionTitle(section)}</p>
          <div className="section_grid">
            {this.renderSection(currentSection[section])}
          </div>
        </div>
      ))
    )
  },

  renderSectionTitle(title) {
    if(title === "families_&_housing") { return `Families & \n Housing` }
    return _.capitalize(_.upperFirst(title.replace(/_/g, " ")))
  },

  renderSection(section) {
    const keys = Object.keys(section)
    const { data_types } = section
    const medianAgeFirst = _.sortBy(keys.filter(k => k !== 'data_types'), (o) => o != 'median_age')
    return (
      medianAgeFirst.map((key, idx) => (
        <div key={`section_${key}_${idx}`} className="value_group">
          {!section[key] &&
            <div className="no_data_tooltip">
              <span className="tooltip_text">No data</span>
            </div>
          }
          <span className="number">{`${this.renderSectionNumber(section[key], data_types[key])}`}</span>
          <span className="description">{_.upperFirst(key.replace(/_/g, " "))}</span>
        </div>
      ))
    )
  },

  renderSectionNumber(number, data_type) {
    const types = {
      decimal: formatNumber,
      percentage: toFixed,
      money: formatMoney
    }
    if(!number) { return `\u2013`}
    if(data_type == 'money') { return types[data_type](number, '$', 0) }
    return data_type == 'percentage' ? `${types[data_type](number)}%`: types[data_type](number)
  },

  render() {
    const { market_name } = this.state;
    return (
      <div className="audience_tab">
        <div className="demographic_tabs">
          {this.renderTabs()}
        </div>
        <div className="demographic_body">
          <div className="market_name">{market_name}</div>
          {this.renderSections()}
          <div className="source">
            Source: 2020 US Census
          </div>
        </div>
      </div>
    )
  },
});

export default DemographicsTab;
