import {
  ADD_SPEND_GROUP_TO_CAMPAIGN,
  FETCH_DEMOGRAPHICS,
  FETCH_GEO_RECOMMENDATIONS,
  LOAD_PLAN,
  SAVE_PLAN,
  SELECT_CAMPAIGN,
  SET_STEP,
  TOGGLE_ADD_SPEND_GROUP_TO_CAMPAIGN,
  TOGGLE_MEDIA_TYPE,
  TOGGLE_OBJECTIVE,
  TOGGLE_SPEND_GROUP_TO_PLAN,
  UPDATE_GEOGRAPHIES,
  UPDATE_LAST_TYPE_AT,
  UPDATE_LOCATION_FILTER,
  UPDATE_NEW_CAMPAIGN_NAME,
  UPDATE_SELECTED_DEMOGRAPHICS,
  UPDATE_SPEND_GROUP,
  UPDATE_CAMPAIGN_START_DATE,
  UPDATE_CAMPAIGN_END_DATE,
  TOGGLE_ADD_TO_CAMPAIGN,
  LOAD_SPEND_GROUPS
} from './actions';

import _ from 'lodash';

export const STEPS = {
  basics: 'BASICS',
  geography: 'GEOGRAPHY',
  demographics: 'DEMOGRAPHICS',
  budget: 'BUDGET',
  confirm: 'CONFIRM'
}

function nextStep(step) {
  if (STEPS.basics === step) return STEPS.demographics;
  if (STEPS.demographics === step) return STEPS.geography;
  if (STEPS.geography === step) return STEPS.budget;
  if (STEPS.demographics === step) return STEPS.budget;
  if (STEPS.budget === step) return STEPS.confirm;
  return STEPS.budget;
}

const INITIAL_STATE = {
  objectives: [
    "Brand Awareness",
    "Website Traffic",
    "Foot Traffic",
    "Product Launch",
    "App Installs",
    "Social Media Buzz",
    "Direct Response",
  ],

  selected_objectives: [],

  media_types: [
    "Billboard",
    "Street Furniture",
    "Wallscape",
    "Wildposting",
    "Bus",
    "Airport",
    "Retail",
    "Sports Venues"
  ],

  selected_media_types: [],

  selected_demographics: {},

  spend_groups: [],

  geographies: [],

  campaign_start_date: '',

  campaign_end_date: '',

  campaign: '',

  campaign_name: '',

  step: STEPS.basics,

  add_to_campaign: false
}

export default (state = INITIAL_STATE, {type, payload}) => {
  if ([
    FETCH_DEMOGRAPHICS,
    TOGGLE_ADD_SPEND_GROUP_TO_CAMPAIGN,
    SELECT_CAMPAIGN,
    ADD_SPEND_GROUP_TO_CAMPAIGN,
    UPDATE_GEOGRAPHIES,
    UPDATE_LOCATION_FILTER,
    UPDATE_LAST_TYPE_AT,
    SET_STEP,
    UPDATE_NEW_CAMPAIGN_NAME,
    UPDATE_CAMPAIGN_START_DATE,
    UPDATE_CAMPAIGN_END_DATE,
  ].includes(type)) {
    return {
      ...state,
      ...payload
    }
  }

  switch (type) {
    case SAVE_PLAN: return savePlan(state, payload);
    case TOGGLE_MEDIA_TYPE: return toggleMediaType(state, payload);
    case TOGGLE_OBJECTIVE: return toggleObjective(state, payload);
    case LOAD_PLAN: return loadPlan(state, payload);
    case FETCH_GEO_RECOMMENDATIONS: return fetchGeoRecommendations(state, payload);
    case UPDATE_CAMPAIGN_START_DATE: return updateCampaignStartDate(state, payload);
    case UPDATE_CAMPAIGN_END_DATE: return updateCampaignEndDate(state, payload);
    case UPDATE_SELECTED_DEMOGRAPHICS: return updateSelectedDemographic(state, payload);
    case TOGGLE_SPEND_GROUP_TO_PLAN: return toggleSpendGroupToPlan(state, payload);
    case UPDATE_SPEND_GROUP: return updateSpendGroup(state, payload);
    case LOAD_SPEND_GROUPS: return loadSpendGroups(state, payload);
    case TOGGLE_ADD_TO_CAMPAIGN: return toggleAddToCampaign(state, payload)
    default: return state;
  }
}

function savePlan(state, payload) {
  return {
    ...state,
    ...payload,
    step: nextStep(state.step)
  }
}

function toggleAddToCampaign(state, payload) {
  return {
    ...state,
    ...payload,
    add_to_campaign: !state.add_to_campaign
  }
}

function toggleMediaType(state, { media_type }) {
  return toggleElementOnList(state, 'selected_media_types', media_type);
}

function toggleObjective(state, { objective }) {
  return toggleElementOnList(state, 'selected_objectives', objective);
}

function loadPlan(state, { plan }) {
  const { token, objectives, media_types, demographics, spend_groups } = plan;
  return {
    ...state,
    token,
    selected_objectives: objectives,
    selected_media_types: media_types,
    selected_demographics: demographics,
    spend_groups: spend_groups,
    step: planStep(plan)
  }
}  

function fetchGeoRecommendations(state, payload) {
  return {
    ...state,
    ...payload,
    geo_recommendations_fetched_at: Date.now()
  }
}

function updateSelectedDemographic(state, payload) {
  const selected_demographics = { ...state.selected_demographics } || {};
  if (payload.demographic) {
    selected_demographics[payload.topic] = payload.demographic;
  } else {
    delete selected_demographics[payload.topic];
  }

  return {
    ...state,
    selected_demographics
  };
}

function toggleSpendGroupToPlan(state, { spend_group }) {
  return toggleElementOnList(state, 'spend_groups', spend_group, idFilter(spend_group));
}

function updateSpendGroup(state, { spend_group }) {
  return updateElementOnList(state, 'spend_groups', spend_group);
}

function loadSpendGroups(state, { spend_groups }) {
  return {
    ...state,
    spend_groups
  }
}

function planStep({ objectives, media_types, demographics, spend_groups }) {
  if (spend_groups && spend_groups.length) {
    return STEPS.budget;
  }

  if(demographics && Object.keys(demographics).length) {
    return STEPS.geography;
  }

  if((objectives && objectives.length) && (media_types && media_types.length)){
    return STEPS.demographics;
  }

  return STEPS.basics;
}

function toggleElementOnList(state, list_name, new_value, finder=(e => e === new_value)) {
  let list = state[list_name];

  if (list.some(finder)) {
    list = list.filter(e => !finder(e));
  } else {
    list = list.concat([new_value]);
  }

  const new_state = { ...state };
  new_state[list_name] = list;
  return new_state;
}

function updateElementOnList(state, list_name, new_value, finder=idFilter(new_value)) {
  const list = state[list_name].map(e => {
    if (!finder(e)) return e;
    return new_value;
  });

  const new_state = { ...state };
  new_state[list_name] = list;
  return new_state;
}

function idFilter(item) {
  return (element) => element.id === item.id;
}
