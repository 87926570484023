import moment from "moment";
// @ts-ignore
import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import { fetchPopsOutstanding, hidePopsOutstandingModal, resetDashboard } from "./actions";

const ASSETS = (window as any).ASSETS;

class PopsOutstandingModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.onHide = this.onHide.bind(this);
    this.renderPopOutstanding = this.renderPopOutstanding.bind(this);
    this.renderHeaders = this.renderHeaders.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onHide() {
    const { hidePopsOutstandingModal } = this.props;
    hidePopsOutstandingModal();
  }

  componentWillUnmount() {
    const { resetDashboard } = this.props;
    resetDashboard();
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier, show_pops_outstanding_modal, fetchPopsOutstanding } = this.props;

    if (!!!prevProps.pops_outstanding && !!!prevProps.show_pops_outstanding_modal && show_pops_outstanding_modal) {
      fetchPopsOutstanding(selected_supplier);
    } else if (prevProps.selected_supplier !== selected_supplier) {
      fetchPopsOutstanding(selected_supplier);
    }
  }

  onRowClick(pop_outstanding) {
    if (pop_outstanding.supplier_id == null) {
      window.location.href = `/campaigns/${pop_outstanding.supplier_token}/design_assets/incomplete`;
    } else {
    window.location.href = `/campaigns/${pop_outstanding.campaign_id}/design_assets/incomplete`;
    }
  }

  renderPopOutstanding(pop_outstanding) {
    return (
      <div key={pop_outstanding.campaign_id} className="item_row" onClick={() => this.onRowClick(pop_outstanding)}>
        <div className="campaign_section">
          <div className="mobile-only-col-name">Campaign Name:</div>
          <div className="campaign_name_col pop_outstanding">{pop_outstanding.campaign_name}</div>
        </div>

        <div className="section">
          <div className="mobile-only-col-name">Start Date:</div>
          <div className="start_date_col">
            {pop_outstanding.start_date && moment(pop_outstanding.start_date).format("MM/DD/YYYY")}
          </div>
        </div>

        <div className="section">
          <div className="mobile-only-col-name">End Date:</div>
          <div className="end_date_col">
            {pop_outstanding.end_date && moment(pop_outstanding.end_date).format("MM/DD/YYYY")}
          </div>
        </div>

        <div className="section">
          <div className="mobile-only-col-name">POPs Outstanding</div>
          <div className="count_col">{pop_outstanding.count}</div>
        </div>

        <div className="section">
          <div className="mobile-only-col-name">Upload Deadline</div>
          <div className="upload_dealine_col">
            {pop_outstanding.upload_deadline && moment(pop_outstanding.upload_deadline).format("MM/DD/YYYY")}
          </div>
        </div>

        <div className="view_unit_link_col">
          <a>View POP</a>
        </div>
      </div>
    );
  }

  renderHeaders() {
    return (
      <div className="item_headers">
        <div className="campaign_col_header">Campaign Name</div>
        <div className="col_header">Start Date</div>
        <div className="col_header">End Date</div>
        <div className="col_header">POPs Outstanding</div>
        <div className="col_header">Upload Deadline</div>
      </div>
    );
  }

  render() {
    const { show_pops_outstanding_modal, pops_outstanding, loading_pops_outstanding } = this.props;

    return (
      <Modal show={show_pops_outstanding_modal} onHide={this.onHide} className="pops_outstanding_modal">
        <Modal.Header>
          <div className="header-area">
            <div className="modal-header-text">POPs Outstanding</div>
            <div className="close-modal-area">
              <div className="close-modal" onClick={this.onHide}>
                Close
                <img className="close-modal-x" src={ASSETS["x"]} style={{ float: "right" }} />
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {loading_pops_outstanding && (
            <div className="loading-spinner">
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          )}
          <div className="item_area">
            {pops_outstanding && this.renderHeaders()}
            {pops_outstanding &&
              pops_outstanding.map(pop_outstanding => {
                return this.renderPopOutstanding(pop_outstanding);
              })}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: { show_pops_outstanding_modal, pops_outstanding, selected_supplier, loading_pops_outstanding }
  }) => ({
    show_pops_outstanding_modal,
    pops_outstanding,
    selected_supplier,
    loading_pops_outstanding
  }),
  {
    fetchPopsOutstanding,
    hidePopsOutstandingModal,
    resetDashboard,
  },
)(PopsOutstandingModal);
