import * as React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import { createMovables } from "./actions";

class CreateMovableFlights extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderBody = this.renderBody.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { creating_flights, onHide } = this.props;
    if (!!!creating_flights && prevProps.creating_flights) {
      onHide();
    }
  }

  onSubmit() {
    const { createMovables, unit_tokens, onHide, campaign_token } = this.props;
    createMovables(unit_tokens, campaign_token);
  }

  renderBody() {
    const { creating_flights, unit_tokens, onHide } = this.props;
    const countText = `${unit_tokens.length} selected`;

    return (
      <Modal.Body>
        {creating_flights && (
          <div>
            <div className="loading-spinner">
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        )}
        {!!!creating_flights && (
          <div>
            <div className="confirmation-text">
              Create movable flights for {countText} unit{`${unit_tokens.length > 1 ? "s" : ""}`}?
            </div>
            <div className="button-area">
              <button className="confirmation_button btn btn-success" onClick={this.onSubmit}>
                Submit
              </button>
              <button className="cancel_button btn btn-default" onClick={onHide}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    );
  }

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} animation={false} id="flight_action_modal">
        <Modal.Header>
          <Modal.Title>Create Movable Flights</Modal.Title>
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_builder_ui: { creating_flights } }) => ({ creating_flights }),
  { createMovables },
)(CreateMovableFlights);
