import * as React from "react";

export function useMenuItem(onClick: () => void, expanded?: boolean) {
  const [collapsed, setCollapsed] = React.useState(!expanded);

  React.useEffect(() => {
    setCollapsed(!expanded);
  }, [expanded]);

  const onMakeClick = () => {
    onClick();
    // If there is no expanded prop set it will perform a toggle
    if (expanded === undefined) {
      setCollapsed(!collapsed);
    }
  };
  return [collapsed, onMakeClick];
}
