import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

import GlobalActions from '../actions/GlobalActions';

export default createClass({
  propTypes: {
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      email: '',
      password: '',
      errors: this.props.errors,
    };
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors != this.state.errors) {
      this.setState({ errors: nextProps.errors });
    }
  },

  onOpenLogin() {
    window.location.assign("/login");
  },

  onSkip() {
    GlobalActions.closePopup();
  },

  onForgot() {
    document.location = '/users/password/new';
  },

  onSubmit(e) {
    e.preventDefault();

    if (this.state.email == '') {
      this.setState({ errors: 'Please enter your email address!' });
    }

    this.props.onSubmit({
      email: this.state.email,
      phone: this.state.password,
      //contact_me: this.state.contact_me,
    });
  },

  render() {
    return (
      <div className="login__form">
        <form className="form" onSubmit={this.onSubmit} >
          <div className="settings-label" style={{ marginBottom: 0, paddingBottom: 7.5, fontSize: 16, fontWeight: '600', color: '#44ac6b' }}>Sign up to get access</div>
          <div className="settings-label" style={{ fontSize: 12, fontWeight: 'normal', color: 'gray' }}>We will never share your email or send spam.</div>
          {this.state.errors && <div className="message message_error">{this.state.errors}</div>}
          <input className="input" type="email" placeholder="Email Address" onChange={(e) => { this.setState({ email: e.target.value }); }} style={(this.state.errors && this.state.errors.match(/email/) ? { borderColor: 'red' } : {})} />
          <input className="input" type="phone" placeholder="Phone Number (Optional)" onChange={(e) => { this.setState({ password: e.target.value }); }} />
          {/* <label style={{marginBottom:30}}>
            <input name="contact_me" type="checkbox" onChange={(e) => { this.setState({ contact_me: e.target.checked }); }} />
            <span style={{fontWeight:'normal', color: '#919191',marginLeft:10}}>Yes! Please contact me to plan a personalized campaign</span>
          </label>*/}
          <button className="btn btn_primary register__submit" type="submit" onClick={this.onSubmit}>Continue</button>
        </form>
      </div>
    );
  },
});
