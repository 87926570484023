import React from 'react';
import createClass from 'create-react-class';
import { browserHistory } from 'react-router-legacy';
import GlobalActions from '../../actions/GlobalActions'
import Form from './Form'

export default createClass({
  displayName: 'EmailTemplates/Edit',

  getInitialState() {
    return {
      emailTemplate: null
    }
  },

  componentDidMount() {
    const emailTemplateId = this.props.params.email_template_id

    $.get('/api/v1/email_templates/' + emailTemplateId)
    .then(emailTemplate => this.setState({ emailTemplate }))
  },

  render() {
    const { emailTemplate } = this.state

    return (
      <div className="container">
        <h2>Edit Email Template</h2>
        {emailTemplate && <Form emailTemplate={emailTemplate} />}
      </div>
    )
  }

});
