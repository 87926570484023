import React, { Component } from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import { isMobile } from '../../utils/mobile';
import { params, paramsAdd, paramsRemove } from "../../utils/QueryString";
import SortBy from './SortBy';
import DateRangeFilter from './DateRangeFilter';
import CpmFilter from './CpmFilter';
import PriceFilter from './PriceFilter';
import MediaTypeFilter from './MediaTypeFilter';
import UnitScreenSubtypeFilter from './UnitScreenSubtypeFilter';
import MediaSubTypeFilter from './MediaSubTypeFilter';
import TagsFilter from './TagsFilter';
import PoiFilter from "./PoiFilter";
import SizesFilter from './SizesFilter';
import ScreenTypeFilter from './ScreenTypeFilter';
import SuppliersFilter from './SuppliersFilter';
import UnitTagsFilter from './UnitTagsFilter';
import MoreFiltersList from './MoreFiltersList';
import OrientationFilter from './OrientationFilter';
import FaceIdInput from '../Browse/FaceIdInput';
import NextBookedDaysFilter from './NextBookedDaysFilter';
import AvailabilityFilter from './AvailabilityFilter';
import FlightTypeFilter from './FlightTypeFilter';
import FavoritesFilter from '../FavoritesFilter';
import RecommendedFilter from '../RecommendedFilter';
import InCartFilter from '../InCartFilter';
import AdvertiserFilter from './AdvertiserFilter';
import PreviousAdvertiserFilter from './PreviousAdvertiserFilter';
import MarketFilter from './MarketFilter';
import CampaignFilter from './CampaignFilter';
import BookingStatusFilter from './BookingStatusFilter';
import {
  clearFilters,
  sortUnits,
  filterUnits
} from '../../actions/UnitsActions';
import { filterPlaces } from "../../components/Places/actions";
import PoiDistanceFilter from './PoiDistanceFilter';
import HealthScoreFilter from './HealthScoreFilter';
import TabPanelIdInput from '../Browse/TabPanelIdInput';

class FiltersBar extends Component {

  constructor(props) {
    super(props);
    this.onClearAll = this.onClearAll.bind(this);
    this.debouncedScrollHandler = _.debounce(this.handleScroll.bind(this), 20, { 'maxWait': 100 });
    this.state = {
      sticky: false,
      showSearchSelection: 'face_id'
    }
  }

  handleQueryParams(filter) {
    const keys = Object.keys(filter)
    keys.forEach(key => !!filter[key] ? paramsAdd(key, filter[key]) : paramsRemove(key))
  }

  onClearAll() {
    const { clearFilters, sortUnits } = this.props;
    sortUnits({ sort_by: 'default' });
    clearFilters()
  }

  isFilteredBy(list) {
    const { activeFilters } = this.props;
    return list.some((filter) => this.isFilterPresent(filter, activeFilters))
  }

  isFilterPresent(filter, activeFilters) {
    if (filter === "unit_ids") return false;
    const isKeyPresent = _.has(activeFilters, filter);
    const value = activeFilters[filter];
    if (isKeyPresent && value && (filter === "advertiser" || filter === "campaign")) return true
    const isTrue = typeof(value) === "boolean" && !!value;
    const isNonEmptyArrayOrObject = !_.isEmpty(value);
    const isNonEmptyString = typeof(value) === "string" && !!value.length;


    return  isKeyPresent && (isTrue || isNonEmptyArrayOrObject || isNonEmptyString)
  }

  moreFiltersList() {
    return [
      'only_units_without_tags',
      'only_units_with_pricing',
      'only_units_without_pricing',
      'only_units_with_picture',
      'only_units_without_picture',
      'has_managed_supplier',
      'has_broker_supplier',
      'has_not_broker_supplier',
      'packages_only',
      'exclude_packages',
      'has_margin_agreement',
      'only_cannabis_friendly',
      'on_hold',
      'exclude_units_with_restrictions',
      'only_units_with_rate_card_pricing',
      'geopath_audited',
      'exclude_favorites',
      'exclude_recommended',
      'has_attribution',
      'no_attribution',
      'unknown_attribution',
      'aq_verified_attribution',
      'instant_book',
      'price_greater_than_rate_card'
    ]
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.activeFilters !== prevProps.activeFilters) {
      if(this.props.resetSelectAll) {
        this.props.resetSelectAll()
      }
    }
  }
  componentDidMount() {
    document.addEventListener('scroll', this.debouncedScrollHandler, true);
    this.parseQueryStringFilters()
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.debouncedScrollHandler, true);
  }

  parseQueryStringFilters() {
    const { filterUnits } = this.props
    const filtersToApply = this.processQueryStringFilter()
    return filterUnits(filtersToApply)
  }

  processQueryStringFilter() {
    const filters = params()
    return Object.keys(filters).reduce((unitFilter, filterKey) => {
      if (filterKey == 'sort_by') { return this.applyQueryStringSortBy(filters[filterKey], unitFilter) }
      if (filterKey == 'tags[]') { return this.handleTagFilter(filters[filterKey], unitFilter) }
      return this.parseFilterFromQueryString(filterKey, unitFilter)
    }, {})
  }

  applyQueryStringSortBy(queryStringSortBy, filtersToApply) {
    const { onSort } = this.props
    onSort(queryStringSortBy)
    return filtersToApply
  }

  handleTagFilter(queryStringTags, filtersToApply) {
    return {...filtersToApply, ...{ tags: queryStringTags.map(t => JSON.parse(t)) }}
  }

  parseFilterFromQueryString(queryStringKey, queryStringFilters) {
    const filters = params()
    const key = queryStringKey.replace('[]', '')
    const value = filters[queryStringKey]
    const parsedValue = value == 'true' ? true : value
    queryStringFilters[key] = parsedValue
    return queryStringFilters
  }

  handleScroll(event) {
    if (isMobile) return;
    const { top } = this.node.getBoundingClientRect();
    const { sticky } = this.state;
    if (top < 0) {
      this.setState({ sticky: true })
    } else {
      this.setState({ sticky: false })
    }
  }

  anyFiltersPresent() {
    const { activeFilters } = this.props;
    return Object.keys(activeFilters).some((filter) => this.isFilterPresent(filter, activeFilters))
  }

  distanceFromPOI() {
    const { units } = this.props;
    if(Array.isArray(units.all_units)) { return _.get(units, 'all_units').map(u => u.distance_from_point_of_interest) }
    const cacheKeys = Object.keys(units.all_units)
    return cacheKeys.map(k => units.all_units[k].units).flatten().map(u => u.distance_from_point_of_interest)
  }

  onChangeSearchSelection = (e) => {
    this.setState({ showSearchSelection: e })
  }

  render() {
    const {
      onSort,
      activeFilters,
      units,
      currentUser,
      campaign,
      collapsed,
      isBrowseView,
      isMock,
      isVendorMap,
      isCampaignMap
    } = this.props;
    const { sticky } = this.state;
    const listOfMoreFilters = this.moreFiltersList();

    const isAdmin = currentUser && currentUser.is_admin;
    const isAgency = currentUser && currentUser.is_agency;
    const isSupplier = currentUser && currentUser.is_supplier;
    const canFilterAttributes = currentUser.is_agency_with_unit_tag_filter || !!!isAgency
    const permissions = _.get(campaign, 'permissions', {});
    const isSupplierCampaign = campaign && campaign.supplier;

    let moreFiltersInitialValues = {}
    listOfMoreFilters.forEach((filter) => {
      moreFiltersInitialValues[filter] = this.isFilterPresent(filter, activeFilters)
    });

    const sortedBy = _.get(units, 'sort_by', null);
    const isSorted = !!sortedBy && sortedBy != 'default';

    return (
      <div className="campaign-tab__filters" ref={node => this.node = node} style={{gridArea: 'filters'}}>
        <div className={cs('filters_bar', {sticky: sticky, collapsed: !!collapsed})}>
          <ul className="filters_bar_list" id="filters_bar_list">
            {(!isVendorMap && !isCampaignMap) && <SortBy
              permissions={permissions}
              campaignToken={_.get(campaign, 'campaignId')}
              onSort={onSort}
              isActive={isSorted}
              initialValue={sortedBy}
              poiDistances={isBrowseView ? null : this.distanceFromPOI()}
              handleQueryParams={this.handleQueryParams}
            />}
            <li className="filter_by_face_id">
              {this.state.showSearchSelection === 'face_id' && <FaceIdInput unit_filters={activeFilters} onFilter={this.props.onFilter} />}
              {this.state.showSearchSelection === 'tab_panel_id' && <TabPanelIdInput unit_filters={activeFilters} onFilter={this.props.onFilter} />}
              <select className="search_selection" onChange={e => this.onChangeSearchSelection(e.target.value)}>
                <option value="face_id">Face ID</option>
                <option value="tab_panel_id">Geopath ID</option>
              </select>
            </li>
            {(!isBrowseView && !isVendorMap && !isCampaignMap) &&
              <React.Fragment>
                <FavoritesFilter
                  isActive={this.isFilterPresent('only_favorites', activeFilters)}
                  handleQueryParams={this.handleQueryParams}
                />
                <RecommendedFilter
                  isActive={this.isFilterPresent('only_recommended', activeFilters)}
                  handleQueryParams={this.handleQueryParams}
                />
                <InCartFilter
                  isActive={this.isFilterPresent('only_in_cart', activeFilters)}
                  handleQueryParams={this.handleQueryParams}
                />
              </React.Fragment>
            }
            {((isAdmin || isSupplier || isAgency)) &&
              <DateRangeFilter
                isActive={this.isFilteredBy(['startDate', 'endDate'])}
                isVendorMap={isVendorMap}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {(!isVendorMap && permissions.can_view_availability) &&
              <AvailabilityFilter
                isActive={this.isFilteredBy(['availability_status'])}
                active={units.unit_filter.availability_status}
                initialValue={{ active: units.unit_filter.availability_status }}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {(_.get(campaign, 'campaign.booked')) &&
              <BookingStatusFilter
                isActive={this.isFilteredBy(['only_booked', 'only_not_booked'])}
                initialValue={{
                  onlyBooked:     this.isFilterPresent('only_booked', activeFilters) ? activeFilters.only_booked : false,
                  onlyNotBooked: this.isFilterPresent('only_not_booked', activeFilters) ? activeFilters.only_not_booked : false
                }}
                handleQueryParams={this.handleQueryParams}
              />
            }
            <PriceFilter
              isActive={this.isFilteredBy(['min_price', 'max_price'])}
              initialValue={{
                minPrice: this.isFilterPresent('min_price', activeFilters) ? activeFilters.min_price : null,
                maxPrice: this.isFilterPresent('max_price', activeFilters) ? activeFilters.max_price : null
              }}
              isBrowseView={isBrowseView}
              handleQueryParams={this.handleQueryParams}
             />
            <PoiDistanceFilter
              isActive={this.isFilteredBy(['min_poi_distance', 'max_poi_distance'])}
              initialValue={{
                minPoiDistance: this.isFilterPresent('min_poi_distance', activeFilters) ? activeFilters.min_poi_distance : null,
                maxPoiDistance: this.isFilterPresent('max_poi_distance', activeFilters) ? activeFilters.max_poi_distance : null
              }}
              isBrowseView={isBrowseView}
              handleQueryParams={this.handleQueryParams}
             />
            <CpmFilter
              isActive={this.isFilteredBy(['min_cpm', 'max_cpm'])}
              initialValue={{
                minCpm: this.isFilterPresent('min_cpm', activeFilters) ? activeFilters.min_cpm : null,
                maxCpm: this.isFilterPresent('max_cpm', activeFilters) ? activeFilters.max_cpm : null
              }}
              handleQueryParams={this.handleQueryParams}
            />
            {(!isBrowseView && campaign) &&
              <NextBookedDaysFilter
                isActive={this.isFilteredBy(['min_diff', 'max_diff'])}
                initialValue={{
                  minDiff: this.isFilterPresent('min_diff', activeFilters) ? activeFilters.min_diff : null,
                  maxDiff: this.isFilterPresent('max_diff', activeFilters) ? activeFilters.max_diff : null
                }}
                handleQueryParams={this.handleQueryParams}
              />
            }
            <MediaTypeFilter
              isActive={this.isFilteredBy(['type'])}
              initialValue={this.isFilterPresent('type', activeFilters) && activeFilters.type}
              isBrowseView={isBrowseView}
              handleQueryParams={this.handleQueryParams}
            />
            <MediaSubTypeFilter
              isActive={this.isFilteredBy(['subtypes'])}
              initialValue={this.isFilterPresent('subtypes', activeFilters) && activeFilters.subtypes}
              isBrowseView={isBrowseView}
              handleQueryParams={this.handleQueryParams}
            />
            <ScreenTypeFilter
              isActive={this.isFilteredBy(['screen_type'])}
              initialValue={this.isFilterPresent('screen_type', activeFilters) && activeFilters.screen_type}
              handleQueryParams={this.handleQueryParams}
            />
            {isAdmin &&
              <HealthScoreFilter
                isActive={this.isFilteredBy(['health_score_status'])}
                initialValue={this.isFilterPresent('health_score_status', activeFilters) && activeFilters.health_score_status}
                handleQueryParams={this.handleQueryParams}
              />
            }
            <UnitScreenSubtypeFilter
              isActive={this.isFilteredBy(['unit_screen_subtype'])}
              initialValue={this.isFilterPresent('unit_screen_subtype', activeFilters) && activeFilters.unit_screen_subtype}
              handleQueryParams={this.handleQueryParams}
            />
            {!isBrowseView && !_.isEmpty(this.props.pois) &&
              <PoiFilter
                isActive={!_.isEmpty(this.props.poi_filter)}
                initialValue={this.props.poi_filter}
                filterPlaces={this.props.filterPlaces}
                handleQueryParams={this.handleQueryParams}
              />
            }
            <TagsFilter
              isActive={this.isFilteredBy(['tags'])}
              initialValue={this.isFilterPresent('tags', activeFilters) && activeFilters.tags}
              handleQueryParams={this.handleQueryParams}
            />
            <SizesFilter
              isActive={this.isFilteredBy(['size'])}
              initialValue={this.isFilterPresent('size', activeFilters) && activeFilters.size}
              handleQueryParams={this.handleQueryParams}
            />
            {(!isBrowseView && (isAdmin || isSupplier) && isSupplierCampaign) &&
              <FlightTypeFilter
                isActive={this.isFilteredBy(['booked', 'held', 'movable', 'proposed'])}
                initialValue={{
                  booked: this.isFilterPresent('booked', activeFilters) ? activeFilters.booked : false,
                  held: this.isFilterPresent('held', activeFilters) ? activeFilters.held : false,
                  movable: this.isFilterPresent('movable', activeFilters) ? activeFilters.movable : false,
                  proposed: this.isFilterPresent('proposed', activeFilters) ? activeFilters.proposed : false
                }}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {!isVendorMap &&
              <OrientationFilter
                isActive={this.isFilteredBy(['orientation']) || this.isFilteredBy(['browse_orientation'])}
                initialValue={this.isFilterPresent('orientation', activeFilters) && activeFilters.orientation}
                isBrowseView={isBrowseView}
                supplier={activeFilters.supplier}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {((isAdmin || isAgency) || (!isBrowseView && permissions.can_filter_by_supplier)) &&
              <SuppliersFilter
                isActive={this.isFilteredBy(['supplier'])}
                initialValue={this.isFilterPresent('supplier', activeFilters) && activeFilters.supplier}
                units={this.props.units}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {canFilterAttributes &&
              <UnitTagsFilter
                isActive={this.isFilteredBy(['unit_tag'])}
                initialValue={this.isFilterPresent('unit_tag', activeFilters) && activeFilters.unit_tag}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {(isBrowseView && (isAdmin || isAgency || isSupplier)) &&
              <AdvertiserFilter
                isActive={this.isFilteredBy(['advertiser'])}
                initialValue={this.isFilterPresent('advertiser', activeFilters) && activeFilters.advertiser}
                advertiser={activeFilters.advertiser}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {isAdmin &&
              <PreviousAdvertiserFilter
                isActive={this.isFilteredBy(['previous_advertiser'])}
                initialValue={this.isFilterPresent('previous_advertiser', activeFilters) && activeFilters.previous_advertiser}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {!isBrowseView &&
              <MarketFilter
                isActive={this.isFilteredBy(['markets'])}
                initialValue={this.isFilterPresent('markets', activeFilters) && activeFilters.markets}
                handleQueryParams={this.handleQueryParams}
              />
            }
            {isBrowseView &&
              <CampaignFilter
                isActive={this.isFilteredBy(['campaign'])}
                initialValue={this.isFilterPresent('campaign', activeFilters) && activeFilters.campaign}
                campaign={activeFilters.campaign}
              />
            }
            <MoreFiltersList
              isActive={this.isFilteredBy(listOfMoreFilters)}
              initialValue={moreFiltersInitialValues}
              isBrowseView={isBrowseView}
              handleQueryParams={this.handleQueryParams}
            />
            {this.anyFiltersPresent() &&
              <li className="clear_filters">
                <a onClick={this.onClearAll}>
                  Clear filters
                  <i className="fa-light fa-filter-circle-xmark"></i>
                </a></li>
             }
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ units, currentUser, campaign, filters_bar: { collapsed }, places_ui: { filtered, places } }) => ({
  units,
  currentUser,
  campaign,
  collapsed,
  poi_filter: filtered,
  pois: places
});

export default connect(
  mapStateToProps,
  { clearFilters, sortUnits, filterPlaces, filterUnits }
 )(FiltersBar)
