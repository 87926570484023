import "./navbar.css";
import { User } from "../../types/user";
import GuestUserMenu from "./GuestUserMenu";
import React, { ReactElement, useEffect, useState } from "react";
import TabList from "./TabList";
import UserMenu from "./UserMenu";
import styled from "styled-components";

export interface NavBarConfig {
  disableGetStarted?: boolean;
  loginUrl?: string;
  app: {
    name: string;
    color: string;
  };
}

export interface NavbarWindow extends Window {
  ASSETS: {
    logo: string;
  };
  navbar: NavBarConfig;
}

declare let window: NavbarWindow;
const assets = window.ASSETS;

interface StyledContainerProps {
  noBorder?: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  padding: 0 15px;
  background-color: #fff;
  border-bottom: ${(props: StyledContainerProps): string => (props.noBorder ? "none" : "1px solid #e7e7e7")};
  height: 50px;
  font-family: Inter;
`;

// const LogoImage = styled.img`
//   width: 129px;
//   height: 50px;
//   padding: 15px 0;
// `;

const LogoImage = styled.img.attrs({
  alt: "logo image"
})`width: 129px;
   height: 50px;
   padding: 15px 0;
`;

type NavBarProps = {
  user?: User;
  noBorder?: boolean;
};

function navBarContent(user?: User): ReactElement {
  if (!user || user.is_guest) {
    return <GuestUserMenu config={window.navbar} />;
  } else {
    return (
      <>
        <TabList key="tab-list" user={user} />
        <UserMenu key="user-menu" user={user} />
      </>
    );
  }
}

const NavBar = ({ user, noBorder }: NavBarProps): ReactElement => {
  const { navbar } = window;
  return (
    <StyledContainer className="navbar" noBorder={noBorder}>
      <a href="/" aria-label="home page link">
        <LogoImage src={assets.logo} />
        {navbar?.app?.name && (
          <span style={{ color: navbar.app.color }} className="navbar__appName">
            {navbar.app.name}
          </span>
        )}
      </a>
      {navBarContent(user)}
    </StyledContainer>
  );
};
NavBar.displayName = "NavBar";
export default NavBar;
