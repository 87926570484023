import { connect } from 'react-redux';
import React from 'react';
import Select from 'react-select';
import {
  fetchSalesUsers
} from '../../Inventory/actions';
import {
  fetchAllAdvertisers,
} from '../../Advertisers/actions';
import {
  loadCampaign
} from "../../Campaign/actions";
import {
  createCampaign,
  updateCampaign
} from './actions';
import DateRangePicker from '../../DatePicker/Range';
import CreateAdvertiser from '../../Advertisers/Create';
import Header from './Header';
import BreadCrumbs from './BreadCrumbs';
import UploadButton from '../../UploadButton';
import moment from 'moment';
import Tooltip from "rc-tooltip";

const tool_tips_copy = {
  'hl_zc_obj': { label: 'Highlighted Zip Codes', copy: 'Add in zip codes to send to vendors and to highlight on campaign map for client.'},
  'am_obj' : { label: 'Account Manager', copy: 'Account Manager attached to the RFP. This user will be copied on all outbound emails from system to client and to vendors. ' },
}

class CampaignBuilderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      advertiser_id: null,
      name: "",
      budget: "",
      start_date: null,
      end_date: null,
      sales_user_id: null,
      show_advertiser_create: false,
      advertisers: [],
      highlighted_regions: "",
      pinned_message: "",
      spend_goal_enabled: false,
      spend_goal: "",
      spend_goal_message: "",
    };

    this.onAdvertiserChange = this.onAdvertiserChange.bind(this);
    this.clearBrandingLogoUrl = this.clearBrandingLogoUrl.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    const { fetchSalesUsers, fetchAllAdvertisers, editing, loadCampaign, campaign_token } = this.props;
    fetchSalesUsers();
    fetchAllAdvertisers();
    if (editing && campaign_token) {
      loadCampaign(campaign_token);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { advertiser, advertisers, campaign, editing } = nextProps;
    if (advertisers) {
      this.setState({
        advertisers
      })
    }
    if (advertiser) {
      this.setState({
        advertisers: this.state.advertisers.concat(advertiser),
        advertiser_id: advertiser.id,
        show_advertiser_create: false
      })
    }
    if (editing && campaign && campaign !== this.props.campaign) {
      this.setState({
        advertiser_id: campaign.advertiser_id,
        name: campaign.name,
        budget: campaign.budget,
        start_date: moment(campaign.start_date),
        end_date: moment(campaign.end_date),
        sales_user_id: campaign.sales_user_id,
        highlighted_regions: campaign.highlighted_regions,
        branding_logo_url: campaign.branding_logo_url
      });
    }
  }

  onAdvertiserChange(option) {
    if (option) this.setState({ advertiser_id: option && option.value });
    else this.setState({ advertiser_id: null });
  }

  onSubmit() {
    const {
      name,
      advertiser_id,
      sales_user_id,
      start_date,
      end_date,
      highlighted_regions,
      budget,
      branding_logo_url,
      pinned_message,
      spend_goal_enabled,
      spend_goal,
      spend_goal_message,
    } = this.state;
    const { createCampaign, updateCampaign, editing, campaign_token } = this.props;

    const campaign_params = {
      name,
      start_date: start_date && start_date.toDate(),
      end_date: end_date && end_date.toDate(),
      advertiser_id: advertiser_id,
      sales_user_id,
      budget,
      highlighted_regions,
      branding_logo_url,
      pinned_message,
      spend_goal_enabled,
      spend_goal,
      spend_goal_message,
    };

    if (editing) {
      updateCampaign(campaign_params, campaign_token);
    } else {
      createCampaign(campaign_params);
    };
  }

  async getAdvertisers(input) {
    const { fetchAutocompleteAdvertisers, advertisers } = this.props;
    await fetchAutocompleteAdvertisers(input);
    return { options: advertisers };
  }

  renderAdvertiserArea() {
    const { advertiser_id, show_advertiser_create, advertisers } = this.state;

    const add_advertiser_element =
    <a onClick={e => this.setState({show_advertiser_create: !!!show_advertiser_create})}>
      {show_advertiser_create ? 'hide' : 'Add New Advertiser'}
    </a>
    const options = advertisers.map(a => { return {label: a.name, value: a.id}; })
    return (
      <div className="col-md-12">
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label" style={{display: 'flex', justifyContent: 'space-between' }}>
            Advertiser {add_advertiser_element}
          </div>
          <Select
            value={options.find(o => o.value === advertiser_id)}
            onChange={this.onAdvertiserChange}
            options={options}
            valueKey='id'
            labelKey='name'
            cache={false}
            onSelectResetsInput={false}
            onBlurResetsInput={false}
            clearable={true}
            placeholder='Select an Advertiser'
          />
          {show_advertiser_create && <CreateAdvertiser />}
        </div>
        {/* <div className="row">
          <a onClick={e => this.setState({show_advertiser_create: !!!show_advertiser_create})}>
            {show_advertiser_create ? 'hide' : 'Add New Advertiser'}
          </a>
        </div> */}
      </div>
    )
  }

  renderDateRangePickerRow() {
    const { start_date, end_date } = this.state;
    const additionalPresets = [
      {
        text: '24 weeks',
        length: 24,
        period: 'weeks'
      },
      {
        text: '52 weeks',
        length: 52,
        period: 'weeks'
      }
    ];

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form_label">Timeline</div>
          <DateRangePicker
            openDirection="down"
            start_date={start_date}
            end_date={end_date}
            additionalPresets={additionalPresets}
            onDatesChange={({ start_date, end_date }) =>
              this.setState({ start_date, end_date })
            }
          />
        </div>
      </div>
    )
  }

  clearBrandingLogoUrl() {
    this.setState({
      branding_logo_url: null
    });
  }

  onUploadComplete(upload, file) {
    this.setState({
      branding_logo_url: file.url
    })
  }

  renderCampLocations(){
    const { highlighted_regions } = this.state;
    return (
      <div className="campaign_builder_column row">
        <h4 className="col-md-4" style={{marginTop: 30}}>
          <i className="fa fa-location-arrow" />
          <span>Campaign Locations</span>
        </h4>
        <div className="col-md-8 form-section">
          <div className="row">
            <div className="col-sm-12">
              <div className="form_label">Highlighted Zip Codes (Comma-separated)</div>
              <textarea
                value={highlighted_regions}
                className="form-control"
                onChange={e => this.setState({ highlighted_regions: e ? e.target.value : "" })}
                placeholder="90291, 91502, 12420"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCampDetails() {
    const { name, sales_user_id, budget } = this.state;
    const { sales_users } = this.props;
    const options = sales_users.map(u => { return { label: u.name, value: u.id }; })
    return (
      <div className="campaign_builder_column row">
        <h4 className="col-md-4" style={{marginTop: 30}}>
          <i className="fa fa-list" />
          <span>Campaign Details</span>
        </h4>
        <div className="col-md-8 form-section">
        {this.renderAdvertiserArea()}
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              <div className="form_label">Campaign Name</div>
              <input
                value={name}
                className="form-control"
                type="text"
                onChange={e => this.setState({ name: e ? e.target.value : "" })}
              />
            </div>
          </div>
          {this.renderDateRangePickerRow()}
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              {this.renderLabelTooltip(tool_tips_copy.am_obj.label, tool_tips_copy.am_obj.copy)}
              <Select
                placeholder="Select a sales user"
                value={options.find(o => o.value === sales_user_id)}
                onChange={opt =>
                  this.setState({
                    sales_user_id: opt && opt.value
                  })
                }
                options={options}
                valueKey="id"
                labelKey="name"
                clearable={true}
              />
            </div>
          </div>
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="col-sm-12">
              <div className="form_label">Budget</div>
              <input
                value={budget}
                className="form-control"
                type="textarea"
                onChange={e => this.setState({ budget: e ? e.target.value : "" })}
              />
            </div>
          </div>
          {this.renderClientLogoSection()}
          {this.props.currentUser.is_supplier_seller && this.renderVendorSellerSection()}
        </div>
      </div>
    )
  }

  renderClientLogoSection() {
    const { branding_logo_url } = this.state;

    return (
      <div className="row" style={{marginBottom: '16px'}}>
          <div className="col-sm-12">
            <div className="form_label">Client Logo</div>
            <UploadButton onComplete={this.onUploadComplete}>
              <button type="button" className="btn btn-default" style={{width: '100%', height: '40px'}} onClick={(e) => { e.preventDefault(); }}>
                <i className="fa fa-upload" style={{ marginRight: 7.5 }} />
                Upload Image
              </button>
            </UploadButton>
            {(branding_logo_url) && <div className="client-logo-area">
              <img src={branding_logo_url} style={{ width: 100, marginTop: 10, marginRight: 10 }} />
              <a onClick={this.clearBrandingLogoUrl}>Remove logo</a>
            </div>}
          </div>
      </div>
    )
  }

  renderVendorSellerSection() {
    const { pinned_message, spend_goal_enabled, spend_goal, spend_goal_message } = this.state;
    return (
      <div className="col-sm-12">
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label">Pinned Message</div>
          <textarea
            value={pinned_message}
            className="form-control"
            type="textarea"
            placeholder="Enter a message to be pinned to the campaign map page."
            onChange={e => this.setState({ pinned_message: e ? e.target.value : "" })}
          />
        </div>
        <div className="row" style={{marginBottom: '16px'}}>
          <div className="form_label">Campaign Target</div>
          <div className="form-check" style={{display: 'flex',  flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: '9px'}}>
            <input
              value={spend_goal_enabled}
              checked={spend_goal_enabled}
              type="checkbox"
              onChange={e => this.setState({ spend_goal_enabled: e ? e.target.checked : false })}
            />
            <label style={{height: '9px'}}>
              Enable Campaign Target
            </label>
          </div>
        </div>
        {spend_goal_enabled &&
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="form_label">Campaign Budget Goal</div>
            <input
              value={spend_goal}
              className="form-control"
              type="number"
              placeholder="Enter amount"
              onChange={e => this.setState({ spend_goal: e ? e.target.value : "" })}
            /></div>}
        {spend_goal_enabled &&
          <div className="row" style={{marginBottom: '16px'}}>
            <div className="form_label">Campaign Budget Message</div>
            <textarea
              value={spend_goal_message}
              className="form-control"
              placeholder="Enter custom message"
              type="textarea"
              onChange={e => this.setState({ spend_goal_message: e ? e.target.value : "" })}
            />
          </div>
        }
      </div>
    );
  }

  renderLabelTooltip(label, copy) {
    return (
      <Tooltip placement="right" overlay={copy}>
        <div className="form_label" style={{width: 'fit-content'}}>
          {label}<i className="fa fa-info-circle" />
        </div>
      </Tooltip>
    );
  }

  render() {
    const {
      advertiser_id, name, sales_user_id, highlighted_regions,
      budget, start_date, end_date
    } = this.state;
    const { sales_users, goToStep, step, units_added } = this.props;
    const save_enabled = advertiser_id && name && name.length && start_date && end_date && sales_user_id;

    return (
      <div>
        <div className="campaign_builder_form">
          <div style={{marginLeft: '-20px', marginRight: '-20px'}}>
            <Header
              save_enabled={save_enabled}
              campaign={{...this.state}}
              onSubmit={this.onSubmit}
            />
          </div>
          <div className="breadcrumbs">
            <BreadCrumbs
              goToStep={goToStep}
              step={step}
              disable_last_step={!!!units_added || units_added.length === 0}
            />
          </div>
          <div className="container">
            {this.renderCampDetails()}
            {this.renderCampLocations()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ inventory: { sales_users },
     advertisers_ui: { advertiser, advertisers },
     campaign: { campaign },
     campaign_builder_ui: { campaign_token },
     currentUser
   }) => ({
    sales_users, advertisers, advertiser, campaign, campaign_token, currentUser
  }),
  { createCampaign,
    fetchSalesUsers,
    fetchAllAdvertisers,
    loadCampaign,
    updateCampaign,
  }
)(CampaignBuilderForm);
