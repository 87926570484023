import Reflux from 'reflux';
import GlobalActions from '../actions/GlobalActions';
import AuthStore from './AuthStore';

import { get, post } from '../utils/api';
import param from '../utils/objectToQuery';


export default Reflux.createStore({

  listenables: [GlobalActions],

  init() {
    this.state = {
      popup: null,
      sentNoResultsRequest: false,
      showedSplash: false,
      userInitiatedASearch: false,
      popupOptions: {},
      onClose: null,
      contactPhoneNumber: AppConfig.contactPhoneNumber,
    };
  },

  setContactPhoneNumber(newPhone) {
    this.state.contactPhoneNumber = newPhone;
    this.trigger('phoneChanged');
  },

  getContactPhoneNumber() {
    return this.state.contactPhoneNumber;
  },

  getNumericContactPhoneNumber() {
    return this.state.contactPhoneNumber.replace(')', '').replace('(','').replace('-','').replace(' ','')
  },

  onShowSplash() {
    if (this.shouldShowSplash()) {
      this.onOpenPopup('splash')
    }
  },

  shouldShowSplash() {
    return !AppConfig.isLoggedIn && !AuthStore.isLoggedIn() && !AppConfig.skipSplash && !this.state.showedSplash
  },

  onOpenPopup(popup, options = {}) {
    if (popup == 'splash') this.state.showedSplash = true
    this.state.popup = popup;
    this.state.popupOptions = options
    this.state.onClose = options.onClose
    this.trigger('popup:open');
  },

  openUnit(unit) {
    this.state.unit = unit
    this.state.popup = 'unit'
    this.trigger('popup:open')
  },


  openUnitID(unit_id, options = {}) {
    options.unit_id = unit_id
    this.onOpenPopup('unit_by_id', options)
  },

  onClosePopup(options = {}) {
    if (this.state.onClose && options.skipOnClose != true) {
      this.state.onClose()
    }

    this.state.popup = null;
    this.state.onClose = null;
    this.state.unit = null
    this.trigger('popup:close');
  },

  onShowMessage(text, type, sticky = false) {
    this.onHideMessage(); // force cleanup and re-render
    this.state.message = {text, type, sticky};
    this.trigger('message:show');
  },

  onShowError(text, sticky = false) {
    this.onHideMessage(); // force cleanup and re-render
    this.state.message = { text, type: "error", sticky }
    this.trigger('message:show');
  },

  onHideMessage() {
    this.state.message = null;
    this.trigger('message:hide');
  },

  onSendMessage(params) {
    return post(`/contact_us`, params)
      .then(res => {
        this.onClosePopup()
        this.onShowMessage('We received your message! We will get back to you within 48 hours!')
      })
      .catch(err => console.log(err));
  },

  onLog(params) {
    return post('/api/v1/logs', params)
  },

  onSendFeedback(feedback, campaign_token) {
    return post('/api/v1/logs', { feedback, campaign_token })
  },

  onLogSearch(data) {
    GlobalActions.log({action: 'search', data: data})
  },

  onSentNoResultsRequest() {
    this.state.sentNoResultsRequest = true
  },

  getState() {
    return this.state;
  },

});
