import React, { Component } from 'react';
import GlobalActions from '../actions/GlobalActions';
import cs from 'classnames';

const { SUCCESS, ERROR, WARNING, INFO } = { SUCCESS: 'success', ERROR: 'error', WARNING: 'warning', INFO: 'info' };

class Message extends Component {

  icon() {
    const { type } = this.props;

    switch(type) {
      case SUCCESS: return <i className="fa fa-check" />;
      case ERROR:   return <i className="fa fa-close" />;
      case WARNING: return <i className="fa fa-exclamation-triangle" />;
      case INFO:    return <i className="fa fa-info-circle" />;
    }
  }

  render() {
    const { text, type, sticky } = this.props;

    return (
      <div className={cs('message-wrap', { auto_hide: !sticky })} onClick={GlobalActions.hideMessage}>
        {sticky && <i className="close_message fa fa-close" />}
        <div className={`message message_${type}`}>
          {this.icon()}
          {text}
        </div>
      </div>
    );
  }

}

Message.defaultProps = {
  type: 'success'
}

export default Message
