import { TOGGLE_FILTERS } from '../actions/filters_bar_actions';

type Action = { type: string, payload: null };
const initialState = {
  collapsed: true
}

export default(state = initialState, action: Action): Object | null => {
  switch(action.type) {
    case TOGGLE_FILTERS: return { collapsed: !state.collapsed };
    default: return state;
  }
}
