import * as React from 'react';
import { connect } from 'react-redux';
import {
  fetchPopsOutstandingCount,
  showPopsOutstandingModal
} from './actions';

class PopsOutstanding extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const { selected_supplier } = this.props;
    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier)
    }
  }

  fetchData(supplier) {
    const { fetchPopsOutstandingCount } = this.props;
    fetchPopsOutstandingCount(supplier);
  }

  render() {
    const {
      pops_outstanding_count,
      loading_pops_outstanding_count,
      showPopsOutstandingModal
    } = this.props;

    return (
      <div id="unit_requests" className="todo-card">
        {loading_pops_outstanding_count && <div className="loading-spinner">
          <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        </div>}
        {!!!loading_pops_outstanding_count &&
          <div className={`count ${pops_outstanding_count >= 10 ? 'high' : ''}`}>
            {pops_outstanding_count}
          </div>
        }
        <div className="card-name">POPs Outstanding</div>
        <button className="todo-button" onClick={showPopsOutstandingModal}>
          View
        </button>
      </div>
    );
  }
}

export default connect(
  // @ts-ignore
  ({ vendor_dashboard: {
       pops_outstanding_count,
       loading_pops_outstanding_count,
       selected_supplier
     }
   }) => ({
     pops_outstanding_count,
     loading_pops_outstanding_count,
     selected_supplier
   }),
  {
    fetchPopsOutstandingCount,
    showPopsOutstandingModal
  }
)(PopsOutstanding);
