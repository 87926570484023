import cs from "classnames";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import Tooltip from "rc-tooltip";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  applyDiscount,
  createCampaignSupplierContract,
  sendPriceProposal,
} from "../../actions/campaign_supplier_contracts";
import GlobalActions from "../../actions/GlobalActions";
import { dollarFormatter } from "../../utils/formatters";
import ContractTypeBadge from "./ContractTypeBadge";
import PriceAdjustment from "./PriceAdjustment";
import Totals from "./Totals";
import ContractConfirmation from '../ContractConfirmation';

const MAX_CONTACTS_PER_CONTRACT = 5;

class Form extends Component<any, any> {
  constructor(props) {
    super(props);
    const { referenceContract } = this.props;
    this.state = {
      note: "",
      custom_language: "",
      vendor_proposal_note: "",
      contacts: referenceContract.signers.map(signer => `${signer.id}`),
      _previous: null,
      number_of_contacts: referenceContract.signers.length > 0 ? referenceContract.signers.length : 1,
      url: null,
      items_changes: {},
      items: this.props.items,
      referenceContract: referenceContract,
      is_counter_signer: false,
      has_custom_contracts: false,
      templates: [],
      selected_template: null,
      show_confirmation: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
    this.onMarkForRemoval = this.onMarkForRemoval.bind(this);
    this.onSelectContact = this.onSelectContact.bind(this);
    this.onFocusContact = this.onFocusContact.bind(this);
    this.addContactDropwdown = this.addContactDropwdown.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.onSendPriceProposal = this.onSendPriceProposal.bind(this);
    this.saveDiscount = this.saveDiscount.bind(this);
    this.onSendPriceProposalClick = this.onSendPriceProposalClick.bind(this);
    this.onApplyPercentageChange = this.onApplyPercentageChange.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.onSelectTemplate = this.onSelectTemplate.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleConfirmationClick = this.handleConfirmationClick.bind(this);
  }

  componentDidMount(){
    const { user } = this.props;
    const can_send_counter_signature = _.get(user, 'permissions.can_send_counter_signature');
    const has_custom_contracts = _.get(user, 'permissions.has_custom_contracts');
    const show_send_proposals = _.get(user, 'email') === 'ted@adquick.com';
    // temp determination for contract templates
    // talon user (counter signer) and has custom templates
    has_custom_contracts && can_send_counter_signature && this.getTemplates();
    // non talon user with custom templates
    has_custom_contracts && !can_send_counter_signature && this.getCustomTemplates()
    this.setState({
      is_counter_signer: can_send_counter_signature,
      has_custom_contracts: has_custom_contracts,
      show_send_proposals: show_send_proposals
     });
  }

  async getTemplates(){
    try {
      const response = await axios.get('/api/v1/campaign_supplier_contract_templates');
      const { templates } = response.data;
      this.setState({ templates: templates});
    } catch (error) {
      console.error(error);
    }
  }
  async getCustomTemplates(){
    // need two differnt calls for template api 
    // this is for custom contract non talon 
    try {
      const response = await axios.get('/api/v1/contract_templates/supplier_templates');
      const { templates } = response.data;
      this.setState({ templates: templates});
    } catch (error) {
      console.error(error);
    }
  }

  filteredContractItems() {
    const { filterByFavorite, filterByRecommended, filterByBooked } = this.props;
    const { items } = this.state;
    const filter_items = items && filterByBooked ? items.filter(item => item.booked) : items;

    if (!filterByFavorite && !filterByRecommended) {
      return filter_items;
    }
    if (filterByFavorite && filterByRecommended) {
      return filter_items.filter(item => item.favorited && item.recommended);
    }
    if (filterByFavorite) {
      return filter_items.filter(item => item.favorited);
    }
    if (filterByRecommended) {
      return filter_items.filter(item => item.recommended);
    }
  }

  handleConfirmationClick() {
    this.setState({ show_confirmation: !this.state.show_confirmation });
  }

  onSave() {
    this.setState({ show_confirmation: true });
  }

  onChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onItemChange(event) {
    let { name, value, checked, dataset } = event.target;
    if (name == "has_production") { value = checked }
    if (name !== "has_production") { value = _.toNumber(value) };
    const change = { [name]: value };
    const items = this.filteredContractItems().map(item => {
      if (item.token === dataset.cuToken) { return this.updateItemWithChange(item, change) };
      return item;
    });
    const updated = {
      [dataset.cuToken]: {
        ...this.state.items_changes[dataset.cuToken],
        ...change,
      },
    };
    this.setState({
      items_changes: {
        ...this.state.items_changes,
        ...updated,
      },
      items,
    });
  }

  updateItemWithChange(item, change) {
    const updatedItem = {...item, ...change}
    updatedItem['vendor_produced'] = !updatedItem['has_production']
    return updatedItem
  }
  onSelectTemplate(e) {
    this.setState({ selected_template: e.target.value });
  }

  async saveDiscount(options, afterSave) {
    const { campaign, applyDiscount, contractType } = this.props;
    const { items_changes, referenceContract, contacts, custom_language, note, vendor_proposal_note } = this.state;
    const items = this.filteredContractItems();
    const supplierId = _.get(referenceContract, "supplier.id");
    await applyDiscount(campaign.token, referenceContract.id, supplierId, {
      ...referenceContract,
      ...options,
      is_hellosign: contractType === "hellosign",
      items,
      items_changes,
      signer_ids: contacts,
      custom_language,
      vendor_proposal_note,
      note,
    });
    GlobalActions.showMessage("Price adjustment info saved.");
    this.setState({ saving: false }, afterSave);
  }

  onMarkForRemoval(cuToken) {
    const { items_changes } = this.state;
    const currentValue = _.get(items_changes, `[${cuToken}]._destroy`, false);
    const change = { _destroy: !currentValue };
    const updated = { [cuToken]: { ...items_changes[cuToken], ...change } };
    this.setState({ items_changes: { ...items_changes, ...updated } });
  }

  async onSubmit(sendPriceProposal) {
    const { note,
            contacts,
            url,
            items_changes,
            custom_language,
            referenceContract,
            vendor_proposal_note,
            is_counter_signer,
            selected_template,
            has_custom_contracts
          } = this.state;
    const items = this.filteredContractItems();
    const { createCampaignSupplierContract, campaign, onHideForm, contractType } = this.props;
    const supplierId = _.get(referenceContract, "supplier.id");
    const contract = {
      custom_language,
      vendor_proposal_note,
      note,
      url,
      items,
      items_changes,
      is_hellosign: contractType === "hellosign",
      signer_ids: contacts,
      id: referenceContract.id,
    };
    if (has_custom_contracts && !selected_template) {
      GlobalActions.showError("Please Select and confirm a contract template");
      return;
    } else if (this.isValid()) {
      await createCampaignSupplierContract(campaign.campaignId, supplierId, contract, sendPriceProposal, is_counter_signer, selected_template);
      if (sendPriceProposal) {
        this.onSendPriceProposal();
      } else {
        GlobalActions.showMessage("Contract was successfully created");
        onHideForm();
      }
    } else {
      if (contractType === "hellosign") {
        GlobalActions.showError("Please select at least one signer from the list");
      } else {
        GlobalActions.showError("You need to upload a PDF file first");
      }
    }
  }

  async onSendPriceProposal() {
    const { sendPriceProposal, campaign } = this.props;
    const { referenceContract, contacts } = this.state;
    const items = this.filteredContractItems();
    const supplierId = _.get(referenceContract, "supplier.id");
    await sendPriceProposal(campaign.token, referenceContract.id, supplierId, items, contacts);
    GlobalActions.showMessage("Price proposal sent.");
  }

  onSendPriceProposalClick() {
    this.onSubmit(true);
  }

  onUploadComplete(upload, file) {
    this.setState({ url: upload.path });
    GlobalActions.showMessage("File upload completed", "info");
  }

  isValid() {
    const { contacts, url } = this.state;
    const { contractType } = this.props;
    if (contractType === "hellosign") {
      return contacts.length;
    } else {
      return url && url.length;
    }
  }

  buildNewContacts(value) {
    const { contacts, _previous } = this.state;
    if (contacts.length === 0) {
      return [value];
    } else {
      if (contacts.includes(_previous)) {
        return contacts.map(contact => {
          if (contact === _previous) {
            return value;
          } else {
            return contact;
          }
        });
      } else {
        return [...contacts, value];
      }
    }
  }

  onSelectContact(event) {
    const { value } = event.target;
    this.setState({ contacts: this.buildNewContacts(value), _previous: null });
    event.target.blur();
  }

  onFocusContact(event) {
    const { value } = event.target;
    this.setState({ _previous: value });
  }

  contactsDropdown(idx) {
    const { referenceContract } = this.state;
    const contacts = _.get(referenceContract, "supplier.contacts", []);
    return (
      <select
        key={idx}
        className="form-control"
        value={this.state.contacts[idx]}
        onChange={this.onSelectContact}
        onFocus={this.onFocusContact}
      >
        <option value={undefined}>Select signer</option>
        {contacts.map(contact => (
          <option key={contact.id} value={contact.id} disabled={this.state.contacts.includes(`${contact.id}`)}>
            {contact.name}
          </option>
        ))}
      </select>
    );
  }

  addContactDropwdown() {
    const { number_of_contacts } = this.state;
    if (number_of_contacts >= MAX_CONTACTS_PER_CONTRACT)
      return GlobalActions.showMessage("Can't have more than 5 signers per contract", "info");
    this.setState({ number_of_contacts: number_of_contacts + 1 });
  }

  componentDidUpdate(prevProps) {
    const { campaign_supplier_contracts } = this.props;
    const { referenceContract } = this.state;
    if (campaign_supplier_contracts !== prevProps.campaign_supplier_contracts) {
      if (!!!referenceContract.id) {
        const newContract = campaign_supplier_contracts.lastItem;
        this.setState({ items: newContract.items, referenceContract: newContract });
      } else {
        const updatedContract = campaign_supplier_contracts.filter(c => c.id === referenceContract.id)[0];
        this.setState({ items: updatedContract.items, referenceContract: updatedContract });
      }
    }
  }

  renderItem(item) {
    const { items_changes } = this.state;
    const markedForRemoval = _.get(items_changes, `[${item.token}]._destroy`, false);

    return (
      <tr key={item.token} className={cs({ marked_for_removal: markedForRemoval })}>
        <td>{item.supplier_face_id}</td>
        <td>{dollarFormatter(item.suggested_price)}</td>
        <td>
          <input
            data-cu-token={item.token}
            type="number"
            name="supplier_price"
            value={item.supplier_price}
            onChange={this.onItemChange}
            disabled={markedForRemoval}
          />
        </td>
        <td>
          <input
            data-cu-token={item.token}
            type="number"
            name="supplier_production_cost"
            value={item.supplier_production_cost}
            onChange={this.onItemChange}
            disabled={markedForRemoval}
          />
        </td>
        <td>
          <input
            data-cu-token={item.token}
            type="number"
            name="supplier_installation_cost"
            value={item.supplier_installation_cost}
            onChange={this.onItemChange}
            disabled={markedForRemoval}
          />
        </td>
        <td>
          <input
            data-cu-token={item.token}
            type="checkbox"
            name="has_production"
            onChange={this.onItemChange}
            disabled={markedForRemoval}
          />
          <p className="hover_actions text-right">
            <i
              className={cs("fa", { "fa-times": !markedForRemoval, "fa-undo": markedForRemoval })}
              onClick={() => this.onMarkForRemoval(item.token)}
            />
          </p>
        </td>
      </tr>
    );
  }

  renderContractTemplateDropdown(){
    const { templates } = this.state;
    return (
      <div style={{ maxWidth: "100%", maxHeight: "100%" }}>
        <label>
          Contract Template {" "}
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.3}
            overlay="This will determine the contracts custom template"
          >
            <i className="fa fa-info-circle"></i>
          </Tooltip>
        </label>
        <select className="form-control" value={this.state.selected_template} onChange={this.onSelectTemplate}>
          <option value={undefined}></option>
          {templates.map(( template, idx ) => <option key={idx} value={template.id}>{template.name}</option>)}
        </select>
      </div>
    );
  }

  renderContactsDropdown() {
    const { number_of_contacts, is_counter_signer } = this.state;
    return (
      <div className="contacts_dropdown">
        <div>
          {_.times(number_of_contacts, idx => {
            return this.contactsDropdown(idx);
          })}
          {!is_counter_signer && <a onClick={this.addContactDropwdown}>Add another signer</a> }
        </div>
      </div>
    );
  }

  renderVendorNote() {
    const { note } = this.state;
    return (
      <div className="contract_note">
        <label>
          Vendor Contract Note{" "}
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.3}
            overlay="This note will be included on the contract itself, and also in the generated email containing the HelloSign contract"
          >
            <i className="fa fa-info-circle"></i>
          </Tooltip>
        </label>
        <textarea
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          name="note"
          value={note}
          onChange={this.onChange}
          rows={5}
        ></textarea>
      </div>
    );
  }

  renderCustomLanguage() {
    const { custom_language } = this.state;

    return (
      <div className="contract_language">
        <label>
          Custom Vendor Contract Language{" "}
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.3}
            overlay="This custom language will be included after the terms and conditions, on the contract"
          >
            <i className="fa fa-info-circle"></i>
          </Tooltip>
        </label>
        <textarea
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          name="custom_language"
          value={custom_language}
          onChange={this.onChange}
          rows={5}
        ></textarea>
      </div>
    );
  }

  renderProposalNote() {
    const { vendor_proposal_note, show_send_proposals } = this.state;

    return (
      show_send_proposals && <div className="proposal_note">
        <label>
          Vendor Proposal Note{" "}
          <Tooltip
            placement="bottom"
            mouseEnterDelay={0.3}
            overlay="This note will be included in the proposal email for vendors"
          >
            <i className="fa fa-info-circle"></i>
          </Tooltip>
        </label>
        <textarea
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          name="vendor_proposal_note"
          value={vendor_proposal_note}
          onChange={this.onChange}
          rows={5}
        ></textarea>
      </div>
    );
  }

  renderActions() {
    const { referenceContract, show_send_proposals, show_confirmation, is_counter_signer } = this.state;
    const { onHideForm } = this.props;
    const { contractType } = this.props;
    
    return (
      <div className="actions">
        {is_counter_signer && (
          <button className="btn btn-block" onClick={this.onSave}>
            {contractType === "hellosign" ? "Send Contract" : "Save"}
          </button>
        )}
        {!is_counter_signer && (
          <button className="btn btn-block" onClick={() => this.onSubmit(false) }>
            {contractType === "hellosign" ? "Send Contract" : "Save"}
          </button>
        )}
        {contractType === "hellosign" && show_send_proposals && (
          <button className="btn btn-block send-price-prop-button" onClick={this.onSendPriceProposalClick}>
            Send Price Proposal
          </button>
        )}
        {referenceContract.negotiation_sent_at && (
          <div className="proposal-sent-at">
            Proposal sent:
            <br />
            {moment(referenceContract.negotiation_sent_at).format("LLL")}
          </div>
        )}
        {referenceContract.pricing_confirmed_at && (
          <div className="proposal-sent-at">
            Pricing Confirmed:
            <br />
            {moment(referenceContract.pricing_confirmed_at).format("LLL")}
          </div>
        )}
        <button className="btn-link btn-block" onClick={onHideForm}>
          Cancel
        </button>
      </div>
    );
  }

  convertArrayToObject(array, key) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  }

  onApplyPercentageChange(price_percent, prod_percent, install_percent, afterApply) {
    const { items_changes } = this.state;
    const items = this.filteredContractItems();
    const new_items = items.map(item => {
      return {
        ...item,
        supplier_price: price_percent ? item.supplier_price - item.supplier_price * price_percent : item.supplier_price,
        supplier_production_cost: prod_percent
          ? item.supplier_production_cost - item.supplier_production_cost * prod_percent
          : item.supplier_production_cost,
        supplier_installation_cost: install_percent
          ? item.supplier_installation_cost - item.supplier_installation_cost * install_percent
          : item.supplier_installation_cost,
      };
    });

    const new_items_changes = {
      ...items_changes,
      ...this.convertArrayToObject(
        new_items.filter(item => !!!(items_changes[item.token] && items_changes[item.token]._destroy)),
        "token",
      ),
    };

    this.setState(
      {
        items_changes: new_items_changes,
        items: new_items,
      },
      afterApply,
    );
  }

  render() {
    const { url, referenceContract, items_changes, is_counter_signer, has_custom_contracts, show_confirmation} = this.state;
    const items = this.filteredContractItems();
    const { contractType } = this.props;
    const itemsForTotal = items.filter(item => !!!(items_changes[item.token] && items_changes[item.token]._destroy));
    const showPriceAdjustment = (contractType === "hellosign" && !is_counter_signer);
    return (
      <div className="form" key="form">
        <div className={`form_fields ${contractType}`}>
          <div className="contract_type_badge">
            <ContractTypeBadge type={contractType} onUploadComplete={this.onUploadComplete} isDone={!!url} />
          </div>
          {contractType === "hellosign" && this.renderContactsDropdown()}
          {has_custom_contracts && this.renderContractTemplateDropdown()}
          {contractType === "hellosign" && this.renderProposalNote()}
          {contractType === "hellosign" && this.renderVendorNote()}
          {contractType === "hellosign" && this.renderCustomLanguage()}
        </div>
        <div className="supplier_contracts_table">
          {showPriceAdjustment && (
            <PriceAdjustment referenceContract={referenceContract} onDiscountChange={this.saveDiscount} />
          )}
          <table className="table_form">
            <thead>
              <tr>
                <th>Face ID</th>
                <th>Suggested $</th>
                <th>Media</th>
                <th>Prod</th>
                <th>Install</th>
                <th>Prod with CG</th>
              </tr>
            </thead>
            <tbody>{items.map(item => this.renderItem(item))}</tbody>
          </table>
          <Totals items={itemsForTotal} applyChanges={this.onApplyPercentageChange} />
        </div>
        {this.renderActions()}
        {is_counter_signer && (
          <ContractConfirmation
            handleConfirmationClick={this.handleConfirmationClick}
            show_confirmation={show_confirmation}
            onSubmit={this.onSubmit}
            type={"supplier_contract"}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ campaign, campaign_supplier_contracts }) => ({
  campaign,
  campaign_supplier_contracts,
});

export default connect(mapStateToProps, {
  createCampaignSupplierContract,
  applyDiscount,
  sendPriceProposal,
})(Form);
