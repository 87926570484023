import * as React from 'react';
import { connect } from 'react-redux';
import { loadSupplierOptions } from '../../actions/supplier_actions';
import Supplier from '../Inventory/Filters/Supplier.jsx'
import { setSupplier } from './actions';

class SupplierFilter extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {};
    this.setSupplier = this.setSupplier.bind(this);
  }

  componentDidMount() {
    const { currentUser, loadSupplierOptions } = this.props;
    if (currentUser.is_admin) {
      loadSupplierOptions();
    };
  }

  setSupplier(selection) {
    const supplier_token = selection.supplier;
    const { setSupplier } = this.props;
    setSupplier(supplier_token);
  }

  render() {
    const { currentUser, suppliers } = this.props;
    if (currentUser.is_admin && suppliers && suppliers.length > 0) {
      return (
        <div className="filters_bar" style={{
          width: 200,
          background: '#fafafa',
          border: 'none',
          marginLeft: -15,
          top: '',
          bottom: '',
          right: '',
          position: 'unset'
        }}>
          <div className="filters_bar_list">
            <Supplier setFilter={this.setSupplier}/>
          </div>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    };
  }
}

export default connect(
  // @ts-ignore
  ({ currentUser, suppliers }) => ({ currentUser, suppliers }),
  { loadSupplierOptions, setSupplier }
)(SupplierFilter);
