import React from 'react'
import createClass from 'create-react-class'
import { connect } from 'react-redux';
import * as Actions from '../Companies/actions'
import GlobalActions from '../../actions/GlobalActions';
import { browserHistory } from 'react-router-legacy'

const roles = [
  'Marketing',
  'Performance Marketing',
  'Growth Marketing',
  'Brand',
  'Acquisition',
  'Media'
]

const Group = createClass({
  displayName: 'Group',

  getInitialState() {
    return {
      roles: []
    }
  },

  componentWillMount() {
    const { pooler, group } = this.props

    this.setLocalStorage()
    this.debouncedPollProgress = _.debounce((job_id, group_id) => {
      pooler(job_id, group_id)
    }, 3000)
    
    if (group.scraping) {
      const scrape = JSON.parse(localStorage.getItem('scrape'))
      if(_.get(scrape[group.id], 'job_id')) {
        this.debouncedPollProgress(scrape[group.id]['job_id'], group.id)
      }
    }
  },

  componentDidUpdate(prevProps) {
    const { scrape, group } = this.props
    if(prevProps.scrape != this.props.scrape) {
      if(_.get(scrape[group.id], 'job_id')) {
        this.debouncedPollProgress(scrape[group.id].job_id, group.id)
        const local_scrape = JSON.parse(localStorage.getItem('scrape'))
        local_scrape[group.id] = scrape[group.id]
        localStorage.setItem('scrape', JSON.stringify(local_scrape))
      }
    } 
    else if(this.props.scrape[group.id] && !group.scraping) {
      this.debouncedPollProgress.cancel()
    }
  },

  async scrapeGroup(e) {
    const { onScrape } = this.props
    e.preventDefault()
    const { group } = this.props
    const { roles } = this.state
    if(_.isEmpty(roles)) {
      return GlobalActions.showError(
        "Select at least 1 role"
      );
    }
    onScrape(group.id, roles)
  },

  setLocalStorage() {
    const storage = localStorage.getItem('scrape')
    !storage && localStorage.setItem('scrape', JSON.stringify({}))
  },

  currentProgress() {
    const { scrape, group } = this.props
    const current = scrape[group.id]['completed_tasks'] + scrape[group.id]['failed_tasks']
    const total = scrape[group.id]['total_tasks']
    const progress = (current*100)/total
    return progress.toFixed(0)
  },

  confirmDeleteModal() {
    const { group, deleteGroup } = this.props
    const popupOptions = {
      onConfirmSubmit: deleteGroup,
      headerText: "Confirm company group deletion?",
      bodyText: `The company group ${group.name} is going to be deleted`,
      data: group.id
    }
    GlobalActions.openPopup('confirm-submit', popupOptions)
  },

  toggleRole(role) {
    const { roles } = this.state
    if (roles.includes(role)) {
      return this.setState({ roles: roles.filter(r => r !== role) })
    }
    this.setState({ roles: [...roles, role] })
  },

  viewAccountNameProgress() {
    const { group } = this.props
    browserHistory.push(`/company_groups/${group.id}/progress`)
  },

  render() {
    const { group, companies, scrape, hideDelete, showProgressButton } = this.props
    return (
      <div className="panel panel-default" style={{ marginBottom:10 }}>
        <div className="panel-body" >
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, auto)', alignItems: 'center', marginBottom: '15px' }}>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <span><b>Group: </b> {group.name}</span> <span><b>Company count: </b> {companies.length}</span>
            </div>
            { !hideDelete &&
              <div>
                <button style={{ width: '100%', padding: '15px' }} className='btn btn-danger' onClick={() => this.confirmDeleteModal()}>Delete</button>
              </div>
            }
            {
              showProgressButton &&
              <div>
                <button style={{ width: '100%', padding: '15px' }} className='btn btn-primary' onClick={() => this.viewAccountNameProgress()}>View Progress</button>
              </div>
            }
          </div>
          <div>
            <form onSubmit={this.scrapeGroup}>
              <div className="section">
                <div className="row subsection">
                  <div className="col-sm-12">
                    <span className="section-name">Roles:</span>
                    <div>
                      {roles.map((r,k) => {
                        return (
                          <label key={k} className="checkbox-inline">
                            <input type="checkbox" onClick={() => this.toggleRole(r)} value={r} /> {r}
                          </label>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <button disabled={group.scraping} type="submit" style={{ marginTop: '10px' }} className='btn btn-primary btn-submit'>Scrape</button>
              </div>
            </form>
          </div>
          {scrape[group.id] && group.scraping &&
            <div className="progress" style={{ marginTop: '10px', height: '50px'}}>
              <div className="progress-bar" aria-valuenow={this.currentProgress()} aria-valuemin="0" aria-valuemax="100" style={{ display:'flex', justifyContent:'center', alignItems:'center', width: `${this.currentProgress()}%`}}>
                {`${this.currentProgress()}%`}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
})

const mapActions = {
  onScrape: Actions.asyncScrapeGroup,
  pooler: Actions.asyncPoller,
  deleteGroup: Actions.asyncDeleteGroup
}

export default connect(null, mapActions)(Group);