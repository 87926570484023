import React, { Component } from "react";
import ReactSelect from "react-select";
import * as styleGen from "../styles";
import { SelectOption } from "../../../../models/production_orders";

interface IBulkOptionSelectorProps {
  label: string;
  options: SelectOption[];
  selected: SelectOption | null;
  onChange: (option: SelectOption) => void;
}

interface IBulkOptionSelectorState {}

class BulkOptionSelector extends Component<
  IBulkOptionSelectorProps,
  IBulkOptionSelectorState
> {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, options, selected, onChange } = this.props;
    return (
      <div style={styleGen.orderActionInput()}>
        <label>{label}</label>
        <ReactSelect
          placeholder="Choose one"
          value={selected}
          options={options}
          onChange={option => onChange(option)}
        />
      </div>
    );
  }
}

export default BulkOptionSelector;
