import React, { PureComponent } from "react";
import UploadButtonList from "../UploadButtonList";
import UploadDesignAssetAction from "../UploadDesignAssetAction";
import UploadPopWithPostedDate from "../UploadPopWithPostedDate";
import { ICampaignPermissions } from "../../../../models/campaign";
import * as styleGen from "../styles";

interface ISubmittedActionProps {
  unit: any;
  campaign_permissions: ICampaignPermissions;
  campaign_id: string;
  callback?: (response) => void;
}

class Action extends PureComponent<ISubmittedActionProps> {
  newStyle = { ...styleGen.uploadButton(), borderRadius: 0 }
  constructor(props) {
    super(props);
  }

  public render() {
    const { campaign_permissions } = this.props;
    return (
      <UploadButtonList
        campaign_permissions={campaign_permissions}
        renderUploadButton={this.renderUploadButton.bind(this)}
        renderUploadPopButton={this.renderUploadPopButton.bind(this)}
      />
    )
  }

  private renderUploadButton(disabled: boolean = false) {
    const { unit, campaign_id, callback } = this.props;
    return (
      <UploadDesignAssetAction
        progressBarColor={styleGen.uploadButton().background}
        unit={unit}
        campaign_id={campaign_id}
        callback={callback} >
        <button className="btn btn-default" style={this.newStyle} onClick={(e) => e.preventDefault()} disabled={disabled}>
          Creative
        </button>
      </UploadDesignAssetAction>
    );
  }

  private renderUploadPopButton() {
    const { unit, campaign_id, campaign_permissions, callback } = this.props;
    return (
      <UploadPopWithPostedDate
        unit={unit}
        campaign_permissions={campaign_permissions}
        campaign_id={campaign_id}
        callback={callback}
        buttonStyle={this.newStyle}
      />
    );
  }
}

export default Action;
