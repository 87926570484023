import "rc-tooltip/assets/bootstrap.css";

import cs from "classnames";
import _ from "lodash";
import moment from "moment";
import Tooltip from "rc-tooltip";
import * as React from "react";
import { connect } from "react-redux";

import {
  getFlightDates,
  getRejectAssetFromAssets,
  getRejectionString,
  isRejected,
  state_map,
} from "../../../models/design_assets";
import SupplierName from "../../SupplierName";
import DesignAssetCardItem from "./DesignAssetCardItem";
import DueDate from "./DueDate";
import FaceId from "./FaceId";
import IncompleteMainAction from "./Incomplete/IncompleteMainAction";
import PillBadge from "./PillBadge";
import PopCardItem from "./PopCardItem";
import PostedMainAction from "./Posted/PostedMainAction";
import ProductionOrderCardItem from "./ProductionOrderCardItem";
import RejectedMainAction from "./Rejected/RejectedMainAction";
import ShippingAddress from "./ShippingAddress";
import Size from "./Size";
import SpecSheet from "./SpecSheet";
import SubmittedMainAction from "./Submitted/SubmittedMainAction";
import TakeDownDate from "./TakeDownDate";
import Thumbnail from "./Thumbnail";
import UnitTypeIcon from "./UnitTypeIcon";
import PackageOverage from "./CircleGraphics/PackageOverage";
import ViewUnit from "./ViewUnit";

interface CardProps {
  status: string;
  active: boolean;
  unit: any;
  order?: any;
  campaign: any;
  campaign_permissions: any;
  currentUser: any;
  shipping_addresses: any[];
  onUnitSelected: (unit: any, selected: boolean) => void;
  fetchProductionOrders?: (campaignId) => void;
}

interface CardState {
  show_admin_info: boolean;
  submitted: boolean;
  callback_response: string;
}

class Card extends React.Component<CardProps, CardState> {
  private style = {
    thumb: { paddingRight: "10px", width: "8%" },
    unit_info: { padding: "0px 10px", width: "25%" },
    size: { padding: "0px 10px", width: "12%" },
    size_div: { fontSize: 11, marginBottom: 5 },
    type: { padding: "0px 10px", width: "14%" },
    type_label: { fontSize: 11, marginBottom: 5 },
    spec_sheet: { padding: "0px 10px", width: "15%" },
    due_date: { padding: "0px 10px", width: "10%" },
    actions: { padding: "0px 10px", width: "13%" },
    selection: { padding: "0px 10px", width: "2%" },
    flight_date: { color: "#777777", fontStyle: "italic", fontSize: 13 },
  };

  constructor(props) {
    super(props);
    this.showAdminOnlyInfo = this.showAdminOnlyInfo.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toogleSubmitted = this.toogleSubmitted.bind(this);

    this.state = {
      show_admin_info: false,
      submitted: false,
      callback_response: "",
    };
  }

  renderNoteIcon() {
    const { unit } = this.props;
    const { posting_note, posting_note_by, posting_note_at } = unit;

    return (
      <div className="note_present">
        <Tooltip
          placement="bottom"
          overlay={
            <div>
              {posting_note}
              <br></br>
              <span className="note-details">
                {posting_note_by} - {posting_note_at ? moment(posting_note_at).format("LLL") : ""}
              </span>
            </div>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <i className="fa fa-sticky-note posting_note_icon" aria-hidden="true"></i>
        </Tooltip>
      </div>
    );
  }

  public componentDidUpdate(prevProps, prevState) {
    if (this.state.submitted) {
      setTimeout(() => { this.setState({ submitted: false }); }, 5000);
    }
  }

  getOrderItems() {
    const { order } = this.props
    return !!order ? order.items : [];
  }

  public render() {
    const {
      active,
      unit,
      campaign_permissions,
      campaign,
      status,
      shipping_addresses,
    } = this.props;
    const isCompass = _.get(campaign, "advertiser.name") === "Compass";
    const { show_admin_info, submitted } = this.state;
    if (submitted) {
      return this.renderCallbackResponse();
    }
    if (!unit || _.isEmpty(unit)) {
      return null;
    }

    const order_shipping_address = this.getAddressFromUnitOrderItem()
    const shipping_address = order_shipping_address ? order_shipping_address : shipping_addresses[unit.supplier_address_id]

    return (
      <div id={unit.id} className={cs("design_card", { rejected_card: isRejected(unit) })}>
        {this.renderRejectionHeader(unit)}
        <div className={cs("campaign_designs_card", { active: active })} onClick={this.toggleSelection}>
          <div className="thumb" style={this.style.thumb}>
            <Thumbnail unit={unit} />
            <div style={{ width: 64, textAlign: 'center' }}>
              <ViewUnit unit={unit} currentUser={this.props.currentUser} />
            </div>
          </div>
          <div className="unit-info" style={this.style.unit_info} onDoubleClick={() => this.showAdminOnlyInfo(unit)}>
            {show_admin_info ? (
              <div className="title_container">
                <h2 className="title">cu: {unit.campaign_unit_token}</h2>
              </div>
            ) : (
              <div className="title_container">
                {unit.supplier_face_id && !unit.lime && (
                  <FaceId
                    label={
                      campaign.campaign.is_mock || campaign.campaign.hide_face_ids ? unit._id : unit.supplier_face_id
                    }
                  />
                )}
                {this.renderUnitName() && <h2 className="title">{unit.name}</h2>}
              </div>
            )}
            <UnitTypeIcon isLime={unit.lime} type={unit.unit_type} />
            <PillBadge label={unit.screen_type || "static"} />
            {campaign_permissions && campaign_permissions.can_view_supplier_name && (
              <SupplierName
                className="badge badge-light"
                user={this.props.currentUser}
                supplier={{
                  name: unit.supplier_name,
                  is_managed: unit.is_managed,
                  is_fully_submitted: unit.is_fully_submitted,
                  is_broker: unit.is_broker
                }}
              />
            )}
            <div style={this.style.flight_date}>Flight: {getFlightDates(unit)}</div>
            {shipping_address && <ShippingAddress shipping_address={shipping_address} />}
          </div>
          <div className="size" style={this.style.size_div}>
            <ul>
              <li style={this.style.size}>Size</li>
              <Size size={unit.size} isLime={unit.lime} />
            </ul>
          </div>
          <div className="type" style={this.style.type}>
            <ul>
              <li style={this.style.type_label}>Type</li>
              <li style={{ color: "#273438" }}>{_.startCase(unit.unit_type)}</li>
            </ul>
          </div>
          {status !== state_map.posted && (
            <div className="spec-sheet" style={this.style.spec_sheet}>
              <SpecSheet unit={unit} campaign_permissions={campaign_permissions} campaignId={campaign.campaignId} />
            </div>
          )}
          <div className="due_date" style={this.style.due_date}>
            <DueDate unit={unit} isCompass={isCompass} />
          </div>
          {this.getOrderItemForUnit() && this.renderOverage(unit)}
          {status === state_map.posted && (
            <div className="due_date" style={this.style.due_date}>
              <TakeDownDate unit={unit} campaign={campaign} />
            </div>
          )}
          <div className="actions" style={this.style.actions}>
            <ul>
              <li style={{ fontSize: 11, marginBottom: 5 }}>&nbsp;</li>
              {this.renderMainAction(status, unit, campaign, campaign_permissions)}
            </ul>
          </div>
          <div className="selection" style={this.style.selection}>
            <ul>
              <li style={{ fontSize: 11, marginBottom: 5 }}>&nbsp;</li>
              <input type="checkbox" onChange={this.toggleSelection} checked={active}></input>
            </ul>
          </div>
          {unit.posting_note && this.renderNoteIcon()}
        </div>
        {this.renderDesignCardItems(status, unit, campaign, campaign_permissions)}
      </div>
    );
  }

  renderOverage(unit) {
    if (!unit.is_package) return

    return <div className="overage">
      <PackageOverage unitOverage={this.getOrderItemForUnit().overage} campaignUnitToken={unit.campaign_unit_token}></PackageOverage>
    </div>
  }

  getOrderItemForUnit() {
    const { campaign_unit_token } = this.props.unit
    const orderItems = this.getOrderItems()
    return orderItems.find(i => i.campaign_unit_token == campaign_unit_token)
  }

  getAddressFromUnitOrderItem() {
    const orderItem = this.getOrderItemForUnit()
    if (!orderItem) { return }
    return {
      address_line: `${orderItem.recipient_address_1 || ""} ${orderItem.recipient_address_2 || ""}`.trim(),
      city: orderItem.recipient_city,
      zip_code: orderItem.recipient_postal_code,
      state: orderItem.recipient_state,
    }
  }

  private renderCallbackResponse() {
    const { callback_response } = this.state;
    const message = {
      posted: "Unit successfully posted!",
      submitted: "Unit successfuly submitted!",
    };
    return (
      <div className="design_card">
        <div style={{ flexDirection: "column" }} className="campaign_designs_card">
          <i style={{ color: "green", fontSize: "45px" }} className="fa fa-check-circle-o"></i>
          <h3>{message[callback_response] || "Unit successfully submitted to production!"}</h3>
        </div>
      </div>
    );
  }

  private renderMainAction(status, unit, campaign, campaign_permissions) {
    switch (status) {
      case state_map.incomplete:
        return (
          <IncompleteMainAction
            unit={unit}
            campaign_id={campaign.campaignId}
            campaign_permissions={campaign_permissions}
            callback={this.toogleSubmitted}
          />
        );
      case state_map.submitted:
        return (
          <SubmittedMainAction
            unit={unit}
            campaign_id={campaign.campaignId}
            campaign_permissions={campaign_permissions}
            callback={this.toogleSubmitted}
          />
        );
      case state_map.posted:
        return (
          <PostedMainAction unit={unit} campaign_id={campaign.campaignId} campaign_permissions={campaign_permissions} />
        );
      case state_map.rejected:
        return (
          <RejectedMainAction
            unit={unit}
            campaign_id={campaign.campaignId}
            campaign_permissions={campaign_permissions}
          />
        );
    }
  }

  private renderRejectionHeader(unit) {
    if (!isRejected(unit)) {
      return <div />;
    }
    const rejectedAsset = getRejectAssetFromAssets(unit);
    return (
      <div className="rejection_header" title={getRejectionString(unit)}>
        <i className="fa fa-exclamation-triangle" />
        {rejectedAsset.rejection_reason}
      </div>
    );
  }

  private renderUnitName() {
    const { campaign } = this.props;
    if (campaign.campaign && campaign.campaign.is_mock) {
      return false;
    }
    return true;
  }

  private renderDesignCardItems(status, unit, campaign, campaign_permissions) {
    const { order } = this.props;
    const { design_assets, proofs } = unit;
    const hasAssets = !_.isEmpty(design_assets);
    const hasProof = !_.isEmpty(proofs);
    if (status === "circlegraphics") {
      // @ts-ignore
      return <ProductionOrderCardItem unit={unit} order={order} campaignId={campaign.campaignId} fetchProductionOrders={this.props.fetchProductionOrders} />;
    } else {
      return (
        <div className="design_card_items">
          {hasAssets && (
            <DesignAssetCardItem unit={unit} campaign={campaign} campaign_permissions={campaign_permissions} status={status} />
          )}
          {hasProof && this.renderAssetProofItem(unit)}
          {unit.pop && <PopCardItem unit={unit} campaign={campaign} campaign_permissions={campaign_permissions} />}
        </div>
      );
    }
  }

  private renderAssetProofItem(unit) {
    const { proofs } = unit;
    if (!!!proofs) {
      return <div />;
    }
    return <div>{proofs.map(proof => this.renderProof(proof))}</div>;
  }

  private renderProof(proof) {
    const { asset_name, asset_url } = proof;
    const file_name = asset_name;
    return (
      <div key={`proof_item_${proof.id}`} className="design_card_item">
        <span style={{ marginRight: 5 }}>Digital Proof:</span>
        <a target="_blank" href={asset_url}>
          <i className="fa fa-file-o margin-right" aria-hidden="true" />
          {file_name}
        </a>
      </div>
    );
  }

  private showAdminOnlyInfo(unit) {
    if (this.props.currentUser.is_admin) {
      this.setState({ show_admin_info: !this.state.show_admin_info });
    }
  }

  private toggleSelection(event) {
    const { active, unit } = this.props;
    this.props.onUnitSelected(unit, !active);
  }

  private toogleSubmitted(callback_response) {
    const { submitted } = this.state;
    this.setState({ submitted: !submitted, callback_response });
  }
}

export default connect(
  (state: any) => ({
    currentUser: state.currentUser,
    shipping_addresses: state.shipping_addresses,
  }),
  {}
)(Card);
