import {
  CLEAR_CAMPAIGN_UNITS,
  LOAD_CAMPAIGN_UNITS,
  LOAD_ALL_CAMPAIGN_UNITS,
  CREATE_HOLDS,
  LOAD_MORE_RESULTS,
  CREATE_BOOKINGS,
  SUBMIT_BOOKINGS_JOB,
  HAS_CONFLICTS,
  UPDATE_CAMPAIGN_UNITS,
  REMOVE_FLIGHTS,
} from "./actions";

const initialState = {
  campaign_id: null,
  units: [],
  units_updated: [],
  offset: 0,
  job_id: null,
  unavailable_units: [],
  available_units: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CAMPAIGN_UNITS:
      return { ...initialState, units: [...state.units] };
    case LOAD_CAMPAIGN_UNITS: {
      return loadCampaignUnits(state, action.payload);
    }
    case LOAD_ALL_CAMPAIGN_UNITS: {
      return loadAllCampaignUnits(state, action.payload);
    }
    case CREATE_HOLDS: {
      return createHolds(state, action.payload);
    }
    case LOAD_MORE_RESULTS: {
      return loadMoreResults(state, action.payload);
    }
    case CREATE_BOOKINGS: {
      return createBookings(state, action.payload);
    }
    case HAS_CONFLICTS: {
      return hasConflicts(state, action.payload);
    }
    case SUBMIT_BOOKINGS_JOB: {
      return submitBookingsJob(state, action.payload);
    }
    case UPDATE_CAMPAIGN_UNITS: {
      return updateCampaignUnits(state, action.payload);
    }
    case REMOVE_FLIGHTS: {
      return removeCampaignUnits(state, action.payload);
    }
    default:
      return state;
  }
};

function loadMoreResults(state, payload) {
  const { campaign_id, more_results_count, offset, showing_all_flights } = payload;
  const units = state.units.concat(payload.units);
  if (!showing_all_flights) {
    units.forEach(
      unit =>
        (unit.campaign_units = unit.campaign_units.filter(cu => cu.campaign_id == campaign_id))
    );
  }

  return {
    ...state,
    more_results_count,
    offset,
    units: [...units.filter(u => u.campaign_units && u.campaign_units.length > 0)],
    loading_more: false
  };
}

function loadCampaignUnits(state, payload) {
  const { campaign_id, more_results_count, offset } = payload;
  let { units } = payload;

  units.forEach(unit => (unit.campaign_units = unit.campaign_units.filter(cu => cu.campaign_id == campaign_id)));
  return {
    ...state,
    campaign_id,
    units: [...units.filter(u => u.campaign_units && u.campaign_units.length > 0)],
    more_results_count,
    offset,
    units_updated: [],
    available_units: [],
    unavailable_units: []
  };
}

function loadAllCampaignUnits(state, payload) {
  const { units, campaign_id, more_results_count, offset } = payload;
  const filtered_units = units.filter(u => {
    const campaign_units_count = u.campaign_units.filter(cu => {
      return cu.campaign_id == campaign_id;
    }).length;
    return campaign_units_count > 0;
  });
  return {
    ...state,
    campaign_id,
    units: [...filtered_units.filter(u => u.campaign_units && u.campaign_units.length > 0)],
    more_results_count,
    offset,
    units_updated: [],
    unavailable_units: [],
    available_units: []
  };
}

function createHolds(state, payload) {
  const { units_updated, units } = unitsFromPayload(state, payload, "on_hold");
  return {
    ...state,
    units: [...units],
    units_updated
  };
}

function submitBookingsJob(state, payload) {
  const { job_id } = payload;
  return {
    ...state,
    job_id
  };
}

function createBookings(state, payload) {
  const { units_updated, units } = unitsFromPayload(state, payload, "booked");
  const { booked_campaign_token } = payload;
  return {
    ...state,
    units: [...units],
    units_updated,
    booked_campaign_token,
    available_units: [],
    unavailable_units: []
  };
}

function hasConflicts(state, payload) {
  const { available_units, unavailable_units } = payload;
  return {
    ...state,
    available_units,
    unavailable_units,
    units_updated: []
  };
}

function removeCampaignUnits(state, payload) {
  const { campaign_id, campaign_units_deleted } = payload;
  const { units } = state;
  const deleted_ids = campaign_units_deleted.map(unit => unit.slug);

  return {
    ...state,
    campaign_id,
    units: units.filter(unit => !deleted_ids.includes(unit.slug)),
    units_updated: campaign_units_deleted
  };
}

function updateCampaignUnits(state, payload) {
  const { campaign_id, units_updated } = payload;
  const { units } = state;

  const units_after_update = updateCampaignUnitsByToken(units, units_updated);

  return {
    ...state,
    campaign_id,
    units: units_after_update,
    units_updated
  };
}

// helper functions

function updateCampaignUnitsByToken(units, updated_values) {
  return units.map(unit => {
    return {
      ...unit,
      campaign_units: unit.campaign_units.map(campaign_unit => {
        const updated_value = updated_values.find(val => val.token == campaign_unit.token);
        if (updated_value) {
          const { start_date, end_date, price, installation_cost, production_cost, rationale } = updated_value;
          return { ...campaign_unit, start_date, end_date, price, installation_cost, production_cost, rationale };
        } else return campaign_unit;
      })
    };
  });
}

function unitsFromPayload(state, payload, workflow_state) {
  const { campaign_units_updated } = payload;
  const units = updateCampaignUnitsWorkflowState(
    state.units,
    campaign_units_updated,
    workflow_state
  );
  const units_updated = campaign_units_updated.map(campaign_unit => campaign_unit.supplier_face_id);
  return { units_updated, units };
}

function updateCampaignUnitsWorkflowState(units, campaign_units_updated, workflow_state) {
  const face_ids = campaign_units_updated.map(u => u.supplier_face_id);
  const campaign_unit_ids = campaign_units_updated.map(u => u.campaign_unit_id);
  return units.map(unit =>
    face_ids.includes(unit.supplier_face_id)
      ? {
          ...unit,
          campaign_units: setWorkflowState(campaign_unit_ids, unit, workflow_state)
        }
      : unit
  );
}

function setWorkflowState(campaign_unit_ids, unit, workflow_state) {
  return unit.campaign_units.map(campaign_unit =>
    campaign_unit_ids.includes(campaign_unit.id)
      ? { ...campaign_unit, workflow_state }
      : campaign_unit
  );
}
