import moment from 'moment';
import momentRange from 'moment-range';
import accounting, { formatNumber } from "accounting";

class UnitModel {

  constructor(unit) {
    this.unit = unit;
  }

  prettyScreenType() {
    let screenType = this.unit.screen_type;
    if (screenType) {
      screenType = screenType.charAt(0).toUpperCase() + screenType.substr(1);
    } else {
      screenType = "Static";
    }
    return screenType;
  }

  // gets available flight ranges
  getAvailableFlightRanges() {
    const unit = this.unit;
    const availableFlights = unit.campaign_units.filter(flight => (
      flight.workflow_state == 'available' || flight.workflow_state == 'on_hold' || flight.workflow_state == 'proposed'
    ));
    const dateRanges = availableFlights.map(flight => {
      let flightStartDate = moment(flight.start_date);
      let flightEndDate = moment(flight.end_date);
      //if (unit.is_static) { flightStartDate = this.ensureLeadTime(flightStartDate, flightEndDate) }

      return {
        state: 'available',
        range: moment.range(
          moment(flightStartDate).format('MM/DD/YYYY'),
          moment(flightEndDate).format('MM/DD/YYYY'),
        ),
      }
    });
    return dateRanges;
  }

  // gets the first date of first available flight and last date of last flight
  getFirstAndLastDates(dateRanges=null) {
    dateRanges = dateRanges || this.getAvailableFlightRanges();
    const firstAvailableDate = dateRanges.map(dateRange => dateRange.range.start).sort()[0]
    const sortedEndingDates = dateRanges.map(dateRange => dateRange.range.end).sort()
    const lastAvailableDate = sortedEndingDates[sortedEndingDates.length - 1]
    return { startDate: firstAvailableDate, endDate: lastAvailableDate }
  }

  // adds time to start date to ensure there are 6 or more days between startDate and upcoming Monady
  ensureLeadTime(startDate, endDate) {
    // requirement: at least 7 days between now and the first upcoming Monday
    let daysTillStart = startDate.diff(moment(), 'days')
    // if startDate is before 7 days from now and endDate is at least 1 week in the future
    if (daysTillStart < 7 && endDate > moment().add(1,'week')) {
      // if today == monday, just add 1 week to today
      if (moment().weekday() === 1) {
        startDate = moment().add(7, 'days');
      } else {
        // if today is after monday, find upcoming monday then add 1 week to that
        let endOfWeek = moment().endOf('week') // returns Saturday
        startDate = endOfWeek.add(9, 'days') // get to the next Monday
      }
    }
    return startDate;
  }

  getCpm(price, impressions) {
    const cpm = price / (impressions / 1000.0);
    return `${(Math.round(cpm * 20.0) / 20.0).toFixed(2)}`;
  }

  getPrice(unit, { lower, higher }) {
    const lower_price = Math.round(unit[lower] / 25) * 25
    const higher_price = Math.round(unit[higher] / 25) * 25
    return `${accounting.formatMoney(lower_price)} \u2013 ${accounting.formatMoney(higher_price)}`
  }

  getPriceForDuration(unit) {
    return accounting.formatMoney(unit.price_for_duration)
  }

  getMockCpmValues() {
    const unit = this.unit.properties ? this.unit.properties : this.unit;
    if (!unit.impressions) { return; }
    if (!unit.lower_price && !unit.higher_price) { return; }
    return ({
      min_cpm: parseFloat(this.getCpm(unit.lower_price, unit.impressions)),
      max_cpm: parseFloat(this.getCpm(unit.higher_price, unit.impressions))
    });
  }

  getMockCpm(campaign) {
    const { enable_analytics_cpm, analytics_charge_method, analytics_cpm } = campaign || {};

    const unit = this.unit.properties ? this.unit.properties : this.unit
    const agency_markup = (_.has(campaign, 'agency_markup') && _.has(campaign, 'price_agency_markup')) ? parseFloat(campaign.agency_markup) : 0.0;
    if (campaign && campaign.is_mock) {
      if (!unit.impressions) { return; }
      if (!unit.lower_price && !unit.higher_price) { return; }

      return `$${this.getCpm(unit.lower_price, unit.impressions)} \u2013 $${this.getCpm(unit.higher_price, unit.impressions)}`;
    }
    if(agency_markup > 0.0) {
      if(!unit.impressions) {return;}
      if(!unit.price) {return;}
      return this.getCpm(unit.price, unit.impressions);
    }
    if (!unit.cpm) return null
    return this.getCpmWithAnalytics(enable_analytics_cpm, analytics_charge_method, unit.cpm, analytics_cpm)
  }

  getCpmWithAnalytics(enable_analytics_cpm, analytics_charge_method, original_cpm, analytics_cpm) {
    if (enable_analytics_cpm && analytics_charge_method === 'baked_in') {
      const mediaCpm = accounting.formatMoney(original_cpm)
      const analyticsCpm = accounting.formatMoney(analytics_cpm, '').replace(/^0\./, '.')
      return `${mediaCpm} + ${analyticsCpm}`
    } else {
      return accounting.formatMoney(original_cpm)
    }
  }

  getMockPrice(campaign) {
    const unit = this.unit.properties ? this.unit.properties : this.unit
    if(_.get(campaign, 'is_mock')) {
      if(!!unit.lower_price) { return this.getPrice(unit, { lower: 'lower_price', higher: 'higher_price' }) }
    }
    return unit.price_with_analytics
      ? accounting.formatMoney(unit.price_with_analytics)
      : unit.price
      ? accounting.formatMoney(unit.price)
      : null;
  }

  getMockRateCardPrice(campaign) {
    const unit = this.unit.properties ? this.unit.properties : this.unit
    if(_.get(campaign, 'is_mock')) {
      if (!unit.rate_card_price) { return }
      return this.getPrice(unit, { lower: 'rate_card_lower_price', higher: 'rate_card_higher_price' })
    }
    return accounting.formatMoney(unit.rate_card_price)
  }

}

export default UnitModel;
