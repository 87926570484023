export default class UrlParams {
  constructor(search) {
    this.queryString = (search || location.search).substr(1);
    this.params = {};
    this.parseQueryString();
  }

  parseQueryString() {
    this.queryString.split('&').reduce((a, b) => {
      let [key, val] = b.split('=');
      a[key] = val;
      return a
    }, this.params);
  }

  get(key) {
    if (this.params[key]) {
      return decodeURIComponent(this.params[key]);
    } else {
      return null;
    }
  }
};
