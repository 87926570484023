import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import PlacesStore from "../../stores/PlacesStore";

// TODO: Improve to set focus to the input when opening the modal
class AddCustomPOIFormModal extends Component {
    constructor(props) {
        super(props);
        this.onHide = this.onHide.bind(this);
        this.onClose = this.onClose.bind(this);
        this.state = {
            name: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    };

    handleChange(event) {
        this.setState({name: event.target.value});
    }

    handleSubmit(event) {
        const self = this;
        axios.post(`/api/v1/campaigns/${this.props.campaign_id}/custom_point_of_interests`, {
            custom_point_of_interest: {
                name: this.state.name,
                latitude: this.props.current_custom_poi.poi.lat,
                longitude: this.props.current_custom_poi.poi.lng
            }
        }).then(function(data) {
            PlacesStore.trigger('places:updated');
            self.onHide();
        }).catch(function(error) {
            document.querySelectorAll('.custom_point_of_interest_marker').forEach(function(m) { m.remove() })
            alert("There was an error");
            self.onHide();
        });

        event.preventDefault();
    }

    onHide() {
        const { onHide } = this.props;
        onHide();
        document.querySelectorAll('.custom_point_of_interest_marker').forEach(function(m) { m.remove() })
    }

    onClose() {
        const { onHide } = this.props;
        document.querySelectorAll('.custom_point_of_interest_marker').forEach(function(m) { m.remove() })
        onHide();
    }

    render() {
        const { show } = this.props;

        return (
            <Modal show={show} onHide={this.onHide} className="custom_poi_form_modal">
                <form onSubmit={this.handleSubmit}>
                <Modal.Header>
                    <Modal.Title>Add custom POI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Placemarker Name</label>
                        <input type="text" className="form-control" value={this.state.value} onChange={this.handleChange} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onClose}>Close</Button>
                    <Button type="submit" bsStyle="primary">
                        Submit
                    </Button>
                </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default connect(({ map }) => ({ current_custom_poi: map.current_custom_poi }))(AddCustomPOIFormModal);
