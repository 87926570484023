import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';

export default createClass({
  propTypes: {
    supplier: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {};
  },

  render() {
    const { supplier } = this.props;
    const customStyle = { maxHeight: 100, maxWidth: '100%'};
    const defaultStyle = { maxHeight: 40, maxWidth: '100%' };
    const isPublicis = supplier.name === "Publicis" && supplier.id === 118;
    const styles = isPublicis ? customStyle : defaultStyle;

    if (supplier && supplier.logo && supplier.background_color) {
      return (<div style={{ padding: 20, background: supplier.background_color }}>
        <img src={supplier.logo} style={styles} />
      </div>);
    }
    return <div />;
  },
});
