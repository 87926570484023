import { connect } from 'react-redux';
import Measure from "react-measure";
import React from "react";
import moment from "moment";

import { getMonday } from "../../utils/dateCalculator";
import { sidebarUnits, withinBounds } from '../../models/Units';
import { toggleUnitPopup } from '../NewMap/actions';
import DigitalListItem from "./DigitalListItem";
import Header from "./Header";
import ListItem from "./ListItem";
import MapActions from '../../actions/MapActions';
import UnitModel from "../../models/unitModel";

class Listings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      position: 0,
      unitsSelected: false,
      limit: 20,
      per_page: 20
    };
  }

  componentDidMount() {
    if (!this.props.isInventoryView) {
      this.setFirstAvailableFlight();
    }

    $(window).scroll(this.scrollHandler.bind(this));
  }

  componentWillUnmount() {
    $(window).off("scroll", this.scrollHandler.bind(this));
  }

  scrollHandler(e) {
    var $el = $(".fixedElement");
    var isPositionFixed = $el.css("position") == "fixed";

    if ($(window).scrollTop() > 175 && !isPositionFixed) {
      $(".fixedElement").css({ position: "fixed", top: "0px" });
    }
    if ($(window).scrollTop() < 175 && isPositionFixed) {
      $(".fixedElement").css({ position: "static", top: "0px" });
    }
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  setFirstAvailableFlight() {
    let flights = [];
    this.props.data.units.forEach(unit =>
      flights = flights.concat(
        unit.campaign_units.filter(
          flight =>
            flight.workflow_state == "available" ||
            flight.workflow_state == "on_hold" ||
            (this.props.campaign && flight.workflow_state == "proposed")
        )
      )
    );
    const firstFlightStartDate = flights
      .map(flight => flight.start_date)
      .sort()[0];
    this.setState({ firstFlightStartDate });
  }

  onListClick(unit_id) {
    this.props.onUnitListingClick(unit_id);
  }

  onListOver(unit) {
    this.props.toggleUnitPopup(unit.id);
  }

  onListOut(unit) {
    this.props.toggleUnitPopup();
  }

  getInterval() {
    return this.props.filters && this.props.filters.interval == "month"
      ? "month"
      : "week";
  }

  getNextDates(interval = "week") {
    const { filters } = this.props;

    const defaultCount = 12;
    const startDate = this.getStartDate();
    const intervalsCount = filters.endDate
      ? moment()
          .range(filters.startDate, filters.endDate)
          .diff(interval)
      : defaultCount;
    const dateObjects = [];

    for (
      let i = this.state.position;
      i < this.state.position + intervalsCount;
      i++
    ) {
      const date = moment(startDate)
        .add(i, interval)
        .toDate();
      dateObjects.push(date);
    }

    return dateObjects;
  }

  getStartDate() {
    const { filters } = this.props;
    let startDate;
    if (filters.startDate) {
      startDate = moment(filters.startDate);
    } else if (this.state.firstFlightStartDate) {
      startDate = moment(this.state.firstFlightStartDate).subtract(
        1,
        this.getInterval()
      );
    } else {
      if (filters.interval == "month") {
        startDate = moment().startOf("month")._d
      } else {
        startDate = getMonday(new Date());
      }
    }
    return startDate;
  }

  onLeft() {
    this.setState({ position: this.state.position - 1 });
  }

  onRight() {
    this.setState({ position: this.state.position + 1 });
  }

  onUnitSelectAll() {
    const unitsSelected = !!!this.state.unitsSelected;
    this.setState({ unitsSelected });
    this.props.onUnitSelectAll(unitsSelected);
  }

  unitsToRender() {
    if (this.props.isInventoryView) {
      return this.props.data.units
    } else {
      return sidebarUnits(withinBounds(this.props.units, this.props.bounds), true).slice(0, this.props.limit);
    }
  }

  render() {
    const interval = this.getInterval();
    const dates = this.getNextDates(interval);
    const headers = dates;
    const { width, height } = this.state.dimensions;
    const leftWidth = 300;
    const rightWidth = 150;
    const middleWidth = width - leftWidth - rightWidth;
    const startDate = dates[0];
    const endDate = moment(dates[dates.length - 1]).add(1, interval);
    const selectedUnitIds = this.props.selectedUnitIds;
    const { isInventoryView, periodForecast, data, permissions, editable, clickable } = this.props;
    const { units } = data
    const unitsToRender = this.unitsToRender()

    // prevent timeline overlap if filters take more than one row
    const filter_bar_element = document.getElementById('filters_bar_list')
    const filters_height = filter_bar_element ? filter_bar_element.offsetHeight : 48;
    const top_offset = (111 + (filters_height - 48));

    return (
      <div className="timeline">
        <div className="sticky" style={{ position: "sticky", top: (isInventoryView ? top_offset : 0), backgroundColor: '#ffffff', zIndex: 800 }}>
          <Header
            headers={headers}
            leftWidth={leftWidth}
            middleWidth={middleWidth}
            rightWidth={rightWidth}
            height={height}
            onLeft={this.onLeft.bind(this)}
            onRight={this.onRight.bind(this)}
            onUnitSelectAll={this.onUnitSelectAll.bind(this)}
            unitsSelected={this.state.unitsSelected}
            isInventoryView={isInventoryView}
            periodForecast={periodForecast}
            interval={interval}
          />
        </div>
        <Measure onMeasure={dimensions => this.setState({ dimensions })}>
          <div className="timeline__list" id="timeline-list-items" style={{willChange: 'transform'}}>
            {unitsToRender.map(unit => {
              return (
                <li
                  className="timeline__item"
                  key={`unit-${unit.id}`}
                  onClick={() => this.onListClick(unit.id)}
                  onMouseOver={() => this.onListOver(unit)}
                  onMouseOut={() => this.onListOut(unit)}
                >
                {(unit.is_digital && this.props.isInventoryView)
                  ?
                    <DigitalListItem
                      permissions={permissions}
                      editable={editable}
                      clickable={clickable}
                      headers={headers}
                      startDate={startDate}
                      endDate={endDate}
                      leftWidth={leftWidth}
                      middleWidth={middleWidth}
                      rightWidth={rightWidth}
                      height={height}
                      unit={unit}
                      howLong={4}
                      filters={this.props.filters}
                      width={this.state.dimensions.width}
                      isActive={unit.id === this.props.activeUnit}
                      selectedUnitIds={selectedUnitIds}
                      onUnitSelect={this.props.onUnitSelect}
                      onUnitDetailsClick={this.props.onUnitDetailsClick}
                      onShowEditFlightModal={this.props.onShowEditFlightModal}
                      campaign={this.props.campaign}
                    />
                     :
                    <ListItem
                      permissions={permissions}
                      editable={editable}
                      clickable={clickable}
                      headers={headers}
                      startDate={startDate}
                      endDate={endDate}
                      leftWidth={leftWidth}
                      middleWidth={middleWidth}
                      rightWidth={rightWidth}
                      height={height}
                      unit={unit}
                      howLong={4}
                      filters={this.props.filters}
                      width={this.state.dimensions.width}
                      isActive={unit.id === this.props.activeUnit}
                      selectedUnitIds={selectedUnitIds}
                      onUnitSelect={this.props.onUnitSelect}
                      isInCart={unit.in_cart}
                      onUnitDetailsClick={this.props.onUnitDetailsClick}
                      onFavoriteUnit={this.props.onFavoriteUnit}
                      isSearchView={this.props.isSearchView}
                      isCartView={this.props.isCartView}
                      isInventoryView={this.props.isInventoryView}
                      isCampaignView={this.props.isCampaignView}
                      onShowEditFlightModal={this.props.onShowEditFlightModal}
                      campaign={this.props.campaign}
                    />}
                </li>
              );
            })}
          </div>
        </Measure>
      </div>
    );
  }
}

export default connect(({ map })=>({ bounds: map.bounds }), {toggleUnitPopup})(Listings);
