import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";

import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";

const POPUP_WIDTH = 180;

class BookingStatusFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = Object.assign(
      {
        isExpanded: false,
        onlyBooked: false,
        onlyNotBooked: false,
      },
      this.props.initialValue,
    );
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  componentDidUpdate(prevProps) {
    const { initialValue, isActive } = this.props
    if (initialValue.onlyBooked !== prevProps.initialValue.onlyBooked) {
      this.handleFilterChanges({ onlyBooked: initialValue.onlyBooked })
    }
    if (initialValue.onlyNotBooked !== prevProps.initialValue.onlyNotBooked) {
      this.handleFilterChanges({ onlyNotBooked: initialValue.onlyNotBooked })
    }
    if (!isActive && prevProps.isActive) { this.onClear(); }
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear() {
    this.setState({ onlyBooked: false, onlyNotBooked: false });
  }

  onBookingStatusSelect(option) {
    const { onlyBooked, onlyNotBooked } = this.state;
    if (option == 'only_booked') { return this.handleFilterChanges({ onlyBooked: !onlyBooked }) }
    return this.handleFilterChanges({ onlyNotBooked: !onlyNotBooked })
  }

  handleFilterChanges(filterChanges) {
    this.setState(filterChanges, this.onFilter)
  }

  onFilter() {
    const { onlyBooked, onlyNotBooked } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    const filter = { only_booked: onlyBooked, only_not_booked: onlyNotBooked }
    _.defer(filterUnits, filter);
    handleQueryParams(filter)
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { onlyBooked, onlyNotBooked } = this.state;
    if (!onlyBooked && !onlyNotBooked) return "Bookings";
    if (onlyBooked && onlyNotBooked) return "Booked + Not booked";
    if (onlyBooked) return "Booked only";
    return "Not booked only";
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  renderPopup() {
    const { onlyBooked, onlyNotBooked } = this.state;
    return (
      <div className="filter_popup screen_type_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>BOOKING STATUS</h4>
        <ul>
          <li onClick={() => this.onBookingStatusSelect('only_booked')} className={cs({ active: onlyBooked })}>
            Booked
            <i className="fa fa-check" />
          </li>
          <li onClick={() => this.onBookingStatusSelect('only_not_booked')} className={cs({ active: onlyNotBooked })}>
            Not booked
            <i className="fa fa-check" />
          </li>
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(null, { filterUnits })(BookingStatusFilter);
