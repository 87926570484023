import React from 'react'
import SimpleMap from '../SimpleMap'
import Chartkick from "chartkick";
import Chart from 'chart.js';
import Loading from '../Loading';

Chartkick.addAdapter(Chart);

export default class SmartDevices extends React.Component<any, any> {
  interval: any;

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      device: {}
    }
  }

  componentDidMount() {
    this.loadData()
    this.interval = setInterval(this.loadData.bind(this), 2500)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  loadData() {
    $.get('/api/v1/smart_devices/whatever').then(({ device, device_data }) => {
      this.setState({ device, loading: false }, () => {
        this.renderChart(device_data)
      })
    })
  }

  renderChart(data) {
    new Chartkick.LineChart("device-activity-chart", data, {})
  }

  render() {
    const { device, loading } = this.state

    if (loading) {
      return <Loading/>
    }

    const { lat, lon } = device

    return (
      <div className="container">
        <h1>{device.name}</h1>
        <div className="row">
          <div className="col-md-9">
            <span className="badge badge-success">Connected</span>
            <div style={{paddingTop:10}}>
              <div>Latitude: {device.lat}</div>
              <div>Longitude: {device.lon}</div>
            </div>
            <div className="chart">
              <h3>Live Activity</h3>
              X-Axis: People (Red), Cars (Blue) <br />
              Y-Axis: Time in 1 minute increments
              <div id="device-activity-chart" className="chart" />
            </div>
            <div className="video-feed">
              <h3>Recorded Video</h3>
              (Last 5 minutes)
              (Last 30 minutes)
              (Last 24 hours)
            </div>
          </div>
          <div className="col-md-3">
            <SimpleMap
              lat={lat}
              lon={lon}
              point_coordinates={[lon, lat]}
              point_title={device.name}
              zoom={13}
            />
          </div>
        </div>
      </div>
    )
  }
}
