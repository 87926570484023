import mapboxgl from 'mapbox-gl';

export default class MapLoadListener {
  map: mapboxgl.Map

  constructor(map: mapboxgl.Map) {
    this.map = map;
  }

  async waitMapLoad(resolve?: Function) {
    if (this.map.loaded()) return resolve && resolve();
    if (resolve) return setTimeout(this.waitMapLoad.bind(this, resolve), 100);

    return new Promise((resolve, _reject) => {
      setTimeout(this.waitMapLoad.bind(this, resolve), 100);
    });
  }
}
