import React, { PureComponent, ChangeEvent, ReactElement, CSSProperties } from "react";
import { FormControl, FormGroup, FormControlProps } from "react-bootstrap";

const DEFAULT_ROWS = 3;

export interface IZipCodesProps {
  zip_codes: string;
  rows?: number;
  minWidth?: string;
  onChange: (value: string, isValid: boolean | null) => any;
  children?: HTMLElement | ReactElement<HTMLElement>;
}

export interface IZipCodesState {
  zip_codes: string;
  isValid: boolean | null;
}

class ZipCodesInput extends PureComponent<IZipCodesProps, IZipCodesState> {

  constructor(props: IZipCodesProps) {
    super(props);
    this.state = {
      isValid: null,
      zip_codes: this.props.zip_codes
    };
  }

  public handleChange(event: ChangeEvent<FormControlProps>) {
    const regex = /^([, ]*\d{5})+[, ]?/;
    const zipCodes = event.target.value.replace(/[\s, \,]/g, ' ').trim()
    const isValid = regex.test(zipCodes as string);
    this.setState({ isValid, zip_codes: zipCodes as string });
    this.props.onChange(zipCodes as string, isValid);
  }

  public render() {
    return (
      <FormGroup validationState={this.validationState}>
        <label>Zip Code</label>
        <div className="form-inline">
          <FormControl
            style={this.inputStyle}
            componentClass="textarea"
            rows={this.props.rows || DEFAULT_ROWS}
            defaultValue={this.state.zip_codes}
            placeholder="Separated by comma or space"
            onChange={this.handleChange.bind(this)}
          />
          {this.props.children}
        </div>
      </FormGroup>
    );
  }

  private get validationState() {
    return this.state.isValid ? "success" : "error";
  }

  private get inputStyle(): CSSProperties {
    return {
      minWidth: this.props.minWidth || "100%"
    }
  }
}

export default ZipCodesInput;
