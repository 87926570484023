import React from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import {
  filterUnits,
} from '../actions/UnitsActions';
import Tooltip from "rc-tooltip";

class RecommendedFilter extends React.Component {

  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
  }

  onFilter() {
    const { isActive, filterUnits, handleQueryParams } = this.props;
    const filter = { only_recommended: !isActive }
    _.defer(filterUnits, filter)
    handleQueryParams(filter)
  }

  render() {
    const { isActive } = this.props;
    return (
      <Tooltip
        placement="bottom"
        trigger={['hover']}
        overlayStyle={{ width: '200px', height: "200px"}}
        overlay={<span style={{ width: "fit-content" }}>Recommended button to view total recommended plan cost, impressions and aggregate CPM.  Great to use in conjunctions with tags. </span>}
      >
        <label
          className={cs({
            "filters__recommendedOnly": true,
            "active filters__recommendedOnly_active": isActive
          })}
          onClick={this.onFilter}
        >
          <span className="badge_recommended">R</span>
          <span className="text-label">Recommended</span>
        </label>
      </Tooltip>

    )
  }
};

export default connect(
  null,
  { filterUnits }
)(RecommendedFilter)
