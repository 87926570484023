import { connect } from 'react-redux';
import React from 'react'
import _ from 'lodash'
import { savePlan, showGeography } from '../actions';
import { STEPS } from '../reducer';
import Geos from './Geos';
import SelectDemos from '../SelectDemos';
import BottomNav from '../BottomNav';

class Audience extends React.Component {
  getRecommendations() {
    this.props.savePlan();
    return this.props.showGeography();
  }

  render() {
    const { step } = this.props;
    return (
      <div className="section">
        <div className="row">
          <div className="col-md-4">
            <div className="subsection">
              <div className="heading">Add Demographics</div>
              <div className="subheading">Select some demographics you would like to target</div>
            </div>
          </div>
          <div className="col-md-8">
            <SelectDemos/>
          </div>
         </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <BottomNav /> 
          </div>
        </div>
      </div>
    )
  }
}
export default connect(
  ({planner}) => ({...planner}),
  { savePlan, showGeography, }
)(Audience);
