import React from "react"

export default class ThanksForSubmitting extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="thanks-for-submitting">
        <div className="flex-container">
          <div className="row">
            <div className="flex-item">
              <i className="fa fa-check-circle-o thanks-check" aria-hidden="true"></i>
            </div>
            <div className="flex-item">
              <h1 className="thanks-header">Thank you for submitting your campaign details!</h1>
            </div>
            <div className="flex-item">
              <p className="thanks-body">
                We're building your campaign now and you'll
                receive an email from one of our sales representatives
                within 24 hours
              </p>
            </div>
            <div className="flex-item thanks-bottom-panel">
              <p className="thanks-body">
                New to OOH? Checkout our <a href="/billboard-cost">billboard cost calculator</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
