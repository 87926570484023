import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import GlobalActions from "../../../actions/GlobalActions";
import { get, post } from "../../../utils/api";
import DateRangePicker from "../../DatePicker/Range";
import DatePicker from "../../DatePicker/Single";
import {
  createLeaseAttachment,
  detroyLeaseAttachment,
  fetchLeaseAttachments,
  fetchUnitLease,
  saveUnitLease,
} from "./actions";
import LeaseAttachmentDropzone from "./LeaseAttachmentDropzone";
import LeaseMaterials from "./LeaseMaterials";

class LeaseTab extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      code: "",
      name: "",
      start_date: null,
      end_date: null,
      status: "active",
      notes: "",
      retired_at: null,
      saving: false,
      loading: true,
      attachments_loading: true,
      attachments: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchLease = this.fetchLease.bind(this);
    this.onSaveUploadLeaseAttachment = this.onSaveUploadLeaseAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
  }

  componentDidMount() {
    this.fetchLease();
  }

  async fetchAttachments() {
    const { fetchLeaseAttachments } = this.props;
    const { id } = this.state;
    if (id) {
      const attachments = await fetchLeaseAttachments(id);
      this.setState({
        attachments_loading: false,
        attachments,
      });
    }
  }

  async fetchLease() {
    const { unit, fetchUnitLease } = this.props;
    const { start_date, end_date } = this.state;
    const lease = await fetchUnitLease(unit.id);
    this.setState(
      {
        id: lease.id || null,
        code: lease.code || "",
        name: lease.name || "",
        start_date: lease.start_date ? moment(lease.start_date) : start_date,
        end_date: lease.end_date ? moment(lease.end_date) : end_date,
        status: lease.status || "active",
        notes: lease.notes || "",
        retired_at: lease.retired_at ? moment(lease.retired_at) : null,
        loading: false,
      },
      this.fetchAttachments,
    );
  }

  async onSubmit(callback) {
    const { saveUnitLease, unit } = this.props;
    const { start_date, end_date } = this.state;
    const lease = await saveUnitLease({
      unit_id: unit.id,
      ...this.state,
    });
    this.setState(
      {
        id: lease.id || null,
        code: lease.code || "",
        name: lease.name || "",
        start_date: lease.start_date ? moment(lease.start_date) : start_date,
        end_date: lease.end_date ? moment(lease.end_date) : end_date,
        status: lease.status || "active",
        notes: lease.notes || "",
        retired_at: lease.retired_at ? moment(lease.retired_at) : null,
        loading: false,
        saving: callback ? true : false,
      },
      callback,
    );
  }

  async onSaveUploadLeaseAttachment(file_url) {
    const { id } = this.state;
    if (!!!id) {
      this.setState({ file_for_save: file_url });
      this.onSubmit(this.createLease);
    } else {
      this.setState({ saving: true, file_for_save: file_url }, this.createLease);
    }
  }

  async createLease() {
    const { createLeaseAttachment } = this.props;
    const { id, file_for_save } = this.state;
    const attachments = await createLeaseAttachment(file_for_save, id);
    this.setState({ saving: false, attachments }, () => {
      GlobalActions.showMessage("Lease materials saved");
    });
  }

  renderCode() {
    const { code } = this.state;
    return (
      <div style={{ maxWidth: 280, marginBottom: 25 }}>
        <label>Lease Code</label>
        <input
          style={{ marginTop: 10 }}
          className="form-control"
          type="text"
          value={code}
          name="code"
          placeholder={"Enter code"}
          onChange={e => this.setState({ code: e.target.value })}
        />
      </div>
    );
  }

  renderName() {
    const { name } = this.state;
    return (
      <div style={{ maxWidth: 280, marginBottom: 25 }}>
        <label>Lease Name</label>
        <input
          style={{ marginTop: 10 }}
          className="form-control"
          type="text"
          name="name"
          value={name}
          placeholder={"Enter lease name"}
          onChange={e => this.setState({ name: e.target.value })}
        />
      </div>
    );
  }

  renderDates() {
    const { start_date, end_date } = this.state;

    return (
      <div style={{ display: "grid", maxWidth: 290 }}>
        <label style={{ marginBottom: 10 }}>Dates</label>
        <DateRangePicker
          start_date={start_date}
          end_date={end_date}
          small={true}
          showDefaultInputIcon
          onDatesChange={({ start_date, end_date }) => this.setState({ start_date, end_date })}
        />
      </div>
    );
  }

  renderStatus() {
    const { status, retired_at } = this.state;

    return (
      <div className="lease_status" style={{ marginTop: 10 }}>
        <label>Lease Status</label>
        {["active", "inactive", "retired"].map(status_name => {
          return (
            <div className="status_radio">
              <label
                style={{
                  textTransform: "capitalize",
                  fontWeight: "normal",
                  fontSize: 14,
                  color: "#333333",
                  marginBottom: 5,
                }}
              >
                <input
                  style={{ marginRight: 8 }}
                  type="radio"
                  value={status_name}
                  checked={status === status_name}
                  onChange={e => this.setState({ status: e.target.value })}
                />
                {status_name}
              </label>
            </div>
          );
        })}
        {status === "retired" && this.renderRetiredDate()}
      </div>
    );
  }

  renderRetiredDate() {
    const { retired_at } = this.state;
    return (
      <DatePicker
        date={retired_at}
        onDateChange={({ date }) => this.setState({ retired_at: date })}
        showClearDate={true}
        placeholder={"Select date"}
        showDefaultInputIcon={true}
        small={true}
        isOutsideRange={() => false}
      />
    );
  }

  async deleteAttachment(attachment_id) {
    const { id } = this.state;
    const { detroyLeaseAttachment } = this.props;
    const attachments = await detroyLeaseAttachment(id, attachment_id);
    this.setState(
      {
        attachments,
      },
      () => GlobalActions.showMessage("Successfully Deleted"),
    );
  }

  renderMaterials() {
    const { id, attachments_loading, attachments } = this.state;
    return (
      <div style={{ marginBottom: 25 }}>
        <label>Lease Materials</label>
        <LeaseMaterials
          attachments_loading={attachments_loading}
          attachments={attachments}
          deletable={true}
          deleteAttachment={this.deleteAttachment}
        />
        <LeaseAttachmentDropzone onCreateAttachment={this.onSaveUploadLeaseAttachment} />
      </div>
    );
  }

  renderNotes() {
    const { notes } = this.state;

    return (
      <div>
        <label>Notes</label>
        <textarea
          className="form-control"
          style={{
            height: 85,
            margin: "10px 0px",
            maxWidth: 300,
            border: "1px solid #CCCCCC",
            borderRadius: 4,
            resize: "vertical",
            maxHeight: 100,
          }}
          value={notes}
          onChange={e => this.setState({ notes: e.target.value })}
        />
      </div>
    );
  }

  updateCallback() {
    this.setState(
      {
        loading: false,
        saving: false,
      },
      () => GlobalActions.showMessage("Lease details saved."),
    );
  }

  render() {
    const { onHide } = this.props;
    const { saving, loading, unit } = this.state;

    return (
      <div>
        {!!!loading && (
          <div className="row" style={{ margin: "30px 0px" }}>
            <div className="col-md-6">
              {this.renderCode()}
              {this.renderName()}
              {this.renderDates()}
              {this.renderStatus()}
            </div>
            <div className="col-md-6">
              {this.renderMaterials()}
              {this.renderNotes()}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn btn-success"
              style={{ float: "right", margin: "0px 20px 0px 18px", width: 88, backgroundColor: "#44AC6B" }}
              onClick={() => this.setState({ saving: true }, () => this.onSubmit(this.updateCallback))}
              disabled={saving || loading}
            >
              {saving && <i className="fa fa-cog fa-spin" aria-hidden="true"></i>} Save
            </button>
            <button className="btn btn-default" style={{ float: "right", width: 88 }} onClick={onHide}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({}) => ({}), {
  detroyLeaseAttachment,
  fetchUnitLease,
  saveUnitLease,
  createLeaseAttachment,
  fetchLeaseAttachments,
})(LeaseTab);
