import React from "react";

export default class Success extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }

  render() {
    const { company_name, name, email } = this.props;

    return (
      <div className="">
        <div id="schedule_form">
          <div style={{ fontSize: "22px" }}>
            {company_name && company_name.value && company_name.value.length
              ? `AdQuick + ${company_name.value} for the win!`
              : "Schedule a demo with AdQuick"}
          </div>
          <div
            className="calendly-inline-widget"
            data-url={`https://calendly.com/team-adquick/meeting-with-adquick?name=${
              name && name.value ? name.value : ""
            }&email=${email && email.value ? email.value : ""}`}
            style={{ minWidth: "320px", height: "1700px" }}
          />
        </div>
      </div>
    );
  }
}
