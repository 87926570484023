import "./index.css";

import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import Summary from "./Summary";
import UnitsSummary from "./UnitsSummary";
import AddOnsSummary from "./AddOnsSummary";
import EmptyCart from "./EmptyCart";
import { loadCartUnits, getCartTotal, removeFromCart } from "../../../actions/cart";
import { loadCampaign, loadCampaignUnits, loadSomeReachAndFrequency } from '../../Campaign/actions';
import FaArrowLeft from "@adquick/icons/fa/FaArrowLeft";
import GlobalActions from '../../../actions/GlobalActions';

type Props = {
  params: any;
};

interface RootState {
  cart: any;
  campaign: any;
}

// Where can I put this to make it available to all components?
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'turbo-frame': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        src?: string;
        id: string;
        refresh?: string;
        loading?: string;
      };
    }
  }
}

const LogoInfo = (props) => {
  const cart = useSelector((state: RootState) => state.cart);
  const campaign = useSelector((state: RootState) => state.campaign);
  const { count, token } = props;
  const { isRemovingAllFromCart } = cart;
  const dispatch = useDispatch();

  const onClearCart = async () => {
    if (isRemovingAllFromCart) { return; }
    if (confirm('Are you sure you want to clear your cart?')) {
      await dispatch(removeFromCart(campaign.token, [], true));
      GlobalActions.showMessage("All units have been removed from the cart");
      await dispatch(getCartTotal(campaign.token));
    }
  };

  if (!_.isEmpty(campaign)) {
    return (
      <div className="logo-info">
        <div className="back-button-link-icon">
          <FaArrowLeft />
          <a href={`/campaigns/${token}/map`} data-turbo="false"> Return to Map </a>
        </div>
        <img className="company-logo" src={campaign.campaign.branding_logo_url} />
        <p className="campaign-name">{campaign.name}</p>
        {props.count > 0 &&
          <ul className="list-inline cart-count">
            <li>{count} units</li>
            <li><a onClick={onClearCart}>{ isRemovingAllFromCart ? "Removing all units from cart" : "Clear cart"}</a></li>
          </ul>
        }
      </div>
    );
  } else {
    return <div></div>;
  }
};

const availsRefreshStatus = (campaignToken: string) => {
  return <turbo-frame id={`campaign_cart_refresh_status_${campaignToken}`} src={`/api/v1/campaigns/${campaignToken}/cart_refresh`}>
  </turbo-frame>
}


const CampaignCheckout: React.FunctionComponent<Props> = (props: Props) => {
  const token = props.params.campaign_id;
  const [step, setStep] = React.useState(0);
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();
  const { totals, cart_units, add_ons } = cart;

  useEffect(() => {
    dispatch(loadCampaign(token));
    dispatch(loadCampaignUnits(token));
    dispatch(getCartTotal(token));
    dispatch(loadCartUnits(token));
    dispatch(loadSomeReachAndFrequency(token));
  }, []);

  const summary_padding = step !== 3 ? { paddingRight: "32px" } : {};

  return (
    <div style={{ paddingTop: "16px", background: "#FAFAFA", minHeight: "100%" }}>
      <div className="checkout-container">
        <div className="checkout-block-left" style={summary_padding}>
          <LogoInfo count={totals && totals.count} token={token}/>
          {availsRefreshStatus(token)}
          {cart_units.length === 0 && <EmptyCart token={token} />}
          {add_ons.length > 0 && <AddOnsSummary token={token} />}
          {cart_units.length > 0 && <UnitsSummary token={token} />}
        </div>
        <div className="checkout-block-right" style={{marginTop: '48px'}}>
          {cart_units.length > 0 && <Summary />}
        </div>
      </div>
    </div>
  );
};

export default CampaignCheckout;
