import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import Warning from './Warning'
import UnitPopup from '../UnitPopup'
import { favoriteUnits, unfavoriteUnits } from '../../actions/UnitsActions';

class CampaignUnitPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.onFavoriteUnit = this.onFavoriteUnit.bind(this)
  }

  async onFavoriteUnit(unitId, campaignUnitId, isFavorited, showCallback) {
    const campaignUnitIds = campaignUnitId ? [campaignUnitId] : []
    const { unfavoriteUnits, favoriteUnits, campaign: { campaignId } } = this.props;

    isFavorited
      ? await unfavoriteUnits(campaignUnitIds, [unitId], campaignId, showCallback)
      : await favoriteUnits(campaignUnitIds, [unitId], campaignId, showCallback);
  }

  renderBookByModalTip() {
    const { campaign } = this.props
    const currentCampaign = campaign && campaign.campaign
    if(currentCampaign) {
      const now = moment()
      const bookByDate = moment(currentCampaign.book_by)
      const dayDiff = bookByDate.diff(now,'days')
      const bookByTooltip = false
      let bookByMessage = ''
      let style = ''
      if(dayDiff >= 0) {
        bookByMessage = <span><b>{`We recommend booking this unit by ${moment(currentCampaign.book_by).format('l')}`}.</b> After that, there’s a high likelihood this unit will be unavailable.</span>
        style = 'info'
      } else {
        bookByMessage = <span><b>This unit is past its book by date.</b> It may be booked at any time – we recommend checking out soon if you are interested in this unit.</span>
        style = 'danger'
      }
      return <Warning message={bookByMessage} tooltip={bookByTooltip} style={style}/>
    }
  }

  render() {
    const show = this.props.show || !!this.props.unit
    return (
      <Modal
        show={show}
        onHide={this.props.onClose}
        onEnter={() => { $('.modal').attr('tabindex', null); }}
        autoFocus={false}
        dialogClassName='unit_modal_with_comments'
      >
        {this.props.campaign.campaign && this.props.campaign.campaign.book_by &&
          <div style={{backgroundColor:'rgba(0,0,0,0.83)',height:'45px',borderTopLeftRadius:'8px',borderTopRightRadius:'8px'}}>
            {this.renderBookByModalTip()}
          </div>
        }
        <UnitPopup
         {...this.props}
         units={this.props.units.all_units}
         campaignId={this.props.campaign.token}
         permissions={this.props.campaign.permissions}
         onFavoriteUnit={this.onFavoriteUnit}
        />
      </Modal>
    )
  }
}


export default connect(
  (state) => ({
    units: state.units,
    campaign: state.campaign,
  }),
  {
    favoriteUnits,
    unfavoriteUnits,
  }
)(CampaignUnitPopup)
