import { connect } from "react-redux";
import React, { Component } from 'react';
import UploadButton from "../../UploadButton";
import { isEmpty, get } from 'lodash';
import {
  loadCampaignDesigns,
  uploadDesignAsset,
} from "../../../actions/campaign_design_actions";

type Asset = {
  id: number
}

interface UploadDesignAssetActionProps {
  unit: any;
  campaign_id: string;
  progressBarColor?: string | number;
  asset?: Asset;
  loadCampaignDesigns: (campaign_id: string) => void;
  uploadDesignAsset: (campaign_id: string, units: {}[], upload: {}, asset_id: any) => {};
  callback?: (response) => void;
  children: React.ReactNode;
}
interface UploadDesignAssetActionState {
  show_modal: boolean;
  run_callback: boolean;
}
class UploadDesignAssetAction extends Component<UploadDesignAssetActionProps, UploadDesignAssetActionState> {

  constructor(props) {
    super(props);

    this.onUploadComplete = this.onUploadComplete.bind(this);

    this.state = {
      show_modal: false,
      run_callback: this.props.callback ? true : false
    }
  }

  public render() {
    const { children, progressBarColor } = this.props;

    return <UploadButton onComplete={this.onUploadComplete} progressBarColor={progressBarColor}>
        {children}
    </UploadButton>;
  }

  private async onUploadComplete(upload, file) {
    const { unit, campaign_id, asset, callback } = this.props;
    let confirmed = true;
    const unitHasAssets = unit.design_assets.length > 0;
    const unitIsStatic = unit.screen_type === "static";
    if ((unitIsStatic && unitHasAssets ) || asset) {
      confirmed = confirm(`
CAUTION! You are about to replace the design asset for this unit!
If you have multiple files to upload, package them all in one zip file and submit the zip.

Are you sure you want to continue?`);
    }
    if (confirmed) {
      const uploadInfo = {
        file_name: file.name,
        file_path: upload.path,
        file_size: file.size,
        file_type: file.type,
      };
      await this.props.uploadDesignAsset(campaign_id, [unit], uploadInfo, get(asset, 'id'));
      //@ts-ignore
      this.state.run_callback && callback('submitted');
      this.props.loadCampaignDesigns(campaign_id);
    }
  }

}

const mapStateToProps = ({}) => ({});
export default connect(
  mapStateToProps,
  { loadCampaignDesigns, uploadDesignAsset },
 )(UploadDesignAssetAction);
