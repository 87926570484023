import { UnitTag } from "../models/unit_tag";
import { del, get, post } from "../utils/api";

const prefix = "unit_tags";
const UNIT_TAGS_API_URL = "/api/v1/unit_tags/options_for_select"

export const LOAD_UNIT_TAGS = `${prefix}/LOAD_UNIT_TAGS`;
export const loadUnitTags = () => async (dispatch, getState) => {
  const res = await get(UNIT_TAGS_API_URL, null);
  const unit_tags: UnitTag[] = res.options_for_select;
  dispatch({ type: LOAD_UNIT_TAGS, payload: { unit_tags } });
};
