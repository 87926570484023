import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  bulkApproveDesignAssets,
  loadCampaignDesigns,
  loadCampaignPops,
} from "../../../../actions/campaign_design_actions";
import { getDesignAssetsIds } from "../../../../models/design_assets";
import UploadStore from "../../../../stores/UploadStore";
import UploadButton from "../../../UploadButton";
import * as styleGen from "../styles";

interface BulkActionsProps {
  units: any[];
  unit_selection_map: any;
  status: string;
  campaign_permissions: any;
  campaign: any;
  bulkApproveDesignAssets: (campaign_id: string, design_asset_ids: number[]) => void;
  loadCampaignDesigns: (campaign_id: string) => void;
  loadCampaignPops: (campaign_id: string) => void;
  unselectAll: () => void;
  onToggleSelectAll: (selected: boolean) => void;
}
interface BulkActionsState {}
class BulkActions extends Component<BulkActionsProps, BulkActionsState> {
  constructor(props) {
    super(props);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.onBulkApprove = this.onBulkApprove.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  public render() {
    const { units, unit_selection_map } = this.props;
    const all_selected = _.every(units, unit => unit_selection_map[unit.id]);
    const selected_units = this.selectedUnits();

    return (
      <div className="bulk_actions" style={styleGen.actions()}>
        {selected_units.length > 0 && this.renderActions()}
        <input type="checkbox" onChange={this.selectAll} checked={all_selected} style={styleGen.checkbox()}></input>
      </div>
    );
  }

  private selectedUnits() {
    const { units, unit_selection_map } = this.props;
    return units.filter(unit => unit_selection_map[unit.id]);
  }

  private renderActions() {
    const { campaign_permissions } = this.props;
    const selected_units = this.selectedUnits();
    const buttons: JSX.Element[] = [];

    if (campaign_permissions.can_approve_design_asset) {
      buttons.push(this.renderBulkApproveButton());
    }

    if (campaign_permissions.can_upload_design_asset) {
      buttons.push(this.renderUploadButton());
    }

    return buttons;
  }

  private selectAll(event) {
    this.props.onToggleSelectAll(event.target.checked);
  }

  private renderBulkApproveButton() {
    return (
      <a key="bulk_approve" onClick={this.onBulkApprove} className="btn btn-default" style={styleGen.approveButton()}>
        Approve Selected
      </a>
    );
  }

  private renderUploadButton(disabled = false) {
    return (
      <UploadButton
        key="upload_button"
        onComplete={this.onUploadComplete}
        progressBarColor={styleGen.uploadButton().background}
      >
        <button
          className="btn btn-default"
          style={styleGen.uploadButton()}
          onClick={e => e.preventDefault()}
          disabled={disabled}
        >
          <i className="fa fa-cloud-upload" style={{ marginRight: 5 }} /> Upload Selected
        </button>
      </UploadButton>
    );
  }

  private onUploadComplete(upload, file) {
    const { campaign } = this.props;
    const campaign_id = campaign.campaignId;
    const selected_units = this.selectedUnits();

    UploadStore.uploadImage(selected_units, upload, file, {
      campaign_id,
      upload_type: "design_asset",
    })
      .then(res => this.props.loadCampaignDesigns(campaign_id))
      .then(res => this.props.unselectAll());
  }

  private async onBulkApprove() {
    const { campaign } = this.props;
    const campaign_id = campaign.campaignId;
    const selected_units = this.selectedUnits();
    const design_asset_ids = getDesignAssetsIds(selected_units);

    await this.props.bulkApproveDesignAssets(campaign_id, design_asset_ids);
  }
}

const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { bulkApproveDesignAssets, loadCampaignDesigns, loadCampaignPops })(
  BulkActions,
);
