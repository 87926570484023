import React, { Component } from 'react';
import accounting from 'accounting';

class Header extends Component {

   formatMoney(amount) {
    // Remove cents if it's .00
    return accounting.formatMoney(amount).replace('.00', '')
  }

  render() {
    const { reference, key, contracts, onShowForm, formIsExpanded } = this.props;
    const notCanceled = contracts.filter(c => !c.canceled_at);
    const { units_cost, production_cost, installation_cost, total } = reference;
    const overallMediaCost = _.sumBy(notCanceled, (c) => c.units_cost);
    const overallProductionCost = _.sumBy(notCanceled, (c) => c.production_cost);
    const overallInstallationCost = _.sumBy(notCanceled, (c) => c.installation_cost);
    const overallTotal = _.sumBy(notCanceled, (c) => c.total);

    return (
      <div className="header" key={key}>
        <h4>{reference.supplier.name}</h4>
        <div className="supplier_contracts_table">
          <table className="references">
            <thead>
              <tr>
                <th></th>
                <th>Media</th>
                <th>Prod</th>
                <th>Install</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Reference</td>
                <td>{this.formatMoney(units_cost)}</td>
                <td>{this.formatMoney(production_cost)}</td>
                <td>{this.formatMoney(installation_cost)}</td>
                <td>{this.formatMoney(total)}</td>
              </tr>
            </tbody>
          </table>
          {!!contracts.length &&
            <table className="contracts">
              <tbody>
                <tr>
                  <td>Contract</td>
                  <td>{this.formatMoney(overallMediaCost)}</td>
                  <td>{this.formatMoney(overallProductionCost)}</td>
                  <td>{this.formatMoney(overallInstallationCost)}</td>
                  <td>{this.formatMoney(overallTotal)}</td>
                </tr>
              </tbody>
            </table>
          }
        </div>
        <div className="actions">
          {!formIsExpanded &&
            <div className="btn-group">
              <button className="btn btn-block dropdown-toggle" data-toggle="dropdown">
                Actions
                <span className="caret pull-right"></span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a
                    onClick={() => onShowForm('hellosign')}
                    className="hellosign_option"
                  >Send Contract</a>
                </li>
                <li>
                  <a
                    onClick={() => onShowForm('upload')}
                    className="upload_option"
                  >Upload Contract</a>
                </li>
              </ul>
             </div>
          }
        </div>
      </div>
     )
  }

}

export default Header;
