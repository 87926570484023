import React from 'react';
import createClass from 'create-react-class';
import GlobalActions from '../actions/GlobalActions';

export default createClass({
  displayName: 'CampaignRequestSent',

  render() {
    return (<div className="campaign-request-form register text-center" id='campaign-request-thank-you'>
      <p><b className="text-green">Thank you for submitting a campaign request!</b></p>
      <p>Kick your feet up, relax, grab some popcorn and we’ll have options to you in less than 48 hours!</p>
      <img
        src="https://media4.giphy.com/media/g627NTrFmqzYY/giphy.gif"
        style={{ width: 225, height: 168.25, marginTop: 15, borderRadius: 4 }}
      />
    </div>);
  },
});
