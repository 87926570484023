import React, { Component } from 'react';
import CarouselControls from '../../UnitInfo/CarouselControls';
import CampaignCarouselNavigation, { Keys } from '../../../models/CampaignCarouselNavigation';

const { LEFT, UP, RIGHT, DOWN, ENTER, F, T } = Keys;

class KeyboardNavigation extends Component {

  constructor(props){
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.carousel = new CampaignCarouselNavigation(this.props.units);
    this.debouncedOnMoveCursorBack     = _.debounce(unit => this.props.onMoveCursor(unit), 100, { 'maxWait': 1000 });
    this.debouncedOnMoveCursorForward  = _.debounce(unit => this.props.onMoveCursor(unit), 100, { 'maxWait': 1000 });
  }

  componentDidMount(){
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.units, nextProps.units)) {
      this.carousel = new CampaignCarouselNavigation(nextProps.units);
    }
    if (nextProps.manualIndex && this.props.manualIndex != nextProps.manualIndex) {
      this.carousel.jump(nextProps.manualIndex);
    }
  }

  onPrev(e) {
    e.preventDefault();
    this.debouncedOnMoveCursorForward.cancel();
    const { onPrev, activeUnitId } = this.props;
    const prev = this.carousel.prev()
    this.debouncedOnMoveCursorBack(prev)
  }

  onNext(e) {
    e.preventDefault();
    this.debouncedOnMoveCursorBack.cancel();
    const { onNext, activeUnitId } = this.props;
    const next = this.carousel.next()
    this.debouncedOnMoveCursorForward(next)
  }

  isFavorited(unit) {
    return !!unit.campaign_units.find(u => u.is_favorited)
  }

  onKeyDown(e) {
    const  { activeUnitId, carouselMode, onEnter, onFavoriteUnit, onToggleInfoAndMap } = this.props;
    const unit = this.props.units.find(u => u.id == this.props.activeUnitId)
    if (e.target.tagName.toLowerCase() !== 'body') return false;

    switch (e.keyCode) {
      case F:
        if (activeUnitId) onFavoriteUnit(unit.id, null, this.isFavorited(unit), false);
        break;
      case LEFT:
      case UP:
        this.onPrev(e);
        break;
      case RIGHT:
      case DOWN:
        this.onNext(e);
        break;
      case ENTER:
        if (!carouselMode && activeUnitId) onEnter(activeUnitId);
        break;
      case T:
        if (carouselMode) onToggleInfoAndMap();
        break;
    }
  }

  isBeginningOfList() {
    const _idx = this.carousel.activeIndex;
    if (_idx == undefined || _idx == 0) return true;
    return false;
  }

  isEndOfList() {
    const _idx = this.carousel.activeIndex;
    const _max_idx = this.carousel.units.length - 1;
    if (_idx == _max_idx) return true;
    return false;
  }

  render(){
    const { carouselMode } = this.props;
    const isBeginningOfList = this.isBeginningOfList();
    const isEndOfList = this.isEndOfList();

    return (
      <CarouselControls
        show={!!carouselMode}
        onPrev={this.onPrev}
        onNext={this.onNext}
        isBeginningOfList={isBeginningOfList}
        isEndOfList={isEndOfList}
      />
    )
  }

}

export default KeyboardNavigation
