

import "./style.css";
import uniq from "lodash/uniq";
import groupBy from "lodash/groupBy";
import camelCase from "lodash/camelCase";
import debounce from "lodash/debounce";

// @ts-ignore
import Picker from "vanilla-picker";

const EXCLUDE_ELEMENTS = ["html", "head", "script", "meta", "link", "style", "title", "symbol", "iframe", "defs", "svg"];

declare global {
  interface Window {
    ColorTool: ColorTool;
  } 
}

export default class ColorTool {

  element: HTMLDivElement | null = null;
  relativeX: number = 0;
  relativeY: number = 0;
  mouseDown: boolean = false;
  groupedElements: { [cssProp:string]: { [key:string]: HTMLElement[] } } = {};
  changes: { [cssProp:string]: { key: string, next: string }[] } = {};
  shiftPressed: boolean = false;

  constructor() {
    window.onkeydown = this.onKeyDown.bind(this);
    window.onkeyup = this.onKeyUp.bind(this);
  }

  buildModal() {
    const div = document.createElement("div");
    const reloadBtn = document.createElement("div");
    const resetBtn = document.createElement("div");
    const saveBtn = document.createElement("div");
    const instructions = document.createElement("div");

    div.id = "__color_tool";
    div.draggable = true;
    div.ondragend = this.onDragEnd.bind(this);
    div.ondragstart = this.onDragStart.bind(this);

    instructions.classList.add("instructions");
    instructions.textContent = `
      Hold SHIFT to highlight the elements
    `;

    reloadBtn.classList.add("reload");
    reloadBtn.onclick = this.reload.bind(this);

    resetBtn.classList.add("reset");
    resetBtn.onclick = this.reset.bind(this);

    saveBtn.classList.add("save");
    saveBtn.onclick = this.save.bind(this);
      
    div.appendChild(this.buildLoadField());
    div.appendChild(instructions);
    div.appendChild(resetBtn);
    div.appendChild(saveBtn);
    div.appendChild(reloadBtn);

    div.appendChild(this.buildColorButtonsFor("background-color"));
    div.appendChild(this.buildColorButtonsFor("color"));
    div.appendChild(this.buildColorButtonsFor("border-color"));
    
    return div;
  }

  getAllPageElements(): HTMLElement[] {
    return Array.from(document.querySelectorAll(`*:${EXCLUDE_ELEMENTS.map(s => `not(${s})`).join(":")}`))
      .filter(this.notHidden.bind(this));
  }

  getButtonByKey(key) {
    return document.querySelector(`.colortarget[ref="${key}"] button`) as HTMLElement;
  }

  getKey(elm, property) {
    return `${elm.tagName}:${this.styleValue(elm, property)}`
  }

  getTagFromKey(key) {
    return key.replace(/\:.*/, "");
  }

  getColorFromKey(key) {
    return key.replace(/.*\:/, "");
  }

  getAlphaChannel(color) {
    const alpha = color.match(/\, [0-1]\)/);
    if (alpha && color.includes("rgba")) {
      return alpha[0].replace(", ", "").replace(")", "");
    }
  }

  notHidden(elm) {
    const display = this.styleValue(elm, "display");
    const opacity = this.styleValue(elm, "opacity");
    return display !== "none" && opacity != "0";
  }

  elementsByCssProperty(property: string) {
    return groupBy(this.getAllPageElements(), elm => this.getKey(elm, property));
  }

  styleValue(el: HTMLElement, property: string): string {
    return window.getComputedStyle(el).getPropertyValue(property)
  }

  buildColorButtonsFor(propName) {
    this.groupedElements[propName] = this.elementsByCssProperty(propName);
    this.changes[propName] = [];

    const div = document.createElement("div");
    const label = document.createElement("label");
    const ul = document.createElement("ul");
    const options = Object.keys(this.groupedElements[propName]);

    label.textContent = propName;

    ul.id = "__color_tool-divs"; 
    options.forEach(this.buildColorSwitch(propName, ul));
    
    div.classList.add("wrapper");
    div.appendChild(label);
    div.appendChild(ul);

    return div; 
  }

  buildColorSwitch(propName, ul) {
    return (key) => {
      const color = this.getColorFromKey(key);
      const li = document.createElement("li");

      if (this.getAlphaChannel(color) === "0") return;
      
      li.classList.add('colortarget');
      li.setAttribute('ref', key);
      li.appendChild(this.buildColorButton(propName, key));

      ul.appendChild(li);
    }
  }

  buildColorButton(propName: string, key: string) {
    const button = document.createElement("button");
    const text = document.createElement("span");
    const color = this.getColorFromKey(key);
    const tag = this.getTagFromKey(key);
    const picker = new Picker({ parent: button, color });

    text.textContent = tag.toLowerCase();
    text.style.color = color;
    
    button.style.backgroundColor = color;
    button.appendChild(text);

    button.onmousemove = this.highlightElements(propName, key);
    button.onmouseleave = this.removeHighlights.bind(this);
    picker.onChange = this.onChangeColor(button, key, propName);
    
    return button;
  }

  buildLoadField() {
    const loadWrapper = document.createElement("div");
    const loadButton = document.createElement("div");
    const loadInput = document.createElement("input");

    loadButton.classList.add("load_button");

    loadWrapper.classList.add("loader");
    loadWrapper.appendChild(loadButton);

    loadButton.onclick = () => loadInput.click();

    loadInput.type = "file";
    loadInput.style.display = "none";
    loadInput.placeholder = "Load Theme";
    loadInput.onchange = this.onLoadTheme(loadInput);

    loadWrapper.appendChild(loadInput);

    return loadWrapper;
  }

  highlightElements(propName, key) {
    return () => {
      if (this.shiftPressed) {
        this.groupedElements[propName][key].forEach(this.appendHighlight(propName, key));
      } else {
        this.removeHighlights();
      }
    }
  }

  removeHighlights() {
    const hls = document.querySelectorAll('.__color_tool-highlight');
    if (hls.length) {
      hls.forEach(hl => hl.remove());
    }
  }

  removeAlpha(color) {
    if (color.includes("rgba")) {
      return color.replace(/\, [0-1]\)/, ", 1)");
    } else {
      return color;
    }
  }

  appendHighlight(propName, key) {
    return (elm, i) => {
      const ref = `${propName}.${key}.${i}`;

      if (document.querySelector(`[ref="${ref}"]`)) return;

      const hl = document.createElement("div");
      const rect = elm.getBoundingClientRect();
      const elmPosition = this.styleValue(elm, "position");
      const isAbs = ["absolute", "relative", "fixed"].includes(elmPosition);
      
      hl.setAttribute("ref", ref);
      
      hl.classList.add("__color_tool-highlight");
      hl.style.position = "absolute";
      hl.style.zIndex = "999";
      hl.style.width = `${rect.width}px`;
      hl.style.height = `${rect.height}px`;
      hl.style.left = `${rect.left}px`;
      hl.style.top = `${rect.top}px`;

      document.body.appendChild(hl);
    }
  }

  pushChange(key: string, propName: string, next: string) {
    const change = this.changes[propName].find(c => c.key === key);
    if (change) {
      change.next = next;
    } else {
      this.changes[propName].push({ key, next });
    }
  }

  updateElements(propName) {
    this.changes[propName].forEach(c => {
      this.groupedElements[propName][c.key].forEach(elm => {
        elm.style.cssText = `${propName}: ${c.next} !important`;
      });
    }); 
  }

  updateAllElements() {
    const props = Object.keys(this.changes);
    props.forEach(this.updateElements.bind(this));
  }

  onLoadTheme(input) {
    return () => {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        try {
          //@ts-ignore
          this.changes = JSON.parse(atob(reader.result.split(",")[1]));
          this.updateAllElements();
        } catch(e) {
          console.error("Cannot load your theme:\n", e);
        }
      }

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  onKeyDown(event) {
    this.shiftPressed = event.shiftKey;
  }

  onKeyUp() {
    this.shiftPressed = false;
    this.removeHighlights();
  }

  onChangeColor(button, key, propName) {
    return next => {
      button.style.backgroundColor = next.rgbaString;
      this.pushChange(key, propName, next.rgbaString);
      this.updateElements(propName);
    }
  }

  onDragStart(event) {
    const rect = event.target.getBoundingClientRect();
    this.relativeX = event.clientX - rect.left;
    this.relativeY = event.clientY - rect.top;
  }

  onDragEnd(event) {
    const movedX = event.target.offsetLeft + event.layerX;
    const movedY = event.target.offsetTop + event.layerY;
    event.target.style.left = `${movedX - this.relativeX}px`;
    event.target.style.top = `${movedY - this.relativeY}px`;
  }

  onRouteChange() {
    console.log("changed");
    this.reload();
  }

  save() {
    const a = document.createElement("a");
    const json = JSON.stringify(this.changes);
    const blob = new Blob([json], { type: "octet/stream" });
    const url = window.URL.createObjectURL(blob);
    const now = new Date();

    document.body.appendChild(a);

    a.style.display = "none";
    a.href = url;
    a.download = `theme_${now.toISOString()}.json`;
    a.click();
    
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  reset() {
    const props = Object.keys(this.changes);
    props.forEach(prop => {
      
      this.changes[prop].forEach(change => {
        const colorBtn = this.getButtonByKey(change.key);
        const color = this.getColorFromKey(change.key);
        change.next = color;
        colorBtn.style.backgroundColor = color;
      });

      this.updateElements(prop);
    });

  }

  start() {
    try {
      window.ColorTool.element = window.ColorTool.buildModal();
      document.body.appendChild(window.ColorTool.element);
    } catch(e) {
      console.error(`
        ColorTool was not initialized, make sure to instantiate it in your DOM \n
          window.ColorTool = new ColorTool();
      `, e);
    }
  }

  reload() {
    document.body.removeChild(window.ColorTool.element as HTMLElement);
    window.ColorTool.element = window.ColorTool.buildModal();
    document.body.appendChild(window.ColorTool.element);
  }

  kill() {
    try {
      document.body.removeChild(this.element as HTMLElement);
      this.element = null;
    } catch(e) {

    }
  }
}  