import React, { Component } from 'react';

import Contract from './Contract';
import Form from './Form';
import Header from './Header';

class Card extends Component {

  constructor(props) {
    super(props);
    this.onShowForm = this.onShowForm.bind(this);
    this.onHideForm = this.onHideForm.bind(this);
    this.onApplyDiscount = this.onApplyDiscount.bind(this);
    this.state = {
      showForm: false,
      formContractType: null,
      editingContract: null,
    }
  }

  onShowForm(type, contract) {
    this.setState({ showForm: true, formContractType: type, editingContract: contract });
  }

  onHideForm() {
    this.setState({ showForm: false, formContractType: null, editingContract: null });
  }

  onApplyDiscount() {
    this.setState({ editingContract: contract });
  }

  filterContractItems() {
    const { referenceContract } = this.props;
    const { editingContract } = this.state;
    return editingContract ? editingContract.items : referenceContract.items;
  }

  renderForm() {
    const { formContractType, editingContract } = this.state;
    const { referenceContract, filterByFavorite, filterByRecommended, filterByBooked, user } = this.props;
    return (
      <Form
        onApplyDiscount={this.onApplyDiscount}
        referenceContract={editingContract || referenceContract}
        onHideForm={this.onHideForm}
        contractType={formContractType}
        items={this.filterContractItems()}
        filterByFavorite={filterByFavorite}
        filterByRecommended={filterByRecommended}
        filterByBooked={filterByBooked}
        user={user}
      />
    );
  }

  renderContract(contract) {
    const { showForm, editingContract } = this.state;
    const isEditingThisContract = showForm && editingContract && editingContract.id === contract.id;
    return (
      <div key={`contract_${contract.id}`}>
        <Contract contract={contract} onShowForm={this.onShowForm} />
      </div>
    )
  }

  render() {
    const { showForm } = this.state;
    const { referenceContract, contracts } = this.props;

    return (
      <div className="supplier_contract_card">
        <Header
          reference={referenceContract}
          contracts={contracts}
          onShowForm={this.onShowForm}
          formIsExpanded={showForm}
        />
        {contracts.map(contract => this.renderContract(contract))}
        {showForm && this.renderForm()}
      </div>
    );
  }
}

export default Card;
