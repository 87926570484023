import React, { useState, ChangeEvent } from "react";
import cs from "classnames";
import validate from "validate.js";
import isEmpty from "lodash/isEmpty";
import { post } from "../../../../utils/api";
import { TValidatorConstraint } from "../../../../models/validator_constraints";
import GlobalActions from "../../../../actions/GlobalActions";

const constraints = {
  production_cost: {
    presence: {
      allowEmpty: false
    },
    numericality: {
      onlyInteger: false,
      greaterThan: 0
    }
  }
};

interface IProductionCostModal {
  onHide: () => void;
  loadCampaignUnits: (campaign_token: string) => void;
  campaign_token: string;
  unit_ids: Array<string>;
};

const MassUpdateProductionCostModal = ({ onHide, loadCampaignUnits, campaign_token, unit_ids }: IProductionCostModal) => {
  const [production_cost, setProductionCost] = useState("");
  const [saving, toggleSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const renderBody = () => {
    const hasErrors = !isEmpty(errors['production_cost']);
    return (
      <div 
        className={cs("form-group", {"has-error": hasErrors })}
      >
        <label>Please input the production cost ($) per square foot</label>
        <div className="input-group">
          <div className="input-group-addon">$</div>
          <input
            style={{width:"auto",borderTopRightRadius:"4px",borderBottomRightRadius:"4px"}}
            className="form-control"
            value={production_cost}
            type="text"
            onChange={onProductionCostChange}/>
          <div
            style={{
              height:"32px",
              display:"flex",
              alignItems:"center",
              paddingLeft:"5px"
            }}
          >/sqft</div>
        </div>
        <span
          className="help-block"
          style={{
            width:"254px",
            fontSize: "13px",
            lineHeight: "16px",
            color: "#333333"
          }}
        >
          <em>Note:</em> The recommended production cost is $1.40 per square foot
        </span>
        {renderErrors(errors['production_cost'])}
      </div>
    )
  };

  const onProductionCostChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductionCost(e.target.value);
  }

  const renderErrors = (errors = []) => {
    if(isEmpty(errors)) { return; }
    return errors.map((error, idx) => renderError(error, idx));
  };

  const renderError = (error, idx) => {
    return (
      <span
        key={`error${idx}`}
        className="help-block"
        style={{color:"red"}}
      >
        {error}
      </span>
    );
  };

  const renderFooter = () => {
    return (
      <div
        style={{display:"flex",justifyContent:"flex-end"}}
      >
        <button
          style={{width:"20%",backgroundColor:"unset",color:"#858585"}}
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          style={{width:"20%"}}
          onClick={onSubmit}
        >
          {saving ? "Saving..." : "Save"}
        </button>
      </div>
    )
  };

  const onSubmit = () => {
    const errors = validate(
      { production_cost },
      constraints as TValidatorConstraint
    ) || {};
    if(!isEmpty(errors)) { return setErrors({ errors }); }
    setErrors({});
    toggleSaving(!saving);
    return massUpdateUnits();
  };

  const massUpdateUnits = async () => {
    try {
      await post(`/api/v1/campaign_units/mass_update_production_cost`, setPayload());
      await loadCampaignUnits(campaign_token);
      GlobalActions.showMessage("Successfully updated the selected units!");
      onHide();
    } catch (error) {
      GlobalActions.showError(error);
      toggleSaving(false);
    }
  };

  const setPayload = () => {
    return {
      campaign_id: campaign_token,
      unit_ids,
      production_cost_per_square_feet: production_cost
    };
  }

  return (
    <div style={{padding:"10px 25px"}}>
      {renderBody()}
      {renderFooter()}
    </div>
  )
};

export default MassUpdateProductionCostModal;
