import React from 'react'
import moment from 'moment';
import { formatMoney } from 'accounting';
import Loading from '../Loading';

export default class Tasks extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      monthly: {},
      loading: true
    }
  }

  componentDidMount() {
    this.loadTasks()
  }

  loadTasks() {
    $.get('/api/v1/reports/bookings_over_time').then(({ monthly }) => {
      this.setState({ monthly, loading: false })
    })
  }

  render() {
    const { monthly, loading } = this.state

    if (loading) {
      return <Loading/>
    }

    return (
      <div className="container">
        <h3>Spend Over Time</h3>

        <table className="table table-striped">
          <thead>
            <tr style={{fontWeight:'bold'}}>
              <td>Month</td>
              <td>Spend</td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(monthly).map(month => (
              <tr key={month}>
                <td>{month}</td>
                <td>{formatMoney(monthly[month])}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
