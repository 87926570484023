import { get, post, del, delWithBody, put } from "../../../utils/api";
import GlobalActions from "../../../actions/GlobalActions";

export const CLEAR_CAMPAIGN_UNITS = "marketplace/campaign/campaign_units/clear-campaign-units";
export const clearCampaignUnits = () => async dispatch => {
  dispatch({ type: CLEAR_CAMPAIGN_UNITS, payload: {} });
};

export const LOAD_CAMPAIGN_UNITS = "marketplace/campaign/campaign_units/load-campaign-units";
export const loadCampaignUnits = campaign_id => async dispatch => {
  const res = await get(`/api/v1/timeline?campaign=${campaign_id}`)
  const { units, more_results_count, offset } = res.data;
  dispatch({ type: LOAD_CAMPAIGN_UNITS, payload: { units, campaign_id, more_results_count, offset } });
}

export const REMOVE_FLIGHTS = "marketplace/campaign/campaign_units/remove-flights";
export const removeFlights = (campaign_id, selected_units) => async dispatch => {
  const campaign_unit_ids = removableCampaignUnitIds(selected_units, campaign_id);
  const res = await delWithBody(`/api/v1/campaigns/${campaign_id}/timeline_flights`, { campaign_unit_ids });
  const { campaign_units_deleted } = res.data;
  dispatch({ type: REMOVE_FLIGHTS, payload: { campaign_units_deleted } });
};

export const UPDATE_CAMPAIGN_UNITS = "marketplace/campaign/campaign_units/update-campaign-units";
export const updateCampaignUnits = (campaign_id, campaign_units) => async dispatch => {
  const res = await post(`/api/v1/timeline/update_campaign_units`, { timeline: { campaign_id, campaign_units } });
  const { units_updated } = res.data;
  dispatch({ type: UPDATE_CAMPAIGN_UNITS, payload: { campaign_id, units_updated } });
}

export const UPDATE_FLIGHT_DATES = "marketplace/campaign/campaign_units/update-flight-dates";

export const LOAD_MORE_RESULTS = 'marketplace/campaign/campaign_units/load_more_results'
export const viewMoreResults = (campaign_id, offset, showing_all_flights) => async dispatch => {
  const new_offset = (offset === 0 ? 50 : offset + 100);
  const res = await get(`/api/v1/timeline?campaign=${campaign_id}&offset=${new_offset}&limit=100`)
  const { units, more_results_count } = res.data;
  dispatch({ type: LOAD_MORE_RESULTS, payload: { units, campaign_id, more_results_count, offset: new_offset, showing_all_flights } });
}

export const LOAD_ALL_CAMPAIGN_UNITS = "marketplace/campaign/campaign_units/load-all-campaign-units";
export const loadAllCampaignUnits = campaign_id => async dispatch => {
  const res = await get(`/api/v1/timeline?campaign=${campaign_id}`)
  const { units, more_results_count, offset } = res.data;
  dispatch({ type: LOAD_ALL_CAMPAIGN_UNITS, payload: { units, campaign_id, more_results_count, offset } });
}

export const CREATE_HOLDS = "marketplace/campaign/campaign_units/create-holds";
export const createHolds = (campaign_id, selected_units) => async dispatch => {
  const campaign_unit_ids = filteredCampaignUnitIds(selected_units, ["proposed"], campaign_id);
  const res = await post(`/api/v1/timeline/create_holds`, { timeline: { campaign_unit_ids } });
  const { campaign_units_updated } = res.data;
  dispatch({ type: CREATE_HOLDS, payload: { campaign_units_updated } });
}

export const SUBMIT_BOOKINGS_JOB = "marketplace/campaign/campaign_units/submit-job";
export const submitBookingsJob = (campaign_id, selected_units) => async dispatch => {
  const campaign_unit_ids = filteredCampaignUnitIds(selected_units, ["proposed", "on_hold"], campaign_id);
  const res = await post(`/api/v1/timeline/check_availability`, {
    timeline: { campaign_unit_ids, campaign_id }
  });
  if (res.status == 200) {
    const { job_id } = res.data;
    dispatch({ type: SUBMIT_BOOKINGS_JOB, payload: { job_id } });
  }
};

export const CREATE_BOOKINGS = "marketplace/campaign/campaign_units/create-bookings";
export const HAS_CONFLICTS = "marketplace/campaign/campaign_units/has-conflicts";
export const createBookings = (
  campaign_id,
  available_units,
  unavailable_units
) => async dispatch => {
  if (unavailable_units && unavailable_units.length)
    dispatch({ type: HAS_CONFLICTS, payload: { available_units, unavailable_units } });
  else if (available_units && available_units.length) {
    const res = await post(`/api/v1/timeline/create_bookings`, {
      timeline: { campaign_unit_ids: available_units.map(u => u.campaign_unit_id), campaign_id }
    });
    if (!!!res.status == 200) {
      GlobalActions.showError("Error creating bookings, please contact AdQuick")
      console.log(res)
      return
    }
    const { campaign_units_updated, booked_campaign_token } = res.data;
    dispatch({ type: CREATE_BOOKINGS, payload: { campaign_units_updated, booked_campaign_token } });
  } else console.log({ data: res.data });
};

function filteredCampaignUnitIds(units, workflow_states, campaign_id) {
  return units
    .map(unit => {
      return unit.campaign_units
        .filter(cu => {
          return workflow_states.includes(cu.workflow_state) && cu.campaign_id == campaign_id;
        })
        .map(campaign_unit => campaign_unit.id);
    })
    .flat();
}

function removableCampaignUnitIds(units, campaign_id) {
  return units
    .map(unit => {
      return unit.campaign_units
        .filter(cu => {
          return cu.campaign_id == campaign_id;
        })
        .map(campaign_unit => campaign_unit.id);
    })
    .flat();
}
