import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { post } from '../../../marketplace/utils/api';
import GlobalActions from "../../actions/GlobalActions";
import { createTag, fetchUnitTagOptions } from '../Inventory/Unit/actions';
import { hideModal } from "./actions";

class UpdateAttributesModal extends React.Component {

  constructor(props) {
    super(props);
    this.saveAttributes = this.saveAttributes.bind(this);
    this.state = {
      validationErrors: [],
      selected_tags: []
    }
  }

  async componentDidMount() {
    const { fetchUnitTagOptions } = this.props;
    await fetchUnitTagOptions();
  }

  async saveAttributes(event) {
    event.preventDefault()
    const units = this.props.selected_units
    const tags = this.state.selected_tags

    try {
      const params = {
        unit_ids: units.map(u => u.id),
        custom_tags: tags.map(t => t.value)
      }
      await post("/api/v1/unit_tags/bulk_set_for_unit", params);
      this.props.hideModal();
      GlobalActions.showMessage("Unit attributes updated with success.")
    } catch (error) {
      GlobalActions.showError('Error saving unit attributes')
      if (error.responseJSON) {
        return this.setState({validationErrors: error.responseJSON.errors})
      }
      this.setState({error: 'Error saving unit attributes'})
    }
  }

  render() {
    const {
      hideModal,
      show
    } = this.props;
    const selectedLength = this.props.selected_units.length;
    const message = `Editing attributes for ${selectedLength} units. This will add the selected attributes to those units`
    return (
      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{selectedLength === 0 ? 'Select units on inventory page to update availability' : message} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderOptions()}
        </Modal.Body>
      </Modal>
    )
  }

  tagOptions() {
    const unit_tag_options = this.props.unit_tag_options || [];
    return unit_tag_options;
  }


  onTagChange(_tags, selectedTag) {
    const { selected_tags } = this.state;
    this.setState({
      selected_tags: selected_tags.concat(selectedTag.option)
    });
  }

  async onTagCreate(tag) {
    const { createTag } = this.props;
    await createTag(tag);
  }

  async onSearch(text) {
    const options = this.tagOptions();
    const match = options.filter(o => o.label.toLocaleLowerCase().includes(text.toLocaleLowerCase()) );
    return match;
  }

  async onTagRemove(e, tag_id) {
    const filtered_tags = this.state.selected_tags.filter(t => t.value !== tag_id)
    this.setState({
      selected_tags: filtered_tags
    })
  }

  renderSelectedTags() {
    const { selected_tags } = this.state;
    if (isEmpty(selected_tags)) { return; }
    return (
      <div>
        <h5
          style={{color: "#4A90E2"}}
        >
          Selected Unit Attributes
        </h5>
        <div style={{
          display: "flex",
          flexWrap: "wrap"
        }}>
          {selected_tags.map((tag, idx) => {
            return (
              <div
                className="selected-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "rgba(74, 144, 226, 0.06)",
                  borderRadius: "4px",
                  color: "#4A90E2",
                  padding: "5px 0 5px 10px",
                  margin: "3px 5px"
                }}
                key={`tag-${idx}`}
              >
                <div>{tag.label}</div>
                <div
                  onClick={(e) => this.onTagRemove(e, tag.value)}
                  style={{
                  padding: "0 12px",
                  fontWeight: "bolder",
                  cursor: "pointer"
                }}
                >
                  x
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }

  renderOptions() {
    const unit_tag_options = this.tagOptions();
    return (
      <div id="unit-tags">
        <div className="header-section">
          <div className="header">
            <label>Unit Attributes</label>
          </div>
        </div>
        <AsyncCreatableSelect
          className="selectInput"
          isClearable={false}
          placeholder="Select Unit Attributes"
          isMulti
          onChange={this.onTagChange.bind(this)}
          onCreateOption={this.onTagCreate.bind(this)}
          loadOptions={this.onSearch.bind(this)}
          defaultOptions={unit_tag_options}
          styles={{
            multiValue: (base) => ({ ...base, display: "none" })
          }}
        />
        {this.renderSelectedTags()}
        <input type="submit" value='Save' className='btn btn-primary' style={{marginTop: '10px'}} onClick={ e => this.saveAttributes(e)}/>
        <input type="submit" value='Close' className='btn btn-default' style={{marginTop: '10px', marginLeft: '20px'}} onClick={ _e => this.props.hideModal()}/>
      </div>
    );
  }

};

export default connect(
  ({inventory: {selected_units, unit_tag_options}}) => {
    return {selected_units, unit_tag_options};
  }, {hideModal, createTag, fetchUnitTagOptions }
)(UpdateAttributesModal);
