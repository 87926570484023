import React, { Component } from 'react';
import cs from 'classnames';
import { isMobile } from '../../../utils/mobile';
import Thumbnail from './Thumbnail';
import UnitTypeIcon from './UnitTypeIcon';
import FaceId from './FaceId';
import PillBadge from './PillBadge';
import Size from './Size';
import Cpm from './Cpm';
import Orientation from './Orientation';
import Price from './Price';
import Impressions from './Impressions';
import RecommendedBadge from './RecommendedBadge';
import NextBooked from './NextBooked';
import Favorite from "../../Campaign/Favorite";
import FairPriceIcon from "../../UnitInfo/FairPriceIcon";

class Card extends Component {

  constructor(props){
    super(props);
    const { unit, activeUnitId } = this.props;
    const isCurrentlyActive = unit.id === activeUnitId
    this.state = {
      isCurrentlyActive
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeUnitId) {
      this.setState({ isCurrentlyActive: nextProps.activeUnitId === this.props.unit.id})
    } else {
      // Filters clear active unit, so no unit can be in active status
      this.setState({ isCurrentlyActive: false })
    }
  }

  renderUnitName() {
    const { campaign } = this.props
    if (campaign.campaign && campaign.campaign.is_mock) { return false }
    return true
  }

  isFavorited() {
    const { unit } = this.props;
    return unit.campaign_units && unit.campaign_units.find(u => u.is_favorited);
  }

  render(){
    const { unit, campaign_permissions, campaign, onUnitCardClick, idx, hide_unit_prices } = this.props;
    const { isCurrentlyActive } = this.state;
    const price_guide_disabled = campaign && campaign.campaign ? campaign.campaign.price_guide_disabled : false;

    if(!unit || _.isEmpty(unit)) return null;
    return (
      <div id={unit.id} className={cs('campaign_catalog_card', { active: isCurrentlyActive })} onClick={() => { onUnitCardClick(unit.id, idx) }}>
        <div className="card_thumb">
          <Thumbnail src={unit.image_file_link} />
        </div>
        <div className="card_title_and_badges">
          <h2 className="title">
            {unit.supplier_face_id && !unit.lime &&
              <FaceId label={(campaign.campaign.is_mock || campaign.campaign.hide_face_ids) ? unit._id : unit.supplier_face_id} />
            }
            {this.renderUnitName() &&
              <span className="unit_name">{unit.name}</span>
            }
          </h2>
          <UnitTypeIcon isLime={unit.lime} type={unit.unit_type} />
          {(!!!price_guide_disabled && campaign_permissions.can_view_price_guide) && <FairPriceIcon
            unit={unit}
            iconSize={16}
            style={{ display: 'inline-block', paddingRight: 5 }}
          />}
          <PillBadge label={unit.screen_type || 'static'} className="screen_type_badge" />
          {campaign_permissions && campaign_permissions.can_view_supplier_name &&
            <PillBadge label={unit.supplier_name} className="supplier_name_badge" />
          }
        </div>
        <div className="card_size_and_cpm">
          <ul>
            <Size size={unit.size} isLime={unit.lime} />
            {!hide_unit_prices && <Cpm unit={unit} campaign={campaign.campaign} cpm={unit.cpm} />}
            { unit.orientation && _.trim(unit.orientation) != '' &&
              <Orientation orientation={unit.orientation} />
            }
          </ul>
        </div>
        <div className="card_price_and_impressions">
          <ul>
            <Price
              unit={unit}
              campaign={campaign.campaign}
              isTransit={!!unit.media_subtypes}
              campaignHidePrices={campaign.hide_prices}
              hide_unit_prices={hide_unit_prices}
            />
            <Impressions impressions={unit.impressions} />
            {campaign && unit.next_booked_diff &&
              <NextBooked diff={unit.next_booked_diff} />
            }
          </ul>
        </div>
        <div className="card_recommended">
          {(campaign_permissions && (campaign_permissions.can_view_recommended || campaign_permissions.can_recommend_units)) &&
            <RecommendedBadge
              unitId={unit.id}
              campaignId={campaign.campaignId}
              isRecommended={unit.is_recommended}
              canRecommend={campaign_permissions.can_recommend_units}
            />
          }
        </div>
        <div className="card_favorite">
          {campaign_permissions && campaign_permissions.can_view_favorites &&
            <Favorite
              isFavorited={this.isFavorited()}
              unitId={unit.id}
              onFavoriteUnit={this.props.onFavoriteUnit}
              showCallback={false}
            />
          }
        </div>
      </div>
    )
  }
}

export default Card
