import {
  LOAD_SHIP_TO_CONTACTS,
  CREATE_SHIPPING_ADDRESS
} from "../actions/supplier_contacts_actions";
import {
  ISupplierShippingAddress,
  onLoadContacts,
  onAddContact
} from "../models/supplier_address"

const initialState: ISupplierShippingAddress = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SHIP_TO_CONTACTS:
      return onLoadContacts(state, action.payload);
    case CREATE_SHIPPING_ADDRESS:
      return onAddContact(state, action.payload);
    default:
      return state;
  }
};

