import React from 'react';
import createClass from 'create-react-class';
import { browserHistory } from 'react-router-legacy';
import GlobalActions from '../../actions/GlobalActions'
import Form from './Form'

export default createClass({
  displayName: 'EmailTemplates/Create',

  render() {
    return (
      <div className="container">
        <h2>Create Email Template</h2>
        <Form />
      </div>
    )
  }

});
