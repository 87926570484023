import { SET_ADVERTISER } from '../actions/greylabel'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ADVERTISER: {
      const { advertiser, agency } = action.payload;
      return {
        ...state,
        advertiser,
        agency
      };
    }
    default: {
      return {
        ...state,
        advertiser: AppConfig.advertiserGreyLabel,
        agency: AppConfig.agencyGreyLabel
      };
    }
  }
};
