import isEqual from "lodash/isEqual";
import React from "react";
import { connect } from "react-redux";

import MapStore from "../../stores/MapStore";
import NewMapStore from "../../stores/NewMapStore";
import PlacesStore from "../../stores/PlacesStore";
import { setBounds } from "./actions";
import DrawToolsModal from "./DrawToolsModal";
import MapBox from "./MapBox";
import UnitLabels from "./UnitLabels";
import UnitReach from "./UnitReach";
import { loadCampaign, loadCampaignUnits} from "../Campaign/actions";
import { findDOMNode } from 'react-dom';
class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSteps: [],
      initialized: false,
    };
  }

  componentDidMount() {
    NewMapStore.reset();
    const mapProps = {
      rich_pins: this.props.rich_pins,
      draw_tool: this.props.draw_tool,
      sidebar_popup: this.props.sidebar_popup,
      isCampaignView: this.props.isCampaignView,
      user: this.props.user,
      bounds: this.props.map.bounds,
      campaign_permissions: this.props.permissions,
      hidePrices: this.props.campaign.hide_prices || false,
      onMove: this.props.onMove,
      onUnitClick: this.props.onMarkerClick,
      filterUnits: this.props.filterUnits,
      toggleSelectedMarkers: this.props.toggleSelectedMarkers,
      toggleSelectedPolygons: this.props.toggleSelectedPolygons,
      toggleCustomPOIFormModal: this.props.toggleCustomPOIFormModal,
      selectCoordinates: this.props.selectCoordinates,
      endUnitLoading: this.props.endUnitLoading,
      campaign: this.props.campaign,
      showAvailability: this.showAvailability(),
      selectedTagsForFilter: this.props.selectedTagsForFilter,
      audienceUnits: this.props.audience_units,
      audienceEnabled: this.props.audience_enabled,
      showUnitOrientation: this.props.showUnitOrientation,
      padding: this.props.padding,
      updateCurrentCustomPOI: this.props.updateCurrentCustomPOI,
    };

    const mapbox = new MapBox("new-map", mapProps).init();
    this.props.onMapboxCreated(mapbox);
    this.mapbox = mapbox;
    this.mapbox.props = this.props.map;
    this.setState({ initialized: true });
    this.setBoundsToActualRenderedBox();

    this.loadCampaignData();

    this.unsubscribeList = [
      MapStore.listen(this.onMapStoreChange.bind(this)),
      NewMapStore.listen(this.onNewMapStoreChange.bind(this)),
      PlacesStore.listen(this.onPlacesChange.bind(this)),
    ];

    window.addLoadingStep = this.addLoadingStep.bind(this);
    window.removeLoadingStep = this.removeLoadingStep.bind(this);
    this.refreshMapFromBroadcast = this.refreshMapFromBroadcast.bind(this);

    // Add event listener for adquick:reload_units
    // See Campaign#broadcast_reload_units_event and make sure the "target" matches
    // This current is #new-map
    this.mapElement = findDOMNode(this);
    this.mapElement.addEventListener('adquick:reload_units', this.refreshMapFromBroadcast);
  }


  // Add cleanup method
  componentWillUnmount() {
    if (this.mapElement) {
      this.mapElement.removeEventListener('adquick:reload_units', this.refreshMapFromBroadcast);
    }
  }

  async refreshMapFromBroadcast() {
    this.setState({ hideLoading: true });
    await this.props.loadCampaignUnits(this.props.campaign.token, true);
    this.setState({ hideLoading: false });
  }

  removeLoadingStep(name) {
    const steps = this.state.loadingSteps.slice();
    this.setState({ loadingSteps: steps.filter(step => step !== name) });
    return this.state.loadingSteps;
  }

  addLoadingStep(name) {
    const steps = this.state.loadingSteps.slice();
    steps.push(name);
    this.setState({ loadingSteps: steps });
    return this.state.loadingSteps;
  }

  componentDidUpdate(prevProps) {
    const { units, campaign, map, bounds, selectedTagsForFilter } = this.props;
    if (!isEqual(prevProps.campaign, campaign)) {
      this.loadCampaignData();
    }
    if (!isEqual(prevProps.units, units)) {
      this.loadCampaignUnits();
    }
    if (!isEqual(prevProps.map, map)) {
      this.mapbox.props = map;
    }
    if (!isEqual(prevProps.audience_units, this.props.audience_units)) {
      this.mapbox.filterByAudience(this.props.audience_units);
    }
    if (!isEqual(prevProps.audience_enabled, this.props.audience_enabled)) {
      this.mapbox.toggleAudience(this.props.audience_enabled);
    }
    if (!prevProps.bounds && bounds) {
      const map_bounds = [
        [bounds.min_lon, bounds.min_lat],
        [bounds.max_lon, bounds.max_lat],
      ];
      this.mapbox.props = { bounds: map_bounds };
    }
    if (!isEqual(prevProps.selectedTagsForFilter, selectedTagsForFilter)) {
      this.mapbox.selected_tags_for_filter = selectedTagsForFilter;
    }
  }

  setBoundsToActualRenderedBox() {
    // By doing this, the sidebar will "listen" to the actual
    // rendered bounds and thus render new units on add even
    // when they"re out of the originally campaign bounds.
    // This is the same we do at `onMove`.
    const { _sw, _ne } = this.mapbox.map.getBounds();
    const bounds = [
      [_sw.lng, _sw.lat],
      [_ne.lng, _ne.lat],
    ];
    this.props.setBounds(bounds);
  }

  loadCampaignData() {
    this.loadCampaignUnits();
    this.loadCampaignPlacemarkers();
    this.loadZipCodes();
    this.loadCampaignArea();
  }

  loadCampaignUnits() {
    if (!this.mapbox || !this.props.campaign || !this.props.campaign.token || !this.props.units) return;

    this.mapbox.units = this.props.units;
    this.loadCampaignUnitGeojsons();
  }

  async loadCampaignUnitGeojsons() {
    if ((this.mapbox.geojsons && this.mapbox.geojsons.length > 0) || _.isEmpty(this.props.units.all_units)) return;
    const geojsons = await NewMapStore.loadCampaignUnitGeojsons(this.props.units.all_units);
    this.mapbox.geojsons = geojsons;
    this.mapbox.updateGeojson();
  }

  async loadCampaignPlacemarkers() {
    if (!this.props.campaign || !this.props.campaign.token) return;
    const placemarkers = await NewMapStore.loadCampaignPlacemarkers(this.props.campaign.token);
    if (!_.isEmpty(this.props.filtered_pois)) {
      placemarkers.features = placemarkers.features.filter(o => this.props.filtered_pois.includes(o.properties.label));
    }
    this.mapbox.placemarkers = placemarkers;
    this.mapbox.updateMap();
  }

  async loadZipCodes() {
    if (!this.props.campaign || !this.props.campaign.token) return;

    const zip_codes = await NewMapStore.loadZipCodes(this.props.campaign.token);
    if (!zip_codes) return;

    this.mapbox.zip_codes = zip_codes;
    this.mapbox.updateMap();
  }

  async loadCampaignArea() {
    if (!this.props.campaign || !this.props.campaign.token) return;

    const campaign_area = await NewMapStore.loadCampaignArea(this.props.campaign.token);
    if (!campaign_area) return;

    this.mapbox.campaign_area = campaign_area;
    this.mapbox.updateMap();
  }

  componentWillUnmount() {
    if (this.unsubscribeList) {
      this.unsubscribeList.map(fn => fn());
    }
  }

  onMapStoreChange(event) {
    if (event === "map:showDataLayer") {
      const visibleDataLayer = MapStore.state.visibleDataLayer;
      if (visibleDataLayer) this.mapbox.updateDataLayer(visibleDataLayer.dataLayer());
      else this.mapbox.updateDataLayer();
    }
  }

  onPlacesChange(event) {
    if (event === "places:filtered_by_label") this.loadCampaignPlacemarkers();
    if (event === "places:updated") this.loadCampaignPlacemarkers();
    if (event === "places:nearby_units_added") this.loadCampaignUnits();
  }

  async onNewMapStoreChange(event) {
    if (event === "map:zoomOut") this.mapbox.map.flyTo({ zoom: this.mapbox.map.getZoom() - 1 });
    if (event === "map:togglePrice") this.mapbox.togglePrice();
    if (event === "map:toggleFaceId") this.mapbox.toggleFaceId();
    if (event === "map:toggleDesignAssets") this.mapbox.toggleDesignAssets();
    if (event === "map:showAdwords") {
      const data_layer = await NewMapStore.loadAdwords(this.props.campaign.id);
      this.mapbox.updateDataLayer(data_layer);
    }
    if (event === "map:hideAdwords") this.mapbox.updateDataLayer();
  }

  showAvailability() {
    const {
      campaign: { advertiser },
    } = this.props;
    return advertiser && advertiser.unit_availability_flow;
  }

  availsLoadingIndicator() {
    // Match this id with CampaignAvailsBatch.stream_target
    return <div id="avail_batch_stream_target"></div>;
  }

  loadingIndicator() {
    let css = "";

    if (!this.state.hideLoading && (this.props.map.loading || !this.props.unitsLoaded)) {
      css = "is_loading";
    }

    return (
      <div className={css} style={{ zIndex: 1 }}>
        <div className="ui map-loading map-loading-big" style={{ zIndex: 1 }}>
          <i className="fal fa-spinner-third fa-spin"></i>
          <p className="map-loading-title">Your campaign is loading</p>
          <p className="map-loading-subtitle">Please wait</p>
        </div>
      </div>
    );
  }

  stepLoading() {
    const css = Boolean(this.state.loadingSteps.length) ? "is_loading" : "";

    return (
      <div id="map-step-loading" className={`ui map-step-loading ${css}`}>
        <div className="step-loading__container">
          {this.state.loadingSteps.map((step, i) => (
            <div className="step-loading__step" key={`loading-step-${i}`}>
              <i className="fal fa-spinner-third fa-spin"></i>
              {step}
            </div>
          ))}
        </div>
      </div>
    );
  }

  cta_button() {
    const { campaign, permissions } = this.props;
    if (!permissions.can_only_view_planner_campaigns) return null;
    const route = `/signup?email=${encodeURIComponent(campaign.visitor_email)}`;
    return(
      <a href={route}
         className="ui blue soft-shadow button"
         target="_blank"
         style={{position: 'absolute', bottom: '26px', right: '16px', zIndex: 2 }}>
        Talk with our team to learn more about AdQuick
      </a>
    )

  }

  render() {
    return (
      <div className="new-map" style={{ width: "100%", height: "100%" }}>
        {this.stepLoading()}
        {this.cta_button()}
        {this.availsLoadingIndicator()}
        {this.props.showLoadingIndicator && this.loadingIndicator()}
        {this.state.initialized && this.props.units && (
          <div>
            <UnitLabels mapbox={this.mapbox} show_availability={this.showAvailability()} />
            <UnitReach
              mapbox={this.mapbox}
              show_units_reach={this.props.map.show_units_reach}
              units={this.props.units}
            />
          </div>
        )}
        <DrawToolsModal {...this.props} />
        <div style={{ height: "100%" }} id="new-map"></div>
      </div>
    );
  }
}

const mapStateToProps = ({ units, places_ui }) => ({
  filtered_pois: places_ui.filtered,
  audience_enabled: _.get(units, "unit_filter.audience_enabled", null),
  audience_units: _.get(units, "unit_filter.audience_units", null),
  selectedTagsForFilter: _.get(units, "unit_filter.tags", null),
  unitsLoaded: _.get(units, "loaded", false),
});

export default connect(mapStateToProps, { setBounds, loadCampaign, loadCampaignUnits })(Map);
