import React from 'react';

export default ({setSegmentType}: {setSegmentType: Function}) => (
  <div className="geopath-segment-form geopath-segment-type">
    <h3>Choose which option you'd like</h3>
    <button onClick={() => setSegmentType("demographic")}>Demographic segment</button>
    <button onClick={() => setSegmentType("psycographic")}>Psychographic segment</button>
  </div>
);

