import { connect } from 'react-redux';
import React from 'react';
import { Modal } from 'react-bootstrap';
import createClass from 'create-react-class';
import { fetchActivityLogs, restoreCampaignUnit } from './Activity/actions'
import Group from './Activity/Group'
import UnitPopup from '../UnitPopup';
import Loading from "../Loading";

const Activity = createClass({
  displayName: 'Campaign/Activity',

  getInitialState() {
    return {
      popup_open: false,
      unit_id: ''
    }
  },

  async componentWillMount() {
    await this.props.fetchActivityLogs()
  },

  componentDidMount() {
    this.interval = setInterval(
      () => this.props.fetchActivityLogs(),
      10000
    );
  },

  componentWillUnmount() {
    clearInterval(this.interval);
  },

  toggleUnitModal(unit_id = '') {
    const { popup_open } = this.props
    this.setState({ unit_id, popup_open: !popup_open })
  },

  renderActivity() {
    const { activity_logs, restoreCampaignUnit, fetchActivityLogs, currentUser, isFetching } = this.props;

    if (_.isEmpty(activity_logs) && !isFetching) {
      return (<div key={1}>
        <div style={{ textAlign: 'center', paddingTop: 30, fontWeight: '600' }}>
          No activity yet
        </div>
      </div>);
    }

    if (_.isEmpty(activity_logs) && isFetching) {
      return <Loading>Loading activity logs</Loading>;
    }

    const groupStyle = {
      fontSize: 21,
      paddingBottom: 8,
      marginBottom: 24,
      borderBottom: '1px solid #eee'
    }

    // Building array manually rather than on a loop to ensure group order
    // otherwise, if one of the groups is initially empty and later populated
    // it'd be placed at the bottom.
    return(
      [<Group
        key='TODAY'
        heading='Today'
        group_label='today'
        activityLogs={activity_logs['TODAY']}
        restoreCampaignUnit={restoreCampaignUnit}
        fetchActivityLogs={fetchActivityLogs}
        timeFormat='h:mma'
        toggleUnitModal={this.toggleUnitModal}
      />,
      <Group
        key='THIS_WEEK'
        heading='This Week'
        group_label='this_week'
        activityLogs={activity_logs['THIS_WEEK']}
        restoreCampaignUnit={restoreCampaignUnit}
        fetchActivityLogs={fetchActivityLogs}
        timeFormat='h:mma'
        toggleUnitModal={this.toggleUnitModal}
      />,
      <Group
        key='LAST_WEEK'
        heading='Last Week'
        group_label='last_week'
        activityLogs={activity_logs['LAST_WEEK']}
        restoreCampaignUnit={restoreCampaignUnit}
        fetchActivityLogs={fetchActivityLogs}
        timeFormat='h:mma'
        toggleUnitModal={this.toggleUnitModal}
      />,
      <Group
        key='ALL'
        heading='All'
        group_label='all'
        activityLogs={activity_logs['ALL']}
        restoreCampaignUnit={restoreCampaignUnit}
        fetchActivityLogs={fetchActivityLogs}
        timeFormat='h:mma'
        toggleUnitModal={this.toggleUnitModal}
      />
      ]
    )
  },

  render() {
    return (
    <div className="campaignNew__list">
      <Modal
        show={this.state.popup_open}
        onHide={() => this.setState({ popup_open: false})}
        onEnter={() => { $('.modal').attr('tabindex', null); }}
        autoFocus={false}
      >
        <UnitPopup
          unit_id={this.state.unit_id}
          units={this.props.data.units}
          activityView={true}
          onClose={() => this.setState({ popup_open: false})}
          withDeleted={true}
          {...this.props.unitPopup}
        />
      </Modal>
      {this.renderActivity()}
    </div>);
  },
});

export default connect(
  ({ activity, currentUser }) => ({ ...activity, currentUser }),
  { fetchActivityLogs, restoreCampaignUnit }
)(Activity)
