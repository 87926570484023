import {
  FETCH_QA_ISSUES_SUCCESS
} from './actions';

const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case FETCH_QA_ISSUES_SUCCESS:
      return {
        ...state,
        ...payload
      }
    default: return state;
  }
}
