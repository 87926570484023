import React, { useState, ChangeEvent } from "react";
import cs from "classnames";
import isEmpty from "lodash/isEmpty"
import { post } from "../../../../utils/api";
import GlobalActions from "../../../../actions/GlobalActions";

interface IPricingModal {
  onHide: () => void;
  loadCampaignUnits: (campaign_token: string) => void;
  campaign_token: string;
  unit_ids: Array<string>;
};

const MassUpdatePricingModal = ({ onHide, loadCampaignUnits, campaign_token, unit_ids }: IPricingModal) => {
  const [pricing_percentage, setPricingPercentage] = useState("");
  const [normalize_with_supplier_price_margin, toggleNormalizePrice] = useState(false)
  const [saving, toggleSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const renderBody = () => {
    const hasErrors = !isEmpty(errors['pricing_percentage']);
    return (
      <div
        className={cs("form-group", {"has-error": hasErrors })}
      >
        <label>Cost Adjustment %</label>
        <div className="input-group">
          <input
            style={{width:"auto",borderTopRightRadius:"4px",borderBottomRightRadius:"4px"}}
            className="form-control"
            value={pricing_percentage}
            type="text"
            onChange={onPricingChange}/>
          <div
            style={{
              height:"32px",
              display:"flex",
              alignItems:"center",
              paddingLeft:"5px"
            }}
          >%</div>
        </div>
        <em>E.g:</em> Type 10 to increase 10%, or -10 to reduce 10%

        <div className="form-check" style={{marginTop: '10px'}}>
          <input
            style={{width:"auto",borderTopRightRadius:"4px",borderBottomRightRadius:"4px"}}
            type="checkbox"
            defaultChecked={false}
            className="form-check-input"
            checked={normalize_with_supplier_price_margin}
            onChange={onNormalizeChanged}/>
          <label className="form-check-label" style={{marginLeft: '5px'}}>Normalize price by supplier price %</label>
        </div>
        <div>
        To normalize margin, we will reset the Client Price to match Supplier Submitted Price and then apply your margin.  Please do not take this action if you want margin to be applied to a price different from the supplier submitted price.
        </div>
        <div>
          <em>E.g:</em> Type 25 on the cost adjustment field, if the Supplier Price is 100 the Client Price will be updated to 125
        </div>
        <span
          className="help-block"
          style={{
            fontSize: "13px",
            lineHeight: "16px",
            color: "#858585"
          }}
        >
        </span>
        {renderErrors(errors['pricing_percentage'])}
      </div>
    )
  };

  const onPricingChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPricingPercentage(e.target.value);
  }
  const onNormalizeChanged = () => {
    toggleNormalizePrice(!normalize_with_supplier_price_margin);
  }

  const renderErrors = (errors = []) => {
    if(isEmpty(errors)) { return; }
    return errors.map((error, idx) => renderError(error, idx));
  };

  const renderError = (error, idx) => {
    return (
      <span
        key={`error${idx}`}
        className="help-block"
        style={{color:"red"}}
      >
        {error}
      </span>
    );
  };

  const renderFooter = () => {
    return (
      <div
        style={{display:"flex",justifyContent:"flex-end"}}
      >
        <button
          style={{width:"20%",backgroundColor:"unset",color:"#858585"}}
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          style={{width:"20%"}}
          onClick={onSubmit}
        >
          {saving ? "Saving..." : "Save"}
        </button>
      </div>
    )
  };

  const onSubmit = () => {
    setErrors({});
    toggleSaving(!saving);
    return massUpdateUnits();
  };

  const massUpdateUnits = async () => {
    try {
      await post(`/api/v1/campaigns/${campaign_token}/campaign_units/mass_update_pricing`, setPayload());
      await loadCampaignUnits(campaign_token);
      GlobalActions.showMessage("Successfully updated the selected units!");
      onHide();
    } catch (error) {
      console.log(error);
      setErrors({ pricing_percentage: [error] });
      toggleSaving(false);
    }
  };

  const setPayload = () => {
    return {
      campaign_id: campaign_token,
      unit_ids,
      pricing_percentage,
      normalize_with_supplier_price_margin
    };
  }

  return (
    <div style={{padding:"10px 25px"}}>
      {renderBody()}
      {renderFooter()}
    </div>
  )
};

export default MassUpdatePricingModal;
