import React from 'react';
import ReactDOMServer from 'react-dom/server';
import accounting from 'accounting';
import inRange from 'lodash/inRange';

const minOpacity = 0.4
const maxOpacity = 0.9
const noScoreOpacity = 0.4
const default_score_key = 'score'

export const addZipCodeLayers =  (campaignToken, data_layer_id, success) => {
  loadZipCodes(campaignToken, data_layer_id, (data_layer) => {
    success(createDataLayer(data_layer));
  })
}

const loadZipCodes = (campaignToken, data_layer_id, success) => {
  $.ajax({
    dataType: "json",
    url: `/api/v1/data_layers/${data_layer_id}?campaign_id=${campaignToken}`,
    success
  })
}

export const createDataLayer = (data_layer, score_key) => {
  const features = buildFeaturesFromDataLayer(data_layer, score_key);

  const style = feature => ({
    fillOpacity: feature.properties.opacity,
    fillColor: feature.properties.color,
    color: 'gray',
    weight: 0.5
  })

  return L.geoJSON(features, { style, onEachFeature });
}

const buildFeaturesFromDataLayer = (data_layer, score_key) => {
  score_key = score_key || default_score_key;
  let zip_codes = data_layer.zip_codes
  const scores = zip_codes.map(zip => zip[score_key])
  const maxScore = Math.max(...scores)

  const features = zip_codes.map(zipCode => {
    let feature_geometry = {};
    let feature_properties = {};
    if (zipCode.coordinates[0] && zipCode.coordinates[0][0] && !zipCode.coordinates[0][0][0]) {
      zipCode.coordinates = [zipCode.coordinates]
    }
    feature_geometry = {
      coordinates: zipCode.coordinates,
      type: 'Polygon',
    }
    feature_properties = {
      ...zipCode,
      opacity: calculateOpacity(zipCode, maxScore, score_key),
      fillColor: calculateColor(zipCode, maxScore),
      color: calculateColor(zipCode, maxScore)
    }

    return {
      type: 'Feature',
      properties: feature_properties,
      geometry: feature_geometry
    }
  });

  return {
    type: 'FeatureCollection',
    features: features
  };
}

export const calculateOpacity = (zipCode, maxScore, scoreKey) => {
  if (!maxScore) return noScoreOpacity;

  scoreKey = scoreKey || default_score_key;
  const score = zipCode[scoreKey];

  if (score == null) {
    return noScoreOpacity;
  }

  const opacity = score*(maxOpacity-minOpacity)/maxScore + minOpacity;

  return opacity;
}

export const calculateColor = (zip_code, max_score, score_key) => {
  score_key = score_key || default_score_key;
  const score = zip_code[score_key];

  if (zip_code.color) {
    return zip_code.color;
  } else {
    return '#675BB8';
  }
}

const onEachFeature = (feature, layer) => {
  (function (layer, zipCode) {
    layer.on('mouseover', (e) => {
      const popupContent = renderPopup(zipCode);
      const zipPopup = L.popup({ autoPan: false }).setContent(popupContent)
      layer.bindPopup(zipPopup).openPopup();
    });

    layer.on('mouseout', e => layer.closePopup());
  }(layer, feature.properties));
};

const renderPopup = zip_code => {
  const score = zip_code.score;
  let scoreText;
  if (score) {
    if (zip_code.score_type == "n") {
      scoreText = accounting.formatNumber(score)
    } else {
      if (score.toFixed().toString().length > 2) {
        scoreText = "$" + score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        scoreText = score + "%";
      }
    }
  }
  return ReactDOMServer.renderToString(
    <div className="text-left">
      <div><b>{zip_code.zip_code}</b></div>
      <div>{zip_code.place_name}, {zip_code.state_abbreviation}</div>
      {(scoreText) ? <div>{scoreText}</div> : ''}
      {(zip_code.cpc) ? <div>{zip_code.cpc} CPC</div> : ''}
      {(zip_code.clicks) ? <div>{zip_code.clicks} clicks</div> : ''}
      {(zip_code.impressions) ? <div>{zip_code.impressions} impressions</div> : ''}
      {(zip_code.conversions) ? <div>{zip_code.conversions} conversions</div> : ''}
      {(zip_code.cost) ? <div>{zip_code.cost} cost</div> : ''}
      {(zip_code.ctr) ? <div>{zip_code.ctr} CTR</div> : ''}
    </div>
  )
}
