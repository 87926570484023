import React from "react";
import accounting from "accounting";
import { connect } from "react-redux";
import cs from "classnames";
import moment from "moment";
import _ from "lodash";
import createClass from "create-react-class";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import Favorite from "../Campaign/Favorite";
import AuthStore from "../../stores/AuthStore";

import { text_for_flight, list_item_classes } from "./list_item_helper";

import { showAddFlightModal, showBatchAddFlightsModal } from "../Inventory/actions";

const ListItem = createClass({
  displayName: "Timeline/DigitalListItem",
  marginForFlight: 28,
  current_type_overlap_position: 0,
  max_overlap_position: 0,

  onAddFlight() {
    this.props.onUnitSelect(this.props.unit.id, true, true);
    this.props.showBatchAddFlightsModal(this.props.unit);
  },

  onEditFlight(campaign_unit) {
    const { editable, clickable, onShowEditFlightModal } = this.props;
    if (campaign_unit.workflow_state === "available" || !editable || !clickable) {
      return;
    }
    // to handle showing multiple bars for +1 quantity digitial units we dupe them
    // on the back end, and append --i to the id.
    const correct_campaign_unit = {
      ...campaign_unit,
      id: campaign_unit.id.replace(/--\d/, '')
    }
    onShowEditFlightModal(this.props.unit, correct_campaign_unit);
  },

  onDetailsClick() {
    if (this.props.onUnitDetailsClick) {
      this.props.onUnitDetailsClick(this.props.unit.id);
    }
  },

  renderFlightByType(cus, prev_overlap_max) {
    const starting_margin_top = 28 * prev_overlap_max;
    // need to compact because of return conditions
    const current_row = this.renderFlights(cus, starting_margin_top).filter(el =>
      el ? true : false
    );
    return current_row;
  },

  renderAllFlights() {
    const { unit, startDate, endDate } = this.props;
    let current_overlap_position;

    const start = moment.utc(startDate).toDate();
    const end = moment.utc(endDate).toDate();
    if (!unit.campaign_units){return []};
    const campaign_units = unit.campaign_units.filter(cu => {
      if (!!!cu.start_date || !!!cu.end_date) return false;
      const flightStartDate = moment(cu.start_date).toDate();
      const flightEndDate = moment(cu.end_date).toDate();
      if (moment(flightStartDate).isBefore(start) && moment(flightEndDate).isAfter(start))
        return true; // longer on both sides
      if (moment(flightStartDate).isAfter(start) && moment(flightStartDate).isBefore(end))
        return true; // starts in range
      if (moment(flightEndDate).isAfter(start) && moment(flightEndDate).isBefore(end)) return true; // ends in range
      if (moment(flightStartDate).isAfter(start) && moment(flightEndDate).isBefore(end))
        return true; // in between
      return false;
    });
    current_overlap_position = 0;
    const all_line_items = ["available", "proposed", "booked", "movable", "on_hold"].reduce(
      (total, ws) => {
        const filtered_flights = campaign_units.filter(cu => cu.workflow_state == ws);
        const current_flights = this.renderFlightByType(filtered_flights, current_overlap_position);
        const flights_by_type = (total || []).concat(current_flights);
        if (current_flights.length)
          current_overlap_position += this.current_type_overlap_position + 1;
        return flights_by_type;
      },
      []
    );

    this.max_overlap_position = current_overlap_position;

    return all_line_items;
  },

  overlapsDates(f1, f2) {
    if (moment(f1.start_date).isSameOrAfter(moment(f2.end_date).toDate())) return false;
    if (moment(f2.start_date).isSameOrAfter(moment(f1.end_date).toDate())) return false;
    if (moment(f1.end_date).isSameOrBefore(moment(f2.start_date).toDate())) return false;
    if (moment(f2.end_date).isSameOrBefore(moment(f1.start_date).toDate())) return false;
    return true;
  },

  group(flights) {
    if (flights.length == 0) return [];
    let sorted_flights = _.sortBy(flights, "start_date");
    let result = [];
    let next_line = sorted_flights;
    let current_line_number = 0;

    while (!!!next_line.length == 0) {
      let head = next_line.shift();
      let line_without_head = next_line;
      head.position = current_line_number;
      let current_line = [head];
      next_line = [];
      line_without_head.forEach(f => {
        if (this.overlapsDates(f, head)) {
          f.position = current_line_number + 1; // go to the next line
          next_line.push(f);
        } else {
          const current_overlaps = current_line.map(lf => this.overlapsDates(f, lf));
          if (current_overlaps.filter(x => x).length > 0) {
            // f overlaps anyone else on the line?
            f.position = current_line_number + 1; // go to the next line
            next_line.push(f);
          } else {
            f.position = current_line_number; // stay on the line
            current_line.push(f);
          }
        }
      });
      current_line_number += 1;
      result.push(current_line);
    }
    return result ? _.flatten(result) : [];
  },

  renderFlights(scoped_campaign_units, starting_margin_top) {
    const {
      unit,
      middleWidth,
      startDate,
      endDate,
      editable,
      is_campaign_timeline_view
    } = this.props;
    if (!!!scoped_campaign_units) return null;
    const maxWidth = middleWidth;
    this.current_type_overlap_position = 0;

    const days = moment.range(startDate, endDate).diff("day");
    const dayWidth = maxWidth / days;

    const grouped_flights = this.group(scoped_campaign_units);
    if (!!!grouped_flights) return null;
    return grouped_flights.map(campaign_unit => {
      const flightStartDate = moment.utc(campaign_unit.start_date);
      const flightEndDate = moment.utc(campaign_unit.end_date);

      // calculate indentation
      const daysFromStartOfTimeline = moment.range(startDate, campaign_unit.start_date).diff("day");
      let indent =
        dayWidth *
        (daysFromStartOfTimeline == 0 ? daysFromStartOfTimeline : daysFromStartOfTimeline + 1);

      // calculate day range since beginning of viewport date
      let rangeDays = moment
        .range(
          flightStartDate.isBefore(moment.utc(startDate)) ? startDate : campaign_unit.start_date,
          flightEndDate.isAfter(moment.utc(endDate)) ? endDate : campaign_unit.end_date
        )
        .diff("day");
      if (flightStartDate.isBefore(moment.utc(startDate))) {
        rangeDays += 1;
      }

      let width = dayWidth * (rangeDays + 1);
      if (indent < 0) indent = 0;
      if (width + indent >= maxWidth) width = maxWidth - indent;

      const classNames = list_item_classes(unit, campaign_unit);

      let marginTop, overlapPosition;

      overlapPosition = campaign_unit.position;

      if (this.current_type_overlap_position < overlapPosition)
        this.current_type_overlap_position = overlapPosition;

      if (overlapPosition > 0) {
        marginTop = this.marginForFlight * overlapPosition + starting_margin_top;
      } else {
        marginTop = starting_margin_top;
      }

      const show_campaign_timeline_proposed_style =
        is_campaign_timeline_view && campaign_unit.workflow_state == "proposed";

      const flightStyle = {
        marginLeft: indent,
        width,
        overflow: "hidden",
        height: 23,
        marginTop: marginTop + 10,
        opacity: show_campaign_timeline_proposed_style ? 0.6 : 1,
        zIndex: show_campaign_timeline_proposed_style ? 200 : 100
      };

      if (indent > maxWidth || width == 0) flightStyle.display = "none"; // hide it if the flight is going off the screen

      if (flightEndDate.isSameOrAfter(endDate)) {
        flightStyle.borderTopRightRadius = 0;
        flightStyle.borderBottomRightRadius = 0;
      }

      if (flightStartDate.isSameOrBefore(startDate)) {
        flightStyle.borderTopLeftRadius = 0;
        flightStyle.borderBottomLeftRadius = 0;
      }

      const { bar_text, tooltip_text } = text_for_flight(unit, campaign_unit);
      const startDateText = moment.utc(campaign_unit.start_date).format("MMM D");
      const endDateText = moment.utc(campaign_unit.end_date).format("MMM D");
      const overlayComment = (
        <div>
          {tooltip_text} ({startDateText} - {endDateText})
        </div>
      );

      const flightElement = (
        <div
          className={classNames}
          style={flightStyle}
          onClick={this.onEditFlight.bind(this, campaign_unit)}
          key={campaign_unit.id}
        >
          <div>
            {editable && !!!show_campaign_timeline_proposed_style && (
              <span className="text-center">{bar_text}</span>
            )}
            <div style={{ float: "left", opacity: 0.7 }}>{startDateText}</div>
            <div style={{ float: "right", opacity: 0.7 }}>{endDateText}</div>
          </div>
        </div>
      );

      return (
        <Tooltip placement="top" overlay={overlayComment} key={`campaign_unit-${campaign_unit.id}`}>
          {flightElement}
        </Tooltip>
      );
    });
  },

  renderDateMarkers() {
    let index = 0;
    const { headers, middleWidth, height } = this.props;

    return headers.map(() => {
      const markerStyle = {
        width: 1,
        height,
        backgroundColor: "#E6EDF0",
        left: (middleWidth / headers.length) * index,
        position: "absolute",
        zIndex: 1
      };

      index += 1;
      return (
        <div style={{ position: "relative", width: 0, height: 0 }} key={index}>
          <div style={markerStyle} />
        </div>
      );
    });
  },

  renderTimelineItemContent(unit) {
    const { howLong } = this.props;
    const user = AuthStore.state.user;

    if (user.is_admin || user.is_agency) {
      return (
        <div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data timelineItem__supplier_name text-muted">
              {unit.supplier_name}
            </div>
          </div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data timelineItem__size">
              {unit.size && (
                <span>
                  <span className="text-muted">Size</span> {unit.size}
                </span>
              )}
            </div>
            <div className="timelineItem__text_data timelineItem__impressions">
              {unit.impressions && unit.impressions > 0 ? (
                <span className="text-muted">
                  {accounting.format(unit.impressions)} imp/
                  {howLong}w
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else if (user.is_supplier_admin) {
      return (
        <div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data timelineItem__size">
              {unit.size && (
                <span>
                  <span className="text-muted">Size</span> {unit.size}
                </span>
              )}
            </div>
            <div className="timelineItem__text_data" style={{ float: "right" }} />
          </div>
          <div className="timelineItem__row">
            <div className="timelineItem__text_data">
              {unit.cpm && (
                <span>
                  <span className="text-muted">CPM </span>
                  {unit.cpm}
                </span>
              )}
            </div>
            <div className="timelineItem__text_data timelineItem__impressions">
              {unit.impressions && unit.impressions > 0 ? (
                <span className="text-muted">
                  {accounting.format(unit.impressions)} imp/
                  {howLong}w
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="timelineItem__row">
          <div className="timelineItem__text_data timelineItem__size">
            {unit.size && (
              <span>
                <span className="text-muted">Size</span> {unit.size}
              </span>
            )}
          </div>
          <div className="timelineItem__text_data timelineItem__impressions">
            {unit.impressions && unit.impressions > 0 ? (
              <span className="text-muted">
                {accounting.format(unit.impressions)} imp/
                {howLong}w
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  },

  render() {
    const {
      unit,
      howLong,
      middleWidth,
      rightWidth,
      leftWidth,
      editable,
      filters,
      is_campaign_timeline_view,
      isInventoryView,
      isInCart
    } = this.props;

    const all_flights = this.renderAllFlights();

    const className = cs(`timelineItem_${unit.unit_type}`, "timelineItem", {
      timelineItem_active: this.props.isActive,
      "direct-match":
        filters &&
        filters.q &&
        unit.supplier_face_id &&
        filters.q == unit.supplier_face_id.toString()
    });

    const thumbnailWidth = 50;
    const permissions = this.props.permissions || {};

    const styles = {
      thumbnail: {
        backgroundImage: `url(${
          unit.lime
            ? "https://adquick.imgix.net/uploads/unit_image/lime_square.jpg"
            : unit.thumbnail_url
        })`,
        border: "1px solid #eee",
        width: thumbnailWidth,
        marginTop: "11px"
      },
      flights: {
        float: "left",
        width: middleWidth
      },
      pricing: {
        width: rightWidth
      },
      leftSide: {
        float: "left",
        width: leftWidth
      }
    };

    let rowHeight = 70;
    if (this.max_overlap_position > 0) {
      rowHeight = 53 + this.max_overlap_position * this.marginForFlight;
    }

    const selected =
      this.props.selectedUnitIds && unit && this.props.selectedUnitIds.includes(unit.id);

    let actionButton, selectedCheckbox;
    if (editable) {
      actionButton = (
        <div
          className="btn btn-default btn-sm"
          style={{ margin: "auto", marginTop: 18 }}
          onClick={this.onAddFlight}
        >
          <i className="fa fa-plus" />
        </div>
      );

      selectedCheckbox = (
        <div
          className="btn btn-default btn-sm"
          style={{ margin: "auto", marginTop: 18 }}
          onClick={e => this.props.onUnitSelect(unit.id, !!!selected)}
        >
          {selected === true ? (
            <i className="fa fa-check-square-o" />
          ) : (
            <i className="fa fa-square-o" />
          )}
        </div>
      );
    } else if (permissions.can_book_unit) {
      actionButton = (
        <div className="btn btn-primary btn-sm" style={{ margin: "auto" }}>
          Add To Cart
        </div>
      );
    }

    const unavailableStyle = {
      width: "100%",
      height: rowHeight,
      opacity: 0.8,
      background: "#fff",
      textAlign: "center",
      paddingTop: 20,
      zIndex: 999,
      position: "absolute",
      color: "gray",
      fontWeight: "bold"
    };

    return (
      <div className={className} style={{ height: rowHeight }}>
        {unit.unavailable && (
          <div style={unavailableStyle}>
            This unit is partially unavailable due to a booking by another customer.
          </div>
        )}

        <div style={styles.leftSide} onClick={this.onDetailsClick}>
          {(!unit.thumbnail_url && !!!is_campaign_timeline_view) && (
            <div className="timelineItem__image no_image" style={styles.thumbnail}>
              <i className="fa fa-image" style={{ marginRight: 0 }} />
            </div>
          )}
          {unit.thumbnail_url && <div className="timelineItem__image" style={styles.thumbnail} />}
          <div className="timelineItem__content">
            {this.props.isCampaignView && permissions.can_view_favorites && (
              <div className="timelineItem__favorite">
                <Favorite
                  isFavorited={unit.is_favorited}
                  unitId={unit.id}
                  onFavoriteUnit={this.props.onFavoriteUnit}
                  showCallback={false}
                />
              </div>
            )}
            <h2 className="timelineItem__title">
              {(isInCart && !!!isInventoryView) && <i className="fa fa-check" />}
              {unit.supplier_face_id && (
                <span className="listItem__face_id">{unit.supplier_face_id}</span>
              )}
              {unit.name}
            </h2>
            {this.renderTimelineItemContent(unit)}
          </div>
        </div>
        <div className="timelineItem__flights" style={styles.flights}>
          {this.renderDateMarkers()}
          {all_flights}
        </div>
        <div className="timelineItem__pricing" style={styles.pricing}>
          {is_campaign_timeline_view ? (
            <div>
              <input
                type="checkbox"
                checked={selected}
                onClick={e => this.props.onUnitSelect(unit.id, !!!selected)}
                style={{ display: "inline-block", margin: 30 }}
              />
            </div>
          ) : (
            <div>
              {AppConfig.showPricing && !editable && (
                <div className="timelineItem__price">
                  {accounting.formatMoney(unit.price)}/{howLong}w
                </div>
              )}
              <div className="timelineItem__actions" style={{ width: "100%", textAlign: "center" }}>
                <span style={{ verticalAlign: "middle", marginLeft: 10 }}>{actionButton}</span>
                <span style={{ verticalAlign: "middle", marginLeft: 10 }}>{selectedCheckbox}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
});

ListItem.propTypes = {
  unit: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  middleWidth: PropTypes.number.isRequired,
  rightWidth: PropTypes.number.isRequired,
  leftWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default connect(
  null,
  { showAddFlightModal, showBatchAddFlightsModal }
)(ListItem);
