import React from 'react';
import cs from 'classnames';
import { connect } from 'react-redux';
import CheckboxFilter from '../../CheckboxFilter';
import { isMobile } from '../../../utils/mobile';
const POPUP_WIDTH = 400;

class MoreFilters extends React.Component {

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
    this.onDone = this.onDone.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {
      isExpanded: false
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true })
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false })
    }
  }

  onCheckboxSelect(name, checked) {
    const { setFilter } = this.props;
    setFilter(name, checked);
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  relevantFilters() {
    const filters = this.props.filters || {};
    return {
      only_units_with_pricing: filters.only_units_with_pricing,
      only_units_without_pricing: filters.only_units_without_pricing,
      only_units_with_picture: filters.only_units_with_picture,
      only_units_without_picture: filters.only_units_without_picture,
      packages_only: filters.packages_only,
      exclude_packages: filters.exclude_packages,
      only_cannabis_friendly: filters.only_cannabis_friendly,
      exclude_units_with_restrictions: filters.exclude_units_with_restrictions,
      only_units_with_rate_card_pricing: filters.only_units_with_rate_card_pricing,
      stale_availability: filters.stale_availability,
      verified_only: filters.verified_only,
      unverified_only: filters.unverified_only,
      has_attribution: filters.has_attribution,
      no_attribution: filters.no_attribution
    };
  }

  onClear() {
    const { setFilter } = this.props;
    this.setState({ isExpanded: false}, () => {
      setFilter({
        'only_units_with_pricing': null,
        'only_units_without_pricing': null,
        'only_units_with_picture': null,
        'only_units_without_picture': null,
        'packages_only': null,
        'exclude_packages': null,
        'only_cannabis_friendly': null,
        'exclude_units_with_restrictions': null,
        'only_units_with_rate_card_pricing': null,
        'stale_availability': null,
        'has_attribution': null,
        'no_attribution': null 
      });
    });
  }

  getEnabledFilters() {
    const relevantFilters = this.relevantFilters();
    const enabledList = Object.keys(relevantFilters).filter((key) => !!relevantFilters[key])
    return enabledList;
  }

  getButtonLabel() {
    const enabledCount = this.getEnabledFilters().length;
    if (!enabledCount) return 'More';
    if (enabledCount > 0) return `More · ${enabledCount}`;
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = (left + POPUP_WIDTH) > window.innerWidth;
    if (flipNeeded) return 'right';
    return 'left';
  }

  renderPopup() {
    const relevantFilters = this.relevantFilters();
    const { currentUser } = this.props;
    const isAdmin = currentUser && currentUser.is_admin;

    return (
      <div className="filter_popup more_filters_popup" style={{ [this.getPopupPosition()]: '-1px' }}>
        <div>
          <div>
            <h4>Pricing</h4>
            <div className="checkbox_group">
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="With pricing only" attributeName="only_units_with_pricing" />
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Without pricing only" attributeName="only_units_without_pricing" />
            </div>
            <hr/>
          </div>
          <div>
            <h4>Photos</h4>
            <div className="checkbox_group">
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="With photos only" attributeName="only_units_with_picture" />
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Without photos only" attributeName="only_units_without_picture" />
            </div>
            <hr/>
          </div>
          <div>
            <h4>Packages</h4>
            <div className="checkbox_group">
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Packages only" attributeName="packages_only" />
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Exclude packages" attributeName="exclude_packages" />
            </div>
            <hr/>
          </div>
          <div>
            <h4>SpecSheets</h4>
            <div className="checkbox_group">
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Verified Only" attributeName="verified_only" />
              <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Unverified Only" attributeName="unverified_only" />
            </div>
            <hr/>
          </div>
        </div>
        <h4>Other</h4>
        <div className="checkbox_group">
          <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Cannabis friendly only" attributeName="only_cannabis_friendly" />
          <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="Without restrictions only" attributeName="exclude_units_with_restrictions" />
          <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="With rate card pricing only" attributeName="only_units_with_rate_card_pricing" />
          <CheckboxFilter filters={relevantFilters} setFilter={this.onCheckboxSelect} label="With stale availability only" attributeName="stale_availability" />
        </div>
        <div className="padded_content">
          <p className="actions">
            <a className="clear hidden-xs" onClick={this.onClear}>Clear</a>
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const { isExpanded } = this.state;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => this.node = node} className={cs({active: this.getEnabledFilters().length > 0 || isExpanded})}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    )
  }
};

export default connect(
  ({ currentUser }) => ({ currentUser }), {}
)(MoreFilters);
