import axios from "axios";
import React from 'react';
import PlacesStore from "../../../stores/PlacesStore";
import GlobalActions from "../../../actions/GlobalActions";
import { connect } from "react-redux";
import JobProgress from "../../../utils/JobProgress";
import Loading from '../../Loading';

class ImportPoisByBrand extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      brands: [],
      visible_brands: [],
      loading: true,
      q: '',
      places_updated: false,
    }

    this.job_progress = new JobProgress()
  }

  componentDidMount() {
    $.get(`/api/v1/brands?campaign_token=${this.props.campaign_id}`).then(response => {
      this.setState({
        brands: response,
        visible_brands: response.slice(0, 50),
        loading: false
      })
    })
  }

  componentWillUnmount() {
    if (this.state.places_updated) PlacesStore.triggerUpdate(this.props.places)
    if (this.job_progress) clearInterval(this.job_progress)
  }

  onSearch(q) {
    this.setState({ q })

    let results

    if (q == "") {
      results = this.state.brands
    } else {
      const { brands } = this.state
      const lowerQ = q.toLowerCase()
      results = brands.filter(brand => brand[1].toLowerCase().includes(lowerQ))
    }

    this.setState({
      visible_brands: results.slice(0, 50)
    })
  }

  onAdd(brand_id) {
    this.setState({ loading: true }, () => {
      const { bounds } = this.props.map

      const params = {
        bounds: `${bounds[0][1]},${bounds[0][0]},${bounds[1][1]},${bounds[1][0]}`,
        brand_id: brand_id
      };

      axios.post(`/api/v1/campaigns/${this.props.campaign_id}/brands`, params).then(({ data }) => {
        this.job_progress.startPolling(data.job_id, () => this.onComplete(data))
      }).catch((error) => {
        const { response } = error;
        if (response.status === 409){
          GlobalActions.showMessage(response.data.error, "warning", true);
        } else {
          GlobalActions.showError("Something unexpected happened");
        }
        this.setState({ saving: false });
      });

    })
  }

  onComplete(response) {
    this.setState({ places_updated: true }, () => {
      GlobalActions.showMessage(`${response.total} POIs added to campaign`)
      this.props.hide()
    })
  }

  render() {
    const { brands, visible_brands, loading, q } = this.state

    return (
      <div>
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.hide}><span aria-hidden="true">&times;</span></button>
          <h4 className="modal-title">Import POIs by Brand</h4>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Search for brand" onChange={e => this.onSearch(e.target.value)} value={q} />
          </div>
          <div style={{maxHeight:300,overflowY:'scroll'}}>
            {loading && <Loading/>}
            {!loading && <Brands visible_brands={visible_brands} brands={brands} onAdd={this.onAdd.bind(this)} />}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.props.hide}>Done</button>
        </div>
      </div>
    );
  }
}

const Brands = ({ visible_brands, brands, onAdd }) => (
  <div>
    <table className="table">
      <tbody>
        {visible_brands.map(brand => (
          <tr key={brand[0]}>
            <td>{brand[1]}</td>
            <td style={{textAlign:'right',paddingRight:20}}>
              <i className="fa fa-plus" onClick={() => onAdd(brand[0])} style={{cursor:'pointer'}} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {brands.length > visible_brands.length &&
      <em style={{color:'gray'}}>Showing {visible_brands.length} of {brands.length} brands</em>
    }
  </div>
)

export default connect(
  ({ map }) => ({ map }),
  null
)(ImportPoisByBrand);
