import * as React from "react";
import FaMinusCircle from "@adquick/icons/fa/FaMinusCircle";
import FaPlusCircle from "@adquick/icons/fa/FaPlusCircle";
import FiEdit from "@adquick/icons/fi/FiEdit";
import IoIosArrowForward from "@adquick/icons/io/IoIosArrowForward";
import styled, { css } from "styled-components";

import { Button, ButtonSize } from "../Button";
import { GridWrapper } from "../Grid";
import { Input } from "../Form/Input";
import { LabelWrapper } from "../Form/Label";
import { PopoverWrapper } from "../Popover";
import { useMenuItem } from "./menuItemHook";
import Theme from "../../theme";

export interface IMenuItemProps {
  title?: string;
  titleIcon?: string;
  Icon: React.FunctionComponent;
  expanded?: boolean;
  open?: boolean;
  hideArrow?: boolean;
  showContent?: boolean;
  useColumns?: boolean;
  disableWhiteBox?: boolean;
  onClick?: () => void;
  children?: React.ReactNode | string;
}

export const MenuItem = ({
  children,
  title,
  Icon,
  open,
  hideArrow,
  useColumns,
  disableWhiteBox,
  showContent = false,
  onClick = () => null,
}: IMenuItemProps) => {
  return (
    <Wrapper open={open} onClick={onClick}>
      <StaticLink open={open}>
        {!hideArrow && (
          <RightIconWrapper>
            <IoIosArrowForward />
          </RightIconWrapper>
        )}
        <LeftIconWrapper>{<Icon />}</LeftIconWrapper>
        <Title>{title}</Title>
      </StaticLink>
      {Boolean(showContent) && (
        <StaticInfoView disableWhiteBox={disableWhiteBox} useColumns={useColumns}>
          {children}
        </StaticInfoView>
      )}
    </Wrapper>
  );
};

export const CollapsibleMenuItem = React.forwardRef<HTMLDivElement, IMenuItemProps>(
  ({ children, title, Icon, expanded, onClick = () => null }, ref) => {
    const [collapsed, onMakeClick] = useMenuItem(onClick, expanded);
    const contentRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

    const collapseIcon = () =>
      collapsed ? <FaPlusCircle /> : <FaMinusCircle/>;

    return (
      <Wrapper ref={ref}>
        <LinkWrapper style={{ cursor: "pointer" }} onClick={onMakeClick as () => void}>
          <RightIconWrapper>{collapseIcon()}</RightIconWrapper>
          <LeftIconWrapper>{<Icon />}</LeftIconWrapper>
          <Title>{title}</Title>
        </LinkWrapper>
        <ChildrenWrapper ref={contentRef} collapsed={collapsed as boolean}>
          {children}
        </ChildrenWrapper>
      </Wrapper>
    );
  },
);

export const EditableMenuItem = ({ children, title, Icon, expanded, onClick = () => null }: IMenuItemProps) => {
  const [collapsed, onMakeClick] = useMenuItem(onClick, expanded);
  const [expandedHeight, setExpandedHeight] = React.useState(0);
  const [collapsedHeight, setCollapsedHeight] = React.useState(0);
  const [wrapperRect, setWrapperRect] = React.useState<DOMRect>();

  const contentRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const formRef = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const infoRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    setExpandedHeight(formRef.current.clientHeight);
    setCollapsedHeight(infoRef.current.clientHeight);
  }, [formRef, infoRef]);

  React.useEffect(() => {
    setWrapperRect(contentRef.current?.getBoundingClientRect());
  }, [contentRef]);

  const collapseIcon = () =>
    collapsed ? (
      <div>
        <Button fontSize="11px" size={ButtonSize.Small} onClick={onMakeClick as () => void} invert={true}>
          EDIT <FiEdit />
        </Button>
      </div>
    ) : (
      <FaMinusCircle/>
    );

  return (
    <Wrapper>
      <LinkWrapper style={{ cursor: "pointer" }} onClick={onMakeClick as () => void}>
        <RightIconWrapper>{collapseIcon()}</RightIconWrapper>
        <LeftIconWrapper>{<Icon />}</LeftIconWrapper>
        <Title>{title}</Title>
      </LinkWrapper>
      <EditChildrenWrapper height={collapsed ? collapsedHeight : expandedHeight} ref={contentRef}>
        <FormView ref={formRef} hide={!!collapsed}>
          {children}
        </FormView>
        <InfoView ref={infoRef} wrapper={wrapperRect} expandedHeight={expandedHeight} hide={!collapsed}>
          {children}
        </InfoView>
      </EditChildrenWrapper>
    </Wrapper>
  );
};

export const NoSelection = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: left;
  text-align: center;
  color: #fff;
  user-select: none;
`;

export const FormView = styled.form<{ hide: boolean }>`
  display: block;
  padding: 0;
  margin: 0;
  z-index: 1;
  margin-bottom: 20px;
  opacity: ${props => (props.hide ? 0 : 1)};
  transition: opacity 0.3s ease;
`;

const INFO_VIEW_PADDING = 22;

export interface IWrapperRect {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
}

export const InfoView = styled.div<{
  hide?: boolean;
  expandedHeight?: number;
  wrapper?: IWrapperRect;
}>`
  width: ${props => (props.wrapper?.width || 0) - INFO_VIEW_PADDING}px;
  margin-top: -${props => (props.expandedHeight || 0) + INFO_VIEW_PADDING / 1.5}px;
  position: relative;
  z-index: 0;
  background: ${Theme.PRIMARY_COLOR.comp};
  box-shadow: ${Theme.SHADOW_LIGHT_RIGHT};
  padding: 8px 8px;
  border-radius: 8px;
  opacity: ${props => (props.hide ? 0 : 1)};
  transition: opacity 0.3s ease;

  ${GridWrapper} {
    display: block;
  }

  ${Input} {
    width: 60%;
    display: inline-block;
    right: 0;
    color: ${Theme.PRIMARY_COLOR.color};
    line-height: 14px;
    font-weight: 600;
    font-size: 14px;
    pointer-events: none;
  }

  ${LabelWrapper} {
    line-height: 14px;
    width: 30%;
    display: inline-block;
    left: 0;
    text-align: right;
    color: ${Theme.PRIMARY_COLOR.color};
    padding: 5px 0 0 0 !important;
    font-size: 14px;
    font-weight: normal;
  }
`;

export const LinkWrapper = styled.div`
  position: relative;
  z-index: 3;
  line-height: 42px;
  cursor: pointer;
`;

export const Wrapper = styled.div<{ open?: boolean }>`
  min-height: 42px;
  cursor: pointer;
  background: ${props => (props.open ? Theme.PRIMARY_COLOR.color : "#f5f5f5")};
  transition: background 0.3s ease;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 20px;
  clear: left;

  ${PopoverWrapper} {
    right: -20px;
    z-index: 100 !important;
  }
`;

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  align-items: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #858585;
  display: inline-block;
  margin: 0;
  margin-left: 20px;
`;

export const LeftIconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 2px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const RightIconWrapper = styled.span`
  float: right;
  transform: translateY(2px);
`;

export const StaticValue = styled.div`
  color: ${Theme.PRIMARY_COLOR.color};
  line-height: 14px;
  font-weight: 600;
  font-size: 13px;
  padding-right: 8px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StaticLabel = styled.div`
  color: ${Theme.PRIMARY_COLOR.color};
  line-height: 14px;
  font-size: 13px;
  padding-bottom: 5px;
  padding-right: 10px;
  font-weight: normal;
  text-align: right;
  margin-bottom: 5px;
  &:nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const StaticInfoView = styled.div<{
  useColumns?: boolean;
  disableWhiteBox?: boolean;
}>`
  position: relative;
  z-index: 1;
  border-radius: 8px;
  margin-bottom: 16px;
  user-select: none;
  padding: ${props => (props.disableWhiteBox ? "0 11px" : "13px 11px")};
  background: ${props => (props.disableWhiteBox ? "transparent" : Theme.PRIMARY_COLOR.comp)};
  box-shadow: ${props => (props.disableWhiteBox ? "none" : Theme.SHADOW_LIGHT_RIGHT)};
  text-align: ${props => (props.disableWhiteBox ? "center" : "left")};

  ${props =>
    props.useColumns &&
    !props.disableWhiteBox &&
    css`
      display: grid;
      grid-template-columns: 35% 65%;
    `}
`;

export const StaticLink = styled(LinkWrapper)<{
  open?: boolean;
}>`
  ${Title} {
    transition: color 0.3s ease;
    color: ${props => (props.open ? "#FFF" : "#555")};
  }

  ${LeftIconWrapper} {
    color: ${props => (props.open ? "rgba(255, 255, 255, 0.4)" : "#CCC")};
    transition: color 0.3s ease;
  }

  ${RightIconWrapper} {
    color: ${props => (props.open ? "rgba(255, 255, 255, 0.4)" : "#CCC")};
    font-size: 16px;
    transform: ${props => (props.open ? "rotate(-180deg)" : "rotate(0deg)")};
    transition: 0.3s ease;
    transition-property: transform, color;
  }
`;

export interface IMenuItemChildrenWrapper {
  collapsed?: boolean;
  forwardedRef?: any;
}

export const ChildrenWrapper = styled.div<IMenuItemChildrenWrapper>`
  opacity: ${props => (props.collapsed ? "0" : "1")};
  transition: margin-top 0.3s ease, opacity 0.2s ease-in-out ${props => (props.collapsed ? "" : "0.2s")};
  pointer-events: ${props => (props.collapsed ? "none" : "auto")};
  margin-top: ${props => {
    const height = props.forwardedRef?.current?.offsetHeight; // @ts-ignore
    return props.collapsed ? `-${height ? `${height}px` : "350px"}` : "0";
  }};
`;

export const EditChildrenWrapper = styled(ChildrenWrapper)<{ height: number }>`
  height: ${props => props.height + 20}px;
  transition: height 0.3s ease;
`;
