import * as React from "react";

export default class StatusBadge extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { status } = this.props;
    const style = {
      padding: 7,
      borderRadius: 8,
      height: 32,
      fontSize: 14,
      maxWidth: 130,
      ...this.props.style,
    };

    if (!!!status) {
      return <div></div>;
    }
    if (status === "active") {
      return (
        <div
          className="unit-status active"
          style={{
            ...style,
            textAlign: "center",
            color: "#44AC6B",
            background: "#E1F2E7",
          }}
        >
          Active <i style={{ marginLeft: 5 }} className="fa fa-check-circle-o" aria-hidden="true"></i>
        </div>
      );
    } else if (status === "inactive") {
      return (
        <div
          className="unit-status inactive"
          style={{
            ...style,
            textAlign: "center",
            color: "#F5A623",
            background: "#FDF1DC",
          }}
        >
          Inactive <i style={{ marginLeft: 5 }} className="fa fa-minus-circle" aria-hidden="true"></i>
        </div>
      );
    } else if (status === "retired") {
      return (
        <div
          className="unit-status retired"
          style={{
            ...style,
            textAlign: "center",
            color: "#555555",
            background: "#EEEEEE",
          }}
        >
          Retired <i style={{ marginLeft: 5 }} className="fa fa-times-circle-o" aria-hidden="true"></i>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
