import React from "react";

class Scores extends React.Component {
  constructor(props) {
    super(props)
  }

  scores() {
    const { unit: { scores } } = this.props
    return scores.reduce((acc, score) => {
      const scoreKey = Object.keys(score)[0]
      acc[scoreKey] = score[scoreKey]
      return acc
    }, {})
  }

  isThereScore() {
    const scores = this.scores()
    const keys = Object.keys(scores)
    return keys.map(scoreType => !!scores[scoreType]).includes(true)
  }

  isScoreNaN(score) {
    return isNaN(parseInt(score))
  }

  totalScore() {
    const scores = this.scores()
    const keys = Object.keys(scores)

    return keys.map(scoreType => scores[scoreType]).reduce((acc, value) => {
      acc = acc + value
      return acc
    }, 0)
  }

  validScores() {
    const { unit: { scores } } = this.props
    return scores.filter(s => !!s[Object.keys(s)[0]])
  }

  scoreTypeMapping(scoreKey) {
    const types = {
      price: 'Pricing',
      pop_density: 'Population Density',
      poi: 'POI Proximity',
      cpm: 'Value',
      audience: 'Audience Targeting'
    }
    return types[scoreKey]
  }

  renderScores() {
    const { unit: { scores } } = this.props
    
    return scores.map(score => this.renderScore(score))
  }

  renderScore(score) {
    const scoreKey = Object.keys(score)[0]
    const currentScore = score[scoreKey]
    return (
      <div className="score-grid">
        <div>{this.scoreTypeMapping(scoreKey)}</div>
        <div className="score-line">
          <div
            className={`score-line-progress score-${scoreKey}`}
            style={{width: `${this.isScoreNaN(currentScore) ? 0 : score[scoreKey]}%`}}>
          </div>
        </div>
        <div className="score-value">
          {this.isScoreNaN(currentScore) ? "N/A": Math.round(currentScore)}
        </div>
      </div>
    )
  }

  renderTotalScore() {   
    const totalScore = this.isThereScore() ? Math.round(this.totalScore() / this.validScores().length) : "N/A"
    
    return <span className="score-value">{totalScore}</span>
  }

  render() {
    return (
      <div className="score_tab">
        <div>Unit score: {this.renderTotalScore()}</div>
        <div className="scores-container">{this.renderScores()}</div>
      </div>
    )
  }
}

export default Scores