import Select from "react-select";
import {browserHistory} from 'react-router-legacy';
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import React from 'react';
import accounting from 'accounting';
import createClass from 'create-react-class';
import cs from 'classnames';
import {removeCampaignUnit, loadCampaignUnits } from '../Campaign/actions';
import {showEditUnitModal, hideModal} from '../Inventory/actions.js'
import {updateMapPosition} from '../NewMap/actions';
import DemographicsTab from './DemographicsTab';
import UnitMapTab from './UnitMapTab';
import PriceTab from './PriceTab';
import AdminTab from './AdminTab';
import Favorite from '../Campaign/Favorite';
import GlobalActions from '../../actions/GlobalActions';
import InfoTab from './InfoTab';
import LocationTab from './LocationTab';
import Pin from '../Campaign/Pin';
import PopsTab from './PopsTab';
import Recommend from '../Campaign/Recommend';
import SearchStore from '../../stores/SearchStore';
import Tags from './Tags';
import UnitAvailability from '../Browse/UnitAvailability';
import BookOrHoldNotice from '../UnitInfo/BookOrHoldNotice';
import UnitModel from "../../models/unitModel";
import {isMobile} from '../../utils/mobile';
import HeaderPrice from './HeaderPrice';
import Scores from './Scores';
import {
  removeFromCart,
} from '../../actions/cart';
import FairPriceIcon from './FairPriceIcon';
import { get } from "../../utils/api";
import debounce from "lodash/debounce";

const tabs = [
  { label: 'Info', value: 'info' },
  { label: 'Audience', value: 'audience' },
  { label: 'Map', value: 'map' },
  { label: 'POPs', value: 'pops' },
  { label: 'Price', value: 'price' },
  { label: 'Admin', value: 'admin' },
  { label: 'Score', value: 'score' }
];

export function transitUnitPriceRange(unit) {
  if (!unit) return '';
  if (!Array.isArray(unit.media_subtypes)) return '';

  const prices = unit.media_subtypes.map(u => u.price).sort();
  const minPrice = accounting.formatMoney(prices[0], "$", 0);
  const maxPrice = prices.length === 1 ? "" :  `-${accounting.formatMoney(prices[prices.length-1], "", 0)}`

  return `${minPrice}${maxPrice} per vehicle`;
}

const UnitInfo = createClass({
  displayName: 'UnitInfo',

  propTypes: {
    unit: PropTypes.object.isRequired,
    user: PropTypes.object,
  },

  getInitialState() {
    return ({
      campaign: this.props.campaign,
      activeTab: 'info',
      bookableUnit: this.props.unit,
      campaignPermissions: this.campaignPermissions(),
      unitModel: new UnitModel(this.props.unit),
      campaigns: []
    });
  },

  campaignPermissions() {
    return this.props.permissions || {}
  },

  componentDidMount() {
    this.debounceFindCampaign = debounce(query => {
      this.findCampaign(query)
    }, 400)
    window.afterRemoveUnit = this.props.loadCampaignUnits;
    window.hideModal = this.props.onClose;
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab) {
      this.setState({ activeTab: nextProps.activeTab })
    }
  },

  renderTabs() {
    let neededTabs = this.tabs();
    const { user, isSearchView, campaign } = this.props;
    const { activeTab } = this.state;
    const options = this.props.options || {};

    if ((user && user.is_supplier) || (user && user.is_guest) || options.treatAsBookedUnit) {
      neededTabs = neededTabs.filter(tab => tab.label !== "Book");
    };

    if ((!user.is_admin && !user.is_supplier) || !window.location.pathname.startsWith("/campaigns/")) {
      neededTabs = neededTabs.filter(tab => tab.label !== "POPs");
    }

    if (!user.permissions.can_view_unit_modal_price_tab || user.permissions.hide_unit_prices) {
      neededTabs = neededTabs.filter(tab => tab.value !== "price");
    }

    if ((campaign && campaign.campaign && campaign.campaign.is_mock) || user.permissions.hide_unit_prices) {
      neededTabs = neededTabs.filter(tab => tab.value !== "book");
    }

    if (window.location.pathname.startsWith("/campaigns/") || (!user.is_admin && !user.is_supplier_admin)) {
      neededTabs = neededTabs.filter(tab => tab.value !== "admin");
    }

    if (isSearchView && user.use_rate_card_price_on_browse || user.permissions.hide_unit_prices) {
      neededTabs = neededTabs.filter(tab => tab.value != "price");
    }

    return neededTabs.map((tab) => {
      const onClick = () => this.setState({ activeTab: tab.value });
      const className = cs({
        unitInfo__tab: true,
        unitInfo__tab_active: activeTab === tab.value,
      });

      return (
        <div className={className} onClick={onClick} key={tab.value}>
          {tab.label}
        </div>
      );
    });
  },

  viewOnMap() {
    const { unit, options } = this.props;

    if (options && options.onViewOnMap) {
      options.onViewOnMap();
    }

    if (document.location.pathname.startsWith('/timeline') || document.location.pathname.startsWith('/inventory')) {
      browserHistory.push(`/search?center=${unit.lat},${unit.lon}&zoom=17`);
    } else {
      SearchStore.setView([unit.lat, unit.lon], 17);
      this.props.updateMapPosition({ center: [unit.lon, unit.lat], zoom: 17 });
    }
    GlobalActions.closePopup();
  },

  onRemoveFromCart() {
    const { bookableUnit } = this.state;
    const { campaign, removeFromCart } = this.props;
    removeFromCart(campaign.token, [bookableUnit.campaign_unit_token])
  },

  async removeFromCampaign() {
    const campaign_id = this.props.campaign.campaign.id;
    const unit_id = this.props.unit.id
    await this.props.removeCampaignUnit(campaign_id, unit_id);
    GlobalActions.closePopup();
  },

  addToCampaign() {
    this.setState({ showAddToCampaign: true });
  },

  onAddToCampaignSave() {
    const unit = this.props.unit;
    $.post(`/api/v1/units/${unit.id}/add_to_campaign?campaign=${this.state.toBeAddedToCampaign}`, (response) => {
      GlobalActions.showMessage('Added to campaign!');
      this.setState({ showAddToCampaign: false });
      this.setState({ addedToCampaignSuccess: response.campaign.token });
    });
  },

  price() {
    const isBrowseAndCanViewPrice = this.props.isSearchView && this.props.user.permissions.agency_browse_type == "range"
    const price = isBrowseAndCanViewPrice ? this.state.unitModel.getPrice(this.props.unit, {lower: 'lower_price', higher: 'higher_price'}) : this.state.unitModel.getMockPrice(this.props.campaign && this.props.campaign.campaign)

    if (this.props.unit.media_subtypes) {
      return transitUnitPriceRange(this.props.unit);
    }

    return price;
  },

  tabs() {
    const { unit } = this.props

    const hasScores = this.isUnitScoreFlagEnabled() && unit.scores.length > 0
    const hasDemoData = unit &&
      typeof(unit.demographics_data) == "object" &&
      !_.isEmpty(unit.demographics_data)

    return tabs.filter(tab => {
      if (tab.label === 'Streetview' && unit.lime) {
        return false
      } else if (tab.label == 'Audience' && !hasDemoData) {
        return false
      } else if (tab.label == 'Score' && !hasScores) {
        return false
      }
      else {
        return true
      }
    })
  },

  async findCampaign(query) {
    if (!query || query.length < 3) {
      return this.state.campaigns;
    }

    const { campaigns } = await get(`/api/v1/search/options?query=${query}`)

    this.setState({ campaigns });
    return campaigns;
  },

  renderAddToCampaign() {
    if (!this.state.showAddToCampaign) return
    const campaign = this.state.campaigns.find(o => o.value === this.state.toBeAddedToCampaign)
    return (
      <div>
        <div style={{ float: 'left', marginRight: 5, width: 300 }}>
          <Select
            placeholder="Select campaign"
            options={this.state.campaigns}
            value={campaign}
            onChange={(option) => {
              this.setState({ toBeAddedToCampaign: option.value });
            }}
            onInputChange={(query) => this.debounceFindCampaign(query)}
          />
        </div>
        {this.state.toBeAddedToCampaign &&
          <button className="btn btn-primary" onClick={this.onAddToCampaignSave} style={{ float: 'left', marginRight: 5 }}>Save</button>}
        <button className="btn btn-default" onClick={() => { this.setState({ showAddToCampaign: false }); }} style={{ float: 'left' }}>Cancel</button>
      </div>
    )
  },

  renderAddToCampaignSuccess() {
    if (!this.state.addedToCampaignSuccess) return

    return (
      <div>
        <span style={{ float: 'right' }}>
          <a className="btn btn-primary" href={`/campaigns/${this.state.addedToCampaignSuccess}`} target="_blank">View Campaign</a>
          <button className="btn btn-default" style={{ marginLeft: 5 }} onClick={() => GlobalActions.closePopup()}>Close</button>
        </span>
        <div style={{ color: 'green', paddingTop: "7.5px" }}>
          <i className="fa fa-check" style={{ marginRight: "5px" }}/>
          Successfully added to campaign.
        </div>
      </div>
    )
  },

  showCannabisHeader() {
    const { unit, campaign } = this.props
    const isCampaignView = !!campaign
    const isCannabisCampaign = isCampaignView && campaign.is_cannabis_campaign
    if(isCampaignView && isCannabisCampaign) { return unit.is_cannabis_friendly }
    if(!isCampaignView) { return unit.is_cannabis_friendly }
    return false
  },

  currentCampaign() {
    const { campaign } = this.props
    return campaign ? campaign.campaign : null;
  },

  renderUnitName() {
    const { unit } = this.props
    const campaign = this.currentCampaign()
    if(campaign && campaign.is_mock) { return }
    return ` - ${unit.name}`
  },

  renderFaceId() {
    const { unit } = this.props
    const campaign = this.currentCampaign()
    const is_ibo_unit = unit.supplier_id === 1835;
    if(campaign && (campaign.is_mock || campaign.hide_face_ids)) { return `${unit._id}` }
    if (is_ibo_unit) {
      return `${unit.supplier_face_id.split('-')[0]}`
    }
    return `${unit.supplier_face_id}`
  },

  getCampaignUnits() {
    const { unit, units, isActivityView } = this.props
    if(isActivityView) { return [] }
    const filteredUnits = units.filter(u => u.id == unit.id)
    const campaignUnits = filteredUnits.map(u => u.campaign_units)
    const flattenCampaignUnits = campaignUnits.reduce((acc, val) => acc.concat(val), [])
    const uniqueCampaignUnits = _.uniqBy(flattenCampaignUnits, 'id')
    return _.compact(uniqueCampaignUnits)
  },

  getNextBookedByDiff() {
    const { unit, units } = this.props
    const unitFromSerializer = units && units.find(u => u.id == unit.id)
    return unitFromSerializer && unitFromSerializer.next_booked_diff
  },

  async onRemoveCampaign() {
    const { onClose } = this.props;
    const message = "Are you sure you want to remove this unit from the campaign?";
    const result = window.confirm(message);
    if(!result){ return };
    try {
      await this.removeFromCampaign();
      onClose && onClose();
    } catch (error) {
      GlobalActions.showError(error);
    }
  },

  renderCampaignButton() {
    const { user, campaign, unit, onClose } = this.props;
    const options = this.props.options || {};
    const campaignId = campaign && campaign.campaign && campaign.campaign.token;
    const unitId = unit.id
    const permissions = this.campaignPermissions();
    const is_managed_supplier = user ? user.is_managed_supplier : null;
    const is_unmanaged_supplier = user ? user.is_unmanaged_supplier : null;

    if (user === null || options.hideRemoveAndAddButtons) { return; }
    if (campaignId != null && campaignId != '') {
      if (!!!permissions.can_remove_unit || options.treatAsBookedUnit) { return; }
        // if (user.is_supplier || !permissions.show_unit_removal_reason) {
          return(
            <button
            className="btn btn-default"
            onClick={this.onRemoveCampaign}
            style={{ float: 'left', marginRight: 5 }}
            ><i className="fa fa-trash"/></button>
          )
        // } else {
        //   return (
        //     <button
        //       data-remove-units-campaign-id-value={campaignId}
        //       data-remove-units-unit-id-value={unitId}
        //       data-remove-units-use-stimulate-value={false}
        //       data-controller="remove-units"
        //       data-action="click->remove-units#openRemovalReasonModal"
        //       className="btn btn-default"
        //       style={{ float: 'left', marginRight: 5 }}
        //     ><i className="fa fa-trash"/></button>
        //   );
        // }
    } else {
      if (user.is_supplier) { return; }
      return (
        <button
          className="btn btn-default"
          onClick={this.addToCampaign}
          style={{ float: 'left', marginRight: 5 }}
        >Add To Campaign</button>
      );
    }
  },

  renderEditButton() {
    const { user } = this.props;
    const campaignPermissions = this.campaignPermissions();
    const canEditValues = user && (campaignPermissions.can_edit_campaign_unit_attributes || user.is_supplier_admin || user.is_supplier_seller || user.is_admin);
    if(!canEditValues) { return }
    const unitEditButton = <button
    className="btn btn-default"
    onClick={this.onEditClick}
    style={{ float: 'left' }}
    >Edit</button>
    if (this.props.unit.is_package) {
      return(
        <>
        {unitEditButton}
        <button
          className="btn btn-default"
          data-action="campaign-header#openPackageModal"
          style={{float: 'left'}}
        >
          Edit Package
        </button>
        </>
      )
    }

    return unitEditButton
  },

  renderBonusButton() {
    const { user } = this.props;
    const canEditValues = user && (user.is_admin || user.is_supplier_admin || user.is_agency);
    if(!canEditValues) { return }
    const { bookableUnit } = this.state;
    const setButton = <button className="btn btn-default" onClick={() => this.props.onToggleBonusUnit(bookableUnit.campaign_unit_token, bookableUnit.id, 'set_bonus')} style={{ float: 'left' }}>Set Bonus</button>
    const unsetButton = <button className="btn btn-default" onClick={() => this.props.onToggleBonusUnit(bookableUnit.campaign_unit_token, bookableUnit.id, 'unset_bonus')} style={{ float: 'left' }}>Unset Bonus</button>

    return <React.Fragment>
      {setButton}
      {unsetButton}
      </React.Fragment>
  },

  onEditClick() {
    const { onClose, showEditUnitModal, unit, user } = this.props;
    const currentCampaign = this.currentCampaign();
    const firstTab = user.is_agency ? 'campaign_unit_details' : 'details'
    const initialTab = currentCampaign ? 'campaign_unit_details' : firstTab
    const campaignId = currentCampaign ? currentCampaign.token : null;
    if (onClose) { onClose() };
    showEditUnitModal(unit, initialTab, campaignId);
  },

  rateCardPrice() {
    const { unit, user } = this.props
    if (!!!unit.price && (!!!user || !!!user.is_supplier)) {
      return this.state.unitModel.getPrice(unit, {lower: 'rate_card_lower_price', higher: 'rate_card_higher_price'})
    }
    return this.state.unitModel.getMockRateCardPrice(this.currentCampaign())
  },

  builderAddToCampaign() {
    const { campaign, unit } = this.props;
    GlobalActions.openPopup('vendor_add_units_modal', {
      unit: {
        supplier_face_id: unit.supplier_face_id,
        slug: unit.slug,
        start_date: campaign.start_date,
        end_date: campaign.end_date,
        price: unit.price
      }
    });
  },

  isUnitScoreFlagEnabled() {
    const { feature_flags, campaign } = this.props
    const { can_show_scores } = campaign && campaign.campaign || {}
    const flag = feature_flags.find(f => f.name == 'unit_scores')
    return (flag && flag['status']) || can_show_scores
  },

  render() {
    const { unit, units, user, isSearchView, campaign, onClose,
           isFavorited, onFavoriteUnit, isPinned, onPinUnit,
           onToggleRecommendUnit, isRecommended, isVendorMapView, onToggleBonusUnit, isBonus } = this.props;
    const { bookableUnit, activeTab, showAddToCampaign, addedToCampaignSuccess } = this.state;
    const is_managed_supplier = user ? user.is_managed_supplier : null;
    const is_unmanaged_supplier = user ? user.is_unmanaged_supplier : null;
    const campaignId = campaign && campaign.campaign && campaign.campaign.token;
    const currentCampaign = this.currentCampaign();
    const permissions = this.campaignPermissions();
    const advertiser = currentCampaign ? currentCampaign.advertiser : null;
    const advertiserName = advertiser ? advertiser.name : null;
    const isCompass = (advertiserName === 'Compass');
    const isAvailabilityFlowEnabled = _.get(advertiser, 'unit_availability_flow')
    const unitFromData = units && units.find(u => u.id === unit.id)
    const campaign_unit_token = bookableUnit ? bookableUnit.campaign_unit_token : null;
    const campaign_units = currentCampaign && this.getCampaignUnits()

    const hide_prices = currentCampaign && currentCampaign.hide_prices;
    const isAgencySearch = isSearchView && user.is_agency;
    const agencyCanViewPrice = isAgencySearch && user.permissions.can_view_price_on_browse

    let tab;

    switch (this.state.activeTab) {
      case 'info':
        tab = <InfoTab
          unit={unit}
          campaign_units={campaign_units}
          campaign={campaign}
          user={user}
          isAgencySearch={isAgencySearch}
          onFavoriteUnit={onFavoriteUnit}
          getNextBookedByDiff={this.getNextBookedByDiff}
        />;
        break;
      case 'location':
        tab = <LocationTab unit={unit} />;
        break;
      case 'pops':
        tab = <PopsTab
          unit={unit}
          user={user}
          campaignUnitToken={campaign_unit_token}
        />;
        break;
      case 'audience':
        tab = <DemographicsTab
          unit={unit}
          user={user}
          inCart={unit.in_cart}
        />;
        break;
      case 'map':
        tab = <UnitMapTab unit={unit} />
        break;
      case 'price':
        tab = <PriceTab
          unit={unit}
          campaign={campaign}
          user={user}
        />;
        break;
      case 'admin':
        tab = <AdminTab unit={unit} user={user} />;
        break;
      case 'score':
        tab = <Scores unit={unit} />;
        break;
    }
    const showPricing = this.price() && !unit.is_place_marker && AppConfig.showPricing && (!isAgencySearch || agencyCanViewPrice);
    const showPriceForDuration = !isSearchView && this.props.campaign.campaign && this.props.campaign.campaign.show_price_for_duration;
    const showRateCard = !showPricing && user.permissions.can_view_browse;
    const useRateCardOnly = isSearchView && user.use_rate_card_price_on_browse;
    const { image_street_view_external_link, streetview_verified } = unit;
    const price_guide_disabled = currentCampaign ? currentCampaign.price_guide_disabled : false;

    return (
      <div className="unitInfo-wrap" data-controller='campaign-header' data-campaign-header-user-id={user.id} data-campaign-header-campaign-token={campaign.token} data-campaign-header-campaign-unit-token={campaign_unit_token}>
        {!isMobile() && <div style={{ float: 'right' }} className="visible-xs" onClick={() => { GlobalActions.closePopup(); }}>
          <button className="btn btn-link"><i className="fa fa-close" /></button>
        </div>}

        {this.showCannabisHeader() && <div className="unitInfo__cannabis-header">
          <div className="cannabis_icon"></div>
          Cannabis-friendly
        </div>}

        <div className="unitInfo__header">
          {isMobile() && <div style={{ paddingBottom: 20 }}>
            <div className="btn btn-link" style={{padding:0}} onClick={() => { onClose ? onClose() : GlobalActions.closePopup() }}>Back To Map</div>
          </div>}

          <div className="unitInfo__icon-wrap" style={{position: 'relative', left: -10}}>
            <svg>
              <use className={`unitInfo__icon unitInfo__icon_${unit.unit_type}`} xlinkHref={`#icon_${unit.unit_type}`} />
            </svg>
            {(!!!price_guide_disabled && permissions.can_view_price_guide && showPricing) && <FairPriceIcon
              unit={unit}
              iconSize={24}
              style={{
                float: 'right',
                position: 'relative',
                right: -30,
                top: -27
              }}
            />}
          </div>

          <h1 className="unitInfo__title">
            {(unit.is_package) && <span><span className="label label-default">PKG</span> </span>}
            {(unit.is_recommended && permissions.can_view_recommended) &&
              <span className="recommended">
                Recommended
              </span>
            }
            {!unit.lime && <span>{this.renderFaceId()}</span>}
            {this.renderUnitName()}
          </h1>
          {
            !user.permissions.hide_unit_prices && showPricing && <HeaderPrice
              unit={unit}
              user={user}
              base_price={this.price()}
              showPricing={showPricing}
              showRateCard={showRateCard}
              showPriceForDuration={showPriceForDuration}
              mockRatecard={this.rateCardPrice()}
              useRateCardOnly={useRateCardOnly}
            />
          }
        </div>
        {!unit.is_place_marker && <div className="unitInfo__tabs">
          {this.renderTabs()}
        </div>}
        {!unit.is_place_marker && tab}

        {activeTab !== 'book' && <div className="unitInfo__btns" style={{ overflow: 'none', minHeight: 80 }}>
          {this.renderAddToCampaign()}
          {this.renderAddToCampaignSuccess()}

          {campaignId && permissions.can_view_favorites && <div className="unitInfo__favorite">
            <Favorite
              isFavorited={isFavorited}
              unitId={bookableUnit.id}
              onFavoriteUnit={onFavoriteUnit}
              showCallback={false} />
          </div>}

          {false && user.is_admin && onPinUnit &&
            <div className="unitInfo__pin">
              <Pin
                isPinned={isPinned}
                unitId={bookableUnit.id}
                onPinUnit={onPinUnit}
              />
            </div>
          }

          {(permissions.can_recommend_units) && onToggleRecommendUnit &&
            <div className="unitInfo__recommended">
              <Recommend
                isRecommended={isRecommended}
                unitId={bookableUnit.id}
                onToggleRecommendUnit={onToggleRecommendUnit}
              />
            </div>
          }

          {(!showAddToCampaign && !addedToCampaignSuccess) && <div className="unitInfo-btn-row">
            {this.renderCampaignButton()}
            {this.renderEditButton()}

            {<a href={image_street_view_external_link} rel="noopener noreferrer" target="_blank">
              <button className="btn btn-default">
                {streetview_verified && <i className="fas fa-badge-check" style={{ marginRight: '5px', color: 'var(--blue)' }} />}
                Street View
              </button>
            </a>}

            {(!unit.is_place_marker && !window.mobileCheck()) &&
              <button
                className="btn btn-default"
                onClick={this.viewOnMap}
              >View On Map</button>
            }
          </div>}
          {isVendorMapView && <button
            className="btn btn-default"
            onClick={this.builderAddToCampaign}
            style={{ float: 'left' }}
          >Add To Campaign</button>}
        </div>}
        <div className="unitInfo__btns" style={{ paddingTop: 0, marginTop: '-20px', paddingBottom: '20px' }}>
          {!isAvailabilityFlowEnabled && <BookOrHoldNotice
              unit={unitFromData || unit}
            />
          }
          {isAvailabilityFlowEnabled && <UnitAvailability
            unit={unitFromData || unit}
            permissions={permissions}
            campaign={currentCampaign}
            isInCart={unit.in_cart}
          />}
        </div>

        {(currentCampaign && permissions.can_edit_tags && activeTab == 'info') &&
         <div className="unitInfo__btns" style={{ paddingTop: 0, marginTop: 0, paddingBottom: '20px' }}>
           <Tags campaign={campaign} unit={bookableUnit} loadUnit={this.props.loadUnit} />
         </div>}

        {user.is_hacker &&
          <div className="unitInfo__btns" style={{ fontSize: '13px', color: '#aaa', marginTop: '-20px', clear: 'both' }}>CU Token: {unit.campaign_unit_token} | U Slug: {unit.slug}</div>
        }
      </div>
    );
  },
});

export default connect(({ feature_flags }) => ({ feature_flags }), {
  showEditUnitModal,
  hideModal,
  removeCampaignUnit,
  loadCampaignUnits,
  updateMapPosition,
  removeFromCart,
})(UnitInfo);
