import React, { ChangeEvent, PureComponent, ReactElement } from "react";
import { Button, Col, Form, FormControl, FormControlProps, FormGroup, Modal, Row, Checkbox } from "react-bootstrap";
import { TwitterPicker, ColorResult } from "react-color";
import { get, post, put, del } from "../../../utils/api2";
import { AREA_TYPES } from "./constants";
import ZipCodesInput from "../../ZipCodesInput";
import Async from "react-select/async";
import Select from 'react-select';
import * as styleGen from "./styles";
import * as helper from "./helpers";
import Dropzone, { FileWithPreview } from 'react-dropzone'

export interface IDistrict {
  value: string;
  label: string;
}

export interface IMarket {
  value: string
  label: number
}

export interface IHighlightedArea {
  id: number;
  area_type: string;
  data_layer_id?: number;
  name: string;
  color: string;
  value?: string | IDistrict[];
  areas: any[];
  visible: true;
  selectedMarkets: IMarket[],
  _showColorPicker: boolean;
}


export interface IHighlightedAreaProps {
  show: boolean;
  campaignId: string;
  highlightedAreas: IHighlightedArea[];
  onHide: (bounds: IHighlightedArea[]) => any;
}

export interface IHighlightedAreaState {
  area_type: string;
  districts: IDistrict[];
  zip_codes: string;
  isZipCodesValid: boolean | null;
  markets: IMarket[];
  selectedMarkets: IMarket[];
  highlighted_areas: IHighlightedArea[];
}

const initialState = {
  highlighted_areas: [],
  area_type: "zip_codes",
  districts: [],
  zip_codes: "",
  markets: [],
  selectedMarkets: [],
  isZipCodesValid: null
};

export interface ICongressionalDistrict {
  location_id: string;
  name: string;
}

class HighlightedArea extends PureComponent<IHighlightedAreaProps, IHighlightedAreaState> {

  constructor(props: IHighlightedAreaProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.updateHighlightedAreas();
    this.getDmas();
  }

  componentDidUpdate(prevProps) {
    if (this.props.highlightedAreas.length !== prevProps.highlightedAreas.length) {
      this.updateHighlightedAreas();
    }
  }

  public get renderCongressionalDistrictsSelector() {
    return <FormGroup>
      <label>Locations</label>
      <div className="form-inline">
        <div style={styleGen.districtsSelect()}>
          <Async
            isMulti
            clearable
            value={this.state.districts}
            className="selectInput"
            placeholder="Select a location"
            loadOptions={this.getGeopathSegments}
            onChange={this.onChangeDistrict.bind(this)}
          />
        </div>
        {this.renderAddButton(this.addDistricts, !this.state.districts.length)}
      </div>
    </FormGroup>;
  }

  public get renderMarkersSelect() {
    return <FormGroup>
      <label>Markets</label>
      <div className="form-inline">
        <div style={styleGen.districtsSelect()}>
          <Select
            isMulti
            className="selectInput"
            options={this.state.markets}
            placeholder="Select a market"
            onChange={this.onChangeMarkets.bind(this)}
            clearable
          />
        </div>
        {this.renderAddButton(this.addMarkets, !this.state.selectedMarkets)}
      </div>
    </FormGroup>;
  }


  public onDropFiles(files: FileWithPreview[]) {
    files.map((file: FileWithPreview) => {
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        if (reader.result === null) {
          throw 'Error uploading file'
        }
        const result = reader.result.toString()
        this.addFile({body: result, name: file.name})
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    })
  }

  private async addFile(file) {
    const fileAsArray = [file];
    const name = "";
    const builtHighlightedAreas = helper.buildHighlightedArea({
      name,
      area_type: this.state.area_type,
      visible: true
    }, fileAsArray);
    const dataLayers = await this.saveHighlightedRegions(builtHighlightedAreas, fileAsArray);
    const createdHighlightedAreas = dataLayers.map(dataLayer => helper.extractHighlightedArea(dataLayer))

    this.setState({
      selectedMarkets: [],
      highlighted_areas: [...this.state.highlighted_areas, ...createdHighlightedAreas],
    });
  }

  public get renderKMLUpload() {
    const boxStyle = { border: "1px dashed #4A90E2", borderRadius: "8px", cursor: "pointer" };
    return <Dropzone onDrop={this.onDropFiles.bind(this)} accept=".kml" style={boxStyle} multiple={true} inputProps={{style: {display: "none"}}}>
      <section>
        <div >
          <p>Drop KML files here, or click to select files</p>
        </div>
      </section>
    </Dropzone>
  }

  public get renderZipCodeInput() {
    return (
      <ZipCodesInput
        rows={1}
        minWidth="222px"
        zip_codes={this.state.zip_codes}
        onChange={this.onChangeZipCodes.bind(this)}>
        {this.renderAddButton(this.addZipCodes, !this.state.isZipCodesValid)}
      </ZipCodesInput>
    );
  }

  public get renderLocationInput(): JSX.Element {
    switch (this.state.area_type) {
      case "congressional_districts":
        return this.renderCongressionalDistrictsSelector
      case "zip_codes":
        return this.renderZipCodeInput
      case "dmas":
        return this.renderMarkersSelect
      case "kml_custom_bounds":
        return this.renderKMLUpload
      default:
        return this.renderCongressionalDistrictsSelector
    }
  }

  public renderAddButton(onAdd: Function, disabled: boolean) {
    return <Button
      className="btn btn-default btn-green-fill"
      disabled={disabled}
      style={styleGen.btnAdd()}
      onClick={onAdd.bind(this)}>
      <i className="fa fa-plus"></i>
    </Button>
  }

  public renderOptionType(data: object, valueKey: string, nameKey: string, i: number) {
    return <option key={`ha_type_${i}`} value={data[valueKey]}>{data[nameKey]}</option>;
  }

  public renderHighlightedAreas(highlightedArea: IHighlightedArea, i: number): ReactElement<HTMLElement> {

    const onChangeComplete = (color: ColorResult) =>
      this.onChangeColor(color, highlightedArea, this.updateColorOnState(i));

    return (
      <div key={`ha_${i}`} style={styleGen.highlightedAreaRow()}>
        <Checkbox
          checked={this.state.highlighted_areas[i].visible}
          onChange={() => this.onToggleArea(highlightedArea, i)}></Checkbox>

        <div style={styleGen.inlineBlock}>
          <a style={styleGen.colorPickerBtn(highlightedArea.color)}
            onClick={() => this.updateHighlightedAreaByIndex(i, { ...highlightedArea, _showColorPicker: true })}>
          </a>
          <div style={styleGen.colorPicker(highlightedArea._showColorPicker)}>
            <TwitterPicker
              color={highlightedArea.color}
              onChangeComplete={onChangeComplete} />
          </div>
        </div>

        <div>
          <h4 style={styleGen.highlightedAreaTitle()}>{helper.getAreaName(highlightedArea.area_type)}</h4>
          <label style={styleGen.highlightedAreaName()}>{highlightedArea.name}</label>
        </div>

        <a style={styleGen.btnTrash()} onClick={() => this.deleteHighlightedArea(highlightedArea)}>
          <i className="fa fa-trash" />
        </a>
      </div>
    );
  }

  public render() {
    const { show } = this.props;

    return (
      <Modal show={show} onHide={this.handleHideModal.bind(this)}>
        <Modal.Header>
          <Modal.Title>Custom Map Bounds</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <label>Data Source</label>
                  <FormControl
                    value={this.state.area_type}
                    onChange={this.onChangeGeographyType.bind(this)}
                    componentClass="select"
                    placeholder="Select a geographyc type">
                    {AREA_TYPES.map((type, i) => {
                      if (type.type !== 'custom_bounds') {
                        return this.renderOptionType(type, "type", "name", i)
                      } else {
                        return null;
                      }
                    })}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col sm={6}>
                {this.renderLocationInput}
              </Col>
            </Row>
          </Form>

          <div id="custom_bounds_modal_options">
            {this.state.highlighted_areas.map(this.renderHighlightedAreas.bind(this))}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-green-fill" onClick={this.handleHideModal.bind(this)}>Done</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private onChangeColor(color: ColorResult, highlightedArea: IHighlightedArea, afterChange: Function) {
    if (highlightedArea.data_layer_id) {
      this.updateColor(highlightedArea.data_layer_id, color.hex);
    }
    afterChange(color, highlightedArea);
  }

  private updateColorOnState(index: number) {
    return (color: ColorResult, highlightedArea: IHighlightedArea) =>
      this.updateHighlightedAreaByIndex(index, {
        ...highlightedArea,
        _showColorPicker: false,
        color: color.hex,
      });
  }

  private onToggleArea(highlightedArea: IHighlightedArea, i: number) {
    const visible = !this.state.highlighted_areas[i].visible;
    this.updateHighlightedAreaByIndex(i, { ...highlightedArea, visible });
    this.updateVisible(highlightedArea.id, visible)
  }

  private updateHighlightedAreas() {
    this.setState({ highlighted_areas: this.props.highlightedAreas });
  }

  private async getGeopathSegments(search: string) {
    const congressional_districts: ICongressionalDistrict[] = await get("/api/v1/congressional_districts", { search });
    const options = congressional_districts.map(({ location_id, name }) => ({ value: location_id, label: name }));
    return options;
  }

  private handleHideModal() {
    this.props.onHide(this.state.highlighted_areas);
  }

  private onChangeZipCodes(zip_codes: string, isZipCodesValid: boolean | null) {
    this.setState({ zip_codes, isZipCodesValid });
  }

  private onChangeMarkets(markets: IMarket[]) {
    this.setState({ selectedMarkets: markets });
  }

  private async addZipCodes() {
    let { zip_codes } = this.state;
    let splitZipCodes: string[];

    if ((zip_codes as string).indexOf(",") > -1) {
      splitZipCodes = (zip_codes as string).split(",").map(zip_code => zip_code.trim());
    } else {
      splitZipCodes = (zip_codes as string).split(" ").map(zip_code => zip_code.trim()).filter(zip_code => (zip_code !== ""));
    }
    const name = splitZipCodes.join(", ");
    const highlighted_area = helper.buildHighlightedArea({
      name: name,
      area_type: this.state.area_type,
      visible: true
    }, name);
    let regions: string[];
    if ((highlighted_area.value as string).indexOf(",") > -1) {
      regions = (highlighted_area.value as string).split(",").map(zip_code => zip_code.trim());
    } else {
      regions = (highlighted_area.value as string).split(" ").map(zip_code => zip_code.trim()).filter(zip_code => (zip_code !== ""));
    }
    const dataLayers = await this.saveHighlightedRegions(highlighted_area, regions);
    const highlightedAreas = dataLayers.map(dataLayer => helper.extractHighlightedArea(dataLayer))

    this.setState({
      zip_codes: "",
      highlighted_areas: [...this.state.highlighted_areas, ...highlightedAreas]
    });
  }

  private onChangeDistrict(districts: IDistrict[]) {
    if (!districts) { return this.setState({ districts: [] }); }
    this.setState({ districts });
  }

  private async addDistricts() {
    const { districts } = this.state;
    const name = districts.map(d => d.label).join(", ");
    const highlighted_area = helper.buildHighlightedArea({
      name,
      area_type: this.state.area_type,
      visible: true
    }, districts);
    const regions = districts.map(d => d.value);
    const dataLayers = await this.saveHighlightedRegions(highlighted_area, regions);
    const highlightedAreas = dataLayers.map(dataLayer => helper.extractHighlightedArea(dataLayer))
    this.setState({
      districts: [],
      highlighted_areas: [...this.state.highlighted_areas, ...highlightedAreas]
    });
  }

  private async addMarkets() {
    const { selectedMarkets } = this.state;
    const name = selectedMarkets.map(m => m.label).join(", ");
    const highlighted_area = helper.buildHighlightedArea({
      name,
      area_type: this.state.area_type,
      visible: true
    }, selectedMarkets);
    const regions = selectedMarkets.map(m => m.value);
    const dataLayers = await this.saveHighlightedRegions(highlighted_area, regions);
    const highlightedAreas = dataLayers.map(dataLayer => helper.extractHighlightedArea(dataLayer))
    this.setState({
      selectedMarkets: [],
      highlighted_areas: [...this.state.highlighted_areas, ...highlightedAreas]
    });
  }

  private onChangeGeographyType(event: ChangeEvent<FormControlProps>) {
    this.setState({ area_type: `${event.target.value}` });
  }

  private updateHighlightedAreaByIndex(i: number, value: any) {
    const highlighted_areas = [...this.state.highlighted_areas];
    highlighted_areas[i] = value;
    this.setState({ highlighted_areas });
  }

  private async deleteHighlightedArea(highlighted_area: IHighlightedArea) {
    await del(`/api/v1/data_layers/${highlighted_area.id}?campaign_id=${this.props.campaignId}`)
    const highlighted_areas = this.state.highlighted_areas.filter(current_area => current_area.id !== highlighted_area.id)
    this.setState({ highlighted_areas })
  }

  private async saveHighlightedRegions(highlighted_area: IHighlightedArea, regions: string[] | number[] | FileWithPreview[] | any) {
    return await post(`/api/v1/data_layers/highlighted_area?campaign_id=${this.props.campaignId}`, {
      name: highlighted_area.name,
      color: highlighted_area.color,
      area_type: highlighted_area.area_type,
      regions
    });
  }

  private async updateColor(id: number, color: string) {
    return await put(`/api/v1/highlighted_areas/${id}`, { color })
  }

  private async updateVisible(id: number, visible: boolean) {
    return await put(`/api/v1/data_layers/${id}?campaign_id=${this.props.campaignId}`, { visible })
  }

  private async getDmas() {
    const { records } = await get("/api/v2/dmas")
    const markets = records.map(m => {
      return {
        value: m.id,
        label: m.name
      }
    })
    this.setState({ markets })
  }
}

export default HighlightedArea;
