import ReactGA from 'react-ga';

export const logPageView = function () {
  ReactGA.pageview(window.location.pathname);
}

export const logEvent = function(params) {
  ReactGA.event(params);
}

export const logModalView = function(name) {
  ReactGA.modalview(name);
}
