import * as React from 'react';
import {
  fetchAllOccupancyData,
  fetchAllRevenueData
} from './actions';
import { connect } from 'react-redux';
import { LineChart } from 'react-chartkick'

class AllOccupancyChart extends React.Component<any, any> {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { selected_supplier } = this.props;
    this.fetchData(selected_supplier);
  }

  componentDidUpdate(prevProps) {
    const {
      selected_supplier,
      booking_year_offsets,
      fetchAllOccupancyData
    } = this.props;

    if (selected_supplier !== prevProps.selected_supplier) {
      this.fetchData(selected_supplier)
    }

    if ( booking_year_offsets && !!!prevProps.booking_year_offsets ||
         booking_year_offsets !== prevProps.booking_year_offsets
       ) {
      booking_year_offsets.forEach((offset) => {
        fetchAllOccupancyData(selected_supplier, offset);
      });
    }
  }

  fetchData(supplier) {
    const { fetchAllOccupancyData } = this.props;
    fetchAllOccupancyData(supplier);
  }

  renderLoadingSpinner() {
    return (
      <div className="loading-spinner">
        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    )
  }

  render() {
    const { loading_all_occupancy, all_occupancy } = this.props;

    return (
      <div>
        {(!!!loading_all_occupancy && all_occupancy && Object.keys(all_occupancy).length > 0) &&
          <LineChart
            data={all_occupancy}
            suffix="%"
            download={true}
            curve={false}
            colors={["#6EC379", "#555"]}
          />
        }
        {loading_all_occupancy && this.renderLoadingSpinner()}
      </div>
    );
  }
}

export default connect(
  ({
    // @ts-ignore
    vendor_dashboard: {
      all_occupancy,
      loading_all_occupancy,
      selected_supplier,
      booking_year_offsets,
    }
  }) => ({
    all_occupancy,
    loading_all_occupancy,
    selected_supplier,
    booking_year_offsets,
  }),
  {
    fetchAllOccupancyData,
    fetchAllRevenueData
  }
)(AllOccupancyChart);
