import React, { Component } from "react";
import Card from "../Card";
import ProductionOrderBulkActions from "./ProductionOrderBulkActions";
import { 
  ProductionOrderType,
  ProductionOrderActions
} from "../../../../models/production_orders";
import { CampaignUnit } from "../../../../models/Units";

interface IProductionOrderProps {
  order: ProductionOrderType;
  units: CampaignUnit[]
  campaign: any;
  campaign_permissions: any;
  supplier_contacts: any;
  fetchProductionOrders: (campaign_id) =>any;
  refreshProductionOrder(order_id: number): any;
  cancelProductionOrderItem: (order_id) => any;
  deleteProductionOrder: (order_id) => any;
  submitProductionOrder: (order_id) => any;
  submitProductionOrderBulk: (order_id, payload) => any;
  bulkUpdateUnit: (unit_ids, payload) => void;  
}

interface IProductionOrderState {
  expanded: boolean;
  refreshing: boolean;
  submitting: boolean;
  cancelling: boolean;
  deleting: boolean;
  selected_items: {};
}

class ProductionOrder extends Component<IProductionOrderProps, IProductionOrderState> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      refreshing: false,
      submitting: false,
      deleting: false,
      cancelling: false,
      selected_items: {}
    }
    this.toogleStateAttr = this.toogleStateAttr.bind(this);
    this.renderSubmitAction = this.renderSubmitAction.bind(this);
    this.renderCancelAction = this.renderCancelAction.bind(this);
    this.refreshProductionOrder = this.refreshProductionOrder.bind(this);
    this.submitProductionOrder = this.submitProductionOrder.bind(this);
    this.deleteProductionOrder = this.deleteProductionOrder.bind(this);
    this.cancelProductionOrder = this.cancelProductionOrder.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onItemSelect = this.onItemSelect.bind(this);
  }

  public render() {
    const { order, units, supplier_contacts, campaign } = this.props
    const { expanded, refreshing, selected_items } = this.state
    return (
      <div className="campaign_designs_group">
        <div className="row">
          <div className="col-md-12 group_header">
            <h4 style={{ cursor: "default"}}>
              {this.renderCaret()}
              <span 
                style={{ cursor: "pointer"}}
                onClick={() => this.toogleStateAttr('expanded')}
              >
                {this.renderOrderId()}
              </span>
              <span className="count">
                ({this.renderUnitCountInOrder()})
              </span>
            </h4>
          </div>
          <ProductionOrderBulkActions
            campaignId={campaign.campaignId}
            expanded={expanded}
            order={order}
            selected_items={selected_items}
            units={units}
            supplier_contacts={supplier_contacts}
            onSelectAll={this.onSelectAll}
            onSubmitBulk={this.props.submitProductionOrderBulk}
            bulkUpdateUnit={this.props.bulkUpdateUnit}
            fetchProductionOrders={this.props.fetchProductionOrders}
          />
        </div>
        <div className="production_order_group" style={{ display: expanded ? "block" : "none" }}>
          {this.renderOrders()}
        </div>
        <div className="design_card_item">
          {order.status !== "new" &&
            <a title="get order updates" onClick={this.refreshProductionOrder}>
              <i className={`fa fa-refresh ${refreshing ? 'fa-spin' : ''}`} aria-hidden="true"></i>
            </a>
          }
          <span>Status: {order.status} </span>
          {this.renderOrderAction()}
        </div>
      </div>
    )
  }

  private renderCaret() {
    const { expanded } = this.state;
    if(expanded) { return <i className="fa fa-caret-down margin-right group_arrow"/> }
    return <i className="fa fa-caret-right margin-right group_arrow"/>
  }

  private renderOrderId() {
    const { order } = this.props;
    const order_id = order.external_id || order.id;
    return `Order ${order_id}`
  }

  private toogleStateAttr(attr: ProductionOrderActions) {
    //@ts-ignore
    this.setState({ [attr]: !this.state[attr] } as Pick<IProductionOrderState, keyof IProductionOrderState>);
  }

  private renderUnitCountInOrder() {
    const { order } = this.props
    return order.items.length
  }

  private onSelectAll(event) {
    const { units } = this.props;
    const { target: { checked }} = event;
    const selected_items = units.reduce((acc: CampaignUnit, unit) => {
      return { ...acc, [unit.id]: checked }
    }, {})
    this.setState({ selected_items });
  }

  private renderOrders() {
    const { selected_items } = this.state;
    const { units, order, campaign, campaign_permissions } = this.props;
    return units.map(unit =>
      <Card
        status="circlegraphics"
        key={`cu-${unit.campaign_unit_token}`}
        unit={unit}
        order={order}
        active={selected_items[unit.id]}
        onUnitSelected={this.onItemSelect}
        campaign={campaign}
        campaign_permissions={campaign_permissions}
        fetchProductionOrders={this.props.fetchProductionOrders}
      />
    )
  }

  private onItemSelect(item, selected) {
    const { selected_items } = this.state
    this.setState({
      selected_items: { ...selected_items, [item.id]: selected },
    })
  }

  private renderOrderAction() {
    const { order: { status } } = this.props
    const flows = {
      new: this.renderSubmitAction,
      canceled: () => { return <div></div> },
      default: this.renderCancelAction,
      submitted: this.renderCancelAction
    }
    const action = status || 'default';
    return flows[action]();
  }

  private renderSubmitAction() {
    const { submitting, deleting } = this.state;
    return (
      <div className="submit_production_order">
        <a style={{ margin: "0px 5px" }} onClick={this.submitProductionOrder}>
          {submitting ? "Submitting..." : "Submit"}
        </a>
        <a style={{ margin: "0px 5px", color: "#db5f44" }} onClick={this.deleteProductionOrder}>
          {deleting ? "Deleting..." : "Delete"}
        </a>
      </div>
    )
  }

  private renderCancelAction() {
    const { cancelling } = this.state
    return (
      <a className="submit_production_order" onClick={this.cancelProductionOrder}>
        {cancelling ? "Canceling..." : "Cancel"}
      </a>
    )
  }

  private async submitProductionOrder() {
    const { order: { id }, submitProductionOrder } = this.props;
    this.toogleStateAttr('submitting');
    await submitProductionOrder(id);
    this.toogleStateAttr('submitting');
  }

  private async deleteProductionOrder() {
    const { order: { id }, deleteProductionOrder } = this.props
    if (confirm("Are you sure you want to delete this order?")) {
      this.toogleStateAttr('deleting');
      await deleteProductionOrder(id);
      this.toogleStateAttr('deleting');
    }
  }

  private async cancelProductionOrder() {
    const { order: { id }, cancelProductionOrderItem } = this.props
    if (confirm("Are you sure you want to cancel this order? Canceling from here WILL NOT cancel the order entirely, you must reach out to Circle Graphics directly to cancel this order")) {
      this.toogleStateAttr('cancelling');
      await cancelProductionOrderItem(id);
      this.toogleStateAttr('cancelling');
    }
  }

  private async refreshProductionOrder() {
    const { refreshProductionOrder, order: { id } } = this.props;
    this.toogleStateAttr('refreshing');
    await refreshProductionOrder(id);
    this.toogleStateAttr('refreshing');
  }

}

export default ProductionOrder