import accounting from "accounting";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { resetContractItemPricing } from "../../actions/campaign_supplier_contracts";
import GlobalActions from "../../actions/GlobalActions";
import ConfirmPriceResetModal from "./ConfirmPriceResetModal";

interface Props {
  campaign: any;
  referenceContract: any;
  onDiscountChange: Function;
  resetContractItemPricing: any;
}

interface State {
  saving: boolean;
  discount_percentage: any;
  discount_on_media: boolean;
  discount_on_prod: boolean;
  discount_on_install: boolean;
  show_confirm_price_reset: boolean;
}

class PriceAdjustment extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { referenceContract } = this.props;
    const { discount_on_media, discount_on_prod, discount_on_install, discount_percentage } = referenceContract;

    this.state = {
      saving: false,
      discount_percentage: discount_percentage === "0.0" ? "0" : discount_percentage,
      discount_on_media,
      discount_on_prod,
      discount_on_install,
      show_confirm_price_reset: false,
    };

    this.onDiscountChange = this.onDiscountChange.bind(this);
    this.onApplyToPriceChange = this.onApplyToPriceChange.bind(this);
    this.onApplyToProdChange = this.onApplyToProdChange.bind(this);
    this.onApplyToInstallChange = this.onApplyToInstallChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.onResetDiscounts = this.onResetDiscounts.bind(this);
    this.afterSave = this.afterSave.bind(this);
    this.onHidePriceResetModal = this.onHidePriceResetModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { referenceContract } = this.props;
    if (prevProps.referenceContract.discount_percentage !== referenceContract.discount_percentage) {
      this.setState({ discount_percentage: referenceContract.discount_percentage });
    }
  }

  onDiscountChange(e) {
    const value = e.target.value;
    if (!!!value) {
      this.setState({ discount_percentage: "0" });
    } else if (parseFloat(value) > 100) {
      this.setState({ discount_percentage: "100" });
    } else if (value.includes(".") && value.split(".")[1].length > 2) {
      this.setState({ discount_percentage: `${parseFloat(value).toFixed(2)}` });
    } else {
      this.setState({ discount_percentage: `${parseFloat(value)}` });
    }
  }

  renderDiscountArea() {
    const { discount_percentage } = this.state;
    const { past_avg_discount } = this.props.referenceContract;

    return (
      <div className="discount-area">
        <div className="section-label">Discount</div>
        <input
          className="discount-input"
          onChange={this.onDiscountChange}
          value={discount_percentage}
          type="number"
          min={0}
          max={100}
        />
        <div className="percent">%</div>
        <div className="section-label avg-past-discount">
          Avg past discount: {accounting.formatNumber(past_avg_discount * 100, 2)}%
        </div>
      </div>
    );
  }

  onApplyToPriceChange() {
    const { discount_on_media } = this.state;
    this.setState({ discount_on_media: !!!discount_on_media });
  }

  onApplyToProdChange() {
    const { discount_on_prod } = this.state;
    this.setState({ discount_on_prod: !!!discount_on_prod });
  }

  onApplyToInstallChange() {
    const { discount_on_install } = this.state;
    this.setState({ discount_on_install: !!!discount_on_install });
  }

  renderCheckbox(value, onChange, label) {
    return (
      <div className="checkbox-area">
        <div className="checkable" style={{ display: "flex" }} onClick={onChange}>
          <input className="checkbox" type="checkbox" checked={value} onChange={onChange} />
          <div className="checkbox-label">{label}</div>
        </div>
      </div>
    );
  }

  renderApplyToArea() {
    const { discount_on_media, discount_on_prod, discount_on_install } = this.state;

    return (
      <div className="apply-to-area">
        <div className="section-label">Apply To</div>
        {this.renderCheckbox(discount_on_media, this.onApplyToPriceChange, "Media")}
        {this.renderCheckbox(discount_on_prod, this.onApplyToProdChange, "Prod")}
        {this.renderCheckbox(discount_on_install, this.onApplyToInstallChange, "Install")}
      </div>
    );
  }

  renderAppliedDiscount() {
    const { referenceContract } = this.props;
    const { discount_percentage } = referenceContract;

    return (
      <div className="applied-discount">{`${discount_percentage ? discount_percentage : "0"}% discount applied`}</div>
    );
  }

  async saveChanges() {
    const { discount_percentage, discount_on_media, discount_on_prod, discount_on_install } = this.state;
    const { campaign, referenceContract, onDiscountChange } = this.props;
    const supplierId = _.get(referenceContract, "supplier.id");
    onDiscountChange(
      {
        discount_percentage,
        discount_on_media,
        discount_on_prod,
        discount_on_install,
      },
      this.afterSave,
    );
  }

  afterSave() {
    this.setState({ saving: false });
  }

  async resetDiscounts() {
    const { resetContractItemPricing, referenceContract } = this.props;
    if (referenceContract.token) {
      await resetContractItemPricing(referenceContract);
      this.setState({ saving: false, show_confirm_price_reset: false });
      GlobalActions.showMessage("Prices reset");
    } else {
      this.setState({ saving: false, show_confirm_price_reset: false });
    }
  }

  async onResetDiscounts() {
    const { saving } = this.state;
    if (saving) {
      return false;
    }
    this.setState(
      {
        saving: true,
        discount_percentage: 0,
        discount_on_media: false,
        discount_on_prod: false,
        discount_on_install: false,
      },
      this.resetDiscounts,
    );
  }

  renderButtonArea() {
    const { saving } = this.state;

    return (
      <div className="button-area">
        <button
          className={`apply-button ${saving ? "disabled" : ""}`}
          onClick={() => {
            this.setState({ saving: true }, this.saveChanges);
          }}
          disabled={saving}
        >
          Apply
        </button>
        <a
          className={`reset-button ${saving ? "disabled" : ""}`}
          onClick={() => {
            this.setState({ show_confirm_price_reset: true });
          }}
        >
          Reset
        </a>
      </div>
    );
  }

  onHidePriceResetModal() {
    this.setState({
      show_confirm_price_reset: false,
    });
  }

  renderConfirmPriceReset() {
    const { show_confirm_price_reset, saving } = this.state;
    return (
      <ConfirmPriceResetModal
        show={show_confirm_price_reset}
        onConfirm={this.onResetDiscounts}
        onHide={this.onHidePriceResetModal}
        saving={saving}
      />
    );
  }

  render() {
    return (
      <div id="price_adjustment">
        {this.renderConfirmPriceReset()}
        <div className="main-col">
          <div className="area-header">Price Adjustment</div>
          {this.renderDiscountArea()}
          {this.renderApplyToArea()}
          {this.renderAppliedDiscount()}
        </div>
        <div className="button-col">{this.renderButtonArea()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({ campaign });

export default connect(mapStateToProps, {
  resetContractItemPricing,
})(PriceAdjustment);
