
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./date_picker.css";
import * as React from "react";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { Button, ButtonColor, ButtonSize } from "../Button";

export interface DatePickerRangeProps {
  allowSameDay?: boolean;
  [propName: string]: any;
}

export default class DatePickerRange<T extends DatePickerRangeProps> extends React.Component<T, any> {
  constructor(props: Readonly<T>) {
    super(props);
    this.state = {
      start_date: this.props.start_date,
      end_date: this.props.end_date,
      focusedInput: null,
      selectedPreset: null,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onPresetSelected = this.onPresetSelected.bind(this);
    this.daysAllowed = this.daysAllowed.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
    this.dispatchDatesWithError = this.dispatchDatesWithError.bind(this);
  }

  componentWillReceiveProps(nextProps: any) {
    const { start_date, end_date } = nextProps;
    this.setState({ start_date, end_date });
  }

  onDatesChange({ start_date, end_date }: any) {
    const isEndDateValid = this.endDateValidation(start_date, end_date);
    if (!isEndDateValid && end_date) {
      return this.dispatchDatesWithError(start_date, end_date);
    }
    this.setState({ start_date, end_date }, this.props.onDatesChange({ start_date, end_date, error: [] }));
  }

  onPresetSelected(selectedPreset: any) {
    const { text, length, period } = selectedPreset;
    const { start_date } = this.state;
    const startDate = start_date ? moment(start_date) : this.findNextMonday();
    const endDate = moment(startDate)
      .add(length, period)
      .subtract(1, "day");
    this.setState({ selectedPreset: text }, this.onDatesChange({ start_date: startDate, end_date: endDate }));
  }

  renderDatePresets() {
    const { selectedPreset } = this.state;
    const additionalPresets = this.props.additionalPresets || [];
    //@ts-ignore
    const allPresets = [...presets, ...additionalPresets]; 

    return (
      <div className="CalendarPreset">
        {allPresets.map((preset, idx) => {
          return (
            <Button
              color={selectedPreset == (preset as any).text ? ButtonColor.Default : ButtonColor.Neutro}
              size={ButtonSize.Small}
              key={idx}
              onClick={() => this.onPresetSelected(preset)}
            >
              {(preset as any).text}
            </Button>
          );
        })}
        {this.renderCalendarInfo()}
      </div>
    );
  }

  daysAllowed(date: any) {
    const { blockDays } = this.props;
    if (!blockDays) return;
    return date.weekday() !== 1 || date.isBefore(moment());
  }

  endDateValidation(start_date: any, end_date: any) {
    return moment(end_date).isSameOrAfter(
      moment(start_date)
        .add(4, "weeks")
        .subtract(1, "day"),
    );
  }

  dispatchDatesWithError(start_date: any, end_date: any) {
    const errorMessage = "End date must be at least 4 weeks from start date";
    this.setState({ start_date, end_date });
    return this.props.onDatesChange({
      start_date,
      end_date,
      error: [errorMessage],
    });
  }

  renderCalendarInfo() {
    const { calendarInfo } = this.props;
    if (!calendarInfo) return;
    return calendarInfo;
  }

  findNextMonday() {
    return moment()
      .startOf("isoWeek")
      .add(1, "week");
  }

  render() {
    const { start_date, end_date, focusedInput } = this.state;
    const {
      hideClearButton,
      small,
      withPortal,
      noBorder,
      showDefaultInputIcon,
      openDirection,
      appendToBody,
    } = this.props;

    return (
      <DateRangePicker
        startDate={start_date} // momentPropTypes.momentObj or null,
        startDateId="range_start_date" // PropTypes.string.isRequired,
        endDate={end_date} // momentPropTypes.momentObj or null,
        endDateId="range_end_date" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }: any) =>
          this.onDatesChange({ start_date: startDate, end_date: endDate })
        } // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput: any) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        isOutsideRange={() => false}
        renderCalendarInfo={this.renderDatePresets}
        disabled={this.props.disabled === true ? true : false}
        minimumNights={this.props.allowSameDay === true ? 0 : 1}
        //numberOfMonths={1}
        isDayBlocked={this.daysAllowed}
        showClearDates={hideClearButton ? false : true}
        small={small}
        withPortal={withPortal}
        noBorder={noBorder}
        showDefaultInputIcon={showDefaultInputIcon}
        openDirection={openDirection}
        appendToBody={typeof appendToBody === "undefined" ? false : appendToBody}
      />
    );
  }
}

const presets = [
  {
    text: "4 weeks",
    length: 4,
    period: "weeks",
  },
  {
    text: "6 weeks",
    length: 6,
    period: "weeks",
  },
  {
    text: "8 weeks",
    length: 8,
    period: "weeks",
  },
  {
    text: "10 weeks",
    length: 10,
    period: "weeks",
  },
  {
    text: "12 weeks",
    length: 12,
    period: "weeks",
  },
  {
    text: "16 weeks",
    length: 16,
    period: "weeks",
  },
];
