import React from 'react';
import createClass from 'create-react-class';
import { browserHistory } from 'react-router-legacy';
import GlobalActions from '../../actions/GlobalActions'

export default createClass({
  displayName: 'EmailTemplates/Form',

  getInitialState() {
    const { emailTemplate } = this.props

    return {
      saving: false,
      name: emailTemplate ? emailTemplate.name : '',
      subject: emailTemplate ? emailTemplate.subject : '',
      html_body: emailTemplate ? emailTemplate.html_body : '',
      text_body: emailTemplate ? emailTemplate.text_body : '',
      file_attachment: emailTemplate ? emailTemplate.file_attachment : ''
    };
  },

  componentDidMount() {
  },

  onSubmit(e) {
    e.preventDefault();

    const { emailTemplate } = this.props
    const { name, text_body, html_body, subject, file_attachment } = this.state;

    if (name == '' || html_body == '' || subject == '') {
      this.setState({ errors: "All fields are required" });
      return;
    }

    let data = {
      email_template: { name, text_body, html_body, subject, file_attachment }
    };

    this.setState({ saving: true });

    let method
    let url = '/api/v1/email_templates'
    if (emailTemplate) {
      url += '/' + emailTemplate.id
      method = 'PUT'
    } else {
      method = 'POST'
    }

    $.ajax({url, method, data}).then(response => {
      GlobalActions.showMessage("Saved email template!")
      browserHistory.push('/email_templates')
    }).catch((error) => {
      this.setState({ errors: error.message, saving: false });
      if (DEBUG) console.log(error);
    });
  },

  render() {
    const { saving } = this.state;
    return (
      <div>
        {saving && <div className="searchTab__loading" />}
        {!saving && <form onSubmit={this.onSubmit}>
          {this.state.errors && <div className="message message_error">{this.state.errors}</div>}

          <div style={{ marginBottom: 10 }}>
            <label>Internal Name</label>
            <input value={this.state.name} className="form-control" type="input" placeholder="Internal Name" onChange={(e) => { this.setState({ name: e.target.value }); }} style={(this.state.errors && this.state.errors.match(/name/) ? { borderColor: 'red' } : {})} />
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Subject</label>
            <input value={this.state.subject} className="form-control" type="input" placeholder="Subject" onChange={(e) => { this.setState({ subject: e.target.value }); }} style={(this.state.errors && this.state.errors.match(/subject/) ? { borderColor: 'red' } : {})} />
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>HTML Body</label>
            <textarea value={this.state.html_body} style={{ height: 250 }} className="form-control" type="input" placeholder="Write a message" onChange={(e) => { this.setState({ html_body: e.target.value }); }} />
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Text Body</label>
            <textarea disabled value={this.state.text_body} style={{ height: 250 }} className="form-control" type="input" placeholder="Write a message" />
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>File Attachment</label>
            <select onChange={e => this.setState({ file_attachment: e.target.value })} value={this.state.file_attachment} className="form-control">
              <option></option>
              <option value="AdQuick One Pager.pdf">AdQuick One Pager</option>
              <option value="AdQuick Comparison.png">AdQuick Comparison</option>
              <option value="AdQuick Metrics.pdf">AdQuick Metrics</option>
            </select>
          </div>
          <div style={{ marginBottom: 0 }}>
            <button className="btn btn-primary" type="submit">Save</button>
          </div>
        </form>}
      </div>
    )
  }

});
