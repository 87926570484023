import GlobalActions from "../../../actions/GlobalActions";
import NewMapStore from '../../../stores/NewMapStore';
import { get, post, put } from "../../../utils/api";
import JobProgress from '../../../utils/JobProgress';

const URL = "/api/v1/vendors/campaign_builders";

export const createCampaign = campaign => async (dispatch, getState) => {
  const response = await post(URL, { campaign });
  if (response.status == 200) {
    dispatch({
      type: "CREATE_CAMPAIGN_BUILDER_SUCCESS",
      payload: { ...response.data }
    });
  }
};

export const updateCampaign = (campaign, campaign_token) => async (dispatch, getState) => {
  const response = await put(URL, {
    campaign: {
      ...campaign,
      campaign_token
    }
  });
  if (response.status == 200) {
    dispatch({
      type: "UPDATE_CAMPAIGN_BUILDER_SUCCESS",
      payload: { ...response.data }
    });
  }
};

export const loadCampaignData = campaign_builder_token => async (
  dispatch,
  getState
) => {
  const response = await get(`${URL}/${campaign_builder_token}`);
  if (response.status == 200) {
    dispatch({
      type: "LOAD_CAMPAIGN_BUILDER_SUCCESS",
      payload: { ...response.data }
    });
  }
};

export const addUnits = (campaign_token, campaign_unit_data) => async (
  dispatch,
  getState
) => {
  const response = await post(`${URL}/add_units`, {
    campaign: {
      campaign_token,
      campaign_unit_data: JSON.stringify(campaign_unit_data)
    }
  });
  if (response.status == 200) {
    dispatch({
      type: "CAMPAIGN_BUILDER_ADD_UNITS_SUCCESS",
      payload: { ...response.data }
    });
  }
};

export const batchAddUnits = (campaign_token, campaign_unit_data) => async dispatch => {
  const payload = {
    campaign: {
      campaign_token,
      campaign_unit_data: JSON.stringify(campaign_unit_data)
    }
  }
  const { job_id } = await post(`${URL}/batch_add_units`, payload);
  return job_id;
};

export const batchAddUnitsSuccess = (data) => dispatch => {
  dispatch({
    type: "CAMPAIGN_BUILDER_ADD_UNITS_SUCCESS",
    payload: { ...data }
  });
}

export const removeUnit = (campaign_token, campaign_unit_token) => async (
  dispatch,
  getState
) => {
  const obj = {
    campaign: {
      campaign_token,
      units: JSON.stringify([{ campaign_unit_token }])
    }
  };
  const response = await post(`${URL}/remove_units`, obj);
  if (response.status == 200) {
    dispatch({
      type: "CAMPAIGN_BUILDER_REMOVE_UNIT_SUCCESS",
      payload: { ...response.data }
    });
    GlobalActions.showMessage("Unit successfully removed from campaign");
  } else
    GlobalActions.showError(
      "Error removing unit from campaign, please contact AdQuick"
    );
};

export const createProposals = (unit_tokens, campaign_token) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens };
  dispatch({ type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_BEGIN" });
  const response = await post(`${URL}/create_proposals`, params);
  if (response.status == 200) {
    GlobalActions.showMessage("Flight information saved");
    dispatch({
      type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_SUCCESS",
      payload: { ...response.data, unit_tokens, flight_type: 'proposed' }
    });
  }
};

export const createBookings = (unit_tokens, campaign_token) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens };
  dispatch({ type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_BEGIN" });
  const response = await post(`${URL}/create_bookings`, params);
  if (response.status == 200) {
    GlobalActions.showMessage("Flight information saved");
    dispatch({
      type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_SUCCESS",
      payload: { ...response.data, unit_tokens, flight_type: 'booked' }
    });
    return true
  }
  if (response.status == 405) {
    GlobalActions.showError("Unable to make changes, some of the selected dates have conflicts");
    dispatch({
      type: "CAMPAIGN_BUILDER_UPDATE_CONFLICTS",
      payload: { ...response.data }
    });
    return false
  }
};

export const createHolds = (unit_tokens, campaign_token, hold_expires_at) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens, hold_expires_at };
  dispatch({ type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_BEGIN" });
  const response = await post(`${URL}/create_holds`, params);
  if (response.status == 200) {
    GlobalActions.showMessage("Flight information saved");
    dispatch({
      type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_SUCCESS",
      payload: { ...response.data, unit_tokens, flight_type: 'on_hold' }
    });
    return true
  }
  if (response.status == 405) {
    GlobalActions.showError("Unable to make changes, some of the selected dates have conflicts");
    dispatch({
      type: "CAMPAIGN_BUILDER_UPDATE_CONFLICTS",
      payload: { ...response.data }
    });
    return false
  }
};

export const createMovables = (unit_tokens, campaign_token) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens };
  dispatch({ type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_BEGIN" });
  const response = await post(`${URL}/create_movables`, params);
  if (response.status == 200) {
    GlobalActions.showMessage("Flight information saved");
    dispatch({
      type: "CAMPAIGN_BUILDER_CREATE_FLIGHTS_SUCCESS",
      payload: { ...response.data, unit_tokens, flight_type: 'movable' }
    });
    return true
  }
  if (response.status == 405) {
    GlobalActions.showError("Unable to make changes, some of the selected dates have conflicts");
    dispatch({
      type: "CAMPAIGN_BUILDER_UPDATE_CONFLICTS",
      payload: { ...response.data }
    });
    return false
  }
};

export const addNotes = (unit_tokens, campaign_token, rationale) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens, rationale };
  dispatch({ type: "CAMPAIGN_BUILDER_ADD_NOTES_BEGIN" });
  const response = await post(`${URL}/add_notes`, params);
  if (response.status == 200) {
    dispatch({
      type: "CAMPAIGN_BUILDER_ADD_NOTES_SUCCESS",
      payload: { ...response.data }
    });
  }
};

export const updateDates = (unit_tokens, campaign_token, start_date, end_date) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens, start_date, end_date };
  dispatch({ type: "CAMPAIGN_BUILDER_UPDATE_DATES_BEGIN" });
  const response = await post(`${URL}/update_dates`, params);
  if (response.status == 200) {
    GlobalActions.showMessage("Flight dates updated.");
    dispatch({
      type: "CAMPAIGN_BUILDER_UPDATE_DATES_SUCCESS",
      payload: { ...response.data, unit_tokens, start_date, end_date }
    });
    return true;
  }
  if (response.status == 405) {
    GlobalActions.showError("Unable to make changes, some of the selected dates have conflicts");
    dispatch({
      type: "CAMPAIGN_BUILDER_UPDATE_CONFLICTS",
      payload: { ...response.data }
    });
    return false;
  }
};

export const updateSpots = (unit_tokens, campaign_token, spots) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens, spots };
  dispatch({ type: "CAMPAIGN_BUILDER_UPDATE_SPOTS_BEGIN" });
  const response = await post(`${URL}/update_spots`, params);
  if (response.status == 200) {
    dispatch({
      type: "CAMPAIGN_BUILDER_UPDATE_SPOTS_SUCCESS",
      payload: { ...response.data, quantity: spots, unit_tokens }
    });
  }
};

export const updatePricing = (unit_tokens, campaign_token, price, production, installation) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens, price, production, installation };
  dispatch({ type: "CAMPAIGN_BUILDER_UPDATE_PRICING_BEGIN" });
  const response = await post(`${URL}/update_pricing`, params);
  if (response.status == 200) {
    dispatch({
      type: "CAMPAIGN_BUILDER_UPDATE_PRICING_SUCCESS",
      payload: { ...response.data }
    });
  }
};

export const deleteUnits = (unit_tokens, campaign_token) => async (dispatch, getState) => {
  const params = { campaign_token, unit_tokens };
  dispatch({ type: "CAMPAIGN_BUILDER_DELETE_UNITS_BEGIN" });
  const response = await post(`${URL}/delete_units`, params);
  if (response.status == 200) {
    dispatch({
      type: "CAMPAIGN_BUILDER_DELETE_UNITS_SUCCESS",
      payload: { ...response.data }
    });
  }
};

export const loadZipCodes = campaign_id => async (dispatch, getState) => {
  const zip_codes = await NewMapStore.loadZipCodes(campaign_id);
  dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_ZIP_CODES', payload: { zip_codes } });
};

export const resetBuilderState = () => async (dispatch, getState) => {
  dispatch({ type: 'CAMPAIGN_BUILDER_RESET_MAP_STATE', payload: {} });
};

export const goToStep = (step) => async (dispatch, getState) => {
  dispatch({ type: 'CAMPAIGN_BUILDER_GO_TO_STEP', payload: { step } });
};

export const loadFaceIds = (excluded_face_ids = []) => async (dispatch, getState) => {
  const response = await get(`/api/v1/inventory/face_ids`);
  if (response.status === 200) {
    const { supplier_face_ids } = response.data;
    dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_FACE_IDS', payload: { supplier_face_ids, excluded_face_ids } });
  } else {
    dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_FACE_IDS', payload: { supplier_face_ids: [], excluded_face_ids } });
  }
};

export const loadMediaTypeOptions = () => async (dispatch, getState) => {
  const res = await get('/api/v1/media_types');
  const { data } = res;
  dispatch({ type: 'LOAD_MEDIA_TYPE_OPTIONS', payload: { media_types: data.media_types } });
};

export const loadMediaSubtypeOptions = () => async (dispatch, getState) => {
  const res = await get('/api/v1/media_subtypes/options');
  const { subtypes } = res;
  dispatch({ type: 'LOAD_MEDIA_SUBTYPE_OPTIONS', payload: { media_subtypes: subtypes } });
};

export const filterSupplierFaceIds = (supplier_face_ids, exclusions) => async (dispatch, getState) => {
  dispatch({ type: 'FILER_SUPPLIER_FACE_IDS', payload: { supplier_face_ids, exclusions } });
};

export const loadBuilderCampaignUnits = (campaign_token) => async (dispatch, getState) => {
  if (!campaign_token) return;

  const response = await get(
    `${URL}/load_campaign_units?campaign_token=${campaign_token}`
  );
  const { campaign_units } = response.data;
  dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_CAMPAIGN_UNITS_SUCCESS', payload: { campaign_units } });
};

export const loadBuilderCampaignUnitsBg = (campaign_token) => async (dispatch, getState) => {
  if (!campaign_token) return;

  const { campaign_builder_ui: { loading_units } } = getState();
  if(loading_units) { return }

  const job_pooling = new JobProgress();
  const { job_id } = await get(`${URL}/load_campaign_units_bg?campaign_token=${campaign_token}`);
  dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_CAMPAIGN_UNITS_STARTED' });
  job_pooling.startPolling(job_id, (response) => {
    const { data } = response;
    const campaign_units = data.campaign_units
    dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_CAMPAIGN_UNITS_SUCCESS', payload: { campaign_units } });
  })
}

export const loadBuilderAvailabilityBg = (campaign_token) => async (dispatch, getState) => {
  const { campaign_builder_ui: { loading_units } } = getState();
  if(loading_units) { return }

  const job_pooling = new JobProgress();
  const { job_id } = await get(`${URL}/load_availability_bg?campaign_token=${campaign_token}`);
  dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_AVAILABILITY_STARTED' });
  job_pooling.startPolling(job_id, (response) => {
    const { data } = response;
    const campaign_units = data.campaign_units
    dispatch({ type: 'CAMPAIGN_BUILDER_LOAD_AVAILABILITY_SUCCESS', payload: { campaign_units } });
  })
}
