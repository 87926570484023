import cs from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { sidebarUnits } from '../../models/Units';
import { filterUnits } from "../../actions/UnitsActions";
import { isMobile } from "../../utils/mobile";

const POPUP_WIDTH = 180;

class ScreenTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onDone = this.onDone.bind(this);
    this.state = {
      isExpanded: false,
      selectedScreenTypes: this.props.initialValue ? this.props.initialValue : []
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.onToggle);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onToggle);
  }
  
  componentDidUpdate(prevProps) {
    const { isActive, initialValue } = this.props
    if (!isActive && prevProps.isActive) { this.onClear() }
    if (initialValue && initialValue !== prevProps.initialValue) {
      this.setState({ selectedScreenTypes: initialValue })
    }
  }

  onToggle(event) {
    const { isExpanded } = this.state;
    const clickedWithinElement = this.node && this.node.contains(event.target);
    if (!isExpanded && clickedWithinElement) {
      this.setState({ isExpanded: true });
    } else if (isExpanded && (!clickedWithinElement || this.node == event.target)) {
      this.setState({ isExpanded: false });
    }
  }

  onClear() {
    this.setState({ selectedScreenTypes: [] }, this.onFilter);
  }

  onScreenTypeSelect(option) {
    const { selectedScreenTypes } = this.state;
    if (selectedScreenTypes.includes(option)) {
      this.setState(
        { selectedScreenTypes: selectedScreenTypes.filter(screenType => screenType !== option) },
        this.onFilter,
      );
    } else {
      this.setState({ selectedScreenTypes: selectedScreenTypes.concat([option]) }, this.onFilter);
    }
  }

  onFilter() {
    const { selectedScreenTypes } = this.state;
    const { filterUnits, handleQueryParams } = this.props;
    _.defer(filterUnits, { screen_type: !!selectedScreenTypes.length ? selectedScreenTypes : null });
    handleQueryParams({ 'screen_type[]': selectedScreenTypes })
  }

  onDone(event) {
    event.preventDefault();
    this.setState({ isExpanded: false });
  }

  getButtonLabel() {
    const { selectedScreenTypes } = this.state;
    if (!selectedScreenTypes.length) return "Screen type";
    if (selectedScreenTypes.length == 1) return `${_.capitalize(selectedScreenTypes[0])} only`;
    if (selectedScreenTypes.length > 1) return "Digital + Static";
  }

  getPopupPosition() {
    const { left, right } = this.node.getBoundingClientRect();
    const flipNeeded = left + POPUP_WIDTH > window.innerWidth;
    if (flipNeeded) return "right";
    return "left";
  }

  countUnitScreenType(screenType) {
    const { units } = this.props;
    const filteredUnit = sidebarUnits(units, true) //no multi flights
    return filteredUnit.filter(u => u.screen_type == screenType).length
  }

  renderPopup() {
    const { selectedScreenTypes } = this.state;
    const screen_types = ["static", "digital"];
    return (
      <div className="filter_popup screen_type_popup" style={{ [this.getPopupPosition()]: "-1px" }}>
        <h4>SCREEN TYPE</h4>
        <ul>
          {screen_types.map(option => {
            const count = this.countUnitScreenType(option)
            const isActive = selectedScreenTypes.includes(option);
            return (
              <li key={option} onClick={() => this.onScreenTypeSelect(option)} className={cs({ active: isActive })}>
                {_.capitalize(option)}
                <i className="count">({count})</i>
                <i className="fa fa-check" />
              </li>
            );
          })}
        </ul>
        <div className="padded_content">
          <p className="actions">
            <a onClick={this.onDone}>Done</a>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { isExpanded } = this.state;
    const { isActive } = this.props;
    const buttonLabel = this.getButtonLabel();
    return (
      <li ref={node => (this.node = node)} className={cs({ active: isActive || isExpanded })}>
        {buttonLabel}
        {isExpanded && this.renderPopup()}
      </li>
    );
  }
}

export default connect(({ units }) => ({ units }), { filterUnits })(ScreenTypeFilter);
