import React, { PureComponent } from 'react';
import cs from 'classnames';
import { isMobile } from '../../../utils/mobile';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class PinnedMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false
    }

    this.hideMessage = this.hideMessage.bind(this)
  }

  componentDidMount() {
    const { id } = this.props.pinnedMessageInfo;
    const hidden = !!cookies.get(`hide_pinned_message_${id}`)
    return { hidden: hidden }
  }

  hideMessage() {
    const { id } = this.props.pinnedMessageInfo;
    cookies.set(`hide_pinned_message_${id}`, true, { path: '/' });
    this.setState({ hidden: true })
  }

  render() {
    const { message, user, days_since } = this.props.pinnedMessageInfo;
    const { hidden } = this.state;

    return (
      <div className={cs('pinned_message_overlay', { hidden: hidden })}>
        <div className="campaign-pinned-message">
          {isMobile() &&
            <div
              className="close-pinned-message"
              onClick={this.hideMessage}
            >
              <i className="fa fa-close"/>
            </div>
          }
          <div className="campaign-pinned-message-user">
            <img className="avatar" src={user.avatar_url}></img>
            <div className="pinned-message-user-name">{user.name}</div>
            <div className="pinned-message-user-info email">{user.email}</div>
            <div className="pinned-message-user-info phone">{user.phone}</div>
          </div>
          <div className="campaign-pinned-message-details">
            <div className="pinned-by">
              <i className="fa fa-thumb-tack"></i>
              <span className="pinned">Pinned</span> by {user.email}
            </div>
            <div className="campaign-pinned-message-time">
              <div>{days_since}</div>
            </div>
            <div className="campaign-pinned-message-body">
              <div dangerouslySetInnerHTML={{__html: message}}></div>
            </div>
          </div>
          {isMobile() &&
            <div
              className="cta-pinned-message"
              onClick={this.hideMessage}
            >
              OK
            </div>
          }
        </div>
      </div>
    );
  }
}
