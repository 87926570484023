import {
  CANCEL_CAMPAIGN_SUPPLIER_CONTRACT,
  CREATE_CAMPAIGN_SUPPLIER_CONTRACT,
  DESTROY_CAMPAIGN_SUPPLIER_CONTRACT,
  DISCOUNT_CAMPAIGN_SUPPLIER_CONTRACT,
  LOAD_CAMPAIGN_SUPPLIER_CONTRACTS,
  RESET_CAMPAIGN_SUPPLIER_CONTRACT_PRICING,
  SEND_PRICE_PROPOSAL,
} from "../actions/campaign_supplier_contracts";
import { CampaignSupplierContract } from "../models/campaign_supplier_contract";

interface Action {
  type: string;
  payload: any;
}

export default (state: CampaignSupplierContract[], { payload, type }: Action): CampaignSupplierContract[] => {
  switch (type) {
    case LOAD_CAMPAIGN_SUPPLIER_CONTRACTS:
      return payload.resetList ? payload.campaign_supplier_contracts : [...state, ...payload.campaign_supplier_contracts];
    case RESET_CAMPAIGN_SUPPLIER_CONTRACT_PRICING:
      return onCreateOrUpdatedContract(state, payload.campaign_supplier_contract);
    case CREATE_CAMPAIGN_SUPPLIER_CONTRACT:
      return onCreateOrUpdatedContract(state, payload.campaign_supplier_contract);
    case DISCOUNT_CAMPAIGN_SUPPLIER_CONTRACT:
      return onCreateOrUpdatedContract(state, payload.campaign_supplier_contract);
    case CANCEL_CAMPAIGN_SUPPLIER_CONTRACT:
      return onCancel(state, payload.campaign_supplier_contract);
    case DESTROY_CAMPAIGN_SUPPLIER_CONTRACT:
      return onDestroy(state, payload.contract_id);
    case SEND_PRICE_PROPOSAL:
      return onCreateOrUpdatedContract(state, payload.campaign_supplier_contract);
    default:
      return state || [];
  }
};

const onCreateOrUpdatedContract = (state, contract) => {
  if (state.filter(c => c.id === contract.id).length > 0) {
    return state.map(c => {
      return c.id === contract.id ? contract : c;
    });
  } else {
    return [...state, contract];
  }
};

const onCancel = (state, contract) => {
  return [...state.filter(c => c.id !== contract.id), contract];
};

const onDestroy = (state, contract_id) => {
  return [...state.filter(c => c.id !== contract_id)];
};
