import first from "lodash/first";
import last from "lodash/last";
import { formatDate } from "../Components/Chart";

export interface ICampaignDuration {
  start: string | Date;
  end: string | Date;
}

type ChartConfig = {
  datasets: Dataset[];
}

type Dataset = {
  data: Element[];
}

type Element = {
  x: string;
}

const findMiddleIndexPoint = (start, end, data) => {
  const getIndex = (date) => data.indexOf(data.find((tick) => formatDate(tick.x) === date));
  const startIndex = getIndex(start);
  const endIndex = getIndex(end);
  return startIndex + Math.round((endIndex - startIndex) / 2);
};

export const buildOptions = (campaignDuration: ICampaignDuration) => (chartConfig: ChartConfig) => {

  const firstSet = first(chartConfig.datasets);
  if (!firstSet) return {};

  const firstDate = formatDate(first(firstSet.data)?.x);
  const lastDate = formatDate(last(firstSet.data)?.x);
  const campaignStart = formatDate(campaignDuration.start);
  const campaignEnd = formatDate(campaignDuration.end);

  return {
    elements: {
      point: { radius: 0 },
    },
    scales: {
      yAxes: [{
        ticks: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: "CTR",
        },
      }],
      xAxes: [
        {
          id: "during_x",
          type: "category",
          gridLines: {
            color: "#4A90E2",
            drawOnChartArea: true,
            borderDash: [12, 10],
            drawBorder: false,
          },
          ticks: {
            callback: (dateLabel: string) => {
              const isStartDate = dateLabel === campaignStart;
              const isEndDate = dateLabel === campaignEnd;

              if (isStartDate || isEndDate) {
                return ""; // to draw separate lines
              }
            },
          },
        },
        {
          type: "category",
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            fontColor: "#858585",
            fontSize: 12,
            fontSyle: "strong",
            maxRotation: 0,
            callback: (dateLabel: string) => {
              const isCurrent = (index) => formatDate(firstSet.data[index]?.x) === dateLabel;

              if (isCurrent(findMiddleIndexPoint(firstDate, campaignStart, firstSet.data))) {
                return "Before";
              }

              if (isCurrent(findMiddleIndexPoint(campaignStart, campaignEnd, firstSet.data))) {
                return "During";
              }

              if (isCurrent(findMiddleIndexPoint(campaignEnd, lastDate, firstSet.data))) {
                return "After";
              }
            },
          },
        },
        {
          type: "category",
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            autoSkip: false,
            fontColor: "#858585",
            fontSize: 12,
            maxRotation: 0,
            callback: (dateLabel: string) => {
              const isCurrent = (index) => formatDate(firstSet.data[index].x) === dateLabel;

              if (isCurrent(findMiddleIndexPoint(firstDate, campaignStart, firstSet.data))) {
                return `${firstDate}-${campaignStart}`;
              }

              if (isCurrent(findMiddleIndexPoint(campaignStart, campaignEnd, firstSet.data))) {
                return `${campaignStart}-${campaignEnd}`;
              }

              if (isCurrent(findMiddleIndexPoint(campaignEnd, lastDate, firstSet.data))) {
                return `${campaignEnd}-${lastDate}`;
              }
            },
          },
        },
      ],
    },
  };
};
