import React, { Component } from "react";
import { connect } from "react-redux";
import CampaignSupplierContracts from "../../CampaignSupplierContracts";
import CampaignRequests from "../../Requests/CampaignRequests";
import CampaignUnits from "../Edit/CampaignUnits";
import InventoryUpload from "../Edit/InventoryUpload";
import QaIssues from "./QaIssues";


class Manage extends Component {
  constructor(props) {
    super(props);
    this.renderCampaignRequest = this.renderCampaignRequest.bind(this)
  }


  subPages() {
    switch (this.props.step) {
      case "requests":
        return this.renderCampaignRequest()
      case "contracts":
        return this.renderContracts()
      case "units":
        return this.renderUnits()
      case "files":
        return this.renderFiles()
      case "qa_issues":
        return this.renderQa()
      default:
        return this.renderCampaignRequest()
    }
  }

  renderCampaignRequest() {
    return <CampaignRequests
    campaign={this.props.campaign}
    campaignPermissions={this.props.campaignPermissions}
    user={this.props.user}
  />
  }

  renderContracts() {
    return <CampaignSupplierContracts campaign={this.props.campaign} user={this.props.user} />
  }

  renderUnits() {
    return <CampaignUnits data={this.props.data} onUnitListingClick={this.props.onUnitListingClick} />
  }

  renderFiles() {
    return <InventoryUpload campaign={this.props.campaign} />
  }

  renderQa() {
    return <QaIssues data={{ ...this.props }} />
  }

  render() {
    return (
      <div className="container" style={{ marginTop: 10 }}>
        {this.subPages()}
      </div>
    )
  }
}

const mapStateToProps = ({ issues }) => ({
  ...issues,
});

export default connect(mapStateToProps)(Manage);
