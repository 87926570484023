import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./date_picker.css";
import * as React from "react";
import { SingleDatePicker } from "react-dates";

export default class Single extends React.Component<any, any> {
  displayName = "Single Date Picker";

  getInitialState() {
    return {
      date: this.props.date,
      focused: false,
      showClearDate: this.props.showClearDate,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    const { date } = nextProps;
    this.setState({ date });
  }

  onDateChange(date: any) {
    this.setState({ date, focused: false }, this.props.onDateChange({ date }));
  }

  render() {
    const { date, focused, showClearDate } = this.state;

    return (
      <SingleDatePicker
        date={date} // momentPropTypes.momentObj or null,
        id="date_input" // PropTypes.string.isRequired,
        onDateChange={this.onDateChange} // PropTypes.func.isRequired,
        focused={focused}
        onFocusChange={({ focused }: any) => this.setState({ focused })} // PropTypes.func.isRequired,
        showClearDate={showClearDate}
      />
    );
  }
}
