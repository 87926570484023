import * as React from "react";
import WhiteBox from "../WhiteBox";
import Grid from "../Grid";
import TextIcon from "../TextIcon";

export interface MetricProps {
  active?: boolean;
  metric: string;
  useHover?: boolean;
  number?: number;
  percentage?: number;
}

const MetricBox = ({
  active,
  metric,
  percentage,
  number,
  useHover = false
}: MetricProps): React.ReactElement => (
  <WhiteBox
    active={active}
    inline={true}
    padding="12px 16px"
    margin="0 10px 0 0"
    useHover={useHover}
  >
    <Grid columns="2fr 1fr" padding="0">
      <TextIcon fontSize="14px">{metric}</TextIcon>
      <TextIcon
        fontSize="14px"
        faIcon="fa-arrow-circle-up"
        iconColor="#44AC6B"
        textColor="#333"
      >
        {percentage && `${percentage}%`}
        {number}
      </TextIcon>
    </Grid>
  </WhiteBox>
);

export default MetricBox;
