import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  onExportComplete
} from "./actions";
import JobProgress from '../../../utils/JobProgress';
import GlobalActions from '../../../actions/GlobalActions';

class ExportProgress extends PureComponent {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { job_id, export_type } = this.props;
    if (job_id && (!!!prevProps || job_id !== prevProps.job_id)) {
      const job_progress = new JobProgress();
      job_progress.startPolling(job_id, this.onExportReady.bind(this));
      window.addLoadingStep(`Exporting ${export_type}...`);
    }
  }

  onExportReady(completed_job) {
    const { export_type, onExportComplete, campaign_exporting } = this.props;
    const { data } = completed_job;
    const { url, error } = data;
    window.removeLoadingStep(`Exporting ${export_type}...`);
    if (error) {
      GlobalActions.showError(error);
    }
    if (url && campaign_exporting) {
      GlobalActions.showMessage(this.exportReadyMessage(url), "success", true);
    }
    onExportComplete();
  }

  exportReadyMessage(url) {
    const message = (
      <span>
        Export is ready, get it on the <a href={url} target="_blank">Attachments Tab</a>.
      </span>
    );
    return message;
  }

  render() {
    return <div />;
  }
}

export default connect(
  // @ts-ignore
  ({ campaign_export: { show_export_progress_modal, campaign_exporting, job_id, export_type, error }, currentUser }) => ({
    show_export_progress_modal,
    campaign_exporting,
    job_id,
    export_type,
    currentUser,
    error,
  }),
  { onExportComplete }
)(ExportProgress);
