import React from 'react';
import createClass from 'create-react-class';

import { del, get, post } from '../../../utils/api';
import AdwordsAuthorize from './Authorize';
import AuthStore from '../../../stores/AuthStore';
import CampaignStore from '../../../stores/CampaignStore';
import Charts from './Charts';
import Comment from './Comment';
import Metric from './Metric';
import Loading from '../../Loading';

export default createClass({

  async componentDidMount() {
    const adwords_status = await CampaignStore.hasAdwords(this.props.campaign.token);
    if (adwords_status === false) {
      this.setState({ adwords_status, loading: false });
      return;
    }

    const { reports, comments } = await get(`/api/v1/campaigns/${this.props.campaign.token}/adwords_reports`);
    this.setState({ reports, comments, adwords_status, loading: false });
  },

  getInitialState() {
    return {
      adwords_status: true,
      loading: true,
      selectedMetric: 'clicks',
      user: AuthStore.getState().user,
      comments: {}
    };
  },

  selectMetric(selectedMetric) {
    this.setState({ selectedMetric });
  },

  dateFormat(date) {
    if (!date) return "";

    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  },

  deleteComment(metric) {
    del(`/api/v1/campaigns/${this.props.campaign.token}/adwords_reports_comment`, { metric });
    const { comments } = this.state;
    delete comments[metric];
    this.setState({ comments });
  },

  async updateComment(metric, comment) {
    await post(`/api/v1/campaigns/${this.props.campaign.token}/adwords_reports_comment`, { comment, metric });
    const { comments } = this.state;
    comments[metric] = comment;
    this.setState({ comments });
  },

  render() {
    if (this.state.loading) {
      return <Loading/>
    }
    if (this.state.adwords_status !== 'ready') {
      return this.activate();
    }

    return (
      <div className="adwords_analytics">
        { this.header() }
        { this.summary() }
        { this.chart() }
      </div>
    );
  },

  activate() {
    return (
      <div style={{marginTop: 10}}>
        <AdwordsAuthorize campaign={ this.props.campaign } google_adwords_enabled={ this.state.adwords_status }/>
      </div>
    );
  },

  instructions() {
    return (
      <div>
        <h3>Your account is pending</h3>
        <p>To activate the Adwords integration, follow these steps:</p>
      </div>
    );
  },

  header() {
    return (
      <div className="adwords_header">
        <h4>Google AdWords Analysis</h4>
        <div>
          <strong>AdWords Reports Used</strong>
          <ul>
            <li>
              Before outdoor campaign:
              { this.dateFormat(this.before().start_at) }
              { " to " }
              { this.dateFormat(this.before().end_at) }
            </li>
            <li>
              During outdoor campaign:
              { this.dateFormat(this.during().start_at) }
              { " to " }
              { this.dateFormat(this.during().end_at) }
            </li>
          </ul>
        </div>
      </div>
    );
  },

  legend() {
    return (
      <div className="legend">
        <div className="legend-item">
          <div className="legend-stroke blue" />
          <div>Campaign Area</div>
        </div>
        <div className="legend-item">
          <div className="legend-stroke orange" />
          <div>Control Area</div>
        </div>
      </div>
    );
  },

  summary() {
    return (
      <div className="summary">
        <div className="header">
          <h5>summary</h5>
          <div className="line"/>
        </div>
        <Comment
          user={ this.state.user }
          metric={ 'all' }
          comment={ this.state.comments['all'] }
          updateComment={ this.updateComment }
          deleteComment={ this.deleteComment }
        />
        { this.metrics() }
      </div>
    );
  },

  chart() {
    return (
      <div className="adwords_charts">
        <div className="header">
          <h5>{ this.state.selectedMetric }</h5>
          <div className="line"/>
          { this.legend() }
        </div>
        <Charts metric={ this.state.selectedMetric } reports={ this.state.reports } before={ this.before() } during={ this.during() } dateFormat={ this.dateFormat}>
          <Comment
            user={ this.state.user }
            metric={ this.state.selectedMetric }
            comment={ this.state.comments[this.state.selectedMetric] }
            updateComment={ this.updateComment }
            deleteComment={ this.deleteComment }
          />
        </Charts>
      </div>
    );
  },

  metrics() {
    return (
      <div className="metrics">
        {
          ['clicks', 'impressions', 'conversions', 'ctr', 'cpc'].map(metric => (
            <Metric
              key={ metric }
              metric={ metric }
              selected={ metric === this.state.selectedMetric }
              before={ this.before() }
              during={ this.during() }
              onClick={ () => this.selectMetric(metric) }
            />
          ))
        }
      </div>
    );
  },

  before() {
    return (this.state.reports && this.state.reports[0]) || {};
  },

  during() {
    return (this.state.reports && this.state.reports[1]) || {};
  }

});
