import React from 'react';
import cs from 'classnames';

import createClass from 'create-react-class';
import { connect } from 'react-redux';
import { BootstrapTable } from 'react-bootstrap-table';
import { push } from '../../actions/router';
import Edit from './Edit';
import AuthStore from '../../stores/AuthStore';
import { get, del } from '../../utils/api';
import GlobalActions from '../../actions/GlobalActions';
import Loading from '../Loading';

const roleFormatter = (cell) => {
  const classNames = cs('label', {
    'label-success': cell === 'admin',
    'label-primary': cell === 'sales',
    'label-default': cell === 'contact' || 'installer',
  });

  return (
    <span className={classNames}>{cell}</span>
  );
};

const Table = createClass({
  getInitialState() {
    return {
      users: [],
      auth: AuthStore.getState()
    }
  },

  componentDidMount() {
    this.loadUsers()
  },

  createNewUser() {
    this.setState({ user: { id: null, email: "", name: "", role: null, media_types: [], markets: [] } })
  },

  async loadUsers() {
    this.setState({ loading: true });
    const { users } = await get('/api/v1/supplier_users');
    this.setState({ users, loading: false });
  },

  onRowClick(user) {
    DEBUG && console.log('clicked user', user)
    const auth = this.state.auth.user;
    if(auth.is_admin || auth.is_supplier_admin) {
      this.setState({ user });
    }
  },

  async onDeleteRow(row) {
    const res = await del(`/api/v1/supplier_users/${row[0]}`);
    if (res.status == 200) {
      GlobalActions.showMessage('Deleted User');
      this.loadUsers();
    } else {
      GlobalActions.showError("Something went wrong and could not delete the user");
    }
  },

  render() {
    const { users, auth, user, loading } = this.state
    const currentUser = auth.user || {};
    const { is_admin, is_supplier_admin } = currentUser;
    users.forEach(u => u.market_names = u.markets.map(m => m.name));

    if (loading) return <Loading/>

    return (
      <div style={{ position: 'relative' }}>
        { !user &&
          <div>
            {
              (is_supplier_admin) &&
              <a
                style={{ position: 'absolute', right: 0, top: '-47px' }}
                className="btn btn-success"
                onClick={ this.createNewUser }>
                <i className="fa fa-plus" style={{ marginRight: '10px' }} />
                Account
              </a>
            }
            <BootstrapTable
              data={users}
              options={{ onRowClick: this.onRowClick, onDeleteRow: this.onDeleteRow }}
              deleteRow={ true }
              selectRow={ { mode: 'radio' } }
              tableStyle={{ cursor: 'pointer' }}
              remote hover striped>
              <TableHeaderColumn dataField='id' isKey hidden />
              {is_admin && <TableHeaderColumn dataField="supplier">Supplier</TableHeaderColumn> }
              <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
              <TableHeaderColumn dataField='market_names'>Markets</TableHeaderColumn>
              <TableHeaderColumn dataField='media_types'>Media Types</TableHeaderColumn>
              <TableHeaderColumn dataField='role' dataFormat={roleFormatter}>Permission Level</TableHeaderColumn>
            </BootstrapTable>
          </div>
        }
        {
          <Edit user={ user } onUserSave={ this.loadUsers } resetUser={ () => this.setState({ user: null }) } />
        }
      </div>
    );
  }
})

Table.propTypes = {

};

export default connect(
  null, { push }
)(Table);
