import React from 'react';
import createClass from 'create-react-class';
import CampaignDataLayerModel from '../../../models/CampaignDataLayerModel';
import SelectDemos from './SelectDemos';

export default createClass({
  getInitialState() {
    return {
    };
  },

  componentWillMount() {
  },

  componentWillUnmount() {
  },

  onAddDemographicDataLayer(data_layer_id) {
    const params = {
      campaign_id: this.props.campaign.token,
      data_layer_type: "Demographic",
      data_layer_id: data_layer_id,
    }

    $.post(`/api/v1/data_layers`, params).then(campaign_data_layer => {
      this.props.onAdd(new CampaignDataLayerModel(campaign_data_layer))
    })
  },

  onAddGeopathSegmentDataLayer(data_layer_id) {
    const params = {
      campaign_id: this.props.campaign.token,
      data_layer_type: "GeopathSegment",
      data_layer_id: data_layer_id,
    }

    $.post(`/api/v1/data_layers`, params).then(campaign_data_layer => {
      this.props.onAdd(new CampaignDataLayerModel(campaign_data_layer))
    })
  },

  render() {
    return (
      <div>
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.hide}><span aria-hidden="true">&times;</span></button>
          <h4 className="modal-title">Add Data Layer</h4>
          <div style={{ color: "var(--medium-grey)", fontStyle: "italic" }}>Source: 2020 US Census</div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <SelectDemos
                onSelect={this.onAddDemographicDataLayer}
                demographic_ids={[]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },

})
