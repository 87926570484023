import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import React from "react";
import isEmpty from "lodash/isEmpty";

import {
  batchAddUnits,
  batchAddUnitsSuccess,
  filterSupplierFaceIds,
  goToStep,
  loadBuilderCampaignUnits,
  resetBuilderState,
} from "../Vendors/CampaignBuilder/actions";
import GlobalActions from "../../actions/GlobalActions";
import JobProgress from "../../utils/JobProgress";
import UpdateUnit from "../Timeline/UpdateUnit";

class VendorAddUnitsFromMarkers extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      campaign: null,
      units: [],
      saving: false,
      job_pooling: new JobProgress(),
    };
    this.buildUnitData = this.buildUnitData.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.onAddToCampaign = this.onAddToCampaign.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.renderNext = this.renderNext.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.onJobComplete = this.onJobComplete.bind(this);
  }

  componentDidMount() {
    this.buildUnitData();
  }

  buildUnitData() {
    const options = this.props.options || {};
    const { unit } = options;
    const { markers, campaign } = this.props;
    if (unit) {
      // regretful hack for a mysterious overlay blocking the modal..
      const overlayEl = $(".overlay");
      if (overlayEl.length === 1) {
        overlayEl.css("display", "none");
      }
      this.setState({
        units: [
          {
            ...unit,
            start_date: campaign.start_date,
            end_date: campaign.end_date,
          },
        ],
      });
    } else {
      const units = markers.map(marker => {
        return {
          supplier_face_id: marker.properties.faceId,
          slug: marker.properties.id,
          start_date: campaign.start_date,
          end_date: campaign.end_date,
          price: marker.properties.price,
          screen_type: marker.properties.screen_type,
          quantity: marker.properties.screen_type !== "static" ? 1 : null,
        };
      });
      this.setState({ units });
    }
  }

  onUnitChange(unit_data) {
    const { units } = this.state;
    this.setState({
      units: units.map(u => (u.slug == unit_data.slug ? { ...unit_data, is_dirty: true } : u)),
    });
  }

  componentWillUnmount() {
    const { loadBuilderCampaignUnits, campaign } = this.props;
    loadBuilderCampaignUnits(campaign.token);
  }

  componentDidUpdate(prevProps) {
    const { filterSupplierFaceIds, units_added, supplier_face_ids, markers } = this.props;
    if (markers !== prevProps.markers) {
      this.buildUnitData();
    }
    if (units_added && units_added !== prevProps.units_added) {
      const excluded_supplier_face_ids = units_added.map(unit => {
        return unit.supplier_face_id;
      });
      filterSupplierFaceIds(supplier_face_ids, excluded_supplier_face_ids);
    }
  }

  onAddToCampaign() {
    const { batchAddUnits, campaign } = this.props;
    const { units } = this.state;
    this.setState({ saving: true }, async () => {
      const job_id = await batchAddUnits(campaign.token, units);
      this.state.job_pooling.startPolling(job_id, this.onJobComplete);
    });
  }

  onJobComplete(response) {
    const { data } = response;
    const { error, already_on_campaign } = data;

    this.setState({ saving: false });
    if (already_on_campaign && already_on_campaign > 0) {
      GlobalActions.showMessage(`Skipped ${already_on_campaign} units already on this campaign.`);
    } else if (error) {
      return GlobalActions.showError("Something went wrong");
    }
    this.props.batchAddUnitsSuccess(data);
  }

  renderSuccess() {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "250px",
          justifyContent: "center",
          fontSize: "26px",
          alignItems: "center",
        }}
      >
        All units added to campaign!
      </div>
    );
  }

  onNextClick() {
    const { goToStep } = this.props;
    this.onHideModal();
    goToStep(3);
  }

  renderNext() {
    const { campaign, campaign_builder, goToStep } = this.props;
    const inBuilder = !!!window.location.pathname.includes("campaigns/");

    if (inBuilder) {
      return (
        <a onClick={this.onNextClick} className="btn btn-success" style={{ marginRight: 5 }}>
          Review Campaign
        </a>
      );
    } else {
      return (
        <a href={`/campaigns/${campaign.token}/timeline`} className="btn btn-success" style={{ marginRight: 5 }}>
          View Flights
        </a>
      );
    }
  }

  renderForm() {
    const { units, saving } = this.state;
    return (
      <div>
        <div className="row header_row">
          <div className="col-xs-2" style={{ margin: "5px 5px 5px 10px" }}>
            Face ID
          </div>
          <div className="col-xs-5" style={{ margin: "5px 5px 5px 0px", paddingLeft: "10px" }}>
            Dates
          </div>
          <div className="col-xs-2" style={{ margin: "5px 5px 5px 0px", paddingLeft: "5px" }}>
            4 Week Price
          </div>
          <div className="col-xs-2" style={{ margin: "5px 5px 5px 0px", paddingLeft: "5px" }}>
            Spots
          </div>
        </div>
        {units.map(unit => {
          return <UpdateUnit key={`id-${unit.slug}`} unit={unit} onChange={this.onUnitChange} />;
        })}
      </div>
    );
  }

  onHideModal() {
    const { onHide } = this.props;
    if (onHide) {
      onHide();
    } else {
      GlobalActions.closePopup();
    }
  }

  unitsAddedFaceIds() {
    const { units_added } = this.props;
    const units = units_added || [];
    return units.map(u => u.supplier_face_id);
  }

  isUnitsAdded() {
    const { units_added } = this.props;
    return !isEmpty(units_added);
  }

  renderConfirmMessage() {
    const { units } = this.state;
    return (
      <div
        style={{
          display: "flex",
          minHeight: "250px",
          justifyContent: "center",
          fontSize: "26px",
          alignItems: "center",
        }}
      >
        {`Add ${units.length} units to the campaign?`}
      </div>
    );
  }

  allUnitsAdded() {
    const { units } = this.state;
    if (units.length == 0) { return false; }
    const unitFaceIds = units.map(u => u.supplier_face_id);
    const added_face_ids = this.unitsAddedFaceIds();
    return unitFaceIds.every(face_id => added_face_ids.includes(face_id));
  }

  render() {
    const { units, saving } = this.state;
    const allUnitsAdded = this.allUnitsAdded();
    const body = allUnitsAdded ? this.renderSuccess() : this.renderConfirmMessage();
    return (
      <Modal show={true} onHide={this.onHideModal} dialogClassName="modal_large" className="vendor_campaign_map_modal">
        <Modal.Header className="text-center" closeButton>
          <b>Confirm adding {units.length} units to campaign</b>
        </Modal.Header>
        <Modal.Body className="row">
          <div className="col-xs-12">
            {body}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.onHideModal} className={`btn ${allUnitsAdded ? "add_more_units" : "btn-default"}`}>
            {allUnitsAdded ? "Add More Units" : "Cancel"}
          </button>
          {allUnitsAdded ? (
            this.renderNext()
          ) : (
            <button onClick={this.onAddToCampaign} className="btn btn-primary" disabled={saving}>
              {saving ? "Saving..." : "Add To Campaign"}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  ({
    // @ts-ignore
    campaign_builder_ui: { units_added, campaign_builder, supplier_face_ids },
    // @ts-ignore
    campaign: { campaign },
  }) => ({
    units_added,
    campaign_builder,
    supplier_face_ids,
    campaign
  }),
  {
    batchAddUnits,
    resetBuilderState,
    goToStep,
    filterSupplierFaceIds,
    loadBuilderCampaignUnits,
    batchAddUnitsSuccess,
  },
)(VendorAddUnitsFromMarkers);
