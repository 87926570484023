import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {hideModal, updateAvails} from "./actions";

class UpdateAvailsModal extends React.Component {

  constructor(props) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
  }

  onUpdate(e) {
    e.preventDefault();
    const { selected_units } = this.props;
    const unitIds = selected_units.map(u => u.id);
    this.props.updateAvails(unitIds);
    this.props.hideModal();
  }

  render() {
    const {
      hideModal,
      show,
      selected_units
    } = this.props;
    const selectedLength = this.props.selected_units.length;

    return (
      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{selectedLength === 0 ? 'Select units on inventory page to update availability' : `Update availability on ${selectedLength} units`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="clearfix">
            {selected_units.length > 0 && (
              <div>
                <div
                  className="selected_units form-group"
                  style={{backgroundColor: "#f0f0f0", margin: "10px", padding: "10px"}}
                >
                  <h5 style={{marginLeft: "15px"}}>You're about to update availability of the following units:</h5>
                  <ul style={{padding: "10px", marginLeft: "20px", listStyleType: "disc"}}>
                    {selected_units.map(unit => {
                      return (
                        <li
                          style={{marginTop: "1px", padding: "1px", listStyleType: "disc"}}
                          key={`selected-unit-${unit.id}-${unit.name}`}
                        >{`${unit.supplier_face_id} - ${unit.name}`}</li>
                      );
                    })}
                  </ul>
                </div>
                <input
                  type="submit"
                  style={{margin: "10px"}}
                  value="Update"
                  className="btn btn-default"
                  onClick={this.onUpdate}
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
};

export default connect(
  ({inventory: {selected_units}}) => {
    return {selected_units};
  }, {hideModal, updateAvails}
)(UpdateAvailsModal);
