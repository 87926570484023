import React, { Component } from 'react';
import moment from 'moment';

class BookOrHoldNotice extends Component {

  unitIsAvailable(){
    const { unit } = this.props;
    const { supplier_status, available } = unit;
    return available && _.includes(['initial_approved', 'refresh_approved'], supplier_status)
  }

  daysSinceMarkedAsAvailable(){
    const { unit } = this.props;
    const marked_available_at = unit.available;
    const now = moment(new Date())
    const difference = moment.duration(now.diff(marked_available_at));
    return Math.floor(difference.asDays());
  }

  availabilityHasExpired(){
    return this.daysSinceMarkedAsAvailable() > 7
  }

  render() {
    const { unit } = this.props;
    const { available } = unit;
    const daysSinceMarkedAsAvailable = this.daysSinceMarkedAsAvailable()
    const unitIsAvailable = this.unitIsAvailable() && daysSinceMarkedAsAvailable < 30;
    const daysLeftToBookOrHold = 7 - daysSinceMarkedAsAvailable;
    const availabilityHasExpired = this.availabilityHasExpired();

    return (
      <div>
        {unitIsAvailable && !availabilityHasExpired && <div className="unit-availability">
          <div className="availability-book-or-hold">
            <div className="available-on">
              <span className="strong">
                Availability received on {moment(available).format('LL')}
              </span>
              <p>
                Book or hold this unit in the next {daysLeftToBookOrHold} days.
                After that, unit might become unavailable.
              </p>
            </div>
          </div>
        </div>}
        {unitIsAvailable && availabilityHasExpired && <div className="unit-availability">
          <div className="availability-book-or-hold availability-expired">
            <div className="available-on">
              <span className="strong">
                We got availability for this unit {this.daysSinceMarkedAsAvailable()} days ago
              </span>
              <p>
                If you're interested in this unit,
                you should book or hold it as soon as possible to avoid it becoming unavailable
              </p>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BookOrHoldNotice
